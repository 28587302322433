import BreachStore from './Store.js';
import Store from '../../../../../Common/Store.js';

class Utils {
    // constructor() {
    // }
    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    log() {
    }

    setEnv(module_name) {
        let api_url = `${Store.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;
        BreachStore.updateStore('module_name',module_name);
        BreachStore.updateStore('api_url',api_url);
        BreachStore.updateStore('is_admin',false);
        BreachStore.updateStore('is_debug',false);
    }

    copyObject = (obj) => {
        return JSON.parse(JSON.stringify(obj))
    }
    isBase64(str) {
        if (str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

}

export default new Utils();
