import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPen} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js';
import DatePicker from 'react-date-picker';
import HtmlEditor from '../Common/HtmlEditor';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import IndexLoader from '../../Common/IndexLoader.js';

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #333;
    font-weight: 600;
`;

const ModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #234062;
    font-family: 'Montserrat', sans-serif;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #f7f7f7;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:10px;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
    cursor:pointer;
`;
const ModeltopText = styled.div`
    display: grid;
    grid-gap: 26px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6ebd6;
    color: #f6ce76;
    background-color: #f6ebd6;
`;
const ModalContainertop = styled.div`
    display: grid;
    // grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 10px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;



const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const RevertButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #f5a81e;;
    color: #ffffff;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 10px;
    font-size: 11px;
    cursor:pointer;
`;
const PublishButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const UpdateButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #00b0a9;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const DeleteButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;


class FaqApprovalModal extends React.Component
{
    state = {
        faq_header_text: '',
        faq_answer: '',
        faq_query: '',
        faq_id: 0,
        binFiles: [],
        cur_files_json: null,
        tags:[],
        ref_id: '',
        editquestionmode:false,
        edittagmode: false,
        editanswermode: false,
        revertfaq:false,
        requested_by: 0,
        revert_reason: '',
        company_id : 0,
        is_reverted: true,
        show_save: false,
        faq_created_by: 0,
        from_api: 0,
        original_faq_id: 0,
        show_scorll: true,
        ready:false,
        expiry_date: new Date(),
        alert_param: null,
        cur_lane: '',
        last_action: '',
        is_loading: false
    };


    filesLoaded = (jsonObj) => {
        let contact_id = Store.getStoreData('contact_id');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let binFiles = this.state.binFiles;
        let file_exists = {};
        let curBinFiles = [];

        if(jsonObj.command === 'add'){
            for(let i=0; i < files_to_show.length; i++) {
                if(typeof files_to_show[i].name !== "undefined"){
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if(!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = contact_id;
                        files_to_show[i].upload_date = Date.now();
                    }
                    if(typeof file_exists[files_to_show[i].name] === "undefined"){
                        binFiles.push(files_to_show[i]);
                        file_exists[files_to_show[i].name] = true;
                    }
                }

            }
            curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
            this.setState({binFiles: curBinFiles});
        }else{
            for(let i=0; i < binFiles.length; i++) {
                if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
                    binFiles.splice(i,1);
                }
            }
            this.setState({binFiles: binFiles});
        }
    }

    constructor(props) {
        super(props);
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }



    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    saveFAQ(){
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let bin_files = this.state.binFiles;
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let dateobj = new Date(this.state.expiry_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        let postData = { 
            "command": "send_save_faq",
             faq_query: this.state.faq_query, 
             faq_answer: answer_summary,
             tags: selectedtags,
             faq_id: this.state.faq_id,
             binFiles : bin_files,
             company_id: this.state.company_id,
             revert_save: this.state.show_save,
             expiry_date: expiry_date
         };

        let api = new APICall();
        api.indexCommand(postData, this.processSaveFAQ);
        this.setState({is_loading: true})
    }

    processSaveFAQ = (result) => {
        let msg = 'Your FAQ has been saved.'
        let title = 'Task Save'
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, is_loading: false})
        
    }


    PublishORrevertFAQ(){
        let altmsg = this.state.revertfaq ? "Are you sure you want to revert this FAQ?" : "Are you sure you want to publish this FAQ?";
        let title = 'Confirm';
        let alert_param = {title: title, message: altmsg, ok_text: 'OK', confirm: true, alertHandler: this.confirmPublishORrevertHandler, stack: {parms: {}}}
        this.setState({alert_param: alert_param})
    }

    confirmPublishORrevertHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let bin_files = this.state.binFiles;
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let command = this.state.revertfaq ? "send_faq_revert": "send_publish_faq";
        if(command === "send_faq_revert"){
            if(this.state.revert_reason === '') {
                let msg = 'Revert reason cannot be blank'
                let title = 'Warning'
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.onlyalertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return false;
            }
        }
        let dateobj = new Date(this.state.expiry_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        let postData = { 
            "command": command,
             faq_query: this.state.faq_query, 
             faq_answer: answer_summary,
             tags: selectedtags,
             faq_id: this.state.faq_id,
             binFiles : bin_files,
             revert_reason: this.state.revert_reason,
             requested_by: this.state.requested_by,
             company_id: this.state.company_id,
             car_ref_id: this.state.ref_id,
             from_api: this.state.from_api,
             original_faq_id: this.state.original_faq_id
         };

         if(command === 'send_publish_faq') {
             postData['expiry_date'] = expiry_date;
         }

         console.log("postData", postData);

        let api = new APICall();
         api.indexCommand(postData, this.processApprovalFAQ);
         this.setState({is_loading: true})
    }

    processApprovalFAQ =(result) => {
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = this.state.revertfaq ? "FAQ reverted back to author." : "The FAQ has been published.";
        let title = 'Success'
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param, is_loading: false})
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    isBase64(str) {
        if (str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    processFAQData = (result) => {
        let task_json = JSON.parse(result.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let {faq_header_text,faq_answer, faq_query, faq_id, binFiles, cur_files_json, tags, ref_id, company_id, is_reverted,faq_created_by, from_api, original_faq_id, expiry_date, cur_lane, last_action} = this.state;

        let flag = this.isBase64(task_json.object_data.answer);
        faq_answer = task_json.object_data.answer;
        if(flag){
            faq_answer = window.decodeURIComponent(atob(task_json.object_data.answer))
        }

        faq_query = task_json.object_data.query;
        faq_header_text = task_json.object_data.query;
        faq_id = result.id;
        cur_files_json = task_json.object_data.bin_files;
        binFiles = task_json.object_data.bin_files;
        ref_id = ('faq_ref_id' in task_json.object_data) ? task_json.object_data.faq_ref_id : "";
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        let ntags = this.isLoadTags(ptags);
        let create_for = !task_json.object_data.create_for ? 0 : task_json.object_data.create_for;
        company_id = result.company_id;
        is_reverted = result.cur_lane === 'ADD_CAR_FAQ' ? false : true;
        faq_created_by = result.contact_id;
        from_api = result.from_api;

        let moduleconfig = Store.getStoreData('moduleconfig');
        let days_after_faq_expire = 0;
        if(moduleconfig.enabled_faq_expiry){
            days_after_faq_expire = parseInt(moduleconfig.days_after_faq_expire);
        }
        let faq_expire_date = new Date(Utils.adddaystoDate(days_after_faq_expire));

        expiry_date = ('expiry_date' in task_json.object_data) ? new Date(task_json.object_data.expiry_date) : faq_expire_date;
        original_faq_id = ('original_faq_id' in task_json.object_data) ? task_json.object_data.original_faq_id : 0;
        cur_lane = result.cur_lane;
        last_action = result.last_action;
        this.setState({faq_header_text,faq_answer, faq_query, faq_id, binFiles,cur_files_json, tags:ntags, ref_id, requested_by:create_for, company_id, is_reverted, faq_created_by, from_api, original_faq_id, ready:true, expiry_date, cur_lane, last_action});
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }

    componentDidMount()
    {
        let cur_faq_approval_data = Store.getStoreData('cur_faq_approval_data');
        this.processFAQData(cur_faq_approval_data);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            let cur_faq_approval_data = Store.getStoreData('cur_faq_approval_data');
            this.processFAQData(cur_faq_approval_data);
        }
        if(this.state.show_scorll) {
            this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
        
    }

    editQuestion = (event) => {
        event.preventDefault();
        let editquestionmode = this.state.editquestionmode ? false : true;
        let show_scorll = false;
        this.setState({editquestionmode, show_scorll});

    }

    editTags = (event) => {
        event.preventDefault();
        let edittagmode = this.state.edittagmode ? false : true;
        let show_scorll = false;
        this.setState({edittagmode, show_scorll});

    }

    editAnswer = (event) => {
        event.preventDefault();
        let show_scorll = false;
        let editanswermode = this.state.editanswermode ? false : true;
        this.setState({editanswermode, show_scorll});
    }

    RevertFAQ = (event) => {
        event.preventDefault();
        let show_scorll = false;
        let revertfaq = this.state.revertfaq ? false : true;
        this.setState({revertfaq, show_scorll});
    }



    onExpireDateChange = (date) => {
        console.log("date", date);
        this.setState({ expiry_date: date })
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }

    onlyalertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
    }


    UpdateFAQ(flag){
      let msg = flag === "delete" ? "Are you sure you want to delete this FAQ?" : "Are you sure you want to update this FAQ?";
      let title = 'Confirm';
      // alert("Mandatory Fields must be populated to progress");
      let parms = {};
      parms["flag"] = flag;
      let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: true, alertHandler: this.confirmAlertHandler, stack: {parms: parms}}
      this.setState({alert_param: alert_param})
    }

    confirmAlertHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let parms = stack.parms;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));

        let bin_files = this.state.binFiles;
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
         if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let command = parms.flag === "delete" ? "send_faq_delete": "send_update_faq";

        let dateobj = new Date(this.state.expiry_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        let postData = { 
         "command": command,
          faq_query: this.state.faq_query, 
          faq_answer: answer_summary,
          tags: selectedtags,
          binFiles : bin_files,
          task_id: this.state.faq_id,
          faq_id: this.state.original_faq_id,
          cur_lane: this.state.cur_lane,
          last_action: this.state.last_action+"_"+"SAVE",
          'only_update': true
        };

        if(command === 'send_update_faq') {
          postData['expiry_date'] = expiry_date;
        }

        console.log("postData", postData);

        let api = new APICall();
        api.indexCommand(postData, this.processApprovalupdateFAQ);
        this.setState({is_loading: true})

    }

    processApprovalupdateFAQ =(result) => {
        let msg = result.error_msg;
        let title = 'Alert';
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        stack['mode'] = result.error_code === 0 ? result.mode : '';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param, is_loading: false})
        
    }


    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        console.log("this.state", this.state);
        return (
            <div>
                <ModalContainer>
                    {
                        (() => {
                            if(this.state.is_loading) {
                                return <CSLLoader style={{position: 'absolute'}} />
                            }
                        })()
                    }
                    <ModalHeaderText>{this.state.ref_id} - {this.state.faq_header_text.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</ModalHeaderText>
                    <ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></ModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <ModalBody>
                        <ModalBodyInner>
                            <ModeltopText style={{fontWeight:"600",color: "#f6ce76"}}>The FAQ has submited by { Store.getStoreData('contacts')[this.state.faq_created_by] } and requires your approval before it can be published</ModeltopText>
                            <div style={{clear: "both"}}></div>
                            <ModalContainertop>
                                <ModalNameLabelDiv>Question <span><FaPen onClick={this.editQuestion}/></span></ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                {
                                    (() => {
                                        if(this.state.editquestionmode){
                                            return(<ModalTextArea name="faq_query" defaultValue={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />)
                                        }else{
                                            return(<Style.ModalTextAreaReadonly defaultValue={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly />)
                                        }
                                    })()
                                }
                                </ModalNameInputDiv>
                            </ModalContainertop>
                                
                                <ModalContainertop>
                                    <ModalNameLabelDiv>Answer <span><FaPen onClick={this.editAnswer}/></span></ModalNameLabelDiv>
                                        <ModalNameInputDiv>
                                            {
                                                (() => {
                                                    if(this.state.editanswermode){
                                                        {/*return(<ModalTextArea name="faq_answer" defaultValue={this.state.faq_answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />)*/}
                                                        return <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.faq_answer} />
                                                    }else{
                                                        return <div dangerouslySetInnerHTML={{__html: this.state.faq_answer}} style={{textAlign: "justify"}}></div> 
                                                    }
                                                })()
                                            }
                                        </ModalNameInputDiv>
                                        <div style={{clear: "both"}}></div>
                                <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />*/}
                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} addnew={false} />
                                </Style.ModalNameInputDiv>
                            </ModalContainertop>
                            <div style={{clear: "both"}}></div>
                            <ModalContainertop>
                                <ModalNameLabelDiv>Tags <span><FaPen onClick={this.editTags}/></span></ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                    {
                                        this.state.tags.map((tag, index) => {
                                            if(this.state.edittagmode){
                                                return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                            }else {
                                                if(tag.selected){
                                                    return (<tag.styledcomponent key={index} >{tag.name}</tag.styledcomponent>);
                                                }
                                            }
                                            
                                        })
                                    }
                                </ModalNameInputDiv>
                            </ModalContainertop>
                            {
                                (() => {
                                    if(Store.getStoreData('role') === 'admin_manager') {
                                        return(<ModalContainertop>
                                                <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                                <CSLDateCover>
                                                    <DatePicker
                                                            onChange={this.onExpireDateChange}
                                                            clearIcon={null}
                                                            calendarIcon={null}
                                                            locale={"en-GB"}
                                                            value={this.state.expiry_date}
                                                            />
                                                            </CSLDateCover>
                                                </ModalContainertop>
                                            )
                                    }
                                })()
                            }
                        </ModalBodyInner>
                    </ModalBody>
                    {
                        (() => {
                            if(this.state.revertfaq) {
                                return(
                                    <div>
                                        <div style={{clear: "both"}}></div>
                                        <ModalContainertop style={{backgroundColor: "#fff"}}>
                                            <ModalNameInputDiv>
                                                <ModalTextArea name="revert_reason" value={this.state.revert_reason} onChange={this.handleChange} />
                                            </ModalNameInputDiv>
                                        </ModalContainertop>
                                        <CancelSubmitButtonOuterContainer>
                                            <CancelButton onClick={this.closeModal}>Close</CancelButton>
                                            <RevertButton onClick={() => this.PublishORrevertFAQ()}>Revert</RevertButton>
                                        </CancelSubmitButtonOuterContainer>
                                    </div>
                                )
                            }else{
                                return(<div>
                                    <CancelSubmitButtonOuterContainer>
                                        <CancelButton onClick={this.closeModal}>Close</CancelButton>
                                        {
                                            (() => {
                                                if(this.state.from_api === 0) {
                                                    return(<div style={{display:"inline"}}>
                                                        {
                                                            (() => {
                                                                if(this.state.is_reverted) {
                                                                    return(<RevertButton onClick={this.RevertFAQ}>Revert</RevertButton>)
                                                                }
                                                            })()
                                                        }
                                                    </div>)
                                                }
                                            })()
                                        }
                                        <PublishButton onClick={() => this.PublishORrevertFAQ()}>Publish</PublishButton>
                                        {
                                            (() => {
                                                if(Store.getStoreData('role') === 'admin_manager') {
                                                    return(<div style={{display:"inline"}}>
                                                        <DeleteButton onClick={() => this.UpdateFAQ('delete')}>Delete</DeleteButton>
                                                        <UpdateButton onClick={() => this.UpdateFAQ('update')}>Update</UpdateButton>
                                                        </div>)
                                                }
                                            })()
                                        }
                                    </CancelSubmitButtonOuterContainer>
                                </div>)
                            }
                        })()
                    }
                    <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>

                </ModalContainer>
                <AlertBox alertParam = {this.state.alert_param} />
            </div>    
        );
    }
}
export default FaqApprovalModal;