import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import APICall from '../Common/APICall.js';


class BareIndex extends React.Component
{
  render()
  {
    return (
      <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
      </div>
    );
  }
}

export default BareIndex;
