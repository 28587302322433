import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import Dropzone from '../Common/Dropzone';
import { FaTimes} from 'react-icons/fa';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import moment from 'moment';
import AlertBox from '../Common/AlertBox';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #2C2C2C;
    font-size: 17px;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 30px;
    color: #9C9C9C;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #282828;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;  
    font-family: 'Montserrat',sans-serif; 
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 24px;
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 72px;
    display:inline;
`;

const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 0px 20px 10px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #E1E3E4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px; 
    display: inline-block;
    margin-top: 28px;   
`;

const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class TeamFollowupModal extends React.Component
{

    state = {task: null, payload: {}, task_id: null, comments: "",task_title:"", task_note: "", ready: false, send_mail_config: false,
    curlane: '',binFiles: [], cur_files_json: null, is_disabled: false, isLoadedIcon: false, alert_param: null };

    GetPayloadData = (parent_task) => {
        let parent_task_json = JSON.parse(parent_task.task_json);

        let qtext_nature_of_conflict = parent_task_json.object_data.headerinfo.nature_of_conflict
        if(Utils.isBase64(parent_task_json.object_data.headerinfo.nature_of_conflict)){
            qtext_nature_of_conflict = window.decodeURIComponent(atob(qtext_nature_of_conflict))
        }
        let qtext_risk_description = parent_task_json.object_data.headerinfo.risk_description
        if(Utils.isBase64(parent_task_json.object_data.headerinfo.risk_description)){
            qtext_risk_description = window.decodeURIComponent(atob(qtext_risk_description))
        }
        let qtext_steps_to_mitigate_risk = parent_task_json.object_data.headerinfo.steps_to_mitigate_risk
        if(Utils.isBase64(parent_task_json.object_data.headerinfo.steps_to_mitigate_risk)){
            qtext_steps_to_mitigate_risk = window.decodeURIComponent(atob(qtext_steps_to_mitigate_risk))
        }
        let additional_records_of_disclosure = {}
        additional_records_of_disclosure.enabled = parent_task_json.object_data.headerinfo.additional_records_of_disclosure.enabled
        let qtext_ard_description = parent_task_json.object_data.headerinfo.additional_records_of_disclosure.description
        if(Utils.isBase64(parent_task_json.object_data.headerinfo.additional_records_of_disclosure.description)){
            qtext_ard_description = window.decodeURIComponent(atob(qtext_ard_description))
        }
        additional_records_of_disclosure.description = qtext_ard_description;

        let disclosure_to_third_party = {};
        disclosure_to_third_party.enabled = parent_task_json.object_data.headerinfo.disclosure_to_third_party.enabled
        let qtext_dtp_description = parent_task_json.object_data.headerinfo.disclosure_to_third_party.description
        if(Utils.isBase64(parent_task_json.object_data.headerinfo.disclosure_to_third_party.description)){
            qtext_dtp_description = window.decodeURIComponent(atob(qtext_dtp_description))
        }
        disclosure_to_third_party.description = qtext_dtp_description

        let conflicts_raised_date = 'conflicts_raised_date' in parent_task_json.object_data.headerinfo ? moment(parent_task_json.object_data.headerinfo.conflicts_raised_date).format('DD/MM/YYYY') : ''
        let conflict_title = 'conflict_title' in parent_task_json.object_data.headerinfo ? parent_task_json.object_data.headerinfo.conflict_title : '';

        let payload = {};
        payload.Ref_ID = parent_task_json.object_data.ref_id;
        payload.conflict_title = conflict_title;
        payload.conflicts_raised_date = conflicts_raised_date;
        payload.nature_of_conflict = qtext_nature_of_conflict;
        payload.risk_description = qtext_risk_description;
        payload.steps_to_mitigate_risk = qtext_steps_to_mitigate_risk;
        payload.additional_records_of_disclosure =  additional_records_of_disclosure;
        payload.disclosure_to_third_party = disclosure_to_third_party;
        payload.additional_fields = 'additional_fields' in parent_task_json.object_data.headerinfo ? parent_task_json.object_data.headerinfo.additional_fields : [];

        return payload;
    }

	componentDidMount()
	{
        let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
        let task_data = Store.getStoreData('followup_task');
        curlane = task_data.subtask.cur_lane;
        let followup_task = task_data.subtask;
        task_id = followup_task.id;
        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject
        task_note = task_json.object_data.note
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        }
        let task_command = "";
        if('commands' in task_json.object_data){
            task_command = task_json.object_data.commands;
        }
        let parent_task = task_data.parenttask;
        console.log("parent_task",followup_task)
        payload = this.GetPayloadData(parent_task);
        console.log("payload", task_data);
        this.setState({task: task_json,payload,task_id,task_title,task_note,comments: task_command,curlane,cur_files_json: cur_files_json,ready:true});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
            let task_data = Store.getStoreData('followup_task');
            curlane = task_data.subtask.cur_lane;
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject
            task_note = task_json.object_data.note
            let parent_task = task_data.parenttask;
            console.log("parent_task",parent_task)
            payload = this.GetPayloadData(parent_task);
            console.log("payload", task_data);
            this.setState({task: task_json,payload,task_id,task_title,task_note,curlane,ready:true});
		}
	}
    closeModal = (event) => {
        // if (document.referrer.endsWith("/tmview")) {
        //     window.location = '/';
        // } else {
        //     //event.preventDefault();
        //     this.props.closeModal();
        // }
        if(Store.getStoreData('isreferenceUrl') === true){
            // window.location = '/';
            this.props.closeModal();
        } else {
            this.props.closeModal();
        }
    }

    UpdateSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        //console.log(task_json);
        task_json['task_data'].last_action = 'COMP_COI_FOLLOWUP_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_COI_FOLLOWUP_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments;
        task_json['action_data'] = {action: "COMP_COI_FOLLOWUP_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_COI_FOLLOWUP_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        let postData = {command: "update_conflicts_followup_task", task_json: task_json, task_id: this.state.task_id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.afterCompleted);
    }
    afterCompleted = (result) => {
        // console.log("Completed:", result);
        // alert('Followup task completed successfully.');
        let alert_param = {title: 'Success', message: 'Followup task completed successfully.', ok_text: 'Ok', confirm: false,
                alertHandler: this.okAfterComplete, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        // window.location.reload(true);
    }

    okAfterComplete = (result, stack) => {
        this.setState({alert_param: null})
        window.location.reload();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    viewGenerateField = (data, data_container) => {
        // if(data.type === 'Date'){
        //     let dateValue = "";
        //     if(data.value !== ''){
        //         const reported_date_obj = new Date(data.value);
        //         const reported_date_day = reported_date_obj.getDate() < 10 ? "0"+reported_date_obj.getDate().toString() : reported_date_obj.getDate();
        //         const reported_date_month = reported_date_obj.getMonth()+1 < 10 ? `0${(reported_date_obj.getMonth() + 1).toString()}` : reported_date_obj.getMonth()+1;
        //         const reported_date_year = reported_date_obj.getFullYear();
        //         dateValue = `${reported_date_day}/${reported_date_month}/${reported_date_year}`;
        //     }
        //     return (
        //         <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
        //             <div style={{display: "inline-block"}}>
        //                 <MRModalLabel>{data.nickname}</MRModalLabel>
        //                 <div style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{dateValue}</div>
        //             </div>
        //         </div>
        //     );

        // }
        // if(data.type === 'User List'){
        //     let contacts =  Store.getStoreData('contacts');
        //     let userName = "";
        //     if(data.value !== ''){
        //         userName = data.value in contacts ? contacts[data.value] : "";
        //     }
        //     return (
        //         <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
        //             <div style={{display: "inline-block"}}>
        //                 <MRModalLabel>{data.nickname}</MRModalLabel>
        //                 <div style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{userName}</div>
        //             </div>
        //         </div>
        //     );
        // }
        if(data.type !== 'Date' || data.type !== 'User List'){
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%"}}>
                    <div style={{display: "inline-block"}}>
                        <MRModalLabel>{data.nickname}</MRModalLabel>
                        <div style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{data.value}</div>
                    </div>
                </div>
            );
        }
    }

    render()
    {
        let curAssignTo = Store.getStoreData('followup_task').subtask.cur_assigned_to;
        let curUserID = Store.getStoreData('contact_id');
        let role = Store.getStoreData('role');
        console.log("task_data===>", role)
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
            <RegModalContainer>
                <div style={{marginTop: "17px"}}>
                    <RegModalHeaderText>{this.state.task_title}</RegModalHeaderText>
                    <RegModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></RegModalHeaderCloseBtn>
                </div>
                <RegDismisOuterContainer>
                    <OverviewInfoBody style={{marginBottom: "10px"}}>
                        <MRModalLabel>Task Description</MRModalLabel>
                    {/* <div style={{marginBottom: "15px"}}><span style={{color: "#383939",fontWeight: "bold"}}>Task Description</span></div>  */}
                    <div dangerouslySetInnerHTML={{__html: this.state.task_note}} style={{textAlign: "justify",color: "#6C6C6C"}}></div>
                    {
                        (() => {
                            if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                return(<div>
                                        <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                        <ModalNameInputDiv>
                                            <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                        </ModalNameInputDiv>
                                </div>)
                            }
                        })()
                    }
                    </OverviewInfoBody>
                    <OverviewInfoBody style={{marginBottom: "10px"}}>
                        <MRModalLabel>Conflict Title</MRModalLabel>
                        <div dangerouslySetInnerHTML={{__html: this.state.payload.conflict_title}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                        <MRModalLabel style={{marginTop: "30px"}}>Date Raised</MRModalLabel>
                        <div style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{this.state.payload.conflicts_raised_date}</div>
                        <MRModalLabel style={{marginTop: "30px"}}>Please describe the nature of the Conflict</MRModalLabel>
                        <div dangerouslySetInnerHTML={{__html: this.state.payload.nature_of_conflict}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                        <MRModalLabel style={{marginTop: "30px"}}>Please describe the risk</MRModalLabel>
                        <div dangerouslySetInnerHTML={{__html: this.state.payload.risk_description}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                        <MRModalLabel style={{marginTop: "30px"}}>Please describe the steps being taken to mitigate this risk</MRModalLabel>
                        <div dangerouslySetInnerHTML={{__html: this.state.payload.steps_to_mitigate_risk}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                        <MRModalLabel style={{marginTop: "30px"}}>Are there any additional records or information relating to this Disclosure?</MRModalLabel>
                        <div>
                            <div className="switch">
                                <input type="radio" name="allowAROD" id="yes" value="Yes" checked={this.state.payload.additional_records_of_disclosure.enabled === 'Yes'}  />
                                <Radiolevel className="rediolabel" htmlFor="yes" style={{cursor: "default"}}>Yes</Radiolevel>
                                <input type="radio" name="allowAROD" id="no" value="No" checked={this.state.payload.additional_records_of_disclosure.enabled === 'No'}  />
                                <Radiolevel className="rediolabel" htmlFor="no" style={{cursor: "default"}}>No</Radiolevel>
                                <span className="switchFilter"></span>
                            </div>
                        </div>
                        {
                            (() => {
                                if(this.state.payload.additional_records_of_disclosure.enabled === 'Yes') {
                                    return (
                                    <div dangerouslySetInnerHTML={{__html: this.state.payload.additional_records_of_disclosure.description}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                    );
                                }
                            })()
                        }
                        <MRModalLabel style={{marginTop: "30px"}}>Has or will a Disclosure be made to a 3rd party?</MRModalLabel>
                        <div>
                            <div className="switch1">
                                <input type="radio" name="allowThiredParty" id="yes1" value="Yes" checked={this.state.payload.disclosure_to_third_party.enabled === 'Yes'}  />
                                <Radiolevel className="rediolabel" htmlFor="yes1" style={{cursor: "default"}}>Yes</Radiolevel>
                                <input type="radio" name="allowThiredParty" id="no1" value="No" checked={this.state.payload.disclosure_to_third_party.enabled === 'No'}  />
                                <Radiolevel className="rediolabel" htmlFor="no1" style={{cursor: "default"}}>No</Radiolevel>
                                <span className="switchFilter1"></span>
                            </div>
                        </div>
                        {
                            (() => {
                                if(this.state.payload.disclosure_to_third_party.enabled === 'Yes') {
                                    return (
                                    <div dangerouslySetInnerHTML={{__html: this.state.payload.disclosure_to_third_party.description}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                    );
                                }
                            })()
                        }
                        {
                            (() => {
                                if (this.state.payload.additional_fields.length !== 0) {
                                    let custom_html = [];
                                    this.state.payload.additional_fields.forEach((item) => {
                                        const field = this.viewGenerateField(item, "additional_fields");
                                        custom_html.push(field);
                                    })
                                    return custom_html;
                                }
                            })()
                        }
                    </OverviewInfoBody>
                </RegDismisOuterContainer>
                <MRModalBody>
                {
                    (() => {
                        if(curAssignTo === curUserID && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                            return(<div>
                                    <MRModalLabel>Comments</MRModalLabel>
                                    <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                                    </div>)
                            }else if((role === "admin_manager" || role === "manager") && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                return(<div>
                                    <MRModalLabel>Comments</MRModalLabel>
                                    <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                                    </div>)
                            }else{
                                return(<div>
                                    <MRModalLabel>Comments</MRModalLabel>
                                    <RegDismissalReason name="comments" value={this.state.comments} readOnly></RegDismissalReason>
                                    </div>)
                            }
                    })()
                }
                </MRModalBody>
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
                    {
                        (() => {
                            if(curAssignTo === curUserID && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                return(<RegSubmitButton onClick={this.UpdateSubTask}>Complete</RegSubmitButton>)
                            }else if((role === "admin_manager" || role === "manager") && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                return(<RegSubmitButton onClick={this.UpdateSubTask}>Complete</RegSubmitButton>)
                            }
                        })()
                    }

                </RegCancelSubmitButtonOuterContainer>
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        )
    }

}
export default TeamFollowupModal;
