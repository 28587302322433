import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning, IoIosOptions } from "react-icons/io";
import {FiSettings} from "react-icons/fi";
import TeamAskBreachModal from './TeamAskBreachModal';
import TeamFollowupModal from './TeamFollowupModal';
import SideBar from '../Filter/SideBar';
import CSLLoader from '../Common/CSLLoader';
import TeamViewIncidentModal from './TeamViewIncidentModal';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from './../Common/XcelLoader';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    //width: 96%;
    margin: 0 auto;
    margin-top: 30px;  
    border-top: 1px solid #d7dddf;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;  
`;
const OverviewInfoBody = styled.div`
	//padding: 15px;
    background-color: #ffffff;
    //border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const BoxStyle = styled.div`
  float: left;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, .2);
  margin-right: 10px;
`;
const ConflictInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: black;
`;
const ConflictSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #414141;
`;
const CSLDateCover = styled.div`
    height: 20px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #282828;
    margin-bottom: 10px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const ExportButtonDisable = styled.button`
    box-sizing: border-box;
    background-color: #213e61a1;
    color: #F2F2F2;
    border: 1px solid #213e61a1;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px; 
`;
const HeaderTitle = styled.div`
    float: left;
    color: rgb(84, 84, 84);
    font-weight: 600;
    font-size: 16px;
    font-family: "DMSans-Bold", "DM Sans Bold", "DM Sans", sans-serif;
    font-weight: 700;
    margin-top: 5px;
`;
const SavedStatus = styled.div`
    width: 25px;
    height: 25px;    
    padding: 2px 2px 2px 2px;
    border-radius: 2px;
    border: 1px solid #143151;
    background-color: #143151;
    box-sizing: border-box;
`;
const OthersStatus = styled.div`
    width: 25px;
    height: 25px;    
    padding: 2px 2px 2px 2px;
    border-radius: 2px;
    border: 1px solid #04ADA8;
    background-color: #04ADA8;
    box-sizing: border-box;
`;
class BreachList extends React.Component
{
    state = {
        ready: false, 
        show_modal: null,
        breachTaskList: [],
        breachTaskListFilter: [],
        myfollowupTask: [],
        followUp: null,
        updateRegulatorlist: {},  
        updateReasonforbreachlist: {},
        updateBreachimpactlist: {},
        updateDepartmentlist: {},    
        sidebar: false,
        isLoadedIcon: false   
    };
    constructor(props) {
        super(props);
    }
    sidebarOpen = false
    sidebarClosed = false
    componentDidMount() {  
        
        let module_config = Store.getStoreData('module_config');
        //console.log(followupTask)       

        let myBreachTask = Store.getStoreData('myinitiatedtasks');  

        let task_id = this.props.taskId;
        console.log("task_id3===>",task_id)        
        
        let updateReasonforbreachlist = {}
        if('reason_for_Breach' in module_config && module_config.reason_for_Breach.length !== 0){
            module_config.reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    updateReasonforbreachlist[item.reasonforBreachID.toString()] = item.reasonforBreachName
                }
            })
        }  

        let updateBreachimpactlist = {};
        if('breach_impact' in module_config && module_config.breach_impact.length !== 0){
            module_config.breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    updateBreachimpactlist[item.breachImpactID.toString()] = item.breachImpactName
                }
            })
        }
        let updateDepartmentlist = {};
        if('business_areas' in module_config && module_config.business_areas.length !== 0){
            module_config.business_areas.forEach((item) => {
                if(item.isActive === 1){
                    updateDepartmentlist[item.businessAreaID.toString()] = item.businessAreaName
                }
            })
        }    

        this.setState({ breachTaskList: myBreachTask,breachTaskListFilter: myBreachTask, updateReasonforbreachlist: updateReasonforbreachlist,
            updateBreachimpactlist: updateBreachimpactlist, updateDepartmentlist: updateDepartmentlist})
        if(task_id > 0) {
            console.log("task id", task_id)
            this.goToTaskUrlBare(task_id)
        }else{
            let followupTask = Store.getStoreData('followup_tasks');
            let followup = {};
            for(let d of followupTask){
            //for(let i=0;i<followupTask.length;i++){
                followup[d[3]] = d[4];
            }
            this.setState({ready: true, followUp: followup, myfollowupTask: followupTask})
        }
    }

    componentDidUpdate(prevprops) {
        if(this.props === prevprops)return
            let followupTask = Store.getStoreData('followup_tasks');
            let myBreachTask = Store.getStoreData('myinitiatedtasks');
            let followup = {};
            for(let d of followupTask){
            //for(let i=0;i<followupTask.length;i++){
                followup[d[3]] = d[4];
            }       
            this.setState({ breachTaskList: myBreachTask,breachTaskListFilter: myBreachTask,followUp: followup,myfollowupTask: followupTask, ready: true })
    }

    goToTaskUrlBare = (taskID) => {  
        let postData = { "command" : "getTaskCallbyTaskID", "task_id" : taskID }
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.processBreachTaskAPIData);        
    }

    processBreachTaskAPIData = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            alert(msg)
            return false;
          }
        let cur_lane = result.result.parenttask.cur_lane;
        let parentID = result.result.parenttask.id;
        if(cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE' || cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE' || cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
            this.showFollowUpTaskApi(parentID);
        }else if(cur_lane === 'COMP_BREACH_USERSAVE'){
            this.processBreachTaskData(result);
        }else{
            this.processBreachViewTaskData(result);
        }   
    }

    showFollowUpTaskApi = (task_id) => {
        //event.preventDefault();
        console.log('ROWS', task_id); 
        let postData = { command: "GetSubTaskCallbyTaskID", task_id: task_id};
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.openFollowupModalTaskApi);
    }
    openFollowupModalTaskApi = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            alert(msg)
            return false;
          }
          Store.updateStore('followup_task', result.result.task);
          this.setState({showModal: 'view_followup_modal',ready: true});
    }
    
    processMyBreachRegisterValue = () => {
        var mytaskdata = this.state.breachTaskList;
        //console.log("mytaskdata",mytaskdata)
        const has_gc = Store.getStoreData('has_gc');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[    
        {'Header' : '', accessor: 'icon', Cell: row => (
            <div>
                {
                    (() => {
                        if(row.original.status === "Saved"){
                            return( <SavedStatus/>)
                        }else{
                            return( <OthersStatus/>)
                        }
                    })()
                }                
                </div>
            ), width: 40, sortable: false,filterable:false, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {padding: "5px 0px 0px 0px"}},     
        {'Header' : 'STATUS', accessor: 'status', Cell: row => (
            <div style={{padding: "1px 4px 4px 4px",fontWeight: "700", color: "#545454"}}>{row.original.status}</div>                        
            ), width: 90, headerStyle: {textAlign: 'left',fontWeight: "600"}},
        {'Header' : 'ID', accessor: 'breachid', width: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},
        {Header: 'TYPE', accessor: 'breach_type', minWidth: 110, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},
        {Header: 'INCIDENT', accessor: 'incident_title', minWidth: 110, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},  
        {Header: 'COMPANY', accessor: 'company_name', minWidth: 110, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}, show: has_gc ? true : false},
        {Header: 'INT/EXT', accessor: 'int_ext', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},          
        {Header: 'REPORTABLE', accessor: 'isreportable', minWidth: 80, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},          
         {'Header' : 'Tasks', accessor: 'no_of_followup', Cell: row => (
            <div style={{textDecoration: "underline",color: "#00B5B2"}} >{row.original.no_of_followup}</div>
          ), minWidth: 60, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center',fontWeight: "700", color: "#545454"}}, 
        {Header: 'INCIDENT DATE', accessor: 'incident_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center',fontWeight: "700", color: "#545454"}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }}, 
        {Header: 'CLOSED DATE', accessor: 'closed_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center',fontWeight: "700", color: "#545454"}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},    
        {'Header' : 'Action', Cell: row => (            
                <MRButton style={{cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>View</MRButton>                
          ), width: 120, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}}];

        let gc_assoc = Store.getStoreData('gc_assoc');        
        let gc_company = "";
        for(let d of mytaskdata) {           
            i++;  
            gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
            let status = "";
            if(d[2] === "COMP_BREACH_REGISTER") {
                status =  "Registered";                
            }else if(d[2] === "COMP_BREACH_USERSAVE") {
                status = "Saved";                
            }else if(d[2] === "COMP_BREACH_COMPLETE") {
                //status = "Complete";  
                status = "Closed";  
            }else if(d[2] === "COMP_BREACH_REGISTER_ACTION") {
                status = "In Progress";                 
            }

            let ref_id = "";
            if(d[7] !== null) {
                ref_id = d[7];
            }

            let breachType = "";
            if(d[8] === 'breach_of_rules'){
                breachType = "Incident";
                if(d[9] !== ''){
                    breachType = d[9];
                }
            }else if(d[8] === 'conduct_rules_breach'){            
                breachType = "Conduct Rules Breach";                
            }        
            
            let internal_external = "-";
            if(d[11] === 0){
                internal_external = 'External';
            }else if(d[11] === 1){
                internal_external = 'Internal';
                if(d[12] !== ''){
                    internal_external = d[12];
                }
            }

            let isReportable = 'Reportable';
            if(d[13] === 0){
                isReportable = 'Not Reportable';
            }

            let countFollowup = 0;
            if(d[0] in this.state.followUp){
                countFollowup = 1; 
            }
            let nooffollowup = countFollowup < 10 ? "0"+countFollowup.toString() : countFollowup;

            let elem = {
                    'id': d[0],
                    'status' : status,
                    'breachid' : ref_id,
                    'breach_type': breachType,
                    'incident_title': d[10] !== '' ? d[10] : '-',
                    'company_name' : gc_company,
                    'int_ext': internal_external,
                    'isreportable': isReportable,
                    'reportable': d[13],
                    'no_of_followup': nooffollowup,
                    'incident_date' : moment.unix(d[14]).format('DD/MM/YYYY'), 
                    'closed_date': d[2] === "COMP_BREACH_COMPLETE" ? moment.unix(d[5]).format('DD/MM/YYYY') : '-'                   
                   };
            ret.data.push(elem); 
            let sarr = [];                       
            ret.data.forEach((item) => {
                if (item.reportable === 1) {
                    sarr.push(item);
                }
            })
            ret.data.forEach((item) => {
                if (item.reportable === 0) {
                    sarr.push(item);
                }
            })
            ret.data = sarr;           
        }        
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }

    exportToExcelCallApi = (event) => {
        //event.preventDefault();
        let postData = { command: "fetchExcelExport", role: Store.getStoreData('role')};
        let api = new APICall();
        api.command(postData, this.processExcelExport);
    }

    processExcelExport = (result) => {
        console.log("result", result); 
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = result.result.all_tasks;  
        wb.SheetNames.push("Breaches Register Team"); 
        wb.Sheets["Breaches Register Team"] = XLSX.utils.json_to_sheet(XcelLoader.breach_export_tasks(open_tasks)); 
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);  
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Breaches_Register' + '-' + tm + fileExtension);     
    }

    exportToExcel = () => {
        let wb = {SheetNames:[], Sheets:{}};        
        let open_tasks = Store.getStoreData('table_cur_data') === null ? [] : Store.getStoreData('table_cur_data'); 
        wb.SheetNames.push("Breaches Register Team"); 
        wb.Sheets["Breaches Register Team"] = XLSX.utils.json_to_sheet(XcelLoader.breach_export_tasks(open_tasks)); 
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);  
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Breaches_Register' + '-' + tm + fileExtension);  
    }    

    goToTaskUrl = (row) => (event) => {
        event.preventDefault();  
        //console.log('ROWS', row);      
        this.showBreachRegisterModal(row);            
    }

    showBreachRegisterModal = (row) => {
        //event.preventDefault();
        if(this.props.taskId > 0) {
            Store.updateStore('q_index', 1);
            let postData = { "command" : "getTaskCallbyTaskID", "task_id" : row }
            postData['gcs'] = Store.getStoreData('gc_filters');
            let api = new APICall();
            api.command(postData, this.processBreachTaskDataNew);
        }else{
            console.log('Rows', row);
            Store.updateStore('q_index', row.index);
            let postData = { "command" : "get_task", "task_id" : row.original.id }
            this.setState({isLoadedIcon: true})
            let api = new APICall();
            if(row.original.status === 'Saved'){
                api.command(postData, this.processBreachTaskData);
            }else{
                api.command(postData, this.processBreachViewTaskData);
            }            
        }        
    }

    processBreachTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            // let title = "Alert";
            // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            // console.log("alert_param", alert_param)
            // this.setState({alert_param: alert_param, ready: true})
            alert(msg)
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "teamAskBreachModal", ready: true});               
    }

    processBreachTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "teamAskBreachModal", ready: true, isLoadedIcon: false});               
    }

    processBreachViewTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "viewIncidentModalTeam", ready: true, isLoadedIcon: false});               
    }

    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }

    processMyfollowupValue = () => {
        var followup_tasks = this.state.myfollowupTask;
        console.log("followup_tasks",followup_tasks)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
            {Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
            {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
            {Header: 'Status', accessor: 'status', width: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}, 
            {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, sortMethod: (a, b) => {
                if (a === b) {
                    return 0;
                }
                let adue = a.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                let  bbdue = b.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse > bReverse ? 1 : -1;
              }}, 
            {'Header' : 'Action', Cell: row => (
               <div>
                   <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.goToFollowTaskUrl(row.original.id)}>View</MRButton>
               </div>
            ), headerStyle:{textAlign: 'left',fontWeight: "600"}}];
        for(let d of followup_tasks) {
            i++;            
            let today = moment().unix();
            let text = (d[5]+86400) > today ? 'Pending' : 'Overdue'; 
            let color = (d[5]+86400) > today ? '#2E8B57' : '#FF0000';              
            let followupType = "";
            if(d[2] === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT'){
                followupType = "Follow up Tasks";
            }else if(d[2] === 'COMP_BREACH_REMEDIAL_ASSIGNMENT'){
                followupType = "Remedial Actions";
            }else if(d[2] === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT'){
                followupType = "Preventative Measures";
            }
            let task = followupType +" - "+ d[7];
            //let discloserName = Store.getStoreData('contacts')[d.contact_id]
            let elem = {'task' : task,
                        'assigned_to' : Store.getStoreData('contacts')[parseInt(d[6])],
                        'status' : {text: text, color: color},
                        'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                        'id' : d[0]
                   };
            ret.data.push(elem);
        }        
        return ret;
    }

    goToFollowTaskUrl = (task_id) => (event) => {
        event.preventDefault();  
        console.log('ROWS', task_id);      
        this.showFollowUp(task_id);            
    }

    showFollowUp = (task_id) => {
        //event.preventDefault();
        console.log('ROWS', task_id); 
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        console.log(result);        
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal',ready: true, isLoadedIcon: false});
    }

    toggleSideBar = () =>  {
        this.setState({sidebar: true})
    }

    closeSidebar = (val) => {
        console.log('Index closeSidebar')
        this.sidebarClosed = true
        this.setState({sidebar:false})
    }

    filterSubmit = (filterbreachList) => {   
        this.setState({breachTaskList: filterbreachList})
    }

    closeTaskModal = (event) => {
        this.props.closeUserDialog();  
    }

    render()
    {   
        let task_id = this.props.taskId;
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'teamAskBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><TeamAskBreachModal closeTaskModal={this.closeTaskModal} /></div>; break;     
                            case 'view_followup_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><TeamFollowupModal closeTaskModal={this.closeTaskModal} /></div>; break;            
                            case 'viewIncidentModalTeam' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><TeamViewIncidentModal closeTaskModal={this.closeTaskModal} /></div>; break;                                                
                        }
                    }
                })()
                }
            </div>)
        }
        return (
            <div> 
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <div>
                    {
                        (() => {  
                            if(this.state.sidebar) return   <SideBar closeSidebar = {this.closeSidebar} breachTaskList={this.state.breachTaskListFilter} filterSubmitValue={this.filterSubmit}/>
                        })()
                    }
                </div>
                <OverviewInfoHeader>
                    <HeaderTitle>Incident Register</HeaderTitle>           
                    <div style={{float: "right", color: "#323232", fontSize: "20px"}}><FiSettings /></div> 
                    <div style={{float: "right", color: "#323232", fontSize: "20px",marginRight: "17px", cursor: "pointer"}} onClick={() => this.toggleSideBar()}><IoIosOptions /></div>  
                    <div style={{float: "right", borderLeft: "2px solid #EBEEF3",height: "24px",marginRight: "17px"}}></div>                     
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processMyBreachRegisterValue} headerText={''} tableRows="10" refreshCallback={this.refreshCallback} />  
                    <div style={{float: "left"}}>
                    {
                        (() => {
                            if(this.state.breachTaskList.length !== 0){
                                return(
                                <ExportButton                                 
                                onClick={() => this.exportToExcelCallApi()}>                                    
                                    Export
                                </ExportButton>)
                            }else{
                                return(<ExportButtonDisable>Export</ExportButtonDisable>)
                            }
                        })()
                    }  
                    </div> 
                    <div style={{float: "left", color: "#04ADA8", fontWeight: "600", fontStyle: "italic",marginTop: "19px",marginLeft: "15px"}}>{this.state.breachTaskList.length} Incidents Listed</div>                  
                    <div style={{clear: "both"}}></div>                    
                </OverviewInfoBody> 

                {
                    (() => {
                        if(Store.getStoreData('followup_tasks').length !== 0){
                            return(<div>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>My Tasks</div> 
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody>
                                    <CSLTable add={false} processData={this.processMyfollowupValue} headerText={''} tableRows="5" refreshCallback={this.refreshCallback} />                                         
                                </OverviewInfoBody>
                            </div>)
                        }
                    })()
                }    
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'teamAskBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><TeamAskBreachModal closeModal={this.closeModal} /></div>; break;
                            case 'view_followup_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><TeamFollowupModal closeModal={this.closeModal} /></div>; break;
                            case 'viewIncidentModalTeam' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><TeamViewIncidentModal closeModal={this.closeModal} /></div>; break;
                        }
                    }
                })()
                }
            </div>
        );
    }
}
export default BreachList;