let complete_filters = {
	task: {name: 'task', 'nickname': 'Task', type: 'text', options: {},selections: {}, size: 2},
	cur_assigned_to: {name: 'cur_assigned_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
	contact_id: {name: 'contact_id', 'nickname': 'Created By', type: 'multi', options: {},selections: {}, size: 2},
	module_name: {name: 'module_name', 'nickname': 'Source', type: 'single', options: {},selections: {}, size: 1},
	due_date: {name: 'due_date', 'nickname': 'Date', type: 'date_range', options: {},selections: {}, size: 2},
	due_month: {name: 'due_month', 'nickname': 'Month', type: 'multi', options: {},selections: {}, size: 2},
	company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2}
}

module.exports = complete_filters