import React from 'react';
// import APICallextra from './Common/APICallextra';
import AlertBox from './Components/Common/AlertBox';
import Store from './Common/Store.js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, alert_param: null };

    if (this.props.showError === false) {
      this.state.error = null;
      this.state.errorInfo = null;
    }
  }

  componentDidCatch = (error, info) => {
    console.log("error did catch", error.toString(), "error");
    console.log("error did catch", info.componentStack.toString());

    let alert_param = {title: 'Error', message: error.toString(), ok_text: 'Ok', confirm: false, alertHandler: this.alertHandler, stack: {id: 0}};

    // let api = new APICallextra();
    // let postData = { command: "save_errorlog" };
    // postData['module'] = 'car';
    // postData['log_type'] = 'error';
    // postData['log_json'] = error.toString();
    // api.command(postData, this.processserverlog);
    // this.setState({ error: error, errorInfo: info, alert_param: alert_param });
    return false;
  };

  processserverlog = (result) => {
      console.log("result", result);
  }

  alertHandler = (result, stack) => {
    this.setState({alert_param: null})
    let url = "/";
    window.location.assign(url);
  }

  render() {
    if(this.state.alert_param !== null) {
      return <AlertBox alertParam = {this.state.alert_param} />
    }else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
