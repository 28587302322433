import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import CSLTable from '../../Common/CSLTable';
import FollowupModal from './FollowupModal'
import { FaTimes} from 'react-icons/fa';
import CSLLoader from '../Common/CSLLoader';
import CMFollowupTask from './CMFollowupTask';
import { MdModeEdit,MdDelete } from "react-icons/md";
import AlertBox from '../Common/AlertBox';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 21%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #2C2C2C;
    font-size: 17px;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #9C9C9C;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #282828;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
// const OverviewInfoBody = styled.div`
//     padding: 15px;
//     background-color: #EDF0F1;
//     border: 1px solid #d7dddf;
//     border-bottom-left-radius: 5px;
//     border-bottom-right-radius: 5px;
//     border-top-right-radius: 5px;
//     border-top-left-radius: 5px;
//     width: 96%;
//     margin: 0 auto;
//     margin-bottom: 30px; 
//     display: inline-block;
//     margin-top: 28px;   
// `;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -52px;
  z-index: 1000;
  opacity: 0.8;
`;

class CurrentFollowupListsModal extends React.Component
{
    
    state = {
        isLoadedIcon: false, 
        showFollowupModal: false, 
        review_list: [], 
        alert_param: null, 
        taskid: 0, 
        followupTasksList: [], 
        ready: false,
        ischangesFollowup: false,
    };

	componentDidMount()
	{
        let all_childTask = Store.getStoreData('current_followup_lists');
        console.log("all_childTask===>", all_childTask)
        let taskid = all_childTask[0].parent_task_id;  
        this.setState({taskid, followupTasksList: all_childTask, ready: true})         
	}
	
    closeModal = (event) => {
        event.preventDefault();
        if(this.state.ischangesFollowup){
            this.props.closeModalandRefresh();
        }else{
            this.props.closeModal();
        } 
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({showModal: null})
    }

    processMyfollowupTaskData = () => {
        var taskdata = this.state.followupTasksList;
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Notes', accessor: 'notes', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 200, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 150, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action', Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showFollowUp(row.original.id)}>View</MRButton>
                                {
                                    (() => {                                        
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                                <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><MdModeEdit /></div>
                                                {
                                                    (() => {
                                                        if(row.original.role !== 'cm'){
                                                            return (<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteChildTask(row.original.id)}><MdDelete /></div>)
                                                        }
                                                    })()
                                                }                                                
                                            </div>)         
                                        }                               
                                    })()
                                }
                            </div>
                         ), width: 180, headerStyle:{textAlign: 'left',fontWeight: "600"}}
                    ];
        for(let d of taskdata) {           
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_COI_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_COI_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let notes = taskdataobject.note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            
            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            // console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            // console.log('due_date_status', due_date_status_final, 'today',today)

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';   
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }     
            // console.log("role==>", Store.getStoreData('role'))              
            let elem = {
                'id' : d.id,
                'index' : i, 
                'assign_to' : assigntoName,
                'notes' : notes,
                'due_date' : due_date,                
                'status' : {text: text, color: color},
                'status_value': text,
                'role': Store.getStoreData('role')
               };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteChildTask = (taskid) => (event) => {
        event.preventDefault();  
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteConfirmChildTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }

    deleteConfirmChildTask = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }               
        let postData = {command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData",postData)  
        this.setState({isLoadedIcon: true})          
        let api = new APICall();
        api.command(postData, this.processRemoveFollowupData);               
    }

    processRemoveFollowupData = (result) => {        
        this.setState({alert_param: null, ischangesFollowup: true})        
        let alert_param = {title: 'Success', message: 'Follow-up task deleted successfully', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})        
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editFollowupModal);
    }

    editFollowupModal = (result) => {
        console.log("followup_task",result.result.task);        
        let gcid = parseInt(result.result.task.parenttask.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(result.result.task.parenttask.company_id)%100000);
        console.log("gcid",gcid);  
        let gc_users = [];
        let my_company = null;
        let gc_company = Store.getStoreData('gc_company');
        let company_contacts = Store.getStoreData('contacts');      
        for(let gc of gc_company) {
            if(gc.id === gcid)my_company = gc;
        }        
        let com_roles = Store.getStoreData('userroles');
        for(let id of Object.keys(my_company.users)) {
            if (com_roles[id.toString()] !== 'no_access' ) {
                gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
            }
        }
        console.log('gc_users', gc_users)
        Store.updateStore('ftask_index', 0);        
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showFollowupModal: true, review_list: gc_users}) 
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
    }

    closeFollowupModal = () => {                   
        this.setState({ showFollowupModal: false });
    }
    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
    }

    EditrefreshTaskValue = (taskValue) => {
        //console.log(taskValue)        
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);  
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;        
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_COI_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_COI_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData); 
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);   
    }

    processEditFollowupTask = (result) => {
        let alert_param = {title: 'Success', message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})        
        //alert("The follow-up task has been updated successfully.");  
    }

    updateprocessallchildtask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(command_data, this.updateallChildTask); 
    }

    updateallChildTask = (result) => {
        Store.updateStore('current_followup_lists', result.result.followuptasks);
        console.log('result.result.current_child_lists', result);  
        this.setState({ showFollowupModal: false,            
            followupTasksList: result.result.followuptasks,
            isLoadedIcon: false 
        });  
    }

    render()
    {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }  
        return(
            <div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <RegModalContainer>
                <div>
                    <RegModalHeaderText>{this.props.curConflictsid} - Follow-up Tasks</RegModalHeaderText>
                    <RegModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></RegModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </div>
                <RegDismisOuterContainer>                      
                    <OverviewInfoBody style={{marginBottom: "0px", borderRadius: "5px", backgroundColor: "#EDF0F1", marginTop: "28px", border: "1px solid #d7dddf"}}>
                        <div><span style={{color: "#383939"}}>These are the current active Follow-up tasks associated with this conflict.</span></div>                    
                    </OverviewInfoBody>
                </RegDismisOuterContainer> 
                <RegDismisOuterContainer>
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow-up Tasks</div>                                                                               
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader> 
                    <OverviewInfoBody style={{marginBottom: "0px", border: "none",padding: "0px",width: "98%"}}>
                    {
                        (() => {
                            if(this.state.showFollowupModal){
                                return(<CMFollowupTask headerText={'Edit'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch} EditrefreshTaskValue={this.EditrefreshTaskValue}/>)                                
                            }
                        })()
                    }   
                    </OverviewInfoBody>   
                    <OverviewInfoBody>               
                    {
                        (() => {
                            if(Store.getStoreData('current_followup_lists').length !== 0) {
                                return(<CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />)
                            }else {
                                return(<div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Follow-up Task Available</div>);
                            }
                        })()
                    }
                    </OverviewInfoBody>  
                </RegDismisOuterContainer>                                       
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
                </RegCancelSubmitButtonOuterContainer>  
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {                            
                            case 'view_followup_modal' : return <FollowupModal closeModal={this.closefollowupModal} />; break;
                        }
                    }
                })()
                }  
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        )
    }

}
export default CurrentFollowupListsModal;
