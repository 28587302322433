import React from 'react';
import PropTypes from "prop-types";
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import '../../Components/Common/CslDatepicker.css';

class CSLDatePicker extends React.Component {

	static propTypes = {
		/** Initial Date */
		updateFromDate: PropTypes.instanceOf(Date),
		/** Return Date instance*/
		ondateValue: PropTypes.func,
		/** Reset value */
		isclear: PropTypes.bool,
		/** callback: Date has changed */
		changeClear: PropTypes.func,
	};

	state = { date: new Date() };

	componentDidMount() {
		let curDate = new Date();
		if ('updateFromDate' in this.props) {
			curDate = this.props.updateFromDate;
		}
		this.setState({ date: curDate })
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			if (this.props.isclear) {
				let curDate = new Date();
				if ('updateFromDate' in this.props)
					curDate = this.props.updateFromDate;
				this.props.changeClear()
				this.setState({ date: curDate })
			}
		}
	}

	onChange = (date) => {
		this.setState({ date });
		this.props.ondateValue(date);
	}

	// onChange = date => this.setState({ date });
	render() {
		return (
			<DatePicker
				onChange={this.onChange}
				value={this.state.date}
				clearIcon={null}
				//calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
				calendarIcon={null}
				locale={"en-GB"}
				className="csldatecontainer"
			/>
		);
	}
}

export default CSLDatePicker;
