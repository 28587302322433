import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import CSLTable from '../Common/CSLTable';
import DataCardSection from './DataCardSection';
import PendingTaskTable from './PendingTaskTable';
import CompleteTaskTable from './CompleteTaskTable';
import FollowupTaskTable from './../AM/FollowupTaskTable';
import APICall from '../../Common/APICall';

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;

class TeamIndex extends React.Component
{
    state = {
        ready: false,
        showModal: null
        
    };
    constructor(props) {
        super(props);
    }

    setComponentState = () => {
        if(this.props.taskId > 0) {

            let postData = { "command" : "GetSubTaskCallbyTaskID", "task_id" : this.props.taskId }
            postData['gcs'] = Store.getStoreData('gc_filters');
            let api = new APICall();
            api.command(postData, this.openTaskModal);                        
        } 
    }

    openTaskModal = (result) => {
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            alert(msg)
            return false;
        }
        console.log("result===>", result);
        let curlane = result.result.task.subtask.cur_lane;
        console.log("curlane===>",curlane);
        if(curlane === "COMP_ATTESTATION_ASSIGNMENT_SHARE"){            
            let task_json = JSON.parse(result.result.task.subtask.task_json); 
            let shareType = task_json.object_data.headerinfo.Type === "information" ? "Information" : "Acknowledge"; 
            Store.updateStore('cur_pending_task', result.result.task);
            // Store.updateStore('cur_parent_pending_task', result.parentdatanew);
            Store.updateStore('cur_parent_binFiles', result.binFiles);  
            Store.updateStore('cur_share_type',shareType) 
            this.setState({showModal: 'view_pending_modal',ready: true});
        }else if(curlane === "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT"){
            Store.updateStore('followup_task', result.result.task);
            this.setState({showModal: 'view_followup_modal', ready: true});
        }else{
            Store.updateStore('cur_complete_task', result.result.task);
            // Store.updateStore('cur_parent_pending_task', result.parentdatanew);
            Store.updateStore('cur_parent_binFiles', result.binFiles);
            this.setState({showModal: 'view_complete_modal',ready: true});
        }
    }

    componentDidMount()
    {
        this.setComponentState()
    }

    componentDidUpdate(prevprops)
    {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    closeTaskModal = (event) => {
        this.props.closeUserDialog();  
    }


    render()
    {  
        let task_id = this.props.taskId !== 0 ? this.props.taskId : 0;
        console.log("task_id2===>",task_id)
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'view_pending_modal' : return <PendingTaskTable taskId={task_id} closeTaskModal={this.closeTaskModal}/>; break;
                            case 'view_complete_modal' : return <CompleteTaskTable taskId={task_id} closeTaskModal={this.closeTaskModal}/>; break;  
                            case 'view_followup_modal' : return <FollowupTaskTable taskId={task_id} closeTaskModal={this.closeTaskModal}/>; break;                                                     
                        }
                    }
                })()
                }
            </div>)
        }
        return (
            <IndexContainer>
                <div style={{marginTop: "50px",display: "block",height: "auto", backgroundColor: "#F5F9FF",paddingLeft: "10px",paddingRight: "10px"}}>
                <DataCardSection />

                <PendingTaskTable taskId={task_id}/>
                <CompleteTaskTable taskId={task_id}/>
                <FollowupTaskTable widthValue={"92%"}/>            
                <div style={{clear: "both"}}></div>
                </div>       
            </IndexContainer>
        );
    }
}
export default TeamIndex;