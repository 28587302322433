import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents'
import ClarificationMessage from '../CA/ClarificationMessage';
import DatePicker from 'react-date-picker';
import HtmlEditor from '../Common/HtmlEditor';
import ShowFAQModal from '../FAQ/ShowFAQModal';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';

const InactiveOverlay = styled.div`
      display: block;
      box-sizing: border-box;
      height: 3000px;
      width: 100%;
      background-color: #ffffff;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1000;
      opacity: 0.6;
    `;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #333;
    font-weight: 600;
`;


const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
    cursor:pointer;
`;

const ModalContainertop = styled.div`
    display: grid;
    // grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 10px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;

const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor:pointer;
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #234062;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: auto;
    padding: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const SelectedPillCA = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;


class ShowClosecarModal extends React.Component
{
    state = {
        company: '',
        query: '',        
        reason:'',
        answer: '',
        binFiles: [],
        cur_files_json: null,
        extbinFiles: [],
        ext_cur_files_json: null,
        accept_mode: null,
        index: -1,
        form_data: null,
        id:0,
        category_id: 0,
        ext_advice: false,
        ext_advice_provider:'',
        ext_advice_txt: '',
        ext_ref_number: '',
        ext_contact_name: '',
        currentLane: '',
        accept_note: '',
        clarifications: [],
        answered_by: 0,
        ref_id: '',
        tags_count: 0,
        tags:[],
        ready: false,
        show_faq:false,
        faq_question: '',
        faq_answer: '',
        faq_expire_date: new Date(),
        jumping:false,
        custom_fields: {},
        enabled_topics: false,
        topic: {},
        from_faq: false,
        view_faq: false,
        faq_id: 0,
        alert_param: null,
        is_loading: false
    };

    constructor(props) {
        super(props);
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    SendforApprovalFAQ(flag){
        // let bin_files = this.state.binFiles;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let dateobj = new Date(this.state.faq_expire_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        let postData = { 
                        command: "add_faq", 
                        question: this.state.faq_question.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                        answer: answer_summary, 
                        bin_files: bin_files,
                        task_id: this.state.id, 
                        assign_to: this.state.form_data.contact_id, 
                        tags: selectedtags,
                        car_ref_id: this.state.ref_id,
                        company_id: this.state.form_data.company_id,
                        is_approved: flag,
                        expiry_date: expiry_date
                    };
        if(this.state.enabled_topics) {
            postData['topic'] = this.state.topic;
        }
        if(this.state.ext_advice){
            postData['ext_advice'] = this.state.ext_advice;
        }
         Utils.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processFAQadd);
        this.setState({is_loading: true})
    }

    processFAQadd =(result) => {
        let msg = '';
        let title = 'Task save';
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        this.setState({is_loading:false})
        if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin'){
            if(result.is_approved === 0) {
                msg = 'Your FAQ has been saved.';
                // alert('Your FAQ has been saved.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }else{
                msg = 'The FAQ has been published.';
                title = 'Alert';
                // alert("The FAQ has been published.");
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }
        }else{
            if(result.is_approved === 0) {
                msg = 'Your FAQ has been saved.';
                // alert('Your FAQ has been saved.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }else {
                msg = 'This FAQ has been sent to a manager for approval.';
                title = 'Alert';
                // alert("This FAQ has been sent to a manager for approval.");
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }
            
        }
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        let x = SelectedPillCA;
        if(this.state.show_faq) {
            x = SelectedPill;
        }
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: x,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }

    processFAQData = (result) => {
        let contact_id = Store.getStoreData('contact_id');
        let form_data = {};
        form_data = result;
        let currentLane = form_data.cur_lane;
        let task_json = JSON.parse(form_data.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }

        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
        }

        let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0;

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;

        let ref_id = '';
        if('ref_id' in task_json.object_data) {
            ref_id = task_json.object_data.ref_id;
        }
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }

        let tags = this.isLoadTags(ptags)

        let tags_count =  Object.keys(ptags).length;

        let custom_fields = this.state.custom_fields;
        custom_fields.enabled = false;
        custom_fields = ('custom_fields' in  task_json.object_data) ? task_json.object_data.custom_fields : custom_fields;

        let moduleconfig = Store.getStoreData('moduleconfig');
        let days_after_faq_expire = 0;
        if(moduleconfig.enabled_faq_expiry){
            days_after_faq_expire = parseInt(moduleconfig.days_after_faq_expire);
        }
        let faq_expire_date = new Date(Utils.adddaystoDate(days_after_faq_expire));
        
        let answer = task_json.object_data.answer;
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        let topic_obj = this.state.topic_obj;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }

        let from_faq = ('from_faq' in task_json.object_data) ? task_json.object_data.from_faq : false;
        let faq_id = ('faq_id' in task_json.object_data) ? task_json.object_data.faq_id : 0;
        

        this.setState({ company: gc_company,cur_files_json: cur_files_json, ext_cur_files_json: ext_cur_files_json,
                        form_data:form_data, query: task_json.object_data.query,
                        accept_mode: task_json.object_data.accept_mode,
                        reason: task_json.object_data.reason, 
                        answer: answer,
                        answered_by: answered_by,
                        clarifications: ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [],
                        ext_advice: ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : false,
                        ext_advice_txt: ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '',
                        ext_advice_provider: ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : '',
                        ext_ref_number: ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '',
                        ext_contact_name: ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '',
                        id: form_data.id, ready: true, currentLane: currentLane, 
                        ref_id:ref_id, tags_count:tags_count, tags: tags, faq_question:task_json.object_data.query, 
                        faq_answer:answer, custom_fields: custom_fields, faq_expire_date: faq_expire_date, enabled_topics: enabled_topics,topic: topic,
                        from_faq: from_faq, faq_id:faq_id});
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    componentDidMount()
    {
        let cur_faq_modal_data = Store.getStoreData('cur_question_answer_modal_data');
        this.processFAQData(cur_faq_modal_data);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            let cur_faq_modal_data = Store.getStoreData('cur_question_answer_modal_data');
            this.processFAQData(cur_faq_modal_data);
        }
        // this.el.scrollIntoView({behavior: "smooth", block: "end"});
        if(this.state.show_faq && this.state.jumping) {
            this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    }



    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        this.setState({binFiles: files});
    }



    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value, jumping:false  })
    }

    showHideFaq = (event) => {
        event.preventDefault();
        let show_faq = this.state;
        show_faq = this.state.show_faq ? false: true;
        let jumping = show_faq 
        this.setState({show_faq: show_faq, jumping: jumping});

    }

    viewFaq = (event) => {
        let {view_faq} = this.state;
        view_faq = this.state.view_faq ? false: true;
        this.setState({view_faq: view_faq});
    }

    rfereshFAQ = () => {
        let {view_faq} = this.state;
        view_faq = this.state.view_faq ? false: true;
        this.setState({view_faq: view_faq});
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let { tags} = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags, jumping:false });
    }

    onExpireDateChange = (date) => {
        this.setState({ faq_expire_date: date })
    }

    showCustomFields = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv style={{width: '93%'}}>
                    <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true}/>
                </Style.ModalNameInputDiv>
            </div>
        );
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let contacts = Store.getStoreData('contacts');
        let form_data = Store.getStoreData('cur_question_answer_modal_data');
        let contact_id = form_data.contact_id;
        //let contact = contacts[contact_id];
        
        let task_json = JSON.parse(form_data.task_json);
        let object_data = JSON.parse(form_data.task_json).object_data;
        let requester_name = "";
        if('requester_name' in object_data) {
          requester_name = object_data.requester_name;
        }
        let contact = form_data.from_api === 0 ? contacts[contact_id] : requester_name;
        console.log('object_data', this.state.currentLane);
        let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
        let clLength = clarifications.length
        let last_answer = clarifications.length === 0 ? object_data.answer : clarifications[clLength - 1].note
        let last_answerer = '';
        if(this.state.currentLane != 'COMP_CAR_TEAMSAVE' && this.state.currentLane != '')
        {
            last_answerer = clarifications.length === 0 ? contacts[object_data.answered_by.toString()] : contacts[clarifications[clLength - 1].performed_by.toString()]
        }        
        let last_answer_utime = clarifications.length === 0 ? object_data.answer_date : clarifications[clLength - 1].actiondate
        let last_answer_time_obj = new Date(last_answer_utime)
        // let last_answer_time = last_answer_time_obj.toLocaleString('en-GB').replace(/,/g, '');

        let last_answer_time = last_answer_time_obj.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')
        
        let orig_answer = {actiondate: object_data.answer_date, note: object_data.answer, performed_by: object_data.answered_by }
        clarifications.reverse();
        let request_date = new Date(task_json.action_data[0].actiondate).toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '');
        let status = form_data.cur_lane === 'COMP_CAR_USER_COMPLETED' ? 'Closed' : 'Rejected';
        return (
            <div>
                <ModalContainer>
                    {
                        (() => {
                            if(this.state.is_loading) {
                                return <CSLLoader style={{position: 'absolute'}} />
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.view_faq && this.state.faq_id > 0) {
                                return <div><InactiveOverlay /><ShowFAQModal faq_id={this.state.faq_id} is_car={true} rfereshFAQ={this.rfereshFAQ}/></div>
                            }
                        })()
                    }
                    <ModalHeaderText>
                    {
                        (() => {
                            if(this.state.show_faq) {
                                return 'Add New FAQ'
                            }else {
                                return <div>{this.state.ref_id} - {this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</div>
                            }
                        })()
                    }
                    </ModalHeaderText>
                    {
                        (() => {
                            if(this.state.show_faq) {
                                return <ModalHeaderCloseBtn onClick={this.showHideFaq}><FaTimes /></ModalHeaderCloseBtn>
                            } else {
                                return <ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></ModalHeaderCloseBtn>
                            }
                        })()
                    }
                    <div style={{clear: "both"}}></div>
                    <ModalBody>
                        <ModalBodyInner>
                            <ModalContainertop>
                                <table style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th style={{textAlign: "left",color: "#243e57"}}><strong>ID</strong></th>
                                        {
                                            (() => {
                                                if(this.state.enabled_topics) {
                                                    return <th style={{textAlign: "left",color: "#243e57"}}><strong>Topic</strong></th>
                                                }
                                            })()
                                        }
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Requester</strong></th>
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Requested Date</strong></th>
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Status</strong></th>
                                        <th style={{textAlign: "left", color: "#243e57"}}><strong>Tags</strong></th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign: "left",color: "#243e57"}}>
                                            {
                                                (() => {
                                                    if(this.state.show_faq) {
                                                        return 'N/A';
                                                    }else{
                                                        return this.state.ref_id
                                                    }
                                                })()
                                            }
                                            </td>
                                            {
                                                (() => {
                                                    if(this.state.enabled_topics) {
                                                        return <td style={{textAlign: "left", color: "#243e57"}}>{this.state.topic.value}</td>
                                                    }
                                                })()
                                            }
                                            <td style={{textAlign: "left", color: "#243e57"}}>{contact}</td>
                                            <td style={{textAlign: "left", color: "#243e57"}}>{request_date}</td>
                                            <td style={{textAlign: "left", color: "#243e57"}}>{status}</td>
                                            <td style={{textAlign: "left", color: "#243e57"}}>{this.state.tags_count}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {
                                    (() => {
                                        if(Store.getStoreData('has_gc')) {
                                            return (<div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>{this.state.company}</Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                        }
                                    })()
                                }
                                <ModalNameLabelDiv>Question</ModalNameLabelDiv>
                                <ModalNameInputDiv style={{whiteSpace:  "break-spaces"}}>{this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</ModalNameInputDiv>
                                <ModalNameLabelDiv>Answer</ModalNameLabelDiv>
                                <div dangerouslySetInnerHTML={{__html: this.state.answer}} style={{textAlign: "justify", padding: "10px"}}></div>
                                <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                    {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />*/}
                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />
                                </ModalNameInputDiv>
                                <ModalNameLabelDiv>Tags</ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                    {
                                        this.state.tags.map((tag, index) => {
                                            if(tag.selected){
                                                      return (<tag.styledcomponent key={index} >{tag.name}</tag.styledcomponent>);
                                                  }
                                        })
                                    }
                                </ModalNameInputDiv>
                            
                            {
                                (() => {
                                    if(this.state.show_faq) {
                                        return(   
                                                <div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Question</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <Style.ModalTextArea name="faq_question" defaultValue={this.state.faq_question.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />
                                                        </Style.ModalNameInputDiv>

                                                        <Style.ModalNameLabelDiv>Answer</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.faq_answer} />
                                                        </Style.ModalNameInputDiv>
                                                        <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true} />*/}
                                                            <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true} />
                                                        </Style.ModalNameInputDiv>
                                                        <Style.ModalNameLabelDiv>Tags</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            {
                                                                this.state.tags.map((tag, index) => {
                                                                    return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                                                })
                                                            }
                                                        </Style.ModalNameInputDiv>
                                                        {
                                                            (() => {
                                                                if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                                                    return(
                                                                        <div style={{display:"contents"}}>
                                                                            <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                                                                <CSLDateCover>
                                                                                    <DatePicker
                                                                                            onChange={this.onExpireDateChange}
                                                                                            clearIcon={null}
                                                                                            calendarIcon={null}
                                                                                            locale={"en-GB"}
                                                                                            value={this.state.faq_expire_date}
                                                                                            />
                                                                                </CSLDateCover>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                </div>
                                             )
                                    }else{
                                        return(                                    
                                            <div>
                                                {
                                                    (() => {
                                                        if(this.state.clarifications != 0)
                                                        {
                                                            return (<div>
                                                                {
                                                                    (() => {
                                                                        if(this.state.accept_mode == 'acknowledge') {
                                                                            return (
                                                                                <Style.ModalNameInputDiv>
                                                                                    <Style.ModalWiderDiv >
                                                                                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                                                        <Style.ModalClearDiv />
                                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px', backgroundColor: 'green',width: '93%'}}>
                                                                                            {last_answerer}&nbsp;&nbsp;&nbsp;&nbsp;{last_answer_time}
                                                                                        </Style.ModalWiderHeaderDiv>
                                                                                        <Style.ModalTextArea style={{width: '93.3%'}} name="reason" defaultValue={this.state.reason.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true} />
                                                                                    </Style.ModalWiderDiv>
                                                                                </Style.ModalNameInputDiv>
                                                                            );
                                                                        }else {
                                                                            return (
                                                                                <Style.ModalNameInputDiv>
                                                                                    <Style.ModalWiderDiv >
                                                                                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                                                        <Style.ModalClearDiv />
                                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',backgroundColor: 'red',width: '93%' }}>
                                                                                            {last_answerer}&nbsp;&nbsp;&nbsp;&nbsp;{last_answer_time}
                                                                                        </Style.ModalWiderHeaderDiv>
                                                                                        <Style.ModalTextArea style={{width: '93.3%'}} name="reason" defaultValue={this.state.reason.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={false} />
                                                                                    </Style.ModalWiderDiv>
                                                                                </Style.ModalNameInputDiv>
                                                                            );
                                                                        }
                                                                    })()
                                                                }
                                                                
                                                            {
                                                                clarifications.map((c,i) => {
                                                                    if(i > 0)return (<ClarificationMessage cardata={form_data} clarification={c} />)
                                                                })
                                                            }
                                                            </div>)
                                                            
                                                        }
                                                    })()
                                                }

                                                <Style.ModalNameInputDiv>
                                                <Style.ModalWiderDiv>

                                                    {
                                                        (() => {
                                                            if(this.state.custom_fields.enabled) {
                                                                if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                                    const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                                        return item.name === "reference_number"
                                                                    })

                                                                    if(('value' in field) && field.value !=='') {
                                                                        return this.showCustomFields(field, "custom_terminology_fields");
                                                                    }
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(this.state.custom_fields.enabled) {
                                                                if (this.state.custom_fields.additional_fields.length !== 0) {
                                                                    let custom_html = [];
                                                                    this.state.custom_fields.additional_fields.forEach((item) => {
                                                                        if(('value' in item) && item.value !=='') {
                                                                            const field = this.showCustomFields(item, "additional_fields");
                                                                            custom_html.push(field);
                                                                        }
                                                                    })
                                                                    return custom_html;
                                                                }
                                                            }
                                                        })()
                                                    }
                                                
                                                </Style.ModalWiderDiv>
                                                </Style.ModalNameInputDiv>

                                                {
                                                    (() =>{
                                                        if (this.state.ext_advice){
                                                            return(
                                                                <Style.ModalNameInputDiv>
                                                                    <Style.ModalWiderDiv>
                                                                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                                        {
                                                                            (() => {
                                                                                if(this.state.ext_advice_provider != ""){
                                                                                return(<div>
                                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                            External Advice Providers
                                                                                        </Style.ModalWiderHeaderDiv>
                                                                                            <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_advice_provider.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                                    </div>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                            External advice required
                                                                        </Style.ModalWiderHeaderDiv>
                                                                            <Style.ModalTextArea style={{width: '93.3%'}} value={this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>

                                                                        {
                                                                            (() => {
                                                                                if(this.state.ext_ref_number != ""){
                                                                                    return(<div>
                                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                            External Reference Number
                                                                                        </Style.ModalWiderHeaderDiv>
                                                                                            <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                                    </div>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        {
                                                                            (() => {
                                                                                if(this.state.ext_contact_name != ""){
                                                                                    return(<div>
                                                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                            External Contact Name
                                                                                        </Style.ModalWiderHeaderDiv>
                                                                                            <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                                    </div>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        {
                                                                         (() => {
                                                                             if(this.state.ext_cur_files_json.length > 0) {
                                                                                 return(<div>
                                                                                             <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                                           Attachments
                                                                                             </Style.ModalWiderHeaderDiv>
                                                                                             <div style={{width: '93.3%'}}>
                                                                                                   <Dropzone initFiles={this.state.ext_cur_files_json} addnew={false} />
                                                                                             </div>
                                                                                         </div>
                                                                                     )
                                                                             }
                                                                         })()
                                                                        }
                                                                    </Style.ModalWiderDiv>
                                                                </Style.ModalNameInputDiv>

                                                            )
                                                        }
                                                    })()
                                                }
                                        </div>)
                                    }
                                })()
                            }
                            </ModalContainertop>
                        </ModalBodyInner>
                    </ModalBody>
                    <CancelSubmitButtonOuterContainer>
                        {
                            (() => {
                                if(!this.state.show_faq) {
                                    return(<CancelButton onClick={this.closeModal}>Close</CancelButton>);
                                }
                            })()
                        }
                            
                        {
                            (() => {
                                if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin' || Store.getStoreData('role') === 'ca') {
                                    
                                    if(this.state.show_faq) {
                                        return(<div style={{display:"inline"}}>
                                                <CancelButton onClick={this.showHideFaq}>Close</CancelButton>
                                                <SaveButton onClick={() => this.SendforApprovalFAQ(0)}>Create FAQ and Save</SaveButton>
                                                {
                                                    (() => {
                                                        if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                                            return(<SaveButton onClick={() => this.SendforApprovalFAQ(1)}>Publish FAQ</SaveButton>)
                                                        }else if(Store.getStoreData('role') === 'ca') {
                                                         return(<SaveButton onClick={() => this.SendforApprovalFAQ(1)}>Create and Send for Approval</SaveButton>)
                                                    }
                                                    })()
                                                }
                                            </div>);
                                    }else{
                                        return(<div style={{display:"inline"}}>
                                            {
                                                (() => {
                                                    if(this.state.from_faq) {
                                                        return <SubmitButton onClick={this.viewFaq}>View FAQ</SubmitButton>
                                                    }else{
                                                        return <SubmitButton onClick={this.showHideFaq}>Add to FAQ</SubmitButton>
                                                    }
                                                })()
                                            }
                                        </div>)
                                    }
                                    
                                } 
                            })()
                        }
                        <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>
                    </CancelSubmitButtonOuterContainer>
                </ModalContainer>
                <AlertBox alertParam = {this.state.alert_param} />
            </div>    
        );
    }
}
export default ShowClosecarModal;