import React from 'react';
import { FaTimes, FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
// import {Dropzone} from '@mcc-regtech/core-library';
import Dropzone from '../Common/Dropzone';
import { Store } from '../Common';
import '../../App.css';
import '../../Components/Common/CSLDatePickerForModal.css';

const MRModalLabel = styled.label`
  color: #212121;
  font-weight: 600;
  margin-top: 10px;
`;
const MRModalButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 11px;
  float: right;
  cursor: pointer;
`;
const MRModalCancelBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
`;
const MRModalNextBtn = styled(MRModalButton)`
  background-color: #37ada7;
  border: 1px solid #37ada7;
  margin-left: 10px;
`;
const MRModalSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: #414141;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const FCKCommentDiv = styled.div`
  padding-left: 20px;
  padding-right: 8px;
  color: #a0a0a0;
`;
const FCKTextarea = styled.textarea`
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;
`;
const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 20%;
  padding-top: 7px;
  font-weight: 600;
  color: #979797;
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 80%;
  padding-top: 7px;
`;
const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;
const ScoreContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  width: 100%;
  margin: 0px auto;
  padding: 15px;
  left: 15%;
`;
const ScoreHeaderContainer = styled.div`
  background-color: #36aba6;
  height: auto;
  padding: 10px;
  color: #ffffff;
  border: 1px solid #36aba6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`padding: 10px;`;
const HeaderButton = styled.div`
  color: #ffffff;
  width: 30px;
  height: 29px;
  float: right;
  font-size: 20px;
  cursor: pointer;
`;
const MRModalInput = styled.input`
  height: 26px;
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
`;

class FollowupTask extends React.Component {
    state = {
        tasks: null,
        ready: null,
        followup_sub: '',
        assigned_to: 0,
        followup_note: '',
        date: new Date(),
        review_list: [],
        parent_task_id: 0,
        due_by: '1970-01-01',
        binFiles: [],
        cur_files_json: null,
        isdesable: false,
        // for preventing multiple clicks
        disabled: false
    };

    componentDidMount() {

        let index = Store.getStoreData('ftask_index') === null ? 0 : Store.getStoreData('ftask_index');
        //debugger;
        if (index >= 0) {
            //let due_by=this.props.followuptaskvalue[index].dueby
            this.setState({
                // due_by: this.props.followuptaskvalue[index].dueby,
                followup_sub: this.props.followuptaskvalue[index].subject,
                followup_note: this.props.followuptaskvalue[index].note,
                assigned_to: this.props.followuptaskvalue[index].assignedto,
                review_list: this.props.revieweeList
            });
        } else {
            //debugger;
            let rlst = this.props.revieweeList;
            let date = new Date();
            let curmonth = parseInt(date.getMonth());
            let currmonth = curmonth + 1;
            var dateStr =
                currmonth >= 10
                    ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                    : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
            let reviewee_name = this.props.revieweeList.sort(function (a, b) {
                return a.ContactName > b.ContactName ? 1 : b.ContactName > a.ContactName ? -1 : 0;
            });
            //debugger;
            if (this.props.revieweeList != null && this.props.revieweeList.length > 0) {
                if (this.props.revieweeList.some((user) => parseInt(user.ID) === parseInt(this.props.discloserId))) {
                    this.setState({ assigned_to: this.props.discloserId.toString() });
                } else {
                    this.setState({ assigned_to: this.props.revieweeList[0].ID });
                }
            }
            this.setState({
                due_by: dateStr,
                review_list: reviewee_name,
                /*commented because Eric mentoned that on certain group company while
                 creating followup task, it was throwing error
                 */
                // assigned_to: this.props.revieweeList.filter((user) => user.ID === this.props.discloserId.toString())[0].ID
            });
        }
    }

    closeTaskModal = () => {
        this.props.closeFollowupTaskModal();
    };
    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({ [evt.target.name]: evt.target.value });
    };

    onDateChangeDueBy = (date) => {
        //debugger;
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ due_by: dateStr, date: date });
    };

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({ followup_note: event.target.value });
    };

    addTask = (event) => {
        event.preventDefault();
        //preventing multiple clicks
        if (this.state.disabled) {
            return;
        }
        this.setState({ disabled: true });

        let task = {};
        //debugger;
        let bin_files =
            this.state.cur_files_json === null
                ? this.state.binFiles
                : [...this.state.binFiles, ...this.state.cur_files_json];
        task.subject = this.state.followup_sub;
        task.note = this.state.followup_note;
        task.assignedto = this.state.assigned_to;
        task.dueby = this.state.due_by;
        task.binFiles = bin_files;
        this.props.refreshTaskValue(task);
        //this.props.closeFollowupTaskModal();
        this.setState({ followup_sub: '', followup_note: '', assigned_to: 0, date: new Date() });
    };

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for (let i = 0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if (!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({ binFiles: files });
    };

    render() {
        let index = Store.getStoreData('ftask_index') === null ? 0 : Store.getStoreData('ftask_index');
        return (
            <ScoreContainer>
                <ScoreHeaderContainer>
                    Add new follow up task<HeaderButton onClick={this.closeTaskModal}>
                        <FaTimes />
                    </HeaderButton>
                </ScoreHeaderContainer>
                <ScoreBodyContainer>
                    <MRModalLabel>Subject</MRModalLabel>
                    <MRModalInput
                        type="text"
                        name="followup_sub"
                        onChange={this.handleChange}
                        value={this.state.followup_sub}
                    />
                    <FCKCommentDiv style={{ padding: '0px', marginTop: '10px', marginBottom: '10px' }}>
                        <MRModalLabel>Note</MRModalLabel>
                        <FCKTextarea
                            rows="6"
                            style={{ width: '98%', marginTop: '10px' }}
                            value={this.state.followup_note}
                            onChange={this.changeFollowupNote}
                        />
                    </FCKCommentDiv>
                    <MRModalLabel>Assign To</MRModalLabel>
                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                        {this.state.review_list.map((reviewer) => (
                            <option key={reviewer.ID} value={reviewer.ID}>
                                {reviewer.ContactName}
                            </option>
                        ))}
                    </MRModalSelect>
                    <MRModalLabel>Due By</MRModalLabel>
                    <CSLDateCover>
                        <DatePicker
                            onChange={this.onDateChangeDueBy}
                            clearIcon={null}
                            //calendarIcon={null}
                            calendarIcon={<FaCalendarAlt style={{ color: '#243E57' }} />}
                            locale={'en-GB'}
                            minDate={new Date()}
                            value={this.state.date}
                        />
                    </CSLDateCover>
                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                    <ModalNameInputDiv>
                        <Dropzone
                            onFilesAdded={this.filesLoaded}
                            initFiles={this.state.cur_files_json}
                            addnew={!this.state.isdesable}
                        />
                    </ModalNameInputDiv>
                </ScoreBodyContainer>
                {(() => {
                    if (index >= 0) {
                    } else {
                        return (
                            <div style={{ height: '60px', width: '100%', paddingRight: '12px' }}>
                                <MRModalNextBtn disabled={this.state.disabled} onClick={this.addTask}>
                                    {this.state.disabled ? 'Adding Task...' : 'Add Task'}
                                </MRModalNextBtn>
                                <MRModalCancelBtn onClick={this.closeTaskModal}>Close</MRModalCancelBtn>
                            </div>
                        );
                    }
                })()}
            </ScoreContainer>
        );
    }
}

export default FollowupTask;
