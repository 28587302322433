import styled from 'styled-components';

/**   This is the ClearBoth */
const ClearBoth = styled.div`
    clear: both;
`;

/** @component */
export default ClearBoth

// .clearfix {
//     overflow: auto;
//   }

