import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import CSLLoader from './../Common/CSLLoader';
import FollowupTaskModal from './FollowupTaskModal';
import EditFollowupTaskModal from './EditFollowupTaskModal';
import moment from 'moment';
import { MdModeEdit,MdDelete } from "react-icons/md";
import AlertBox from '../Common/AlertBox';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; 
    margin: 0 auto;
    margin-top: 30px;  
    border: 1px solid #d7dddf;  
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 4px 9px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 4px 9px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 102%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
class FollowupTaskTable extends React.Component
{
    state = {
        ready: false,
        isLoadedIcon: false,        
        followuptasks: [],
        showModal: null,
        is_show_allaction_toggle: false,
        alert_param: null
    };
    constructor(props) {
        super(props);
    }

    componentDidMount()
    {
        this.setComponentState()
    }

    componentDidUpdate(prevprops)
    {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    setComponentState = () => {
        let followuptasks = Store.getStoreData('followuptask');
        this.setState({ followuptasks})   
        let task_id = this.props.taskId;
        if(task_id > 0) {
            console.log("task id", task_id)
            this.setState({showModal: 'view_followup_modal', ready: true});
        }else{
            this.setState({ready: true})
        }     
    }    

    // componentDidUpdate(prevProps) {
    //     if (prevProps !== this.props) {
    //         let followuptasks = Store.getStoreData('followuptask');
    //         this.setState({ followuptasks, ready: true})  
    //     }
    // } 

    processFollowupTaskData = () => {
        var followuptask = this.state.followuptasks;           
        let ret = {data: [], columns: []};
        let i = 0;
        let role = Store.getStoreData('role');
        let allaction_toggle = this.state.is_show_allaction_toggle;
        let contact_id = Store.getStoreData('contact_id');

        let has_gc = Store.getStoreData('has_gc');
        let gc_users = Store.getStoreData('gc_users');
        let update_followuptask = [];
        if(allaction_toggle && role === 'am' && has_gc === true){  
            for(let k of followuptask){
                if(k[6] in gc_users){
                    update_followuptask.push(k); 
                }
            }                                                                  
        }else{                                    
            update_followuptask = followuptask;
        }
        let lp = Store.getStoreData('language_pack')

        ret.columns =[{Header: lp['header_for_status_column'] === undefined ? 'Status' : lp['header_for_status_column'], accessor: 'status_value', width: 120, 
                        Cell: row => (<div>
                            {
                              (() => {                                                    
                                switch(row.original.status_value) {
                                    case 'Overdue': return  <div style={{borderLeft: "9px solid #ff0000",height: "24px"}}><div style={{padding: "4px",marginLeft: "10px"}}>{row.original.status_value}</div></div>; break;                                  
                                    case 'Completed': return  <div style={{borderLeft: "9px solid #04ada8",height: "24px"}}><div style={{padding: "4px",marginLeft: "10px"}}>{row.original.status_value}</div></div>; break;                                  
                                    default: return  <div style={{borderLeft: "9px solid #5656AA",height: "24px"}}><div style={{padding: "4px",marginLeft: "10px"}}>{row.original.status_value}</div></div>
                                }                                
                              })()
                            }
                          </div>), headerStyle: {textAlign: 'left'}},
                        {Header: lp['header_for_task_column'] === undefined ? 'Task' : lp['header_for_task_column'], accessor: 'task', minWidth: 350, headerStyle: {textAlign: 'left'}}, 
                        {Header: lp['header_for_assigned_to_column'] === undefined ? 'Assigned To' : lp['header_for_assigned_to_column'], accessor: 'assigned_to', minWidth: 170, headerStyle: {textAlign: 'left'}},                        
                        {Header: lp['header_for_due_date_column'] === undefined ? 'Due Date' : lp['header_for_due_date_column'], accessor: 'due_date', minWidth: 150, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                                return 0;
                            }
                            let adue = a.split("/");
                            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                          }},                        
                        {'Header' : lp['header_for_action_column'] === undefined ? 'Action' : lp['header_for_action_column'],Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton onClick={this.goToFollowTaskUrl(row.original.id)}>View</MRButton>
                                {
                                    (() => {                                        
                                        if(role !== 'team'){
                                            return(<div style={{display: "inline-flex"}}>
                                                <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><MdModeEdit /></div>
                                                <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.customdeleteFollowupTaskAlert(row.original.id)}><MdDelete /></div>
                                                </div>)
                                        }
                                    })()
                                }                                
                            </div>
                         ), width: 180, headerStyle: {textAlign: 'left'}}                          
                     ];
        for(let d of update_followuptask) {
            i++;      
            
            let status = "Pending";
            if(d[2] === 'COMP_ATTESTATION_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d[2] === 'COMP_ATTESTATION_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }

            let today = moment().unix();
            let text = (d[5]+86400) > today ? status : 'Overdue';  
            if(status === "Pending"){
                text = (d[5]+86400) > today ? status : 'Overdue';                
            }else if(status === "Completed"){
                text = status;
                
            }                  
            let task = Store.getStoreData('contacts')[parseInt(d[6])] +" has been assigned a task titled "+ d[7];  
            let elem = {};  
            if(role !== 'team'){
                if(!allaction_toggle){
                    if(d[6] === parseInt(contact_id)){
                        elem = {'task' : task,
                            'assigned_to' : Store.getStoreData('contacts')[parseInt(d[6])],                        
                            'status_value': text,
                            'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                            'id' : d[0]
                        };
                        ret.data.push(elem); 
                    }
                }else{
                    elem = {'task' : task,
                        'assigned_to' : Store.getStoreData('contacts')[parseInt(d[6])],                        
                        'status_value': text,
                        'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                        'id' : d[0]
                    };
                    ret.data.push(elem); 
                }
            }else{
                elem = {'task' : task,
                    'assigned_to' : Store.getStoreData('contacts')[parseInt(d[6])],                        
                    'status_value': text,
                    'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                    'id' : d[0]
                };
                ret.data.push(elem);
            } 
        }
        ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
                return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);            
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
        });
        return ret;
    }

    customdeleteFollowupTaskAlert = (taskid) => (event) => {
        event.preventDefault();  
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteFollowupTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }
    deleteFollowupTask = (result, stack) => {
        //event.preventDefault();  
        //if (window.confirm('Are you sure you want to delete this record?')) {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log('ROWS', stack.taskid);        
        let postData = {command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData",postData)            
        let api = new APICall();
        api.command(postData, this.processRemoveFollowupData);
        //}       
    }

    processRemoveFollowupData = (result) => {
        console.log("result", result)
        //alert('Follow-up task deleted successfully');
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Follow-up task deleted successfully.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.customupdateprocessRemoveFollowupData, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload(true);
    }
    customupdateprocessRemoveFollowupData = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload(true);
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_single_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editFollowupModal);
    }
    editFollowupModal = (result) => {
        console.log("followup_task",result.result.task);
        //Store.updateStore('ftask_index', 0);        
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showModal: 'edit_followup_modal', isLoadedIcon: false}) 
    }

    goToFollowTaskUrl = (task_id) => (event) => {
        event.preventDefault(); 
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        this.setState({isLoadedIcon: true})    
        api.command(postData, this.openFollowupModal);     
    }

    openFollowupModal = (result) => {
        console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
    }

    closeModal = (event) => {        
        this.setState({showModal: null})
    }

    toggleAction = (event) => {        
        event.preventDefault();
        let allaction_toggle = this.state.is_show_allaction_toggle ? false : true;        
        this.setState({is_show_allaction_toggle: allaction_toggle})
    }
    
    render()
    {  
        let role = Store.getStoreData('role');
        let lp = Store.getStoreData('language_pack')
        let task_id = this.props.taskId;
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }    
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'view_followup_modal' : return <FollowupTaskModal closeModal={this.closeModal} />; break;                                                       
                        }
                    }
                })()
                }
            </div>)
        }       
        return (  
            <div>  
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }  
                <OverviewInfoHeader style={{width: 'widthValue' in this.props ? this.props.widthValue : "96%"}}>
                    <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>{lp['my_tasks_header'] === undefined ? 'My Tasks' : lp['my_tasks_header']}</div> 
                    {
                        (() => {
                            if (role !== 'team') {
                                return (
                                    <div style={{float: "right", marginRight: "10px"}}>
                                        <div style={{float: "left", marginTop: "4px",color: "#323232", fontWeight: "600",fontSize: "15px"}}>{lp['show_all_actions'] === undefined ? 'Show All Actions' : lp['show_all_actions']}</div>
                                        <div style={{float: "right", marginLeft: "10px"}}>
                                        {
                                            (() => {
                                                if (this.state.is_show_allaction_toggle === true) {
                                                    return (
                                                        <div>
                                                            <SelectedRadio>{lp['yes'] === undefined ? 'Yes' : lp['yes']}</SelectedRadio>
                                                            <UnselectedRadio onClick={this.toggleAction}>{lp['no'] === undefined ? 'No' : lp['no']}</UnselectedRadio>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div>
                                                            <UnselectedRadio onClick={this.toggleAction}>{lp['yes'] === undefined ? 'Yes' : lp['yes']}</UnselectedRadio>
                                                            <SelectedRadio>{lp['no'] === undefined ? 'No' : lp['no']}</SelectedRadio>
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                );
                            }
                        })()
                    }                                
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody style={{width: 'widthValue' in this.props ? this.props.widthValue : "96%"}}>
                    <CSLTable add={false} processData={this.processFollowupTaskData} headerText={''} tableRows={7} refreshCallback={this.refreshCallback} /> 
                </OverviewInfoBody>  

                {
                    (() => {
                        if (this.state.showModal !== null) {
                            switch(this.state.showModal) {                                
                                case 'view_followup_modal' : return <div><InactiveOverlay style={{height: "102%"}}/><FollowupTaskModal closeModal={this.closeModal} /></div>; break;
                                case 'edit_followup_modal' : return <div><InactiveOverlay style={{height: "102%"}}/><EditFollowupTaskModal closeModal={this.closeModal} /></div>; break;
                            }
                        }
                    })()
                }   
            <AlertBox alertParam = {this.state.alert_param}/>           
            </div>
        );
    }
}
export default FollowupTaskTable;