import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock, IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store';
import General from './General';
import Email from './Email';
import Unassigntask from './Unassigntask';
import EnableScheme from './EnableScheme';
import APICall from '../../Common/APICall.js';
import { Link } from "react-router-dom";
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import SchemeTaskSet from './SchemeTaskSet'
import Reload from '../../Common/Reload.js';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

const ActiveQSetTab = styled.div`
    background-color: #04ADA8;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const QSetTab = styled.div`
    background-color: #FFFFFF;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const SpacerTab = styled.div`
    background-color: #FFFFFF;
    min-height: 10px;
`;

const RadioBlock = styled.div`
    margin-top: 10px;
`;

const SelectedRadio = styled.div`
    background-color: #37ADA7;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;
const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
    display: inline-block;
`;

const ModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 60px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    position: fixed;
    background-color: #ffffff;
    top: 100px;
    left: calc(50vw - 250px);
    width: 650px;
    box-sizing: border-box;
    height: 400px;
    min-height: 300px;
    box-sizing: border-box;
    background-color: #F3F7FB;
    border: 1px solid #F3F7FB;
    border-radius: 5px;
    z-index: 1010;
    opacity: 1.0;
`
const ModalHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-left: 7px solid #04ADA8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`
const ModalBody = styled.div`
    width: 100%;
    padding-left: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 200px;
    min-height: 200px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    z-index: 1020;
    opacity: 1.0;
`
const ModalFooter = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const ModalHeaderTextLarge = styled.div`
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-right: 0px;
    width: calc(100% - 60px);
    color: #5D748E;
    font-size: 16px;
    font-weight: 700;
    float: left;
`
const ModalHeaderClose = styled.div`
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-left: 0px;
    width: 30px;
    color: #5D748E;
    font-size: 20px;
    font-weight: 700;
    float: right;
    cursor: pointer;
`
const ModalHeaderTextSmall = styled.div`
    padding-left: 10px;
    padding-top: 3px;
    width: calc(100% - 10px);
    color: #5D748E;
    font-size: 12px;
    font-weight: 700;
`

const ClearFloat = styled.div`
    clear: both;
`
const CancelButton = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`;
const SaveButton = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 25px;
`;

class AddSchemeModal extends React.Component {
    state = {name: '', is_active: 1, scheme_id: '0'}
    switchRadio = (value) => {
        this.setState({is_active: value})
    }

    changeNickName = (event) => {
        let name = event.target.value
        this.setState({name})
    }

    saveScheme = () => {
        if(this.state.name.trim() === '') {
            alert('You must specify a name for the Scheme.')
            return
        }
        this.props.saveScheme(this.state)
    }
    render() {
        console.log('AddQSetModal state', this.state)
        return (<div>
            <InactiveOverlay />
            <ModalContainer>
                <ModalHeader>
                    <ModalHeaderTextLarge>Add Scheme</ModalHeaderTextLarge><ModalHeaderClose onClick={this.props.closeAddSchemeModal}><FaTimes /></ModalHeaderClose>
                    <ClearFloat />
                    <ModalHeaderTextSmall>Complete the required fields to create a task scheme</ModalHeaderTextSmall>
                </ModalHeader>
                <ModalBody>
                <ModalLabel>Task scheme Name</ModalLabel>
                <ModalInput style={{width: "95%"}} onChange={this.changeNickName} value={this.state.name} />
                <ModalLabel>Active?</ModalLabel>
                {
                    (() => {
                        if(this.state.is_active === 1) {
                            return (
                                <RadioBlock>
                                    <SelectedRadio>Yes</SelectedRadio>
                                    <UnselectedRadio onClick={() => this.switchRadio(0)}>No</UnselectedRadio>
                                </RadioBlock>
                                )
                        }
                        return (
                                <RadioBlock>
                                    <UnselectedRadio onClick={() => this.switchRadio(1)}>Yes</UnselectedRadio>
                                    <SelectedRadio>No</SelectedRadio>
                                </RadioBlock>
                                )
                    })()
                }
                </ModalBody>
                <ModalFooter>
                    <SaveButton onClick={this.saveScheme} style={{float: 'right', marginTop: 15, backgroundColor: '#143151'}}>Submit</SaveButton>
                    <CancelButton onClick={this.props.closeAddSchemeModal} style={{float: 'right', marginTop: 15, backgroundColor: '#ffffff'}}>Cancel</CancelButton>
                </ModalFooter>
            </ModalContainer>
        </div>)
    }
}

class ModuleConfigHOC extends React.Component
{
    state = {
		active_section_id: "general",
		module_config: null,
        requester: "",
        dirty: false,
        permissions: {},
        all_company_users: null,
        changed_user_roles: {},
        alert_param: null,
        schemes: [],
        active_scheme_id: 0,
        show_inactive_schems: false,
        show_addscheme_modal: false
    };

    constructor(props) {
        super(props);
        let postData = { command: "get_module_config" };
        let api = new APICall();
        api.command(postData, this.getInitialPermission);

    }

    getInitialPermission = (result) => {
        console.log("result ===>", result)
        let contactlist = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : {};
        let user_roles = contactlist.result.user_roles['tmview'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        for(let key in cls){
            if(cls[key].IsActive === true && cls[key].IsDeleted === false){
                let cu = {};
                cu['email'] = cls[key].EmailAddress;
                cu['user_id'] = key;
                cu['name'] = cls[key].ContactName;
                cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
                company_users.push(cu)
            }
        }
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users

        };
        this.setState({permissions:permissions, module_config: result.moduleconfig, all_company_users: company_users});
    }

    getInitialPermission_obsulate = (result) => {
        console.log("result ===>", result)
        const permissions = {
            user_roles: result.user_roles,
            gc_companies: result.gc_companies,
            roles: result.roles,
            company_users: result.company_users
        };
        console.log(JSON.parse(JSON.stringify(result.company_users)));
        const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        this.setState({permissions:permissions, module_config: result.moduleconfig, all_company_users: all_company_users});
        // this.setState({module_config: result.moduleconfig})
    }

	returnSection = (section) => (event) => {
		event.preventDefault();
        if(section === "enabletaskscheme"){
            let schemes = Store.getStoreData('schemes')
            if(schemes === null) {
                const postData = { command: "schemes", action: 'list_schemes' };
                const api = new APICall();
                api.command(postData, this.processSchemes);
                // this.setState({active_section_id: section});
            } else {
                this.setState({schemes: schemes});
            }
        }
        this.setState({active_section_id: section, active_scheme_id: 0});
	}

    returnSchemeSection = (section) => (event) => {
        event.preventDefault();
    }

    processSchemes = (result) => {
        console.log('resut schemes', result)
        let schemes = result.result.schemes
        Store.updateStore('schemes', schemes)
        this.setState({schemes: schemes});
    }

    returnSchemeSection = (section) => (event) => {
        event.preventDefault();
        let schemes = Store.getStoreData('schemes')
        if(schemes === null) {
            const postData = { command: "schemes", action: 'list_schemes' };
            const api = new APICall();
            api.command(postData, this.processSchemes);
            this.setState({active_section_id: section});
        } else {
            this.setState({active_section_id: section, schemes: schemes});
        }
    }



    afterUpdate = (result) => {
        // console.log(result);
        // alert(result.error_msg);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
        if(result.error_code > 0) {
            alert_param['message'] = result.error_msg;
        }
        this.setState({module_config: result.result, alert_param: alert_param});
    }

    afterRoleUpdate = (result) => {
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
        if(result.error_code > 0) {
            alert_param['message'] = result.error_msg;
        }
        this.setState({alert_param: alert_param});
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null});
    }

    updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    updateEmailRemainder = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    updatePermissions = (obj, single_obj) => {
        console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }

    updateModuleconfig = (event) => {
        event.preventDefault();
        let {module_config, changed_user_roles} = this.state;
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config};
        api.command(postData, this.afterUpdate);
    }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        window.location.replace(url);
        // window.close();
    }

    updateUnassignedTask = (module_config) => {
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);

        console.log("postData --->", postData)
    }

    setActiveScheme = (active_scheme_id) => {
        this.setState({active_scheme_id})
    }

    showInactiveSchems = (event) => {
        event.preventDefault();
        let show_inactive_schems = !this.state.show_inactive_schems
        this.setState({show_inactive_schems})
    }

    showAddSchemeModal = () => {
        this.setState({show_addscheme_modal: true})
    }
    closeAddSchemeModal = () => {
        this.setState({show_addscheme_modal: false})
    }

    processSaveScheme = (result) => {
        console.log('processSaveScheme result', result)
        if(result.error_code === 0) {
            let schemes = result.result.schemes
            this.setState({schemes, show_addscheme_modal: false, active_scheme_id: result.result.active_scheme_id})
        }
    }

    saveScheme = (scheme) => {
        let api = new APICall()
        let postData = {command: 'schemes', action: 'update_scheme', scheme_id: 0, name: scheme.name, is_active: scheme.is_active, scheme_json: {used_tasks: {}}}
        api.command(postData, this.processSaveScheme);
    }

    updateSchemeTask = (scheme) => {
        let api = new APICall()
        let postData = {command: 'schemes', action: 'update_scheme', scheme_id: scheme.id, name: scheme.name, is_active: scheme.is_active, scheme_json: scheme.scheme_json}
        api.command(postData, this.processSaveScheme);
    }

	render()
	{
        console.log("module config", this.state.module_config);
        if (Store.getStoreData('role') !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if (this.state.module_config === null) {
            return <div><CSLLoader /></div>;
        }
        let schemes = this.state.schemes === null ? [] : this.state.schemes;
        console.log("schemes", schemes)
        let active_schemes_num = 0
        for(let s of schemes) {
            if(s.is_active === 1)active_schemes_num++
        }
        let inactive_schemes_num = schemes.length - active_schemes_num
        let scheme_header = null

        for(let sh of schemes) {
            if(this.state.active_scheme_id.toString() !== '0' && sh.id.toString() === this.state.active_scheme_id.toString()) {
                scheme_header = sh
                break
            }
        }
		return (
			<div style={{padding: "10px 10px 20px 10px"}}>
                <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                	<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                        <ReviewTab onClick={this.returnSection("general")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>

                        <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "email") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Email</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("unassigndef")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "unassigndef") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Unassigned Tasks</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Unassigned Tasks</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("enabletaskscheme")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "enabletaskscheme") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Task Schemes</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Task Schemes</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <div>
                        {
                            (() => {
                                if(this.state.active_section_id === 'enabletaskscheme') {
                                    if(this.state.schemes === null) {
                                        return <QSetTab>Retrieving Schems from the server...</QSetTab>
                                    } else {
                                        return (<div>
                                            {
                                                (() => {
                                                    if(active_schemes_num === 0) {
                                                        return <QSetTab style={{cursor: 'default'}}>No Active Schems found...</QSetTab>
                                                    } else {
                                                        return (
                                                            <div>
                                                            {
                                                                schemes.map((s, i) => {
                                                                    if(s.is_active === 1){
                                                                        if(s.id.toString() === this.state.active_scheme_id.toString()) {
                                                                            return <ActiveQSetTab style={{paddingLeft: 25}} key={i}>{s.name}</ActiveQSetTab>
                                                                        } else {
                                                                            return <QSetTab onClick={() => this.setActiveScheme(s.id.toString())} style={{paddingLeft: 25}} key={i}>{s.name}</QSetTab>
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            </div>
                                                            )
                                                    }

                                                })()
                                            }
                                            <SpacerTab />
                                            <QSetTab onClick={() => this.showAddSchemeModal()} style={{fontWeight: 600, color: '#8BD8D6'}}>Add new</QSetTab>
                                            <SpacerTab />
                                            <ReviewTab onClick={this.showInactiveSchems} style={{marginTop: "2px"}}>
                                            {
                                                (() => {
                                                    if (this.state.show_inactive_schems) {
                                                        return (<div style={{float: "left", fontWeight: "600"}}>Inactive</div>);
                                                    } else {
                                                        return (<div style={{float: "left"}}>Inactive</div>);
                                                    }
                                                })()
                                            }
                                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px"}}>
                                            {
                                                (() => {
                                                    if (this.state.show_inactive_schems) {
                                                        return (<IoIosArrowUp />);
                                                    } else {
                                                        return (<IoIosArrowDown />);
                                                    }
                                                })()
                                            }
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                            <SpacerTab />
                                            </ReviewTab>
                                            {
                                                (() => {
                                                    if(this.state.show_inactive_schems) {
                                                        return (<div>
                                                                {
                                                                    (() => {
                                                                        if(inactive_schemes_num === 0) {
                                                                            return <QSetTab style={{cursor: 'default'}}>No Inactive Schemes found...</QSetTab>
                                                                        } else {
                                                                            return (
                                                                                <div>
                                                                                {
                                                                                    schemes.map((s, i) => {
                                                                                        if(s.is_active === 0){
                                                                                            if(s.id.toString() === this.state.active_scheme_id.toString()) {
                                                                                                return <ActiveQSetTab style={{paddingLeft: 25}} key={i}>{s.name}</ActiveQSetTab>
                                                                                            } else {
                                                                                                return <QSetTab onClick={() => this.setActiveScheme(s.id.toString())} style={{paddingLeft: 25}} key={i}>{s.name}</QSetTab>
                                                                                            }
                                                                                        }

                                                                                    })
                                                                                }
                                                                                </div>
                                                                                )
                                                                        }

                                                                    })()
                                                                }
                                                            </div>)
                                                    }
                                                })()
                                            }
                                        </div>)
                                    }
                                }
                            })()
                        }
                        </div>

                	</ReviewLeftContainer>
                	<ReviewRightContainer>
                        {

                            (() => {
                                if (this.state.active_section_id === "general") {
                                    return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} />);
                                }
                                if (this.state.active_section_id === "email") {
                                    return (<Email variables={this.state.module_config.variables} email={JSON.parse(JSON.stringify(this.state.module_config.email))} updateEmail={this.updateEmail} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder}/>);
                                }
                                if (this.state.active_section_id === "unassigndef") {
                                    return (<Unassigntask module_config={JSON.parse(JSON.stringify(this.state.module_config))} updateUnassignedTask={this.updateUnassignedTask} />);
                                }
                                if (this.state.active_section_id === "enabletaskscheme" && this.state.active_scheme_id === 0) {
                                    return (<EnableScheme general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} />);
                                }
                                if (this.state.active_section_id === "enabletaskscheme" && this.state.active_scheme_id > 0) {
                                    return (<SchemeTaskSet
                                                scheme_id={this.state.active_scheme_id}
                                                scheme_header={scheme_header}
                                                updateSchemeTask={this.updateSchemeTask}
                                                is_active={scheme_header !== null && 'is_active' in scheme_header ? scheme_header.is_active : 1}
                                            />);
                                }

                            })()
                        }

                        {
                            (() => {
                                if (this.state.active_section_id !== "general" && this.state.active_section_id !== "email" && this.state.active_section_id !== "unassigndef" && this.state.active_section_id!=="enabletaskscheme") {
                                    return (
                                        <FooterButtonsContainer>
                                            <CancelBtn onClick={this.closePage}>Cancel</CancelBtn><SaveBtn onClick={this.updateModuleconfig}>Save</SaveBtn>
                                        </FooterButtonsContainer>
                                    );
                                }
                            })()
                        }
                	</ReviewRightContainer>
                    <AlertBox alertParam = {this.state.alert_param} />
                	<div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
                {
                    (() => {
                        if(this.state.show_addscheme_modal) {
                            return <AddSchemeModal closeAddSchemeModal={this.closeAddSchemeModal} saveScheme={this.saveScheme} />
                        }
                    })()
                }
			</div>
		);
	}
}

export default ModuleConfigHOC;
