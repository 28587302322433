import React from 'react';
import styled from 'styled-components';
import {FaTimes,FaSearch} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store';
import HtmlEditor from '../../HtmlEditor';
import RUPills from '../Partials/RUPills';
import RUFullPills from '../Partials/RUFullPills';
import RegisterChildTasksRUTable from '../Partials/RegisterChildTasksRUTable';
import DatePicker from 'react-date-picker';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import ViewManagerRU from './ViewManagerRU';
import ShareInfoAndActionModal from '../Modals/ShareInfoAndActionModal';
import AlertBox from './../Common/AlertBox';
import '../../App.css';
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 25px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const RegModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #979797;
`;
const RegModalIssueDateInput = styled.input`
    height: 30px;
    width: 90%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-top: 8px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegModalNameInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisalButtonDiv = styled.div`
    display: block;
    box-sizing: border-box;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegMarkForActionButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #d5d5d5;
    color: #757575;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    margin-left: 5px;
    font-size: 10px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegShareButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062;
    color: #ffffff;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 5px;
    /* float: right; */
    cursor: pointer;
`;
const StatusBadge = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 200px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:default;
`;
const RegDismissalReason = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;    
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
class RegisterManagerViewModal extends React.Component
{
    state = {
        viewModal: null,
        alert_param: null
    };
    constructor(props) {
        super(props);
      }
    componentDidMount() {
    }

    closeRegulatoryModal = (event) => {
        // if (document.referrer.endsWith("/tmview")) {
        //     window.location = '/';
        // } else {
        //     this.props.closeModal();
        // }
        // if(Store.getStoreData('isreferenceUrl') === true){
        //     window.location = '/';
        // } else {
        //     this.props.closeModal();
        // }
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
              window.location = 'process.env.REACT_APP_RCM_URL';
            }else{
              //window.location = '/';
              this.props.closeTaskModal();
            }
          } else {
            this.props.closeModal();
        }
    }
    viewrcmDetails = () => {
        this.setState({ viewModal: 'rcmDetails'})
    }
    shareobjectname = (shareInfoId) => {
        let name = null
        console.log("Sourav",shareInfoId)
        let shareuserlist = Store.getStoreData('shareuserlist')
        console.log("shareuserlist",shareuserlist)
        for(let i =0;i<shareuserlist.length;i++){
            //console.log("Sourav1",shareuserlist[i].id)
            if(JSON.stringify(shareuserlist[i].id) === shareInfoId){

                name = shareuserlist[i].name
            }
        }
        return name;
    }

    processMyShareInformation = () => {
        var taskdata = Store.getStoreData('managerViewDetails').shareInfo;
        console.log("taskdata.shareUserInfo",taskdata)
        let shareinfo_data = [];
        Object.keys(taskdata).map((d, key) => {
            let shareinfo_object = {}
            shareinfo_object.id = d
            shareinfo_object.name = this.shareobjectname(d);
            shareinfo_object.status = taskdata[d].cur_view;
            shareinfo_object.taskID = taskdata[d].taskID;
            const infosend_date_obj = new Date(taskdata[d].date);
            const infosend_day = infosend_date_obj.getDate() < 10 ? "0"+infosend_date_obj.getDate().toString() : infosend_date_obj.getDate();
            const infosend_month = infosend_date_obj.getMonth()+1 < 10 ? `0${(infosend_date_obj.getMonth() + 1).toString()}` : infosend_date_obj.getMonth()+1;
            const infosend_year = infosend_date_obj.getFullYear();
            const infosend_date = `${infosend_day}/${infosend_month}/${infosend_year}`;
            shareinfo_object.sendate = infosend_date
            let responseDate = "--/--/----";
            if(taskdata[d].responsedate !== ""){
                const response_date_obj = new Date(taskdata[d].responsedate);
                const response_day = response_date_obj.getDate() < 10 ? "0"+response_date_obj.getDate().toString() : response_date_obj.getDate();
                const response_month = response_date_obj.getMonth()+1 < 10 ? `0${(response_date_obj.getMonth() + 1).toString()}` : response_date_obj.getMonth()+1;
                const response_year = response_date_obj.getFullYear();
                responseDate = `${response_day}/${response_month}/${response_year}`;
            }
            shareinfo_object.response = responseDate;
            //shareinfo_object.response = taskdata.shareUserInfo[d].responsedate === "" ? "--/--/----" : "--/--/----"
            shareinfo_data.push(shareinfo_object);
        })
        console.log("shareinfo_data",shareinfo_data)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'Name', accessor: 'name_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
                    {Header: 'Status', accessor: 'status', minWidth: 200, headerStyle: {textAlign: 'left'}},
                    {Header: 'Send Date', accessor: 'send_date', minWidth: 200, headerStyle: {textAlign: 'left'}},
                    {Header: 'Response Date', accessor: 'response_date', minWidth: 170, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                        <div>
                            {/* <MRButton onClick={this.shareinfoView}>View</MRButton> */}
                            {
                            (() => {
                                //console.log("row.original.status===>",row.original.status)
                                if(row.original.status !== 'Unopened') {
                                    return (<MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id,row.original.taskid)}>View</MRButton>)

                                }else{
                                    return (<MRButton style={{cursor:"pointer"}} onClick={() => this.resendEmailtoUser(row.original.id,row.original.taskid)}>Resend</MRButton>)
                                }
                            })()
                            }
                        </div>
                    ), width: 120, headerStyle: {textAlign: 'center'}}];
        for(let d of shareinfo_data) {
            i++;
            let elem = {
                    'id': d.id,
                    'taskid': d.taskID,
                    'name_value' : d.name,
                    'status': d.status,
                    'send_date': d.sendate,
                    'response_date': d.response
                   };
            ret.data.push(elem);
        }
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }

    viewEmailNotification = (userID,taskid) => {
        let postData = {command: "view_user_email", task_id: taskid, contact_id: userID};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterviewemailLog);
    }
    afterviewemailLog = (result) => {
        //console.log("Completed:", result);
        this.setState({emailLogData: result.emaillog, viewModal: 'shareInfoAndAction'})
    }

    resendEmailtoUser = (userid,taskid) =>{
        console.log("userid",userid)
        let task_id = taskid;
        console.log("userid",task_id)
        let postData = {command: "resend_email_to_share_user", task_id: task_id, send_mail: true, contact_id: userid,system_id: 1001};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);
    }
    afterresendCompleted = (result) => {
        //alert('Resend mail successfully.');
        let alert_param = {title: 'Success', message: 'The e-mail has been successfully re-sent to the user.', ok_text: 'OK', confirm: false,
                           alertHandler: this.updateafterAlertLoading, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateafterAlertLoading = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    exportToCSVShareInfo  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",name_value: "Name",status: "Status",send_date:"Send Date",response_date: "Response Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "name_value" : cur_table_data[k].name_value ,
                "status" : cur_table_data[k].status ,
                "send_date" : cur_table_data[k].send_date ,
                "response_date" : cur_table_data[k].response_date ,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
            container.pop()
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Share_for_information.csv");
        document.body.appendChild(link);
        link.click();
      }

      closeModal = () => {
        this.setState({ viewModal: null})
    }

    processMyShareAcknowledgement = () => {
        var taskdata = Store.getStoreData('managerViewDetails').shareAcknow;
        let shareacknow_data = [];
        Object.keys(taskdata).map((d, key) => {
            let shareacknow_object = {}
            shareacknow_object.id = d
            shareacknow_object.name = this.shareobjectname(d);
            shareacknow_object.status = taskdata[d].cur_view;
            shareacknow_object.taskID = taskdata[d].taskID;
            const infosend_date_obj = new Date(taskdata[d].date);
            const infosend_day = infosend_date_obj.getDate() < 10 ? "0"+infosend_date_obj.getDate().toString() : infosend_date_obj.getDate();
            const infosend_month = infosend_date_obj.getMonth()+1 < 10 ? `0${(infosend_date_obj.getMonth() + 1).toString()}` : infosend_date_obj.getMonth()+1;
            const infosend_year = infosend_date_obj.getFullYear();
            const infosend_date = `${infosend_day}/${infosend_month}/${infosend_year}`;
            shareacknow_object.sendate = infosend_date
            let responseDate = "--/--/----";
            if(taskdata[d].responsedate !== ""){
                const response_date_obj = new Date(taskdata[d].responsedate);
                const response_day = response_date_obj.getDate() < 10 ? "0"+response_date_obj.getDate().toString() : response_date_obj.getDate();
                const response_month = response_date_obj.getMonth()+1 < 10 ? `0${(response_date_obj.getMonth() + 1).toString()}` : response_date_obj.getMonth()+1;
                const response_year = response_date_obj.getFullYear();
                responseDate = `${response_day}/${response_month}/${response_year}`;
            }
            shareacknow_object.response = responseDate;
            shareacknow_data.push(shareacknow_object);
        })
        console.log("shareinfo_data",shareacknow_data)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'Name', accessor: 'name_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Status', accessor: 'status', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Send Date', accessor: 'send_date', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Response Date', accessor: 'response_date', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                {/* <MRButton >View</MRButton> */}
                {
                    (() => {
                        //console.log("row.original.status===>",row.original.status)
                        if(row.original.status !== 'Unopened') {
                            return (<MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id,row.original.taskid)}>View</MRButton>)

                        }else{
                            return (<MRButton style={{cursor:"pointer"}} onClick={() => this.resendEmailtoUser(row.original.id,row.original.taskid)}>Resend</MRButton>)
                        }
                    })()
                }
            </div>
          ), width: 120, headerStyle: {textAlign: 'center'}}];
        for(let d of shareacknow_data) {
            i++;
            let elem = {
                    'id': d.id,
                    'taskid': d.taskID,
                    'name_value' : d.name,
                    'status': d.status,
                    'send_date': d.sendate,
                    'response_date': d.response
                   };
            ret.data.push(elem);
        }
        Store.updateStore('table_cur_acknow_data', ret.data)
        return ret;
    }

    exportToCSVShareAcknow  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_acknow_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",name_value: "Name",status: "Status",send_date:"Send Date",response_date: "Response Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "name_value" : cur_table_data[k].name_value ,
                "status" : cur_table_data[k].status ,
                "send_date" : cur_table_data[k].send_date ,
                "response_date" : cur_table_data[k].response_date ,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
            container.pop()
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Share_for_acknowledgement.csv");
        document.body.appendChild(link);
        link.click();
      }

      exportToCSVFollowupAction  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_followup_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {index: "1234",assign_to: "Assign To",notes: "Notes",due_date:"Due Date",issue_date: "Issue Date",statustext: "Status"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "assign_to" : cur_table_data[k].assign_to ,
                "notes" : cur_table_data[k].notes ,
                "due_date" : cur_table_data[k].due_date ,
                "statustext" : cur_table_data[k].statustext,
                "index" :  cur_table_data[k].index ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
            container.pop()
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "follow_up_actions.csv");
        document.body.appendChild(link);
        link.click();
      }

      resendToAll = (resendType) => (event) => {
          event.preventDefault();
        if(resendType === 'info'){
            let checkresendValue = false;
            let resendData = []
            let cur_info_table_data = Store.getStoreData('table_cur_data');
            for(let i=0;i<cur_info_table_data.length;i++){
                let resendobject = {};
                if(cur_info_table_data[i].status === 'Unopened'){
                    checkresendValue = true;
                    resendobject.user_id = cur_info_table_data[i].id;
                    resendobject.task_id = cur_info_table_data[i].taskid;
                    resendData.push(resendobject);
                }
            }
            if(checkresendValue){
                console.log(resendData);
                let postData = {command: "resend_email_to_all_share_user", resendData: resendData};
                console.log(postData);
                const api = new APICall();
                api.command(postData, this.afterresendCompleted);
            }else{
                //alert("There are no pending actions to resend.")
                let alert_param = {title: 'ERROR', message: "There are no pending actions to resend.", ok_text: 'OK', confirm: false,
                    alertHandler: this.updateafterAlertLoading, stack: {}}
                this.setState({alert_param: alert_param})
            }
        }else if(resendType === 'ackn'){
            let checkresendValue = false;
            let resendData = []
            let cur_ackn_table_data = Store.getStoreData('table_cur_acknow_data');
            for(let i=0;i<cur_ackn_table_data.length;i++){
                let resendobject = {};
                if(cur_ackn_table_data[i].status === 'Unopened'){
                    checkresendValue = true;
                    resendobject.user_id = cur_ackn_table_data[i].id;
                    resendobject.task_id = cur_ackn_table_data[i].taskid;
                    resendData.push(resendobject);
                }
            }
            if(checkresendValue){
                console.log(resendData);
                let postData = {command: "resend_email_to_all_share_user", resendData: resendData};
                console.log(postData);
                const api = new APICall();
                api.command(postData, this.afterresendCompleted);
            }else{
                //alert("There are no pending actions to resend.")
                let alert_param = {title: 'ERROR', message: "There are no pending actions to resend.", ok_text: 'OK', confirm: false,
                    alertHandler: this.updateafterAlertLoading, stack: {}}
                this.setState({alert_param: alert_param})
            }
        }

      }


    render()
    {
        let managerviewDetails = Store.getStoreData('managerViewDetails');
        let thisTaskChildren = Store.getStoreData('currentFollowupTask');
        let docType = "";
        //   docType = managerviewDetails.documentType;
        //   if(!isNaN(managerviewDetails.documentType)) {
        //       //const documentType = Store.getStoreData('rutypename');
        //       const documentType = Store.getStoreData('rutypenameAll');
        //       let doctype = {};
        //       for(let i=0; i < documentType.length; i++) {
        //           doctype[documentType[i].id] = documentType[i].documentname;
        //       }
        //       docType = doctype[managerviewDetails.documentType]
        //   }
          let dNames = "";
        if(managerviewDetails.documentType !== null) {
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            }
            let i=0;
            for (let doc of managerviewDetails.documentType){
                i++;
                if(doc in doctype){
                    dNames += doctype[doc]
                    if(i <= managerviewDetails.documentType.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }
        return (<div>
            <RegModalContainer>
            <RegModalHeaderText>{managerviewDetails.ruName}</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "30px"}}/>
             <RegDismisOuterContainer>
                <OverviewInfoBody style={{marginBottom: "10px"}}>
                    <div style={{marginBottom: "30px",display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {dNames}</div>
                    <div style={{marginBottom: "30px",display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Regulator </span>&nbsp; {managerviewDetails.regulatorName}</div>
                    <div style={{float: "right",marginBottom: "30px",display: "inline-block"}} onClick={this.viewrcmDetails}><span style={{fontWeight: "bold", color: "#57C3C1",textDecoration: "underline",cursor: "pointer"}}>View RU</span></div>
                    <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(managerviewDetails.summary))}} style={{textAlign: "justify"}}></div>
                </OverviewInfoBody>
            </RegDismisOuterContainer>

            <hr style={{borderTop: "0px solid #ccc"}}/>
            {
                (() => {
                    let shareUserInfo = 0;
                    if(Object.keys(managerviewDetails.shareInfo).length !== 0) {
                        shareUserInfo = Object.keys(managerviewDetails.shareInfo).length
                            return(
                                <div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Information ( {shareUserInfo} )</div>
                                    <div style={{float: "right"}}><MRButton style={{cursor:"pointer",border: "1px solid #282828"}} onClick={this.resendToAll('info')}>Resend to All</MRButton></div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                <CSLTable add={false} processData={this.processMyShareInformation} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                                <RegCancelSubmitButtonOuterContainer>
                                <RegSubmitButton onClick={this.exportToCSVShareInfo}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>
                            </RegDismisOuterContainer>
                            </div>)
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Information ( {shareUserInfo} )</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#c4c4c4"}}>There are currently no Information Shared</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()
            }
            {
                (() => {
                    let shareUserAcknowledge = 0;
                    if(Object.keys(managerviewDetails.shareAcknow).length !== 0) {
                        shareUserAcknowledge = Object.keys(managerviewDetails.shareAcknow).length
                            return(
                            <div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Action ( {shareUserAcknowledge} )</div>
                                    <div style={{float: "right"}}><MRButton style={{cursor:"pointer",border: "1px solid #282828"}} onClick={this.resendToAll('ackn')}>Resend to All</MRButton></div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                <CSLTable add={false} processData={this.processMyShareAcknowledgement} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                                <RegCancelSubmitButtonOuterContainer>
                                <RegSubmitButton onClick={this.exportToCSVShareAcknow}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>
                            </RegDismisOuterContainer>
                            </div>)
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Action ( {shareUserAcknowledge} )</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#c4c4c4"}}>There are currently no Action Shared</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()
            }
            <RegDismisOuterContainer>
            <RegDismisInnerContainer>
                <RegisterChildTasksRUTable add={false} headertext = "Follow up Actions" followup_tasks={thisTaskChildren} Outstanding={true}/>
            </RegDismisInnerContainer>
            </RegDismisOuterContainer>
            {
                (() => {
                    if(thisTaskChildren.length !== 0){
                        return(
                            <RegCancelSubmitButtonOuterContainer>
                                <RegSubmitButton onClick={this.exportToCSVFollowupAction}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>
                        )
                    }
                })()
            }
            <RegCancelSubmitButtonOuterContainer>
                <RegCancelButton onClick={this.closeRegulatoryModal}>Close</RegCancelButton>
            </RegCancelSubmitButtonOuterContainer>
            {
                (() => {
                    if(this.state.viewModal !== null) {
                        switch(this.state.viewModal) {
                            case 'rcmDetails' : return <ViewManagerRU refreshstate={this.refreshstate} closeModal={this.closeModal}/>; break;
                            case 'shareInfoAndAction' : return <ShareInfoAndActionModal refreshstate={this.refreshstate} emailLogData={this.state.emailLogData} closeModal={this.closeModal}/>; break;
                        }
                    }
                })()
            }
        </RegModalContainer>
        <AlertBox alertParam = {this.state.alert_param}/>
        </div>);
    }
}

export default RegisterManagerViewModal;
