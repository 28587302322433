import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";
import Store from '../../../Common/Store';
import CSLDivLoader from './CSLDivLoader'

import "./table_styles.css";

const HeaderText = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;
const QaAssignBtn = styled.button`
    background-color: #002060;
    color: #ffffff;
    padding: 10px 46px;
    border: 1px solid #002060;
    border-radius: 5px;
    float: right;
    margin: 15px;
    cursor: pointer;
`
const TopButton = styled.button`
    background-color: #002060;
    color: #ffffff;
    padding: 10px 46px;
    border: 1px solid #002060;
    border-radius: 5px;
    float: left;
    margin: 15px;
    cursor: pointer;
`

const TopText = styled.div`
    color: #282c34;
    float: left;
    margin: 15px;
    cursor: pointer;
`

const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
    float: left;
    margin-right: 5px;
`;

const TableContainer = styled.div`
    background-color: #ffffff;
`

class CSLTable extends React.Component
{
    state = {button_set: {}, button_set_top: {},text_set:{}, text_status:[], view_id:'task_view',grouping:{}, ready: false}
    constructor(props) {
        super(props);
        // // console.log("props", props);
    }

    componentDidMount = () => {
        this.setComponentState()
    }



    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return

        this.setComponentState()
    }


    setComponentState = () => {

        // let text_status = this.state.text_status;
        let view_id = this.state.view_id;

        let x = []

        for(let key in this.props.textView){
            if(this.props.textView[key].is_active) view_id = key
            // text_status[key] = {'display': this.props.textView[key].display, is_active: this.props.textView[key].is_active}
            if(!this.props.textView[key].is_active) x.push({id: key, stack: this.props.textView[key].stack, display: this.props.textView[key].display})
        }

        // console.log("text_status ===>", text_status)


        this.setState({button_set: this.props.buttonSet,button_set_top: this.props.buttonSetTop,text_set:this.props.textView,text_status: x,view_id, grouping:this.props.grouping,  ready: true})
    }
    componentDidMount() {

        let text_status = this.state.text_status;
        for(let key in this.props.textView){
            text_status[key] = {'display': this.props.textView[key].display, is_active: this.props.textView[key].is_active}
        }

        console.log("text_status ===>", text_status)


        this.setState({button_set: this.props.buttonSet,button_set_top: this.props.buttonSetTop,text_set:this.props.textView,text_status: text_status, grouping:this.props.grouping, ready: true})
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
        let fileName = "export";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();

        let headerCSV = this.props.export_columns;
        console.log("headerCSV",headerCSV)
        let data= tableData.data;
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
            let cont = {};

            Object.entries(headerCSV).forEach(([key, value]) => {
                // console.log("data[k][key]",data[k][key])
                    // cont[key] = data[k][key].replace(/,/g, ' ');
                    let d = "N/A";
                    if(typeof(data[k][key]) !== 'undefined' && data[k][key] !== null){
                        d = data[k][key]
                    }
                    cont[key] = d.toString().replace(/,/g, ' ').replace(/-/g, '-').replace(/&rsquo/g, '').replace( /[\r\n]+/gm, "" ).replace( /#/g, "" );
            })            
            csv_data_arr.push(cont);
        }
        // console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        // console.log("csv_data_final_arr",csv_data_final_arr)
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();
    
    }

    goToReport = () => (event) => {
        event.preventDefault();
        this.props.goToReport();
    }

    openButtonCallback = (button_id) => {
        this.state.button_set[button_id].button_callback(this.state.button_set[button_id].stack)
    }

    openTopButtonCallback = (button_id) => {
        this.state.button_set_top[button_id].button_callback(this.state.button_set_top[button_id].stack)
    }

    openTopTextCallback = (button_id) => {
        console.log("button_id", this.state.text_set[button_id])
        this.state.text_set[button_id].button_callback(this.state.text_set[button_id].stack)
    }


	render() {

        if(!this.state.ready)return <div></div>
        console.log('this.props', this.state.text_status);
		let tableData = this.props.processData();
		console.log('tableData', tableData);
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
        // if(tableData.data.length === 0)return <CSLDivLoader />
		return (
			<TableContainer>
                {
                    (() => {
                        if ("show_header" in this.props && this.props.show_header === false) {
                            return (<div></div>);
                        } else {
                            return (<HeaderText>{this.props.headerText}</HeaderText>);
                        }
                    })()
                }                    
                {
                    Object.keys(this.state.button_set_top).map((b, i) => {
                            if(this.state.button_set_top[b].is_visible)return (<TopButton key={i} onClick={() => this.openTopButtonCallback(b)}>{this.state.button_set_top[b].display}</TopButton>);
                        })
                }

                {
                    (() => {
                        if(this.state.text_set!==null){
                            return(<div style={{float:"right"}}>
                    <TopText>
                        <div style={{display:"flex"}}>
                        <b>{this.state.text_set[this.state.view_id]?.display+"."}</b>  &nbsp;
                        {
                            (() => {
                                if(this.state.grouping){
                                    return this.state.grouping['slogan'];
                                }else{
                                    return "Switch to"
                                }

                            })()
                        }
                        {
                            (() => {
                                if(this.state.grouping){
                                    let x = this.state.text_status.find(x => x.id === this.state.grouping['group_by']);

                                    console.log("x==>", x)

                                    return(<div><div style={{paddingLeft:"5px", color: "#00b0f0", fontWeight: "800"}} onClick={() => this.openTopTextCallback(x.id)}><b>Return to {x.display}</b> </div></div>)
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.text_status.length === 1) return(<div><div style={{paddingLeft:"5px", color: "#00b0f0", fontWeight: "800"}} onClick={() => this.openTopTextCallback(this.state.text_status[0].id)}><b>{this.state.text_status[0].display}</b> </div></div>)
                                if(this.state.text_status.length === 2) return(<div style={{display:"contents"}}><div style={{paddingLeft:"5px", color: "#00b0f0", fontWeight: "800"}} onClick={() => this.openTopTextCallback(this.state.text_status[0].id)}><b>{this.state.text_status[0].display}</b> </div>  &nbsp;or <div style={{paddingLeft:"5px",color: "#00b0f0", fontWeight: "800"}} onClick={() => this.openTopTextCallback(this.state.text_status[1].id)}>{this.state.text_status[1].display} </div></div>)
                            })()
                        }

                        </div>
                    </TopText>

                </div>)
                        }
                    })()
                }





                    <div style={{clear: 'both'}} />                          
                    <div style={{marginTop: '5px', width:'100%'}}>
	                    <ReactTable
	                    	PaginationComponent={Pagination}
						    data={tableData.data}
						    columns={tableData.columns}
						    defaultPageSize={parseInt(row_count)}
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id
                                // // console.log('id',id);
                                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                            }}
						/>
                        {
                            (() => {
                                if ('export' in this.props && this.props.export === true && tableData.data.length > 0) {                            
                                    return (<ExportButton  onClick={this.exportToCSV()}>Export</ExportButton>);                                                        
                                }
                            })()
                        }
                        {
                            (() => {
                                if ('report' in this.props && this.props.report === true && tableData.data.length > 0) {                            
                                    return (<ExportButton style={{width: 250}}  onClick={this.goToReport()}>Run Report of Current View</ExportButton>);                                                        
                                }
                            })()
                        }
                        <div style={{clear: "both"}}></div>
					</div>
			</TableContainer>
			);
	}
}

export default CSLTable;