import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import BMViewFollowupModal from './BMViewFollowupModal';
import BMViewRemedialModal from './BMViewRemedialModal';
import BMViewPreventativeModal from './BMViewPreventativeModal';
import { FaTimes} from 'react-icons/fa';
import CSLLoader from '../Common/CSLLoader';
import { MdModeEdit,MdDelete } from "react-icons/md";
import BMAddFollowupTask from './BMAddFollowupTask';
import AlertBox from '../Common/AlertBox';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -52px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;
    padding-left: 5px;
    display:inline;
`;
const ModalNameLabelDivReference1 = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #212121;
    display:inline;
`;

const FilterBody = styled.div`
	background-color: #ffffff;
	
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;

const OverviewInfoHeader = styled.div`
    padding: 10px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;  
    border-top: 1px solid #d7dddf;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;  
`;

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class CurrentChildTaskListsModal extends React.Component
{
    
    state = {showModal: null,
        followupTasksList: [],
        remedialTasksList: [],
        preventativeTasksList: [],
        ready: false,
        isLoadedIcon: false,
        showFollowupModal: false,        
        review_list: [],
        taskid: 0,
        alert_param: null,
        ischangesFollowup: false,
    };

	componentDidMount()
	{
        let followupTasksList = []
        let remedialTasksList = []
        let preventativeTasksList = []
        let all_childTask = Store.getStoreData('current_childtask_lists');
        let parentCompanyID = 0;
        let taskid = 0
        all_childTask.forEach((item) => {	
            parentCompanyID = item.company_id;
            taskid = item.parent_task_id;
            if(item.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                followupTasksList.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                remedialTasksList.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                preventativeTasksList.push(item)
            }
        });        

        let cur_gc_id = parseInt(parentCompanyID) < 100000 ? parseInt(parentCompanyID) : parseInt(parentCompanyID)%100000;        
        let company_contacts = Store.getStoreData('contacts'); 
        let gc_company = Store.getStoreData('gc_company');
        let gc_users = [];
        let my_company = null;            
        for(let gc of gc_company) {
            if(gc.id === cur_gc_id)my_company = gc;
        }
        //console.log('review_list', my_company.users, "cur_gc_id==>", cur_gc_id)
        let com_roles = Store.getStoreData('userroles');
        if(Store.getStoreData('has_gc') === true){
            for(let id of Object.keys(my_company.users)) {
                if (com_roles[id.toString()] !== 'no_access' && id in company_contacts) {
                    gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
                }
            }
        }else{
            for(let k in company_contacts){
                gc_users.push({ID: k, ContactName: company_contacts[k.toString()]});
            }
        }
        console.log('gc_users', gc_users)


        this.setState({followupTasksList: followupTasksList,
            remedialTasksList: remedialTasksList,
            preventativeTasksList: preventativeTasksList,
            review_list: gc_users,
            taskid,
            ready: true})
	}
	
    closeModal = (event) => {
        event.preventDefault();
        if(this.state.ischangesFollowup){
            this.props.closeModalandRefresh();
        }else{
            this.props.closeModal();
        }        
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({showModal: null})
    }   

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
    }

    showRemedial = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openRemedialModal);
    }

    openRemedialModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_remedial_modal', isLoadedIcon: false});
    }

    showPreventative = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openpreventativeModal);
    }

    openpreventativeModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_preventative_modal', isLoadedIcon: false});
    }

    processMyFollowupTaskValue = () => {
        var taskdata = this.state.followupTasksList;
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Task', accessor: 'task', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},                         
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        // {'Header' : 'Action', Cell: row => (
                        //     <div>
                        //         <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showFollowUp(row.original.id)}>View</MRButton>
                        //     </div>
                        //  ), minWidth: 120, headerStyle:{textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}}
                         {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showFollowUp(row.original.id)}>View</MRButton>  
                                {
                                    (() => {                                        
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><MdModeEdit /></div>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteChildTask(row.original.id, 'followup')}><MdDelete /></div>
                                            </div>)         
                                        }                               
                                    })()
                                } 
                            </div>
                         ), width: 180, headerStyle: {textAlign: 'left'}} 
                    ];
        for(let d of taskdata) {           
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            //console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );
            
            let today = new Date();
            //console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';  
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }                   
            let elem = {
                'id' : d.id,
                'index' : i, 
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,                
                'status' : {text: text, color: color},
                'status_value': text
               };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteChildTask = (taskid, childtaskName) => (event) => {
        event.preventDefault();  
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteConfirmChildTask, stack: {taskid: taskid, childtaskName: childtaskName}}
        this.setState({alert_param: alert_param})
    }
    
    deleteConfirmChildTask = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }               
        let postData = {command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData",postData)  
        this.setState({isLoadedIcon: true})          
        let api = new APICall();
        api.command(postData, this.processRemoveFollowupData(stack.childtaskName));               
    }

    processRemoveFollowupData = (childtaskName) => (result) => {        
        this.setState({alert_param: null, ischangesFollowup: true})
        let message = '';
        if(childtaskName === 'followup'){
            message = 'Follow-up task deleted successfully'; 
        }else if(childtaskName === 'remedial'){
            message = 'Remedial Action deleted successfully'; 
        }else if(childtaskName === 'preventative'){
            message = 'Preventative Measures deleted successfully';
        }else{
            message = 'Record deleted successfully';
        }
        let alert_param = {title: 'Success', message: message, ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})        
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_single_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editFollowupModal);
    }

    editFollowupModal = (result) => {
        //console.log("followup_task",result.result.task);
        Store.updateStore('ftask_index', 0);        
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showFollowupModal: true}) 
    }

    processMyRemedialTaskValue = () => {
        var taskdata = this.state.remedialTasksList;
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Task', accessor: 'task', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},                         
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        // {'Header' : 'Action', Cell: row => (
                        //     <div>
                        //         <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showRemedial(row.original.id)}>View</MRButton>
                        //     </div>
                        //  ), minWidth: 120, headerStyle:{textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}}
                         {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showRemedial(row.original.id)}>View</MRButton>  
                                {
                                    (() => {                                        
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editRemedialTask(row.original.id)}><MdModeEdit /></div>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteChildTask(row.original.id, 'remedial')}><MdDelete /></div>
                                            </div>)         
                                        }                               
                                    })()
                                } 
                            </div>
                         ), width: 180, headerStyle: {textAlign: 'left'}} 
                    ];
        for(let d of taskdata) {           
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_REMEDIAL_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            //console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );
            
            let today = new Date();
            //console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';  
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }                   
            let elem = {
                'id' : d.id,
                'index' : i, 
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,                
                'status' : {text: text, color: color},
                'status_value': text
               };
            ret.data.push(elem);
        }
        return ret;
    }    

    editRemedialTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_single_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editRemedialModal);
    }

    editRemedialModal = (result) => {
        //console.log("followup_task",result.result.task);
        Store.updateStore('ftask_index', 0);        
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showRemedialModal: true}) 
    }

    processMyPreventativeTaskValue = () => {
        var taskdata = this.state.preventativeTasksList;
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Task', accessor: 'task', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},                         
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        // {'Header' : 'Action', Cell: row => (
                        //     <div>
                        //         <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showPreventative(row.original.id)}>View</MRButton>
                        //     </div>
                        //  ), minWidth: 120, headerStyle:{textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}}
                         {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showPreventative(row.original.id)}>View</MRButton>  
                                {
                                    (() => {                                        
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editPreventativeTask(row.original.id)}><MdModeEdit /></div>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteChildTask(row.original.id, 'preventative')}><MdDelete /></div>
                                            </div>)         
                                        }                               
                                    })()
                                } 
                            </div>
                         ), width: 180, headerStyle: {textAlign: 'left'}}
                    ];
        for(let d of taskdata) {           
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_PREVENTATIVE_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            //console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );
            
            let today = new Date();
            //console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';  
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }                   
            let elem = {
                'id' : d.id,
                'index' : i, 
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,                
                'status' : {text: text, color: color},
                'status_value': text
               };
            ret.data.push(elem);
        }
        return ret;
    }
    
    editPreventativeTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_single_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editPreventativeModal);
    }

    editPreventativeModal = (result) => {
        //console.log("followup_task",result.result.task);
        Store.updateStore('ftask_index', 0);        
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showPreventativeModal: true}) 
    }
    
    closePreventativeModal = () => {                   
        this.setState({ showPreventativeModal: false});
    }

    closeRemedialModal = () => {                   
        this.setState({ showRemedialModal: false});
    }

    closeFollowupModal = () => {                   
        this.setState({ showFollowupModal: false});
    }  
    
    EditremedialTaskValueFetch = (taskValue) => {
        //console.log(taskValue)        
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);  
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;        
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_BREACH_REMEDIAL_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_REMEDIAL_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData); 
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.processEditRemedialTask);   
    }

    processEditRemedialTask = (result) => {
        let alert_param = {title: 'Success', message: 'The Remedial Action has been updated successfully.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})  
    }
    
    EditpreventativeTaskValueFetch = (taskValue) => {
        //console.log(taskValue)        
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);  
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;        
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_BREACH_PREVENTATIVE_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_PREVENTATIVE_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData); 
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.processEditPreventativeTask);   
    }

    processEditPreventativeTask = (result) => {
        let alert_param = {title: 'Success', message: 'The Preventative Measures has been updated successfully.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})        
        //alert("The follow-up task has been updated successfully.");  
    }

    EditfollowupTaskValueFetch = (taskValue) => {
        //console.log(taskValue)        
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);  
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;        
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_BREACH_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData); 
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);   
    }

    processEditFollowupTask = (result) => {
        let alert_param = {title: 'Success', message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})        
        //alert("The follow-up task has been updated successfully.");  
    }

    updateprocessallchildtask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(command_data, this.updateallChildTask); 
    }

    updateallChildTask = (result) => {
        Store.updateStore('current_childtask_lists', result.result.followuptasks);
        console.log('result.result.current_child_lists', result);  
        let followupTasksList = []
        let remedialTasksList = []
        let preventativeTasksList = []
        let all_childTask = result.result.followuptasks;        
        all_childTask.forEach((item) => {	            
            if(item.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                followupTasksList.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                remedialTasksList.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                preventativeTasksList.push(item)
            }
        });

        this.setState({ showFollowupModal: false,
            showRemedialModal: false, 
            showPreventativeModal: false, 
            followupTasksList: followupTasksList,
            remedialTasksList: remedialTasksList,
            preventativeTasksList: preventativeTasksList, isLoadedIcon: false });  
    }

    render()
    {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }        
        return(
            <div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer> 
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.curBreachid} - Follow-up Tasks</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>These are the current active follow-up tasks associated with this breach.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Remedial Actions</div>          
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "15px",marginLeft: "5px"}}>({this.state.remedialTasksList.length})</div>                                                              
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>    
                        {
                            (() => {
                                if(this.state.showRemedialModal){
                                    return(<BMAddFollowupTask followuptaskvalue={this.state.remedialTasksList} headerText={'remedial action task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeRemedialModal} EditrefreshTaskValue={this.EditremedialTaskValueFetch}/>)
                                }
                            })()
                        }                    
                        <OverviewInfoBody>
                        {
                            (() => {
                                if(this.state.remedialTasksList.length !== 0){
                                    return(<CSLTable add={false} processData={this.processMyRemedialTaskValue} headerText={''} tableRows="3"/>)
                                }else{
                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no remedial actions tasks assigned.</div>)
                                }
                            })()
                        }                                   
                        </OverviewInfoBody> 
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Preventative Measures</div>   
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "15px",marginLeft: "5px"}}>({this.state.preventativeTasksList.length})</div>                                                                      
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader> 
                        {
                            (() => {
                                if(this.state.showPreventativeModal){
                                    return(<BMAddFollowupTask followuptaskvalue={this.state.preventativeTasksList} headerText={'preventative measures task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closePreventativeModal} EditrefreshTaskValue={this.EditpreventativeTaskValueFetch}/>)
                                }
                            })()
                        }                       
                        <OverviewInfoBody>
                        {
                            (() => {
                                if(this.state.preventativeTasksList.length !== 0){
                                    return(<CSLTable add={false} processData={this.processMyPreventativeTaskValue} headerText={''} tableRows="3"/>)
                                }else{
                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no preventative measures tasks assigned.</div>)
                                }
                            })()
                        }                                   
                        </OverviewInfoBody>
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Follow-up Tasks</div>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "15px",marginLeft: "5px"}}>({this.state.followupTasksList.length})</div>
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader> 
                        {
                            (() => {                                
                                if(this.state.showFollowupModal){
                                    return(<BMAddFollowupTask followuptaskvalue={this.state.followupTasksList} headerText={'follow-up task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} EditrefreshTaskValue={this.EditfollowupTaskValueFetch}/>)
                                }                                
                            })()
                        }                       
                        <OverviewInfoBody>
                        {
                            (() => {
                                if(this.state.followupTasksList.length !== 0){
                                    return(<CSLTable add={false} processData={this.processMyFollowupTaskValue} headerText={''} tableRows="3"/>)
                                }else{
                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no follow-up tasks assigned.</div>)
                                }
                            })()
                        }                                   
                        </OverviewInfoBody> 
                        
                    </div>
                </MRModalBody>
                <MRModalFooter>   
                    <div style={{position: "relative",padding: "17px"}}>                                         
                        <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn> 
                    </div>                                  
                </MRModalFooter>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {                            
                            case 'view_followup_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewFollowupModal closeModal={this.closefollowupModal} /></div>; break;
                            case 'view_remedial_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewRemedialModal closeModal={this.closefollowupModal} /></div>; break;
                            case 'view_preventative_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewPreventativeModal closeModal={this.closefollowupModal} /></div>; break;
                        }
                    }
                })()
                } 
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>)
    }

}
export default CurrentChildTaskListsModal;
