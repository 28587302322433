		let data = {table_headers: [], data: []};
        data.module = {id: 'tmview', display: 'Task Manager'}
		data.table_headers = [
            {
                "accessor": "status", "nickname": "Status", type: 'alpha', width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "task", "nickname": "Task", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "company_name", "nickname": "Company", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "assigned_to_text", "nickname": "Assignment", type: 'alpha', width: 1.4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "created_by", "nickname": "User", type: 'alpha', width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "task_type", "nickname": "Source", type: 'alpha', width: 1.3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "due_date", "nickname": "Date", type: 'date', width: 1.3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "due_month", "nickname": "Month", type: 'alpha', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                "accessor": "flagged", "nickname": "Flagged", type: 'alpha', width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            }
        ];

        data.sortby = {
            fields: [
                {"accessor": "status", "nickname": "Status"},
                {"accessor": "task", "nickname": "Task"},
                {"accessor": "company_name", "nickname": "Company"},
                {"accessor": "assigned_to_text", "nickname": "Assigned To"},
                {"accessor": "created_by", "nickname": "Created By"},
                {"accessor": "task_type", "nickname": "Source"},
                {"accessor": "due_date", "nickname": "Date"},
                {"accessor": "due_month", "nickname": "Due Month"},
                {"accessor": "flagged", "nickname": "Flagged"}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {"accessor": "status", "nickname": "Status"},
                {"accessor": "task", "nickname": "Task"},
                {"accessor": "company_name", "nickname": "Company"},
                {"accessor": "assigned_to_text", "nickname": "Assigned To"},
                {"accessor": "created_by", "nickname": "Created By"},
                {"accessor": "task_type", "nickname": "Source"},
                {"accessor": "due_date", "nickname": "Date"},
                {"accessor": "due_month", "nickname": "Due Month"},
                {"accessor": "flagged", "nickname": "Flagged"}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data