import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import CSLHeader from '../CSLHeader';
import WelcomeBanner from '../WelcomeBanner';
import CSLTable from '../Common/CSLTable';
import AlertBox from '../Common/AlertBox';
import AdvancedFilter from '../Partials/AdvancedFilter';
import CSLLoader from '../../Components/Common/CSLLoader';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import RegisterHelper from './RegisterHelper.js'
import cookie from 'react-cookies';
import ShowemailModal from '../Task/ShowemailModal';
import ShowhintModal from '../Task/ShowhintModal';
import RegisterReporting from './CSLRegister/RegisterReporting';
import CompleteReporting from './CSLRegister/CompleteReporting';
import EmployeeReporting from './CSLRegister/EmployeeReporting';
import GcReporting from './CSLRegister/GcReporting';
import reportData from './reportData.js';
import reportempData from './reportempData.js';
import reportgcData from './reportgcData.js';
import filters from './reportFilters.js';
import empFilters from './empFilters.js';
import gcFilters from './gcFilters.js';
import complete_filters from './completeFilters.js';
import pill_filters from './pillFilters.js';
import complete_pill_filters from './completePill.js';
import CSLPageloader from '../Common/CSLPageloader';
import TasksHOC from '../Tasks/TasksHOC';
import ComDecom from '../../Common/ComDecom.js';
import FilterTasks from '../../Common/filterTasks.js';
import Reload from '../../Common/Reload.js';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const TeamList = styled.div`
  padding-top: 5px;
  padding-left: 15px;
`
const TopList = styled.div`
  padding-top: 45px;
  padding-left: 15px;
`

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor: pointer;
`;

const MRButtond = styled.button`
    box-sizing: border-box;
    background-color: #e6e6e6;
    color: #ffffff;
    border: 1px solid #e6e6e6;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;

const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 130px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
`;

 const CheckBox = props => (
  <input type="checkbox" />
);

const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;
  margin-right:15px;
  opacity: 1 ; 
`;

const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  margin-right:15px;
  opacity: 1 ; 
`;

const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;
  margin-right:15px;
  opacity: 1 ; 
`;

const RadioBlock = styled.div`
    padding: 5px;
`;
const SelectedRadio = styled.div`
    background-color: #37ADA7;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;

const HeaderTextRight = styled.div`
    color: #213e61;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 7px;
`;

const months = {
                  '01': 'January',
                  '02': 'February',
                  '03': 'March',
                  '04': 'April',
                  '05': 'May',
                  '06': 'June',
                  '07': 'July',
                  '08': 'August',
                  '09': 'September',
                  '10': 'October',
                  '11': 'November',
                  '12': 'December'
               }


const sourceMap = {filechecker: 'File Checker', car: 'Compliance Advice Register', rcm: 'Regulatory Change Management', conflicts:'Conflicts of Interest', cmp:'Compliance Monitoring Programme', tmview: 'Task Manager', attest: 'Attestations', committees: 'Boards and Committees', breach: 'Incident Register', gifts: 'Gifts and Hospitality', padreg: 'PA Dealings', finpromo: 'Financial Promotions', docmgmt: 'Document Library'}
const completeMap = {COMPLETE: 1, COMPLETED: 1,CONFIRMED: 1, DISMISSED : 1, REJECTED: 1, DISSMISS: 1, REJECT: 1}

class RegisterIndex extends React.Component {

    state = {
            view: 'register',
            prefered_columns: null,
            prefered_completed_columns: null,
            filters: filters,
            pill_filters: pill_filters,
            ready: false,
            alert_param: null,
            filter: {},
            show_modal: '',
            task_id: 0,
            hint_id: 0,
            hint_task_id: 0,
            due_date: '',
            show_complete: false,
            offset: 0,
            tasks: 0,
            is_default: false,
            module: null,
            task_type: null,
            ref_id: null,
            cur_lane: null,
            resend_loading: false,
            parent_task_id: 0,
            viewtable:{task:true,emp:false,gc: false, grouping: false},
            emp_view:{},
            gc_view:{},
            grouping:null
          }

    constructor(props) {
        super(props);
    }

    closeUserDialog = (is_reload) => {
        this.setState({task_id: 0, module: null});
        this.processDataRefresh();
    }


    componentDidMount(){
        this.processDataRefresh();
    }

    processDataRefresh = () => {
      let gc_filters = Store.getStoreData('gc_filters');
      let loggedincontact = Store.getStoreData('loggedincontact');
      let contact_id = loggedincontact.ID;
      let r = {};
      r['result'] = {};
      let user_roles = {};
      let role = {}
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles
        for(let ur in user_roles){
            if(contact_id in user_roles[ur])role[ur.toString()] = user_roles[ur][contact_id];

        }
      }
      let gcs = [];
      for(let g in gc_filters){
        gcs.push(parseInt(g))
      }
      let teams_users = Utils.getDirectReports();
      let reporters = Store.getStoreData('reporters');

      let gc_users = Store.getStoreData('gc_users');
      let direct_reports = [];
      let assign_users = [];
      if(reporters){
        for( let t in reporters) {
          direct_reports.push(parseInt(t));
        }
      }

      for(let gu in gc_users) {
        assign_users.push(parseInt(gu));
      }


      let api = new APICall();
      let postData = {};
      postData['command'] = 'get_all_tasks';
      postData['gcs'] = gcs;
      postData['roles'] = role;
      postData['direct_reports'] = direct_reports;
      postData['reporters'] = reporters;
      postData['gc_users'] = assign_users;
      console.log("postData report", postData)
      api.command(postData, this.processAlltasks)
    }

    alertHandler = (result, stack) => {
      // let url = Store.getStoreData('is_admin') ? "/ConsultantSummary.aspx" : "/select/ServiceBasedLanding";
      // window.location = url;
      Reload.ReloadPage();
    }

    processAlltasks = (result) => {
      console.log("refresh callback", result)
        // if('reporters' in result.result)Store.updateStore('reporters', result.result.reporters);
        if(result.error_code > 0){
            let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                       alertHandler: this.alertHandler, stack: {id: 0}}
            alert_param["message"] = result.error_msg;
            this.setState({ ready: true, alert_param: alert_param});
            return;
        }

        let inactive_user_list = Store.getStoreData('inactive_user_list');
        let unassigntasks = Store.getStoreData('unassigntasks');
        let user_login_hist = Store.getStoreData('user_login_hist');
        let enabled_csl_user_list = Store.getStoreData('enabled_csl_user_list');
        let flags = Store.getStoreData('flags');
        let tasks = ComDecom.deCompress(result.result['tasks'], true, 'task_id');
        // console.log("tasks register", tasks)
        Store.updateStore('raw_tasks', tasks);
        let task_result = FilterTasks.getFiltertasks(tasks, inactive_user_list, unassigntasks, user_login_hist,enabled_csl_user_list, flags)
        Store.updateStore('taskdata', task_result.rows);
        let filter = cookie.load('filter')
        filter = typeof filter !== 'undefined' ? filter : this.state.filter;
        let prefered_columns = RegisterHelper.preferedColumns()
        let prefered_completed_columns = RegisterHelper.preferedCompleteColumns()
        this.setState({ filter: filter, prefered_columns:prefered_columns,prefered_completed_columns:prefered_completed_columns, ready: true});
    }

    getTaskDescriptioh = (description) => {
        let task_description = '';
        if(Utils.isBase64(description)){
          task_description = window.decodeURIComponent(atob(description))
        }
      return task_description.replace( /(<([^>]+)>)/ig, '');
    }


    refreshState = (f) => {
      let filter = cookie.load('filter')
      filter = typeof filter !== 'undefined' ? filter : this.state.filter;
      if(filter[f]){
        delete filter[f]
      }else{
        filter[f] = 1;
      }
      cookie.save('filter', filter)
      // console.log("filter", filter)
      this.setState({view_count: this.state.view_count + 1})
    }

    getStatus = (row) => {
      let lane_parts = row.cur_lane.split('_')
      let lane_end = lane_parts[lane_parts.length - 1]
      let status = row.cur_lane === "COMP_GENERIC_APPROVAL_PENDING" ? 'Approval required' : 'Pending'
      let due_date = new Date(row.due_date);
      let cur_date = new Date();
      let cur_date_int = cur_date.getFullYear()*10000 + cur_date.getMonth()*100 + cur_date.getDate();
      let due_date_int = due_date.getFullYear()*10000 + due_date.getMonth()*100 + due_date.getDate();
      if(due_date_int < cur_date_int)status = 'Overdue'

      return status;
    }

    getDate = (input_date) => {
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        if(dateStr === "1970-01-01"){
            return '';
        }
        let dateParts = dateStr.split('-');
        let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
}

    getDuecalDate = (input_date) => {
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        if(dateStr === "1970-01-01"){
            return '';
        }
        let dateParts = dateStr.split('-');
        let ret = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
    }

    getInclusion = (filter) => {
      let count = 0;
      let keys = Object.keys(filter)
      for(let i=0; i < keys.length; i++) {
        count += filter[keys[i]] * this.state.filter[keys[i]]
      }
      return (count > 0)
    }

    processUpdateFlags = (result) => {
      console.log('result from update_flags', result)
      let taskdata = Store.getStoreData('temp_taskdata')
      let flagged_count = Store.getStoreData('temp_flagged_count')
      let focused_count = Store.getStoreData('temp_focused_count')
      Store.updateStore('flagged_count', flagged_count)
      Store.updateStore('focused_count', focused_count)
      Store.updateStore('taskdata',taskdata)
      this.refreshState()
    }

    flagTask = (index) => (event) => {
      event.preventDefault()
      let flagValue = event.target.checked ? 1 : 0;
      let task = Store.getStoreData('current_data')[index];
      let taskData = Store.getStoreData('taskdata');
      taskData[task.id].filter.Flagged = flagValue;
      taskData[task.id].filter.Focused = flagValue;
      let flagged_count = 0
      let focused_count = 0
      let keys = Object.keys(taskData)
      let flags = {}
      for(let i=0; i < keys.length; i++) {
        if(taskData[keys[i]].filter.Flagged === 1) {
          flagged_count++
          flags[keys[i]] = 1
        }
        if(taskData[keys[i]].filter.Focused === 1)focused_count++
      }
      Store.updateStore('temp_flagged_count', flagged_count)
      Store.updateStore('temp_focused_count', focused_count)
      Store.updateStore('temp_taskdata',taskData)
      let postData = {command: 'update_flags', flags: flags}
      let api = new APICall();
      api.command(postData, this.processUpdateFlags)
    }

    reSendMail = (task_id) => (event) => {
      let alert_param = {title: 'Warning', message: 'Are you sure you want to resend this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                         alertHandler: this.resendMailProcessor, stack: {task_id: task_id}}
      this.setState({alert_param: alert_param})
    }

  resendMailProcessor = (result, stack) => {
      if(!result || stack.prevent) {
          this.setState({alert_param: null})
          return
      }
      let task_id = stack.task_id;
      let postData = {command: "resend_mail", task_id: task_id};
      let api = new APICall();
      api.command(postData, this.reloadTask);
      this.setState({alert_param: null,resend_loading: true})
    }

    reloadTask = (result) => {
      if(result.error_code === 0) {
        let sent_mail = Store.getStoreData('sent_mail');
        //let sent_number = sent_mail[parseInt(result.result.task_id)];
        let sent_number = (result.result.task_id in sent_mail) ? sent_mail[parseInt(result.result.task_id)] : 0;
        sent_mail[parseInt(result.result.task_id)] = sent_number + 1;
        Store.updateStore('sent_mail', sent_mail);
        let alert_param = {title: 'Success', message: 'The task has been re-sent.', ok_text: 'Ok', confirm: false,
                              alertHandler: this.alertreloadTaskHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, resend_loading: false})
      }else{
        let alert_param = {title: 'Warning', message: result.error_msg, ok_text: 'Ok', confirm: false,
                              alertHandler: this.alertreloadTaskHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, resend_loading: false})
      }


    }

    alertreloadTaskHandler = (result, stack) => {
      this.setState({alert_param: null})
      this.refreshState()
    }

    showEmails = (count,task_id) => (event) =>{
      // event.preventDefault()
      this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'emails_modal', task_id: task_id,email_count: count});
    }

    closeModal = (is_reload=null) => {
      // alert("id", this.state.task_id)
        if(is_reload===null){
            let inactive_user_list = Store.getStoreData('inactive_user_list');
            let unassigntasks = Store.getStoreData('unassigntasks');
            let user_login_hist = Store.getStoreData('user_login_hist');
            let enabled_csl_user_list = Store.getStoreData('enabled_csl_user_list');
            let flags = Store.getStoreData('flags');
            // console.log("tasks register", tasks)
            let tasks = Store.getStoreData('raw_tasks');
            let task_result = FilterTasks.getFiltertasks(tasks, inactive_user_list, unassigntasks, user_login_hist,enabled_csl_user_list, flags)
            Store.updateStore('taskdata', task_result.rows);
            this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: '', ready: true, cur_lane: null});
        }else{
          this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: '', cur_lane: null});
          this.processDataRefresh();
        }

    }

    getStatusHeader = () => {
        return {'Header' : 'Status', 'accessor': 'status', Cell: row => (
                         <div>
                         {
                          (() => {
                            switch(row.original.status) {
                              case 'Overdue': return  <div><Overdue><span style={{paddingLeft: "15px"}}></span></Overdue>{row.original.status}</div>; break;
                              case 'Complete': return  <div><Complete><span style={{paddingLeft: "15px"}}></span></Complete>{row.original.status}</div>; break;
                              default: return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>
                            }
                          })()
                         }

                         </div>

                        ), width: 120, headerStyle: {textAlign: 'left'}}

    }

    getTaskHeader = () => {
      return {Header: 'Task', accessor: 'task', width: 450, headerStyle: {textAlign: 'left'}}
    }

    getCompanyHeader = () => {
      return  {Header: 'Company', accessor: 'company_name', minWidth: 120, headerStyle: {textAlign: 'left'}}
    }

    getAssignedToHeader = () => {
      return {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{fontWeight: `${cellInfo.value.weight}`}}>{cellInfo.value.text}</div>),sortMethod: (a, b) => {
                          if (a === b) {
                            return 0;
                          }
                          return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;

                        },
                        filterMethod: (filter, row) => {
                          return row[filter.id].text !== undefined ? String(row[filter.id].text).toLowerCase().includes(filter.value.toLowerCase()) : true
                          }
                        }
    }
    getCreatedByHeader = () => {
      return {Header: 'Created by', accessor: 'created_by', minWidth: 120, headerStyle: {textAlign: 'left'}}
    }

    getSourceHeader = () => {
      return {Header: 'Source', accessor: 'task_type', minWidth: 120, headerStyle: {textAlign: 'left'}}
    }

    getDateHeader = () => {
      return {Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                          if (a === b) {
                            return 0;
                          }
                          let aReverse = 0;
                          let bReverse = 0;
                          let adue = a.split("/");
                          aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                          let  bbdue = b.split("/");
                          bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                          return aReverse > bReverse ? 1 : -1;


                        }}
    }

    getCompletedDateHeader = () => {
      return {Header: 'Completed Date', accessor: 'completed_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                          if (a === b) {
                            return 0;
                          }
                          let aReverse = 0;
                          let bReverse = 0;
                          let adue = a.split("/");
                          aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                          let  bbdue = b.split("/");
                          bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                          return aReverse > bReverse ? 1 : -1;


                        }}
    }

    getFlaggedHeader = () => {
      return {'Header' : 'Flagged', Cell: row => (
                         <div>
                            <input type="checkbox" checked={row.original.flagged} onChange={this.flagTask(row.index)} disabled={row.original.is_hint} />
                         </div>
                        ), minWidth: 80, headerStyle: {textAlign: 'left'}}
    }

    getReSentHeader = () => {
      return {Header: 'Re-sent', accessor: 'resent', minWidth: 100, headerStyle: {textAlign: 'left'},
                          Cell: cellInfo => (
                            <div>
                                  {

                                    (() => {
                                      if(Store.getStoreData('role') ==="admin_manager" && parseInt(cellInfo.value) > 0) {
                                        return <div style={{cursor: "pointer", color: "#0000EE"}} onClick={this.showEmails(cellInfo.value, cellInfo.original.id)}>{cellInfo.value}</div>
                                      }else{
                                        return <div>{cellInfo.value}</div>
                                      }
                                    })()
                                  }
                            </div>
                            )
                          }
    }

    getActionHeader = () => {
      return {'Header' : 'Action', Cell: row => (
                         <div>
                              <MRButton onClick={this.goToTaskUrl(row.original.id, row.original.module_name, row.original.is_hint, row.original.cur_lane, row.original.parent_task_id, row.original.ref_id, row.original.company_id)}>View</MRButton>
                              {
                                (() => {
                                  if(Store.getStoreData('role') !=="team") {

                                    if(row.original.is_hint) {
                                      return <MRButtond>Resend</MRButtond>
                                    }else{
                                      return <MRButton onClick={this.reSendMail(row.original.id)}>Resend</MRButton>
                                    }
                                  }

                                })()
                              }
                         </div>
                        ), width: 220, headerStyle: {textAlign: 'left'}}


    }

    getActionHeadercomplete = () => {
      return {'Header' : 'Action', Cell: row => (
                         <div>
                              <MRButton onClick={this.goToCompleteTaskUrl(row.original.id, row.original.module_name, row.original.is_hint, row.original.raw_task_type, row.original.ref_id, row.original.cur_lane, row.original.parent_task_id, row.original.company_id)}>View</MRButton>
                              {
                                (() => {
                                  if(Store.getStoreData('role') !=="team") {

                                    if(row.original.is_hint) {
                                      return <MRButtond>Resend</MRButtond>
                                    }else{
                                      return <MRButton onClick={this.reSendMail(row.original.id)}>Resend</MRButton>
                                    }
                                  }

                                })()
                              }
                         </div>
                        ), width: 220, headerStyle: {textAlign: 'left'}}
    }

    processAcceptanceQueueData = (Data) => {
        let sent_mail = Store.getStoreData('sent_mail');
        let taskData = Store.getStoreData('taskdata') === null ? [] : Store.getStoreData('taskdata');
        let contacts = Store.getStoreData('contacts');
        let contact = Store.getStoreData('loggedincontact');
        var task_arr = []
        var filter_arr = []
        for(var k = 0 ; k < Object.keys(taskData).length; k++)
        {
            task_arr.push(taskData[Object.keys(taskData)[k]].row)
            filter_arr.push(taskData[Object.keys(taskData)[k]].filter)
        }
        let ret = {data: [], columns: [], raw_data: [], row_filters: {}};

        let has_gc = Store.getStoreData('has_gc')

        ret.columns.push(this.getStatusHeader())
        ret.columns.push(this.getTaskHeader())
        if(has_gc)ret.columns.push(this.getCompanyHeader())
        ret.columns.push(this.getAssignedToHeader())
        ret.columns.push(this.getCreatedByHeader())
        ret.columns.push(this.getSourceHeader())
        ret.columns.push(this.getDateHeader())
        ret.columns.push(this.getFlaggedHeader())
        ret.columns.push(this.getReSentHeader())
        ret.columns.push(this.getActionHeader())


        for(var j = 0 ; j< task_arr.length ; j++)
        {
          let status = this.getStatus(task_arr[j])
          let assigned_to = task_arr[j].cur_assigned_to === 0 ? 'Unassigned' : parseInt(task_arr[j].cur_assigned_to) === parseInt(contact.contact_id) ? 'Me' : contacts[task_arr[j].cur_assigned_to.toString()]
          let  csv_assign_to = task_arr[j].cur_assigned_to === 0 ? 'Unassigned' :  contacts[task_arr[j].cur_assigned_to.toString()]
          let created_by = task_arr[j].contact_id === 0 ? '-' : parseInt(task_arr[j].contact_id) === parseInt(contact.contact_id) ? 'Me' : contacts[task_arr[j].contact_id.toString()]
          let csv_created_by = task_arr[j].contact_id === 0 ? '-' : contacts[task_arr[j].contact_id.toString()]
          let date_shown = this.getDate(task_arr[j].due_date);
          let created_date = this.getDate(task_arr[j].created_at);

          let due_dt = this.getDuecalDate(task_arr[j].due_date);


          let CompanyName = '';
          let gc_assoc = Store.getStoreData('gc_assoc')
          console.log("gc_assoc", gc_assoc)
            if(parseInt(task_arr[j].company_id) < 100000){
              CompanyName = Store.getStoreData('parrent_company')
            }else{
              // CompanyName =Store.getStoreData('gc_companies')[task_arr[j].company_id%100000]
              CompanyName = gc_assoc[task_arr[j].company_id].is_active === 0 ? gc_assoc[task_arr[j].company_id].company_name +" (Inactive)" : gc_assoc[task_arr[j].company_id].company_name
            }

          let weight = assigned_to === 'Unassigned' ? 600 : 100;
          let task_type = sourceMap[task_arr[j].module_id];
          let is_hint = false;
          if(task_arr[j].module_id === 'tmview'){
            let is_overdue = this.checkRecurringOverdue(task_arr[j]);
            task_type = !is_overdue ? 'Task (Recurring)' : 'Task (One Off)';
            is_hint = ('is_hint' in task_arr[j]) ? task_arr[j].is_hint : false;
          }

          let elem = {'index':j,
                      'status': status,
                      'task': task_arr[j].name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                      'description': this.getTaskDescriptioh(task_arr[j].description),
                      'assigned_to': {text: assigned_to, weight: weight},
                      'assigned_to_text': assigned_to,
                      'created_by': created_by,
                      'company_name':CompanyName,
                      'company_id' : task_arr[j].company_id,
                      'task_type': task_type,
                      'cur_lane' : task_arr[j].cur_lane,
                      'due_date' : date_shown,
                      'flagged' : filter_arr[j].Flagged === 1 ? true : false,
                      'module_id' :sourceMap[task_arr[j].module_id],
                      'csv_assign_to': csv_assign_to,
                      'csv_created_by': csv_created_by,
                      'csv_status': status,
                      'csv_created_date': created_date,
                      'csv_flagged': filter_arr[j].Flagged === 1 ? 'Y' : 'N',
                      'resent' : (task_arr[j].id in sent_mail) ? sent_mail[parseInt(task_arr[j].id)] : 0,
                      'id' : task_arr[j].id,
                      'parent_task_id' : ('parent_task_id' in task_arr[j]) ? task_arr[j].parent_task_id : 0,
                      'ref_id' : ('ref_id' in task_arr[j]) ? task_arr[j].ref_id : 0,
                      'is_hint' : is_hint,
                      'module_name': task_arr[j].module_id,
                      'days_overdue' : RegisterHelper.DayestoComplete(due_dt)
          };
          ret.data.push(elem);
          ret.raw_data.push(elem);
          ret.row_filters[task_arr[j].id] = taskData[task_arr[j].id].filter;
        }
        Store.updateStore('current_data', ret.data);
        return ret;
    }

    checkRecurringOverdue = (task) => {
      let is_recurring = ('is_recurring' in task) ? (task.is_recurring === null ? false: task.is_recurring) : false;
      if(is_recurring){
        let due_date = new Date(task.due_date);
        let cur_date = new Date();
        let cur_date_int = cur_date.getFullYear()*10000 + cur_date.getMonth()*100 + cur_date.getDate();
        let due_date_int = due_date.getFullYear()*10000 + due_date.getMonth()*100 + due_date.getDate();
        let is_overdue = due_date_int < cur_date_int ? true : false;
        return is_overdue
      }else{
        return true;
      }
    }

  showEmpgroupView = (flag, m, emp_id, gcs)=>(event) =>{
    let x = Store.getStoreData('taskdata')
    let y = {}
    for(let k in x) {
      let c = x[k].row.company_id.toString()
      if(gcs.hasOwnProperty(c) && parseInt(x[k].row.cur_assigned_to) === parseInt(emp_id) && flag === "Multiple"){
        y[k] = 1
      }

      if(x[k].filter[flag] === 1 && parseInt(x[k].row.cur_assigned_to) === parseInt(emp_id)){
        y[k] = 1
      }
    }
    console.log("showEmpgroupView", flag, m)
    if(m > 0){
      let grouping = {};
      grouping['group_by'] = 'emp_view'
      grouping['flag'] = flag
      grouping['emp'] = emp_id
      grouping['gc_id'] = 0
      grouping['gcs'] = gcs
      let contacts = Store.getStoreData('contacts');
      let created_by = parseInt(emp_id) === 0 ? 'Unassigned' : contacts[emp_id.toString()]
      grouping['slogan'] = "Filtered View - "+ Object.keys(y).length +" "+ flag +" tasks assigned to "+ created_by +"."

      let viewtable = this.state.viewtable;
      viewtable['task'] = false
      viewtable['emp'] = false
      viewtable['gc'] = false
      viewtable['grouping'] = true
      this.setState({viewtable:viewtable, grouping:grouping})
    }

  }

  showGcgroupView = (flag, m, gc_id)=>(event) =>{
    let x = Store.getStoreData('taskdata')
    let y = {}
    for(let k in x) {
      if(x[k].filter[flag] === 1 && parseInt(x[k].row.company_id) === parseInt(gc_id)){
        y[k] = 1
      }
    }
    console.log("showEmpgroupView", flag, m)
    if(m > 0){
      let grouping = {};
      grouping['group_by'] = 'gc_view'
      grouping['flag'] = flag
      grouping['emp'] = 0
      grouping['gc_id'] = gc_id
      grouping['gcs'] = {}
      let CompanyName = '';
      let gc_assoc = Store.getStoreData('gc_assoc')
        if(parseInt(gc_id) < 100000){
          CompanyName = Store.getStoreData('parrent_company')
        }else{
          CompanyName = gc_assoc[gc_id].is_active === 0 ? gc_assoc[gc_id].company_name +" (Inactive)" : gc_assoc[gc_id].company_name
        }
      grouping['slogan'] = "Filtered View - "+ Object.keys(y).length +" "+ flag +" tasks assigned to "+ CompanyName +"."
      let viewtable = this.state.viewtable;
      viewtable['task'] = false
      viewtable['emp'] = false
      viewtable['gc'] = false
      viewtable['grouping'] = true
      this.setState({viewtable:viewtable, grouping:grouping})
    }

  }

  processGroupview = () => {
    console.log("processGroupview", this.state.grouping)
    let x = Store.getStoreData('taskdata') === null ? [] : Store.getStoreData('taskdata');
    let taskData = {}
    let grouping = this.state.grouping;
    if(grouping['group_by'] === 'emp_view'){
        for(let k in x) {
          if(parseInt(x[k].row.cur_assigned_to) === parseInt(this.state.grouping['emp'])){
            if(grouping['flag'] === 'Multiple') {
              let c = x[k].row.company_id.toString()
              if(this.state.grouping['gcs'].hasOwnProperty(c)){
                taskData[k] = x[k]
              }
            }else{
              if(x[k].filter[grouping['flag']] === 1){
                taskData[k] = x[k]
              }
            }
          }
        }
    }else{
      for(let k in x) {
        if(parseInt(x[k].row.company_id) === parseInt(this.state.grouping['gc_id'])){
          if(x[k].filter[grouping['flag']] === 1){
            taskData[k] = x[k]
          }
        }
      }
    }



    let sent_mail = Store.getStoreData('sent_mail');
    let contacts = Store.getStoreData('contacts');
    let contact = Store.getStoreData('loggedincontact');
    var task_arr = []
    var filter_arr = []
    for(var k = 0 ; k < Object.keys(taskData).length; k++)
    {
        task_arr.push(taskData[Object.keys(taskData)[k]].row)
        filter_arr.push(taskData[Object.keys(taskData)[k]].filter)
    }




    let ret = {data: [], columns: [], raw_data: [], row_filters: {}};

    let has_gc = Store.getStoreData('has_gc')

    ret.columns.push(this.getStatusHeader())
    ret.columns.push(this.getTaskHeader())
    if(has_gc)ret.columns.push(this.getCompanyHeader())
    ret.columns.push(this.getAssignedToHeader())
    ret.columns.push(this.getCreatedByHeader())
    ret.columns.push(this.getSourceHeader())
    ret.columns.push(this.getDateHeader())
    ret.columns.push(this.getFlaggedHeader())
    ret.columns.push(this.getReSentHeader())
    ret.columns.push(this.getActionHeader())


    for(var j = 0 ; j< task_arr.length ; j++)
    {
      let status = this.getStatus(task_arr[j])
      let assigned_to = task_arr[j].cur_assigned_to === 0 ? 'Unassigned' : parseInt(task_arr[j].cur_assigned_to) === parseInt(contact.contact_id) ? 'Me' : contacts[task_arr[j].cur_assigned_to.toString()]
      let  csv_assign_to = task_arr[j].cur_assigned_to === 0 ? 'Unassigned' :  contacts[task_arr[j].cur_assigned_to.toString()]
      let created_by = task_arr[j].contact_id === 0 ? '-' : parseInt(task_arr[j].contact_id) === parseInt(contact.contact_id) ? 'Me' : contacts[task_arr[j].contact_id.toString()]
      let csv_created_by = task_arr[j].contact_id === 0 ? '-' : contacts[task_arr[j].contact_id.toString()]
      let date_shown = this.getDate(task_arr[j].due_date);
      let created_date = this.getDate(task_arr[j].created_at);
      let CompanyName = '';
      let gc_assoc = Store.getStoreData('gc_assoc')
      console.log("gc_assoc", gc_assoc)
        if(parseInt(task_arr[j].company_id) < 100000){
          CompanyName = Store.getStoreData('parrent_company')
        }else{
          // CompanyName =Store.getStoreData('gc_companies')[task_arr[j].company_id%100000]
          CompanyName = gc_assoc[task_arr[j].company_id].is_active === 0 ? gc_assoc[task_arr[j].company_id].company_name +" (Inactive)" : gc_assoc[task_arr[j].company_id].company_name
        }

      let weight = assigned_to === 'Unassigned' ? 600 : 100;
      let task_type = sourceMap[task_arr[j].module_id];
      let is_hint = false;
      if(task_arr[j].module_id === 'tmview'){
        let is_overdue = this.checkRecurringOverdue(task_arr[j]);
        task_type = !is_overdue ? 'Task (Recurring)' : 'Task (One Off)';
        is_hint = ('is_hint' in task_arr[j]) ? task_arr[j].is_hint : false;
      }

      let elem = {'index':j,
                  'status': status,
                  'task': task_arr[j].name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                  'description': this.getTaskDescriptioh(task_arr[j].description),
                  'assigned_to': {text: assigned_to, weight: weight},
                  'assigned_to_text': assigned_to,
                  'created_by': created_by,
                  'company_name':CompanyName,
                  'company_id' : task_arr[j].company_id,
                  'task_type': task_type,
                  'cur_lane' : task_arr[j].cur_lane,
                  'due_date' : date_shown,
                  'flagged' : filter_arr[j].Flagged === 1 ? true : false,
                  'module_id' :sourceMap[task_arr[j].module_id],
                  'csv_assign_to': csv_assign_to,
                  'csv_created_by': csv_created_by,
                  'csv_status': status,
                  'csv_created_date': created_date,
                  'csv_flagged': filter_arr[j].Flagged === 1 ? 'Y' : 'N',
                  'resent' : (task_arr[j].id in sent_mail) ? sent_mail[parseInt(task_arr[j].id)] : 0,
                  'id' : task_arr[j].id,
                  'parent_task_id' : ('parent_task_id' in task_arr[j]) ? task_arr[j].parent_task_id : 0,
                  'ref_id' : ('ref_id' in task_arr[j]) ? task_arr[j].ref_id : 0,
                  'is_hint' : is_hint,
                  'module_name': task_arr[j].module_id
      };
      ret.data.push(elem);
      ret.raw_data.push(elem);
      ret.row_filters[task_arr[j].id] = taskData[task_arr[j].id].filter;
    }
    Store.updateStore('current_data', ret.data);
    console.log("showEmpgroupView", ret.data)
    return ret;












  }

   employeeView = () => {
    let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
    let x = Store.getStoreData('taskdata')
    let contacts = Store.getStoreData('contacts');
    let gc_assoc = Store.getStoreData('gc_assoc')
    let y = {}
    for(let key in x){
        y[x[key].row.cur_assigned_to] = {'company': {}, 'all':0, 'open': 0, 'flagged': 0, 'overdue': 0}
    }

    for(let key in x){
        y[x[key].row.cur_assigned_to]['company'][x[key].row.company_id] = 1
        y[x[key].row.cur_assigned_to]['all'] = y[x[key].row.cur_assigned_to]['all']  + x[key].filter['All']
        y[x[key].row.cur_assigned_to]['flagged'] = y[x[key].row.cur_assigned_to]['flagged']  + x[key].filter['Flagged']
        y[x[key].row.cur_assigned_to]['overdue'] = y[x[key].row.cur_assigned_to]['overdue']  + x[key].filter['Overdue']
        y[x[key].row.cur_assigned_to]['open'] = y[x[key].row.cur_assigned_to]['open']  + x[key].filter['Open']
    }

    console.log("y", y)

    let i = 0;
    let associated_taks = 0;
    ret.columns =[
                    {Header: 'Employee', accessor: 'emp_id', width: 450, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{fontWeight: cellInfo.value === 'Unassigned' ? '600' : '400'}}>{cellInfo.value}</div>),sortMethod: (a, b) => {
                        if (a === b) {
                          return 0;
                        }
                        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;

                      }

                      },
                    {Header: 'Company', accessor: 'company', minWidth: 100, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 'Multiple' ? '#00b0f0' : '',  fontWeight: cellInfo.value === 'Multiple' ? '600' : '100', cursor: cellInfo.value === "Multiple" ? "pointer" : ""}} onClick={this.showEmpgroupView('Multiple',cellInfo.value ==='Multiple' ? 1 : 0,cellInfo.original.contact_id, cellInfo.original.companies)}>{cellInfo.value}</div>),sortMethod: (a, b) => {
                        if (a === b) {
                          return 0;
                        }
                        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;

                      }

                      },
                    {Header: 'Open', accessor: 'open', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showEmpgroupView('Open',cellInfo.value,cellInfo.original.contact_id, cellInfo.original.companies)}>{cellInfo.value}</div>)},
                    {Header: 'Overdue', accessor: 'overdue', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600',  cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showEmpgroupView('Overdue',cellInfo.value,cellInfo.original.contact_id, cellInfo.original.companies)}>{cellInfo.value}</div>)},
                    {Header: 'Flagged', accessor: 'flagged', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showEmpgroupView('Flagged',cellInfo.value,cellInfo.original.contact_id, cellInfo.original.companies)}>{cellInfo.value}</div>)},
                    {Header: 'All', accessor: 'all', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0', fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : "" }} onClick={this.showEmpgroupView('All',cellInfo.value,cellInfo.original.contact_id, cellInfo.original.companies)}>{cellInfo.value}</div>)},
                    ];




    for(let d in y) {
      let CompanyName = '';
        if(Object.keys(y[d].company).length > 1){
          CompanyName = 'Multiple'
        }else{
          let gc = y[d].company[Object.keys(y[d].company)[0]]
          if(parseInt(gc) < 100000){
              CompanyName = Store.getStoreData('parrent_company')
            }else{
              CompanyName = gc_assoc[gc].is_active === 0 ? gc_assoc[gc].company_name +" (Inactive)" : gc_assoc[gc].company_name
            }
        }

        i++;

        let created_by = parseInt(d) === 0 ? 'Unassigned' : contacts[d.toString()]

        let elem = {
            emp_id: created_by,
            company: CompanyName,
            open : y[d].open,
            overdue : y[d].overdue,
            flagged : y[d].flagged,
            all: y[d].all,
            companies: y[d].company,
            contact_id: d
        };
        ret.data.push(elem);
        ret.raw_data.push(elem);
    }
    ret.raw_data.sort(function(a,b) {return (a.emp_id.toLowerCase() > b.emp_id.toLowerCase()) ? 1 : ((b.emp_id.toLowerCase() > a.emp_id.toLowerCase()) ? -1 : 0);} );
    console.log("viewTaskCallback", ret)
    return ret;
   }

    processCGview = () => {
        let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
        let x = Store.getStoreData('taskdata')
        let z = {}
        for(let key in x){
            z[x[key].row.company_id] = {'all':0, 'open': 0, 'flagged': 0, 'overdue': 0}
        }

        for(let key in x){
            z[x[key].row.company_id]['all'] = z[x[key].row.company_id]['all'] + x[key].filter['All']
            z[x[key].row.company_id]['open'] = z[x[key].row.company_id]['open'] + x[key].filter['Open']
            z[x[key].row.company_id]['flagged'] = z[x[key].row.company_id]['flagged'] + x[key].filter['Flagged']
            z[x[key].row.company_id]['overdue'] = z[x[key].row.company_id]['overdue'] + x[key].filter['Overdue']
        }

        let i = 0;
        let associated_taks = 0;
        ret.columns =[
                        {Header: 'Company', accessor: 'company_id', width: 450, headerStyle: {textAlign: 'left'}},
                        {Header: 'Open', accessor: 'open', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showGcgroupView('Open',cellInfo.value,cellInfo.original.gc_id)}>{cellInfo.value}</div>)},
                        {Header: 'Overdue', accessor: 'overdue', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showGcgroupView('Overdue',cellInfo.value,cellInfo.original.gc_id)}>{cellInfo.value}</div>)},
                        {Header: 'Flagged', accessor: 'flagged', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showGcgroupView('Flagged',cellInfo.value,cellInfo.original.gc_id)}>{cellInfo.value}</div>)},
                        {Header: 'All', accessor: 'all', minWidth: 40, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{color: cellInfo.value === 0 ? '' : '#00b0f0',  fontWeight: cellInfo.value === 0 ? '400' : '600', cursor: cellInfo.value > 0 ? "pointer" : ""}} onClick={this.showGcgroupView('All',cellInfo.value,cellInfo.original.gc_id)}>{cellInfo.value}</div>)}
                        ];

        for(let d in z) {
          let CompanyName = '';
          let gc_assoc = Store.getStoreData('gc_assoc')
            if(parseInt(d) < 100000){
              CompanyName = Store.getStoreData('parrent_company')
            }else{
              CompanyName = gc_assoc[d].is_active === 0 ? gc_assoc[d].company_name +" (Inactive)" : gc_assoc[d].company_name
            }
            i++;
            let elem = {
                company_id: CompanyName,
                open : z[d].open,
                overdue : z[d].overdue,
                flagged : z[d].flagged,
                all: z[d].all,
                gc_id: d
            };
            ret.data.push(elem);
            ret.raw_data.push(elem);
            // ret.row_filters[d] = {'Mytasks': z[d].mytasks};
        }
        ret.raw_data.sort(function(a,b) {return (a.company_id.toLowerCase() > b.company_id.toLowerCase()) ? 1 : ((b.company_id.toLowerCase() > a.company_id.toLowerCase()) ? -1 : 0);} );
        console.log("viewTaskCallback", ret)
        return ret;
    }

    processCompleteData = () => {
      let sent_mail = Store.getStoreData('sent_mail');
      let taskData = Store.getStoreData('completedtaskdata') === null ? [] : Store.getStoreData('completedtaskdata');
      let contacts = Store.getStoreData('contacts');
      let contact = Store.getStoreData('loggedincontact');
      var task_arr = []
      for(var k = 0 ; k < Object.keys(taskData).length; k++)
      {
          task_arr.push(taskData[Object.keys(taskData)[k]].row)
      }

      // console.log("taskData", taskData)
      let ret = {data: [], columns: [], raw_data: [], row_filters: {}};

      let has_gc = Store.getStoreData('has_gc')

      ret.columns.push(this.getStatusHeader())
      ret.columns.push(this.getTaskHeader())
      if(has_gc)ret.columns.push(this.getCompanyHeader())
      ret.columns.push(this.getAssignedToHeader())
      ret.columns.push(this.getCreatedByHeader())
      ret.columns.push(this.getSourceHeader())
      ret.columns.push(this.getCompletedDateHeader())
      // ret.columns.push(this.getFlaggedHeader())
      ret.columns.push(this.getReSentHeader())
      ret.columns.push(this.getActionHeadercomplete())
      console.log('ret', ret)

      for(var j = 0 ; j< task_arr.length ; j++)
      {
        let task_json = JSON.parse(task_arr[j].task_json);
        let status = 'Complete'

        let assigned_to = task_arr[j].cur_assigned_to === 0 ? 'Unassigned' : task_arr[j].cur_assigned_to === contact.contact_id ? 'Me' : contacts[task_arr[j].cur_assigned_to.toString()]
        let  csv_assign_to = task_arr[j].cur_assigned_to === 0 ? 'Unassigned' :  contacts[task_arr[j].cur_assigned_to.toString()]

        let created_by = task_arr[j].contact_id === 0 ? '-' : task_arr[j].contact_id === contact.contact_id ? 'Me' : contacts[task_arr[j].contact_id.toString()]
        let csv_created_by = task_arr[j].contact_id === 0 ? '-' : contacts[task_arr[j].contact_id.toString()]

        // let date_shown = status === 'Complete' ? this.getDate(task_arr[j].completed_date) : this.getDate(task_arr[j].due_date);
        let date_shown = this.getDate(task_arr[j].completed_date);
        let created_date = this.getDate(task_arr[j].created_at);
let due_date = this.getDate(task_arr[j].due_date);

        let com_dt = this.getDuecalDate(task_arr[j].completed_date);
        let due_dt = this.getDuecalDate(task_arr[j].due_date);


        let gc_assoc = Store.getStoreData('gc_assoc')

        let CompanyName = '';
          if(parseInt(task_arr[j].company_id) < 100000){
            CompanyName = Store.getStoreData('parrent_company')
          }else{
            // CompanyName = Store.getStoreData('gc_companies')[task_arr[j].company_id%100000]
            console.log("task_arr[j].company_id", task_arr[j].company_id)
            console.log("gc_assoc", gc_assoc)
            CompanyName = gc_assoc[task_arr[j].company_id].is_active === 0 ? gc_assoc[task_arr[j].company_id].company_name +" (Inactive)" : gc_assoc[task_arr[j].company_id].company_name
          }

        let weight = assigned_to === 'Unassigned' ? 600 : 100;
        let task_type = sourceMap[task_arr[j].module_id];
        if(task_arr[j].module_id === 'tmview'){
          console.log("is_recurring", task_json.object_data)
          task_type = ('is_recurring' in task_json.object_data && task_json.object_data.is_recurring) ? 'Task (Recurring)' : 'Task (One Off)';
        }
        let elem = {'index':j,
                    'status': status,
                    'task': RegisterHelper.getTaskTitle(task_arr[j]).replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                    'description': RegisterHelper.getTaskDescription(task_arr[j]),
                    'assigned_to': {text: assigned_to, weight: weight},
                    'assigned_to_text': assigned_to,
                    'created_by': created_by,
                    'company_name':CompanyName,
                    'company_id' : task_arr[j].company_id,
                    'task_type': task_type,
                    'cur_lane' : task_arr[j].cur_lane,
                    'completed_date' : date_shown,
                    'due_date' : due_date,
                    'id' : task_arr[j].id,
                    'module_id' :sourceMap[task_arr[j].module_id],
                    'csv_assign_to': csv_assign_to,
                    'csv_created_by': csv_created_by,
                    'csv_status': status,
                    'csv_created_date': created_date,
                    'resent' : (task_arr[j].id in sent_mail) ? sent_mail[parseInt(task_arr[j].id)] : 0,
                    'is_hint' : ('is_hint' in task_arr[j]) ? task_arr[j].is_hint : false,
                    'module_name': task_arr[j].module_id,
                    'cur_assigned_to': task_arr[j].cur_assigned_to,
                    'contact_id': task_arr[j].contact_id,
                    'raw_task_type': task_arr[j].task_type,
                    'ref_id': task_arr[j].ref_id,
                    'parent_task_id': task_arr[j].parent_task_id,
                    'completed_note': RegisterHelper.getTaskCompleteNote(task_arr[j]).replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                    'days_overdue' : RegisterHelper.overDueCalculation(due_dt, com_dt)

        };
        ret.data.push(elem);
        ret.raw_data.push(elem);
        ret.row_filters[task_arr[j].id] = taskData[task_arr[j].id].filter
      }
      Store.updateStore('current_data_completed', ret.data);
      return ret;
    }

    goToCompleteTaskUrl = (id,module_name, is_hint, task_type,ref_id, cur_lane, parent_task_id, company_id) => (event) => {
       event.preventDefault();
       this.setState({task_id: id, module: module_name, show_modal: "view_tasks",task_type: task_type, ref_id: ref_id, cur_lane: cur_lane, parent_task_id: parent_task_id, company_id: company_id })
    }

    goToTaskUrl = (id, module_name, is_hint, cur_lane, parent_task_id, ref_id, company_id) => (event) => {
      event.preventDefault();
      let task = Store.getStoreData('taskdata')[id.toString()].row;
      console.log("tasks", task, id, Store.getStoreData('taskdata'))
      let module_id = task.module_id;
      if(is_hint) {
        let stack = {};
        stack['hint_id'] = task.hint_id;
        stack['hint_task_id'] = task.parameter_id;
        stack['module_id'] = module_id;
        stack['due_date'] = task.due_date;
        // console.log("hint task", stack)
        let alert_param = {title: 'Future task', message: 'This is a future task which is a part of a recurring task. You cannot take any action on this individual task. To view details, please click OK.', ok_text: 'Ok',cancel_text: 'Cancel', confirm: true, alertHandler: this.reladhintHandler, stack: stack}
        this.setState({alert_param: alert_param})
        return false;
      }
      this.setState({task_id: task.id, module: module_id, show_modal: "view_tasks",task_type: task.task_type, ref_id: task.ref_id, cur_lane: cur_lane, parent_task_id: parent_task_id, ref_id: ref_id, company_id: company_id })
    }

    reladhintHandler = (result, stack) => {
      if(!result || stack.prevent) {
          this.setState({alert_param: null})
          return
      }
      let module_id = stack.module_id;
      let task_id = stack.task_id;
      if(module_id === 'tmview'){
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'hint_modal',
         hint_id: stack.hint_id, hint_task_id: stack.hint_task_id,'module_id':  stack.module_id, 'due_date': stack.due_date, alert_param: null});
      }

    }


    addTaskCallback = () => {
      this.setState({task_id: 0, module: 'tmview', show_modal: "view_tasks"})
    }

    showTaskCallback = () => {
      let api = new APICall();
      let postData = {};
      if(Store.getStoreData('completedtaskdata') === null) {
        postData['command'] = 'get_complete_tasks';
        api.command(postData, this.processcompletetasks)
      }
    }

    processcompletetasks = (result) => {
      console.log("tmview store size task",JSON.stringify(result).length/(1024 * 1024))
      Utils.log("processcompletetasks result", result)
      Store.updateStore('completedtaskdata', result.result.rows);
      if('reporters' in result.result)Store.updateStore('reporters', result.result.reporters);
      let is_default = ('top100' in result.result) ? result.result.top100 : false;
      this.setState({view_count: this.state.view_count + 1, show_complete: true, tasks: Object.keys(result.result.rows).length, is_default: is_default});
    }


    switchRadio = (event) => {
        event.preventDefault();
        let show_complete = this.state.show_complete ? false : true;
        if(show_complete){
          let gc_filters = Store.getStoreData('gc_filters');
          let loggedincontact = Store.getStoreData('loggedincontact');
          let contact_id = loggedincontact.ID;
          let r = {};
          r['result'] = {};
          let user_roles = {};
          let role = {}
          let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
          if('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles
            for(let ur in user_roles){
                if(contact_id in user_roles[ur])role[ur.toString()] = user_roles[ur][contact_id];

            }
          }
          let gcs = [];
          for(let g in gc_filters){
            gcs.push(parseInt(g))
          }
          let teams_users = Utils.getDirectReports();
          let reporters = Store.getStoreData('reporters');

          let gc_users = Store.getStoreData('gc_users');
          let direct_reports = [];
          let assign_users = [];
          if(reporters){
            for( let t in reporters) {
              direct_reports.push(parseInt(t));
            }
          }

          for(let gu in gc_users) {
            assign_users.push(parseInt(gu));
          }


          let api = new APICall();
          let postData = {};
          postData['command'] = 'filter_complete_tasks';
          postData['gcs'] = gcs;
          postData['roles'] = role;
          postData['direct_reports'] = direct_reports;
          postData['reporters'] = reporters;
          postData['gc_users'] = assign_users;
          postData['filters'] = {};
          api.command(postData, this.processcompletetasks)
        }
        this.setState({show_complete});
        // this.props.showOnlyAssign(only_assign_me);
    }

    loadTop500 = (n) => {
      Store.updateStore('completedtaskdata', null);
      Store.updateStore('completedtaskdata_temp', null);
      let api = new APICall();
      let postData = {};
      postData['command'] = 'get_complete_tasks';
      api.command(postData, this.processcompletetasks)
      this.setState({offset: 0, view_count: this.state.view_count + 1, show_complete: true})
    }

    loadMore = (n) => {
      let offset = this.state.offset;
      Store.updateStore('completedtaskdata', null);
      n = parseInt(offset) + parseInt(n);
      // alert(n)
      let api = new APICall();
      let postData = {};
      postData['command'] = 'get_complete_tasks';
      postData['n'] = n;
      console.log('postData', postData)
      api.command(postData, this.processcompletetasks)
      this.setState({offset: n, view_count: this.state.view_count + 1, show_complete: true})
      // this.fetchActivityLog(this.state.apply_filter, 0);
    }

    applyFilter = (apply_filter) => {
      Store.updateStore('completedtaskdata', null);
      Utils.log("apply_filter", apply_filter)
      let gc_filters = Store.getStoreData('gc_filters');
      let loggedincontact = Store.getStoreData('loggedincontact');
      let contact_id = loggedincontact.ID;
      let r = {};
      r['result'] = {};
      let user_roles = {};
      let role = {}
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles
        for(let ur in user_roles){
            if(contact_id in user_roles[ur])role[ur.toString()] = user_roles[ur][contact_id];

        }
      }
      let gcs = [];
      for(let g in gc_filters){
        gcs.push(parseInt(g))
      }
      let teams_users = Utils.getDirectReports();
      let reporters = Store.getStoreData('reporters');

      let gc_users = Store.getStoreData('gc_users');
      let direct_reports = [];
      let assign_users = [];
      if(reporters){
        for( let t in reporters) {
          direct_reports.push(parseInt(t));
        }
      }

      for(let gu in gc_users) {
        assign_users.push(parseInt(gu));
      }


      let api = new APICall();
      let postData = {};
      postData['command'] = 'filter_complete_tasks';
      postData['gcs'] = gcs;
      postData['roles'] = role;
      postData['direct_reports'] = direct_reports;
      postData['reporters'] = reporters;
      postData['gc_users'] = assign_users;
      postData['filters'] = apply_filter;
      console.log("postData", postData)
      api.command(postData, this.processFilterCompleteTasks);
      this.setState({ view_count: this.state.view_count + 1, show_complete: true})
    }

    processFilterCompleteTasks = (result) => {
      Utils.log("tmview store size task",JSON.stringify(result).length/(1024 * 1024))
      Utils.log("processcompletetasks result", result)
      Store.updateStore('completedtaskdata', result.result.rows);
      if('reporters' in result.result)Store.updateStore('reporters', result.result.reporters);
      let is_default = ('top100' in result.result) ? result.result.top100 : false;
      this.setState({view_count: this.state.view_count + 1, show_complete: true, tasks: Object.keys(result.result.rows).length, is_default: is_default});
    }

    viewTaskCallback = (stack) => {
      let viewtable = this.state.viewtable;
      viewtable['task'] = false
      viewtable['emp'] = false
      viewtable['gc'] = false
      viewtable['grouping'] = false
      viewtable[stack] = true;
      this.setState({viewtable, grouping: null})
    }


    render()
    {
        if(Store.getStoreData('tmview-index-select') === null) {
          return <div><CSLPageloader /></div>
        }
        let has_gc = Store.getStoreData('has_gc')
        let button_set = {}
        button_set['add_task'] = {id: 'add_task', display: 'Add New Task', stack: 'assignqcmodal', button_callback: this.addTaskCallback, is_visible: true }
        let text_view = {}
        text_view['task_view'] = {id: 'task_view', display: 'Task View', stack: 'task', button_callback: this.viewTaskCallback, is_visible: true, is_active: this.state.viewtable['task'] }
        text_view['emp_view'] = {id: 'emp_view', display: 'Employee View', stack: 'emp', button_callback: this.viewTaskCallback, is_visible: true, is_active: this.state.viewtable['emp'] }
        if(has_gc) text_view['gc_view'] = {id: 'gc_view', display: 'Group Company View', stack: 'gc', button_callback: this.viewTaskCallback, is_visible: true, is_active: this.state.viewtable['gc'] }


        if(this.state.viewtable['grouping']) text_view['group_view'] = {id: 'group_view', display: this.state.grouping['slogan'], is_visible: true}


        let reporters = Store.getStoreData('reporters')
        reporters = reporters === null ? {} : reporters
        let contacts = Store.getStoreData('contacts')
        let reporters_str = ''
        let count = 0
        let max_count = Object.keys(reporters).length
        for(let c_id in reporters) {
          reporters_str += contacts[c_id]
          if(count < max_count)reporters_str += ', '
        }

      console.log("this.state.viewtable",this.state.grouping)

        return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <CSLHeader headerText_Second={'Task Manager'} />
                <WelcomeBanner  welcome={`Welcome back, ` + Store.getStoreData('loggedincontact').contact_name} />
                {
                  (() => {
                    if(Object.keys(reporters).length > 0) {
                      return (<TeamList>
                        The table below lists all your tasks and all tasks that are currently assigned to one of your team members listed below:<br />
                        {reporters_str}
                      </TeamList>)
                    }
                  })()
                }
                {
                  (() => {
                    if (this.state.view === "register") {

                      if(this.state.viewtable['task']){
                        return (
                          <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                    <RegisterReporting
                                        headerText='Task Manager'
                                        moduleId='tmview'
                                        data={reportData}
                                        processTableData={this.processAcceptanceQueueData}
                                        preferedColumns={this.state.prefered_columns}
                                        buttonSet={{}}
                                        buttonSetTop={button_set}
                                        textView={text_view}
                                        filters={this.state.filters}
                                        pillFilters={this.state.pill_filters}
                                        refreshCallback={this.refreshState}
                                        viewCount={this.state.view_count}
                                    />
                                    {
                                      (() => {
                                          if(this.state.resend_loading){
                                              return <CSLLoader />
                                          }
                                      })()
                                    }
                          </div>
                        );
                      }

                      if(this.state.viewtable['grouping']){
                        return (
                          <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                    <RegisterReporting
                                        headerText='Task Manager'
                                        moduleId='tmview'
                                        data={reportData}
                                        processTableData={this.processGroupview}
                                        preferedColumns={this.state.prefered_columns}
                                        buttonSet={{}}
                                        buttonSetTop={button_set}
                                        textView={text_view}
                                        filters={this.state.filters}
                                        pillFilters={this.state.pill_filters}
                                        refreshCallback={this.refreshState}
                                        viewCount={this.state.view_count}
                                        grouping={this.state.grouping}
                                    />
                                    {
                                      (() => {
                                          if(this.state.resend_loading){
                                              return <CSLLoader />
                                          }
                                      })()
                                    }
                          </div>
                        );
                      }

                      if(this.state.viewtable['emp']){
                        return (
                          <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                    <EmployeeReporting
                                        headerText='Task Manager'
                                        moduleId='tmview'
                                        data={reportempData}
                                        processTableData={this.employeeView}
                                        preferedColumns={RegisterHelper.preferedEMPColumns()}
                                        buttonSet={{}}
                                        buttonSetTop={button_set}
                                        textView={text_view}
                                        filters={empFilters}
                                        pillFilters={this.state.pill_filters}
                                        refreshCallback={this.refreshState}
                                        viewCount={this.state.view_count}
                                    />
                          </div>
                        );
                      }

                      if(this.state.viewtable['gc']){
                        return (
                          <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                    <EmployeeReporting
                                        headerText='Task Manager'
                                        moduleId='tmview'
                                        data={reportgcData}
                                        processTableData={this.processCGview}
                                        preferedColumns={RegisterHelper.preferedGCColumns()}
                                        buttonSet={{}}
                                        buttonSetTop={button_set}
                                        textView={text_view}
                                        filters={gcFilters}
                                        pillFilters={this.state.pill_filters}
                                        refreshCallback={this.refreshState}
                                        viewCount={this.state.view_count}
                                    />
                          </div>
                        );
                      }














                    }
                  })()
                }

                    <div style={{float: "left", padding: "7px"}}>
                        <div style={{float: "left", padding: "5px 10px 0px 0px", fontWeight: "600"}}>Show Complete tasks</div>
                        <div style={{float: "left"}}>
                            {
                                (() => {
                                    if (this.state.show_complete === true) {
                                        return (
                                            <div>
                                                <SelectedRadio>Yes</SelectedRadio>
                                                <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div>
                                                <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                                <SelectedRadio>No</SelectedRadio>
                                            </div>
                                        );
                                    }
                                })()
                            }
                        </div>
                        <div style={{clear: "both"}}></div>
                    </div>




                    {
                      (() => {
                        if(this.state.is_default){
                          return (<TopList>
                            By default only top hundred records are showing below. To list out other records, please use the advanced filters.
                          </TopList>)
                        }
                      })()
                    }


                {
                  (() => {
                    if (this.state.show_complete) {
                      return (
                        <div style={{position: "relative", paddingTop:"40px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                  <CompleteReporting
                                      headerText='Completed Task Manager'
                                      moduleId='tmview'
                                      data={reportData}
                                      processTableData={this.processCompleteData}
                                      preferedColumns={this.state.prefered_completed_columns}
                                      buttonSet={{}}
                                      buttonSetTop={{}}
                                      filters={complete_filters}
                                      pillFilters={complete_pill_filters}
                                      refreshCallback={this.refreshState}
                                      viewCount={this.state.view_count}
                                      applyFilter={this.applyFilter}
                                  />
                        </div>
                      );
                    }
                  })()
                }

                {
                  (() => {
                    switch(this.state.show_modal) {
                      case 'emails_modal' : return <div><InactiveOverlay /><ShowemailModal closeModal={this.closeModal} email_count={this.state.email_count} task_id={this.state.task_id}/></div>; break;
                      case 'hint_modal' : return <div><InactiveOverlay /><ShowhintModal closeModal={this.closeModal} hint_id={this.state.hint_id} hint_task_id={this.state.hint_task_id} module_id={this.state.module_id} due_date={this.state.due_date} closeUserDialog={this.closeModal}/></div>; break;
                      case 'view_tasks' : return <div><InactiveOverlay /><TasksHOC task_id={this.state.task_id} module_id={this.state.module} task_type={this.state.task_type} ref_id={this.state.ref_id} closeUserDialog={this.closeModal} cur_lane={this.state.cur_lane} parent_task_id={this.state.parent_task_id} ref_id={this.state.ref_id} company_id={this.state.company_id} /></div>; break;
                    }
                  })()
                }

                <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
    }
}

export default RegisterIndex;
