import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";

import "./table_styles.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #282828;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
`

class CSLTable extends React.Component
{
    constructor(props) {
        super(props);
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }


	render() {
		// console.log('this.props', this.props);
        let tableData = this.props.processData();
        //let row_count = tableData.row_count === 0 ?  3 : 10;
        let tableRows = this.props.tableRows === null ? 5 : this.props.tableRows
        // console.log(row_count);
		// console.log('tableData', tableData);
		return (
			<TableContainer>
			    <HeaderText>{this.props.headerText}</HeaderText>                    
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                }                    
                    <div style={{marginTop: '5px', width:'100%'}}>
	                    <ReactTable
	                    	PaginationComponent={Pagination}
						    data={tableData.data}
						    columns={tableData.columns}
						    defaultPageSize={tableRows}
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }}

						/>
					</div>
			</TableContainer>
			);
	}
}

export default CSLTable;