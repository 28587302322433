import React from 'react';
import styled from 'styled-components';
import { TiArrowBack } from "react-icons/ti";
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import AskQuestionModal from '../Team/AskQuestionModal';
import ShowAskModal from '../Team/ShowAskModal';
import AcceptanceModal from '../Team/AcceptanceModal';
import AnswerQuestionModal from '../CA/AnswerQuestionModal';
import ClarificationModal from '../CA/ClarificationModal';
import ShowClosecarModal from '../Modals/ShowClosecarModal';
import ShowAnsweredModal from '../Modals/ShowAnsweredModal';
import ShowApprovalModal from '../Modals/ShowApprovalModal';
import ShowAnsweredRevretModal from '../Modals/ShowAnsweredRevretModal';
import ShowReapprovalModal from '../Modals/ShowReapprovalModal';
import FaqRequestModal from '../Modals/FaqRequestModal';
import FaqApprovalModal from '../Modals/FaqApprovalModal';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';

import ErrorBoundary from '../../ErrorBoundary';

const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1178px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1000;
    opacity: 0.6;
`;

const NoPermission = styled.div`
    display: block;
    box-sizing: border-box;
    height: 40px;
    font-size:20px;
    font-weight: 700;
    text-align: center;
    background-color: #ffffff;
    position: absolute;
    top: 100px;
    left: 10px;
    right:10px;
    bottom : 10px;
    border: 2px solid #37ada7;
    border-radius:10px;
    color: #37ada7;
    z-index: 1000;
  `;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 130px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
    cursor: pointer;
`;

const Reject = styled.span`
    background-color: #ff0000;
    height: 15px;
    width:15px;
    margin-right:15px;
    opacity: 1 ;
`;

    
const Pending = styled.span`
    background-color: #5656AA;
    height: 15px;
    width:15px;
    margin-right:15px;
    opacity: 1 ; 
`;
const Complete = styled.span`
    background-color: #00ff00;
    height: 15px;
    width:15px;
    margin-right:15px;
    opacity: 1 ;
`;

const completeMap = {COMPLETE: 1, COMPLETED: 1, DISMISSED : 1, REJECTED: 1, DISSMISS: 1, REJECT: 1, ANSWER: 1,TEAMSAVE: 1, CLARIFICATION: 1, ACCEPTANCE: 1, SAVED: 1, CLARIFIED: 1, PENDING: 1}

class generciTasks extends React.Component
{

    state = {
        ready: false,
        show_modal: '',
        overlay_height: null,
        view_count: 0,
        alert_param: null, 
        only_task: false,
        cur_lane: 0,
        include_topic: false,
        loading: false
    };
    constructor(props) {
        super(props);
    }


    componentDidMount() {        
        let task_id = this.props.taskId;
        let company_id = this.props.companyId;
        let cur_lane = this.props.curLane;
        let postData = { "command" : "get_newtask", "task_id" : task_id, company_id: company_id }
        // postData['gcs'] = Store.getStoreData('gc_filters');
        // postData['lane'] = cur_lane;
        console.log("postData", postData)
        let api = new APICall();
        api.indexCommand(postData, this.processQuestionAnswerTaskData);
        this.setState({only_task: true, loading: true})

    }

    alertremoveHandler = (result,stack) => {            
        this.setState({alert_param: null, overlay_height: null, show_modal: ''});
        this.props.closeUserDialog();
    }

    homeAlertremoveHandler = (result, stack) => {
      this.setState({alert_param: null, overlay_height: null, show_modal: ''});
      this.props.closeUserDialog();
    }

    processQuestionAnswerTaskData = (result) => {
      console.log("result==>122", result);
      this.setState({loading: false})
      if(result.error_code > 0) {
        if(result.error_code === 1003){
          let msg = result.error_msg;
          let title = "Alert";
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
          console.log("alert_param", alert_param)
          this.setState({alert_param: alert_param, ready: true})
          
        }else if(result.error_code === 1004) {
          
          let contacts = Store.getStoreData('contacts');
          let rs = result.result;
          let alert_answer = {title: rs['alt_ref_id'], message: 'This Compliance Advice Request was answered by '+ contacts[rs['answered_by']]+' on '+ Utils.timeToDate(rs['answer_date']), ok_text: 'OK', confirm: false, alertHandler: this.homeAlertremoveHandler, stack: {id: 0}}
          console.log("alert_param", alert_answer)
          this.setState({alert_param: alert_answer, ready: true})
        }
        return false;
      }
      let task = result.result.parenttask;
      if(task.is_delete === 1) {
        // alert("This task was deleted");
        // return;
        let msg = "This task was already deleted";
        let title = "Alert";
        // alert('Your advice request has been submitted to the compliance team.');
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, ready: true})
        return false;
      }
      let task_json = JSON.parse(task.task_json);
      let cur_lane = result.result.parenttask.cur_lane;
      let contact_id = Store.getStoreData('contact_id')
      let role = Store.getStoreData("role");
      console.log("cur_lane role==>", cur_lane, role, contact_id, task.id);
      let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0;

      
      if(cur_lane === 'COMP_CAR_TEAMSAVE' && (parseInt(task.contact_id) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
          Store.updateStore('cur_q_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'ask_modal', ready: true});
      }else if((cur_lane === 'COMP_CAR_ANSWER' || cur_lane === 'COMP_CAR_ANSWER_SAVED' || cur_lane === 'COMP_CAR_USER_ACCEPTANCE_PENDING' || cur_lane ==='COMP_CAR_USER_CLARIFICATION') && parseInt(task.contact_id) === parseInt(contact_id) && role === 'team'){
          // alert("Waiting for Compliance Advisor reply");
          // return false;
          let msg = "Waiting for Compliance Advisor reply";
          let title = "Alert";
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
          this.setState({alert_param: alert_param, ready: true})
          return false;
      }else if(cur_lane === 'COMP_CAR_ANSWER' && role !== 'team') {
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_modal', ready: true});
      }else if(cur_lane === 'COMP_CAR_USER_CLARIFICATION' && role !== 'team') {
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'clarify_modal', ready: true});
      }else if(cur_lane === 'COMP_CAR_ANSWER_SAVED' && (parseInt(task.cur_assigned_to) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
          Store.updateStore('cur_a_modal_data',result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_modal', ready: true});
      }else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE_PENDING' && role === 'jr_ca') {
          let msg = "Waiting for Compliance Advisor approval";
          let title = "Alert";
          let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
          this.setState({alert_param: alert_param, ready: true})
      } else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE_PENDING' && (role === 'ca' || role==='admin_manager' || role === 'ca_module_admin')) {
          Store.updateStore('cur_approval_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'approved_modal', ready: true});
      } else if(cur_lane === 'COMP_CAR_USER_ACCEPTANCE' && (parseInt(task.contact_id) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager" || role === "ca" || role === "jr_ca")) {
          Store.updateStore('cur_accept_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'accept_modal', ready: true});
      } else if(cur_lane == 'COMP_CAR_USER_COMPLETED') {
          Store.updateStore('cur_question_answer_modal_data', result.result.parenttask);
          this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'question_answer_modal', ready: true});
      }  else if(cur_lane === 'COMP_CAR_ANSWER_REVERT' && (parseInt(answered_by) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
        Store.updateStore('cur_revert_modal_data',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'answer_revert_modal', ready: true});
      } else if((cur_lane === 'CAR_FAQ_AWAITING_REAPPROVAL') && (parseInt(answered_by) === parseInt(contact_id) || role === "ca_module_admin" || role === "admin_manager")) {
        Store.updateStore('cur_faq_reapproval_date',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'faq_reapproval_modal', ready: true});
      }else if((cur_lane === 'CAR_FAQ_APPROVED' || cur_lane === 'ADD_CAR_FAQ') && role === 'ca') {
        Store.updateStore('cur_faq_modal_data',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'faq_request_modal', ready: true});
      }else if((cur_lane === 'ADD_CAR_FAQ') && (role === 'admin_manager' || role === 'ca_module_admin')) {
        Store.updateStore('cur_faq_modal_data',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'faq_request_modal', ready: true});
      }else if((cur_lane === 'CAR_FAQ_APPROVED') && (role === 'admin_manager' || role === 'ca_module_admin')) {
        Store.updateStore('cur_faq_approval_data',result.result.parenttask);
        this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'faq_approval_modal', ready: true});
      }else{
        let msg = "ERROR: Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error.";
        let title = "Alert";
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, ready: true})
      }
    }



    closeModal = () => {
      this.setState({overlay_height: null, show_modal: ''});
      this.props.closeUserDialog();
    }

    refreshFAQState = () => {
      this.setState({overlay_height: null, show_modal: ''});
      this.props.closeUserDialog();
    }

    closeFAQModal = () => {
      this.setState({overlay_height: null, show_modal: ''});
      this.props.closeUserDialog();
    }

    refreshState = (stack=null) => {
      this.setState({overlay_height: null, show_modal: ''});
      this.props.refreshParentCallback(stack);
    }

    render() {
        console.log("this.state ===>", this.state)

        if(!this.state.ready) {
            return <div><CSLLoader /></div>
        }
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                {
                    (() => {
                        if(this.state.loading) {
                            return <div><CSLLoader /></div>
                        }
                    })()
                }
                {
                  (() => {
                    if (this.state.overlay_height !== null) {
                      switch(this.state.show_modal) {
                        case 'show_ask_modal':return <div><InactiveOverlay /><ErrorBoundary><ShowAskModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} /></ErrorBoundary></div>; break;
                        case 'ask_modal' : return <div><InactiveOverlay /><ErrorBoundary><AskQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} openAnswerModal={this.showQuestionAnswerModal} closeModal={this.closeModal}/></ErrorBoundary></div>; break;
                        case 'accept_modal' : return <div><InactiveOverlay /><ErrorBoundary><AcceptanceModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal}/></ErrorBoundary></div>; break;
                        case 'answer_modal' : return <div><InactiveOverlay /><ErrorBoundary><AnswerQuestionModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;
                        case 'clarify_modal' : return <div><InactiveOverlay /><ErrorBoundary><ClarificationModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;
                        case 'question_answer_modal' : return <div><InactiveOverlay /><ErrorBoundary><ShowClosecarModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;                            
                        case 'save_answer_modal' : return <div><InactiveOverlay /><ErrorBoundary><ShowAnsweredModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;                            
                        case 'approved_modal' : return <div><InactiveOverlay /><ErrorBoundary><ShowApprovalModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break; 
                        case 'answer_revert_modal' : return <div><InactiveOverlay /><ErrorBoundary><ShowAnsweredRevretModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;                           
                        case 'faq_reapproval_modal' : return <div><InactiveOverlay /><ErrorBoundary><ShowReapprovalModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshFAQState} closeModal={this.closeFAQModal} /></ErrorBoundary></div>; break;                           
                        case 'faq_request_modal' : return <div style={{left:'10%', top:'50px', overflow: "auto"}}><InactiveOverlay /><ErrorBoundary><FaqRequestModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;
                        case 'faq_approval_modal' : return <div style={{left:'10%', top:'50px', overflow: "auto"}}><InactiveOverlay /><ErrorBoundary><FaqApprovalModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} closeModal={this.closeModal} /></ErrorBoundary></div>; break;
                        // case 'question_answer_modal' : return <div><InactiveOverlay /><ViewQuestionAnswerModal refreshCallback={this.props.refreshCallback} refreshParentCallback={this.refreshState} /></div>; break;                            
                        default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
                      }
                    }
                  })()
                } 
                
                <AlertBox alertParam = {this.state.alert_param} />
            </div>
        )
    }
}
export default generciTasks