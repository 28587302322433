import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Store from '../../Common/Store.js';
import {FaAngleDown , FaEye} from 'react-icons/fa';
import { MdEdit , MdDelete } from 'react-icons/md';
import {FaTimes} from 'react-icons/fa';
import Utils from '../../Common/Utils.js';
import DatePicker from 'react-date-picker'
import "../../App.css";
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import * as Style from '../Common/StyledComponents';
import {FiDownload} from 'react-icons/fi';
import { saveAs } from 'file-saver';
import AddActions from './AddActions';
import CSLTable from '../Common/CSLTable';
import HtmlEditor from '../Common/HtmlEditor';
import APICall from '../../Common/APICall';
import AlertBox from '../Common/AlertBox.js';
import CSLLoader from '../Common/CSLLoader';
import CMPUtils from '../Common/CMPUtils.js';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 75%;
    left: 5%;
	top: 20px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 98%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin:10px;

`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 77%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-left: 89px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;
const MRModalDivider = styled.div`
    background-color: #EBEBEB;
    height:3px;
    margin-top: 15px;
    margin-bottom: 15px;
	width: calc(99% - 10px);
	margin-left: 12px;
`;
const MRModalLabelTitle = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const TestingSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 78%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 73px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 10px 32px;
    color: #ffffff;
    cursor: pointer;
`;

const MRSubcatHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #26ADA7;
    padding: 13px;
    padding-bottom:27px;
    border: 1px solid #EBEBEB;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left : 17px;
    margin-top:10px;
`;
const MRSubcatList = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #FFFFFF;
    padding: 13px;    
    border: 1px solid #EBEBEB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left : 17px;
`;
const MRModalCircle = styled.label`
    height: 22px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    float: right;
    padding-bottom: 9px;
    padding-left: 5px;
    margin-bottom:2px;
    cursor:pointer;
`;
const MRTestingLabel = styled.label`
    display: inline-block;
    box-sizing: border-box;
    width: 78%;
    background-color: #EEEEEE;
    padding: 14px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-left: 71px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 210px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;
const TestingTextArea = styled.textarea`
    width:70%;
    margin-left:71px;
    margin-top: 15px;
    border: 1px solid #ffffff; 
    borderRadius: 3px; 
    boxShadow: 0 0 4px #c4c4c4;
    padding: 5px; 
    color: #979797; 
    fontFamily: Montserrat,sans-serif;
`;
const PartialDiv = styled.div`
    display:inline-block;
`;
const CompleteModalSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 94%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 20%;
	font-weight: bold;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -100px;
  z-index: 1001;
  opacity: 0.8;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
function GetSortOrder(prop) {
    return function(a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}

class CompleteModal extends React.Component
{
	state = {
        task_data: {},
        risk_area_name:'',
		risk_owner_id : '',
        testing_activity:'',
        testing_description:'',
        testing_freq :'',
		first_schedule : new Date(),
        testing_owner:'',
        testing_objectives:'',
        testing_method:'',
        additional_guidance:'',
        testing_activity_undertaken: '',
        review_attachment:[],
        rag:'0',
        require_remediation: 'No',
        submit_msg:'',
        unique_testing_act_id :null,
        task_id : 0,
        binFiles: [],
        curBinFiles: [],
        actions: [],
		cur_files_json: [],
		actionModalOpen : false,
		is_form_modified : false,
		alert_param: null,
		action_extra_json : {},
		cur_lane: 'COMP_CMP_ASSIGNMENT',
		model_bin_file_id : 0,
		ready : false,
		show_initiate_approval : false ,
		approval_contact : 0 ,
		IsApprovalContactProvided : false ,
		custom_rags: [],
	}

	componentDidMount(){
        this.initializeState(this.props.taskData,this.props.taskId, this.props.my_task , this.props.tasks_without_filter , this.props.child_tasks , this.props.ref_id)
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props) {
            this.initializeState(this.props.taskData,this.props.taskId, this.props.my_task , this.props.tasks_without_filter , this.props.child_tasks , this.props.ref_id)
        }
		if(this.state.require_remediation === 'No' && this.state.is_form_modified === false){
			this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		}
    }
    initializeState(task_arr, task_id, my_task_temp , tasks_without_filter , child_tasks , ref_id){
		let my_task = (typeof my_task_temp === 'undefined') ? {} : JSON.parse(JSON.stringify(my_task_temp));
		//console.log("tasks_without_filter,",tasks_without_filter,ref_id);
		this.cleanHTMLEditor();

		let utils = new Utils();
		let cmputils = new CMPUtils();
		let local_task = {};
		for(let j=0;j<tasks_without_filter.length;j++){
			if(tasks_without_filter[j].ref_id === ref_id ){
				local_task = tasks_without_filter[j];
				break;
			}
		}
		//console.log("completemodal local_task", local_task)
		//console.log("completemodal ref_id", ref_id)
        //console.log("completemodal my_task", my_task)
		//console.log("completemodal tasks_without_filter", tasks_without_filter)
		//console.log("child_tasks", child_tasks)
        var task_data ={};
        let curBinFiles = [];
		let cur_files_json = [];

		let compliance_plans = Store.getStoreData('cmp-compliance_plans');
		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		let original_risk_areas_pre = Store.getStoreData('original_risk_areas_pre');
		//let hint_tasks = Store.getStoreData('hint_tasks');

		let plan_id = ref_id.split('-')[0];
		let plan_json_parsed = {};
		for(let j=0;j<compliance_plans.length;j++){
			if(parseInt(compliance_plans[j].id) === parseInt(plan_id)){
				plan_json_parsed = JSON.parse(compliance_plans[j].plan_json);
				break;
			}
		}
		let testing_activities = plan_json_parsed.testing_activities;
		let task_testing_activity_hints= {};
		for(let j=0;j<testing_activities.length;j++){
			if(testing_activities[j].unique_testing_act_id === ref_id.split('-')[4]){
				task_testing_activity_hints = testing_activities[j];
				break;
			}
		}

		let risk_name_hash = {};
		let risk_owner_id = "";
		let unique_risk_id = task_testing_activity_hints.unique_risk_id;
		for(let i=0;i<original_risk_areas_pre.length;i++){
			risk_name_hash[original_risk_areas_pre[i].unique_risk_id] = original_risk_areas_pre[i].risk_area_name;
			if(task_testing_activity_hints.unique_risk_id.toString() === original_risk_areas_pre[i].unique_risk_id.toString()){
				risk_owner_id = original_risk_areas_pre[i].risk_owner_id;
				//risk_name = this.props.original_risk_areas[i].risk_area_name;
				break;
			}
		}

        let testing_activity = {};
		testing_activity.testing_activity = task_testing_activity_hints.testing_activity;
		testing_activity.testing_description = task_testing_activity_hints.testing_description;
		testing_activity.testing_freq = task_testing_activity_hints.testing_freq;
		testing_activity.first_schedule = local_task.risk_json.first_schedule;
		testing_activity.testing_owner = task_testing_activity_hints.testing_owner;
		testing_activity.testing_objectives = task_testing_activity_hints.testing_objectives;
		testing_activity.testing_method = task_testing_activity_hints.testing_method;
		testing_activity.additional_guidance = task_testing_activity_hints.additional_guidance;
		testing_activity.unique_testing_act_id = task_testing_activity_hints.unique_testing_act_id;
        testing_activity.unique_risk_id = task_testing_activity_hints.unique_risk_id;
        testing_activity.unique_subcat_id = task_testing_activity_hints.unique_subcat_id;

		if(typeof tasks_ref_assoc[ref_id] !== 'undefined'){
			task_id = tasks_ref_assoc[ref_id].id;
			var task_json = JSON.parse(my_task.task_json);
			if('bin_files' in  task_json.object_data) {
				console.log("my_task.object_data.bin_files.length=>"+task_json.object_data.bin_files.length);
				if (task_json.object_data.bin_files.length > 0) {
					task_json.object_data.bin_files.forEach((item) => {
						item.uid = "uid" in item ? item.uid : this.genKey(10);
						if(!("type" in item)){
							curBinFiles.push(item);
						}else{
							cur_files_json.push(item);
						}
					});
				}
				//curBinFiles = my_task.object_data.bin_files;
			}
            //task_data = my_task;
			//var task_json = JSON.parse(task_data.task_json);
			//var task_json = my_task;
			let is_series = "";
			if('is_series' in task_json.task_data){
				is_series = task_json.task_data.is_series;
			}
			let same_series_tasks = [];


			var result = task_json.object_data.result;
			console.log("result[0].rag==>", result[0]);
			var risk_json = task_json.object_data.headerinfo.risk_json;
			var requireRemediation = result[0].require_remediation;
			if(result[0].require_remediation === ""){
				requireRemediation = "No"
			}

			//let rag = (result[0].rag === 'N/A' || result[0].rag.trim() === "") ? 'Green' : result[0].rag.trim();
			let rag_pre = (result[0].rag === 'N/A' || result[0].rag.trim() === "") ? 'Green' : result[0].rag.trim();
			var task_testing_activity = risk_json.testing_activities.length > 0 ? risk_json.testing_activities[0] : risk_json.subcategories.testing_activities[0];
			let current_rag = (rag_pre.toString() === '0') ? rag_pre : cmputils.customRagDecider(this.props.module_configs,rag_pre);

			let addtional_rags_assoc = cmputils.getCustomRagAssoc(this.props.module_configs);
			if(this.state.actions.length > 0)requireRemediation='Yes';
			//console.log("FFFF=>",addtional_rags_assoc,current_rag,requireRemediation);

			//testing_activity.first_schedule = tasks_ref_assoc[ref_id].risk_json.first_schedule;

			let risk_name_hash = {};
			let risk_owner_id = "";
			let unique_risk_id = risk_json.testing_activities.length > 0 ? risk_json.testing_activities[0].unique_risk_id : risk_json.subcategories.testing_activities[0].unique_risk_id;
			for(let i=0;i<this.props.original_risk_areas.length;i++){
				risk_name_hash[this.props.original_risk_areas[i].unique_risk_id] = this.props.original_risk_areas[i].risk_area_name;
				if(unique_risk_id.toString() === this.props.original_risk_areas[i].unique_risk_id.toString()){
					risk_owner_id = this.props.original_risk_areas[i].risk_owner_id;
					//risk_name = this.props.original_risk_areas[i].risk_area_name;
				}
			}

			let followup_actions= [];
			if(this.state.actions.length === 0){
				for(let i = 0; i< child_tasks.result.length; i++){
					//console.log("In tasks_without_filter==>"+tasks_without_filter[i].parent_task_id+",task_id==>"+task_id);
					//if(parseInt(tasks_without_filter[i].parent_task_id) === parseInt(task_id)){
						//console.log("tasks_without_filter==>"+JSON.stringify(tasks_without_filter[i]));
						let child_task_json = JSON.parse(child_tasks.result[i].task_json);
						let followupBinFiles = [];
						if(child_tasks.result[i].cur_lane === 'COMP_CMP_FOLLOWUP_ASSIGNMENT' || child_tasks.result[i].cur_lane === 'COMP_CMP_FOLLOWUP_SAVED' || child_tasks.result[i].cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE' || child_tasks.result[i].cur_lane === 'COMP_CMP_FOLLOWUP_APPROVAL'){
							//console.log("in COMP_CMP_FOLLOWUP_ASSIGNMENT==>");
							let action_status = (child_tasks.result[i].cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE') ? 'Complete' : 'Incomplete';
							let assignto = Store.getStoreData('contact_list')[child_task_json.object_data.headerinfo.risk_json.actions.actionAssignedTo];
							console.log("child_task_json.object_data==>",child_task_json.object_data);
							if('bin_files' in  child_task_json.object_data) {
								child_task_json.object_data.bin_files.forEach((item) => {
									item.uid = "uid" in item ? item.uid : utils.genKey(10);
									followupBinFiles.push(item);
								});
								//actionBinFiles = current_task.object_data.bin_files;
							}
							//console.log("actionDescription==>"+child_task_json.object_data.headerinfo.risk_json.actions.actionDescription);
							let json = {'action_title' : child_task_json.object_data.headerinfo.risk_json.actions.action_title, 'action_description' : child_task_json.object_data.headerinfo.risk_json.actions.action_description, 'action_assignto': child_task_json.object_data.headerinfo.risk_json.actions.action_assignto , action_status : action_status , due_date : (typeof child_task_json.object_data.headerinfo.risk_json.actions.due_date === 'undefined' ? '-' : child_task_json.object_data.headerinfo.risk_json.actions.due_date) , 'cur_lane' : child_tasks.result[i].cur_lane , 'created_by' : child_tasks.result[i].contact_id, 'followupBinFiles':followupBinFiles , 'task_id' :  child_tasks.result[i].id , parent_task_id : task_id};
							//console.log(json);
							followup_actions.push(json);
						}
					//}
				}
			}else{
				followup_actions = this.state.actions;
			}
            let testing_activity_undertaken_text = cmputils.changeStringToAtob(result[0].testing_activity_undertaken);
			let testing_description_text = cmputils.changeStringToAtob(testing_activity.testing_description);
			let testing_objectives_text = cmputils.changeStringToAtob(testing_activity.testing_objectives);
			let testing_method_text = cmputils.changeStringToAtob(testing_activity.testing_method);
			let additional_guidance_text = cmputils.changeStringToAtob(testing_activity.additional_guidance);
			//if(utils.isBase64(result[0].testing_activity_undertaken)){
			//	testing_activity_undertaken_text = window.decodeURIComponent(atob(result[0].testing_activity_undertaken))
			//}


			this.setState({ready : true , task_data:my_task, task_id : task_id , testing_activity: testing_activity.testing_activity,
				testing_description: testing_description_text, testing_freq:testing_activity.testing_freq, first_schedule : testing_activity.first_schedule ,
				testing_owner: testing_activity.testing_owner, testing_objectives: testing_objectives_text,
				testing_method : testing_method_text, additional_guidance: additional_guidance_text,
				risk_area_name: risk_name_hash[unique_risk_id], unique_testing_act_id:testing_activity.unique_testing_act_id,
				testing_activity_undertaken : testing_activity_undertaken_text, rag:current_rag, require_remediation: requireRemediation,
				curBinFiles:curBinFiles , cur_files_json: cur_files_json , is_series : is_series , same_series_tasks : same_series_tasks ,
				risk_owner_id : risk_owner_id , actions : followup_actions , ref_id : this.props.ref_id , testing_activity_obj : testing_activity ,
				model_bin_file_id : task_testing_activity_hints.attachments[0], custom_rags: this.props.module_configs.custom_rags.additional_rags});
		}else{
			let binfiles_id = [];

			let requireRemediation = 'No';

			let testing_description_text = cmputils.changeStringToAtob(testing_activity.testing_description);
			let testing_objectives_text = cmputils.changeStringToAtob(testing_activity.testing_objectives);
			let testing_method_text = cmputils.changeStringToAtob(testing_activity.testing_method);
			let additional_guidance_text = cmputils.changeStringToAtob(testing_activity.additional_guidance);

			let customrags_array = [];
			let custom_rags = this.state.custom_rags;
			console.log("additional_rags==>", custom_rags);
			let first_rag_id = this.props.module_configs.custom_rags.additional_rags[0].id;
			for(let item of this.props.module_configs.custom_rags.additional_rags){
				if(item.isActive === 1){
					customrags_array.push(item);
				}
				if(item.isActive === 0 && this.state.rag === first_rag_id) {
					customrags_array.push(item);
				}
			}

			let addtional_rags_assoc = cmputils.getCustomRagAssoc(this.props.module_configs);
			if(addtional_rags_assoc[customrags_array[0].id].required_remedial_action || this.state.actions.length > 0)requireRemediation='Yes';
			if(!this.props.module_configs.custom_rags.enabled)requireRemediation='No';

			let state_json = {
				testing_activity: testing_activity.testing_activity,
				testing_description: testing_description_text,
				testing_freq:testing_activity.testing_freq,
				first_schedule: testing_activity.first_schedule,
				testing_owner: testing_activity.testing_owner,
				testing_objectives: testing_objectives_text,
				testing_method : testing_method_text,
				additional_guidance: additional_guidance_text,
				risk_area_name: risk_name_hash[unique_risk_id],
				unique_testing_act_id:testing_activity.unique_testing_act_id,
				testing_activity_obj : testing_activity ,
				ref_id : this.props.ref_id,
				model_bin_file_id : task_testing_activity_hints.attachments[0],
				custom_rags: this.props.module_configs.custom_rags.additional_rags,
				rag: '0',
				require_remediation: requireRemediation,
				ready : true
			};
            binfiles_id.push(task_testing_activity_hints.attachments[0]);

			if(binfiles_id.length > 0){
				let postData = {"command": "get_binfiles", "binfiles_id": binfiles_id};
				let api = new APICall();
				let that = this;
				api.command(postData, function callback(result){that.process_binfiles(result,state_json)});
			}else{
				this.setState(state_json) ;
			}
		}

		//console.log("followup_actions in initialize==>"+JSON.stringify(followup_actions));
		//this.setState({actions : followup_actions});
    }
	process_binfiles= (result , state_json) =>{
        //console.log("get_binfiles=>",result);
		let binfiles = JSON.parse(result.result.binfiles);
		let curBinFiles = [];
		binfiles.forEach((item) => {
			//item.uid = "uid" in item ? item.uid : this.genKey(10);
			//if(!("type" in item)){
				curBinFiles.push(item);
			//}
		});
		console.log("state_json=>",state_json);
        console.log("completemodal curBinFiles==>",curBinFiles);
		let state_json_new = JSON.parse(JSON.stringify(state_json))
		state_json_new.curBinFiles = curBinFiles;
		this.setState(state_json_new) ;
	}
	cleanHTMLEditor = () => {
		Store.updateStore('testing_activity_undertaken' , null);
	}
    customRagDecider = (current_rag) => {
		let id_array = [];
		let rag_id = '';
		for(let item of this.props.module_configs.custom_rags.additional_rags){
		//this.props.module_configs.custom_rags.forEach((item) => {
			id_array.push(item.id);
			//let name_arr = item.rag_value.split(current_risk_impact);
			//if(name_arr.length > 1)rag_id = item.id;
			if(item.rag_value === current_rag){rag_id = item.id;break;}
		}
		//if(risk_impact_id === '')return id_array[0];
		if(rag_id !== '') return rag_id;
		if(id_array.includes(current_rag)) return current_rag;

    }
    closeModal = (event)=>{
        event.preventDefault();
        if(this.state.is_form_modified){
            this.props.refreshParent('openDataLostWarningModal');
        }else{
            this.props.refreshParent(null);
		}
    }
	doApprove = (event)=>{
		event.preventDefault();
		let module_configs = this.props.module_configs;

		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		let ref_id = this.state.ref_id;

		var bin_files = this.state.curBinFiles;
		var action_bin_files = this.state.cur_files_json;

		let task_json = {};

		let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
		if(testing_activity_undertaken_text === null){
			testing_activity_undertaken_text = this.state.testing_activity_undertaken;
		}
		let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
		var result = [{unique_testing_act_id:this.state.unique_testing_act_id,
					testing_activity_undertaken: testing_activity_undertaken_value,
					reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation}];
		var task_data = this.state.task_data;
		if(typeof tasks_ref_assoc[ref_id] === 'undefined'){
			var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_udertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
			if(ref_id.split('-')[3].toString() !== '0' ){ // subcat checking
				res_data.object_data.headerinfo.risk_json = {
					unique_risk_id : ref_id.split('-')[2],
					testing_activities : [],
					subcategories : {
						unique_subcat_id : ref_id.split('-')[3],
						testing_activities : [this.state.testing_activity_obj]
					}
				}
			}else{
				res_data.object_data.headerinfo.risk_json = {
					unique_risk_id : ref_id.split('-')[2] ,
					testing_activities : [this.state.testing_activity_obj],
					subcategories : {

					}
				}
			}
			task_json = {
				"task_data": { "module_id": "cmp", "last_action": "COMP_CMP_APPROVAL_REQUEST", "actiondate": Date.now(),
				'system_id' : '1001', 'cur_lane' : 'COMP_CMP_SAVED',
				"cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
				"object_data": res_data.object_data,
				"action_data": { "action": "COMP_CMP_SAVED", "actiondate": Date.now(), "assigned_to": this.state.testing_owner, "cur_lane":"COMP_CMP_SAVED" }
			};
		}else{
			task_json = JSON.parse(this.state.task_data.task_json);
			task_json.task_data.cur_assigned_to = this.state.testing_owner;
		}


		//let task_json = JSON.parse(this.state.task_data.task_json);
		task_json.object_data.result = result;
		task_json.object_data.bin_files = bin_files;
		task_json.object_data.action_bin_files = action_bin_files;
		//current_task.task_data = { "module_id": "cmp", "last_action": "COMP_CMP_COMPLETE", "actiondate": Date.now(), "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'cur_lane': 'COMP_CMP_COMPLETE', due_date: ""};
		var action_data = {action: 'COMP_CMP_SAVED',actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : 'COMP_CMP_SAVED' , performed_by: Store.getStoreData('cmp-loggedin_user_info').ID ,completed_date: Date.now()};
		task_json.action_data = action_data;
		task_data.task_json = task_json;

		let input_json = {allow_outstanding_remedial_actions_to_complete : module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding};

		let rag_json = {};
		if(this.state.rag === 'Red'){
			rag_json = {'risk_area_name' : this.state.risk_area_name , 'risk_owner_id' : this.state.risk_owner_id , 'send_rag_alert_email' : true , 'task_id' : task_data.id };
		}
		let extra_json = {is_series : this.state.is_series , same_series_tasks : this.state.same_series_tasks , task_status : 'APPROVE' , allow_outstanding_remedial_actions_to_complete : input_json.allow_outstanding_remedial_actions_to_complete , rag_json : rag_json};
		//this.props.saveTestingActivities(task_data , extra_json , [] ,this.state.ref_id , this.state.model_bin_file_id);

		this.processCompleteTask(task_data , extra_json , [] ,this.state.ref_id , this.state.model_bin_file_id);
		//alert("This testing activity has been updated.");
		//setTimeout(this.refreshPage(),(120*1000+100));

		//let alert_param = {title: 'Alert', message: 'This testing activity has been updated.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processCompleteTestingActivities, stack: {allow_outstanding_remedial_actions_to_complete : input_json.allow_outstanding_remedial_actions_to_complete} };
		//this.setState({alert_param: alert_param});
	}
	initiateSendForApproval = (event)=>{
        event.preventDefault();
        this.setState({show_initiate_approval : true}) ;
    }

	requestSendForApproval = (event)=>{
		if(this.state.approval_contact == 0){
			this.setState({IsApprovalContactProvided: true});

		}else{
			event.preventDefault();
			let cmputils = new CMPUtils();
			let addtional_rags_assoc = cmputils.getCustomRagAssoc(this.props.module_configs);

			let module_configs = this.props.module_configs;

			let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
			let ref_id = this.state.ref_id;

			var bin_files = this.state.curBinFiles;
			var action_bin_files = this.state.cur_files_json;

			let task_json = {};

			let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
			if(testing_activity_undertaken_text === null){
				testing_activity_undertaken_text = this.state.testing_activity_undertaken;
			}
			let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
			var result = [{unique_testing_act_id:this.state.unique_testing_act_id,
						testing_activity_undertaken: testing_activity_undertaken_value,
						reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation}];
			var task_data = this.state.task_data;
			if(typeof tasks_ref_assoc[ref_id] === 'undefined'){
				var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_udertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
				if(ref_id.split('-')[3].toString() !== '0' ){ // subcat checking
					res_data.object_data.headerinfo.risk_json = {
						unique_risk_id : ref_id.split('-')[2],
						testing_activities : [],
						subcategories : {
							unique_subcat_id : ref_id.split('-')[3],
							testing_activities : [this.state.testing_activity_obj]
						}
					}
				}else{
					res_data.object_data.headerinfo.risk_json = {
						unique_risk_id : ref_id.split('-')[2] ,
						testing_activities : [this.state.testing_activity_obj],
						subcategories : {

						}
					}
				}
				task_json = {
					"task_data": { "module_id": "cmp", "last_action": "COMP_CMP_ASSIGNMENT", "actiondate": Date.now(),
					'system_id' : '1001', 'cur_lane' : 'COMP_CMP_APPROVAL_REQUEST',
					"cur_assigned_to" : this.state.approval_contact, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
					"object_data": res_data.object_data,
					"action_data": { "action": "COMP_CMP_APPROVAL_REQUEST", "actiondate": Date.now(), "assigned_to": this.state.approval_contact, "cur_lane":"COMP_CMP_APPROVAL_REQUEST" }
				};
			}else{
				task_json = JSON.parse(this.state.task_data.task_json);
				task_json.task_data.cur_assigned_to = this.state.approval_contact;
			}


			//let task_json = JSON.parse(this.state.task_data.task_json);
			task_json.object_data.result = result;
			task_json.object_data.bin_files = bin_files;
			task_json.object_data.action_bin_files = action_bin_files;
			//current_task.task_data = { "module_id": "cmp", "last_action": "COMP_CMP_COMPLETE", "actiondate": Date.now(), "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'cur_lane': 'COMP_CMP_COMPLETE', due_date: ""};
			var action_data = {action: 'COMP_CMP_APPROVAL_REQUEST',actiondate :Date.now(), assigned_to: this.state.approval_contact, cur_lane : 'COMP_CMP_APPROVAL_REQUEST' , performed_by: Store.getStoreData('cmp-loggedin_user_info').ID ,completed_date: Date.now()};
			task_json.action_data = action_data;
			task_data.task_json = task_json;

            let input_json = {allow_outstanding_remedial_actions_to_complete : module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding};
			//console.log("task_data==>", JSON.stringify(task_data));
			let extra_json = {is_series : this.state.is_series , same_series_tasks : this.state.same_series_tasks , task_status : 'APPROVAL_REQUEST' , allow_outstanding_remedial_actions_to_complete : input_json.allow_outstanding_remedial_actions_to_complete};

            let followup_tasks_to_be_created = this.getNewFollowupTasks();

			if(addtional_rags_assoc[this.state.rag].required_remedial_action && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0)){
				let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to complete this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
				this.setState({alert_param: alert_param});
				return false;
			}

			this.processCompleteTask(task_data , extra_json , followup_tasks_to_be_created ,this.state.ref_id , this.state.model_bin_file_id);
			//alert("This testing activity has been updated.");
			//setTimeout(this.refreshPage(),(120*1000+100));

			//let alert_param = {title: 'Alert', message: 'This testing activity has been updated.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processCompleteTestingActivities, stack: {allow_outstanding_remedial_actions_to_complete : input_json.allow_outstanding_remedial_actions_to_complete} };
			//this.setState({alert_param: alert_param});
		}
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value, is_form_modified : true});
    }

    handleChangeRag = (event) => {
        event.preventDefault();
		let cmputils = new CMPUtils();
		let addtional_rags_assoc = cmputils.getCustomRagAssoc(this.props.module_configs);
		let require_remediation = this.state.require_remediation;
		if(event.target.value !== '0' && addtional_rags_assoc[event.target.value].required_remedial_action || this.state.actions.length > 0){
			require_remediation='Yes';
		}else{
			require_remediation='No';
		}
        this.setState({[event.target.name]: event.target.value, is_form_modified : true,require_remediation});
    }

	completeTestingActivitiesAlert = (event) =>{
		event.preventDefault();
		if(this.state.rag.toString() === '0'){
			let alert_param = {title: 'Alert', message: 'A Risk Rating has to be selected.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
			this.setState({alert_param: alert_param});
			return false;
		}

		let alert_param = {title: 'Confirm', message: 'This will complete the testing activity and further updates cannot be made. Are you sure you want to continue?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.completeTestingActivities, stack: {} };
		this.setState({alert_param: alert_param});
		return false;
	}

	completeTestingActivities = (result , stack) =>{
		this.setState({alert_param: null});
		if(result === false) return;
		//event.preventDefault();
		let cmputils = new CMPUtils();
		let module_configs = this.props.module_configs;
		//console.log("module_configs,",module_configs);

		let addtional_rags_assoc = cmputils.getCustomRagAssoc(this.props.module_configs);
		//console.log("addtional_rags_assoc==>", addtional_rags_assoc);
		//return;
		let action_incomplete_counter = 0;
		if(this.state.require_remediation === "Yes"){
			if(this.state.actions.length === 0){
				//alert("There are currently no actions associated with this Testing Activity.");
				let alert_param = {title: 'Alert', message: 'There are currently no remedial actions associated with this Testing Activity, at least one remedial action must be added to progress.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
				this.setState({alert_param: alert_param});
				return false;
			}else{
				for(let i=0;i<this.state.actions.length;i++){
					//console.log("actions status==>", this.state.actions[i].action_status);
					if(this.state.actions[i].action_status === 'Incomplete' || this.state.actions[i].action_status === 'New'){
						action_incomplete_counter++;
					}
				}
			}
		}

		if(addtional_rags_assoc[this.state.rag].required_remedial_action && this.state.actions.length === 0){
			let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to complete this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
			this.setState({alert_param: alert_param});
			return false;
		}

		if(!module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding && action_incomplete_counter > 0){
			let alert_param = {title: 'Alert', message: 'Remedial actions are outstanding, please ensure all remedial actions are completed prior to completing this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
			this.setState({alert_param: alert_param});
			return false;
		}
		if(module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding && action_incomplete_counter > 0){

			let alert_param = {title: 'Confirm', message: 'There are one or more Remedial Actions outstanding. These will not be completed on submission and can be accessed from your Remedial Actions table.', ok_text: 'OK',cancel_text: 'Cancel', confirm: true, alertHandler: this.processErrorCompleteTestingActivitiesWithRemedialActions, stack: {allow_outstanding_remedial_actions_to_complete : true} };
			this.setState({alert_param: alert_param});
			return false;
		}
		//console.log("task_data==>", JSON.stringify(this.state.task_data));
        let input_json = {allow_outstanding_remedial_actions_to_complete : module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding};
		this.completeTestingActivitiesBrokenPart(input_json);
	}
	completeTestingActivitiesBrokenPart = (input_json) =>{
		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		let ref_id = this.state.ref_id;

		var bin_files = this.state.curBinFiles;
		var action_bin_files = this.state.cur_files_json;

		let task_json = {};

		let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
		if(testing_activity_undertaken_text === null){
			testing_activity_undertaken_text = this.state.testing_activity_undertaken;
		}
        let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
        var result = [{unique_testing_act_id:this.state.unique_testing_act_id,
                    testing_activity_undertaken: testing_activity_undertaken_value,
                    reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation}];
        var task_data = this.state.task_data;
        if(typeof tasks_ref_assoc[ref_id] === 'undefined'){
			var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_udertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
			if(ref_id.split('-')[3].toString() !== '0' ){ // subcat checking
				res_data.object_data.headerinfo.risk_json = {
					unique_risk_id : ref_id.split('-')[2],
					testing_activities : [],
					subcategories : {
						unique_subcat_id : ref_id.split('-')[3],
						testing_activities : [this.state.testing_activity_obj]
					}
				}
			}else{
				res_data.object_data.headerinfo.risk_json = {
					unique_risk_id : ref_id.split('-')[2] ,
					testing_activities : [this.state.testing_activity_obj],
					subcategories : {

					}
				}
			}
			task_json = {
				"task_data": { "module_id": "cmp", "last_action": "COMP_CMP_ASSIGNMENT", "actiondate": Date.now(),
				'system_id' : '1001', 'cur_lane' : 'COMP_CMP_COMPLETED',
				"cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
				"object_data": res_data.object_data,
				"action_data": { "action": "COMP_CMP_COMPLETED", "actiondate": Date.now(), "assigned_to": this.state.testing_owner, "cur_lane":"COMP_CMP_COMPLETED" }
			};
		}else{
			task_json = JSON.parse(this.state.task_data.task_json);
		}


		//let task_json = JSON.parse(this.state.task_data.task_json);
        task_json.object_data.result = result;
        task_json.object_data.bin_files = bin_files;
        task_json.object_data.action_bin_files = action_bin_files;
		//current_task.task_data = { "module_id": "cmp", "last_action": "COMP_CMP_COMPLETE", "actiondate": Date.now(), "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'cur_lane': 'COMP_CMP_COMPLETE', due_date: ""};
		var action_data = {action: 'COMP_CMP_COMPLETED',actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : 'COMP_CMP_COMPLETED' , performed_by: Store.getStoreData('cmp-loggedin_user_info').ID ,completed_date: Date.now()};
		task_json.action_data = action_data;
		task_data.task_json = task_json;

		//console.log("task_data==>", JSON.stringify(task_data));
		let extra_json = {is_series : this.state.is_series , same_series_tasks : this.state.same_series_tasks , task_status : 'Completed' , allow_outstanding_remedial_actions_to_complete : input_json.allow_outstanding_remedial_actions_to_complete};

		let followup_tasks_to_be_created = ('followup_tasks_to_be_created' in input_json) ? input_json.followup_tasks_to_be_created : [];


		this.processCompleteTask(task_data , extra_json , followup_tasks_to_be_created ,this.state.ref_id , this.state.model_bin_file_id);

	}
    processCompleteTask = (task_data,extra_json,followup_tasks_to_be_created,ref_id,model_bin_file_id) =>{

		this.props.saveTestingActivities([task_data , extra_json , followup_tasks_to_be_created ,this.state.ref_id , this.state.model_bin_file_id]);


		let alert_param = {title: 'Alert', message: 'This testing activity has been updated.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processSaveTestingActivities, stack: {} };
		this.setState({alert_param: alert_param});

    }
	processErrorCompleteTestingActivities = (result , stack) => {
		this.setState({alert_param: null} );
	}
	processErrorCompleteTestingActivitiesWithRemedialActions = (result , stack) => {
		this.setState({alert_param: null});
		if(result === false) return;

		let followup_tasks_to_be_created = this.getNewFollowupTasks();
		let input_json = {allow_outstanding_remedial_actions_to_complete : stack.allow_outstanding_remedial_actions_to_complete , followup_tasks_to_be_created: followup_tasks_to_be_created}
		this.completeTestingActivitiesBrokenPart(input_json);

	}
	processCompleteTestingActivities = (result , stack) => {
		this.setState({alert_param: null} , ()=>{

			if(result === false) return;

			//let that = this;
			//this.props.refreshParent(null);
		});
	}
    refreshPage = () => {
        //window.location.reload();
		  if(this.props.prop_task_id > 0){
			  if(document.referrer.match(/tmview/g)){
				  window.location.assign(document.referrer);
			  }else{
				  window.location.href = process.env.REACT_APP_CMP_MODULE_URL;
			  }
			  //
		  }else{
              window.location.reload();
		  }
          //alert(this.props.prop_task_id);
    }
    saveTestingActivities = (event) =>{
        event.preventDefault();

		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		let role = Store.getStoreData('role');
		var bin_files = this.state.curBinFiles;
		var action_bin_files = this.state.cur_files_json;
        let module_configs = this.props.module_configs;
		//console.log("bin_files size in saveTestingActivities==>", bin_files.length);
        //console.log("this.state.task_data", JSON.parse(this.state.task_data.task_json))
		//return;
		let cur_lane_parent = 'COMP_CMP_SAVED';
		if(role === 'team' && module_configs.general.enable_approval)cur_lane_parent = 'COMP_CMP_APPROVAL';

        var action_data = {action: cur_lane_parent,actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : cur_lane_parent}
        var task_data = this.state.task_data;
		let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
		if(testing_activity_undertaken_text === null){
			testing_activity_undertaken_text = this.state.testing_activity_undertaken;
		}
        let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
        var result = [{unique_testing_act_id:this.state.unique_testing_act_id,
                    testing_activity_undertaken: testing_activity_undertaken_value,
                    reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation}]
		let task_json = {};
        let ref_id = this.state.ref_id;
        //console.log(ref_id);
        if(typeof tasks_ref_assoc[ref_id] === 'undefined'){
			var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_udertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
			if(ref_id.split('-')[3].toString() !== '0' ){ // subcat checking
				res_data.object_data.headerinfo.risk_json = {
					unique_risk_id : ref_id.split('-')[2],
					testing_activities : [],
					subcategories : {
						unique_subcat_id : ref_id.split('-')[3],
						testing_activities : [this.state.testing_activity_obj]
					}
				}
			}else{
				res_data.object_data.headerinfo.risk_json = {
					unique_risk_id : ref_id.split('-')[2] ,
					testing_activities : [this.state.testing_activity_obj],
					subcategories : {

					}
				}
			}
			task_json = {
				"task_data": { "module_id": "cmp", "last_action": "COMP_CMP_ASSIGNMENT", "actiondate": Date.now(),
				'system_id' : '1001', 'cur_lane' : cur_lane_parent,
				"cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
				"object_data": res_data.object_data,
				"action_data": { "action": cur_lane_parent, "actiondate": Date.now(), "assigned_to": this.state.testing_owner, "cur_lane":cur_lane_parent }
			};
		}else{
			task_json = JSON.parse(task_data.task_json);
		}


        task_json.object_data.result = result;
        task_json.object_data.bin_files = bin_files;
		task_json.object_data.action_bin_files = action_bin_files;
        task_json.action_data = action_data;
        task_data.task_json = task_json;
        //console.log("task_data--", task_data)


		let followup_tasks_to_be_created = this.getNewFollowupTasks();

        this.processAddTask(task_data,this.state.rag,this.state.risk_area_name,this.state.risk_owner_id , followup_tasks_to_be_created);
        //console.log("actions===>",this.state.actions.length);
		//this.closeModal(event);
		//this.refreshPage();
    }
	getNewFollowupTasks = () =>{
		let followup_tasks_to_be_created = [];
		let userid = Store.getStoreData('cmp-loggedin_user_info').ID;
		let role = Store.getStoreData('role');
		let module_configs = this.props.module_configs;
		let cur_lane_followup = 'COMP_CMP_FOLLOWUP_ASSIGNMENT';
		if(role === 'team' && module_configs.general.enable_approval)cur_lane_followup = 'COMP_CMP_FOLLOWUP_APPROVAL';
        if(this.state.actions.length !== 0){
            for(let i=0;i<this.state.actions.length;i++){
				if(this.state.actions[i].action_status === 'New'){
					let risk_json = {};
					let action_binfiles = this.state.actions[i].actionBinFiles;
					let action_single = this.state.actions[i];
					delete action_single.actionBinFiles;
					delete action_single.action_status;
					delete action_single.temp_id;
					delete action_single.created_by;
					risk_json.actions = action_single;
					let headerinfo = {}
					headerinfo.risk_json = risk_json;
					headerinfo.unique_testing_act_id = this.state.unique_testing_act_id ;
					//headerinfo.bin_files = action_binfiles;
					let task = {
						"task_data": { "module_id": "cmp", "last_action": "COMP_CMP_FOLLOWUP_CREATE", "ref_id" : this.state.unique_testing_act_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.actions[i].action_assignto, 'task_type' : 'COMP_CMP_FOLLOWUP', 'parent_task_id' : this.state.taskId, 'cur_lane': cur_lane_followup, due_date: this.state.actions[i].due_date},
						"object_data": {headerinfo},
						"action_data": { "action": "COMP_CMP_FOLLOWUP_CREATE", "actiondate": Date.now(), "assigned_to": this.state.actions[i].action_assignto, "cur_lane":cur_lane_followup, performed_by: userid}
					};
					task.object_data.bin_files = action_binfiles;
					//console.log("task follow up",JSON.stringify(task));
					followup_tasks_to_be_created.push(task);

				}

            }
		}
        return 	followup_tasks_to_be_created;
	}
    processAddTask = (task_data,rag,risk_area_name,risk_owner_id , followup_tasks_to_be_created) =>{

		let extra_json = {}
		if(rag === 'Red'){
			extra_json = {'risk_area_name' : risk_area_name , 'risk_owner_id' : risk_owner_id , 'send_rag_alert_email' : true , 'task_id' : task_data.id };
		}
		console.log("extra_json==>",extra_json);

		this.props.saveTestingActivities([task_data,extra_json , followup_tasks_to_be_created,this.state.ref_id , this.state.model_bin_file_id]);


		let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processSaveTestingActivities, stack: {} };
		this.setState({alert_param: alert_param});

		//this.props.refreshParent();

    }
	processSaveTestingActivities = (result , stack) => {
		this.setState({alert_param: null} , ()=>{

			if(result === false) return;

			let that = this;
			//setTimeout(function() {that.refreshPage();},3000);
            //this.props.refreshParent(null);
		});
	}
	filesRemoved = (jsonObj) => {
		if(jsonObj.files_to_show_size === 0){
			this.setState({is_form_modified: false});
		}
    }
    filesLoaded = (jsonObj) => {
        let me = Store.getStoreData('cmp-loggedin_user_info');
		let curBinFiles = this.state.curBinFiles;
		//console.log('files in Modal size 1==>', curBinFiles.length);

		let file_exists = {};
		let curBinFiles_1 = [];
		if(jsonObj.command === 'add'){
			for(let i=0; i < jsonObj.files.length; i++) {
				jsonObj.files[i].name = jsonObj.files[i].name.replace('&', '');
				if(!('user_id' in jsonObj.files[i])) {
					jsonObj.files[i].user_id = me.ID;
					jsonObj.files[i].upload_date = Date.now();
					//files[i].type = "complete_resource";

				}
				if(typeof file_exists[jsonObj.files[i].name] === "undefined"){
					curBinFiles.push(jsonObj.files[i]);
					file_exists[jsonObj.files[i].name] = true;
				}
				curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
				//curBinFiles.push(jsonObj.files[i]);
			}
			//console.log('files in Modal size==>', curBinFiles.length);
			this.setState({curBinFiles: curBinFiles_1 ,is_form_modified : true});
		}else{
			for(let i=0; i < curBinFiles.length; i++) {
				if(curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()){
					curBinFiles.splice(i,1);
				}
			}
			this.setState({curBinFiles: curBinFiles});
		}
    }
	filesRemovedType2 = (jsonObj) => {
		if(jsonObj.files_to_show_size === 0){
			this.setState({is_form_modified: false});
		}
    }
    filesLoadedType2 = (jsonObj) => {
        let me = Store.getStoreData('cmp-loggedin_user_info');
		let cur_files_json = this.state.cur_files_json;
		//console.log('files in Modal size 1==>', curBinFiles.length);
		let file_exists = {};
		let curBinFiles_1 = [];

		if(jsonObj.command === 'add'){
			for(let i=0; i < jsonObj.files.length; i++) {
				jsonObj.files[i].name = jsonObj.files[i].name.replace('&', '');
				if(!('user_id' in jsonObj.files[i])) {
					jsonObj.files[i].user_id = me.ID;
					jsonObj.files[i].upload_date = Date.now();
					jsonObj.files[i].type = "complete_resource";

				}
				if(typeof file_exists[jsonObj.files[i].name] === "undefined"){
					cur_files_json.push(jsonObj.files[i]);
					file_exists[jsonObj.files[i].name] = true;
				}


                curBinFiles_1 = cur_files_json.filter((cur_files_json, index, self) => index === self.findIndex((t) => (t.name === cur_files_json.name && t.uid === cur_files_json.uid)));
				//cur_files_json.push(jsonObj.files[i]);
			}
			//console.log('curBinFiles_1 files in Modal size==>', curBinFiles_1.length);
			//console.log('curBinFiles_1 files in Modal ==>', JSON.stringify(curBinFiles_1));
			this.setState({cur_files_json: curBinFiles_1 , is_form_modified : true});
		}else{
			for(let i=0; i < cur_files_json.length; i++) {
				if(cur_files_json[i].uid.toString() === jsonObj.deleted_uid.toString()){
					cur_files_json.splice(i,1);
				}
			}
			this.setState({cur_files_json: cur_files_json});
		}
    }
    actionModalOpen	= (jsonObj) => {
		console.log("actionModalOpen actions",this.state.actions);
		this.setState({actionModalOpen: jsonObj.status , action_extra_json : {}});
	}
    handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }
    refreshAddActionValue = (action_title,actionDescription,actionAssignedTo,actionBinFiles,action_status , due_date , temp_id) => {
		//console.log("refreshAddActionValue actions,", this.state.actions , temp_id);
		let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
		let utils = new Utils();
        if(action_title != '' && actionAssignedTo != 0) {

            let actionsObject = {};
            actionsObject.action_title = action_title;
            actionsObject.action_description = actionDescription;
            actionsObject.action_assignto = actionAssignedTo;
            actionsObject.actionBinFiles = actionBinFiles;
			actionsObject.followupBinFiles = actionBinFiles;
			actionsObject.action_status = 'New';
			actionsObject.due_date = due_date;
			actionsObject.temp_id = (temp_id !== null) ? temp_id : utils.genKey(8);
			actionsObject.created_by = loggedin_user_info.ID;

			let actions = this.state.actions;

			let updated_actions = [];

			actions.forEach((item) => {
				if(typeof item.temp_id !== 'undefined' && typeof temp_id !== null && item.temp_id === temp_id)item = actionsObject; // 2nd,3rd onward
				updated_actions.push(item);
			});
			//console.log("refreshAddActionValue actions,", updated_actions , temp_id);
			if(temp_id === null)updated_actions.push(actionsObject); // first time
            this.setState({actionModalOpen : false ,actions: updated_actions});
        }

    }
	processSaveAction = (jsonObj) => {
		let actions = this.state.actions;
		let updated_actions = [];
		console.log("processSaveAction,",actions,jsonObj);


		for(let i=0;i<actions.length;i++){
			if(actions[i].task_id.toString() === jsonObj.task_id.toString()){
				actions[i].action_title = jsonObj.action_obj.action_title;
				actions[i].action_description = jsonObj.action_obj.action_description;
				actions[i].action_assignto = jsonObj.action_obj.action_assignto;
				actions[i].due_date = jsonObj.action_obj.due_date;
			}
			updated_actions.push(actions[i]);
		}
		this.props.processSaveAction(jsonObj);
		this.setState({actionModalOpen : false , actions : updated_actions});

	}
	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() +1) < 10 ? '0'+(date.getMonth() +1) : (date.getMonth() +1);
		var dt = (date.getDate() < 10 ) ? '0'+(date.getDate()) : (date.getDate());
		return dt +'/'+ month + '/'+date.getFullYear();
	}
    processMyActionData = () => {
        //console.log("TT=>",Store.getStoreData('contacts'));
		let contact_list = Store.getStoreData('contact_list');
		let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
		let role = Store.getStoreData('role');
		let original_risk_areas_pre = Store.getStoreData('original_risk_areas_pre');
		let risk_owners_assoc = {};
		let subcat_owners_assoc = {};
		original_risk_areas_pre.forEach((item) => {
			if(item.status === 'Active'){
				risk_owners_assoc[`${item.plan_id}-${item.gc_id}-${item.unique_risk_id}`] = item.risk_owner_id;
				item.subcategories.forEach((subcat_item) => {
					if(subcat_item.subcat_status === 'Active'){
						subcat_owners_assoc[`${item.plan_id}-${item.gc_id}-${item.unique_risk_id}-${subcat_item.unique_subcat_id}`] = subcat_item.subcat_assignto;
					}
				});
			}
		});




        var keysdata = this.state.actions;
		console.log("processMyActionData keysdata,",keysdata);
        let ret = {data: [], columns: []};
        let i = 0;
		keysdata.sort(function(a, b){return new Date(a.due_date).getTime() - new Date(b.due_date).getTime()});
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
        {Header: 'Title', accessor: 'action_title', minWidth: 90, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'action_description', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {Header: 'Assign to', accessor: 'action_assignto', minWidth: 70, headerStyle: {textAlign: 'left'}},
		{Header: 'Created by', accessor: 'created_by', minWidth: 70, headerStyle: {textAlign: 'left'}},
		//{Header: 'Due Date', id: 'due_date' ,accessor: c => c.due_date , Cell: c => <span>{c.original.due_date && moment(c.original.due_date).format("DD/MM/YYYY")}</span> , minWidth: 70, headerStyle: {textAlign: 'left'}},
		{Header: 'Due Date', accessor: 'due_date' , minWidth: 120, headerStyle: {textAlign: 'left'} ,sortMethod: (a, b) => {
		if (a === b) {
			return 0;
		}
		let aReverse = 0;
		let bReverse = 0;
		let adue = a.split("/");
		aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
		let bbdue = b.split("/");
		bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
			return aReverse > bReverse ? 1 : -1;
		}},
                      {'Header' : 'Status', Cell: row => (
                        <div>
	                        {
	                            (() => {
	                            	//need to restrict by role
									if(row.original.action_status === "Incomplete"){
										if(row.original.cur_lane === "COMP_CMP_FOLLOWUP_ASSIGNMENT" || row.original.cur_lane === "COMP_CMP_FOLLOWUP_APPROVAL" ){
											return (<MRButton  style={{float: "left",backgroundColor: "#F39C12"}}>Pending</MRButton>);
										}else if(row.original.cur_lane === "COMP_CMP_FOLLOWUP_SAVED"){
											return (<MRButton  style={{float: "left",backgroundColor: "blue"}}>In Progress</MRButton>);
										}else if(row.original.cur_lane === "OVERDUE"){
											return (<MRButton  style={{float: "left",backgroundColor: "red"}}>Overdue</MRButton>);
										}

									}else if(row.original.action_status === "New"){
										if(row.original.cur_lane === "OVERDUE"){
											return (<MRButton  style={{float: "left",backgroundColor: "red"}}>Overdue</MRButton>);
										}else{
											return (<MRButton  style={{float: "left",backgroundColor: "#F39C12"}}>Pending</MRButton>);
										}
									}else{
										return (<MRButton style={{float: "left"}}>Complete</MRButton>);
									}
	                            })()
	                        }


	                        {
	                            (() => {
	                            	//need to restrict by role
										return (
											<div style={{float: "right"}}>


												{
													(() => {
														if(row.original.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE'){
															if(row.original.created_by_id.toString() === loggedin_user_info.ID.toString() || role === 'admin_manager' || ((row.original.risk_owner_key in risk_owners_assoc && risk_owners_assoc[row.original.risk_owner_key].toString() === loggedin_user_info.ID.toString()) || (row.original.subcat_owner_key in subcat_owners_assoc && subcat_owners_assoc[row.original.subcat_owner_key].toString() === loggedin_user_info.ID.toString()))){
																return(
																	<div style={{float: "right", marginRight : "10px"}}>
																		<ViewDiv style={{ curser: "pointer" }} onClick={() => this.viewAction({action_title : row.original.action_title,action_description : row.original.action_description,action_assignto : row.original.action_assignto_id, due_date : new Date(row.original.unformatted_due_date), followupBinFiles : row.original.followupBinFiles , showModal : true , view_mode : false , task_id : row.original.task_id , temp_id : row.original.temp_id })}   title="edit this remedial action" style={{color : "#1A3552"}}><MdEdit /></ViewDiv>
																	</div>
																	);
															}

														}
													})()
												}
												{
													(() => {
														if(row.original.cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE'){
															//if(role === 'admin_manager'){
																return(
																	<div style={{float: "right", marginRight : "10px"}}>
																		<ViewDiv style={{ curser: "pointer" }} onClick={() => this.viewAction({action_title : row.original.action_title,action_description : row.original.action_description,action_assignto : row.original.action_assignto_id, due_date : new Date(row.original.unformatted_due_date), followupBinFiles : row.original.followupBinFiles , showModal : true , view_mode : true , task_id : row.original.task_id})}   title="view this remedial action" style={{color : "#1A3552"}}><FaEye /></ViewDiv>
																	</div>
																	);
															//}

														}
													})()
												}

											</div>);
	                            })()
	                        }

                        </div>
                      ), width: 200, headerStyle: {textAlign: 'center'}},
		];
        for(let d of keysdata) {
            i++;
			let parent_task_ref_id_splitted = this.props.ref_id.split('-');
			let risk_owner_key = `${parent_task_ref_id_splitted[0]}-${parent_task_ref_id_splitted[1]}-${parent_task_ref_id_splitted[2]}`;
			let subcat_owner_key = `${parent_task_ref_id_splitted[0]}-${parent_task_ref_id_splitted[1]}-${parent_task_ref_id_splitted[2]}-${parent_task_ref_id_splitted[3]}`;

			let due_date_obj = new Date(d.due_date);

			let due_day = due_date_obj.getDate() < 10 ? "0"+due_date_obj.getDate().toString() : due_date_obj.getDate();
			let due_month = due_date_obj.getMonth()+1 < 10 ? `0${(due_date_obj.getMonth() + 1).toString()}` : due_date_obj.getMonth()+1;
			let due_year = due_date_obj.getFullYear();
			let due_date_string = `${due_day}/${due_month}/${due_year}`;


			let g1 = new Date();
			let g2 = new Date(d.due_date);
			let cur_lane = d.cur_lane;
            if(g1.withoutTime() > g2.withoutTime()){
				if(cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE')cur_lane = 'OVERDUE';
			}
            let created_by = ('created_by' in d) ? contact_list[d.created_by] : contact_list[loggedin_user_info.ID];
            let elem = {'index' : i,
                    'action_title' : d.action_title,
                    'action_description' : d.action_description,
                    'action_assignto': contact_list[d.action_assignto] ,
					'created_by': created_by,
                    'action_status' : d.action_status,
                    'due_date'	 :    due_date_string,
                    'cur_lane' : cur_lane,
                    'followupBinFiles' : d.followupBinFiles	,
                    'task_id' : d.task_id,
                    'action_assignto_id': d.action_assignto ,
                    'unformatted_due_date'	 :    d.due_date ,
					'created_by_id' : ('created_by' in d) ? d.created_by : loggedin_user_info.ID,
					'risk_owner_key' : risk_owner_key,
					'subcat_owner_key' : subcat_owner_key,
                    'temp_id' : d.temp_id,

                   };
            ret.data.push(elem);
        }
		console.log("processMyActionData keysdata,",ret);
        return ret;
    }
	deleteConfirm = (task_id,temp_id) => (event) => {
		event.preventDefault();
        console.log("deleteConfirm task_id",task_id);
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this action?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
						   alertHandler: this.deleteProcessor, stack: {task_id: task_id , temp_id : temp_id}}
		this.setState({alert_param: alert_param})

	}
	deleteProcessor = (result, stack) => {
		if(!result || stack.prevent) {
			this.setState({alert_param: null})
			return
		}
		let task_id = stack.task_id;
		console.log("deleteProcessor task_id,",task_id);
		if(parseInt(task_id) > 0){
			let postData = { command: "delete_followup_task", task_id: task_id };
			let api = new APICall();
			api.command(postData, this.processDeleteAction);
            this.setState({alert_param: null});
		}else{
			let actions = this.state.actions;
			actions = actions.filter(item => item.temp_id !== stack.temp_id);
			this.setState({actions : actions , alert_param: null});
		}


	}
	processDeleteAction = (result ) =>{
		let actions = this.state.actions;
		actions = actions.filter(item => item.task_id !== result.task_id);
		this.setState({actions : actions });
		this.props.processDeletedAction({task_id : result.task_id});
	}
	viewAction = (jsonObj) => {
		console.log("task_id in viewAction ==>",jsonObj,jsonObj.task_id);
		if(typeof jsonObj.temp_id === 'undefined'){
			let postData = {"command": "get_task", "task_id": jsonObj.task_id};
			let api = new APICall();
			let that = this;
			api.command(postData, function callback(result){that.processViewAction(jsonObj,result)});
		}else{
			console.log("task_id in viewAction ==>",jsonObj,jsonObj.task_id);
			this.setState({actionModalOpen: true , action_extra_json : jsonObj});
		}

		//
	}
	processViewAction = (jsonObj,task_info) => {
		console.log("task_info==>",task_info);
		//console.log("task_json of current followup task ==>"+task_info.result.task.parenttask.task_json);
		let followupBinFiles = [];
        let child_task_json = JSON.parse(task_info.result.task.parenttask.task_json );
		if('bin_files' in  child_task_json.object_data) {
			child_task_json.object_data.bin_files.forEach((item) => {
				item.uid = "uid" in item ? item.uid : Utils.genKey(10);
				followupBinFiles.push(item);
			});
			//actionBinFiles = current_task.object_data.bin_files;
		}
		let action_note_undertaken ="";
		if('result' in  child_task_json.object_data && 'action_note_undertaken' in child_task_json.object_data.result[0]) {
			action_note_undertaken = child_task_json.object_data.result[0].action_note_undertaken;
			//actionBinFiles = current_task.object_data.bin_files;
		}
        jsonObj.followupBinFiles = followupBinFiles;
		jsonObj.action_note_undertaken = action_note_undertaken;

        this.setState({actionModalOpen: true , action_extra_json : jsonObj});
	}
	genKey = (length) => {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	unescapeHTML = (escapedHTML) => {
		return escapedHTML.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
	}
    processcontactrole = () => {
        var parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		var contactmap = [];
		let distinctPeople = [];
		if(parent_has_group_companies === false){
			var contacts = Store.getStoreData('contacts')
			var userrole = Store.getStoreData('contactrole')
			console.log("userrole",userrole.length)
			console.log("contacts",contacts.ID)

			for( let i=0; i<userrole.length; i++) {
				for (let j=0; j<contacts.length; j++) {
					if ((userrole[i].user_id === contacts[j].ID) && (userrole[i].role === 'admin_manager' || userrole[i].role === 'risk_owner' || userrole[i].role === 'plan_admin' )) {
						contactmap.push({ID:contacts[j].ID,name:contacts[j].ContactName})
					}
				}
			}
		}else{
			var gc_assoc = Store.getStoreData('gc_assoc');
			var gc_companies = Store.getStoreData('cmp-gc_companies');
			let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
			let user_wise_gcs = Store.getStoreData('user_wise_gcs');
			let member_group_comp_ids = Store.getStoreData('member_group_comp_ids');
            let role = Store.getStoreData('role');

			let ref_id = this.state.ref_id;
			console.log("ref_id,",ref_id);
			let parent_gc_id = ref_id.split('-')[1];

			let gc_comp ={};
            //Object.keys(gc_assoc).forEach(key => {
				if(parseInt(parent_gc_id) !== 0)gc_comp = gc_companies.find(item => item.is_gc === true && parseInt(item.id) === parseInt(parent_gc_id) );

				if(parseInt(parent_gc_id) === 0)gc_comp = gc_companies.find(item => item.is_gc === false );
			//});
            let selected_gc_id = gc_comp.id;
			//var gc_list = gc_assoc[selected_gc_id];
			let included_gcs = {};
			let users_of_gcs = {};
			if(parseInt(parent_gc_id) !== 0)included_gcs[parent_gc_id.toString()] = 1;
			if(parseInt(parent_gc_id) === 0)included_gcs['0'] = 1;
			Object.keys(gc_assoc).forEach(gc_id => {
				if(gc_assoc[gc_id].is_gc && included_gcs[gc_id.toString()]){
					Object.keys(gc_assoc[gc_id].users).forEach(contact_id => {
						users_of_gcs[contact_id] = gc_assoc[gc_id].users[contact_id];
					});
				}
				if(!gc_assoc[gc_id].is_gc && included_gcs['0']){
					Object.keys(gc_assoc[gc_id].users).forEach(contact_id => {
						users_of_gcs[contact_id] = gc_assoc[gc_id].users[contact_id];
					});
				}
			});
			console.log("users_of_gcs,",users_of_gcs);
			if(role === 'admin_manager'){
				Object.keys(users_of_gcs).forEach(user => {
					if ((users_of_gcs[user].role == 'admin_manager' || users_of_gcs[user].role == 'risk_owner' || users_of_gcs[user].role == 'plan_admin' )) {
						contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName})
					}

				});
			}
            if(role === 'risk_owner' || role === 'plan_admin' || role === 'team'){
				Object.keys(users_of_gcs).forEach(user => {
					if ((users_of_gcs[user].role == 'admin_manager' )) {
						contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName})
					}
					if ((users_of_gcs[user].role == 'risk_owner'  || users_of_gcs[user].role == 'plan_admin'  ) && (loggedin_user_info.ID in gc_assoc[selected_gc_id].users && gc_assoc[selected_gc_id].is_gc)) {
						contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName})
					}
					if ((users_of_gcs[user].role == 'risk_owner'  || users_of_gcs[user].role == 'plan_admin'  ) &&  !gc_assoc[selected_gc_id].is_gc ) { //this is critical for parent
						console.log("contacts included_gcs=>",user_wise_gcs[users_of_gcs[user].ID]);
						if(users_of_gcs[user].ID in user_wise_gcs){
							Object.keys(user_wise_gcs[users_of_gcs[user].ID]).forEach(gc_id => {
								if(member_group_comp_ids.includes(gc_id) && users_of_gcs[user].ID in user_wise_gcs[users_of_gcs[user].ID][gc_id])contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName})
							});
						}

					}
				});
			}
		}
        //console.log("contactmap===>",contactmap)
        let unique = [];
		for( let i = 0; i < contactmap.length; i++ ){
		  if( !unique[contactmap[i].ID]){
			distinctPeople.push(contactmap[i]);
			unique[contactmap[i].ID] = 1;
		  }
		}
        //console.log("contactmap===>",contactmap)selected_gc_id
        return distinctPeople;
    }
	render()
	{
		//console.log("cutom RAG==>", this.state.custom_rags.additional_rags);
        //if (this.state.task_data == null) {
            //return (<div>Loading...</div>);
        //}
       console.log("complete modal props,", this.props);
	   console.log("complete modal state,", this.state);
       //console.log("taskId,", this.props.taskId,this.state.task_data)
       //console.log("require_remediation",this.state.require_remediation)
		let module_configs = this.props.module_configs;
		let cmputils = new CMPUtils();
		let addtional_rags_assoc = cmputils.getCustomRagAssoc(module_configs);
        return (
            <div>
                <MRModalContainer style={{zIndex : 1000}}>
                    <MRModalHeader>
                        <MRModalHeaderText>{this.state.testing_activity}</MRModalHeaderText>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel style={{color:"#FF0000", marginLeft:"40%"}}>{this.state.submit_msg}</MRModalLabel>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalSummary>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Testing Activity</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{this.state.testing_activity}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Description</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.testing_description}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Risk Area</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{this.state.risk_area_name}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Frequency</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{this.state.testing_freq}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Owner </MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{Store.getStoreData('contact_list')[this.state.testing_owner]}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Testing </MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Objectives</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.testing_objectives}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>High Level </MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Testing Method</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.testing_method}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Additional</MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Guidance</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.additional_guidance}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"15%"}}>
                                <MRModalLabelTitle>Useful Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"85%", float: "right"}}>
									<div style={{padding: "10px"}}>
										<Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
										<Style.ModalNameInputDiv>
												    {
												        (() => {
															let spl_dropzone_flg = 'UsefulResources';
															if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
																return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} onRemoveFile={this.filesRemoved} showFileButton={false} spl_dropzone_flg={spl_dropzone_flg}/>;
															}else{
																return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} onRemoveFile={this.filesRemoved} showFileButton={false} spl_dropzone_flg={spl_dropzone_flg}/>;
															}
														})()
													}

										</Style.ModalNameInputDiv>
									</div>
									<div style={{clear: "both", marginTop:"10px"}}></div>

                            </PartialDiv>
                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
							{
								(() => {
									if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
										return <MRModalLabelTitle >Testing Activities Undertaken</MRModalLabelTitle>;
									}
								})()
							}
                            </PartialDiv>
                            <div style={{marginLeft : "250px" , marginTop : "-100px", width:"78%"}}>
							{
								(() => {
									if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
										return  <div dangerouslySetInnerHTML={{__html: this.state.testing_activity_undertaken}} />;
									}
								})()
							}
                            </div>
							<div style={{clear: "both",marginTop: "85px"}}></div>
                        </MRModalSummary>


                        <div style={{clear: "both",marginTop: "25px"}}></div>
                        <PartialDiv style={{width:"100%"}}>
							{/*
                            {
                                (() => {
                                    if(this.state.cur_files_json !== null) {
                                        return <Style.ModalNameLabelDiv style={{marginLeft:"30px", width:"16%"}}>Attachments</Style.ModalNameLabelDiv>
                                    }
                                })()
                            }


                            <Style.ModalNameInputDiv style={{ minHeight:'100px', height: 'auto'}}>
                            {
                                 (() => {
                                     if(this.state.cur_files_json !== null) {
                                                         return(
															<div>
															{
																this.state.curBinFiles.map((f, index) => {
																	if("type" in f){
																	console.log("f", f);
																	const file_parts = f.name.split(".");
																	let ext = "PDF";
																	switch(file_parts[file_parts.length - 1]) {
																	  case 'jpeg': ext = 'JPG';break;
																	  case 'jpg' : ext = 'JPG';break;
																	  case 'png' : ext = 'PNG'; break;
																	  case 'docx' : ext = 'DOC'; break;
																	  case 'doc' : ext = 'DOC'; break;
																	  case 'msg' : ext = 'MSG'; break;
																	  case 'txt' : ext = 'TXT'; break;
																	  case 'ppt' : ext = 'PPT'; break;
																	  case 'pptx' : ext = 'PPTX'; break;
																	  case 'xls' : ext = 'XLS'; break;
																	  case 'xlsx' : ext = 'XLSX'; break;
																	  default: ext = 'PDF'; break;
																	}
																	console.log('ext', ext);
																	let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
																	file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
																	//file_icon_var = ext === 'JPEG' ? 'JPG' : ext;
																	const file_icon = `https://cmpplus.co.uk/common-resource/Resource/Dropzone/${ext}.png`;
																	return (
																	  <div style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
																		<div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
																			<div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
																			  <img src={file_icon} height="100px" width="auto" />
																			</div>
																			<div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
																			  <div><strong title={f.name} style={{color: '#000000'}}>{f.name.length > 22 ? f.name.substring(0, 22) : f.name}</strong><br /><br /></div>
																			  <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</div>
																			</div>
																			<div style={{clear: "both"}}></div>
																		</div>
																	  </div>
																	);
																	}

																})
															}
															<div style={{clear: "both"}}></div>
															</div>
									                    )
                                     } else {
                                         return <div>No existing attachments.</div>
                                     }
                                 })()

                            }
                            </Style.ModalNameInputDiv>
                             <div style={{display: 'flex'}}>
                                 <Style.ModalNameLabelDiv style={{width:"20%", marginLeft: "3%"}}>Attachments</Style.ModalNameLabelDiv>
                                     <Dropzone  style={{marginLeft: "3%"}} onFilesAdded={this.filesLoaded} />
                             </div>
							*/}
                        </PartialDiv>

						<div style={{clear: "both",marginTop: "25px"}}></div>
						<PartialDiv style={{width:"22%", paddingLeft:"32px"}}>
						    {
								(() => {
									if(this.state.ready){
										if('cur_lane' in this.state.task_data){
											if(this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED"){
												return <MRModalLabelTitle>Testing Activities Undertaken</MRModalLabelTitle>;
											}
										}else{
											return <MRModalLabelTitle>Testing Activities Undertaken</MRModalLabelTitle>;
										}
									}

								})()
							}

                        </PartialDiv>
                        <PartialDiv style={{width:"78%"}}>
						    {
								(() => {
									if(this.state.ready){
										if('cur_lane' in this.state.task_data){
											if(this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED"){

												return (<div style={{width: "calc(98% - 10px)" , marginLeft: "calc(28% + 10px)" , marginTop :"-9px" }}><HtmlEditor content={this.state.testing_activity_undertaken} field_name={'testing_activity_undertaken'}/></div>)
											}
										}else{

											return (<div style={{width: "calc(98% - 10px)", marginLeft: "calc(28% + 10px)" , marginTop :"-9px" }}><HtmlEditor content={this.state.testing_activity_undertaken} field_name={'testing_activity_undertaken'}/></div>)
										}
									}

								})()
							}

                        </PartialDiv>

						<div style={{clear: "both",marginTop: "25px"}}></div>
						<PartialDiv style={{width:"15%"}}>
							<MRModalLabelTitle style={{marginLeft:"32px"}}>Attachments</MRModalLabelTitle>
						</PartialDiv>
						<PartialDiv style={{width:"78%" }}>
								<div style={{width:"calc(98% - 10px)" , marginLeft : "90px"}}>

									<Style.ModalNameInputDiv>
												    {
												        (() => {
															if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
																return <Dropzone onFilesAdded={this.filesLoadedType2} initFiles={this.state.cur_files_json} onRemoveFile={this.filesRemovedType2} showFileButton={false}/>;
															}else{
																return <Dropzone onFilesAdded={this.filesLoadedType2} initFiles={this.state.cur_files_json} onRemoveFile={this.filesRemovedType2}/>;
															}
														})()
													}

									</Style.ModalNameInputDiv>
								</div>
								<div style={{clear: "both", marginTop:"10px"}}></div>
                        </PartialDiv>

						{
							(() => {
								if(this.props.module_configs.custom_rags.enabled){
								return(
									<div>
									<div style={{clear: "both",marginTop: "100px"}}></div>
									<PartialDiv style={{width:"15%", paddingLeft:"32px"}}>
										<MRModalLabelTitle>Risk Rating</MRModalLabelTitle>
									</PartialDiv>
									<PartialDiv style={{width:"25%"}}>
										{
											(() => {
												if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
													return <MRModalLabel style={{whiteSpace: "pre-line"}}>{addtional_rags_assoc[this.state.rag].nickname}</MRModalLabel>;
												}else{
													return (
														<CompleteModalSelect onChange={this.handleChangeRag} name="rag" style={{width: "80%"}} value={capitalize(this.state.rag)}>
														{
															(() => {
																let customragsdropdown = [];
																let custom_rags = this.state.custom_rags;
																//console.log("additional_rags==>", custom_rags)
																customragsdropdown.push(<option key={'0'} value={'0'}>Select</option>);
																custom_rags.forEach((item) => {
																	if(item.isActive === 1){
																		customragsdropdown.push(<option key={item.id} value={item.id}>{item.nickname}</option>);
																	}
																	if(item.isActive === 0 && this.state.rag === item.id) {
																		customragsdropdown.push(<option key={item.id} value={item.id}>{item.nickname}</option>);
																	}
																});
																return customragsdropdown;
															})()
														}
														</CompleteModalSelect>
													);
												}
											})()
										}

									</PartialDiv>
									<PartialDiv style={{width:"25%", paddingLeft:"32px"}}>
										<MRModalLabelTitle>Requires Remediation</MRModalLabelTitle>
									</PartialDiv>
									<PartialDiv style={{width:"calc(25% - 30px)"}}>
										{
											(() => {
												if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
													return <MRModalLabel style={{whiteSpace: "pre-line"}}>{this.state.require_remediation}</MRModalLabel>;
												}else{
													return (
														<CompleteModalSelect onChange={this.handleChange} name="require_remediation" value={this.state.require_remediation}>
														{
															(() => {
																let require_remedy_dropdown = [];
																require_remedy_dropdown.push(<option key={'No'} value={'No'}>No</option>)
																require_remedy_dropdown.push(<option key={'Yes'} value={'Yes'}>Yes</option>)
																return require_remedy_dropdown;
															})()
														}
														</CompleteModalSelect>
													);
												}
											})()
										}

									</PartialDiv>
									</div>
								);
								}
							})()
						}
						{
							(() => {
								if(!this.props.module_configs.custom_rags.enabled && 'cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
								return(
									<div>
									<div style={{clear: "both",marginTop: "100px"}}></div>
									<PartialDiv style={{width:"15%", paddingLeft:"32px"}}>
										<MRModalLabelTitle>Risk Rating</MRModalLabelTitle>
									</PartialDiv>
									<PartialDiv style={{width:"25%"}}>
										<MRModalLabel style={{whiteSpace: "pre-line"}}>{addtional_rags_assoc[this.state.rag].nickname}</MRModalLabel>

									</PartialDiv>
									<PartialDiv style={{width:"25%", paddingLeft:"32px"}}>
										<MRModalLabelTitle>Requires Remediation</MRModalLabelTitle>
									</PartialDiv>
									<PartialDiv style={{width:"calc(25% - 30px)"}}>
										<MRModalLabel style={{whiteSpace: "pre-line"}}>{this.state.require_remediation}</MRModalLabel>

									</PartialDiv>
									</div>
								);
								}
							})()
						}
						<div style={{clear: "both",marginTop: "85px"}}></div>
                        <MRModalDivider />
						{
							(() => {
								if(this.state.actionModalOpen){
									return(<div>
                                        <InactiveOverlay />
                                        </div>)
								}
							})()
						}
                        {
                            (() => {
                                if(this.state.require_remediation === 'Yes') {
									//console.log("actions length in remedian==>"+this.state.actions.length);
                                    if(this.state.actions.length === 0)
                                    {
                                        return(<div>
                                        <AddActions actionModalOpen={this.actionModalOpen} refreshAddActionValue={this.refreshAddActionValue} curLane={('cur_lane' in this.state.task_data) ? this.state.task_data.cur_lane : this.state.cur_lane} action_extra_json={this.state.action_extra_json} parent_ref_id={this.state.ref_id} parent_task_id={this.state.task_id}  unique_testing_act_id={this.state.unique_testing_act_id} processSaveAction={this.processSaveAction}/>
                                        <OverviewInfoBody>
                                            <div style={{textAlign: "center",fontStyle: "italic", color: "#000000"}}>There are currently no Actions Associated with this Testing Activity.</div>
                                        </OverviewInfoBody>
                                        </div>)
                                    }else {
                                        return(<div>
                                            <AddActions actionModalOpen={this.actionModalOpen} refreshAddActionValue={this.refreshAddActionValue} curLane={('cur_lane' in this.state.task_data) ? this.state.task_data.cur_lane : this.state.cur_lane} action_extra_json={this.state.action_extra_json} parent_ref_id={this.state.ref_id} parent_task_id={this.state.task_id} unique_testing_act_id={this.state.unique_testing_act_id} processSaveAction={this.processSaveAction}/>
                                            <OverviewInfoBody>
                                                <CSLTable add={false} processData={this.processMyActionData} headerText={'Actions List'} tableRows="5" refreshCallback={this.refreshCallback} />
                                            </OverviewInfoBody>
                                        </div>)
                                    }
                                }
                            })()
                        }
                    </MRModalBody>
                    <MRModalFooter>
                        {
                            (() => {
								//console.log("Store.getStoreData('role'),",Store.getStoreData('role'),this.props.taskId);
								if(this.props.taskId > 0){
								//if(typeof this.state.task_data.cur_lane !== "undefined"){
									//console.log("render cur_lane,",this.state.task_data.cur_lane);
									if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED"){
										return (
										<div>
										{
											(() => {
                                                 if(Store.getStoreData('role') === 'team' && module_configs.general.enable_approval){
													 if(this.state.task_data.cur_lane.toString() !== "COMP_CMP_APPROVAL_REQUEST"){
														 if(this.state.task_data.cur_lane.toString() === "COMP_CMP_SAVED"){
															 return (
															 <div>
															 <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>
															 </div>
															 );
														 }else{
															 return (
															 <div>
															 <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.initiateSendForApproval}>Send for Approval</CatBtn>
															 <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
															 </div>
															 );
														 }
													 }

												 }else{
													 if(this.state.task_data.cur_lane.toString() !== "COMP_CMP_APPROVAL_REQUEST"){
														 return (
														 <div>
														 <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>
														 <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
														 </div>
														 );
													 }else{
														 return (
														 <div>
														{
															(() => {
																if(module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding){
																	return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>
																}else{
																	return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.doApprove}>Approve</CatBtn>;
																}
															})()
														}


														 <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
														 </div>
														 );
													 }
												 }
											})()
										}


										</div>
										)
									}
								//}
								}else{
									//console.log("Store.getStoreData('role'),",Store.getStoreData('role'));
									return (
									<div>
										{
											(() => {
                                                 if(Store.getStoreData('role') === 'team' && module_configs.general.enable_approval){
													 return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.initiateSendForApproval}>Send for Approval</CatBtn>;
												 }else{
													 return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>;
												 }
											})()
										}
									<CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
									</div>
									)
								}
                            })()
                        }


                        <CatBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black", float: "right"}} onClick={this.closeModal}>Cancel</CatBtn>
                        <div style={{clear: "both"}}></div>
                        {
                            (() => {
								if(Store.getStoreData('role') === 'team' && this.state.show_initiate_approval){
									return(
									<div>
										<MRModalDivider />
										<div style={{clear: "both", marginTop: "20px"}}></div>
										<div style={{float : "right" , width : "calc(25% - 30px)" , marginRight : "100px"}}>
											<div style={{fontWeight: "600"}}> Select a Manager : </div>
											<div style={{marginLeft : "50%" , marginTop: "-38px"}}>
												<CompleteModalSelect onChange={this.handleChange} name="approval_contact" value={this.state.approval_contact} style={{ width : "200px" }}>
												{
													(() => {
														let contactsdropdown = [];
														let contacts = this.processcontactrole();
														contacts.sort(GetSortOrder("name"));
														contactsdropdown.push(<option key={0} value={0}>Select User</option>)
														contacts.forEach((item) => {
															contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
														})
														return contactsdropdown;
													})()
												}
												</CompleteModalSelect>
												{
													(() => {
														if(this.state.IsApprovalContactProvided){
															return(
																<div style={{width: "200px" ,marginLeft: "50%" }}>
																	<div style={{clear: "both"}}></div>
																	<label style={{color:"#FF0000"}}>Please select a manager.</label>
																</div>
																)
														}
													})()
												}
											</div>
										</div>
										<div style={{clear: "both", marginTop: "60px"}}></div>
										<MRModalDivider />
										<div style={{clear: "both", marginTop: "20px"}}></div>
										<div style={{float : "right" , width : "calc(25% - 30px)" , marginRight : "2px"}}>
											<CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.requestSendForApproval}>Submit</CatBtn>
										</div>
										<div style={{clear: "both", marginTop: "20px"}}></div>
									</div>


									);
								}
                            })()
                        }
						{/*<MRModalDivider />*/}
                    </MRModalFooter>
                </MRModalContainer>
				<AlertBox alertParam = {this.state.alert_param}/>
				<CSLLoader/>
            </div>
		);
	}
}

export default CompleteModal;
