import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import Dropzone from '../Common/Dropzone.js'
import { saveAs } from 'file-saver';
import * as Style from '../Common/StyledComponents';
import DatePicker from 'react-date-picker';
import HtmlEditor from '../Common/HtmlEditor';
import '../../App.css';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';

const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor:pointer;
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;
const RequestClarificationButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7
    color: #ffffff;
    width: auto;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;


const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const UnselectedPillCA = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
`;
const SelectedPillCA = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
`;

const ModeltopText = styled.div`
    display: grid;
    grid-gap: 26px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6ebd6;
    color: #f6ce76;
    background-color: #f6ebd6;
`;
const CSLDateCover = styled.div`
    margin-top: 20px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;

class ShowFAQModal extends React.Component
{
    state = {
        faq_header_text: '',
        faq_answer: '',
        faq_query: '',
        faq_id: 0,
        binFiles: [],
        cur_files_json: null,
        tags:[],
        ref_id: '',
        read_only: false,
        ready:false,
        approved_by: 0,
        approved_date: '',
        expiry_date: new Date(),
        task_id: 0,
        gc_company: '',
        show_only_expiry: '',
        show_car:false,
        query: '',
        custom_fields: {},
        enabled_topics: false,
        topic_id: "0",
        topic: {},
        other_topic: '',
        list_answrere: [],
        company_id : 0,
        cur_recever_id: 0,
        derived_task_ids:{},
        from_car: false,
        alert_param: null,
        is_loading: false
    };

    constructor(props) {
        super(props);
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        this.setState({binFiles: files});
    }

    closeModal = (event) => {
        event.preventDefault();
        if(this.state.from_car){
            this.props.rfereshFAQ();
        }else{
            let show_car = this.state.show_car;
            if(show_car){
                this.setState({show_car: false});
            }else{
                this.props.refreshParentCallback();
            }
        }
        
    }

    redirecrCarRegister =(event) => {
        event.preventDefault();
        this.props.refreshCarRegister();
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    UpdateFAQ(flag){
      let msg = flag === "delete" ? "Are you sure you want to delete this FAQ?" : "Are you sure you want to reapprove this FAQ?";
      let title = 'Confirm';
      // alert("Mandatory Fields must be populated to progress");
      let parms = {};
      parms["flag"] = flag;
      let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: true, alertHandler: this.confirmAlertHandler, stack: {parms: parms}}
      this.setState({alert_param: alert_param})
    }

    confirmAlertHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let parms = stack.parms;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));

         let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
         let selectedtags = {};
         this.state.tags.forEach((tag) => {
             if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
         })
         let command = parms.flag === "delete" ? "send_faq_delete": "send_reapprove_faq";
         
         let dateobj = new Date(this.state.expiry_date);
         let curmonth = parseInt(dateobj.getMonth());
         let currmonth = curmonth + 1;
         var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
         expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

         let postData = { 
             "command": command,
              faq_query: this.state.faq_query, 
              faq_answer: answer_summary,
              tags: selectedtags,
              binFiles : bin_files,
              task_id: this.state.task_id,
              faq_id: this.state.faq_id,
              'only_update': true
          };

          if(command === 'send_reapprove_faq') {
              postData['expiry_date'] = expiry_date;
          }
          if(this.state.enabled_topics) {
              postData['topic'] = this.state.topic;
              postData['topic_value'] = this.state.topic['value'];
          }

          console.log("postData 12", postData);

         let api = new APICall();
        api.indexCommand(postData, this.processApprovalFAQ);
        this.setState({is_loading: true})

    }



    UpdateFAQ_old(flag){
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let altmsg = flag === "delete" ? "Are you sure you want to delete this FAQ?" : "Are you sure you want to reapprove this FAQ?";
        let confirm = window.confirm(altmsg);
        if (confirm === true) {
           let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
           let selectedtags = {};
           this.state.tags.forEach((tag) => {
               if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
           })
           let command = flag === "delete" ? "send_faq_delete": "send_reapprove_faq";
           
           let dateobj = new Date(this.state.expiry_date);
           let curmonth = parseInt(dateobj.getMonth());
           let currmonth = curmonth + 1;
           var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
           expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

           let postData = { 
               "command": command,
                faq_query: this.state.faq_query, 
                faq_answer: answer_summary,
                tags: selectedtags,
                binFiles : bin_files,
                task_id: this.state.task_id,
                faq_id: this.state.faq_id,
                'only_update': true
            };

            if(command === 'send_reapprove_faq') {
                postData['expiry_date'] = expiry_date;
            }

            console.log("postData", postData);

           let api = new APICall();
          api.command(postData, this.processApprovalFAQ);
        }else {
            return false;
        }
        
    }

    processApprovalFAQ =(result) => {
        let msg = result.error_msg;
        let title = 'Alert';
        let parms = {};
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.AlertHandler, stack: {parms: parms}}
        this.setState({alert_param: alert_param, is_loading: false})
        
    }

    AlertHandler = (result,stack) => {
        this.setState({alert_param: null})
        if(this.state.from_car){
            this.props.rfereshFAQ();
        }else{
            this.props.refreshFaqRegister();
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    getFaqRegisterDate = (faq_id) => {
        // let {faq_answer, faq_query, faq_id, cur_files_json, tags} = this.state;
        let postData = { "command": "get_faq", "faq_id": faq_id };
        let api = new APICall();
        api.indexCommand(postData, this.processSingleFaqdata);
        this.setState({is_loading: true})
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }



    getDate = (input_date) => {
      console.log("input_date", input_date)
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        let dateParts = dateStr.split('-'); 
        let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
     
    }

    processSingleFaqdata = (result) => {
        let {faq_header_text,faq_answer, faq_query, faq_id, cur_files_json, 
          tags, ref_id, read_only, expiry_date, task_id, gc_company, show_only_expiry, 
          query, custom_fields,enabled_topics,topic_id,topic,other_topic,list_answrere, company_id, derived_task_ids, from_car} = this.state;
        from_car = this.props.is_car;
        if(result.result['faq'].is_active === 0) {
            alert("FAQ was deleted");
            if(from_car){
                this.props.rfereshFAQ();
            }
            return;
        }
      console.log("result", result);
      let property_json = JSON.parse(result.result['faq'].property_json);

      if (property_json.bin_files.length !== 0) {
          property_json.bin_files.forEach((item) => {
              item.uid = "uid" in item ? item.uid : this.genKey(10);
          })
      }

      if(Utils.isBase64(property_json.answer)){
        faq_answer = window.decodeURIComponent(atob(property_json.answer))
      }else{
        faq_answer = property_json.answer;
      }
      // query = property_json.query;
      faq_query = property_json.query;
      faq_header_text = property_json.query;
      faq_id = result.result['faq'].id;
      cur_files_json = property_json.bin_files;
      tags = property_json.tags;
      ref_id = property_json.ref_id;
      task_id = result.result['faq'].task_id;
      let gc_assoc = Store.getStoreData('gc_assoc');
      gc_company = parseInt(result.result['faq'].company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(result.result['faq'].company_id)%100000).toString()].company_name;

      // let published_date = ('published_date' in property_json) ? parseInt(property_json.published_date) : new Date();
      company_id = result.result['faq'].company_id;
      let faq_comp = parseInt(company_id) < 100000 ? company_id : company_id%100000;
      list_answrere = Utils.showCompanyUsers(faq_comp,'answrer');

      let published_date = result.result['faq'].published_date;
      var c_date = new Date(published_date);
      let dateStr = c_date.toLocaleDateString('en-GB').replace(/,/g, '')

      
      console.log("result.result['faq'].expire_date", result.result['faq'].expire_date)
      

      let published_by = ('published_by' in property_json) ? Store.getStoreData('contacts')[property_json.published_by] : Store.getStoreData('contacts')[result.result['faq'].contact_id]
      // let published_by = result.result['faq'].contact_id === 0 ? "Api" : Store.getStoreData('contacts')[result.result['faq'].contact_id]
      expiry_date = this.getDate(result.result['faq'].expire_date) === '01/01/1970' ? this.state.expiry_date : new Date(result.result['faq'].expire_date);
      show_only_expiry = this.getDate(result.result['faq'].expire_date);
      let rawtags = Store.getStoreData('tags');
      Utils.log("rawtags", rawtags);
      let otags = [];
      let x = SelectedPill;
      let y = UnselectedPill;
      if(Store.getStoreData('role') === 'ca' || Store.getStoreData('role') === 'jr_ca' || Store.getStoreData('role') === 'team') {
        read_only = true;
        x = SelectedPillCA;
        y = UnselectedPillCA
      }
      rawtags.forEach((top) => {
            if(tags[top.ID.toString()] ===  top.TagName) {
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: x,
                    selected: true
                });
            }else{
            otags.push({
                name: top.TagName,
                id: top.ID,
                styledcomponent: y,
                selected: false
            });
        }
          
      })
      let stagnames = [];
      otags.forEach((item) => {
          stagnames.push(item.name);
      })
      stagnames.sort();
      let ftags = [];
      for (let i=0; i<stagnames.length; i++) {
          for (let j=0; j<otags.length; j++) {
              if (stagnames[i] === otags[j].name) {
                  ftags.push(otags[j]);
              }
          }
      }
      //custome fields
      let moduleconfig = Store.getStoreData('moduleconfig');
      let store_topic = Store.getStoreData('topics_exclude_other');
      if(moduleconfig.enabled_topics && store_topic.length > 0){
        enabled_topics = true;
        if('topic' in property_json && 'value' in property_json.topic){
            topic = property_json.topic;
            topic_id = topic.id;
        }else{
            topic_id = store_topic[0].id;
            topic = store_topic[0];
        }
      }

      custom_fields = ("custom_fields" in moduleconfig) ? moduleconfig.custom_fields : {};

      custom_fields.custom_terminology_fields.forEach((item) => {
          if(item.type==="Dropdown"){
              item['value'] = item.dropdown_options[0]
          }{
              item['value'] = ''
          }
      });

      custom_fields.additional_fields.forEach((item) => {
          if(item.type==="Dropdown"){
              item['value'] = item.dropdown_options[0]
          }else{
              item['value'] = ''
          }
      });

    if('derived_task_ids' in property_json){
        derived_task_ids = property_json.derived_task_ids;
    }

    
    this.setState({faq_header_text,faq_answer, faq_query, faq_id, cur_files_json, tags: ftags, 
        ref_id, read_only, ready: true, approved_by:published_by, 
        approved_date:dateStr, expiry_date, task_id, gc_company: gc_company, 
        show_only_expiry, custom_fields,enabled_topics,topic_id,topic,other_topic, list_answrere, company_id,derived_task_ids, from_car, is_loading: false});
    }

    componentDidMount()
    {
        this.getFaqRegisterDate(this.props.faq_id);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.getFaqRegisterDate(this.props.faq_id);
        }
    }

    handleDownload = (index) => {
        let cur_file = this.state.cur_files_json[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        //console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        //console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    onExpireDateChange = (date) => {
        console.log("date", date);
        this.setState({ expiry_date: date })
    }

    showCarRequestModal = (event) => {
        event.preventDefault();
        let show_car = this.state;
        show_car = this.state.show_car ? false: true;
        this.setState({show_car: show_car});
    }

    handleChangeTopic = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        for(let t of Store.getStoreData('topics')) {
            if(t.id===event.target.value){
                topic = t;
            }
        }
        this.setState({ [event.target.name] : event.target.value, topic: topic})
    }

    handleChangeOther = (event) => {
        event.preventDefault();
        let topic = this.state.topic; 
        topic.value = event.target.value;
        this.setState({topic: topic})
    }

    generateReferenceField = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                {
                    (() => {
                        if (field.type === "Alpha") {
                            return (<Style.ModalInput type="text" name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}/>)
                        } else if(field.type === "Dropdown") {
                            return (
                                <Style.MRModalSelect name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}>
                                    {
                                        field.dropdown_options.map((opt, index) => {
                                            return(
                                                <option key={index} value={opt}>{opt}</option>
                                            );
                                        })
                                    }
                                </Style.MRModalSelect>
                            );
                        }else{
                            return (<Style.ModalInput type="text" name={field.name} value={field.value} onChange={this.handleAddlField(field.type, data_container)}/>)
                        }
                    })()
                }
                </Style.ModalNameInputDiv>
            </div>
        );
    }

    generateadditionalField = (data, data_container) => {
        return (
            <div key={data.name} style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{data.nickname}</Style.ModalNameLabelDiv>
                {
                    (() => {
                        let fieldhtml = <div></div>;
                        switch(data.type) {
                            case "Alpha":
                                fieldhtml = this.createAlphaField(data, data_container);
                                break;
                            case "Numeric":
                                fieldhtml = this.createNumericField(data, data_container);
                                break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            default:
                                fieldhtml = fieldhtml;
                        }
                        return fieldhtml;
                    })()
                }
            </div>
        );
    }

    createAlphaField = (data, data_container) => {
        return (<Style.ModalNameInputDiv><Style.ModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} /></Style.ModalNameInputDiv>);
    }

    createNumericField = (data, data_container) => {
        return (<Style.ModalNameInputDiv><Style.ModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} min={0}/></Style.ModalNameInputDiv>);
    }
    createDropdownField = (data, data_container) => {
        return (
            <Style.ModalNameInputDiv>
                <Style.MRModalSelect onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    {
                        data.dropdown_options.map((opt, index) => {
                            return(
                                <option key={index} value={opt}>{opt}</option>
                            );
                        })
                    }
                </Style.MRModalSelect>
            </Style.ModalNameInputDiv>
        );
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let custom_fields = this.state.custom_fields;
        console.log(custom_fields[data_container.toString()])
        custom_fields[data_container.toString()].forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })    
        this.setState({custom_fields: custom_fields, toggle:false})
    }

    createClarification = (submit_form) => (event) => {
        if(this.state.query === '') {
            let alert_param = {title: 'Warning', message: 'The question cannot be blank.', ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        let custom_fields = {}
        if(this.state.custom_fields.enabled) {
            custom_fields = this.state.custom_fields;
            let is_alert = false;
            let is_numeric = false;
            Utils.log("custom_fields",custom_fields )
            custom_fields['additional_fields'].forEach((item) => {
                    if(item.required){
                        if(item.value === ""){
                            is_alert = true;
                        }
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                }
                            }
                        }
                    }
            })
            custom_fields['custom_terminology_fields'].forEach((item) => {
                    if(item.required){
                        if(item.value === ""){
                            is_alert = true;
                        }
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                }
                            }
                        }
                    }
            })

            if(is_numeric) {
                let alert_param = {title: 'Warning', message: 'Please enter numeric value.', ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param, 'is_disabled': false})
                return false;
            }
            if(is_alert) {
                let alert_param = {title: 'Warning', message: 'Mandatory Fields must be populated to progress', ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param, 'is_disabled': false})
                return false;
            }
        }

        let confirm = true;
        if(submit_form === 1) {
            let msg = 'Changes are not possible after submission, so please ensure your question is complete.';
            let title = 'Confirm';
            let parms = {};
            parms["submit"] = submit_form;
            parms["custom_fields"] = custom_fields;
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: true, alertHandler: this.confirmClarifyHandler, stack: {parms: parms}}
            this.setState({alert_param: alert_param})
            return false;

        }

        if(confirm === true) {
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            let postData = { "command": "add_query", 
                    query:this.state.query, 
                    submit:submit_form, 
                    bin_files: bin_files, 
                    custom_fields: custom_fields,
                    from_faq: true,
                    faq_id: this.state.faq_id,
                    company_id: this.state.company_id,
                    cur_recever_id: this.state.cur_recever_id,
                    faq_ref: this.state.ref_id,
                    task_id: 0,
                    derived_task_ids: this.state.derived_task_ids
                };
                if(this.state.enabled_topics) {
                    postData['topic'] = this.state.topic;
                }
            let api = new APICall();
            Utils.log("postData", postData)
            api.indexCommand(postData, this.processAddQuery);
            this.setState({is_loading: true})
        }else{
            return false;
        }
    }


    confirmClarifyHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let parms = stack.parms;
        let postData = { "command": "add_query", 
                query:this.state.query, 
                submit:parms.submit, 
                bin_files: bin_files, 
                custom_fields: parms.custom_fields,
                from_faq: true,
                faq_id: this.state.faq_id,
                company_id: this.state.company_id,
                cur_recever_id: this.state.cur_recever_id,
                faq_ref: this.state.ref_id,
                task_id: 0,
                derived_task_ids: this.state.derived_task_ids
            };
            if(this.state.enabled_topics) {
                postData['topic'] = this.state.topic;
            }
        let api = new APICall();
        Utils.log("postData", postData)
        api.command(postData, this.processAddQuery);
    }

    processAddQuery = (result) => 
    {

        let msg = "Your advice request has been submitted to the compliance team.";
        let title = "Alert";
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertprocessAddQuery, stack: {id: 0}}
        this.setState({alert_param: alert_param, is_loading: false})
    }

    alertprocessAddQuery = (result, stack) => {
      if(!result || stack.prevent) {
          this.setState({alert_param: null})
          return
      }
      this.setState({alert_param: null})
      this.props.refreshFaqRegister();

    }

    sameAlertremoveHandler = (result, stack) => {
      if(!result || stack.prevent) {
          this.setState({alert_param: null})
          return
      }
      this.setState({alert_param: null})

    }

    

    render()
    {
        // console.log(this.state);
        if(!this.state.ready) {
            return(<CSLLoader />);
        }
        console.log(this.state);
        return (
            <Style.ModalContainer>
            {
                (() => {
                    if(this.state.is_loading) {
                        return <CSLLoader style={{position: 'absolute'}} />
                    }
                })()
            }
                {
                    (() => {
                        if(this.state.show_car) {
                            return <Style.ModalHeaderText>Submit Advice Request</Style.ModalHeaderText>
                        }else{
                            return(<Style.ModalHeaderText>{this.state.ref_id} - {this.state.faq_header_text.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</Style.ModalHeaderText>)
                        }

                    })()
                }
                <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <Style.ModalBody>
                    <Style.ModalBodyInner>
                        
                        {
                            (() => {
                                if(this.state.show_car) {
                                    return <ModeltopText style={{fontWeight:"600",color: "#f6ce76", marginLeft: "10px", width: "95%"}}>This advice request relates to FAQ Ref: {this.state.ref_id}</ModeltopText>
                                }else{
                                    return <ModeltopText style={{fontWeight:"600",color: "#f6ce76", marginLeft: "10px", width: "95%"}}>This FAQ was submitted and published by {this.state.approved_by} on {this.state.approved_date}  </ModeltopText>
                                }
                            })()
                        }


                        {
                            (() => {
                                if(this.state.show_car) {
                                    return(<Style.ModalContainertop>
                                                <Style.ModalNameLabelDiv>CAR Reference</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Style.ModalNameInputDiv>N/A (New Advice request)</Style.ModalNameInputDiv>
                                                </Style.ModalNameInputDiv>
                                                {
                                                    (() => {
                                                        if(this.state.enabled_topics) {
                                                            return(
                                                                <div style={{display:"contents"}}>
                                                                    <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                                        <Style.ModalNameInputDiv>
                                                                            <Style.MRModalSelect name="topic_id" value={this.state.topic_id} onChange={this.handleChangeTopic}>
                                                                                {
                                                                                    Store.getStoreData('topics').map((item) => {
                                                                                        {
                                                                                            return (
                                                                                                <option key={item.id} value={item.id}>{item.topic}</option>
                                                                                            );
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Style.MRModalSelect>
                                                                        </Style.ModalNameInputDiv>
                                                                </div>
                                                                )
                                                        }
                                                    })()
                                                }

                                                {
                                                    (() => {
                                                        if(this.state.enabled_topics && this.state.topic_id === "other") {
                                                            return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                                        }
                                                    })()

                                                }

                                                {
                                                    (() => {
                                                        if(Store.getStoreData('moduleconfig').specific_user_assignment_enabled && Store.getStoreData('moduleconfig').requesting_user_assignment_enabled) {
                                                            return(
                                                                <div style={{display:"contents"}}>
                                                                    <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                        <Style.MRModalSelect name="cur_recever_id" value={this.state.cur_recever_id} onChange={this.handleChange}>
                                                                            {
                                                                                this.state.list_answrere.map((item) => {
                                                                                    {
                                                                                        return (
                                                                                            <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                                                        );
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Style.MRModalSelect>
                                                                    </Style.ModalNameInputDiv>
                                                                </div>
                                                                )

                                                        }
                                                    })()
                                                }

                                                <Style.ModalNameLabelDiv>Advice Request</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Style.ModalTextArea name="query" value={this.state.query.replace(/'+/g,"'")} onChange={this.handleChange} />
                                                </Style.ModalNameInputDiv>
                                                {
                                                    (() => {
                                                        if(this.state.custom_fields.enabled) {
                                                            if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                                const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                                    return item.name === "reference_number"
                                                                })

                                                                return this.generateReferenceField(field, "custom_terminology_fields");
                                                            }
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.custom_fields.enabled) {
                                                            if (this.state.custom_fields.additional_fields.length !== 0) {
                                                                let custom_html = [];
                                                                this.state.custom_fields.additional_fields.forEach((item) => {
                                                                    const field = this.generateadditionalField(item, "additional_fields");
                                                                    custom_html.push(field);
                                                                })
                                                                return custom_html;
                                                            }
                                                        }
                                                    })()
                                                }
                                                <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                                </Style.ModalNameInputDiv>
                                            </Style.ModalContainertop>)
                                }else{
                                    return(
                                        <Style.ModalContainertop>
                                            {
                                                (() => {
                                                    if(Store.getStoreData('has_gc')) {
                                                        return (<div style={{display:"contents"}}>
                                                                    <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                      <Style.ModalNameInputDiv>{this.state.gc_company}</Style.ModalNameInputDiv>
                                                                    </Style.ModalNameInputDiv>
                                                                </div>
                                                                )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if(this.state.enabled_topics) {
                                                        return(
                                                            <div style={{display:"contents"}}>
                                                                <Style.ModalNameLabelDiv>Select a Topic</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                        <Style.MRModalSelect name="topic_id" value={this.state.topic_id} onChange={this.handleChangeTopic}>
                                                                            {
                                                                                Store.getStoreData('topics').map((item) => {
                                                                                    {
                                                                                        return (
                                                                                            <option key={item.id} value={item.id}>{item.topic}</option>
                                                                                        );
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Style.MRModalSelect>
                                                                    </Style.ModalNameInputDiv>
                                                            </div>
                                                            )
                                                    }
                                                })()
                                            }

                                            {
                                                (() => {
                                                    if(this.state.enabled_topics && this.state.topic_id === "other") {
                                                        return <div style={{display:"contents"}}><Style.ModalNameLabelDiv></Style.ModalNameLabelDiv><Style.ModalNameInputDiv><Style.ModalInput type="text" name="other_topic" value={this.state.topic.value.replace(/'+/g,"'")} onChange={this.handleChangeOther} /></Style.ModalNameInputDiv></div>
                                                    }
                                                })()

                                            }
                                            <Style.ModalNameLabelDiv>Question</Style.ModalNameLabelDiv>
                                            <Style.ModalNameInputDiv>
                                                <Style.ModalTextArea name="faq_query" defaultValue={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.read_only} />
                                            </Style.ModalNameInputDiv>
                                            <Style.ModalNameLabelDiv>Answer</Style.ModalNameLabelDiv>
                                            <Style.ModalNameInputDiv>
                                                
                                                {
                                                    (() => {
                                                        if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                                            return <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.faq_answer} />;
                                                        } else {
                                                            return (<div style={{backgroundColor: '#FFFFFF'}}>
                                                                        <div dangerouslySetInnerHTML={{__html: this.state.faq_answer}} style={{textAlign: "justify", padding: "10px"}}></div>
                                                                    </div> ) 
                                                        }
                                                    })()
                                                }
                                            </Style.ModalNameInputDiv>

                                            <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                            <Style.ModalNameInputDiv>
                                                {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />*/}
                                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />

                                            </Style.ModalNameInputDiv>

                                            <Style.ModalNameLabelDiv>Tags</Style.ModalNameLabelDiv>
                                            <Style.ModalNameInputDiv style={{ padding:"0px",marginTop:"20px",backgroundColor:'#fff'}}>
                                                {
                                                   this.state.tags.map((tag, index) => {
                                                    if(this.state.read_only){
                                                        return (<tag.styledcomponent key={index}>{tag.name}</tag.styledcomponent>);
                                                    }else{
                                                        return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                                    }
                                                            
                                                        })
                                                    
                                                }
                                            </Style.ModalNameInputDiv>
                                            <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                            {
                                                (() => {
                                                    if(Store.getStoreData('role') === 'admin_manager'){
                                                        return(                            
                                                            <CSLDateCover>
                                                                <DatePicker
                                                                        onChange={this.onExpireDateChange}
                                                                        clearIcon={null}
                                                                        calendarIcon={null}
                                                                        locale={"en-GB"}
                                                                        value={this.state.expiry_date}
                                                                        />
                                                            </CSLDateCover>
                                                            )
                                                    }else{
                                                        return (<Style.ModalNameInputDiv style={{ padding:"0px",marginTop:"20px"}}>{this.state.show_only_expiry}</Style.ModalNameInputDiv>)
                                                    }
                                                })()
                                            }

                                        </Style.ModalContainertop>)
                                }
                            })()
                        }

                    </Style.ModalBodyInner>
                </Style.ModalBody>
                <Style.CancelSubmitButtonOuterContainer>
                    <CancelButton onClick={this.closeModal}>Close</CancelButton>
                    {
                        (() => {
                            if(this.state.show_car) {
                                return <SubmitButton onClick={this.createClarification(1)}>Submit</SubmitButton>
                            }else{
                                return(<div style={{display:"inline"}}>
                                        {
                                            (() => {
                                                if(Store.getStoreData('moduleconfig').allow_faq_clarify_question && !this.state.from_car) {
                                                    return(<div style={{display:"inline"}}>
                                                            <RequestClarificationButton onClick={this.showCarRequestModal}>Request Clarification</RequestClarificationButton>
                                                            </div>)
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if(Store.getStoreData('role') === 'admin_manager') {
                                                    return(<div style={{display:"inline"}}>
                                                            <SaveButton onClick={() => this.UpdateFAQ('delete')}>Delete</SaveButton>
                                                            <SubmitButton onClick={() => this.UpdateFAQ('reapprovd')}>Update</SubmitButton>
                                                            </div>);
                                                } 
                                            })()
                                        }
                                    </div>)
                            }
                        })()
                    }
                </Style.CancelSubmitButtonOuterContainer>
                <AlertBox alertParam = {this.state.alert_param} />
            </Style.ModalContainer>
        );
    }
}
export default ShowFAQModal;