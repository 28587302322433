import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import Dropzone from '../Common/Dropzone.js';
import * as Style from '../Common/StyledComponents';
import DataLostWarningModal from './DataLostWarningModal';
import DatePicker from 'react-date-picker';
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';
import AlertBox from '../Common/AlertBox.js';
import CSLLoader from '../Common/CSLLoader';

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #a0a0a0;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;

const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-weight: bold;
	font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 13px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 13px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1002;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 95%;   
    margin-bottom: 10px;
	top: 5%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #DEDEDE;*/
    color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
	font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;
const ActionSelect = styled.select`    
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 92%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
	font-weight: bold;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1005;
  opacity: 0.8;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    background-color: #EDF1F1;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const CSLDateCover = styled.div`
    margin-top: 0px;
    width: 250px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;

function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
class AddActions extends React.Component
{
    state = { 
        showModal: false,
		disabled_button : false,
        binFiles: [],
		curBinFiles: [],
        action_title: "",
        actionDescription: "",
		action_status: "",
        assignedTo: 0,
		IsActionTitleProvided : false,
		IsActionAssignedToProvided : false,
		is_form_modified : false,
		open_data_lost_warning_modal : false,
		due_date : new Date(),
		first_request : true,
		view_mode : false,
		task_id : 0,
		parent_task_id : 0,
		alert_param : null,
		temp_id : null,
		
    }

	componentDidMount()
    {
		//window.scrollTo(0, 0);
		//console.log("Store.getStoreData('contacts') in risk area modal==>"+JSON.stringify(Store.getStoreData('contacts')));
		
        //if(typeof this.props.action_extra_json !== 'undefined'){
			//this.setState({ due_date : new Date(), action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false, showModal: true } , ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
		//}
        //console.log("add actions componentDidMount");
		let task_id = ('task_id' in this.props.action_extra_json) ? this.props.action_extra_json.task_id : this.state.task_id;
		if(task_id !== this.state.task_id){
			console.log("task_id==>",task_id,this.props.parent_ref_id,this.props.action_extra_json);
			//console.log("I am in componentDidUpdate of AddActions");
			let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : false;
			//console.log(task_id+"<=task_id   view_mode==>"+view_mode+"<==>"+JSON.stringify(this.props.action_extra_json));
			//let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : this.state.view_mode;
			if(view_mode === true){
				this.setState({ due_date : new Date(this.props.action_extra_json.due_date), action_title: this.props.action_extra_json.action_title , actionDescription : this.props.action_extra_json.action_description , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : true , task_id : task_id , parent_task_id : this.props.parent_task_id , parent_ref_id : this.props.parent_ref_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
			}else{
				if(parseInt(task_id) > 0 || typeof this.props.action_extra_json.temp_id !== 'undefined'){
					console.log("in all life",this.props.action_extra_json.temp_id);
					this.setState({ due_date : new Date(this.props.action_extra_json.due_date), action_title: this.props.action_extra_json.action_title , actionDescription : this.props.action_extra_json.action_description , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false , task_id : task_id , parent_task_id : this.props.parent_task_id, parent_ref_id : this.props.parent_ref_id , temp_id : this.props.action_extra_json.temp_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});							
				}else{
					console.log("not in all life",this.props.action_extra_json.temp_id);
					this.setState({ due_date : new Date(), action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false, task_id : 0 , parent_task_id : this.props.parent_task_id, parent_ref_id : this.props.parent_ref_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
				}
			}
		}		
    }

    componentDidUpdate(prevProps) {
		
		if (prevProps !== this.props) {
			
            //if(this.state.first_request){
				//console.log("add actions componentDidUpdate2");
				
				let task_id = ('task_id' in this.props.action_extra_json) ? this.props.action_extra_json.task_id : this.state.task_id;

				if(task_id !== this.state.task_id){
					//console.log("task_id==>",task_id,this.props.parent_ref_id,this.props.action_extra_json);
					//console.log("I am in componentDidUpdate of AddActions");
					let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : false;
					//console.log(task_id+"<=task_id   view_mode==>"+view_mode+"<==>"+JSON.stringify(this.props.action_extra_json));
					//let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : this.state.view_mode;
					if(view_mode === true){
						this.setState({ due_date : this.props.action_extra_json.due_date, action_title: this.props.action_extra_json.action_title , actionDescription : this.props.action_extra_json.action_description , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : true , task_id : task_id , parent_task_id : this.props.parent_task_id , parent_ref_id : this.props.parent_ref_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
					}else{
						if(parseInt(task_id) > 0 || typeof this.props.action_extra_json.temp_id !== 'undefined'){
							console.log("in all life",this.props.action_extra_json.temp_id);
							this.setState({ due_date : this.props.action_extra_json.due_date, action_title: this.props.action_extra_json.action_title , actionDescription : this.props.action_extra_json.action_description , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false , task_id : task_id , parent_task_id : this.props.parent_task_id, parent_ref_id : this.props.parent_ref_id , temp_id : this.props.action_extra_json.temp_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});							
						}else{
							console.log("not in all life",this.props.action_extra_json.temp_id);
							this.setState({ due_date : new Date(), action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false, task_id : 0 , parent_task_id : this.props.parent_task_id, parent_ref_id : this.props.parent_ref_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
						}
					}
				}
			//}

		}
		
	}
	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() +1) < 10 ? '0'+(date.getMonth() +1) : (date.getMonth() +1);
		var dt = (date.getDate() < 10 ) ? '0'+(date.getDate()) : (date.getDate());
		return dt +'/'+ month + '/'+date.getFullYear();
	}	
    
    showAddAction = (index) => (event) => {
		//console.log("in show action");
        event.preventDefault();
        this.setState({ due_date : new Date(), action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false, showModal: true , first_request : true , view_mode : false , task_id : 0 , parent_task_id : this.state.parent_task_id} , ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
    }
    closeAddActionModal = (event) => {
		event.preventDefault();
        
        if(this.state.is_form_modified){
            //this.props.refreshParent('openDataLostWarningModal')
			this.setState({open_data_lost_warning_modal : true});
        }else{
            this.setState({ showModal: false , first_request : true ,view_mode : false , task_id : 0 , parent_task_id : 0} , ()=>{this.props.actionModalOpen({'status' : false})});
        }
    }
	filesRemoved = (jsonObj) => {
		if(jsonObj.files_to_show_size === 0){
			this.setState({curBinFiles: [] , is_form_modified : false});
		}
	}
	refreshState  = (val) => {
		if(val === null){
			this.setState({open_data_lost_warning_modal : false , showModal: false} , ()=>{this.props.actionModalOpen({'status' : false})});
		}else{
			this.setState({open_data_lost_warning_modal : false } , ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
		}
	}
    filesLoaded = (jsonObj) => {
        let me = Store.getStoreData('cmp-loggedin_user_info');
		let curBinFiles = this.state.curBinFiles;

		let file_exists = {};	
		let curBinFiles_1 = [];	
        if(jsonObj.command === 'add'){
			for(let i=0; i < jsonObj.files.length; i++) {
				jsonObj.files[i].name = jsonObj.files[i].name.replace('&', '');
				if(!('user_id' in jsonObj.files[i])) {
					jsonObj.files[i].user_id = me.ID;
					jsonObj.files[i].upload_date = Date.now();
					//jsonObj.files[i].type = "followup_action_resource";
				}
				if(typeof file_exists[jsonObj.files[i].name] === "undefined"){
					curBinFiles.push(jsonObj.files[i]);
					file_exists[jsonObj.files[i].name] = true;
				}
				curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));	
				//curBinFiles.push(jsonObj.files[i]);
			}
			//console.log('files in Modal', files);
			this.setState({curBinFiles: curBinFiles_1 , is_form_modified: true});
		}else{//delete
			for(let i=0; i < curBinFiles.length; i++) {
				if(curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()){
					curBinFiles.splice(i,1);
				}
			}
			this.setState({curBinFiles: curBinFiles});
		}
    }
	
    processcontactrole = () => {
        var parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		var contactmap = [];
		let distinctPeople = [];
		if(parent_has_group_companies === false){
			var contacts = Store.getStoreData('contacts')
			var userrole = Store.getStoreData('contactrole')
			console.log("userrole",userrole.length)
			console.log("contacts",contacts.ID)
			
			for( let i=0; i<userrole.length; i++) {
				for (let j=0; j<contacts.length; j++) {
					if ((userrole[i].user_id === contacts[j].ID) && (userrole[i].role === 'admin_manager' || userrole[i].role === 'risk_owner' || userrole[i].role === 'team' || userrole[i].role === 'plan_admin' || userrole[i].role === 'subcat_owner')) {
						contactmap.push({ID:contacts[j].ID,name:contacts[j].ContactName}) 
					}
				}
			}
		}else{
			var gc_assoc = Store.getStoreData('gc_assoc');
			var gc_companies = Store.getStoreData('cmp-gc_companies');
			let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
			let user_wise_gcs = Store.getStoreData('user_wise_gcs');
			let member_group_comp_ids = Store.getStoreData('member_group_comp_ids');
            let role = Store.getStoreData('role');			
			
			let ref_id = this.props.parent_ref_id;
			console.log("parent_ref_id,",ref_id);
			let parent_gc_id = ref_id.split('-')[1];
			
			let gc_comp ={};
            //Object.keys(gc_assoc).forEach(key => {
				if(parseInt(parent_gc_id) !== 0)gc_comp = gc_companies.find(item => item.is_gc === true && parseInt(item.id) === parseInt(parent_gc_id) );
				
				if(parseInt(parent_gc_id) === 0)gc_comp = gc_companies.find(item => item.is_gc === false );
			//});
            let selected_gc_id = gc_comp.id;			
			//var gc_list = gc_assoc[selected_gc_id];
			let included_gcs = {};
			let users_of_gcs = {};
			if(parseInt(parent_gc_id) !== 0)included_gcs[parent_gc_id.toString()] = 1;
			if(parseInt(parent_gc_id) === 0)included_gcs['0'] = 1;
			Object.keys(gc_assoc).forEach(gc_id => {
				if(gc_assoc[gc_id].is_gc && included_gcs[gc_id.toString()]){
					Object.keys(gc_assoc[gc_id].users).forEach(contact_id => {
						users_of_gcs[contact_id] = gc_assoc[gc_id].users[contact_id];
					});
				}
				if(!gc_assoc[gc_id].is_gc && included_gcs['0']){
					Object.keys(gc_assoc[gc_id].users).forEach(contact_id => {
						users_of_gcs[contact_id] = gc_assoc[gc_id].users[contact_id];
					});
				}				
			});
			console.log("users_of_gcs,",users_of_gcs);
			if(role === 'admin_manager'){
				Object.keys(users_of_gcs).forEach(user => {
					if ((users_of_gcs[user].role == 'admin_manager' || users_of_gcs[user].role == 'risk_owner' || users_of_gcs[user].role == 'team' || users_of_gcs[user].role == 'plan_admin' || users_of_gcs[user].role == 'subcat_owner')) {
						contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName}) 
					}

				});				
			}
            if(role === 'risk_owner' || role === 'team' || role === 'plan_admin' || role === 'subcat_owner'){			
				Object.keys(users_of_gcs).forEach(user => {
					if ((users_of_gcs[user].role == 'admin_manager' )) {
						contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName}) 
					}
					if ((users_of_gcs[user].role == 'risk_owner'  || users_of_gcs[user].role == 'team'  || users_of_gcs[user].role == 'plan_admin'  || users_of_gcs[user].role == 'subcat_owner') && (loggedin_user_info.ID in gc_assoc[selected_gc_id].users && gc_assoc[selected_gc_id].is_gc)) {
						contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName}) 
					}
					if ((users_of_gcs[user].role == 'risk_owner'  || users_of_gcs[user].role == 'team' || users_of_gcs[user].role == 'plan_admin'  || users_of_gcs[user].role == 'subcat_owner') &&  !gc_assoc[selected_gc_id].is_gc ) { //this is critical for parent
						console.log("contacts included_gcs=>",user_wise_gcs[users_of_gcs[user].ID]);
						if(users_of_gcs[user].ID in user_wise_gcs){
							Object.keys(user_wise_gcs[users_of_gcs[user].ID]).forEach(gc_id => {
								if(member_group_comp_ids.includes(gc_id) && users_of_gcs[user].ID in user_wise_gcs[users_of_gcs[user].ID][gc_id])contactmap.push({ID:users_of_gcs[user].ID,name:users_of_gcs[user].ContactName})
							});						
						}

					}
				});
			}
		}
        //console.log("contactmap===>",contactmap)
        let unique = [];
		for( let i = 0; i < contactmap.length; i++ ){
		  if( !unique[contactmap[i].ID]){
			distinctPeople.push(contactmap[i]);
			unique[contactmap[i].ID] = 1;
		  }
		}
        //console.log("contactmap===>",contactmap)selected_gc_id
        return distinctPeople;
    }			

    submitAddActionValue = (event) => {
		
		this.setState({disabled_button: true} , ()=>{
			if(this.state.action_title.trim() == '' || this.state.assignedTo === 0){
				if(this.state.action_title.trim() == ''){
					this.setState({IsActionTitleProvided: true , disabled_button : false}); 
				}				

				if(this.state.assignedTo === 0 ){
					this.setState({IsActionAssignedToProvided: true ,  disabled_button : false})
				}
				
			}else{		
				event.preventDefault();

				//console.log("action_title",this.state.action_title,"actionDescription",this.state.actionDescription,"assignedTo",this.state.assignedTo, "due_date" : this.state.due_date ,"binFiles",this.state.binFiles)
				 if(parseInt(this.state.task_id) > 0){ // edit / update
						let risk_json = {};
						var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
											
						let actionsObject = {};
						actionsObject.action_title = this.state.action_title;
						actionsObject.action_description = this.state.actionDescription;
						actionsObject.action_assignto = this.state.assignedTo;
						//actionsObject.actionBinFiles = bin_files;
						actionsObject.due_date = this.state.due_date;					

						risk_json.actions = actionsObject;                
						let headerinfo = {}
						headerinfo.risk_json = risk_json;
						headerinfo.unique_testing_act_id = this.props.unique_testing_act_id ;
						//headerinfo.bin_files = action_binfiles;			
						let task = {
							"task_data": { "module_id": "cmp", "last_action": "COMP_CMP_FOLLOWUP_CREATE", "ref_id" : this.props.unique_testing_act_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assignedTo, 'task_type' : 'COMP_CMP_FOLLOWUP', 'parent_task_id' : this.state.parent_task_id, 'cur_lane': 'COMP_CMP_FOLLOWUP_SAVED', due_date: this.state.due_date},
							"object_data": {headerinfo},
							"action_data": {action: 'COMP_CMP_FOLLOWUP_SAVED',actiondate :Date.now(), assigned_to: this.state.assignedTo, cur_lane : 'COMP_CMP_FOLLOWUP_SAVED'}
						};
						task.object_data.bin_files = bin_files;
						
						let postData = {"command": "saveFollowupActionTask", "task_id" : this.state.task_id, "task_json" :task , "assigned_to" :this.state.assignedTo , "parent_ref_id" : this.state.parent_ref_id};
						let api = new APICall();
						let that = this;
						//api.command(postData, this.processSaveCompleteAction);
						api.command(postData, function callback(result){let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: that.processSaveAction, stack: {task_id : that.state.task_id , parent_task_id : that.state.parent_task_id , action_obj : actionsObject , parent_ref_id : that.state.parent_ref_id , task_json : result.task_json} }; that.setState({alert_param: alert_param});});					
				 }else{ // add new
					var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
					//this.props.refreshAddActionValue(this.state.action_title,this.state.actionDescription,this.state.assignedTo,this.state.binFiles)
					this.props.refreshAddActionValue(this.state.action_title,this.state.actionDescription,this.state.assignedTo,bin_files,this.state.action_status , this.state.due_date,this.state.temp_id);
					this.setState({ action_title: "", actionDescription: "", assignedTo: 0,binFiles: [],showModal: false , IsActionTitleProvided: false , IsActionAssignedToProvided : false , task_id : 0,disabled_button : false}, ()=>{this.props.actionModalOpen({'status' : false})});

				}     
			}
		});		
    }
	processSaveAction = (result , stack) => {
		

			//if(result === false) return;
		    //console.log("processSaveAction stack",stack);	
			this.props.processSaveAction(stack);
			//this.props.actionModalOpen({'status' : false});
			this.setState({alert_param: null , showModal : false , first_request : true ,view_mode : false , task_id : 0 , parent_task_id : 0,disabled_button : false}, ()=>{this.props.actionModalOpen({'status' : false})});
		
	}		
    refreshPage = () => {
        window.location.reload();
    }	
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, is_form_modified: true});            
    }
    onScheduleDateChange = (date) => {
        this.setState({due_date: date});
    }
    handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    render() {
		console.log("AddActionModal state:", this.state);
		console.log("in here showModal",this.state.showModal);
        return(<div>
            {
                (() => {
                    if(this.state.showModal) {
                        return(<div > 
                            <MRModalContainer>
                                <MRModalHeader>
									{
										(() => {
											if(this.state.view_mode){
												return(
													<MRModalHeaderText>View Action</MRModalHeaderText>
													)
											}else{
												return(
													<MRModalHeaderText>Create / Update Action</MRModalHeaderText>
													)
											}
										})()
									}		
                                    <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                                    <div style={{clear: "both"}}></div>
                                </MRModalHeader>
                                <MRModalBody>
                                <RegModalContainertop>
                                    <RegModalNameLabelDiv>Action Title</RegModalNameLabelDiv>
									{
										(() => {
											if(this.state.view_mode){
												return(
													<RegModalNameLabelDiv>{this.state.action_title}</RegModalNameLabelDiv>
													)
											}else{
												return(
													<MRModalInput style={{width: "90%",marginLeft: "25px" , fontWeight: "bold" , color: "#000000"}} name="action_title" onChange={this.handleChange} value={this.state.action_title}/>
													)
											}
										})()
									}		
                                    
									{
										(() => {
											if(this.state.IsActionTitleProvided){
												return(
													<div style={{width: "200px" ,marginLeft: "150px", marginTop: "5px" }}>
														<div style={{clear: "both"}}></div>
														<label style={{color:"#FF0000"}}>Please provide action title.</label>
													</div>
													)
											}  
										})()
									}									
                                </RegModalContainertop>
                                <RegModalContainertop style={{marginTop: "20px"}}>
                                    <RegModalNameLabelDiv>Description</RegModalNameLabelDiv>
									{
										(() => {
											if(this.state.view_mode){
												return(
													<RegModalNameLabelDiv>{this.state.actionDescription}</RegModalNameLabelDiv>
													)
											}else{
												return(
													<FCKTextarea rows="6" style={{width: "90%",marginLeft: "25px" , resize: "none"}} name="actionDescription" onChange={this.handleChange} value={this.state.actionDescription}/>
													)
											}
										})()
									}
                                    
                                </RegModalContainertop>
                                <RegModalContainertop style={{marginTop: "20px"}}>
                                    <RegModalNameLabelDiv>Assigned To</RegModalNameLabelDiv>
									{
										(() => {
											if(this.state.view_mode){
												return(
													<RegModalNameLabelDiv>{Store.getStoreData('contact_list')[this.state.assignedTo]}</RegModalNameLabelDiv>
													)
											}else{
												return(
													<ActionSelect style={{marginLeft: "25px"}} name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo}>
													{
														(() => {
															let contactsdropdown = [];
															let contacts = this.processcontactrole();
															contacts.sort(GetSortOrder("name"));
															contactsdropdown.push(<option key={0} value={0}>Select User</option>)
															contacts.forEach((item) => {
																contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
															})
															return contactsdropdown;        
														})()
													}
													</ActionSelect>
													)
											}
										})()
									}

									{
										(() => {
											if(this.state.IsActionAssignedToProvided){
												return(
													<div style={{width: "200px" ,marginLeft: "150px", marginTop: "5px" }}>
														<div style={{clear: "both"}}></div>
														<label style={{color:"#FF0000"}}>Please select a user.</label>
													</div>
													)
											}  
										})()
									}									
                                </RegModalContainertop>
								<RegModalContainertop style={{marginTop: "20px"}}>
									<RegModalNameLabelDiv>Due Date</RegModalNameLabelDiv>
									{
										(() => {
											if(this.state.view_mode){
												return(
													<RegModalNameLabelDiv>{this.getFormattedDate(this.state.due_date)}</RegModalNameLabelDiv>
													)
											}else{
												return(
													<CSLDateCover style={{display: "inline-block", marginLeft: "27px"}}>
														<DatePicker
																onChange={this.onScheduleDateChange}
																clearIcon={null}
																calendarIcon={null}
																locale={"en-GB"}
																value={this.state.due_date}
																style={{border:"none" ,  fontWeight: "bold"}}
																/>
													</CSLDateCover>
													)
											}
										})()
									}

								</RegModalContainertop>

                                <div style={{display: 'flex',marginTop: "25px"}}>
                                                <Style.ModalNameLabelDiv style={{width:"20%", marginRight: "50px",paddingTop: "3px" , color: "#000000" , fontWeight: "bold"}}>Attachments</Style.ModalNameLabelDiv>
												<Style.ModalNameInputDiv style={{ float: "right" ,marginRight: "50px"}}>
													
												    {
												        (() => {
															if(this.state.view_mode){
																return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} onRemoveFile={this.filesRemoved} showFileButton={false}/>;
															}else{
																return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} onRemoveFile={this.filesRemoved}/>;
															}
														})()
													}
												</Style.ModalNameInputDiv>
                                </div>
                                <div style={{clear: "both"}} ></div>
								<RegModalContainertop style={{marginTop: "20px"}}>
									{
										(() => {
											if(this.state.view_mode){
												return(
													<RegModalNameLabelDiv>Action Note Undertaken</RegModalNameLabelDiv>
													)
											}
										})()
									}									
									{
										(() => {
											if(this.state.view_mode){
												return(
													<RegModalNameLabelDiv>{this.state.action_note_undertaken}</RegModalNameLabelDiv>
													)
											}
										})()
									}

								</RegModalContainertop>
                                <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                                </MRModalBody>
								<MRModalFooter>
                                <div style={{height: '50px', paddingRight: "31px", marginTop: "50px"}}>   
									{
										(() => {
											if(this.state.view_mode){
												return (<div>&nbsp;</div>)
											}else{
												if(!this.state.disabled_button)return <MRModalNextBtn  style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7" }} onClick={this.submitAddActionValue}>Submit</MRModalNextBtn>;
											}
										})()
									}													
                                    
                                    <MRModalCancelBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black"}} onClick={this.closeAddActionModal}>Cancel</MRModalCancelBtn>
                                </div>
								</MRModalFooter>
                            </MRModalContainer>
                        </div>)
                    }
									
                })()
            }
			{

			  (()=>{
				if(this.state.open_data_lost_warning_modal){ 
				  return (
						  <div>
							<InactiveOverlay2 />
							<DataLostWarningModal refreshParentModal={this.refreshState}/>
						  </div>

					)
				}
			  })()
			  
			}
            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Actions</div>
				{
					(() => {
						if(this.props.curLane.toString() !== "COMP_CMP_COMPLETED") {
							return <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddAction(-1)}><FaPlusCircle /></div>
						}
					})()
				}
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
        <AlertBox alertParam = {this.state.alert_param}/>
        <CSLLoader/>		
        </div>)
    }
}
export default AddActions;