import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import MangeTask from './MangeTask';
import CompleteTask from './CompleteTask';
import ApprovarTask from './ApprovarTask';
import CSLLoader from '../../Common/CSLLoader';
import AlertBox from '../../Common/AlertBox';

const FolderContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 75%;
  position: fixed;
  margin: 8px;
  top: 8px;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  border-color: #495E75;
  border-width: 3px;
  box-shadow: 0 0 20px #a7a6a6;
`;
const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;
class TmviewHOC extends React.Component
{
	state = { cur_view: 'add_task',  task_id: 0, ready:false, alert_param: null }
	constructor(props) {
	    super(props);
	}
	componentDidMount()
	{
	    this.processTaskData();
	}

	processTaskData = () => {
		let task_id = this.props.task_id;
		let cur_view = this.state.cur_view;
		let cur_lane = this.props.cur_lane;
		if (typeof(cur_lane) === "undefined"){
			this.getCurlane(task_id);
		}else{
			if(cur_lane === "COMP_GENERIC_COMPLETED") {
				cur_view = "complete";
			}else if(cur_lane === "COMP_GENERIC_APPROVAL_PENDING"){
				cur_view = "approvar_task";
			}else{
				cur_view = "add_task";
			}
			this.setState({ cur_view: cur_view, task_id: task_id, ready: true});
		}
	}

	getCurlane = (task_id) => {
		let postData = { "command" : "get_task_lane", "task_id" : task_id }
		postData['gcs'] = Store.getStoreData("gc_filters")
		let api = new APICall();
		api.command(postData, this.processEditTaskData);
	}

	processEditTaskData = (result) => {
		console.log("result ===>", result.result)
		let cur_view = this.state.cur_view;
		if(result.error_code === 0) {
			let cur_lane = result.result.cur_lane;
			if(cur_lane === "COMP_GENERIC_COMPLETED") {
				cur_view = "complete";
			}else if(cur_lane === "COMP_GENERIC_APPROVAL_PENDING"){
				cur_view = "approvar_task";
			}else{
				cur_view = "add_task";
			}
			this.setState({ cur_view: cur_view, task_id: result.result.task_id, ready: true});
		}else{
			let msg = result.error_msg;
			let title = "Alert";
			let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
			console.log("alert_param", alert_param)
			this.setState({alert_param: alert_param, ready: true, cur_view: 'no_task'})
		}
	}

	/*componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}*/

	/*processTaskData = () => {
		let task_id = this.props.task_id;
		console.log("task id", task_id)
		if(task_id > 0) {
			let postData = { "command" : "get_task_lane", "task_id" : task_id }
			postData['gcs'] = Store.getStoreData("gc_filters")
			let api = new APICall();
			api.command(postData, this.processEditTaskData);
		}else{
			this.setState({ cur_view: 'add_task', task_id: task_id, ready: true});
		}
	}

	processEditTaskData = (result) => {
		console.log("result ===>", result.result)
		let cur_view = this.state.cur_view;
		if(result.error_code === 0) {
			let cur_lane = result.result.cur_lane;
			if(cur_lane === "COMP_GENERIC_COMPLETED") {
				cur_view = "complete";
			}else{
				cur_view = "add_task";
			}
			this.setState({ cur_view: cur_view, task_id: result.result.task_id, ready: true});
		}else{
			let msg = result.error_msg;
			let title = "Alert";
			let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
			console.log("alert_param", alert_param)
			this.setState({alert_param: alert_param, ready: true, cur_view: 'no_task'})
		}
	}*/


	alertremoveHandler = (result, stack) => {
		// let url = Store.getStoreData('is_debug') ? "/" : "/";
		let url = Store.getStoreData('is_debug') ? "/" : "/select/microservice/msmctop/tmview";
		window.location = url;
	}

	render()
	{
		if(!this.state.ready) {
		    return <div><CSLLoader /></div>
		}
		console.log("this.state", this.state)
		return (
		  <FolderContainer>
			    {
			    	(() => {
			    		switch(this.state.cur_view) {
			    		  case 'add_task' : return <MangeTask task_id={this.state.task_id} closeUserDialog={this.props.closeUserDialog}/>; break;
			    		  case 'complete': return <CompleteTask task_id={this.state.task_id} closeUserDialog={this.props.closeUserDialog} />; break;
			    		  case 'approvar_task': return <ApprovarTask task_id={this.state.task_id} closeUserDialog={this.props.closeUserDialog} />; break;
			    		  case 'no_task': return <AlertBox alertParam = {this.state.alert_param} closeUserDialog={this.props.closeUserDialog}/>; break;
			    		 	default: return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>; break
			    		}

			    	})()
			    }
		  </FolderContainer>

		);
	}
}
export default TmviewHOC;
