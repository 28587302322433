import Store from './Store.js';
import moment from 'moment';

class IndexLoader {
    divideAllTasksIndex(flagedValue){
        let flagedObject = {};
        //let flaged_followup_count_object = {}        
        for(let d of flagedValue) {        
          if(d.task_json.object_data.qset_answer.flagged === true){
            flagedObject[d.task_id] = true
          }
        //   let count_followup = 0;
        //   for(let k of followup_flaged_tasks){
        //       if(k.parent_task_id === d.task_id){
        //         count_followup = count_followup + 1;
        //       }
        //   }
        //   flaged_followup_count_object[d.task_id] = count_followup;
        }
        Store.updateStore('flagged_count_object', flagedObject);
        return true;
    }
    
    divideAllShareTasks(parentTaskID){
      let childTasksValue = Store.getStoreData('child_share_task_all');    
      let childArray = [];  
      for(let d of childTasksValue) {
        if(parseInt(d[7]) === parseInt(parentTaskID)){
          let childObject = {};
          let task_json = {task_data: {}, object_data: {headerinfo: {}, qset_answer: {} } }         

          childObject.id = d[0];
          childObject.cur_assigned_to = d[9];
          childObject.due_date = moment.unix(d[5]).format('YYYY-MM-DD');
          childObject.last_action = d[3];
          childObject.created_at = moment.unix(d[8]);			
          childObject.is_active = 1;

          task_json.task_data['actiondate'] = d[10];
          task_json.object_data.headerinfo['Type'] = d[6];
          task_json.object_data['at_type'] = d[11];
          task_json.object_data.qset_answer['flagged'] = d[12];
          task_json.object_data.qset_answer['isReviewed'] = d[13];

          childObject.task_json = JSON.stringify(task_json)
          childArray.push(childObject);
        }
      }
      Store.updateStore('updated_shareTask', childArray);
    }
}

export default new IndexLoader();