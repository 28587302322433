let data = {table_headers: [], data: []};
data.module = {id: 'tmview', display: 'Task Manager'}
data.table_headers = [
    {
        "accessor": "company_id", "nickname": "Company", type: 'alpha', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}
    },
    {
        "accessor": "open", "nickname": "Open", type: 'alpha', width: 1,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}
    },
    {
        "accessor": "overdue", "nickname": "Overdue", type: 'alpha', width: 1,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}
    },
    {
        "accessor": "flagged", "nickname": "Flagged", type: 'alpha', width: 1,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}
    },
    {
        "accessor": "all", "nickname": "All", type: 'alpha', width: 1,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}
    }
   
];

data.sortby = {
    fields: [
        {"accessor": "company_id", "nickname": "Company"}
    ],
    selected: {"accessor": "none", "order": "Descending"}
};
data.groupby = {
    fields: [
        {"accessor": "company_id", "nickname": "Company"}
    ],
    selections: []
};
data.font_size_map = {
    section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
    introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
    header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
    table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
    footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
}

module.exports = data