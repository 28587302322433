import React from 'react';
import Utils from '../../Common/Utils.js';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";
import { usePromiseTracker } from "react-promise-tracker";

const Loader = styled.div`
    position: absolute;
    left: 45%;
    top:45%;
    z-index: 20001;
`

const CSLbucketloader = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div>
        <Loader>
            <ClipLoader
              size={100}
              color={"#123abc"}
              loading={true}
            />
        </Loader>
      </div>
    )
  );
};

export default CSLbucketloader;
