import Store from "../../Common/Store";
class ReportDataNew {
    reportData() {
        let data = {table_headers: [], data: []};
        let lp = Store.getStoreData('language_pack')
        data.module = {id: 'attest', display: 'Attestation'}
		data.table_headers = [
            {
                accessor: "status_text", nickname: lp['managers_attestations_table_status'] === undefined ? 'Status' : lp['managers_attestations_table_status'], type: "alpha", width: 1.1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "document_name", nickname: lp['managers_attestations_table_attestation_name'] === undefined ? 'Attestation Name' : lp['managers_attestations_table_attestation_name'], type: "alpha", width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "followuptask", nickname: lp['tasks'] === undefined ? 'Tasks' : lp['tasks'], type: "alpha", width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "flagged", nickname: lp['flagged'] === undefined ? 'Flagged' : lp['flagged'], type: "numeric", width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "info", nickname: lp['managers_attestations_table_information'] === undefined ? 'Info' : lp['managers_attestations_table_information'], type: "numeric", width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "acknowledge", nickname: lp['assigned'] === undefined ? 'Assigned' : lp['assigned'], type: "numeric", width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "outstanding_acknowledge", nickname: lp['managers_attestations_table_overdue'] === undefined ? 'Overdue' : lp['managers_attestations_table_overdue'], type: "numeric", width: 1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            }
        ];    

        data.sortby = {
            fields: [
                {accessor: "status_text", nickname: lp['managers_attestations_table_status'] === undefined ? 'Status' : lp['managers_attestations_table_status'], type: "alpha", width: 1.2},
                {accessor: "document_name", nickname: lp['managers_attestations_table_attestation_name'] === undefined ? 'Attestation Name' : lp['managers_attestations_table_attestation_name'], type: "alpha", width: 1.8},
                {accessor: "tasks", nickname: lp['tasks'] === undefined ? 'Tasks' : lp['tasks'], type: "numeric", width: 1},
                {accessor: "flagged", nickname: lp['flagged'] === undefined ? 'Flagged' : lp['flagged'], type: "numeric", width: 1},
                {accessor: "info", nickname: lp['managers_attestations_table_information'] === undefined ? 'Info' : lp['managers_attestations_table_information'], type: "numeric", width: 1},
                {accessor: "assigned", nickname: lp['assigned'] === undefined ? 'Assigned' : lp['assigned'], type: "numeric", width: 1},
                {accessor: "overdue", nickname: lp['managers_attestations_table_overdue'] === undefined ? 'Overdue' : lp['managers_attestations_table_overdue'], type: "numeric", width: 1}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {accessor: "status_text", nickname: lp['managers_attestations_table_status'] === undefined ? 'Status' : lp['managers_attestations_table_status'], type: "alpha", width: 1.2},
                {accessor: "document_name", nickname: lp['managers_attestations_table_attestation_name'] === undefined ? 'Attestation Name' : lp['managers_attestations_table_attestation_name'], type: "alpha", width: 1.8},
                {accessor: "tasks", nickname: lp['tasks'] === undefined ? 'Tasks' : lp['tasks'], type: "numeric", width: 1},
                {accessor: "flagged", nickname: lp['flagged'] === undefined ? 'Flagged' : lp['flagged'], type: "numeric", width: 1},
                {accessor: "info", nickname: lp['managers_attestations_table_information'] === undefined ? 'Info' : lp['managers_attestations_table_information'], type: "numeric", width: 1},
                {accessor: "assigned", nickname: lp['assigned'] === undefined ? 'Assigned' : lp['assigned'], type: "numeric", width: 1},
                {accessor: "overdue", nickname: lp['managers_attestations_table_overdue'] === undefined ? 'Overdue' : lp['managers_attestations_table_overdue'], type: "numeric", width: 1}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }
        return data
    }
}

export default new ReportDataNew();