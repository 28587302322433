import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';

const defaultButton = props => <button {...props}>{props.children}</button>;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: auto;
    font-size: 11px;
    height: 20px;
    border-radius: 1px;
    margin-right:3px;
`;
const MRButtonLight = styled.button`
    box-sizing: border-box;
    background-color: #F2F2F3;
    color: #37ada7;
    border: 1px solid #37ada7;
    width: auto;
    font-size: 11px;
    height: 20px;
    border-radius: 1px;
    margin-right:3px;
`;


export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <div className="Table__pagination">
        <div className="Table__prevPageWrapper">
            <MRButton onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}>{'<'}</MRButton>
        </div>
        <div className="Table__visiblePagesWrapper">
          {visiblePages.map((page, index, array) => {
            return (
                <MRButtonLight key={index} onClick={this.changePage.bind(null, page)}>{array[index - 1] + 2 < page ? `...${page}` : page}</MRButtonLight>
            );
          })}
        </div>
        <div className="Table__nextPageWrapper">
            <MRButton onClick={() => {
              if (activePage === this.props.pages) return;
              this.changePage(activePage + 1);
            }}>{'>'}</MRButton>
        </div>
      </div>
    );
  }
}
