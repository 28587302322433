import React from 'react';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";
import { usePromiseTracker } from "react-promise-tracker";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20000;
  opacity: 0.1;
`;

const Loader = styled.div`
    position: fixed;
    left: 45vw;
    top:45vh;
    z-index: 20001;
`

const CSLLoader = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div>
        <InactiveOverlay />
        <Loader>
            <ClipLoader
              size={100}
              color={"#123abc"}
              loading={true}
            />
        </Loader>
      </div>
    )
  );
};

export default CSLLoader;
