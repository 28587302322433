import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaCalendarAlt, FaStarOfLife} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import CSLLoader from '../Common/CSLLoader';
import './AskConflicts.css';
import AlertBox from '../Common/AlertBox';
import DatePicker from 'react-date-picker';
import CSLTextInput from './../Common/CSLTextInput';
import HtmlEditor from './../Common/HtmlEditor';
import Utils from '../../Common/Utils.js';
import moment from 'moment';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #343434;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const ModalNameLabelDivReference1 = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #212121;
    display:inline;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #1E3E62;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #282828;
    padding-left: 24px;
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #282828;
    padding-left: 72px;
    display:inline;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 39%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 10px;
    font-family: 'Montserrat',sans-serif;
    display: inline-block;    
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 50%;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: #758597; 
    margin-left: 50px;
`;
const RegModalNameInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #6d6d6d;
    background-color: #EEEEEE;
`;

class TeamAskConflictModal extends React.Component
{
    state = {
        nature_of_conflict: "",
        risk_description: "",
        steps_to_mitigate_risk: "",
        additional_records_of_disclosure: {
            enabled: "No",
            description: ""
        },
        disclosure_to_third_party: {
            enabled: "No",
            description: ""
        },
        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        companyID: 0,
        due_date: '1970-01-01',
        isdesable: false,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        alert_param: null,
        currentcompanyName: "",
        isLoadedIcon: false,
        conflicts_raised_date: new Date(),
        conflict_title: "",
        conflictTypeList: [],
        conflict_type: '',
        assign_data: null,
        showDescriptionCType: false,
        cTypeDescription: "",
        conflictsOwnerID: '',
        conflictsOwnerLists: [],
        isOwnerUpdtBtn: false,
        isSaveCloseBtn: false,
        removalText: "",
        status: ""
    };
    
    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');        
        console.log('index in componentDidMount', index);        
        console.log("contact_id==>",Store.getStoreData('contact'))
        let gc_company = Store.getStoreData('gc_company');
        let gc_companies = Store.getStoreData('gc_companies');        
        let cur_gc_id = 0;        
        let is_gc = false;  
        let module_config = Store.getStoreData('module_config');
        let types = module_config.conflict_types;
        let conflictTypeList = [];
        for(let k in types){
            if(types[k].isActive === 1){
                let c_obj = {};
                c_obj.id = k
                c_obj.name = types[k].title
                conflictTypeList.push(c_obj);
            }
        }
        conflictTypeList = conflictTypeList.sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} );
        //custom field start                  
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) { 
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        //copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        } 
        console.log("additional_fields===>", additional_fields)

        // let conflictsOwnerLists = [];
        // let contacts = Store.getStoreData('contacts');
        // for(let k in contacts){
        //     conflictsOwnerLists.push({'id': k, 'name': contacts[k]})
        // }
        // conflictsOwnerLists = conflictsOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        //custom field end      
        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);            
            const {additional_records_of_disclosure} = this.state;
            additional_records_of_disclosure.enabled = task_json.object_data.headerinfo.additional_records_of_disclosure.enabled
            // additional_records_of_disclosure.description = task_json.object_data.headerinfo.additional_records_of_disclosure.description
            const {disclosure_to_third_party} = this.state;
            disclosure_to_third_party.enabled = task_json.object_data.headerinfo.disclosure_to_third_party.enabled
            // disclosure_to_third_party.description = task_json.object_data.headerinfo.disclosure_to_third_party.description      

            let lastDigitcurgc_id = parseInt(form_data.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(form_data.company_id)%100000);      
            // let curgc_id = task_json.task_data.group_company_id;
            // let sampleNumber = curgc_id;
            // let lastDigitcurgc_id = sampleNumber % 100000;
            // console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
            let gc_company = Store.getStoreData('gc_company');
            let is_gc = false;
            let currentcompanyName = "";
            let companyUsers = {};
            for(let gc of gc_company) {                
                if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                    is_gc = gc.is_gc;
                    console.log("gc.id", gc.id);
                    currentcompanyName = gc.company_name
                    companyUsers = gc.users;
                }
            }

            let contacts = Store.getStoreData('contacts');
            let conflictsOwnerLists = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contacts){
                        conflictsOwnerLists.push({'id': d, 'name': contacts[d]})
                    }                
                })
                conflictsOwnerLists = conflictsOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            }

            let cur_gc_id = parseInt(lastDigitcurgc_id)
            let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
            let due_date = new Date(form_data.due_date);            
            let isdesable = false;
            if(form_data.cur_lane !== "COMP_CONFLICTS_TEAMSAVE") {
                isdesable = true;
            }
            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = "uid" in item ? item.uid : this.genKey(10);
                })
            }

            let conflicts_raised_date = 'conflicts_raised_date' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.conflicts_raised_date) : new Date()
            let conflict_title = 'conflict_title' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.conflict_title : ''
            let conflict_type = 'conflict_type' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.conflict_type : '';

            console.log("conflict_type===>", conflict_type)
            let conflictsType = Store.getStoreData('conflictstype')            
            let showDescriptionCType = false;
            let cTypeDescription = '';
            console.log("conflict_type===>", conflict_type)
            if(conflict_type in conflictsType && 'isComments' in conflictsType[conflict_type] && parseInt(conflictsType[conflict_type].isComments) === 1){
                showDescriptionCType = true
                cTypeDescription = 'cTypeDescription' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.cTypeDescription : '';
            }

            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : form_data.cur_lane === 'COMP_CONFLICTS_TEAMSAVE' ? additional_fields : [];

            let qtext_nature_of_conflict = task_json.object_data.headerinfo.nature_of_conflict
            if(Utils.isBase64(task_json.object_data.headerinfo.nature_of_conflict)){
                qtext_nature_of_conflict = window.decodeURIComponent(atob(qtext_nature_of_conflict))
            }
            Store.updateStore('nature_of_conflict', null)

            let qtext_risk_description = task_json.object_data.headerinfo.risk_description
            if(Utils.isBase64(task_json.object_data.headerinfo.risk_description)){
                qtext_risk_description = window.decodeURIComponent(atob(qtext_risk_description))
            }
            Store.updateStore('risk_description', null)

            let qtext_steps_to_mitigate_risk = task_json.object_data.headerinfo.steps_to_mitigate_risk
            if(Utils.isBase64(task_json.object_data.headerinfo.steps_to_mitigate_risk)){
                qtext_steps_to_mitigate_risk = window.decodeURIComponent(atob(qtext_steps_to_mitigate_risk))
            }
            Store.updateStore('steps_to_mitigate_risk', null)
            
            let qtext_ard_description = task_json.object_data.headerinfo.additional_records_of_disclosure.description
            if(Utils.isBase64(task_json.object_data.headerinfo.additional_records_of_disclosure.description)){
                qtext_ard_description = window.decodeURIComponent(atob(qtext_ard_description))
            }
            additional_records_of_disclosure.description = qtext_ard_description;
            Store.updateStore('ard_description', null)

            let qtext_dtp_description = task_json.object_data.headerinfo.disclosure_to_third_party.description
            if(Utils.isBase64(task_json.object_data.headerinfo.disclosure_to_third_party.description)){
                qtext_dtp_description = window.decodeURIComponent(atob(qtext_dtp_description))
            }            
            disclosure_to_third_party.description = qtext_dtp_description
            Store.updateStore('dtp_description', null)

            let conflictsOwnerID = 'conflictsOwnerID' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.conflictsOwnerID : ''

            let contact_id = Store.getStoreData('contact_id');
            let isOwnerUpdtBtn = false;            
            if(form_data.cur_lane === "COMP_CONFLICTS_COMPLETE" && conflictsOwnerID.toString() === contact_id.toString() && 'record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true && 'conflict_owners_update' in module_config.general && module_config.general.conflict_owners_update === true){
                isOwnerUpdtBtn = true;
            }

            let actiondata = task_json.action_data;

            let actiondata_lastObject = actiondata[actiondata.length - 1];
            let removalText = "";            
            if('cur_lane' in actiondata_lastObject && actiondata_lastObject.cur_lane === 'COMP_COI_REMOVE' && 'removal_type' in actiondata_lastObject){
                removalText = actiondata_lastObject.removal_type;
                if('removal_description' in actiondata_lastObject && actiondata_lastObject.removal_description !== ''){
                    removalText = actiondata_lastObject.removal_type+" - "+actiondata_lastObject.removal_description
                }                
            }
            
            let status = "";
            if(form_data.cur_lane === "COMP_CONFLICTS_REGISTER") {
                status =  "Registered";
            }else if(form_data.cur_lane === "COMP_CONFLICTS_COMPLETE") {
                status =  "Confirmed";
            }else if(form_data.cur_lane === "COMP_COI_REMOVE") {
                status = "Removed"
            }

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                nature_of_conflict:  qtext_nature_of_conflict,
                risk_description: qtext_risk_description,
                steps_to_mitigate_risk: qtext_steps_to_mitigate_risk,
                additional_records_of_disclosure: additional_records_of_disclosure, 
                disclosure_to_third_party: disclosure_to_third_party,
                curlane: form_data.cur_lane,
                taskid: form_data.id, gc_companies: gc_companies, gc_company: Store.getStoreData('gc_company'), ready: true, cur_gc_id: lastDigitcurgc_id, is_gc: is_gc,
                companyID: companyID, due_date: due_date,isdesable: isdesable,cur_files_json: task_json.object_data.bin_files, currentcompanyName: currentcompanyName,
                conflictTypeList,
                conflicts_raised_date,
                conflict_title,
                conflict_type,
                assign_data,
                showDescriptionCType,
                cTypeDescription,
                conflictsOwnerLists,
                conflictsOwnerID,
                isOwnerUpdtBtn,
                removalText,
                status
            });            
        }else{

            //new section start
            let gc_company = Store.getStoreData('gc_company');
            let update_gc_company = [];
            if('general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
                for(let gc of gc_company) {                
                    if(gc.is_active === 1 && parseInt(gc.id) !== parseInt(Store.getStoreData('curCompanyID'))){
                        update_gc_company.push(gc)
                    }
                }
            }else{
                for(let gc of gc_company) {                
                    if(gc.is_active === 1){
                        update_gc_company.push(gc)
                    }
                }
                // update_gc_company = gc_company;
            }
            if(!Store.getStoreData('has_gc')){ 
                cur_gc_id = update_gc_company[0].id;                    
            }
            //new section end
            // if(gc_companies.length === 0){
            //     console.log("Sourav");
            //     cur_gc_id = parseInt(Store.getStoreData('curCompanyID'));
            // }
            console.log("Sourav=====>",cur_gc_id);
            let due_date = new Date();
            assign_data.additional_fields = additional_fields;

            Store.updateStore('nature_of_conflict', null)
            Store.updateStore('risk_description', null)
            Store.updateStore('steps_to_mitigate_risk', null)
            Store.updateStore('ard_description', null)
            Store.updateStore('dtp_description', null)

            let contacts = Store.getStoreData('contacts');

            let conflictsOwnerLists = [];
            if(gc_companies.length === 0){
                for(let k in contacts){
                    conflictsOwnerLists.push({'id': k, 'name': contacts[k]})
                }   
                conflictsOwnerLists = conflictsOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            }

            console.log("contacts===>", gc_companies.length)

            this.setState({ Ref_ID: "Not Yet Generated", 
                            gc_company: update_gc_company, 
                            gc_companies: gc_companies, ready: true, companyID: cur_gc_id,
                            cur_gc_id: cur_gc_id, is_gc: is_gc, due_date: due_date,
                            conflictTypeList,
                            assign_data,
                            conflictsOwnerLists,
                            // conflictsOwnerID: Store.getStoreData('contact_id')
                        });            	
        }        
    }

    handleChangeCurGc = (evt) => {        
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = true;
        let companyUsers = {};        
        for(let gc of gc_company) {            
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
                companyUsers = gc.users;
            }
        }
        let contacts = Store.getStoreData('contacts');
        let conflictsOwnerLists = [];
        if(Object.keys(companyUsers).length !== 0){
            Object.keys(companyUsers).map((d, index) => {
                if(d in contacts){
                    conflictsOwnerLists.push({'id': d, 'name': contacts[d]})
                }                
            })
            conflictsOwnerLists = conflictsOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        }
        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID, conflictsOwnerLists});        
    }
    closeView = () => {        
        if(Store.getStoreData('isreferenceUrl') === true){            
            this.props.closeModal();
        } else {
            this.props.closeModal();
        }        
    }
    handleAdditionalROD = (event) => {
        console.log(event.target.value)
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.enabled = event.target.value;
        if(event.target.value === 'No'){
            additional_records_of_disclosure.description = "";
        }
        this.setState({ additional_records_of_disclosure })        
    }
    handleThiredParty = (event) => {
        console.log(event.target.value)
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.enabled = event.target.value;
        if(event.target.value === 'No'){
            disclosure_to_third_party.description = "";
        }
        this.setState({ disclosure_to_third_party })        
    }

    updateConfirmConflict = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        const module_config = Store.getStoreData('module_config');
        if(this.state.conflict_title === ''){                
            problems = true
            message += 'Please enter the Conflict title.<br>'
        }
        if(this.state.showDescriptionCType && this.state.cTypeDescription === ''){
            problems = true
            message += 'Please fill the conflicts type description.<br>'
        }
        let nature_of_conflict = Store.getStoreData('nature_of_conflict');
        if(nature_of_conflict === null){
            nature_of_conflict = this.state.nature_of_conflict;
        }
        let risk_description = Store.getStoreData('risk_description');
        if(risk_description === null){
            risk_description = this.state.risk_description;
        }
        let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
        if(steps_to_mitigate_risk === null){
            steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
        }
        if(nature_of_conflict === '' || risk_description === "" || steps_to_mitigate_risk === "") {               
            problems = true
            message += 'Please fill all the information.<br>'          
        }
        let ard_description = Store.getStoreData('ard_description');
        if(ard_description === null){
            ard_description = this.state.additional_records_of_disclosure.description;
        }
        if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === "") {   
            problems = true
            message += 'Please fill the details for information relating to this Disclosure.<br>'                                     
        }
        let dtp_description = Store.getStoreData('dtp_description');
        if(dtp_description === null){
            dtp_description = this.state.disclosure_to_third_party.description;
        }
        if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === "") {   
            problems = true
            message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'                            
        }
        if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {                        
                    if (item.required === true && item.value === "") {
                        fields.push(item.nickname);
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                    numericFields.push(item.nickname);
                                }
                            }
                        }
                    }                                                
                })
            }
            if (fields.length !== 0) {
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ".<br>";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);                    
                problems = true
                message += err_msg +`<br>`   
            }
            if(is_numeric) {                    
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                } 
                problems = true
                message += err_msg_new +`<br>`   
            } 
        } 
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkDefaultalertparam, stack: {}}    
            this.setState({alert_param: alert_param})   
            return         
        }

        let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict)); 
        let risk_description_value = btoa(window.encodeURIComponent(risk_description));  
        let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));  
        let ard_description_value = btoa(window.encodeURIComponent(ard_description));  
        let dtp_description_value = btoa(window.encodeURIComponent(dtp_description)); 

        let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}              
        let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

        let conflictsOwnerID = ''
        if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
            conflictsOwnerID = this.state.conflictsOwnerID
        }

        let comdate = moment.utc(Store.getStoreData('cur_q_modal_data').completed_date).format('YYYY-MM-DD')

        let task_json = JSON.parse(Store.getStoreData('cur_q_modal_data').task_json); 

        let acData = task_json.action_data;
        let acObject = {}
        for(let d of acData){
            if('cur_lane' in d && d.cur_lane === 'COMP_CONFLICTS_COMPLETE'){
                acObject = d
            }
        }
        console.log("acObject===>", acObject)

        task_json['task_data'].actiondate = Date.now();

        task_json['object_data'].headerinfo.nature_of_conflict = nature_of_conflict_value;
        task_json['object_data'].headerinfo.risk_description = risk_description_value;
        task_json['object_data'].headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;
        task_json['object_data'].headerinfo.additional_records_of_disclosure = ard;
        task_json['object_data'].headerinfo.disclosure_to_third_party = dtp;
        task_json['object_data'].headerinfo.conflicts_raised_date = this.state.conflicts_raised_date;
        task_json['object_data'].headerinfo.conflict_title = this.state.conflict_title;
        task_json['object_data'].headerinfo.conflict_type = this.state.conflict_type;
        task_json['object_data'].headerinfo.cTypeDescription = this.state.cTypeDescription;
        task_json['object_data'].headerinfo.additional_fields = this.state.assign_data.additional_fields;
        task_json['object_data'].headerinfo.conflictsOwnerID = conflictsOwnerID;

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];

        acObject.performed_by = Store.getStoreData('contact_id')
        acObject.lastactiontime = moment().unix()

        task_json['action_data'] = acObject;

        let managerIDs = {}
        let userroles = Store.getStoreData('userroles');
        for(let k in userroles){
            if(userroles[k] === 'manager'){
                managerIDs[k] = 1
            }
        }
        let postData = {command: "update_save_coi_task", task_json: task_json, task_id: this.state.taskid, bin_files: bin_files, managerIDs: managerIDs };
        // console.log(moment.utc(Store.getStoreData('cur_q_modal_data').completed_date).format('YYYY-MM-DD'))
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.updateaftersaveandclose);
    }

    updateaftersaveandclose = (result) => {
        let alert_param = {title: 'Success', message: 'The Conflicts has been saved.', ok_text: 'Ok', confirm: false, 
        alertHandler: this.okAfterComplete, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    submitRegisterConflict = (submit_form) => (event) => {
        event.preventDefault();
        let company_id = this.state.companyID;
        let problems = false
        let message = '';
        const module_config = Store.getStoreData('module_config');
        if(submit_form === 1) {
            if(this.state.cur_gc_id === 0){
                // alert('Please select company'); 
                problems = true
                message += 'Please select company.<br>'
            }
            if(this.state.conflict_title === ''){                
                problems = true
                message += 'Please enter the Conflict title.<br>'
            }
            if(this.state.showDescriptionCType && this.state.cTypeDescription === ''){
                problems = true
                message += 'Please fill the conflicts type description.<br>'
            }
            let nature_of_conflict = Store.getStoreData('nature_of_conflict');
            if(nature_of_conflict === null){
                nature_of_conflict = this.state.nature_of_conflict;
            }
            let risk_description = Store.getStoreData('risk_description');
            if(risk_description === null){
                risk_description = this.state.risk_description;
            }
            let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
            if(steps_to_mitigate_risk === null){
                steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
            }
            if(nature_of_conflict === '' || risk_description === "" || steps_to_mitigate_risk === "") {
                // alert('Please fill all the information');   
                problems = true
                message += 'Please fill all the information.<br>'          
            }
            let ard_description = Store.getStoreData('ard_description');
            if(ard_description === null){
                ard_description = this.state.additional_records_of_disclosure.description;
            }
            if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === "") {                
                // alert('Please fill the details for information relating to this Disclosure');
                problems = true
                message += 'Please fill the details for information relating to this Disclosure.<br>'                                     
            }
            let dtp_description = Store.getStoreData('dtp_description');
            if(dtp_description === null){
                dtp_description = this.state.disclosure_to_third_party.description;
            }
            if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === "") {                
                // alert('Please fill the details for Disclosure be made to a 3rd party'); 
                problems = true
                message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'                            
            }
            if(module_config.custom_fields.enabled){
                let fields = [];
                let is_numeric = false;
                let numericFields = [];
                if (module_config.custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {                        
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }                                                
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ".<br>";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);                    
                    problems = true
                    message += err_msg +`<br>`   
                }
                if(is_numeric) {                    
                    let err_msg_new = "";
                    numericFields.forEach((item) => {
                        err_msg_new = err_msg_new + item + ", ";
                    })
                    err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                    if (numericFields.length === 1) {
                        err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                    } else {
                        err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                    } 
                    problems = true
                    message += err_msg_new +`<br>`   
                } 
            } 
            if(problems) {
                let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
                this.setState({alert_param: alert_param})   
                return         
            }

            let conflictsOwnerID = ''
            if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
                conflictsOwnerID = this.state.conflictsOwnerID
            }

            let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict));  
            let risk_description_value = btoa(window.encodeURIComponent(risk_description));  
            let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));  
            let ard_description_value = btoa(window.encodeURIComponent(ard_description));  
            let dtp_description_value = btoa(window.encodeURIComponent(dtp_description)); 

            let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}              
            let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}
                    
            let headerinfo = {};
            headerinfo.nature_of_conflict = nature_of_conflict_value;
            headerinfo.risk_description = risk_description_value;
            headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;        
            headerinfo.additional_records_of_disclosure = ard;        
            headerinfo.disclosure_to_third_party = dtp; 
            headerinfo.conflicts_raised_date = this.state.conflicts_raised_date; 
            headerinfo.conflict_title = this.state.conflict_title;
            headerinfo.conflict_type = this.state.conflict_type;
            headerinfo.cTypeDescription = this.state.cTypeDescription;    
            headerinfo.additional_fields = this.state.assign_data.additional_fields;   
            headerinfo.conflictsOwnerID = conflictsOwnerID;
            
            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            //final_object_data.ref_id = this.state.Ref_ID;
            
            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            console.log("bin_files==>",bin_files)
    
            let task_json = {
                "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(),            
                "cur_assigned_to" : 0, 'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
                "group_company_id": this.state.cur_gc_id},
                "object_data": final_object_data, "group_company_id": company_id,
                "action_data": { "action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_REGISTER","performed_by": Store.getStoreData('contact_id') }
            };
            // console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "create_conflicts_task", task_json: task_json,bin_files: bin_files, 
            submit: submit_form, role: Store.getStoreData('role'), is_gc: this.state.is_gc, gc_company_id: this.state.cur_gc_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processConflictsData);                        
        } else {
            if(this.state.cur_gc_id === 0){
                // alert('Please select company'); 
                problems = true
                message += 'Please select company.<br>'
            }
            let ard_description = Store.getStoreData('ard_description');
            if(ard_description === null){
                ard_description = this.state.additional_records_of_disclosure.description;
            }
            if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === "") {                
                // alert('Please fill the details for information relating to this Disclosure');
                problems = true
                message += 'Please fill the details for information relating to this Disclosure.<br>'                                 
            }
            let dtp_description = Store.getStoreData('dtp_description');
            if(dtp_description === null){
                dtp_description = this.state.disclosure_to_third_party.description;
            }
            if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === "") {                
                // alert('Please fill the details for Disclosure be made to a 3rd party');
                problems = true
                message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'                              
            }
            if(problems) {
                let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
                this.setState({alert_param: alert_param})   
                return         
            }

            let nature_of_conflict = Store.getStoreData('nature_of_conflict');
            if(nature_of_conflict === null){
                nature_of_conflict = this.state.nature_of_conflict;
            }
            let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict)); 

            let risk_description = Store.getStoreData('risk_description');
            if(risk_description === null){
                risk_description = this.state.risk_description;
            }
            let risk_description_value = btoa(window.encodeURIComponent(risk_description));  
            let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
            if(steps_to_mitigate_risk === null){
                steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
            }
            let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));  

            let ard_description_value = btoa(window.encodeURIComponent(ard_description));  
            let dtp_description_value = btoa(window.encodeURIComponent(dtp_description)); 

            let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}              
            let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

            let conflictsOwnerID = ''
            if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
                conflictsOwnerID = this.state.conflictsOwnerID
            }
            
            let headerinfo = {};
            headerinfo.nature_of_conflict = nature_of_conflict_value;
            headerinfo.risk_description = risk_description_value;
            headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;        
            headerinfo.additional_records_of_disclosure = ard;        
            headerinfo.disclosure_to_third_party = dtp;
            headerinfo.conflicts_raised_date = this.state.conflicts_raised_date; 
            headerinfo.conflict_title = this.state.conflict_title;
            headerinfo.conflict_type = this.state.conflict_type; 
            headerinfo.cTypeDescription = this.state.cTypeDescription; 
            headerinfo.additional_fields = this.state.assign_data.additional_fields;   
            headerinfo.conflictsOwnerID = conflictsOwnerID;    
            
            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;                

            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            console.log("bin_files==>",bin_files)
    
            let task_json = {
                "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(),            
                "cur_assigned_to" : 0, 'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0,'due_date' : dueDateStr, 
                'group_company_id': this.state.cur_gc_id},
                "object_data": final_object_data, "group_company_id": company_id,
                "action_data": { "action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_REGISTER","performed_by": Store.getStoreData('contact_id') }
            };
            // console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "create_conflicts_task", task_json: task_json,bin_files: bin_files, 
            submit: submit_form, is_gc: this.state.is_gc,
            gc_company_id: this.state.cur_gc_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processConflictsData);            
        }  
    }

    checkDefaultalertparam = (result, stack) => {        
        this.setState({alert_param: null})
    }

    processConflictsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){
            //alert('Conflict of Interest registered successfully');
            let alert_param = {title: 'Success', message: 'Conflict of Interest registered successfully.', ok_text: 'Ok', confirm: false, 
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})  
        }else {
            //alert('Conflicts of Interest saved successfully');            
            let alert_param = {title: 'Success', message: 'This conflict has been saved but not submitted for Registration yet. You may modify and Register it later.', ok_text: 'Ok', confirm: false, 
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})  
        }
    }
    okAfterComplete = (result, stack) => {
        // console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }   

    updateRegisterConflict = (submit_form) => (event) => {
        event.preventDefault();
        let company_id = this.state.companyID;
        let problems = false
        let message = '';
        const  module_config = Store.getStoreData('module_config');  
        if(submit_form === 1) {
            if(this.state.cur_gc_id === 0){
                // alert('Please select company');
                problems = true
                message += 'Please select company.<br>'  
            }
            if(this.state.conflict_title === ''){                
                problems = true
                message += 'Please enter the Conflict title.<br>'
            }
            if(this.state.showDescriptionCType && this.state.cTypeDescription === ''){
                problems = true
                message += 'Please fill the conflicts type description.<br>'
            }
            let nature_of_conflict = Store.getStoreData('nature_of_conflict');
            if(nature_of_conflict === null){
                nature_of_conflict = this.state.nature_of_conflict;
            }
            let risk_description = Store.getStoreData('risk_description');
            if(risk_description === null){
                risk_description = this.state.risk_description;
            }
            let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
            if(steps_to_mitigate_risk === null){
                steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
            }
            if(nature_of_conflict === '' || risk_description === "" || steps_to_mitigate_risk === "") {
                // alert('Please fill all the information');
                problems = true
                message += 'Please fill all the information.<br>'         
            }
            let ard_description = Store.getStoreData('ard_description');
            if(ard_description === null){
                ard_description = this.state.additional_records_of_disclosure.description;
            }
            if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === "") {                
                // alert('Please fill the details for information relating to this Disclosure');    
                problems = true
                message += 'Please fill the details for information relating to this Disclosure.<br>'                             
            }
            let dtp_description = Store.getStoreData('dtp_description');
            if(dtp_description === null){
                dtp_description = this.state.disclosure_to_third_party.description;
            }
            if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === "") {                
                // alert('Please fill the details for Disclosure be made to a 3rd party');
                problems = true
                message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'                             
            }
            if(module_config.custom_fields.enabled){
                let fields = [];
                let is_numeric = false;
                let numericFields = [];
                if (module_config.custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {                        
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }                                                
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ".<br>";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);                    
                    problems = true
                    message += err_msg +`<br>`   
                }
                if(is_numeric) {                    
                    let err_msg_new = "";
                    numericFields.forEach((item) => {
                        err_msg_new = err_msg_new + item + ", ";
                    })
                    err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                    if (numericFields.length === 1) {
                        err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                    } else {
                        err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                    } 
                    problems = true
                    message += err_msg_new +`<br>`   
                } 
            } 
            if(problems) {
                let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
                this.setState({alert_param: alert_param})   
                return         
            }

            let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict));  
            let risk_description_value = btoa(window.encodeURIComponent(risk_description));  
            let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));  
            let ard_description_value = btoa(window.encodeURIComponent(ard_description));  
            let dtp_description_value = btoa(window.encodeURIComponent(dtp_description)); 

            let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}              
            let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

            let conflictsOwnerID = ''
            if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
                conflictsOwnerID = this.state.conflictsOwnerID
            }
                    
            let headerinfo = {};
            headerinfo.nature_of_conflict = nature_of_conflict_value;
            headerinfo.risk_description = risk_description_value;
            headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;        
            headerinfo.additional_records_of_disclosure = ard;        
            headerinfo.disclosure_to_third_party = dtp; 
            headerinfo.conflicts_raised_date = this.state.conflicts_raised_date; 
            headerinfo.conflict_title = this.state.conflict_title;   
            headerinfo.conflict_type = this.state.conflict_type;
            headerinfo.cTypeDescription = this.state.cTypeDescription; 
            headerinfo.additional_fields = this.state.assign_data.additional_fields;   
            headerinfo.conflictsOwnerID = conflictsOwnerID;
            
            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            //final_object_data.ref_id = this.state.Ref_ID;

            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            console.log("bin_files==>",bin_files)
            
            let task_json = {};
            if(submit_form === 1) {
                task_json = {
                    "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), 
                    'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_REGISTER',
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
                    "group_company_id": company_id},
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_REGISTER"}
                };
            }else{
                task_json = {
                    "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(), 
                    'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_TEAMSAVE',
                    'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0,'due_date' : dueDateStr, "group_company_id": company_id},
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_TEAMSAVE"}
                };
            }
            // console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "update_conflicts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, role: Store.getStoreData('role'), 
            taskid: this.state.taskid, gc_id: company_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processUpdateConflictsData);                 
        }else{
            if(this.state.cur_gc_id === 0){
                //alert('Please select company');
                problems = true
                message += 'Please select company.<br>'
            }
            let ard_description = Store.getStoreData('ard_description');
            if(ard_description === null){
                ard_description = this.state.additional_records_of_disclosure.description;
            }
            if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === ""){                
                //alert('Please fill the details for information relating to this Disclosure');
                problems = true
                message += 'Please fill the details for information relating to this Disclosure.<br>'                                 
            }
            let dtp_description = Store.getStoreData('dtp_description');
            if(dtp_description === null){
                dtp_description = this.state.disclosure_to_third_party.description;
            }
            if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === ""){                
                //alert('Please fill the details for Disclosure be made to a 3rd party');
                problems = true
                message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'                             
            }
            if(problems) {
                let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
                this.setState({alert_param: alert_param})   
                return         
            }

            let nature_of_conflict = Store.getStoreData('nature_of_conflict');
            if(nature_of_conflict === null){
                nature_of_conflict = this.state.nature_of_conflict;
            }
            let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict)); 

            let risk_description = Store.getStoreData('risk_description');
            if(risk_description === null){
                risk_description = this.state.risk_description;
            }
            let risk_description_value = btoa(window.encodeURIComponent(risk_description));  
            let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
            if(steps_to_mitigate_risk === null){
                steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
            }
            let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));  

            let ard_description_value = btoa(window.encodeURIComponent(ard_description));  
            let dtp_description_value = btoa(window.encodeURIComponent(dtp_description)); 

            let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}              
            let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

            let conflictsOwnerID = ''
            if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
                conflictsOwnerID = this.state.conflictsOwnerID
            }
                
            let headerinfo = {};
            headerinfo.nature_of_conflict = nature_of_conflict_value;
            headerinfo.risk_description = risk_description_value;
            headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;        
            headerinfo.additional_records_of_disclosure = ard;        
            headerinfo.disclosure_to_third_party = dtp;
            headerinfo.conflicts_raised_date = this.state.conflicts_raised_date; 
            headerinfo.conflict_title = this.state.conflict_title;
            headerinfo.conflict_type = this.state.conflict_type;  
            headerinfo.cTypeDescription = this.state.cTypeDescription; 
            headerinfo.additional_fields = this.state.assign_data.additional_fields;
            headerinfo.conflictsOwnerID = conflictsOwnerID;    
            
            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            //final_object_data.ref_id = this.state.Ref_ID;
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            console.log("bin_files==>",bin_files)
            
            let task_json = {};
            if(submit_form === 1) {
                task_json = {
                    "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), 
                    'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_REGISTER', 
                    'group_company_id': company_id,
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_REGISTER"}
                };
            }else{
                task_json = {
                    "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(), 
                    'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_TEAMSAVE', 'group_company_id': company_id,
                    'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_TEAMSAVE"}
                };
            } 
            
            // console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "update_conflicts_task", task_json: task_json,bin_files: bin_files, submit: submit_form, 
            taskid: this.state.taskid};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processUpdateConflictsData);            
        }
    }

    processUpdateConflictsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){
            //alert('Conflict of Interest registered successfully');
            let alert_param = {title: 'Success', message: 'Conflict of Interest registered successfully.', ok_text: 'Ok', confirm: false, 
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})  
        }else {
            //alert('Conflicts of Interest saved successfully');            
            let alert_param = {title: 'Success', message: 'Conflicts of Interest updated successfully.', ok_text: 'Ok', confirm: false, 
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})  
        }        
    }

    handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleChangeCType = (evt) => {  
        let conflict_types = Store.getStoreData('module_config').conflict_types;
        let showDescriptionCType = false        
        if(evt.target.value in conflict_types && 'isComments' in conflict_types[evt.target.value] && parseInt(conflict_types[evt.target.value].isComments) === 1){
            showDescriptionCType = true
        }               
        this.setState({[evt.target.name]: evt.target.value, showDescriptionCType});
    }

    onChangeConflictsRaisedDate = (date) => { 
        this.setState({conflicts_raised_date: date});        
    }

    handleChangeDTTP = (evt) => {
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.description = evt.target.value;
        this.setState({ disclosure_to_third_party })
    }
    handleChangeAROD = (evt) => {
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.description = evt.target.value;
        this.setState({ additional_records_of_disclosure })   
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;    						
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break; 
                            // case "Date":
                            //     fieldhtml = this.createDateField(data, data_container);
                            //     break;                           
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }
    createAlphaField = (data, data_container) => {
        console.log("isdesable===>", this.state.isdesable)
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" disabled={this.state.isdesable}/>);        
    }
    handleTextChange = (obj) => {
		console.log("obj:", obj);		
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));		
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}
    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="number" min={0} name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} style={{width: "98%"}} disabled={this.state.isdesable}/>);
    }
    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)    
        console.log("data_container===>", data_container)  
        assign_data[data_container].forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })        	
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.setState({assign_data: assign_data});       
    }
    createDropdownField = (data, data_container) => {
        console.log("data.value==>", data.value)
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value} disabled={this.state.isdesable}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>                                                
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value" disabled={this.state.isdesable}/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    updateConfirmTask = (event) => {
        // event.preventDefault();            
        this.setState({isdesable: false, isSaveCloseBtn: true, isOwnerUpdtBtn: false})
    }   

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let module_config = Store.getStoreData('module_config');          
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
            <MRModalContainer>
            <MRModalHeader>
                <MRModalHeaderText>Conflicts of Interest Disclosure</MRModalHeaderText>
                <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>                
            </MRModalHeader>
            <div style={{paddingTop: '20px'}}>
                <ModalNameLabelDivReference>Conflicts ID</ModalNameLabelDivReference>
                <ModalNameLabelDivReferenceID>{this.state.Ref_ID}</ModalNameLabelDivReferenceID>
                </div>
            <MRModalBody>
                <MRModalBodyInner style={{backgroundColor: "#EDF0F1",fontWeight: "bold"}}>                           
                     Please fill out the below fields to fully submit your Conflicts of Interest Disclosure.                           
                </MRModalBodyInner>
                <div style={{paddingTop: '40px'}}>
                    {
                        (() => {
                            if(Store.getStoreData('q_index') >= 0) {
                                console.log("conflicts_raised_date==>", this.state.conflicts_raised_date)
                                return(<div>
                                    <div style={{display: "inline-block", width: "50%"}}>
                                        <ModalNameLabelDivReference1>Company - </ModalNameLabelDivReference1>
                                        <MRModalLabel style={{display: "inline-block",marginLeft: "35px"}}>{this.state.currentcompanyName}</MRModalLabel>
                                    </div>
                                    <div style={{display: "inline-block", width: "50%"}}>
                                        <ModalNameLabelDivReference1>Date Conflict was raised </ModalNameLabelDivReference1>
                                        <CSLDateCover id="conflicts_raised_date" style={{display: "inline-block"}}>
                                            <DatePicker
                                                    onChange={this.onChangeConflictsRaisedDate}
                                                    clearIcon={null}                                                    
                                                    locale={"en-GB"}
                                                    calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                    value={this.state.conflicts_raised_date}
                                                    disabled={this.state.isdesable}                                                    
                                            />
                                        </CSLDateCover>
                                    </div>
                                </div>
                                )
                            }else{
                                if(Store.getStoreData('gc_companies').length === 0){
                                return(<div>
                                    <div style={{display: "inline-block", width: "50%"}}>
                                            <ModalNameLabelDivReference1>Company - </ModalNameLabelDivReference1>                                        
                                            {
                                                (() => {
                                                    if(this.state.gc_company.length !== 0){
                                                        return(<MRModalLabel style={{display: "inline-block",marginLeft: "35px"}}>{this.state.gc_company[0].company_name}</MRModalLabel>)
                                                    }
                                                })()
                                            }                                         
                                    </div>
                                    <div style={{display: "inline-block", width: "50%"}}>
                                        <ModalNameLabelDivReference1>Date Conflict was raised </ModalNameLabelDivReference1>
                                        <CSLDateCover id="conflicts_raised_date" style={{display: "inline-block"}}>
                                            <DatePicker
                                                    onChange={this.onChangeConflictsRaisedDate}
                                                    clearIcon={null}                                                    
                                                    locale={"en-GB"}
                                                    calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                    value={this.state.conflicts_raised_date}                                                    
                                            />
                                        </CSLDateCover>
                                    </div>
                                </div>)
                                }else{
                                    return(<div>
                                        <div style={{display: "inline-block", width: "50%"}}>
                                            <ModalNameLabelDivReference1>Select Company </ModalNameLabelDivReference1>                                        
                                            <MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc} disabled={this.state.isdesable} style={{width: "50%", height: "35px", marginLeft: "50px"}}>
                                            <option key={0} value={0}>Select</option>
                                                {
                                                        this.state.gc_company.map((gc) => {
                                                        return(
                                                        <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                        );
                                                    })
                                                }
                                            </MRModalSelect>                                       
                                        </div>                                        
                                        <div style={{display: "inline-block", width: "50%"}}>
                                            <ModalNameLabelDivReference1>Date Conflict was raised </ModalNameLabelDivReference1>
                                            <CSLDateCover id="conflicts_raised_date" style={{display: "inline-block"}}>
                                                <DatePicker
                                                        onChange={this.onChangeConflictsRaisedDate}
                                                        clearIcon={null}                                                    
                                                        locale={"en-GB"}
                                                        calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                        value={this.state.conflicts_raised_date}                                                    
                                                />
                                            </CSLDateCover>
                                        </div>
                                    </div>)
                                }
                            }
                        })()
                    }
                
                </div>
                {
                    (() => {
                        if(this.state.status === 'Removed'){
                            return(<div style={{width: "100%"}}>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <MRModalLabel style={{marginTop: "50px"}}>Status</MRModalLabel>
                                <RegModalNameInput style={{width: "50%"}} defaultValue={this.state.status} readOnly/>
                            </div>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <MRModalLabel style={{marginTop: "50px"}}>Reason for Removal</MRModalLabel>
                                <RegModalNameInput style={{width: "80%"}} defaultValue={this.state.removalText} readOnly/>
                            </div>    
                        </div>)
                        }
                    })()
                }
                
                <MRModalLabel style={{marginTop: "50px"}}>Conflict Title</MRModalLabel>                
                <MRModalInput name="conflict_title" value={this.state.conflict_title} onChange={this.handleChange} disabled={this.state.isdesable}/>
                {
                    (() => {
                        if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
                            return(<div>
                                <MRModalLabel style={{marginTop: "50px"}}>Conflict Owner</MRModalLabel>
                                <MRModalSelect id="conflictsOwnerID" name="conflictsOwnerID" onChange={this.handleChange} value={this.state.conflictsOwnerID} disabled={this.state.isdesable}>
                                    <option value="">Select</option>    
                                    {
                                        this.state.conflictsOwnerLists.map((rc,index) => {
                                            return(<option key={index} value={rc.id}>{rc.name}</option>);
                                        })
                                    }                                                           
                                </MRModalSelect>
                            </div>)
                        }
                    })()
                }
                <MRModalLabel style={{marginTop: "50px"}}>Type</MRModalLabel>  
                {
                    (() => {
                        if(!this.state.isdesable){
                            return(<div>
                                <MRModalSelect id="conflict_type" name="conflict_type" onChange={this.handleChangeCType} value={this.state.conflict_type} disabled={this.state.isdesable}>
                                    <option value="">Select</option>    
                                    {
                                        this.state.conflictTypeList.map((rc,index) => {
                                            return(<option key={index} value={rc.id}>{rc.name}</option>);
                                        })
                                    }                                                           
                                </MRModalSelect>
                                {
                                    (() => {                        
                                        if(this.state.showDescriptionCType){
                                            return(
                                                <div>
                                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Conflicts Type Description</MRModalLabel><span style={{marginLeft: "5px",color: "red", fontSize: "15px"}}>*</span>                
                                                    <MRModalTextarea rows="5" name="cTypeDescription" value={this.state.cTypeDescription} onChange={this.handleChange} readOnly={this.state.isdesable}/>            
                                                </div>
                                            )
                                        }
                                    })()
                                }
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.isdesable){
                            return(<div>
                                <MRModalSelect id="conflict_type" name="conflict_type" onChange={this.handleChangeCType} value={this.state.conflict_type} disabled={this.state.isdesable}>
                                    <option value="">Select</option>    
                                    {
                                        Object.keys(module_config.conflict_types).map((d, index) => {
                                            return (<option key={index} value={d}>{module_config.conflict_types[d].title}</option>);
                                        })
                                    }                                                           
                                </MRModalSelect>
                                {
                                    (() => {                        
                                        if(this.state.showDescriptionCType){
                                            return(
                                                <div>
                                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Conflicts Type Description</MRModalLabel>              
                                                    <MRModalTextarea rows="5" name="cTypeDescription" value={this.state.cTypeDescription} onChange={this.handleChange} readOnly={this.state.isdesable}/>            
                                                </div>
                                            )
                                        }
                                    })()
                                }
                            </div>)
                        }
                    })()
                }
                
                {
                    (() => {
                        if(this.state.isdesable){
                            return(<div>
                                    <MRModalLabel style={{marginTop: "50px"}}>Please describe the nature of the Conflict</MRModalLabel>                
                                    <div dangerouslySetInnerHTML={{__html: this.state.nature_of_conflict}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>            
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the risk</MRModalLabel>                
                                    <div dangerouslySetInnerHTML={{__html: this.state.risk_description}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>      
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the steps being taken to mitigate this risk</MRModalLabel>                
                                    <div dangerouslySetInnerHTML={{__html: this.state.steps_to_mitigate_risk}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>            
                                                    
                            </div>)
                        }else{
                            return(<div>
                                    <MRModalLabel style={{marginTop: "50px"}}>Please describe the nature of the Conflict</MRModalLabel>                
                                    {/* <MRModalTextarea rows="5" name="nature_of_conflict" value={this.state.nature_of_conflict} onChange={this.handleChange} readOnly={this.state.isdesable}/> */}
                                    <div style={{width: "100%"}}><HtmlEditor content={this.state.nature_of_conflict} content_type={'nature_of_conflict'}/></div>      
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the risk</MRModalLabel>                
                                    {/* <MRModalTextarea rows="5" name="risk_description" value={this.state.risk_description} onChange={this.handleChange} readOnly={this.state.isdesable}/> */}
                                    <div style={{width: "100%"}}><HtmlEditor content={this.state.risk_description} content_type={'risk_description'}/></div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the steps being taken to mitigate this risk</MRModalLabel>                
                                    {/* <MRModalTextarea rows="5" name="steps_to_mitigate_risk" value={this.state.steps_to_mitigate_risk} onChange={this.handleChange} readOnly={this.state.isdesable}/> */}
                                    <div style={{width: "100%"}}><HtmlEditor content={this.state.steps_to_mitigate_risk} content_type={'steps_to_mitigate_risk'}/></div>
                                </div>)
                        }
                    })()
                }
                
                {/* custom field start */}
                {
                    (() => {
                        if('custom_fields' in module_config && module_config.custom_fields.enabled){
                            if (this.state.assign_data.additional_fields.length !== 0) {
                                let custom_html = [];
                                this.state.assign_data.additional_fields.forEach((item) => {                                    
                                    const field = this.generateField(item, "additional_fields");
                                    custom_html.push(field);                                    
                                })
                                return custom_html;
                            }
                        }
                    })()
                }
                {/* custom field end */}
                <MRModalLabel style={{marginTop: "30px"}}>Are there any additional records or information relating to this Disclosure?</MRModalLabel>
                {
                    (() => {
                        if(this.state.isdesable){
                            return(
                                <div>
                                <div>
                                    <div className="switch">    
                                        <input type="radio" name="allowAROD" id="yes" value="Yes" checked={this.state.additional_records_of_disclosure.enabled === 'Yes'}  />
                                        <Radiolevel className="rediolabel" htmlFor="yes">Yes</Radiolevel>
                                        <input type="radio" name="allowAROD" id="no" value="No" checked={this.state.additional_records_of_disclosure.enabled === 'No'}  />
                                        <Radiolevel className="rediolabel" htmlFor="no">No</Radiolevel>
                                        <span className="switchFilter"></span>                                                                                   
                                    </div>
                                </div>
                                {
                                    (() => {
                                        if(this.state.additional_records_of_disclosure.enabled === 'Yes') {
                                            return (
                                                <div dangerouslySetInnerHTML={{__html: this.state.additional_records_of_disclosure.description}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>   
                                            );
                                        }
                                    })()
                                }
                                </div>
                            )
                        }else{
                            return(<div>
                                    <div>
                                        <div className="switch">    
                                            <input type="radio" name="allowAROD" id="yes" value="Yes" checked={this.state.additional_records_of_disclosure.enabled === 'Yes'}  onChange={this.handleAdditionalROD}/>
                                            <Radiolevel className="rediolabel" htmlFor="yes">Yes</Radiolevel>
                                            <input type="radio" name="allowAROD" id="no" value="No" checked={this.state.additional_records_of_disclosure.enabled === 'No'} onChange={this.handleAdditionalROD} />
                                            <Radiolevel className="rediolabel" htmlFor="no">No</Radiolevel>
                                            <span className="switchFilter"></span>                                                                                   
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.additional_records_of_disclosure.enabled === 'Yes') {
                                                return (
                                                // <MRModalTextarea rows="6" placeholder="Describe..." name="description" value={this.state.additional_records_of_disclosure.description} onChange={this.handleChangeAROD}/>
                                                <div style={{width: "100%"}}><HtmlEditor content={this.state.additional_records_of_disclosure.description} content_type={'additional_records_of_disclosure_description'}/></div>
                                                );
                                            }
                                        })()
                                    }
                            </div>)
                        }
                    })()
                }                
                <MRModalLabel style={{marginTop: "30px"}}>Has or will a Disclosure be made to a 3rd party?</MRModalLabel>
                {
                    (() => {
                        if(this.state.isdesable){
                            return(<div>
                                    <div>
                                        <div className="switch1">    
                                            <input type="radio" name="allowThiredParty" id="yes1" value="Yes" checked={this.state.disclosure_to_third_party.enabled === 'Yes'} />
                                            <Radiolevel className="rediolabel" htmlFor="yes1">Yes</Radiolevel>
                                            <input type="radio" name="allowThiredParty" id="no1" value="No" checked={this.state.disclosure_to_third_party.enabled === 'No'} />
                                            <Radiolevel className="rediolabel" htmlFor="no1">No</Radiolevel>
                                            <span className="switchFilter1"></span>                                                                                   
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.disclosure_to_third_party.enabled === 'Yes') {
                                                return (
                                                    <div dangerouslySetInnerHTML={{__html: this.state.disclosure_to_third_party.description}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>  
                                                );
                                            }
                                        })()
                                    }
                            </div>)
                        }else{
                            return(<div>
                                    <div>
                                        <div className="switch1">    
                                            <input type="radio" name="allowThiredParty" id="yes1" value="Yes" checked={this.state.disclosure_to_third_party.enabled === 'Yes'}  onChange={this.handleThiredParty}/>
                                            <Radiolevel className="rediolabel" htmlFor="yes1">Yes</Radiolevel>
                                            <input type="radio" name="allowThiredParty" id="no1" value="No" checked={this.state.disclosure_to_third_party.enabled === 'No'} onChange={this.handleThiredParty} />
                                            <Radiolevel className="rediolabel" htmlFor="no1">No</Radiolevel>
                                            <span className="switchFilter1"></span>                                                                                   
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.disclosure_to_third_party.enabled === 'Yes') {
                                                return (
                                                // <MRModalTextarea rows="6" placeholder="Describe..." value={this.state.disclosure_to_third_party.description} name="description" onChange={this.handleChangeDTTP}/>
                                                <div style={{width: "100%"}}><HtmlEditor content={this.state.disclosure_to_third_party.description} content_type={'disclosure_to_third_party_description'}/></div>
                                                );
                                            }
                                        })()
                                    }
                            </div>)
                        }
                    })()
                } 
                <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                
                {
                    (() => {
                        if(this.state.isSaveCloseBtn){
                            return(<ModalNameInputDiv>                    
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true}/>
                            </ModalNameInputDiv>)
                        }else{
                            return(<ModalNameInputDiv>                    
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={!this.state.isdesable}/>
                            </ModalNameInputDiv>)
                        }
                    })()
                }
            </MRModalBody>
            <MRModalFooter>
                {
                    (() => {
                        if(Store.getStoreData('q_index') >= 0) {
                            if(this.state.curlane === "COMP_CONFLICTS_TEAMSAVE") {
                                return(<div>
                                    <MRModalNextBtn onClick={this.updateRegisterConflict(1)} disabled={this.state.is_disabled}>Register Conflict</MRModalNextBtn>
                                    <MRModalSaveCloseBtn onClick={this.updateRegisterConflict(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>                                    
                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>);
                            }
                            return(
                                <div>                   
                                    {
                                        (() => {
                                            if(this.state.isOwnerUpdtBtn){
                                                return(<MRModalSaveCloseBtn style={{backgroundColor: "#1E3E62", color: "#ffffff", border: "1px solid #1E3E62"}} onClick={this.updateConfirmTask}>Update</MRModalSaveCloseBtn>)
                                            }
                                        })()
                                    }        
                                    {
                                        (() => {
                                            if(this.state.isSaveCloseBtn){
                                                return(<MRModalSaveCloseBtn style={{backgroundColor: "#1E3E62", color: "#ffffff", border: "1px solid #1E3E62"}} onClick={this.updateConfirmConflict}>Save and Close</MRModalSaveCloseBtn>)
                                            }
                                        })()
                                    }                                            
                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>
                            )
                        }else {
                            return(
                                <div>
                                    <MRModalNextBtn onClick={this.submitRegisterConflict(1)} disabled={this.state.is_disabled}>Register Conflict</MRModalNextBtn>
                                    <MRModalSaveCloseBtn onClick={this.submitRegisterConflict(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>
                            )
                        }
                    })()
                }
                
                <div style={{clear: "both"}}></div>
            </MRModalFooter>
        </MRModalContainer>
        <AlertBox alertParam={this.state.alert_param}/>        
        </div>
        );
    }
}

export default TeamAskConflictModal;