import React from "react";
import styled from "styled-components";
import TAPICall from "./src/Common/APICall.js";
import FinpromoStore from "./src/Common/Store.js";
import Store from './../../../Common/Store';
import GenerciTasks from './src/Components/FPR/generciTasks';

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;


class FinpromoHOC extends React.Component {

    state={ready: false, role:''}

    componentDidMount(){
        this.setEnv('finpromo');
    }

    setEnv(module_name) {
        let api_url = `${Store.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;

        FinpromoStore.updateStore('module_name',module_name);
        FinpromoStore.updateStore('api_url',api_url);
        FinpromoStore.updateStore('is_admin',false);
        FinpromoStore.updateStore('is_debug',false);

        let postData = { command: "taskindex" };
        let api = new TAPICall();
        api.command(postData, this.processtaskIndex);
    }

    processtaskIndex = (result) => {
        console.log("processtaskIndex ===>", result.result)
        let r = {};
        r['result'] = {};
        let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        let user_roles = {};
        let contactNameValue = '';
        let contacts_all = {};
        let contractsemail_all = {};
        let listcontacts_all = [];
        let arrMgr = [];
        let contactid = result.result['contact_id'];
        if ('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles['finpromo'];
            contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : '';
            for (let k in cts.result.contactlist) {
                if (user_roles[k.toString()] !== undefined) {
                    contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
                    let listConObject = {};
                    listConObject.ID = cts.result.contactlist[k.toString()].ID;
                    listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
                    listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
                    listcontacts_all.push(listConObject);
                    if (cts.result.user_roles['finpromo'][k.toString()] !== 'no_access') {
                        contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                    }
                    if (cts.result.user_roles['finpromo'][k.toString()] === 'fprm' || cts.result.user_roles['finpromo'][k.toString()] === 'manager') {
                        arrMgr.push({ ID: k.toString(), ContactName: cts.result.contactlist[k.toString()].ContactName });
                    }
                }
            }
            FinpromoStore.updateStore('contact', contactNameValue);
            FinpromoStore.updateStore('userroles', user_roles);
            FinpromoStore.updateStore('contacts', contacts_all);
            FinpromoStore.updateStore('contactsemail', contractsemail_all);
            FinpromoStore.updateStore('reviewee_list', listcontacts_all);
            FinpromoStore.updateStore('get_managers', arrMgr);
        }

        let loggedincontact = Store.getStoreData('loggedincontact');
        let parentcompany_id = Store.getStoreData('parentcompany_id');
        let role = user_roles[loggedincontact.ID];
        FinpromoStore.updateStore('role', role);
        let module_config = result.result['module_config'];
        let gc_company = result.result['gc_company']
        let gc_companies = result.result['gc_companies']
        FinpromoStore.updateStore('module_config', module_config);
        FinpromoStore.updateStore('contact_id', result.result['contact_id']);
        FinpromoStore.updateStore('curCompanyID', result.result['curCompanyID']);
        FinpromoStore.updateStore('gc_company', gc_company);
        FinpromoStore.updateStore('gc_companies', gc_companies);
        let has_gc = gc_company.length > 1 ? true : false;
        FinpromoStore.updateStore('has_gc', has_gc);
        let fields = module_config.fields;
        let custom_fields = [];
        if (module_config.custom_fields.enabled && module_config.custom_fields.additional_fields.length) {
            custom_fields = module_config.custom_fields.additional_fields;
        }
        custom_fields.forEach((field) => {
            fields.push(field.name);
            if (
                field.type === 'Dropdown' &&
                field.other //for the case Dropdown has an 'other' field
            )
                fields.push(`other_${field.name}`);
        });
        FinpromoStore.updateStore('fields', fields);
        FinpromoStore.updateStore('custom_fields', custom_fields);

        let gc_assoc = {}
        let gc_users = {}
        let gc_filters = {};
        for (let gc of result.result.gc_company) {
            let gc_id = gc.id.toString();
            gc_assoc[gc_id] = gc.company_name;
            if (gc.is_gc) {
                gc_filters[gc.id.toString()] = 1;
            }
            if (!gc.is_gc) {
                continue
            }
            for (let uid in gc.users) {
                if (!(uid in gc_users)) gc_users[uid] = {}
                gc_users[uid][gc_id] = 1
            }
        }
        console.log("gc_assoc==>", gc_users, gc_assoc)
        FinpromoStore.updateStore('gc_assoc', gc_assoc);
        let myGroup = [];
        if (result.result['role'] === 'manager') {
            let alluser = result.result.gc_company[0].users
            for (let key in alluser) {
                if (alluser[key] !== 'no_access') {
                    myGroup.push(key);
                }
            }
        } else {
            for (let key in gc_users) {
                if (user_roles[key.toString()] !== 'no_access') {
                    myGroup.push(key);
                }
            }
        }

        //console.log("myGroup===>", myGroup)
        FinpromoStore.updateStore('my_group', myGroup);
        this.setState({ready: true})
    }

    closeUserDialog = (is_reload) => {
        this.setState({show_modal: false})
        this.props.closeUserDialog(is_reload)
    }

    render() {
        if(!this.state.ready)return(<div></div>)
        let task_id = this.props.task_id;
        let cur_lane = this.props.match ? ('curLane' in this.props.match.params ? this.props.match.params.curLane: 0) : 0;
        return(
            <FolderContainer>
                <GenerciTasks taskId={task_id} curLane={cur_lane} closeUserDialog={this.closeUserDialog} />
            </FolderContainer>
        );
    }
}

export default FinpromoHOC;
