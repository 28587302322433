import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
// import { Dropzone } from '@mcc-regtech/core-library';
import Dropzone from "../Common/Dropzone";
import { APICall, Store } from '../Common';
import CSLLoader from './../Common/CSLLoader';

const RegModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 97%;
	position: absolute;
	margin: 0 0;
	top: 55%;
	z-index: 1001;
	background-color: #f7f7f7;
	border-radius: 5px;
	box-shadow: 0 0 20px #a7a6a6;
	margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
	float: left;
	box-sizing: border-box;
	margin-top: 16px;
	margin-left: 20px;
	color: #2c2c2c;
	font-size: 17px;
	font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
	float: right;
	box-sizing: border-box;
	margin-top: 16px;
	margin-right: 20px;
	color: #9c9c9c;
	font-size: 16px;
	cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
	display: block;
	box-sizing: border-box;
	padding-right: 20px;
	padding-bottom: 20px;
	text-align: right;
	margin-top: 30px;
`;
const RegCancelButton = styled.button`
	display: inline-block;
	box-sizing: border-box;
	background-color: #ffffff;
	color: #909090;
	border: 2px solid #dadada;
	width: 100px;
	height: 35px;
	border-radius: 4px;
	font-size: 11px;
	cursor: pointer;
`;
const RegSubmitButton = styled.button`
	display: inline-block;
	box-sizing: border-box;
	background-color: #37ada7;
	color: #ffffff;
	width: 100px;
	height: 35px;
	border-radius: 4px;
	border: 1px solid #37ada7;
	margin-left: 5px;
	font-size: 11px;
	cursor: pointer;
`;
const RegDismissalReason = styled.textarea`
	height: 130px;
	width: 98%;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	padding: 5px;
	color: #444444;
	font-weight: 100;
`;
const MRModalLabel = styled.div`
	color: #212121;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
`;
const MRModalBody = styled.div`
	display: block;
	box-sizing: border-box;
	padding: 0px 20px 10px 20px;
`;
const RegDismisOuterContainer = styled.div`
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 6px 20px 20px 20px;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
	background-color: #edf0f1;
	border: 1px solid #d7dddf;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	width: 95%;
	margin: 0 auto;
	margin-bottom: 30px;
	display: inline-block;
	margin-top: 28px;
`;
const ModalNameLabelDiv = styled.div`
	height: auto;
	width: 100%;
	padding-top: 30px;
	font-weight: 600;
	color: #1a3552;
`;
const ModalNameInputDiv = styled.div`
	height: auto;
	width: 100%;
	padding-top: 30px;
	color: #1a3552;
	font-family: 'Montserrat', sans-serif;
`;

class FollowupModal extends React.Component {
	state = {
		task: null,
		payload: {},
		task_id: null,
		comments: '',
		task_title: '',
		task_note: '',
		ready: false,
		send_mail_config: false,
		curlane: '',
		binFiles: [],
		cur_files_json: null,
		is_disabled: false,
		isLoadedIcon: false
	};

	GetPayloadData = (parent_task) => {
		let payload = {};
		let parent_task_json = JSON.parse(parent_task.task_json);
		payload.Ref_ID = parent_task_json.object_data.ref_id;
		return payload;
	};

	constructor(props) {
		super(props);
		this.api = new APICall();
	}

	componentDidMount() {
		let { task, payload, task_id, task_title, task_note, curlane, ready } = this.state;
		console.log('-----------------------');
		let task_data = Store.getStoreData('followup_task');
		console.log('inside FollowUpModal.js');
		console.log(task_data);

		curlane = task_data.subtask.cur_lane;
		let followup_task = task_data.subtask;
		task_id = followup_task.id;
		console.log(task_id);
		console.log('-----------------------');
		let task_json = JSON.parse(followup_task.task_json);
		task_title = task_json.object_data.subject;
		task_note = task_json.object_data.note;
		let cur_files_json = [];
		if ('bin_files' in task_json.object_data) {
			cur_files_json = task_json.object_data.bin_files;
		}
		let task_command = '';
		if ('commands' in task_json.object_data) {
			task_command = task_json.object_data.commands;
		}
		let parent_task = task_data.parenttask;
		payload = this.GetPayloadData(parent_task);
		this.setState({
			task: task_json,
			payload,
			task_id,
			task_title,
			task_note,
			comments: task_command,
			curlane,
			cur_files_json: cur_files_json,
			ready: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			let { task, payload, task_id, task_title, task_note, curlane, ready } = this.state;
			let task_data = Store.getStoreData('followup_task');
			curlane = task_data.subtask.cur_lane;
			let followup_task = task_data.subtask;
			task_id = followup_task.id;
			let task_json = JSON.parse(followup_task.task_json);
			task_title = task_json.object_data.subject;
			task_note = task_json.object_data.note;
			let parent_task = task_data.parenttask;
			payload = this.GetPayloadData(parent_task);
			this.setState({ task: task_json, payload, task_id, task_title, task_note, curlane, ready: true });
		}
	}
	closeModal = (event) => {
		if (Store.getStoreData('isreferenceUrl') === true) window.location = process.env.REACT_APP_FINPROMO_URL;
		else this.props.closeModal();
	};

	UpdateSubTask = (event) => {
//		debugger;
		event.preventDefault();
		let task_json = this.state.task;
		task_json['task_data'].last_action = 'COMP_FPR_FOLLOWUP_COMPLETE';
		task_json['task_data'].cur_lane = 'COMP_FPR_FOLLOWUP_COMPLETE';
		task_json['task_data'].actiondate = Date.now();
		task_json['object_data'].commands = this.state.comments;
		task_json['action_data'] = {
			action: 'COMP_FPR_FOLLOWUP_COMPLETE',
			actiondate: Date.now(),
			performed_by: Store.getStoreData('contact_id'),
			cur_lane: 'COMP_FPR_FOLLOWUP_COMPLETE',
			comments: this.state.comments,
			assigned_to: 0,
			//for displaying note after followup gets completed..
			note: task_json['object_data'].hasOwnProperty('note')?task_json['object_data']['note']:''
		};
		let postData = { command: 'update_followup_task', task_json: task_json, task_id: this.state.task_id };
		this.setState({ isLoadedIcon: true })
		this.api.command(postData, this.afterCompleted);
	};
	afterCompleted = (result) => {
		alert('Followup task completed successfully.');
		window.location.reload(true);
	};

	handleChange = (event) => {
		event.preventDefault();
		this.setState({ [event.target.name]: event.target.value });
	};

	filesLoaded = (files) => {
		let contact_id = Store.getStoreData('contact_id');
		for (let i = 0; i < files.length; i++) {
			files[i].name = files[i].name.replace('&', '');
			if (!('user_id' in files[i])) {
				files[i].user_id = contact_id;
				files[i].upload_date = Date.now();
			}
		}
		this.setState({ binFiles: files });
	};

	render() {
		let curAssignTo = Store.getStoreData('followup_task').subtask.cur_assigned_to;
		let curUserID = Store.getStoreData('contact_id');
		let role = Store.getStoreData('role');
		if (!this.state.ready) {
			return <div> Loading.....</div>;
		}
		return (
			<div>
				{
					(() => {
						if (this.state.isLoadedIcon) {
							return <div><CSLLoader style={{ position: 'absolute' }} /></div>
						}
					})()
				}
			<RegModalContainer>
				<div>
					<RegModalHeaderText>{this.state.task_title}</RegModalHeaderText>
					<RegModalHeaderCloseBtn onClick={this.closeModal}>
						<FaTimes />
					</RegModalHeaderCloseBtn>
				</div>
				<RegDismisOuterContainer>
					<OverviewInfoBody style={{ marginBottom: '10px' }}>
						<div style={{ marginBottom: '15px' }}>
							<span style={{ color: '#383939', fontWeight: 'bold' }}>Task Description</span>
						</div>
						<div
							dangerouslySetInnerHTML={{ __html: this.state.task_note }}
							style={{ textAlign: 'justify', color: '#737575' }}
						/>
						{(() => {
							if (this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0) {
								return (
									<div>
										<ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
										<ModalNameInputDiv>
											<Dropzone
												onFilesAdded={this.filesLoaded}
												initFiles={this.state.cur_files_json}
												addnew={false}
											/>
										</ModalNameInputDiv>
									</div>
								);
							}
						})()}
					</OverviewInfoBody>
				</RegDismisOuterContainer>
				<MRModalBody>
					{(() => {
						if (curAssignTo === curUserID && this.state.curlane !== 'COMP_FPR_FOLLOWUP_COMPLETE') {
							return (
								<div>
									<MRModalLabel>Comments</MRModalLabel>
									<RegDismissalReason
										name="comments"
										onChange={this.handleChange}
										value={this.state.comments}
									/>
								</div>
							);
						} else if (
							(role === 'admin_manager' || role === 'manager') &&
							this.state.curlane !== 'COMP_FPR_FOLLOWUP_COMPLETE'
						) {
							return (
								<div>
									<MRModalLabel>Comments</MRModalLabel>
									<RegDismissalReason
										name="comments"
										onChange={this.handleChange}
										value={this.state.comments}
									/>
								</div>
							);
						} else {
							return (
								<div>
									<MRModalLabel>Comments</MRModalLabel>
									<RegDismissalReason name="comments" value={this.state.comments} readOnly />
								</div>
							);
						}
					})()}
				</MRModalBody>
				<RegCancelSubmitButtonOuterContainer>
					<RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
					{(() => {
						if (curAssignTo === curUserID && this.state.curlane !== 'COMP_FPR_FOLLOWUP_COMPLETE') {
							return <RegSubmitButton onClick={this.UpdateSubTask}>Complete</RegSubmitButton>;
						} else if (
							(role === 'admin_manager' || role === 'manager') &&
							this.state.curlane !== 'COMP_FPR_FOLLOWUP_COMPLETE'
						) {
							return <RegSubmitButton onClick={this.UpdateSubTask}>Complete</RegSubmitButton>;
						}
					})()}
				</RegCancelSubmitButtonOuterContainer>
			</RegModalContainer>
			</div>
		);
	}
}
export default FollowupModal;
