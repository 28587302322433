import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from '../../Common/Store.js';

class HtmlEditorDescription extends React.Component {
    render() {
        let { content, readOnly } = this.props;
        // console.log('Store.getStore()');
        // console.log(Store.getStoreData('summary'));
        //console.log(`%c ${readOnly}`, 'background:red;color:black;font-size:12px');
        return (
            <div>
                <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    disabled={readOnly}
                    config={{
                        link: { defaultProtocol: 'http://', addTargetToExternalLinks: true },
                        removePlugins: [ 'Table', 'BlockQuote', 'ImageUpload', 'Indent', 'MediaEmbed' ]
                    }}
                    onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor) => {
                        //console.log('inside onChange');
                        const linkCommand = editor.commands.get('link');
                        const model = editor.model;
                        const selection = model.document.selection;
                        const linkHref = selection.getAttribute('linkHref');
                        if (linkHref !== undefined) {
//                            debugger;
                            console.log(event);
                            console.log(editor);
                            if (linkHref.indexOf('http://') == 0 || linkHref.indexOf('https://') == 0) {
                                console.log('The link has http or https.');
                            } else {
                                alert(
                                    'Please ensure that you have included the full URL. It should begin with https:// or similar.'
                                );
                                return false;
                            }
                        }


                        const data = editor.getData();
                        // console.log("data:", data);
                        Store.updateStore('description', data);

                        // console.log( { event, editor, data } );
                    }}
                    onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                    }}
                />
            </div>
        );
    }
}
export default HtmlEditorDescription;
