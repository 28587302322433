import Store from '../../../Common/Store';
import http from 'mcc-front-aux/dist/httpCommon'

class TAPICall {
    constructor() {
        this.url = `${Store.getStoreData('base_api_url')}/microservice/gifts/api/command`
        this.is_admin = Store.getStoreData('is_admin');
        this.is_debug = Store.getStoreData('is_debug');
        this.module_name = 'gifts';

    }

    async command(postData, callback) {
        let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
        jsonDataString = jsonDataString.replace(/\+/g, "--plus--");
        jsonDataString = jsonDataString.replace(/'+/g, "--quote--");
        jsonDataString = jsonDataString.replace(/\n/g, "");
        jsonDataString = jsonDataString.replace(/\r/g, "");

        // Add in the json command if it exists
        let postURL = this.url
        if('command' in postData) {
            postURL += `/${postData.command}`
        }


        http.post(postURL, {jsondata: jsonDataString}).then(res => {
            res = res.data.body
            res = res.replace(/(--plus--)/g, "+");
            res = res.replace(/(--quote--)/g, "'");
            res = res.replace(/(--and--)/g, "&");
            res = res.replace(/'+/g, "'");
            res = JSON.parse(res);
            callback(res);
        }).catch(error => {
            console.log(error);
            return(null)
        });
        // trackPromise(fetch(this.url, httpVerb)
        //   .then(res => res.json())
        //   .then(result => {
        //     result = JSON.stringify(result);
        //     result = result.replace(/(--plus--)/g, "+");
        //     result = result.replace(/(--quote--)/g, "'");
        //     result = result.replace(/(--and--)/g, "&");
        //     result = result.replace(/'+/g, "'");
        //     result = JSON.parse(result);
        //     callback(result);
        //   }).catch(error => {
        //     console.log(error);
        //     return(null)
        //   }));
    }
}

export default TAPICall;
