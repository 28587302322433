import Store from './../../Common/Store';
import moment from 'moment';
import Utils from './../../Common/Utils';

class XcelLoader {
	breach_export_tasks(tasks){		
		let j = 0;
		let results = [];
        let module_config = Store.getStoreData('module_config');  
		let has_gc = Store.getStoreData('has_gc');      
		let gc_assoc = Store.getStoreData('gc_assoc');
		let gc_company = Store.getStoreData('gc_company');
		let contacts = Store.getStoreData('contacts');
		let role = Store.getStoreData('role');

		let regulator_types = {};
        if('regulator_types' in Store.getStoreData('module_config') && Store.getStoreData('module_config').regulator_types.length !== 0){
            Store.getStoreData('module_config').regulator_types.forEach((item) => {
                if(item.isActive === 1){
                    regulator_types[item.id] = item.ragulatorname;
                }
            })
        }


		
		let resolution_owner = {};  
        if('business_areas' in Store.getStoreData('module_config') && Store.getStoreData('module_config').business_areas.length !== 0){
			Store.getStoreData('module_config').business_areas.forEach((item) => {
				if(item.isActive === 1){
					resolution_owner[item.businessAreaID] = item.businessAreaName
				}
			})
        }
		let review_list_all = Store.getStoreData('reviewee_list');		
		let user_roles = Store.getStoreData('userroles'); 
		for(let k of review_list_all){
			if(k.ID in user_roles && user_roles[k.ID.toString()] !== 'no_access'){
				resolution_owner[k.ContactName + '-' + k.ID] = k.ContactName;
			}
		}
        
		for(let d of tasks) {
			j++;		  
			let taskJson = d.task_json;
		    let elem = {		       
					'Incident ID': taskJson.object_data.ref_id 
		        };
				if(has_gc){					
					let gcid = parseInt(d.company_id) < 100000 ? parseInt(gc_company[0].id) : (parseInt(d.company_id)%100000);
					let gcname = "";
					if(gcid in gc_assoc){
						gcname = gc_assoc[gcid];
					}
					elem['Group Company'] = gcname 
				}
				let breachType_name = taskJson.object_data.headerinfo.breach_type === 'conduct_rules_breach' ? 'Conduct Rules Breach' : 'Incident'
				let incidentType_name = 'n/a'
				if('incidentType_name' in taskJson.object_data.headerinfo){
					if(taskJson.object_data.headerinfo.incidentType_name === ''){
						incidentType_name = 'n/a'
					}else if(taskJson.object_data.headerinfo.incidentType_name === '-'){
						incidentType_name = 'n/a'
					}else{
						incidentType_name = taskJson.object_data.headerinfo.incidentType_name;
					}
				}
				
				elem['Incident Category'] = breachType_name; 
				elem['Incident Type'] = incidentType_name; 
				elem['Incident Title'] = 'incident_title' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.incident_title : "";

				let qtext = taskJson.object_data.headerinfo.breach_deatils
				if(Utils.isBase64(taskJson.object_data.headerinfo.breach_deatils)){
					qtext = window.decodeURIComponent(atob(qtext))
				} 
				let regex = /(<([^>]+)>)/ig
				elem['Incident Details'] = qtext.replace(regex, ""); 
				elem['Impact Type'] = 'impact_type' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.impact_type : ""; 
				elem['Estimated Loss'] = 'est_los_val' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.est_los_val : ""; 
				elem['Actual Loss'] = 'act_los_val' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.act_los_val : ""; 
				elem['Estimated Third Party Cost'] = 'est_thi_val' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.est_thi_val : ""; 
				elem['Actual Third Party Cost'] = 'act_thi_val' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.act_thi_val : ""; 
				elem['Financial impact calculation methodology'] = 'fin_impact_cal_method' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.fin_impact_cal_method : ""; 
				let com_paid_date = 'com_paid_date' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.com_paid_date : "";
        
				if(com_paid_date !== ''){
					const com_paid_obj = new Date(com_paid_date);
					const com_paid_day = com_paid_obj.getDate() < 10 ? "0"+com_paid_obj.getDate().toString() : com_paid_obj.getDate();
					const com_paid_month = com_paid_obj.getMonth()+1 < 10 ? `0${(com_paid_obj.getMonth() + 1).toString()}` : com_paid_obj.getMonth()+1;
					const com_paid_year = com_paid_obj.getFullYear();
					com_paid_date = `${com_paid_day}/${com_paid_month}/${com_paid_year}`;
				}

				elem['Compensation paid'] = com_paid_date;
				
				if(role !== 'team'){
					let allContacts = contacts;
					let user_name = '';
					if(taskJson.object_data.headerinfo.conduct_rule_user_id !== null){
						user_name = allContacts[taskJson.object_data.headerinfo.conduct_rule_user_id]
					}
					elem['User name'] = user_name;
				}
				elem['Entry Date'] = moment.unix(d.created_at).format('DD/MM/YYYY');
				elem['Entry user'] = d.contact_id in contacts ? contacts[d.contact_id] : "";
				let last_update_date = moment(d.actiondate).unix();
				elem['Last Update Date'] = moment.unix(last_update_date).format('DD/MM/YYYY');
				elem['Last Update User'] = d.last_update_user in contacts ? contacts[d.last_update_user] : "";
				elem['Incident Date'] = moment.unix(taskJson.object_data.headerinfo.breach_date).format('DD/MM/YYYY');

				let detected_date = 'dateofDetection' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.dateofDetection : "";
        
				if(detected_date !== ''){
					const detected_obj = new Date(detected_date);
					const detected_day = detected_obj.getDate() < 10 ? "0"+detected_obj.getDate().toString() : detected_obj.getDate();
					const detected_month = detected_obj.getMonth()+1 < 10 ? `0${(detected_obj.getMonth() + 1).toString()}` : detected_obj.getMonth()+1;
					const detected_year = detected_obj.getFullYear();
					detected_date = `${detected_day}/${detected_month}/${detected_year}`;
				}
				elem['Detected Date'] = detected_date;
				elem['Closed Date'] = d.cur_lane === "COMP_BREACH_COMPLETE" ? moment.unix(last_update_date).format('DD/MM/YYYY') : '';

				let isinternal_external_incident_view = "";		
				let department_name = ""		
				if('isinternal_external_incident' in taskJson.object_data.headerinfo){					
					if(taskJson.object_data.headerinfo.isinternal_external_incident === 0){
						isinternal_external_incident_view = "External";
					}else if(taskJson.object_data.headerinfo.isinternal_external_incident === 1){
						isinternal_external_incident_view = "Internal"; 
						if(taskJson.object_data.headerinfo.department_name !== ""){
							department_name = taskJson.object_data.headerinfo.department_name;
						}
					}
				} 
				elem['Internal / External'] = isinternal_external_incident_view;
				elem['Department'] = department_name;

				
				let thirdPartyDetails = 'third_party_details' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.third_party_details : "";
				if(Utils.isBase64(thirdPartyDetails)){
					thirdPartyDetails = window.decodeURIComponent(atob(thirdPartyDetails))
				} 
				
				elem['Third Party'] = thirdPartyDetails.replace(regex, ""); 

				//elem['Third Party'] = 'third_party_details' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.third_party_details : "";
				elem['Reportability'] = 'is_reportability' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.is_reportability === 0 ? "No" : "Yes" : "";
				let reportedDate = "";
				let regulatorName = "";
				let rule_reference = "";
				let istrading_error = "";
				let fpp_name = "";
				if(elem['Reportability'] === 'Yes'){
					// const report_obj = new Date(taskJson.object_data.headerinfo.reported_date);
					// const report_day = report_obj.getDate() < 10 ? "0"+report_obj.getDate().toString() : report_obj.getDate();
					// const report_month = report_obj.getMonth()+1 < 10 ? `0${(report_obj.getMonth() + 1).toString()}` : report_obj.getMonth()+1;
					// const report_year = report_obj.getFullYear();
					// reportedDate = `${report_day}/${report_month}/${report_year}`;	
					regulatorName = taskJson.object_data.headerinfo.regulator_id in regulator_types ? regulator_types[taskJson.object_data.headerinfo.regulator_id] : "";
					rule_reference = 'rule_reference' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.rule_reference : "";
					istrading_error = 'istrading_error' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.istrading_error === 0 ? "No" : "Yes" : "";
					fpp_name = 'fpp_name' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.fpp_name : "";
				}				
				elem['Reported Date'] = reportedDate;
				elem['Regulator'] = regulatorName;
				elem['Rule / Reference'] = rule_reference;
				elem['Trading Error'] = istrading_error;
				elem['Fund/Portfolio/Product Name'] = fpp_name;

				let disciplinaryAction = 'disciplinary_action' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.disciplinary_action === 'no' ? "No" : "Yes" : "";
				let detailsOfInvestigation = "";
				if(disciplinaryAction === 'Yes'){
					detailsOfInvestigation = 'details_of_investigation' in taskJson.object_data.headerinfo ? taskJson.object_data.headerinfo.details_of_investigation : ""
				}
				elem['Disciplinary Action'] = disciplinaryAction;
				elem['Details of Investigation'] = detailsOfInvestigation;
				elem['Regulator Reference'] = rule_reference;

				if(role !== 'team'){
					let reasonforbreachlistObject = {};
					if('reason_for_Breach' in Store.getStoreData('module_config') && Store.getStoreData('module_config').reason_for_Breach.length !== 0){
						Store.getStoreData('module_config').reason_for_Breach.forEach((item) => {
							if(item.isActive === 1){								
								reasonforbreachlistObject[item.reasonforBreachID.toString()] = item.reasonforBreachName;
							}
						})
					}					
					let rootCauseName = "";
					if('reasonforbreach' in taskJson.object_data.headerinfo && taskJson.object_data.headerinfo.reasonforbreach in reasonforbreachlistObject){
						rootCauseName = reasonforbreachlistObject[taskJson.object_data.headerinfo.reasonforbreach];
					}
					elem['Root Cause'] = rootCauseName;

					let breachimpactlistObject = {};
					if('breach_impact' in Store.getStoreData('module_config') && Store.getStoreData('module_config').breach_impact.length !== 0){
						Store.getStoreData('module_config').breach_impact.forEach((item) => {
							if(item.isActive === 1){								
								breachimpactlistObject[item.breachImpactID.toString()] = item.breachImpactName;
							}
						})
					}
					let incidentImpactName = "";
					if('breachimpact' in taskJson.object_data.headerinfo && taskJson.object_data.headerinfo.breachimpact in breachimpactlistObject){
						incidentImpactName = breachimpactlistObject[taskJson.object_data.headerinfo.breachimpact];
					}
					elem['Incident Impact'] = incidentImpactName;
					let ownership = "";
					if('ownershipresolution' in taskJson.object_data.headerinfo && taskJson.object_data.headerinfo.ownershipresolution in resolution_owner){
						ownership = resolution_owner[taskJson.object_data.headerinfo.ownershipresolution];
					}
					elem['Ownership'] = ownership;
					let riskCategory = '';
					if('selectedRiskCategory' in taskJson.object_data.headerinfo && taskJson.object_data.headerinfo.selectedRiskCategory.length !== 0){
						let rskcate = taskJson.object_data.headerinfo.selectedRiskCategory;
						let i=0;
						for(let d of rskcate){							
							riskCategory += d.label;
							if(i < rskcate.length - 1) {
								riskCategory += ', '
							}
							i++;
						}
					}
					elem['Risk Category(s)'] = riskCategory;
					let taskAsignUser = Store.getStoreData('task_assign_user');
					elem['Number of follow up tasks'] = d.task_id in taskAsignUser ? taskAsignUser[d.task_id].followup.toString() : "0";
					elem['Number of remedial tasks'] = d.task_id in taskAsignUser ? taskAsignUser[d.task_id].remedial.toString() : "0";
					elem['Number of preventative tasks'] = d.task_id in taskAsignUser ? taskAsignUser[d.task_id].preventative.toString() : "0";
				}
                   
            if(Object.keys(module_config).length > 0 && module_config.custom_fields.enabled) { 				               
				if(module_config.custom_fields.additional_fields.length !== 0 && 'additional_fields' in taskJson.object_data.headerinfo && taskJson.object_data.headerinfo.additional_fields.length !== 0){
                    taskJson.object_data.headerinfo.additional_fields.forEach((item) => {  
						if(item.value === 'other'){
							elem[item.nickname] = item.other_value
						}else{    
							if(item.type === 'Date'){
								let dateValue = "";
								if(item.value !== ''){
									const reported_date_obj = new Date(item.value);
									const reported_date_day = reported_date_obj.getDate() < 10 ? "0"+reported_date_obj.getDate().toString() : reported_date_obj.getDate();
									const reported_date_month = reported_date_obj.getMonth()+1 < 10 ? `0${(reported_date_obj.getMonth() + 1).toString()}` : reported_date_obj.getMonth()+1;
									const reported_date_year = reported_date_obj.getFullYear();
									dateValue = `${reported_date_day}/${reported_date_month}/${reported_date_year}`;
								}
								elem[item.nickname] = dateValue
							}else if(item.type === 'User List'){
								let contacts =  Store.getStoreData('contacts');
								let userName = "";
								if(item.value !== ''){
									userName = item.value in contacts ? contacts[item.value] : "";
								}
								elem[item.nickname] = userName
							}else if(item.type !== 'Date' || item.type !== 'User List'){
								elem[item.nickname] = item.value
							}
						}
                    })
                }
            }
		    results.push(elem)
		}
		return results;
	}
}
export default new XcelLoader();