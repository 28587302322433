import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import {FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 70%;
    left: 5%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size : 16px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border : 1px solid;
    border-color: #C2C2C2
    border-radius: 4px;
    padding: 10px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 300;
    margin-top: 2px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalDivider = styled.div`
    background-color: #A0A0A0;
    height:3px;
    margin-top: 20px;
`;
const Spacer = styled.div`
  height: 5px;
`;
const MRModalLabelTitle = styled.label`
    color: #a0a0a0;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalLabelTitle_Imp = styled.label`
    color: #a0a0a0;
    font-weight: bold;
`;
const CLButton = styled.button`
    box-sizing: border-box;
    background-color: #ffffff;
    color: #A0A0A0;
    border: 1px solid #A0A0A0;
    width: 100px;
    font-size: 13px;
    height: 31px;
    border-radius: 2px;
    margin-top: 39px;
    float: right;
    cursor: pointer;
`;
const MRModalBodyHeader = styled.div`
    border : 1px solid;
    border-color: #C2C2C2
    border-radius: 4px;
    padding: 15px;
    background-color: #EDF1F1;
    height : 102px;
`;
const MRModalBodyHeader_Imp = styled.div`
    border : 1px solid;
    border-color: #C2C2C2
    border-radius: 4px;
    padding: 15px;
    background-color: #EDF1F1;
    height : 102px;
`;

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #949494;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;

class ShareInfoAndActionModal extends React.Component
{
    closeModal = (event) => {
        this.props.closeModal();
    }    
    render() {    
        if(Object.keys(this.props.emailLogData).length !== 0){
            let emailjson = JSON.parse(this.props.emailLogData.email_json)
            return(<div>
                <RegModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>{emailjson.subject}</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyHeader>
                            <MRModalLabelTitle style={{color:"#000000", fontWeight:"bold"}}>Status : </MRModalLabelTitle>
                            <MRModalLabelTitle style={{color:"#000000", fontWeight:"bold"}}>Delivered</MRModalLabelTitle><br/>
                            <MRModalLabel>This email was successfully delivered on <MRModalLabelTitle style={{color:"#000000", fontWeight:"500"}}>{emailjson.sent_date} </MRModalLabelTitle> to <MRModalLabelTitle style={{color:"#000000", fontWeight:"500"}}>{emailjson.recieipent_name} ({emailjson.recieipent_email}) </MRModalLabelTitle></MRModalLabel>
                        </MRModalBodyHeader>
                        {
                            (() => {
                                if('task_status' in this.props && this.props.task_status === 'Completed'){
                                    return(<MRModalBodyHeader_Imp style={{backgroundColor: "#D1EEED", marginTop:"15px", height:"17px"}}>                            
                                    <MRModalLabelTitle_Imp style={{color:"#04ada8", fontWeight:"600"}}>The task associated with this email has been actioned.</MRModalLabelTitle_Imp>
                                </MRModalBodyHeader_Imp>)
                                }else{
                                    return(<MRModalBodyHeader_Imp style={{backgroundColor: "#F5DBDB", marginTop:"15px", height:"17px"}}>
                                    <MRModalLabelTitle_Imp style={{color:"#E2A2A3", fontWeight:"600"}}>The task associated with this email has not yet been actioned</MRModalLabelTitle_Imp>
                                </MRModalBodyHeader_Imp>)
                                }
                            })()
                        }                       
                        
                        <MRModalHeaderText  style={{marginTop:"25px"}}>Subject</MRModalHeaderText>
                        <MRModalBodyInner style={{marginTop:"15px",marginLeft:"77px", }}>
                        {emailjson.subject}
                        </MRModalBodyInner>
                        <MRModalHeaderText  style={{marginTop:"25px"}}>Body</MRModalHeaderText>
                        <MRModalBodyInner dangerouslySetInnerHTML={{ __html: emailjson.body }} style={{marginTop:"15px",marginLeft:"77px", }}>
                     
                        </MRModalBodyInner>
                        <CLButton onClick={this.closeModal}>Close</CLButton>
                        <div style={{clear: "both"}}></div>
                    </MRModalBody>
                </RegModalContainer>
            </div>   )
        }else{            
                return(<div>
                    <RegModalContainer>
                        <MRModalHeader>
                            <MRModalHeaderText></MRModalHeaderText>
                            <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                            <div style={{clear: "both"}}></div>
                        </MRModalHeader>
                        <MRModalBody>                            
                            <MRModalBodyHeader_Imp style={{backgroundColor: "#F5DBDB", marginTop:"15px", height:"45px"}}>
                                <MRModalLabelTitle_Imp style={{color:"#E2A2A3", fontWeight:"600"}}>No e-mail was sent relating to this task.</MRModalLabelTitle_Imp>
                            </MRModalBodyHeader_Imp>
                            <CLButton onClick={this.closeModal}>Close</CLButton>
                            <div style={{clear: "both"}}></div>
                        </MRModalBody>
                    </RegModalContainer>
                </div>   )
            
        }      
        
    }
}
export default ShareInfoAndActionModal



