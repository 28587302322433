import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store';

import TAPICall from "./TAPICall.js";
import DatePicker from 'react-date-picker';
import {FaPlusCircle, FaTimes} from 'react-icons/fa';
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { MdClear } from "react-icons/md";
import Dropzone from '../../Common/Dropzone.js';
import CSLLoader from '../../Common/CSLLoader.js';
import AlertBox from "../../Common/AlertBox";
import './Followup.css';
const moment = require("moment");
const last_action_time = moment().unix();


const OverviewInfoHeader = styled.div`
	padding: 12px 15px;
	background-color: #04ada8;
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const ScoreContainer = styled.div`
   display: block;
   box-sizing: border-box;
   height: auto;
   width: 60%;
   position: fixed;
   margin: 80px 100px;
   top: 8px;
   z-index: 1001;
   background-color: #f7f7f7;
   border-radius: 5px;
   box-shadow: 0 0 20px #a7a6a6;
`
const ScoreBodyContainer = styled.div`
    padding: 15px;
    text-align: left;
    overflow-y: scroll;
    height: calc(65vh - 20px);
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 5px;
    font-weight: 600;
    color: #282828;
`
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const ModalInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalTextArea = styled.textarea`
    height: 130px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 34px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #282828;
`;
const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    border-radius: 5px;
`;
const DocHeader = styled.div`
    border-width: 1px;
    background-color: white;
    border-color: #e9e9e9;
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: 70px;
    border-radius: 5px;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    color: #282828;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
`;


class CSLAtteachmentViewer extends React.Component {
  state = {ready: false, files: null, task_id: null, is_completed: false }

  setComponentState = () => {
    console.log("this.props", this.props)
    if(this.props.task_id){
        this.setState({ready: true, files: this.props.files, task_id: this.props.task_id, is_completed: this.props.is_completed})
    }
  }
  componentDidMount = () => {
    this.setComponentState()
  }
  componentDidUpdate = (prevprops) => {
    if(this.props === prevprops)return
    this.setComponentState()
  }

  handleDownload(image_id, task_id) {
    let api = new TAPICall();
    let postData = { command: "download_followup_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData)
    api.command(postData, this.processDownloadFile);
  }
  handleRemove(image_id, task_id){
    let api = new TAPICall();
    let postData = { command: "remove_followup_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData)
  }

  processDownloadFile = (result) => {
    let dbinfile = result.result;
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  }

  processVersionBySingleDocument = (result) => {
    let versions = result.result;
    let version_main = result.result.filter((x) => {
      return x.is_main == 1;
    });
    let bin_file = JSON.parse(version_main[0].bin_file)[0].bin_file
    let file_parts = JSON.parse(version_main[0].bin_file)[0].name.split('.')
    let mime_type = file_parts[file_parts.length - 1].toLowerCase()
    this.setState({ ready: true, filedata_b64: bin_file, mime_type: mime_type, doc_id: this.props.docId });
  };

  imageUrl(file){
      const file_parts = file.split(/\.(?=[^\.]+$)/);
      let ext = "PDF";
      let extention = file_parts[file_parts.length - 1];
      switch(extention) {
        case 'jpeg':
        case 'jpg' : ext = 'JPG';break;
        case 'png' : ext = 'PNG'; break;
        case 'docx' : ext = 'DOC'; break;
        case 'doc' : ext = 'DOC'; break;
        case 'msg' : ext = 'MSG'; break;
        case 'txt' : ext = 'TXT'; break;
        case 'ppt' : ext = 'PPT'; break;
        case 'pptx' : ext = 'PPTX'; break;
        case 'xls' : ext = 'XLS'; break;
        case 'xlsx' : ext = 'XLS'; break;
        default: ext = 'PDF'; break;
      }
      console.log('ext', ext);
      let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
      file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
      const file_icon = `/dropzoneImages/${ext}.png`;
      return file_icon
  }

  render() {
    if(!this.state.ready)return (<div></div>)
    return (<div style={{width: '100%'}}>
        {
          (() => {
            let i = 0;
            for(let key in this.state.files){
                return (
                    <div style={{width: "50%",boxSizing: "border-box",padding:i === 0 || i % 2 === 0? "10px 10px 10px 0px": "10px 0px 10px 10px"}}>
                          <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                            <div style={{float: "left",padding: "10px",boxSizing: "border-box"}}>
                                <img src={this.imageUrl(this.state.files[key])} style={{ height:"100px", width:"auto" }} />
                            </div>
                            <div style={{ float: "left",padding: "10px",boxSizing: "border-box" }}>
                              <div>
                                <strong title={this.state.files[key]} style={{ color: "#000000" }}>
                                  {this.state.files[key].length > 20? this.state.files[key].substring(0, 20): this.state.files[key]}
                                </strong>
                                <br />
                                <br />
                              </div>
                              <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(key,this.state.task_id)}>
                                <FiDownload /> Download
                              </div>
                              {
                                (() => {
                                    if(!this.state.is_completed){
                                        return(
                                            <div style={{cursor: "pointer",marginTop: "10px"}} onClick={() => this.handleRemove(key,this.state.task_id)} >
                                                <MdClear /> Remove
                                            </div>)
                                    }
                                })()
                              }
                            </div>
                            <div style={{ clear: "both" }}></div>
                          </div>
                        </div>
                        );
                i++
            }
          })()
        }
      </div>)
  }
}



class AddTaskModal extends React.Component
{
    state = {
        showModal: false,
        contact_list: [],
        ready: false,
        due_date: new Date(),
        document_id: null,
        binFiles: [],
        curBinFiles: [],
        cur_files_json:null,
        addnew: true,
        is_complete: false,
        task:{name:"",description:"",completed_note:"", assigned_to: 0, doc_id: 0, task_id: 0, is_completed: false},
        alert_param: null,
        iconurl: null,
        loading: false
    }



    componentDidMount()
	{
        let contact_assoc = this.props.contacts;
        console.log("followup tasks", this.props.task)
        let addnew = ('file_name' in this.props.task && this.props.task['file_name'] !=="") ? false : true;
        let due_date = ('due_date' in this.props.task) ? new Date(this.props.task['due_date']) : this.state.due_date;
        this.setState({ contact_list: contact_assoc  , ready: true, task: this.props.task, due_date: due_date, addnew:addnew});
    }

    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let contact_assoc = this.props.contacts;
            console.log("followup tasks", this.props.task)
            let addnew = ('file_name' in this.props.task && this.props.task['file_name'] !=="") ? false : true;
            let due_date = ('due_date' in this.props.task) ? new Date(this.props.task['due_date']) : this.state.due_date;
            this.setState({ contact_list: contact_assoc  , ready: true, task: this.props.task, due_date: due_date, addnew:addnew});
		}
    }





    closeFollowupTaskModal = (event) => {
        event.preventDefault()
        this.props.closeUserDialog();
    }
    handleChange = (evt) => {
        let task = this.state.task;
        task[evt.target.name] = evt.target.value;
        this.setState({task: task})
    }

    onDateChangeDueBy = (date) => {
        this.setState({due_date: date})
    }

    addCompleteNote = (event) => {
      this.setState({is_complete: true})
    }



    addSubTask = (mode) => (event) => {
        if (event) event.preventDefault();
        let postData = { command: "add_followup_task" };
        let task = this.state.task;
        if("file_name" in task && Object.keys(task['file_name']).length > 0  && this.state.binFiles.length > 0){
           task["uploaded_doc"] = this.state.binFiles;
        }
        if(!this.state.is_complete)delete task["completed_note"]
        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
        task['due_date'] = dueDateStr;
        postData['task'] = task;
        postData['mode'] = mode;
        console.log("postData", postData);
        this.setState({loading: true})
        let api = new TAPICall();
        api.command(postData, this.processFollowup);
    }

    processFollowup = (result) => {
        console.log("result", result)
        let stack = {};
        let task = this.state.task;
        let t = {};
        t['id'] = task.id;
        t['name'] = task.name;
        t['description'] = task.description;
        t['cur_assigned_to'] = task.assigned_to
        t['cur_assigned_to'] = task.assigned_to
        let cur_lane = "COMP_DOC_FOLLOWUP_ASSIGN";
        let msg = "The follow-up task has been updated.";
        if("mode" in result){
            switch(result['mode']){
                case 1:
                    msg = "The follow-up task has been updated.";
                    break;
                case 2:
                    cur_lane = "COMP_DOC_FOLLOWUP_COMPLETE";
                    msg = "The follow-up task has been completed.";
                    break;
            }
        }
        t['cur_lane'] = cur_lane;
        stack['task_id'] = result.error_code === 0 ? task.id : 0
        stack['task'] = result.error_code === 0 ? t : {};
        this.setState({loading: false})
        let alert_param = {title: "Success",message: msg,ok_text: "OK",confirm: false,alertHandler: this.AlertremoveHandler,stack: stack};
        this.setState({ alert_param: alert_param });
    }

    AlertremoveHandler = (result, stack) => {
        this.props.refreshParentCallback(stack)
      };

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData("contact").ID;
        let t = {};
        for (let i = 0; i < files.length; i++) {
          files[i].name = files[i].name.replace("&", "");
          if (!("user_id" in files[i])) {
            files[i].user_id = contact_id;
            files[i].upload_date = last_action_time;
          }
          t[files[i].uid.toString()] = files[i].name
        }

        let task = this.state.task;
        task["file_name"] = t;
        this.setState({binFiles: files,addnew: false, task: task});
    }

    render()
	{

        if (!this.state.ready) {
			return (
				<div></div>
			);
		}
        console.log("this.state", this.state)
        return(<div>
            <ScoreContainer>

            {
                (() => {
                    if(this.state.loading){
                        return <CSLLoader style={{position: 'absolute'}} />
                    }

                })()
            }

            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow-up Actions</div>
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>


                <DocHeader style={{borderRadius:"0", height:"50px"}}>
                  {
                    (() => {
                        if(this.state.task['is_completed']){
                            return <div style={{fontSize: "14px",float: "left",marginTop: "15px",marginLeft: "10px"}}>View Follow-up Task</div>
                        }else{
                            return <div style={{fontSize: "14px",float: "left",marginTop: "15px",marginLeft: "10px"}}>Update Follow-up Task</div>
                        }
                    })()
                  }
                  <ModalHeaderCloseBtn onClick={this.closeFollowupTaskModal}><FaTimes /></ModalHeaderCloseBtn>
                </DocHeader>

                <ScoreBodyContainer>
                    <ModalNameLabelDiv>Task Name</ModalNameLabelDiv>
                    <SpacerL></SpacerL>
                    <ModalNameInputDiv>
                        <ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.task['name']} readOnly={this.state.task['is_completed']}/>
                    </ModalNameInputDiv>
                    <SpacerL></SpacerL>
                    <ModalNameLabelDiv>Description</ModalNameLabelDiv>
                    <SpacerL></SpacerL>
                    <ModalNameInputDiv>
                      <ModalTextArea name="description" onChange={this.handleChange} value={this.state.task['description']} readOnly={this.state.task['is_completed']} />
                    </ModalNameInputDiv>
                    <SpacerL></SpacerL>

                    <ModalNameLabelDiv>Assign To</ModalNameLabelDiv>
                    <SpacerL></SpacerL>
                    {
                        (() => {
                            if(this.state.task['is_completed']){
                                return <ModalNameInputDiv>{this.state.contact_list[this.state.task['assigned_to'].toString()]}</ModalNameInputDiv>
                            }else{
                                return(
                                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.task['assigned_to']}>
                                        {
                                            (() => {
                                                  if (Object.keys(this.state.contact_list).length !== 0) {
                                                    let c = [];
                                                    for (let key in this.state.contact_list) {
                                                      c.push(
                                                        <option key={key} value={key}>
                                                          {this.state.contact_list[key]}
                                                        </option>
                                                      );
                                                    }
                                                    return c;
                                                  }
                                            })()
                                        }
                                    </MRModalSelect>)
                            }
                        })()
                    }

                   <SpacerL></SpacerL>
                    <ModalNameLabelDiv>Due Date</ModalNameLabelDiv>
                    <SpacerL></SpacerL>
                    <CSLDateCover>
                        <DatePicker
                                onChange={this.onDateChangeDueBy}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                value={this.state.due_date}
                                />
                    </CSLDateCover>
                   {
                        (() => {
                            if(!this.state.task['is_completed']){
                                return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={this.state.addnew} buttontxt="Add an attachment"/>
                            }
                        })()
                   }
                    {
                        (() => {
                            if(this.state.task['id'] > 0 && !this.state.addnew && this.state.binFiles.length === 0){
                                return <CSLAtteachmentViewer files={this.state.task['file_name']} task_id ={this.state.task['id']} is_completed = {this.state.task['is_completed']} />
                            }
                        })()
                    }


                    {
                      (() => {
                        if (this.state.is_complete) {
                          return(<div>
                            <ModalNameLabelDiv>Comment</ModalNameLabelDiv>
                            <SpacerL></SpacerL>
                            <ModalNameInputDiv>
                              <ModalTextArea name="completed_note" onChange={this.handleChange} value={this.state.task["completed_note"]} />
                            </ModalNameInputDiv>
                          </div>)
                        }
                      })()
                    }

                    {
                      (() => {
                        if (this.state.task["is_completed"]) {
                          return(<div>
                            <ModalNameLabelDiv>Completion Comments</ModalNameLabelDiv>
                            <SpacerL></SpacerL>
                            <ModalNameInputDiv>
                              <ModalTextArea value={this.state.task["completed_note"]} />
                            </ModalNameInputDiv>
                          </div>)
                        }
                      })()
                    }


                </ScoreBodyContainer>
                <DocFooter style={{textAlign: "right", height: "35px", padding:"10px"}}>

                    {
                        (() => {
                            if(!this.state.task['is_completed']){
                                return(<div>
                                        {
                                         (() => {
                                           if(this.state.is_complete){
                                             return <MRModalNextBtn onClick={this.addSubTask(2)}>SUBMIT</MRModalNextBtn>;
                                           }else{
                                             return(<div>
                                                 {
                                                   (() => {
                                                   if (this.state.task["id"] > 0) {
                                                     return (
                                                       <div>
                                                         <MRModalNextBtn onClick={this.addSubTask(1)}>UPDATE</MRModalNextBtn>
                                                         <MRModalNextBtn onClick={this.addCompleteNote}>COMPLETE</MRModalNextBtn>
                                                       </div>
                                                     );
                                                   } else {
                                                     return <MRModalNextBtn onClick={this.addSubTask(0)}>SUBMIT</MRModalNextBtn>;
                                                   }
                                                 })()
                                               }
                                             </div>)
                                           }
                                         })()
                                        }
                                    </div>)
                            }

                        })()
                    }


                    <MRModalCancelBtn onClick={this.closeFollowupTaskModal}>CANCEL</MRModalCancelBtn>
                </DocFooter>
                <AlertBox alertParam={this.state.alert_param} />
            </ScoreContainer>

        </div>);
    }

}
export default AddTaskModal;
