import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import RegisterFollowupTaskModal from '../Select/RegisterFollowupTaskModal';
import CSLTable from '../Common/CSLTable';
import ManagerOutstandingTask from '../Register/ManagerOutstandingTask';
import APICall from './../../Common/APICall';
import FollowupTaskModal from './../Select/FollowupTaskModal';
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;  
  margin-right:15px;
  opacity: 1 ; 
`;
const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  margin-right:15px;  
  opacity: 1 ; 
`;
const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;  
  margin-right:15px;
  opacity: 1 ; 
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 300%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
//   left: -2%;
//   top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
class RegisterChildTasksRUTable extends React.Component
{
    state={
        modal_view: null
    }

    constructor(props) {
        super(props);
        console.log("ABC==>",this.props.followup_tasks)
    }
    processChildFeedsData = () => {
        let ret = {data: [], columns: [], row_count: 0};
        let feedsdata = this.props.followup_tasks;
        let i = 0;
        console.log("feedsdata length =>", feedsdata.length );
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status_value', minWidth: 100, headerStyle: {textAlign: 'left'}, 
                        Cell: row => (<div>
                            {
                              (() => {                          
                                switch(row.original.status_value) {
                                  case 'Overdue': return  <div><Overdue><span style={{paddingLeft: "15px"}}></span></Overdue>{row.original.status_value}</div>; break;                                  
                                  case 'Completed': return  <div><Complete><span style={{paddingLeft: "15px"}}></span></Complete>{row.original.status_value}</div>; break;                                  
                                  default: return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status_value}</div>
                                }
                              })()
                            }
                          </div>), headerStyle: {textAlign: 'left'}},
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Notes', accessor: 'notes', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                        
                        {'Header' : 'Action', Cell: row => (
                            <div>
                                <MRButton style={{cursor: "pointer"}} onClick={this.viewAssignTask(row.original.id)}>View</MRButton>
                            </div>
                          ), minWidth: 100, headerStyle: {textAlign: 'center'}},
                    ];

        for(let d of feedsdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let status = "Pending";
            if(d.last_action === 'COMP_RCM_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_RCM_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            let contacts = Store.getStoreData('contacts');
            let contact_name = "None";
            for(let i = 0; i < contacts.length; i++)
            {
                if(contacts[i].ID == parseInt(d.cur_assigned_to)){
                    contact_name = contacts[i].ContactName; 
                    break;
                }
            }
            let notes = tdata[0].note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            var day_str = dd > 10 ? dd.toString() : "0"+dd.toString();
            var month_str = mm > 10 ? mm.toString() : "0"+mm.toString();
            var str_due_date = day_str + "/"+ month_str +"/"+yyyy.toString();

            var due_date_status = new Date(yyyy, mm-1, dd);

            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }            
            let elem = {
                    'index' : i, 
                    'id': d.id,
                    'assign_to' : contact_name,
                    'notes' : notes,
                    'due_date' : str_due_date,
                    'issue_date' : d.issue_date,
                    'status' : {text: text, color: color},
                    'status_value': text
                   };
            ret.data.push(elem);
        }
        ret.row_count = i;
        Store.updateStore('table_cur_followup_data', ret.data)
        return ret;
    }

    viewAssignTask = (followupid) => (event) => {
        event.preventDefault();
        console.log(followupid)
        // Store.updateStore('cur_followup_task', this.props.followup_tasks[index])
        // this.setState({ modal_view: 'view_followup_task'})
        let postData = { command: "get_subtask", task_id: followupid};
        let api = new APICall();
        api.command(postData, this.openFollowupModal);  
    }

    openFollowupModal = (result) => {
        console.log("followup_task",result.result.task);
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ modal_view: 'view_followup_task'})
                 
    }

    closeModal = () => {
        this.setState({ modal_view: null });
    }

    viewOutstandingTask = (event) => {
        event.preventDefault();
        this.setState({ modal_view: 'show_outstanding_modal'})
    }

    render()
    {
        let noOfRows = 'noOfRows' in this.props ? this.props.noOfRows : 5;
        if(this.props.followup_tasks.length !== 0){
            return(<div>
                <RegDismisOuterContainer>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.props.headertext} ( {this.props.followup_tasks.length} )</div> 
                    {
                        (() => {
                            if('Outstanding' in this.props && this.props.Outstanding === true){                                
                                return(<div style={{float: "right"}}><MRButton style={{cursor:"pointer",border: "1px solid #282828",width: "auto"}} onClick={this.viewOutstandingTask}>Chase Outstanding Actions</MRButton></div>)                                
                            }
                        })()
                    }                               
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>          
                    <CSLTable add={false} processData={this.processChildFeedsData} tableRows={noOfRows} headerText={''} />
                </OverviewInfoBody>
                </RegDismisOuterContainer>
                {
                    (() => {
                        if(this.state.modal_view === "view_followup_task"){
                            //return(<RegisterFollowupTaskModal closeModal={this.closeModal}/>)
                            return(<div style={{position:'absolute', top:'0%', width:'100%', height:'33%'}}><InactiveOverlay /><FollowupTaskModal closeModal={this.closeModal}/></div>)
                        }else if(this.state.modal_view === 'show_outstanding_modal'){
                            return(<ManagerOutstandingTask closeModal={this.closeModal}/>)
                        }
                    })()
                }
                </div>);
        }else{
            return(<div>
                <RegDismisOuterContainer>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.props.headertext} ( {this.props.followup_tasks.length} )</div>                                
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>           
                <OverviewInfoBody>
                    <div style={{textAlign: "center",fontStyle: "italic", color: "#c4c4c4"}}>There are currently no Associated RC Tasks Available</div>
                </OverviewInfoBody>
                </RegDismisOuterContainer>
                </div>);
        }        
    }
}
export default RegisterChildTasksRUTable;