import React from "react";
import styled from "styled-components";
import TAPICall from "./TAPICall.js";
import AddTaskModal from "./AddTaskModal";
import Store from '../../../Common/Store';
import AlertBox from "../../Common/AlertBox";

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: fixed;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;

const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 34px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #282828;
`;

class DocumentHOC extends React.Component {
  state = {
    task: null,
    contacts: null,
    user_roles: null,
    ready: false,
    show_modal: false,
    task_loading: false,
    alert_param: null
  };

  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.callTask();
  };

  hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
    if (Object.keys(gc_users).length === 0) return true;
    if (manager_id === "") return true;
    let manager_companies = gc_users[manager_id];
    let user_companies = gc_users[user_id];
    for (let cid in manager_companies) {
      for (let uid in user_companies) {
        if (cid.toString() === uid.toString()) return true;
      }
    }
    return false;
  };

  processFollowuptask = (result) => {
    console.log("resullt tasks", result)
    let raw_task = result.result.task;
    let r = {};
    r['result'] = {};
    let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;

    let user_roles = {};
    let contact_assoc = {};
    if('contactlist' in cts.result && 'user_roles' in cts.result) {
      user_roles = cts.result.user_roles['docmgmt'];
      for(let k in cts.result.contactlist){
        if (cts.result.user_roles['docmgmt'][k.toString()] !== 'no_access' && cts.result.user_roles["docmgmt"][k.toString()] !== undefined) {
          contact_assoc[k.toString()] = cts.result.contactlist[k.toString()].ContactName
        }
      }
    }
    let is_access = false;
    let contact = Store.getStoreData("contact");
    let created_by = raw_task.contact_id;
    let cur_assign = raw_task.cur_assigned_to;
    let role = cts.result.user_roles['docmgmt'][contact.ID.toString()]
    if(role === "admin_manager"){
      is_access = true;
    }else{
      if(parseInt(contact.ID) === parseInt(created_by) || parseInt(contact.ID) === parseInt(cur_assign)){
        is_access = true;
      }
    }
    if(is_access){
      let task_json = JSON.parse(raw_task.task_json);
      let task = {};
      task['id'] = raw_task.id;
      task['name'] = task_json.object_data.name;
      task['description'] = task_json.object_data.description;
      task['assigned_to'] = raw_task.cur_assigned_to;
      task['file_name'] = ('file_name' in task_json.object_data) ? task_json.object_data.file_name : "";
      task['due_date'] = raw_task.due_date;
      task['doc_id'] = raw_task.ref_id;
      task['completed_note'] = ('completed_note' in task_json.object_data) ? task_json.object_data.completed_note : "";
      task['is_completed'] = raw_task.cur_lane === "COMP_DOC_FOLLOWUP_COMPLETE" ? true : false;

      let assignments = result.result.assignments;
      let user_assignments = result.result.user_assignments;
      let companies_assoc = result.result.companies_assoc;
      for (let c_id in companies_assoc) {
        let include = false;
        if ("companies" in assignments && c_id in assignments.companies) {
          include = true;
        } else {
          if ("tags_config" in companies_assoc[c_id]) {
            for (let t_id in companies_assoc[c_id].tags_config) {
              for (let v_id in companies_assoc[c_id].tags_config[t_id]) {
                if ("tags" in assignments && t_id in assignments.tags && v_id in assignments.tags[t_id]) include = true;
              }
            }
          }
        }
        if (!include && result.result["role"]!=="admin_manager") delete companies_assoc[c_id];
      }
      let gc_users = {};
      for (let k in user_assignments) {
        if (!(k in gc_users)) gc_users[k] = {};
        for (let key in companies_assoc) {
          if (key in user_assignments[k].companies) {
            gc_users[k][key.toString()] = 1;
          }
        }
      }
      let show_all = user_roles[contact.ID.toString()] === "admin_manager" ? true : false;

      let user_list = {};
      if (Object.keys(gc_users).length !== 0) {
        for (let key in contact_assoc) {
          if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
            if (key in gc_users) {
              user_list[key.toString()] = contact_assoc[key.toString()];
            }
          }
        }
      } else {
        user_list = contact_assoc;
      }
      this.setState({user_roles: user_roles, contacts: user_list,task: task, show_modal: true, ready: true})
    }else{
      let alert_param = { title: "No Access", message: "You are not authorised to access this task. If you think this is in error, please contact your administrator.", ok_text: "Ok", confirm: false, alertHandler: this.alertHandler, stack: { id: 0 } };
      this.setState({ ready: true, alert_param: alert_param });
      return false;
    }

  }

  alertHandler = (result, stack) => {
    this.setState({ alert_param: null });
    window.location = "/select/ServiceBasedLanding";
  };

  callTask = () => {
    let task_id = this.props.task_id;
    let api = new TAPICall();
    let postData = { command: "get_followup_task", task_id: task_id };
    console.log("postData", postData)
    this.setState({task_loading: true})
    api.command(postData, this.processFollowuptask);
  }

  closeUserDialog = () => {
    this.setState({show_modal: false, task_loading: false})
    this.props.closeUserDialog(true)
  }

  refreshState = (stack=null) => {
      console.log("refresh satete", stack)
      this.setState({show_modal: false})
      if(stack!==null){
        let task = stack.task;
        if(task.cur_lane === "COMP_DOC_FOLLOWUP_COMPLETE"){
          stack['mode'] = 'delete'
        }
        this.props.refreshParentState(stack)
      }else{
          this.props.closeUserDialog()
      }


      // this.props.refreshState()
  }

  render() {
    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }
    if(!this.state.ready)return(<div>....</div>)
    return (
      <FolderContainer>
      {
        (() => {
          if(this.state.show_modal) {
            return <AddTaskModal contacts ={this.state.contacts} task = {this.state.task} closeUserDialog={this.closeUserDialog} refreshParentCallback={this.refreshState} />
          }

        })()
      }
      </FolderContainer>
    );
  }
}

export default DocumentHOC;
