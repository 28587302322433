import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import CSLTable from '../Common/CSLTable';
import DataCardSection from './DataCardSection';
import APICall from '../../Common/APICall';
import PendingInfoTaskModal from './PendingInfoTaskModal';
import PendingAcknowTaskModal from '../AM/AMPendingAcknowTaskModal';
import CSLLoader from '../Common/CSLLoader';
import moment from 'moment';

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;

const RUThumbnailsContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
`;
const RUThumbnail = styled.div`
    float: left;
    box-sizing: border-box;
    margin-left: 20px;
    width: calc(22% - 10px);
    height: 160px;
    background-color: #ffffff;
    border-radius: 3px;
`;
const RUThumbnailHeader = styled.div`
    padding: 20px 20px 0px 20px;
    font-weight: 600;
    color: #282828;
    font-size: 16px;
`;
const RUThumbnailbody = styled.div`
    padding: 20px;
    font-weight: 600;
    font-size: 50px;
    color: #434343;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 92%;
    margin: 0 auto;
    margin-top: 30px;
    border: 1px solid #d7dddf;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 92%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 12px;
    height: 25px;
    border-radius: 4px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class PendingTaskTable extends React.Component
{
    state = {
        ready: false,
        my_pending_tasks: [], 
        showModal: null,
        isloadericon: false       
    };
    constructor(props) {
        super(props);
    }

    setComponentState = () => {
        let pending_tasks = Store.getStoreData('mypendingtask');
        let task_id = this.props.taskId;
        this.setState({my_pending_tasks: pending_tasks})
        if(task_id > 0) {
            console.log("task id", task_id)
            this.goToPendingTaskUrl(task_id)
        }else{
            this.setState({ready: true})
        }
    }

    componentDidMount()
    {
        this.setComponentState()
    }

    componentDidUpdate(prevprops)
    {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    processMyPandingTask = () => {
        var mypendingtaskdata = this.state.my_pending_tasks;
        //console.log("mytaskdata",mypendingtaskdata)
        let lp = Store.getStoreData('language_pack')
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
            {'Header' : lp['status'] === undefined ? 'Status' : lp['status'], accessor: 'status_text', Cell: row => (
                <div>
                    {
                        (() => {
                            if(row.original.status === "Pending"){
                                return( <div style={{borderLeft: "9px solid #143151",height: "24px"}}><div style={{padding: "4px",marginLeft: "10px"}}>{row.original.status_text}</div></div>)
                            }else{
                                return( <div style={{borderLeft: "9px solid #DD4054",height: "24px"}}><div style={{padding: "4px",marginLeft: "10px"}}>{row.original.status_text}</div></div>)
                            }
                        })()
                    }
                    
                    </div>
                ), width: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}},
            {Header: lp['attestation_name'] === undefined ? 'Attestation Name' : lp['attestation_name'], accessor: 'document_name', minWidth: 350, headerStyle: {textAlign: 'left',fontWeight: "600"}},
            {Header: lp['type'] === undefined ? 'Type' : lp['type'], accessor: 'type', minWidth: 170, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
            {Header: lp['created_date'] === undefined ? 'Created Date' : lp['created_date'], accessor: 'created_date', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
            {Header: lp['due_date'] === undefined ? 'Due Date' : lp['due_date'], accessor: 'due_date', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}, sortMethod: (a, b) => {
                if (a === b) {
                    return 0;
                }
                let adue = a.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                let  bbdue = b.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse > bReverse ? 1 : -1;
              }}, 
            {Header: lp['action'] === undefined ? 'Action' : lp['action'], Cell: row => (
                <div>
                    <MRButton style={{cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>{lp['view'] === undefined ? 'View' : lp['view']}</MRButton>
                </div>
            ), width: 120, headerStyle: {textAlign: 'center',fontWeight: "600"}}];

        
        for(let d of mypendingtaskdata) {           
            i++;            
            let today = moment().unix();
            let pen = lp['pending'] === undefined ? 'Pending' : lp['pending']
            let ove = lp['overdue'] === undefined ? 'Overdue' : lp['overdue']
            let status_text = (d[1]+86400) > today ? pen : ove;
            let status = (d[1]+86400) > today ? 'Pending' : 'Overdue';            
            let documentName = d[3];
            // let Type = d[4] === "information" ? "Information" : "Acknowledge";  
            let Type = d[4] === "information" ? lp['information'] === undefined ? 'Information' : lp['information'] : lp['acknowledge'] === undefined ? 'Acknowledge' : lp['acknowledge'];                     
            let elem = {
                    'id': d[0],
                    'status' : status,
                    'status_text' : status_text,
                    'document_name': documentName,
                    'type': Type,
                    'created_date': moment.unix(d[2]).format('DD/MM/YYYY'),
                    'due_date': moment.unix(d[1]).format('DD/MM/YYYY')
                   };
            ret.data.push(elem);

            let sarr = [];                       
            ret.data.forEach((item) => {
                if (item.status === "Overdue") {
                    sarr.push(item);
                }
            })
            ret.data.forEach((item) => {
                if (item.status === "Pending") {
                    sarr.push(item);
                }
            })
            ret.data = sarr;
        }
        ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
              return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);            
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          });        
        return ret;
    }

    goToTaskUrl = (row) => (event) => {
        event.preventDefault();  
        //console.log('ROWS', row);      
        this.goToPendingTaskUrl(row);            
    }

    goToPendingTaskUrl = (row) => {
        //event.preventDefault();
        if(this.props.taskId > 0) {            
            let shareType = Store.getStoreData('cur_share_type')
            if(shareType === "Information"){                
                this.setState({showModal: 'view_pending_info_modal',ready: true});
            }else{                
                this.setState({showModal: 'view_pending_acknow_modal',ready: true});
            }            
        }else{
            console.log('ROWS', row); 
            let postData = { command: "get_subtask", task_id: row.original.id};
            let api = new APICall();
            if(row.original.type === 'Information'){
                api.command(postData, this.openPendingTaskModal);
            }else{
                api.command(postData, this.openPendingAcknowTaskModal);
            }
            this.setState({isloadericon: true}) 
        }
    }

    openPendingTaskModal = (result) => {
        console.log(result);
        Store.updateStore('cur_pending_task', result.result.task);
        // Store.updateStore('cur_parent_pending_task', result.parentdatanew);
        Store.updateStore('cur_parent_binFiles', result.binFiles);
        this.setState({showModal: 'view_pending_info_modal',ready: true});
    }

    openPendingAcknowTaskModal = (result) => {
        console.log(result);
        Store.updateStore('cur_pending_task', result.result.task);
        // Store.updateStore('cur_parent_pending_task', result.parentdatanew);
        Store.updateStore('cur_parent_binFiles', result.binFiles);
        this.setState({showModal: 'view_pending_acknow_modal',ready: true});
    }

    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }

    render()
    {  
        let lp = Store.getStoreData('language_pack')
        let task_id = this.props.taskId;
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'view_pending_info_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><PendingInfoTaskModal closeModal={this.closeModal} closeTaskModal={this.props.closeTaskModal}/></div>; break;
                            case 'view_pending_acknow_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><PendingAcknowTaskModal closeModal={this.closeModal} closeTaskModal={this.props.closeTaskModal}/></div>; break;                                                       
                        }
                    }
                })()
                }
            </div>)
        }
        return (<div>    
                {
                    (() => {
                        if (this.state.showModal !== null) {
                            switch(this.state.showModal) {
                                case 'view_pending_info_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay style={{height: "230%"}}/><PendingInfoTaskModal closeModal={this.closeModal} /></div>; break;
                                case 'view_pending_acknow_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay style={{height: "230%"}}/><PendingAcknowTaskModal closeModal={this.closeModal} /></div>; break;
                            }
                        }
                    })()
                }
                {
                    (()=> {
                        if(this.state.isloadericon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }        
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>{lp['my_open_attestations'] === undefined ? 'My Open Attestations' : lp['my_open_attestations']}</div>                       
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processMyPandingTask} headerText={''} tableRows={7} refreshCallback={this.refreshCallback} /> 
                </OverviewInfoBody>
                </div>
        );
    }
}
export default PendingTaskTable;