import React from 'react';
//import './src/App.css';
import styled from 'styled-components';
import Store from './src/Common/Store.js';
import BaseStore from './../../../Common/Store.js';
import APICall from './src/Common/TAPICall.js';
import APIContact from './src/Common/APIContact.js';
import Utils from './src/Common/Utils.js';
import ComDecom from './src/Common/ComDecom.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CSLLoader from './src/Components/Common/CSLLoaderNonPromise'
import ModuleConfigHOC from './src/Components/Moduleconfig/ModuleConfigHOC';
import CSLPageloader from './src/Components/Common/CSLPageloader';
import AlertBox from './src/Components/Common/AlertBox';
import CommitteeUtils from './src/Components/Common/CommitteeUtils';
import AddActionsTmView from './src/Components/Tabs/AddActionsTmView';
import moment from 'moment';

const start_time = moment().seconds();

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;

const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 34px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #282828;
`;

class GovernanceTmViewHOC extends React.Component {
  state = {
		ready:false,
		module_configs : {},
		alert_param: null,
		is_loader : true,
  };

    componentDidMount() {
		//console.log("cmp contactlist,",localStorage.getItem("contactlist"));
		this.setEnv('committees');
		this.processContactData();
		/*setTimeout(() => {
			this.callApi();
		}, 200);*/

    }
    setEnv(module_name) {
		let api_url = `${BaseStore.getStoreData('base_api_url')}/microservice/committees/api/command`;

        Store.updateStore('module_name',module_name);
		Store.updateStore('api_url',api_url);
		Store.updateStore('is_admin',false);
		Store.updateStore('is_debug',false);

        //let postData = { command: "taskindex" };
        //let api = new TAPICall();
        //api.command(postData, this.processtaskIndex);
    }

   processContactData = () => {

		let cl = JSON.parse(localStorage.getItem("contactlist")).result.contactlist;
		//console.log("contactlist,",cl);
		let user_roles = JSON.parse(localStorage.getItem("contactlist")).result.user_roles.committees;
		let contactist_assoc = cl;
		let contact_list = {};
		let listcontacts = [];
		let contactrole = [];
		let company_users = {};
		Object.keys(contactist_assoc).forEach((uid) => {
			if(uid in user_roles){
				contact_list[uid.toString()] = contactist_assoc[uid.toString()].ContactName;
				listcontacts.push({ID : parseInt(uid) , ContactName : contactist_assoc[uid.toString()].ContactName });
				company_users[uid.toString()] = {ID : parseInt(uid) , ContactName : contactist_assoc[uid.toString()].ContactName , email : contactist_assoc[uid.toString()].EmailAddress , role : user_roles[uid.toString()]};
				contactrole.push({user_id : parseInt(uid) , role : user_roles[uid.toString()]});
			}

		});

		Store.updateStore('contacts', listcontacts);
		Store.updateStore('contact_list', contact_list);
		Store.updateStore('users', company_users);
		Store.updateStore('contactrole', contactrole);
		//Store.updateStore('company_users', company_users);
		this.callApi();

   }
    callApi(){
    	let postData = { command: "index_tmview" , data:{include_inactive_committee : 'yes'} , task_id: this.props.task_id , ref_id: this.props.ref_id};
        let api = new APICall();
        api.command(postData, this.processIndex);
    }

    processIndex = (result) => {
		console.log("result,",result);
		let comdecom = new ComDecom();
		let committeeUtils = new CommitteeUtils();
    	if(result.error_code === 0) {
    		if(result.role === "no_access") {
              Store.updateStore('role', result.role);
    		  let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
    		                     alertHandler: this.alertHandler, stack: {}};
    		  this.setState({ ready: true, alert_param: alert_param});
    		  //return false;
    		}else{
				let parent_has_group_companies = false;
				let module_configs = {};
				let tasks = [];
				let followup_tasks = [];
				let permissions = {};
				permissions['admin_manager'] = {'module_config' : true , 'add_committee' : true ,  'edit_committee' : true ,  'delete_committee' : true , 'view_committee_structure' : true , 'view_committee_details' : true , 'add_meeting' : true , 'edit_meeting' : true , 'view_meeting' : true , 'delete_meeting' : true , 'add_followup_task_in_meeting' : true , 'view_followup_task_in_meeting' : true , 'edit_followup_task_in_meeting' : true};
				permissions['governance_manager'] = {'module_config' : false , 'add_committee' : true ,  'edit_committee' : true ,  'delete_committee' : false , 'view_committee_structure' : true , 'view_committee_details' : true , 'add_meeting' : true , 'edit_meeting' : true , 'view_meeting' : true , 'delete_meeting' : false , 'add_followup_task_in_meeting' : true , 'view_followup_task_in_meeting' : true , 'edit_followup_task_in_meeting' : true};
				permissions['team'] = {'module_config' : false , 'add_committee' : false ,  'edit_committee' : false ,  'delete_committee' : false , 'view_committee_structure' : true , 'view_committee_details' : true , 'add_meeting' : false , 'edit_meeting' : false , 'view_meeting' : true , 'delete_meeting' : false , 'add_followup_task_in_meeting' : true , 'view_followup_task_in_meeting' : true , 'edit_followup_task_in_meeting' : false};
				permissions['no_access'] = {'module_config' : false , 'add_committee' : false ,  'edit_committee' : false ,  'delete_committee' : false , 'view_committee_structure' : false , 'view_committee_details' : false , 'add_meeting' : false , 'edit_meeting' : false , 'view_meeting' : false , 'delete_meeting' : false , 'add_followup_task_in_meeting' : false , 'view_followup_task_in_meeting' : false , 'edit_followup_task_in_meeting' : false};
				Store.updateStore('permissions', permissions[result.role]);

				let assignments = 'assignments' in result.result ? result.result.assignments : {}
				let user_assignments = 'user_assignments' in result.result ? result.result.user_assignments : {}
				Store.updateStore('role', result.role);
				Store.updateStore('assignments', assignments);
				Store.updateStore('user_assignments', user_assignments);
				//Store.updateStore('contacts', result.result.contacts.result.listcontacts);
				//Store.updateStore('contact_list', result.result.contacts.result.contacts);
				Store.updateStore('parent_has_group_companies', result.result.parent_has_group_companies);
				Store.updateStore('loggedin_user_info', result.result.loggedin_user_info);
				//Store.updateStore('contactrole', result.result.contactrole.result.contactrole);
				let companies = 'companies' in result.result ? result.result.companies : {};
				Store.updateStore('companies', companies);
				let companies_assoc = 'companies_assoc' in result.result ? result.result.companies_assoc : {};
				for(let c_id in companies_assoc) {
					let include = false
					if('companies' in  assignments && c_id in assignments.companies) {
						include = true
					} else {
						if('tags_config' in companies_assoc[c_id]){
							for(let t_id in companies_assoc[c_id].tags_config) {
								for(let v_id in companies_assoc[c_id].tags_config[t_id]) {
									if('tags' in assignments && t_id in assignments.tags && v_id in assignments.tags[t_id])include = true
								}
							}
						}

					}
					if(!include)delete companies_assoc[c_id]
				}

				Store.updateStore('companies_assoc', companies_assoc);
				let tag_values = 'tag_values' in result.result ? result.result.tag_values : {};
				for(let t_id in tag_values) {
					if('tags' in assignments &&  !(t_id in assignments.tags)) {
						delete tag_values[t_id]
					} else {
						for(let v_id in tag_values[t_id].values) {
							if('tags' in assignments && !(v_id in assignments.tags[t_id]))delete tag_values[t_id].values[v_id]
						}
					}
				}
console.log("I am here 1");
				Store.updateStore('tag_values', tag_values);
				let company_name = 'company_name' in result.result ? result.result.company_name : 'N/A';
				Store.updateStore('company_name', company_name);
				//Store.updateStore('employee_list', comdecom.deCompress(result.result.employee_list));
                committeeUtils.map_employee_with_contact(comdecom.deCompress(result.result.employee_list));
                let committee_input_json = {committees: comdecom.deCompress(result.result.committeeWithCount) , meetings : comdecom.deCompress(result.result.meetings_list) , tasks_list: [] };
				let committeeWithCount_json = committeeUtils.convertToCommitteesWithCount(committee_input_json);

				let governance = result.result.governance;
				//Store.updateStore('governance', governance);
				let filtered_committees = []
				console.log('companies_assoc', companies_assoc)
				for(let gc of governance.committees) {
					if(result.role !== 'admin_manager'){
						let association = 'association' in gc.json_data ? gc.json_data.association : 'parent'
						switch(association) {
							case 'gc': if(gc.json_data.gc_id in companies_assoc)filtered_committees.push(gc); break;
							case 'grouping' : if(gc.json_data.grouping_id in assignments.tags && gc.json_data.grouping_value_id in assignments.tags[gc.json_data.grouping_id])filtered_committees.push(gc); break;
							default: filtered_committees.push(gc);
						}
					}else{
						filtered_committees.push(gc);
					}
				}

				for(let g of filtered_committees) {
					g.parent_id = g.json_data.parent_id === '' ? '0' : g.json_data.parent_id;
					g.id = g.id;
					g.show = g.is_active === true ? true : false;
					g.gc_id = ('gc_id' in g.json_data) ? g.json_data.gc_id : '0';
				}
				let is_member_of_committee_hash = {};
				for(let committee of committeeWithCount_json.committees) {
					is_member_of_committee_hash[committee.PK_CommitteeID.toString()] = committee.is_member_of_this_committee ? true : false;
				}

				if(result.result.parent_has_group_companies && result.role === 'governance_manager'){
					for(let p of filtered_committees){
						if(user_assignments[result.result.loggedin_user_info.ID]['companies'][p.gc_id] !== 1) is_member_of_committee_hash[p.id.toString()] = false;
						if(p.gc_id.toString() === '0') is_member_of_committee_hash[p.id.toString()] = true;
					}
				}
				for(let p of filtered_committees){
					p.is_member_of_committee = is_member_of_committee_hash[p.id.toString()];
				}

				let gov_committees = committeeUtils.convertListToTree(filtered_committees);

				module_configs = JSON.parse(result.result.module_configs.config_json);
				//Store.updateStore('module_configs', module_configs);
				Store.updateStore('ViewCommitteeTasksListVisibility', true);

				let committee_meeting_date_assoc = committeeWithCount_json.committee_meeting_date_hash;
				let action_extra_json = {};

				//console.log('filtered_committees', filtered_committees, Store.getStoreData('contact_list') , action_extra_json);
				let task_json = JSON.parse(result.result.task.result.task.parenttask.task_json);
				let action_json = task_json.object_data.headerinfo.action_json;
				let bin_files = task_json.object_data.bin_files;
				let roll_over_task_ref_id = '';
				if ('roll_over_task_ref_id' in action_json) roll_over_task_ref_id = action_json.roll_over_task_ref_id;
				let view_mode = (result.result.task.result.task.parenttask.cur_lane !== 'COMP_COMMITTEES_COMPLETE') ? false : true ;
				let action_note_undertaken = ('action_note_undertaken' in action_json) ? action_json.action_note_undertaken : '';
				action_extra_json = {action_title : action_json.action_title,action_description : action_json.action_description,action_assignto : action_json.action_assignto, due_date : action_json.due_date, action_note_undertaken : action_note_undertaken , showModal : true , view_mode : view_mode , task_id : result.result.task.result.task.parenttask.id , followupBinFiles: bin_files , roll_over_task_ref_id: roll_over_task_ref_id};
				let committee_id = action_json.committee_id;
console.log("I am here 5,",committee_id,filtered_committees,committee_meeting_date_assoc);
				Store.updateStore('Meeting_Date_For_Task', committee_meeting_date_assoc[committee_id]);
				for(let c of filtered_committees){
					if(c.id.toString() === committee_id.toString()){
						Store.updateStore('Committee_Name_For_Task', c.json_data.committeename);
						break;
					}
				}


				this.setState({ready: true , module_configs : module_configs , task_json , action_extra_json, is_loader: false});
			}
    	}else{
    		let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
    		           alertHandler: this.alertHandler, stack: {id: 0}}
    		this.setState({ ready: true, alert_param: alert_param});
    	}
    }

    alertHandler = (result, stack) => {
      this.setState({alert_param: null})
      localStorage.removeItem('committees-index');
      window.location = '/select/tmview';
    }

	closeUserDialog = () => {
		this.setState({ready: false , is_loader: false});
		this.props.closeUserDialog();
	}


    actionModalOpen	= (jsonObj) => {
		console.log("jsonObj 1,",jsonObj);
		if('task_id' in jsonObj && 'task_status' in jsonObj){
			this.setState({actionModalOpen: jsonObj.status , action_extra_json : {} , is_loader: false});
			this.props.closeUserDialog(true);
		}else{
			console.log("jsonObj 2,",jsonObj);
			this.setState({actionModalOpen: jsonObj.status , action_extra_json : {}, is_loader: false});
			if('action_popup' in jsonObj)this.props.closeUserDialog();

		}
	}
	closeMeetingModal = () => {
		//Store.updateStore('meetingDataEditMode',null);
        //Store.updateStore('MeetingOverlay', null);
        Store.updateStore('ViewCommitteeTasksListVisibility', false);
        Store.updateStore('modal_committee_tasks_View_CommitteeID', 0);
		this.props.closeUserDialog(true);

    }



  render() {
    console.log("GovernanceTmViewHOC state:",this.state);
    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }
    //if(!this.state.ready)return(<div>....</div>)

    return (
      <FolderContainer>
      {
        (() => {
			if(this.state.is_loader) {
				return <div><CSLLoader /></div>
			}
        })()
      }
      {
        (() => {
          if(this.state.ready) {
             return <AddActionsTmView
					actionModalOpen={this.actionModalOpen}
					curLane={this.props.cur_lane}
					action_extra_json={this.state.action_extra_json}
					task_json={this.state.task_json}
					prop_task_id={this.props.prop_task_id}
					closeCommitteeTaskListModal={this.closeMeetingModal}
					/>;

          }

        })()
      }
      </FolderContainer>
    );
  }
}

export default GovernanceTmViewHOC;
