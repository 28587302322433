import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from '../../Common/Store.js';
import AlertBox from './AlertBox.js';

class HtmlEditor extends React.Component
{
    state = {alert_param: null};

    sameAlertremoveHandler = (result,stack) => {
                        this.setState({alert_param: null})
                    }

    render()
    {
        let content = this.props.content;
        return (
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    data={content}
                    config={{ link: {defaultProtocol: 'http://', addTargetToExternalLinks: true}, removePlugins: ['Table', 'BlockQuote', 'ImageUpload', 'Indent', 'MediaEmbed'] }}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        // console.log("event", event)
                        // console.log("editor", editor)
                        const linkCommand = editor.commands.get( 'link' );
                        const model = editor.model;
                        const selection = model.document.selection;
                        const linkHref = selection.getAttribute( 'linkHref' );
                        if(linkHref !== undefined){
                            if (linkHref.indexOf("http://") == 0 || linkHref.indexOf("https://") == 0) {
                                console.log("The link has http or https.");
                            }else{
                                let msg = 'Please ensure that you have included the full URL. It should begin with https:// or similar.';
                                let title = 'Warning'
                                // alert("Please ensure that you have included the full URL. It should begin with a protocol string such as https or http and be followed by ://.")
                                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                                this.setState({alert_param: alert_param})
                                return false
                            }
                        }
                        const data = editor.getData();
                        // selection.removeAttribute('linkHref')
                        console.log("editor.getData", data)

                        Store.updateStore('description', data);
                    } }


                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                    } }
                />
                <AlertBox alertParam = {this.state.alert_param} />
            </div>
        );
    }
}
export default HtmlEditor;