import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 200px;
    z-index: 1002;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:20px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 16px 15px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #F2EBD9;
    font-weight: bold;
    margin-bottom: 25px;
    color: #E39E00;    
`;

class AMCompleteTaskModal extends React.Component
{
    state = {
        ready: false,
        binFiles: [],
        cur_files_json: null,
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),
        completeDate: new Date(),
        resendUserID: 0,
        resendTaskID: 0,
        Parent_taskid: 0,
        alert_param: null
    };

    componentDidMount()
	{
        let task_data = Store.getStoreData('cur_complete_task');

        let parent_task_binFiles = Store.getStoreData('cur_parent_binFiles');

        let subtaskdata = task_data.subtask;
        let task_id = subtaskdata.id;
        let parenttaskdata = task_data.parenttask;
        let parent_task_json = JSON.parse(parenttaskdata.task_json);
        let task_json = JSON.parse(subtaskdata.task_json);

        //let binfile_parent_json = JSON.parse(parent_task_data.task_json);

        let cur_files_json = parent_task_binFiles;
        // if('bin_files' in binfile_parent_json.object_data){
        //     cur_files_json = binfile_parent_json.object_data.bin_files;
        // }

        let duedate = new Date(parenttaskdata.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? '0' + duedate.getDate()+'/' : duedate.getDate()+'/';
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth+'/'+duedate.getFullYear() : '0'+uploadedmonth+'/'+duedate.getFullYear();

        let actiondate = new Date(parent_task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';
        editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear();

        const complete_at_date_obj = new Date(task_json.task_data.actiondate);
        const complete_at_day = complete_at_date_obj.getDate() < 10 ? "0"+complete_at_date_obj.getDate().toString() : complete_at_date_obj.getDate();
        const complete_at_month = complete_at_date_obj.getMonth()+1 < 10 ? `0${(complete_at_date_obj.getMonth() + 1).toString()}` : complete_at_date_obj.getMonth()+1;
        const complete_at_year = complete_at_date_obj.getFullYear();
        const complete_at_date = `${complete_at_day}/${complete_at_month}/${complete_at_year}`;


        console.log("subtask",cur_files_json)
        this.setState({cur_files_json: cur_files_json,
            documentVersion: task_json.object_data.headerinfo.document_version,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,
            description: task_json.object_data.headerinfo.description,
            completeDate: complete_at_date,
            task: task_json,
            resendUserID: subtaskdata.contact_id,
            resendTaskID: subtaskdata.id,
            Parent_taskid:parenttaskdata.id,
            ready:true})
    }

    resendEmailtoUser = () => {
        console.log("taskid",this.state.resendTaskID,"userid",this.state.resendUserID)
        let postData = {command: "resend_email_to_share_user", task_id: this.state.resendTaskID, send_mail: true, contact_id: this.state.resendUserID,system_id: 1001};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);
    }

    afterresendCompleted = (result) => {
        //alert('The attestation has been re-sent.');
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The attestation has been re-sent.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.okAfterResent, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }
    okAfterResent = (result) => {
        this.setState({alert_param: null})
    }
    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_ATTEST_URL;
              }else{
                window.location = '/';
              }
        } else {
            this.props.closeModal();
        }
    }
    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    render()
    {
        let lp = Store.getStoreData('language_pack')
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        //console.log('CompleteTaskModal this.state', this.state)
        let headerText = "Review Attestation";
        let subHeaderText = "completed";
        if('headerText' in this.props){
            headerText = this.props.headerText;
            subHeaderText = "acknowledged";
        }
        let dtext = this.state.description;
        if(Utils.isBase64(dtext)){
            dtext = window.decodeURIComponent(atob(dtext))
        }
        let msg = 'This is a read only version of this attestation. It was ' + subHeaderText + ' on '+ this.state.completeDate+'.'
        return (
            <div>
            <MRModalContainer>
                <MRModalHeader>
                    {/* <MRModalHeaderText>{headerText}</MRModalHeaderText> */}
                    <MRModalHeaderText>{lp['review_attestation'] === undefined ? 'Review Attestation' : lp['review_attestation']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>View the details of your previously submitted attestation.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "5px 35px"}}>
                        <MRModalBodyInner >
                            {lp['viewing_completed_attestation_message'] === undefined ? msg : lp['viewing_completed_attestation_message'].replace('[[date]]', this.state.completeDate)}
                        </MRModalBodyInner>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",paddingTop: 25,paddingLeft: 25,paddingRight: 25, paddingBottom: 5,marginBottom: "5px"}}>
                                {
                                    (() => {
                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                            return(<div>
                                                    <MRModalLabel>{lp['document_information'] === undefined ? 'Document Information' : lp['document_information']}</MRModalLabel>
                                                    <ModalNameInputDiv>
                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} downloadThroughApi={true} ParentTaskid={this.state.Parent_taskid}/>
                                                    </ModalNameInputDiv>
                                                    <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>
                                            </div>)
                                        }
                                    })()
                                }
                                <div style={{display: "inline-block",float: "left"}}>
                                    <MRModalLabel>{lp['document_version'] === undefined ? 'Document Version' : lp['document_version']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontWeight: "600"}}>{this.state.documentVersion}</MRModalLabelSub>
                                </div>
                                <div style={{display: "inline-block",float: "right"}}>
                                    <div style={{display: "inline-block",marginRight: "80px"}}>
                                    <MRModalLabel>{lp['uploaded_date'] === undefined ? 'Uploaded Date' : lp['uploaded_date']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.uploadedDate}</MRModalLabelSub>
                                    </div>
                                    <div style={{display: "inline-block"}}>
                                    <MRModalLabel>{lp['edited_date'] === undefined ? 'Edited Date' : lp['edited_date']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.editedDate}</MRModalLabelSub>
                                    </div>
                                </div>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel>{lp['description'] === undefined ? 'Description' : lp['description']}</MRModalLabel>
                        {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div>  */}
                        <div dangerouslySetInnerHTML={{__html: dtext}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                        </div>
                    </div>
                    <div style={{position: "relative",padding: "5px 35px", marginTop: 2}}>

                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",paddingLeft: "25px",marginBottom: "25px", marginTop: 10, paddingTop: 5}}>
                            {/* <MRModalLabel style={{marginTop: 10}}>Overall Comments</MRModalLabel> */}
                            <MRModalLabel style={{marginTop: 10}}>{lp['comment_box_title'] === undefined ? 'Comments' : lp['comment_box_title']}</MRModalLabel>
                            {
                                (() => {
                                    let atType = 'at_type' in this.state.task.object_data ? this.state.task.object_data.at_type : 'Document';
                                    if(atType === 'Document'){
                                        return(<div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line", paddingBottom: 10}}>
                                            {this.state.task.object_data.headerinfo.comments}
                                            </div>)
                                    }else{
                                        return(<div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line", paddingBottom: 10}}>
                                            {this.state.task.object_data.qset_answer.overall_comment}
                                            </div>)
                                    }
                                })()
                            }
                        </div>
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                    {
                        (()=> {
                            if('isResend' in this.props && this.props.isResend === true){
                                return(<MRModalSaveCloseBtn onClick={this.resendEmailtoUser}>Resend</MRModalSaveCloseBtn> )
                            }
                        })()
                    }
                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default AMCompleteTaskModal;
