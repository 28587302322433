import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaRegCircle, FaRegDotCircle, FaRegCheckSquare, FaRegSquare} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 200px;
    z-index: 1002;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;

const SectionHeader = styled.div`
    background-color: #44546A;
    color: white;
    padding-top: 5px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    height: 30px;    
    width: calc(100% - 10px);
`

const QuestionLine = styled.div`
    background-color: white;
    color: #44546A;  
    width: calc(100% - 10px);
    border-bottom: 2px solid #E8EAEC;
`

const QuestionNumber = styled.div`
    background-color: white;
    color: #44546A;
    padding-top: 5px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    height: 30px;    
    width: 30px;
    float: left;
`
const QuestionBox = styled.div`
    background-color: white;
    color: #44546A;
    padding-top: 5px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    min-height: 40px;    
    width: calc(100% - 70px);
    float: left;
`

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:20px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 16px 15px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;

const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #F2EBD9;
    font-weight: bold;
    margin-bottom: 25px;
    color: #E39E00;    
`;

const AnswerButton = styled.div`
    width: 70px;
    height: 40px;
    margin: 0px;
    // padding-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    float: left;
    text-align: center;
`


const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;   
    font-family: 'Montserrat', sans-serif;
`;

const ClearFloat = styled.div`
    clear: both;
`

class AMPendingAcknowTaskModal extends React.Component
{
    state = {
        ready: false,
        module_config: null,
        binFiles: [],
        cur_files_json: null,
        qset_cur_files_json: null,
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),
        task: null,
        parent_task_json: null,
        parenttaskdata: null,
        fileObject: {},
        task_id: null,
        is_alert_msg: true,
        comments: "",
        isDesableButton: false,
        isCheckbox: false,
        curr_task_contactid: 0,
        qsetdueDate: new Date(),
        Parent_taskid: 0,
        downloadThroughApiQuestionset: false,
        alert_param: null
    };

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return

        this.setComponentState()
    }

    setComponentState = () => {
        let task_data = Store.getStoreData('cur_pending_task');
        // let parent_task_data = Store.getStoreData('cur_parent_pending_task');
        let parent_task_binFiles = Store.getStoreData('cur_parent_binFiles');
        let module_config = Store.getStoreData('module_config')

        let subtaskdata = task_data.subtask;
        let task_id = subtaskdata.id;
        let parenttaskdata = task_data.parenttask;
        let parent_task_json = JSON.parse(parenttaskdata.task_json);
        let task_json = JSON.parse(subtaskdata.task_json);

        //let binfile_parent_json = JSON.parse(parent_task_data.task_json);

        let cur_files_json = parent_task_binFiles;
        // if('bin_files' in binfile_parent_json.object_data){
        //     cur_files_json = binfile_parent_json.object_data.bin_files;
        // }
        let fileid = {}
        cur_files_json.forEach((item) => {
            fileid[item.uid] = parenttaskdata.id
        });
        console.log("fileid",fileid)

        let duedate = new Date(parenttaskdata.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? '0' + duedate.getDate()+'/' : duedate.getDate()+'/';
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth+'/'+duedate.getFullYear() : '0'+uploadedmonth+'/'+duedate.getFullYear();

        let actiondate = new Date(parent_task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';
        editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear();

        let is_alert_msg = true;
        if(cur_files_json.length === 0){
            is_alert_msg = false;
        }
        if('downloadedFile' in task_json.object_data.headerinfo){
            let objectDate = task_json.object_data.headerinfo.downloadedFile;
            Object.keys(fileid).map((item) => {
                console.log("item",item)
                if(item in objectDate){
                    is_alert_msg = false;
                }else{
                    is_alert_msg = true;
                }
            })
        }
        console.log("parent_task_json",parent_task_json)
        let comments = "";
        if('comments' in task_json.object_data.headerinfo){
            comments = task_json.object_data.headerinfo.comments;
        }
        let qsetbinfilesIDObject = {}
        if(parent_task_json.object_data.at_type === 'QuestionSet') {
            console.log("task_json",task_json.object_data)
            if(!('qset_answer' in task_json.object_data)) {
                is_alert_msg = true
                task_json.object_data.qset_answer = JSON.parse(JSON.stringify(parent_task_json.object_data.qset))
                task_json.object_data.qset_answer.status = 'incomplete'
                for(let sid in task_json.object_data.qset_answer.sections) {
                    task_json.object_data.qset_answer.sections[sid].status = 'not_started'
                    for(let q of task_json.object_data.qset_answer.sections[sid].questions) {
                        //console.log("BinfilesId==>", q.binfiles_id)
                        if('binfiles_id' in q && q.binfiles_id !== 0){
                            qsetbinfilesIDObject[q.binfiles_id] = 1;
                        }
                        q.answer = null
                        q.confirmed = null
                    }
                }
            }
            if(task_json.object_data.qset_answer.status !== 'complete'){
                is_alert_msg = true
                for(let sid in task_json.object_data.qset_answer.sections) {
                    for(let q of task_json.object_data.qset_answer.sections[sid].questions) {
                        if('binfiles_id' in q && q.binfiles_id !== 0){
                            qsetbinfilesIDObject[q.binfiles_id] = 1;
                        }
                    }
                }
            }else {
                is_alert_msg = false
            }

            if('isSaveData' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.isSaveData === true){
                for(let sid in task_json.object_data.qset_answer.sections) {
                    for(let q of task_json.object_data.qset_answer.sections[sid].questions) {
                        if('binfiles_id' in q && q.binfiles_id !== 0){
                            qsetbinfilesIDObject[q.binfiles_id] = 1;
                        }
                    }
                }
            }

            if(!('confirmed' in task_json.object_data.qset_answer)) {
                task_json.object_data.qset_answer.confirmed = true
                if('general' in module_config && module_config.general.configure_attestation_wording_enable === true) {
                    task_json.object_data.qset_answer.confirmed = false
                }
            }
            if(!('flagged' in task_json.object_data.qset_answer)) {
                task_json.object_data.qset_answer.flagged = false
            }
        }

        console.log("task_json===>",task_json)
        this.setState({cur_files_json: cur_files_json,
            documentVersion: task_json.object_data.headerinfo.document_version,
            module_config: module_config,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,
            description: task_json.object_data.headerinfo.description,
            task: task_json,
            parent_task_json: parent_task_json,
            parenttaskdata: parenttaskdata,
            task_id: task_id,
            is_alert_msg: is_alert_msg,
            comments: comments,
            curr_task_contactid: subtaskdata.contact_id,
            qsetdueDate: subtaskdata.due_date,
            Parent_taskid:parenttaskdata.id
            })

            console.log("qsetbinfilesIDObject==>", qsetbinfilesIDObject)

        if('qsetbinfilesIds' in parent_task_json.object_data){
            let qsetbinfilesIds = parent_task_json.object_data.qsetbinfilesIds;
            this.setState({qset_cur_files_json: qsetbinfilesIds, downloadThroughApiQuestionset: true, ready:true})
        }else if(Object.keys(qsetbinfilesIDObject).length !== 0){
            let postData = {command: "fetch_multipleQsetbinfile", bin_fileids: qsetbinfilesIDObject};
            console.log("postData",postData)
            let api = new APICall();
            api.command(postData, this.processfetchAttachmentQuestionsets);
        }else{
            this.setState({ready:true})
        }
    }

    processfetchAttachmentQuestionsets = (result) => {
        console.log("result==>", result)
        this.setState({qset_cur_files_json: result.result,ready:true})
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }


    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_ATTEST_URL;
              }else{
                // window.location = '/';
                this.props.closeTaskModal();
              }
        } else {
            this.props.closeModal();
        }
    }
    cencleDesable = () => {
        this.setState({isDesableButton: false})
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    updateDownloadValue = (uid) => {
        console.log("uid",uid)
        if(this.state.is_alert_msg){
            this.state.fileObject[uid] = this.state.parenttaskdata.id
            let task_json = this.state.task;
            task_json['object_data'].headerinfo.downloadedFile = this.state.fileObject;
            let postData = {command: "update_download_file_task", task_json: task_json, task_id: this.state.task_id };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.afterDownloadCompleted(this.state.fileObject));
        }
    }
    afterDownloadCompleted = (fileobject) => (result) => {
        console.log("Completed:", result);
        let fileid = {}
        this.state.cur_files_json.forEach((item) => {
            fileid[item.uid] = this.state.parenttaskdata.id
        });
        let is_alert_msg = true;
        console.log("fileid",fileid,"fileobject",fileobject)
        Object.keys(fileid).map((item) => {
            //console.log("item",item)
            if(item in fileobject){
                is_alert_msg = false;
            }else{
                is_alert_msg = true;
            }
        })
        this.setState({is_alert_msg: is_alert_msg})
    }
    completeTask = (event) => {
        event.preventDefault();
        let lp = Store.getStoreData('language_pack')
        let moduleconfig = Store.getStoreData('module_config');
        let parent_task_json = this.state.parent_task_json;

        let d_date = new Date(this.state.qsetdueDate);
        let dueQuest_date = moment.unix(Math.floor(d_date / 1000)).format('YYYY/MM/DD')

        if(parent_task_json.object_data.at_type === 'QuestionSet') {
            let task_json = this.state.task;
            let isFlagged = false;
            if('flagged' in task_json.object_data.qset_answer){
                isFlagged = task_json.object_data.qset_answer.flagged;
            }
            task_json['object_data'].headerinfo.isSaveData = false;
            task_json['task_data'].last_action = 'COMP_ATTESTATION_SHARE_COMPLETED';
            task_json['task_data'].cur_lane = 'COMP_ATTESTATION_SHARE_COMPLETED';
            task_json['task_data'].actiondate = Date.now();
            task_json['task_data'].due_date = dueQuest_date;
            task_json['action_data'] = {action: "COMP_ATTESTATION_SHARE_COMPLETED", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_ATTESTATION_SHARE_COMPLETED",comments: this.state.comments, assigned_to: 0 };
            let postData = {command: "update_share_completed_task", task_json: task_json, task_id: this.state.task_id, isfalgged: isFlagged};
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.afterCompleted);
            return
        }
        if('general' in moduleconfig && moduleconfig.general.configure_attestation_wording_enable === true){
            if(this.state.isCheckbox){
                let task_json = this.state.task;
                //console.log(task_json);
                task_json['task_data'].last_action = 'COMP_ATTESTATION_SHARE_COMPLETED';
                task_json['task_data'].cur_lane = 'COMP_ATTESTATION_SHARE_COMPLETED';
                task_json['task_data'].actiondate = Date.now();
                task_json['task_data'].due_date = dueQuest_date;
                task_json['object_data'].headerinfo.comments = this.state.comments;
                task_json['action_data'] = {action: "COMP_ATTESTATION_SHARE_COMPLETED", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_ATTESTATION_SHARE_COMPLETED",comments: this.state.comments, assigned_to: 0 };
                let postData = {command: "update_share_completed_task", task_json: task_json, task_id: this.state.task_id };
                console.log(postData);
                const api = new APICall();
                api.command(postData, this.afterCompleted);
            }else{
                let msg = lp['confirm_before_submitting '] === undefined ? 'Please tick the box to confirm before submitting the attestation.' : lp['confirm_before_submitting ']
                //alert(msg)
                let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: msg, ok_text: 'Ok', confirm: false,
                            alertHandler: this.checkalertparam, stack: {}}
                this.setState({alert_param: alert_param})
                return;
            }
        }else{
            let task_json = this.state.task;
            //console.log(task_json);
            task_json['task_data'].last_action = 'COMP_ATTESTATION_SHARE_COMPLETED';
            task_json['task_data'].cur_lane = 'COMP_ATTESTATION_SHARE_COMPLETED';
            task_json['task_data'].actiondate = Date.now();
            task_json['task_data'].due_date = dueQuest_date;
            task_json['object_data'].headerinfo.comments = this.state.comments;
            task_json['action_data'] = {action: "COMP_ATTESTATION_SHARE_COMPLETED", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_ATTESTATION_SHARE_COMPLETED",comments: this.state.comments, assigned_to: 0 };
            let postData = {command: "update_share_completed_task", task_json: task_json, task_id: this.state.task_id };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.afterCompleted);
        }
    }
    checkalertparam = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }
    checkalertparamSave = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload(true);
    }
    afterCompleted = (result) => {
        console.log("Completed:", result);
        let lp = Store.getStoreData('language_pack')
        let msg = lp['attestation_completed_successfully'] === undefined ? 'Attestation completed successfully.' : lp['attestation_completed_successfully']
        //alert(msg);
        console.log("success message", msg);
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: msg, ok_text: 'Ok', confirm: false,
                          alertHandler: this.customAlertAfterComplete, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})

        /*//window.location.reload(true);
        let parent_task_json = this.state.parent_task_json;
        //let contactid = this.state.curr_task_contactid;
        let contactid = Store.getStoreData('contact_id');
        parent_task_json['object_data'].headerinfo.shareUserList[contactid.toString()].status = "complete";
        let postData = {command: "update_download_file_task", task_json: parent_task_json, task_id: this.state.parenttaskdata.id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterUpdateParentTaskCompleted);*/
    }
    customAlertAfterComplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null});
        let parent_task_json = this.state.parent_task_json;
        //let contactid = this.state.curr_task_contactid;
        let contactid = Store.getStoreData('contact_id');
        parent_task_json['object_data'].headerinfo.shareUserList[contactid.toString()].status = "complete";
        let postData = {command: "update_download_file_task", task_json: parent_task_json, task_id: this.state.parenttaskdata.id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterUpdateParentTaskCompleted);
    }

    afterUpdateParentTaskCompleted = (result) => {
        // console.log("Completed:", result);
        //alert('Task completed successfully.');
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_ATTEST_URL;
              }else{
                window.location = '/';
              }
        } else {
            window.location.reload(true);
        }
    }

    saveandCloseTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        task_json['object_data'].headerinfo.comments = this.state.comments;
        task_json['object_data'].headerinfo.isSaveData = true;
        let postData = {command: "update_download_file_task", task_json: task_json, task_id: this.state.task_id };
        console.log('saveandCloseTask postData', postData);
        const api = new APICall();
        api.command(postData, this.aftersaveandclosetask);
    }

    aftersaveandclosetask = (result) => {
        // console.log("Completed:", result);
        let lp = Store.getStoreData('language_pack')
        let msg = lp['acknowledgement_saved_successfully'] === undefined ? 'Acknowledgement saved successfully.' : lp['acknowledgement_saved_successfully']
        if(this.state.parent_task_json.object_data.at_type === 'QuestionSet'){
            let notAnswerQuestion = {}
            let q_no = 0
            Object.keys(this.state.task.object_data.qset_answer.sections).map((id, i) => {
                let sec = this.state.task.object_data.qset_answer.sections[id]
                sec.questions.map((q, j) => {
                    if(q.is_active === 1) {
                        q_no++
                        if(q.answer === null){
                            notAnswerQuestion[q_no] = 1
                        }
                    }
                })
            })
            //console.log("notAnswerQuestion===>", notAnswerQuestion)
            if(Object.keys(notAnswerQuestion).length !== 0){
                let comm = '';
                let i = 0
                for(let k in notAnswerQuestion){
                    comm += k.toString()
                    if(Object.keys(notAnswerQuestion).length)
                    if(i < Object.keys(notAnswerQuestion).length - 1) {
                        comm += ', '
                    }
                    i++
                }
                msg = 'Your attestation responses have been saved, please complete questions '+ comm +' to complete and submit your acknowledgment'
            }else{
                msg = lp['save_close_answering_question_set'] === undefined ? 'Your attestation responses have been saved, please review and submit your acknowledgment' : lp['save_close_answering_question_set']
            }
        }

        //alert(msg)
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: msg, ok_text: 'Ok', confirm: false,
                          alertHandler: this.checkalertparamSave, stack: {}}
        console.log("alertPaRAM", alert_param);
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload(true);
    }

    acknowledgedTask = (event) => {
        event.preventDefault();
        let lp = Store.getStoreData('language_pack')
        if(this.state.is_alert_msg){
            let msg = lp['please_download_attachment'] === undefined ? 'Please download the attachment(s) before completing the task.' : lp['please_download_attachment']
            //alert(msg)
            let alert_param = {title: 'Warning', message: msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkalertparam, stack: {}}
            this.setState({alert_param: alert_param})
        }
        else{
            console.log("Sourav")
            this.setState({isDesableButton: true})
        }
    }
    handlenaSectionIncluded = (ischeck) => (event) => {
        this.setState({isCheckbox: ischeck})
    }

    handlenaQSetSectionIncluded = (checked) => (event) => {
        let task = this.state.task
        task.object_data.qset_answer.confirmed = checked
        this.setState({task})
    }

    recordCompletionStatus = (qset) => {
        let qset_status = 'complete'
        let module_config = Store.getStoreData('module_config')
        for(let id in qset.sections) {
            let sec = qset.sections[id]
            let sec_status = 'complete'
            for(let q of sec.questions) {
                let q_status = 'complete'
                if(q.is_active === 1) {
                    if(q.answer === null) {q_status = 'not_started'; sec_status = 'incomplete'; qset_status = 'incomplete'}
                    else {
                        if(q.answer !== 'Yes' && (!('comment' in q) || q.comment === '' || q.comment === null)) {q_status = 'not_commented'; sec_status = 'incomplete';  qset_status = 'incomplete'}
                        if(q.answer === 'Yes' && q.attestation_required === 1 && q.confirmed !== 1) {q_status = 'not_attested'; sec_status = 'incomplete';  qset_status = 'incomplete'}
                        if(q.answer !== 'Yes' && q.answer !== null) {qset.flagged = true;}
                    }
                    q.status = q_status
                }
            }
            sec.status = sec_status
        }
        qset.status = qset_status
        if(qset_status === 'complete' && !('overall_comment' in qset)) {
            qset.overall_comment = ''
        }
    }

    answerClick = (value, section_id, q_index) => {
        let task = this.state.task
        task.object_data.qset_answer.sections[section_id].questions[q_index].answer = value
        if(value !== 'Yes' && !('comment' in task.object_data.qset_answer.sections[section_id].questions[q_index])){
            task.object_data.qset_answer.sections[section_id].questions[q_index].comment = ''
        }
        this.recordCompletionStatus(task.object_data.qset_answer)
        this.setState({task})
    }

    commentChange = (section_id, q_index, event) => {
        console.log('event, section_id, q_index', event, section_id, q_index)
        let task = this.state.task
        task.object_data.qset_answer.sections[section_id].questions[q_index].comment = event.target.value
        this.recordCompletionStatus(task.object_data.qset_answer)
        this.setState({task})
    }

    overallCommentChange = (event) => {
        let task = this.state.task
        task.object_data.qset_answer.overall_comment = event.target.value
        this.setState({task})
    }

    clickConfirm = (section_id, q_index) => {
        let task = this.state.task
        task.object_data.qset_answer.sections[section_id].questions[q_index].confirmed = (task.object_data.qset_answer.sections[section_id].questions[q_index].confirmed + 1) % 2
        this.recordCompletionStatus(task.object_data.qset_answer)
        this.setState({task})
    }

    render()
    {
        let lp = Store.getStoreData('language_pack')
        let moduleconfig = Store.getStoreData('module_config');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let task_data = Store.getStoreData('cur_pending_task');
        let subtaskdata = task_data.subtask;
        let task_json = JSON.parse(subtaskdata.task_json);
        //console.log('this.state', this.state)
        if(this.state.parent_task_json.object_data.at_type === 'QuestionSet'){
            console.log('this.state', this.state.task)

            let contacts = Store.getStoreData('contacts')
            let assigned_by = contacts[this.state.parent_task_json.task_data.contact_id]
            let assigned_to = contacts[subtaskdata.cur_assigned_to]
            let assigned_date = moment.unix(Math.floor(this.state.task.task_data.actiondate / 1000)).format('DD/MM/YYYY')
            //let d_date = new Date(this.state.task.task_data.due_date);
            let d_date = new Date(this.state.qsetdueDate);
            let dueQuest_date = moment.unix(Math.floor(d_date / 1000)).format('DD/MM/YYYY')
            let default_confirmation_text = this.state.module_config.general.configure_attestation_wording_details.confirmation_text
            let is_incomplete = this.state.task.object_data.qset_answer.status === 'complete' ? false : true
            console.log("is_incomplete===>", is_incomplete)
            let q_no = 0
            // console.log('contacts[this.state.parent_task_json.task_data.contact_id]', )
            return (
            <div>
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{lp['review_attestation_screen_title'] === undefined ? 'Review Attestation' : lp['review_attestation_screen_title']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{lp['review_attestation_screen_prompt'] === undefined ? 'View the details of this attestation and complete any required actions' : lp['review_attestation_screen_prompt']}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                            <div style={{display: "inline-block",float: "left", width: '25%'}}>
                                <MRModalLabel>{lp['assigned_to_field_title'] === undefined ? 'Assigned To' : lp['assigned_to_field_title']}</MRModalLabel>
                                <MRModalLabelSub style={{fontWeight: "600"}}>{assigned_to}</MRModalLabelSub>
                            </div>
                            <div style={{display: "inline-block",float: "left", width: '25%'}}>
                                <MRModalLabel>{lp['assigned_by_field_title'] === undefined ? 'Assigned By' : lp['assigned_by_field_title']}</MRModalLabel>
                                <MRModalLabelSub style={{fontWeight: "600"}}>{assigned_by}</MRModalLabelSub>
                            </div>
                            <div style={{display: "inline-block",float: "left", width: '25%'}}>
                                <MRModalLabel>{lp['assigned_date_field_title'] === undefined ? 'Assigned Date' : lp['assigned_date_field_title']}</MRModalLabel>
                                <MRModalLabelSub style={{fontWeight: "600"}}>{assigned_date}</MRModalLabelSub>
                            </div>
                            <div style={{display: "inline-block",float: "left", width: '25%'}}>
                                <MRModalLabel>{lp['due_date_field_title'] === undefined ? 'Due Date' : lp['due_date_field_title']}</MRModalLabel>
                                <MRModalLabelSub style={{fontWeight: "600"}}>{dueQuest_date}</MRModalLabelSub>
                            </div>
                            <div style={{clear: "both"}}></div>
                            {

                                Object.keys(this.state.task.object_data.qset_answer.sections).map((id, i) => {
                                    let sec = this.state.task.object_data.qset_answer.sections[id]
                                    console.log("section===>", sec)
                                    let isShowSecName = false
                                    sec.questions.map((q) => {
                                        if(q.is_active === 1) {
                                            isShowSecName = true
                                        }
                                    })
                                    return (<div key={i}>
                                            {
                                                (() => {
                                                    if(isShowSecName){
                                                        return<SectionHeader>{sec.name}</SectionHeader>
                                                    }
                                                })()
                                            }
                                            {
                                                sec.questions.map((q, j) => {
                                                    console.log('sec, q, i, j', sec, q, i, j)
                                                    if(q.is_active === 1) {
                                                        q_no++
                                                        let qtext = q.text;
                                                        let isdesign = false
                                                        if(Utils.isBase64(qtext)){
                                                            qtext = window.decodeURIComponent(atob(qtext))
                                                            isdesign = true;
                                                        }
                                                        return (<QuestionLine key={1000*(i+1)+j}>
                                                            {
                                                                // (() => {
                                                                    // if(isdesign){
                                                                        // return(<QuestionNumber style={{paddingTop: "21px"}}>Q{q_no}</QuestionNumber>)
                                                                    // }else{
                                                                        // return(<QuestionNumber style={{paddingTop: "21px"}}>Q{q_no}</QuestionNumber>)
                                                                    // }
                                                                // })()
                                                            }
                                                            <QuestionNumber style={{paddingTop: "21px", width: "auto"}}>Q{q_no}</QuestionNumber>
                                                            <QuestionBox style={{paddingTop: "21px"}}>
                                                                {q.title}
                                                            </QuestionBox>
                                                            <ClearFloat />
                                                            <div dangerouslySetInnerHTML={{__html: qtext}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif",fontSize: "16px", padding: "5px", marginLeft: "5px"}}></div>

                                                            {
                                                                (() => {
                                                                    if('binfiles_id' in q && q.binfiles_id !== 0 && q.binfiles_id in this.state.qset_cur_files_json){
                                                                        return(
                                                                            <ModalNameInputDiv style={{marginLeft: "12px"}}>
                                                                                <Dropzone initFiles={this.state.qset_cur_files_json[q.binfiles_id]} addnew={false} downloadThroughApiQuestionset={this.state.downloadThroughApiQuestionset} qsetbinfiles_id={q.binfiles_id}/>
                                                                            </ModalNameInputDiv>
                                                                        )
                                                                    }
                                                                })()
                                                            }

                                                            <QuestionBox style={{paddingTop: "10px", paddingLeft: "5px",marginLeft: "5px"}}>{lp['response_header'] === undefined ? 'Response' : lp['response_header']}:</QuestionBox>
                                                            <AnswerButton>
                                                                {
                                                                    (() => {
                                                                        if(q.answer === 'Yes') {
                                                                            return <FaRegDotCircle />
                                                                        } else {
                                                                            return <FaRegCircle onClick={() => this.answerClick('Yes', id, j)} />
                                                                        }
                                                                    })()
                                                                }
                                                                &nbsp;&nbsp;{lp['yes_answer_option'] === undefined ? 'Yes' : lp['yes_answer_option']}
                                                            </AnswerButton>
                                                            <AnswerButton>

                                                                {
                                                                    (() => {
                                                                        if(q.answer === 'No') {
                                                                            return <FaRegDotCircle />
                                                                        } else {
                                                                            return <FaRegCircle onClick={() => this.answerClick('No', id, j)} />
                                                                        }
                                                                    })()
                                                                }
                                                                &nbsp;&nbsp;{lp['no_answer_option'] === undefined ? 'No' : lp['no_answer_option']}
                                                            </AnswerButton>
                                                            <AnswerButton>

                                                                {
                                                                    (() => {
                                                                        if(q.answer === 'N/A') {
                                                                            return <FaRegDotCircle />
                                                                        } else {
                                                                            return <FaRegCircle onClick={() => this.answerClick('N/A', id, j)} />
                                                                        }
                                                                    })()
                                                                }
                                                                &nbsp;&nbsp;{lp['n/a_answer_option'] === undefined ? 'N/A' : lp['n/a_answer_option']}
                                                            </AnswerButton>
                                                            <ClearFloat />
                                                            {
                                                                (() => {
                                                                    if(q.answer !== 'Yes' && q.answer !== null) return (<div>
                                                                        <MRModalLabel>{lp['comments_title'] === undefined ? 'Comment' : lp['comments_title']}</MRModalLabel>
                                                                        <MRModalTextarea placeholder={lp['comments_prompt_text'] === undefined ? 'Comments are mandatory' : lp['comments_prompt_text']} value={q.comment} onChange={(e) => this.commentChange(id, j, e)} />
                                                                    </div>)
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                    if(q.attestation_required === 1) {
                                                                        if(q.show_default === 0) {
                                                                            if(q.answer === 'No' || q.answer === 'N/A'){
                                                                                return(<div style={{marginBottom: "8px",width: "100%", backgroundColor: "#C6C6C6"}}>
                                                                                            {
                                                                                                (() => {
                                                                                                    if(q.attestation_text !== '') return (<QuestionBox style={{width: '98%', backgroundColor: "#C6C6C6"}}>
                                                                                                                                            {q.attestation_text}
                                                                                                                                        </QuestionBox>)
                                                                                                })()
                                                                                            }
                                                                                            <QuestionNumber style={{backgroundColor: "#C6C6C6"}}>
                                                                                            {
                                                                                                (() => {
                                                                                                    if(q.confirmed === 1) return <FaRegCheckSquare />
                                                                                                    else return <FaRegSquare />
                                                                                                })()
                                                                                            }
                                                                                            </QuestionNumber>
                                                                                            <QuestionBox style={{backgroundColor: "#C6C6C6"}}>
                                                                                                {q.confirmation_text === '' ? default_confirmation_text : q.confirmation_text}
                                                                                            </QuestionBox>
                                                                                            <ClearFloat />
                                                                                        </div>
                                                                                    )
                                                                            }else{
                                                                                return(<div style={{marginBottom: "8px"}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(q.attestation_text !== '') return (<QuestionBox style={{width: '99%'}}>
                                                                                                                                        {q.attestation_text}
                                                                                                                                    </QuestionBox>)
                                                                                            })()
                                                                                        }
                                                                                        <QuestionNumber>
                                                                                        {
                                                                                            (() => {
                                                                                                if(q.confirmed === 1) return <FaRegCheckSquare style={{cursor: 'pointer'}} onClick={() => this.clickConfirm(id, j)} />
                                                                                                else return <FaRegSquare style={{cursor: 'pointer'}} onClick={() => this.clickConfirm(id, j)} />
                                                                                            })()
                                                                                        }
                                                                                        </QuestionNumber>
                                                                                        <QuestionBox>
                                                                                            {q.confirmation_text === '' ? default_confirmation_text : q.confirmation_text}
                                                                                        </QuestionBox>
                                                                                        <ClearFloat />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        } else {
                                                                            return (<div style={{marginBottom: "8px"}}>
                                                                                            <QuestionNumber>
                                                                                            {
                                                                                                (() => {
                                                                                                    if(q.confirmed === 1) return <FaRegCheckSquare style={{cursor: 'pointer'}} onClick={() => this.clickConfirm(id, j)} />
                                                                                                    else return <FaRegSquare style={{cursor: 'pointer'}} onClick={() => this.clickConfirm(id, j)} />
                                                                                                })()
                                                                                            }
                                                                                            </QuestionNumber>
                                                                                            <QuestionBox>
                                                                                                {default_confirmation_text}
                                                                                            </QuestionBox>
                                                                                            <ClearFloat />
                                                                                        </div>)
                                                                        }
                                                                    }
                                                                })()
                                                            }
                                                        </QuestionLine>)
                                                        }
                                                })
                                            }
                                        </div>)
                                })
                            }
                        </div>
                        {
                            (() => {
                                if(!is_incomplete) return (<div>
                                    {/* <MRModalLabel>Overall Comment</MRModalLabel> */}
                                    <MRModalLabel>{lp['comment_box_title'] === undefined ? 'Comments' : lp['comment_box_title']}</MRModalLabel>
                                    <MRModalTextarea style={{height: 80, marginBottom: "20px"}}
                                        value={this.state.task.object_data.qset_answer.overall_comment}
                                        //placeholder='Overall comments is mandatory...'
                                        placeholder={lp['comment_box_prompt'] === undefined ? 'Comments' : lp['comment_box_prompt']}
                                        onChange={this.overallCommentChange} />
                                </div>)
                            })()
                        }
                        <MRModalBodyInner >
                        {
                            (() => {
                                if(is_incomplete) {
                                    return <div>{lp['complete_questions_reminder_message'] === undefined ? 'You have not answered all attestation questions. You must first answer all questions before you can acknowledge this attestation.' : lp['complete_questions_reminder_message']}</div>
                                } else if(!('overall_comment' in this.state.task.object_data.qset_answer) || this.state.task.object_data.qset_answer.overall_comment === ''){
                                    return <div>{lp['complete_comments_reminder_message'] === undefined ? 'You are almost there. Please detail any required comments in the Comments field and you will be ready to acknowledge and complete this attestation' : lp['complete_comments_reminder_message']}</div>
                                } else {
                                    return <div>{lp['ready_to_submit_message'] === undefined ? 'You are now ready to submit your acknowledgement.' : lp['ready_to_submit_message']}</div>
                                }
                            })()
                        }
                        </MRModalBodyInner>
                        {
                            (() => {
                                if(!is_incomplete) return (<div>
                                    {
                                        (() => {
                                            if('is_configure_attestation_wording' in task_json.object_data && task_json.object_data.is_configure_attestation_wording){
                                                return(
                                                    <div>
                                                        <div style={{display: "inline-block"}}>
                                                        <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",fontWeight: "100"}}>{task_json.object_data.additionalText}</MRModalSubHeaderText>
                                                        </div>
                                                        <div>
                                                            {
                                                                (() => {
                                                                    if(this.state.task.object_data.qset_answer.confirmed){
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaQSetSectionIncluded(false)}><IoIosCheckboxOutline /></div>)
                                                                    }else{
                                                                        return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaQSetSectionIncluded(true)}><IoIosSquareOutline /></div>)
                                                                    }
                                                                })()
                                                            }
                                                        <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents"}}>{task_json.object_data.confirmationText}</MRModalSubHeaderText>
                                                        </div>
                                                    </div>
                                                )
                                            }else{
                                                if('general' in moduleconfig && moduleconfig.general.configure_attestation_wording_enable === true){
                                                    return(
                                                        <div>
                                                            <div style={{display: "inline-block"}}>
                                                            <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",fontWeight: "100"}}>{moduleconfig.general.configure_attestation_wording_details.additional_text}</MRModalSubHeaderText>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (() => {
                                                                        if(this.state.task.object_data.qset_answer.confirmed){
                                                                            return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaQSetSectionIncluded(false)}><IoIosCheckboxOutline /></div>)
                                                                        }else{
                                                                            return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaQSetSectionIncluded(true)}><IoIosSquareOutline /></div>)
                                                                        }
                                                                    })()
                                                                }
                                                            <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents"}}>{moduleconfig.general.configure_attestation_wording_details.confirmation_text}</MRModalSubHeaderText>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        })()
                                    }
                                </div>)
                            })()
                        }
                    </div>
                </MRModalBody>
                <MRModalFooter style={{paddingTop: 20, paddingBottom: 8, paddingRight: 15, height: 60, width: 'calc(100% - 20px)'}}>
                {
                    (() => {
                        //if(is_incomplete || !('overall_comment' in this.state.task.object_data.qset_answer) || this.state.task.object_data.qset_answer.overall_comment === '' || !this.state.task.object_data.qset_answer.confirmed)
                        if(is_incomplete || !this.state.task.object_data.qset_answer.confirmed)
                            return <MRModalSaveCloseBtn onClick={this.saveandCloseTask}>{lp['save_close'] === undefined ? 'Save and Close' : lp['save_close']}</MRModalSaveCloseBtn>
                        else return (<div>
                            <MRModalSaveCloseBtn style={{width: 250}} onClick={this.completeTask}>{lp['acknowledge_and_submit_button'] === undefined ? 'Acknowledge and Submit' : lp['acknowledge_and_submit_button']}</MRModalSaveCloseBtn>
                            <MRModalSaveCloseBtn onClick={this.saveandCloseTask}>{lp['save_close'] === undefined ? 'Save and Close' : lp['save_close']}</MRModalSaveCloseBtn>
                        </div>)
                    })()
                }

                    <MRModalCancelBtn onClick={this.closeView}>{lp['cancel_button'] === undefined ? 'Cancel' : lp['cancel_button']}</MRModalCancelBtn>
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
            )
        }
        let dtext = this.state.description;
        if(Utils.isBase64(dtext)){
            dtext = window.decodeURIComponent(atob(dtext))
        }
        return (
            <div>
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{lp['review_attestation'] === undefined ? 'Review Attestation' : lp['review_attestation']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{lp['view_the_details_of_this_attestation'] === undefined ? 'View the details of this attestation and complete any required actions' : lp['view_the_details_of_this_attestation']}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <MRModalBodyInner >
                        {
                            (() => {
                                if(this.state.is_alert_msg) {
                                    return <div>{lp['you_have_not_yet_downloaded'] === undefined ? 'You have not yet downloaded the current document. You must download and review to Submit.' : lp['you_have_not_yet_downloaded']}</div>
                                } else {
                                    return <div>{lp['ready_to_submit_your_acknowledgement'] === undefined ? 'You are now ready to submit your acknowledgement.' : lp['ready_to_submit_your_acknowledgement']}</div>
                                }
                            })()
                        }

                        </MRModalBodyInner>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                                {
                                    (() => {
                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                            return(<div>
                                                    <MRModalLabel>{lp['document_information'] === undefined ? 'Document Information' : lp['document_information']}</MRModalLabel>
                                                    <ModalNameInputDiv>
                                                        <Dropzone onFilesAdded={this.filesLoaded} saveDownloadData={true} updateDownloadData={this.updateDownloadValue} initFiles={this.state.cur_files_json} addnew={false} downloadThroughApi={true} ParentTaskid={this.state.Parent_taskid}/>
                                                    </ModalNameInputDiv>
                                                    <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>
                                            </div>)
                                        }
                                    })()
                                }
                                <div style={{display: "inline-block",float: "left"}}>
                                    <MRModalLabel>{lp['document_version'] === undefined ? 'Document Version' : lp['document_version']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontWeight: "600"}}>{this.state.documentVersion}</MRModalLabelSub>
                                </div>
                                <div style={{display: "inline-block",float: "right"}}>
                                    <div style={{display: "inline-block",marginRight: "80px"}}>
                                    <MRModalLabel>{lp['uploaded_date'] === undefined ? 'Uploaded Date' : lp['uploaded_date']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.uploadedDate}</MRModalLabelSub>
                                    </div>
                                    <div style={{display: "inline-block"}}>
                                    <MRModalLabel>{lp['edited_date'] === undefined ? 'Edited Date' : lp['edited_date']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.editedDate}</MRModalLabelSub>
                                    </div>
                                </div>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel>{lp['description'] === undefined ? 'Description' : lp['description']}</MRModalLabel>
                        {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div> */}
                        <div dangerouslySetInnerHTML={{__html: dtext}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                        </div>
                        <MRModalLabel>{lp['comments'] === undefined ? 'Comments' : lp['comments']}</MRModalLabel>
                        <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                    </div>
                </MRModalBody>
                <MRModalFooter>

                    {
                        (() => {
                            if(this.state.isDesableButton){
                                return(
                                    <div style={{position: "relative",padding: "30px"}}>
                                        <MRModalSaveCloseBtn style={{backgroundColor: "#D8D8D8",border: "1px solid #D8D8D8",color: "#919191"}}>{lp['review_attestation_screen_acknowledge'] === undefined ? 'Acknowledge' : lp['review_attestation_screen_acknowledge']}</MRModalSaveCloseBtn>
                                        <MRModalSaveCloseBtn style={{backgroundColor: "#D8D8D8",border: "1px solid #D8D8D8",color: "#919191"}}>{lp['save_close'] === undefined ? 'Save and Close' : lp['save_close']}</MRModalSaveCloseBtn>
                                        <MRModalCancelBtn style={{backgroundColor: "#D8D8D8",border: "1px solid #D8D8D8",color: "#919191"}}>{lp['cancel'] === undefined ? 'Cancel' : lp['cancel']}</MRModalCancelBtn>
                                    </div>
                                )
                            }else{
                                return(
                                    <div style={{position: "relative",padding: "30px",marginBottom: "50px"}}>
                                        <MRModalSaveCloseBtn onClick={this.acknowledgedTask}>{lp['review_attestation_screen_acknowledge'] === undefined ? 'Acknowledge' : lp['review_attestation_screen_acknowledge']}</MRModalSaveCloseBtn>
                                        <MRModalSaveCloseBtn onClick={this.saveandCloseTask}>{lp['save_close'] === undefined ? 'Save and Close' : lp['save_close']}</MRModalSaveCloseBtn>
                                        <MRModalCancelBtn onClick={this.closeView}>{lp['cancel'] === undefined ? 'Cancel' : lp['cancel']}</MRModalCancelBtn>
                                    </div>
                                )
                            }
                        })()
                    }

                    <div style={{clear: "both"}}></div>
                    {
                        (() => {
                            if(this.state.isDesableButton){
                                return(
                                    <div>
                                        {
                                            (() => {
                                                if('is_configure_attestation_wording' in task_json.object_data && task_json.object_data.is_configure_attestation_wording){
                                                    return(
                                                        <div>
                                                            <div style={{display: "inline-block"}}>
                                                            <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",fontWeight: "100"}}>{task_json.object_data.additionalText}</MRModalSubHeaderText>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (() => {
                                                                        if(this.state.isCheckbox){
                                                                            return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaSectionIncluded(false)}><IoIosCheckboxOutline /></div>)
                                                                        }else{
                                                                            return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaSectionIncluded(true)}><IoIosSquareOutline /></div>)
                                                                        }
                                                                    })()
                                                                }
                                                            <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents"}}>{task_json.object_data.confirmationText}</MRModalSubHeaderText>
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    if('general' in moduleconfig && moduleconfig.general.configure_attestation_wording_enable === true){
                                                        return(
                                                            <div>
                                                                <div style={{display: "inline-block"}}>
                                                                <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",fontWeight: "100"}}>{moduleconfig.general.configure_attestation_wording_details.additional_text}</MRModalSubHeaderText>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        (() => {
                                                                            if(this.state.isCheckbox){
                                                                                return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaSectionIncluded(false)}><IoIosCheckboxOutline /></div>)
                                                                            }else{
                                                                                return(<div style={{cursor: "pointer", fontSize:"23px",marginLeft: "35px", marginBottom: "20px", float: "left", marginRight: "25px"}} onClick={this.handlenaSectionIncluded(true)}><IoIosSquareOutline /></div>)
                                                                            }
                                                                        })()
                                                                    }
                                                                <MRModalSubHeaderText style={{marginTop: "20px",paddingRight: "30px",display: "contents"}}>{moduleconfig.general.configure_attestation_wording_details.confirmation_text}</MRModalSubHeaderText>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })()
                                        }
                                        <div style={{position: "relative",paddingRight: "30px",marginBottom: "65px",paddingBottom: "4px",paddingTop: "42px"}}>
                                            <MRModalSaveCloseBtn onClick={this.completeTask}>{lp['submit'] === undefined ? 'Submit' : lp['submit']}</MRModalSaveCloseBtn>
                                            <MRModalCancelBtn onClick={this.cencleDesable}>Cancel</MRModalCancelBtn>
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                )
                            }
                        })()
                    }

                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default AMPendingAcknowTaskModal;
