import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaAngleDown,FaRegCheckSquare,FaRegSquare,FaAngleUp } from 'react-icons/fa';
import CslTypeAhead from './CslTypeAhead';
import { Icon } from 'react-icons-kit';
import {androidRemove} from 'react-icons-kit/ionicons/androidRemove';
import CslDatepicker from './CslDatepicker';
import moment from 'moment';
import Store from './../../Common/Store';


const SelectedRadio = styled.div`
    background-color: #04ADA8;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;
const RadioBlock = styled.div`
    margin-top: 10px;
    padding: 2%;
`;

const CSLDateCover = styled.div`
    margin-top: 1%;
    margin-bottom: 2%;
    width: 50%;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    font-weight: 200;
    `;

const BlankRAdiv = styled.div`
    font-size: 11px;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 10px;
    font-style: italic;
`;

const TabItemContainer = styled.div`
    padding: 2% 2%;
    box-sizing: border-box;
    display: flex;
`;

const DropDownContainer = styled.div`
    padding: 2% 2%;
    border-bottom: 1px solid #bfbbbb ;
    
`;
const SMRTDes = styled.div`
    padding: 2% 2%;
`;



const TabItemText = styled.div`
    float: left;
    font-weight: 600;
    color: #213E61;
    flex: 1;
    min-width: 0; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ModalBodySecondaryHeader = styled.div`
    display: block;
    color: #213E61;
    font-weight: bold;
    padding: 2%;
    margin-top: 2%;
    flex: 1;
    
`;

const TabItemExpander = styled.div`
    float: right;
    cursor: pointer;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: 60%;
    position: fixed;
    z-index: 1001;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 410px;
    right: 75px;
    top: 350px;
    z-index: 30000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid #9c9696;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
	color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    height: 473px;
    overflow-y: scroll;
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 98%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin:10px;

`;
const MRDes = styled.div`
    display: block;
    padding: 2%;
    font-weight: 100;
    font-style: italic;
    color: grey;
    flex:1;

`;
const CheckBoxContainer = styled.div`
    fontSize: 12px;
    display: block;
    marginLeft: 2%;
    marginTop: 2%;
    margin-bottom: 2%;
    font-weight: 200;
    width: 100%;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding-bottom: 14px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
	color: #000000;
    font-weight: 200;
    margin-top: 20px;
    margin-left: 2%;
`;

const CSLSpacer = styled.div`
    clear: both;
    min-height: 5px;
`
const CSLHorzSpacer = styled.div`
    float: left;
    width: 15px;
`
const FieldLabel = styled.div`
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding-bottom: 15px;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;

const Section45 = styled.div`
	float: left;
	width: 45%;
	box-sizing: border-box;
`;
const Section40 = styled.div`
	float: left;
	width: 40%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section10 = styled.div`
	float: left;
	width: 10%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 10px;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 114px;
    height: 33px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 114px;
    height: 33px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;

Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}



class SideBar extends React.Component
{
	state = {  
        ready : false,
        statusData: [], 
        filterstatusData: {},
        isclear: false, 
        breachType: [],
        filterbreachType: {},
        reportable: [],
        filterreportableType: {},
        internal_external: [],
        filterinternal_external: {},
        updateFromDate: null,
        filterRequestedBreachFromDate: new Date(),
        filterRequestedBreachToDate: new Date(),
        filterRequestedReportedFromDate: new Date(),
        filterRequestedReportedToDate: new Date(),
        company_lists: [],
        filtercompany: {},    
        filterRequestedRecordedFromDate: new Date(),      
        filterRequestedRecordedToDate: new Date(),
        reportedUser_lists: [],
        filterReportedUser: {}   
	}

	constructor(props) {
		super(props);
	}
	componentDidMount = () => {    
        let statusData = [			
			{id: 1, label: "Complete"},
			{id: 2, label: "In Progress"},
			{id: 3, label: "Registered"},
			{id: 4, label: "Saved"},
            {id: 5, label: "Closed"}
        ]

        // let breachType = [
        //     {id: 1, label: "Incident"},
        //     {id: 2, label: "Conduct Rules Breach"}
        // ]
        let reportable = [
            {id: 1, label: "Reportable"},
            {id: 2, label: "Non-Reportable"}
        ]

        let gc_company =  Store.getStoreData('gc_company');
        let company_lists = [];
        for(let k of gc_company){
            let obj = {"id": k.id, "label": k.company_name};  
            company_lists.push(obj);
        }

        let updateBreachFromDate = new Date(this.state.filterRequestedBreachFromDate.getFullYear(), this.state.filterRequestedBreachFromDate.getMonth() - 12, this.state.filterRequestedBreachFromDate.getDate());

        let updateReportedFromDate = new Date(this.state.filterRequestedReportedFromDate.getFullYear(), this.state.filterRequestedReportedFromDate.getMonth() - 12, this.state.filterRequestedReportedFromDate.getDate());

        let updateRecordedFromDate = new Date(this.state.filterRequestedRecordedFromDate.getFullYear(), this.state.filterRequestedRecordedFromDate.getMonth() - 12, this.state.filterRequestedRecordedFromDate.getDate());

        let breachTaskAll = this.props.breachTaskList;        
        let internal_external_object = {};
        let breachType_object = {};
        for(let k of breachTaskAll){
            if(k[11] === 0){
                internal_external_object['External'] = 1;                
            }else if(k[11] === 1){
                internal_external_object['Internal'] = 1;                
                if(k[12] !== '' && k[12] !== '-'){
                    internal_external_object[k[12].toString()] = 1;                                  
                }                
            }

            if(k[8] === 'breach_of_rules'){                
                breachType_object['Incident'] = 1;
                if(k[9] !== '' && k[9] !== '-'){
                    breachType_object[k[9].toString()] = 1;                    
                }
            }else if(k[8] === 'conduct_rules_breach'){  
                breachType_object['Conduct Rules Breach'] = 1;             
            }
        }
        let internal_external = [];
        for(let d in internal_external_object){
            internal_external.push({id: d, label: d})
        }
        let breachType = [];
        for(let p in breachType_object){
            breachType.push({id: p, label: p})
        }

        let contacts =  Store.getStoreData('contacts');
        let contact_id = Store.getStoreData('contact_id');
        let reportedUser_lists = [];
        if(Store.getStoreData('role') !== 'team'){
            for(let k in contacts){
                let obj = {"id": k, "label": contacts[k]};  
                reportedUser_lists.push(obj);
            }
        }else{
            reportedUser_lists = [{"id": contact_id, "label": contacts[contact_id]}]
        }
        

        this.setState({statusData, reportable, breachType, updateBreachFromDate,  filterRequestedBreachFromDate: updateBreachFromDate, updateReportedFromDate, updateRecordedFromDate,
            filterRequestedReportedFromDate: updateReportedFromDate, company_lists, ready: true, internal_external,
            filterRequestedRecordedFromDate: updateRecordedFromDate,
            reportedUser_lists
        })
    }

    componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			let statusData = [			
                {id: 1, label: "Complete"},
                {id: 2, label: "In Progress"},
                {id: 3, label: "Registered"},
                {id: 4, label: "Saved"},
                {id: 5, label: "Closed"}
            ]
    
            let breachType = [
                {id: 1, label: "Incident"},
                {id: 2, label: "Conduct Rules Breach"}
            ]
            let reportable = [
                {id: 1, label: "Reportable"},
                {id: 2, label: "Non-Reportable"}
            ]    
    
            this.setState({statusData, reportable, breachType, ready: true})
		}
	}
    

    closeModal = () => {
        this.props.closeSidebar({close : true});

    }
   
	handleClickOutside = evt => {
        this.props.closeSidebar({close : true});
    };

    statusValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("ricipientObject",filtertypeObject)        
        this.setState({filterstatusData: filtertypeObject})
    }

    breachTypeValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("breachType",filtertypeObject)        
        this.setState({filterbreachType: filtertypeObject})
    }
    companyValue = (selected_options) => {        
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("breachType",filtertypeObject)        
        this.setState({filtercompany: filtertypeObject})
    }
    retortedUserValue = (selected_options) => {        
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("filterReportedUser",filtertypeObject)        
        this.setState({filterReportedUser: filtertypeObject})
    }

    reportableTypeValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("breachType",filtertypeObject)        
        this.setState({filterreportableType: filtertypeObject})
    }
    internalExternalValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("breachType",filtertypeObject)        
        this.setState({filterinternal_external: filtertypeObject})
    }
    
    changeClear = () => {
		this.setState({isclear: false})
    }
    
    onRequestedBreachFromDate = (date) => {
        this.setState({filterRequestedBreachFromDate: date});
    }
    onRequestedReportedFromDate = (date) => {
        this.setState({filterRequestedReportedFromDate: date});
    }
    onRequestedRecordedFromDate = (date) => {
        this.setState({filterRequestedRecordedFromDate: date});
    }
	onRequestedBreachToDate = (date) => {
        this.setState({filterRequestedBreachToDate: date});
    }
    onRequestedReportedToDate = (date) => {
        this.setState({filterRequestedReportedToDate: date});
    }
    onRequestedRecordedToDate = (date) => {
        this.setState({filterRequestedRecordedToDate: date});
    }

    filterSubmit = () => {           
        let breachTaskAll = this.props.breachTaskList
        console.log("breachTaskAll", breachTaskAll)
        let filterbreach = [];
        if (Object.keys(this.state.filterstatusData).length === 0) {
            filterbreach = breachTaskAll;
        } else {  
            let assignuser = Store.getStoreData('task_assign_user');          
            breachTaskAll.forEach((item) => {
                let resultvalue = 0;

                let numberOfFollowup = 0;
                if(item[3] !== null){              
                    numberOfFollowup = JSON.parse(item[3]).length;
                }

                if(item[2] === "COMP_BREACH_REGISTER") {
                    resultvalue = 3;
                    if(numberOfFollowup !== 0 && item[0] in assignuser && assignuser[item[0]].totalNoOfPenFollowupTask === 0){
                        resultvalue = 1;
                    }
                }else if(item[2] === "COMP_BREACH_USERSAVE") {
                    resultvalue = 4;
                }else if(item[2] === "COMP_BREACH_COMPLETE") {
                    resultvalue = 5;
                }else if(item[2] === "COMP_BREACH_REGISTER_ACTION"){
                    resultvalue = 2;
                    if(numberOfFollowup !== 0 && item[0] in assignuser && assignuser[item[0]].totalNoOfPenFollowupTask === 0){
                        resultvalue = 1;
                    }
                }                
                if (resultvalue.toString() in this.state.filterstatusData) {
                    filterbreach.push(item);
                }
            })
        }        
        let filterbreachType = [];
        if (Object.keys(this.state.filterbreachType).length === 0) {
            filterbreachType = filterbreach;
        } else {
            filterbreach.forEach((item) => {     
                let breachType = "";
                let breachTypeIncident = "";
                if(item[8] === 'breach_of_rules'){
                    breachType = "Incident";
                    if(item[9] !== ''){
                        breachTypeIncident = item[9];
                    }
                }else if(item[8] === 'conduct_rules_breach'){            
                    breachType = "Conduct Rules Breach";                
                }                
                if (breachType in this.state.filterbreachType) {
                    filterbreachType.push(item);
                }else if (breachTypeIncident in this.state.filterbreachType) {
                    filterbreachType.push(item);
                }
            })
        }
        //company start
        let filterCompanyValue = [];
        if (Object.keys(this.state.filtercompany).length === 0) {
            filterCompanyValue = filterbreachType;
        } else {
            filterbreachType.forEach((item) => {
                if(item[15] === 0 && item[1] in this.state.filtercompany){
                    filterCompanyValue.push(item);
                }else if(item[15] !== 0 && item[15] in this.state.filtercompany){
                    filterCompanyValue.push(item);
                }
            })
        }
        //company end
        //intr_exter start
        let filterInternalExternalValue = [];
        if (Object.keys(this.state.filterinternal_external).length === 0) {
            filterInternalExternalValue = filterCompanyValue;
        } else {
            filterCompanyValue.forEach((item) => {
                let internal_external = "";
                if(item[11] === 0){
                    internal_external = 'External';
                }else if(item[11] === 1){
                    internal_external = 'Internal';
                    if(item[12] !== ''){
                        internal_external = item[12];
                    }
                }
                if(internal_external in this.state.filterinternal_external){
                    filterInternalExternalValue.push(item);
                }
            })
        }
        //intr_exter end

        let filterreportableTypeValue = [];
        if (Object.keys(this.state.filterreportableType).length === 0) {
            filterreportableTypeValue = filterInternalExternalValue;
        } else {
            filterInternalExternalValue.forEach((item) => {                
                let reportableTypeValue = item[13];                 
                let RTypeID = 0;
                if(reportableTypeValue === 1){
                    RTypeID = 1;
                }else if(reportableTypeValue === 0){
                    RTypeID = 2;
                }                
                if (RTypeID.toString() in this.state.filterreportableType) {
                    filterreportableTypeValue.push(item);
                }
            })
        }
        
        let breachformdatetoDate = [];
        if (this.state.filterRequestedBreachFromDate === "" && this.state.filterRequestedBreachToDate === "") {
            breachformdatetoDate = filterreportableTypeValue;
        }else{            
            filterreportableTypeValue.forEach((item) => {                
                let actionDate = item[14];
                let toDate = this.state.filterRequestedBreachToDate;
                toDate.setHours(23);
                toDate.setMinutes(59);
                toDate.setSeconds(59);
                let endDate = moment(toDate).unix()
                
                if(actionDate > moment(this.state.filterRequestedBreachFromDate).unix() && actionDate < endDate){
                    breachformdatetoDate.push(item);
                }
            })
        }
        let reportedformdatetoDate = [];
        if (this.state.filterRequestedReportedFromDate === "" && this.state.filterRequestedReportedToDate === "") {
            reportedformdatetoDate = breachformdatetoDate;
        }else{
            breachformdatetoDate.forEach((item) => {                
                let actionDate = item[5];
                let toDate = this.state.filterRequestedReportedToDate;
                toDate.setHours(23);
                toDate.setMinutes(59);
                toDate.setSeconds(59);
                let endDate = moment(toDate).unix()                
                if(actionDate > moment(this.state.filterRequestedReportedFromDate).unix() && actionDate < endDate){
                    reportedformdatetoDate.push(item);
                }
            })
        }
        //recorded Date start
        let recordedformdatetoDate = [];        
        if (this.state.filterRequestedRecordedFromDate === "" && this.state.filterRequestedRecordedToDate === "") {
            recordedformdatetoDate = reportedformdatetoDate;
        }else{
            reportedformdatetoDate.forEach((item) => {                
                let createDate = item[16];
                let toDate = this.state.filterRequestedRecordedToDate;
                toDate.setHours(23);
                toDate.setMinutes(59);
                toDate.setSeconds(59);
                let endDate = moment(toDate).unix()                
                if(createDate > moment(this.state.filterRequestedRecordedFromDate).unix() && createDate < endDate){
                    recordedformdatetoDate.push(item);
                }
            })
        }
        //reported Date end
        //company start
        let filterReportedValue = [];
        if (Object.keys(this.state.filterReportedUser).length === 0) {
            filterReportedValue = recordedformdatetoDate;
        } else {
            recordedformdatetoDate.forEach((item) => {
                if(item[17] !== null && item[17] in this.state.filterReportedUser){
                    filterReportedValue.push(item);
                }
            })
        }
        //company end
        this.props.filterSubmitValue(filterReportedValue)
    }

    filterClear = () => {        
		console.log("Sourav")   
        let filterBreachFromdate = new Date();
        let updateBreachFromDate = new Date(filterBreachFromdate.getFullYear(), filterBreachFromdate.getMonth() - 12, filterBreachFromdate.getDate());
        let filterReportedFromdate = new Date();
        let updateReportedFromDate = new Date(filterReportedFromdate.getFullYear(), filterReportedFromdate.getMonth() - 12, filterReportedFromdate.getDate());
        let filterRecordedFromdate = new Date();
        let updateRecordedFromDate = new Date(filterRecordedFromdate.getFullYear(), filterRecordedFromdate.getMonth() - 12, filterRecordedFromdate.getDate());        
        
		this.setState({ isclear: true, filterstatusData: {},
            filterbreachType: {},
            filtercompany: {},
            filterreportableType: {},
            filterRequestedBreachFromDate: updateBreachFromDate, filterRequestedReportedFromDate: updateReportedFromDate, filterRequestedRecordedFromDate: updateRecordedFromDate,
            filterRequestedBreachToDate: new Date(),
            filterRequestedReportedToDate: new Date(),
            filterRequestedRecordedToDate: new Date()
        })
		let breachTaskAll = this.props.breachTaskList	
		this.props.filterSubmitValue(breachTaskAll)		
	}

	render()
	{
		if(!this.state.ready) {
            return <div>Loading...</div>
        }
        //console.log('SideBar this.state', this.state)
		return (
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Advance Filters</MRModalHeaderText>
                    
                    <MRModalLabel style={{color:"#FF0000", marginLeft:"40%"}}></MRModalLabel>
                    <MRModalHeaderCloseBtn onClick= {() => this.closeModal()} ><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>  
                    <div style={{padding: "26px"}}>
                    <FieldLabel>Status</FieldLabel>
                    <FilterBody>
                    {
                        (() => {
                            if (this.state.statusData.length !== 0) {
                                return <CslTypeAhead data={this.state.statusData} ricipientValue={this.statusValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                            }
                        })()
                    }
                    </FilterBody>
                    <FieldLabel style={{marginTop: "15px"}}>Type</FieldLabel>
                    <FilterBody>
                    {
                        (() => {
                            if (this.state.breachType.length !== 0) {
                                return <CslTypeAhead data={this.state.breachType} ricipientValue={this.breachTypeValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                            }
                        })()
                    }
                    </FilterBody>
                    <FieldLabel style={{marginTop: "15px"}}>Company</FieldLabel>
                    <FilterBody>
                    {
                        (() => {
                            if (this.state.company_lists.length !== 0) {
                                return <CslTypeAhead data={this.state.company_lists} ricipientValue={this.companyValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                            }
                        })()
                    }
                    </FilterBody>
                    <FieldLabel style={{marginTop: "15px"}}>Internal/External</FieldLabel>
                    <FilterBody>
                    {
                        (() => {
                            if (this.state.internal_external.length !== 0) {
                                return <CslTypeAhead data={this.state.internal_external} ricipientValue={this.internalExternalValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                            }
                        })()
                    }
                    </FilterBody>
                    <FieldLabel style={{marginTop: "15px"}}>Reportable</FieldLabel>
                    <FilterBody>
                    {
                        (() => {
                            if (this.state.reportable.length !== 0) {
                                return <CslTypeAhead data={this.state.reportable} ricipientValue={this.reportableTypeValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                            }
                        })()
                    }
                    </FilterBody>
                    
                    <FieldLabel style={{marginTop: "15px"}}>Incident Date</FieldLabel>
                    <Section45><CslDatepicker updateFromDate={this.state.updateBreachFromDate} ondateValue={this.onRequestedBreachFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
                    <Section10><Icon icon={androidRemove}/></Section10>
                    <Section45><CslDatepicker ondateValue={this.onRequestedBreachToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
                    <div style={{clear: "both"}}></div>
                    <FieldLabel style={{marginTop: "15px"}}>Closed Date</FieldLabel>
                    <Section45><CslDatepicker updateFromDate={this.state.updateReportedFromDate} ondateValue={this.onRequestedReportedFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
                    <Section10><Icon icon={androidRemove}/></Section10>
                    <Section45><CslDatepicker ondateValue={this.onRequestedReportedToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
                    <div style={{clear: "both"}}></div>
                    <FieldLabel style={{marginTop: "15px"}}>Recorded Date</FieldLabel>
                    <Section45><CslDatepicker updateFromDate={this.state.updateRecordedFromDate} ondateValue={this.onRequestedRecordedFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
                    <Section10><Icon icon={androidRemove}/></Section10>
                    <Section45><CslDatepicker ondateValue={this.onRequestedRecordedToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
                    <div style={{clear: "both"}}></div>

                    <FieldLabel style={{marginTop: "15px"}}>Reported By</FieldLabel>
                    <FilterBody>
                    {
                        (() => {
                            if (this.state.reportedUser_lists.length !== 0) {
                                return <CslTypeAhead data={this.state.reportedUser_lists} ricipientValue={this.retortedUserValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                            }
                        })()
                    }
                    </FilterBody>
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>                    
                    <MRModalSaveCloseBtn onClick={this.filterSubmit}>APPLY</MRModalSaveCloseBtn>                                    
                    <MRModalCancelBtn onClick={this.filterClear}>RESET</MRModalCancelBtn>
                    </div> 
                    <div style={{clear: "both"}}></div>       
                </MRModalFooter>
            </MRModalContainer>
			);
		  	
	
	}
}

export default SideBar;