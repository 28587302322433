import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import {FaAngleRight} from 'react-icons/fa';
import {FaTimes} from 'react-icons/fa';
import Utils from '../../Common/Utils.js'


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 3003;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 37%;
    top: 117px;
    left: 19%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #a0a0a0;*/
    color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-left: 24px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;

const RiskSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
    height:40px
`;

class DataLostWarningModal extends React.Component
{
	state = {
       
	}

	componentDidMount()
    {
        this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        
    }

    modalYesClick = (event)=>{
        event.preventDefault();
		if(Store.getStoreData('sub_cat_opened') === true){
			this.props.refreshParentModal('sub_cat_opened');
		}else if(Store.getStoreData('from_subcat_to_activity') === true){
			this.props.refreshParentModal('from_subcat_to_activity');
		}else if(Store.getStoreData('from_riskarea_to_activity') === true){
			this.props.refreshParentModal('from_riskarea_to_activity');
		}else{
			Store.updateStore('last_unique_risk_id', null);
			Store.updateStore('last_unique_subcat_id', null);
			this.props.refreshParentModal(null);
		}
    }
    modalNoClick = (event)=>{
        event.preventDefault();
        this.props.refreshParentModal('no');
    }
	render()
	{
        if (this.state.risk_owner_id === "") {
            return (<div>Loading...</div>);
        }
      
        return (
            <div>
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Confirm</MRModalHeaderText>
                        <div style={{clear: "both"}}></div>                       
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalLabel>The information entered will be lost if you continue to exit this page. Continue?</MRModalLabel>
                        <div style={{clear: "both", marginTop:"42px"}}></div>
                        <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", marginLeft: "28%"}} onClick={this.modalYesClick}>Yes</CatBtn>
                        <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", marginLeft: "21px"}} onClick={this.modalNoClick}>No</CatBtn>
                     </MRModalBody>  
                    <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                </MRModalContainer>
            </div>                  
		);
	}
}

export default DataLostWarningModal;