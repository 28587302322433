import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faGear, faSquareList } from "@fortawesome/pro-thin-svg-icons";
/*global localStorage*/
const HeadContainer = styled.div`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class CSLHeader extends React.Component
{
    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
          let site_layout = JSON.parse(localStorage.getItem('site_layout'));
          use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)
        let role = Store.getStoreData('role')
        return (
            <HeadContainer>
                <a href={'/'}>
                  <HomeText>
                    <FontAwesomeIcon icon={faHome} style={{marginRight: '7px'}} />
                    <span style={{fontWeight: "800"}}>{this.props.headerText_Second}</span>
                  </HomeText>
                </a>
              { role === 'admin_manager' &&
                <Categories title='Module Config' >
                  <a href={'/moduleconfig'}>
                    <FontAwesomeIcon icon={faGear} style={{ color: '#848484' }} />
                  </a>
                </Categories>
              }
              { role === 'admin_manager' &&
                <Categories title='Audit Logs' >
                  <a href={process.env.REACT_APP_AUDIT_URL}>
                    <FontAwesomeIcon icon={faSquareList} style={{ color: '#848484' }} />
                  </a>
                </Categories>
              }
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default CSLHeader;
