import React from 'react';
import moment from 'moment';
import CSLTable from './CSLTable';
import AdvanceFilter from './AdvanceFilter';
// import ReportModal from './ReportModal';
// import PdfGenerator from './PdfGenerator';
import CSLReportModal from './CSLReportModal';
import PdfGenerator from './PdfGenerator';

import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import Utils from '../../../Common/Utils.js';
import styled from 'styled-components';



const RButton = styled.button`
	background-color: #1E3E62;
    color: #ffffff;
    padding: 10px 15px;
    border: 1px solid #1E3E62;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const Section88 = styled.div`
	width: calc(100% - 10px);
	box-sizing: border-box;
	float: left;
`;
const Section12 = styled.div`
	width: 12%;
	box-sizing: border-box;
	float: left;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 160px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
    cursor: pointer;
`;

const completeFiled = {cur_assigned_to: 'assigned_to_text', contact_id: 'created_by', company_id : 'company_name'}


class GcReporting extends React.Component
{
	state = {
				show_filter: true, 
				filters: null, 
				pill_filters: null, 
				show_report: false, 
				generate: false, 
				groupby_selections: [], 
				sortby_selected: {accessor: 'none', order: 'Descending'},
				tilte_pdf : null,
				orientation:'portrait'
			}

	data = {};

	constructor(props) {
    	super(props);
    	this.scrollDiv = React.createRef();
  	}

  	setComponentState = () => {
  		let filters = this.state.filters === null ? this.props.filters : this.state.filters;
      console.log("filters->>comple", filters)
  		let pill_filters = this.state.pill_filters === null ? this.props.pillFilters : this.state.pill_filters
  		let grouping_config = Store.getStoreData('groupings')
  		let tags_config = grouping_config === null ? [] : grouping_config.tags_config
  		let groupings = {}
  		let data = this.props.data
      // console.log('this.state.filters', this.state.filters)
  		for(let t of tags_config) {
  			let id = Object.keys(t)[0]
  			groupings[id] = t[id]
    		let included = data.table_headers.filter(obj => {
  			  return obj.accessor === id
  			})
			// // console.log('setComponentState included', included)
  			if(included.length === 0)data.table_headers.push({accessor: id, nickname: t[id], type: 'alpha', width: 0})
  			included = data.groupby.fields.filter(obj => {
  			  return obj.accessor === id
  			})
  			if(included.length === 0)data.groupby.fields.push({accessor: id, nickname: t[id]})
  			included = data.sortby.fields.filter(obj => {
  			  return obj.accessor === id
  			})
  			if(included.length === 0)data.sortby.fields.push({accessor: id, nickname: t[id]})

  			if(!(id in filters))filters[id] = {name: id, nickname: t[id], options: {}, selections: {}, size: 2, type: 'multi', is_grouping: true}
        let applied_filters = Store.getStoreData('applied_filters')
        if(applied_filters !== null)filters[id].selections = applied_filters[id].selections
  		}
  	
      let table_data = this.generateTableData()


      console.log("gc_companies", Store.getStoreData('gc_assoc'), filters)
      let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
      let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
  		this.setState({filters, pill_filters, data, groupings, tilte_pdf, orientation})
  	}



  	componentDidMount() {
  		this.setComponentState()
  	}

  	componentDidUpdate(prevprops) {
  		if(prevprops === this.props)return

  		this.setComponentState()
  	}

	clearAdvancedFilter = () => {
		// window.location.reload()
		this.props.refreshCallback()
	}
	/*applyFilter = (filters) => {
		let applied_filters = JSON.parse(JSON.stringify(filters))
        this.setState({filters: applied_filters})
        Store.updateStore('applied_filters', applied_filters)
        this.props.refreshCallback()

	}*/

  applyFilter = (filters) => {
    let applied_filters = JSON.parse(JSON.stringify(filters));
    let options = {};
    for(let key in applied_filters){
      let filter = {};
      for(let opt in applied_filters[key].selections){
        if(key === "due_date"){options[key] = applied_filters[key].selections}
        if(key !== "due_date"){
          filter[opt] = 1;
          options[key] = filter;
        }
      }
    }
    this.props.applyFilter(options);
  }

	showOnlyAssign = (flag) =>{
	  this.props.showOnlyAssign(flag);
	}

	setShowTable = (flag, table) => {
	  this.props.setShowTable(flag, table);
	}

	toggleConfigureReport = (event) => {
		let show_configure_report = false;
		show_configure_report = show_configure_report === true ? false : true;
		this.setState({show_configure_report});
	}

	hideReport = () => {
		this.setState({show_report: false});
	}

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});
	}

	reportCallback = () => {
		this.setState({show_report: true})
	}

	changeSort = (sortby) => {
        let data = JSON.parse(JSON.stringify(this.state.data));
        this.data.sortby = sortby;
        this.setState({data: this.data, sortby_selected: sortby.selected});
	}

	changeGroup = (groupby) => {
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.groupby = groupby;
        this.setState({data: this.data, groupby_selections: groupby.selections});
	}

    pillClickHandler = (event) => {
        event.preventDefault();
        
        let pill_id = [event.target.name]
        let pill_filters = this.state.pill_filters;
        pill_filters[pill_id].is_active = (pill_filters[pill_id].is_active + 1) % 2
        this.setState({pill_filters})
        this.props.refreshCallback()
    }

    filterDateScore = (date) => {
    	// // // console.log('applyAdvancedFilterDate, date', date)
    	let date_parts = date.split('T')[0].split('-')
    	return parseInt(date_parts[0]) * 10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[2])
    }
    tableDateScore = (date) => {
    	let date_parts = date.split('/')
    	return parseInt(date_parts[2]) * 10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[0])
    }

    filterRow = (elem) => {
    	let filters = this.state.filters
    	let inclusion = 1
    	// // console.log('applyAdvancedFilterDate filters, elem', filters, elem)
    	for(let id in filters) {
    		if(Object.keys(filters[id].selections).length === 0) {
    			inclusion *= 1
    		} else {
    			switch(filters[id].type) {
    				case 'text' : inclusion *= elem[id].toLowerCase().includes(filters[id].selections.text.toLowerCase()) ? 1 : 0; break;
    				case 'multi' : 
    				case 'single' : inclusion *= (elem[id] in filters[id].selections ? 1 : 0); break;
    				case 'date_range' : let from_date = this.tableDateScore(filters[id].selections.from_date)
    									let to_date = this.tableDateScore(filters[id].selections.to_date)
    									let table_date = this.tableDateScore(elem[id])
    									// // console.log('applyAdvancedFilterDate, from_date, to_date, table_date', from_date, to_date, table_date)
    									inclusion *= (table_date >= from_date && table_date <= to_date) ? 1 : 0; break;
    			}
    		}
    	}

    	return (inclusion > 0)
    }

    getInclusion = (row_filter) => {
    	let pill_filters = this.state.pill_filters
    	// // console.log('getInclusion pill_filters, row_filter', pill_filters, row_filter)

    	let included_count = 0
    	for(let pid in pill_filters) {
    		included_count += pill_filters[pid].is_active * row_filter[pid]
    	}

    	return (included_count > 0)
    }

    generateTableData = () => {
    	let data = this.props.processTableData()
      console.log("data =====>", data)
    	let groupings = Store.getStoreData('groupings')
      groupings = groupings === null ? {tags: {}, tags_config: []} : groupings
    	let gc_assoc = Store.getStoreData('gc_assoc')
      
    	let grouping_tags = {}
  		for(let t of groupings.tags_config) {
  			let id = Object.keys(t)[0]
  			grouping_tags[id] = t[id]
  		}
    	let tags = {}
    	for(let id in groupings.tags) {
    		tags[id] = {}
    		for(let g of groupings.tags[id]) {
    			tags[id][g.id] = g.name
    		}
    	}
    	// // // console.log('generateTableData gc_assoc', gc_assoc)
    	for(let r of data.raw_data) {
    		let company_id = (parseInt(r.company_id) % 100000).toString()
    		let gc_grouping = (company_id in gc_assoc && 'tags_config' in gc_assoc[company_id]) ? gc_assoc[company_id].tags_config : {}
    		// // // console.log('generateTableData gc_grouping', gc_grouping)
    		for(let tid in grouping_tags) {
    			if(tid in gc_grouping && tid in tags) {
    				if(gc_grouping[tid] in tags[tid]) {
    					r[tid] = tags[tid][gc_grouping[tid]]
    				} else {
    					r[tid] = 'None'
    				}
    			} else {
    				r[tid] = 'None'
    			}
    		}
    	}
    	console.log('generateTableData data.raw_data', data.raw_data)
    	let ret = {columns: data.columns, data: [], raw_data: []} 

    	for(let elem of data.raw_data) {
				ret.raw_data.push(elem);    		
				ret.data.push(elem);    		
    	}

    	return ret
    }

    goToReport = () => {
    	this.setState({show_report: true})
    }
		toggleOrientation = (mode) => {
			this.setState({orientation: mode});
		}

	render()
	{
		
		if(this.state.filters === null) return <div></div>

		console.log('this.props.buttonSetTop', this.props)
		return (
			<div style={{position: "relative", backgroundColor: "#F2F2F2", padding: "10px 0px"}} ref={this.scrollDiv}>
				{/*
          <Section88>
                    {
                      Object.keys(this.state.pill_filters).map((pid, i) => {
                        return(<FilterButton key={i} style={{backgroundColor: this.state.pill_filters[pid].is_active ? '#243E57' : '#EEF2F7', 
                            color:  this.state.pill_filters[pid].is_active ? "#EEF2F7" : '#243E57' , marginRight:"15px"}} name={this.state.pill_filters[pid].name} onClick={this.pillClickHandler}>
                            <span style={{float: 'left'}}>&#10003;    </span>{this.state.pill_filters[pid].display}<span style={{float: 'right'}}> 
                            {this.state.pill_filters[pid].count}</span>
                          </FilterButton>);
                      })
                    }
                  </Section88>
        */}
				<div style={{clear: "both", height: '3px'}}></div>
				<div style={{clear: "both"}}></div>

				<AdvanceFilter 
					filters={this.state.filters} 
					applyFilter={this.applyFilter} 
					clearAdvancedFilter={this.clearAdvancedFilter}
				/>
				<CSLTable export={true}  goToReport={this.goToReport} export_file_name={"Register"}  
							add={false}  
							processData={this.generateTableData} 
							prefered_columns = {this.props.preferedColumns} 
							export_columns = {this.props.preferedColumns} 
							headerText={`${this.props.headerText} Register`}  
							tableRows={10} 
							refreshCallback={this.refreshCallback} 
							// qcbutton={this.props.qcbutton} 
							// toggleAssignQcModal={this.props.toggleAssignQcModal} 
							// qa_enabled={this.props.quality_assurance_enabled} 
							report={true}
              showHF={true}
              showtopLebel={this.props.showtopLebel}

              buttonSet={this.props.buttonSet}
              buttonSetTop={this.props.buttonSetTop}
              textView={this.props.textView}
				/>           

				{
					(() => {
						if(this.state.show_report) {
							let fields = this.props.fields
							let data = this.props.data
							data.data = this.generateTableData().data
							// this.data = {
							// 					data: this.generateTableData().data, 
							// 					groupby: {fields: fields, selections: this.state.groupby_selections}, 
							// 					sortby: {fields: fields, selected: this.state.sortby_selected},
							// 					table_headers: fields,
							// 					module: {display: this.props.headerText, id: this.props.moduleId}
							// 				}
							Utils.log('widths new_data', this.data)
							return (
									<div style={{position:'absolute', top:'0px', width:'100%'}}>
										<InactiveOverlay />
										<CSLReportModal
											data={data}
											changeSort={this.changeSort}
											changeGroup={this.changeGroup}
											hideReport={this.hideReport}
											toggleGenerate={this.toggleGenerate}
											filters={this.state.filters}
											toggleOrientation={this.toggleOrientation}
										/>
									</div>
								);
						}
					})()
				}
				{
					(() => {
						if (this.state.generate) {
							return (
								<div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
									<InactiveOverlay style={{zIndex: "1010"}} />
									<PdfGenerator 
									filters={this.state.filters} 
									pills={this.state.pill_filters} 
									toggleGenerate={this.toggleGenerate} 
									contactName={Store.getStoreData('contact')} 
									tiltePDF={this.state.tilte_pdf}
                  orientation={this.state.orientation}
									/>
								</div>
							);
						}
					})()
				}
			</div>
		);
	}

	scrollToMyRef = () => this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
}

export default GcReporting;

