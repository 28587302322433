import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import * as Style from '../Common/StyledComponents'
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import AlertBox from '../Common/AlertBox';
import ShowScheme from './ShowScheme';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;

`;

class CSLParameter extends React.Component
{
	state = { view_count: 0,  alert_param: null, parameters: [], prefered_columns: {}, ready: false, show_inactive: false, overlay_height: null, show_modal: '', parameter_id: 0}
	componentDidMount(){
	    this.processParameter();
	}
	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processParameter()
	    }
	}
	processParameter = () => {
		let prefered_columns = {};
		prefered_columns['name'] = 'Task Name';
		prefered_columns['description'] = 'Task Description';
		prefered_columns['status'] = 'Status';
		this.setState({parameters: [], prefered_columns: prefered_columns, ready: true})
	}

	viewTask = (id) => (event) => {
	 event.preventDefault();
	  let url = `/parameter/${id}`;
	  window.location = url;
	}

	viewSchemes = (id) => (event) => {
		// event.preventDefault();
		this.setState({overlay_height : `${document.body.scrollHeight.toString()}px`, show_modal: 'show_scheme', parameter_id: id});
	}

	closeModal = () => {
	  this.setState({view_count : this.state.view_count + 1, overlay_height: null, show_modal: ''});
	}


	RemoveTask = (task_id) => (event) => {
		let alert_param = {title: 'Warning', message: 'Do you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
		                   alertHandler: this.removeTaskProcessor, stack: {task_id: task_id}}
		this.setState({alert_param: alert_param})
	}

	removeTaskProcessor = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let task_id = stack.task_id;
        let postData = {command: "remove_parameter", id: task_id};
		let api = new APICall();
		api.command(postData, this.reloadTask);
        this.setState({alert_param: null})
    }

    reloadTask = (result) => {
		let alert_param = {title: 'Success', message: 'Task has been removed successfully..', ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
		if(result.error_code > 0) {
			alert_param['message'] = result.error_msg;
		}
		this.setState({alert_param: alert_param})
    }

    alertremoveHandler = (result, stack) => {
    	let url = "/parameter";
    	window.location = url;
    }


    Filtertabledata  = (data) => {
    	let result = [];
    	if(this.state.show_inactive){
    	  for(let d of data) {
    	    if(d.is_active === 0){
    	      result.push(d);
    	    }
    	  }
    	}else{
    	  for(let d of data) {
    	    if(d.is_active === 1){
	    	    result.push(d)
	    	}
    	  }
    	}
    	return result;
    }


	processData = () => {
		let tabledata = Store.getStoreData('parameters');
		tabledata = this.Filtertabledata(tabledata);
		let associated_schemes = Store.getStoreData('associated_schemes');
		// console.log("tabledata", tabledata);
		let ret = {data: [], columns: []};
		let i = 0;
		let associated_taks = 0;
		ret.columns =[
		                {Header: 'Task Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left'}},
		                {Header: 'Task Description', accessor: 'description', minWidth: 200, headerStyle: {textAlign: 'left'}},
		                {Header: 'Associated Task Schemes', accessor: 'associated_schemes', width: 150, headerStyle: {textAlign: 'left'},
		                	Cell: row => (
		                	  <div style={{cursor: "pointer", color: "#0000EE"}} onClick={this.viewSchemes(row.original.id)}>{row.original.associated_schemes}</div>
		                	  )
		            	},
		                {Header: 'Status', accessor: 'status', width: 150, headerStyle: {textAlign: 'left'}},
		                {'Header' : 'Action', Cell: row => (
		                   <div>
		                       <MRButton onClick={this.viewTask(row.original.id)}>View</MRButton>
		                       <MRButton onClick={this.RemoveTask(row.original.id)}>Delete</MRButton>
		                   </div>
		                ), width: 200, headerStyle: {textAlign: 'left'}}];

		for(let d of tabledata) {
			associated_taks = Object.keys(associated_schemes[d.id.toString()]).length;
		    i++;
		    let elem = {
		        id: d.id,
		        name : d.name,
		        description : d.description,
		        associated_schemes : associated_taks,
		        status : d.is_active === 1 ? "Active" : "Inactive"
		    };
		    ret.data.push(elem);
		}
		return ret;
	}

	ChangeInactive = (event) => {
		// event.preventDefault();
		let show_inactive = this.state.show_inactive ? false : true;
		this.setState({show_inactive});
	}

	render()
	{
		let add_task_url = '/parameter/0';
		return (
			<IndexContainer>
				<a href={add_task_url}><AddRbutton>Add</AddRbutton></a>
				<div style={{padding: "10px", fontWeight: "600"}}><input type="checkbox" checked={this.state.show_inactive} onChange={this.ChangeInactive} /> Show Inactive</div>
				<CSLTable add={false} processData={this.processData} tableRows={10} headerText={'MCC Recurring Tasks'} export={true} prefered_columns={this.state.prefered_columns}/>
				<AlertBox alertParam = {this.state.alert_param} />
				{
				  (() => {
				    if (this.state.show_modal === 'show_scheme') {
				      return <div><InactiveOverlay /><ShowScheme closeModal={this.closeModal} parameter_id={this.state.parameter_id}/></div>
				    }
				  })()
				}
			</IndexContainer>

			)
	}
}
export default CSLParameter;
