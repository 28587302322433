import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import { FaAngleDoubleRight, FaAngleDoubleLeft, FaAngleRight, FaAngleLeft, FaPlusCircle} from "react-icons/fa";

const CSLDateCover = styled.div`
  margin-top: 25px;
  width: 50%;
  border-radius: 5px;
  background-color: #ffffff;
  height: 35px;
  padding-top: 5px;
  padding-left: 10px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 0px;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 0px;
`;
const Box = styled.div`
	border: 1px solid #395875;
	padding: 6px;
	height: 25px;
	border-radius: 4px;
	margin-bottom: 20px;
	background-color: #335575;
`
class Managescheme extends React.Component
{
	state = {
		scheme: {},
		scheme_id: 0,
		scheme_name: "",
		description: "",
		alert_param: null,
		is_active: 1,
		ready: false,
		available_tasks:{},
		used_tasks:{}
	};
	constructor(props) {
	    super(props);
	}
	closeModal = (event) => {
	    event.preventDefault();
	    window.location.href = "/scheme";

	}
	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}
	processEditTaskData = (result) => {
		let tasks = Store.getStoreData('parameters');
		let available_tasks = {};
		console.log("result", result)
		let scheme = JSON.parse(result.scheme_json)
		let used_tasks = scheme.used_tasks;
		for(let i = 0; i<tasks.length; i++) {
			if(used_tasks[tasks[i].id]){
				available_tasks[tasks[i].id.toString()] = {name: tasks[i].name, description: tasks[i].description, frequency: tasks[i].frequency, included: true}
			}else{
				available_tasks[tasks[i].id.toString()] = {name: tasks[i].name, description: tasks[i].description, frequency: tasks[i].frequency}
			}

		}
		this.setState({scheme_id: result.id, scheme_name: result.name, description: result.description, is_active: result.is_active, scheme: scheme,available_tasks:available_tasks, used_tasks: used_tasks, ready: true})
	}

	processTaskData = () => {
		let tasks = Store.getStoreData('parameters');
		console.log("parameters", tasks)
		let available_tasks = {};
		for(let i = 0; i<tasks.length; i++) {
			available_tasks[tasks[i].id.toString()] = {name: tasks[i].name, description: tasks[i].description, frequency: tasks[i].frequency}
		}
		console.log("available_tasks", available_tasks)
		let schemeId = this.props.match ? this.props.match.params.schemeId : 0;
		console.log("schemeId", schemeId)
		if(schemeId > 0) {
			let postData = { "command" : "get_scheme", "id" : schemeId }
			let api = new APICall();
			api.command(postData, this.processEditTaskData);
		}else{
			this.setState({ ready: true, available_tasks:available_tasks });
		}

	}



	manageParameter = (submit_form) => (event) => {
	    if(this.state.scheme_name === '') {
	        let alert_param = {title: 'New task', message: 'ERROR: Scheme name cannot be blank.', ok_text: 'Ok', confirm: false, alertHandler: this.alertcloseHandler, stack: {id: 0}}
	        this.setState({alert_param: alert_param})
	        return false;
	    }
	    let postData = {
	    		command: "save_scheme",
	            scheme_name:this.state.scheme_name,
	            description: this.state.description,
	            is_active: this.state.is_active,
	            submit:submit_form,
	            scheme_id: this.state.scheme_id
	        };
        if(this.state.scheme_id > 0){
        	postData['scheme'] = this.state.scheme;
        }

        let parameters = this.state.available_tasks;

        let used_parameters = {};
        for(let task_id of Object.keys(parameters)) {
        	if(parameters[task_id].included){
        		used_parameters[task_id.toString()] = {
        			name: parameters[task_id].name,
        			description: parameters[task_id].description,
        			frequency: parameters[task_id].frequency
        		}
        	}
        }
        postData['used_tasks'] = used_parameters;

	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processAddTask);

	}

	processAddTask = (result) =>
	{
		console.log("result", result)

		let mgs = "Scheme has been saved.";
		let title = "Success";

		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertaddHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})

	}

	alertaddHandler = (result, stack) => {
		let url = "/scheme";
		window.location = url;
	}

	alertcloseHandler = (result, stack) => {
		this.setState({alert_param: null})
	}


	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {is_active} = this.state;
		is_active = is_active === 1 ? 0 : 1;
		this.setState({is_active})
	}

    availableTasks = () => {
    	let ret = {data: [], columns: []}

    	ret.columns =[{'Header' : 'Unassociated Recurring Tasks',accessor: 'name', Cell: row => (
    		                <div onClick={this.selectTasks(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
    		                	<label  style={{cursor: 'pointer', fontWeight:'400', fontSize: '12px'}}>{row.original.name}</label>
    		            	</div>
    			         ),headerStyle: {textAlign: 'left'}},
    					];

		for(let task_id of Object.keys(this.state.available_tasks)) {
			if(!this.state.available_tasks[task_id].included ){
				ret.data.push({name: this.state.available_tasks[task_id].name, id: task_id, selected: this.state.available_tasks[task_id].selected})
			}
		}
		ret.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

		return ret;
    }

    selectTasks = (id) => (event) => {
    	event.preventDefault();
    	let available_tasks = this.state.available_tasks;
    	available_tasks[id].selected = available_tasks[id].selected ? false : true;
    	this.setState({available_tasks})
    }


    usedTasks = () => {
    	let ret = {data: [], columns: []}
    	ret.columns =[{'Header' : 'Associated MCC Recurring Tasks',accessor: 'name', Cell: row => (
    		                <div onClick={this.selectTasks(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
    		                <label  style={{cursor: 'pointer', fontWeight:'400', fontSize: '12px'}}>{row.original.name}</label>
    		            	</div>
    			         ),headerStyle: {textAlign: 'left'}}
    					];


		for(let task_id of Object.keys(this.state.available_tasks)) {
			if(this.state.available_tasks[task_id].included ){
				ret.data.push({name: this.state.available_tasks[task_id].name, id: task_id, selected: this.state.available_tasks[task_id].selected})
			}
		}
		ret.data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
		return ret;
    }


    selectTags = (event) => {
    	event.preventDefault();
    	let available_tasks = this.state.available_tasks;
    	for(let task_id of Object.keys(available_tasks)) {
    		if(!available_tasks[task_id].included && available_tasks[task_id].selected) {
    			available_tasks[task_id].included = true;
    			available_tasks[task_id].selected = false;
    		}
    	}
    	this.setState({available_tasks})
    }

    deSelectTags = (event) => {
    	event.preventDefault();
    	let available_tasks = this.state.available_tasks;
    	// console.log("used tags", used_tags)
    	let used = false;
    	for(let task_id of Object.keys(available_tasks)) {
    		if(available_tasks[task_id].included && available_tasks[task_id].selected) {
				available_tasks[task_id].included = false;
				available_tasks[task_id].selected = false;
    		}
    	}

    	this.setState({available_tasks})
    }
    deSelectAllTags = (event) => {
    	event.preventDefault();
    	let available_tasks = this.state.available_tasks;
    	let used_tasks = this.state.used_tasks;
    	let used = false;
    	for(let task_id of Object.keys(available_tasks)) {
    		available_tasks[task_id].included = false;
    		available_tasks[task_id].selected = false;
    	}

    	this.setState({available_tasks})

    }
    selectAllTags = (event) => {
    	event.preventDefault();
    	let available_tasks = this.state.available_tasks;
    	for(let task_id of Object.keys(available_tasks)) {
			available_tasks[task_id].included = true;
			available_tasks[task_id].selected = false;
    	}
    	this.setState({available_tasks})
    }



	render()
	{
		if(!this.state.ready) {
		    return <div><CSLLoader /></div>;
		}
		console.log("this.sate", this.state.available_tasks)
		return (<div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
			<Style.CSLModalContainer>
			    <Style.ModalHeaderText>
			    {
			    	(() => {
			    		if(this.state.task_id === 0) {
			    			return "Add MCC Scheme";
			    		}else{
			    			return "Update MCC Scheme";
			    		}
			    	})()
			    }
			    </Style.ModalHeaderText>

			    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    <div style={{clear: "both"}}></div>
			    <Style.ModalBody>
			        <Style.ModalBodyInner>
			            <Style.ModalContainertop>
			                <Style.ModalNameLabelDiv>Name</Style.ModalNameLabelDiv>
			                <Style.ModalNameInputDiv>
			                    <Style.ModalInput name="scheme_name" value={this.state.scheme_name.replace(/'+/g,"'")} onChange={this.handleChange} />
			                </Style.ModalNameInputDiv>
			                <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
			                <Style.ModalNameInputDiv>
			                    <Style.ModalTextArea name="description" value={this.state.description.replace(/'+/g,"'")} onChange={this.handleChange}/>
			                </Style.ModalNameInputDiv>
			                <Style.ModalNameLabelDiv>MCC Recurring tasks</Style.ModalNameLabelDiv>

        					<Style.ModalNameInputDiv>
        						<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "calc(50% - 25px)", borderRadius: "3px",padding: "5px", float: "left"}} >
        							<CSLTable add={false} processData={this.availableTasks} headerText={''} tableRows={10} />
        						</div>
        						<div style={{paddingTop: "140px", borderRadius: "3px",float: "left", width: '30px'}}>
        							<Box style={{cursor: "pointer"}} onClick={this.selectAllTags}><FaAngleDoubleRight style={{color: "#ffffff"}} /></Box>
        							<Box style={{cursor: "pointer"}} onClick={this.selectTags}><FaAngleRight style={{color: "#ffffff"}} /><br/><br/></Box>
        							<Box style={{cursor: "pointer"}} onClick={this.deSelectTags}><FaAngleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
        							<Box style={{cursor: "pointer"}} onClick={this.deSelectAllTags}><FaAngleDoubleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
        						</div>
        						<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "calc(50% - 25px)", borderRadius: "3px",padding: "5px", float: "left" }}>
        							<CSLTable add={false} processData={this.usedTasks} headerText={''} tableRows={10} />
        						</div>
        					</Style.ModalNameInputDiv>


		                    <Style.ModalNameLabelDiv>Active</Style.ModalNameLabelDiv>
		                    <Style.ModalNameInputDiv>
	                    	{
	                    		(() => {
	                    			if (this.state.is_active) {
	                    				return (
	                    					<div>
	                    						<SelectedRadio>Yes</SelectedRadio>
	                    						<UnselectedRadio onClick={this.switchRadio('is_active')}>No</UnselectedRadio>
	                    					</div>
	                    				);
	                    			} else {
	                    				return (
	                    					<div>
	                    						<UnselectedRadio onClick={this.switchRadio('is_active')}>Yes</UnselectedRadio>
	                    						<SelectedRadio>No</SelectedRadio>
	                    					</div>
	                    				);
	                    			}
	                    		})()
	                    	}
		                    </Style.ModalNameInputDiv>




			            </Style.ModalContainertop>
			        </Style.ModalBodyInner>
			    </Style.ModalBody>
			    <Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			            <Style.SubmitButton onClick={this.manageParameter(0)}>Save</Style.SubmitButton>
			    </Style.CancelSubmitButtonOuterContainer>
			</Style.CSLModalContainer>
			<AlertBox alertParam = {this.state.alert_param} />
				</div>)
	}
}

export default Managescheme;
