import Store from './Store';
import http from 'mcc-front-aux/dist/httpCommon'
// import { trackPromise } from 'react-promise-tracker';
/* global fetch */

class TAPICall {
  constructor() {
    //this.url = Store.getStoreData("is_debug") ? "http://cloud9.pensivedata.in:5006/api/command" : "/select/microservice/";
    this.url = Store.getStoreData("api_url");
    this.is_admin = Store.getStoreData("is_admin");
    this.is_debug = Store.getStoreData("is_debug");
    this.module_name = "cmp";
  }

  strRawToWeb(jsonDataString) {
    jsonDataString = jsonDataString.replace(/&/g, "--and--");
    jsonDataString = jsonDataString.replace(/\+/g, "--plus--");
    jsonDataString = jsonDataString.replace(/'+/g, "--quote--");
    jsonDataString = jsonDataString.replace(/\n/g, "--newline--");
    jsonDataString = jsonDataString.replace(/\r/g, "--creturn--");

    return jsonDataString
  }

  strWebToRaw(result) {
    result = result.replace(/(--plus--)/g, "+");
    result = result.replace(/(--quote--)/g, "'");
    result = result.replace(/(--and--)/g, "&");
    result = result.replace(/(--newline--)/g, "\n");
    result = result.replace(/(--creturn--)/g, "\r");
    result = result.replace(/'+/g, "'");

    return result
  }

  async command(postData, callback) {
    postData.env = "select";

    let jsonDataString = this.strRawToWeb(JSON.stringify(postData))

    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }


    http.post(postURL, {jsondata: jsonDataString}).then(result => {
      result = result.data.body
      result = this.strWebToRaw(JSON.stringify(result));
      result = JSON.parse(result);
      callback(result);
    }).catch(error => {
      console.log('Error data',error?.response?.data);
      return null;
    });
     // fetch(url, httpVerb)
     //  .then((res) => res.json())
     //  .then((result) => {
     //    result = this.strWebToRaw(JSON.stringify(result));
     //    result = JSON.parse(result);
     //    callback(result);
     //  })
     //  .catch((error) => {
     //    console.log('Error data',error?.response?.data);
     //    return null;
     //  })
  }
}

export default TAPICall;
