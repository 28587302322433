		let data = {table_headers: [], data: []};
        data.module = {id: 'conflicts', display: 'Conflict of Interest'}
		data.table_headers = [
            {
                accessor: "status", nickname: "Status", type: "alpha", width: 0.83,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "conflictid", nickname: "ID", type: "alpha", width: 0.91,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "conflict", nickname: "Conflict", type: "alpha", width: 2.35,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "no_of_followup", nickname: "Tasks", type: "alpha", width: 0.52,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':false, 'right':true}
            },
            {
                accessor: "conflicttype", nickname: "Conflict Type", type: "alpha", width: 1.4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "discloser", nickname: "Discloser", type: "alpha", width: 0.94,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "company_name", nickname: "Company", type: "alpha", width: 0.94,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "discloserdate", nickname: "Date", type: "date", width: 0.82,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            }
        ];

        data.sortby = {
            fields: [
                {accessor: "status", nickname: "Status", type: "alpha", width: 1.2},
                {accessor: "conflictid", nickname: "ID", type: "alpha", width: 1.3},
                {accessor: "conflict", nickname: "Conflict", type: "alpha", width: 1.5},
                {accessor: "no_of_followup", nickname: "Tasks", type: "alpha", width: 2},
                {accessor: "conflicttype", nickname: "Conflict Type", type: "alpha", width: 1.4},
                {accessor: "discloser", nickname: "Discloser", type: "alpha", width: 0.8},
                {accessor: "company_name", nickname: "Company", type: "alpha", width: 1.1},
                {accessor: "discloserdate", nickname: "Date", type: "date", width: 1.1},
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {accessor: "status", nickname: "Status", type: "alpha", width: 1.2},
                {accessor: "conflictid", nickname: "ID", type: "alpha", width: 1.3},
                {accessor: "conflict", nickname: "Conflict", type: "alpha", width: 1.5},
                {accessor: "no_of_followup", nickname: "Tasks", type: "alpha", width: 2},
                {accessor: "conflicttype", nickname: "Conflict Type", type: "alpha", width: 1.4},
                {accessor: "discloser", nickname: "Discloser", type: "alpha", width: 0.8},
                {accessor: "company_name", nickname: "Company", type: "alpha", width: 1.1},
                {accessor: "discloserdate", nickname: "Date", type: "date", width: 1.1},
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data