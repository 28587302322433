import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import {FaTimes} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import {IoIosArrowUp} from 'react-icons/io';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 60%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #373737;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #252525;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 94%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 94%;
    margin: 0 auto;        
`;

const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 96%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;   
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class ManagerOutstandingTask extends React.Component
{
    state = { ready: false, outstandingTasks: [], rowchecked: true, sendOutstandingTask: {},}

    constructor(props) {
		super(props);
    }
    componentDidMount = () => {
        let thisTaskChildren = Store.getStoreData('currentFollowupTask');
        
        let newthisTaskChildren = [];
        thisTaskChildren.forEach((item) => {
            if(item.cur_lane === "COMP_RCM_FOLLOWUP_ASSIGNMENT"){
                item.select = true;
                newthisTaskChildren.push(item);
                let val = item.id
                this.state.sendOutstandingTask[item.cur_assigned_to]= val 
            }
        })
        console.log("Sourav", newthisTaskChildren)
       this.setState({ready:true, outstandingTasks: newthisTaskChildren})
    }

    closeFollowupModal = (event) => {
        this.props.closeModal();
    }

    selectClientAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let outstandingTasksAll = this.state.outstandingTasks; 
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            outstandingTasksAll.forEach((item) => {                
                    item.select = true;
                    selectArray.push(item);
                    let val = item.id
                    this.state.sendOutstandingTask[item.cur_assigned_to]= val  
            })
            this.setState({rowchecked: rowchecked,outstandingTasks: selectArray, sendOutstandingTask: this.state.sendOutstandingTask})
       }else{
            let i = 0;            
            let selectArray = [];
            outstandingTasksAll.forEach((item) => {                
                    item.select = false;
                    selectArray.push(item);  
                    delete this.state.sendOutstandingTask[item.cur_assigned_to.toString()];               
            })
            this.setState({rowchecked: rowchecked,outstandingTasks: selectArray, sendOutstandingTask: this.state.sendOutstandingTask})
       }
   }

   selectClient = (event) => {        
        let index = event.target.value;        
        var outstandingTasksAll = this.state.outstandingTasks;        
            
        if(outstandingTasksAll[index].select){
            outstandingTasksAll[index].select = false;            
            delete this.state.sendOutstandingTask[outstandingTasksAll[index].cur_assigned_to.toString()];                    
        } else {
            outstandingTasksAll[index].select = true;            
            let userid = outstandingTasksAll[index].cur_assigned_to.toString();
            let val = outstandingTasksAll[index].id;            
            this.state.sendOutstandingTask[userid] = val;                            
        }
        this.setState({outstandingTasks: outstandingTasksAll,sendOutstandingTask: this.state.sendOutstandingTask,rowchecked: false})
    }

    processMyoutstandingTasksData = () => {            
        var clientList = this.state.outstandingTasks;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectClientAll}/></div>, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectClient}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Username', accessor: 'username', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Status', accessor: 'status', width: 180, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                    {Header: 'Task Name', accessor: 'task_name', minWidth: 100, headerStyle: {textAlign: 'left'}}];         
                      
        for(let d of clientList) {           
            i++;  
            let objectData = JSON.parse(d.task_json).object_data;
            let documentname = objectData.documentName;
            let contacts = Store.getStoreData('contacts');
            let contact_name = "None";
            for(let i = 0; i < contacts.length; i++)
            {
                if(contacts[i].ID == parseInt(d.cur_assigned_to)){
                    contact_name = contacts[i].ContactName; 
                    break;
                }
            }
            let status = "Pending";
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            var day_str = dd > 10 ? dd.toString() : "0"+dd.toString();
            var month_str = mm > 10 ? mm.toString() : "0"+mm.toString();
            var str_due_date = day_str + "/"+ month_str +"/"+yyyy.toString();

            var due_date_status = new Date(yyyy, mm-1, dd);

            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }              
            let elem = {'index' : i,
                    'id': d.cur_assigned_to, 
                    'username' : contact_name,
                    'select' : d.select,
                    'status' : {text: text, color: color}, 
                    'task_name' : documentname                   
                    };
            ret.data.push(elem);
        
        }
        
        return ret;
    }

    sendOutstandingTask = (event) => {
        event.preventDefault();
        console.log("sendOutstandingTask",this.state.sendOutstandingTask)
        let resendData = [];
        for(let i=0;i<Object.keys(this.state.sendOutstandingTask).length;i++){
            let resendobject = {};
            resendobject.user_id = parseInt(Object.keys(this.state.sendOutstandingTask)[i])
            resendobject.task_id = parseInt(this.state.sendOutstandingTask[Object.keys(this.state.sendOutstandingTask)[i]])
            resendData.push(resendobject);            
        }
        console.log("sendOuts",resendData)
        
        let postData = {command: "resend_email_to_all_share_user", resendData: resendData};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);
    }
    afterresendCompleted = (result) => {        
        alert('Resend mail successfully.');
        this.props.closeModal();
    }

    render() {
        if(!this.state.ready){
            return(<div>Loading..</div>)
        } 
              
        return(<RegModalContainer>
                <RegModalHeaderText>All Outstanding Tasks</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeFollowupModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select which user's task(s) you would like to send reminder e-mails to.</div> 
                    <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                {
                    (() => {
                        if(this.state.outstandingTasks.length !== 0){
                            return(                                           
                                    <CSLTable add={false} processData={this.processMyoutstandingTasksData} headerText={''} tableRows={10}/>
                                    // <div></div>
                            )
                        }else{
                            return(<div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Task(s) Available.</div>)
                        }
                    })()
                } 
                </OverviewInfoBody> 
                                  
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeFollowupModal}>Cancel</RegCancelButton>
                    {
                        (() => {
                            if(this.state.outstandingTasks.length !== 0){
                                return(<RegSubmitButton onClick={this.sendOutstandingTask}>Submit</RegSubmitButton>)
                            }
                        })()
                    }                    
                </RegCancelSubmitButtonOuterContainer>                
            </RegModalContainer>)
    }
}
export default ManagerOutstandingTask;