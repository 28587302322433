let filters = {
	status: {name: 'status', 'nickname': 'Status', type: 'multi', options: {},selections: {}, size: 2},
	task: {name: 'task', 'nickname': 'Task', type: 'text', options: {},selections: {}, size: 2},
	assigned_to_text: {name: 'assigned_to_text', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
	created_by: {name: 'created_by', 'nickname': 'Created By', type: 'multi', options: {},selections: {}, size: 2},
	task_type: {name: 'task_type', 'nickname': 'Source', type: 'single', options: {},selections: {}, size: 1},
	due_date: {name: 'due_date', 'nickname': 'Date', type: 'date_range', options: {},selections: {}, size: 2},
	due_month: {name: 'due_month', 'nickname': 'Month', type: 'multi', options: {},selections: {}, size: 2},
	company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2}
}

module.exports = filters