import Store from './Store.js';
import BaseStore from './../../../../../Common/Store';
import { trackPromise } from 'react-promise-tracker';
import Utils from '../Common/Utils';
import moment from 'moment';
import http from 'mcc-front-aux/dist/httpCommon'

const cache_commands = { contactlist: 1}
const cache_renew_time = 15 * 60 * 60

class APIContact {
    constructor() {
        this.url = `${BaseStore.getStoreData('base_api_url')}/microservice/userstorage/api/command`;
        this.is_admin = Store.getStoreData('is_admin');
        this.is_debug = Store.getStoreData('is_debug');
        this.module_name = 'userstorage';
    }

    command(postData, callback, incremental_callback) {
        postData.env = "select";

        console.log("postData==>", Store.getStoreData('is_admin'))
        let now_time = moment().unix()
        let module_name = 'userstorage'
        let command = postData.command
        let command_id = `${module_name}-${command}`
        let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
        let incremental = (typeof incremental_callback !== 'undefined') ? true : false
        jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
        jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
        jsonDataString = jsonDataString.replace(/\n/g, '');
        jsonDataString = jsonDataString.replace(/\r/g, '');
        if(command in cache_commands) {
            console.log("command_id==>", command_id)
            let cached_result = Store.getStoreData(command_id)
            // console.log('cached_result_parsed', JSON.parse(cached_result))
            let cached_time = Store.getStoreData(command_id + '-last_cached')
            //console.log('cached_time, now_time, now_time - cached_time, cache_renew_time', cached_time, now_time, now_time - cached_time, cache_renew_time)
            if(cached_time !== null && cached_result !== null && now_time - cached_time < cache_renew_time) {
                console.log('cached_result', cached_result)
                postData.incremental = 'incremental' in postData ? postData.incremental : incremental;
                try {
                    let str_cached_result = JSON.stringify(cached_result);
                    if(cached_result.error_code === 0)callback(cached_result);
                } catch (err) {
                    console.log(err);
                }
            }
        }
        http.post(this.url, {jsondata: jsonDataString}).then(result => {
            result = result.data.body
            result = result.replace(/(--plus--)/g, "+");
            result = result.replace(/(--quote--)/g, "'");
            result = result.replace(/(--and--)/g, "&");
            result = result.replace(/(&rsquo;)/g, "'");
            result = result.replace(/'+/g,"'");
            result = JSON.parse(result);
            if(command in cache_commands){
                console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(result).length/1024));
                Store.updateStore(command_id, result)
                Store.updateStore(command_id + '-last_cached', now_time)
            }
            if(incremental){
                incremental_callback(result);
            } else {
                callback(result);
            }

        }).catch(error => {
            console.log(error);
            return(null)
        });

    //     trackPromise(fetch(this.url, httpVerb)
    //         .then(res => res.json())
    //         .then(result => {
    //             result = JSON.stringify(result);
    //             result = result.replace(/(--plus--)/g, "+");
    //             result = result.replace(/(--quote--)/g, "'");
    //             result = result.replace(/(--and--)/g, "&");
    //             result = result.replace(/(&rsquo;)/g, "'");
    //             result = result.replace(/'+/g,"'");
    //             result = JSON.parse(result);
    //             console.log("APIContact result,",JSON.parse(JSON.stringify(result)));
    //             if(command in cache_commands){
    //                 console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(result).length/1024));
    //                 Store.updateStore(command_id, result)
    //                 Store.updateStore(command_id + '-last_cached', now_time)
    //             }
    //             if(incremental){
    //                 incremental_callback(result);
    //             } else {
    //                 callback(result);
    //             }
    //
    //         }).catch(error => {
    //             console.log(error);
    //             return(null)
    //         }));
     }

    async commandWithoutCallback(postData, callback) {
        postData.env = "select";

        let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
        jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
        jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
        jsonDataString = jsonDataString.replace(/\n/g, '');
        jsonDataString = jsonDataString.replace(/\r/g, '');

            http.post(this.url, {jsondata: jsonDataString}).then(result => {
                result = result.data.body

                result = result.replace(/(--plus--)/g, "+");
                result = result.replace(/(--quote--)/g, "'");
                result = result.replace(/(--and--)/g, "&");
                result = result.replace(/(&rsquo;)/g, "'");
                result = result.replace(/'+/g,"'");
                result = JSON.parse(result);
                callback(result);
            }).catch(error => {
                console.log(error);
                return(null)
            });

        // trackPromise(fetch(this.url, httpVerb)
        //     .then(res => res.json())
        //     .then(result => {
        //         result = JSON.stringify(result);
        //         result = result.replace(/(--plus--)/g, "+");
        //         result = result.replace(/(--quote--)/g, "'");
        //         result = result.replace(/(--and--)/g, "&");
        //         result = result.replace(/(&rsquo;)/g, "'");
        //         result = result.replace(/'+/g,"'");
        //         result = JSON.parse(result);
        //         callback(result);
        //     }).catch(error => {
        //         console.log(error);
        //         return(null)
        //     }));
    }
}

export default APIContact;
