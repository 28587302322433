import React from 'react';
import styled from 'styled-components';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
class EnableScheme extends React.Component
{
	state = {general: null};

	componentDidMount()
	{
		this.setState({general: this.props.general});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({general: this.props.general});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}


	render()
	{

		if (this.state.general === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<EmailContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Enable Task Schemes?</EmailBoldLabel>
					{
						(() => {
							if (this.state.general.enabled_task_scheme) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('enabled_task_scheme')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('enabled_task_scheme')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
			</EmailContainer>
		);
	}
}

export default EnableScheme;