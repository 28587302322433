import React from 'react';
import styled from 'styled-components';
import Store from './src/Common/Store.js';
import BaseStore from './../../../Common/Store.js';
import APICall from './src/Common/TAPICall.js';
import APIContact from './src/Common/APIContact.js';
import ComDecom from './src/Common/ComDecom.js';
import Utils from './src/Common/Utils.js';
//import Index from './Components/Index';
import CMPUtils from './src/Components/Common/CMPUtils';
import CSLLoader from './src/Components/Common/CSLLoaderNonPromise'
//import ModuleConfigHOC from './src/Components/ModuleConfigHOC';
import moment from 'moment';
import AlertBox from './src/Components/Common/AlertBox';
import CompleteModal from "./src/Components/Modals/CompleteModal";
import CompleteActionModal from "./src/Components/Modals/CompleteActionModal";
import AddActions from './src/Components/Modals/AddActions';
import TmViewStore from './../../../Common/Store';

const start_time = moment().seconds();

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;

const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 34px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #282828;
`;

class CmpTmViewHOC extends React.Component {
    state = {ready: false, task_loading: false , risk_areas:[], tasks:[] , followup_tasks:[] , testing_activities_master:[] , module_configs : {} , compliance_plan : {} , alert_param : null ,  ex_time : moment().unix() , task_id : 0 , prop_task_id : 0 , modal_view : null , is_loader : true,actionModalOpen : false,action_extra_json : {},parent_ref_id: '',parent_task_id: 0};

	role_global = 'no_access';

    //constructor(props) {
    //  super(props);
      //let utils = new Utils();
      //utils.setEnv();
	  //Store.updateStore('module_name', 'cmp');
	  //this.callContact();

    //}
    setEnv(module_name) {
        let api_url = `${BaseStore.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;
        Store.updateStore('module_name',module_name);
		Store.updateStore('api_url',api_url);
		Store.updateStore('is_admin',false);
		Store.updateStore('is_debug',false);

        //let postData = { command: "taskindex" };
        //let api = new TAPICall();
        //api.command(postData, this.processtaskIndex);
    }
    componentDidMount() {
		//console.log("cmp contactlist,",localStorage.getItem("contactlist"));
		this.setEnv('cmp');
		this.processContactData();
		/*setTimeout(() => {
			this.callApi();
		}, 200);*/

    }
	callContact() {
		if(!Store.getStoreData('is_admin')) {
		  let windowUrl = window.location.href;
		  if(windowUrl.indexOf('register') === -1){
			let postData = { command: "contactlist"};
			let api = new APIContact();
			let j = api.command(postData, this.processContactData);
		  }
		}
	}
   processContactData = () => {
	    /*console.log("processContactData,",result);
		let comdecom = new ComDecom();
		let cl = result.result['contactlist'];
		if('fields' in cl && 'rows' in cl)cl = comdecom.deCompress(result.result['contactlist'], true, 'ID');
		//result.result['contactlist'] = cl;
		let user_roles = result.result.user_roles.cmp;
		*/
		let cl = JSON.parse(localStorage.getItem("contactlist")).result.contactlist;
		//console.log("contactlist,",cl);
		let user_roles = JSON.parse(localStorage.getItem("contactlist")).result.user_roles.cmp;
		let contactist_assoc = cl;
		let contact_list = {};
		let listcontacts = [];
		let contactrole = [];
		let company_users = {};
		Object.keys(contactist_assoc).forEach((uid) => {
			if(uid in user_roles){
				contact_list[uid.toString()] = contactist_assoc[uid.toString()].ContactName;
				listcontacts.push({ID : parseInt(uid) , ContactName : contactist_assoc[uid.toString()].ContactName });
				company_users[uid.toString()] = {ID : parseInt(uid) , ContactName : contactist_assoc[uid.toString()].ContactName , email : contactist_assoc[uid.toString()].EmailAddress , role : user_roles[uid.toString()]};
				contactrole.push({user_id : parseInt(uid) , role : user_roles[uid.toString()]});
			}

		});

		Store.updateStore('contacts', listcontacts);
		Store.updateStore('contact_list', contact_list);
		Store.updateStore('users', company_users);
		Store.updateStore('contactrole', contactrole);
		//Store.updateStore('company_users', company_users);
		this.callApi();

   }
	callApi(){
          //let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
          let last_action_time = 0;
		  let postData = { command: "get_task_tmview" , task_id: this.props.task_id , parent_task_id: this.props.parent_task_id , last_action_time : last_action_time};
		  let api = new APICall();
		  let that = this;
          api.command(postData, function callback(result){that.processIndex(result , last_action_time )})
	}

	processIndex = (result , last_action_time_sent) => {
        console.log("result:", result);
		let comDecom = new ComDecom();
		let cmputils = new CMPUtils();
        const is_admin = Store.getStoreData('is_admin');
        //let risk_areas = [];
		let testing_activities_master = [];
		let module_configs = {};
		let tasks = [];
		let followup_tasks = [];
		let compliance_plans = {};
		let parent_has_group_companies = false;

        if (is_admin) {
            console.log("csl");
        } else {
            console.log("select",result);
			Store.updateStore('role', result.index.result.role);
			const users = Store.getStoreData('users');
			if(result.index.result.role !== 'no_access'){
                    //const start_time = Date.now();
					let gc_companies = [];
					let loggedin_user_info = {};
					if(last_action_time_sent === 0){
						gc_companies = result.index.result.gc_companies;
						loggedin_user_info = result.index.result.loggedin_user_info;
					}else{
						gc_companies = Store.getStoreData('cmp-gc_companies');
						loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
					}
					Store.updateStore('cmp-last_action_time', result.index.result.last_action_time );

					gc_companies.forEach((gc) => {
						Object.keys(gc.users).forEach((uid) => {
							gc.users[uid.toString()] = users[uid.toString()];
						});
					});
					const parent_company = gc_companies.find(gc => gc.is_gc === false);
					//console.log("parent_company",parent_company);
					let gc_assoc = {};

					loggedin_user_info.member_of_gc_list = {};
					gc_companies.forEach((gc) => {
						gc_assoc[gc.id] = gc;
						if(gc.is_gc)loggedin_user_info.member_of_gc_list[gc.id] = gc; // only consider group companies , not the parent company
						if(gc.is_gc)parent_has_group_companies = true;
					});
					let member_group_comp_ids = [];
					let user_wise_gcs = {};
					Object.keys(loggedin_user_info.member_of_gc_list).forEach(grc_id => {
						member_group_comp_ids.push(grc_id);
						Object.keys(loggedin_user_info.member_of_gc_list[grc_id].users).forEach(contact_id => {
							user_wise_gcs[contact_id] = (contact_id in user_wise_gcs) ? user_wise_gcs[contact_id] : {};
							user_wise_gcs[contact_id][grc_id] =  gc_assoc[grc_id].users ;
						});
					});
					console.log("gc_assoc:", gc_assoc);
					let admin_managers = {};
					let plan_admins = {};
					let risk_owners = {};
					let subcat_owners = {};
					let teams = {};
					let company_users = Store.getStoreData('users');
					for (let key in company_users) {
						if (company_users[key.toString()].role === "admin_manager") {
							admin_managers[key.toString()] = company_users[key.toString()];
						}
					}
					for (let key in company_users) {
						if (company_users[key.toString()].role === "plan_admin") {
							plan_admins[key.toString()] = company_users[key.toString()];
						}
					}
					for (let key in company_users) {
						if (company_users[key.toString()].role === "risk_owner") {
							risk_owners[key.toString()] = company_users[key.toString()];
						}
					}
					for (let key in company_users) {
						if (company_users[key.toString()].role === "subcat_owner") {
							subcat_owners[key.toString()] = company_users[key.toString()];
						}
					}
					for (let key in company_users) {
						if (company_users[key.toString()].role === "team") {
							teams[key.toString()] = company_users[key.toString()];
						}
					}
					console.log("admin_managers:", admin_managers);
					for (let key in gc_assoc) {
						for (let akey in admin_managers) {
							if (cmputils.hasJsonStructure(gc_assoc[key.toString()].users) && akey.toString() in gc_assoc[key.toString()].users === false) {
								gc_assoc[key.toString()].users[akey.toString()] = admin_managers[akey.toString()];
							}
						}
					}
					if(parent_has_group_companies === false){
						Object.keys(gc_assoc).forEach(gc_id => {
							gc_assoc['0'] = gc_assoc[gc_id.toString()];
							delete gc_assoc[gc_id.toString()];
						});
					}
					let assurance_partners_raw = 'assurance_partners' in result.index.result? result.index.result.assurance_partners : []
					let assurance_partners = {}
					for(let ap of assurance_partners_raw) {
						if(ap.status === 'Active') assurance_partners[ap.id] = ap
					}

					let followup_tasks = comDecom.deCompress(result.index.result.testing_activities.my_followup_tasks);
					if(comDecom.deCompress(result.index.result.testing_activities.my_tasks).length > 0)cmputils.generate_tasks_ref_assoc(comDecom.deCompress(result.index.result.testing_activities.my_tasks));

					Store.updateStore('cmp-last_action_time', result.index.result.last_action_time );
					cmputils.updateMyActualTasks(comDecom.deCompress(result.index.result.testing_activities.my_tasks));
					//cmputils.updateFollowupTasks(comDecom.deCompress(result.index.result.testing_activities.my_followup_tasks));
					cmputils.updateCompliancePlans(comDecom.deCompress(result.index.result.compliance_plans));

					Store.updateStore('cmp-gc_companies', gc_companies );
					Store.updateStore('cmp-loggedin_user_info', loggedin_user_info );


					Store.updateStore('current_gcid', 0);
					Store.updateStore('parent_company', parent_company);
					//Store.updateStore('users', result.result.company_users);
					//Store.updateStore('loggedin_user_info', loggedin_user_info);
					Store.updateStore('parent_has_group_companies', parent_has_group_companies);
					Store.updateStore('gc_companies', gc_companies);
					//Store.updateStore('admin_managers', admin_managers);
					//Store.updateStore('plan_admins', plan_admins);
					//Store.updateStore('risk_owners', risk_owners);
					//Store.updateStore('subcat_owners', subcat_owners);
					//Store.updateStore('teams', teams);
					Store.updateStore('gc_assoc', gc_assoc);
					Store.updateStore('member_group_comp_ids', member_group_comp_ids);
					Store.updateStore('user_wise_gcs', user_wise_gcs);
					//Store.updateStore('contacts', result.result.contacts.result.listcontacts);
					//Store.updateStore('contact_list', result.result.contacts.result.contacts);
					//Store.updateStore('contactrole', result.result.contactrole.result.contactrole);
					//Store.updateStore('assurance_partners', assurance_partners);
					//Store.updateStore('risk_area_bounding_client_rect',{});
					//Store.updateStore('subcat_bounding_client_rect',{});
					//console.log("gc_assoc:", gc_assoc);
					//console.log("parent_company:", parent_company);
					//risk_areas = result.result.risk_areas.length === 0 ? [] : JSON.parse(result.result.risk_areas.config_json);
					//testing_activities_master = result.result.testing_activities_master.length === 0 ? [] : JSON.parse(result.result.testing_activities_master.config_json);
					module_configs = JSON.parse(result.index.result.module_configs.config_json);

                    //console.log("I am here");
					tasks = this.generate_tasks();

                    let end_time = moment().seconds();
					let execution_time = end_time - start_time;
					//execution_time = execution_time / 1000;
					//, tasks,followup_tasks,testing_activities_master,module_configs,this.prop.task_id,this.props.ref_id
					console.log("execution time of Index in seconds:", start_time , end_time ,execution_time , tasks , Store.getStoreData('original_risk_areas_pre'));

                    let state_json = {ready: true, risk_areas : [], tasks : tasks , followup_tasks : followup_tasks , testing_activities_master : testing_activities_master , module_configs : module_configs , task_id: this.props.task_id , ref_id: this.props.ref_id ,original_risk_areas : Store.getStoreData('original_risk_areas_pre')};

					let ref_id = (result.result.task.parenttask.task_type === 'COMP_CMP') ? this.props.ref_id : JSON.parse(result.parent_task_json).task_data.ref_id;

					this.openCompleteModal(result, ref_id, state_json);

					//this.setState({ready: true, risk_areas : [], tasks : tasks , followup_tasks : followup_tasks , testing_activities_master : testing_activities_master , module_configs : module_configs , task_id: this.props.task_id , ref_id: this.props.ref_id });
			}else{
					//Store.updateStore('loggedin_user_info', 'Guest');

					Store.updateStore('role', result.index.result.role);
					let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
						alertHandler: this.alertHandler, stack: {}};
					this.setState({ ready: true, alert_param: alert_param});
			}



			//console.log("followup_tasks 1==>",followup_tasks);
        }
		//console.log("module_configs==>",module_configs);

    }
    generate_tasks = () => {
				let compliance_plans_ids = [];
				let plan_names = [];
				let tasks_without_filter = [];
				let cmputils = new CMPUtils();

				let original_risk_areas_pre_temp = [];
				let gc_companies = Store.getStoreData('gc_companies')
				let compliance_plans = Store.getStoreData('cmp-compliance_plans');
				let gc_assoc = Store.getStoreData('gc_assoc');
				let parent_company = Store.getStoreData('parent_company');
				let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
				let my_actual_tasks = Store.getStoreData('cmp-my_actual_tasks');
				let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
				let role = Store.getStoreData('role');

				//console.log("generate_tasks ,",compliance_plans,gc_assoc);

				if(compliance_plans.length > 0){
					let compliance_plan_assoc = {};
					for(let j=0;j<compliance_plans.length;j++){
						let plan_json = JSON.parse(compliance_plans[j].plan_json);
						plan_names.push({
							name: plan_json.plan_name,
							id: compliance_plans[j].id,
							sort_field: plan_json.plan_name,
						});
						//console.log('LeftPanelCompliancePlan compliance_plan', compliance_plans[j])
						let plan_ref = compliance_plans[j].plan_ref_id.split('_')[1]
                        compliance_plans_ids.push(compliance_plans[j].id);
						plan_json.risk_areas.forEach((item) => {

							item.plan_id = compliance_plans[j].id;
							item.gc_id = compliance_plans[j].gc_id;
							item.plan_ref = plan_ref
							item.sort_field = item.gc_id.toString() !== '0' ? item.risk_area_name + '-' + gc_assoc[item.gc_id.toString()].company_name :  item.risk_area_name + '-' + parent_company.company_name
							let subcategories = [];
							item.subcategories.forEach((subcat_item) => {
								subcat_item.plan_id = compliance_plans[j].id;
								subcat_item.gc_id = compliance_plans[j].gc_id;
								subcat_item.plan_ref = plan_ref;
								subcategories.push(subcat_item);
							});
							item.subcategories = subcategories;
							original_risk_areas_pre_temp.push(item);
						});
						//console.log('LeftPanelCompliancePlan compliance_plan', original_risk_areas_pre_temp)

						// selected_plan_id = compliance_plans[0].id;
						//console.log('MGRIndex original_risk_areas_pre', original_risk_areas_pre_temp)

						let plan_ref_id = compliance_plans[j].plan_ref_id;
						compliance_plan_assoc[plan_ref_id] = {id : compliance_plans[j].id};
						//let hint_task_json = {};
						//console.log("plan_json,compliance_plans[j].id,my_actual_tasks,compliance_plans[j].plan_ref_id=>",plan_json,compliance_plans[j].id,[],compliance_plans[j].plan_ref_id);
						let ret_from_hint_task =  cmputils.processHintTmView(plan_json,compliance_plans[j].id,my_actual_tasks,compliance_plans[j].plan_ref_id);
						//console.log("ret_from_hint_task=>",ret_from_hint_task);
						ret_from_hint_task.new_parent_tasks_list.forEach((item) => {
							tasks_without_filter.push(item);
						});

					}
					//console.log("generate_tasks ,",original_risk_areas_pre_temp);
				}
				let original_risk_areas_pre = original_risk_areas_pre_temp;
				Store.updateStore('original_risk_areas_pre', original_risk_areas_pre);
				return tasks_without_filter;
    }

  	openCompleteModal =(result, ref_id, state_json)=>{

      console.log("openCompleteModal,",result , ref_id , state_json);
	  let plan_id = ref_id.split('-')[0];
	  let compliance_plans = Store.getStoreData('cmp-compliance_plans');
	  let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
	  let role = Store.getStoreData('role');
	  let company_id = ref_id.split('-')[6];
	  let plan_json  = {};
	  let testing_owner = 0;
	  for(let i=0;i<compliance_plans.length;i++){
		  if(compliance_plans[i].id === parseInt(plan_id)){
			  plan_json = JSON.parse(compliance_plans[i].plan_json);
			  break;
		  }
	  }
	  if('testing_activities' in plan_json){
		  for(let p of plan_json.testing_activities){
			  if(ref_id.split('-')[2].toString() === p.unique_risk_id.toString() && ref_id.split('-')[3].toString() === p.unique_subcat_id.toString() && ref_id.split('-')[4].toString() === p.unique_testing_act_id){
				  testing_owner = parseInt(p.testing_owner);
				  break;
			  }
		  }
	  }
console.log("company_id,",company_id,parseInt(loggedin_user_info.ClientCompanyID));
	  let error_code = 0;
	  if(parseInt(company_id) > 0){
		  if(parseInt(company_id) !== parseInt(loggedin_user_info.ClientCompanyID)){
              error_code = 2001;
		  }else{
              if(role === 'no_access'){
                  error_code = 2001;
              }
              if((role === 'risk_owner' || role === 'team') && parseInt(testing_owner) !== parseInt(loggedin_user_info.ID)){
                  error_code = 2001;
              }
		  }
	  }else{
          error_code = 2001;
	  }

	  if( error_code === 2001){
          let alert_param = {title: 'No Access', message: 'You are not authorised to access this testing activity. If you think this is in error, please contact your administrator.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.taskAlertHandler, stack: {} };
		  this.setState({alert_param : alert_param});
	  }else{

		  this.process_openCompleteModal(result,ref_id,state_json);
		  //let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		  //if(tasks_ref_assoc !== null && typeof tasks_ref_assoc[ref_id.toString()] !== 'undefined'){
			  //let postData = {"command": "get_task", "task_id": this.props.task_id};
			  //let api = new APICall();
			  //let that = this;
			  //api.command(postData, function callback(result){that.process_openCompleteModal(result,ref_id,state_json)});

		  /*}else{
				this.setState({
					modal_view : 'openCompleteModal' ,
					ref_id : ref_id ,
				});
		  }*/
	  }

  	}
	process_openCompleteModal= (result,ref_id,state_json) =>{
	  console.log("process_openCompleteModal task type==>",result.result.task.parenttask.task_type);
	  //console.log("process_openCompleteModal ==>",result);
	  if(result.result.task.parenttask.task_type === 'COMP_CMP'){
		  let task_json = JSON.parse(result.result.task.parenttask.task_json);
		  let task_id = task_json.object_data.ref_id.split("-")[1];
		  console.log("process_openCompleteModal task_id==>",task_id , task_json.object_data);
		  state_json.modal_view = 'openCompleteModal';
		  state_json.my_task = result.result.task.parenttask;
		  state_json.child_tasks = result.result.task.childtask;
		  state_json.is_loader = false;
		  //state_json.original_risk_areas = Store.getStoreDate('original_risk_areas_pre');
		  this.setState(state_json);
	  }
	  if(result.result.task.parenttask.task_type === 'COMP_CMP_FOLLOWUP'){
		  console.log("openActionCompleteModal task_id==>");
		  state_json.modal_view = 'openActionCompleteModal';
		  state_json.my_task = result.result.task.parenttask;
		  state_json.task_id = this.props.task_id;
		  let p_tsk = {};
		  p_tsk.task_json = result.parent_task_json;
		  state_json.my_parent_task = p_tsk;
		  state_json.is_loader = false;
		  if(result.result.task.parenttask.cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
			state_json.actionModalOpen = true;
			let parent_task_json_parsed = JSON.parse(result.parent_task_json);
			let task_json_parsed = JSON.parse(result.result.task.parenttask.task_json);
			let action_json = task_json_parsed.object_data.headerinfo.risk_json.actions;
			let jsonObj_pre = {action_title : action_json.action_title,action_description : action_json.action_description,action_assignto : action_json.action_assignto, due_date : action_json.due_date, followupBinFiles : [] , showModal : true , view_mode : true , task_id : this.props.task_id , parent_task_id : this.props.parent_task_id , parent_ref_id : parent_task_json_parsed.task_data.ref_id};
			let jsonObj = this.processViewAction(jsonObj_pre,result);
			state_json.action_extra_json = jsonObj;
			state_json.parent_ref_id = jsonObj.parent_ref_id;
			state_json.parent_task_id = jsonObj.parent_task_id;
			//this.setState({actionModalOpen: true , action_extra_json : jsonObj , parent_ref_id : jsonObj.parent_ref_id , parent_task_id : jsonObj.parent_task_id ,is_loader: false});
		  }
		  //state_json.original_risk_areas = Store.getStoreDate('original_risk_areas_pre');
		  this.setState(state_json);
	  }
	}
	process_openActionCompleteModal= (result , parent_task_id) =>{
	  console.log("openActionCompleteModal result ==>",result);
	  //console.log("task_json of current task ==>"+result.result.task.parenttask.task_json);
	  //this.setState({modal_view: 'openCompleteModal', task_id: result.result.task_id, my_task: result.result.task.parenttask.task_json});
	  let p_tsk = {};
	  p_tsk.task_json = result.parent_task_json;
	  this.setState({is_loader : false , modal_view: 'openActionCompleteModal',task_id: result.result.task_id, my_task: result.result.task.parenttask , parent_task_id , my_parent_task: p_tsk});

	}
	alertHandler = (result, stack) => {
	  this.setState({alert_param: null})
	  localStorage.removeItem('cmp-index');
	  localStorage.removeItem('cmp-get_mgr_index');
	  window.location = '/select/ServiceBasedLanding';
	}
  closeUserDialog = (json_status) => {
    this.setState({ready: false , is_loader: false , modal_view: null,actionModalOpen: false , action_extra_json : {}});
    (typeof json_status !== 'undefined') ? this.props.closeUserDialog(json_status) : this.props.closeUserDialog();
  }
  processDeletedAction = (jsonobj) => {
	  let cmputils = new CMPUtils();
	  let followup_tasks = Store.getStoreData('cmp-followup_tasks');
      //console.log("processDeletedAction followup_tasks",followup_tasks);
	  let non_deleted_followup_tasks = followup_tasks.filter(item => item.id.toString() !== jsonobj.task_id.toString());

	  //Store.updateStore('cmp-followup_tasks', non_deleted_followup_tasks);
      let followup_actions = cmputils.processFollowupActions(non_deleted_followup_tasks);

	  this.setState({followup_actions: followup_actions});
  }
  processSaveAction = (jsonobj) => {
	  let cmputils = new CMPUtils();
	  let followup_tasks = Store.getStoreData('cmp-followup_tasks');

	  console.log("processSaveAction jsonobj,",jsonobj);
      let updated_followup_tasks = [];

	  followup_tasks.forEach((item) => {
		  if(item.id.toString() === jsonobj.task_id.toString()){
              item.task_json = jsonobj.task_json;
			  item.cur_assigned_to = parseInt(jsonobj.action_obj.action_assignto);
		  }
		  updated_followup_tasks.push(item);
	  });

      //cmputils.updateFollowupTasks(updated_followup_tasks);
      //Store.updateStore('followup_tasks',updated_followup_tasks);
      let followup_actions = cmputils.processFollowupActions(updated_followup_tasks);
	  //console.log("processSaveAction jsonobj,",updated_followup_tasks,followup_actions);
	  this.setState({followup_actions: followup_actions});
  }
  saveTestingActivities = (data) =>{
    this.setState({is_loader: true});
    let task_data = data[0];
    let extra_json = data[1];
    let followup_tasks_to_be_created = data[2];
    let ref_id = data[3];
    let model_bin_file_id = data[4];

    let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
	let that = this;
    let postData = {"command": "updateTask", "task_id" : ('id' in task_data) ? task_data.id : 0, "task_json" :task_data.task_json , "extra_json" : extra_json , ref_id : ref_id , followup_tasks_to_be_created : followup_tasks_to_be_created , model_bin_file_id : model_bin_file_id , last_action_time : last_action_time};
    let api = new APICall();
    //console.log(postData);
    api.command(postData, that.processSaveTestingActivities);


  }
  processSaveTestingActivities = (result ) =>{
	  console.log("processSaveTestingActivities=>",result);
	  let cmputils = new CMPUtils();
	  cmputils.update_tasks_ref_assoc_for_single_ref_id(result.my_task);

	  let task_id = result.my_task.id;

	  let original_risk_areas = this.state.original_risk_areas;
	  let active_risk_assoc = {};
	  for(let j=0;j<original_risk_areas.length;j++){
		  active_risk_assoc[`${original_risk_areas[j].plan_id}-${original_risk_areas[j].gc_id}-${original_risk_areas[j].unique_risk_id}`] = true;
	  }
	  cmputils.updateFollowupTasks(result.my_followup_tasks);
	  let updated_followup_tasks = [];
	  let followup_tasks = Store.getStoreData('cmp-followup_tasks');

	  for(let ft of followup_tasks) {
		if(active_risk_assoc[`${ft.parent_task_ref_id.split('-')[0]}-${ft.parent_task_ref_id.split('-')[1]}-${ft.parent_task_ref_id.split('-')[2]}`])updated_followup_tasks.push(ft);
	  }
	  //let followup_actions = cmputils.processFollowupActions(updated_followup_tasks);
	  (result.my_task.cur_lane === 'COMP_CMP_COMPLETED') ? this.closeUserDialog(true) : this.closeUserDialog();
  }
	saveAction = (data) =>{
		let task_data = data[0];
		let parent_ref_id = data[1];
		this.setState({is_loader: true});
		let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
		let that = this;
		let postData = {"command": "saveFollowupActionTask", "task_id" : task_data.id, "task_json" :task_data.task_json , "assigned_to" :task_data.assigned_to , "parent_ref_id" : parent_ref_id , last_action_time : last_action_time};
		let api = new APICall();
		//api.command(postData, this.processSaveCompleteAction);
		api.command(postData, function callback(result){let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: that.processSaveCompleteAction, stack: {result : result} }; that.setState({alert_param: alert_param , is_loader: false});});
	}
	processSaveCompleteAction = (result , stack) => {
		let cmputils = new CMPUtils();
		this.setState({alert_param: null});
		//cmputils.updateFollowupTasks(stack.result.my_followup_tasks);
		//let followup_tasks = Store.getStoreData('cmp-followup_tasks');
		//let actions = cmputils.processFollowupActions(followup_tasks);
		this.closeUserDialog(true);
	}
  saveCompleteAction = (data) =>{
	let task_data = data[0];
    this.setState({is_loader: true});
    let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
	let that = this;
    let postData = {"command": "updateFollowupActionTask", "task_id" : task_data.id, "task_json" :task_data.task_json , "assigned_to" :task_data.assigned_to , last_action_time : last_action_time};
    let api = new APICall();
    //api.command(postData, this.processSaveCompleteAction);
	api.command(postData, function callback(result){
		let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: that.processSaveCompleteAction, stack: {result : result} };
		that.setState({alert_param: alert_param , is_loader: false});
	});
  }
	actionModalOpen	= (jsonObj) => {
		if(!jsonObj.status){
			this.closeUserDialog();
		}
	}
	processViewAction = (jsonObj,task_info) => {
		console.log("task_info==>",task_info);
		//console.log("task_json of current followup task ==>"+task_info.result.task.parenttask.task_json);
		let followupBinFiles = [];
        let child_task_json = JSON.parse(task_info.result.task.parenttask.task_json );
		if('bin_files' in  child_task_json.object_data) {
			child_task_json.object_data.bin_files.forEach((item) => {
				item.uid = "uid" in item ? item.uid : Utils.genKey(10);
				followupBinFiles.push(item);
			});
			//actionBinFiles = current_task.object_data.bin_files;
		}
		let action_note_undertaken ="";
		if('result' in  child_task_json.object_data && 'action_note_undertaken' in child_task_json.object_data.result[0]) {
			action_note_undertaken = child_task_json.object_data.result[0].action_note_undertaken;
			//actionBinFiles = current_task.object_data.bin_files;
		}
        jsonObj.followupBinFiles = followupBinFiles;
		jsonObj.action_note_undertaken = action_note_undertaken;

		return jsonObj;


	}
  render() {
    console.log("CmpTmViewHOC state:",this.state);
    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }
    //if(!this.state.ready)return(<div>....</div>)

    return (
      <FolderContainer>
      {
        (() => {
			if(this.state.is_loader) {
				return <div><CSLLoader /></div>
			}
        })()
      }
      {
        (() => {
          if(this.state.modal_view === 'openCompleteModal') {
             //return <TmViewCompleteModal compliance_plans = {this.state.plan}/>
			 return	<CompleteModal
				refreshParent={this.closeUserDialog}
				taskData={this.state.tasks}
				taskId={this.props.task_id}
				saveTestingActivities={this.saveTestingActivities}
				my_task = {this.state.my_task}
				tasks_without_filter = {this.state.tasks}
				prop_task_id={this.state.prop_task_id}
				original_risk_areas={this.state.original_risk_areas}
				child_tasks={this.state.child_tasks}
				ref_id={this.props.ref_id}
				module_configs={this.state.module_configs}
				processDeletedAction={this.processDeletedAction}
				processSaveAction={this.processSaveAction}
				/>;

          }
		  if(this.state.modal_view === 'openActionCompleteModal'){
				if(this.state.actionModalOpen){
					return(<div>
						<AddActions actionModalOpen={this.actionModalOpen} curLane={'COMP_CMP_COMPLETED'} action_extra_json={this.state.action_extra_json} parent_ref_id={this.state.parent_ref_id} parent_task_id={this.state.parent_task_id}  unique_testing_act_id={this.state.unique_testing_act_id} processSaveAction={this.processSaveAction}/>
						</div>)
				}else{
					return <CompleteActionModal
						refreshParent={this.closeUserDialog}
						taskData={this.state.tasks}
						taskId={this.props.parent_task_id}
						saveCompleteAction={this.saveCompleteAction}
						saveAction={this.saveAction}
						my_task = {this.state.my_parent_task}
						tasks_without_filter = {this.props.tasks}
						current_task = {this.state.my_task}
						current_task_Id = {this.props.task_id}
						module_configs={this.state.module_configs}
						prop_task_id={'0'}
					/>;
				}

		  }

        })()
      }
      </FolderContainer>
    );
  }
}

export default CmpTmViewHOC;
