import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosOptions } from "react-icons/io";
import {FiSettings} from "react-icons/fi";
import BMAskBreachModal from './BMAskBreachModal';
import BMBreachApprovalModal from './BMBreachApprovalModal';
import CurrentChildTaskListsModal from './CurrentChildTaskListsModal';
import CSLRegisterTable from '../RDR/CSLRegisterTable';
import reportData from '../RDR/reportData';
import BMViewFollowupModal from './BMViewFollowupModal';
import BMViewRemedialModal from './BMViewRemedialModal';
import BMViewPreventativeModal from './BMViewPreventativeModal';
import SideBar from '../Filter/SideBar';
import AlertBox from '../Common/AlertBox';
import moment from 'moment';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import BMViewIncidentModal from './BMViewIncidentModal';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    //width: 96%;
    margin: 0 auto;
    margin-top: 30px;  
    border-top: 1px solid #d7dddf;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;  
`;
const OverviewInfoBody = styled.div`
	//padding: 15px;
    background-color: #ffffff;
    //border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const BoxStyle = styled.div`
  float: left;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, .2);
  margin-right: 10px;
`;
const ConflictInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: black;
`;
const ConflictSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #414141;
`;
const CSLDateCover = styled.div`
    height: 20px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #282828;
    margin-bottom: 10px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const ExportButtonDisable = styled.button`
    box-sizing: border-box;
    background-color: #213e61a1;
    color: #F2F2F2;
    border: 1px solid #213e61a1;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;    
`;
const HeaderTitle = styled.div`
    float: left;
    color: rgb(84, 84, 84);
    font-weight: 600;
    font-size: 16px;
    font-family: "DMSans-Bold", "DM Sans Bold", "DM Sans", sans-serif;
    font-weight: 700;
    margin-top: 5px;
`;
const SavedStatus = styled.div`
    width: 25px;
    height: 25px;    
    padding: 2px 2px 2px 2px;
    border-radius: 2px;
    border: 1px solid #143151;
    background-color: #143151;
    box-sizing: border-box;
`;
const OthersStatus = styled.div`
    width: 25px;
    height: 25px;    
    padding: 2px 2px 2px 2px;
    border-radius: 2px;
    border: 1px solid #04ADA8;
    background-color: #04ADA8;
    box-sizing: border-box;
`;

class BMBreachList extends React.Component
{
    state = {
        ready: false, 
        show_modal: null,
        breachTaskList: [],
        breachTaskListFilter: [],
        userRole: "",
        curBreachid: 0,  
        updateRegulatorlist: {},  
        updateReasonforbreachlist: {},
        updateBreachimpactlist: {},
        updateDepartmentlist: {},
        sidebar: false,
        alert_param: null,
        fields: [],
        isLoadedIcon: false,
        assignuser: {},
        myfollowupTask: []
    };
    constructor(props) {
        super(props);
    }
    sidebarOpen = false
    sidebarClosed = false
    componentDidMount() {        
        let myBreachTask = Store.getStoreData('mytasks');  
        let module_config = Store.getStoreData('module_config');

        let task_id = this.props.taskId;
        console.log("task_id3===>",task_id)       
        
        let updateReasonforbreachlist = {}
        if('reason_for_Breach' in module_config && module_config.reason_for_Breach.length !== 0){
            module_config.reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    updateReasonforbreachlist[item.reasonforBreachID.toString()] = item.reasonforBreachName
                }
            })
        }  

        let updateBreachimpactlist = {};
        if('breach_impact' in module_config && module_config.breach_impact.length !== 0){
            module_config.breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    updateBreachimpactlist[item.breachImpactID.toString()] = item.breachImpactName
                }
            })
        }
        let updateDepartmentlist = {};
        if('business_areas' in module_config && module_config.business_areas.length !== 0){
            module_config.business_areas.forEach((item) => {
                if(item.isActive === 1){
                    updateDepartmentlist[item.businessAreaID.toString()] = item.businessAreaName
                }
            })
        }     

        let fields = []
        fields.push({accessor: 'status', nickname: 'Status', type: 'alpha', width: 1.2})
        fields.push({accessor: 'breachid', nickname: 'ID', type: 'alpha', width: 2.6})
        fields.push({accessor: 'breach_type', nickname: 'Type', type: 'alpha', width:1.2})
        fields.push({accessor: 'incident_title', nickname: 'Incident', type: 'alpha', width:1.1})
        fields.push({accessor: 'isreportable', nickname: 'Reportable', type: 'alpha', width:2})
        fields.push({accessor: 'no_of_followup', nickname: 'Tasks', type: 'numeric', width:1})
        fields.push({accessor: 'incident_date', nickname: 'Incident Date', type: 'numeric', width:1})
        fields.push({accessor: 'closed_date', nickname: 'Closed Date', type: 'numeric', width:1}) 


        let userrole = Store.getStoreData('role'); 

        let assignuser = Store.getStoreData('task_assign_user');
        let followupTask = Store.getStoreData('followup_tasks');

        this.setState({ breachTaskList: myBreachTask, breachTaskListFilter:myBreachTask, userRole:userrole, updateReasonforbreachlist: updateReasonforbreachlist,
        updateBreachimpactlist: updateBreachimpactlist, updateDepartmentlist: updateDepartmentlist, assignuser, myfollowupTask: followupTask})
        if(task_id > 0) {
            console.log("task id", task_id)
            this.goToTaskUrlBare(task_id)
        }else{
            this.setState({ready: true, fields})
        }
    }

    componentDidUpdate(prevprops) {
        if(this.props === prevprops)return
            let myBreachTask = Store.getStoreData('mytasks');     
            let assignuser = Store.getStoreData('task_assign_user');   
            let followupTask = Store.getStoreData('followup_tasks'); 
            this.setState({ breachTaskList: myBreachTask, breachTaskListFilter:myBreachTask, assignuser, myfollowupTask: followupTask, ready: true })
    }

    goToTaskUrlBare = (taskID) => {    
        let postData = { "command" : "getTaskCallbyTaskID", "task_id" : taskID }
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.processBreachTaskAPIData);       
    }

    processBreachTaskAPIData = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            alert(msg)
            return false;
          }
        let cur_lane = result.result.parenttask.cur_lane;
        let parentID = result.result.parenttask.id;        
        if(cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE' || cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE' || cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
            this.currentChildTaskModal(parentID);
        }else if(cur_lane === 'COMP_BREACH_USERSAVE'){
            this.processBreachSaveTaskData(result);
        }else{
            this.processBreachTaskData(result);
        }        
    } 

    currentChildTaskModal = (task_id) => {
        //event.preventDefault();
        console.log('ROWS', task_id); 
        let postData = { command: "GetSubTaskCallbyTaskID", task_id: task_id};
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.openCurrentChildModal);
    }
    openCurrentChildModal = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            alert(msg)
            return false;
          }
        if('task' in result.result && (result.result.task.subtask.cur_lane === "COMP_BREACH_FOLLOWUP_ASSIGNMENT" || result.result.task.subtask.cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE')){
            Store.updateStore('followup_task', result.result.task);
            this.setState({showModal: 'view_followup_modal',ready: true});
        }else if('task' in result.result && (result.result.task.subtask.cur_lane === "COMP_BREACH_REMEDIAL_ASSIGNMENT" || result.result.task.subtask.cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE')){
            Store.updateStore('followup_task', result.result.task);
            this.setState({showModal: 'view_remedial_modal',ready: true});
        }else if('task' in result.result && (result.result.task.subtask.cur_lane === "COMP_BREACH_PREVENTATIVE_ASSIGNMENT" || result.result.task.subtask.cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE')){
            Store.updateStore('followup_task', result.result.task);
            this.setState({showModal: 'view_preventative_modal',ready: true});
        }        
    }
    
    processMyBreachRegisterValue = () => {
        var mytaskdata = this.state.breachTaskList;
        const has_gc = Store.getStoreData('has_gc');
        //console.log("mytaskdata",mytaskdata)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  
        {'Header' : '', accessor: 'icon', Cell: row => (
            <div>
                {
                    (() => {
                        if(row.original.status === "Saved"){
                            return( <SavedStatus/>)
                        }else{
                            return( <OthersStatus/>)
                        }
                    })()
                }                
                </div>
            ), width: 40, sortable: false,filterable:false, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {padding: "5px 0px 0px 0px"}},     
        {'Header' : 'STATUS', accessor: 'status', Cell: row => (
            <div style={{padding: "1px 4px 4px 4px",fontWeight: "700", color: "#545454"}}>{row.original.status}</div>                        
            ), width: 90, headerStyle: {textAlign: 'left',fontWeight: "600"}},
        {'Header' : 'ID', accessor: 'breachid', width: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},
        {Header: 'TYPE', accessor: 'breach_type', minWidth: 110, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},
        {Header: 'INCIDENT', accessor: 'incident_title', minWidth: 110, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},  
        {Header: 'COMPANY', accessor: 'company_name', minWidth: 110, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}, show: has_gc ? true : false},
        {Header: 'INT/EXT', accessor: 'int_ext', minWidth: 100, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},          
        {Header: 'REPORTABLE', accessor: 'isreportable', minWidth: 80, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},          
        {'Header' : 'Tasks', accessor: 'no_of_followup', Cell: row => (
            (() => {
                if(row.original.no_of_followup === '00'){
                    return(<div style={{textDecoration: "underline",color: "#00B5B2"}} >{row.original.no_of_followup}</div>)
                }else{
                    return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.goToFollowupTask(row)}>{row.original.no_of_followup}</div>)
                }
            })()
        ), minWidth: 60, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center',fontWeight: "700", color: "#545454"}}, 
        {Header: 'INCIDENT DATE', accessor: 'incident_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center',fontWeight: "700", color: "#545454"}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }}, 
        {Header: 'CLOSED DATE', accessor: 'closed_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center',fontWeight: "700", color: "#545454"}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},  
        {'Header' : 'Action', Cell: row => (            
            <div style={{display: "inline-flex"}}>
                <MRButton style={{cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>View</MRButton>
                {
                (() => {
                    if(this.state.userRole === 'manager' || this.state.userRole === 'admin_manager'){
                        return(<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#7d7d7d", fontWeight: "bold"}} onClick={this.updatedeleteBreachTask(row.original.id)}>x</div>)
                    }
                })()
                }                
            </div>                    
        ), width: 120, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}}];

        let gc_assoc = Store.getStoreData('gc_assoc');        
        let gc_company = "";
        let assignuser = this.state.assignuser;
        for(let d of mytaskdata) {           
            i++; 
            gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
            let status = "";

            let numberOfFollowup = 0;
            if(d[3] !== null){              
            //   numberOfFollowup = JSON.parse(d[3]).length;
                if(d[0] in assignuser){
                    numberOfFollowup = assignuser[d[0]].totalNoOfChildTask;
                }
            }

            if(d[2] === "COMP_BREACH_REGISTER") {
                status =  "Registered";  
                if(numberOfFollowup !== 0 && d[0] in assignuser && assignuser[d[0]].totalNoOfPenFollowupTask === 0){
                    status = "Complete"; 
                }               
            }else if(d[2] === "COMP_BREACH_USERSAVE") {
                status = "Saved";                
            }else if(d[2] === "COMP_BREACH_COMPLETE") {
                //status = "Complete";  
                status = "Closed";  
            }else if(d[2] === "COMP_BREACH_REGISTER_ACTION") {
                status = "In Progress";    
                if(numberOfFollowup !== 0 && d[0] in assignuser && assignuser[d[0]].totalNoOfPenFollowupTask === 0){
                    status = "Complete"; 
                }             
            }

            let ref_id = "";
            if(d[7] !== null) {
                ref_id = d[7];
            }

            let breachType = "";
            if(d[8] === 'breach_of_rules'){
                breachType = "Incident";
                if(d[9] !== ''){
                    breachType = d[9];
                }
            }else if(d[8] === 'conduct_rules_breach'){            
                breachType = "Conduct Rules Breach";                
            }        
            
            let internal_external = "-";
            if(d[11] === 0){
                internal_external = 'External';
            }else if(d[11] === 1){
                internal_external = 'Internal';
                if(d[12] !== ''){
                    internal_external = d[12];
                }
            }

            let isReportable = 'Reportable';
            if(d[13] === 0){
                isReportable = 'Not Reportable';
            }
            
            

            let nooffollowup = numberOfFollowup < 10 ? "0"+numberOfFollowup.toString() : numberOfFollowup;  

            let elem = {
                    'id': d[0],
                    'status' : status,
                    'breachid' : ref_id,
                    'breach_type': breachType,
                    'incident_title': d[10] !== '' ? d[10] : '-',
                    'company_name' : gc_company,
                    'int_ext': internal_external,
                    'isreportable': isReportable,
                    'reportable': d[13],
                    'no_of_followup': nooffollowup,
                    'incident_date' : moment.unix(d[14]).format('DD/MM/YYYY'), 
                    'closed_date': d[2] === "COMP_BREACH_COMPLETE" ? moment.unix(d[5]).format('DD/MM/YYYY') : '-'                   
                   };
            ret.data.push(elem); 
            let sarr = [];                       
            ret.data.forEach((item) => {
                if (item.reportable === 1) {
                    sarr.push(item);
                }
            })
            ret.data.forEach((item) => {
                if (item.reportable === 0) {
                    sarr.push(item);
                }
            })
            ret.data = sarr;       
        }        
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }

    
      handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }

    goToFollowupTask = (row) => (event) => {
        event.preventDefault();  
        let command_data = { "command": "listfollowups", parent_id: row.original.id };
        console.log('current_child_lists list command', command_data);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(command_data, this.updateFollowups(row.original.breachid));    
    }    
    updateFollowups = (breachID) => (result) => {
        Store.updateStore('current_childtask_lists', result.result.followuptasks);
        console.log('result.result.current_child_lists', result);
        //this.setState({child_task : result.result });
        this.setState({ showModal: "currentChildtaskList", curBreachid: breachID, isLoadedIcon: false});  
    }

    updatedeleteBreachTask = (taskid) => (event) => {
        event.preventDefault();  
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteBreachTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }

    deleteBreachTask = (result, stack) => {        
        //if (window.confirm('Are you sure you want to delete this record?')) {
            if(!result) {
                this.setState({alert_param: null})
                return
            }
            console.log('ROWS', stack.taskid);        
            let postData = {command: "remove_task", task_id: stack.taskid };
            console.log("postData",postData)         
            this.setState({alert_param: null, isLoadedIcon: true})   
            let api = new APICall();
            api.command(postData, this.processRemoveBreachData);
        //}       
    }

    processRemoveBreachData = (result) => {
        console.log("result", result)
        this.setState({alert_param: null})
        let alert_param = {title: 'Success', message: 'Breach deleted successfully', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateprocessRemoveBreachData, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //alert('Breach deleted successfully');
        //window.location.reload();
    }
    updateprocessRemoveBreachData = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();     
    }

    goToTaskUrl = (row) => (event) => {
        event.preventDefault();         
        this.showBreachRegisterModal(row);            
    }

    showBreachRegisterModal = (row) => {
        //event.preventDefault();
        if(this.props.taskId > 0) {
            let mytaskdata = Store.getStoreData('mytasks');
            let curlane = "";
            for(let d of mytaskdata){
            //for(let i=0;i<mytaskdata.length;i++){                
                if(d[0] === parseInt(row)){
                    curlane = d[2]
                }
            }            
            console.log("mytaskdata",curlane)
            Store.updateStore('q_index', 1);
            let postData = { "command" : "getTaskCallbyTaskID", "task_id" : row }
            postData['gcs'] = Store.getStoreData('gc_filters');
            let api = new APICall();            
            if(curlane === "COMP_BREACH_USERSAVE"){
                api.command(postData, this.processBreachSaveTaskDataNew);
            }else{
                api.command(postData, this.processBreachTaskDataNew);
            }
        }else{
            console.log('Rows', row);
            Store.updateStore('q_index', row.index);
            let postData = { "command" : "get_task", "task_id" : row.original.id }
            this.setState({isLoadedIcon: true})
            let api = new APICall();
            if(row.row.status === 'Saved') {
                api.command(postData, this.processBreachSaveTaskData);
            }else{
                api.command(postData, this.processBreachTaskData);
            }
        }        
    } 

    processBreachSaveTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            // let title = "Alert";
            // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            // console.log("alert_param", alert_param)
            // this.setState({alert_param: alert_param, ready: true})
            alert(msg)
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "BMSaveBreachModal", ready: true});               
    }
    processBreachTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            // let title = "Alert";
            // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            // console.log("alert_param", alert_param)
            // this.setState({alert_param: alert_param, ready: true})
            alert(msg)
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        Store.updateStore('cur_all_childtask_data', result.result.childtask.result);
        this.setState({showModal: "BMApprovalBreachModal", ready: true});               
    }
    
    processBreachSaveTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "BMSaveBreachModal", ready: true, isLoadedIcon: false});               
    }
    processBreachTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        Store.updateStore('cur_all_childtask_data', result.result.childtask.result);
        this.setState({showModal: "BMApprovalBreachModalUpdated", ready: true, isLoadedIcon: false});               
    }

    closeModal = (event) => {        
        this.setState({
            showModal: null
        })
    }   
    
    closeForApproval = (event) => {        
        let postData = { "command": "refreshTask" };
        let api = new APICall();
        api.command(postData, this.processTask);        
    }
    processTask = (result) => {
        console.log("result==>",result)  
        this.setState({
            breachTaskList: result.result['my_tasks'],
            assignuser: result.result['task_assign_user'],
            myfollowupTask: result.result['followuptasks'],
            showModal: null
        })
    }

    toggleSideBar = () =>  {
        this.setState({sidebar: true})
    }

    closeSidebar = (val) => {
        console.log('Index closeSidebar')
        this.sidebarClosed = true
        this.setState({sidebar:false})
    }

    filterSubmit = (filterbreachList) => {   
        this.setState({breachTaskList: filterbreachList})
    }

    closeTaskModal = (event) => {
        this.props.closeUserDialog();  
    }

    processMyfollowupValue = () => {
        var followup_tasks = this.state.myfollowupTask;
        console.log("followup_tasks",followup_tasks)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
            {Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
            {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}}, 
            {Header: 'Status', accessor: 'status', width: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}, 
            {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, sortMethod: (a, b) => {
                if (a === b) {
                    return 0;
                }
                let adue = a.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                let  bbdue = b.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse > bReverse ? 1 : -1;
              }}, 
            {'Header' : 'Action', Cell: row => (
               <div>
                   <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.goToFollowTaskUrl(row.original.id)}>View</MRButton>
               </div>
            ), headerStyle:{textAlign: 'left',fontWeight: "600"}}];
        for(let d of followup_tasks) {
            i++;            
            let today = moment().unix();
            let text = (d[5]+86400) > today ? 'Pending' : 'Overdue'; 
            let color = (d[5]+86400) > today ? '#2E8B57' : '#FF0000';              
            let followupType = "";
            if(d[2] === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT'){
                followupType = "Follow up Tasks";
            }else if(d[2] === 'COMP_BREACH_REMEDIAL_ASSIGNMENT'){
                followupType = "Remedial Actions";
            }else if(d[2] === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT'){
                followupType = "Preventative Measures";
            }
            let task = followupType +" - "+ d[7];
            //let discloserName = Store.getStoreData('contacts')[d.contact_id]
            let elem = {'task' : task,
                        'assigned_to' : Store.getStoreData('contacts')[parseInt(d[6])],
                        'status' : {text: text, color: color},
                        'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                        'id' : d[0]
                   };
            ret.data.push(elem);
        }        
        return ret;
    }

    goToFollowTaskUrl = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openChildModalModal);
    }
    openChildModalModal = (result) => {
        console.log(result);
        Store.updateStore('followup_task', result.result.task);
        if(result.result.task.subtask.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT'){
            this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
        }else if(result.result.task.subtask.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT'){
            this.setState({showModal: 'view_remedial_modal', isLoadedIcon: false});
        }else if(result.result.task.subtask.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT'){
            this.setState({showModal: 'view_preventative_modal', isLoadedIcon: false});
        }
        // this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({showModal: null})
    } 

    render()
    {   
        let task_id = this.props.taskId;
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'BMSaveBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMAskBreachModal closeModal={this.closeModal} closeTaskModal={this.closeTaskModal}/></div>; break;   
                            case 'BMApprovalBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMBreachApprovalModal closeTaskModal={this.closeTaskModal} /></div>; break;     
                            case 'BMApprovalBreachModalUpdated' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewIncidentModal closeTaskModal={this.closeTaskModal}/></div>; break; 
                            case 'view_followup_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewFollowupModal closeTaskModal={this.closeTaskModal} /></div>; break;       
                            case 'view_remedial_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewRemedialModal closeTaskModal={this.closeTaskModal} /></div>; break;
                            case 'view_preventative_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewPreventativeModal closeTaskModal={this.closeTaskModal} /></div>; break;                                                     
                        }
                    }
                })()
                }
            </div>)
        }
        return (
            <div> 
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <div>
                    {
                        (() => {  
                            if(this.state.sidebar) return   <SideBar closeSidebar = {this.closeSidebar} breachTaskList={this.state.breachTaskListFilter} filterSubmitValue={this.filterSubmit}/>
                        })()
                    }
                </div>
                <OverviewInfoHeader>
                    <HeaderTitle>Incident Register</HeaderTitle>          
                    <div style={{float: "right", color: "#323232", fontSize: "20px"}}><FiSettings /></div> 
                    <div style={{float: "right", color: "#323232", fontSize: "20px",marginRight: "17px", cursor: "pointer"}} onClick={() => this.toggleSideBar()}><IoIosOptions /></div>  
                    <div style={{float: "right", borderLeft: "2px solid #EBEEF3",height: "24px",marginRight: "17px"}}></div>                     
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    {/*<CSLTable 
                        add={false}
                        processData={this.processMyBreachRegisterValue} 
                        headerText={''} 
                        tableRows={10} 
                        refreshCallback={this.refreshCallback} 
                    />  */}
                    <CSLRegisterTable
                        reportButton={true}
                        headerText={''}
                        headerButtons={{}}
                        moduleId='breach'
                        data={reportData}
                        processData={this.processMyBreachRegisterValue}
                        tableRows={10} 
                        buttonSet={{}}
                        refreshCallback={this.refreshCallback}
                        export={true}
                        fields={this.state.fields}
                    />
                    <div style={{float: "left"}}>
                    </div> 
                    <div style={{float: "left", color: "#04ADA8", fontWeight: "600", fontStyle: "italic",marginTop: "21px",marginLeft: "4px"}}>{this.state.breachTaskList.length} Incidents Listed</div>                  
                    <div style={{clear: "both"}}></div>                    
                </OverviewInfoBody>  
                {
                    (() => {
                        if(Store.getStoreData('followup_tasks').length !== 0){
                            return(<div>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>My Tasks</div> 
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody>
                                    <CSLTable add={false} processData={this.processMyfollowupValue} headerText={''} tableRows="5" refreshCallback={this.refreshCallback} />                                         
                                </OverviewInfoBody>
                            </div>)
                        }
                    })()
                }               
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'BMSaveBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMAskBreachModal closeModal={this.closeModal} /></div>; break;
                            case 'BMApprovalBreachModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMBreachApprovalModal closeModalForApproval={this.closeForApproval} closeModalNormal={this.closeModal}/></div>; break; 
                            case 'BMApprovalBreachModalUpdated' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewIncidentModal closeModalForApproval={this.closeForApproval} closeModalNormal={this.closeModal}/></div>; break; 
                            case 'currentChildtaskList' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><CurrentChildTaskListsModal curBreachid={this.state.curBreachid} closeModal={this.closeModal} closeModalandRefresh={this.closeForApproval} /></div>; break;  
                            case 'view_followup_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewFollowupModal closeModal={this.closefollowupModal} /></div>; break;
                            case 'view_remedial_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewRemedialModal closeModal={this.closefollowupModal} /></div>; break;
                            case 'view_preventative_modal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><BMViewPreventativeModal closeModal={this.closefollowupModal} /></div>; break;                          
                        }
                    }
                })()
                }
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}
export default BMBreachList;