import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall';
import * as Style from '../Common/StyledComponents';
import DatePicker from 'react-date-picker';
import { saveAs } from 'file-saver';
import CSLLoaderNonPromise from '../Common/CSLLoaderNonPromise';
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import HtmlEditor from '../Common/HtmlEditor';
import CommitteeUtils from '../Common/CommitteeUtils';
import moment from 'moment';

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #a0a0a0;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;

const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    /*font-weight: bold;*/
	font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;

const MRModalContainer_old = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1002;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 95%;   
    margin-bottom: 10px;
	margin-left: 7px;
	top: 25%;
`;
const MRModalHeader_old = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
	background-color: #04ADA8;
`;
const MRModalSemiHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    /*padding: 20px 20px 10px 20px;*/
	background-color: grey;
`;
const MRModalHeaderText_old = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #DEDEDE;*/
    color: #FFFFFF;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn_old = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
`;
const MRModalBody_old = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 89% !important;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
	font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 500px;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;
const ActionSelect = styled.select`    
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
	/*font-weight: bold;*/
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1005;
  opacity: 0.8;
`;
const MRModalFooter_old = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    background-color: #EDF1F1;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const CSLDateCover = styled.div`
    margin-top: 0px;
    width: 250px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;

/*  */
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: 600px;
    width: 100%;
    position: absolute;
    top: 38px;
    z-index: 1004;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 10px;
`;
const FormSubmitButton = styled.button`
    color: #fff;
    background: linear-gradient(to top, #04ADA8 0%, #04ADA8 100%);
    box-shadow: inset 0px 1px 1px 0px #04ADA8;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid #04ADA8;
    border-radius:3px;
    margin-right:10px;
`;
const FormCancelButton = styled.button`
    color: #000000;
    background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: inset 0px 1px 1px 0px #FFFFFF;
    width: 116px;
    padding: 6px 16px;
    border: 1px solid grey;
    border-radius:3px;
    margin-right:10px;
`;

let committeeUtils = new CommitteeUtils();

function GetSortOrder(prop) {
    return function(a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
class AddActionsTmView extends React.Component
{
    state = {
        showModal: false,
        is_loader: false,
        binFiles: [],
		curBinFiles: [],
        subject: "",
        actionDescription: "",
		action_status: "",
        assignedTo: 0,
        action_note_undertaken: 'dd',
		IsActionTitleProvided : false,
		IsActionAssignedToProvided : false,
		IsActionProvided : false,
		is_form_modified : false,
		open_data_lost_warning_modal : false,
		due_date : new Date(),
		first_request : true,
		view_mode : false,
		task_id : 0,
        committeeId : 0,
        validate_user: {},
        is_pre_complete: false,
        roll_over_task_ref_id: '',
    }

	componentDidMount()
    {
		this.cleanHTMLEditor();
        let task_id = ('task_id' in this.props.action_extra_json) ? this.props.action_extra_json.task_id : this.state.task_id;
        //if(task_id !== this.state.task_id){
            //console.log("task_id==>"+task_id);
            //console.log("I am in componentDidUpdate of AddActions");
            let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : false;
			let desc_text = committeeUtils.changeStringToAtob(this.props.action_extra_json.action_description);
            if(view_mode === true){
                this.setState({committeeId : this.props.committeeId, due_date : this.props.action_extra_json.due_date, subject: this.props.action_extra_json.action_title , actionDescription : desc_text , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : true , task_id : task_id});
            }else{
                if(task_id > 0){
                    this.setState({committeeId : this.props.committeeId, due_date : this.props.action_extra_json.due_date, subject: this.props.action_extra_json.action_title , actionDescription : desc_text , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false , task_id : task_id, roll_over_task_ref_id: this.props.action_extra_json.roll_over_task_ref_id });
                }else{
                    this.setState({committeeId : this.props.committeeId, due_date : new Date(), subject: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false, task_id : 0, roll_over_task_ref_id: this.props.action_extra_json.roll_over_task_ref_id });
                }
            }
        //}

    }

    processUserrestriction = (committeeId) => {
        const committees = Store.getStoreData("filtered_committees");
        const user_assignments =  Store.getStoreData("user_assignments");
        let single = {};
        for (let element in committees) {
            if(parseInt(committees[element].id) === parseInt(committeeId)) {
                single = committees[element];
            }
        }
        let exist_users = {}
        if(Object.keys(single).length > 0) {
            let json_data = single.json_data;
            if("gc_id" in json_data){
                if(parseInt(json_data.gc_id) > 0) {
                    for(let key in user_assignments){
                        if(user_assignments[key].companies[json_data.gc_id]){
                            exist_users[key] = 1;
                        }
                    }
                }

                if(parseInt(json_data.grouping_id) > 0) {
                    for(let key in user_assignments){
                        if(user_assignments[key].tags[json_data.grouping_id][json_data.grouping_value_id]){
                            exist_users[key] = 1;
                        }
                    }
                }

            }
        }
        return exist_users;

    }

    componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			// console.log("in componentDidUpdate action", this.props.committeeId);
            // this.processUserrestriction(this.props.committeeId)
            //if(this.state.first_request){

				/*
				let task_id = ('task_id' in this.props.action_extra_json) ? this.props.action_extra_json.task_id : this.state.task_id;
				if(task_id !== this.state.task_id){
					//console.log("task_id==>"+task_id);
					//console.log("I am in componentDidUpdate of AddActions");
					let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : false;
					//console.log(task_id+"<=task_id   view_mode==>"+view_mode+"<==>"+JSON.stringify(this.props.action_extra_json));
					//let view_mode = ('view_mode' in this.props.action_extra_json) ? this.props.action_extra_json.view_mode : this.state.view_mode;
					if(view_mode === true){
						this.setState({committeeId : this.props.committeeId, due_date : this.props.action_extra_json.due_date, subject: this.props.action_extra_json.action_title , actionDescription : this.props.action_extra_json.action_description , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : true , task_id : task_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
					}else{
						if(task_id > 0){
							this.setState({committeeId : this.props.committeeId, due_date : this.props.action_extra_json.due_date, subject: this.props.action_extra_json.action_title , actionDescription : this.props.action_extra_json.action_description , assignedTo : this.props.action_extra_json.action_assignto , curBinFiles : this.props.action_extra_json.followupBinFiles , action_note_undertaken : this.props.action_extra_json.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false , task_id : task_id}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
						}else{
						    this.setState({committeeId : this.props.committeeId, due_date : new Date(), subject: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : false, task_id : 0}, ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
						}
					}
				}
				*/
			//}

		}

	}
	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() +1) < 10 ? '0'+(date.getMonth() +1) : (date.getMonth() +1);
		var dt = (date.getDate() < 10 ) ? '0'+(date.getDate()) : (date.getDate());
		return dt +'/'+ month + '/'+date.getFullYear();
	}

    showAddAction = (index) => (event) => {
		//console.log("in show action");
        event.preventDefault();
        this.setState({ due_date : new Date(), subject: "" , actionDescription : "" , action_status : "" , assignedTo : 0 , curBinFiles : [] , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false, showModal: true , first_request : true , view_mode : false , task_id : 0} , ()=>{this.props.actionModalOpen({'status' : true}); this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
    }
    closeAddActionModal = (event) => {
		event.preventDefault();
		if(Store.getStoreData('ViewCommitteeTasksListVisibility') === true){
			//this.props.closeCommitteeTaskListModal();
			//Store.updateStore('ViewCommitteeTasksListVisibility' , false);
		}

        //if(this.state.is_form_modified){
            //this.props.refreshParent('openDataLostWarningModal')
			//this.setState({open_data_lost_warning_modal : true});
        //}else{
            if(this.props.prop_task_id > 0){
				  if(document.referrer){
					  if(document.referrer.match(/tmview/g)){
						  window.location.assign(document.referrer);
					  }else{
						  window.location.href = process.env.REACT_APP_GOVERNANCE_URL_URL;
					  }
					  //
				  }else{
					  window.location.href = process.env.REACT_APP_GOVERNANCE_URL_URL;
				  }
			}else{
                this.setState({ showModal: false , first_request : true ,view_mode : false , task_id : 0} , ()=>{this.props.actionModalOpen({'status' : false,'action_popup': "close"})});
			}
        //}
    }

	refreshState  = (val) => {
		if(val === null){
			this.setState({open_data_lost_warning_modal : false , showModal: false} , ()=>{this.props.actionModalOpen({'status' : false})});
		}else{
			this.setState({open_data_lost_warning_modal : false } , ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
		}
	}


    processcontactrole = () => {
        var contacts = Store.getStoreData('contacts')
        var userrole = Store.getStoreData('contactrole')
        console.log("userrole",userrole.length)
        console.log("contacts",contacts.ID)
        console.log("committeeId",this.state.committeeId)


        let is_valid_users = this.processUserrestriction(this.props.committeeId);

        var contactmap = [];
        for( let i=0; i<userrole.length; i++) {
            for (let j=0; j<contacts.length; j++) {
                if ((userrole[i].user_id == contacts[j].ID) && (userrole[i].role == 'admin_manager' || userrole[i].role == 'governance_manager' || userrole[i].role == 'team')) {
                    if(Object.keys(is_valid_users).length === 0){
                        contactmap.push({ID:contacts[j].ID,name:contacts[j].ContactName})
                    }else{
                        if(is_valid_users[contacts[j].ID]){
                            contactmap.push({ID:contacts[j].ID,name:contacts[j].ContactName})
                        }
                    }

                }
            }
        }
        console.log("contactmap===>",contactmap)
        return contactmap;
    }

    submitAddActionValue = (event) => {
		event.preventDefault();
		let actionDescription = Store.getStoreData('actionDescription');
		let IsActionTitleProvided = this.state.IsActionTitleProvided;
		let IsActionAssignedToProvided = this.state.IsActionAssignedToProvided;
		let IsActionDescriptionProvided = this.state.IsActionDescriptionProvided;
		//console.log("submitAddActionValue,",actionDescription,this.state.actionDescription);
        if (this.state.subject.trim() === '' || this.state.assignedTo === 0 || this.state.assignedTo === "" || this.state.actionDescription === '<p></p>' ||  this.state.actionDescription === '<p>.</p>' ||  actionDescription === null) {
            if(this.state.subject.trim() === ''){
				IsActionTitleProvided = true;
            }else{
				IsActionTitleProvided = false;
            }
            if(this.state.assignedTo === 0 || this.state.assignedTo === ''){
				IsActionAssignedToProvided = true;
            }else{
				IsActionAssignedToProvided = false;
            }
            if(this.state.actionDescription === '<p></p>' ||  this.state.actionDescription === '<p>.</p>' ||  actionDescription === null){
				IsActionDescriptionProvided = true;
            }else{
				IsActionDescriptionProvided = false;
            }
			actionDescription = (actionDescription === null) ? actionDescription = '<p></p>': actionDescription;

			this.setState({ IsActionTitleProvided ,IsActionAssignedToProvided,IsActionDescriptionProvided , actionDescription});

		} else {

            var bin_files = this.state.curBinFiles;
            let task_id = (this.state.task_id === 0) ? Utils.genKey(10) : this.state.task_id;
            //this.props.refreshAddActionValue(this.state.action_title,this.state.actionDescription,this.state.assignedTo,this.state.binFiles)
            this.props.refreshAddActionValue(this.state.subject, this.state.actionDescription, this.state.assignedTo, bin_files, this.state.action_status, this.state.due_date, this.state.action_note_undertaken, task_id)
            this.setState({ subject: "", actionDescription: "", assignedTo: 0, binFiles: [], showModal: false, IsActionTitleProvided: false, IsActionAssignedToProvided: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': false }) });

        }
    }
    completeTask = (event) => {
		event.preventDefault();
        if(this.state.action_note_undertaken.trim() === ''){
            this.setState({IsActionProvided: true});


        }else{

            this.setState({is_loader: true});

			setTimeout(() => {
				this.preProcessCompleteTask();
			}, 30);


		}
    }
    preProcessCompleteTask = () =>{
		let task_json = this.props.task_json;
		let action_json = task_json.object_data.headerinfo.action_json;
		let actionDescription_text = Store.getStoreData('actionDescription');
		if (actionDescription_text === null) {
			actionDescription_text = this.state.actionDescription;
		}
		let actionDescription_value = btoa(window.encodeURIComponent(actionDescription_text));
		task_json.object_data.headerinfo.action_json.action_description = actionDescription_value;
		task_json.object_data.headerinfo.action_json.action_note_undertaken = this.state.action_note_undertaken;
		task_json.object_data.headerinfo.action_json.actionBinFiles = this.state.curBinFiles;

		task_json.task_data = { "module_id": "committees", "last_action": "COMP_COMMITTEES_COMPLETE", "actiondate": Date.now(), 'task_type': 'COMP_COMMITTEES_COMPLETE', 'cur_lane': 'COMP_COMMITTEES_COMPLETE', completed_date: Date.now() };
		var action_data = { action: 'COMP_COMMITTEES_COMPLETE', actiondate: Date.now(), assigned_to: Store.getStoreData('loggedin_user_info').ID, cur_lane: 'COMP_COMMITTEES_COMPLETE', performed_by: Store.getStoreData('loggedin_user_info').ID };
		if (Store.getStoreData('ViewCommitteeTasksListVisibility')) {
			action_data = { action: 'COMP_COMMITTEES_COMPLETE', actiondate: Date.now(), assigned_to: action_json.action_assignto, cur_lane: 'COMP_COMMITTEES_COMPLETE', performed_by: Store.getStoreData('loggedin_user_info').ID };
		}
		task_json.action_data = action_data;
		var task_data = {};
		task_data.task_json = task_json;
		task_data.id = this.state.task_id;
		task_data.performed_by = Store.getStoreData('loggedin_user_info').ID;

		let postData = { "command": "updateFollowupTask", "task_id": task_data.id, "task_json": task_data.task_json, "assigned_to": task_data.assigned_to };
		let api = new APICall();
		//api.command(postData, this.processSaveCompleteAction);
		let that = this;
		api.command(postData, function callback(result) { that.processCompleteTask(task_data.id, that.state.action_note_undertaken) });
	}
    processCompleteTask = (task_id ,action_note_undertaken) =>{
		let that = this;

		if(this.props.prop_task_id > 0){
			  if(document.referrer){
				  if(document.referrer.match(/tmview/g)){
					  window.location.assign(document.referrer);
				  }else{
					  window.location.href = process.env.REACT_APP_GOVERNANCE_URL_URL;
				  }
				  //
			  }else{
				  window.location.href = process.env.REACT_APP_GOVERNANCE_URL_URL;
			  }
		}else{
			this.props.actionModalOpen({'status' : false, task_id : task_id , task_status : 'complete' , action_note_undertaken : action_note_undertaken});
			this.setState({ is_loader:false ,showModal: false , first_request : true ,view_mode : false , task_id : 0});
			//this.setState({ is_loader:false ,showModal: false , first_request : true ,view_mode : false , task_id : 0} , ()=>{this.props.actionModalOpen({'status' : false, task_id : task_id , task_status : 'complete' , action_note_undertaken : action_note_undertaken})});
		}
    }
    saveTask = (event) => {
        event.preventDefault();
        this.setState({ is_loader: true });
        setTimeout(() => {
            this.preProcessSaveTask();
        }, 30);
    }
    preProcessSaveTask = () => {
		let task_json = this.props.task_json;
		let action_json = task_json.object_data.headerinfo.action_json;
		task_json.object_data.headerinfo.action_json.action_title = this.state.subject;
		let actionDescription_text = Store.getStoreData('actionDescription');
		if (actionDescription_text === null) {
			actionDescription_text = this.state.actionDescription;
		}
		let actionDescription_value = btoa(window.encodeURIComponent(actionDescription_text));
		task_json.object_data.headerinfo.action_json.action_description = actionDescription_value;
		task_json.object_data.headerinfo.action_json.action_assignto = this.state.assignedTo;
		task_json.object_data.headerinfo.action_json.due_date = this.state.due_date;
		task_json.object_data.headerinfo.action_json.actionBinFiles = this.state.curBinFiles;
		task_json.task_data = { "module_id": "committees", "last_action": "COMP_COMMITTEES_ASSIGNMENT", "actiondate": Date.now(), 'task_type': 'COMP_COMMITTEES', 'cur_lane': 'COMP_COMMITTEES_SAVED', completed_date: Date.now() };
		var action_data = { action: 'COMP_COMMITTEES_SAVED', actiondate: Date.now(), assigned_to: this.state.assignedTo, cur_lane: 'COMP_COMMITTEES_SAVED', performed_by: Store.getStoreData('loggedin_user_info').ID };
		if (Store.getStoreData('ViewCommitteeTasksListVisibility')) {
			action_data = { action: 'COMP_COMMITTEES_SAVED', actiondate: Date.now(), assigned_to: this.state.assignedTo, cur_lane: 'COMP_COMMITTEES_SAVED', performed_by: Store.getStoreData('loggedin_user_info').ID };
		}
		task_json.action_data = action_data;
		var task_data = {};
		task_data.task_json = task_json;
		task_data.id = this.state.task_id;
		task_data.performed_by = Store.getStoreData('loggedin_user_info').ID;

		let postData = { "command": "updateFollowupTask", "task_id": task_data.id, "task_json": task_data.task_json, "assigned_to": task_data.assigned_to };
		let api = new APICall();
		//api.command(postData, this.processSaveCompleteAction);
		let that = this;
		api.command(postData, function callback(result) { that.processSaveTask(task_data.id, that.state.subject, that.state.assignedTo, that.state.due_date, that.state.action_note_undertaken) });
    }
    processSaveTask = (task_id, subject, assignedTo, due_date, action_note_undertaken) => {
        let that = this;

        if (this.props.prop_task_id > 0) {
            if (document.referrer) {
                if (document.referrer.match(/tmview/g)) {
                    window.location.assign(document.referrer);
                } else {
                    window.location.href = process.env.REACT_APP_GOVERNANCE_URL_URL;
                }
                //
            } else {
                window.location.href = process.env.REACT_APP_GOVERNANCE_URL_URL;
            }
        } else {
            this.setState({ is_loader: false, showModal: false, first_request: true, view_mode: false, task_id: 0 }, () => { this.props.actionModalOpen({ 'status': false, task_id: task_id, task_status: 'incomplete', subject: subject, assignedTo: assignedTo, due_date: due_date, action_note_undertaken: action_note_undertaken }) });
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, is_form_modified: true});
    }
    onScheduleDateChange = (date) => {
        this.setState({due_date: date});
    }
    filesLoaded = (jsonObj) => {
        let me = Store.getStoreData('loggedin_user_info');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let curBinFiles = this.state.curBinFiles;

        let file_exists = {};
        let curBinFiles_1 = [];

        if (jsonObj.command === 'add') {
            for (let i = 0; i < files_to_show.length; i++) {
                if (typeof files_to_show[i].name !== "undefined") {
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if (!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = me.ID;
                        files_to_show[i].upload_date = Date.now();
                        files_to_show[i].initial_type = "testing_activity_resource";
                    } else {
                        files_to_show[i].initial_type = "testing_activity_resource";
                    }
                    if (typeof file_exists[files_to_show[i].name] === "undefined") {
                        curBinFiles.push(files_to_show[i]);
                        file_exists[files_to_show[i].name] = true;
                    }
                }
            }
            curBinFiles_1 = curBinFiles.filter((curBinFiles, index, self) => index === self.findIndex((t) => (t.name === curBinFiles.name && t.uid === curBinFiles.uid)));
            this.setState({ curBinFiles: curBinFiles_1, is_form_modified: true });

        } else {//delete
			console.log("filesRemoved jsonObj,",jsonObj)
            for (let i = 0; i < curBinFiles.length; i++) {
                if (curBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()) {
                    curBinFiles.splice(i, 1);
                }
            }
			console.log("filesRemoved jsonObj,",curBinFiles)
            this.setState({ curBinFiles: curBinFiles });
        }
    }

	filesRemoved = (jsonObj) => {
		if(jsonObj.files_to_show_size === 0){
			this.setState({curBinFiles: [] , is_form_modified : false});
		}
	}

    handleDownload = (index) => {
		console.log("curBinFiles,",this.state.curBinFiles,index);
		let cur_file = null;
		for(let p of this.state.curBinFiles){
			if(p.uid.toString() === index.toString())cur_file = p;break;
		}
        //let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }
    preCompleteTask = () => {
        this.setState({ is_pre_complete: true });
    }
    cleanHTMLEditor = () => {
        Store.updateStore('actionDescription', null);
    }

    render() {
        let overview_bgcolor = (typeof this.props.overviewBgColor !== 'undefined') ? this.props.overviewBgColor : '#26ADA7';
        let plus_circle_bgcolor = (typeof this.props.overviewBgColor !== 'undefined') ? this.props.overviewBgColor : '#ffffff';
        console.log("AddActions state:", this.state)
        console.log("AddActions props:", this.props)
        return (<div>
            {
                (() => {
                    if (this.state.showModal) {
                        return (<div >
                            <MRModalContainer>
                                <MRModalHeader>
                                    {
                                        (() => {
                                            if (this.state.view_mode) {
                                                return (
                                                    <MRModalHeaderText>View follow-up task</MRModalHeaderText>
                                                )
                                            } else {
                                                if (this.state.task_id > 0) {
                                                    return (
                                                        <MRModalHeaderText>Edit follow-up task</MRModalHeaderText>
                                                    )
                                                } else {
                                                    return (
                                                        <MRModalHeaderText>Add follow-up task</MRModalHeaderText>
                                                    )
                                                }
                                            }
                                        })()
                                    }
                                    <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                                    <div style={{ clear: "both" }}></div>
                                </MRModalHeader>
                                <div style={{ clear: "both" }} ></div>
                                <MRModalSemiHeader style={{ paddingLeft: "20px", color: "#FFFFFF", fontWeight: "500" }}>
                                    {
                                        (() => {
                                            if (this.state.roll_over_task_ref_id !== '') return (<div>This task has been carried over from a previous meeting record.</div>)
                                        })()
                                    }
                                    <div>Committee Name: {Store.getStoreData('Committee_Name_For_Task')}</div>
                                    <div>Meeting Date: {this.getFormattedDate(Store.getStoreData('Meeting_Date_For_Task'))}</div>
                                </MRModalSemiHeader>
                                <div style={{ clear: "both" }} ></div>
                                <MRModalBody style={{maxHeight: "500px" ,overflowY: "scroll"}}>
                                    {
                                        (() => {
                                            if (this.state.is_loader) {
                                                return <div><CSLLoaderNonPromise /></div>
                                            }

                                        })()
                                    }
                                    <div style={{ clear: "both" }} ></div>
                                    {/*<RegModalContainertop>*/}
                                    {/*<RegModalNameLabelDiv>Subject</RegModalNameLabelDiv>*/}
                                    <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Task Name<label style={{ color: "#FF0000" }}>* </label></RegModalNameLabelDiv>
                                    <div style={{ clear: "both", marginTop: "8px" }}></div>
                                    {
                                        (() => {
                                            if (this.state.view_mode) {
                                                return (
                                                    <div style={{ marginLeft: "27px", width: "90%" }} >
                                                        {this.state.subject}
                                                    </div>
                                                );
                                            } else {
                                                if (this.state.task_id > 0) {
                                                    if (this.state.is_pre_complete) {
                                                        return (
                                                            <div style={{ marginLeft: "27px", width: "90%" }} >
                                                                {this.state.subject}
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <MRModalInput style={{marginLeft: "25px", color: "#000000" }} name="subject" onChange={this.handleChange} value={this.state.subject} />
                                                        );
                                                    }

                                                } else {
                                                    return (
                                                        <MRModalInput style={{ marginLeft: "25px", color: "#000000" }} name="subject" onChange={this.handleChange} value={this.state.subject} />
                                                    )
                                                }
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (this.state.IsActionTitleProvided) {
                                                return (
                                                    <div style={{ width: "200px", marginLeft: "30px", marginTop: "5px" }}>
                                                        <div style={{ clear: "both" }}></div>
                                                        <label style={{ color: "#FF0000" }}>Please enter subject</label>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                    <div style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Note<label style={{ color: "#FF0000" }}>* </label></RegModalNameLabelDiv>
                                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (
                                                        <div style={{ marginLeft: "27px", width: "90%" ,wordWrap: "break-word" , maxWidth: "80%" }} dangerouslySetInnerHTML={{ __html: this.state.actionDescription }}>
                                                        </div>
                                                    )
                                                } else {
                                                    if (this.state.task_id > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            return (
                                                                <div style={{ marginLeft: "27px", width: "90%" ,wordWrap: "break-word" , maxWidth: "80%"}} dangerouslySetInnerHTML={{ __html: this.state.actionDescription }}>
                                                                </div>
                                                            );
                                                        } else {
                                                            //return(
                                                            {/*<FCKTextarea rows="6" style={{width: "500px" ,marginLeft: "25px" , resize: "none"}} name="actionDescription" onChange={this.handleChange} value={this.state.actionDescription}/>*/ }
                                                            //	<div style={{marginLeft: "25px"}}><HtmlEditor content={this.state.actionDescription} field_name={'actionDescription'}/></div>
                                                            //	);
                                                            return (
                                                                <div style={{ marginLeft: "27px", marginLeft: "25px", width: "calc(90%)" }}><HtmlEditor content={this.state.actionDescription} field_name={'actionDescription'} /></div>
                                                            );
                                                        }

                                                    } else {
                                                        return (
                                                            <div style={{ marginLeft: "27px", width: "calc(90%)" }}><HtmlEditor content={this.state.actionDescription} field_name={'actionDescription'} /></div>
                                                        );
                                                    }
                                                }
                                            })()
                                        }
                                    {
                                        (() => {
                                            if (this.state.IsActionDescriptionProvided) {
                                                return (
                                                    <div style={{ width: "200px", marginLeft: "30px", marginTop: "5px" }}>
                                                        <div style={{ clear: "both" }}></div>
                                                        <label style={{ color: "#FF0000" }}>Please enter note</label>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Assigned To<label style={{ color: "#FF0000" }}>* </label></RegModalNameLabelDiv>
                                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (
                                                        <div style={{ marginLeft: "27px" , width: "90%" }}>
                                                            {Store.getStoreData('contact_list')[this.state.assignedTo]}
                                                        </div>
                                                    )
                                                } else {
                                                    if (this.state.task_id > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            return (
                                                                <div style={{ marginLeft: "27px" , width: "90%"}}>
                                                                    {Store.getStoreData('contact_list')[this.state.assignedTo]}
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <ActionSelect style={{ marginLeft: "25px" , width: "90%"}} name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo}>
                                                                    {
                                                                        (() => {
                                                                            let contactsdropdown = [];
                                                                            let contacts = this.processcontactrole();
                                                                            contacts.sort(GetSortOrder("name"));
                                                                            contactsdropdown.push(<option key={0} value={'0'}>Select User</option>)
                                                                            contacts.forEach((item) => {
                                                                                contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
                                                                            })
                                                                            return contactsdropdown;
                                                                        })()
                                                                    }
                                                                </ActionSelect>
                                                            );

                                                        }

                                                    } else {
                                                        return (
                                                            <ActionSelect style={{ marginLeft: "25px" , width: "90%"}} name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo}>
                                                                {
                                                                    (() => {
                                                                        let contactsdropdown = [];
                                                                        let contacts = this.processcontactrole();
                                                                        contacts.sort(GetSortOrder("name"));
                                                                        contactsdropdown.push(<option key={0} value={0}>Select User</option>)
                                                                        contacts.forEach((item) => {
                                                                            contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
                                                                        })
                                                                        return contactsdropdown;
                                                                    })()
                                                                }
                                                            </ActionSelect>
                                                        )
                                                    }
                                                }
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (this.state.IsActionAssignedToProvided) {
                                                    return (
                                                        <div style={{ width: "200px", marginLeft: "-10%", marginTop: "38px" }}>
                                                            <div style={{ clear: "both" }}></div>
                                                            <label style={{ color: "#FF0000" }}>Please select a user.</label>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                    </div>
                                    <RegModalContainertop style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Due Date</RegModalNameLabelDiv>
                                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    let due_date_moment = moment(this.state.due_date);
                                                    let due_date_year = due_date_moment.format('YYYY');
                                                    let due_date_month = due_date_moment.format('MM');
                                                    let due_date_day = due_date_moment.format('DD');
                                                    let due_date = `${due_date_day}/${due_date_month}/${due_date_year}`;
                                                    return (
                                                        <div style={{ marginLeft: "27px" }}>
                                                            {due_date}
                                                        </div>
                                                    );
                                                } else {
                                                    if (this.state.task_id > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            let due_date_moment = moment(this.state.due_date);
                                                            let due_date_year = due_date_moment.format('YYYY');
                                                            let due_date_month = due_date_moment.format('MM');
                                                            let due_date_day = due_date_moment.format('DD');
                                                            let due_date = `${due_date_day}/${due_date_month}/${due_date_year}`;
                                                            return (
                                                                <div style={{ marginLeft: "27px" }}>
                                                                    {due_date}
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <CSLDateCover style={{ display: "inline-block", marginLeft: "27px"}}>
                                                                    <DatePicker
                                                                        onChange={this.onScheduleDateChange}
                                                                        clearIcon={null}
                                                                        calendarIcon={null}
                                                                        locale={"en-GB"}
                                                                        value={this.state.due_date}
                                                                        style={{ fontWeight: "200" }}
                                                                    />
                                                                </CSLDateCover>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <CSLDateCover style={{ display: "inline-block", marginLeft: "27px"}}>
                                                                <DatePicker
                                                                    onChange={this.onScheduleDateChange}
                                                                    clearIcon={null}
                                                                    calendarIcon={null}
                                                                    locale={"en-GB"}
                                                                    value={this.state.due_date}
                                                                    style={{ fontWeight: "200" }}
                                                                />
                                                            </CSLDateCover>
                                                        )
                                                    }
                                                }
                                            })()
                                        }

                                    </RegModalContainertop>
                                    <RegModalContainertop style={{ marginTop: "20px" }}>
                                        <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Attachment(s)</RegModalNameLabelDiv>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ padding: "10px" }}>
                                                <div style={{ marginLeft: "-110px" }}>
                                                    {
                                                        (() => {
                                                            let status = (this.state.view_mode) ? false : true;
                                                            return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} height={"200px"} onRemoveFile={this.filesRemoved} showFileButton={status} handleDownload={this.handleDownload} removable={status} />
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </RegModalContainertop>

                                    <div style={{ clear: "both" }} ></div>
                                    <div style={{ marginTop: "20px" }}>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (
                                                        <div>
                                                            <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Comments</RegModalNameLabelDiv>
                                                            <div style={{ clear: "both", marginTop: "8px" }}></div>
                                                            <div style={{ marginLeft: "27px" ,wordWrap: "break-word" , maxWidth: "80%"}}>
                                                                {this.state.action_note_undertaken}
                                                            </div>
                                                        </div>

                                                    );
                                                } else {
                                                    if (this.state.task_id > 0 && this.state.is_pre_complete) {
                                                        return (
                                                            <div>
                                                                <RegModalNameLabelDiv style={{ marginLeft: "30px" }}>Comments</RegModalNameLabelDiv>
                                                                <div style={{ clear: "both", marginTop: "8px" }}></div>
                                                                <FCKTextarea rows="6" style={{ width: "90%", marginLeft: "25px", resize: "none" }} name="action_note_undertaken" onChange={this.handleChange} value={this.state.action_note_undertaken} />
                                                            </div>
                                                        )
                                                    }
                                                }

                                            })()
                                        }
                                        {/*
										(() => {
											if(this.state.task_id > 0){
												if(this.state.view_mode){
													return(
														<RegModalNameLabelDiv>{this.state.action_note_undertaken}</RegModalNameLabelDiv>
														)
												}else{
                                                    return(
														<FCKTextarea rows="6" style={{width: "90%",marginLeft: "25px" , resize: "none"}} name="action_note_undertaken" onChange={this.handleChange} value={this.state.action_note_undertaken}/>
														)
												}
											}
										})()
									*/}
                                        {
                                            (() => {
                                                if (this.state.IsActionProvided) {
                                                    return (
                                                        <div style={{ width: "200px", marginLeft: "120px", marginTop: "5px" }}>
                                                            <div style={{ clear: "both" }}></div>
                                                            <label style={{ color: "#FF0000" }}>Please provide comments</label>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                    </div>


                                    <div style={{ clear: "both" }} ref={el => { this.el = el; }}></div>
                                </MRModalBody>
                                <MRModalFooter>
                                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                                        {
                                            (() => {
                                                if (this.state.view_mode) {
                                                    return (<div>&nbsp;</div>)
                                                } else {
                                                    if (parseInt(this.state.task_id) > 0) {
                                                        if (this.state.is_pre_complete) {
                                                            return (
                                                                <div>
                                                                    <FormSubmitButton style={{ background: "#26ADA7", border: "1px solid #26ADA7", float: "right", width: "150px" }} onClick={this.completeTask}>Submit</FormSubmitButton>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div>
                                                                    <FormSubmitButton style={{ background: "#26ADA7", border: "1px solid #26ADA7", float: "right", width: "150px" }} onClick={this.preCompleteTask}>Complete</FormSubmitButton>
                                                                    <FormSubmitButton style={{ background: "linear-gradient(to top, #1E3E62 0%, #1E3E62 100%)", boxShadow: "inset 0px 1px 1px 0px #1E3E62", border: "1px solid #1E3E62", float: "right", width: "150px" }} onClick={this.saveTask}>Update</FormSubmitButton>
                                                                </div>
                                                            );
                                                        }

                                                    } else {
                                                        return <FormSubmitButton style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right" }} onClick={this.submitAddActionValue}>Add Task</FormSubmitButton>;
                                                    }
                                                }
                                            })()
                                        }

                                        <FormCancelButton onClick={this.closeAddActionModal}>Cancel</FormCancelButton>
                                    </div>
                                </MRModalFooter>
                            </MRModalContainer>
                        </div>)
                    }

                })()
            }

            {/*
            <OverviewInfoHeader style={{backgroundColor: overview_bgcolor}}>

				{
					(() => {
						if(this.props.curLane.toString() !== "COMP_COMMITTEES_COMPLETE" && !Store.getStoreData('ViewCommitteeTasksListVisibility')) {
							return <div style={{float: "right", color: plus_circle_bgcolor, fontSize: "17px", cursor: "pointer"}} onClick={this.showAddAction(-1)} title="add a followup task"><FaPlusCircle /></div>
						}
					})()
				}
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
		*/}


            {/*<MRModalContainer>
                    <MRModalHeader>
						{
							(() => {
								if(this.state.view_mode){
									return(
										<MRModalHeaderText>View Follow-up Task</MRModalHeaderText>
										)
								}else{
									if(this.state.task_id > 0){
										return(
											<MRModalHeaderText>Update Task</MRModalHeaderText>
											)
									}else{
										return(
											<MRModalHeaderText>Add Follow-up Task</MRModalHeaderText>
											)
									}
								}
							})()
						}
                        <div style={{clear: "both"}}></div>
                        <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
						<div style={{clear: "both"}} ></div>
						<MRModalSemiHeader style={{paddingLeft : "20px" , color: "#FFFFFF" , fontWeight: "500"}}>
							Committee Name : {Store.getStoreData('Committee_Name_For_Task')}
							<br/>
							Meeting Date : {this.getFormattedDate(Store.getStoreData('Meeting_Date_For_Task'))}
						</MRModalSemiHeader>
						<MRModalBody style={{marginTop: "20px" }}>

						</MRModalBody>
                    </MRModalHeader>
                </MRModalContainer>
				*/}
        </div>
        )
    }
}
export default AddActionsTmView;
