import React from 'react';
import {FaTimes, FaEdit} from 'react-icons/fa';
import {AiFillDelete} from "react-icons/ai";
import styled from 'styled-components';
import {APICall, Store, Utils, CSLLoader} from '../Common';
import ClearBoth from '../Common/ClearBoth';
import CSLTable from '../../Components/Common/CSLTable';
import AdvancedFilter from './AdvancedFilter';
import TaskApprovalModal from './TaskApprovalModal';
import TaskModal from './TaskModal';
import FollowupModal from './FollowupModal';
import FollowupListModal from './FollowupListModal';
import EditFollowUpModal from "./EditFollowUpModal";
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import XcelLoader from '../Common/XcelLoader.js';


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
  // padding: 0 5px;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 98%;
  margin: 0 auto;
  margin-top: 30px;
`;
const OverviewInfoBody = styled.div`
  // padding: 5px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const OverviewInfoHeaderTitle = styled.div`
  position: relative;
  padding: 12px;
  float: left;
  color: #323232;
  font-weight: 600;
  margin: 2px;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
  cursor: pointer;
`;
const ExportsLine = styled.div`
  color: #04ada8;
  font-weight: 600;
  font-style: italic;
  margin-top: 28px;
  margin-left: 15px;
`;

const ExportButtonDisable = styled.button`
  box-sizing: border-box;
  background-color: #213e61a1;
  color: #F2F2F2;
  border: 1px solid #213e61a1;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
`;

class generciTasks extends React.Component {
    state = {
        contact_id: Store.getStoreData('contact_id'),
        contacts: Store.getStoreData('contacts'),
        promotions: Store.getStoreData('module_config').promotion_types,
        statuses: Store.getStoreData('module_config').status,
        general_config: Store.getStoreData('module_config').general,
        isclear: false,
        isLoaded: false,
        pending_tasks: [],
        advanceFilter: false,
        show_modal: null,
        //open edit follow up modal
        show_edit_followup_modal: null,
        filterRequestedFromDate: new Date(),
        filterRequestedToDate: new Date(),
        ready: false,
        tasksList: null,
        tasksListFilter: null,
        curID: 0,
        userRole: '',
        isEditedFromReviewRequired: null,
        isEnabledExpiryDate: Store.getStoreData('module_config').general.add_expiry_date_for_financial_promotions,
        isDeletionComplete: true,
        isReviewDateEnabledFromConfig: Store.getStoreData('module_config').general.add_review_date_for_finpromo,
        isLoadedIcon: false
    };

    constructor(props) {

        super(props);
        this.api = new APICall();
    }

    componentDidMount() {
        let task_id = this.props.taskId;
        Store.updateStore('q_index', 1);
        const postData = {command: 'get_task', task_id: task_id};
        this.api.command(postData, this.taskDataModal);
    }

    changeClear = () => this.setState({isclear: false});

    goToTaskUrlBare = (taskID) => {
        const mytaskdata = Store.getStoreData('mytasks');
        let checkid = false;
        for (let i = 0; i < mytaskdata.length; i++) if (mytaskdata[i].id.toString() === taskID) checkid = true;
        if (checkid) this.showApprovalModal(taskID);
        else this.currentfollowupModal(taskID);
    };

    currentfollowupModal = (task_id) => {
        //event.preventDefault();
        const postData = {command: 'get_subtask', task_id: task_id};
        this.api.command(postData, this.openCurrentFollowupModal);
    };

    openCurrentFollowupModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'triggerFollowupModal', ready: true});
    };


    taskDataModal = (result) => {
        console.log("result==>", result)
        const task = result.result.parenttask;
        //console.log('subtask ==>', subtask);
        let task1 = {
            parenttask:result.result.parenttask,
            subtask:result.result.parenttask
        }
        //const modal = this.state.userRole === 'team' || task.cur_lane === 'COMP_FINPROMO_TEAMSAVE' ? 'triggerTaskModal' : 'triggerTaskApprovalModal';
        let modal = '';
        if (task.cur_lane == 'COMP_FINPROMO_TEAMSAVE') {
            modal = "triggerTaskModal";
        } else if (task.cur_lane == 'COMP_FPR_FOLLOWUP_ASSIGNMENT' || task.cur_lane =='COMP_FPR_FOLLOWUP_COMPLETE') {
            modal = "triggerFollowupModal";
        } else {
            modal = "triggerTaskApprovalModal";
        }


        console.log('this.state.userrole taskDataModal ==>', this.state.userRole);
        Store.updateStore('followup_task', task1);
        Store.updateStore('cur_q_modal_data', task);
        Store.updateStore('updated_followups', result.result.childtask.result);

        this.setState({showModal: modal, ready: true, isLoadedIcon: false});
    };


    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = {command: 'get_subtask', task_id: task_id};
        this.setState({isLoadedIcon: true})
        this.api.command(postData, this.openFollowupModal);
    };

    //open for edit modal
    showEditFollowUpModal = (task_id) => (event) => {
        event.preventDefault();
        let postData = {command: 'get_subtask', task_id: task_id};
        this.setState({isLoadedIcon: true})
        this.api.command(postData, this.openFollowupEditModal);
    };
    openFollowupModal = (result) => {
        console.log('openFollowupModal==>',result)
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
    };


    openFollowupEditModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_edit_modal', isLoadedIcon: false});
    };

    advanceFiltershow = (val) => this.setState({advanceFilter: val});

    closeModal = (event) => this.setState({showModal: null});

    closeModal = () => {
        this.setState({showModal: null});
        this.props.closeUserDialog()
    }

    switchToTaskModal = (data) => {
        this.setState({showModal: null}, () => this.setState({
            showModal: 'triggerTaskModal',
            isEditedFromReviewRequired: data
        }));
    }

    closeModalAndRefresh = () => {
        this.setState({showModal: null});
        this.props.closeUserDialog(true)
    };


    render() {
        if (!this.state.ready) return <div><CSLLoader/></div>;
        return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                {
                    (() => {
                        if (this.state.showModal !== null) {
                            switch (this.state.showModal) {
                                case 'triggerTaskApprovalModal':
                                    return <TaskApprovalModal closeModalAndRefresh={this.closeModalAndRefresh}
                                                              closeModalAndRefreshNormal={this.closeModal}/>;
                                case 'triggerTaskModal':
                                    return <TaskModal closeModal={this.closeModal}/>;
                                case 'triggerFollowupModal':
                                    return <FollowupModal closeModal={this.closeModal}/>;
                            }
                        }
                    })()
                }

            </div>
        )
    }
}

export default generciTasks;