import React from 'react';
import styled from 'styled-components';
import * as Style from '../../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import Utils from '../../../Common/Utils.js';
import Dropzone from '../../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import '../../../App.css';
import AlertBox from '../../Common/AlertBox';
import CSLLoader from '../../Common/CSLLoader';

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalContainertop = styled.div`
    display: grid;
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 5px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;
const ModalBody = styled.div`
    padding: 10px 20px 10px 20px;
    overflow-y: scroll;
    height: calc(65vh - 20px);
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f7f7f7;
`;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 2px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:10px;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
`;

class ApprovarTask extends React.Component
{
	state = {
		task_id: 0,
		task_name: "",
		description: "",
		assign_to: 0,
		cur_assigned_to: 0,
		comments: '',
		completed_date: new Date(),
		binFiles: [],
		cur_files_json: null,
		ready: false,
		is_completed: false,
		alert_param: null
	};
	constructor(props) {
	    super(props);
	}
	closeModal = (event) => {
	    event.preventDefault();
	    this.props.closeUserDialog();
	}
	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}
	getDate = (input_date) => {
	  if(input_date != null){
	    let dateStr = input_date.split('T')[0];
	    let dateParts = dateStr.split('-'); 
	    let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
	    return ret;
	  }else{
	    return '';
	  }
	 
	}
	processEditTaskData = (result) => {
		let list_contacts = Store.getStoreData('list_contacts')
		console.log("result", result);
		let task = result.result.parenttask;
		let task_json = JSON.parse(task.task_json);
		let task_name = task_json.object_data.name;
		let description = ('description' in task_json.object_data) ? task_json.object_data.description : '';
		if(Utils.isBase64(description)){
		  description = window.decodeURIComponent(atob(description))
		}

		let due_date = this.getDate(task.due_date);
		if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let cur_assigned_to = task_json.object_data.cur_assigned_to;
        let cur_files_json = task_json.object_data.bin_files;
        let binFiles = task_json.object_data.bin_files;
        let is_completed = task.cur_lane === 'COMP_GENERIC_COMPLETED' ? true : false;
        let comments = 'comments' in task_json.object_data ? task_json.object_data.comments : "";
		this.setState({ assign_to: task.cur_assigned_to,task_name: task_name,description:description,due_date:due_date,binFiles:binFiles,cur_files_json:cur_files_json,task_id:task.id,is_completed:is_completed,comments: comments, ready: true, cur_assigned_to: cur_assigned_to});
	}

	processTaskData = () => {
		let task_id = this.props.task_id;
		let list_contacts = Store.getStoreData('list_contacts')
		let postData = { "command" : "get_task", "task_id" : task_id }
		postData['gcs'] = Store.getStoreData("gc_filters")
		let api = new APICall();
		api.command(postData, this.processEditTaskData);
	}

	filesLoaded = (jsonObj) => {
	    let contact_id = Store.getStoreData('contact_id');
	    let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
	    let binFiles = this.state.binFiles;

	    console.log("binFiles", binFiles)
	    console.log("jsonObj", jsonObj)

	    let file_exists = {};
	    let curBinFiles = [];

	    if(jsonObj.command === 'add'){
	        for(let i=0; i < files_to_show.length; i++) {
	            if(typeof files_to_show[i].name !== "undefined"){
	                files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
	                if(!('user_id' in files_to_show[i])) {
	                    files_to_show[i].user_id = contact_id;
	                    files_to_show[i].upload_date = Date.now();
	                }
	                if(typeof file_exists[files_to_show[i].name] === "undefined"){
	                    binFiles.push(files_to_show[i]);
	                    file_exists[files_to_show[i].name] = true;
	                }
	            }

	        }
	        curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
	        this.setState({binFiles: curBinFiles});
	    }else{
	        for(let i=0; i < binFiles.length; i++) {
	            if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
	                binFiles.splice(i,1);
	            }
	        }
	        this.setState({binFiles: binFiles});
	    }
	}
	onDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({due_date: date});
	}
	completeGeneralTask = (submit_form) => (event) => {
	    if(this.state.comments === '') {
	        alert('ERROR: Comments cannot be blank.');
	        return false;
	    }
	    let bin_files = this.state.binFiles;
	    let postData = { "command": "complete_task", 
	            task_name:this.state.task_name, 
	            submit:submit_form, 
	            bin_files: bin_files, 
	            task_id: this.state.task_id,
	            comments: this.state.comments,
	            assign_to: this.state.cur_assigned_to,
	            approval: true
	        };
	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processCompletdTask);
	    
	}

	processCompletdTask = (result) => 
	{
		let alert_param = {title: 'Task complete', message: 'Your task has been completed.', ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})
	}

	RemoveTask = (task_id) => (event) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
		                   alertHandler: this.removeTaskProcessor, stack: {task_id: task_id}}
		this.setState({alert_param: alert_param})
	}

	removeTaskProcessor = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let task_id = stack.task_id;
        let postData = {command: "remove_task", task_id: task_id};
		let api = new APICall();
		api.command(postData, this.reloadTask);
        this.setState({alert_param: null})
    }

	reloadTask = (result) => {
		let alert_param = {title: 'Task remove', message: 'Task has been removed successfully.', ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})
	}

	alertremoveHandler = (result, stack) => {
		this.props.closeUserDialog(true);
	}

	render()
	{
		if(!this.state.ready) {
		    return <div><CSLLoader /></div>;
		}
		return (<div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
			<ModalContainer>
			    <Style.ModalHeaderText>Approval Task</Style.ModalHeaderText>
			    
			    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    <div style={{clear: "both"}}></div>
			    <ModalBody>
			        <ModalBodyInner>
			            <ModalContainertop>
			                <ModalNameLabelDiv>Task Name</ModalNameLabelDiv>
			                <ModalNameInputDiv>
			                    <Style.ModalInputReadonly name="task_name" value={this.state.task_name.replace(/'+/g,"'")} readOnly={true} />
			                </ModalNameInputDiv>
			            </ModalContainertop>
			            <ModalContainertop>
			                <ModalNameLabelDiv>Description</ModalNameLabelDiv>
			                <ModalNameInputDiv>
			                    <div dangerouslySetInnerHTML={{__html: this.state.description}} style={{textAlign: "justify", padding: "10px"}}></div>
			                </ModalNameInputDiv>
			            </ModalContainertop>
			            <ModalContainertop>
			            	<ModalNameLabelDiv>Due Date</ModalNameLabelDiv>
			            	<ModalNameInputDiv>
			            		<div>{this.state.due_date}</div>
			            	</ModalNameInputDiv>
			            </ModalContainertop>

			            <ModalContainertop>
			                <ModalNameLabelDiv>Assigned By</ModalNameLabelDiv>
			                <ModalNameInputDiv>
			                    <div>{Store.getStoreData('contacts')[this.state.cur_assigned_to.toString()]}</div>
			                </ModalNameInputDiv>
			            </ModalContainertop>

			            <ModalContainertop>
			                <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
			                <ModalNameInputDiv>
			                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} addnew={!this.state.is_completed} />
			                </ModalNameInputDiv>
			            </ModalContainertop>

			            <ModalContainertop>
			                <ModalNameLabelDiv>Comments</ModalNameLabelDiv>
			                <ModalNameInputDiv>
			                   <Style.ModalTextAreaInvisible name="comments" value={this.state.comments} onChange={this.handleChange} />
			                </ModalNameInputDiv>
			            </ModalContainertop>

			        </ModalBodyInner>
			    </ModalBody>
			    <Style.CancelSubmitButtonOuterContainer>
			        <Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			            {
			            	(() => {
			            		if(!this.state.is_completed) {
			            			return(<Style.SubmitButton onClick={this.completeGeneralTask(1)}>Complete</Style.SubmitButton>)
			            		}

			            	})()
			            }

			            {
			            	(() => {
			            		if(Store.getStoreData('role') === 'admin_manager' && this.state.task_id > 0) {
			            			return <Style.SubmitButton style={{backgroundColor: "#e13751"}}  onClick={this.RemoveTask(this.state.task_id)}>Remove</Style.SubmitButton>
			            		}
			            	})()
			            }
			            
			        </Style.CancelSubmitButtonOuterContainer>
			    </Style.CancelSubmitButtonOuterContainer>
			</ModalContainer>
			<AlertBox alertParam = {this.state.alert_param} />
				</div>)
	}
}

export default ApprovarTask;