import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from './../../Common/Store';

class HtmlEditor extends React.Component
{    
    render()
    {
        let content = this.props.content;   
        //console.log("content++", content)
        return (
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    data={content}
                    config={{ link: {defaultProtocol: 'http://', addTargetToExternalLinks: true}, removePlugins: ['Table', 'BlockQuote', 'ImageUpload', 'Indent', 'MediaEmbed'] }}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const linkCommand = editor.commands.get( 'link' );
                        const model = editor.model;
                        const selection = model.document.selection;
                        const linkHref = selection.getAttribute( 'linkHref' );
                        if(linkHref !== undefined){
                            if (linkHref.indexOf("http://") == 0 || linkHref.indexOf("https://") == 0) {
                                console.log("The link has http or https.");
                            }else{
                                alert("Please ensure that you have included the full URL. It should begin with https:// or similar.")
                                return false
                            }
                        }

                        const data = editor.getData();
                        //console.log("data:", data);   
                        if('sectionID' in this.props && this.props.sectionID === 1){
                            Store.updateStore('third_party_details', data);
                        }else{
                            Store.updateStore('incident_details', data);
                        }
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }
}
export default HtmlEditor;