import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import { MdModeEdit,MdDelete } from "react-icons/md";
import moment from 'moment';
import FollowupTaskModal from './FollowupTaskModal';
import CSLLoader from './../Common/CSLLoader';
import AMFollowupTask from './AMFollowupTask';
import AlertBox from '../Common/AlertBox';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:20px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 15px 15px;
    background-color: #04ADA8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;

const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

class AMFollowupListsModal extends React.Component
{
    state = {
        ready: false,
        listFollowupTask: [],
        showFollowupModal: null,
        isLoadedIcon: false,
        showModal: false,
        alert_param: null,
        review_list: []
    };

    componentDidMount()
	{
        let listFollowupTask = Store.getStoreData('listFollowupTask');
        let review_list = Store.getStoreData('reviewee_list');
        let com_roles = Store.getStoreData('userroles');
        let gc_users = [];
        for(let i=0;i<review_list.length;i++){
            if (com_roles[review_list[i].ID.toString()] !== 'no_access' ) {
                gc_users.push({ID: review_list[i].ID, ContactName: review_list[i].ContactName});
            }
        }
        this.setState({listFollowupTask, review_list: gc_users, ready: true})
    }

    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_ATTEST_URL;
              }else{
                window.location = '/';
              }
        } else {
            this.props.closeModal();
        }
    }

    processMyfollowupTaskData = () => {
        var taskdata = this.state.listFollowupTask;
        //console.log("taskdata",taskdata)

        let gc_users = Store.getStoreData('gc_users');
        let has_gc = Store.getStoreData('has_gc');
        let role = Store.getStoreData('role');
        let update_taskdata = [];
        if(role === 'am' && has_gc === true){
            taskdata.forEach((item) => {
                let curassignto = item.cur_assigned_to.toString();
                if(curassignto in gc_users){
                    update_taskdata.push(item);
                }
            })
        }else{
            update_taskdata = taskdata;
        }

        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 150, headerStyle: {textAlign: 'left'}},
                        {Header: 'Notes', accessor: 'notes', minWidth: 300, headerStyle: {textAlign: 'left'}},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 100, headerStyle: {textAlign: 'left'}},
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton onClick={this.goToFollowTaskUrl(row.original.id)}>Action</MRButton>
                                {
                                    (() => {
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><MdModeEdit /></div>
                                            <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.customDeleteFollowupTaskAlert(row.original.id)}><MdDelete /></div>
                                            </div>)
                                        }
                                    })()
                                }
                            </div>
                         ), width: 180, headerStyle: {textAlign: 'left'}}
                    ];
        for(let d of update_taskdata) {
            i++;
            let tdata = d.task_json.object_data;

            let status = "Pending";
            if(d.cur_lane === 'COMP_ATTESTATION_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.cur_lane === 'COMP_ATTESTATION_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }

            let assigntoName = Store.getStoreData('contacts')[d.cur_assigned_to]
            let notes = tdata.note;

            let today = moment().unix();
            let text = (d.due_date+86400) > today ? status : 'Overdue';
            let color = (d.due_date+86400) > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = (d.due_date+86400) > today ? status : 'Overdue';
                color = (d.due_date+86400) > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }

            let elem = {
                'index' : i,
                'id': d.task_id,
                'assign_to' : assigntoName,
                'notes' : notes,
                'due_date' : moment.unix(d.due_date).format('DD/MM/YYYY'),
                'status' : {text: text, color: color},
                'status_value': text
               };
            ret.data.push(elem);
        }
        ret.data.sort(function(a,b) {return (a.assign_to > b.assign_to) ? 1 : ((b.assign_to > a.assign_to) ? -1 : 0);} );
        return ret;
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_single_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editFollowupModal);
    }
    editFollowupModal = (result) => {
        console.log("followup_task",result.result.task);
        Store.updateStore('ftask_index', 0);
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showModal: true})
    }
    customDeleteFollowupTaskAlert = (taskid) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.deleteFollowupTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }

    deleteFollowupTask = (result, stack) => {
        //event.preventDefault();
        //if (window.confirm('Are you sure you want to delete this record?')) {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log('ROWS', stack.taskid);
        let postData = {command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData",postData)
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.processRemoveFollowupData);
        //}
    }

    processRemoveFollowupData = (result) => {
        //alert('Follow-up task deleted successfully');
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Follow-up task deleted successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customAMFollowupDeleteAlert, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        /*let taskid = Store.getStoreData('masterFollowupID');
        let postData = { "command" : "get_all_followuptask", "task_id" : taskid }
        let api = new APICall();
        api.command(postData, this.updateFollowups);*/
    }
    customAMFollowupDeleteAlert = (result, stack) => {
        this.setState({alert_param: null});
        let taskid = Store.getStoreData('masterFollowupID');
        let postData = { "command" : "get_all_followuptask", "task_id" : taskid }
        let api = new APICall();
        api.command(postData, this.updateFollowups);
    }

    updateFollowups = (result) => {
        console.log('task result', result);
        Store.updateStore('listFollowupTask', result.followupTasks)
        this.setState({isloadericon: false, listFollowupTask: result.followupTasks, showFollowupModal: null, showModal: false});
    }

    goToFollowTaskUrl = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.openFollowupModal);
    }

    openFollowupModal = (result) => {
        console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showFollowupModal: 'view_followup_modal', isLoadedIcon: false});
    }

    closeModal = (event) => {
        this.setState({showFollowupModal: null, showModal: false})
    }

    EditfollowupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);
    }

    processEditFollowupTask = (result) => {
        //alert("The follow-up task has been updated.");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customAMFollowupUpdateAlert, stack: {}};
        this.setState({alert_param: alert_param, isLoadedIcon: false});
        /*let taskid = Store.getStoreData('masterFollowupID');
        let postData = { "command" : "get_all_followuptask", "task_id" : taskid }
        let api = new APICall();
        api.command(postData, this.updateFollowups);*/
    }
    customAMFollowupUpdateAlert = (result, stack) => {
        console.log(result,stack);
        this.setState({alert_param: null});
        let taskid = Store.getStoreData('masterFollowupID');
        let postData = { "command" : "get_all_followuptask", "task_id" : taskid };
        let api = new APICall();
        api.command(postData, this.updateFollowups);
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        return (
            <div>
            {
                (()=> {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
            <AlwaysScrollToView />
                <MRModalHeader>
                    <MRModalHeaderText>Attestation - Follow up Tasks</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>These are the current active follow up tasks associated with this Attestation.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow-up Tasks</div>
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        {
                            (() => {
                                if(this.state.showModal) {
                                    return(<AMFollowupTask revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeModal} EditrefreshTaskValue={this.EditfollowupTaskValueFetch}/>);
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.listFollowupTask.length !== 0) {
                                    return(
                                        <OverviewInfoBody>
                                            <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="8" refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    )
                                }else {
                                    return(
                                    <OverviewInfoBody>
                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no follow-up tasks assigned.</div>
                                    </OverviewInfoBody>
                                    );
                                }
                            })()
                        }
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
            {
                (() => {
                    if (this.state.showFollowupModal !== null) {
                        switch(this.state.showFollowupModal) {
                            case 'view_followup_modal' : return <FollowupTaskModal closeModal={this.closeModal} />; break;
                        }
                    }
                })()
            }
            <AlertBox alertParam={this.state.alert_param} />
            </div> );
    }
}

export default AMFollowupListsModal;
