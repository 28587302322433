import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import CSLTable from '../Common/CSLTable';
import { IoMdCreate, IoIosClose, IoMdAdd } from "react-icons/io";

import { FaPen } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';

const CSLDateCover = styled.div`
  margin-top: 5px;
  width: 40%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #282828;
  background-color: #ffffff;
  height: 30px;
  padding-top: 2px;
  padding-left: 2px;
  float: left;
  position: relative;
`;
const CSLtableDate = styled.div`
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: #282828;
	background-color: #ffffff;
	height: 30px;
	position: relative;
	`;
const SchemeDateCover = styled.div`
	margin-top: 4px;
	width: 100%;
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: #282828;
	background-color: #ffffff;
	height: 25px;
	padding-left: 2px;
	float: right;
	position: absulate;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 0px;
`;
const MRButton = styled.button`
	width: 33px;
	font-size: 20px;
	height: 28px;
	margin-left: 10px;
	cursor: pointer
`;

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;

class MangeTask extends React.Component
{
	state = {
		task: {},
		task_id: 0,
		task_name: "",
		description: "",
		assign_to: 0,
		completed_date: new Date(),
		due_date: new Date(),
		show_due_date: '',
		binFiles: [],
		cur_files_json: null,
		ready: false,
		alert_param: null,
		makecomplete: false,
		comments: "",
		repeate_task: false,
		frequency: "monthly",
		schedule_date: new Date(),
		scheme_id: 0,
		show_scheme: false,
		hint_tasks: {},
		edit_hint: false,
		single_hint: {},
		is_recurring: false,
		is_overdue: false,
		row_id: 0,
		show_calander: false,
		calender_date: '',
		tableindex: 0,
		isOpen: false,
		id_due_change: false,
		edit_due: null
	};
	constructor(props) {
	    super(props);
	}
	closeModal = (event) => {
	    event.preventDefault();
	    if(this.state.makecomplete) {
	    	this.setState({makecomplete: false})
	    }else{
	    	window.location.href = "/";
	    }

	}
	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	handleSchemeChange = (event) => {
		event.preventDefault();
		let scheme_id = event.target.value;
		if(scheme_id !=="0"){
	        let postData = {command: "get_scheme", id: scheme_id};
			let api = new APICall();
			api.command(postData, this.SchemeTask);
		}else{
			this.setState({show_scheme: false, scheme_id: scheme_id})
		}
	}
	SchemeTask = (result) => {
		console.log("result scheme", result)
		let list_contacts = Store.getStoreData('list_contacts')
		let scheme_json = JSON.parse(result.scheme_json);
		let used_tasks = scheme_json.used_tasks;
		let hint_tasks = {}
		let date = new Date()
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
		for(let key in used_tasks) {
			console.log("used", used_tasks[key])
			hint_tasks[key.toString()] = {"id": key, "name" : used_tasks[key].name, "description": used_tasks[key].description, "frequency": used_tasks[key].frequency, is_recurring: true, assign_to: list_contacts[0].ID, schedule_date: dateStr, first_schedule_date: dateStr, is_delete: false}
		}
		console.log("hint_tasks", hint_tasks)
		this.setState({show_scheme: true, scheme_id: result.id, hint_tasks: hint_tasks})
	}

	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}
	processEditTaskData = (result) => {
		let list_contacts = Store.getStoreData('list_contacts')
		console.log("result ===>", result);
		let task = result.result.parenttask;
		let task_json = JSON.parse(task.task_json);
		let task_name = task_json.object_data.name;
		let description = task_json.object_data.description;
		let due_date = new Date(task.due_date);
		let show_due_date = this.getDate(task.due_date);
		if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let cur_files_json = task_json.object_data.bin_files;
        let binFiles = task_json.object_data.bin_files;
        let is_recurring = ('is_recurring' in task_json.object_data) ? task_json.object_data.is_recurring : false;
        let is_overdue = task.cur_lane === "COMP_GENERIC_COMPLETED" ? false : true;
		this.setState({task:task, assign_to: task.cur_assigned_to,task_name: task_name,description:description,due_date:due_date,show_due_date: show_due_date,binFiles:binFiles,cur_files_json:cur_files_json,task_id:task.id, ready: true, is_recurring: is_recurring, is_overdue: is_overdue});
	}

	processTaskData = () => {
		let task_id = this.props.task_id;
		let list_contacts = Store.getStoreData('list_contacts')
		if(task_id > 0) {
			let postData = { "command" : "get_task", "task_id" : task_id }
			let api = new APICall();
			api.command(postData, this.processEditTaskData);
		}else{
			this.setState({ assign_to: list_contacts[0].ID, ready: true});
		}
	}

	filesLoaded = (jsonObj) => {
	    let contact_id = Store.getStoreData('contact_id');
	    let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
	    let binFiles = this.state.binFiles;

	    console.log("binFiles", binFiles)
	    console.log("jsonObj", jsonObj)

	    let file_exists = {};
	    let curBinFiles = [];

	    if(jsonObj.command === 'add'){
	        for(let i=0; i < files_to_show.length; i++) {
	            if(typeof files_to_show[i].name !== "undefined"){
	                files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
	                if(!('user_id' in files_to_show[i])) {
	                    files_to_show[i].user_id = contact_id;
	                    files_to_show[i].upload_date = Date.now();
	                }
	                if(typeof file_exists[files_to_show[i].name] === "undefined"){
	                    binFiles.push(files_to_show[i]);
	                    file_exists[files_to_show[i].name] = true;
	                }
	            }

	        }
	        curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
	        this.setState({binFiles: curBinFiles});
	    }else{
	        for(let i=0; i < binFiles.length; i++) {
	            if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
	                binFiles.splice(i,1);
	            }
	        }
	        this.setState({binFiles: binFiles});
	    }
	}
	onDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({due_date: date});
	}
	onscheduleDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({schedule_date: date});
	}
	createScheduleTask = (event) => {
		event.preventDefault();
		if(this.state.task_name === '') {
		    let mgs = "ERROR: Task name cannot be blank.";
		    let title = "ERROR";
		    let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
		    this.setState({alert_param: alert_param})
		    return false;
		}
		if(this.state.frequency === "0") {
			let mgs = "ERROR: Please select a frequency.";
			let title = "ERROR";
			let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
			this.setState({alert_param: alert_param})
			return false;
		}

		let curmonth_sched = parseInt(this.state.schedule_date.getMonth());
		curmonth_sched = curmonth_sched + 1;
		var schedDateStr = curmonth_sched >= 10 ? this.state.schedule_date.getFullYear()+'/'+curmonth_sched+'/' : this.state.schedule_date.getFullYear()+'/0'+curmonth_sched+'/';
		schedDateStr += this.state.schedule_date.getDate() < 10 ? '0' + this.state.schedule_date.getDate() : this.state.schedule_date.getDate();
	    let bin_files = this.state.binFiles;
	    let assign_to = this.state.assign_to;
	    let loggedincontact = Store.getStoreData('loggedincontact');
	    if(Store.getStoreData('role') === "team") {
			assign_to = loggedincontact.contact_id;
		}
	    /*let postData = { "command": "add_schedule",
	            task_name:this.state.task_name,
	            bin_files: bin_files,
	            task_id: this.state.task_id,
	            description: this.state.description,
	            assign_to: assign_to,
	            schedule_date: schedDateStr,
	            frequency: this.state.frequency,
	            is_schedule: this.state.repeate_task,
	            is_recurring: true
	        };*/
	    let id =  Utils.genKey(5);
	    let hint_tasks = {};
	    let single_hint = {};
	    single_hint['id'] = id;
	    single_hint['name'] = this.state.task_name;
	    single_hint['description'] = this.state.description;
	    single_hint['frequency'] = this.state.frequency;
	    single_hint['is_recurring'] = true;
	    single_hint['assign_to'] = assign_to;
	    single_hint['schedule_date'] = schedDateStr;
	    single_hint['first_schedule_date'] = schedDateStr;
	    single_hint['is_delete'] = false;

	    hint_tasks[id.toString()] = single_hint;


    	// let hint_tasks = this.state.hint_tasks;
        let postData = {
        					"command": "create_mcc_scheme",
                			hint_tasks:hint_tasks,
                			bin_files: bin_files
                		};
	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processAddScheduleTask);

	}
	processAddScheduleTask = (result) => {
			let mgs = "New scheduled tasks have been created";
			let title = "New task";
			let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
			this.setState({alert_param: alert_param})
	}
	createGeneralTask = (submit_form) => (event) => {
	    if(this.state.task_name === '') {
	        let mgs = "ERROR: Task name cannot be blank.";
	        let title = "ERROR";
	        let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
	        this.setState({alert_param: alert_param})
	        return false;
	    }

	    let curmonth = parseInt(this.state.due_date.getMonth());
	    let currmonth = curmonth + 1;
	    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
	    dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
	    let bin_files = this.state.binFiles;
	    let assign_to = this.state.assign_to;
	    let loggedincontact = Store.getStoreData('loggedincontact');
	    if(Store.getStoreData('role') === "team") {
			assign_to = loggedincontact.contact_id;
		}
	    let postData = { "command": "add_task",
	            task_name:this.state.task_name,
	            submit:submit_form,
	            bin_files: bin_files,
	            task_id: this.state.task_id,
	            description: this.state.description,
	            assign_to: assign_to,
	            due_date: dueDateStr
	        };
	    let d = this.getDate(this.state.task['due_date']);
	    let x = Utils.checkIssamedate(dueDateStr, d)
	    postData['is_due_change'] = x;
	    let api = new APICall();

	    // console.log("postData", postData);
	    api.command(postData, this.processAddTask);

	}

	processAddTask = (result) =>
	{
		let mgs = "New task has been created.";
		let title = "New task";
	    if(result.submit === 1) {
	        mgs = "New task has been created";
	        title = "New task";
	    }else if(result.submit === 2) {
	    	mgs = "Task has been updated.";
	    	title = "Task update";
	    }else{
	    	mgs = "The task has been updated.";
	    	title = "Task Saved";
	    }

		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})

	}

	RemoveTask = (task_id) => (event) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
				                   alertHandler: this.removeTaskProcessor, stack: {task_id: task_id}}
		let is_overdue = this.state.is_overdue;
		let is_recurring = this.state.is_recurring;
		let ref_id = this.state.task.ref_id;
		let schedule_date = this.state.task.due_date;
		if(is_overdue && is_recurring) {
			alert_param = {title: 'Confirm', message: 'Tasks already scheduled are not completed. Are you sure want to delete this activity?', ok_text: 'Yes',cancel_text: 'No', confirm: true,
			alertHandler: this.removeTaskProcessor, stack: {task_id : task_id, ref_id : ref_id, schedule_date: schedule_date} , continue_to_next_screen_if_yes: true ,
			next_messages: [{value : 1 , msg : 'Delete all the future tasks starting from this one?'},{value : 2 , msg : 'Delete only this particular instance?'}] ,
			option_type: 'Radio'};
		}
		this.setState({alert_param: alert_param});



		// let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
		//                    alertHandler: this.removeTaskProcessor, stack: {task_id: task_id}}
		// this.setState({alert_param: alert_param})
	}

	removeTaskProcessor = (result, stack) => {

        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let task_id = stack.task_id;
        let option = ('option_value' in stack) ? stack.option_value : 0;

        let postData = {command: "remove_task", task_id: task_id};
        if(option == 1 && stack.ref_id != ''){
        	console.log("stack", stack, result, option)
        	postData['command'] = "remove_recurring_task";
        	postData['ref_id'] = stack.ref_id;
        	postData['schedule_date'] = stack.schedule_date;
        }
        console.log("postData", postData)
		let api = new APICall();
		api.command(postData, this.reloadTask);
        this.setState({alert_param: null})
    }

    alertremoveHandler = (result, stack) => {
    	let url = "/";
    	window.location = url;
    }

	reloadTask = (result) => {

		// alert('Task has been removed successfully.');
		let alert_param = {title: 'Success', message: 'This task has been removed.', ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})
	}

	makeComplete = (event) => {
		event.preventDefault()
		let makecomplete = this.state.makecomplete ? false : true;
		this.setState({makecomplete: makecomplete})
	}

	completeGeneralTask = (submit_form) => (event) => {
	    if(this.state.comments === '') {
	        let mgs = "ERROR: Comments cannot be blank.";
	        let title = "ERROR";
	        let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
	        this.setState({alert_param: alert_param})
	        return false;
	    }
	    let curmonth = parseInt(this.state.completed_date.getMonth());
	    let currmonth = curmonth + 1;
	    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
	    dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
	    let bin_files = this.state.binFiles;
	    let postData = { "command": "complete_task",
	            task_name:this.state.task_name,
	            submit:submit_form,
	            bin_files: bin_files,
	            task_id: this.state.task_id,
	            comments: this.state.comments,
	            completed_date: dueDateStr
	        };
	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processCompletdTask);

	}

	processCompletdTask = (result) =>
	{
		let alert_param = {title: 'Task complete', message: 'Your task has been completed.', ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})
	}

	getDate = (input_date) => {
	  if(input_date != null){
	    let dateStr = input_date.split('T')[0];
	    let dateParts = dateStr.split('-');
	    let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
	    return ret;
	  }else{
	    return '';
	  }

	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {repeate_task} = this.state;
		repeate_task = repeate_task ? false : true;
		this.setState({repeate_task})
	}

	ChangeRecurring = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].is_recurring = hint_tasks[id].is_recurring ? false : true;
		hint_tasks[id].frequency = hint_tasks[id].is_recurring ? hint_tasks[id].frequency : 'once';
		this.setState({hint_tasks: hint_tasks})
	}

	handleAssignChange = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].assign_to = event.target.value
		this.setState({hint_tasks: hint_tasks})
	}
	handleFrequencyChange = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].frequency = event.target.value
		this.setState({hint_tasks: hint_tasks})
	}

	onSchemeDateChange = (id) => (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    let hint_tasks = this.state.hint_tasks;
	    hint_tasks[id].schedule_date = dateStr;
	    hint_tasks[id].first_schedule_date = dateStr;
	    this.setState({hint_tasks: hint_tasks, show_calander: false})
	}

	viewParam = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		let hint_task = hint_tasks[id]
		hint_task["id"] = id;
		this.setState({edit_hint: true, single_hint: hint_task})
	}
	RemoveParam = (id) => (event) => {
		event.preventDefault();
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
		                   alertHandler: this.removeSchemeTask, stack: {id: id}}
		this.setState({alert_param: alert_param})
		// let hint_tasks = this.state.hint_tasks;
		// let hint_task = hint_tasks[i]
	}

	removeSchemeTask = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let id = stack.id;
        let hint_tasks = this.state.hint_tasks;
        delete hint_tasks[id];
        this.setState({hint_tasks: hint_tasks, alert_param: null})
	}

	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	handlehintChange = (event) => {
		let single_hint = this.state.single_hint;
		single_hint[event.target.name] = event.target.value;
		console.log("single_hint", single_hint)
		this.setState({single_hint: single_hint})
	}

	closeScheme = (event) => {
		this.setState({edit_hint: false, single_hint: {}})
	}
	updateSchemeTask = (event) => {
		let alert_param = {title: 'Success', message: 'Your scheme task has been saved', ok_text: 'Ok', cancel_text: 'No', confirm: false,
		                   alertHandler: this.loadSchemeTask, stack: {id: 0}}

		let hint_tasks = this.state.hint_tasks;
		let single_hint = this.state.single_hint;
		let id = single_hint.id;
		hint_tasks[id] = single_hint;

		// console.log("hint_tasks", single_hint)
		if(single_hint.name === "") {
			alert_param = {title: 'Alert', message: 'ERROR: Task name cannot be blank.', ok_text: 'Ok', confirm: false,alertHandler: this.loadSchemeTask, stack: {id: 0}}
			this.setState({alert_param: alert_param})
			return false
		}

		this.setState({alert_param: alert_param,hint_tasks: hint_tasks, edit_hint: false, single_hint: {} })
	}
	loadSchemeTask = (result, stack) => {
		if(!result || stack.prevent) {
		    this.setState({alert_param: null})
		    return
		}
		this.setState({alert_param: null})
	}




	createRecurringTask = (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		let l = Object.keys(hint_tasks).length;
		let alert_param = {title: 'Confirm', message: 'Clicking OK will confirm that '+l+' tasks are scheduled and, where enabled, emails will be sent to all contacts with new tasks assigned', ok_text: 'OK',cancel_text: 'Cancel', confirm: true,
		alertHandler: this.createRecurring, stack: {id: 0}};
		this.setState({alert_param: alert_param});

	}

	createRecurring = (result, stack) => {
		if(!result || stack.prevent) {
		    this.setState({alert_param: null})
		    return
		}
		this.setState({alert_param: null})
		let hint_tasks = this.state.hint_tasks;
	    let postData = { "command": "create_mcc_scheme",
	            			hint_tasks:this.state.hint_tasks
	            		};
	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processRecurringTask);
	}




	processRecurringTask = (result) =>
	{
		let hint_tasks = this.state.hint_tasks;
		let l = Object.keys(hint_tasks).length;
		let alert_param = {title: 'Success', message: 'Tasks are scheduled and, where enabled, emails will be sent to all contacts with new tasks assigned', ok_text: 'OK', cancel_text: 'No', confirm: false,
		                   alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})
	}

	addsubTask = (event) => {
		let date = new Date()
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
		let list_contacts = Store.getStoreData('list_contacts')
		let single_hint = {};
		single_hint['id'] = Utils.genKey(5);
		single_hint['name'] = '';
		single_hint['description'] = '';
		single_hint['frequency'] = 'monthly';
		single_hint['is_recurring'] = true;
		single_hint['assign_to'] = list_contacts[0].ID;
		single_hint['schedule_date'] = dateStr;
		single_hint['first_schedule_date'] = dateStr;
		single_hint['is_delete'] = false;
		this.setState({edit_hint: true, single_hint: single_hint});
	}


	processschemeData = () => {
		let tasks = this.state.hint_tasks;
		let frequency = Utils.list_shedules();
		// console.log("tabledata", tabledata);
		let ret = {data: [], columns: []};
		let i = 0;
		ret.columns =[
		                {Header: 'Name', accessor: 'name', width: 100, headerStyle: {textAlign: 'left'}},
		                {Header: 'Description', accessor: 'description', minWidth: 150, headerStyle: {textAlign: 'left'}},
		                 {'Header' : 'Recurring task', Cell: row => (
		                    <div>
		                        <input type="checkbox" checked={row.original.is_recurring} onChange={this.ChangeRecurring(row.original.id)} />
		                    </div>
		                 ), width: 120, headerStyle: {textAlign: 'left'}},
		                {Header: 'Assigned To', Cell: row => (
		                    <div>
                    				<Style.MRModalSelect style ={{height: "30px"}} name="assign_to" value={row.original.assign_to} onChange={this.handleAssignChange(row.original.id)}>
    			                    {
    			                        Store.getStoreData('list_contacts').map((item) => {
    			                            {
    			                                return (
    			                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
    			                                );
    			                            }
    			                        })
    			                    }
    			                    </Style.MRModalSelect>
		                    </div>
		                 ), width: 100, headerStyle: {textAlign: 'left'}},
		                {Header: 'First Schedule Date', Cell: row => (
			                    <CSLtableDate onClick={this.viewCalender(row.original.id, row.original.schedule_date, row.viewIndex)}>
	                    			<DatePicker
	                    	          clearIcon={null}
	                    	          calendarIcon={null}
	                    	          locale={"en-GB"}
	                    	          value={row.original.schedule_date}
	                    	        />
							</CSLtableDate>
		                 ), width: 150, headerStyle: {textAlign: 'left'}},
		                {Header: 'Frequency', Cell: row => (
		                    <div>
                				<Style.MRModalSelect style ={{height: "30px"}} name="frequency" value={row.original.frequency} onChange={this.handleFrequencyChange(row.original.id)} >
			                    {
			                        frequency.map((item) => {
			                            {
			                                return (
			                                    <option key={item.id} value={item.name}>{item.nickname}</option>
			                                );
			                            }
			                        })
			                    }
			                    </Style.MRModalSelect>
		                    </div>
		                 ), width: 120, headerStyle: {textAlign: 'left'}},
		                {'Header' : 'Action', Cell: row => (
		                   <div>
		                       <ViewDiv onClick={this.viewParam(row.original.id)}><FaPen style={{ float: "right", marginRight: "14px" }}/></ViewDiv>
		                       <ViewDiv onClick={this.RemoveParam(row.original.id)}><MdDelete style={{ float: "right", marginRight: "14px" }}/></ViewDiv>
		                   </div>
		                ), width: 75, headerStyle: {textAlign: 'left'}}];

		for(let key in tasks) {
		    i++;
		    let elem = {
		    	index : i,
		        id: key,
		        name : tasks[key].name,
		        description : tasks[key].description,
		        is_recurring : tasks[key].is_recurring,
		        frequency:  tasks[key].frequency,
		        assign_to: tasks[key].assign_to,
		        schedule_date: tasks[key].schedule_date === "" ? this.state.schedule_date : new Date(tasks[key].schedule_date),
		        show_schedule_date: tasks[key].schedule_date
		    };
		    ret.data.push(elem);
		}
		return ret;
	}
	viewCalender = (row_id, date, index) => (event) => {
		event.preventDefault();
	    this.setState({row_id: row_id, show_calander: true, calender_date: date, tableindex:index, isOpen: true})
	}

	HideDate = (index) => (event) => {
		this.setState({show_calander: false, tableindex:index})
	}

	closePicker = () => {
		// event.preventDefault();
	    this.setState({
	      isOpen: false
	    });
	  }

	  /*selectDate(date) {
	    this.setState({
	      selectedDate: date
	    });
	  }*/

	  selectDate = (id) => (date) => {
	      let curmonth = parseInt(date.getMonth());
	      let currmonth = curmonth + 1;
	      var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	      let hint_tasks = this.state.hint_tasks;
	      hint_tasks[id].schedule_date = dateStr;
	      hint_tasks[id].first_schedule_date = dateStr;
	      this.setState({hint_tasks: hint_tasks, show_calander: false, isOpen: false})
	  }




	render()
	{
		if(!this.state.ready) {
		    return <div><CSLLoader /></div>;
		}
		let task =  this.state.task;
		let loggedincontact = Store.getStoreData('loggedincontact').contact_id;
		let created_by = task.contact_id;
		let assign_to = task.cur_assigned_to;
		let frequency = Utils.list_shedules();
		let role = Store.getStoreData('role');
		let schemes = Store.getStoreData('select_schemes');
		let enabled_task_scheme = Store.getStoreData('enabled_task_scheme');
		console.log("enabled_task_scheme", enabled_task_scheme)
		return (<div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
			<Style.ModalContainer>
			    <Style.ModalHeaderText>
			    {
			    	(() => {
			    		if(this.state.task_id === 0) {
			    			return "Create Task";
			    		}else{
			    			return "Update Task";
			    		}
			    	})()
			    }
			    </Style.ModalHeaderText>

			    {
			    	(() => {
			    		if(this.state.show_scheme && this.state.edit_hint){
			    			return <Style.ModalHeaderCloseBtn onClick={this.closeScheme}><FaTimes /></Style.ModalHeaderCloseBtn>
			    		}else{
			    			return <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    		}
			    	})()
			    }

			    <div style={{clear: "both"}}></div>
			    <Style.ModalBody>
			        <Style.ModalBodyInner>
			            <Style.ModalContainertop>
		                {
		                	(() => {
		                		if(this.state.task_id === 0 && (role === "admin_manager" || role === "team_manager") && enabled_task_scheme && !this.state.edit_hint) {
		                			return(<div style={{display: "contents"}}>
		                				<Style.ModalNameLabelDiv>Select Scheme</Style.ModalNameLabelDiv>
		                				<Style.ModalNameInputDiv>
			                				<Style.MRModalSelect name="scheme_id" value={this.state.scheme_id} onChange={this.handleSchemeChange}>
			                				<option key="0" value="0">Select a scheme</option>
						                    {
						                        schemes.map((item) => {
						                            {
						                                return (
						                                    <option key={item.id} value={item.id}>{item.name}</option>
						                                );
						                            }
						                        })
						                    }
						                    </Style.MRModalSelect>
		                				</Style.ModalNameInputDiv>
		                			</div>)
		                		}
		                	})()
		                }

		                {
		                	(() => {
		                		if(this.state.show_scheme){
		                			return (<div style={{display: "contents"}}>

		                						{
		                							(() => {
		                								if(this.state.edit_hint){
		                									return(<div style={{display: "contents"}}>
		                										<Style.ModalNameLabelDiv>Task Name</Style.ModalNameLabelDiv>
		                										<Style.ModalNameInputDiv>
		                										    <Style.ModalInput name="name" value={this.state.single_hint.name.replace(/'+/g,"'")} onChange={this.handlehintChange} />
		                										</Style.ModalNameInputDiv>
		                										<Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
		                										<Style.ModalNameInputDiv>
		                										    <Style.ModalTextArea name="description" value={this.state.single_hint.description.replace(/'+/g,"'")} onChange={this.handlehintChange} />
		                										</Style.ModalNameInputDiv>
								                				<Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
								                					<Style.ModalNameInputDiv>
										                				<Style.MRModalSelect name="frequency" value={this.state.single_hint.frequency} onChange={this.handlehintChange}>
													                    {
													                        frequency.map((item) => {
													                            {
													                                return (
													                                    <option key={item.id} value={item.name}>{item.nickname}</option>
													                                );
													                            }
													                        })
													                    }
													                    </Style.MRModalSelect>
												                    </Style.ModalNameInputDiv>
		                										</div>)
		                								}else{
		                									return(<div style={{display: "contents"}}>
		                										<Style.ModalNameLabelDiv>Tasks</Style.ModalNameLabelDiv>
		                										<Style.ModalNameInputDiv>
		                											<CSLTable add={false} processData={this.processschemeData} tableRows={10} headerText={'MCC Scheme'} subtask={true} addsubTask={this.addsubTask}/>
		                											{
		                												(() => {
		                													if(this.state.show_calander) {
		                													    // console.log('this.state.x, this.state.y', this.state.x, this.state.y)
		                													    const startDate = null;
		                													    return (
		                													    	<Style.MemberMenu style={{top: `${300 + (this.state.tableindex*41)}px`, right: 10}} onMouseLeave={this.HideDate(this.state.row_id)} >
		                													                    	<div>
		                													                    		<Style.MemberMenuLine>
		                															                    	<DatePicker
		                													                    	          onChange={this.onSchemeDateChange(this.state.row_id)}
		                													                    	          locale={"en-GB"}
		                													                    	          clearIcon={null}
		                													                    	          calendarIcon={null}
		                													                    	          value={this.state.calender_date}
		                													                    	          isOpen={true}

		                													                    	        />
		                												                    	        </Style.MemberMenuLine>
		                											                    	        </div>

		                													                </Style.MemberMenu>

		                													            )
		                													}
		                												})()
		                											}
		                										</Style.ModalNameInputDiv>
		                									</div>)
		                								}

		                							})()
		                						}

		                					</div>)
		                		}else{
		                			return(<div style={{display: "contents"}}>
							                <Style.ModalNameLabelDiv>Task Name</Style.ModalNameLabelDiv>
							                <Style.ModalNameInputDiv>
							                    <Style.ModalInput name="task_name" value={this.state.task_name.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.makecomplete} />
							                </Style.ModalNameInputDiv>
							                <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
							                <Style.ModalNameInputDiv>
							                    <Style.ModalTextArea name="description" value={this.state.description.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.makecomplete}/>
							                </Style.ModalNameInputDiv>

							                {
							                	(() => {
							                		if(Store.getStoreData('role') !=='team') {
							                			return(<div style={{display: "contents"}}>
					                				                <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
					                				                <Style.ModalNameInputDiv>
					                				                {
					                				                	(() => {
					                				                		if(this.state.makecomplete) {
					                				                			return <div>{Store.getStoreData('contacts')[this.state.assign_to.toString()]}</div>
					                				                		}else{
					                				                			return(
					                					                				<Style.MRModalSelect name="assign_to" value={this.state.assign_to} onChange={this.handleChange}>
					                								                    {
					                								                        Store.getStoreData('list_contacts').map((item) => {
					                								                            {
					                								                                return (
					                								                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
					                								                                );
					                								                            }
					                								                        })
					                								                    }
					                								                    </Style.MRModalSelect>
					                				                    			)
					                				                		}
					                				                	})()
					                				                }
					                				                </Style.ModalNameInputDiv>
							                				</div>)
							                		}
							                	})()
							                }

							                {
							                	(() => {
							                		if(this.state.task_id === 0) {
							                			return(<div style={{display:"contents"}}>
							                				<Style.ModalNameLabelDiv>Repeat this task </Style.ModalNameLabelDiv>
							                				<Style.ModalNameInputDiv>
							                					{
							                						(() => {
							                							if (this.state.repeate_task) {
							                								return (
							                									<div>
							                										<SelectedRadio>Yes</SelectedRadio>
							                										<UnselectedRadio onClick={this.switchRadio('repeate_task')}>No</UnselectedRadio>
							                									</div>
							                								);
							                							} else {
							                								return (
							                									<div>
							                										<UnselectedRadio onClick={this.switchRadio('repeate_task')}>Yes</UnselectedRadio>
							                										<SelectedRadio>No</SelectedRadio>
							                									</div>
							                								);
							                							}
							                						})()
							                					}
							                				</Style.ModalNameInputDiv>
							                				</div>)
							                		}
							                	})()
							                }


							                {
							                	(() => {
							                		if(this.state.repeate_task) {
							                			return(<div style={{display:"contents"}}>
							                				<Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
							                					<Style.ModalNameInputDiv>
									                				<Style.MRModalSelect name="frequency" value={this.state.frequency} onChange={this.handleChange}>
												                    {
												                        frequency.map((item) => {
												                            {
												                                return (
												                                    <option key={item.id} value={item.name}>{item.nickname}</option>
												                                );
												                            }
												                        })
												                    }
												                    </Style.MRModalSelect>
											                    </Style.ModalNameInputDiv>
											                    <Style.ModalNameLabelDiv>First scheduled<span style={{color:"#ff474a"}} title="Schedule date must be greter than current date">*</span></Style.ModalNameLabelDiv>
				                                				<CSLDateCover>
				                				                    <DatePicker
				                				                              onChange={this.onscheduleDateChange}
				                				                              clearIcon={null}
				                				                              calendarIcon={null}
				                				                              locale={"en-GB"}
				                				                              value={this.state.schedule_date}
				                				                            />
				                				                </CSLDateCover>
							                				</div>)
							                		} else {
							                			return(<div style={{display:"contents"}}>
					                				                <Style.ModalNameLabelDiv>Due Date</Style.ModalNameLabelDiv>
					                				                {
					                				                	(() => {
					                				                		if(this.state.makecomplete) {
					                				                			return <Style.ModalNameInputDiv>{this.state.show_due_date}</Style.ModalNameInputDiv>
					                				                		}else{
					                				                			return(
					                				                				<CSLDateCover>
					                								                    <DatePicker
					                								                              onChange={this.onDateChange}
					                								                              clearIcon={null}
					                								                              calendarIcon={null}
					                								                              locale={"en-GB"}
					                								                              value={this.state.due_date}
					                								                            />
					                								                </CSLDateCover>)
					                				                		}
					                				                	})()
					                				                }
							                				</div>)
							                		}
							                	})()
							                }



							                <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
							                <Style.ModalNameInputDiv style={{paddingTop: "0px"}}>
							                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} />
							                </Style.ModalNameInputDiv>


							                {
							                	(() => {
							                		if(this.state.makecomplete) {
							                			return(
							                				<div style={{display: "contents"}}>
											                    <Style.ModalNameLabelDiv>Comments</Style.ModalNameLabelDiv>
											                    <Style.ModalNameInputDiv>
											                        <Style.ModalTextArea name="comments" value={this.state.comments.replace(/'+/g,"'")} onChange={this.handleChange} />
											                    </Style.ModalNameInputDiv>
											                </div>
											              )
							                		}
							                	})()
							                }
		                			</div>)
		                		}

		                	})()
		                }

			            </Style.ModalContainertop>
			        </Style.ModalBodyInner>
			    </Style.ModalBody>

			    {
			    	(() => {
			    		if(this.state.show_scheme){
			    			return(<Style.CancelSubmitButtonOuterContainer>
			    				{
			    					(() => {
			    						if(this.state.edit_hint){
			    							return(<div>
			    								<Style.CancelButton onClick={this.closeScheme}>Back</Style.CancelButton>
			    								<Style.SubmitButton onClick={this.updateSchemeTask}>Save</Style.SubmitButton>
			    							</div>)
			    						}else{
			    							return(<div>
			    								<Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			    								<Style.SubmitButton onClick={this.createRecurringTask}>Submit</Style.SubmitButton>
			    							</div>)
			    						}
			    					})()
			    				}

			    			</Style.CancelSubmitButtonOuterContainer>)
			    		}else{
			    			return(<Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			            {
			            	(() => {
			            		if(this.state.task_id === 0) {
			            			if(this.state.repeate_task){
			            				return <Style.SubmitButton onClick={this.createScheduleTask}>Submit</Style.SubmitButton>
			            			}else{
			            				return <Style.SubmitButton onClick={this.createGeneralTask(1)}>Submit</Style.SubmitButton>
			            			}
			            		}else{
			            			if(this.state.makecomplete) {
			            				return (<Style.SubmitButton onClick={this.completeGeneralTask(1)}>Submit</Style.SubmitButton>)
			            			}else{
			            				if(Store.getStoreData('role') === 'admin_manager') {
			            					return (<div style={{display:"contents"}}>
			            						<Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.createGeneralTask(2)}>Update</Style.SubmitButton>
			            						<Style.SubmitButton style={{backgroundColor: "#04ada8"}} onClick={this.makeComplete}>Complete</Style.SubmitButton>
			            						<Style.SubmitButton style={{backgroundColor: "#e13751"}} onClick={this.RemoveTask(this.state.task_id)}>Remove</Style.SubmitButton>
			            					</div>)
			            				}else{
											if(parseInt(loggedincontact) === parseInt(created_by)){
			            						return (<div style={{display:"contents"}}>
			            						<Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.createGeneralTask(2)}>Update</Style.SubmitButton>
			            						<Style.SubmitButton style={{backgroundColor: "#04ada8"}} onClick={this.makeComplete}>Complete</Style.SubmitButton>
			            						</div>)
			            					} else if(parseInt(loggedincontact) === parseInt(assign_to)){
			            						return <Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.makeComplete}>Complete</Style.SubmitButton>
			            					}
			            				}
			            			}
			            		}
			            	})()
			            }

			    </Style.CancelSubmitButtonOuterContainer>)
			    		}
			    	})()
			    }
			    <CSLLoader />
			</Style.ModalContainer>
			<AlertBox alertParam = {this.state.alert_param} />
				</div>)
	}
}

export default MangeTask;
