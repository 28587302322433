import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaStarOfLife} from 'react-icons/fa';
import APICall from './../TAPICall';
import Store from './../../../../Common/Store';
import Dropzone from './../Common/Dropzone';
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import {FaCalendarAlt} from 'react-icons/fa';
import CSLTextInput from './../Common/CSLTextInput';
import moment from 'moment';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const SpacerS = styled.div`
    height: 5px;
`;
const CSLDateCover = styled.div`   
    margin-top: 8px;
    width: 40%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #282828;
    background-color: #ffffff;
    height: 25px;
    padding-top: 2px;
    padding-left: 2px;
    // float: left;
    position: relative;
`;

class GMAskGiftModal extends React.Component
{
    state = {
        giftStatus: "received",
        recipientlist: [],
        recipientName: "",
        recipientNameAll: "",
        isOtherRecipientName: false,
        donorName: "",
        giftTypeid: 0,
        giftDescription: "",
        giftTypeList: [],
        currency: "",
        minvalue: 0,

        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        companyID:0,
        due_date: '1970-01-01',
        isdesable: false,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        filterAlldatacheck: {},
        currentcompanyName: "",
        isLoadedIcon: false,
        giftedDate: new Date(),
        assign_data: null
    };
    
    componentDidMount()
	{
        let index = this.props.q_index === null ? 0 : this.props.q_index;        
        console.log('index in componentDidMount', index);        
        let contracts = this.props.contacts;
        let contactsArray = [];
        let role = this.props.role;
        if(role === "admin_manager" || role === "manager"){
            let otherObject = {id: "Other", name: "Other"}        
            contactsArray.push(otherObject);
        }
        let recipientlist = contactsArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
                
        console.log("contactsArray==>",recipientlist);
        Store.updateStore('recipientlistUpdate', recipientlist);
        let giftTypeList = [];
        if('gift_types' in this.props.module_config){
            let giftTypeListValue = this.props.module_config.gift_types;
            for(let d of giftTypeListValue){
                if(d.isActive !== 0){
                    giftTypeList.push(d);
                }
            }
        }
        let minimumValue = 0;
        if('general' in this.props.module_config && 'companyCurrencyDetails' in this.props.module_config.general){
            minimumValue = this.props.module_config.general.companyCurrencyDetails.minvalue;
        }
        let currencyValue = "";
        if('general' in this.props.module_config && 'companyCurrencyDetails' in this.props.module_config.general){
            currencyValue = this.props.module_config.general.companyCurrencyDetails.currency;
        }

        //custom field start
        let module_config = this.props.module_config;        
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) { 
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        //copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        } 

        console.log("additional_fields===>", additional_fields)
        //custom field end


        let gc_company = this.props.gc_company;
        let gc_companies = this.props.gc_companies;
        let cur_gc_id = 0;
        let is_gc = false;        
        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = this.props.cur_q_modal_data;
            console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);            
            //let curgc_id = task_json.task_data.group_company_id;
            let curgc_id = form_data.company_id
            let sampleNumber = curgc_id;
            let lastDigitcurgc_id = sampleNumber % 100000;
            console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
            let gc_company = this.props.gc_company;
            let is_gc = false;
            let companyUsers = {};
            let currentcompanyName = "";
            for(let gc of gc_company) {                
                if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                    is_gc = gc.is_gc;
                    console.log("gc.id", gc.id);
                    currentcompanyName = gc.company_name
                    companyUsers = gc.users;
                }
            }
            //new       
            console.log("companyUsers==>", companyUsers)     
            let contactsArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        contactsObject.id = contracts[d];
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);
                    }
                }) 
            }
            let recipientlistold = Store.getStoreData('recipientlistUpdate');
            for(let i=0;i<contactsArray.length;i++){
                recipientlistold.push(contactsArray[i])
            }        
            //console.log("contactsArray==>==>", recipientlistold) 
            let recipientlistnew = recipientlistold.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            //new


            let cur_gc_id = parseInt(lastDigitcurgc_id)
            let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
            
            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = "uid" in item ? item.uid : this.genKey(10);
                })
            }
            let due_date = new Date(form_data.due_date);

            let isOtherRecipientName = false;
            if(task_json.object_data.headerinfo.check_recipient_value === 'Other'){
                isOtherRecipientName = true;
            }

            let filterAlldatacheck = {};
            filterAlldatacheck.cur_gc_id = lastDigitcurgc_id;
            filterAlldatacheck.recipientName = task_json.object_data.headerinfo.check_recipient_value;
            filterAlldatacheck.donorName = task_json.object_data.headerinfo.donor_name;
            filterAlldatacheck.giftTypeid = task_json.object_data.headerinfo.gift_typeid;
            filterAlldatacheck.giftDescription = task_json.object_data.headerinfo.gift_description;

            let giftedDate = 'giftedDate' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.giftedDate) : new Date();

            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : form_data.cur_lane === 'COMP_GIFTS_TEAMSAVE' ? additional_fields : [];

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                recipientlist: recipientlistnew,
                giftTypeList: giftTypeList,
                currency: currencyValue !== "" ? currencyValue : this.props.currencyDetails.currency,
                giftStatus: task_json.object_data.headerinfo.gift_status,
                recipientName: task_json.object_data.headerinfo.check_recipient_value,
                recipientNameAll: task_json.object_data.headerinfo.recipient_name,
                isOtherRecipientName: isOtherRecipientName,
                donorName: task_json.object_data.headerinfo.donor_name,
                giftTypeid: task_json.object_data.headerinfo.gift_typeid,
                minvalue: task_json.object_data.headerinfo.minvalue,
                giftDescription: task_json.object_data.headerinfo.gift_description,
                curlane: form_data.cur_lane,
                taskid: form_data.id, gc_companies: gc_companies, gc_company: this.props.gc_company, ready: true, 
                cur_gc_id: lastDigitcurgc_id, is_gc: is_gc, 
                companyID: companyID, due_date: due_date,cur_files_json: task_json.object_data.bin_files, filterAlldatacheck: filterAlldatacheck, currentcompanyName: currentcompanyName,
                giftedDate,
                assign_data: assign_data});
                //console.log("lastDigitcurgc_id", lastDigitcurgc_id,"is_gc",is_gc);
        }else{            
            let due_date = new Date();

            let gc_company = this.props.gc_company;
            let userrole = this.props.role;
            let update_gc_company = [];
            if(userrole === 'gm' && 'general' in this.props.module_config && 'show_parent_company_gifts' in this.props.module_config.general && this.props.module_config.general.show_parent_company_gifts === false){
                for(let gc of gc_company) {                
                    if(parseInt(gc.id) !== parseInt(this.props.companyID)){
                        update_gc_company.push(gc)
                    }
                }
            }else{
                update_gc_company = gc_company;
            }

            let companyUsers = {};
            if(!this.props.has_gc){                
                companyUsers = update_gc_company[0].users;    
                cur_gc_id = update_gc_company[0].id;                 
            }
            let contactsArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        contactsObject.id = contracts[d];
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);
                    }
                }) 
            }
            let recipientlistold = Store.getStoreData('recipientlistUpdate');
            for(let i=0;i<contactsArray.length;i++){
                recipientlistold.push(contactsArray[i])
            }        
            
            let recipientlistnew = recipientlistold.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 

            assign_data.additional_fields = additional_fields;

            this.setState({ Ref_ID:"Not Yet Generated", gc_company: update_gc_company, gc_companies: gc_companies, 
                ready: true, 
                companyID: cur_gc_id, 
                cur_gc_id: cur_gc_id,
                is_gc: is_gc,due_date: due_date,recipientlist: recipientlistnew,giftTypeList: giftTypeList,
                currency: currencyValue !== "" ? currencyValue : this.props.currencyDetails.currency,
                minvalue: minimumValue,
                assign_data: assign_data});
            //console.log("this.generateRefID=",this.generateRefID())		
        }        
    }

    handleChangeCurGc = (evt) => {
        let gc_companies = this.props.gc_companies;
        let gc_company = this.props.gc_company;
        let is_gc = false;
        let companyUsers = {};
        for(let gc of gc_company) {
            //gc.id = (gc.id * 100000) + gc_company.id;
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;                
                companyUsers = gc.users;
            }
        }

        let contracts = this.props.contacts;        
        let contactsArray = [];
        if(Object.keys(companyUsers).length !== 0){
            Object.keys(companyUsers).map((d, index) => {
                if(d in contracts){
                    let contactsObject = {}
                    contactsObject.id = contracts[d];
                    contactsObject.name = contracts[d];
                    contactsArray.push(contactsObject);
                }
            }) 
        }
        let recipientlist = Store.getStoreData('recipientlistUpdate');
        for(let i=0;i<contactsArray.length;i++){
            recipientlist.push(contactsArray[i])
        }        
        //console.log("contactsArray==>==>", recipientlist) 
        let recipientlistnew = recipientlist.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 

        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID, recipientlist: recipientlistnew});
        // this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc});
        //console.log("CURGCID", this.state.cur_gc_id);
    }

    handleChangeRecipient = (evt) => {
        if(evt.target.value != 'Other'){
           this.setState({recipientName: evt.target.value,recipientNameAll: evt.target.value,isOtherRecipientName: false}) 
        }else{
            this.setState({isOtherRecipientName: true, recipientName: evt.target.value, recipientNameAll: ""})
        }
    }
    
    closeView = () => {        
        if(this.props.q_index >= 0) {
            console.log('filterAlldatacheck',this.state.filterAlldatacheck)
            if(parseInt(this.state.cur_gc_id) !== this.state.filterAlldatacheck.cur_gc_id || this.state.recipientName !== this.state.filterAlldatacheck.recipientName || this.state.donorName !== this.state.filterAlldatacheck.donorName || this.state.giftTypeid.toString() !== this.state.filterAlldatacheck.giftTypeid.toString() || this.state.giftDescription !== this.state.filterAlldatacheck.giftDescription){
                if (window.confirm('All data will be lost if you exit the screen, do you want to continue?')){
                    this.props.closeUserDialog();
                }
            }else{
                this.props.closeUserDialog();
            }
        }else{
            if(parseInt(this.state.cur_gc_id) !== 0 || this.state.recipientName !== "" || this.state.donorName !== "" || this.state.giftTypeid.toString() !== "0" || this.state.giftDescription !== ""){
                if (window.confirm('All data will be lost if you exit the screen, do you want to continue?')){
                    this.props.closeUserDialog();
                }                
            }else{
                this.props.closeUserDialog();
            }
        }
    }    

    handleAdditionalROD = (event) => {
        console.log(event.target.value)
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.enabled = event.target.value;
        if(event.target.value === 'No'){
            additional_records_of_disclosure.description = "";
        }
        this.setState({ additional_records_of_disclosure })        
    }
    handleThiredParty = (event) => {
        console.log(event.target.value)
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.enabled = event.target.value;
        if(event.target.value === 'No'){
            disclosure_to_third_party.description = "";
        }
        this.setState({ disclosure_to_third_party })        
    }

    submitRegisterGift = (submit_form) => (event) => {
        event.preventDefault();
        let company_id = this.state.companyID;
        const  module_config = this.props.module_config;
        if(submit_form === 1) {
            console.log("minvalue",parseInt(this.state.minvalue),"module_config",parseInt(this.props.module_config.general.companyCurrencyDetails.minvalue))
            if(this.state.cur_gc_id === 0){
                alert('Please select company.'); 
                return;
            }
            if(this.state.recipientName === "") {                
                alert('Please select recipient name.');     
                return;                            
            }
            if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
                alert('Please add name of other recipient.');
                return; 
            }
            if(this.state.giftStatus === "received" && this.state.donorName === ""){
                alert('Please add donor name.');
                return; 
            }
            if(this.state.giftTypeid.toString() === "0"){
                alert('Please select gift type.');
                return; 
            }
            if(this.state.giftDescription === ""){
                alert('Please provide a description of the gift/hospitality.');
                return;
            }
            if(module_config.custom_fields.enabled){
                let fields = [];
                let is_numeric = false;
                let numericFields = [];
                if (module_config.custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {
                        if(item.displayFor === this.state.giftStatus){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                            if(item.value){
                                if(item.type === 'Numeric') {
                                    if(item.value.length > 0) {
                                        if(isNaN(item.value)){
                                            is_numeric = true;
                                            numericFields.push(item.nickname);
                                        }
                                    }
                                }
                            }
                        }                        
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ", ";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    if (fields.length === 1) {
                        err_msg = err_msg + " field is mandatory. ";
                    } else {
                        err_msg = err_msg + " fields are mandatory. ";
                    }                    
                    alert(err_msg);
                    return;
                }
                if(is_numeric) {
                    console.log("Sourav2")
                    let err_msg_new = "";
                    numericFields.forEach((item) => {
                        err_msg_new = err_msg_new + item + ", ";
                    })
                    err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                    if (numericFields.length === 1) {
                        err_msg_new = "Please put the numeric vaule in the "+ err_msg_new + " field. ";
                    } else {
                        err_msg_new = "Please put the numeric vaule in the "+ err_msg_new + " fields. ";
                    }                    
                    alert(err_msg_new);
                    return;                
                } 
            } 
            if(parseInt(this.state.minvalue) < parseInt(this.props.module_config.general.companyCurrencyDetails.minvalue) && !window.confirm('This value is less that the minimum value of reportable gifts/hospitality. Do you want to proceed?')){                
                this.setState({cur_gc_id:null,giftStatus: "received",recipientName: "",recipientNameAll: "",donorName: "",giftTypeid: 0,giftDescription: "",}) 
                return;              
            }
                               
                    let headerinfo = {};
                    headerinfo.gift_status = this.state.giftStatus;
                    headerinfo.recipient_name = this.state.recipientNameAll;
                    headerinfo.donor_name = this.state.donorName;
                    headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                    headerinfo.currency = this.state.currency;
                    headerinfo.minvalue = this.state.minvalue;
                    headerinfo.gift_description = this.state.giftDescription;
                    headerinfo.check_recipient_value = this.state.recipientName;
                    headerinfo.giftedDate = this.state.giftedDate;
                    headerinfo.additional_fields = this.state.assign_data.additional_fields;

                    let final_object_data = {};
                    final_object_data.headerinfo = headerinfo; 

                   let curmonth = parseInt(this.state.due_date.getMonth());
                    let currmonth = curmonth + 1;
                    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
                    dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 

                    let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                    console.log("bin_files==>",bin_files)
            
                    let task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),            
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr, 
                        'group_company_id': this.state.cur_gc_id},
                        "object_data": final_object_data, "group_company_id": company_id,
                        "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","performed_by": 4616 }
                    };
                    console.log("task jason", JSON.stringify(task_json));
                    let postData = {command: "create_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form, role: this.props.role,
                    is_gc: this.state.is_gc,
                    gc_company_id: this.state.cur_gc_id};
                    console.log("postData",postData)
                    this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
                    let api = new APICall();
                    api.command(postData, this.processGiftsData);               
        
                       
        } else {            
                let userrole = this.props.role;
                if(userrole === 'gm' && this.state.cur_gc_id === 0){
                    alert('Please select company.'); 
                }else{
                    let headerinfo = {};
                    headerinfo.gift_status = this.state.giftStatus;
                    headerinfo.recipient_name = this.state.recipientNameAll;
                    headerinfo.donor_name = this.state.donorName;
                    headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                    headerinfo.currency = this.state.currency;
                    headerinfo.minvalue = this.state.minvalue;
                    headerinfo.gift_description = this.state.giftDescription;
                    headerinfo.check_recipient_value = this.state.recipientName;
                    headerinfo.giftedDate = this.state.giftedDate;
                    headerinfo.additional_fields = this.state.assign_data.additional_fields;

                    let final_object_data = {};
                    final_object_data.headerinfo = headerinfo; 
                    let curmonth = parseInt(this.state.due_date.getMonth());
                    let currmonth = curmonth + 1;
                    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
                    dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

                    let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                    console.log("bin_files==>",bin_files)
                
        
                let task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),            
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr, 'group_company_id': this.state.cur_gc_id},
                    "object_data": final_object_data,"group_company_id": company_id,
                    "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","performed_by": 4616 }
                };
                console.log("task jason", JSON.stringify(task_json));
                let postData = {command: "create_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form,
                is_gc: this.state.is_gc, 
                gc_company_id: this.state.cur_gc_id };
                console.log("postData",postData)
                this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
                let api = new APICall();
                api.command(postData, this.processGiftsData);
            }
        }  
    }
    processGiftsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){
            alert('Gifts registered successfully.');
        }else {
            alert('The gift has been saved. It is available to be modified and added to the register.');
        }        
        window.location.reload();
    }

    updateRegisterGift = (submit_form) => (event) => {
        event.preventDefault();
        let company_id = this.state.companyID;      
        const  module_config = this.props.module_config;  
        if(submit_form === 1) {
            if(this.state.cur_gc_id === 0){
                alert('Please select company.'); 
                return;
            }
            if(this.state.recipientName === "") {                
                 alert('Please select recipient name.');  
                 return;                               
            }
            if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
                alert('Please add name of other recipient.'); 
                return;
            }
            if(this.state.giftStatus === "received" && this.state.donorName === ""){
                alert('Please add donor name.'); 
                return;
            }
            if(this.state.giftTypeid.toString() === "0"){
                alert('Please select gift type.');
                return; 
            }
            if(this.state.giftDescription === ""){
                alert('Please provide a description of the gift/hospitality.');
                return
            }
            if(module_config.custom_fields.enabled){
                let fields = [];
                let is_numeric = false;
                let numericFields = [];
                if (module_config.custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {
                        if(item.displayFor === this.state.giftStatus){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                            if(item.value){
                                if(item.type === 'Numeric') {
                                    if(item.value.length > 0) {
                                        if(isNaN(item.value)){
                                            is_numeric = true;
                                            numericFields.push(item.nickname);
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ", ";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    if (fields.length === 1) {
                        err_msg = err_msg + " field is mandatory. ";
                    } else {
                        err_msg = err_msg + " fields are mandatory. ";
                    }                    
                    alert(err_msg);
                    return;
                }
                if(is_numeric) {
                    console.log("Sourav2")
                    let err_msg_new = "";
                    numericFields.forEach((item) => {
                        err_msg_new = err_msg_new + item + ", ";
                    })
                    err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                    if (numericFields.length === 1) {
                        err_msg_new = "Please put the numeric vaule in the "+ err_msg_new + " field. ";
                    } else {
                        err_msg_new = "Please put the numeric vaule in the "+ err_msg_new + " fields. ";
                    }                    
                    alert(err_msg_new);
                    return;                
                } 
            } 
            if(parseInt(this.state.minvalue) < parseInt(this.props.module_config.general.companyCurrencyDetails.minvalue) && !window.confirm('This value is less that the minimum value of reportable gifts/hospitality. Do you want to proceed?')){                
                this.setState({cur_gc_id:null,giftStatus: "received",recipientName: "",recipientNameAll: "",donorName: "",giftTypeid: 0,giftDescription: "",})
                return;               
            }
                                
                let headerinfo = {};
                headerinfo.gift_status = this.state.giftStatus;
                headerinfo.recipient_name = this.state.recipientNameAll;
                headerinfo.donor_name = this.state.donorName;
                headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                headerinfo.currency = this.state.currency;
                headerinfo.minvalue = this.state.minvalue;
                headerinfo.gift_description = this.state.giftDescription;
                headerinfo.check_recipient_value = this.state.recipientName;
                headerinfo.giftedDate = this.state.giftedDate;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo; 
                //final_object_data.ref_id = this.state.Ref_ID;

                let curmonth = parseInt(this.state.due_date.getMonth());
                let currmonth = curmonth + 1;
                var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
                dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 
                let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                console.log("bin_files==>",bin_files)
                    
                    let task_json = {};
                    if(submit_form === 1) {
                        task_json = {
                            "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), 
                            'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER',
                            "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
                            "group_company_id": company_id},
                            "object_data": final_object_data,
                            "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER"}
                        };
                }else{
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), 
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE',
                        'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0,'due_date' : dueDateStr,
                        'group_company_id': company_id},
                        "object_data": final_object_data,
                        "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
                    };
                } 
                console.log("task jason", JSON.stringify(task_json));
                let postData = {command: "update_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form, taskid: this.state.taskid,role: this.props.role, gc_id: company_id };
                console.log("postData",postData)
                this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
                let api = new APICall();
                api.command(postData, this.processUpdateGiftsData);
                       
        }      
        else{
            if(this.state.cur_gc_id === 0){
                alert('Please select company.');             
            }else{ 
                
                let headerinfo = {};
                headerinfo.gift_status = this.state.giftStatus;
                headerinfo.recipient_name = this.state.recipientNameAll;
                headerinfo.donor_name = this.state.donorName;
                headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                headerinfo.currency = this.state.currency;
                headerinfo.minvalue = this.state.minvalue;
                headerinfo.gift_description = this.state.giftDescription;
                headerinfo.check_recipient_value = this.state.recipientName;
                headerinfo.giftedDate = this.state.giftedDate;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo; 
                
                let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                console.log("bin_files==>",bin_files)
                
                let task_json = {};
                if(submit_form === 1) {
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), 
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER','company_id' : company_id, 'group_company_id': company_id,
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id': 0 },
                        "object_data": final_object_data,
                        "action_data": { "action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER"}
                    };
                }else{
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), 
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE', 'company_id' : company_id, 'group_company_id': company_id,
                        'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                        "object_data": final_object_data,
                        "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
                    };
                } 
                console.log("task jason", JSON.stringify(task_json));
                let postData = {command: "update_gifts_task", task_json: task_json,bin_files: bin_files, submit: submit_form, 
                taskid: this.state.taskid, gc_company_id: company_id};
                console.log("postData",postData)
                this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
                let api = new APICall();
                api.command(postData, this.processUpdateGiftsData);
            }
        }
    }

    processUpdateGiftsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){
            alert('Gift registered successfully');
        }else {
            alert('Gift updated successfully');
        }        
        window.location.reload();
    }

    handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }
    handleChangeReceiveGiven = (evt) => {    
        //custom field start
        let module_config = this.props.module_config;        
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) { 
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        } 
        //console.log("additional_fields===>", additional_fields)
        //custom field end
        let assign_data = {};
        assign_data.additional_fields = additional_fields;

        this.setState({[evt.target.name]: evt.target.value, assign_data});
    }

    onChangeGiftedDate = (date) => { 
        this.setState({giftedDate: date});
    }

    handleChangeDTTP = (evt) => {
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.description = evt.target.value;
        this.setState({ disclosure_to_third_party })
    }
    handleChangeAROD = (evt) => {
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.description = evt.target.value;
        this.setState({ additional_records_of_disclosure })   
    }

    filesLoaded = (files) => {
        let contact_id = this.props.contactID;
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;    						
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;                            
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);        
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);		
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));		
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}

    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="text" min={0} name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)    
        console.log("data_container===>", data_container)    
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }	
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.setState({assign_data: assign_data});       
    }

    createDropdownField = (data, data_container) => {
        console.log("data.value==>", data.value)
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>                                                
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }   
        let module_config = this.props.module_config;     
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <MRModalContainer> 
                    <MRModalHeader>
                        <MRModalHeaderText>Add Gifts & Hospitality Entry</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        <MRModalSubHeaderText>Fill out the below fields to fully submit your Gifts & Hospitality disclosure.</MRModalSubHeaderText>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px"}}>
                        {/* <div style={{paddingTop: '20px',marginBottom: "10px"}}>
                            <ModalNameLabelDivReference>GIFTS ID</ModalNameLabelDivReference>
                            <ModalNameLabelDivReferenceID>{this.state.Ref_ID}</ModalNameLabelDivReferenceID>
                        </div> */}
                        {
                            (() => {
                                if(this.props.q_index >= 0) {
                                    return(
                                        <div>
                                            <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                            <MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.currentcompanyName}</MRModalLabel>
                                        </div>
                                    )
                                }else{
                                    if(this.props.gc_companies.length === 0){
                                        return(
                                            <div>
                                                <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>                                        
                                                {
                                                    (() => {
                                                        if(this.state.gc_company.length !== 0){
                                                            return(<MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.gc_company[0].company_name}</MRModalLabel>)
                                                        }
                                                    })()
                                                }                                       
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>
                                                <MRModalLabel>Select Company </MRModalLabel>                                        
                                                <MRModalSelect id="select_company" name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                                <option key={0} value={0}>Select</option>
                                                    {
                                                            this.state.gc_company.map((gc) => {
                                                            return(
                                                            <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>                                       
                                            </div>
                                        )
                                    }
                                }                                
                            })()
                        }
                            <SpacerS/>  
                            <MRModalLabel>Type</MRModalLabel>
                            <MRModalSelect id='type' name="giftTypeid" onChange={this.handleChange} value={this.state.giftTypeid}>
                                <option value={0}>Select</option>
                                {
                                    this.state.giftTypeList.map((gf,index) => {
                                        return(
                                            <option key={index} value={gf.giftTypeID}>{gf.giftTypeName}</option>
                                        );
                                    })
                                }
                            </MRModalSelect>
                            <SpacerS/>
                            <MRModalLabel>Was the gift received or given?</MRModalLabel>  
                            <MRModalSelect id='received_given' name="giftStatus" onChange={this.handleChangeReceiveGiven} value={this.state.giftStatus} >
                                <option value="received">Received</option>
                                <option value="given">Given</option>   
                            </MRModalSelect> 
                            <SpacerS/>
                            <MRModalLabel>Gifted Date</MRModalLabel>
                            <CSLDateCover id="gifted_date" style={{display: "inline-block"}}>
                                <DatePicker
                                        onChange={this.onChangeGiftedDate}
                                        clearIcon={null}
                                        calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                        locale={"en-GB"}                                            
                                        value={this.state.giftedDate}
                                />
                            </CSLDateCover>
                            <SpacerS/>
                            <MRModalLabel>Recipient</MRModalLabel>  
                            <MRModalSelect id='recipient' name="recipientName" onChange={this.handleChangeRecipient} value={this.state.recipientName} >
                                <option value="">Select</option>                    
                                {
                                    this.state.recipientlist.map((rc,index) => {
                                        return(
                                        <option key={index} value={rc.id}>{rc.name}</option>
                                        );
                                    })
                                }                      
                            </MRModalSelect>
                            {
                                (() => {
                                    if(this.state.isOtherRecipientName){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Add name of other recipient</MRModalLabel>
                                            <MRModalInput id='recipient_name' type="text" name="recipientNameAll" onChange={this.handleChange} value={this.state.recipientNameAll} />
                                        </div>)
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.giftStatus === "received"){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Donor</MRModalLabel>
                                            <MRModalInput id='donor' type="text" placeholder="Enter donor name..." name="donorName" onChange={this.handleChange} value={this.state.donorName} />
                                        </div>)
                                    }
                                })()
                            }
                            <SpacerS/>
                            <MRModalLabel>Value</MRModalLabel>
                            <MRModalInput style={{display: "inline-block",width: "10%"}} value={this.state.currency} readOnly/>
                            <MRModalInput style={{width: "10%",marginLeft: "30px"}} id='value' type="text" name="minvalue" onChange={this.handleChange} value={this.state.minvalue} /> 
                            <SpacerS/>
                            {/* custom field start */}
                            {
                                (() => {
                                    if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                        if (this.state.assign_data.additional_fields.length !== 0) {
                                            let custom_html = [];
                                            this.state.assign_data.additional_fields.forEach((item) => {
                                                if(item.displayFor === this.state.giftStatus){
                                                    const field = this.generateField(item, "additional_fields");
                                                    custom_html.push(field);
                                                }
                                            })
                                            return custom_html;
                                        }
                                    }
                                })()
                            }
                            {/* custom field end */}

                            <SpacerS/>
                            <MRModalLabel style={{marginTop: "30px"}}>Description</MRModalLabel> 
                            <MRModalLabel style={{fontWeight: "100",fontSize: "13px",color: "#919292"}}>Please provide a description of the gift/hospitality</MRModalLabel>                            
                            <MRModalTextarea id='description' rows="6" name="giftDescription" value={this.state.giftDescription} onChange={this.handleChange} />
                            <SpacerS/>
                            <MRModalLabel>Attachments</MRModalLabel> 
                            <ModalNameInputDiv>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                            </ModalNameInputDiv>  
                        </div>
                    </MRModalBody>
                    <MRModalFooter>
                    {
                        (() => {
                            if(this.props.q_index >= 0) {
                                if(this.state.curlane === "COMP_GIFTS_TEAMSAVE") {
                                    return(<div style={{position: "relative",padding: "17px"}}>                    
                                    <MRModalNextBtn id='submit' onClick={this.updateRegisterGift(1)} disabled={this.state.is_disabled}>Submit</MRModalNextBtn> 
                                    <MRModalSaveCloseBtn id='save_and_close' onClick={this.updateRegisterGift(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>                                    
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>);
                                }
                                return(
                                    <div style={{position: "relative",padding: "17px"}}>                                                                       
                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                    </div>
                                )
                            }else{
                                return(
                                <div style={{position: "relative",padding: "17px"}}>                    
                                    <MRModalNextBtn id='submit' onClick={this.submitRegisterGift(1)} disabled={this.state.is_disabled}>Submit</MRModalNextBtn> 
                                    <MRModalSaveCloseBtn id='save_close' onClick={this.submitRegisterGift(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>                                    
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>
                                )
                            }
                        })()
                    }  
                    </MRModalFooter>
                </MRModalContainer>
            </div>);
    }
}

export default GMAskGiftModal;