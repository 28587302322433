import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import RoleChanger from './RoleChanger';

const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;

class Permissions extends React.Component
{
	state = {permissions: null};

	componentDidMount()
	{
		console.log("this.props.permissions",this.props.permissions)
		this.setState({permissions: this.props.permissions});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({permissions: this.props.permissions});
		}
	}

	changeRole = (obj) => {
		let permissions = JSON.parse(JSON.stringify(this.state.permissions));
		permissions.company_users.forEach((user) => {
			if (user.user_id === parseInt(obj.user_id)) {
				user.role = obj.role;
			}
		});
		this.props.updatePermissions(permissions, obj);
		// this.setState({permissions});
		// console.log("Role changed");
	}

	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Permission Level', Cell: row => (
                           <RoleChanger key={row.original.user_id} roles={this.state.permissions.roles} active_role={row.original.active_role} user_id={row.original.user_id} changeRole={this.changeRole} />
						), headerStyle:{textAlign: 'left'}}];
		let companyUser = this.state.permissions.company_users.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        companyUser.forEach((user) => {
        	let elem = {user_id: user.user_id, company_id: user.company_id, name: user.name, active_role: user.role};
        	ret.data.push(elem);
        })
        return ret;
	}

	searchUsers = (event) => {
		event.preventDefault();
		this.props.searchUsers(event.target.value);
	}

	changeAllRoles = (event) => {
		event.preventDefault();
		this.props.changeAllRoles(event.target.value);
	}

	render()
	{
		if (this.state.permissions === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div style={{width: "70%"}}>
				<SearchInput placeholder="Search..." onChange={this.searchUsers} />
				<div style={{marginTop: "20px"}}>
					{/*<div style={{float: "left", boxSizing: "border-box", width: "50%", paddingRight: "10px"}}>
						<div style={{fontWeight: "600", color: "#222222", marginBottom: "10px"}}>Company</div>
						<PermissionDropdown>
							{
								this.state.permissions.gc_companies.map((cmp) => {
									return (<option key={cmp.id} value={cmp.id}>{cmp.company_name}</option>);
								})
							}
						</PermissionDropdown>
					</div>*/}
					{/*<div style={{float: "left", boxSizing: "border-box", width: "50%", paddingLeft: "10px"}}>*/}
						<div style={{fontWeight: "600", color: "#222222", marginBottom: "10px"}}>Set permission level for all chosen users</div>
						<PermissionDropdown onChange={this.changeAllRoles}>
							<option key="0" value="0">Select</option>
							{
								this.state.permissions.roles.map((role) => {
									return (<option key={role.name} value={role.name}>{role.nickname}</option>);
								})
							}
						</PermissionDropdown>
					{/*</div>*/}
					{/*<div style={{clear: "both"}}></div>*/}
				</div>
				<div style={{marginTop: "20px", backgroundColor: "#ffffff", width: "100%", borderRadius: "3px"}}>
					<div style={{fontWeight: "600", color: "#222222", padding: "20px"}}>Users</div>
					<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="20" refreshCallback={this.refreshState} />
				</div>
			</div>
		);
	}
}

export default Permissions;