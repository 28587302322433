import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import * as Style from '../Common/StyledComponents'
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import AlertBox from '../Common/AlertBox';

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;

`;

class CSLScheme extends React.Component
{
	state = { view_count: 0,  alert_param: null, scheme: [], prefered_columns: {}, show_inactive: false}
	componentDidMount(){
	    this.processScheme();
	}
	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processScheme()
	    }
	}
	processScheme = () => {
		let prefered_columns = {};
		prefered_columns['name'] = 'Scheme Name';
		prefered_columns['description'] = 'Scheme Description';
		prefered_columns['no_tasks'] = 'No. of tasks';
		this.setState({scheme: [], prefered_columns: prefered_columns})
	}
	viewTask = (id) => (event) => {
	 event.preventDefault();
	  let url = `/scheme/${id}`;
	  window.location = url;
	}


	RemoveTask = (task_id) => (event) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this scheme?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
		                   alertHandler: this.removeTaskProcessor, stack: {task_id: task_id}}
		this.setState({alert_param: alert_param})
	}

	removeTaskProcessor = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let task_id = stack.task_id;
        let postData = {command: "remove_scheme", id: task_id};
		let api = new APICall();
		api.command(postData, this.reloadTask);
        this.setState({alert_param: null})
    }

    reloadTask = (result) => {
		let alert_param = {title: 'Success', message: 'Scheme has been removed successfully..', ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
		if(result.error_code > 0) {
			alert_param['message'] = result.error_msg;
		}
		this.setState({alert_param: alert_param})
    }

    alertremoveHandler = (result, stack) => {
    	let url ="/scheme";
    	window.location = url;
    }

    Filtertabledata  = (data) => {
    	let result = [];
    	if(this.state.show_inactive){
    	  for(let d of data) {
    	    if(d.is_active === 0){
    	      result.push(d);
    	    }
    	  }
    	}else{
    	  for(let d of data) {
    	    result.push(d)
    	  }
    	}
    	return result;
    }

	processData = () => {
		let tabledata = Store.getStoreData('schemes');
		tabledata = this.Filtertabledata(tabledata);
		console.log("tabledata", tabledata);
		let ret = {data: [], columns: []};
		let i = 0;
		ret.columns =[
		                {Header: 'Scheme Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left'}},
		                {Header: 'Scheme Description', accessor: 'description', minWidth: 200, headerStyle: {textAlign: 'left'}},
		                {Header: 'No. of tasks', accessor: 'no_tasks', minWidth: 50, headerStyle: {textAlign: 'left'}},
		                {'Header' : 'Action', Cell: row => (
		                   <div>
		                       <MRButton onClick={this.viewTask(row.original.id)}>View</MRButton>
		                       <MRButton onClick={this.RemoveTask(row.original.id)}>Delete</MRButton>
		                   </div>
		                ), width: 200, headerStyle: {textAlign: 'left'}}];

		for(let d of tabledata) {
		    i++;
		    let elem = {
		        id: d.id,
		        name : d.name,
		        description : d.description,
		        no_tasks : d.parameter_numbers.toString(),
		        status: d.status
		    };
		    ret.data.push(elem);
		}
		return ret;
	}

	ChangeInactive = (event) => {
		// event.preventDefault();
		let show_inactive = this.state.show_inactive ? false : true;
		this.setState({show_inactive});
	}

	render()
	{
		let add_scheme_url = '/scheme/0';
		return (
			<IndexContainer>
				<a href={add_scheme_url}><AddRbutton>Add</AddRbutton></a>
				<div style={{padding: "10px", fontWeight: "600"}}><input type="checkbox" checked={this.state.show_inactive} onChange={this.ChangeInactive} /> Show Inactive</div>
				<CSLTable add={false} processData={this.processData} tableRows={10} headerText={'MCC Schemes'} export={true} prefered_columns={this.state.prefered_columns}/>
				<AlertBox alertParam = {this.state.alert_param} />
			</IndexContainer>
			)
	}
}
export default CSLScheme;
