import React from 'react';
import styled from 'styled-components';
const RegFullPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
`;
class RUFullPills extends React.Component
{
    render()
    {
        const doctype = this.props.doctypes;
        return (
            <RegFullPill>
                {doctype}
            </RegFullPill>
        );
    }
}
export default RUFullPills;