import React from "react";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import * as Style from '../Common/StyledComponents';
import CSLTable from '../Common/CSLTable';
import CSLCheckBox from "./CSLCheckBox";
import APICall from '../../Common/APICall.js';
import AlertBox from '../Common/AlertBox';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import HtmlEditor from '../Common/HtmlEditor';
import Store from '../../Common/Store';

const SearchInput = styled.input`
  height: 30px;
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #222222;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
`;
const MRModalLabel = styled.div`
  color: #212121;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
`;
const TypesContainer = styled.div`
  width: 100%;
`;
const TypesInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 18px;
`;
const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
  margin-right: 10px;
`;

const SelectedRadio = styled.div`
  background-color: #37ADA7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #F4F4F4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const RadioBlock = styled.div`
  margin-top: 0px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 60px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    position: fixed;
    background-color: #ffffff;
    top: 135px;
    left: calc(50vw - 155px);
    width: 704px;
    box-sizing: border-box;
    height: 400px;
    min-height: 300px;
    box-sizing: border-box;
    background-color: #F3F7FB;
    border: 1px solid #F3F7FB;
    border-radius: 5px;
    z-index: 1010;
    opacity: 1.0;
`

const ModalHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-left: 7px solid #04ADA8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`
const ModalBody = styled.div`
    width: 100%;
    padding-left: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 200px;
    min-height: 200px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    z-index: 1020;
    opacity: 1.0;
`
const ModalFooter = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const ModalHeaderTextLarge = styled.div`
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-right: 0px;
    width: calc(100% - 60px);
    color: #5D748E;
    font-size: 16px;
    font-weight: 700;
    float: left;
`
const ModalHeaderClose = styled.div`
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-left: 0px;
    width: 30px;
    color: #5D748E;
    font-size: 20px;
    font-weight: 700;
    float: right;
    cursor: pointer;
`
const ModalHeaderTextSmall = styled.div`
    padding-left: 10px;
    padding-top: 3px;
    width: calc(100% - 10px);
    color: #5D748E;
    font-size: 12px;
    font-weight: 700;
`

const ClearFloat = styled.div`
    clear: both;
`
const CancelButton = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`;
const SaveButton = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 25px;
`;
const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
    display: inline-block;
`;

const ModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;

class UpdateScheme extends React.Component {
    state = {scheme_head:{}, ready: false}
    constructor(props) {
        super(props);
    }

    setComponentState = () => {
        let scheme_head = this.props.scheme_head;
        this.setState({scheme_head:scheme_head, ready: true});
    }

    componentDidMount()
    {       
        this.setComponentState()
    }

    componentDidUpdate(prevProps)
    {
        if(this.props === prevProps)return
        this.setComponentState()
    }

    switchRadio = (value) => {
        let scheme_head = this.state.scheme_head;
        scheme_head['is_active'] = value;
        this.setState({scheme_head})
    }

    changeNickName = (event) => {
        let scheme_head = this.state.scheme_head;
        scheme_head['name'] = event.target.value;
        this.setState({scheme_head})
    }

    saveScheme = () => {
        if(this.state.scheme_head['name'].trim() === '') {
            alert('You must specify a name for the Scheme.')
            return
        }
      this.props.saveScheme(this.state.scheme_head)
    }
    render() {

        return (<div>
            <InactiveOverlay />
            <ModalContainer>
                <ModalHeader>
                    <ModalHeaderTextLarge>Update Scheme</ModalHeaderTextLarge><ModalHeaderClose onClick={this.props.closeModal}><FaTimes /></ModalHeaderClose>
                    <ClearFloat />
                    <ModalHeaderTextSmall>Complete the required fields to update a task scheme</ModalHeaderTextSmall>
                </ModalHeader>
                <ModalBody>
                <ModalLabel>Task scheme Name</ModalLabel>
                <ModalInput style={{width: "95%"}} onChange={this.changeNickName} value={this.state.scheme_head['name']} />
                <ModalLabel>Active?</ModalLabel>
                {
                    (() => {
                        if(this.state.scheme_head['is_active'] === 1) {
                            return (
                                <RadioBlock>
                                    <SelectedRadio>Yes</SelectedRadio>
                                    <UnselectedRadio onClick={() => this.switchRadio(0)}>No</UnselectedRadio>
                                </RadioBlock>
                                )
                        }
                        return (
                                <RadioBlock>
                                    <UnselectedRadio onClick={() => this.switchRadio(1)}>Yes</UnselectedRadio>
                                    <SelectedRadio>No</SelectedRadio>
                                </RadioBlock>
                                )
                    })()
                }
                </ModalBody>
                <ModalFooter>
                    <SaveButton onClick={this.saveScheme} style={{float: 'right', marginTop: 15, backgroundColor: '#143151'}}>Submit</SaveButton>
                    <CancelButton onClick={this.props.closeModal} style={{float: 'right', marginTop: 15, backgroundColor: '#ffffff'}}>Cancel</CancelButton>
                </ModalFooter>
            </ModalContainer>
        </div>)
    }
}


class AddSchemeTaskModal extends React.Component {
    state = {
      task: {},
      task_id: 0,
      task_name: "",
      description: "",
      ready: false,
      alert_param: null,
      frequency: "monthly",
      is_active: 1
    };

    constructor(props) {
        super(props);
    }

    setComponentState = () => {
        let task = this.props.task;
        if(task!==null){
          let description = ('description' in task) ? task.description : '';
          if(Utils.isBase64(description)){
            description = window.decodeURIComponent(atob(description))
          }
          this.setState({task_id: task.id, task_name: task.name, frequency: task.frequency, is_active: task.is_active, description: description, ready: true})
        }else{
          this.setState({task_id: 0, task_name: "", frequency: "monthly", is_active: 1, description: "", ready: true});
        }

    }

    componentDidMount()
    {       
        this.setComponentState()
    }

    componentDidUpdate(prevProps)
    {
        if(this.props === prevProps)return
        this.setComponentState()
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    switchRadio = (key) => (event) => {
      event.preventDefault();
      let {is_active} = this.state;
      is_active = is_active === 1 ? 0 : 1;
      this.setState({is_active})
    }

    manageSchemeTask = (event) => {
      if(this.state.task_name === '') {
          let alert_param = {title: 'Alert', message: 'ERROR: Task name cannot be blank.', ok_text: 'Ok', confirm: false, alertHandler: this.alertcloseHandler, stack: {id: 0}}
          this.setState({alert_param: alert_param})
          return false;
      }
      let id = this.state.task_id === 0 ? Utils.genKey(5) : this.state.task_id;
      let description = Store.getStoreData('description');
      if(description === null){
          description = this.state.description;
      }
      let description_summary = btoa(window.encodeURIComponent(description));
      let task = {};
      task['id'] = id;
      task['name'] = this.state.task_name;
      task['description'] = description_summary;
      task['frequency'] = this.state.frequency;
      task['is_active'] = this.state.is_active;
      this.props.saveSchemeTask(task)
    }

    alertcloseHandler = (result, stack) => {
      this.setState({alert_param: null})
    }

    render() {
      if(!this.state.ready) {
          return <div>Loading.....</div>;
      }
      let frequency = Utils.list_shedules();
        console.log('AddQSetModal state', this.state)
        return (<div>
      <InactiveOverlay />
      <ModalContainer>
          <Style.ModalHeaderText>
          {
            (() => {
              if(this.state.task_id === 0) {
                return "Add MCC Recurring Task";
              }else{
                return "Update MCC Recurring Task";
              }
            })()
          }
          </Style.ModalHeaderText>
          
          <Style.ModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
          <div style={{clear: "both"}}></div>
          <Style.ModalBody>
              <Style.ModalBodyInner>
                  <Style.ModalContainertop>
                      <Style.ModalNameLabelDiv>Name</Style.ModalNameLabelDiv>
                      <Style.ModalNameInputDiv>
                          <Style.ModalInput name="task_name" value={this.state.task_name.replace(/'+/g,"'")} onChange={this.handleChange} />
                      </Style.ModalNameInputDiv>
                      <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
                      <Style.ModalNameInputDiv>
                          <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.description} />
                      </Style.ModalNameInputDiv>
                      <Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
                  <Style.ModalNameInputDiv>
                        <Style.MRModalSelect name="frequency" value={this.state.frequency} onChange={this.handleChange}>
                          {
                              frequency.map((item) => {
                                  {
                                      return (
                                          <option key={item.id} value={item.name}>{item.nickname}</option>
                                      );
                                  }
                              })
                          }
                          </Style.MRModalSelect>
                        </Style.ModalNameInputDiv>
                        <Style.ModalNameLabelDiv>Active</Style.ModalNameLabelDiv>
                        <Style.ModalNameInputDiv>
                        {
                          (() => {
                            if (this.state.is_active) {
                              return (
                                <div>
                                  <SelectedRadio>Yes</SelectedRadio>
                                  <UnselectedRadio onClick={this.switchRadio('is_active')}>No</UnselectedRadio>
                                </div>
                              );
                            } else {
                              return (
                                <div>
                                  <UnselectedRadio onClick={this.switchRadio('is_active')}>Yes</UnselectedRadio>
                                  <SelectedRadio>No</SelectedRadio>
                                </div>
                              );
                            }
                          })()
                        }
                        </Style.ModalNameInputDiv>




                  </Style.ModalContainertop>
              </Style.ModalBodyInner>
          </Style.ModalBody>
          <Style.CancelSubmitButtonOuterContainer>
                  <Style.CancelButton onClick={this.props.closeModal}>Cancel</Style.CancelButton>
                  <Style.SubmitButton onClick={this.manageSchemeTask}>Save</Style.SubmitButton>
          </Style.CancelSubmitButtonOuterContainer>
      </ModalContainer>
      <AlertBox alertParam = {this.state.alert_param} />
        </div>)
    }
}


class SchemeTaskSet extends React.Component {

  state = {
    scheme:{},
    used_tasks: {},
    add_task: false,
    update_scheme: false,
    task:null,
    alert_param: null,
    scheme_head: {}
  };

  constructor(props) {
    super(props);
  }

  setComponentState = () => {
      console.log("scheme_header", this.props.scheme_header)
      let scheme = this.props.scheme_header;
      let scheme_json = JSON.parse(scheme.scheme_json);
      let used_tasks = scheme_json['used_tasks'];
      this.setState({scheme:scheme, used_tasks: used_tasks})

  }

  componentDidMount()
  {   
        this.setComponentState()
  }

  componentDidUpdate(prevProps)
  {
    if(this.props === prevProps)return
        this.setComponentState()
  }

  updateScheme = (event) => {
    event.preventDefault();
    let scheme_head = {};
    let scheme = this.state.scheme;
    scheme_head['name'] = scheme.name;
    scheme_head['is_active'] = scheme.is_active;
    this.setState({update_scheme: true, scheme_head: scheme_head})
  }

  addSchemeTask = (event) => {
    event.preventDefault();
    this.setState({add_task: true})
  }

  saveScheme = (scheme_head) => {
    let scheme = this.state.scheme;
    scheme['name'] = scheme_head.name;
    scheme['is_active'] = scheme_head.is_active;
    let scheme_json = JSON.parse(scheme.scheme_json);
    scheme_json['used_tasks'] = this.state.used_tasks;
    scheme.scheme_json = scheme_json;
    this.setState({update_scheme: false})
    this.props.updateSchemeTask(scheme);
  }

  saveSchemeTask = (task) => {
    let scheme = this.state.scheme;
    let used_tasks = this.state.used_tasks;
    let id = task.id;
    used_tasks[id] = task;
    let scheme_json = JSON.parse(scheme.scheme_json);
    scheme_json['used_tasks'] = used_tasks;
    scheme.scheme_json = scheme_json;
    // console.log("used_tasks", scheme)
    this.setState({add_task: false})
    this.props.updateSchemeTask(scheme);
  }

  closeModal = () => {
    this.setState({add_task: false, task: null, update_scheme: false,schem_head: {}})
  }

  checkCallback = (checked,id) => {
    let scheme = this.state.scheme;
    let used_tasks = this.state.used_tasks;
    let task = used_tasks[id.toString()]
    task.is_active = checked ? 1 : 0;
    used_tasks[id] = task;
    let scheme_json = JSON.parse(scheme.scheme_json);
    scheme_json['used_tasks'] = used_tasks;
    scheme.scheme_json = scheme_json;
    this.props.updateSchemeTask(scheme);
  }

  openModal = (id) => (event) => {
    event.preventDefault();
    let scheme = this.state.scheme;
    let used_tasks = this.state.used_tasks;
    let task = used_tasks[id.toString()]
    this.setState({ add_task: true, task: task });
  };

  deleteSchemeTask = (id) => (event) => {
    event.preventDefault();
    let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.removeShemeTask, stack: {task_id: id}}
    this.setState({ alert_param: alert_param });
  }

  removeShemeTask = (result, stack) => {
    if(!result || stack.prevent) {
        this.setState({alert_param: null})
        return
    }
    let task_id = stack.task_id;
    let scheme = this.state.scheme;
    let used_tasks = this.state.used_tasks;
    delete used_tasks[task_id.toString()]
    let scheme_json = JSON.parse(scheme.scheme_json);
    scheme_json['used_tasks'] = used_tasks;
    scheme.scheme_json = scheme_json;
    this.props.updateSchemeTask(scheme);
     this.setState({alert_param: null})
    }

  tableData = () => {
    let table = { data: [], columns: [] };

    table.columns = [
      { Header: "#", accessor: "index", width: 30, headerStyle: { textAlign: "center" }, style: { fontFamily: "monospace", fontSize: "14px", textAlign: "right" } },
      {Header: 'Task Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left'}},
      {Header: 'Task Description', accessor: 'description', minWidth: 200, headerStyle: {textAlign: 'left'}},
      {Header: 'Status', Cell: row => (
                                <CSLCheckBox checked={row.original.status} id={row.original.id} checkCallback={this.checkCallback} style={{borderColor: ''}} />
                      ), minWidth: 50, headerStyle: {textAlign: 'left', visible:'false'}},

      {
        Header: "Action",
        Cell: (row) => (
          <div>
            <FaEdit  onClick={this.openModal(row.original.id)}  style={{ cursor: "pointer", fontSize: "18px", color: "#b4b5b4", display: "inline-block", marginRight: "5px" }} />
            <FaTimes onClick={this.deleteSchemeTask(row.original.id)} style={{ cursor: "pointer", fontSize: "18px", color: "#b4b5b4", display: "inline-block" }} />
          </div>
        ),
        width: 55,
        headerStyle: { textAlign: "center" },
      },
    ];
    let data = this.state.used_tasks;
    let i = 0;
    for(let key in data){
      i++;
      
      let description = ('description' in data[key]) ? data[key].description : '';
      if(Utils.isBase64(description)){
        description = window.decodeURIComponent(atob(description))
      }


      let elem = {'index' : i, 
              'name': data[key].name,
              'description': description.replace(/<\/?[^>]+(>|$)/g, ""),
              'id': data[key].id,
              'status': data[key].is_active
             };
      table.data.push(elem);
    }
    return table;
  };

  render() {
    return (
      <div>
        <div style={{ width: "90%" }}>
          <TypesContainer>
            <TypesInnerContainer>
              <div style={{ display: "flow-root" }}>
                <div style={{ float: "left" }}>
                  <TypesBoldLabel style={{ display: "inline-block" }}>{this.props.scheme_header['name']}</TypesBoldLabel>
                  <FaEdit  style={{ cursor: "pointer", color: "#b4b5b4", display: "inline-block", marginRight: "5px" }} onClick={this.updateScheme}/>
                  </div>
                <div onClick={this.addSchemeTask} style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                  <FaPlusCircle />
                </div>
              </div>
              <CSLTable add={false} processData={this.tableData} tableRows={10} />
            </TypesInnerContainer>
          </TypesContainer>
          {
              (() => {
                  if(this.state.add_task) {
                      return <AddSchemeTaskModal closeModal={this.closeModal} saveSchemeTask={this.saveSchemeTask} task={this.state.task} />
                  }
              })()
          }
          {
            (() => {
              if(this.state.update_scheme) {
                return <UpdateScheme closeModal={this.closeModal} saveScheme={this.saveScheme} scheme_head={this.state.scheme_head} />
              }
            })()
          }
        </div>
        <AlertBox alertParam = {this.state.alert_param} />
      </div>
    );
  }
}

export default SchemeTaskSet;
