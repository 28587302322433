import React from 'react';
import { Icon } from 'react-icons-kit';
import {calendar} from 'react-icons-kit/fa/calendar';
import DatePicker from 'react-date-picker';
import './CslDatepicker.css';

class CslDatepicker1 extends React.Component
{
	state = { date: new Date() };
	onChange = date => {
		this.setState({ date });
		this.props.showfromDateChange(date);
	}

	render()
	{
		return (
			<DatePicker
				onChange={this.onChange}
				value={this.state.date}
				calendarIcon={<Icon size={20} style={{color: "#2F496C"}} icon={calendar} />}
				clearIcon={null}
				format="dd/MM/y"
				className="csldatecontainer"
	        />
		);
	}
}

export default CslDatepicker1;