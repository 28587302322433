import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import {FaTimes} from 'react-icons/fa';
import APICall from '../../Common/APICall';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 60%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #373737;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #252525;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;

const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 96%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;   
`;

class RegisterFollowupTaskModal extends React.Component
{
    state = { ready: false, documentName: '', documentType:'',upload_date: new Date(),summary:'', comments_note: '',curlane: ''}

    constructor(props) {
		super(props);
    }
    componentDidMount = () => {
       console.log(Store.getStoreData('cur_followup_task'))
       let objectData = JSON.parse(Store.getStoreData('cur_followup_task').task_json).object_data;
       let documentname = 'documentName' in objectData ? objectData.documentName : objectData.documentname;
       let curlane = Store.getStoreData('cur_followup_task').cur_lane;
       console.log("curlane",curlane)
       let docType = "";
        docType = objectData.documentTypes;
        if(!isNaN(objectData.documentTypes)) {
            const documentType = Store.getStoreData('rutypename');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            } 
            docType = doctype[objectData.documentTypes]
        }

        let uploadDate = objectData.uploadDate;
        if (uploadDate.includes(" ") === true) {
            uploadDate = uploadDate.replace(" ", "+");
        }
        const upload_date_obj = new Date(uploadDate);
		const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
		const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
		const upload_year = upload_date_obj.getFullYear();
        const upload_date = `${upload_day}/${upload_month}/${upload_year}`;

        

       this.setState({documentName: documentname,documentType:docType,upload_date: upload_date,summary: objectData.summary,curlane: curlane,ready: true})
       //console.log(documentname)
    }

    closeFollowupModal = (event) => {
        this.props.closeModal();
    }

    render() {
        if(!this.state.ready){
            return(<div>Loading..</div>)
        } 
              
        return(<RegModalContainer>
                <RegModalHeaderText>Task Generated from RCs</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeFollowupModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
                <RegDismisOuterContainer>
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.state.documentName}</div>                                                                
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>                            
                        <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(this.state.summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>                                       
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {this.state.documentType}</div>
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {this.state.upload_date}</div> 
                    </OverviewInfoBody>
                </RegDismisOuterContainer>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>                       
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeFollowupModal}>Cancel</RegCancelButton>
                </RegCancelSubmitButtonOuterContainer>                
            </RegModalContainer>)
    }
}
export default RegisterFollowupTaskModal;