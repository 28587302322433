import React from "react";
import styled from "styled-components";
import DocumentHOC from "./document/DocumentHOC";
import TmviewHOC from "./tmview/TmviewHOC";
import CarHOC from "./car/CarHOC";
import GiftsHOC from "./gifts/GiftsHOC";
import ConflictsHOC from "./conflicts/ConflictsHOC";
import CmpTmViewHOC from "./cmp/CmpTmViewHOC";
import RcmHOC from "./rcm/RcmHOC";
import BreachHOC from "./breach/BreachHOC";
import AttestHOC from "./attest/AttestHOC";
import GovernanceTmViewHOC from "./committees/GovernanceTmViewHOC";
import FinpromoHOC from "./finpromo/FinpromoHOC";
import Store from './../../Common/Store';

const modules = {car:1,docmgmt:1,tmview:1, rcm: 1, gifts: 1, conflicts: 1, breach: 1, attest: 1, committees: 1, finpromo: 1};

// Any modules that require redirecting to a different url are stored in here
const moduleUrls ={
  "cmp": process.env.REACT_APP_CMP_MODULE_URL,
  "filechecker": process.env.REACT_APP_FILECHECKER_URL,
}

class TasksHOC extends React.Component {
  state = {
    task_id: 0,
    module_id: null,
    cur_lane: null,
    parent_task_id: 0,
    ref_id: 0,
    company_id: 0
  };

  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.callTaskpage()
  };

  callTaskpage = () => {
    if(modules[this.props.module_id]) {
      this.setState({task_id: this.props.task_id, module_id: this.props.module_id, cur_lane: this.props.cur_lane, parent_task_id: this.props.parent_task_id, ref_id: this.props.ref_id, company_id: this.props.company_id})
    }else{
      // get url for module redirection
      const moduelUrl = moduleUrls[this.props.module_id];

      // if the module redirect url is not found, then return after printing error
      if (moduelUrl === undefined) {
        console.log("module redirect url not found for module: ", this.props.module_id)
        return;
      }

      let url = `${moduelUrl}/${this.props.task_id}`

      if(this.props.module_id === 'cmp'){
        switch(this.props.task_type) {
          case "COMP_CMP":
            url = `${moduelUrl}/${this.props.ref_id}`;
            break;
          case "COMP_CMP_FOLLOWUP":
            url = `${moduelUrl}/remedial/${this.props.task_id}`;
            break;
        }
      }
      window.location = url;
    }
  }


  closeUserDialog = (is_reload=null) => {
    this.setState({task_id: 0, module_id: null})
    this.props.closeUserDialog(is_reload)
  }

  refreshState = (stack=null) => {
    console.log("tmview tasks", stack)
    this.setState({task_id: 0, module_id: null})
    let tasks = Store.getStoreData('raw_tasks')
    if(stack!==null){
      if('mode' in stack && stack.mode === "delete"){
        delete tasks[stack.task_id.toString()];
      }else{
        let original_task = tasks[stack.task_id.toString()]
        let stack_task = stack.task;
        for(let key in stack_task){
          original_task[key] = stack_task[key]
        }
        tasks[stack.task_id.toString()] = original_task;
      }
      Store.updateStore('raw_tasks', tasks)
    }

    this.props.closeUserDialog()
  }

  render() {
    if(this.state.module_id === null)return(<div>....</div>)
    console.log("module_id ====>", this.state.task_id)
    return (
        <div>
          {
            (() => {
              switch(this.state.module_id) {
                case "docmgmt":
                  return <DocumentHOC task_id = {this.state.task_id} company_id = {this.state.company_id} closeUserDialog={this.closeUserDialog} refreshParentState={this.refreshState}/>
                case "tmview":
                  return <TmviewHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog}  />
                case "car":
                  return <CarHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog}  refreshParentState={this.refreshState}/>
                case "gifts":
                  return <GiftsHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog} />
                case "conflicts":
                  return <ConflictsHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog} />
                case "attest":
                  return <AttestHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog} />
                case "breach":
                  return <BreachHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog} />
                case "rcm":
                  return <RcmHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog} />
                case "cmp":
                  return <CmpTmViewHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} ref_id = {this.state.ref_id} parent_task_id = {this.state.parent_task_id} closeUserDialog={this.closeUserDialog} />
                case "committees":
                  return <GovernanceTmViewHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} ref_id = {this.state.ref_id} parent_task_id = {this.state.parent_task_id} closeUserDialog={this.closeUserDialog} />
                case "finpromo":
                  return <FinpromoHOC task_id = {this.state.task_id} company_id = {this.state.company_id} cur_lane = {this.state.cur_lane} closeUserDialog={this.closeUserDialog} />
              }
            })()
          }
        </div>
    );
  }
}

export default TasksHOC;
