import React from 'react';
import Store from '../../Common/Store.js';
import styled from 'styled-components';
import {FaPlus, FaTimes} from 'react-icons/fa';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const OkButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #04ACA7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const OkDangerButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #EA5A6E;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: white;
    color: #646A7E;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const AlertBoxContainer = styled.div`
	position: fixed;
	top: 40vh;
	left: 40vw;
    width: 450px;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F7F7F7;
    color: #2D2D2D;
    border-radius: 10px;
    border-style: solid;
    border-color: #F7F7F7;
    z-index: 5000;
`
const AlertBoxHeader = styled.div`
    width: calc(100% - 15px);
    background-color: #F7F7F7;
    color: #646A7E;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    height: 50px;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
`
const AlertBoxBody = styled.div`
    width: calc(100% - 30px);
    background-color: #F7F7F7;
    color: #646A7E;
    font-size: 18px;
    min-height: 100px;
    text-align: center;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px
`
const AlertBoxFooter = styled.div`
    width: calc(100% - 15px);
    background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
`

class AlertBox extends React.Component
{
    state = {view_count: 0 ,option_value : 0};

    constructor(props) {
        super(props);
		this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
		if(this.props.alertParam !== null){
            let title = 'title' in this.props.alertParam ? this.props.alertParam.title : 'Alert';
            let confirm = 'confirm' in this.props.alertParam ? this.props.alertParam.confirm : false;
            let danger = 'danger' in this.props.alertParam ? this.props.alertParam.danger : false;
            let ok_text = 'ok_text' in this.props.alertParam ? this.props.alertParam.ok_text : 'Ok';
            let cancel_text = 'cancel_text' in this.props.alertParam ? this.props.alertParam.cancel_text : 'Cancel';
			let continue_to_next_screen_if_yes = 'continue_to_next_screen_if_yes' in this.props.alertParam ? this.props.alertParam.continue_to_next_screen_if_yes : false;
			let no_button = 'no_button' in this.props.alertParam ? this.props.alertParam.no_button : false;
			let next_messages = 'next_messages' in this.props.alertParam ? this.props.alertParam.next_messages : [];
			let option_type = 'option_type' in this.props.alertParam ? this.props.alertParam.option_type : '';
            this.targetElement = document.querySelector('#CSLAlertBox');
            console.log('props', this.props)
            disableBodyScroll(this.targetElement);

            this.setState({title: title, confirm: confirm, danger: danger, ok_text: ok_text, cancel_text: cancel_text, message: this.props.alertParam.message , continue_to_next_screen_if_yes : continue_to_next_screen_if_yes , next_messages : next_messages , option_type : option_type , no_button})  			
		}
	}
    componentDidUpdate(prev_props) {
        if(this.props !== prev_props && this.props.alertParam !== null) {
            let title = 'title' in this.props.alertParam ? this.props.alertParam.title : 'Alert';
            let confirm = 'confirm' in this.props.alertParam ? this.props.alertParam.confirm : false;
            let danger = 'danger' in this.props.alertParam ? this.props.alertParam.danger : false;
            let ok_text = 'ok_text' in this.props.alertParam ? this.props.alertParam.ok_text : 'Ok';
            let cancel_text = 'cancel_text' in this.props.alertParam ? this.props.alertParam.cancel_text : 'Cancel';
			let continue_to_next_screen_if_yes = 'continue_to_next_screen_if_yes' in this.props.alertParam ? this.props.alertParam.continue_to_next_screen_if_yes : false;
			let no_button = 'no_button' in this.props.alertParam ? this.props.alertParam.no_button : false;
			let next_messages = 'next_messages' in this.props.alertParam ? this.props.alertParam.next_messages : [];
			let option_type = 'option_type' in this.props.alertParam ? this.props.alertParam.option_type : '';
            this.targetElement = document.querySelector('#CSLAlertBox');
            console.log('props', this.props)
            disableBodyScroll(this.targetElement);

            this.setState({title: title, confirm: confirm, danger: danger, ok_text: ok_text, cancel_text: cancel_text, message: this.props.alertParam.message , continue_to_next_screen_if_yes : continue_to_next_screen_if_yes , next_messages : next_messages , option_type : option_type , no_button})                        
        }
    }

    processOkNext = () => {
    	enableBodyScroll(this.targetElement);
		let stack = this.props.alertParam.stack;
		stack['option_value'] = this.state.option_value;
		this.setState({ show_next_screen: false } , ()=>{
			this.props.alertParam.alertHandler(true, stack);
		});
		
    }

    processOk = () => {
    	enableBodyScroll(this.targetElement);
		if(this.state.continue_to_next_screen_if_yes){
			this.setState({ show_next_screen: true }) 
		}else{
    		this.props.alertParam.alertHandler(true, this.props.alertParam.stack)
		}
    }

    processCancel = () => {
    	enableBodyScroll(this.targetElement);
    	this.props.alertParam.alertHandler(false, this.props.alertParam.stack)
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

	




    render()
    {
        //console.log('this.props.alertParam', this.props.alertParam)
        //console.log('next_messages==>', this.state.next_messages)
        if(this.props.alertParam === null)return <div></div>

        return (
            <div>
            	<InactiveOverlay />
                <AlertBoxContainer id="CSLAlertBox">
                	<AlertBoxHeader>{this.state.title}
                	</AlertBoxHeader>
                	<AlertBoxBody>
                	{
                		(() => {
                			if(this.state.show_next_screen) {
                                return <List list={this.state.next_messages} handleChange={this.handleChange}/>

							}else{
								return <div dangerouslySetInnerHTML={{__html: this.state.message}}></div>
							}
                		})()
                	}
                	</AlertBoxBody>
                	<AlertBoxFooter>
                	{
                		(() => {
							if(!this.state.no_button){
								if(this.state.danger) {
									return <OkDangerButton onClick={this.processOk}>{this.state.ok_text}</OkDangerButton>
								} else {
									if(this.state.show_next_screen){
										return <OkButton onClick={this.processOkNext}>{this.state.ok_text}</OkButton>
									}else{
										return <OkButton onClick={this.processOk}>{this.state.ok_text}</OkButton>
									}
								}								
							}
                			
                		})()
                	}
                	{
                		(() => {
                			if(this.state.confirm) return <CancelButton onClick={this.processCancel}>{this.state.cancel_text}</CancelButton>
                		})()
                	}
                	</AlertBoxFooter>
                </AlertBoxContainer>
            </div>
            )
    }
}

function List(props) {
  if (!props.list) {
    return null;
  }
 
  return (
    <div>
      {props.list.map((item, index) => (
        <Item item={item}  handleChange = {props.handleChange}/>
      ))}
    </div>
  );
}
 
function Item(props) {
  
  return (
    <div style={{float : "left"}}><input type="radio" name="option_value" value={props.item.value} onChange={props.handleChange} />{props.item.msg}</div>
  );
}


export default AlertBox;