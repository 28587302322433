import React from 'react';
import styled from 'styled-components';
const RegPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
`;
class RUPills extends React.Component
{
    render()
    {
        const doctypes = this.props.doctypes;
        return (
            <RegPill>{doctypes}</RegPill>
        );
    }
}
export default RUPills;