import React from 'react';
import styled from 'styled-components';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb';

const GeneralContainer = styled.div`
	width: 90%;
`;

const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	width: 65%;
	padding: 30px;
`;

const Unassignheader = styled.div`
	font-weight: bold;
	text-align: center;
	background-color: #69819D;
	width: 90%;
`
const Cell = styled.div`
	display: table-cell;
	border-width: thin;
	padding-left: 5px;
	padding-right: 5px;
	color: white
`;
const CellRow = styled.div`
	display: table-row;
`;
const Unassigntable = styled.div`
	display: table;
	width: 100%
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
	display: table-cell;
	
`;

class Unassigntask extends React.Component
{
	state = {module_config: null};

	componentDidMount()
	{
		console.log("this.props.module_config", this.props.module_config)
		this.setState({module_config: this.props.module_config});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({module_config: this.props.module_config});
		}
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		let {module_config} = this.state;
		module_config.unassigntasks.forEach((item) => {
		if (item.id === section_id) {
			item.enabled = item.enabled ? false : true; 
			console.log("matched", item);
		}
		})
		this.props.updateUnassignedTask(module_config);
	}

	showAlertmessage = (section_id) => (event) => {
		event.preventDefault();
		alert("This selection cannot be disabled");
	}

	onChangeIntervalIQ  = (values, handle) => {
		let {module_config} = this.state;
		let value = Math.floor(parseFloat(values[handle]));
		module_config.general.not_login_period = value;
		this.props.updateUnassignedTask(module_config);
	}


	render()
	{

		if (this.state.module_config === null) {
			return (
				<div>Loading...</div>
			);
		}
		let tooltips = [wNumb({decimals: 0, suffix: 'days'})];
		return (
			<GeneralContainer>
				<GeneralInnerContainer>
				<div style={{fontWeight: "600", color: "#222222", marginBottom: "10px"}}>Define unassigned tasks</div>
				<Unassigntable>
					<Unassignheader style={{height: "35px"}}>
						<Cell style={{float:"left", marginTop:"10px"}}>Definition</Cell>
						<Cell style={{float:"right", marginTop:"10px"}}>Enabled?</Cell>
					</Unassignheader>
					<div style={{marginTop: "10px", width: "90%"}}>
						<CellRow style={{height: "35px", padding:"10px", color:"#edf0f1"}}>
							<GeneralLabel style={{color:"#aaa"}}>Tasks with no individual assigned</GeneralLabel>
							<GeneralLabel style={{color:"#aaa"}}>
							{
								(() => {
									if(this.state.module_config.unassigntasks[0].enabled) {
										return <div style={{cursor: "pointer", fontSize:"21px", paddingLeft:"199px"}} onClick={this.showAlertmessage(this.state.module_config.unassigntasks[0].id)}><IoIosCheckboxOutline /></div>
									}else{
										return <div style={{cursor: "pointer", fontSize:"21px", paddingLeft:"199px"}} onClick={this.showAlertmessage(this.state.module_config.unassigntasks[0].id)}><IoIosSquareOutline /></div>
									}

								})()
							}
							
							</GeneralLabel>
						</CellRow>
						<CellRow style={{height: "35px", padding:"10px"}}>
							<GeneralLabel>Tasks assigned to individuals never logged in</GeneralLabel>
							<GeneralLabel>
							{
								(() => {
									if(this.state.module_config.unassigntasks[1].enabled) {
										return <div style={{cursor: "pointer", fontSize:"21px", paddingLeft:"199px"}} onClick={this.handlenaSectionIncluded(this.state.module_config.unassigntasks[1].id)}><IoIosCheckboxOutline /></div>
									}else{
										return <div style={{cursor: "pointer", fontSize:"21px", paddingLeft:"199px"}} onClick={this.handlenaSectionIncluded(this.state.module_config.unassigntasks[1].id)}><IoIosSquareOutline /></div>
									}

								})()
							}
							</GeneralLabel>
						</CellRow>
						<CellRow style={{height: "35px", padding:"10px"}}>
							<GeneralLabel>Tasks assigned to individuals not logged in for a period of time</GeneralLabel>
							<GeneralLabel>
								{
									(() => {
										if(this.state.module_config.unassigntasks[2].enabled) {
											return <div style={{cursor: "pointer", fontSize:"21px", paddingLeft:"199px"}} onClick={this.handlenaSectionIncluded(this.state.module_config.unassigntasks[2].id)}><IoIosCheckboxOutline /></div>
										}else{
											return <div style={{cursor: "pointer", fontSize:"21px", paddingLeft:"199px"}} onClick={this.handlenaSectionIncluded(this.state.module_config.unassigntasks[2].id)}><IoIosSquareOutline /></div>
										}

									})()
								}
							</GeneralLabel>
						</CellRow>
					</div>
					{
						(() => {
							if(this.state.module_config.unassigntasks[2].enabled) {
								return(					
										<div style={{paddingTop: '40px', width: "90%"}}>
											<Nouislider range={{ min: 1, max: 30 }} start={this.state.module_config.general.not_login_period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeIntervalIQ} />
										</div>
									)
							}
						})()
					}

				</Unassigntable>
				</GeneralInnerContainer>
			</GeneralContainer>
		);
	}
}

export default Unassigntask;