import React from 'react';
import styled from 'styled-components';
import {FaTimes,FaSearch} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store.js';
import HtmlEditor from '../../HtmlEditor';
import RUPills from '../Partials/RUPills';
import RUFullPills from '../Partials/RUFullPills';
import RegisterChildTasksRUTable from '../Partials/RegisterChildTasksRUTable';
import DatePicker from 'react-date-picker';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import RCMDetailsRegisterModal from './RCMDetailsRegisterModal';
import ShareInfoAndActionModal from './ShareInfoAndActionModal';
import AlertBox from './../Common/AlertBox';
import '../../App.css';
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;    
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const RegModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #979797;
`;
const RegModalIssueDateInput = styled.input`
    height: 30px;
    width: 90%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-top: 8px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegModalNameInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisalButtonDiv = styled.div`
    display: block;
    box-sizing: border-box;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegMarkForActionButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #d5d5d5;
    color: #757575;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    margin-left: 5px;
    font-size: 10px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #282828;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegShareButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062;
    color: #ffffff;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 5px;
    /* float: right; */
    cursor: pointer;
`;
const StatusBadge = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 200px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:default;
`;
const RegDismissalReason = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;    
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
class RegisterClientModal extends React.Component
{
    state = {
        resonforaction: false,
        reasonforcomplete: false,
        complete_note: "",
        followuptask:false,
        textareaValue:"",
        date: new Date(),
        assign_note: "",
        dismiss_note: "",
        due_by: '1970-01-01',
        assigned_to: 0,
        lane: "COMP_RCM_ASSIGNMENT",
        externalLink: [],
        shareModal: false,
        sharedata: null,
        shareobject: {},
        shareUserInfo: {},
        shareUserAcknowledge: {},
        send_mail_config: false,
        task_json:null,
        viewModal: null,
        emailLogData: {},
        alert_param: null
    };
    constructor(props) {
        super(props);
      }
    componentDidMount() {
        let objectdata = Store.getStoreData('task');
        let {shareobject, shareUserInfo, shareUserAcknowledge,sharedata} = this.state;
        if('shareobject' in objectdata){
            shareobject = objectdata.shareobject;
        }
        if("shareUserInfo" in objectdata) {
            shareUserInfo = objectdata.shareUserInfo;
        }
        if("shareUserAcknowledge" in objectdata) {
            shareUserAcknowledge = objectdata.shareUserAcknowledge;
        }
        sharedata = Store.getStoreData('shareuserlist');
        let send_mail_config = false;
        // let module_config = Store.getStoreData('module_config');
        // console.log("module_config",module_config)
        // if(Object.keys(module_config).length > 0) {
        //     if(module_config.email.length > 0) {
        //         send_mail_config = true;
        //     }
        // }
        // console.log("send_mail_config--->",send_mail_config)
        let task = Store.getStoreData('this_task_detail');
        let task_json = JSON.parse(task.task.parenttask.task_json);

        this.setState({ sharedata, shareobject,shareUserInfo, shareUserAcknowledge, send_mail_config,task_json});
    }

    closeRegulatoryModal = (event) => {
        // if (document.referrer.endsWith("/tmview")) {
        //     window.location = '/';
        // } else {
        //     this.props.closeModal();
        // }
        // if(Store.getStoreData('isreferenceUrl') === true){
        //     window.location = '/';
        // } else {
        //     this.props.closeModal();
        // }

        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
              window.location = process.env.REACT_APP_RCM_URL;
            }else{
            //   window.location = '/';
                this.props.closeTaskModal();
            }
          } else {
            this.props.closeModal();
        }
    }
    closeShareModal = (event) => {
        this.setState({shareModal: false})
    }
    addResonforDismiss = (event) => {
        event.preventDefault();
        if(Store.getStoreData('parent_task_id') > 0) {
            this.setState({resonforaction: true, lane: "COMP_RCM_FOLLOWUP_DISMISS"})
        }else{
            this.setState({resonforaction: true, followuptask: false, assign_note: "", assigned_to: 0, due_by: "", lane: "COMP_RCM_DISMISS"});
        }
    }
    addFollowupActionsection = (event) => {
        event.preventDefault();
        if(Store.getStoreData('parent_task_id') > 0) {
            this.setState({resonforaction: false, reasonforcomplete: true, dismiss_note: "", lane: "COMP_RCM_FOLLOWUP_COMPLETE"});
        }else{
            this.setState({resonforaction: false, followuptask: true, dismiss_note: "", lane: "COMP_RCM_ACTION"});
        }

    }

    handleOnChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    saveClientRegulatorychange = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        if (this.state.lane === "COMP_RCM_ASSIGNMENT") {
            //alert("Either mark for action or dismiss before saving");
            message += 'Either mark for action or dismiss before saving.';
            problems = true;
        }
        if (this.state.lane === "COMP_RCM_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for dismissal");
            message += 'Please provide a reason for dismissal.';
            problems = true;
        }
        if (this.state.lane === "COMP_RCM_FOLLOWUP_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for followup task dismissal");
            message += 'Please provide a reason for followup task dismissal.';
            problems = true;
        }
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})
        }else{
            let url = Store.getStoreData('api_url');
            let action_data = {
                task_id: Store.getStoreData('current_task_id'),
                cur_lane: this.state.lane,
                assigned_to: this.state.assigned_to,
                action: this.state.lane === "COMP_RCM_ACTION" ? "COMP_RCM_ASSIGN" : "COMP_RCM_DISMISS",
                note: this.state.lane === "COMP_RCM_ACTION" ? this.state.assign_note : this.state.dismiss_note,
                performed_by: Store.getStoreData('loggedincontact'),
                due_date: this.state.due_by
            };
            if(Store.getStoreData('parent_task_id') > 0){
                action_data = {
                    task_id: Store.getStoreData('current_task_id'),
                    cur_lane: this.state.lane,
                    assigned_to: 0,
                    action: this.state.lane === "COMP_RCM_FOLLOWUP_COMPLETE" ? "COMP_RCM_FOLLOWUP_COMPLETE" : "COMP_RCM_FOLLOWUP_DISMISS",
                    note: this.state.lane === "COMP_RCM_FOLLOWUP_DISMISS" ? this.state.dismiss_note : this.state.complete_note,
                };
            }

            let postData = { "command": "addactiontotask", "SystemID": 1001, action_data: action_data, task_id: Store.getStoreData('current_task_id')};

            let api = new APICall();
            this.setState({isLoaderIcon: true})
            api.command(postData, this.processsSaveClientRegulatorychange);


            // let jsonDataString = JSON.stringify(postData).replace('&','[[and]]');
            // console.log('jsonDataString', jsonDataString);
            // if(!is_debug)url += "msgw/rcm/api/command";
            // let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + jsonDataString };
            // fetch(url, httpVerb)
            // .then(res => res.json())
            // .then(result => {
            //     console.log(result);
            //     alert(result.error_msg);
            //     window.location.reload(true);
            // })
        }
    }

    processsSaveClientRegulatorychange = (result) => {
        console.log("update data", result);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false,
                        alertHandler: this.processSaveClientRegulatorychange, stack: {}}
        this.setState({alert_param: alert_param})
    }

    processSaveClientRegulatorychange = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }

    aftercheckvalidationAll = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    onDateChange = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        this.setState({due_by: dateStr, date: date});
    }
    processMyExternalLink = () => {
        var linksdata = Store.getStoreData('task').externalLinks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Link', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Comment', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {
            i++;
            let elem = {'index' : i,
                    'url_value' : d.url,
                    'description_value' : d.description
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    selectUser = (event) => {
        let index = event.target.value;
        let userdata = this.state.sharedata;

        if(userdata[index].select){
            userdata[index].foracknowledgement = true;
            userdata[index].forinformation = true;
            userdata[index].select = false;
            userdata[index].forinformationCheck = false;
            userdata[index].foracknowledgementCheck = false;
            delete this.state.shareobject[userdata[index].id.toString()];
            delete this.state.shareUserInfo[userdata[index].id.toString()];
        }else{
            let userid = userdata[index].id.toString();
            let val = 1;
            this.state.shareobject[userid] = val;
            this.state.shareUserInfo[userid] = val;

            userdata[index].foracknowledgement = false;
            userdata[index].forinformation = false;
            userdata[index].select = true;
            userdata[index].forinformationCheck = true;
            userdata[index].foracknowledgementCheck = false;
        }
        this.setState({sharedata: userdata, shareobject:this.state.shareobject, shareUserInfo: this.state.shareUserInfo})
        console.log("userid:-",this.state.shareobject);
        console.log("shareuserinfo:-",this.state.shareUserInfo);
    }
    selectinformation = (event) => {
        let index = event.target.value;
        let userdata = this.state.sharedata;
        if(userdata[index].forinformationCheck){
            userdata[index].forinformationCheck = false;
            userdata[index].foracknowledgementCheck = true;
            let userid = userdata[index].id.toString();
            let val = 1;
            this.state.shareUserAcknowledge[userid] = val;
            delete this.state.shareUserInfo[userdata[index].id.toString()];
            //shareUserAcknowledge
        }else{
            let userid = userdata[index].id.toString();
            let val = 1;
            this.state.shareUserInfo[userid] = val;
            delete this.state.shareUserAcknowledge[userdata[index].id.toString()];
            userdata[index].forinformationCheck = true;
            userdata[index].foracknowledgementCheck = false;
        }
        this.setState({sharedata: userdata, shareUserInfo:this.state.shareUserInfo,shareUserAcknowledge:this.state.shareUserAcknowledge})
        console.log("shareuserinfo:-",this.state.shareUserInfo);
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
    }
    selectacknowledgement = (event) => {
        let index = event.target.value;
        let userdata = this.state.sharedata;
        if(userdata[index].foracknowledgementCheck){
            userdata[index].foracknowledgementCheck = false;
            userdata[index].forinformationCheck = true;
            let userid = userdata[index].id.toString();
            let val = 1;
            this.state.shareUserInfo[userid] = val;
            delete this.state.shareUserAcknowledge[userdata[index].id.toString()];
        }else{
            let userid = userdata[index].id.toString();
            let val = 1;
            this.state.shareUserAcknowledge[userid] = val;
            delete this.state.shareUserInfo[userdata[index].id.toString()];
            userdata[index].foracknowledgementCheck = true;
            userdata[index].forinformationCheck = false;
        }
        this.setState({sharedata: userdata, shareUserAcknowledge:this.state.shareUserAcknowledge,shareUserInfo:this.state.shareUserInfo})
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
        console.log("shareuserinfo:-",this.state.shareUserInfo);
    }

    processMyshareData = () => {
        var sharedata = this.state.sharedata;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : 'Select', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectUser}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}},
        {Header: 'Name', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Role', accessor: 'role_value', minWidth: 80, headerStyle: {textAlign: 'left'}},
        {Header: 'Email', accessor: 'email_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {'Header' : 'For Information', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.for_information_check} value={row.index} disabled={row.original.for_information} onClick={this.selectinformation}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}},
         {'Header' : 'For Acknowledgement', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.for_acknowledgement_check} value={row.index} disabled={row.original.for_acknowledgement} onClick={this.selectacknowledgement}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}}];
        for(let d of sharedata) {
            i++;
            let elem = {'index' : i,
                    'id': d.id,
                    'name_value' : d.name,
                    'role_value' : d.role,
                    'email_value' : d.email,
                    'for_information' : d.forinformation,
                    'for_acknowledgement' : d.foracknowledgement,
                    'for_acknowledgement_check' : d.foracknowledgementCheck,
                    'for_information_check' : d.forinformationCheck,
                    'select' : d.select
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    showShareModal = (event) => {
        event.preventDefault();
        this.setState({ shareModal: true })
    }
    searchUsers = (event) => {
        let search_string = event.target.value;
        console.log("searchString===>",search_string)
		if (search_string === "") {
            var sharedata = Store.getStoreData('shareuserlist')
            this.setState({ sharedata })
        }else {
            let all_company_users = Store.getStoreData('shareuserlist');
            let sharedata = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    sharedata.push(user);
                }
            })
            this.setState({ sharedata })
        }
    }
    changeAllRoles = (event) => {
        console.log(event.target.value);
        let search_role = event.target.value;
        if(search_role === '0') {

            let sharedata = Store.getStoreData('shareuserlist')
            this.setState({ sharedata })
        }else {
            let all_company_users = Store.getStoreData('shareuserlist')
            let sharedata = [];
            all_company_users.forEach((user) => {
                if (user.role.includes(search_role.toLowerCase())) {
                    sharedata.push(user);
                }
            })
            this.setState({ sharedata })
        }
    }
    shareUser = (event) => {
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let comnd_task = {}
        let module_config = Store.getStoreData('module_config');
        let mail_config = {};
        // if(this.state.send_mail_config) {
        //     if(Object.keys(module_config).length > 0) {
        //         mail_config = module_config.email.find((obj) => {
        //             return obj.id === "email_1"
        //         });
        //     }
        //     mail_config = this.getMailStrucData(mail_config);
        //     console.log(mail_config);
        // }
        let task_json = JSON.parse(task.task.parenttask.task_json);
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].shareobject = this.state.shareobject;
        task_json['object_data'].shareUserInfo = this.state.shareUserInfo;
        task_json['object_data'].shareUserAcknowledge = this.state.shareUserAcknowledge;
        task_json['action_data'] = {action: "COMP_RCM_CREATE", actiondate: Date.now(), cur_lane: "COMP_RCM_ASSIGNMENT", shareobject:this.state.shareobject, assigned_to: 0 , mail_url: window.location.href, mail_config: mail_config, send_mail: this.state.send_mail_config};

        let postData = {command: "update_rcm_task", task_json: task_json, task_id: task_id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterCompleted);
    }

    getMailStrucData = (data) => {
        let task_json = this.state.task_json;
        console.log("task_json",task_json)
        let contact_name = Store.getStoreData('contact');
        // console.log("payload", JSON.stringify(payload));
        let mail_body = data.body;
        mail_body = mail_body.replace("{{ru_name}}", task_json.object_data.documentName);
        mail_body = mail_body.replace("{{regulator}}", contact_name);
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace("{{ru_name}}", task_json.object_data.documentName);
        mail_subject = mail_subject.replace("{{regulator}}", contact_name);
        data.subject = mail_subject;
        return data;
    }

    afterCompleted = (result) => {

        //alert('Share task successfully.');
        let alert_param = {title: 'Success', message: 'Share task successfully.', ok_text: 'OK', confirm: false,
                        alertHandler: this.processSaveClientRegulatorychange, stack: {}}
        this.setState({alert_param: alert_param})
    }
    shareobjectname = (shareInfoId) => {
        let name = null
        console.log("Sourav",shareInfoId)
        let shareuserlist = Store.getStoreData('shareuserlist')
        console.log("shareuserlist",shareuserlist)
        for(let i =0;i<shareuserlist.length;i++){
            //console.log("Sourav1",shareuserlist[i].id)
            if(JSON.stringify(shareuserlist[i].id) === shareInfoId){

                name = shareuserlist[i].name
            }
        }
        return name;
    }
    processMyShareInformation = () => {
        var taskdata = Store.getStoreData('task');
        console.log("taskdata.shareUserInfo",taskdata.shareUserInfo)
        let shareinfo_data = [];
        Object.keys(taskdata.shareUserInfo).map((d, key) => {
            let shareinfo_object = {}
            shareinfo_object.id = d
            shareinfo_object.name = this.shareobjectname(d);
            shareinfo_object.status = taskdata.shareUserInfo[d].cur_view;
            const infosend_date_obj = new Date(taskdata.shareUserInfo[d].date);
            const infosend_day = infosend_date_obj.getDate() < 10 ? "0"+infosend_date_obj.getDate().toString() : infosend_date_obj.getDate();
            const infosend_month = infosend_date_obj.getMonth()+1 < 10 ? `0${(infosend_date_obj.getMonth() + 1).toString()}` : infosend_date_obj.getMonth()+1;
            const infosend_year = infosend_date_obj.getFullYear();
            const infosend_date = `${infosend_day}/${infosend_month}/${infosend_year}`;
            shareinfo_object.sendate = infosend_date
            let responseDate = "--/--/----";
            if(taskdata.shareUserInfo[d].responsedate !== ""){
                const response_date_obj = new Date(taskdata.shareUserInfo[d].responsedate);
                const response_day = response_date_obj.getDate() < 10 ? "0"+response_date_obj.getDate().toString() : response_date_obj.getDate();
                const response_month = response_date_obj.getMonth()+1 < 10 ? `0${(response_date_obj.getMonth() + 1).toString()}` : response_date_obj.getMonth()+1;
                const response_year = response_date_obj.getFullYear();
                responseDate = `${response_day}/${response_month}/${response_year}`;
            }
            shareinfo_object.response = responseDate;
            //shareinfo_object.response = taskdata.shareUserInfo[d].responsedate === "" ? "--/--/----" : "--/--/----"
            shareinfo_data.push(shareinfo_object);
        })
        console.log("shareinfo_data",shareinfo_data)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'Name', accessor: 'name_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
                    {Header: 'Status', accessor: 'status', minWidth: 200, headerStyle: {textAlign: 'left'}},
                    {Header: 'Send Date', accessor: 'send_date', minWidth: 200, headerStyle: {textAlign: 'left'}},
                    {Header: 'Response Date', accessor: 'response_date', minWidth: 170, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                        <div>
                            {/* <MRButton onClick={this.shareinfoView}>View</MRButton> */}
                            {
                            (() => {
                                //console.log("row.original.status===>",row.original.status)
                                if(row.original.status !== 'Unopened') {
                                    return (<MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id)}>View</MRButton>)

                                }else{
                                    return (<MRButton style={{cursor:"pointer"}} onClick={() => this.resendEmailtoUser(row.original.id)}>Resend</MRButton>)
                                }
                            })()
                            }
                        </div>
                    ), width: 120, headerStyle: {textAlign: 'center'}}];
        for(let d of shareinfo_data) {
            i++;
            let elem = {
                    'id': d.id,
                    'name_value' : d.name,
                    'status': d.status,
                    'send_date': d.sendate,
                    'response_date': d.response
                   };
            ret.data.push(elem);
        }
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }

    exportToCSVShareInfo  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",name_value: "Name",status: "Status",send_date:"Send Date",response_date: "Response Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "name_value" : cur_table_data[k].name_value ,
                "status" : cur_table_data[k].status ,
                "send_date" : cur_table_data[k].send_date ,
                "response_date" : cur_table_data[k].response_date ,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
            container.pop()
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Share_for_information.csv");
        document.body.appendChild(link);
        link.click();
      }

    viewEmailNotification = (userID) => {
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let postData = {command: "view_user_email", task_id: task_id, contact_id: userID};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterviewemailLog);
    }

    afterviewemailLog = (result) => {
        console.log("Completed:", result);
        this.setState({emailLogData: result.emaillog, viewModal: 'shareInfoAndAction'})
    }

    resendEmailtoUser = (userid) =>{
        console.log("userid",userid)
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        console.log("userid",task_id)
        let postData = {command: "resend_email_to_share_user", task_id: task_id, send_mail: true, contact_id: userid,system_id: 1001};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);
    }

    afterresendCompleted = (result) => {
        // console.log("Completed:", result);
        //alert('Resend mail successfully.');
        let alert_param = {title: 'Success', message: 'The e-mail has been successfully re-sent to the user.', ok_text: 'OK', confirm: false,
                alertHandler: this.aftercheckvalidationAll, stack: {}}
        this.setState({alert_param: alert_param})
    }

    processMyShareAcknowledgement = () => {
        var taskdata = Store.getStoreData('task');
        let shareacknow_data = [];
        Object.keys(taskdata.shareUserAcknowledge).map((d, key) => {
            let shareacknow_object = {}
            shareacknow_object.id = d
            shareacknow_object.name = this.shareobjectname(d);
            shareacknow_object.status = taskdata.shareUserAcknowledge[d].cur_view
            const infosend_date_obj = new Date(taskdata.shareUserAcknowledge[d].date);
            const infosend_day = infosend_date_obj.getDate() < 10 ? "0"+infosend_date_obj.getDate().toString() : infosend_date_obj.getDate();
            const infosend_month = infosend_date_obj.getMonth()+1 < 10 ? `0${(infosend_date_obj.getMonth() + 1).toString()}` : infosend_date_obj.getMonth()+1;
            const infosend_year = infosend_date_obj.getFullYear();
            const infosend_date = `${infosend_day}/${infosend_month}/${infosend_year}`;
            shareacknow_object.sendate = infosend_date
            let responseDate = "--/--/----";
            if(taskdata.shareUserAcknowledge[d].responsedate !== ""){
                const response_date_obj = new Date(taskdata.shareUserAcknowledge[d].responsedate);
                const response_day = response_date_obj.getDate() < 10 ? "0"+response_date_obj.getDate().toString() : response_date_obj.getDate();
                const response_month = response_date_obj.getMonth()+1 < 10 ? `0${(response_date_obj.getMonth() + 1).toString()}` : response_date_obj.getMonth()+1;
                const response_year = response_date_obj.getFullYear();
                responseDate = `${response_day}/${response_month}/${response_year}`;
            }
            shareacknow_object.response = responseDate;
            shareacknow_data.push(shareacknow_object);
        })
        console.log("shareinfo_data",shareacknow_data)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'Name', accessor: 'name_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Status', accessor: 'status', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Send Date', accessor: 'send_date', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Response Date', accessor: 'response_date', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                {/* <MRButton >View</MRButton> */}
                {
                    (() => {
                        //console.log("row.original.status===>",row.original.status)
                        if(row.original.status !== 'Unopened') {
                            return (<MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id)}>View</MRButton>)

                        }else{
                            return (<MRButton style={{cursor:"pointer"}} onClick={() => this.resendEmailtoUser(row.original.id)}>Resend</MRButton>)
                        }
                    })()
                }
            </div>
          ), width: 120, headerStyle: {textAlign: 'center'}}];
        for(let d of shareacknow_data) {
            i++;
            let elem = {
                    'id': d.id,
                    'name_value' : d.name,
                    'status': d.status,
                    'send_date': d.sendate,
                    'response_date': d.response
                   };
            ret.data.push(elem);
        }
        Store.updateStore('table_cur_acknow_data', ret.data)
        return ret;
    }

    exportToCSVShareAcknow  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_acknow_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",name_value: "Name",status: "Status",send_date:"Send Date",response_date: "Response Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "name_value" : cur_table_data[k].name_value ,
                "status" : cur_table_data[k].status ,
                "send_date" : cur_table_data[k].send_date ,
                "response_date" : cur_table_data[k].response_date ,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
            container.pop()
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Share_for_acknowledgement.csv");
        document.body.appendChild(link);
        link.click();
      }

      exportToCSVFollowupAction  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_followup_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {index: "1234",assign_to: "Assign To",notes: "Notes",due_date:"Due Date",issue_date: "Issue Date",statustext: "Status"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "assign_to" : cur_table_data[k].assign_to ,
                "notes" : cur_table_data[k].notes ,
                "due_date" : cur_table_data[k].due_date ,
                "statustext" : cur_table_data[k].statustext,
                "index" :  cur_table_data[k].index ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
            container.pop()
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "follow_up_actions.csv");
        document.body.appendChild(link);
        link.click();
      }

    viewrcmDetails = () => {
        this.setState({ viewModal: 'rcmDetails'})
    }
    shareinfoView = () => {
        this.setState({ viewModal: 'shareInfoAndAction'})
    }
    closeModal = () => {
        this.setState({ viewModal: null})
    }

    render()
    {
        let parent_task_id = Store.getStoreData('parent_task_id');
        let tableType = Store.getStoreData('table_type');
        let currentTask = Store.getStoreData('current_task');
        console.log("Current task===>",currentTask)
        let lastAction = currentTask.action_data[currentTask.action_data.length-1];
        let contacts_list = Store.getStoreData('contacts');
        let thisTaskDetail = Store.getStoreData('this_task_detail');
        let thisTaskChildren = thisTaskDetail.task.childtask.result;
        let thisTask = thisTaskDetail.task.parenttask;
        let contacts = {};
        for(var i=0; i < contacts_list.length; i++) {
            contacts[contacts_list[i].ID.toString()] = contacts_list[i].ContactName;
        }

        let docType = "";
        //   docType = currentTask.object_data.documentTypes;
        //   if(!isNaN(currentTask.object_data.documentTypes)) {
        //       //const documentType = Store.getStoreData('rutypename');
        //       const documentType = Store.getStoreData('rutypenameAll');
        //       let doctype = {};
        //       for(let i=0; i < documentType.length; i++) {
        //           doctype[documentType[i].id] = documentType[i].documentname;
        //       }
        //       docType = doctype[currentTask.object_data.documentTypes]
        //   }
        let dNames = "";
        if(currentTask.object_data.documentTypes !== null) {
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            }
            let i=0;
            for (let doc of currentTask.object_data.documentTypes){
                i++;
                if(doc in doctype){
                    dNames += doctype[doc]
                    if(i <= currentTask.object_data.documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }

        let assignedTo = contacts[lastAction.assigned_to];
        let rufeed = Store.getStoreData('task');
        //console.log("Current task111===>",rufeed)
		const issue_date_obj = new Date(rufeed.issueDate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_day}/${issue_month}/${issue_year}`;
        let mod_date = "N/A";
        if (typeof rufeed.modificationDate !== 'undefined') {
            if(rufeed.modificationDate !== null){
            //console.log("modificationDate",rufeed.modificationDate)
            let modificationDate = rufeed.modificationDate;
            if (modificationDate.includes(" ") === true){
                modificationDate = modificationDate.replace(" ", "+");
            }

            const mod_date_obj = new Date(modificationDate);
            const mod_day = mod_date_obj.getDate() < 10 ? "0"+mod_date_obj.getDate().toString() : mod_date_obj.getDate();
            const mod_month = mod_date_obj.getMonth()+1 < 10 ? `0${(mod_date_obj.getMonth() + 1).toString()}` : mod_date_obj.getMonth()+1;
            const mod_year = mod_date_obj.getFullYear();
            mod_date = `${mod_day}/${mod_month}/${mod_year}`;
            }
        }
        // let uploadDate = rufeed.uploadDate;
        // if (uploadDate.includes(" ") === true) {
        //     uploadDate = uploadDate.replace(" ", "+");
        // }
        // const upload_date_obj = new Date(uploadDate);
		// const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
		// const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
		// const upload_year = upload_date_obj.getFullYear();
        // const upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        let upload_date = "N/A";
        if (rufeed.uploadDate !== null) {
            let uploadDate = rufeed.uploadDate;
            if (uploadDate.includes(" ") === true) {
                uploadDate = uploadDate.replace(" ", "+");
            }
            const upload_date_obj = new Date(uploadDate);
            const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
            const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
            const upload_year = upload_date_obj.getFullYear();
            upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        }else{
            upload_date = issue_date;
        }
        let prdate = "N/A";
        if (typeof rufeed.publicResponseDate !== 'undefined') {
        if (rufeed.publicResponseDate !== null) {
            let publicResponseDate = rufeed.publicResponseDate;
            if (publicResponseDate.includes(" ") === true) {
                publicResponseDate = publicResponseDate.replace(" ", "+");
            }
            const pr_date_obj = new Date(publicResponseDate);
            const pr_day = pr_date_obj.getDate() < 10 ? "0"+pr_date_obj.getDate().toString() : pr_date_obj.getDate();
            const pr_month = pr_date_obj.getMonth()+1 < 10 ? `0${(pr_date_obj.getMonth() + 1).toString()}` : pr_date_obj.getMonth()+1;
            const pr_year = pr_date_obj.getFullYear();
            prdate = `${pr_day}/${pr_month}/${pr_year}`;
        }
        }
        let today = new Date();
        let dueDate = new Date(thisTask.due_date);

        let taskStatus = today <= dueDate ? 'Pending' : 'Overdue';
        taskStatus = thisTaskChildren.length > 0 ? taskStatus + ', In progress' : taskStatus;
        let taskStatusColor = '#FF0000';
        switch(taskStatus) {
            case 'Pending' : taskStatusColor = '#b3b300'; break;
            case 'Overdue' : taskStatusColor = '#FF0000'; break;
            case 'Pending, In progress' : taskStatusColor = '#23FF78'; break;
            case 'Overdue, In progress' : taskStatusColor = '#FF4545'; break;
        }
        return (<div>
            <RegModalContainer>
            <RegModalHeaderText>{rufeed.documentName}</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "30px"}}/>
            <RegDismisOuterContainer>
                <OverviewInfoBody style={{marginBottom: "10px"}}>
                    <div style={{marginBottom: "30px",display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type:</span>&nbsp; {dNames}</div>
                    <div style={{marginBottom: "30px",display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Regulator: </span>&nbsp; {rufeed.issuingBodies}</div>
                    <div style={{float: "right",marginBottom: "30px",display: "inline-block"}} onClick={this.viewrcmDetails}><span style={{fontWeight: "bold", color: "#57C3C1",textDecoration: "underline",cursor: "pointer"}}>View RU</span></div>
                    <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(rufeed.summary))}} style={{textAlign: "justify"}}></div>
                </OverviewInfoBody>
            </RegDismisOuterContainer>
            <hr style={{borderTop: "0px solid #ccc"}}/>
            {
                (() => {
                    let shareUserInfo = 0;
                    if('shareUserInfo' in rufeed) {
                        shareUserInfo = Object.keys(rufeed.shareUserInfo).length
                            return(
                                <div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Information ( {shareUserInfo} )</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                <CSLTable add={false} processData={this.processMyShareInformation} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                                <RegCancelSubmitButtonOuterContainer>
                                    {
                                        (() => {
                                                var taskdata = Store.getStoreData('task');
                                                if(Object.keys(taskdata.shareUserInfo).length !== 0){
                                                    return(<RegSubmitButton onClick={this.exportToCSVShareInfo}>Export</RegSubmitButton>)
                                                }
                                        })()
                                    }
                            </RegCancelSubmitButtonOuterContainer>
                            </RegDismisOuterContainer>
                            </div>)
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Information ( {shareUserInfo} )</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#bdbdbd"}}>There are currently no Information Shared</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()
            }
            {
                (() => {
                    let shareUserAcknowledge = 0;
                    if('shareUserAcknowledge' in rufeed) {
                        shareUserAcknowledge = Object.keys(rufeed.shareUserAcknowledge).length
                            return(
                            <div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Action ( {shareUserAcknowledge} )</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                <CSLTable add={false} processData={this.processMyShareAcknowledgement} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                                <RegCancelSubmitButtonOuterContainer>
                                {
                                    (() => {
                                            var taskdata = Store.getStoreData('task');
                                            if(Object.keys(taskdata.shareUserAcknowledge).length !== 0){
                                                return(<RegSubmitButton onClick={this.exportToCSVShareAcknow}>Export</RegSubmitButton>)
                                            }
                                    })()
                                }

                            </RegCancelSubmitButtonOuterContainer>
                            </RegDismisOuterContainer>
                            </div>)
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared for Action ( {shareUserAcknowledge} )</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#bdbdbd"}}>There are currently no Action Shared</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()
            }
            <RegDismisOuterContainer style={{padding: "0px"}}>

                <RegisterChildTasksRUTable add={false} headertext = "Follow up Actions" followup_tasks={thisTaskChildren}/>

            </RegDismisOuterContainer>
            <RegCancelSubmitButtonOuterContainer>
                {
                    (() => {
                        if(Store.getStoreData('isreferenceUrl') !== true && Store.getStoreData('isReferenceEmail') !== true){
                            return(<RegSubmitButton onClick={this.exportToCSVFollowupAction}>Export</RegSubmitButton>)
                          }
                    })()
                }
            </RegCancelSubmitButtonOuterContainer>
            <RegCancelSubmitButtonOuterContainer>
                <RegCancelButton onClick={this.closeRegulatoryModal}>Close</RegCancelButton>
            </RegCancelSubmitButtonOuterContainer>
            {
                (() => {
                    if(this.state.viewModal !== null) {
                        switch(this.state.viewModal) {
                            case 'rcmDetails' : return <RCMDetailsRegisterModal refreshstate={this.refreshstate} closeModal={this.closeModal}/>; break;
                            case 'shareInfoAndAction' : return <ShareInfoAndActionModal refreshstate={this.refreshstate} emailLogData={this.state.emailLogData} closeModal={this.closeModal}/>; break;
                        }
                    }
                })()
            }
        </RegModalContainer>
        <AlertBox alertParam = {this.state.alert_param}/>
        </div>);
    }
}

export default RegisterClientModal;
