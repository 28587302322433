import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import HtmlEditor from '../Common/HtmlEditor';
import TasksHOC from '../Tasks/TasksHOC';
import { IoMdCreate, IoIosClose, IoMdAdd } from "react-icons/io";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
const CSLDateCover = styled.div`
  margin-top: 25px;
  /*width: 40%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #282828;
  background-color: #ffffff;*/
  height: 30px;
  padding-top: 2px;
  padding-left: 2px;
  float: left;
  position: relative;
`;
const CSLtableDate = styled.div`
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: #282828;
	background-color: #ffffff;
	height: 30px;
	position: relative;
	`;
const SchemeDateCover = styled.div`
	margin-top: 4px;
	width: 100%;
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: #282828;
	background-color: #ffffff;
	height: 25px;
	padding-left: 2px;
	float: right;
	position: absulate;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 0px;
`;
const MRButton = styled.button`
	width: 33px;
	font-size: 20px;
	height: 28px;
	margin-left: 10px;
	cursor: pointer
`;

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;

class ShowhintModal extends React.Component
{
	state = {
		ready: false,
		task: {},
		ref: {},
		frequency: "monthly",
		alert_param: null,
		binFiles: [],
		cur_files_json: null,
		hint_loading: false,
		show_ref: ''
	};
	constructor(props) {
	    super(props);
	}
	closeModal = (event) => {
	    event.preventDefault();
	    this.setState({hint_loading: false})
	    this.props.closeModal()
	}
	closeRefModal = (is_reload=null) => {
		// event.preventDefault();
		// alert(is_reload)
		this.setState({show_ref: ''})
		// this.props.closeModal(is_reload)
		if(is_reload!==null){
			this.props.closeModal(is_reload)
		}
	}
	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	showReferenceTask = (event) => {
		this.setState({show_ref: "view_tasks"})
	}

	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}

	processTaskData = () => {
		this.setState({hint_loading: false})
		let postData = {};
		postData['command'] = 'show_hint';
		postData['hint_id'] = this.props.hint_id;
		postData['hint_task_id'] = this.props.hint_task_id;
		postData['module_id'] = this.props.module_id;
		postData['due_date'] = this.props.due_date;
		let api = new APICall();
		api.command(postData, this.showhintTask);
	}

	showhintTask = (result) => {
		console.log("result.name", result)
		let task = result.result
		task.due_date = this.getDate(task.due_date)
		let description = ('description' in task) ? task.description : '';
		if(Utils.isBase64(description)){
		  description = window.decodeURIComponent(atob(description))
		}
		task['description'] = description;
		let ref = ('ref' in result) ? result.ref : {};
		this.setState({task: task,ready: true, hint_loading: false, ref: ref })
	}

	filesLoaded = (jsonObj) => {
	    let contact_id = Store.getStoreData('contact_id');
	    let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
	    let binFiles = this.state.binFiles;

	    console.log("binFiles", binFiles)
	    console.log("jsonObj", jsonObj)

	    let file_exists = {};
	    let curBinFiles = [];

	    if(jsonObj.command === 'add'){
	        for(let i=0; i < files_to_show.length; i++) {
	            if(typeof files_to_show[i].name !== "undefined"){
	                files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
	                if(!('user_id' in files_to_show[i])) {
	                    files_to_show[i].user_id = contact_id;
	                    files_to_show[i].upload_date = Date.now();
	                }
	                if(typeof file_exists[files_to_show[i].name] === "undefined"){
	                    binFiles.push(files_to_show[i]);
	                    file_exists[files_to_show[i].name] = true;
	                }
	            }

	        }
	        curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
	        this.setState({binFiles: curBinFiles});
	    }else{
	        for(let i=0; i < binFiles.length; i++) {
	            if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
	                binFiles.splice(i,1);
	            }
	        }
	        this.setState({binFiles: binFiles});
	    }
	}

	createTask = (submit_form) => (event) => {
		this.setState({hint_loading: true})
		let task = this.state.task;
		let description = Store.getStoreData('description');
		if(description === null){
		    description = task['description'];
		}
		let description_summary = btoa(window.encodeURIComponent(description));
		task['description'] = description_summary
		let bin_files = this.state.binFiles;
		task['command'] = "add_hint_task";
		task['bin_files'] = bin_files;
		console.log("task", task)
		let api = new APICall();
		api.command(task, this.processAddTask);

	}

	processAddTask = (result) =>
	{
		console.log("result", result)
		let mgs = "The task has been updated.";
		let title = "Task Saved";
		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, hint_loading: false})
	}

	alertremoveHandler = (result, stack) => {
		this.props.closeUserDialog(true);
	}


	handlehintChange = (event) => {
		let task = this.state.task;
		task[event.target.name] = event.target.value;
		this.setState({task: task})
	}

	onscheduleDateChange = (date) => {
		let task = this.state.task;
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    task.due_date = dateStr;
	    this.setState({task: task});
	}

	getDate = (input_date) => {
	  if(input_date != null){
	    let dateStr = input_date.split('T')[0];
	    if(dateStr === "1970-01-01"){
	        return '';
	    }
	    let dateParts = dateStr.split('-');
	    let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
	    return ret;
	  }else{
	    return '';
	  }

	}





	render()
	{

		if(!this.state.ready) {
		    return <div><CSLLoader /></div>;
		}
		console.log("this.state", this.state)
		let task =  this.state.task;
		let created_by = task.contact_id;
		let assign_to = task.assign_to;
		let loggedincontact = Store.getStoreData('loggedincontact').contact_id;
		let frequency = Utils.list_shedules();
		let role = Store.getStoreData('role');
		let description = ('description' in task) ? task.description : '';
		if(Utils.isBase64(description)){
		  description = window.decodeURIComponent(atob(description))
		}
		console.log("this.state", frequency)
		return (<div style={{backgroundColor: "#f2f2f3"}}>
					<Style.ModalContainer>
					    <Style.ModalHeaderText>Future Task</Style.ModalHeaderText>
					    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>

					    <div style={{clear: "both"}}></div>
					    <Style.ModalBody>
					        <Style.ModalBodyInner>
					            <Style.HeaderText>
					            	The task is a part of the following recurring task: <span style={{cursor: "pointer", color: "#115d9c"}} onClick={this.showReferenceTask}>{this.state.ref['ref_id']}</span>
					            </Style.HeaderText>
					            <Style.ModalContainertop>
					            	<Style.ModalNameLabelDiv>Task Name</Style.ModalNameLabelDiv>
					            	<Style.ModalNameInputDiv>
					            	    {/*<Style.ModalInput name="name" value={task.name.replace(/'+/g,"'")} onChange={this.handlehintChange} readOnly={true}/>*/}
					            	    <Style.ModalInputReadonly name="name" value={task.name.replace(/'+/g,"'")} readOnly={true}/>
					            	</Style.ModalNameInputDiv>
					            	<Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
					            	<Style.ModalNameInputDiv>
					            		{/*<Style.ModalTextArea name="description" value={task.description.replace(/'+/g,"'")} onChange={this.handlehintChange} />
					            		<HtmlEditor style={{width: '98%', padding: "5px"}} content={description} readOnly={true}/>*/}
					            		<div dangerouslySetInnerHTML={{__html: description}} style={{textAlign: "justify"}}></div>
					            	</Style.ModalNameInputDiv>

        			                {
        			                	(() => {
        			                		if(Store.getStoreData('role') !=='team') {
        			                			return(<div style={{display: "contents"}}>
        	                				                <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
        	                				                <Style.ModalNameInputDiv>
				                                				{/*<Style.MRModalSelect name="assign_to" value={assign_to} onChange={this.handlehintChange}>
				                			                    {
				                			                        Store.getStoreData('list_contacts').map((item) => {
				                			                            {
				                			                                return (
				                			                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
				                			                                );
				                			                            }
				                			                        })


				                			                    </Style.MRModalSelect>*/}
				                			                    <div>{Store.getStoreData('contacts')[this.state.task.assign_to]}</div>
        	                				                </Style.ModalNameInputDiv>
        			                				</div>)
        			                		}
        			                	})()
        			                }


					            	<Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
	                					<Style.ModalNameInputDiv>
			                				{/*<Style.MRModalSelect name="frequency" value={task.frequency} onChange={this.handlehintChange} readOnly={true}>
						                    {
						                        frequency.map((item) => {
						                            {
						                                return (
						                                    <option key={item.id} value={item.name}>{item.nickname}</option>
						                                );
						                            }
						                        })
						                    }
						                    </Style.MRModalSelect>*/}
						                    <div>{task.frequency}</div>
					                    </Style.ModalNameInputDiv>
				                    <Style.ModalNameLabelDiv>Due Date</Style.ModalNameLabelDiv>
	                    				<CSLDateCover>
	    				                    {/*<DatePicker
	    				                              onChange={this.onscheduleDateChange}
	    				                              clearIcon={null}
	    				                              calendarIcon={null}
	    				                              locale={"en-GB"}
	    				                              value={new Date(task.due_date)}
	    				                            />*/}
	    				                            {task.due_date}
	    				                </CSLDateCover>

	    				            <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
	    				            <Style.ModalNameInputDiv style={{paddingTop: "25px"}}>
	    				            {
	    				            	(() => {
	    				            		if(this.state.binFiles.length > 0){
	    				            			return <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} addnew={false} />
	    				            		}else{
	    				            			return "No attachments"
	    				            		}
	    				            	})()
	    				            }

	    				            </Style.ModalNameInputDiv>
					            </Style.ModalContainertop>
					        </Style.ModalBodyInner>
					    </Style.ModalBody>
					    <Style.CancelSubmitButtonOuterContainer>
		                	<Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
		                	{/*<Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.createTask(1)}>Save</Style.SubmitButton>*/}
		                </Style.CancelSubmitButtonOuterContainer>


					    {
					    	(() => {
					    		if(this.state.hint_loading){
					    			return <CSLLoader />
					    		}
					    	})()
					    }
					</Style.ModalContainer>
					{
					  (() => {
					    switch(this.state.show_ref) {
									case 'view_tasks' : return <div><InactiveOverlay /><TasksHOC task_id={this.state.ref['task_id']} module_id='tmview' closeUserDialog={this.closeRefModal} cur_lane={this.state.ref['cur_lane']} /></div>; break;
					    }
					  })()
					}
					<AlertBox alertParam = {this.state.alert_param} />
				</div>)

				}
		}
		export default ShowhintModal;
