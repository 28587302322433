import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import CSLTable from '../Common/CSLTable';
import RegulatoryTeamModal from '../Modals/RegulatoryTeamModal';
import RegisterClientModal from '../Modals/RegisterClientModal';
import RegisterManagerViewModal from './RegisterManagerViewModal';
import CSLLoader from '../../Components/Common/CSLLoader';
import CSLbucketloader from '../Common/CSLbucketloader';
import Utils from '../../Common/Utils';
import RegisterViewCount from './RegisterViewCount';
import FollowupTaskModal from '../Select/FollowupTaskModal';
import RegulatoryShareModal from './../Modals/RegulatoryShareModal';
import AlertBox from './../Common/AlertBox';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 130px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
`;

const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;    
    margin-top: 30px;  
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  
`;
const HeaderButton = styled.button`
    box-sizing: border-box;
    background-color: #EBEBEB;
    color: #606575;
    border: 1px solid #CECECE;
    width: 100px;
    font-size: 13px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 0 4px #CECECE;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;    
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    width: 97.6%;
    margin: 0 auto;
    margin-bottom: 30px;    
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -132px;
  z-index: 1000;
  opacity: 0.8;
`;

class RegisterManagerView extends React.Component
{
    state = {
        filter: {
          All: 1,
          Inprogress: 0,
          Pending: 0,
          Completed: 0,
        },
        ready: false,
        task_list: null,
        modal_view:null,
        view_count: 0,
        alert_param: null
    };

    goToTaskUrlBare = (taskID) => {
      console.log('share_with_me', Store.getStoreData('share_with_me'));
      this.showRcmregisterData(taskID);
    }

    processDataRefresh = () => {
      let api = new APICall();
      let postData = {};
      if(Store.getStoreData('rutaskdata') === null) {
          postData['command'] = 'get_rcm_registry';
          console.log("postdata", postData)
          api.command(postData, this.processregisterRCM)
      }
      if(Store.getStoreData('userViewData') === null) {
        postData['command'] = 'get_rcm_registry_user';
        console.log("postdata", postData)
        api.command(postData, this.processregisterVuewUserRCM)
      }
      this.setState({ready: true})
  }

  processregisterVuewUserRCM = (result) => {
    console.log("result===>",result)
    Utils.log("userViewData", result)
    Store.updateStore('userViewData', result.userViewlist);
    this.setState({view_count: this.state.view_count + 1});
  }

  processregisterRCM = (result) => {
      console.log("result===>",result)
      Utils.log("rutaskdata", result)
      Utils.log(Store.getStoreData('contacts'))
      let countRUresult = RegisterViewCount.managerViewCount(result);

      let task_id = this.props.taskID;
      console.log("task_id===555>",task_id)
      if(task_id > 0) {
        console.log("task id", task_id)
        this.goToTaskUrlBare(task_id)
      }else{
        this.setState({view_count: this.state.view_count + 1});
      }
  }
  componentDidMount() {
      let task_id = this.props.taskID;
      console.log("task_id===555>",task_id)
      if(task_id > 0) {
        console.log("task id", task_id)
        this.goToTaskUrlBare(task_id)
      }else{
        this.processDataRefresh()
      }
  }

  // componentDidUpdate(prevProps)
  // {
  //     if (prevProps !== this.props) {
  //         let task_id = this.props.taskID;
  //         console.log("task_id===555>",task_id)
  //         if(task_id > 0) {
  //           console.log("task id", task_id)
  //           this.goToTaskUrlBare(task_id)
  //         }else{
  //           this.processDataRefresh()
  //         }
  //     }
  // }

    getStatus = (row) => {
      let lane_parts = row.cur_lane.split('_')
      let lane_end = lane_parts[lane_parts.length - 1]
      let status = 'Open'
      if(lane_end == 'DISMISS'){
        status = 'Dismissed'
      }else if(lane_end == 'COMPLETED'){
        status = 'Completed'
      }else{
        status = 'Open'
      }
      return status;
    }

    getInclusion = (filter) => {
      let count = 0;
      let keys = Object.keys(filter)
      for(let i=0; i < keys.length; i++) {
        count += filter[keys[i]] * this.state.filter[keys[i]]
      }
      return (count > 0)
    }

    processAcceptanceQueueData = () => {
        let taskData = Store.getStoreData('rutaskdata') === null ? [] : Store.getStoreData('rutaskdata');
        //console.log("Result_processAcceptanceQueueData",taskData);
         let ret = {data: [], columns: []};
         let i = 1;
        ret.columns =[
                    // {Header: 'Name', accessor: 'document_name_show', minWidth: 140, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Name', accessor: 'name', Cell: row => (
                      <div>
                          {
                              (() => {
                                  if(row.original.externalLink !== ''){
                                      return(<a target="_blank" href={row.original.externalLink}>{row.original.name}</a>)
                                  }else{
                                      return(<div>{row.original.name}</div>)
                                  }
                              })()

                          }

                      </div>
                    ), minWidth: 140, headerStyle: {textAlign: 'left'}},
                    {Header: 'Type', accessor: 'type', minWidth: 70, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator', accessor: 'regulator', minWidth: 70, headerStyle: {textAlign: 'left'}},
                    {Header: 'Shared – Info', accessor: 'sharedinfo', minWidth: 40, headerStyle: {textAlign: 'left'}},
                    {Header: 'Shared – Action', accessor: 'sharedaction', minWidth: 40, headerStyle: {textAlign: 'left'}},
                    {Header: 'Follow Up Tasks', accessor: 'followuptasks', minWidth: 40, headerStyle: {textAlign: 'left'}},
                    // {Header: 'Issuing Date', accessor: 'issuing_date', minWidth: 170, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                      <div>
                          <MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerView(row.original.id)}>View</MRButton>
                      </div>
                    ), width: 120, headerStyle: {textAlign: 'center'}},

                    ];

        for(var j = 0 ; j< taskData.length ; j++)
        {
          let docType = "";
          docType = taskData[j].documentType;
          if(docType !== ''){
          if(!isNaN(taskData[j].documentType)) {
              //const documentType = Store.getStoreData('rutypename');
              const documentType = Store.getStoreData('rutypenameAll');
              let doctype = {};
              for(let i=0; i < documentType.length; i++) {
                  doctype[documentType[i].id] = documentType[i].documentname;
              }
              docType = doctype[taskData[j].documentType]
          }
          }
          console.log("taskData[j]", docType)
          let elem = {'id' : taskData[j].refid,
                      'name' : taskData[j].ruName,
                      // 'document_name_show' : taskData[j].externalLink !== '' ? <a target="_blank" href={taskData[j].externalLink}>{taskData[j].ruName}</a> : taskData[j].ruName,
                      'externalLink': taskData[j].externalLink,
                      'type' : typeof docType === 'undefined' ? '': docType,
                      'regulator' : taskData[j].regulatorName,
                      'sharedinfo' : taskData[j].shareInfo.toString(),
                      'sharedinfopeople': taskData[j].shareinfoPepoleName,
                      'sharedaction' : taskData[j].shareAcknow.toString(),
                      'sharedactionpeople': taskData[j].shareacknowPepoleName,
                      'followuptasks': taskData[j].shareFollowup.toString()
          };
            ret.data.push(elem);
        }
        return ret;
      }

      gotoManagerView = (id) => (event) => {
        event.preventDefault();
        console.log(id)
        let postData = { "command": "getManagerViewDetails", ref_id : id  };
        let api = new APICall();
        api.command(postData, this.managerGettask);
      }
      managerGettask = (result) => {
        console.log("result===>",result);
        Store.updateStore('managerViewDetails', result.result);
        Store.updateStore('currentFollowupTask', result.result.shareFollowup);
        this.setState({modal_view:'RegisterManagerViewModal',ready: true})
      }

      goToTaskUrl = (id) => (event) => {
        event.preventDefault();
        this.showRcmregisterData(id);
      }

      showRcmregisterData = (id) => {
        Store.updateStore('current_task_id', id);
        if('cur_lane' in this.props && (this.props.cur_lane === 'COMP_RCM_FOLLOWUP_ASSIGNMENT' || this.props.cur_lane === 'COMP_RCM_FOLLOWUP_COMPLETE')){
          let followupid = id;
          let postData = { command: "get_subtask", task_id: followupid};
          let api = new APICall();
          api.command(postData, this.openFollowupModal);
        }else{
          let postData = { "command": "getTaskCallbyTaskID", task_id : id  };
          postData['gcs'] = Store.getStoreData('gc_filters');
          let api = new APICall();
          api.command(postData, this.clientgettask);
        }
      }

      clientgettask = (result) => {
        console.log("MON==>",result)
        if(result.error_code > 0) {
          let msg = result.error_msg;
          alert(msg)
          return false;
        }
        if(result.result.task.parenttask.cur_lane === "COMP_RCM_FOLLOWUP_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_RCM_FOLLOWUP_COMPLETE"){
          let followupid = result.result.task.parenttask.id;
          let postData = { command: "get_subtask", task_id: followupid};
          let api = new APICall();
          api.command(postData, this.openFollowupModal);
        }else{
          let parent_task_id = result.result.task.parenttask.parent_task_id;
          let task = JSON.parse(result.result.task.parenttask.task_json);
          let csl_cl_contactID = Store.getStoreData('loggedincontact');
          let role = Store.getStoreData('role');
          if(role !== 'team' && 'shareobject' in task.object_data && csl_cl_contactID in task.object_data.shareobject){
              Store.updateStore('this_task_detail', result.result);
              Store.updateStore('parent_task_id', parent_task_id);
              Store.updateStore('childtasks', result.result.task['childtask'].result);
              Store.updateStore('task', task.object_data);
              Store.updateStore('current_task', task);
              this.setState({modal_view:'sharetaskModal',ready: true})
          }else{
            Store.updateStore('this_task_detail', result.result);
            Store.updateStore('parent_task_id', parent_task_id);
            Store.updateStore('table_type', 'new');
            Store.updateStore('childtasks', result.result.task['childtask'].result);
            Store.updateStore('task', task.object_data);
            Store.updateStore('current_task', task);
            if(role === 'manager'){
              if('shareobject' in task.object_data && (!(csl_cl_contactID in task.object_data.shareobject))){
                // alert("You have already acknowledged this RU.");
                this.setState({modal_view:'RegisterClientModal',ready: true})
              }else{
                this.setState({modal_view:'RegisterClientModal',ready: true})
              }
            } else if(role === 'team'){
              if('shareobject' in task.object_data && (!(csl_cl_contactID in task.object_data.shareobject))){
                //alert("You have already acknowledged this RU.");
                let alert_param = {title: 'ERROR', message: 'You have already acknowledged this RU.', ok_text: 'OK', confirm: false,
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
                this.setState({alert_param: alert_param})
                //window.location = 'process.env.REACT_APP_RCM_URL';
              }else{
                this.setState({modal_view:'RegulatoryTeamModal',ready: true})
              }
            }else if(role === 'rcm_gatekeeper'){
                //alert("ERROR: Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error.");
                let alert_param = {title: 'ERROR', message: 'Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error.', ok_text: 'OK', confirm: false,
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
                this.setState({alert_param: alert_param})
                //window.location = 'process.env.REACT_APP_RCM_URL';
            }
          }
        }

    }

    updateafterAlertWithLoading = (result, stack) => {
      console.log(result,stack)
      this.setState({alert_param: null})
      window.location = 'process.env.REACT_APP_RCM_URL';
  }

    openFollowupModal = (result) => {
      //console.log("followup_task",result.result.task);
      Store.updateStore('cur_followup_task', result.result.task.subtask)
      Store.updateStore('cur_parentTask', result.result.task.parenttask)
      this.setState({ modal_view: 'view_followup_task',ready: true})
    }

    handleBtn = (event) => {
        event.preventDefault();
        let filter = this.state.filter;
        if( [event.target.name] == "All"){
          filter.All = !filter.All ? 1 : 0
        }
        if( [event.target.name] == "Inprogress"){
          filter.Inprogress = filter.Inprogress === 0 ? 1 : 0
        }
        if( [event.target.name] == "Pending"){
          filter.Pending = filter.Pending === 0 ? 1 : 0
        }
        if( [event.target.name] == 'Completed'){
          filter.Completed = filter.Completed === 0 ? 1 : 0
        }
        this.setState({filter})
    }
    refreshCallback = () => {
      this.props.refreshCallback();
    }
    closeModal = () => {
      this.setState({ modal_view: null });
    }

    closeModalNew = (event) => {
      if(Store.getStoreData('isreferenceUrl') === true){
        if(Store.getStoreData('isReferenceEmail') === true){
          window.location = 'process.env.REACT_APP_RCM_URL';
        }else{
          window.location = '/';
        }
      } else {
        this.setState({ modal_view: null });
      }
  }

    userView = () => {
        this.props.modalView('userView');
    }

    closeTaskModal = (event) => {
      this.props.closeUserDialog();
  }

    render()
    {
      let prefered_columns ={};
      if(!this.state.ready){
        return <div><CSLLoader /></div>
      }
      let task_id = this.props.taskID;
        return (<div>
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                  {
                    (() => {
                      if(task_id === 0){
                        return(
                          <RegDismisOuterContainer>
                          {
                            (() => {
                              let role = Store.getStoreData('role');
                              if(role === 'manager' || role === 'rcm_gatekeeper')
                              {
                                return <OverviewInfoHeader>
                                  <div style={{float: "left", color: "#282828", fontWeight: "600"}}>Regulatory Change Register</div>
                                  <div style={{float: "right", color: "#CBCBCB", fontWeight: "600"}}><HeaderButton style={{color: "#D4D5D8"}} disabled>Team View</HeaderButton></div>
                                  <div style={{float: "right", color: "#CBCBCB", fontWeight: "600"}}><HeaderButton style={{color: "#D4D5D8", cursor: "pointer"}} onClick={this.userView} >User View</HeaderButton></div>
                                  <div style={{float: "right", color: "#CBCBCB", fontWeight: "600"}}><HeaderButton >Manager View</HeaderButton></div>
                                  <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                              }else {
                                return <OverviewInfoHeader>
                                <div style={{float: "left", color: "#282828", fontWeight: "600"}}>Regulatory Change Register</div>
                              </OverviewInfoHeader>
                              }
                            })()
                          }
                          <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                            {
                              (()=>{
                                    prefered_columns = {'name': 'Name', 'type': 'Type', 'regulator' : 'Regulator', 'sharedinfopeople': 'Info', 'sharedinfo': 'Shared - Info', 'sharedactionpeople' : 'Action', 'sharedaction': 'Shared - Action', 'followuptasks' : 'Follow Up Task'}
                                    return(
                                      <div  style={{position: 'relative'}}>
                                      {
                                          (() => {
                                                  if(Store.getStoreData('rutaskdata') === null) {
                                                  return <CSLbucketloader style={{position: 'absolute'}} />
                                                  }
                                          })()
                                      }
                                      <div style={{clear: "both", height: '3px'}}></div>
                                      <CSLTable add={false} export={true} export_file_name={"Register"} prefered_columns = {prefered_columns} processData={this.processAcceptanceQueueData} tableRows={5} refreshCallback={this.refreshCallback} qcbutton={true} />
                                      </div>
                                      )
                              })()
                            }
                            </OverviewInfoBody>
                          </RegDismisOuterContainer>
                        )
                      }
                    })()
                  }

          </div>
          {
              (() => {
                  if(this.state.modal_view != null){
                    switch(this.state.modal_view){
                      case 'RegisterClientModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><RegisterClientModal closeModal={this.closeModal} closeTaskModal={this.closeTaskModal}/></div>; break;
                      case 'RegulatoryTeamModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><RegulatoryTeamModal closeModal={this.closeModal} closeTaskModal={this.closeTaskModal}/></div>; break;
                      case 'RegisterManagerViewModal' : return <RegisterManagerViewModal closeModal={this.closeModal} closeTaskModal={this.closeTaskModal}/>; break;
                      case 'view_followup_task': return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'50px'}}><InactiveOverlay /><FollowupTaskModal closeModal={this.closeModalNew} closeTaskModal={this.closeTaskModal}/></div>; break;
                      case 'sharetaskModal': return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'50px'}}><InactiveOverlay /><RegulatoryShareModal closeModal={this.closeModalNew} closeTaskModal={this.closeTaskModal}/></div>; break;
                    }
              }
              })()
          }
        </div>
        <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
    }

}

export default RegisterManagerView;
