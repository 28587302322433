import React from "react";
import styled from "styled-components";
import TAPICall from "./src/Common/APICall.js";
import AMAttestationList from './src/Components/AM/AMAttestationList.js';
import TeamIndex from './src/Components/Team/TeamIndex.js';
import AttestStore from "./src/Common/Store.js";
import Store from './../../../Common/Store';

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;


class AttestHOC extends React.Component {

    state={ready: false, role:''}

    componentDidMount(){
        this.setEnv('attest');
    }

    setEnv(module_name) {
        let api_url = `${Store.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;
        AttestStore.updateStore('module_name',module_name);
        AttestStore.updateStore('api_url',api_url);
        AttestStore.updateStore('is_admin',false);
        AttestStore.updateStore('is_debug',false);

        let postData = { command: "tmviewindexcall" };
        let api = new TAPICall();
        api.command(postData, this.processtaskIndex);
    }

    processtaskIndex = (result) => {
        AttestStore.updateStore('module_config', result.result['module_config']);
        AttestStore.updateStore('contact_id', result.result['contact_id']);
        AttestStore.updateStore('curCompanyID', result.result['curCompanyID']);

        //set company start
        let gc_comp = Store.getStoreData('gc_companie');
        let my_mgrp_companies = [];
        for(let k=0;k<gc_comp.length;k++){
            if(gc_comp[k].id !== result.result['curCompanyID']){
                let my_mgrp_comObject = {};
                my_mgrp_comObject.address1 = gc_comp[k].address1
                my_mgrp_comObject.address2 = gc_comp[k].address2
                my_mgrp_comObject.company_name = gc_comp[k].company_name
                my_mgrp_comObject.created_at = gc_comp[k].created_at
                my_mgrp_comObject.id = gc_comp[k].id
                my_mgrp_comObject.is_deleted = gc_comp[k].is_deleted
                my_mgrp_comObject.parent_id = gc_comp[k].parent_id
                my_mgrp_comObject.phone = gc_comp[k].phone
                my_mgrp_comObject.tags_config = gc_comp[k].tags_config !== null ? JSON.stringify(gc_comp[k].tags_config) : null;
                my_mgrp_comObject.updated_at = gc_comp[k].updated_at
                my_mgrp_companies.push(my_mgrp_comObject)
            }
        }
        let gc_companies = my_mgrp_companies;
        console.log("gc_comp===>", gc_comp, "gc_companies=>", gc_companies)
        AttestStore.updateStore('gc_company', gc_comp);
        AttestStore.updateStore('gc_companies', gc_companies);
        //set company end


        let has_gc = gc_comp.length > 1 ? true : false;
        AttestStore.updateStore('has_gc',has_gc);

        let gc_assoc = {}
        let gc_users = {}
        let gc_filters = {};
        for(let gc of gc_comp) {
            let gc_id = gc.id.toString();
            gc_assoc[gc_id] = gc.company_name;
            if(gc.is_gc) {
              gc_filters[gc.id.toString()] = 1;
            }
            if(!gc.is_gc) {
              continue
            }
            for(let uid in gc.users) {
              if(!(uid in gc_users))gc_users[uid] = {}
              gc_users[uid][gc_id] = 1
            }
        }
        console.log("gc_filters==>", gc_users)
        AttestStore.updateStore('gc_filters', gc_filters);
        AttestStore.updateStore('gc_assoc', gc_assoc);
        AttestStore.updateStore('gc_users', gc_users);

        let r = {};
        r['result'] = {};
        let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        console.log('cts==>', cts)
        let user_roles = {};

        let contacts_all = {};
        let contractsemail_all = {};
        let listcontacts_all = [];
        let attestation_share_group_allUser_new = [];
        if('contactlist' in cts.result && 'user_roles' in cts.result) {
          user_roles = cts.result.user_roles['attest'];
          for(let k in cts.result.contactlist){
            if(user_roles[k.toString()] !== undefined){
              contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
              let listConObject = {};
              listConObject.ID = cts.result.contactlist[k.toString()].ID;
              listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
              listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
              listcontacts_all.push(listConObject);
              if (cts.result.user_roles['attest'][k.toString()] !== 'no_access' ) {
                  contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                  let shareGripuObject = {};
                  shareGripuObject.company_id = result.result['curCompanyID'];
                  shareGripuObject.email = cts.result.contactlist[k.toString()].EmailAddress;
                  shareGripuObject.name = cts.result.contactlist[k.toString()].ContactName;
                  shareGripuObject.role = cts.result.user_roles['attest'][k.toString()];
                  shareGripuObject.user_id = cts.result.contactlist[k.toString()].ID;
                  attestation_share_group_allUser_new.push(shareGripuObject);
              }
            }
          }
          console.log("user_roles", user_roles);
          AttestStore.updateStore('userroles', user_roles);
          AttestStore.updateStore('contacts', contacts_all);
          AttestStore.updateStore('contactsemail', contractsemail_all);
          AttestStore.updateStore('reviewee_list', listcontacts_all);
          AttestStore.updateStore('attestation_share_group_allusers', attestation_share_group_allUser_new);
        //   let language_pack = localStorage.getItem("language_pack")!==null ? JSON.parse(localStorage.getItem("language_pack")) : {};
          let language_pack = {};
          AttestStore.updateStore('language_pack', language_pack);
        }

        let userroles = AttestStore.getStoreData("userroles");
        let contact = Store.getStoreData("contact");
        let role = userroles[contact.ID.toString()];
        AttestStore.updateStore('role', role);
        AttestStore.updateStore('isreferenceUrl', true)
        this.setState({ready: true, role})
    }

    closeUserDialog = () => {
        this.setState({show_modal: false})
        this.props.closeUserDialog()
    }

    render() {
        if(!this.state.ready)return(<div></div>)
        return(
            <FolderContainer>
                {
                    (() => {
                        switch(this.state.role) {
                        case 'manager' :
                        case 'am' : return <AMAttestationList taskId={this.props.task_id} closeUserDialog={this.closeUserDialog}/>; break;
                        case 'team' : return <TeamIndex taskId={this.props.task_id} closeUserDialog={this.closeUserDialog}/>; break;
                        default: return <div>Sorry, you do not have permission to view this page. </div>;
                        }
                    })()
                }
            </FolderContainer>
        );
    }
}

export default AttestHOC;
