import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import moment from 'moment';

const monthwiseLastDate = {0: 31, 1: 28, 2 : 31, 3: 30, 4: 31, 5: 30 , 6: 31 , 7: 31 , 8: 30, 9: 31, 10: 30 , 11: 31};


const notBase64 = /[^A-Z0-9+/=]/i;

class CommitteeUtils {
	addCommitteesToCommitteesWithCount = (committee_obj) => {
		let role = Store.getStoreData('role');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let user_assignments = Store.getStoreData('user_assignments');
		let committee_with_count = Store.getStoreData('committee_with_count');
		let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		console.log("1 addCommitteesToCommitteesWithCount,",committee_with_count,committee_obj);
		let notecount = 0;
		let is_member_of_this_committee = false;
		let membership_name ='';
		if(typeof JSON.parse(committee_obj.Json_Data).notes != 'undefined'){
			notecount = JSON.parse(committee_obj.Json_Data).notes.length;
		}
		
		if(JSON.parse(committee_obj.Json_Data).committeemembers.length>0){
			JSON.parse(committee_obj.Json_Data).committeemembers.forEach((member) =>{
				if(member.employeeid === parseInt(loggedin_user_info.employeeid)){
					is_member_of_this_committee = true;
					membership_name = member.committeerolename;
				}
			});
		}
		if(role === 'admin_manager'){
			is_member_of_this_committee = true;
			membership_name = '';
		}else if(role === 'governance_manager'){
			if(parent_has_group_companies){
				if('companies' in user_assignments[loggedin_user_info.ID] && committee_obj.gc_id in user_assignments[loggedin_user_info.ID].companies)is_member_of_this_committee = true;
			}else{
				is_member_of_this_committee = true;
			}
		}
		let committee_name = JSON.parse(committee_obj.Json_Data).committeename;
		let jsonobj = { PK_CommitteeID: committee_obj.PK_CommitteeID,  meetingcount: 0 , Json_Data:committee_obj.Json_Data , notecount:notecount , is_member_of_this_committee: is_member_of_this_committee , membership_name: membership_name , IsActive : committee_obj.IsActive, sort_field: committee_name };
		if(committee_with_count === null)committee_with_count.committees = [];
		console.log("2 addCommitteesToCommitteesWithCount,",jsonobj);
		committee_with_count.committees.push(jsonobj);
		console.log("3 addCommitteesToCommitteesWithCount,",committee_with_count);
		Store.updateStore('committee_with_count', committee_with_count);

	}
	convertToCommitteesWithCount = (input_json) => {
		let role = Store.getStoreData('role');
		let loggedin_user_info = Store.getStoreData('loggedin_user_info');
		let user_assignments = Store.getStoreData('user_assignments');
		let employee_contact_assoc = Store.getStoreData('employee_contact_assoc');
		let parent_has_group_companies = Store.getStoreData('parent_has_group_companies');
		let tempcommittees = [];
		let committees_ids = [];
		let meeting_count_hash = {};
		let committee_meeting_date_hash = {};
        input_json.committees.forEach((record) => {
			//console.log("convertToCommitteesWithCount,",record);
            let jsonobj ;
            let notecount = 0;
            let is_member_of_this_committee = false;
            let membership_name ='';
            if(typeof JSON.parse(record.Json_Data).notes != 'undefined'){
                notecount = JSON.parse(record.Json_Data).notes.length;
            }
            
            if(JSON.parse(record.Json_Data).committeemembers.length>0){
                JSON.parse(record.Json_Data).committeemembers.forEach((member) =>{
                    if(parseInt(member.employeeid) === parseInt(employee_contact_assoc[loggedin_user_info.ID].employee_id)){
                        is_member_of_this_committee = true;
                        membership_name = member.committeerolename;
                    }
                });
            }
			//console.log("convertToCommitteesWithCount is_member_of_this_committee,",JSON.parse(record.Json_Data),is_member_of_this_committee);
            if(role === 'admin_manager'){
                is_member_of_this_committee = true;
                membership_name = '';
            }else if(role === 'governance_manager'){
				if(parent_has_group_companies){
					if('companies' in user_assignments[loggedin_user_info.ID] && record.gc_id in user_assignments[loggedin_user_info.ID].companies)is_member_of_this_committee = true;
				}else{
					is_member_of_this_committee = true;
				}
				
            }
            //console.log("convertToCommitteesWithCount is_member_of_this_committee,",record,is_member_of_this_committee);	
            let committee_name = JSON.parse(record.Json_Data).committeename;
            jsonobj = { PK_CommitteeID: record.PK_CommitteeID, companyName: record.CompanyName , meetingcount: record.cnt , Json_Data:record.Json_Data , notecount:notecount , is_member_of_this_committee: is_member_of_this_committee , membership_name: membership_name , IsActive : record.IsActive, sort_field: committee_name, };
            tempcommittees.push(jsonobj);
            tempcommittees.sort(this.fieldCompare)
            committees_ids.push(record.PK_CommitteeID);
            meeting_count_hash[record.PK_CommitteeID] = 0;
        });
		
        let version_hash = {};
        
        input_json.meetings.forEach((record) => {
            let task_ref_id = JSON.parse(record.Json_Data).task_ref_id;
            version_hash[task_ref_id] = record.PK_CommitteeMettingID;
            
        });
		console.log("version_hash,",version_hash);
        input_json.meetings.forEach((record) => {
            let task_ref_id = JSON.parse(record.Json_Data).task_ref_id;
            if(version_hash[task_ref_id] === record.PK_CommitteeMettingID){
                meeting_count_hash[record.FK_CommitteeID] = meeting_count_hash[record.FK_CommitteeID] + 1;
                committee_meeting_date_hash[record.FK_CommitteeID] = record.MettingDate;
            }
        });
        input_json.tasks_list.forEach((record) => {
            record.id = record.task_id;
        });

		let retjson = {committees:tempcommittees , permissions:[] , tasks_list: input_json.tasks_list , meeting_count_hash: meeting_count_hash , committee_meeting_date_hash: committee_meeting_date_hash};
		return retjson;
	}
	
	fieldCompare = (a,b) => {
        return a.sort_field.localeCompare(b.sort_field)
    }

    map_employee_with_contact = (employee_list) =>{
		let employee_contact_assoc = {};
		for(let e of employee_list){
			employee_contact_assoc[e.contact_id.toString()] = {employee_id: e.employeeid, employee_name: e.employeename}
		}
		Store.updateStore('employee_contact_assoc',employee_contact_assoc);
    }

	convertListToTree(list_pre) {
		console.log("convertListToTree,",list_pre);
		let list = JSON.parse(JSON.stringify(list_pre));
		var companyIDs = {};
		list.forEach(function(x) {
			companyIDs[x['id']] = 1;
		});
	    var treeList = [];
	    var lookup = {};
	    list.forEach(function(obj) {
	        if(!(obj['parent_id'] in companyIDs)) {
	        	obj['parent_id'] = 0;
	        }
			obj['children'] = [];
	        lookup[obj['id']] = obj;
	        
	    });
		console.log("lookup,",lookup);
	    list.forEach(function(obj) {
	        if (obj['parent_id'] !== 0) {
				console.log("uu,",obj['parent_id'],lookup[obj['parent_id']]);
	        	lookup[obj['parent_id']]['children'].push(obj);
	        } else {
	            treeList.push(obj);
	        }
	    });
		console.log("convertListToTree,",treeList);
		var retTree = {"children" : treeList, "id" : 0 };
	    return retTree;
	}

    setCommitteeCombinedGcsTopLevel = (list) =>{
		
		for(let lt of list){
			let gcs = [];
			if(lt.children.length > 0){
				gcs = this.getCommitteeCombinedGcsInnerLevel(lt.children);
				gcs.push(lt.gc_id)
				lt.combined_gcs = gcs;
			}else{
				gcs.push(lt.gc_id)
				lt.combined_gcs = gcs;
			}
		}
		//console.log("setCommitteeCombinedGcsTopLevel gcs,",gcs);
		return list;
    } 
    getCommitteeCombinedGcsInnerLevel = (list) =>{
		//console.log("setCommitteeCombinedGcsTopLevel gcs,",list);
		let gcs = [];
		for(let lt of list){
			if(lt.children.length > 0){
				gcs = this.getCommitteeCombinedGcsInnerLevel(lt.children);
				gcs.push(lt.gc_id)
				//lt.children = ll;
			}else{
				//lt.combined_gcs = (lt.combined_gcs === '') ? lt.gc_id : `${lt.combined_gcs}-${lt.gc_id}`;
				gcs.push(lt.gc_id)
			}
		}
		return gcs;
    }
	changeStringToAtob = (str_value) => {
		let str_text = '';
		if(typeof str_value !== 'undefined'){
			str_text = str_value;
			console.log("meetingagenda_text,",this.isBase64(str_value),str_text);
			if(this.isBase64(str_value)){
				str_text = window.decodeURIComponent(atob(str_value));
			}
			console.log("meetingagenda_text,",this.isBase64(str_value),str_text);			
		}
		return str_text;
	}
	isBase64 = (str) => {
		if (str ==='' || str.trim() ===''){ return false; }
		try {
			return btoa(atob(str)) == str;
		} catch (err) {
			return false;
		}
		//return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,3}$/.test(str);
	}
	isBase64_q = (raw_str) => {
		let str = raw_str.trim();
		//assertString(str); // remove this line and make sure you pass in a string
		const len = str.length;
		if (!len || len % 4 !== 0 || notBase64.test(str)) {
			return false;
		}
		const firstPaddingChar = str.indexOf('=');
		return firstPaddingChar === -1 || firstPaddingChar === len - 1 || (firstPaddingChar === len - 2 && str[len - 1] === '=');
	}
}


export default CommitteeUtils;