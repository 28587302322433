import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import '../../App.css';
import BMBreachOfRulesSection from './BMBreachOfRulesSection';
import BMConductRulesBreachSection from './BMConductRulesBreachSection';
import BMApprovalTypeAhead from '../Filter/BMApprovalTypeAhead';
import BMAddFollowupTask from './BMAddFollowupTask';
import CSLTable from '../Common/CSLTable';
import Utils from '../../Common/Utils';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;
    padding-left: 5px;
    display:inline;
`;
const ModalNameLabelDivReference1 = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #212121;
    display:inline;
`;

const FilterBody = styled.div`
	background-color: #ffffff;
	
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;

const OverviewInfoHeader = styled.div`
    padding: 10px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;  
    border-top: 1px solid #d7dddf;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;  
`;

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class BMBreachApprovalModal extends React.Component
{
    state = {
        ready: false,
        breachType: "breach_of_rules",
        isreportable: 1,
        breach_binFiles: [],
        breach_cur_files_json: null,
        evdence_binFiles: [],
        evdence_cur_files_json: null,
        breachDetails: "",
        breachDate: new Date(),
        reportedDate: new Date(),
        regulatorID: "",
        conductRuleUserID: "",

        //new
        incident_title: "",
        incidentType_id: "",
        incident_types: [],
        isinternal_external_incident: 2,//(1 = internal, 0 = External, 2 =  default)
        business_areas: [], // department
        department_id: "",
        third_party_details: "",
        impact_type: "",
        estimated_loss_curreny: "GBP",
        estimated_loss_value: "",
        actual_loss_curreny: "GBP",
        actual_loss_value: "",
        estimated_third_curreny: "GBP",
        estimated_third_value: "",
        actual_third_curreny: "GBP",
        actual_third_value: "",
        fin_impact_cal_method: "",
        com_paid_date: new Date(),
        regulator_types: [],
        rule_reference: "",
        istrading_error: 2,
        fpp_name: "",
        //new

        smcrRoleID: "",
        detailsofinvestigation: "",
        disciplinaryAction: "no",
        disciplinaryAction_binFiles: [],
        disciplinaryAction_cur_files_json: null,
        assign_data: null,

        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        companyID: 0,
        due_date: '1970-01-01',
        is_disabled: false,
        isdesable: false,
        Ref_ID: null,
        curlane: null,
        taskid: null,

        reasonforbreach: "",
        reasonforbreachlist: [],
        breachimpact: "",
        breachimpactlist: [],
        department: "",
        departmentlist: [],
        ownershipresolution: "",
        costOfBreach: 0,
        riskcategorylist: [],
        riskcategorylistnew: [],
        isclear: false,
        selectedRiskCategory: [],

        followupTasksList: [],
        showFollowupModal: false,
        showfollowupcloseButton: true,
        review_list: [],

        showRemedialcloseButton: true,
        showRemedialModal: false,
        remedialTasksList: [],

        showPreventativecloseButton: true,
        showPreventativeModal: false,
        preventativeTasksList: [],

        removal_readonly: false,
        header_message: "",
        module_config: null,
        alert_param: null,
        ischangesFollowup: false,
        isLoadedIcon: false
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index ====>', index);
        let module_config = Store.getStoreData('module_config');
        let custom_terminology_fields = [];
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                module_config.custom_fields.additional_fields.forEach((item) => {
                    let copy_item = JSON.parse(JSON.stringify(item));
                    copy_item.value = item.type === "Calendar" ? new Date() : "";
                    copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                    additional_fields.push(copy_item);
                })
            }
        }
        let reasonforbreachlist = [];
        if('reason_for_Breach' in module_config && module_config.reason_for_Breach.length !== 0){
            module_config.reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    reasonforbreachlist.push(item);
                }
            })
        }
        let breachimpactlist = [];
        if('breach_impact' in module_config && module_config.breach_impact.length !== 0){
            module_config.breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    breachimpactlist.push(item);
                }
            })
        }
        let departmentlist = [];
        if('business_areas' in module_config && module_config.business_areas.length !== 0){
            module_config.business_areas.forEach((item) => {
                if(item.isActive === 1){
                    departmentlist.push(item);
                }
            })
        }
        let riskcategorylist = [];
        let riskcategorylistnew = [];
        if('risk_triggers' in module_config && module_config.risk_triggers.length !== 0)
		module_config.risk_triggers.forEach((item) => {
            if(item.isActive === 1){
                riskcategorylist.push({id: item.riskTriggerID, label: item.riskTriggerName});
                riskcategorylistnew.push(item);
            }
        })

        //let selectedRiskCategory = [{id: 16, label: "Counterparty Risk"}];
        let review_list = Store.getStoreData('reviewee_list');

        let gc_company = Store.getStoreData('gc_company');
        let gc_companies = Store.getStoreData('gc_companies');
        let cur_gc_id = 0;
        let is_gc = false;


        //new
        let incident_types = [];
        if('incident_types' in Store.getStoreData('module_config')){
            incident_types = Store.getStoreData('module_config').incident_types;
        }
        let update_incident_types = []
        for(let k of incident_types){
            if(k.isActive === 1){
                update_incident_types.push(k);
            }
        }
        let business_areas = [];
        if('business_areas' in Store.getStoreData('module_config')){
            business_areas = Store.getStoreData('module_config').business_areas;
        }
        let update_business_areas = [];
        for(let k of business_areas){
            if(k.isActive === 1){
                update_business_areas.push(k);
            }
        }
        let regulator_types = [];
        if('regulator_types' in Store.getStoreData('module_config')){
            regulator_types = Store.getStoreData('module_config').regulator_types;
        }
        let update_regulator_types = [];
        for(let k of regulator_types){
            if(k.isActive === 1){
                update_regulator_types.push(k);
            }
        }



        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);

            let curgc_id = task_json.task_data.group_company_id;
            let sampleNumber = curgc_id;
            let lastDigitcurgc_id = sampleNumber % 100000;
            console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
            let gc_company = Store.getStoreData('gc_company');
            let is_gc = false;
            for(let gc of gc_company) {
                if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                    is_gc = gc.is_gc;
                    console.log("gc.id", gc.id);
                }
            }
            let cur_gc_id = parseInt(lastDigitcurgc_id)
            let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
            let due_date = new Date(form_data.due_date);

            let company_contacts = Store.getStoreData('contacts');
            let gc_users = [];
            let my_company = null;
            for(let gc of gc_company) {
                if(gc.id === cur_gc_id)my_company = gc;
            }
            //console.log('review_list', my_company.users)
            let com_roles = Store.getStoreData('userroles');
            for(let id of Object.keys(my_company.users)) {
                if (com_roles[id.toString()] !== 'no_access' ) {
                    gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
                }
            }
            console.log('gc_users', gc_users)

            let isdesable = false;

            let removal_readonly = false
            if(form_data.cur_lane === "COMP_BREACH_COMPLETE"){
                removal_readonly =  true;
            }
            // if(form_data.cur_lane !== "COMP_BREACH_USERSAVE") {
            //     isdesable = true;
            // }

            let breach_date = new Date(task_json.object_data.headerinfo.breach_date);
            let reported_date = new Date(task_json.object_data.headerinfo.reported_date);

            assign_data.custom_terminology_fields = custom_terminology_fields;
            //assign_data.additional_fields = additional_fields;
            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
            console.log("assign_data",assign_data)

            let breach_cur_files_json = []
            let evdence_cur_files_json = []
            let disciplinaryAction_cur_files_json = []

            if('bin_files' in  task_json.object_data) {
				task_json.object_data.bin_files.forEach((item) => {
                    if("type" in item && item.type === 'breach_resource'){
                        breach_cur_files_json.push(item);
                    }else if("type" in item && item.type === 'evidence_resource'){
						evdence_cur_files_json.push(item);
					}else{
						disciplinaryAction_cur_files_json.push(item);
					}
				});
            }

            let followupTasksList = []
            let remedialTasksList = []
            let preventativeTasksList = []
            let all_childTask = Store.getStoreData('cur_all_childtask_data');
            all_childTask.forEach((item) => {
                if(item.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                    followupTasksList.push(item)
                }
                if(item.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                    remedialTasksList.push(item)
                }
                if(item.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                    preventativeTasksList.push(item)
                }
            });

            let reasonforbreach = ""
            let breachimpact = ""
            let department = ""
            let ownershipresolution = ""
            let costOfBreach = 0
            let selectedRiskCategory = []

            if('reasonforbreach' in task_json.object_data.headerinfo){
                reasonforbreach = task_json.object_data.headerinfo.reasonforbreach
            }
            if('breachimpact' in task_json.object_data.headerinfo){
                breachimpact = task_json.object_data.headerinfo.breachimpact
            }
            if('department' in task_json.object_data.headerinfo){
                department = task_json.object_data.headerinfo.department
            }
            if('ownershipresolution' in task_json.object_data.headerinfo){
                ownershipresolution = task_json.object_data.headerinfo.ownershipresolution
            }
            if('costOfBreach' in task_json.object_data.headerinfo){
                costOfBreach = task_json.object_data.headerinfo.costOfBreach
            }
            if('selectedRiskCategory' in task_json.object_data.headerinfo){
                selectedRiskCategory = task_json.object_data.headerinfo.selectedRiskCategory
            }

            let header_message = "Complete the required fields to create a new Breach Record.";
            let module_config = Store.getStoreData('module_config');

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                breachType: task_json.object_data.headerinfo.breach_type,
                breachDetails: task_json.object_data.headerinfo.breach_deatils,
                breachDate: breach_date,
                reportedDate: reported_date,
                isreportable: parseInt(task_json.object_data.headerinfo.is_reportability),
                regulatorID: parseInt(task_json.object_data.headerinfo.regulator_id),
                breach_cur_files_json: breach_cur_files_json,
                evdence_cur_files_json: evdence_cur_files_json,
                disciplinaryAction_cur_files_json : disciplinaryAction_cur_files_json,
                assign_data: assign_data,
                conductRuleUserID: task_json.object_data.headerinfo.conduct_rule_user_id,
                smcrRoleID: task_json.object_data.headerinfo.smcr_role_id,
                disciplinaryAction: task_json.object_data.headerinfo.disciplinary_action,
                detailsofinvestigation: task_json.object_data.headerinfo.details_of_investigation,
                isdesable: isdesable,
                rowindex: index,
                taskid: form_data.id,
                curlane: form_data.cur_lane,
                reasonforbreachlist: reasonforbreachlist,
                breachimpactlist: breachimpactlist,
                departmentlist: departmentlist,
                riskcategorylist: riskcategorylist,
                riskcategorylistnew: riskcategorylistnew,
                review_list: gc_users,
                followupTasksList: followupTasksList,
                remedialTasksList: remedialTasksList,
                preventativeTasksList: preventativeTasksList,

                removal_readonly: removal_readonly,
                header_message: header_message,
                module_config: module_config,

                reasonforbreach: reasonforbreach,
                breachimpact: breachimpact,
                department: department,
                ownershipresolution: ownershipresolution,
                costOfBreach: costOfBreach,
                selectedRiskCategory: selectedRiskCategory,
                gc_companies: gc_companies, gc_company: Store.getStoreData('gc_company'), ready: true, cur_gc_id: lastDigitcurgc_id, is_gc: is_gc,
            companyID: companyID, due_date: due_date,
                //new
                incident_types: update_incident_types,
                business_areas: update_business_areas,
                regulator_types: update_regulator_types});
        }
    }

    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_BREACH_URL;
            }else{
                this.props.closeTaskModal();
            }
        } else {
            if(this.state.ischangesFollowup){
                this.props.closeModalForApproval();
            }else{
                this.props.closeModalNormal();
            }
            //this.props.closeModalForApproval();
        }
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleChangeReportable = (ischeckValue) =>{
        this.setState({isreportable: ischeckValue, regulatorID: ""})
    }

    onChangeBreachDate = (date) => {
        //this.props.ChangeBreachDate(date)
        this.setState({breachDate: date});
    }
    onChangeReportedDate = (date) => {
        //this.props.ChangeReportedDate(date)
        this.setState({reportedDate: date});
    }

    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_companies');
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = true;
        console.log("gc_company", gc_company);

        for(let gc of gc_company) {
            //gc.id = (gc.id * 100000) + gc_company.id;
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
                console.log("gc.id", gc.id);

            }
        }
        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID});
        //console.log("CURGCID", this.state.cur_gc_id);
    }

    filesLoaded_breach = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "breach_resource"
            }
        }
        this.setState({breach_binFiles: files});
    }

    filesLoaded_evidence = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "evidence_resource"
            }
        }
        this.setState({evdence_binFiles: files});
    }

    filesLoaded_disciplinaryaction = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "disciplinary_resource"
            }

        }
        this.setState({disciplinaryAction_binFiles: files});
    }

    changeClear = () => {
		this.setState({isclear: false})
    }

    riskcategoryValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = [];
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                //filtertypeObject[selected_options[i].id] = selected_options[i].label;
                filtertypeObject.push(selected_options[i])
            }
        }
        console.log("ricipientObject",filtertypeObject)
        this.setState({selectedRiskCategory: filtertypeObject})
    }

    showAddFollowupTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showFollowupModal: true, showfollowupcloseButton: false});
    }

    showAddRemedialTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showRemedialModal: true, showRemedialcloseButton: false});
    }

    showAddPreventativeTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showPreventativeModal: true, showPreventativecloseButton: false});
    }

    closeFollowupModal = () => {
        this.setState({ showFollowupModal: false, showfollowupcloseButton: true });
    }

    closeRemedialModal = () => {
        this.setState({ showRemedialModal: false, showRemedialcloseButton: true });
    }

    closePreventativeModal = () => {
        this.setState({ showPreventativeModal: false, showPreventativecloseButton: true });
    }

    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_FOLLOWUP_ASSIGNMENT", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_BREACH_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_BREACH_FOLLOWUP_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_BREACH_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid };
        console.log("task follow up", postData);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processAddTask);
    }

    remedialTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_REMEDIAL_ASSIGNMENT", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_BREACH_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_BREACH_REMEDIAL_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_BREACH_REMEDIAL_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_REMEDIAL_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid };
        console.log("task follow up", postData);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processAddTaskRemedial);
    }

    preventativeTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_PREVENTATIVE_ASSIGNMENT", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_BREACH_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_BREACH_PREVENTATIVE_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_PREVENTATIVE_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid };
        console.log("task follow up", postData);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processAddTaskPreventative);
    }

    processAddTask = (result) => {
        let alert_param = {title: 'Success', message: 'The follow-up task has been added to this breach.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    processAddTaskRemedial = (result) => {
        let alert_param = {title: 'Success', message: 'A Remedial Action has been successfully added to this incident.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //alert("A Remedial Action has been successfully added to this Breach Register.");
    }

    updateprocessallchildtask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(command_data, this.updateFollowups);
    }

    processAddTaskPreventative = (result) => {
        let alert_param = {title: 'Success', message: 'A Preventative Measures has been successfully added to this incident.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    updateFollowups = (result) => {
        Store.updateStore('cur_all_childtask_data', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        let followup = [];
        let remedial = [];
        let preventative = [];
        result.result.followuptasks.forEach((item) => {
            if(item.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT'){
                followup.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT'){
                remedial.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT'){
                preventative.push(item)
            }
        });
        this.setState({ showFollowupModal: false,showfollowupcloseButton: true,
            showRemedialModal: false, showRemedialcloseButton: true,
            showPreventativeModal: false, showPreventativecloseButton: true,
            followupTasksList: followup, remedialTasksList: remedial, preventativeTasksList: preventative, ischangesFollowup: true, isLoadedIcon: false });
    }

    processMyFollowupTaskValue = () => {
        var taskdata = this.state.followupTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Task', accessor: 'task', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Status', accessor: 'status', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'index' : i,
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    processMyRemedialTaskValue = () => {
        var taskdata = this.state.remedialTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Task', accessor: 'task', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Status', accessor: 'status', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_REMEDIAL_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'index' : i,
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    processMyPreventativeTaskValue = () => {
        var taskdata = this.state.preventativeTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Task', accessor: 'task', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Status', accessor: 'status', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_PREVENTATIVE_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'index' : i,
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    submitApproveBreach_validation = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';

        let child_task = Store.getStoreData('cur_all_childtask_data');
        let child_task_incomplete = 0;
        for(let i=0; i<child_task.length; i++){
            if(child_task[i].cur_lane == "COMP_BREACH_FOLLOWUP_ASSIGNMENT"){
                child_task_incomplete = child_task_incomplete + 1;
            }
            if(child_task[i].cur_lane == "COMP_BREACH_REMEDIAL_ASSIGNMENT"){
                child_task_incomplete = child_task_incomplete + 1;
            }
            if(child_task[i].cur_lane == "COMP_BREACH_PREVENTATIVE_ASSIGNMENT"){
                child_task_incomplete = child_task_incomplete + 1;
            }
        }
        if(child_task_incomplete > 0) {
            problems = true
            message += 'One or more tasks are pending so this record cannot be closed at this time. '
            // alert("One or more tasks are still pending. You can not confirmed this task now.");
            // return;
        }
        if(this.state.reasonforbreach === ""){
            problems = true
            message += 'Please select the Root Cause. '
        }
        if(this.state.breachimpact === ""){
            problems = true
            message += 'Please select the Incident Impact. '
            //alert("Please select the Breach Impact");
        }
        if(this.state.department === ""){
            problems = true
            message += 'Please select the Department. '
            //alert("Please select the Department");
        }
        if(this.state.ownershipresolution === ""){
            problems = true
            message += 'Please select the Ownership of Resolution. '
            //alert("Please select the Ownership of Resolution");
        }
        if(this.state.selectedRiskCategory.length === 0){
            problems = true
            message += 'Please select the Risk Category. '
            //alert("Please select the Risk Category");
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkvalidation, stack: {}}

            this.setState({alert_param: alert_param})
        } else {
            this.checkvalidation(false, {})
        }
    }

    checkvalidation = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(!result){
            //console.log("Sourav")
            if(parseInt(this.state.costOfBreach) < parseInt(Store.getStoreData('module_config').general.companyCurrencyDetails.allowable_value_of_cost)){
                let alert_param = {title: 'Warning', message: 'This value is less than the minimum value of reportable incident. Do you want to proceed?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.verifytask, stack: {}}
                this.setState({alert_param: alert_param})
                //this.setState({costOfBreach:0})
            }else{
                this.submitApproveBreach()
            }
            //this.submitRegisterBreach(stack.submit_form)
        }
    }

    verifytask = (result, stack) => {
        //console.log(result,stack)
        if(!result) {
            this.setState({alert_param: null,costOfBreach:0})
            return
        }
        this.setState({alert_param: null})
        this.submitApproveBreach()
    }

    submitApproveBreach = () => {
            let task_json = JSON.parse(Store.getStoreData('cur_q_modal_data').task_json);
            task_json['task_data'].last_action = 'COMP_BREACH_COMPLETE';
            task_json['task_data'].cur_lane = 'COMP_BREACH_COMPLETE';
            task_json['task_data'].actiondate = Date.now();

            task_json['object_data'].headerinfo.breach_type = this.state.breachType;
            task_json['object_data'].headerinfo.breach_deatils = this.state.breachDetails;
            task_json['object_data'].headerinfo.breach_date = this.state.breachDate;
            task_json['object_data'].headerinfo.is_reportability = this.state.isreportable;
            task_json['object_data'].headerinfo.reported_date = this.state.reportedDate;
            task_json['object_data'].headerinfo.regulator_id = this.state.regulatorID;

            task_json['object_data'].headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID);
            task_json['object_data'].headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
            task_json['object_data'].headerinfo.disciplinary_action = this.state.disciplinaryAction;
            task_json['object_data'].headerinfo.details_of_investigation = this.state.detailsofinvestigation;

            task_json['object_data'].headerinfo.reasonforbreach = this.state.reasonforbreach;
            task_json['object_data'].headerinfo.breachimpact = this.state.breachimpact;
            task_json['object_data'].headerinfo.department = this.state.department;
            task_json['object_data'].headerinfo.ownershipresolution = this.state.ownershipresolution;
            task_json['object_data'].headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;
            task_json['object_data'].headerinfo.costOfBreach = this.state.costOfBreach;
            task_json['object_data'].headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
            task_json['object_data'].headerinfo.additional_fields = this.state.assign_data.additional_fields;

            let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

            let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
            // console.log("bin_files==>",bin_files)

            let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
            // console.log("bin_files==>",disciplinary_action_bin_files)

            var bin_files = [...evibin_files, ...disbin_files, ...brebin_files];

            task_json['action_data'] = {action: "COMP_BREACH_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_COMPLETE", assigned_to: 0 };
            let postData = {command: "update_save_breach_task", task_json: task_json, task_id: this.state.taskid,bin_files: bin_files };
            console.log(postData);
            this.setState({isLoadedIcon: true})
            const api = new APICall();
            api.command(postData, this.afterApproveBreach);
        //}
    }

    afterApproveBreach = (result) => {
        // console.log("Completed:", result);
        //alert('Breach Completed successfully.');
        let alert_param = {title: 'Success', message: 'Incident Completed successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterApproveBreach, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload(true);
    }

    updateafterApproveBreach = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }

    saveandcloseApproveBreach = (event) => {
        event.preventDefault();
        let task_json = JSON.parse(Store.getStoreData('cur_q_modal_data').task_json);
        //console.log("task_json",task_json)
        task_json['task_data'].last_action = 'COMP_BREACH_REGISTER_ACTION';
        task_json['task_data'].cur_lane = 'COMP_BREACH_REGISTER_ACTION';
        task_json['task_data'].actiondate = Date.now();

        task_json['object_data'].headerinfo.breach_type = this.state.breachType;
        task_json['object_data'].headerinfo.breach_deatils = this.state.breachDetails;
        task_json['object_data'].headerinfo.breach_date = this.state.breachDate;
        task_json['object_data'].headerinfo.is_reportability = this.state.isreportable;
        task_json['object_data'].headerinfo.reported_date = this.state.reportedDate;
        task_json['object_data'].headerinfo.regulator_id = this.state.regulatorID;

        task_json['object_data'].headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID);
        task_json['object_data'].headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
        task_json['object_data'].headerinfo.disciplinary_action = this.state.disciplinaryAction;
        task_json['object_data'].headerinfo.details_of_investigation = this.state.detailsofinvestigation;

        task_json['object_data'].headerinfo.reasonforbreach = this.state.reasonforbreach;
        task_json['object_data'].headerinfo.breachimpact = this.state.breachimpact;
        task_json['object_data'].headerinfo.department = this.state.department;
        task_json['object_data'].headerinfo.ownershipresolution = this.state.ownershipresolution;
        task_json['object_data'].headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;
        task_json['object_data'].headerinfo.costOfBreach = this.state.costOfBreach;
        task_json['object_data'].headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
        task_json['object_data'].headerinfo.additional_fields = this.state.assign_data.additional_fields;

        let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

        let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
        // console.log("bin_files==>",bin_files)

        let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
        // console.log("bin_files==>",disciplinary_action_bin_files)

        var bin_files = [...evibin_files, ...disbin_files, ...brebin_files];

        task_json['action_data'] = {action: "COMP_BREACH_REGISTER_ACTION", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_REGISTER_ACTION", assigned_to: 0 };
        let postData = {command: "update_save_breach_task", task_json: task_json, task_id: this.state.taskid, bin_files: bin_files };
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.updateaftersaveandclose);
    }

    updateaftersaveandclose = (result) => {

        let alert_param = {title: 'Success', message: 'The incident has been saved.', ok_text: 'Ok', confirm: false,
        alertHandler: this.aftersaveandclose, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }


    aftersaveandclose = (result, stack) => {
        // console.log("Completed:", result);
        //alert('Breach Register saved successfully.');
        this.setState({alert_param: null})
        let reasonforbreachlist = JSON.parse(JSON.stringify(this.state.reasonforbreachlist));
        let changemodule = 0
        reasonforbreachlist.forEach((item) => {
            if (parseInt(item.reasonforBreachID) === parseInt(this.state.reasonforbreach) && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", reasonforbreachlist);

        let breachimpactlist = JSON.parse(JSON.stringify(this.state.breachimpactlist));
        breachimpactlist.forEach((item) => {
            if (parseInt(item.breachImpactID) === parseInt(this.state.breachimpact) && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", breachimpactlist);

        let departmentlist = JSON.parse(JSON.stringify(this.state.departmentlist));
        departmentlist.forEach((item) => {
            if (parseInt(item.businessAreaID) === parseInt(this.state.department) && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        departmentlist.forEach((item) => {
            if (parseInt(item.businessAreaID) === parseInt(this.state.ownershipresolution) && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", departmentlist);

        let selectedRiskID = {}
        this.state.selectedRiskCategory.forEach((item) => {
            selectedRiskID[item.id.toString()] = item.label
        });
        let riskcategorylist = JSON.parse(JSON.stringify(this.state.riskcategorylistnew));
        riskcategorylist.forEach((item) => {
            if (item.riskTriggerID in selectedRiskID && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", riskcategorylist);

        if(changemodule === 1){
            let {module_config} = this.state;
            module_config.reason_for_Breach = reasonforbreachlist;
            module_config.breach_impact = breachimpactlist;
            module_config.business_areas = departmentlist;
            module_config.risk_triggers = riskcategorylist;
            const postData = {command: "save_module_config", module_config: module_config};
            const api = new APICall();
            api.command(postData, this.afterUpdatemoduleconfig);
        }else{
            window.location.reload();
        }
        //window.location.reload(true);
    }
    afterUpdatemoduleconfig = (result) => {
        console.log(result);
        window.location.reload();
   }

    updateCompletedTask = (event) => {
        event.preventDefault();
        let header_message = "You are amending and updating a Breach that has already been submitted.";
        this.setState({removal_readonly: false, header_message: header_message})
    }

    modifyAssignData = (obj) => {
        this.setState({assign_data: obj});
    }

    handleChangeExIntIncd = (ischeckValue) =>{
        this.setState({isinternal_external_incident: ischeckValue, department_id: "",third_party_details: ""})
    }
    handlenaSectionIncludedDis = (ischeckValue) =>{
        this.setState({disciplinaryAction: ischeckValue})
    }
    handleChangeTradingError = (ischeckValue) =>{
        this.setState({istrading_error: ischeckValue})
    }
    ChangePaidDate = (date) => {
        this.setState({com_paid_date: date});
    }

    render()
    {
        let currencyValue = "GBP"
        let moduleconfig = Store.getStoreData('module_config');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        return (
            <div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Record New Breach</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{this.state.header_message}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div>
                            <ModalNameLabelDivReference>BREACH ID - </ModalNameLabelDivReference>
                            <ModalNameLabelDivReferenceID>{this.state.Ref_ID}</ModalNameLabelDivReferenceID>
                        </div>

                        {
                            (() => {
                                if(Store.getStoreData('gc_companies').length === 0){
                                return(
                                    <div>
                                        <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                        {
                                            (() => {
                                                if(this.state.gc_company.length !== 0){
                                                    return(<MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.gc_company[0].company_name}</MRModalLabel>)
                                                }
                                            })()
                                        }
                                    </div>
                                )
                                }else{
                                    return(
                                        <div>
                                            <MRModalLabel>Select Company </MRModalLabel>
                                            <MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc} disabled={true}>
                                            <option key={0} value={0}>Select</option>
                                                {
                                                        this.state.gc_company.map((gc) => {
                                                        return(
                                                        <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                        );
                                                    })
                                                }
                                            </MRModalSelect>
                                        </div>
                                    )
                                }
                            })()
                        }

                        <MRModalLabel>Breach Type</MRModalLabel>
                        <MRModalSelect name="breachType" onChange={this.handleChange} value={this.state.breachType} disabled={this.state.removal_readonly}>
                            <option value="breach_of_rules">Incident</option>
                            <option value="conduct_rules_breach">Conduct Rules Breach</option>
                        </MRModalSelect>
                        {
                            (() => {
                                if(this.state.breachType === "breach_of_rules"){
                                    return(<BMBreachOfRulesSection
                                        isreportable={this.state.isreportable}
                                        handleChangeReportable={this.handleChangeReportable}
                                        breach_cur_files_json={this.state.breach_cur_files_json}
                                        filesUploadbreach={this.filesLoaded_breach}
                                        cur_files_json={this.state.evdence_cur_files_json}
                                        filesUpload={this.filesLoaded_evidence}
                                        handleChangeAll={this.handleChange}
                                        breachDetails={this.state.breachDetails}
                                        breachDate={this.state.breachDate}
                                        reportedDate={this.state.reportedDate}
                                        regulatorID={this.state.regulatorID}
                                        ChangeBreachDate={this.onChangeBreachDate}
                                        ChangeReportedDate={this.onChangeReportedDate}
                                        disabledCheck={this.state.removal_readonly}
                                        assign_data={this.state.assign_data}
                                        smcrRoleID={this.state.smcrRoleID}
                                        modifyAssignData={this.modifyAssignData}
                                        incident_types={this.state.incident_types}
                                        business_areas={this.state.business_areas}
                                        regulator_types={this.state.regulator_types}
                                        ///
                                        conductRuleUserID={this.state.conductRuleUserID}
                                        isinternal_external_incident={this.state.isinternal_external_incident}
                                        handleChangeExIntIncd={this.handleChangeExIntIncd}
                                        cur_gc_id={this.state.cur_gc_id}
                                        //new
                                        incidentType_id={this.state.incidentType_id}
                                        department_id={this.state.department_id}
                                        third_party_details={this.state.third_party_details}
                                        impact_type={this.state.impact_type}

                                        estimated_loss_curreny={this.state.estimated_loss_curreny}
                                        estimated_loss_value={this.state.estimated_loss_value}
                                        actual_loss_curreny={this.state.actual_loss_curreny}
                                        actual_loss_value={this.state.actual_loss_value}
                                        estimated_third_curreny={this.state.estimated_third_curreny}
                                        estimated_third_value={this.state.estimated_third_value}
                                        actual_third_curreny={this.state.actual_third_curreny}
                                        actual_third_value={this.state.actual_third_value}
                                        fin_impact_cal_method={this.state.fin_impact_cal_method}
                                        com_paid_date={this.state.com_paid_date}
                                        ChangePaidDate={this.ChangePaidDate}
                                        rule_reference={this.state.rule_reference}
                                        istrading_error={this.state.istrading_error}
                                        handleChangeTradingError={this.handleChangeTradingError}
                                        fpp_name={this.state.fpp_name}
                                        disciplinaryAction={this.state.disciplinaryAction}
                                        handlenaSectionIncluded={this.handlenaSectionIncludedDis}
                                        detailsofinvestigation={this.state.detailsofinvestigation}
                                        cur_files_jsonNew={this.state.disciplinaryAction_cur_files_json}
                                        filesUpload1={this.filesLoaded_disciplinaryaction}
                                        />)
                                }else{
                                    return(<BMConductRulesBreachSection
                                        conductRuleUserID={this.state.conductRuleUserID}
                                        handleChangeAll={this.handleChange}
                                        breachDate={this.state.breachDate}
                                        ChangeBreachDate={this.onChangeBreachDate}
                                        isreportable={this.state.isreportable}
                                        handleChangeReportable={this.handleChangeReportable}
                                        reportedDate={this.state.reportedDate}
                                        ChangeReportedDate={this.onChangeReportedDate}
                                        regulatorID={this.state.regulatorID}
                                        filesUpload={this.filesLoaded_evidence}
                                        cur_files_json={this.state.evdence_cur_files_json}
                                        breachDetails={this.state.breachDetails}

                                        smcrRoleID={this.state.smcrRoleID}
                                        detailsofinvestigation={this.state.detailsofinvestigation}
                                        disciplinaryAction={this.state.disciplinaryAction}
                                        filesUpload1={this.filesLoaded_disciplinaryaction}
                                        cur_files_jsonNew={this.state.disciplinaryAction_cur_files_json}
                                        assign_data={this.state.assign_data}
                                        modifyAssignData={this.modifyAssignData}
                                        disabledCheck={this.state.removal_readonly}
                                        cur_gc_id = {this.state.cur_gc_id}

                                        business_areas={this.state.business_areas}
                                        regulator_types={this.state.regulator_types}

                                        ////
                                        isinternal_external_incident={this.state.isinternal_external_incident}
                                        //new

                                        department_id={this.state.department_id}
                                        third_party_details={this.state.third_party_details}
                                        impact_type={this.state.impact_type}
                                        estimated_loss_curreny={this.state.estimated_loss_curreny}
                                        estimated_loss_value={this.state.estimated_loss_value}
                                        actual_loss_curreny={this.state.actual_loss_curreny}
                                        actual_loss_value={this.state.actual_loss_value}
                                        estimated_third_curreny={this.state.estimated_third_curreny}
                                        estimated_third_value={this.state.estimated_third_value}
                                        actual_third_curreny={this.state.actual_third_curreny}
                                        actual_third_value={this.state.actual_third_value}
                                        fin_impact_cal_method={this.state.fin_impact_cal_method}
                                        com_paid_date={this.state.com_paid_date}
                                        ChangePaidDate={this.ChangePaidDate}
                                        rule_reference={this.state.rule_reference}
                                        istrading_error={this.state.istrading_error}
                                        handleChangeTradingError={this.handleChangeTradingError}
                                        fpp_name={this.state.fpp_name}
                                        handlenaSectionIncluded={this.handlenaSectionIncludedDis}
                                        handleChangeExIntIncd={this.handleChangeExIntIncd}
                                        breach_cur_files_json={this.state.breach_cur_files_json}
                                        filesUploadbreach={this.filesLoaded_breach}
                                    />)
                                }
                            })()
                        }
                        <SpacerXL />
                        <UserHr />
                        <MRModalLabel>Reason for Breach</MRModalLabel>
                        <MRModalSelect name="reasonforbreach" onChange={this.handleChange} value={this.state.reasonforbreach} disabled={this.state.removal_readonly}>
                            <option value="">Select</option>
                            {
                                this.state.reasonforbreachlist.map((rc,index) => {
                                    return(
                                    <option key={index} value={rc.reasonforBreachID}>{rc.reasonforBreachName}</option>
                                    );
                                })
                            }
                        </MRModalSelect>
                        <MRModalLabel>Breach Impact</MRModalLabel>
                        <MRModalSelect name="breachimpact" onChange={this.handleChange} value={this.state.breachimpact} disabled={this.state.removal_readonly}>
                            <option value="">Select</option>
                            {
                                this.state.breachimpactlist.map((rc,index) => {
                                    return(
                                    <option key={index} value={rc.breachImpactID}>{rc.breachImpactName}</option>
                                    );
                                })
                            }
                        </MRModalSelect>
                        <MRModalLabel>Department</MRModalLabel>
                        <MRModalSelect name="department" onChange={this.handleChange} value={this.state.department} disabled={this.state.removal_readonly}>
                            <option value="">Select</option>
                            {
                                this.state.departmentlist.map((rc,index) => {
                                    return(
                                    <option key={index} value={rc.businessAreaID}>{rc.businessAreaName}</option>
                                    );
                                })
                            }
                        </MRModalSelect>
                        <MRModalLabel>Ownership of Resolution</MRModalLabel>
                        <MRModalSelect name="ownershipresolution" onChange={this.handleChange} value={this.state.ownershipresolution} disabled={this.state.removal_readonly}>
                            <option value="">Select</option>
                            {
                                this.state.departmentlist.map((rc,index) => {
                                    return(
                                    <option key={index} value={rc.businessAreaID}>{rc.businessAreaName}</option>
                                    );
                                })
                            }
                        </MRModalSelect>

                        <MRModalLabel>Risk Category</MRModalLabel>
                        <FilterBody>
                        {
                            (() => {
                                if(this.state.removal_readonly){
                                    return <BMApprovalTypeAhead data={this.state.riskcategorylist} selectedRiskCategory={this.state.selectedRiskCategory} ricipientValue={this.riskcategoryValue} isReadOnly={true} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                }else{
                                    if (this.state.riskcategorylist !== null) {
                                        return <BMApprovalTypeAhead data={this.state.riskcategorylist} selectedRiskCategory={this.state.selectedRiskCategory} ricipientValue={this.riskcategoryValue} isReadOnly={false} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                    }
                                }
                            })()
                        }
                        </FilterBody>
                        <MRModalLabel>Cost of Breach</MRModalLabel>
                        {
                            (() => {
                                if('general' in moduleconfig && 'companyCurrencyDetails' in moduleconfig.general){
                                    currencyValue = moduleconfig.general.companyCurrencyDetails.currency
                                }
                            })()
                        }
                        <MRModalLabel style={{display: "inline-block",marginTop: "20px",marginRight: "20px"}}>{currencyValue}</MRModalLabel>
                        <MRModalInput style={{height: "22px", width: "15%"}} type="text" value={this.state.costOfBreach} name="costOfBreach" onChange={this.handleChange} readOnly={this.state.removal_readonly}/>
                        <SpacerXL />
                        <UserHr />
                        {/* Followup Section */}
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Follow-up Tasks</div>
                            {
                                (() => {
                                    if(!this.state.removal_readonly){
                                        if(this.state.showfollowupcloseButton){
                                            return(<div style={{float: "right", color: "#323232", fontSize: "20px",cursor: "pointer"}} onClick={this.showAddFollowupTaskModal(-1)}><FaPlusCircle /></div>)
                                        }
                                    }
                                })()
                            }
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        {
                            (() => {
                                if(this.state.showFollowupModal){
                                    return(<BMAddFollowupTask followuptaskvalue={this.state.followupTasksList} headerText={'follow-up task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch}/>)
                                }
                            })()
                        }
                        <OverviewInfoBody>
                        {
                            (() => {
                                if(this.state.followupTasksList.length !== 0){
                                    return(<CSLTable add={false} processData={this.processMyFollowupTaskValue} headerText={''} tableRows="3"/>)
                                }else{
                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no follow-up tasks assigned.</div>)
                                }
                            })()
                        }
                        </OverviewInfoBody>
                        {/* Remedial Actions */}

                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Remedial Actions</div>
                            {
                                (() => {
                                    if(!this.state.removal_readonly){
                                        if(this.state.showRemedialcloseButton){
                                            return(<div style={{float: "right", color: "#323232", fontSize: "20px",cursor: "pointer"}} onClick={this.showAddRemedialTaskModal(-1)}><FaPlusCircle /></div>)
                                        }
                                    }
                                })()
                            }
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        {
                            (() => {
                                if(this.state.showRemedialModal){
                                    return(<BMAddFollowupTask followuptaskvalue={this.state.remedialTasksList} headerText={'remedial action task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeRemedialModal} refreshTaskValue={this.remedialTaskValueFetch}/>)
                                }
                            })()
                        }
                        <OverviewInfoBody>
                        {
                            (() => {
                                if(this.state.remedialTasksList.length !== 0){
                                    return(<CSLTable add={false} processData={this.processMyRemedialTaskValue} headerText={''} tableRows="3"/>)
                                }else{
                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no remedial actions tasks assigned.</div>)
                                }
                            })()
                        }
                        </OverviewInfoBody>

                        {/* Preventative Measures */}

                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Preventative Measures</div>
                            {
                                (() => {
                                    if(!this.state.removal_readonly){
                                        if(this.state.showPreventativecloseButton){
                                            return(<div style={{float: "right", color: "#323232", fontSize: "20px",cursor: "pointer"}} onClick={this.showAddPreventativeTaskModal(-1)}><FaPlusCircle /></div>)
                                        }
                                    }
                                })()
                            }
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        {
                            (() => {
                                if(this.state.showPreventativeModal){
                                    return(<BMAddFollowupTask followuptaskvalue={this.state.preventativeTasksList} headerText={'preventative measures task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closePreventativeModal} refreshTaskValue={this.preventativeTaskValueFetch}/>)
                                }
                            })()
                        }
                        <OverviewInfoBody>
                        {
                            (() => {
                                if(this.state.preventativeTasksList.length !== 0){
                                    return(<CSLTable add={false} processData={this.processMyPreventativeTaskValue} headerText={''} tableRows="3"/>)
                                }else{
                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no preventative measures tasks assigned.</div>)
                                }
                            })()
                        }
                        </OverviewInfoBody>

                    </div>
                </MRModalBody>
                <MRModalFooter>
                {
                    (() => {
                        if(this.state.removal_readonly){
                            return(<div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn onClick={this.updateCompletedTask}>Update</MRModalSaveCloseBtn>
                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                            </div>);
                        }else{
                            if(this.state.rowindex >= 0) {

                                return(<div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn onClick={this.submitApproveBreach_validation} disabled={this.state.is_disabled}>Submit</MRModalSaveCloseBtn>
                                <MRModalSaveCloseBtn onClick={this.saveandcloseApproveBreach} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>
                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>);
                            }
                        }
                    })()
                }
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default BMBreachApprovalModal;
