import Store from '../../Common/Store';
import Utils from '../../Common/Utils';

class RegisterViewCount {
  // constructor() {
  // }  

  managerViewCount = (Value) => {
    var result_data = [];    
    let feeds = Value.allFeeds;
    let tasks = Value.allTasks;
    //console.log("tasks===>",tasks)
    let userslist = Store.getStoreData('contacts');
    if(feeds.length !== 0){        
        for(let i=0;i<feeds.length;i++){
            let resultTask = {checkRU: 0, shareInfo:0,shareAcknow:0,shareFollowup:0,shareinfoPepole: 0, shareacknowPepole: 0, shareinfoPepoleName: '', shareacknowPepoleName: '',externalLink: ''} 
            let shareinfoPepolebyid = {};
            let shareacknowPepolebyid = {};
            if(tasks.length !== 0){                
                for(let j=0;j<tasks.length;j++){
                    if(tasks[j].ref_id === feeds[i].feed_id){
                        resultTask.checkRU = 1 
                        //console.log("CompanyID==>", tasks[j].company_id);
                        //console.log("contact_id==>", tasks[j].contact_id)                
                        const task_json = JSON.parse(tasks[j].task_json);
                        if("shareUserInfo" in task_json.object_data){
                            let infolength = Object.keys(task_json.object_data.shareUserInfo).length;
                            resultTask.shareInfo = infolength;
                            for(let id of Object.keys(task_json.object_data.shareUserInfo)){
                                shareinfoPepolebyid[id]=1;
                            } 
                        }
                        if("shareUserAcknowledge" in task_json.object_data){
                            let acknowLength = Object.keys(task_json.object_data.shareUserAcknowledge).length;
                            resultTask.shareAcknow = acknowLength;
                            for(let id of Object.keys(task_json.object_data.shareUserAcknowledge)){
                                shareacknowPepolebyid[id]=1;
                            }
                        }                
                        if(tasks[j].child_task_ids !== null){
                            //console.log("task_arr",JSON.parse(tasks[i].child_task_ids).length)
                            let followuptasksCount = JSON.parse(tasks[j].child_task_ids).length; 
                            resultTask.shareFollowup = followuptasksCount;                   
                        }
    
                        if('externalLinks' in task_json.object_data && task_json.object_data.externalLinks.length !== 0){
                            resultTask.externalLink = task_json.object_data.externalLinks[0].url;
                        }
                    }
                }

                let shareinfoPepoleName = '';
                let shareacknowPepoleName = '';
                if(Object.keys(shareinfoPepolebyid).length !== 0 || Object.keys(shareacknowPepolebyid).length !== 0){
                    for(let k=0; k<userslist.length;k++)
                    {
                        if(userslist[k].ID in shareinfoPepolebyid){
                            shareinfoPepoleName += userslist[k].ContactName
                            if(k < userslist.length - 1) {
                                shareinfoPepoleName += ','
                            }
                        }
                        if(userslist[k].ID in shareacknowPepolebyid){
                            shareacknowPepoleName += userslist[k].ContactName
                            if(k < userslist.length - 1) {
                                shareacknowPepoleName += ','
                            }
                        }                
                    }            
                }

                resultTask.shareinfoPepoleName = shareinfoPepoleName;
                resultTask.shareacknowPepoleName = shareacknowPepoleName;
                resultTask.shareinfoPepole = Object.keys(shareinfoPepolebyid).length
                resultTask.shareacknowPepole = Object.keys(shareacknowPepolebyid).length   
            }
            let json_feed = JSON.parse(feeds[i].feed_json);
            let update_json_feed = JSON.parse(feeds[i].feed_update_json);
            let documentTypes = update_json_feed.documentTypes;
            let dtype = ""; 
            if (documentTypes !== null) {
                for (let doc of documentTypes){
                    dtype = dtype + doc +",";
                    dtype = dtype.substring(0, dtype.length - 1)
                }
            }
            if(resultTask.checkRU !== 0){
                let ruObject = {}
                ruObject.refid = feeds[i].feed_id;
                ruObject.ruName = json_feed.documentName;
                ruObject.regulatorName = update_json_feed.ragulatorName
                ruObject.documentType = dtype;
                ruObject.shareInfo = resultTask.shareInfo;
                ruObject.shareinfoPepole = resultTask.shareinfoPepole;
                ruObject.shareinfoPepoleName = resultTask.shareinfoPepoleName;
                ruObject.shareAcknow = resultTask.shareAcknow;
                ruObject.shareacknowPepole = resultTask.shareacknowPepole;
                ruObject.shareacknowPepoleName = resultTask.shareacknowPepoleName;
                ruObject.shareFollowup = resultTask.shareFollowup;
                ruObject.externalLink = resultTask.externalLink;
                result_data.push(ruObject);                    
            }
        }
    }

    if(Store.getStoreData('rutaskdata') === null) {
        Store.updateStore('rutaskdata', result_data);
    }
    return true;
 } 

usersViewCount = (Value) => {
    let result = {userViewlist:[]}
    console.log("CountVaalue===>", Value)
    let companyid = Store.getStoreData('companyid');
    let users = Value.allUsers;
    let feeds = Value.allFeeds;
    let tasks = Value.allTasks;
    let followupTaskNew = Value.allFollowupTask;
    let reminderTask = Value.allReminderTask;
    for(let i=0;i<users.length;i++){            
        let usertask = this.userTaskInfo(companyid,users[i].ID,feeds,tasks,followupTaskNew,reminderTask)            
        let userView = {};
        userView.id = users[i].ID;
        userView.userName = users[i].ContactName;
        userView.totalRU = usertask.totalRU;
        userView.actiontotalRU = usertask.actiontotalRU;
        userView.openedRU = usertask.openedRU;
        userView.actionedRU = usertask.actionedRU;
        userView.totalDismissedRU = usertask.totalDismissedRU;
        userView.dismissedRU = usertask.dismissedRU;
        userView.outstandingRU = usertask.outstandingRU;
        userView.remindersRU = usertask.remindersRU;
        userView.avgNoofDays = usertask.avgNoofDays;
        result.userViewlist.push(userView);            
    }
    return result;
}

userTaskInfo(companyid,userID,feeds,tasks,followupTaskNew,reminderTask){
    let resultTask = {totalRU: 0, actiontotalRU:0, openedRU: 0, actionedRU: 0, totalDismissedRU: 0, dismissedRU: 0, outstandingRU: 0,remindersRU: 0, avgNoofDays: 0};
    let noOfDays = 0;

    let all_roles = Store.getStoreData('userroles');
    let role = all_roles[userID];    
    let checkcurtaskid = {}
    if(role !== "" && feeds.length !== 0){
        for(let i=0;i<feeds.length;i++){
            if(role === "manager" || role === "rcm_gatekeeper"){
                let totaltaskru = this.totalTaskRU(feeds[i].feed_id,userID,tasks)                
                resultTask.totalRU = resultTask.totalRU + totaltaskru.allRU;                             
                resultTask.openedRU = resultTask.openedRU + totaltaskru.opened;
                resultTask.outstandingRU = resultTask.outstandingRU + totaltaskru.outstandingRU;
                if(totaltaskru.currentTaskid !== 0){
                    checkcurtaskid[totaltaskru.currentTaskid] = totaltaskru.currentTaskid
                }
                
            }
            if(role === "team"){
                let totaltaskru = this.totalTeamTaskRU(feeds[i].feed_id,userID,tasks)
                resultTask.totalRU = resultTask.totalRU + totaltaskru.allRU;                
                resultTask.openedRU = resultTask.openedRU + totaltaskru.opened;
                resultTask.outstandingRU = resultTask.outstandingRU + totaltaskru.outstandingRU;
                if(totaltaskru.currentTaskid !== 0){
                    checkcurtaskid[totaltaskru.currentTaskid] = totaltaskru.currentTaskid
                }
            }
        }
    }   
    
    if (tasks.length !== 0) {        
        for(let i=0;i<tasks.length;i++){
            const task_json = JSON.parse(tasks[i].task_json);                
            if(tasks[i].ref_id in checkcurtaskid){                     
                followupTaskNew.forEach((item) => {
                    if(item.parent_task_id === tasks[i].id && item.cur_assigned_to === userID){
                        resultTask.actionedRU = resultTask.actionedRU + 1;
                        resultTask.actiontotalRU = resultTask.actiontotalRU + 1; 
                    }
                })
            }else{
                let checkcurtaskidother = {}
                if ("shareUserAll" in task_json.object_data && task_json.object_data.shareUserAll[userID] === 1) {                    
                    resultTask.totalRU = resultTask.totalRU + 1;                    
                }
                if("shareUserInfo" in task_json.object_data && userID in task_json.object_data.shareUserInfo){                    
                    if (task_json.object_data.shareUserInfo[userID].cur_view === "Opened" || task_json.object_data.shareUserInfo[userID].cur_view === "Marked for action") {
                        resultTask.openedRU = resultTask.openedRU + 1;
                    }
                    if (task_json.object_data.shareUserInfo[userID].cur_view === "Opened" || task_json.object_data.shareUserInfo[userID].cur_view === "Marked for action") { 
                        let RUSendDate = new Date(tasks[i].due_date)
                        var dtmonth = RUSendDate.getMonth()+1;
                        var xdate_str = RUSendDate.getFullYear().toString()+"-"+dtmonth.toString()+"-"+RUSendDate.getDate().toString();
                        
                        var due_date = new Date(xdate_str).getTime();
                        let RUopenDate = new Date(task_json.object_data.shareUserInfo[userID].OpenedDate)
                        var ndtmonth = RUopenDate.getMonth()+1;
                        var nxdate_str = RUopenDate.getFullYear().toString()+"-"+ndtmonth.toString()+"-"+RUopenDate.getDate().toString();                            
                        var open_date = new Date(nxdate_str).getTime();
                        
                        var delta = Math.abs(open_date - due_date) / 1000;                            
                        var days = Math.floor(delta / 86400);
                        
                        noOfDays = noOfDays + days;                    
                    }
                    
                    if (task_json.object_data.shareUserInfo[userID].cur_view === "Marked for action") {
                        resultTask.actionedRU = resultTask.actionedRU + 1;
                        resultTask.actiontotalRU = resultTask.actiontotalRU + 1;
                        checkcurtaskidother[tasks[i].id] = tasks[i].id;
                    }
                    if (task_json.object_data.shareUserInfo[userID].cur_view === "Opened" || task_json.object_data.shareUserInfo[userID].cur_view === "Unopened" || task_json.object_data.shareUserInfo[userID].cur_view === "Marked for action") {
                        resultTask.outstandingRU = resultTask.outstandingRU + 1;
                    }
                }
                if("shareUserAcknowledge" in task_json.object_data && userID in task_json.object_data.shareUserAcknowledge){
                    if (task_json.object_data.shareUserAcknowledge[userID].cur_view === "Opened" || task_json.object_data.shareUserAcknowledge[userID].cur_view === "Marked for action") {
                        resultTask.openedRU = resultTask.openedRU + 1;
                    }
                    if (task_json.object_data.shareUserAcknowledge[userID].cur_view === "Opened" || task_json.object_data.shareUserAcknowledge[userID].cur_view === "Marked for action") {
                        
                        let RUSendDate = new Date(tasks[i].due_date)
                        var dtmonth = RUSendDate.getMonth()+1;
                        var xdate_str = RUSendDate.getFullYear().toString()+"-"+dtmonth.toString()+"-"+RUSendDate.getDate().toString();
                        
                        var due_date = new Date(xdate_str).getTime();
                        let RUopenDate = new Date(task_json.object_data.shareUserAcknowledge[userID].OpenedDate)
                        var ndtmonth = RUopenDate.getMonth()+1;
                        var nxdate_str = RUopenDate.getFullYear().toString()+"-"+ndtmonth.toString()+"-"+RUopenDate.getDate().toString();                            
                        var open_date = new Date(nxdate_str).getTime();
                        
                        var delta = Math.abs(open_date - due_date) / 1000;                            
                        var days = Math.floor(delta / 86400);
                        
                        noOfDays = noOfDays + days;
                    } 
                    
                    if (task_json.object_data.shareUserAcknowledge[userID].cur_view === "Opened" || task_json.object_data.shareUserAcknowledge[userID].cur_view === "Unopened" || task_json.object_data.shareUserAcknowledge[userID].cur_view === "Marked for action") {
                        resultTask.outstandingRU = resultTask.outstandingRU + 1;
                        resultTask.actionedRU = resultTask.actionedRU + 1;
                        resultTask.actiontotalRU = resultTask.actiontotalRU + 1;
                        checkcurtaskidother[tasks[i].id] = tasks[i].id;
                    }
                }                
                if(!tasks[i].id in checkcurtaskidother){ 
                    followupTaskNew.forEach((item) => {
                        if(item.parent_task_id === tasks[i].id && item.cur_assigned_to === userID){
                            resultTask.actionedRU = resultTask.actionedRU + 1;
                            resultTask.actiontotalRU = resultTask.actiontotalRU + 1;   
                        }
                    })
                } 
            }                
            if('action' in task_json.action_data[task_json.action_data.length - 1] && task_json.action_data[task_json.action_data.length - 1].action === "COMP_RCM_DISMISS"){
                if('performed_by' in task_json.action_data[task_json.action_data.length - 1] && task_json.action_data[task_json.action_data.length - 1].performed_by === userID){
                    resultTask.dismissedRU = resultTask.dismissedRU + 1;
                }
            }
            let reminderUser = this.checkReminderEmail(tasks[i].id,userID,reminderTask)            
            resultTask.remindersRU = resultTask.remindersRU + reminderUser.reminderCount;
        }   
    }    
    if(noOfDays !== 0){
        resultTask.avgNoofDays = resultTask.totalRU / noOfDays; 
    } 
    return resultTask;
}

totalTaskRU(feedsID,userID,tasks){
    let resultTask = {allRU: 0, opened: 0, outstandingRU: 0,currentTaskid: 0}   
    let userObject = {}
    userObject[0] = 1
    userObject[userID] = 1 
    tasks.forEach((item) => {
        if(item.ref_id === feedsID && item.contact_id in userObject){
            resultTask.allRU = 1            
            resultTask.opened = 1
            resultTask.outstandingRU = 1
            resultTask.currentTaskid=item.ref_id;                
        }
    })      
    return resultTask
}

totalTeamTaskRU(feedsID,userID,tasks){
    let resultTask = {allRU: 0, opened: 0, outstandingRU: 0,currentTaskid: 0}      
    tasks.forEach((item) => {
        if(item.ref_id === feedsID && item.contact_id === userID){
            resultTask.allRU = 1            
            resultTask.opened = 1  
            resultTask.outstandingRU = 1          
            resultTask.currentTaskid=item.ref_id;                
        }
    })     
    return resultTask
}

checkReminderEmail(taskID,userID,reminderTask){
    let res = {reminderCount: 0}    
    reminderTask.forEach((item) => {
        if(item.contact_id === userID && item.task_id === taskID){
            let email_json = JSON.parse(item.email_json);                 
            if('flag' in email_json && email_json.flag === 1){
                res.reminderCount = 1;                    
            }            
        }
    })        
    return res;
}

viewOnlyGWTeam = (Value,checkid) => {
    var result_data = [];    
    let feeds = Value.allFeeds;
    let tasks = Value.allTasks;
    let legecyRUs = Value.all_legecy_ru;
    let tags_result = Value.used_tags;
    //console.log("tasks===>",tasks)
    let regu_pub_date = "";
    let userslist = Store.getStoreData('contacts');
    if(feeds.length !== 0){        
        for(let i=0;i<feeds.length;i++){
            let resultTask = {checkRU: 0, shareInfo:0,shareAcknow:0,shareFollowup:0,shareinfoPepole: 0, shareacknowPepole: 0, shareinfoPepoleName: '', shareacknowPepoleName: '',externalLink: ''} 
            let shareinfoPepolebyid = {};
            let shareacknowPepolebyid = {};
            if(tasks.length !== 0){                
                for(let j=0;j<tasks.length;j++){
                    if(tasks[j].ref_id === feeds[i].feed_id){
                        resultTask.checkRU = 1                                         
                        const task_json = JSON.parse(tasks[j].task_json);
                        // if("shareUserInfo" in task_json.object_data){
                        //     let infolength = Object.keys(task_json.object_data.shareUserInfo).length;
                        //     resultTask.shareInfo = infolength;
                        //     for(let id of Object.keys(task_json.object_data.shareUserInfo)){
                        //         shareinfoPepolebyid[id]=1;
                        //     } 
                        // }
                        // if("shareUserAcknowledge" in task_json.object_data){
                        //     let acknowLength = Object.keys(task_json.object_data.shareUserAcknowledge).length;
                        //     resultTask.shareAcknow = acknowLength;
                        //     for(let id of Object.keys(task_json.object_data.shareUserAcknowledge)){
                        //         shareacknowPepolebyid[id]=1;
                        //     }
                        // }                
                        // if(tasks[j].child_task_ids !== null){
                        //     //console.log("task_arr",JSON.parse(tasks[i].child_task_ids).length)
                        //     let followuptasksCount = JSON.parse(tasks[j].child_task_ids).length; 
                        //     resultTask.shareFollowup = followuptasksCount;                   
                        // }
    
                        // if('externalLinks' in task_json.object_data && task_json.object_data.externalLinks.length !== 0){
                        //     resultTask.externalLink = task_json.object_data.externalLinks[0].url;
                        // }
                    }
                }

                // let shareinfoPepoleName = '';
                // let shareacknowPepoleName = '';
                // if(Object.keys(shareinfoPepolebyid).length !== 0 || Object.keys(shareacknowPepolebyid).length !== 0){
                //     for(let k=0; k<userslist.length;k++)
                //     {
                //         if(userslist[k].ID in shareinfoPepolebyid){
                //             shareinfoPepoleName += userslist[k].ContactName
                //             if(k < userslist.length - 1) {
                //                 shareinfoPepoleName += ','
                //             }
                //         }
                //         if(userslist[k].ID in shareacknowPepolebyid){
                //             shareacknowPepoleName += userslist[k].ContactName
                //             if(k < userslist.length - 1) {
                //                 shareacknowPepoleName += ','
                //             }
                //         }                
                //     }            
                // }

                // resultTask.shareinfoPepoleName = shareinfoPepoleName;
                // resultTask.shareacknowPepoleName = shareacknowPepoleName;
                // resultTask.shareinfoPepole = Object.keys(shareinfoPepolebyid).length
                // resultTask.shareacknowPepole = Object.keys(shareacknowPepolebyid).length   
            }
            //let json_feed = JSON.parse(feeds[i].feed_json);
            //let update_json_feed = JSON.parse(feeds[i].feed_update_json);
            let documentTypes = feeds[i].documentTypes;
            let dtype = ""; 
            if (documentTypes !== null) {
                for (let doc of documentTypes){
                    dtype = dtype + doc +",";
                    dtype = dtype.substring(0, dtype.length - 1)
                }
            }
            if(resultTask.checkRU !== 0){

                if(feeds[i].issueDate !== null){
                    let issue_date_str = feeds[i].issueDate ? feeds[i].issueDate : '1970-01-01T00:00:00.000Z';
                    var dd_issueDate = parseInt(issue_date_str.substring(8,12)) < 10 ? "0"+parseInt(issue_date_str.substring(8,12)) : parseInt(issue_date_str.substring(8,12));
                    var mm_issueDate = parseInt(issue_date_str.substring(5,7)) < 10 ? "0"+parseInt(issue_date_str.substring(5,7)) : parseInt(issue_date_str.substring(5,7));
                    var yyyy_issueDate = parseInt(issue_date_str.substring(0,4));               
                    regu_pub_date = `${dd_issueDate}/${mm_issueDate}/${yyyy_issueDate}`;
                }

                let ruObject = {}
                ruObject.refid = feeds[i].feed_id;
                ruObject.ruName = feeds[i].documentName;
                ruObject.documentType = dtype;
                ruObject.regulatorName = feeds[i].ragulatorName;
                ruObject.tags = feeds[i].tags;
                ruObject.issueDate = regu_pub_date;
                ruObject.isLegecy = false;
                ruObject.legecyRUID = 0;                
                result_data.push(ruObject);                    
            }
        }
    }
    let regulatorNameObject = {};
    let regulatorListAll = Store.getStoreData('ragulatorsname');        
    regulatorListAll.forEach((value) => {  
        regulatorNameObject[value.id.toString()] = value.ragulatorname; 
    })
    // legecy section start    
    //legecyRUs.forEach((value) => {
    for(let k=0;k<legecyRUs.length;k++){
        let this_ru_tags = [];
        tags_result.forEach((t) => {
            //console.log("t.RUId.toString()", t.RUId.toString(), "legecyRUs[k].ID.toString()",legecyRUs[k].ID.toString())
            if (t.RUId.toString() === legecyRUs[k].ID.toString()) {
                //console.log("Sourav",t.TagId)
                this_ru_tags.push(parseInt(t.TagId));
            }
        })
        //console.log("legecyRUObject==>",this_ru_tags )

        let legecyRUObject = {}
        legecyRUObject.refid = Utils.genKey(27);
        legecyRUObject.ruName = legecyRUs[k].RUName;
        legecyRUObject.regulatorName = legecyRUs[k].Source in regulatorNameObject ? regulatorNameObject[legecyRUs[k].Source] : "";
        legecyRUObject.documentType = typeof legecyRUs[k].RUType !== 'undefined' ? legecyRUs[k].RUType : "";
        legecyRUObject.tags = this_ru_tags;             
        legecyRUObject.issueDate = legecyRUs[k].RegulatorPublishedDate;
        legecyRUObject.isLegecy = true;
        legecyRUObject.legecyRUID = legecyRUs[k].ID;
        result_data.push(legecyRUObject);
    }
    //console.log("result_data==>", result_data)
    //new
    let taskData = result_data;
    let task_result = {}; 
    task_result.rows = {};
    let tags = {};
    let filter = {};
    for(var i = 0 ; i< taskData.length; i++)
    {
        if(!(taskData[i].refid in task_result.rows)){    
            let addtags = taskData[i].tags;
            addtags.forEach(function(key) {
                tags[key.toString()] = 1;
                filter[key.toString()] = 1;
            });

            let filter1 = {};    
            let addtags1 = taskData[i].tags;
            addtags1.forEach(function(key) {
                filter1[key.toString()] = 1;
            })
            let final_filter = filter1
            
            task_result.rows[taskData[i].refid] = {row: taskData[i], tags: final_filter}
        }    
    }
    let status_count = {};
    Object.keys(tags).forEach(function(key) {
        let j = 0;
        for(var i = 0 ; i< taskData.length; i++) {
            let addtags = taskData[i].tags;
            let check_tag = {};
            addtags.forEach(function(keynew) {
                check_tag[keynew.toString()] = 1;                
            });
            
            if(key in check_tag) {
                j++;
                status_count[key.toString()] = j;
            }
        }

    });
    task_result['status_count'] = status_count;
    task_result['filter'] = filter;

    //console.log("task_result==>", task_result)


    if(Store.getStoreData('view_only_register_result_all') === null) {       
        Store.updateStore('view_only_register_result_all', task_result);
    }
    if(checkid === 1 && Store.getStoreData('view_only_rutaskdatalegecyAll') === null) {        
        Store.updateStore('view_only_rutaskdatalegecyAll', task_result);
    }
    return true;
 } 

//  getFAQFilter(data) {
    
//     return filter;
// }

}

export default new RegisterViewCount();