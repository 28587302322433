import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import * as Style from '../Common/StyledComponents'
import APICall from '../../Common/APICall';
import WelcomeBanner from '../WelcomeBanner';
import CSLParameter from './CSLParameter';
import CSLScheme from './CSLScheme';



const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;

`;

class CSLIndex extends React.Component
{
    state = {view_count:0}

    componentDidMount()
    {
        console.log("csl table data");
    }

    refreshState = () => {
        this.setState({view_count: this.state.view_count + 1});
    }


    render()
    {
        let p = this.props.p === null ? "parameter" : this.props.p;
        let s = "Recurring Tasks";
        if(p === "scheme")s="MCC Schemes"
        return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <WelcomeBanner   welcome={s}/>
                {
                    (() => {
                      switch(p) {
                        case 'parameter' : return <CSLParameter />; break;
                        case 'scheme': return <CSLScheme />; break;
                      }
                    })()
                }
            </div>
        );
    }
}

export default CSLIndex;