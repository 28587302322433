import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import {FiDownload} from 'react-icons/fi'

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;




const AttachmentLink = styled.input.attrs({ 
  type: 'file'
})`
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
`;

// const AttachmentLink = styled.a`
//     font-size: 12px;
//     font-weight: 600;
//     color: #BEC2C5;
//     cursor: pointer;
// `;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 60%;
    position: fixed;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const CSLModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    font-weight: bold;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
`;
const ModalTextArea = styled.textarea`
    height: 150px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalTextArea_ext = styled.textarea`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
`;
const ModalTextAreaInvisible = styled.textarea`
    height: 150px;
    width: 100%;
    border: none;
    border-radius: 3px;
    background-color:#ffffff;
    resize: none;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const ModalTextAreaReadonly = styled.textarea`
    height: 150px;
    width: calc(100% - 13px);
    border: none;
    background-color:#edf0f1;
    resize: none;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalInputReadonly = styled.input`
    height: 40px;
    width: calc(100% - 13px);
    border: none;
    background-color:#edf0f1;
    resize: none;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #f7f7f7;
    padding: 2px 2px 2px 2px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 5px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;

const ModalNameInputDiv = styled.div`
    height: auto;
    width: calc(100% - 13px);
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const ModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 15% calc(80% - 25px);
    grid-gap: 5px;
    padding: 10px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 10px;
`;
const ModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const ModalDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const ModalDateLabel = styled.label`
    font-weight: 600;
    color: #282828;
`;
const ModalDateInput = styled.input`
    height: 40px;
    width: 50%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;


const ModalLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #282828;
`;
const ModaInputDiv = styled.div`
    height: auto;
    width: calc(100% - 13px);
`;
const ModalWiderDiv = styled.div`
    height: auto;
    padding-left: 21px;
    padding-right: 21px;
    width: 100%;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #282828;
`;
const ModalWiderHeaderDiv = styled.div`
    border-radius: 4px 4px 0px 0px;
    background-color: #04ACA7;
    width:100%;
    height:40px;
    min-height:40px;
`
const ModalClearDiv = styled.div`
    border-radius: 3px 3px 0px 0px;
    width:100%;
    height:10px;
    min-height:30px;
`;
const ModalWiderLineDiv = styled.div`
    background-color: #E4E4E4;
    padding-left: 21px;
    padding-right: 21px;
    width:100%;
    height:3px;
    min-height:3px;
`;
const ModalInput = styled.input`
    height: 40px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const CancelSubmitButtonOuterContainer = styled.div`
    padding-top: 20px;
    display: block;
    box-sizing: border-box;
    padding-right: 19px;
    padding-bottom: 8px;
    text-align: right;
    background-color: #edf0f1;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RejectButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #E65164;
    color: #ffffff;
    border: 2px solid #EA5A6A;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-right:5px;
    cursor: pointer;
`;
const ClarifyButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;;
    border: 1px solid #37ada7
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 12px;
    margin-right:5px;
    cursor: pointer;
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

const Addfaq = styled.button`
   display: inline-block;
    box-sizing: border-box;
    background-color: #234062;
    color: #fff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #234062;
    font-size: 11px;
    margin-right: 250px;
    cursor: pointer;
`
const HeadContainer = styled.div`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 10px;
    font-size: 16px;
    color: #929292;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 10px;
    color: #c3c1c1;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
const ModelTopSection = styled.div`
    width: 100%;
`;
const ModelTopSectionLeft = styled.div`
    float: left;
    width: 100%;
`;
const ModelTopSectionRight = styled.div`
    float: right;
    width: 100%;
`;

const showDate = styled.div`
    font-size: 12px;
    top: 10px;
    left: 415px;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 4px;
    width: 200px;
    left: calc(90vw - 900px);
`;

const MemberMenu = styled.div`
    position: absolute;
    border-radius: 5px;
    padding-top: 7px;
`;

const MemberMenuLine = styled.div`
    width: calc(100% - 14px);
    padding-left: 14px;
    cursor: pointer;

`;

const HeaderText = styled.div`
    background-color: #e7f0f7;
    padding: 15px;
    height: auto;
    color: #282828;
    font-family: 'Montserrat',sans-serif;
    text-align: center;
    font-weight: 600;
`

export { SubmitButton, CancelButton, CancelSubmitButtonOuterContainer, ModalInput, ModalWiderLineDiv, ModalClearDiv, ModalWiderHeaderDiv,
         MRModalSelect, ModalWiderDiv, ModaInputDiv, ModalLabelDiv, ModalDateInput, ModalDateLabel, ModalShortDatesContainer, ModalContainertop,
         ModalHeaderCloseBtn, ModalNameInputDiv, ModalNameLabelDiv, ModalBodyInner, ModalBody, ModalTextArea, ModalHeaderText, ModalContainer,
         AttachmentLink, FileTypeContainer, FileInfoContainer, FileContainer, ModalTextAreaInvisible, RejectButton, ClarifyButton, ModalTextArea_ext, Addfaq,
         HeadContainer,HomeLogo,HomeText,Categories,SingleRowColumnContainer, ModelTopSection,ModelTopSectionLeft,ModelTopSectionRight,ModalTextAreaReadonly,ModalInputReadonly,
         CSLModalContainer,MemberMenu,MemberMenuLine, showDate, HeaderText
          }

