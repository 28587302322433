import React from "react";
import styled from "styled-components";
import TAPICall from "./src/Common/APICall.js";
import CarStore from "./src/Common/Store.js";
import Utils from './src/Common/Utils.js';
import Store from './../../../Common/Store';
import GenerciTasks from './src/Components/Register/generciTasks';

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;


class CarHOC extends React.Component {

    state={ready: false, role:''}

    componentDidMount(){
        this.setEnv('car');
    }

    setEnv(module_name) {
        let api_url = `${Store.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;

        CarStore.updateStore('module_name',module_name);
        CarStore.updateStore('api_url',api_url);
        CarStore.updateStore('is_admin',false);
        CarStore.updateStore('is_debug',false);

        let postData = { command: "taskindex" };
        let api = new TAPICall();
        api.indexCommand(postData, this.processtaskIndex);
    }

    processtaskIndex = (result) => {
        console.log("result car", result)
        let r = {};
        r['result'] = {};
        let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        let user_roles = {};
        let contacts = {}
        if('contactlist' in cts.result && 'user_roles' in cts.result) {
          user_roles = cts.result.user_roles['car'];
          for(let k in cts.result.contactlist){
            contacts[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
          }
        }
        let loggedincontact = Store.getStoreData('loggedincontact')
        let parentcompany_id = Store.getStoreData('parentcompany_id')
        let parrent_company = Store.getStoreData('parrent_company')
        let company = {};
        company['ClientNumber'] = parentcompany_id;
        company['CompanyName'] = parrent_company;


        CarStore.updateStore('company', company);
        CarStore.updateStore('contacts', contacts);
        CarStore.updateStore('user_roles', user_roles);
        CarStore.updateStore('contact', contacts[loggedincontact.ID.toString()]);
        CarStore.updateStore('role', user_roles[loggedincontact.ID]);
        CarStore.updateStore('contact_id', loggedincontact.ID);
        let gc_company = Store.getStoreData('gc_companie')
        let groupings = Store.getStoreData('groupings')
        console.log("gc_company", gc_company)
        CarStore.updateStore('gc_company', gc_company);
        CarStore.updateStore('groupings', groupings);
        CarStore.updateStore('moduleconfig', result.result['moduleconfig'].general);
        CarStore.updateStore('include_other', result.result['moduleconfig'].general.include_other);
        CarStore.updateStore('org_teams', result.result['teams']);

        let tags = [];
        let topics = [];
        for(let id of Object.keys(result.result['moduleconfig'].general.tags)) {
          if(result.result['moduleconfig'].general.tags[id].included) {
            tags.push({ID: id, TagName:result.result['moduleconfig'].general.tags[id].TagName})
          }
          if(result.result['moduleconfig'].general.enabled_topics) {
            if('enabled_topic' in result.result['moduleconfig'].general.tags[id] && result.result['moduleconfig'].general.tags[id].enabled_topic) {
              topics.push({id: id, topic:result.result['moduleconfig'].general.tags[id].TagName, value: result.result['moduleconfig'].general.tags[id].TagName})
            }
          }
        }

        let show_faq = true;
        if(result.result['moduleconfig'].general.access_faq) {
          result.result['moduleconfig'].general.faq_restriction_roles.forEach((item) => {
            if(item.name === user_roles[loggedincontact.ID] && !item.enabled) {
              show_faq = false;
            }
          })
        }

        CarStore.updateStore('show_faq', show_faq);
        CarStore.updateStore('tags', tags);

        let has_gc = gc_company.length > 1 ? true : false;
        CarStore.updateStore('has_gc',has_gc);

        let gc_list = [];
        let gc_assoc = {};
        let gc_filters = {};
        for(let gc of gc_company) {
            gc_list.push(gc);
            gc_assoc[gc.id.toString()] = gc;
            if(gc.is_gc) {
              gc_filters[gc.id.toString()] = 1;
            }
        }

        CarStore.updateStore('gc_list', gc_list);
        CarStore.updateStore('gc_assoc', gc_assoc);
        CarStore.updateStore('gc_filters', gc_filters);

         let list_requester = [];
         let list_answrere =  Utils.ShowTeamUsers(parentcompany_id);
         let list_approvar = [];
         Object.entries(contacts).forEach(([key, value]) => {
             if (user_roles[key.toString()] === 'team' ) {
                 list_requester.push({"ID" : key, "contact_name": value })
             }
              if(parseInt(key) !== parseInt(result.result['contact_id']) && (user_roles[key.toString()] === 'admin_manager' || user_roles[key.toString()] === 'ca' || user_roles[key.toString()] === 'ca_module_admin') && user_roles[key.toString()] !== 'no_access' ) {
               list_approvar.push({"ID" : key, "contact_name": value })
             }

         })

         list_approvar.sort(this.compare);
         list_requester.sort(this.compare);
         topics.sort(function(a,b) {return (a.topic > b.topic) ? 1 : ((b.topic > a.topic) ? -1 : 0);} );
         list_approvar.unshift({"ID": "0", "contact_name":"Compliance Advisers"});
         list_requester.unshift({"ID": "0", "contact_name":"Compliance Advisers"});
         let only_topic = topics;
         CarStore.updateStore('topics_exclude_other', topics);
         if(result.result['moduleconfig'].general.include_other){
          topics.push({id: "other", topic:"Other", value: ""});
         }

        CarStore.updateStore('list_requester', list_requester);
        CarStore.updateStore('list_answrere', list_answrere);
        CarStore.updateStore('list_approvar', list_approvar);
        CarStore.updateStore('topics', topics);
        this.setState({ready: true})
    }

    closeUserDialog = () => {
        this.setState({show_modal: false})
        this.props.closeUserDialog()
    }

    refreshState = (stack=null) => {
        console.log("refresh satete", stack)
        this.setState({show_modal: false})
        if(stack.task){
          let task = stack.task;
          let t = {};
          t['contact_id'] = task.contact_id
          t['company_id'] = task.company_id
          t['created_at'] = task.created_at
          t['cur_assigned_to'] = task.cur_assigned_to
          t['cur_lane'] = task.cur_lane
          t['description'] = task.answer
          t['due_date'] = task.due_date
          t['hint_id'] = 0
          t['id'] = task.task_id
          t['is_hint'] = false
          t['is_recurring'] = false
          t['last_action_time'] = task.last_action_time
          t['module_id'] = "car"
          t['name'] = task.query;
          t['parameter_id'] = 0
          t['parent_task_id'] = 0
          t['ref_id'] = 0
          t['system_id'] = 1001
          t['task_id']= task.task_id
          t['task_type'] = null
          stack.task = t;
          if(task.cur_lane === "COMP_CAR_USER_COMPLETED"){
            stack['mode'] = 'delete'
          }
          this.props.refreshParentState(stack)
        }else{
            this.props.closeUserDialog()
        }


        // this.props.refreshState()
    }

    render() {
        if(!this.state.ready)return(<div></div>)
        let task_id = this.props.task_id;
        let company_id = this.props.company_id;
        let cur_lane = this.props.match ? ('curLane' in this.props.match.params ? this.props.match.params.curLane: 0) : 0;
        return(
            <FolderContainer>
                <GenerciTasks taskId={task_id} companyId={company_id} curLane={cur_lane} closeUserDialog={this.closeUserDialog} refreshParentCallback={this.refreshState}/>
            </FolderContainer>
        );
    }
}

export default CarHOC;
