import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import DatePicker from 'react-date-picker';
import AMShareAttestationModal from './AMShareAttestationModal';
import CSLLoader from '../Common/CSLLoader';
import CSLCheckBox from '../Common/CSLCheckBox';
import AlertBox from '../Common/AlertBox';
import '../../App.css';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {FaTimes, FaPlusCircle, FaLock, FaPencilAlt} from 'react-icons/fa';
import { event } from 'jquery';
import QuestionSet from '../Moduleconfig/QuestionSet';
import HtmlEditorDocument from './../Common/HtmlEditorDocument';
import Utils from './../../Common/Utils';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLink = styled.div`
    color: #94DEDF;
    font-weight: 600;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    cursor: pointer;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: calc(100% - 6px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;

const QSearchInput = styled.input`
    height: 15px;
    width: 270px;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 10px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 2px;
    float: right;
`;
const SectionNameInput = styled.input`
    height: 18px;
    width: 200px;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #ffffff;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 5px;
    padding: 6px;
    background-color: #04ADA8;
    margin-top: -8px;
`;

const QuestionLine = styled.div`
    margin-top: 10px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 25px;
    background-color: white;
`

const QuestionTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    float: left;
    width: calc(100% - 370px);
`

const EditButton = styled.div`
    width: 30px;
    height: 30px;
    margin: 0px;
    padding-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    float: right;
`
const QuestionsBox = styled.div`
    width: calc(100% - 10px);
    height: 300px;
    max-height: 400px;
    overflow-y: scroll;
`

const QSetBox = styled.div`
    width: 100%;
    border-width: 2px;
    border-style: solid;
    border-color: #dadada;
    border-radius: 5px;
    padding: 5px;
`
const ModalContainer = styled.div`
    position: fixed;
    background-color: #ffffff;
    top: 100px;
    left: calc(50vw - 250px);
    width: 650px;
    box-sizing: border-box;
    height: 400px;
    min-height: 300px;
    box-sizing: border-box;
    background-color: #F3F7FB;
    border: 1px solid #545454;
    border-radius: 5px;
    z-index: 1010;
    opacity: 1.0;
`
const QSModalContainer = styled.div`
    position: fixed;
    background-color: #ffffff;
    top: 100px;
    left: 300px;
    width: calc(100vw - 400px);
    box-sizing: border-box;
    height: calc(100vh - 150px);
    min-height: 500px;
    box-sizing: border-box;
    background-color: #F3F7FB;
    border: 1px solid #545454;
    border-radius: 5px;
    z-index: 1010;
    opacity: 1.0;
`
const ModalHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-left: 7px solid #04ADA8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const ModalBody = styled.div`
    width: 100%;
    padding-left: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 200px;
    min-height: 200px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    z-index: 1020;
    opacity: 1.0;
    overflow-y: scroll;
`
const QSModalBody = styled.div`
    width: 100%;
    padding-left: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    height: calc(100vh - 350px);
    min-height: calc(100vh - 350px);
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    z-index: 1020;
    opacity: 1.0;
    overflow-y: scroll;
`
const ModalFooter = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 97px;
    min-height: 97px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const ModalHeaderTextLarge = styled.div`
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-right: 0px;
    width: calc(100% - 60px);
    color: #5D748E;
    font-size: 16px;
    font-weight: 700;
    float: left;
`
const ModalHeaderClose = styled.div`
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-left: 0px;
    width: 30px;
    color: #5D748E;
    font-size: 20px;
    font-weight: 700;
    float: right;
    cursor: pointer;
`
const ModalHeaderTextSmall = styled.div`
    padding-left: 10px;
    padding-top: 3px;
    width: calc(100% - 10px);
    color: #5D748E;
    font-size: 12px;
    font-weight: 700;
`

const SaveButton = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 25px;
`;

const CancelButton = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`

const ClearFloat = styled.div`
    clear: both;
`
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;	
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

class EditQSetModal extends React.Component {
    saveQSet = () => {
        // console.log('updateQSet this.QEditor', this.QEditor.state)
        this.props.updateQSet(this.QEditor.state)
    }
    render() {
        return (<div>
            <QSModalContainer>
                <ModalHeader>
                    <ModalHeaderTextLarge>Edit Question Set</ModalHeaderTextLarge><ModalHeaderClose onClick={this.props.closeEditQSetModal}><FaTimes /></ModalHeaderClose>
                </ModalHeader>
                <QSModalBody>
                    <QuestionSet
                        ref={(QEditor) => {this.QEditor = QEditor;}}
                        qset_id={this.props.qset_id}
                        qset_header={this.props.qset_header}
                        updateQSet={this.updateQSet}
                        is_active={this.props.qset_header.is_active}
                        always_active={true}
                    />
                </QSModalBody>
                <ModalFooter>
                    <SaveButton onClick={this.saveQSet} style={{float: 'right', marginTop: 15, backgroundColor: '#143151'}}>Save</SaveButton>
                    <CancelButton onClick={this.props.closeEditQSetModal} style={{float: 'right', marginTop: 15, backgroundColor: '#ffffff'}}>Cancel</CancelButton>
                </ModalFooter>
            </QSModalContainer>
        </div>)
    }
}

class QSetCustomizeTexts extends React.Component {
    state = {question: null, q_index: null, section_id: null, show_modal: null}

    componentDidMount = () => {
        this.setState({question: this.props.question, q_index: this.props.q_index, section_id: this.props.section_id})
    }

    saveQuestion = () => {
        this.props.saveQuestion(this.state)
    }

    changeAttestationText = (event) => {
        let question = this.state.question
        question.attestation_text = event.target.value
        this.setState({question})
    }

    changeConfirmationText = (event) => {
        let question = this.state.question
        question.confirmation_text = event.target.value
        this.setState({question})
    }

    render() {
        if(this.state.question === null)return (<div></div>)
        return (<div>
                <InactiveOverlay />
                <ModalContainer style={{height: 500}}>
                    <ModalHeader>
                    <ModalHeaderTextLarge>Customise Attestation and Confirmation Text</ModalHeaderTextLarge><ModalHeaderClose onClick={this.props.closeEditQuestionModal}><FaTimes /></ModalHeaderClose>
                    <ClearFloat />
                    <ModalHeaderTextSmall>Please use this form to customise the Attestation and Confirmation texts for this question.</ModalHeaderTextSmall>
                </ModalHeader>
                <ModalBody style={{height: 300}}>
                    <MRModalLabel>Attestation Text</MRModalLabel>
                    <MRModalTextarea style={{height: 60}}  value={this.state.question.attestation_text} onChange={this.changeAttestationText} />
                    <MRModalLabel>Confirmation Text</MRModalLabel>
                    <MRModalTextarea style={{height: 30}} value={this.state.question.confirmation_text} onChange={this.changeConfirmationText} />
                </ModalBody>
                <ModalFooter>
                    <SaveButton onClick={this.saveQuestion} style={{float: 'right', marginTop: 15, backgroundColor: '#143151'}}>Save</SaveButton>
                    <CancelButton onClick={this.props.closeEditQuestionModal} style={{float: 'right', marginTop: 15, backgroundColor: '#ffffff'}}>Cancel</CancelButton>
                </ModalFooter>
                </ModalContainer>
            </div>)
    }
}

class QSetManagerView extends React.Component {
    state = {qset: null, qset_header: null, qset_index: null, show_qedit_modal: false, section_editing: null, question_editing: null}

    setComponentState = () => {
        if(this.state.qset !== null && this.props.qset_index === this.state.qset_index && !('qset_updated' in this.props)) {
            return
        }
        let qset = {sections: {}}
        for(let id in this.props.qset.sections) {
            qset.sections[id] = {id: id, name: this.props.qset.sections[id].name, show: true, is_open: true, questions: []}
            for(let q of this.props.qset.sections[id].questions) {
                //if(q.is_active === 1)qset.sections[id].questions.push(q)
                qset.sections[id].questions.push(q)
            }
        }
        Store.updateStore('qset_all', qset)
        this.setState({qset, qset_header: this.props.qset_header, qset_index: this.props.qset_index})
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return

        this.setComponentState()
    }

    openSection = (id) => {
        let qset = this.state.qset
        qset.sections[id].is_open = true
        this.setState({qset})
    }

    closeSection = (id) => {
        let qset = this.state.qset
        qset.sections[id].is_open = false
        this.setState({qset})
    }

    setActiveInactive = (checked, stack) => {
        let qset = this.state.qset
        qset.sections[stack.section_id].questions[stack.index].is_active = checked ? 1 : 0
        this.props.updateQSet(qset, this.props.qset_header)
        this.setState({qset})
    }

    closeEditQuestionModal = () => {
        this.setState({show_qedit_modal: false, section_editing: null, question_editing: null})
    }

    openEditQuestionModal = (id, i) => {
        this.setState({show_qedit_modal: true, section_editing: id, question_editing: i})
    }

    saveQuestion = (param) => {
        let qset = this.state.qset
        qset.sections[param.section_id].questions[param.q_index] = param.question
        this.setState({qset, show_qedit_modal: false, section_editing: null, question_editing: null})
    }

    searchSectionValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
        let search_string = event.target.value;
        if (search_string === "") {
             let section_list_all = Store.getStoreData('qset_all');
             this.setState({qset: section_list_all});
         }else {
            //console.log("Section==>",this.state.qset.sections);
            let section_list_all = Store.getStoreData('qset_all').sections;
            let qset_section_all = Store.getStoreData('qset_all');
            let filtered_values = {}
            Object.keys(section_list_all).map((id, i) => {
                if (section_list_all[id].name.toLowerCase().includes(search_string.toLowerCase())) {
                    console.log("Filter==>",id);
                    filtered_values[id.toString()] = section_list_all[id];
                }
            })
            qset_section_all['sections'] = filtered_values;
            this.setState({qset: qset_section_all})
         }
	}

    searchQuestionValue = (uid, isOpenCheck) => (event) => {
        event.preventDefault();
        console.log("Sourav",event.target.value);
        console.log("uid",uid);
        let search_string = event.target.value;
        if (search_string === "") {
            let section_list_all = Store.getStoreData('qset_all');
            this.setState({qset: section_list_all});
        }else {
           let question_list_all = Store.getStoreData('qset_all').sections[uid].questions;
           let qset_section_all = Store.getStoreData('qset_all');
           let filtered_values = []
            question_list_all.map((q, j) => {
                if (q.title.toLowerCase().includes(search_string.toLowerCase())) {
                    console.log(q)
                    filtered_values.push(q)
                }
            })
           qset_section_all.sections[uid].questions = filtered_values;
           qset_section_all.sections[uid].is_open = isOpenCheck;
           this.setState({qset: qset_section_all})
        }
    }

    render() {
        console.log('QSetManagerView this.state==>', this.state.qset)
        let lp = Store.getStoreData('language_pack')
        if(this.state.qset === null)return <CSLLoader />
        if(typeof this.state.qset_header === 'undefined')return <CSLLoader />
        console.log('QSetManagerView this.state', this.state.qset_header)
        let q_no = 0;
        return (<QSetBox>
            <QuestionLine><QuestionTitle style={{fontSize: 17}}>{this.state.qset_header.nick_name}</QuestionTitle><QSearchInput onChange={this.searchSectionValue} placeholder={lp['search_sections'] === undefined ? 'Search sections...' : lp['search_sections']} /></QuestionLine>
            {
                Object.keys(this.state.qset.sections).map((id, i) => {
                    return (<div key={i}>
                            <QuestionLine style={{backgroundColor: "#4472C4"}}>
                                <QuestionTitle style={{width: 'calc(100% - 420px)', fontSize: 16, paddingLeft: 20, color: 'white'}}>{this.state.qset.sections[id].name}</QuestionTitle>

                                {
                                    (() => {
                                        if(!this.state.qset.sections[id].is_open) return <EditButton onClick={() => this.openSection(id)} style={{color: "white"}}><IoIosArrowDown /></EditButton>
                                        else return <EditButton onClick={() => this.closeSection(id)} style={{color: "white"}}><IoIosArrowUp /></EditButton>
                                    })()
                                }
                                <QSearchInput onChange={this.searchQuestionValue(id, this.state.qset.sections[id].is_open)} placeholder={lp['search_questions'] === undefined ? 'Search questions...' : lp['search_questions']}/>
                            </QuestionLine>
                            {
                                (() => {
                                    if(this.state.qset.sections[id].is_open) {
                                        return (<div>
                                            <QuestionLine>
                                                <EditButton style={{float: 'left', fontSize: 13, fontWeight: 700, cursor: "default"}}>#</EditButton>
                                                <QuestionTitle style={{width: 'calc(100% - 420px)', fontSize: 13, fontWeight: 700}}>{lp['question_name_col_header'] === undefined ? 'Question' : lp['question_name_col_header']}</QuestionTitle>
                                                <EditButton style={{fontSize: 13, width: 115, fontWeight: 700,cursor: "default"}}>{lp['customise'] === undefined ? 'Customise' : lp['customise']}</EditButton>
                                                <EditButton style={{fontSize: 13, width: 115, fontWeight: 700,cursor: "default"}}>{lp['include'] === undefined ? 'Include' : lp['include']}</EditButton>

                                            </QuestionLine>
                                            <QuestionsBox>
                                            {
                                                this.state.qset.sections[id].questions.map((q, j) => {
                                                    q_no++;
                                                    console.log('Questions q', q)
                                                    return (<QuestionLine key={(i+1)*1000+j}>
                                                                {
                                                                    (() => {
                                                                        if(q.is_active === 1){
                                                                            return(<div>
                                                                                    <EditButton style={{float: 'left', fontSize: 13, fontWeight: 400}}>{q_no}</EditButton>
                                                                                    <QuestionTitle style={{width: 'calc(100% - 420px)', fontSize: 13}}>{q.title}</QuestionTitle>
                                                                            </div>)
                                                                        }else{
                                                                            return(<div>
                                                                                <EditButton style={{float: 'left', fontSize: 13, fontWeight: 400, color: "gray"}}>{q_no}</EditButton>
                                                                                <QuestionTitle style={{width: 'calc(100% - 420px)', fontSize: 13, fontWeight: 400, color: "gray"}}>{q.title}</QuestionTitle>
                                                                            </div>)
                                                                        }
                                                                    })()
                                                                }

                                                                {
                                                                    (() => {
                                                                        if(q.show_default === 0) {
                                                                            if(q.is_active === 1){
                                                                            return (<EditButton style={{fontSize: 13, width: 80, fontWeight: 700, paddingLeft: 20}}>
                                                                                        <FaPencilAlt onClick={() => this.openEditQuestionModal(id, j)} />
                                                                                    </EditButton>)
                                                                            }else{
                                                                                return (<EditButton style={{fontSize: 13, width: 80, fontWeight: 700, paddingLeft: 20, color: "gray"}}>
                                                                                    <FaPencilAlt />
                                                                                </EditButton>)
                                                                            }
                                                                        } else {
                                                                            return (<EditButton style={{fontSize: 13, width: 80, fontWeight: 700, paddingLeft: 20}}>
                                                                                        &nbsp;
                                                                                    </EditButton>)
                                                                        }
                                                                    })()
                                                                }
                                                                <EditButton style={{fontSize: 13, fontWeight: 400, width: 120}}>
                                                                    <CSLCheckBox checkCallback={this.setActiveInactive} checked={q.is_active === 1 ? true : false} stack={{index: j, section_id: id}} />
                                                                </EditButton>


                                                            </QuestionLine>)
                                                })
                                            }
                                            </QuestionsBox>
                                        </div>)
                                    }
                                })()
                            }
                        </div>)
                })
            }
            {
                (() => {
                    if(this.state.show_qedit_modal) return (<div>
                        <QSetCustomizeTexts
                            section_id={this.state.section_editing}
                            q_index={this.state.question_editing}
                            question={this.state.qset.sections[this.state.section_editing].questions[this.state.question_editing]}
                            closeEditQuestionModal={this.closeEditQuestionModal}
                            saveQuestion={this.saveQuestion}
                        />
                    </div>)
                })()
            }
        </QSetBox>)
    }
}

class SelectQSet extends React.Component {
    state = {ready: false, qsets: [], selected_qset_id: '0', selected_qset: null, selected_qset_index: null, orig_qset: null, orig_qset_index: null, orig_qset_id: null, show_modal: null}

    setComponentState = () => {
        let qsets = Store.getStoreData('qsets')
        let selected_qset = this.props.qset
        let selected_qset_index = this.props.qset_index
        let orig_qset = this.props.orig_qset
        let orig_qset_index = this.props.orig_qset_index
        let selected_qset_id = this.state.selected_qset_id

        if(qsets !== null) {
            for(let i = 0; i < qsets.length; i++) {
                if(i===selected_qset_index) {
                    selected_qset_id = qsets[i].id
                }
            }
            this.setState({ready: true, qsets: qsets, selected_qset, selected_qset_index, orig_qset, orig_qset_index, selected_qset_id, orig_qset_id: selected_qset_id})
            return
        }
        const postData = { command: "qsets", action: 'list_qsets', active_only: true };
        const api = new APICall();
        api.command(postData, this.processQsets);
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return

        this.setComponentState()
    }

    processQsets = (result) => {
        console.log("Sourav==>", result.result.qsets)
        let selected_qset = this.props.qset
        let selected_qset_index = this.props.qset_index
        let orig_qset = this.props.orig_qset
        let orig_qset_index = this.props.orig_qset_index
        let selected_qset_id = this.state.selected_qset_id
        let qsets = result.result.qsets
        for(let i = 0; i < qsets.length; i++) {
            if(i===selected_qset_index) {
                selected_qset_id = qsets[i].id
            }
        }
        Store.updateStore('qsets', qsets)
        this.setState({ready: true, qsets, selected_qset, selected_qset_index, orig_qset, orig_qset_index, selected_qset_id, orig_qset_id: selected_qset_id})
    }

    processGetQset = (result) => {
        console.log('processGetQset result', result)
        Store.updateStore(`qset-${this.state.selected_qset_index}`, result.result.qset)
        this.props.saveQSet(result.result.qset, this.state.qsets[this.state.selected_qset_index], this.state.selected_qset_index)
        this.setState({selected_qset: result.result.qset, show_modal: null})
    }

    updateQSet = (qset, qset_header) => {
        this.props.saveQSet(qset, qset_header, this.state.selected_qset_index)
    }

    onSelectQSet = (e) => {
        let qsets = this.state.qsets
        let selected_qset_index = 0
        for(let i = 0; i < qsets.length; i++) {
            if(qsets[i].id.toString() === e.target.value){
                selected_qset_index = i
                break
            }
        }
        console.log('e.target.value', e.target.value)

        let data_id = `qset-${selected_qset_index}`
        let qset = Store.getStoreData(data_id)
        if(e.target.value === this.state.orig_qset_id) {
            qset = this.props.qset
        }
        // if(qset !== null) {
        //     this.setState({selected_qset_id: e.target.value, selected_qset: qset, selected_qset_index})
        //     return
        // }
        this.setState({selected_qset_id: e.target.value, selected_qset: null, selected_qset_index})
        const postData = {command: "qsets", action: 'get_qset', qset_id: e.target.value.toString()};
        const api = new APICall();
        api.command(postData, this.processGetQset);
    }

    processSaveQset = (result) => {
        console.log('processSaveQset result', result)
        if(result.error_code === 0) {
            let qsets = result.result.qsets
            this.setState({qsets, show_modal: null})
            const api = new APICall();
            const postData = {command: "qsets", action: 'get_qset', qset_id: this.state.selected_qset_id};
            api.command(postData, this.processGetQset);
        }
    }

    updateModuleQSet = (params) => {
        console.log('updateQSet param', params)
        let api = new APICall()
        let postData = {command: 'qsets', action: 'update_qset', qset_id: params.qset_id, nickname: params.qset_header.nick_name, is_active: params.qset_header.is_active, qset_json: params.qset}
        console.log('updateQSet postData', postData)
        let qsets = this.state.qsets
        qsets[this.state.selected_qset_index] = params.qset_header
        this.setState({qsets})
        this.props.saveQSet(params.qset, params.qset_header, this.state.selected_qset_index)
        api.command(postData, this.processSaveQset);
    }

    // updateModuleQSet = (params) => {
    //     console.log('updateQSet param', params)
    //     let qsets = this.state.qsets
    //     qsets[this.state.selected_qset_index] = params.qset_header
    //     Store.updateStore(`qset-${this.state.selected_qset_index}`, params.qset)
    //     this.props.saveQSet(params.qset, params.qset_header, this.state.selected_qset_index)
    //     this.setState({qsets, selected_qset: params.qset, show_modal: null})
    // }

    editQSet = () => {
        this.setState({show_modal: 'edit_qset'})
    }

    closeEditQSetModal = () => {
        this.setState({show_modal: null})
    }

    render() {
        let lp = Store.getStoreData('language_pack')
        console.log('SelectQSet this.state', this.state.selected_qset_index)
        if(!this.state.ready)return (<CSLLoader />)

        let role = Store.getStoreData('role')

        return (<div>
                <MRModalLabel>{lp['question_set_dropdown'] === undefined ? 'Question Set' : lp['question_set_dropdown']}</MRModalLabel>
                <MRModalSelect onChange={this.onSelectQSet} value={this.state.selected_qset_id}>
                    <option value="0">{lp['question_set_dropdown_prompt'] === undefined ? 'Select a Question Set...' : lp['question_set_dropdown_prompt']}</option>
                    {
                        this.state.qsets.map((q, i) => {
                            return <option key={i} value={q.id}>{q.nick_name}</option>
                        })
                    }
                </MRModalSelect>
                {
                    (() => {
                        if(this.state.selected_qset_id !== '0') {
                            if(this.state.selected_qset === null) {
                                return <CSLLoader />
                            } else {
                                return (<div><QSetManagerView
                                    qset={this.state.selected_qset}
                                    updateQSet={this.updateQSet}
                                    qset_header={this.state.qsets[this.state.selected_qset_index]}
                                    qset_index={this.state.selected_qset_index}
                                    qset_updated={true}
                                />
                                {
                                    (() => {
                                        if(role === 'admin_manager' && this.state.selected_qset !== null) return <MRModalLink style={{width: "14%"}} onClick={() => this.editQSet()}>{lp['edit'] === undefined ? 'Edit Question Set' : lp['edit']}</MRModalLink>
                                    })()
                                }
                                </div>)
                            }
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.show_modal === 'edit_qset') return (
                            <EditQSetModal
                                closeEditQSetModal={this.closeEditQSetModal}
                                qset_id={this.state.qsets[this.state.selected_qset_index].id}
                                qset_header={this.state.qsets[this.state.selected_qset_index]}
                                updateQSet={this.updateModuleQSet}
                                is_active={this.state.qsets[this.state.selected_qset_index].is_active}
                            />
                            )
                    })()
                }
            </div>)
    }
}

class AMAskAttestationModal extends React.Component
{
    state = {
        ready: false,
        at_type: '',
        binFiles: [],
        cur_files_json: null,
        documentName: "",
        documentVersion: "",
        description: "",
        last_change_date: new Date(),
        next_review_date: new Date(),
        assignedTo: "",
        due_date: new Date(),
        submit_form: null,
        is_disabled: false,
        updateReviewList: [],
        showShareAttestationModal: false,
        taskid: null,
        rowindex: null,
        isloadericon: false,
        qset: null,
        qset_header: null,
        qset_index: null,
        orig_qset: null,
        orig_qset_header: null,
        orig_qset_index: null,
        alert_param: null,
        is_submitting: false,
        is_configure_attestation_wording: false,
        additionalText: "",
        confirmationText: "",
        isReadable: true
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index ====>', index);
        let review_list = Store.getStoreData('reviewee_list');
        let user_roles = Store.getStoreData('userroles');

        let updateReviewList = [];
        review_list.forEach((item) => {
            if(item.ID in user_roles && (user_roles[item.ID] === "manager" || user_roles[item.ID] === "am")){
                updateReviewList.push(item);
            }
        });
        updateReviewList.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
        let moduleconfig = Store.getStoreData('module_config');
        let form_data = {};
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);
            console.log('task_json_Rimpa', task_json);
            let lastChangeDate = new Date(task_json.object_data.headerinfo.last_change_date)
            let newReviewDate = new Date(task_json.object_data.headerinfo.next_review_date)

            let is_configure_attestation_wording = 'is_configure_attestation_wording' in task_json.object_data ? task_json.object_data.is_configure_attestation_wording : false;
            let additionalText = is_configure_attestation_wording ? task_json.object_data.additionalText : moduleconfig.general.configure_attestation_wording_enable ? moduleconfig.general.configure_attestation_wording_details.additional_text : "";
            let confirmationText = is_configure_attestation_wording ? task_json.object_data.confirmationText : moduleconfig.general.configure_attestation_wording_enable ? moduleconfig.general.configure_attestation_wording_details.confirmation_text : "";
            let isReadable = is_configure_attestation_wording ? false : true

            let qtext = task_json.object_data.headerinfo.description;
            if(Utils.isBase64(task_json.object_data.headerinfo.description)){
                qtext = window.decodeURIComponent(atob(task_json.object_data.headerinfo.description))
            }
            Store.updateStore('doc_description', null);

            this.setState({
                at_type: 'at_type' in task_json.object_data ? task_json.object_data.at_type : 'Document',
                cur_files_json: task_json.object_data.bin_files,
                documentName:  task_json.object_data.headerinfo.document_name,
                documentVersion: task_json.object_data.headerinfo.document_version,
                description: qtext,
                last_change_date: lastChangeDate,
                next_review_date: newReviewDate,
                assignedTo: task_json.object_data.headerinfo.assignedTo,
                taskid: form_data.id,
                qset: 'qset' in task_json.object_data ? task_json.object_data.qset : null,
                orig_qset: 'qset' in task_json.object_data ? JSON.parse(JSON.stringify(task_json.object_data.qset)) : null,
                qset_index: 'qset_index' in task_json.object_data ? task_json.object_data.qset_index : null,
                orig_qset_index: 'qset_index' in task_json.object_data ? task_json.object_data.qset_index : null,
                qset_header:  'qset_header' in task_json.object_data ? task_json.object_data.qset_header : null,
                orig_qset_header:  'qset_header' in task_json.object_data ? JSON.parse(JSON.stringify(task_json.object_data.qset_header)) : null,
                updateReviewList: updateReviewList, ready: true,rowindex: index,
                is_configure_attestation_wording, additionalText, confirmationText, isReadable
            });
        }else{
            const nextyear = moment().add(1, 'year').format('YYYY-MM-DD');
            //console.log('nextyear==>', new Date(nextyear));

            if(!('at_type' in moduleconfig.general))moduleconfig.general.at_type = 'All'
            let at_type = moduleconfig.general.at_type === 'All' || moduleconfig.general.at_type === 'Document' ? 'Document' : 'QuestionSet';

            let additionalText = moduleconfig.general.configure_attestation_wording_enable ? moduleconfig.general.configure_attestation_wording_details.additional_text : "";
            let confirmationText = moduleconfig.general.configure_attestation_wording_enable ? moduleconfig.general.configure_attestation_wording_details.confirmation_text : "";

            this.setState({updateReviewList: updateReviewList, ready: true,rowindex: index, at_type, next_review_date: new Date(nextyear), additionalText, confirmationText})
        }
    }

    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_ATTEST_URL;
            }else if(Store.getStoreData('isReferenceDocmgmt') === true){
                window.location = '/';
            }else{
                //window.location = '/';
                this.props.closeTaskModal();
            }
        } else {
            this.props.closeModal();
        }
    }

    saveQSet = (qset, qset_header, qset_index) => {
        console.log("qset==>", qset)
        this.setState({qset, qset_header, qset_index})
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    onLastChangeDate = (date) => {
        this.setState({last_change_date: date});
    }

    onNextReviewDate = (date) => {
        this.setState({next_review_date: date});
    }
    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    submitAttestation = (submit_form) => (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        let lp = Store.getStoreData('language_pack')
        //let moduleconfig = Store.getStoreData('module_config');
        if(this.state.at_type === 'QuestionSet') {
            this.submitQSetAttestation(submit_form)
            return
        }
        if(submit_form === 1) {
        }else{
            if(this.state.documentName === ""){
                //alert('Please provide a document name.');
                problems = true
                message += lp['no_document_name_warning'] === undefined ? 'Please provide a document name. ' : lp['no_document_name_warning']
            }else if(this.state.documentVersion === ""){
                //alert('Please provide the document version.');
                problems = true
                message += 'Please provide the document version. '
            //}else if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === ""){
            //   alert('Please assign to a user.');
            problems = true
            message += 'Please provide the document version. '
            }
        }
        if(problems) {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: lp['confirmation_to_no_document_name_warning'] === undefined ? 'Ok' : lp['confirmation_to_no_document_name_warning'], confirm: false,
            alertHandler: this.checkalertparam, stack: {submit_form: submit_form}}
            this.setState({alert_param: alert_param})
            return false
        }

        let lastChangeDate = parseInt(this.state.last_change_date.getMonth());
        let changecurrmonth = lastChangeDate + 1;
        var changeDateStr = changecurrmonth >= 10 ? this.state.due_date.getFullYear()+'/'+changecurrmonth+'/' : this.state.last_change_date.getFullYear()+'/0'+changecurrmonth+'/';
        changeDateStr += this.state.last_change_date.getDate() < 10 ? '0' + this.state.last_change_date.getDate() : this.state.last_change_date.getDate();

        let nextReviewDate = parseInt(this.state.next_review_date.getMonth());
        let reviewcurrmonth = nextReviewDate + 1;
        var reviewDateStr = reviewcurrmonth >= 10 ? this.state.next_review_date.getFullYear()+'/'+reviewcurrmonth+'/' : this.state.next_review_date.getFullYear()+'/0'+reviewcurrmonth+'/';
        reviewDateStr += this.state.next_review_date.getDate() < 10 ? '0' + this.state.next_review_date.getDate() : this.state.next_review_date.getDate();

        let descriptionText = Store.getStoreData('doc_description');
        if(descriptionText === null){
        descriptionText = this.state.description;
        }
        let description_value = btoa(window.encodeURIComponent(descriptionText));

        let headerinfo = {};
        headerinfo.document_name = this.state.documentName;
        headerinfo.document_version = this.state.documentVersion;
        headerinfo.description = description_value;
        headerinfo.last_change_date = changeDateStr;
        headerinfo.next_review_date = reviewDateStr;
        headerinfo.assignedTo = this.state.assignedTo;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.is_configure_attestation_wording = this.state.is_configure_attestation_wording;
        final_object_data.additionalText = this.state.is_configure_attestation_wording ? this.state.additionalText : "";
        final_object_data.confirmationText = this.state.is_configure_attestation_wording ? this.state.confirmationText : "";

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_CREATE", "actiondate": Date.now(),
            "cur_assigned_to" : 0, 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0,'due_date' : dueDateStr},
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_CREATE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_CREATE","performed_by": Store.getStoreData('contact_id') }
            };
        console.log("task jason", task_json);
        let postData = {command: "create_attestation_task", task_json: task_json,bin_files: bin_files, submit: submit_form };
        console.log("postData",postData)
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processAttestationData);
    }

    checkalertparam = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    processAttestationData = (result) => {
        let message = '';
        let lp = Store.getStoreData('language_pack')
        if(this.state.submit_form === 1){
            //alert('Policy Attestation created successfully.');
            message='Policy Attestation created successfully.';
        }else {
            //alert('Policy Attestation has been saved but not submitted for creation yet. You may modify and create it later.');
            message='Policy Attestation has been saved but not submitted for creation yet. You may modify and create it later.';
            // let my_task = result.current_task;
            // const task_json = my_task[0].task_json;
            // let single_my_task = [];
            // single_my_task[0] = my_task[0].task_id;
            // single_my_task[1] = task_json.object_data.headerinfo.document_name;
            // single_my_task[2] = my_task[0].cur_lane;
            // single_my_task[3] = task_json.object_data.headerinfo.shareUserList;
            // single_my_task[4] = task_json.object_data.headerinfo.acknowledgementDueDate;
            // single_my_task[5] = my_task[0].child_task_ids;
            // single_my_task[6] = task_json.object_data.at_type;


            // let attest_index = Store.getStoreData('attest-index')
            // if(attest_index !== null && 'result' in attest_index && 'my_tasks' in attest_index.result){
            //         attest_index.result.my_tasks.push(single_my_task);
            // }
            // console.log("attest_index==>", attest_index)
            // Store.updateStore('attest-index', attest_index)
            // Store.updateStore('mytasks', attest_index.result.my_tasks)

            /*if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                    window.location = '/select/microservice/mstop/attest';
                }else if(Store.getStoreData('isReferenceDocmgmt') === true){
                    window.location = '/select/microservice/mstop/docmgmt';
                }else{
                    window.location = '/';
                }
            } else {
                this.props.closeModalUpdate();
            }*/
            let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: message, ok_text: 'Ok', confirm: false,
            alertHandler: this.okAfterComplete, stack: {}}

            this.setState({alert_param: alert_param, isLoadedIcon: false})
        }
        //window.location.reload();
    }

    okAfterComplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(this.state.submit_form === 1){
            //alert('Policy Attestation created successfully.');
        }else {
            //alert('Policy Attestation has been saved but not submitted for creation yet. You may modify and create it later.');
            if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                    window.location = process.env.REACT_APP_ATTEST_URL;
                }else if(Store.getStoreData('isReferenceDocmgmt') === true){
                    window.location = '/select/microservice/mstop/docmgmt';
                }else{
                    window.location = '/';
                }
            } else {
                this.props.closeModalUpdate();
            }
        }
    }

    submitQSetAttestation = (submit_form) => {
        console.log('in submitQSetAttestation submit_form, this.state', submit_form, this.state)
        let moduleconfig = Store.getStoreData('module_config');
        let error = false
        let error_msg = ''
        if(this.state.qset_index === null) {
            error = true
            error_msg += 'You must select a Question Set. '
        }
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === "") {
            error = true
            error_msg += 'You must assign the attestation to a user.'
        }
        if(error) {
            let alert_param = {
                            title: 'ERROR',
                            confirm: false,
                            message: 'ERROR: ' + error_msg,
                            alertHandler: this.processSubmitShareQSetAttestation,
                            stack: null
                        }
            this.setState({alert_param})
            return
        }
        let change_date_str = moment.unix(parseInt(this.state.qset_header.updated_at)).format('YYYY/MM/DD')
        let review_date_str = moment.unix(this.state.next_review_date.getTime()/1000).format('YYYY/MM/DD')
        let due_date_str = moment.unix(this.state.due_date.getTime()/1000).format('YYYY/MM/DD')
        console.log('change_date_str, review_date_str', change_date_str, review_date_str)

        let headerinfo = {
                        document_name: this.state.qset_header.nick_name,
                        document_version: 'version' in this.state.qset ? this.state.qset.version : '1.0',
                        description: 'description' in this.state.qset_header ? this.state.qset_header.description : this.state.qset_header.nick_name,
                        last_change_date: change_date_str,
                        next_review_date: review_date_str,
                        assignedTo: this.state.assignedTo
                    }
        let final_object_data = {
            headerinfo: headerinfo,
            qset: this.state.qset,
            qset_index: this.state.qset_index,
            qset_header:this.state.qset_header,
            at_type: 'QuestionSet',
            is_configure_attestation_wording: this.state.is_configure_attestation_wording,
            additionalText: this.state.is_configure_attestation_wording ? this.state.additionalText : "",
            confirmationText: this.state.is_configure_attestation_wording ? this.state.confirmationText : ""
        }

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_CREATE", "actiondate": Date.now(),
            "cur_assigned_to" : 0, 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0,'due_date' : due_date_str},
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_CREATE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_CREATE","performed_by": Store.getStoreData('contact_id') }
        };
        console.log("task jason", task_json);
        let postData = {command: "create_attestation_task", task_json: task_json, bin_files: [], submit: submit_form };
        console.log("postData",postData)
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processAttestationData);
    }

    processSubmitShareQSetAttestation = () => {
        this.setState({alert_param: null})
    }


    submitShareAttestation = (submit_form) => (event) => {
        event.preventDefault();
        if(this.state.at_type === 'QuestionSet') {
            this.submitShareQSetAttestation(submit_form)
            return
        }
        let moduleconfig = Store.getStoreData('module_config');
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)
        let problems = false;
        let message = '';
        let lp = Store.getStoreData('language_pack')
        if(bin_files.length === 0){
            //alert('An attachment is required for submission of this attestation.');
            problems = true;
            message = lp['attachment_required_popup_text'] === undefined ? 'An attachment is required for submission of this attestation.' : lp['attachment_required_popup_text'];
        }else if(this.state.documentName === ""){
            //alert('Please provide a document name.');
            problems = true;
            message = lp['no_document_name_warning'] === undefined ? 'Please provide a document name. ' : lp['no_document_name_warning'];
        }else if(this.state.documentVersion === ""){
            //alert('Please provide the document version.');
            problems = true;
            message = 'Please provide the document version.';
        }else if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === ""){
            //alert('Please assign to a user.');
            problems = true;
            message = 'Please assign to a user.';
        }
        if(problems) {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: lp['confirmation_to_no_document_name_warning'] === undefined ? 'Ok' : lp['confirmation_to_no_document_name_warning'], confirm: false,
            alertHandler: this.checkalertparam, stack: {submit_form: submit_form}}
            this.setState({alert_param: alert_param})
            return false
        }
        let lastChangeDate = parseInt(this.state.last_change_date.getMonth());
        let changecurrmonth = lastChangeDate + 1;
        var changeDateStr = changecurrmonth >= 10 ? this.state.due_date.getFullYear()+'/'+changecurrmonth+'/' : this.state.last_change_date.getFullYear()+'/0'+changecurrmonth+'/';
        changeDateStr += this.state.last_change_date.getDate() < 10 ? '0' + this.state.last_change_date.getDate() : this.state.last_change_date.getDate();

        let nextReviewDate = parseInt(this.state.next_review_date.getMonth());
        let reviewcurrmonth = nextReviewDate + 1;
        var reviewDateStr = reviewcurrmonth >= 10 ? this.state.next_review_date.getFullYear()+'/'+reviewcurrmonth+'/' : this.state.next_review_date.getFullYear()+'/0'+reviewcurrmonth+'/';
        reviewDateStr += this.state.next_review_date.getDate() < 10 ? '0' + this.state.next_review_date.getDate() : this.state.next_review_date.getDate();

        let descriptionText = Store.getStoreData('doc_description');
        if(descriptionText === null){
            descriptionText = this.state.description;
        }
        let description_value = btoa(window.encodeURIComponent(descriptionText));

        let headerinfo = {};
        headerinfo.document_name = this.state.documentName;
        headerinfo.document_version = this.state.documentVersion;
        headerinfo.description = description_value;
        headerinfo.last_change_date = changeDateStr;
        headerinfo.next_review_date = reviewDateStr;
        headerinfo.assignedTo = this.state.assignedTo;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.at_type = 'Document';
        final_object_data.is_configure_attestation_wording = this.state.is_configure_attestation_wording;
        final_object_data.additionalText = this.state.is_configure_attestation_wording ? this.state.additionalText : "";
        final_object_data.confirmationText = this.state.is_configure_attestation_wording ? this.state.confirmationText : "";

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

        // let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        // console.log("bin_files==>",bin_files)

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(), "company_id": parseInt(Store.getStoreData('curCompanyID')), "contact_id": parseInt(Store.getStoreData('contact_id')), "system_id": '1001',
            "cur_lane": "COMP_ATTESTATION_USERSAVE", "cur_assigned_to" : parseInt(Store.getStoreData('contact_id')), 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0,'due_date' : dueDateStr},
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(), "assigned_to": parseInt(Store.getStoreData('contact_id')), "cur_lane":"COMP_ATTESTATION_USERSAVE","performed_by": parseInt(Store.getStoreData('contact_id')) }
        };
        //console.log("task jason", task_json);
        let postData = {command: "create_attestation_task", task_json: task_json,bin_files: bin_files, submit: submit_form, isClickShareButton: 1 };
        // Store.updateStore('prve_task_json', task_json)
        Store.updateStore('prve_bin_files', bin_files)
        console.log("postData",postData)
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processSubmitAttestationData);
    }
    processSubmitAttestationData = (result) => {
        console.log("result", result);
        Store.updateStore('cur_task_data', result.parenttask);
        this.setState({showShareAttestationModal: true, taskid: result.task_id, ready:true})
    }

    updateShareAttestation = (submit_form) => (event) => {
        event.preventDefault();
        if(this.state.at_type === 'QuestionSet') {
            this.updateShareQSetAttestation(submit_form)
            return
        }
       let moduleconfig = Store.getStoreData('module_config');
        console.log("taskid", this.state.taskid)
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)
        let problems = false;
        let message = '';
        let lp = Store.getStoreData('language_pack')
        if(bin_files.length === 0){
            //alert('An attachment is required for submission of this attestation.');
            problems = true;
            message = lp['attachment_required_popup_text'] === undefined ? 'An attachment is required for submission of this attestation.' : lp['attachment_required_popup_text'];
        }else if(this.state.documentName === ""){
            //alert('Please provide a document name.');
            problems = true;
            message = lp['no_document_name_warning'] === undefined ? 'Please provide a document name. ' : lp['no_document_name_warning'];
        }else if(this.state.documentVersion === ""){
            //alert('Please provide the document version.');
            problems = true;
            message = 'Please provide the document version.';
        }else if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === ""){
            //alert('Please assign to a user.');
            problems = true;
            message = 'Please assign to a user.';
        }
        if(problems) {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: lp['confirmation_to_no_document_name_warning'] === undefined ? 'Ok' : lp['confirmation_to_no_document_name_warning'], confirm: false,
            alertHandler: this.checkalertparam, stack: {submit_form: submit_form}}
            this.setState({alert_param: alert_param})
            return false
        }
        let lastChangeDate = parseInt(this.state.last_change_date.getMonth());
        let changecurrmonth = lastChangeDate + 1;
        var changeDateStr = changecurrmonth >= 10 ? this.state.due_date.getFullYear()+'/'+changecurrmonth+'/' : this.state.last_change_date.getFullYear()+'/0'+changecurrmonth+'/';
        changeDateStr += this.state.last_change_date.getDate() < 10 ? '0' + this.state.last_change_date.getDate() : this.state.last_change_date.getDate();

        let nextReviewDate = parseInt(this.state.next_review_date.getMonth());
        let reviewcurrmonth = nextReviewDate + 1;
        var reviewDateStr = reviewcurrmonth >= 10 ? this.state.next_review_date.getFullYear()+'/'+reviewcurrmonth+'/' : this.state.next_review_date.getFullYear()+'/0'+reviewcurrmonth+'/';
        reviewDateStr += this.state.next_review_date.getDate() < 10 ? '0' + this.state.next_review_date.getDate() : this.state.next_review_date.getDate();

        let assignedTo = "";
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable){
            assignedTo = this.state.assignedTo;
        }

        let descriptionText = Store.getStoreData('doc_description');
        if(descriptionText === null){
            descriptionText = this.state.description;
        }
        let description_value = btoa(window.encodeURIComponent(descriptionText));

        let headerinfo = {};
        headerinfo.document_name = this.state.documentName;
        headerinfo.document_version = this.state.documentVersion;
        headerinfo.description = description_value;
        headerinfo.last_change_date = changeDateStr;
        headerinfo.next_review_date = reviewDateStr;
        headerinfo.assignedTo = assignedTo;
        headerinfo.isNextReviewAssignTask = 0;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.is_configure_attestation_wording = this.state.is_configure_attestation_wording;
        final_object_data.additionalText = this.state.is_configure_attestation_wording ? this.state.additionalText : "";
        final_object_data.confirmationText = this.state.is_configure_attestation_wording ? this.state.confirmationText : "";

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

        bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(),
            'system_id' : '1001', 'cur_lane' : 'COMP_ATTESTATION_USERSAVE', 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0 },
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_USERSAVE"}
        };

        let postData = {command: "update_attestation_task", task_json: task_json,bin_files: bin_files, submit: submit_form,
        taskid: this.state.taskid, isClickShareButton: 1};
        console.log("postData",postData)
        Store.updateStore('prve_bin_files', bin_files)
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processUpdateShareAttestation);
    }

    submitShareQSetAttestation = (submit_form) => {
        console.log('in submitShareQSetAttestation submit_form, this.state', submit_form, this.state)
        let moduleconfig = Store.getStoreData('module_config');
        let error = false
        let error_msg = ''
        let lp = Store.getStoreData('language_pack')
        if(this.state.qset_index === null) {
            error = true
            error_msg += 'You must select a Question Set. '
        }
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === "") {
            error = true
            error_msg += 'You must assign the attestation to a user.'
        }
        if(error) {
            let alert_param = {
                            title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'],
                            confirm: false,
                            message: 'ERROR: ' + error_msg,
                            alertHandler: this.processSubmitShareQSetAttestation,
                            stack: null
                        }
            this.setState({alert_param})
            return
        }
        let change_date_str = moment.unix(parseInt(this.state.qset_header.updated_at)).format('YYYY/MM/DD')
        let review_date_str = moment.unix(this.state.next_review_date.getTime()/1000).format('YYYY/MM/DD')
        let due_date_str = moment.unix(this.state.due_date.getTime()/1000).format('YYYY/MM/DD')
        console.log('change_date_str, review_date_str', change_date_str, review_date_str)

        let headerinfo = {
                        document_name: this.state.qset_header.nick_name,
                        document_version: 'version' in this.state.qset ? this.state.qset.version : '1.0',
                        description: 'description' in this.state.qset_header ? this.state.qset_header.description : this.state.qset_header.nick_name,
                        last_change_date: change_date_str,
                        next_review_date: review_date_str,
                        assignedTo: this.state.assignedTo
                    }
        let qsetbinfilesIds = {}
        for(let sid in this.state.qset.sections) {
            for(let q of this.state.qset.sections[sid].questions) {
                if('binfiles_id' in q && q.binfiles_id !== 0 && 'qsetbinfilesArray' in q){
                    qsetbinfilesIds[q.binfiles_id] = q.qsetbinfilesArray;
                }
            }
        }

        let final_object_data = {
            headerinfo: headerinfo,
            qset: this.state.qset,
            qsetbinfilesIds: qsetbinfilesIds,
            qset_index: this.state.qset_index,
            qset_header:this.state.qset_header,
            at_type: 'QuestionSet',
            is_configure_attestation_wording: this.state.is_configure_attestation_wording,
            additionalText: this.state.is_configure_attestation_wording ? this.state.additionalText : "",
            confirmationText: this.state.is_configure_attestation_wording ? this.state.confirmationText : ""
        }

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_CREATE", "actiondate": Date.now(),
            "cur_assigned_to" : 0, 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0,'due_date' : due_date_str},
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_CREATE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_CREATE","performed_by": Store.getStoreData('contact_id') }
        };
        console.log("task jason", task_json);
        let postData = {command: "create_attestation_task", task_json: task_json, bin_files: [], submit: submit_form, isClickShareButton: 1 };
        console.log("postData",postData)
        Store.updateStore('prve_bin_files', [])
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processSubmitAttestationData);
    }

    updateShareQSetAttestation = (submit_form) => {
        let moduleconfig = Store.getStoreData('module_config');
        let error = false
        let error_msg = ''
        let lp = Store.getStoreData('language_pack')
        if(this.state.qset_index === null) {
            error = true
            error_msg += 'You must select a Question Set. '
        }
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === "") {
            error = true
            error_msg += 'You must assign the attestation to a user.'
        }
        if(error) {
            let alert_param = {
                            title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'],
                            confirm: false,
                            message: 'ERROR: ' + error_msg,
                            alertHandler: this.processSubmitShareQSetAttestation,
                            stack: null
                        }
            this.setState({alert_param})
            return
        }
        let change_date_str = moment.unix(parseInt(this.state.qset_header.updated_at)).format('YYYY/MM/DD')
        let review_date_str = moment.unix(this.state.next_review_date.getTime()/1000).format('YYYY/MM/DD')
        let due_date_str = moment.unix(this.state.due_date.getTime()/1000).format('YYYY/MM/DD')
        console.log('change_date_str, review_date_str', change_date_str, review_date_str)

        let assignedTo = "";
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable){
            assignedTo = this.state.assignedTo;
        }

        let headerinfo = {
                        document_name: this.state.qset_header.nick_name,
                        document_version: 'version' in this.state.qset ? this.state.qset.version : '1.0',
                        description: 'description' in this.state.qset_header ? this.state.qset_header.description : this.state.qset_header.nick_name,
                        last_change_date: change_date_str,
                        next_review_date: review_date_str,
                        assignedTo: assignedTo
                    }
        let final_object_data = {
            headerinfo: headerinfo,
            qset: this.state.qset,
            qset_index: this.state.qset_index,
            qset_header:this.state.qset_header,
            at_type: 'QuestionSet',
            is_configure_attestation_wording: this.state.is_configure_attestation_wording,
            additionalText: this.state.is_configure_attestation_wording ? this.state.additionalText : "",
            confirmationText: this.state.is_configure_attestation_wording ? this.state.confirmationText : ""
        }
        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(),
            'system_id' : '1001', 'cur_lane' : 'COMP_ATTESTATION_USERSAVE', 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0 },
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_USERSAVE"}
        };

        let postData = {command: "update_attestation_task", task_json: task_json,bin_files: [], submit: submit_form,
        taskid: this.state.taskid, isClickShareButton: 1};
        console.log("postData",postData)
        Store.updateStore('prve_bin_files', [])
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processUpdateShareAttestation);
    }

    updateQSetAttestation = (submit_form) => {
        let moduleconfig = Store.getStoreData('module_config');
        let error = false
        let error_msg = ''
        let lp = Store.getStoreData('language_pack')
        if(this.state.qset_index === null) {
            error = true
            error_msg += 'You must select a Question Set. '
        }
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable && this.state.assignedTo === "") {
            error = true
            error_msg += 'You must assign the attestation to a user.'
        }
        if(error) {
            let alert_param = {
                            title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'],
                            confirm: false,
                            message: 'ERROR: ' + error_msg,
                            alertHandler: this.processSubmitShareQSetAttestation,
                            stack: null
                        }
            this.setState({alert_param})
            return
        }
        let change_date_str = moment.unix(parseInt(this.state.qset_header.updated_at)).format('YYYY/MM/DD')
        let review_date_str = moment.unix(this.state.next_review_date.getTime()/1000).format('YYYY/MM/DD')
        let due_date_str = moment.unix(this.state.due_date.getTime()/1000).format('YYYY/MM/DD')
        console.log('change_date_str, review_date_str', change_date_str, review_date_str)

        let assignedTo = "";
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable){
            assignedTo = this.state.assignedTo;
        }

        let headerinfo = {
                        document_name: this.state.qset_header.nick_name,
                        document_version: 'version' in this.state.qset ? this.state.qset.version : '1.0',
                        description: 'description' in this.state.qset_header ? this.state.qset_header.description : this.state.qset_header.nick_name,
                        last_change_date: change_date_str,
                        next_review_date: review_date_str,
                        assignedTo: assignedTo
                    }
        let final_object_data = {
            headerinfo: headerinfo,
            qset: this.state.qset,
            qset_index: this.state.qset_index,
            qset_header:this.state.qset_header,
            at_type: 'QuestionSet',
            is_configure_attestation_wording: this.state.is_configure_attestation_wording,
            additionalText: this.state.is_configure_attestation_wording ? this.state.additionalText : "",
            confirmationText: this.state.is_configure_attestation_wording ? this.state.confirmationText : ""
        }

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(),
            'system_id' : '1001', 'cur_lane' : 'COMP_ATTESTATION_USERSAVE', 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0 },
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_USERSAVE"}
        };
        //console.log("task jason", JSON.stringify(task_json));
        let postData = {command: "update_attestation_task", task_json: task_json,bin_files: [], submit: submit_form,
        taskid: this.state.taskid};
        console.log("postData",postData)
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processUpdateAttestationData);
    }

    processUpdateShareAttestation = (result) => {
        console.log("result", result)
        Store.updateStore('cur_task_data', result.parenttask);
        this.setState({showShareAttestationModal: true, taskid: this.state.taskid, ready:true})
    }

    closeShareModal = (id) => {
        this.setState({
            showShareAttestationModal: false,
            taskid: id,
            rowindex: 0
        })
    }

    closeModalUpdateShare = (id) => {
        this.setState({
            showShareAttestationModal: false
        })
    }

    updateAttestation = (submit_form) => (event) => {
        event.preventDefault();
        let moduleconfig = Store.getStoreData('module_config');
        if(this.state.at_type === 'QuestionSet') {
            this.updateQSetAttestation(submit_form)
            return
        }
        console.log("taskid", this.state.taskid)
        let problems = false;
        let message = '';
        let lp = Store.getStoreData('language_pack')
        if(this.state.documentName === ""){
            //alert('Please provide a document name.');
            problems = true;
            message = lp['no_document_name_warning'] === undefined ? 'Please provide a document name. ' : lp['no_document_name_warning'];
        }else if(this.state.documentVersion === ""){
            //alert('Please provide the document version.');
            problems = true
            message ='Please provide the document version.';
        }
        if(problems) {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: lp['confirmation_to_no_document_name_warning'] === undefined ? 'Ok' : lp['confirmation_to_no_document_name_warning'], confirm: false,
            alertHandler: this.checkalertparam, stack: {submit_form: submit_form}}
            this.setState({alert_param: alert_param})
            return false
        }
        let lastChangeDate = parseInt(this.state.last_change_date.getMonth());
        let changecurrmonth = lastChangeDate + 1;
        var changeDateStr = changecurrmonth >= 10 ? this.state.due_date.getFullYear()+'/'+changecurrmonth+'/' : this.state.last_change_date.getFullYear()+'/0'+changecurrmonth+'/';
        changeDateStr += this.state.last_change_date.getDate() < 10 ? '0' + this.state.last_change_date.getDate() : this.state.last_change_date.getDate();

        let nextReviewDate = parseInt(this.state.next_review_date.getMonth());
        let reviewcurrmonth = nextReviewDate + 1;
        var reviewDateStr = reviewcurrmonth >= 10 ? this.state.next_review_date.getFullYear()+'/'+reviewcurrmonth+'/' : this.state.next_review_date.getFullYear()+'/0'+reviewcurrmonth+'/';
        reviewDateStr += this.state.next_review_date.getDate() < 10 ? '0' + this.state.next_review_date.getDate() : this.state.next_review_date.getDate();

        let assignedTo = "";
        if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable){
            assignedTo = this.state.assignedTo;
        }

        let descriptionText = Store.getStoreData('doc_description');
        if(descriptionText === null){
            descriptionText = this.state.description;
        }
        let description_value = btoa(window.encodeURIComponent(descriptionText));

        let headerinfo = {};
        headerinfo.document_name = this.state.documentName;
        headerinfo.document_version = this.state.documentVersion;
        headerinfo.description = description_value;
        headerinfo.last_change_date = changeDateStr;
        headerinfo.next_review_date = reviewDateStr;
        headerinfo.assignedTo = assignedTo;
        headerinfo.isNextReviewAssignTask = 0;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.is_configure_attestation_wording = this.state.is_configure_attestation_wording;
        final_object_data.additionalText = this.state.is_configure_attestation_wording ? this.state.additionalText : "";
        final_object_data.confirmationText = this.state.is_configure_attestation_wording ? this.state.confirmationText : "";

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)

        let task_json = {
            "task_data": { "module_id": "attest", "last_action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(),
            'system_id' : '1001', 'cur_lane' : 'COMP_ATTESTATION_USERSAVE', 'task_type' : 'COMP_ATTEST', 'parent_task_id' : 0 },
            "object_data": final_object_data,
            "action_data": { "action": "COMP_ATTESTATION_USERSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_ATTESTATION_USERSAVE"}
        };
            //console.log("task jason", JSON.stringify(task_json));
        let postData = {command: "update_attestation_task", task_json: task_json,bin_files: bin_files, submit: submit_form,
        taskid: this.state.taskid};
        console.log("postData",postData)
        this.setState({submit_form,is_disabled: true, isloadericon: true, is_submitting: true})
        let api = new APICall();
        api.command(postData, this.processUpdateAttestationData);
    }

    processUpdateAttestationData = (result) => {
        console.log("result", result)
        //alert('Policy Attestation has been saved but not submitted for creation yet. You may modify and create it later.');
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Policy Attestation has been saved but not submitted for creation yet. You may modify and create it later.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.okAfterComplete, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        /*if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = '/select/microservice/mstop/attest';
            }else if(Store.getStoreData('isReferenceDocmgmt') === true){
                window.location = '/select/microservice/mstop/docmgmt';
            }else{
                window.location = '/';
            }
        } else {
            this.props.closeModalUpdate();
        }
        //window.location.reload();*/
    }

    switchRadio = (event) => {
		event.preventDefault();
		let is_configure_attestation_wording = this.state.is_configure_attestation_wording ? false : true;
        let isReadable = is_configure_attestation_wording ? false : true
        let moduleconfig = Store.getStoreData('module_config');
        let additionalText = is_configure_attestation_wording ? this.state.additionalText : moduleconfig.general.configure_attestation_wording_details.additional_text;
        let confirmationText = is_configure_attestation_wording ? this.state.confirmationText : moduleconfig.general.configure_attestation_wording_details.confirmation_text;
		this.setState({is_configure_attestation_wording, isReadable, additionalText, confirmationText});
	}

    handleChangeAdditionalText = (evt) => {
		this.setState({[evt.target.name]: evt.target.value});
	}

	handleChangeConfirmationText = (evt) => {
		this.setState({[evt.target.name]: evt.target.value});
	}


    render()
    {
        let lp = Store.getStoreData('language_pack')
        let moduleconfig = Store.getStoreData('module_config');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }

        let disDocType = lp['document'] === undefined ? 'Document' : lp['document']
        let disQuesType = lp['question_set'] === undefined ? 'Question Set' : lp['question_set']

        let at_types = []
        if(moduleconfig.general.at_type === 'Document' || moduleconfig.general.at_type === 'All')at_types.push({value: 'Document', display: disDocType})
        if(moduleconfig.general.at_type === 'QuestionSet' || moduleconfig.general.at_type === 'All')at_types.push({value: 'QuestionSet', display: disQuesType})

        //console.log('AMAskAttest this.state', this.state)
        let role = Store.getStoreData('role')
        //console.log('AMAskAttest role', at_types)

        return (
            <div>
            {
                (() => {
                    if(this.state.showShareAttestationModal){
                        return(<div style={{position:'absolute', width:'100%', height:'800px'}}><InactiveOverlay style={{height: "215%"}}/>
                            <AMShareAttestationModal taskid={this.state.taskid} closeModal={this.closeShareModal} closeModalUpdate={this.closeModalUpdateShare}/></div>)
                    }
                })()
            }
            {
                (()=> {
                    if(this.state.isloadericon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{lp['record_new_attestation'] === undefined ? 'Record New Attestation' : lp['record_new_attestation']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{lp['complete_fields_message'] === undefined ? 'Complete the fields below to record a new attestation.' : lp['complete_fields_message']}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <MRModalLabel>{lp['attestation_type'] === undefined ? 'Attestation Type' : lp['attestation_type']}</MRModalLabel>
                        {
                            (() => {
                                if(at_types.length === 1){
                                    return <MRModalLabel style={{color: "#545454",fontSize: "14px"}}>{at_types[0].display}</MRModalLabel>
                                }else{
                                    return(
                                        <MRModalSelect name="at_type" onChange={this.handleChange} value={this.state.at_type}>
                                        {
                                            at_types.map((t, i) => {
                                                return <option key={i} value={t.value}>{t.display}</option>
                                            })
                                        }
                                        </MRModalSelect>
                                    )
                                }
                            })()
                        }

                        {
                            (() => {
                                if(this.state.at_type === 'Document') {
                                    return (
                                        <div>
                                            <MRModalLabel style={{marginBottom: 0}}>&nbsp;</MRModalLabel>
                                            <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                                                <MRModalLabel>{lp['upload_attachment'] === undefined ? 'Upload Attachment' : lp['upload_attachment']}</MRModalLabel>
                                                <ModalNameInputDiv>
                                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                                </ModalNameInputDiv>
                                            </div>
                                            <MRModalLabel>{lp['document_name'] === undefined ? 'Document Name' : lp['document_name']}</MRModalLabel>
                                            <MRModalInput type="text" name="documentName" onChange={this.handleChange} value={this.state.documentName} />
                                            <MRModalLabel>{lp['document_version'] === undefined ? 'Document Version' : lp['document_version']}</MRModalLabel>
                                            <MRModalInput type="text" name="documentVersion" onChange={this.handleChange} value={this.state.documentVersion} />
                                            <MRModalLabel>{lp['description'] === undefined ? 'Description' : lp['description']}</MRModalLabel>
                                            {/* <MRModalTextarea rows="6" name="description" value={this.state.description} onChange={this.handleChange} /> */}
                                            <HtmlEditorDocument content={this.state.description}/>
                                            <MRModalLabel>{lp['last_change_date'] === undefined ? 'Last Change Date' : lp['last_change_date']}</MRModalLabel>
                                            <CSLDateCover style={{display: "inline-block"}}>
                                                <DatePicker
                                                        onChange={this.onLastChangeDate}
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        locale={"en-GB"}
                                                        value={this.state.last_change_date}
                                                />
                                            </CSLDateCover>
                                        </div>
                                        )
                                } else {
                                    console.log('AddAttestation this.state', this.state)
                                    return (<div><SelectQSet
                                                qset_index={this.state.qset_index}
                                                orig_qset_index={this.state.orig_qset_index}
                                                qset={this.state.qset}
                                                orig_qset={this.state.orig_qset}
                                                qset_header={this.state.qset_header}
                                                orig_qset_header={this.state.orig_qset_header}
                                                saveQSet={this.saveQSet}
                                            />

                                            </div>)
                                }
                            })()
                        }
                        {
                            (() => {
                                if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable === true){
                                    return(<div>
                                        <div style={{display: "inline-block"}}>
                                        <MRModalLabel>{lp['next_review_date'] === undefined ? 'Next Review Date' : lp['next_review_date']}</MRModalLabel>
                                        <CSLDateCover style={{display: "inline-block"}}>
                                            <DatePicker
                                                    onChange={this.onNextReviewDate}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    locale={"en-GB"}
                                                    minDate={new Date()}
                                                    value={this.state.next_review_date}
                                            />
                                        </CSLDateCover>
                                        </div>
                                        <div style={{display: "inline-block",marginLeft: "20%"}}>
                                        <MRModalLabel>{lp['repeat_task_assigned_to'] === undefined ? 'Repeat Task Assigned To' : lp['repeat_task_assigned_to']}</MRModalLabel>
                                        <MRModalSelect name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo} style={{width: "284px"}} >
                                            <option value="">{lp['select_default_dropdown_option'] === undefined ? 'Select' : lp['select_default_dropdown_option']}</option>
                                            {
                                                this.state.updateReviewList.map((rc,index) => {
                                                    return(
                                                    <option key={index} value={rc.ID}>{rc.ContactName}</option>
                                                    );
                                                })
                                            }
                                        </MRModalSelect>
                                        </div>
                                    </div>)
                                }
                            })()
                        }
                        {
                            (() => {
                                if(moduleconfig.general.configure_attestation_wording_enable){
                                    return(
                                    <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginTop: "25px"}}>
                                        <GeneralLabel>{lp['configure_attestation_wording'] === undefined ? 'Configure Attestation wording' : lp['configure_attestation_wording']}</GeneralLabel>
                                        {
                                            (() => {
                                                if (this.state.is_configure_attestation_wording) {
                                                    return (
                                                        <RadioBlock>
                                                            <SelectedRadio>{lp['yes'] === undefined ? 'Yes' : lp['yes']}</SelectedRadio>
                                                            <UnselectedRadio onClick={this.switchRadio}>{lp['no'] === undefined ? 'No' : lp['no']}</UnselectedRadio>
                                                        </RadioBlock>
                                                    );
                                                } else {
                                                    return (
                                                        <RadioBlock>
                                                            <UnselectedRadio onClick={this.switchRadio}>{lp['yes'] === undefined ? 'Yes' : lp['yes']}</UnselectedRadio>
                                                            <SelectedRadio>{lp['no'] === undefined ? 'No' : lp['no']}</SelectedRadio>
                                                        </RadioBlock>
                                                    );
                                                }
                                            })()
                                        }
                                        <div>
                                            <MRModalLabel>{lp['additional_text'] === undefined ? 'Additional Text' : lp['additional_text']}</MRModalLabel>
                                            <MRModalInput type="text" name="additionalText" value={this.state.additionalText} onChange={this.handleChangeAdditionalText} readOnly={this.state.isReadable}/>
                                            <MRModalLabel>{lp['confirmation_text'] === undefined ? 'Confirmation text' : lp['confirmation_text']}</MRModalLabel>
                                            <MRModalTextarea rows="5" name="confirmationText" value={this.state.confirmationText} onChange={this.handleChangeConfirmationText} readOnly={this.state.isReadable}/>
                                        </div>

                                    </div>
                                    )
                                }
                            })()
                        }
                    </div>
                </MRModalBody>
                <MRModalFooter>
                {
                    (() => {
                        if(this.state.rowindex >= 0) {
                            return(
                            <div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn onClick={this.updateShareAttestation(0)}>{lp['submit_and_share'] === undefined ? 'Submit and Share' : lp['submit_and_share']}</MRModalSaveCloseBtn>
                                <MRModalSaveCloseBtn onClick={this.updateAttestation(0)}>{lp['save_close'] === undefined ? 'Save and Close' : lp['save_close']}</MRModalSaveCloseBtn>
                                <MRModalCancelBtn onClick={this.closeView}>{lp['cancel_button'] === undefined ? 'Cancel' : lp['cancel_button']}</MRModalCancelBtn>
                            </div>
                            )
                        }else{
                            return(
                            <div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn onClick={this.submitShareAttestation(0)}>{lp['submit_and_share'] === undefined ? 'Submit and Share' : lp['submit_and_share']}</MRModalSaveCloseBtn>
                                <MRModalSaveCloseBtn onClick={this.submitAttestation(0)}>{lp['save_close'] === undefined ? 'Save and Close' : lp['save_close']}</MRModalSaveCloseBtn>
                                <MRModalCancelBtn onClick={this.closeView}>{lp['cancel_button'] === undefined ? 'Cancel' : lp['cancel_button']}</MRModalCancelBtn>
                            </div>
                            )
                        }
                    })()
                }
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam={this.state.alert_param} />

            </div>
        );
    }
}

export default AMAskAttestationModal;
