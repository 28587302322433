import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPen} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents'
import HtmlEditor from '../Common/HtmlEditor';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';
import IndexLoader from '../../Common/IndexLoader.js';

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #333;
    font-weight: 600;
`;

const ModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #234062;
    font-family: 'Montserrat', sans-serif;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #f7f7f7;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:10px;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const ModeltopText = styled.div`
    display: grid;
    grid-gap: 26px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6ebd6;
    color: #f6ce76;
    background-color: #f6ebd6;
`;

const ModeltopRevertText = styled.div`
    display: grid;
    grid-gap: 5px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6d6d6;
    color: #e27575;
    background-color: #f6d6d6;
`;

const ModalContainertop = styled.div`
    display: grid;
    // grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 10px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;


const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor:pointer;
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7
    color: #ffffff;
    width: 130px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
`;

const SaveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;;
    color: #ffffff;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:pointer;
    `;

const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    // cursor: pointer;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;

class FaqRequestModal extends React.Component
{
    state = {
        faq_header_text: '',
        faq_answer: '',
        faq_query: '',
        faq_id: 0,
        original_faq_id: 0,
        binFiles: [],
        cur_files_json: null,
        tags:[],
        ref_id: '',
        editquestionmode:false,
        edittagmode: false,
        editanswermode: false,
        revert_command: '',
        revert_by: 0,
        is_edit: false,
        show_save: false,
        ready: false,
        expiry_date: new Date(),
        enabled_topics: false,
        topic: {},
        is_published: false,
        approved_by: 0,
        approved_date: '',
        is_update: false,
        alert_param: null,
        is_loading: false
    };

    constructor(props) {
        super(props);
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    closeModal = (event) => {
        event.preventDefault();
        // this.props.refreshParentCallback();
        this.props.closeModal();
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    SendforApprovalFAQ(){
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        // let bin_files = this.state.binFiles;
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let postData = { 
            "command": "send_approval_faq",
             faq_query: this.state.faq_query, 
             faq_answer: answer_summary,
             tags: selectedtags,
             faq_id: this.state.faq_id,
             binFiles : bin_files
         };

         console.log("postData", postData);

        let api = new APICall();
        api.indexCommand(postData, this.processsendApprovalFAQ);
        this.setState({is_loading: true})
    }

    processsendApprovalFAQ =(result) => {
        this.setState({is_loading: false})
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = "This FAQ has been sent to a manager for approval."
        let title = 'Success';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param})
    }

    saveFAQ() {
        // let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
            if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let postData = { 
            "command": "send_save_faq",
             faq_query: this.state.faq_query, 
             faq_answer: answer_summary,
             tags: selectedtags,
             faq_id: this.state.faq_id,
             binFiles : bin_files,
             revert_save: this.state.show_save
         };

         console.log("postData", postData);

        let api = new APICall();
        api.indexCommand(postData, this.processSaveFAQ);
        this.setState({is_loading: true})
    }

    processSaveFAQ = (result) => {
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        this.setState({is_loading: false})
        let msg = "Your FAQ has been saved."
        let title = 'Success';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param})
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    isBase64(str) {
        if (str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    processFAQData = (result) => {
        let moduleconfig = Store.getStoreData('moduleconfig');
        let task_json = JSON.parse(result.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let {faq_header_text,faq_answer, faq_query, faq_id,original_faq_id, binFiles, cur_files_json, tags, ref_id, revert_command,revert_by,is_edit,show_save, expiry_date,enabled_topics,topic, is_update} = this.state;
        
        let flag = Utils.isBase64(task_json.object_data.answer);
        faq_answer = task_json.object_data.answer;
        if(flag){
            faq_answer = window.decodeURIComponent(atob(task_json.object_data.answer))
        }
        faq_query = task_json.object_data.query;
        faq_header_text = task_json.object_data.query;
        faq_id = result.id;
        original_faq_id = ('original_faq_id' in task_json.object_data) ? task_json.object_data.original_faq_id : 0;
        cur_files_json = task_json.object_data.bin_files;
        binFiles = task_json.object_data.bin_files;
        // ref_id = task_json.object_data.ref_id;
        ref_id = ('faq_ref_id' in task_json.object_data) ? task_json.object_data.faq_ref_id : "";
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        let ntags = this.isLoadTags(ptags);
        is_edit = (result.cur_lane === 'ADD_CAR_FAQ' || result.cur_lane === 'COMP_CAR_FAQ_REVERT') ? true : false;
        show_save = result.cur_lane === 'COMP_CAR_FAQ_REVERT' ? true : false;
        expiry_date = ('expiry_date' in task_json.object_data) ? new Date(task_json.object_data.expiry_date) : this.state.expiry_date;


        //console.log("task_json.action_data[task_json.action_data.length - 1]", task_json.action_data[task_json.action_data.length - 1]);
        revert_command = !task_json.action_data[task_json.action_data.length - 1].rever_reason ? "" : task_json.action_data[task_json.action_data.length - 1].rever_reason;
        revert_by = !task_json.action_data[task_json.action_data.length - 1].rever_reason ? 0 : task_json.action_data[task_json.action_data.length - 1].performed_by;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }
        let approved_by = ('published_by' in task_json.object_data) ? Store.getStoreData('contacts')[task_json.object_data.published_by] : '';

        let published_date = ('published_date' in task_json.object_data) ? task_json.object_data.published_date : this.state.expiry_date;
        var c_date = new Date(published_date);
        let dateStr = c_date.toLocaleDateString('en-GB').replace(/,/g, '');
        
        let is_published = result.cur_lane === "CAR_FAQ_APPROVED" ? true : false;

        if(Store.getStoreData('role') === 'admin_manager') {
            is_update = true;
        }

        this.setState({faq_header_text,faq_answer, faq_query, faq_id,original_faq_id, cur_files_json, tags:ntags, ref_id, revert_command, revert_by,is_edit,show_save, ready:true, expiry_date, enabled_topics,topic,is_published:is_published, approved_by: approved_by, approved_date: dateStr, is_update});
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }

    componentDidMount()
    {
        let cur_faq_modal_data = Store.getStoreData('cur_faq_modal_data');
        this.processFAQData(cur_faq_modal_data);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            let cur_faq_modal_data = Store.getStoreData('cur_faq_modal_data');
            this.processFAQData(cur_faq_modal_data); 
        }
        this.el.scrollIntoView({behavior: "smooth", block: "end"});
    }

    editQuestion = (event) => {
        event.preventDefault();
        let editquestionmode = this.state.editquestionmode ? false : true;
        this.setState({editquestionmode});

    }

    editTags = (event) => {
        event.preventDefault();
        let edittagmode = this.state.edittagmode ? false : true;
        this.setState({edittagmode});

    }

    editAnswer = (event) => {
        event.preventDefault();
        let editanswermode = this.state.editanswermode ? false : true;
        this.setState({editanswermode});
    }


    onExpireDateChange = (date) => {
        console.log("date", date);
        this.setState({ expiry_date: date })
    }


    UpdateFAQ(flag){
      let msg = flag === "delete" ? "Are you sure you want to delete this FAQ?" : "Are you sure you want to update this FAQ?";
      let title = 'Confirm';
      // alert("Mandatory Fields must be populated to progress");
      let parms = {};
      parms["flag"] = flag;
      let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: true, alertHandler: this.confirmAlertHandler, stack: {parms: parms}}
      this.setState({alert_param: alert_param})
    }


    confirmAlertHandler = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let parms = stack.parms;
        let answer = Store.getStoreData('answer');
        if(answer === null){
            answer = this.state.faq_answer;
        }
        let answer_summary = btoa(window.encodeURIComponent(answer));

        let bin_files = this.state.binFiles;
        let selectedtags = {};
        this.state.tags.forEach((tag) => {
         if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
        })
        let command = parms.flag === "delete" ? "send_faq_delete": "send_reapprove_faq";

        let dateobj = new Date(this.state.expiry_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
        expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

        let postData = { 
         "command": command,
          faq_query: this.state.faq_query, 
          faq_answer: answer_summary,
          tags: selectedtags,
          binFiles : bin_files,
          task_id: this.state.faq_id,
          faq_id: this.state.original_faq_id,
          'only_update': true
        };

        if(command === 'send_reapprove_faq') {
          postData['expiry_date'] = expiry_date;
        }

        // console.log("postData", postData);

        let api = new APICall();
        api.indexCommand(postData, this.processApprovalFAQ);
        this.setState({is_loading: true})

    }

    processApprovalFAQ =(result) => {
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        stack['mode'] = result.error_code === 0 ? result.mode : '';
        let msg = result.error_msg;
        let title = 'Alert';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param, is_loading: false})
        
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
        
    }



    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        console.log(this.state);
        return (
            <ModalContainer>
                {
                    (() => {
                        if(this.state.is_loading) {
                            return <CSLLoader style={{position: 'absolute'}} />
                        }
                    })()
                }
                <ModalHeaderText>{this.state.ref_id} - {this.state.faq_header_text.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</ModalHeaderText>
                <ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <ModalBody>
                {
                    (() => {
                        if(this.state.is_published) {
                            return <ModeltopText style={{fontWeight:"600",color: "#f6ce76"}}>This FAQ was submitted and published by {this.state.approved_by} on {this.state.approved_date}  </ModeltopText>
                        }
                    })()
                }
                    
                    <ModalBodyInner>
                    {
                        (() => {
                            if(this.state.is_edit){
                                if(this.state.revert_command === "") {
                                    return(<ModeltopText style={{fontWeight:"600",color: "#f6ce76;"}}>Adding advise request as an FAQ will require approval. Please review and submit when it is ready for approval</ModeltopText>)
                                }else{
                                    return(
                                        <ModeltopRevertText>
                                            <div style={{fontWeight:"600",color: "#f6d6d6;"}}>{ Store.getStoreData('contacts')[this.state.revert_by]} reviewed your FAQ submission and has reverted it back with the following comments.</div>
                                            <div style={{clear: "both"}}></div>
                                            <div style={{fontWeight:"400",color: "#f6d6d6;"}}>{this.state.revert_command.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</div>
                                        </ModeltopRevertText>
                                        )
                                }
                            }
                        })()
                    }
                        
                        <div style={{clear: "both"}}></div>
                        <ModalContainertop>
                            <ModalNameLabelDiv>Question 
                            {
                                (() => {
                                    if(this.state.is_edit || this.state.is_update) {
                                        return(<span><FaPen onClick={this.editQuestion}/></span>)
                                    }
                                })()
                            }
                            </ModalNameLabelDiv>
                            <ModalNameInputDiv>
                            {
                                (() => {
                                    if(this.state.editquestionmode){
                                        return(<ModalTextArea name="faq_query" defaultValue={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />)
                                    }else{
                                       return(<Style.ModalTextAreaReadonly defaultValue={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly />)
                                    }
                                })()
                            }
                            </ModalNameInputDiv>
                        </ModalContainertop>
                        <div style={{clear: "both"}}></div>

                            <ModalContainertop>
                                <ModalNameLabelDiv>Answer 
                                {
                                    (() => {
                                        if(this.state.is_edit || this.state.is_update) {
                                            return(<span><FaPen onClick={this.editAnswer}/></span>)
                                        }
                                    })()
                                }
                                </ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                    {
                                        (() => {
                                            if(this.state.editanswermode) {
                                                return <HtmlEditor style={{width: '98%', padding: "5px", color: "#282828"}} content={this.state.faq_answer} />;
                                            } else {
                                                return (<div>
                                                            <div dangerouslySetInnerHTML={{__html: this.state.faq_answer}} style={{textAlign: "justify", padding: "10px", color: "#282828"}}></div>
                                                        </div> ) 
                                            }
                                        })()
                                    }
                                </ModalNameInputDiv>
                                <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />*/}
                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />
                                </Style.ModalNameInputDiv>
                        </ModalContainertop>
                        <div style={{clear: "both"}}></div>
                        <ModalContainertop>
                            <ModalNameLabelDiv>Tags 
                            {
                                (() => {
                                    if(this.state.is_edit || this.state.is_update) {
                                        return(<span><FaPen onClick={this.editTags}/></span>)
                                    }
                                })()
                            }
                            </ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                {
                                    this.state.tags.map((tag, index) => {
                                        if(this.state.edittagmode){
                                            return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                        }else {
                                            if(tag.selected){
                                                return (<tag.styledcomponent key={index} >{tag.name}</tag.styledcomponent>);
                                            }
                                        }
                                        
                                    })
                                }
                            </ModalNameInputDiv>
                            <div style={{clear: "both"}}></div>
                            {
                                (() => {
                                    if(this.state.enabled_topics) {
                                        return (
                                            <div style={{display:"contents"}}>
                                                <ModalNameLabelDiv>Topic</ModalNameLabelDiv>
                                                    <ModalNameInputDiv>
                                                        {this.state.topic.value}
                                                    </ModalNameInputDiv>
                                            </div>
                                            )
                                    }

                                })()
                            }
                        </ModalContainertop>

                        {
                            (() => {
                                if(this.state.is_update) {
                                    return(<ModalContainertop>
                                            <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                            <CSLDateCover>
                                                <DatePicker
                                                        onChange={this.onExpireDateChange}
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        locale={"en-GB"}
                                                        value={this.state.expiry_date}
                                                        />
                                                        </CSLDateCover>
                                            </ModalContainertop>
                                        )
                                }
                            })()
                        }


                    </ModalBodyInner>
                </ModalBody>
                <CancelSubmitButtonOuterContainer>
                    <CancelButton onClick={this.closeModal}>Close</CancelButton>
                    {
                        (() => {
                            if(this.state.is_edit) {
                                return(
                                    <div style={{display:"inline"}}>
                                        <SaveButton onClick={() => this.saveFAQ()}>Save</SaveButton>
                                        <SubmitButton onClick={() => this.SendforApprovalFAQ()}>Send for approval</SubmitButton>
                                    </div>)
                            }

                        })()
                    }
                    {
                        (() => {
                            if(this.state.is_update) {
                                return(<div style={{display:"inline"}}>
                                    <SaveButton onClick={() => this.UpdateFAQ('update')}>Update</SaveButton>
                                    <SubmitButton onClick={() => this.UpdateFAQ('delete')}>Delete</SubmitButton>
                                </div>)
                            }

                        })()
                    }
                    
                </CancelSubmitButtonOuterContainer>
                <AlertBox alertParam = {this.state.alert_param} />
                <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>
            </ModalContainer>
        );
    }
}
export default FaqRequestModal;