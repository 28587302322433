import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import CSLLoader from '../Common/CSLLoader';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import EmailModal from './EmailModal';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
class ShowemailModal extends React.Component
{
	state = { ready:false, email_logs:[], task:null, modal_view:null }
	constructor(props) {
	    super(props);
	}
	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}

	processEmaillogs = (result) => {
		console.log("result", result);
		this.setState({ready: true, email_logs: result});
	}

	processTaskData = () => {
		let task_id = this.props.task_id;
		let email_count = this.props.email_count;
		let api = new APICall();
		let postData = {};
		postData['command'] = 'show_resent_emails';
		postData['task_id'] = task_id;
		postData['top'] = email_count;
		console.log("postData", postData)
		api.command(postData, this.processEmaillogs)
	}

	closeModal = (event) => {
	    event.preventDefault();
	    this.props.closeModal() 
	}

	refreshState = () => {
		// event.preventDefault();
		this.setState({modal_view: null, task: null});
	}

	showlogdetailsModal = (index) => (event) => {
		event.preventDefault();
		let task = this.state.email_logs[index];
		this.setState({task: task, modal_view: 'show'});
	}

	processEamilsData = () => {
	    let cardata = this.state.email_logs;
	    let ret = {data: [], columns: []};
	    ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
	                   {Header: 'Subject', accessor: 'subject', minWidth: 200, headerStyle: {textAlign: 'left'}},
	                   {Header: 'Sent Date', accessor: 'date', minWidth: 200, headerStyle: {textAlign: 'left'}},
	                   {Header: 'Recieipent Name', accessor: 'recieipent_name', minWidth: 200, headerStyle: {textAlign: 'left'}},
	                   {'Header' : 'Action', Cell: row => (
	      <div>
	          <MRButton onClick={this.showlogdetailsModal(row.index)}>Show</MRButton>
	      </div>
	    ), width: 200, headerStyle: {textAlign: 'left'}}];
	    let i = 0;
	    for(let d of cardata) {
	    	i++;
	        let data = JSON.parse(d.email_json);
	        let date = new Date(data.send_date)
	        let dateStr = date.toLocaleString('en-GB').replace(/,/g, '')
	        let subject = ('subject' in data) ? data.subject.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'") : "";
	        let elem = {'index' : i, 
	                'subject': subject,
	                'date' : dateStr,
	                'recieipent_name': 'recieipent_name' in data ? data.recieipent_name : Store.getStoreData('contacts')[data.sent_to.toString()],
	                'id' : d.id
	               };
	        ret.data.push(elem);
	    }
	    return ret;
	}
	render()
	{
		if(!this.state.ready) {
		    return(<CSLLoader />);
		}
		return (<Style.ModalContainer>
			    <Style.ModalHeaderText>Show Re-sent emails</Style.ModalHeaderText>
			    
			    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    <div style={{clear: "both"}}></div>
			    <Style.ModalBody>
			        <Style.ModalBodyInner>
			              <CSLTable add={false} processData={this.processEamilsData} headerText={'Show emails'} tableRows="5"/>
			              {
			                  (() => {
			                      if(this.state.modal_view !== null) {
			                          return <div style={{position:'absolute', left:'0%', top:'50px', width:'100%'}}><InactiveOverlay /><EmailModal refreshParent={this.refreshState} modalData = {this.state.task}/></div>;
			                      }
			                  })()
			              }
			        </Style.ModalBodyInner>
			    </Style.ModalBody>
			    <Style.CancelSubmitButtonOuterContainer>
			        <Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			        </Style.CancelSubmitButtonOuterContainer>
			    </Style.CancelSubmitButtonOuterContainer>
			</Style.ModalContainer>);
	}
}
export default ShowemailModal;