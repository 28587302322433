import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import Dropzone from '../Common/Dropzone';
import { FaTimes} from 'react-icons/fa';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import moment from 'moment';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    //height: auto;
    width: 45%;
    position: absolute;
    //top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 25%;
`;
const MRModalHeader = styled.div`
    background-color: #F6F9FD;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 90px;    
    width: calc(100% - 0px);
    border-width: 1px 1px 1px 10px;    
    //border-left: 9px solid #04ADA8; 
    //border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 30px;
    margin-left: 35px;  
    color: #1A3552;  
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #1a3552;
    font-weight: 600;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
    opacity: 0.58;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #758597;
    border: 1px solid #dadada;
    width: 94px;
    height: 43px;
    border-radius: 4px;
    font-size: 12px;
    float: right;
    cursor: pointer;
    font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
    font-weight: 500;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
    text-align: left;
    overflow-y: scroll;
    height: 555px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 20px;
    background-color: #e7f0f7;    
    margin-bottom: 25px;
    color: #537386; 
    border: 1px solid #c4cfd6; 
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyTextHeader = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyText = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 500;
    margin-top: 10px;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;   
`;

const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

class BMViewRemedialModal extends React.Component
{

    state = {task: null, payload: {}, task_id: null, comments: "",task_title:"", task_note: "", ready: false, send_mail_config: false,
    curlane: '',binFiles: [], cur_files_json: null, is_disabled: false, alert_param: null, isLoadedIcon: false, parentTaskID: 0, dueDateValue: '-'};

    GetPayloadData = (parent_task) => {
        let payload = {};
        let task_json = JSON.parse(parent_task.task_json);

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gcid = parseInt(parent_task.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(parent_task.company_id)%100000);
        let gcname = "";
        if(gcid in gc_assoc){
            gcname = gc_assoc[gcid];
        }

        let breachType_name = task_json.object_data.headerinfo.breach_type === 'conduct_rules_breach' ? 'Conduct Rules Breach' : 'Incident'
        let incidentType_name = '-';
        if(breachType_name === 'Incident'){
            incidentType_name = 'incidentType_name' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incidentType_name : "-"
        }

        const breachdate_obj = new Date(task_json.object_data.headerinfo.breach_date);
        const breachdate_day = breachdate_obj.getDate() < 10 ? "0"+breachdate_obj.getDate().toString() : breachdate_obj.getDate();
        const breachdate_month = breachdate_obj.getMonth()+1 < 10 ? `0${(breachdate_obj.getMonth() + 1).toString()}` : breachdate_obj.getMonth()+1;
        const breachdate_year = breachdate_obj.getFullYear();
        const breachdate_date = `${breachdate_day}/${breachdate_month}/${breachdate_year}`;

        const created_at_obj = new Date(parent_task.created_at);
        const created_at_day = created_at_obj.getDate() < 10 ? "0"+created_at_obj.getDate().toString() : created_at_obj.getDate();
        const created_at_month = created_at_obj.getMonth()+1 < 10 ? `0${(created_at_obj.getMonth() + 1).toString()}` : created_at_obj.getMonth()+1;
        const created_at_year = created_at_obj.getFullYear();
        const created_at_date = `${created_at_day}/${created_at_month}/${created_at_year}`;

        let allContacts = Store.getStoreData('contacts');
        let user_name = '';
        if(task_json.object_data.headerinfo.conduct_rule_user_id !== null){
            user_name = allContacts[task_json.object_data.headerinfo.conduct_rule_user_id]
        }

        let qtext = task_json.object_data.headerinfo.breach_deatils
        if(Utils.isBase64(task_json.object_data.headerinfo.breach_deatils)){
            qtext = window.decodeURIComponent(atob(qtext))
        }

        let isinternal_external_incident_view = "-";
        let department_name = "-";
        if('isinternal_external_incident' in task_json.object_data.headerinfo){
            if(task_json.object_data.headerinfo.isinternal_external_incident === 0){
                isinternal_external_incident_view = "External";
            }else if(task_json.object_data.headerinfo.isinternal_external_incident === 1){
                isinternal_external_incident_view = "Internal";
                if(task_json.object_data.headerinfo.department_name !== ""){
                    department_name = task_json.object_data.headerinfo.department_name;
                }
            }
        }
        let com_paid_date_view = 'com_paid_date' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.com_paid_date !== '' ? task_json.object_data.headerinfo.com_paid_date : "-" : "-";

        if(com_paid_date_view !== '-'){
            const com_paid_obj = new Date(com_paid_date_view);
            const com_paid_day = com_paid_obj.getDate() < 10 ? "0"+com_paid_obj.getDate().toString() : com_paid_obj.getDate();
            const com_paid_month = com_paid_obj.getMonth()+1 < 10 ? `0${(com_paid_obj.getMonth() + 1).toString()}` : com_paid_obj.getMonth()+1;
            const com_paid_year = com_paid_obj.getFullYear();
            com_paid_date_view = `${com_paid_day}/${com_paid_month}/${com_paid_year}`;
        }

        let breach_cur_files_json = [];
        if('bin_files' in  task_json.object_data) {
            task_json.object_data.bin_files.forEach((item) => {
                if("type" in item && item.type === 'breach_resource'){
                    breach_cur_files_json.push(item);
                }
            });
        }

        let reasonforbreachlistObject = {};
        if('reason_for_Breach' in Store.getStoreData('module_config') && Store.getStoreData('module_config').reason_for_Breach.length !== 0){
            Store.getStoreData('module_config').reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    reasonforbreachlistObject[item.reasonforBreachID.toString()] = item.reasonforBreachName;
                }
            })
        }
        let rootCauseName = "-";
        if('reasonforbreach' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.reasonforbreach in reasonforbreachlistObject){
            rootCauseName = reasonforbreachlistObject[task_json.object_data.headerinfo.reasonforbreach];
        }

        let breachimpactlistObject = {};
        if('breach_impact' in Store.getStoreData('module_config') && Store.getStoreData('module_config').breach_impact.length !== 0){
            Store.getStoreData('module_config').breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    breachimpactlistObject[item.breachImpactID.toString()] = item.breachImpactName;
                }
            })
        }
        let incidentImpactName = "-";
        if('breachimpact' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.breachimpact in breachimpactlistObject){
            incidentImpactName = breachimpactlistObject[task_json.object_data.headerinfo.breachimpact];
        }

        payload.Ref_ID = task_json.object_data.ref_id;
        payload.groupcompanyName = gcname;
        payload.incident_title = 'incident_title' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incident_title : "";
        payload.breachType_name = breachType_name;
        payload.incidentType_name = incidentType_name;
        payload.breachdate_date = breachdate_date;
        payload.created_at = created_at_date;
        payload.closed_date = "-";
        payload.user_name = user_name;
        payload.breach_deatils = qtext;
        payload.isinternal_external_incident_view = isinternal_external_incident_view;
        payload.department_name = department_name;
        payload.impact_type_view = 'impact_type' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.impact_type !== '' ? task_json.object_data.headerinfo.impact_type : "-" : "-";
        payload.est_los_val = 'est_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_val !== '' ? task_json.object_data.headerinfo.est_los_val : "-" : "-";
        payload.act_los_val = 'act_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_val !== '' ? task_json.object_data.headerinfo.act_los_val : "-" : "-";
        payload.est_thi_val = 'est_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_val !== '' ? task_json.object_data.headerinfo.est_thi_val : "-" : "-";
        payload.act_thi_val = 'act_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_val !== '' ? task_json.object_data.headerinfo.act_thi_val : "-" : "-";
        payload.fin_impact_cal_method_view = 'fin_impact_cal_method' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fin_impact_cal_method !== '' ? task_json.object_data.headerinfo.fin_impact_cal_method : "-" : "-";
        payload.com_paid_date_view = com_paid_date_view
        payload.breach_cur_files_json = breach_cur_files_json
        payload.is_reportability = 'is_reportability' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.is_reportability === 0 ? "No" : "Yes" : "-";
        payload.rootCauseName = rootCauseName;
        payload.incidentImpactName = incidentImpactName;
        return payload;
    }

	componentDidMount()
	{
        let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
        let task_data = Store.getStoreData('followup_task');
        curlane = task_data.subtask.cur_lane;
        let followup_task = task_data.subtask;
        task_id = followup_task.id;
        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject
        task_note = task_json.object_data.note
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        }
        let task_command = "";
        if('commands' in task_json.object_data){
            task_command = task_json.object_data.commands;
        }
        let parent_task = task_data.parenttask;
        console.log("parent_task",followup_task)
        payload = this.GetPayloadData(parent_task);
        console.log("payload", task_data);
        let dueDate = moment(followup_task.due_date).unix();
        let dueDateValue = moment.unix(dueDate).format('DD/MM/YYYY')
        this.setState({task: task_json,payload,task_id,task_title,task_note,comments: task_command,curlane,cur_files_json: cur_files_json,parentTaskID: parent_task.id,dueDateValue, ready:true});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
            let task_data = Store.getStoreData('followup_task');
            curlane = task_data.subtask.cur_lane;
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject
            task_note = task_json.object_data.note
            let parent_task = task_data.parenttask;
            console.log("parent_task",parent_task)
            payload = this.GetPayloadData(parent_task);
            console.log("payload", task_data);
            let dueDate = moment(followup_task.due_date).unix();
            let dueDateValue = moment.unix(dueDate).format('DD/MM/YYYY')
            this.setState({task: task_json,payload,task_id,task_title,task_note,curlane,parentTaskID: parent_task.id, dueDateValue, ready:true});
		}
	}
    closeModal = (event) => {
        // if (document.referrer.endsWith("/tmview")) {
        //     window.location = '/';
        // } else {
        //     this.props.closeModal();
        // }
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_BREACH_URL;
            }else{
                this.props.closeTaskModal();
            }
        } else {
            this.props.closeModal();
        }
    }

    UpdateSubTask = (event) => {
        event.preventDefault();
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let task_json = this.state.task;
        //console.log(task_json);
        task_json['task_data'].last_action = 'COMP_BREACH_REMEDIAL_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_BREACH_REMEDIAL_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments;
        task_json['action_data'] = {action: "COMP_BREACH_REMEDIAL_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_REMEDIAL_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        let postData = {command: "update_breach_followup_task", task_json: task_json, task_id: this.state.task_id, parentTaskID: this.state.parentTaskID, bin_files: bin_files};
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.afterCompleted);
    }
    afterCompleted = (result) => {
        // console.log("Completed:", result);
        // alert('Remedial task completed successfully.');
        // window.location.reload(true);
        let alert_param = {title: 'Success', message: 'Remedial task completed successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateaftercomplete, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    updateaftercomplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    render()
    {
        let curAssignTo = Store.getStoreData('followup_task').subtask.cur_assigned_to;
        let curUserID = Store.getStoreData('contact_id');
        let role = Store.getStoreData('role');
        //console.log("task_data===>", role)
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
            <AlwaysScrollToView />
                <MRModalHeader>
                    {/* <MRModalHeaderText>{this.state.task_title}</MRModalHeaderText> */}
                    <MRModalHeaderText>Remedial Action</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <ScoreBodyContainer>
                    <MRModalBody style={{paddingLeft: "25px", paddingRight: "25px"}}>
                        <MRModalBodyInner>
                            <div style={{display: "inline-block", width: "100%"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Incident ID</BodyTextHeader>
                                    <BodyText>{this.state.payload.Ref_ID}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Company</BodyTextHeader>
                                    <BodyText>{this.state.payload.groupcompanyName}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block"}}>
                                    <BodyTextHeader>Incident Title</BodyTextHeader>
                                    <BodyText>{this.state.payload.incident_title}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Incident Category</BodyTextHeader>
                                    <BodyText>{this.state.payload.breachType_name}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Incident Type</BodyTextHeader>
                                    <BodyText>{this.state.payload.incidentType_name}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Incident Date</BodyTextHeader>
                                    <BodyText>{this.state.payload.breachdate_date}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Created Date</BodyTextHeader>
                                    <BodyText>{this.state.payload.created_at}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Closed Date</BodyTextHeader>
                                    <BodyText>{this.state.payload.closed_date}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>User</BodyTextHeader>
                                    <BodyText>{this.state.payload.user_name}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block"}}>
                                    <BodyTextHeader>Incident Details</BodyTextHeader>
                                    <div dangerouslySetInnerHTML={{__html: this.state.payload.breach_deatils}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Internal/External?</BodyTextHeader>
                                    <BodyText>{this.state.payload.isinternal_external_incident_view}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Department</BodyTextHeader>
                                    <BodyText>{this.state.payload.department_name}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Impact Type</BodyTextHeader>
                                    <BodyText>{this.state.payload.impact_type_view}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Estimated Loss</BodyTextHeader>
                                    <BodyText>{this.state.payload.est_los_val}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Actual Loss</BodyTextHeader>
                                    <BodyText>{this.state.payload.act_los_val}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Estimated 3rd Party Cost</BodyTextHeader>
                                    <BodyText>{this.state.payload.est_thi_val}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Actual 3rd Party Cost</BodyTextHeader>
                                    <BodyText>{this.state.payload.act_thi_val}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Financial Impact Calculation</BodyTextHeader>
                                    <BodyText>{this.state.payload.fin_impact_cal_method_view}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Compensation Paid</BodyTextHeader>
                                    <BodyText>{this.state.payload.com_paid_date_view}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "100%"}}>
                                    <BodyTextHeader>Attachments</BodyTextHeader>
                                    <BodyText><Dropzone onFilesAdded={this.filesLoaded_breach} initFiles={this.state.payload.breach_cur_files_json} addnew={false}/></BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>Reportable Incident</BodyTextHeader>
                                    <BodyText>{this.state.payload.is_reportability}</BodyText>
                                </div>
                                <div style={{display: "inline-block", width: "50%"}}>
                                    <BodyTextHeader>User</BodyTextHeader>
                                    <BodyText>No</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block"}}>
                                    <BodyTextHeader>Root Cause</BodyTextHeader>
                                    <BodyText>{this.state.payload.rootCauseName}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block"}}>
                                    <BodyTextHeader>Incident Impact</BodyTextHeader>
                                    <BodyText>{this.state.incidentImpactName}</BodyText>
                                </div>
                            </div>
                        </MRModalBodyInner>
                        <MRModalBodyInner>
                            <div style={{display: "inline-block", width: "100%"}}>
                                <div style={{display: "inline-block", width: "100%"}}>
                                    <BodyTextHeader>Subject</BodyTextHeader>
                                    <BodyText>{this.state.task_title}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block", width: "100%"}}>
                                    <BodyTextHeader>Due Date</BodyTextHeader>
                                    <BodyText>{this.state.dueDateValue}</BodyText>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                <div style={{display: "inline-block"}}>
                                    <BodyTextHeader>Description</BodyTextHeader>
                                    <div dangerouslySetInnerHTML={{__html: this.state.task_note}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                                </div>
                            </div>
                            {
                                (() => {
                                    if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                        return(
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "100%"}}>
                                                    <BodyTextHeader>Attachments</BodyTextHeader>
                                                    <BodyText><Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/></BodyText>
                                                </div>
                                            </div>)
                                    }
                                })()
                            }
                        </MRModalBodyInner>
                        {
                            (() => {
                                if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_REMEDIAL_COMPLETE"){
                                    return(<div>
                                            <MRModalLabel>Comments</MRModalLabel>
                                            <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                                            </div>)
                                    }else if((role === "admin_manager" || role === "manager" || role === "bm") && this.state.curlane !== "COMP_BREACH_REMEDIAL_COMPLETE"){
                                        return(<div>
                                            <MRModalLabel>Comments</MRModalLabel>
                                            <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                                            </div>)
                                    }else{
                                        return(<div>
                                            <MRModalLabel>Comments</MRModalLabel>
                                            <RegDismissalReason name="comments" value={this.state.comments} readOnly></RegDismissalReason>
                                            </div>)
                                    }
                            })()
                        }
                    </MRModalBody>
                </ScoreBodyContainer>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                        {
                            (() => {
                                if(curAssignTo === curUserID && this.state.curlane !== "COMP_BREACH_REMEDIAL_COMPLETE"){
                                    return(<MRModalSaveCloseBtn onClick={this.UpdateSubTask}>COMPLETE</MRModalSaveCloseBtn> )
                                }else if((role === "admin_manager" || role === "manager" || role === "bm") && this.state.curlane !== "COMP_BREACH_REMEDIAL_COMPLETE"){
                                    return(<MRModalSaveCloseBtn onClick={this.UpdateSubTask}>COMPLETE</MRModalSaveCloseBtn> )
                                }
                            })()
                        }
                        <MRModalCancelBtn onClick={this.closeModal}>CLOSE</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        )
    }

}
export default BMViewRemedialModal;
