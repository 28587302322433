import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import Dropzone from '../Common/Dropzone.js'
import { saveAs } from 'file-saver';
import * as Style from '../Common/StyledComponents'
import ClarificationMessage from '../CA/ClarificationMessage'
import Originalanswer from '../CA/Originalanswer'


class ShowAnsweredModal extends React.Component
{
    state = {
        company: '',
        query: '',
        answer: '',
        binFiles: [],
        cur_files_json: null,
        extbinFiles: [],
        ext_cur_files_json: null,
        accept_mode: null,
        index: -1,
        form_data: null,
        id:0,
        category_id: 0,
        ext_advice: false,
        ext_advice_txt: '',
        ext_advice_provider:'',
        ext_ref_number: '',
        ext_contact_name: '',
        accept_note: '',
        clarifications: [],
        answered_by: 0,
        ready: false,
        custom_fields: {},
        enabled_topics: false,
        topic: {}
    };

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        Store.deRegisterCallback('changeTaskaction', this.component_id);
    }


    closeModal = (event) => {
        event.preventDefault();
        // this.props.refreshParentCallback();
        this.props.closeModal();
    }


    closeModal_old = (event) => {
        event.preventDefault();
        // this.props.refreshParentCallback();
        let refer = Store.getStoreData('refer');
        if (document.referrer.endsWith("/tmview") && refer) {
            window.location = '/';
        } else {
            this.props.refreshParentCallback();
        }
    }

    handleDownload = (index) => {
        let cur_file = this.state.cur_files_json[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        //console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        //console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    componentDidMount()
    {
        this.processShowAnswerData();
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.processShowAnswerData();
        }

        this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

    }

    processShowAnswerData() {
        let moduleconfig = Store.getStoreData('moduleconfig');
        let index = Store.getStoreData('a_index') === null ? 0 : Store.getStoreData('a_index');
        let form_data = {};
        form_data = Store.getStoreData('cur_accept_modal_data');
        let task_json = JSON.parse(form_data.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }

        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });
        }


        let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0
        //console.log('form_data', form_data);

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;

        let custom_fields = this.state.custom_fields;
        custom_fields.enabled = false;
        custom_fields = ('custom_fields' in  task_json.object_data) ? task_json.object_data.custom_fields : custom_fields;

        let answer = ('answer' in task_json.object_data) ? task_json.object_data.answer : '';
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }

        this.setState({ company: gc_company, cur_files_json: cur_files_json, ext_cur_files_json: ext_cur_files_json,
                        index: index, form_data:form_data, query: task_json.object_data.query,
                        answer: answer,
                        answered_by: answered_by,
                        clarifications: ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [],
                        ext_advice: ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : '',
                        ext_advice_txt: ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '',
                        ext_advice_provider: ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : '',
                        ext_ref_number: ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '',
                        ext_contact_name: ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '',
                        id: form_data.id, ready: true, custom_fields: custom_fields, enabled_topics:enabled_topics, topic:topic });
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    filesLoaded = (jsonObj) => {
        let contact_id = Store.getStoreData('contact_id');
        let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
        let binFiles = this.state.binFiles;
        let file_exists = {};
        let curBinFiles = [];
        if(jsonObj.command === 'add'){
            for(let i=0; i < files_to_show.length; i++) {
                if(typeof files_to_show[i].uid !== "undefined"){
                    files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
                    if(!('user_id' in files_to_show[i])) {
                        files_to_show[i].user_id = contact_id;
                        files_to_show[i].upload_date = Date.now();
                    }
                    if(typeof file_exists[files_to_show[i].uid] === "undefined"){
                        binFiles.push(files_to_show[i]);
                        file_exists[files_to_show[i].name] = true;
                    }
                }
            }
            curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
            this.setState({binFiles: curBinFiles});
        }else{
            for(let i=0; i < binFiles.length; i++) {
                if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
                    binFiles.splice(i,1);
                }
            }
            this.setState({binFiles: binFiles});
        }
    }

    showCustomFields = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv style={{width: '93%'}}>
                    <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true}/>
                </Style.ModalNameInputDiv>
            </div>
        );
    }


    render()
    {
        // let contact = Store.getStoreData('contact');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let contacts = Store.getStoreData('contacts');
        let form_data = Store.getStoreData('cur_accept_modal_data');
        let title = "My Answered Advice Request"
        if(form_data.cur_lane === "COMP_CAR_ANSWER" || form_data.cur_lane === "COMP_CAR_ANSWER_SAVED" || form_data.cur_lane === "COMP_CAR_USER_CLARIFICATION") {
            title = "My Pending Advice Request"
        }
        let contact_id = form_data.contact_id;
        let contact = contacts[contact_id];
        let task_json = JSON.parse(form_data.task_json);
        let object_data = task_json.object_data;
        console.log('object_data', object_data, form_data);
        let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
        console.log('clarifications', clarifications);
        let clLength = clarifications.length
        let last_answer_utime = clarifications.length === 0 ? object_data.answer_date : clarifications[clLength -1].actiondate
        let last_answer_time_obj = new Date(last_answer_utime)
        let orig_answer = ('answer' in object_data && object_data.answer!=='') ? {actiondate: object_data.answer_date, note: object_data.answer, performed_by: object_data.answered_by } : {};
        clarifications.reverse()
        return (
            <Style.ModalContainer>
                <Style.ModalHeaderText>{title}</Style.ModalHeaderText>
                <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <Style.ModalBody>
                    <Style.ModalBodyInner>
                        <Style.ModalContainertop>
                                {
                                    (() => {
                                        if(Store.getStoreData('has_gc')) {
                                            return (<div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv><Style.ModalNameInputDiv>{this.state.company}</Style.ModalNameInputDiv></Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                        }
                                    })()
                                }
                            <Style.ModalNameLabelDiv>ID</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv><Style.ModalNameInputDiv>{task_json.object_data.ref_id}</Style.ModalNameInputDiv></Style.ModalNameInputDiv>

                            {
                                (() => {
                                    if(this.state.enabled_topics) {
                                        return (
                                            <div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Topic</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                        <Style.ModalNameInputDiv>{this.state.topic.value}</Style.ModalNameInputDiv>
                                                    </Style.ModalNameInputDiv>
                                            </div>
                                            )
                                    }

                                })()
                            }


                            <Style.ModalNameLabelDiv>Advice Request</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalNameInputDiv style={{whiteSpace:  "break-spaces"}}>{this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameInputDiv>
                            </Style.ModalNameInputDiv>
                            <Style.ModalNameLabelDiv>Existing Attachments</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Dropzone initFiles={this.state.ext_cur_files_json} addnew={false} />
                            </Style.ModalNameInputDiv>
                            <Style.ModalNameLabelDiv>Author</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalInput name="contact" defaultValue={contact} readOnly={true} style={{width:'98%'}} />
                            </Style.ModalNameInputDiv>
                        </Style.ModalContainertop>




                        {
                            (() => {
                                if(Object.keys(orig_answer).length > 0){
                                    return(<Style.ModalNameInputDiv>
                                            <Originalanswer cardata={form_data} clarification={orig_answer} />
                                            </Style.ModalNameInputDiv>)
                                }
                            })()
                        }
                        {
                            clarifications.map((c,i) => {
                                return (<ClarificationMessage cardata={form_data} clarification={c} />)
                            })
                        }
                        <div>
                        {/*{
                            clarifications.map((c,i) => {
                                if(i == 0)return (<Originalanswer cardata={form_data} clarification={orig_answer} />)
                            })

                        }*/}
                        </div>
                        <Style.ModalNameInputDiv>
                            <Style.ModalWiderDiv>
                                {
                                    (() => {
                                        if(this.state.custom_fields.enabled) {
                                            if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                    return item.name === "reference_number"
                                                })

                                                if(('value' in field) && field.value!=='') {
                                                    return this.showCustomFields(field, "custom_terminology_fields");
                                                }
                                            }
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if(this.state.custom_fields.enabled) {
                                            if (this.state.custom_fields.additional_fields.length !== 0) {
                                                let custom_html = [];
                                                this.state.custom_fields.additional_fields.forEach((item) => {
                                                    if(('value' in item) && item.value!=='') {
                                                        const field = this.showCustomFields(item, "additional_fields");
                                                        custom_html.push(field);
                                                    }
                                                })
                                                return custom_html;
                                            }
                                        }
                                    })()
                                }
                            </Style.ModalWiderDiv>
                        </Style.ModalNameInputDiv>
                        {
                            (() =>{
                                if (this.state.ext_advice){
                                    return(
                                        <Style.ModalNameInputDiv>
                                            <Style.ModalWiderDiv>
                                                <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                {
                                                    (() => {
                                                        if(this.state.ext_advice_provider != ""){
                                                        return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Advice Providers
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_advice_provider.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                    External advice required
                                                </Style.ModalWiderHeaderDiv>
                                                    <Style.ModalTextArea style={{width: '93.3%'}} value={this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>

                                                {
                                                    (() => {
                                                        if(this.state.ext_ref_number != ""){
                                                            return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Reference Number
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.ext_contact_name != ""){
                                                            return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Contact Name
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                 (() => {
                                                     if(this.state.ext_cur_files_json.length > 0) {
                                                         return(<div>
                                                                     <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                   Attachments
                                                                     </Style.ModalWiderHeaderDiv>
                                                                     <div style={{width: '93.3%'}}>
                                                                           <Dropzone initFiles={this.state.ext_cur_files_json} addnew={false} />
                                                                     </div>
                                                                 </div>
                                                             )
                                                     }
                                                 })()
                                                }
                                            </Style.ModalWiderDiv>
                                        </Style.ModalNameInputDiv>
                                    )
                                }
                            })()
                        }
                    </Style.ModalBodyInner>
                </Style.ModalBody>
                <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>
                <Style.CancelSubmitButtonOuterContainer>
                    <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                </Style.CancelSubmitButtonOuterContainer>
            </Style.ModalContainer>
        );
    }
}
export default ShowAnsweredModal;
