import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle,FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import {BiEdit} from 'react-icons/bi';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import Dropzone from './../Common/Dropzone';
import BMBreachOfRulesSection from './BMBreachOfRulesSection';
import BMConductRulesBreachSection from './BMConductRulesBreachSection';
import BMApprovalTypeAhead from '../Filter/BMApprovalTypeAhead';
import CSLTable from '../Common/CSLTable';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';
import APICall from '../../Common/APICall';
import BMAddFollowupTask from './BMAddFollowupTask';
import BMViewFollowupModal from './BMViewFollowupModal';
import BMViewRemedialModal from './BMViewRemedialModal';
import BMViewPreventativeModal from './BMViewPreventativeModal';
import CSLTextInput from './CSLTextInput';
import DatePicker from 'react-date-picker';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    //height: auto;
    width: 45%;
    position: absolute;
    //top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 25%;
`;
const MRModalHeader = styled.div`
    background-color: #F6F9FD;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 90px;    
    width: calc(100% - 0px);
    border-width: 1px 1px 1px 10px;    
    //border-left: 9px solid #04ADA8; 
    //border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 30px;
    margin-left: 35px;  
    color: #1A3552;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #1a3552;
    font-weight: 600;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
    opacity: 0.58;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #758597;
    border: 1px solid #dadada;
    width: 94px;
    height: 43px;
    border-radius: 4px;
    font-size: 12px;
    float: right;
    cursor: pointer;
    font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
    font-weight: 500;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    color: #758597;      
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #758597;   
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;
    padding-left: 5px;
    display:inline;
`;
const ModalNameLabelDivReference1 = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #212121;
    display:inline;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
    text-align: left;
    overflow-y: scroll;
    height: 555px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 20px;
    background-color: #e7f0f7;    
    margin-bottom: 25px;
    color: #537386; 
    border: 1px solid #c4cfd6; 
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyTextHeader = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyText = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 500;
    margin-top: 10px;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const OverviewInfoHeader = styled.div`
    padding: 10px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    //width: 96%;
    margin: 0 auto;
    margin-top: 30px;  
    border-top: 1px solid #d7dddf;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;  
`;

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class BMViewIncidentModal extends React.Component
{
    state = {
        ready: false,
        Ref_ID: '',
        groupcompanyName: "",
        incident_title: "",
        breachType_name: "",
        incidentType_name: "",
        breachdate_date: new Date(),
        created_at: new Date(),
        dateofDetection: new Date(),
        dateofDetection_view: "-",
        closed_date: "-",
        user_name: "",
        breach_deatils: "",
        isinternal_external_incident_view: "",
        department_name: "",
        impact_type_view: "",
        est_los_val: "",
        act_los_val: "",
        est_thi_val: "",
        act_thi_val: "",
        fin_impact_cal_method_view: "",
        com_paid_date_view: new Date(),
        breach_binFiles: [],
        breach_cur_files_json: null,
        finimp_binFiles: [],
        finimp_cur_files_json: null,
        evdence_binFiles: [],
        evdence_cur_files_json: null,
        disciplinaryAction_binFiles: [],
        disciplinaryAction_cur_files_json: null,
        is_reportability: "No",
        submittedBy_name: "",

        ischangesFollowup: false,
        cur_lane: null,
        isEditable: false,
        breachType: "breach_of_rules",
        conductRuleUserID: "",
        isreportable: 1,
        isinternal_external_incident: 2,
        regulatorID: "",
        department_id: "",
        third_party_details: "",
        breachDate: new Date(),
        reportedDate: new Date(),
        isdesable: false,
        assign_data: null,
        cur_gc_id:null,
        incident_types: [],
        incidentType_id: "",
        business_areas: [],
        impact_type: "",
        regulator_types: [],
        estimated_loss_curreny: "GBP",
        estimated_loss_value: "",
        actual_loss_curreny: "GBP",
        actual_loss_value: "",
        estimated_third_curreny: "GBP",
        estimated_third_value: "",
        actual_third_curreny: "GBP",
        actual_third_value: "",
        fin_impact_cal_method: "",
        com_paid_date: new Date(),
        rule_reference: "",
        istrading_error: 2,
        fpp_name: "",
        disciplinaryAction: "no",
        detailsofinvestigation: "",

        reasonforbreach: "",
        reasonforbreachlist: [],
        breachimpact: "",
        breachimpactlist: [],
        riskcategorylist: [],
        riskcategorylistnew: [],
        selectedRiskCategory: [],
        ownershipresolution: "",
        isclear: false,

        rootCauseName: "",
        incidentImpactName: "",
        resolutionOwnerName: "",
        riskCategoryName: "",

        followupTasksList: [],
        showFollowupModal: false,
        showfollowupcloseButton: true,
        review_list: [],

        showRemedialcloseButton: true,
        showRemedialModal: false,
        remedialTasksList: [],

        showPreventativecloseButton: true,
        showPreventativeModal: false,
        preventativeTasksList: [],
        isLoadedIcon: false,
        alert_param: null,
        taskid: null,
        removal_readonly: false,

        reported_date_view: "",
        regulator_view: "",
        rule_reference_view: "",
        trading_error_view: "",
        fpp_name_view: "",
        action_required_view: "",
        investigation_details_view: "",
        showModal: null,
        conductRuleUserLists: [],
        isFinancialSectionShow: false
    };
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        let form_data = Store.getStoreData('cur_q_modal_data');
        let task_json = JSON.parse(form_data.task_json);

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gcid = parseInt(form_data.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(form_data.company_id)%100000);
        let gcname = "";
        if(gcid in gc_assoc){
            gcname = gc_assoc[gcid];
        }

        let incident_title = 'incident_title' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incident_title : "";
        let breachType_name = task_json.object_data.headerinfo.breach_type === 'conduct_rules_breach' ? 'Conduct Rules Breach' : 'Incident'
        let incidentType_name = '-';
        if(breachType_name === 'Incident'){
            incidentType_name = 'incidentType_name' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incidentType_name : "-"
        }

        const breachdate_obj = new Date(task_json.object_data.headerinfo.breach_date);
        const breachdate_day = breachdate_obj.getDate() < 10 ? "0"+breachdate_obj.getDate().toString() : breachdate_obj.getDate();
        const breachdate_month = breachdate_obj.getMonth()+1 < 10 ? `0${(breachdate_obj.getMonth() + 1).toString()}` : breachdate_obj.getMonth()+1;
        const breachdate_year = breachdate_obj.getFullYear();
        const breachdate_date = `${breachdate_day}/${breachdate_month}/${breachdate_year}`;

        const created_at_obj = new Date(form_data.created_at);
        const created_at_day = created_at_obj.getDate() < 10 ? "0"+created_at_obj.getDate().toString() : created_at_obj.getDate();
        const created_at_month = created_at_obj.getMonth()+1 < 10 ? `0${(created_at_obj.getMonth() + 1).toString()}` : created_at_obj.getMonth()+1;
        const created_at_year = created_at_obj.getFullYear();
        const created_at_date = `${created_at_day}/${created_at_month}/${created_at_year}`;

        let dateofDetection_view = 'dateofDetection' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.dateofDetection) : "-";
        if(dateofDetection_view !== '-'){
            const dateofDetection_view_obj = new Date(dateofDetection_view)
            const dateofDetection_view_day = dateofDetection_view_obj.getDate() < 10 ? "0"+dateofDetection_view_obj.getDate().toString() : dateofDetection_view_obj.getDate();
            const dateofDetection_view_month = dateofDetection_view_obj.getMonth()+1 < 10 ? `0${(dateofDetection_view_obj.getMonth() + 1).toString()}` : dateofDetection_view_obj.getMonth()+1;
            const dateofDetection_view_year = dateofDetection_view_obj.getFullYear();
            dateofDetection_view = `${dateofDetection_view_day}/${dateofDetection_view_month}/${dateofDetection_view_year}`;
        }


        console.log("contacts==>", Store.getStoreData('contacts'))

        let allContacts = Store.getStoreData('contacts');
        let user_name = '';
        if(task_json.object_data.headerinfo.conduct_rule_user_id !== null){
            user_name = allContacts[task_json.object_data.headerinfo.conduct_rule_user_id]
        }

        let submittedBy_name = allContacts[form_data.contact_id]

        let qtext = task_json.object_data.headerinfo.breach_deatils
        if(Utils.isBase64(task_json.object_data.headerinfo.breach_deatils)){
            qtext = window.decodeURIComponent(atob(qtext))
        }

        let isinternal_external_incident_view = "-";
        let department_name = "-";
        let isinternal_external_incident = 2;
        if('isinternal_external_incident' in task_json.object_data.headerinfo){
            isinternal_external_incident = task_json.object_data.headerinfo.isinternal_external_incident;
            if(task_json.object_data.headerinfo.isinternal_external_incident === 0){
                isinternal_external_incident_view = "External";
            }else if(task_json.object_data.headerinfo.isinternal_external_incident === 1){
                isinternal_external_incident_view = "Internal";
                if(task_json.object_data.headerinfo.department_name !== ""){
                    department_name = task_json.object_data.headerinfo.department_name;
                }
            }
        }

        let impact_type_view = 'impact_type' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.impact_type !== '' ? task_json.object_data.headerinfo.impact_type : "-" : "-";
        let est_los_val = 'est_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_val !== '' ? task_json.object_data.headerinfo.est_los_val : "-" : "-";
        let act_los_val = 'act_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_val !== '' ? task_json.object_data.headerinfo.act_los_val : "-" : "-";
        let est_thi_val = 'est_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_val !== '' ? task_json.object_data.headerinfo.est_thi_val : "-" : "-";
        let act_thi_val = 'act_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_val !== '' ? task_json.object_data.headerinfo.act_thi_val : "-" : "-";
        let fin_impact_cal_method_view = 'fin_impact_cal_method' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fin_impact_cal_method !== '' ? task_json.object_data.headerinfo.fin_impact_cal_method : "-" : "-";
        let com_paid_date_view = 'com_paid_date' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.com_paid_date !== '' ? task_json.object_data.headerinfo.com_paid_date : "-" : "-";

        if(com_paid_date_view !== '-'){
            const com_paid_obj = new Date(com_paid_date_view);
            const com_paid_day = com_paid_obj.getDate() < 10 ? "0"+com_paid_obj.getDate().toString() : com_paid_obj.getDate();
            const com_paid_month = com_paid_obj.getMonth()+1 < 10 ? `0${(com_paid_obj.getMonth() + 1).toString()}` : com_paid_obj.getMonth()+1;
            const com_paid_year = com_paid_obj.getFullYear();
            com_paid_date_view = `${com_paid_day}/${com_paid_month}/${com_paid_year}`;
        }

        let breach_cur_files_json = []
        let finimp_cur_files_json = []
        let evdence_cur_files_json = []
        let disciplinaryAction_cur_files_json = []

        if('bin_files' in  task_json.object_data) {
            task_json.object_data.bin_files.forEach((item) => {
                if("type" in item && item.type === 'breach_resource'){
                    breach_cur_files_json.push(item);
                }else if("type" in item && item.type === 'evidence_resource'){
                    evdence_cur_files_json.push(item);
                }else if("type" in item && item.type === 'finimp_resource'){
                    finimp_cur_files_json.push(item);
                }else{
                    disciplinaryAction_cur_files_json.push(item);
                }
            });
        }

        let is_reportability = 'is_reportability' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.is_reportability === 0 ? "No" : "Yes" : "-";

        let department_id = 'department_id' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.department_id : "";
        let third_party_details = 'third_party_details' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.third_party_details : "";

        let qtext_third_party_details = third_party_details;
        if(Utils.isBase64(third_party_details)){
            qtext_third_party_details = window.decodeURIComponent(atob(qtext_third_party_details))
        }

        let breach_date = new Date(task_json.object_data.headerinfo.breach_date);
        let dateofDetection = 'dateofDetection' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.dateofDetection) : new Date();
        let reported_date = new Date(task_json.object_data.headerinfo.reported_date);

        let module_config = Store.getStoreData('module_config');

        let assign_data = {}
        if(form_data.cur_lane === 'COMP_BREACH_COMPLETE'){
            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
        }else{
            let addi_fld = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
            let addi_fld_object = {};
            for(let ad of addi_fld){
                addi_fld_object[ad.name] = ad
            }
            let additional_fields = [];
            if(Object.keys(module_config).length > 0) {
                if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                    module_config.custom_fields.additional_fields.forEach((item) => {
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                        //if('displayin' in item && item.displayin === 'Manager Screen Only'){
                            additional_fields.push(copy_item);
                        //}
                    })
                }
            }
            let update_add_fields = [];
            let notinCurrentModuleConfig = {};
            for(let d of additional_fields){
                if(d.name in addi_fld_object){
                    notinCurrentModuleConfig[d.name] = 1;
                    d.value = addi_fld_object[d.name].value;
                    update_add_fields.push(d)
                }
                if(!(d.name in addi_fld_object)){
                    notinCurrentModuleConfig[d.name] = 1;
                    if('displayin' in d && d.displayin === 'Manager Screen Only'){
                        if('type' in d && d.type === 'Dropdown'){
                            d.value = "";
                            update_add_fields.push(d)
                        }else{
                            update_add_fields.push(d)
                        }
                    }
                }
            }

            for(let adv of addi_fld){
                if(!(adv.name in notinCurrentModuleConfig)){
                    update_add_fields.push(adv);
                }
            }
            console.log("update_add_fields===>", update_add_fields)

            assign_data.additional_fields = update_add_fields;
        }

        let curgc_id = task_json.task_data.group_company_id;
        let sampleNumber = curgc_id;
        let lastDigitcurgc_id = sampleNumber % 100000;

        let incident_types = [];
        if('incident_types' in Store.getStoreData('module_config')){
            incident_types = Store.getStoreData('module_config').incident_types;
        }
        let update_incident_types = []
        for(let k of incident_types){
            if(k.isActive === 1){
                update_incident_types.push(k);
            }
        }
        let business_areas = [];
        if('business_areas' in Store.getStoreData('module_config')){
            business_areas = Store.getStoreData('module_config').business_areas;
        }
        let update_business_areas = [];
        for(let k of business_areas){
            if(k.isActive === 1){
                update_business_areas.push(k);
            }
        }
        update_business_areas = update_business_areas.sort(function(a,b) {return (a.businessAreaName > b.businessAreaName) ? 1 : ((b.businessAreaName > a.businessAreaName) ? -1 : 0);} );

        let regulator_types = [];
        if('regulator_types' in Store.getStoreData('module_config')){
            regulator_types = Store.getStoreData('module_config').regulator_types;
        }
        let update_regulator_types = [];
        let regulator_types_object = {};
        for(let k of regulator_types){
            if(k.isActive === 1){
                update_regulator_types.push(k);
                regulator_types_object[k.id.toString()] = k.ragulatorname;
            }
        }

        let incidentType_id = 'incidentType_id' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incidentType_id : "";

        let impact_type = 'impact_type' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.impact_type : "";
        let estimated_loss_curreny = 'est_los_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_cur : "GBP";
        let estimated_loss_value = 'est_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_val : "";
        let actual_loss_curreny = 'act_los_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_cur : "GBP";
        let actual_loss_value = 'act_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_val : "";
        let estimated_third_curreny = 'est_thi_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_cur : "GBP";
        let estimated_third_value = 'est_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_val : "";
        let actual_third_curreny = 'act_thi_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_cur : "GBP";
        let actual_third_value = 'act_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_val : "";
        let fin_impact_cal_method = 'fin_impact_cal_method' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fin_impact_cal_method : "";
        let com_paid_date = 'com_paid_date' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.com_paid_date !== "" ? new Date(task_json.object_data.headerinfo.com_paid_date) : "" : "";
        let rule_reference = 'rule_reference' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.rule_reference : "";
        let istrading_error = 'istrading_error' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.istrading_error : 2;
        let fpp_name = 'fpp_name' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fpp_name : "";

        let reasonforbreachlist = [];
        let reasonforbreachlistObject = {};
        if('reason_for_Breach' in Store.getStoreData('module_config') && Store.getStoreData('module_config').reason_for_Breach.length !== 0){
            Store.getStoreData('module_config').reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    reasonforbreachlist.push(item);
                    reasonforbreachlistObject[item.reasonforBreachID.toString()] = item.reasonforBreachName;
                }
            })
        }
        let reasonforbreach = ""
        if('reasonforbreach' in task_json.object_data.headerinfo){
            reasonforbreach = task_json.object_data.headerinfo.reasonforbreach
        }
        let rootCauseName = "-";
        if('reasonforbreach' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.reasonforbreach in reasonforbreachlistObject){
            rootCauseName = reasonforbreachlistObject[task_json.object_data.headerinfo.reasonforbreach];
        }

        let breachimpactlist = [];
        let breachimpactlistObject = {};
        if('breach_impact' in Store.getStoreData('module_config') && Store.getStoreData('module_config').breach_impact.length !== 0){
            Store.getStoreData('module_config').breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    breachimpactlist.push(item);
                    breachimpactlistObject[item.breachImpactID.toString()] = item.breachImpactName;
                }
            })
        }
        let breachimpact = ""
        if('breachimpact' in task_json.object_data.headerinfo){
            breachimpact = task_json.object_data.headerinfo.breachimpact
        }
        let incidentImpactName = "-";
        if('breachimpact' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.breachimpact in breachimpactlistObject){
            incidentImpactName = breachimpactlistObject[task_json.object_data.headerinfo.breachimpact];
        }

        let ownershipresolution = "";
        if('ownershipresolution' in task_json.object_data.headerinfo){
            ownershipresolution = task_json.object_data.headerinfo.ownershipresolution
        }

        let riskcategorylist = [];
        let riskcategorylistnew = [];
        if('risk_triggers' in Store.getStoreData('module_config') && Store.getStoreData('module_config').risk_triggers.length !== 0)
		Store.getStoreData('module_config').risk_triggers.forEach((item) => {
            if(item.isActive === 1){
                riskcategorylist.push({id: item.riskTriggerID, label: item.riskTriggerName});
                riskcategorylistnew.push(item);
            }
        })
        let selectedRiskCategory = []
        if('selectedRiskCategory' in task_json.object_data.headerinfo){
            selectedRiskCategory = task_json.object_data.headerinfo.selectedRiskCategory
        }

        let followupTasksList = []
        let remedialTasksList = []
        let preventativeTasksList = []
        let all_childTask = Store.getStoreData('cur_all_childtask_data');
        all_childTask.forEach((item) => {
            if(item.is_active === 1){
                if(item.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                    followupTasksList.push(item)
                }
                if(item.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                    remedialTasksList.push(item)
                }
                if(item.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                    preventativeTasksList.push(item)
                }
            }
        });

        let cur_gc_id = parseInt(lastDigitcurgc_id)
        let company_contacts = Store.getStoreData('contacts');
        let gc_company = Store.getStoreData('gc_company');
        let gc_users = [];
        let my_company = null;
        for(let gc of gc_company) {
            if(gc.id === cur_gc_id)my_company = gc;
        }
        console.log('review_list', my_company.users, "cur_gc_id==>", cur_gc_id)
        let com_roles = Store.getStoreData('userroles');

        if(Store.getStoreData('has_gc') === true){
            for(let id of Object.keys(my_company.users)) {
                if (com_roles[id.toString()] !== 'no_access' && id in company_contacts) {
                    gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
                }
            }
        }else{
            for(let k in company_contacts){
                gc_users.push({ID: k, ContactName: company_contacts[k.toString()]});
            }
        }
        console.log('gc_users', gc_users)

        //let module_config = Store.getStoreData('module_config');

        let removal_readonly = false
        // if(form_data.cur_lane === "COMP_BREACH_COMPLETE"){
        //     removal_readonly =  true;
        // }

        //new

        let reported_date_date = '-';
        let regulator_view = "-";
        let rule_reference_view = "-";
        let trading_error_view = "-"
        let fpp_name_view = "-"
        if(is_reportability === 'Yes'){
            const reported_date_obj = new Date(task_json.object_data.headerinfo.reported_date);
            const reported_date_day = reported_date_obj.getDate() < 10 ? "0"+reported_date_obj.getDate().toString() : reported_date_obj.getDate();
            const reported_date_month = reported_date_obj.getMonth()+1 < 10 ? `0${(reported_date_obj.getMonth() + 1).toString()}` : reported_date_obj.getMonth()+1;
            const reported_date_year = reported_date_obj.getFullYear();
            reported_date_date = `${reported_date_day}/${reported_date_month}/${reported_date_year}`;

            if('regulator_id' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.regulator_id in regulator_types_object){
                regulator_view = regulator_types_object[task_json.object_data.headerinfo.regulator_id];
            }
            if('rule_reference' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.rule_reference !== ''){
                rule_reference_view = task_json.object_data.headerinfo.rule_reference;
            }
            if('istrading_error' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.istrading_error === 1){
                trading_error_view = "Yes";
            }else if('istrading_error' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.istrading_error === 0){
                trading_error_view = "No";
            }
            if('fpp_name' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.fpp_name !== ''){
                fpp_name_view = task_json.object_data.headerinfo.fpp_name;
            }
        }

        let action_required_view = 'disciplinary_action' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.disciplinary_action === 'yes' ? "Yes" : "No" : "-";

        let investigation_details_view = '-';
        if(action_required_view === 'Yes'){
            if('details_of_investigation' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.details_of_investigation !== ''){
                investigation_details_view = task_json.object_data.headerinfo.details_of_investigation;
            }
        }

        //resolutionOwnerName start
        let resolution_owner_listObject = {};
        if('business_areas' in Store.getStoreData('module_config') && Store.getStoreData('module_config').business_areas.length !== 0){
			Store.getStoreData('module_config').business_areas.forEach((item) => {
				if(item.isActive === 1){
					resolution_owner_listObject[item.businessAreaID] = item.businessAreaName
				}
			})
        }
		let review_list_all = Store.getStoreData('reviewee_list');
		let user_roles = Store.getStoreData('userroles');
		for(let k of review_list_all){
			if(k.ID in user_roles && user_roles[k.ID.toString()] !== 'no_access'){
				resolution_owner_listObject[k.ContactName + '-' + k.ID] = k.ContactName;
			}
		}

        let resolutionOwnerName = "-";
        if('ownershipresolution' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.ownershipresolution in resolution_owner_listObject){
            resolutionOwnerName = resolution_owner_listObject[task_json.object_data.headerinfo.ownershipresolution];
        }
        //resolutionOwnerName end
        //riskCategoryName start
        let riskCategoryName = "-";
        let riskCategoryValue = "";
        if('selectedRiskCategory' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.selectedRiskCategory.length !== 0){
            let rskcate = task_json.object_data.headerinfo.selectedRiskCategory;
            let i=0;
            for(let d of rskcate){
                riskCategoryValue += d.label;
                if(i < rskcate.length - 1) {
                    riskCategoryValue += ', '
                }
                i++;
            }
        }
        if(riskCategoryValue !== ''){
            riskCategoryName = riskCategoryValue;
        }
        //riskCategoryName end
        //reviewlist start
        //reviewlist end

        Store.updateStore('incident_details', null)
        Store.updateStore('third_party_details', null)


        //new cusom
        let review_list = Store.getStoreData('reviewee_list');
        if(Store.getStoreData('gc_companies').length === 0){
            let review_list_all = Store.getStoreData('reviewee_list');
            let update_review_list = [];
            for(let k of review_list_all){
                if(k.ID in user_roles && user_roles[k.ID.toString()] !== 'no_access'){
                    update_review_list.push(k);
                }
            }
            review_list = update_review_list;
        }else{
            if(parseInt(lastDigitcurgc_id) === 0){
                review_list = [];
            }else{
                console.log("review_list222===>", lastDigitcurgc_id)
                let gc_company = Store.getStoreData('gc_company');
                let list_users = {};
                for(let gc of gc_company) {
                    if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                        list_users = gc.users;
                    }
                }
                let new_review = [];
                let review_list_update = Store.getStoreData('reviewee_list');
                review_list_update.forEach((user) => {
                    if(user.ID in list_users && user.ID in user_roles && user_roles[user.ID.toString()] === 'bm'){
                        new_review.push(user)
                    }
                    if(user.ID in list_users && user.ID in user_roles && user_roles[user.ID.toString()] === 'team'){
                        new_review.push(user)
                    }
                    if(user.ID in user_roles && user_roles[user.ID.toString()] === 'manager'){
                        new_review.push(user)
                    }
                })
                review_list = new_review;
            }
        }
        //new cusom

        reasonforbreachlist.sort(function(a,b) {return (a.reasonforBreachName > b.reasonforBreachName) ? 1 : ((b.reasonforBreachName > a.reasonforBreachName) ? -1 : 0);} );
        breachimpactlist.sort(function(a,b) {return (a.breachImpactName > b.breachImpactName) ? 1 : ((b.breachImpactName > a.breachImpactName) ? -1 : 0);} );
        update_business_areas.sort(function(a,b) {return (a.businessAreaName > b.businessAreaName) ? 1 : ((b.businessAreaName > a.businessAreaName) ? -1 : 0);} );

        let isFinancialSectionShow = false;
        if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
            isFinancialSectionShow = true;
        }
        if('financial_impact' in Store.getStoreData('module_config') && 'compensation_paid_date' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.compensation_paid_date === true){
            isFinancialSectionShow = true;
        }

        this.setState({
            Ref_ID: task_json.object_data.ref_id,
            groupcompanyName: gcname,
            incident_title,
            breachType_name,
            breachType: task_json.object_data.headerinfo.breach_type,
            incidentType_name,
            breachdate_date,
            created_at: created_at_date,
            dateofDetection_view,
            dateofDetection,
            user_name,
            breach_deatils: qtext,
            isinternal_external_incident_view,
            department_name,
            impact_type_view,
            est_los_val,
            act_los_val,
            est_thi_val,
            act_thi_val,
            fin_impact_cal_method_view,
            com_paid_date_view,
            breach_cur_files_json: breach_cur_files_json,
            finimp_cur_files_json: finimp_cur_files_json,
            is_reportability,
            submittedBy_name,
            cur_lane: form_data.cur_lane,
            conductRuleUserID: task_json.object_data.headerinfo.conduct_rule_user_id,
            isreportable: parseInt(task_json.object_data.headerinfo.is_reportability),
            isinternal_external_incident,
            regulatorID: parseInt(task_json.object_data.headerinfo.regulator_id),
            department_id, third_party_details: qtext_third_party_details,
            evdence_cur_files_json: evdence_cur_files_json,
            disciplinaryAction_cur_files_json : disciplinaryAction_cur_files_json, breachDate: breach_date,
            reportedDate: reported_date,
            assign_data: assign_data,cur_gc_id: lastDigitcurgc_id,
            incident_types: update_incident_types,incidentType_id: incidentType_id,business_areas: update_business_areas,
            impact_type,regulator_types: update_regulator_types,estimated_loss_curreny,
            estimated_loss_value,actual_loss_curreny,actual_loss_value,estimated_third_curreny,estimated_third_value,
            actual_third_curreny,actual_third_value,fin_impact_cal_method,com_paid_date,rule_reference,istrading_error,fpp_name,
            disciplinaryAction: task_json.object_data.headerinfo.disciplinary_action,
            detailsofinvestigation: task_json.object_data.headerinfo.details_of_investigation,
            reasonforbreachlist: reasonforbreachlist,
            reasonforbreach: reasonforbreach,
            breachimpactlist: breachimpactlist,
            breachimpact: breachimpact,
            ownershipresolution: ownershipresolution,
            riskcategorylist: riskcategorylist,
            riskcategorylistnew: riskcategorylistnew,
            selectedRiskCategory: selectedRiskCategory,

            review_list: gc_users,
            followupTasksList: followupTasksList,
            remedialTasksList: remedialTasksList,
            preventativeTasksList: preventativeTasksList,
            taskid: form_data.id,
            module_config: module_config,
            removal_readonly: removal_readonly,
            rootCauseName,
            incidentImpactName,
            resolutionOwnerName,
            riskCategoryName,

            reported_date_view: reported_date_date,
            regulator_view,
            rule_reference_view,
            trading_error_view,
            fpp_name_view,
            action_required_view,
            investigation_details_view,
            conductRuleUserLists: review_list,
            isFinancialSectionShow,
            ready: true
        })
    }

    filesLoaded_breach = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "breach_resource"
            }
        }
        this.setState({breach_binFiles: files});
    }

    filesLoaded_finimp = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "finimp_resource"
            }
        }
        this.setState({finimp_binFiles: files});
    }

    filesLoaded_evidence = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "evidence_resource"
            }
        }
        this.setState({evdence_binFiles: files});
    }

    filesLoaded_disciplinaryaction = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "disciplinary_resource"
            }

        }
        this.setState({disciplinaryAction_binFiles: files});
    }

    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_BREACH_URL;
            }else{
                this.props.closeTaskModal();
            }
        } else {
            if(this.state.ischangesFollowup){
                this.props.closeModalForApproval();
            }else{
                this.props.closeModalNormal();
            }
        }
    }

    editView = (event) => {
        event.preventDefault();
        this.setState({isEditable: true})
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleChangeReportable = (ischeckValue) =>{
        this.setState({isreportable: ischeckValue, regulatorID: ""})
    }
    handleChangeExIntIncd = (ischeckValue) =>{
        this.setState({isinternal_external_incident: ischeckValue, department_id: ""})
    }
    onChangeBreachDate = (date) => {
        this.setState({breachDate: date});
    }
    onChangeDateofDetection = (date) => {
        this.setState({dateofDetection: date});
    }
    onChangeReportedDate = (date) => {
        this.setState({reportedDate: date});
    }
    modifyAssignData = (obj) => {
        this.setState({assign_data: obj});
    }
    ChangePaidDate = (date) => {
        this.setState({com_paid_date: date});
    }
    handleChangeTradingError = (ischeckValue) =>{
        this.setState({istrading_error: ischeckValue})
    }
    handlenaSectionIncludedDis = (ischeckValue) =>{
        this.setState({disciplinaryAction: ischeckValue})
    }

    riskcategoryValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = [];
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                //filtertypeObject[selected_options[i].id] = selected_options[i].label;
                filtertypeObject.push(selected_options[i])
            }
        }
        console.log("ricipientObject",filtertypeObject)
        this.setState({selectedRiskCategory: filtertypeObject})
    }
    changeClear = () => {
		this.setState({isclear: false})
    }

    showAddRemedialTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showRemedialModal: true, showRemedialcloseButton: false});
    }
    showAddPreventativeTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showPreventativeModal: true, showPreventativecloseButton: false});
    }

    closePreventativeModal = () => {
        this.setState({ showPreventativeModal: false, showPreventativecloseButton: true });
    }

    preventativeTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_PREVENTATIVE_ASSIGNMENT", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_BREACH_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_BREACH_PREVENTATIVE_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_PREVENTATIVE_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid };
        console.log("task follow up", postData);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processAddTaskPreventative);
    }

    processAddTaskPreventative = (result) => {
        let alert_param = {title: 'Success', message: 'A Preventative Measures has been successfully added to this incident.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    updateprocessallchildtask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(command_data, this.updateFollowups);
    }

    updateFollowups = (result) => {
        Store.updateStore('cur_all_childtask_data', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        let followup = [];
        let remedial = [];
        let preventative = [];
        result.result.followuptasks.forEach((item) => {
            if(item.cur_lane === 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                followup.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_REMEDIAL_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                remedial.push(item)
            }
            if(item.cur_lane === 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' || item.cur_lane === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                preventative.push(item)
            }
        });
        this.setState({ showFollowupModal: false,showfollowupcloseButton: true,
            showRemedialModal: false, showRemedialcloseButton: true,
            showPreventativeModal: false, showPreventativecloseButton: true,
            followupTasksList: followup, remedialTasksList: remedial, preventativeTasksList: preventative, ischangesFollowup: true, isLoadedIcon: false });
    }

    closeRemedialModal = () => {
        this.setState({ showRemedialModal: false, showRemedialcloseButton: true });
    }

    remedialTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_REMEDIAL_ASSIGNMENT", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_BREACH_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_BREACH_REMEDIAL_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_BREACH_REMEDIAL_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_REMEDIAL_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid };
        console.log("task follow up", postData);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processAddTaskRemedial);
    }
    processAddTaskRemedial = (result) => {
        let alert_param = {title: 'Success', message: 'A Remedial Action has been successfully added to this incident.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //alert("A Remedial Action has been successfully added to this Breach Register.");
    }

    processMyFollowupTaskValue = () => {
        var taskdata = this.state.followupTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Task', accessor: 'task', minWidth: 80, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}},
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '80px',cursor: 'pointer'}} onClick={this.showFollowUp(row.original.id)}>View</MRButton>
                            </div>
                         ), width: 100, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'id' : d.id,
                'index' : i,
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal', isLoadedIcon: false});
    }

    processMyRemedialTaskValue = () => {
        var taskdata = this.state.remedialTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Task', accessor: 'task', minWidth: 80, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}},
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '80px',cursor: 'pointer'}} onClick={this.showRemedial(row.original.id)}>View</MRButton>
                            </div>
                         ), width: 100, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_REMEDIAL_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_REMEDIAL_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            //console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            //console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'id' : d.id,
                'index' : i,
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    showRemedial = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openRemedialModal);
    }
    openRemedialModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_remedial_modal', isLoadedIcon: false});
    }

    processMyPreventativeTaskValue = () => {
        var taskdata = this.state.preventativeTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Task', accessor: 'task', minWidth: 80, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 80, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}},
                        {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'center',fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '80px',cursor: 'pointer'}} onClick={this.showPreventative(row.original.id)}>View</MRButton>
                            </div>
                         ), width: 100, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_BREACH_PREVENTATIVE_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_BREACH_PREVENTATIVE_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let subject = taskdataobject.subject
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'id' : d.id,
                'index' : i,
                'assign_to' : assigntoName,
                'task' : subject,
                'due_date' : due_date,
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    showPreventative = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openpreventativeModal);
    }

    openpreventativeModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_preventative_modal', isLoadedIcon: false});
    }

    showAddFollowupTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showFollowupModal: true, showfollowupcloseButton: false});
    }

    closeFollowupModal = () => {
        this.setState({ showFollowupModal: false, showfollowupcloseButton: true });
    }

    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_FOLLOWUP_ASSIGNMENT", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_BREACH_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_BREACH_FOLLOWUP_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_BREACH_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_BREACH_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid };
        console.log("task follow up", postData);
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processAddTask);
    }

    processAddTask = (result) => {
        let alert_param = {title: 'Success', message: 'The follow-up task has been added to this incident.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    saveandcloseApproveBreach = (event) => {
        event.preventDefault();
        let task_json = JSON.parse(Store.getStoreData('cur_q_modal_data').task_json);
        //console.log("task_json",task_json)

        let incedentDetails = Store.getStoreData('incident_details');
        if(incedentDetails === null){
            incedentDetails = this.state.breach_deatils;
        }
        let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

        let thirdPartyDetails = Store.getStoreData('third_party_details');
        if(thirdPartyDetails === null){
            thirdPartyDetails = this.state.third_party_details;
        }
        let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

        let incidentType_name = this.state.incidentType_name;
        for(let d of this.state.incident_types){
            if(parseInt(d.incidentTypeID) === parseInt(this.state.incidentType_id)){
                incidentType_name = d.incidentTypeName;
            }
        }

        task_json['task_data'].last_action = 'COMP_BREACH_REGISTER_ACTION';
        task_json['task_data'].cur_lane = 'COMP_BREACH_REGISTER_ACTION';
        task_json['task_data'].actiondate = Date.now();

        task_json['object_data'].headerinfo.breach_type = this.state.breachType;
        task_json['object_data'].headerinfo.breach_deatils = incedentDetails_value;
        task_json['object_data'].headerinfo.breach_date = this.state.breachDate;
        task_json['object_data'].headerinfo.is_reportability = this.state.isreportable;
        task_json['object_data'].headerinfo.reported_date = this.state.reportedDate;
        task_json['object_data'].headerinfo.regulator_id = this.state.regulatorID;

        task_json['object_data'].headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID);
        task_json['object_data'].headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
        task_json['object_data'].headerinfo.disciplinary_action = this.state.disciplinaryAction;
        task_json['object_data'].headerinfo.details_of_investigation = this.state.detailsofinvestigation;
        //new
        task_json['object_data'].headerinfo.incident_title = this.state.incident_title;
        task_json['object_data'].headerinfo.incidentType_id = this.state.incidentType_id;
        task_json['object_data'].headerinfo.incidentType_name = incidentType_name;
        task_json['object_data'].headerinfo.isinternal_external_incident = this.state.isinternal_external_incident;
        task_json['object_data'].headerinfo.department_id = this.state.department_id;
        task_json['object_data'].headerinfo.department_name = this.state.department_name;
        task_json['object_data'].headerinfo.third_party_details = thirdPartyDetails_value;
        task_json['object_data'].headerinfo.impact_type = this.state.impact_type;
        task_json['object_data'].headerinfo.est_los_cur = this.state.estimated_loss_curreny;
        task_json['object_data'].headerinfo.est_los_val = this.state.estimated_loss_value;
        task_json['object_data'].headerinfo.act_los_cur = this.state.actual_loss_curreny;
        task_json['object_data'].headerinfo.act_los_val = this.state.actual_loss_value;
        task_json['object_data'].headerinfo.est_thi_cur = this.state.estimated_third_curreny;
        task_json['object_data'].headerinfo.est_thi_val = this.state.estimated_third_value;
        task_json['object_data'].headerinfo.act_thi_cur = this.state.actual_third_curreny;
        task_json['object_data'].headerinfo.act_thi_val = this.state.actual_third_value;
        task_json['object_data'].headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method;
        task_json['object_data'].headerinfo.com_paid_date = this.state.com_paid_date;
        task_json['object_data'].headerinfo.rule_reference = this.state.rule_reference;
        task_json['object_data'].headerinfo.istrading_error = this.state.istrading_error;
        task_json['object_data'].headerinfo.fpp_name = this.state.fpp_name;
        task_json['object_data'].headerinfo.dateofDetection = this.state.dateofDetection;
        //new
        task_json['object_data'].headerinfo.reasonforbreach = this.state.reasonforbreach; //Root Cause
        task_json['object_data'].headerinfo.breachimpact = this.state.breachimpact; //Incident Impact
        task_json['object_data'].headerinfo.ownershipresolution = this.state.ownershipresolution;
        task_json['object_data'].headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;
        // task_json['object_data'].headerinfo.costOfBreach = this.state.costOfBreach;
        // task_json['object_data'].headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
        task_json['object_data'].headerinfo.additional_fields = this.state.assign_data.additional_fields;

        let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

        let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

        let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
        // console.log("bin_files==>",bin_files)

        let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
        // console.log("bin_files==>",disciplinary_action_bin_files)

        var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

        task_json['action_data'] = {action: "COMP_BREACH_REGISTER_ACTION", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_REGISTER_ACTION", assigned_to: 0 };
        let postData = {command: "update_save_breach_task", task_json: task_json, task_id: this.state.taskid, bin_files: bin_files };
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.updateaftersaveandclose);
    }

    saveandcloseCompletedBreach = (event) => {
        event.preventDefault();
        let task_json = JSON.parse(Store.getStoreData('cur_q_modal_data').task_json);
        //console.log("task_json",task_json)

        let incedentDetails = Store.getStoreData('incident_details');
        if(incedentDetails === null){
            incedentDetails = this.state.breach_deatils;
        }
        let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

        let thirdPartyDetails = Store.getStoreData('third_party_details');
        if(thirdPartyDetails === null){
            thirdPartyDetails = this.state.third_party_details;
        }
        let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

        let incidentType_name = this.state.incidentType_name;
        for(let d of this.state.incident_types){
            if(parseInt(d.incidentTypeID) === parseInt(this.state.incidentType_id)){
                incidentType_name = d.incidentTypeName;
            }
        }

        task_json['task_data'].last_action = 'COMP_BREACH_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_BREACH_COMPLETE';
        //task_json['task_data'].actiondate = Date.now();

        task_json['object_data'].headerinfo.breach_type = this.state.breachType;
        task_json['object_data'].headerinfo.breach_deatils = incedentDetails_value;
        task_json['object_data'].headerinfo.breach_date = this.state.breachDate;
        task_json['object_data'].headerinfo.is_reportability = this.state.isreportable;
        task_json['object_data'].headerinfo.reported_date = this.state.reportedDate;
        task_json['object_data'].headerinfo.regulator_id = this.state.regulatorID;

        task_json['object_data'].headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID);
        task_json['object_data'].headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
        task_json['object_data'].headerinfo.disciplinary_action = this.state.disciplinaryAction;
        task_json['object_data'].headerinfo.details_of_investigation = this.state.detailsofinvestigation;
        //new
        task_json['object_data'].headerinfo.incident_title = this.state.incident_title;
        task_json['object_data'].headerinfo.incidentType_id = this.state.incidentType_id;
        task_json['object_data'].headerinfo.incidentType_name = incidentType_name;
        task_json['object_data'].headerinfo.isinternal_external_incident = this.state.isinternal_external_incident;
        task_json['object_data'].headerinfo.department_id = this.state.department_id;
        task_json['object_data'].headerinfo.department_name = this.state.department_name;
        task_json['object_data'].headerinfo.third_party_details = thirdPartyDetails_value;
        task_json['object_data'].headerinfo.impact_type = this.state.impact_type;
        task_json['object_data'].headerinfo.est_los_cur = this.state.estimated_loss_curreny;
        task_json['object_data'].headerinfo.est_los_val = this.state.estimated_loss_value;
        task_json['object_data'].headerinfo.act_los_cur = this.state.actual_loss_curreny;
        task_json['object_data'].headerinfo.act_los_val = this.state.actual_loss_value;
        task_json['object_data'].headerinfo.est_thi_cur = this.state.estimated_third_curreny;
        task_json['object_data'].headerinfo.est_thi_val = this.state.estimated_third_value;
        task_json['object_data'].headerinfo.act_thi_cur = this.state.actual_third_curreny;
        task_json['object_data'].headerinfo.act_thi_val = this.state.actual_third_value;
        task_json['object_data'].headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method;
        task_json['object_data'].headerinfo.com_paid_date = this.state.com_paid_date;
        task_json['object_data'].headerinfo.rule_reference = this.state.rule_reference;
        task_json['object_data'].headerinfo.istrading_error = this.state.istrading_error;
        task_json['object_data'].headerinfo.fpp_name = this.state.fpp_name;
        task_json['object_data'].headerinfo.dateofDetection = this.state.dateofDetection;
        //new
        task_json['object_data'].headerinfo.reasonforbreach = this.state.reasonforbreach; //Root Cause
        task_json['object_data'].headerinfo.breachimpact = this.state.breachimpact; //Incident Impact
        task_json['object_data'].headerinfo.ownershipresolution = this.state.ownershipresolution;
        task_json['object_data'].headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;
        // task_json['object_data'].headerinfo.costOfBreach = this.state.costOfBreach;
        // task_json['object_data'].headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
        task_json['object_data'].headerinfo.additional_fields = this.state.assign_data.additional_fields;

        let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

        let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

        let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
        // console.log("bin_files==>",bin_files)

        let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
        // console.log("bin_files==>",disciplinary_action_bin_files)

        var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

        task_json['action_data'] = {action: "COMP_BREACH_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_COMPLETE", assigned_to: 0, note: "Save the Completed Record" };
        let postData = {command: "update_save_breach_task", task_json: task_json, task_id: this.state.taskid, bin_files: bin_files };
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.updateaftersaveandclose);
    }

    updateaftersaveandclose = (result) => {
        let alert_param = {title: 'Success', message: 'The incident has been saved.', ok_text: 'Ok', confirm: false,
        alertHandler: this.aftersaveandclose, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    aftersaveandclose = (result, stack) => {
        this.setState({alert_param: null})
        let reasonforbreachlist = JSON.parse(JSON.stringify(this.state.reasonforbreachlist));
        let changemodule = 0
        reasonforbreachlist.forEach((item) => {
            if (parseInt(item.reasonforBreachID) === parseInt(this.state.reasonforbreach) && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", reasonforbreachlist);

        let breachimpactlist = JSON.parse(JSON.stringify(this.state.breachimpactlist));
        breachimpactlist.forEach((item) => {
            if (parseInt(item.breachImpactID) === parseInt(this.state.breachimpact) && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", breachimpactlist);

        // let departmentlist = JSON.parse(JSON.stringify(this.state.departmentlist));
        // departmentlist.forEach((item) => {
        //     if (parseInt(item.businessAreaID) === parseInt(this.state.department) && item.isDelete !== 1) {
        //         item.isDelete = 1;
        //         changemodule = 1
        //     }
        // });
        // departmentlist.forEach((item) => {
        //     if (parseInt(item.businessAreaID) === parseInt(this.state.ownershipresolution) && item.isDelete !== 1) {
        //         item.isDelete = 1;
        //         changemodule = 1
        //     }
        // });
        // console.log("modified submitedShareUserList", departmentlist);

        let selectedRiskID = {}
        this.state.selectedRiskCategory.forEach((item) => {
            selectedRiskID[item.id.toString()] = item.label
        });
        let riskcategorylist = JSON.parse(JSON.stringify(this.state.riskcategorylistnew));
        riskcategorylist.forEach((item) => {
            if (item.riskTriggerID in selectedRiskID && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", riskcategorylist);

        if(changemodule === 1){
            let {module_config} = this.state;
            module_config.reason_for_Breach = reasonforbreachlist;
            module_config.breach_impact = breachimpactlist;
            //module_config.business_areas = departmentlist;
            module_config.risk_triggers = riskcategorylist;
            const postData = {command: "save_module_config", module_config: module_config};
            const api = new APICall();
            api.command(postData, this.afterUpdatemoduleconfig);
        }else{
            window.location.reload();
        }
    }
    afterUpdatemoduleconfig = (result) => {
        console.log(result);
        window.location.reload();
   }

   submitApproveBreach_showalert = (event) => {
        event.preventDefault();
        let alert_param = {title: 'Alert', message: 'This record can only be closed by users with the permission – Admin Manager.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.customalertmsgclose, stack: {}}
        this.setState({alert_param: alert_param})
   }

   customalertmsgclose = (result, stack) => {
        this.setState({alert_param: null})
    }

   submitApproveBreach_validation = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';

        let child_task = Store.getStoreData('cur_all_childtask_data');
        let child_task_incomplete = 0;
        let module_config = Store.getStoreData('module_config');
        if(module_config.general.complete_incidencewith_outstanding_tasks === false){
            for(let i=0; i<child_task.length; i++){
                if(child_task[i].cur_lane == "COMP_BREACH_FOLLOWUP_ASSIGNMENT"){
                    child_task_incomplete = child_task_incomplete + 1;
                }
                if(child_task[i].cur_lane == "COMP_BREACH_REMEDIAL_ASSIGNMENT"){
                    child_task_incomplete = child_task_incomplete + 1;
                }
                if(child_task[i].cur_lane == "COMP_BREACH_PREVENTATIVE_ASSIGNMENT"){
                    child_task_incomplete = child_task_incomplete + 1;
                }
            }
            if(child_task_incomplete > 0) {
                problems = true
                message += 'One or more tasks are pending so this record cannot be closed at this time. ';
            }
        }
        if(!this.state.isEditable){
            if ('custom_fields' in Store.getStoreData('module_config') && Store.getStoreData('module_config').custom_fields.enabled) {
                let fields = [];
                if (Store.getStoreData('module_config').custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {
                        if('displayin' in item && item.displayin === 'Manager Screen Only'){
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                        }
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ", ";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    if (fields.length === 1) {
                        err_msg = err_msg + " field is mandatory. ";
                    } else {
                        err_msg = err_msg + " fields are mandatory. ";
                    }
                    problems = true
                    message += err_msg
                }
            }
            if('form_customisation_root_cause' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_manager_form'){
                if(this.state.reasonforbreach === ""){
                    problems = true
                    message += 'Please select the root cause. '
                }
            }
            if('form_customisation_incident_impact' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_manager_form'){
                if(this.state.breachimpact === ""){
                    problems = true
                    message += 'Please select the Incident Impact. '
                }
            }
            if('form_customisation_resolution_owner' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_manager_form'){
                if(this.state.ownershipresolution === ""){
                    problems = true
                    message += 'Please select the Ownership of Resolution. '
                }
            }
            if('form_customisation_risk_category' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_manager_form'){
                if(this.state.selectedRiskCategory.length === 0){
                    problems = true
                    message += 'Please select the Risk Category. '
                }
            }
        }else{
            if ('custom_fields' in Store.getStoreData('module_config') && Store.getStoreData('module_config').custom_fields.enabled) {
                let fields = [];
                if (Store.getStoreData('module_config').custom_fields.additional_fields.length !== 0) {
                    this.state.assign_data.additional_fields.forEach((item) => {
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ", ";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    if (fields.length === 1) {
                        err_msg = err_msg + " field is mandatory. ";
                    } else {
                        err_msg = err_msg + " fields are mandatory. ";
                    }
                    problems = true
                    message += err_msg
                }
            }

            if('form_customisation_root_cause' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_manager_form')){
                if(this.state.reasonforbreach === ""){
                    problems = true
                    message += 'Please select the root cause. '
                }
            }
            if('form_customisation_incident_impact' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_manager_form')){
                if(this.state.breachimpact === ""){
                    problems = true
                    message += 'Please select the Incident Impact. '
                }
            }
            if('form_customisation_resolution_owner' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_manager_form')){
                if(this.state.ownershipresolution === ""){
                    problems = true
                    message += 'Please select the Ownership of Resolution. '
                }
            }
            if('form_customisation_risk_category' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_manager_form')){
                if(this.state.selectedRiskCategory.length === 0){
                    problems = true
                    message += 'Please select the Risk Category. '
                }
            }

        }

        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                        alertHandler: this.checkvalidation, stack: {}}

            this.setState({alert_param: alert_param})
        } else {
            let alert_param = {title: 'Warning', message: 'This will close the incident and no further action can be taken, are you sure you want to close this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                    alertHandler: this.checkvalidationUpdate, stack: {}}
            this.setState({alert_param: alert_param})
            //this.checkvalidation(false, {})
        }
    }

    checkvalidationUpdate = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        this.submitApproveBreach();
    }

    checkvalidation = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(!result){
            this.submitApproveBreach();
        }
    }

    submitApproveBreach = () => {

        let incedentDetails = Store.getStoreData('incident_details');
        if(incedentDetails === null){
            incedentDetails = this.state.breach_deatils;
        }
        let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

        let thirdPartyDetails = Store.getStoreData('third_party_details');
        if(thirdPartyDetails === null){
            thirdPartyDetails = this.state.third_party_details;
        }
        let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

        let incidentType_name = this.state.incidentType_name;
        for(let d of this.state.incident_types){
            if(parseInt(d.incidentTypeID) === parseInt(this.state.incidentType_id)){
                incidentType_name = d.incidentTypeName;
            }
        }

        let task_json = JSON.parse(Store.getStoreData('cur_q_modal_data').task_json);
        task_json['task_data'].last_action = 'COMP_BREACH_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_BREACH_COMPLETE';
        task_json['task_data'].actiondate = Date.now();

        task_json['object_data'].headerinfo.breach_type = this.state.breachType;
        task_json['object_data'].headerinfo.breach_deatils = incedentDetails_value;
        task_json['object_data'].headerinfo.breach_date = this.state.breachDate;
        task_json['object_data'].headerinfo.is_reportability = this.state.isreportable;
        task_json['object_data'].headerinfo.reported_date = this.state.reportedDate;
        task_json['object_data'].headerinfo.regulator_id = this.state.regulatorID;

        task_json['object_data'].headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID);
        task_json['object_data'].headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
        task_json['object_data'].headerinfo.disciplinary_action = this.state.disciplinaryAction;
        task_json['object_data'].headerinfo.details_of_investigation = this.state.detailsofinvestigation;

        task_json['object_data'].headerinfo.reasonforbreach = this.state.reasonforbreach;
        task_json['object_data'].headerinfo.breachimpact = this.state.breachimpact;
        task_json['object_data'].headerinfo.ownershipresolution = this.state.ownershipresolution;
        task_json['object_data'].headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;
        //task_json['object_data'].headerinfo.costOfBreach = this.state.costOfBreach;
        //task_json['object_data'].headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
        task_json['object_data'].headerinfo.additional_fields = this.state.assign_data.additional_fields;
        //new
        task_json['object_data'].headerinfo.incident_title = this.state.incident_title;
        task_json['object_data'].headerinfo.incidentType_id = this.state.incidentType_id;
        task_json['object_data'].headerinfo.incidentType_name = incidentType_name;
        task_json['object_data'].headerinfo.isinternal_external_incident = this.state.isinternal_external_incident;
        task_json['object_data'].headerinfo.department_id = this.state.department_id;
        task_json['object_data'].headerinfo.department_name = this.state.department_name;
        task_json['object_data'].headerinfo.third_party_details = thirdPartyDetails_value;
        task_json['object_data'].headerinfo.impact_type = this.state.impact_type;
        task_json['object_data'].headerinfo.est_los_cur = this.state.estimated_loss_curreny;
        task_json['object_data'].headerinfo.est_los_val = this.state.estimated_loss_value;
        task_json['object_data'].headerinfo.act_los_cur = this.state.actual_loss_curreny;
        task_json['object_data'].headerinfo.act_los_val = this.state.actual_loss_value;
        task_json['object_data'].headerinfo.est_thi_cur = this.state.estimated_third_curreny;
        task_json['object_data'].headerinfo.est_thi_val = this.state.estimated_third_value;
        task_json['object_data'].headerinfo.act_thi_cur = this.state.actual_third_curreny;
        task_json['object_data'].headerinfo.act_thi_val = this.state.actual_third_value;
        task_json['object_data'].headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method;
        task_json['object_data'].headerinfo.com_paid_date = this.state.com_paid_date;
        task_json['object_data'].headerinfo.rule_reference = this.state.rule_reference;
        task_json['object_data'].headerinfo.istrading_error = this.state.istrading_error;
        task_json['object_data'].headerinfo.fpp_name = this.state.fpp_name;
        task_json['object_data'].headerinfo.dateofDetection = this.state.dateofDetection;
        //new

        let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

        let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

        let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
        // console.log("bin_files==>",bin_files)

        let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
        // console.log("bin_files==>",disciplinary_action_bin_files)

        var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

        task_json['action_data'] = {action: "COMP_BREACH_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_BREACH_COMPLETE", assigned_to: 0 };
        let postData = {command: "update_save_breach_task", task_json: task_json, task_id: this.state.taskid,bin_files: bin_files };
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.afterApproveBreach);
    //}
    }

    afterApproveBreach = (result) => {
        // console.log("Completed:", result);
        //alert('Breach Completed successfully.');
        let alert_param = {title: 'Success', message: 'Incident Completed successfully.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterApproveBreach, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload(true);
    }

    updateafterApproveBreach = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }

    viewGenerateField = (data, data_container) => {
        if(data.type === 'Date'){
            let dateValue = "";
            if(data.value !== ''){
                const reported_date_obj = new Date(data.value);
                const reported_date_day = reported_date_obj.getDate() < 10 ? "0"+reported_date_obj.getDate().toString() : reported_date_obj.getDate();
                const reported_date_month = reported_date_obj.getMonth()+1 < 10 ? `0${(reported_date_obj.getMonth() + 1).toString()}` : reported_date_obj.getMonth()+1;
                const reported_date_year = reported_date_obj.getFullYear();
                dateValue = `${reported_date_day}/${reported_date_month}/${reported_date_year}`;
            }
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                    <div style={{display: "inline-block"}}>
                        <BodyTextHeader>{data.nickname}</BodyTextHeader>
                        <BodyText>{dateValue}</BodyText>
                    </div>
                </div>
            );

        }
        if(data.type === 'User List'){
            let contacts =  Store.getStoreData('contacts');
            let userName = "";
            if(data.value !== ''){
                userName = data.value in contacts ? contacts[data.value] : "";
            }
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                    <div style={{display: "inline-block"}}>
                        <BodyTextHeader>{data.nickname}</BodyTextHeader>
                        <BodyText>{userName}</BodyText>
                    </div>
                </div>
            );
        }
        if(data.type !== 'Date' || data.type !== 'User List'){
            return (
                <div key={data.name} style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                    <div style={{display: "inline-block"}}>
                        <BodyTextHeader>{data.nickname}</BodyTextHeader>
                        <BodyText>{data.value}</BodyText>
                    </div>
                </div>
            );
        }
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({showModal: null})
    }

    generateFieldUpdate = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            case "Date":
                                fieldhtml = this.createDateField(data, data_container);
                                break;
                            case "User List":
                                fieldhtml = this.createUserListField(data, data_container);
                                break;
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}
    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="number" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
    }
    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("event.target.name===>", event.target.name)
        console.log("assign_data===>", assign_data)
        console.log("data_container===>", data_container)
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createDropdownField = (data, data_container) => {
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    createDateField = (data, data_container) => {
        console.log("Data==>", data)
        let curValue = data.value !== '' ? data.value : new Date();
		return (<CSLDateCover id={"date-"+data.name} style={{display: "inline-block"}}>
                    <DatePicker
                            onChange={this.onChangeCustomDate(data.name)}
                            clearIcon={null}
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={curValue}
                    />
                </CSLDateCover>);
    }

    onChangeCustomDate = (name) => (date) => {
        //event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("assign_data===>", assign_data)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === name) {
                item.value = date;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createUserListField = (data, data_container) => {
    	return (
        		<MRModalSelect id={"userlist-"+data.name} onChange={this.handleUserListField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        this.state.conductRuleUserLists.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt.ID}>{opt.ContactName}</option>
        		            );
        		        })
        		    }
        		</MRModalSelect>
    	);
    }

    handleUserListField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("event.target.name===>", event.target.name)
        console.log("assign_data===>", assign_data)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let cur_role = Store.getStoreData('role');
        return (<div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{this.state.Ref_ID}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <ScoreBodyContainer>
                <MRModalBody style={{paddingLeft: "25px", paddingRight: "25px"}}>
                    {
                        (() => {
                            if(!this.state.isEditable){
                                return(<div>
                                        <MRModalBodyInner>
                                            You are viewing a read only version of this Incident submitted by {this.state.submittedBy_name}.
                                        </MRModalBodyInner>
                                        <MRModalBodyInner>
                                            <div style={{display: "inline-block", width: "100%"}}>
                                                <div style={{display: "inline-block", width: "90%"}}>
                                                    <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Incident Details</BodyTextHeader>
                                                </div>
                                                <div style={{display: "inline-block", width: "10%"}}>
                                                    {
                                                        (() => {
                                                            // if(this.state.cur_lane !== 'COMP_BREACH_COMPLETE'){
                                                            if(cur_role !== 'bm'){
                                                                return(<div style={{float:"right", fontSize: "18px",cursor:"pointer"}} onClick={this.editView}><BiEdit /></div>)
                                                            }
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Incident ID</BodyTextHeader>
                                                    <BodyText>{this.state.Ref_ID}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Company</BodyTextHeader>
                                                    <BodyText>{this.state.groupcompanyName}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Incident Title</BodyTextHeader>
                                                    <BodyText>{this.state.incident_title}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Created Date</BodyTextHeader>
                                                    <BodyText>{this.state.created_at}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Incident Category</BodyTextHeader>
                                                    <BodyText>{this.state.breachType_name}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Incident Type</BodyTextHeader>
                                                    <BodyText>{this.state.incidentType_name}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Incident Date</BodyTextHeader>
                                                    <BodyText>{this.state.breachdate_date}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Date of Detection</BodyTextHeader>
                                                    <BodyText>{this.state.dateofDetection_view}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Closed Date</BodyTextHeader>
                                                    <BodyText>{this.state.closed_date}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>User</BodyTextHeader>
                                                    <BodyText>{this.state.user_name}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block"}}>
                                                    <BodyTextHeader>Incident Details</BodyTextHeader>
                                                    <div dangerouslySetInnerHTML={{__html: this.state.breach_deatils}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                                                    {/* <BodyText>{this.state.breach_deatils}</BodyText> */}
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Internal/External?</BodyTextHeader>
                                                    <BodyText>{this.state.isinternal_external_incident_view}</BodyText>
                                                </div>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <BodyTextHeader>Department</BodyTextHeader>
                                                    <BodyText>{this.state.department_name}</BodyText>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block"}}>
                                                    <BodyTextHeader>Third Party Details</BodyTextHeader>
                                                    <div dangerouslySetInnerHTML={{__html: this.state.third_party_details}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                                                </div>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                <div style={{display: "inline-block", width: "100%"}}>
                                                    <BodyTextHeader>Attachments</BodyTextHeader>
                                                    <BodyText><Dropzone onFilesAdded={this.filesLoaded_breach} initFiles={this.state.breach_cur_files_json} addnew={false}/></BodyText>
                                                </div>
                                            </div>
                                            {
                                                (() => {
                                                    if(this.state.isFinancialSectionShow){
                                                        return(<div>
                                                                    <div style={{display: "inline-block", width: "100%", marginTop: "10px"}}>
                                                                        <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Financial Impact</BodyTextHeader>
                                                                    </div>
                                                                    {
                                                                        (() => {
                                                                            if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true || 'financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                                                return(<div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                                                                                {
                                                                                    (() => {
                                                                                        if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true){
                                                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                                                            <BodyTextHeader>Impact Type</BodyTextHeader>
                                                                                            <BodyText>{this.state.impact_type_view}</BodyText>
                                                                                        </div> )
                                                                                        }
                                                                                    })()
                                                                                }
                                                                                {
                                                                                    (() => {
                                                                                        if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                                                            <BodyTextHeader>Estimated Loss</BodyTextHeader>
                                                                                            <BodyText>{this.state.est_los_val}</BodyText>
                                                                                        </div>)
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>)
                                                                            }
                                                                        })()
                                                                    }
                                                                    {
                                                                        (() => {
                                                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true || 'financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                                                return(<div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                                {
                                                                                    (() => {
                                                                                        if('financial_impact' in Store.getStoreData('module_config') && 'record_cost_of_incident' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_cost_of_incident === true){
                                                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                                                            <BodyTextHeader>Actual Loss</BodyTextHeader>
                                                                                            <BodyText>{this.state.act_los_val}</BodyText>
                                                                                        </div>)
                                                                                        }
                                                                                    })()
                                                                                }
                                                                                {
                                                                                    (() => {
                                                                                        if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                                                            <BodyTextHeader>Estimated 3rd Party Cost</BodyTextHeader>
                                                                                            <BodyText>{this.state.est_thi_val}</BodyText>
                                                                                        </div>)
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>)
                                                                            }
                                                                        })()
                                                                    }
                                                                    {
                                                                        (() => {
                                                                            if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true || 'financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
                                                                                return(<div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                                {
                                                                                    (() => {
                                                                                        if('financial_impact' in Store.getStoreData('module_config') && 'record_third_party_costs' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.record_third_party_costs === true){
                                                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                                                            <BodyTextHeader>Actual 3rd Party Cost</BodyTextHeader>
                                                                                            <BodyText>{this.state.act_thi_val}</BodyText>
                                                                                        </div>)
                                                                                        }
                                                                                    })()
                                                                                }
                                                                                {
                                                                                    (() => {
                                                                                        if('financial_impact' in Store.getStoreData('module_config') && 'financial_impact_calculation_methodology' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.financial_impact_calculation_methodology === true){
                                                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                                                            <BodyTextHeader>Financial Impact Calculation</BodyTextHeader>
                                                                                            <BodyText>{this.state.fin_impact_cal_method_view}</BodyText>
                                                                                        </div>)
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>)
                                                                            }
                                                                        })()
                                                                    }
                                                                    {
                                                                        (() => {
                                                                            if('financial_impact' in Store.getStoreData('module_config') && 'compensation_paid_date' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.compensation_paid_date === true){
                                                                                return(<div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                                            <div style={{display: "inline-block", width: "50%"}}>
                                                                                                <BodyTextHeader>Compensation Paid</BodyTextHeader>
                                                                                                <BodyText>{this.state.com_paid_date_view}</BodyText>
                                                                                            </div>

                                                                                </div>)
                                                                            }
                                                                        })()
                                                                    }
                                                                    <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                        <div style={{display: "inline-block", width: "100%"}}>
                                                                            <BodyTextHeader>Attachments</BodyTextHeader>
                                                                            <BodyText><Dropzone onFilesAdded={this.filesLoaded_finimp} initFiles={this.state.finimp_cur_files_json} addnew={false}/></BodyText>
                                                                        </div>
                                                                    </div>
                                                        </div>)
                                                    }
                                                })()
                                            }
                                            <div style={{display: "inline-block", width: "100%", marginTop: "10px"}}>
                                                <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Reportability</BodyTextHeader>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                                                <div style={{display: "inline-block", width: "100%"}}>
                                                    <BodyTextHeader>Reportable Incident</BodyTextHeader>
                                                    <BodyText>{this.state.is_reportability}</BodyText>
                                                </div>
                                            </div>
                                            {
                                                (() => {
                                                    if(this.state.is_reportability === 'Yes'){
                                                        return(
                                                            <div>
                                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                                        <BodyTextHeader>Reported Date</BodyTextHeader>
                                                                        <BodyText>{this.state.reported_date_view}</BodyText>
                                                                    </div>
                                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                                        <BodyTextHeader>Regulator</BodyTextHeader>
                                                                        <BodyText>{this.state.regulator_view}</BodyText>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                    <div style={{display: "inline-block"}}>
                                                                        <BodyTextHeader>Rule/Reference</BodyTextHeader>
                                                                        <BodyText>{this.state.rule_reference_view}</BodyText>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                                        <BodyTextHeader>Trading Error</BodyTextHeader>
                                                                        <BodyText>{this.state.trading_error_view}</BodyText>
                                                                    </div>
                                                                    <div style={{display: "inline-block", width: "50%"}}>
                                                                        <BodyTextHeader>Fund/Portfolio/Product Name</BodyTextHeader>
                                                                        <BodyText>{this.state.fpp_name_view}</BodyText>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                    <div style={{display: "inline-block", width: "100%"}}>
                                                                        <BodyTextHeader>Evidence Attachments</BodyTextHeader>
                                                                        <BodyText><Dropzone onFilesAdded={this.filesLoaded_evidence} initFiles={this.state.evdence_cur_files_json} addnew={false}/></BodyText>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }

                                            <div style={{display: "inline-block", width: "100%", marginTop: "10px"}}>
                                                <BodyTextHeader style={{fontSize: "16px",color: "#3b5361"}}>Disciplinary Action</BodyTextHeader>
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "12px"}}>
                                                <div style={{display: "inline-block", width: "100%"}}>
                                                    <BodyTextHeader>Action Required</BodyTextHeader>
                                                    <BodyText>{this.state.action_required_view}</BodyText>
                                                </div>
                                            </div>
                                            {
                                                (() => {
                                                    if(this.state.action_required_view === 'Yes'){
                                                        return(
                                                            <div>
                                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                    <div style={{display: "inline-block"}}>
                                                                        <BodyTextHeader>Investigation Details</BodyTextHeader>
                                                                        <BodyText>{this.state.investigation_details_view}</BodyText>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                                    <div style={{display: "inline-block", width: "100%"}}>
                                                                        <BodyTextHeader>Investigation Attachments</BodyTextHeader>
                                                                        <BodyText><Dropzone onFilesAdded={this.filesLoaded_disciplinaryaction} initFiles={this.state.disciplinaryAction_cur_files_json} addnew={false}/></BodyText>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                {
                                                    (() => {
                                                        if('form_customisation_root_cause' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form')){
                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                            <BodyTextHeader>Root Cause</BodyTextHeader>
                                                            <BodyText>{this.state.rootCauseName}</BodyText>
                                                        </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if('form_customisation_incident_impact' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form')){
                                                            return(<div style={{display: "inline-block", width: "50%"}}>
                                                            <BodyTextHeader>Incident Impact</BodyTextHeader>
                                                            <BodyText>{this.state.incidentImpactName}</BodyText>
                                                        </div>)
                                                         }
                                                    })()
                                                }
                                            </div>
                                            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                            {
                                                (() => {
                                                    if('form_customisation_resolution_owner' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form'){
                                                        return(
                                                        <div style={{display: "inline-block", width: "50%"}}>
                                                            <BodyTextHeader>Resolution Owner</BodyTextHeader>
                                                            <BodyText>{this.state.resolutionOwnerName}</BodyText>
                                                        </div>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if('form_customisation_risk_category' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form'){
                                                        return(
                                                        <div style={{display: "inline-block", width: "50%"}}>
                                                            <BodyTextHeader>Risk Category</BodyTextHeader>
                                                            <BodyText>{this.state.riskCategoryName}</BodyText>
                                                        </div>
                                                        )
                                                    }
                                                })()
                                            }
                                            </div>
                                            {
                                                (() => {
                                                    if (this.state.assign_data.additional_fields.length !== 0) {
                                                        let custom_html = [];
                                                        this.state.assign_data.additional_fields.forEach((item) => {
                                                            if('displayin' in item && item.displayin !== 'Manager Screen Only'){
                                                                const field = this.viewGenerateField(item, "additional_fields");
                                                                custom_html.push(field);
                                                            }
                                                        })
                                                        return custom_html;
                                                    }
                                                })()
                                            }
                                        </MRModalBodyInner>
                                </div>)
                            }else{
                                return(<div>

                                    <MRModalLabel style={{marginTop: "0px"}}>INCIDENT ID</MRModalLabel>
                                    <MRModalInput style={{backgroundColor: "#F0F0F0"}} value={this.state.Ref_ID} disabled/>
                                    <MRModalLabel>COMPANY</MRModalLabel>
                                    <MRModalInput name="cur_gc_id" value={this.state.groupcompanyName} disabled/>
                                    <MRModalLabel>INCIDENT TITLE</MRModalLabel>
                                    <MRModalInput name="incident_title" value={this.state.incident_title} onChange={this.handleChange} />
                                    <MRModalLabel>INCIDENT CATEGORY</MRModalLabel>
                                    <MRModalSelect id="breach_type" name="breachType" onChange={this.handleChange} value={this.state.breachType}>
                                        <option value="breach_of_rules">Incident</option>
                                        <option value="conduct_rules_breach">Conduct Rules Breach</option>
                                    </MRModalSelect>
                                    {
                                        (() => {
                                            if(this.state.breachType === "breach_of_rules"){
                                                return(<BMBreachOfRulesSection
                                                    conductRuleUserID={this.state.conductRuleUserID}
                                                    isreportable={this.state.isreportable}
                                                    isinternal_external_incident={this.state.isinternal_external_incident}
                                                    regulatorID={this.state.regulatorID}
                                                    handleChangeReportable={this.handleChangeReportable}
                                                    handleChangeAll={this.handleChange}
                                                    handleChangeExIntIncd={this.handleChangeExIntIncd}
                                                    department_id={this.state.department_id}
                                                    third_party_details={this.state.third_party_details}
                                                    breach_cur_files_json={this.state.breach_cur_files_json}
                                                    filesUploadbreach={this.filesLoaded_breach}
                                                    finimp_cur_files_json={this.state.finimp_cur_files_json}
                                                    filesUploadfinimp={this.filesLoaded_finimp}
                                                    cur_files_json={this.state.evdence_cur_files_json}
                                                    filesUpload={this.filesLoaded_evidence}
                                                    breachDetails={this.state.breach_deatils}
                                                    breachDate={this.state.breachDate}
                                                    reportedDate={this.state.reportedDate}
                                                    ChangeBreachDate={this.onChangeBreachDate}
                                                    ChangeReportedDate={this.onChangeReportedDate}
                                                    disabledCheck={this.state.isdesable}
                                                    assign_data={this.state.assign_data}
                                                    modifyAssignData={this.modifyAssignData}
                                                    cur_gc_id={this.state.cur_gc_id}
                                                    incident_types={this.state.incident_types}
                                                    incidentType_id={this.state.incidentType_id}
                                                    business_areas={this.state.business_areas}
                                                    impact_type={this.state.impact_type}
                                                    regulator_types={this.state.regulator_types}
                                                    estimated_loss_curreny={this.state.estimated_loss_curreny}
                                                    estimated_loss_value={this.state.estimated_loss_value}
                                                    actual_loss_curreny={this.state.actual_loss_curreny}
                                                    actual_loss_value={this.state.actual_loss_value}
                                                    estimated_third_curreny={this.state.estimated_third_curreny}
                                                    estimated_third_value={this.state.estimated_third_value}
                                                    actual_third_curreny={this.state.actual_third_curreny}
                                                    actual_third_value={this.state.actual_third_value}
                                                    fin_impact_cal_method={this.state.fin_impact_cal_method}
                                                    com_paid_date={this.state.com_paid_date}
                                                    ChangePaidDate={this.ChangePaidDate}
                                                    rule_reference={this.state.rule_reference}
                                                    istrading_error={this.state.istrading_error}
                                                    handleChangeTradingError={this.handleChangeTradingError}
                                                    fpp_name={this.state.fpp_name}
                                                    disciplinaryAction={this.state.disciplinaryAction}
                                                    handlenaSectionIncluded={this.handlenaSectionIncludedDis}
                                                    detailsofinvestigation={this.state.detailsofinvestigation}
                                                    cur_files_jsonNew={this.state.disciplinaryAction_cur_files_json}
                                                    filesUpload1={this.filesLoaded_disciplinaryaction}
                                                    dateofDetection={this.state.dateofDetection}
                                                    changeDateofDetection={this.onChangeDateofDetection}

                                                    reasonforbreach={this.state.reasonforbreach}
                                                    reasonforbreachlist={this.state.reasonforbreachlist}
                                                    breachimpactlist={this.state.breachimpactlist}
                                                    breachimpact={this.state.breachimpact}
                                                    isnotShowRORC={true}
                                                    />)
                                            }else{
                                                return(<BMConductRulesBreachSection
                                                    conductRuleUserID={this.state.conductRuleUserID}
                                                    isreportable={this.state.isreportable}
                                                    isinternal_external_incident={this.state.isinternal_external_incident}
                                                    regulatorID={this.state.regulatorID}
                                                    handleChangeReportable={this.handleChangeReportable}
                                                    handleChangeAll={this.handleChange}
                                                    breachDetails={this.state.breach_deatils}
                                                    breachDate={this.state.breachDate}
                                                    ChangeBreachDate={this.onChangeBreachDate}
                                                    reportedDate={this.state.reportedDate}
                                                    ChangeReportedDate={this.onChangeReportedDate}
                                                    filesUpload={this.filesLoaded_evidence}
                                                    cur_files_json={this.state.evdence_cur_files_json}
                                                    detailsofinvestigation={this.state.detailsofinvestigation}
                                                    disciplinaryAction={this.state.disciplinaryAction}
                                                    filesUpload1={this.filesLoaded_disciplinaryaction}
                                                    cur_files_jsonNew={this.state.disciplinaryAction_cur_files_json}
                                                    assign_data={this.state.assign_data}
                                                    modifyAssignData={this.modifyAssignData}
                                                    disabledCheck={this.state.isdesable}
                                                    cur_gc_id={this.state.cur_gc_id}
                                                    business_areas={this.state.business_areas}
                                                    department_id={this.state.department_id}
                                                    third_party_details={this.state.third_party_details}
                                                    impact_type={this.state.impact_type}
                                                    regulator_types={this.state.regulator_types}
                                                    estimated_loss_curreny={this.state.estimated_loss_curreny}
                                                    estimated_loss_value={this.state.estimated_loss_value}
                                                    actual_loss_curreny={this.state.actual_loss_curreny}
                                                    actual_loss_value={this.state.actual_loss_value}
                                                    estimated_third_curreny={this.state.estimated_third_curreny}
                                                    estimated_third_value={this.state.estimated_third_value}
                                                    actual_third_curreny={this.state.actual_third_curreny}
                                                    actual_third_value={this.state.actual_third_value}
                                                    fin_impact_cal_method={this.state.fin_impact_cal_method}
                                                    com_paid_date={this.state.com_paid_date}
                                                    ChangePaidDate={this.ChangePaidDate}
                                                    rule_reference={this.state.rule_reference}
                                                    istrading_error={this.state.istrading_error}
                                                    handleChangeTradingError={this.handleChangeTradingError}
                                                    fpp_name={this.state.fpp_name}
                                                    handlenaSectionIncluded={this.handlenaSectionIncludedDis}
                                                    handleChangeExIntIncd={this.handleChangeExIntIncd}
                                                    breach_cur_files_json={this.state.breach_cur_files_json}
                                                    filesUploadbreach={this.filesLoaded_breach}
                                                    finimp_cur_files_json={this.state.finimp_cur_files_json}
                                                    filesUploadfinimp={this.filesLoaded_finimp}
                                                    dateofDetection={this.state.dateofDetection}
                                                    changeDateofDetection={this.onChangeDateofDetection}

                                                    reasonforbreach={this.state.reasonforbreach}
                                                    reasonforbreachlist={this.state.reasonforbreachlist}
                                                    breachimpactlist={this.state.breachimpactlist}
                                                    breachimpact={this.state.breachimpact}
                                                    isnotShowRORC={true}
                                                />)
                                            }
                                        })()
                                    }
                                </div>)
                            }
                        })()
                    }
                    <SpacerXL />
                    <UserHr />
                    {
                        (() => {
                            if(!this.state.isEditable && this.state.cur_lane !== 'COMP_BREACH_COMPLETE'){
                                if (this.state.assign_data.additional_fields.length !== 0) {
                                    let custom_html = [];
                                    this.state.assign_data.additional_fields.forEach((item) => {
                                        if('displayin' in item && item.displayin === 'Manager Screen Only'){
                                            const field = this.generateFieldUpdate(item, "additional_fields");
                                            custom_html.push(field);
                                        }
                                    })
                                    return custom_html;
                                }
                            }
                        })()
                    }
                    <SpacerXL />
                    <UserHr />
                    {
                        (() => {
                            if(!this.state.isEditable){
                                if('form_customisation_root_cause' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_manager_form'){
                                    return(<div>
                                    <MRModalLabel>ROOT CAUSE</MRModalLabel>
                                    <MRModalSelect name="reasonforbreach" onChange={this.handleChange} value={this.state.reasonforbreach} disabled={this.state.removal_readonly}>
                                        <option value="">Select</option>
                                        {
                                            this.state.reasonforbreachlist.map((rc,index) => {
                                                return(
                                                <option key={index} value={rc.reasonforBreachID}>{rc.reasonforBreachName}</option>
                                                );
                                            })
                                        }
                                    </MRModalSelect>
                                    </div>)
                                }
                            }else{
                                if('form_customisation_root_cause' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_manager_form')){
                                    return(<div>
                                    <MRModalLabel>ROOT CAUSE</MRModalLabel>
                                    <MRModalSelect name="reasonforbreach" onChange={this.handleChange} value={this.state.reasonforbreach} disabled={this.state.removal_readonly}>
                                        <option value="">Select</option>
                                        {
                                            this.state.reasonforbreachlist.map((rc,index) => {
                                                return(
                                                <option key={index} value={rc.reasonforBreachID}>{rc.reasonforBreachName}</option>
                                                );
                                            })
                                        }
                                    </MRModalSelect>
                                    </div>)
                                }
                            }
                        })()
                    }
                    {
                        (() => {
                            if(!this.state.isEditable){
                                if('form_customisation_incident_impact' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_manager_form'){
                                    return(<div>
                                    <MRModalLabel>INCIDENT IMPACT</MRModalLabel>
                                    <MRModalSelect name="breachimpact" onChange={this.handleChange} value={this.state.breachimpact} disabled={this.state.removal_readonly}>
                                        <option value="">Select</option>
                                        {
                                            this.state.breachimpactlist.map((rc,index) => {
                                                return(
                                                <option key={index} value={rc.breachImpactID}>{rc.breachImpactName}</option>
                                                );
                                            })
                                        }
                                    </MRModalSelect>
                                    </div>)
                                }
                            }else{
                                if('form_customisation_incident_impact' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_manager_form')){
                                    return(<div>
                                    <MRModalLabel>INCIDENT IMPACT</MRModalLabel>
                                    <MRModalSelect name="breachimpact" onChange={this.handleChange} value={this.state.breachimpact} disabled={this.state.removal_readonly}>
                                        <option value="">Select</option>
                                        {
                                            this.state.breachimpactlist.map((rc,index) => {
                                                return(
                                                <option key={index} value={rc.breachImpactID}>{rc.breachImpactName}</option>
                                                );
                                            })
                                        }
                                    </MRModalSelect>
                                    </div>)
                                }
                            }
                        })()
                    }
                    {
                        (() => {
                            if(!this.state.isEditable){
                                if('form_customisation_resolution_owner' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_manager_form'){
                                    return(<div>
                                        <MRModalLabel>RESOLUTION OWNER</MRModalLabel>
                                        <MRModalSelect onChange={this.handleChange} name="ownershipresolution" value={this.state.ownershipresolution} disabled={this.state.removal_readonly}>
                                        {
                                            (() => {
                                                let contactsdropdown = [];
                                                contactsdropdown.push(<option value="">Select</option>)
                                                let departments_list = this.state.business_areas;
                                                for(let k of departments_list) {
                                                    contactsdropdown.push(<option key={k.businessAreaID} value={k.businessAreaID} >{k.businessAreaName}</option>)
                                                }
                                                if(departments_list.length > 0)contactsdropdown.push(<option key={'1'} value={'1'} disabled style={{color: "#EBEBEB"}}>——————————————————————————</option>);
                                                if(this.state.review_list.length !== 0){
                                                    let contacts = this.state.review_list;
                                                    contacts = contacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
                                                    contacts.forEach((item) => {
                                                        contactsdropdown.push(<option key={item.ContactName + '-' + item.ID} value={item.ContactName + '-' + item.ID} selected={true} >{item.ContactName}</option>)
                                                    });
                                                }
                                                return contactsdropdown;
                                            })()
                                        }
                                        </MRModalSelect>
                                    </div>)
                                }
                            }else{
                                if('form_customisation_resolution_owner' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_manager_form')){
                                    return(<div>
                                        <MRModalLabel>RESOLUTION OWNER</MRModalLabel>
                                        <MRModalSelect onChange={this.handleChange} name="ownershipresolution" value={this.state.ownershipresolution} disabled={this.state.removal_readonly}>
                                        {
                                            (() => {
                                                let contactsdropdown = [];
                                                contactsdropdown.push(<option value="">Select</option>)
                                                let departments_list = this.state.business_areas;
                                                for(let k of departments_list) {
                                                    contactsdropdown.push(<option key={k.businessAreaID} value={k.businessAreaID} >{k.businessAreaName}</option>)
                                                }
                                                if(departments_list.length > 0)contactsdropdown.push(<option key={'1'} value={'1'} disabled style={{color: "#EBEBEB"}}>——————————————————————————</option>);
                                                if(this.state.review_list.length !== 0){
                                                    let contacts = this.state.review_list;
                                                    contacts = contacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
                                                    contacts.forEach((item) => {
                                                        contactsdropdown.push(<option key={item.ContactName + '-' + item.ID} value={item.ContactName + '-' + item.ID} selected={true} >{item.ContactName}</option>)
                                                    });
                                                }
                                                return contactsdropdown;
                                            })()
                                        }
                                        </MRModalSelect>
                                    </div>)
                                }
                            }
                        })()
                    }
                    {
                        (() => {
                            if(!this.state.isEditable){
                                if('form_customisation_risk_category' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_manager_form'){
                                    return(<div>
                                        <MRModalLabel>RISK CATEGORY</MRModalLabel>
                                        <FilterBody>
                                        {
                                            (() => {
                                                if(this.state.removal_readonly){
                                                    return <BMApprovalTypeAhead data={this.state.riskcategorylist} selectedRiskCategory={this.state.selectedRiskCategory} ricipientValue={this.riskcategoryValue} isReadOnly={true} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                                }else{
                                                    if (this.state.riskcategorylist !== null) {
                                                        return <BMApprovalTypeAhead data={this.state.riskcategorylist} selectedRiskCategory={this.state.selectedRiskCategory} ricipientValue={this.riskcategoryValue} isReadOnly={false} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                                    }
                                                }
                                            })()
                                        }
                                        </FilterBody>
                                    </div>)
                                }
                            }else{
                                if('form_customisation_risk_category' in Store.getStoreData('module_config').general && (Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_manager_form')){
                                    return(<div>
                                        <MRModalLabel>RISK CATEGORY</MRModalLabel>
                                        <FilterBody>
                                        {
                                            (() => {
                                                if(this.state.removal_readonly){
                                                    return <BMApprovalTypeAhead data={this.state.riskcategorylist} selectedRiskCategory={this.state.selectedRiskCategory} ricipientValue={this.riskcategoryValue} isReadOnly={true} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                                }else{
                                                    if (this.state.riskcategorylist !== null) {
                                                        return <BMApprovalTypeAhead data={this.state.riskcategorylist} selectedRiskCategory={this.state.selectedRiskCategory} ricipientValue={this.riskcategoryValue} isReadOnly={false} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                                    }
                                                }
                                            })()
                                        }
                                        </FilterBody>
                                    </div>)
                                }
                            }
                        })()
                    }

                    <div style={{width: "100%"}}>
                    {/* Remedial Actions */}
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Remedial Actions</div>
                        {
                            (() => {
                                if(!this.state.removal_readonly){
                                    if(this.state.showRemedialcloseButton){
                                        return(<div style={{float: "right", color: "#323232", fontSize: "20px",cursor: "pointer"}} onClick={this.showAddRemedialTaskModal(-1)}><FaPlusCircle /></div>)
                                    }
                                }
                            })()
                        }
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    {
                        (() => {
                            if(this.state.showRemedialModal){
                                return(<BMAddFollowupTask followuptaskvalue={this.state.remedialTasksList} headerText={'remedial action task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeRemedialModal} refreshTaskValue={this.remedialTaskValueFetch}/>)
                            }
                        })()
                    }
                    <OverviewInfoBody>
                    {
                        (() => {
                            if(this.state.remedialTasksList.length !== 0){
                                return(<CSLTable add={false} processData={this.processMyRemedialTaskValue} headerText={''} tableRows="3"/>)
                            }else{
                                return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no remedial actions tasks assigned.</div>)
                            }
                        })()
                    }
                    </OverviewInfoBody>

                    {/* Preventative Measures */}

                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Preventative Measures</div>
                        {
                            (() => {
                                if(!this.state.removal_readonly){
                                    if(this.state.showPreventativecloseButton){
                                        return(<div style={{float: "right", color: "#323232", fontSize: "20px",cursor: "pointer"}} onClick={this.showAddPreventativeTaskModal(-1)}><FaPlusCircle /></div>)
                                    }
                                }
                            })()
                        }
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    {
                        (() => {
                            if(this.state.showPreventativeModal){
                                return(<BMAddFollowupTask followuptaskvalue={this.state.preventativeTasksList} headerText={'preventative measures task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closePreventativeModal} refreshTaskValue={this.preventativeTaskValueFetch}/>)
                            }
                        })()
                    }
                    <OverviewInfoBody>
                    {
                        (() => {
                            if(this.state.preventativeTasksList.length !== 0){
                                return(<CSLTable add={false} processData={this.processMyPreventativeTaskValue} headerText={''} tableRows="3"/>)
                            }else{
                                return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no preventative measures tasks assigned.</div>)
                            }
                        })()
                    }
                    </OverviewInfoBody>
                    {/* Followup Section */}
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>Follow-up Tasks</div>
                        {
                            (() => {
                                if(!this.state.removal_readonly){
                                    if(this.state.showfollowupcloseButton){
                                        return(<div style={{float: "right", color: "#323232", fontSize: "20px",cursor: "pointer"}} onClick={this.showAddFollowupTaskModal(-1)}><FaPlusCircle /></div>)
                                    }
                                }
                            })()
                        }
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    {
                        (() => {
                            if(this.state.showFollowupModal){
                                return(<BMAddFollowupTask followuptaskvalue={this.state.followupTasksList} headerText={'follow-up task'} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch}/>)
                            }
                        })()
                    }
                    <OverviewInfoBody>
                    {
                        (() => {
                            if(this.state.followupTasksList.length !== 0){
                                return(<CSLTable add={false} processData={this.processMyFollowupTaskValue} headerText={''} tableRows="3"/>)
                            }else{
                                return(<div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are no follow-up tasks assigned.</div>)
                            }
                        })()
                    }
                    </OverviewInfoBody>
                    </div>
                </MRModalBody>
                </ScoreBodyContainer>
                <MRModalFooter>
                {
                    (() => {
                        if(this.state.cur_lane === 'COMP_BREACH_COMPLETE'){
                            return(<div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn onClick={this.saveandcloseCompletedBreach} >SAVE AND CLOSE</MRModalSaveCloseBtn>
                                <MRModalCancelBtn id="cancel" onClick={this.closeView}>CANCEL</MRModalCancelBtn>
                            </div> )
                        }else{
                            if(!this.state.isEditable){
                                if(cur_role === 'bm'){
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                    <MRModalSaveCloseBtn onClick={this.submitApproveBreach_showalert} >CLOSE</MRModalSaveCloseBtn>
                                    <MRModalSaveCloseBtn onClick={this.saveandcloseApproveBreach} >SAVE AND CLOSE</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>CANCEL</MRModalCancelBtn>
                                    </div> )
                                }else{
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                    <MRModalSaveCloseBtn onClick={this.submitApproveBreach_validation} >CLOSE</MRModalSaveCloseBtn>
                                    <MRModalSaveCloseBtn onClick={this.saveandcloseApproveBreach} >SAVE AND CLOSE</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>CANCEL</MRModalCancelBtn>
                                    </div> )
                                }

                            }else{
                                if(cur_role === 'bm'){
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                    <MRModalSaveCloseBtn onClick={this.submitApproveBreach_showalert} >CLOSE</MRModalSaveCloseBtn>
                                    <MRModalSaveCloseBtn onClick={this.saveandcloseApproveBreach} >SAVE AND CLOSE</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>CANCEL</MRModalCancelBtn>
                                    </div>)
                                }else{
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                    <MRModalSaveCloseBtn onClick={this.submitApproveBreach_validation} >CLOSE</MRModalSaveCloseBtn>
                                    <MRModalSaveCloseBtn onClick={this.saveandcloseApproveBreach} >SAVE AND CLOSE</MRModalSaveCloseBtn>
                                    <MRModalCancelBtn id="cancel" onClick={this.closeView}>CANCEL</MRModalCancelBtn>
                                    </div>)
                                }

                            }
                        }
                    })()
                }
                </MRModalFooter>
            </MRModalContainer>
            {
                    (() => {
                        if (this.state.showModal !== null) {
                            switch(this.state.showModal) {
                                case 'view_followup_modal' : return <div style={{position:'absolute',width:'100%'}}><BMViewFollowupModal closeModal={this.closefollowupModal} /></div>; break;
                                case 'view_remedial_modal' : return <div style={{position:'absolute', width:'100%'}}><BMViewRemedialModal closeModal={this.closefollowupModal} /></div>; break;
                                case 'view_preventative_modal' : return <div style={{position:'absolute', width:'100%'}}><BMViewPreventativeModal closeModal={this.closefollowupModal} /></div>; break;
                            }
                        }
                    })()
                }
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}
export default BMViewIncidentModal;
