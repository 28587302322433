import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import {FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import moment from 'moment';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;

const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    margin-top: 10px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #EEEEEE;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
    margin-left: 5px;
`;
class RCMDetailsRegisterModal extends React.Component
{
    closeRegulatoryModal = (event) => {
        this.props.closeModal();
    }
    processMyExternalLink = () => {
        var linksdata = Store.getStoreData('task').externalLinks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Link', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Comment', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'url_value' : <a target="_blank" href={d.url}>{d.url}</a>,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    processMykeyData = () => {
        var keysdata = Store.getStoreData('task').keyDates;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of keysdata) {           
            i++;         
            
            let regu_pub_date_auto = "";            
            let dateformatCheck = moment(d.date, 'DD/MM/YYYY',true).isValid();
            if(dateformatCheck){
                regu_pub_date_auto = d.date;
            }else if(d.date !== null){                
                let issue_date_str_auto = d.date ? d.date : '1970-01-01T00:00:00.000Z';
                var dd_issueDate_auto = parseInt(issue_date_str_auto.substring(8,12)) < 10 ? "0"+parseInt(issue_date_str_auto.substring(8,12)) : parseInt(issue_date_str_auto.substring(8,12));
                var mm_issueDate_auto = parseInt(issue_date_str_auto.substring(5,7)) < 10 ? "0"+parseInt(issue_date_str_auto.substring(5,7)) : parseInt(issue_date_str_auto.substring(5,7));
                var yyyy_issueDate_auto = parseInt(issue_date_str_auto.substring(0,4));               
                regu_pub_date_auto = `${dd_issueDate_auto}/${mm_issueDate_auto}/${yyyy_issueDate_auto}`;                
            }

            let elem = {'index' : i, 
                    'date_value' : regu_pub_date_auto,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    render() {
        let rufeed = Store.getStoreData('task');
        const issue_date_obj = new Date(rufeed.issueDate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_day}/${issue_month}/${issue_year}`;

        console.log("rufeed===>",rufeed)

        let docType = "";
        docType = rufeed.documentTypes;
        if(!isNaN(rufeed.documentTypes)) {
            //const documentType = Store.getStoreData('rutypename');
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            } 
            docType = doctype[rufeed.documentTypes]
        }
        
        return(<RegModalContainer>
            <RegModalHeaderText>RC Viewer</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <RegDismisOuterContainer>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{rufeed.documentName}</div>                    
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>           
                <OverviewInfoBody>
                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(rufeed.summary))}} style={{textAlign: "justify"}}></div>                
                <div style={{marginTop: "30px", display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type: </span>{docType}</div>
                <div style={{marginTop: "30px", display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date: </span>{issue_date}</div>                               
                </OverviewInfoBody>
            </RegDismisOuterContainer>    
            {
                rufeed.sections.map((section) => {
                    return (
                        <RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>                                                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>
                            <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>                                 
                                
                            </OverviewInfoBody>
                        </RegDismisOuterContainer>                                    
                    )
                })
            } 
            {
                (() => {                
                    if('externalLinks' in rufeed && rufeed.externalLinks.length !== 0) {   
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>  
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>     
                                <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>    
                            </RegDismisOuterContainer>
                            </div>)   
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Useful Links Available</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()                
            }
            {
                (() => {                            
                    if('keyDates' in rufeed && rufeed.keyDates.length !== 0) {                             
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader> 
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>          
                                    <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                            </div>)
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Key Dates Available</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                    
                })()                
            } 
            <RegCancelSubmitButtonOuterContainer>                    
                <RegCancelButton onClick={this.closeRegulatoryModal}>Cancel</RegCancelButton>                     
            </RegCancelSubmitButtonOuterContainer> 
            </RegModalContainer>)
    }
}
export default RCMDetailsRegisterModal