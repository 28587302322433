import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents'
import ClarificationMessage from './ClarificationMessage'
import Originalanswer from './Originalanswer'
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';


class ClarificationModal extends React.Component
{
    state = {
        ready: false, 
        company: '',
        query: '',
        answer: '',
        clarification: '',
        binFiles: [],
        cur_files_json: null,
        extbinFiles: [],
        ext_cur_files_json: null,
        index: -1,
        form_data: null,
        id:0,
        category_id: 0,
        ext_advice: false,
        ext_advice_txt: '',
        ext_advice_provider:'',
        ext_ref_number: '',
        ext_contact_name: '',
        clarifications: [],
        answered_by: 0,
        show_ext_readonly: false,
        approved_by : 0,
        cur_recever_id: 0,
        acceptance_recever_id: 0,
        only_assign: false,
        cl_asignment_note: '',
        cl_asignment_notes: [],
        custom_fields: {},
        list_answrere: [],
        enabled_topics: false,
        topic: {},
        alert_param: null,
        email_notification: 0,
        emailclarifications: [],
        ignoreclarifications: [],
        is_show: false,
        refresh_count: 0,
        show_notification: true,
        is_loading: false,
        company_id: 0

    };

    constructor(props) {
        super(props);
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
        
    }
    processcloseNotification = (result) => {
        this.props.closeModal();
    }


    processAnswerQuery = (result) => {
        let msg = '';
        let title = 'Alert';
        this.setState({is_loading: false})
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        if(Store.getStoreData('role') === 'jr_ca') {
            msg = 'Your clarification has been sent to a manager for approval.';
            // alert("Your clarification has been sent to a manager for approval.")
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
            this.setState({alert_param: alert_param})
        }else{
            if(this.state.acceptance_recever_id > 0) {
                msg = 'Clarification has been assigned.'
                // alert('Clarification has been assigned!')
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }else{
                msg = 'Your answer has been sent to the requester for acknowledgment.';
                // alert('Your answer has been sent to the requester for acknowledgment.');
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
                this.setState({alert_param: alert_param})
            }
            
        }
    }

    answerCARQuestion(submit_form){
        Store.updateStore('submit_form', submit_form);
        let msg = 'The clarification cannot be blank.'
        let title = 'Warning'
        if(!this.state.only_assign) {
            if(this.state.clarification === '' && submit_form == 1) {
                let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return
            }
        }


        let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];
        let bin_files = [...bin_files1, ...bin_files2];

        let emailclarifications = this.state.emailclarifications;
        let ignoreclarifications = this.state.ignoreclarifications;
        let clarifications =  this.state.clarifications
        for(let key in emailclarifications){
            clarifications.push(emailclarifications[key])
        }
        for(let k in ignoreclarifications){
            clarifications.push(ignoreclarifications[k])
        }

        let postData = { "command": "add_clarification_reply", query_id:this.state.id, clarification: this.state.clarification, submit:submit_form, 
                         bin_files: bin_files, category_id:this.state.category_id,
                        ext_advice: this.state.ext_advice,
                        ext_advice_txt: this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'"),
                        clarifications: clarifications,
                        index: this.state.index, task_id: this.state.form_data === null ? 0 : this.state.form_data.id, 
                        asker_id: this.state.form_data.contact_id, only_assign: this.state.only_assign,
                        cl_asignment_note: this.state.cl_asignment_note, cl_asignment_notes: this.state.cl_asignment_notes, 
                        acceptance_recever_id: this.state.acceptance_recever_id };
        if(Store.getStoreData('role') === 'jr_ca' && this.state.cur_recever_id > 0) {
            postData.approved_by = this.state.approved_by;
        }
        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processAnswerQuery);
        this.setState({is_loading: true})
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }


    componentDidMount() {
        let index = Store.getStoreData('a_index') === null ? 0 : Store.getStoreData('a_index');
        let moduleconfig = Store.getStoreData('moduleconfig');
        let form_data = {};
        form_data = Store.getStoreData('cur_a_modal_data');
        //console.log('form_data', form_data);
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;
        
        let gc_company_id = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;
        let list_answrere = Utils.showCompanyUsers(gc_company_id,'answrer');
        Utils.log("list_answrere", list_answrere);

        let task_json = JSON.parse(form_data.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }


        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
        }


        let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0;
        let approved_by = ('reverted_by' in task_json.object_data) ? task_json.object_data.reverted_by: 0;
        let cur_recever_id = ('cur_recever_id' in task_json.object_data) ? task_json.object_data.cur_recever_id : 0;
        let cl_asignment_notes = ('cl_asignment_notes' in task_json.object_data) ? task_json.object_data.cl_asignment_notes : [];

        let custom_fields = this.state.custom_fields;
        custom_fields.enabled = false;
        custom_fields = ('custom_fields' in  task_json.object_data) ? task_json.object_data.custom_fields : custom_fields;

        console.log("custom_fields =====>", custom_fields);

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }



        this.setState({company: gc_company, cur_files_json: cur_files_json, ext_cur_files_json:ext_cur_files_json,
            index: index, form_data:form_data, query: task_json.object_data.query,
            answered_by: answered_by,
            clarifications: ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [],
            answer: ('answer' in task_json.object_data) ? task_json.object_data.answer : '', 
            ext_advice: ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : '',
            ext_advice_provider: ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : '',
            ext_advice_txt: ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '',
            ext_ref_number: ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '',
            ext_contact_name: ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '',
            id: form_data.id,approved_by:approved_by, cur_recever_id: cur_recever_id,cl_asignment_notes: cl_asignment_notes , 
            ready:true, custom_fields: custom_fields,list_answrere:list_answrere, enabled_topics: enabled_topics,topic: topic, email_notification: 0 });
    }

    trackEmailnotification = () => {
        console.log("this.state.show_notification", this.state.show_notification)
        let postData = {command: "get_notification_count", task_id: this.state.id, company_id: this.state.form_data.company_id};
        const api = new APICall();
        // this.state.show_notification ? api.command(postData, this.processEmailNotification);
        if(this.state.show_notification){
            api.indexCommand(postData, this.processEmailNotification)
        }
    }

    processEmailNotification = (result) => {
        let emailclarifications = result.result;
        this.setState({emailclarifications: emailclarifications, email_notification: emailclarifications.length})
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    handleCheckboxChange = (event) => {
        this.setState({ ext_advice: event.target.checked })
    }

    handleAssignChange = (event) => {
        let only_assign = false;
        if( parseInt(event.target.value) > 0 && (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin'))
        {
            only_assign = true;
        }
        this.setState({ [event.target.name] : event.target.value, only_assign: only_assign })
    }

    showCustomFields = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv style={{width:"93%"}}>
                    <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true}/>
                </Style.ModalNameInputDiv>
            </div>
        );
    }
    
    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }

    ignoreNotification = (event) => {
        event.preventDefault();
        const api = new APICall();
        let emailclarifications = this.state.emailclarifications;
        let ignoreclarifications = this.state.ignoreclarifications;
        for(let key in emailclarifications){
            ignoreclarifications.push(emailclarifications[key])
        }
        this.setState({show_notification: false, email_notification: 0, ignoreclarifications: ignoreclarifications, emailclarifications: []})
        let postData = {command: "ignore_email_notification", task_id: this.state.id};
        postData['ignoreclarifications'] = ignoreclarifications;
        api.indexCommand(postData, this.processignoreNotification);
    }

    processignoreNotification = (result) => {
        console.log("ignore", result)
        this.setState({show_notification: true})
    }

    showNotification = (event) => {
        event.preventDefault();
        const api = new APICall();
        let clarifications = this.state.clarifications === '' ? [] : this.state.clarifications;
        let emailclarifications = this.state.emailclarifications;
        for(let key in emailclarifications){
            clarifications.push(emailclarifications[key])
        }
        this.setState({show_notification: false, email_notification: 0, emailclarifications: [], ignoreclarifications: [], clarifications: clarifications})
        let postData = {command: "update_email_notification", task_id: this.state.id};
        postData['clarifications'] = clarifications;
        api.indexCommand(postData, this.processshowNotification);
        
    }

    processshowNotification = (result) => {
        this.setState({show_notification: true})
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        setTimeout(() => {
            this.trackEmailnotification()
        }, 30000);
        // let contact = Store.getStoreData('contact');
        let contacts = Store.getStoreData('contacts');
        let thisContact = Store.getStoreData('contact');
        let form_data = Store.getStoreData('cur_a_modal_data');
        let task_json = JSON.parse(form_data.task_json);
        let object_data = JSON.parse(form_data.task_json).object_data
        let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
        clarifications.reverse()
        console.log('Clarifications', clarifications.length);
        let orig_answer = {actiondate: object_data.answer_date, note: object_data.answer, performed_by: object_data.answered_by }
        let contact_id = form_data.contact_id;
        let contact = contacts[contact_id];
        //console.log('contact_id, contacts', contact_id, contacts);
        let date = new Date()
        // let dateStr = date.toLocaleString('en-GB').replace(/,/g, '')

        let dateStr = date.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')

        let cl_asignment_notes = JSON.parse(JSON.stringify(this.state.cl_asignment_notes))
        cl_asignment_notes.reverse()
        let last_assignment = cl_asignment_notes[0];
        let cur_assigned = form_data.cur_assigned_to === 0 ? "Compliance Advisers" : contacts[form_data.cur_assigned_to.toString()];
        if('cur_recever_id' in task_json.object_data && form_data.cur_assigned_to === 0) {
          cur_assigned = task_json.object_data.cur_recever_id.toString() === "0" ? "Compliance Advisers" : Store.getStoreData('org_teams')[task_json.object_data.cur_recever_id.toString()].name;
        }
        return (
            <div>
                <Style.ModalContainer style={{height: "auto", width:"60%"}}>
                    {
                        (() => {
                            if(this.state.is_loading) {
                                return <CSLLoader style={{position: 'absolute'}} />
                            }
                        })()
                    }
                    <Style.ModalHeaderText>Provide Compliance Advice</Style.ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <Style.ModalBody>
                        <Style.ModalBodyInner style={{overflowY: "scroll",height: "calc(100vh - 20px)"}}>
                            <Style.ModalContainertop>
                                {
                                    (() => {
                                        if(Store.getStoreData('has_gc')) {
                                            return (<div style={{display:"contents"}}>
                                                        <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <Style.ModalNameInputDiv>{this.state.company}</Style.ModalNameInputDiv>
                                                        </Style.ModalNameInputDiv>
                                                    </div>
                                                    )
                                        }
                                    })()
                                }
                                <Style.ModalNameLabelDiv>CAR Reference</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalNameInputDiv>{task_json.object_data.ref_id}</Style.ModalNameInputDiv>
                                </Style.ModalNameInputDiv>

                                {
                                    (() => {
                                        if(this.state.enabled_topics) {
                                            return (
                                                <div style={{display:"contents"}}>
                                                    <Style.ModalNameLabelDiv>Topic</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <Style.ModalNameInputDiv>{this.state.topic.value}</Style.ModalNameInputDiv>
                                                        </Style.ModalNameInputDiv>
                                                </div>
                                                )
                                        }

                                    })()
                                }
                                
                                <Style.ModalNameLabelDiv>Currently assigned to</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalNameInputDiv>{cur_assigned}</Style.ModalNameInputDiv>
                                </Style.ModalNameInputDiv>
                                <Style.ModalNameLabelDiv>Query</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalTextArea name="query" defaultValue={this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true} />
                                </Style.ModalNameInputDiv>

                                {
                                    (() => {
                                        if(Store.getStoreData('moduleconfig').specific_user_assignment_enabled && Store.getStoreData('role') === 'jr_ca') {
                                            return(
                                                <div style={{display:"contents"}}>
                                                    <Style.ModalNameLabelDiv>Assign to</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                    <Style.MRModalSelect name="acceptance_recever_id" value={this.state.acceptance_recever_id} onChange={this.handleAssignChange}>
                                                    {
                                                        Store.getStoreData('list_approvar').map((item) => {
                                                            {
                                                                return (
                                                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                                );
                                                            }
                                                        })
                                                    }
                                                    </Style.MRModalSelect>
                                                    </Style.ModalNameInputDiv>
                                                </div>
                                                )
                                        }
                                    })()
                                }

                                {
                                    (() => {
                                        if(Store.getStoreData('moduleconfig').specific_user_assignment_enabled && (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin')) {
                                            return(
                                                <div style={{display:"contents"}}>
                                                    <Style.ModalNameLabelDiv>Assign to</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                    <Style.MRModalSelect name="acceptance_recever_id" value={this.state.acceptance_recever_id} onChange={this.handleAssignChange}>
                                                    {
                                                       this.state.list_answrere.map((item) => {
                                                            {
                                                                return (
                                                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                                );
                                                            }
                                                        })
                                                    }
                                                    </Style.MRModalSelect>
                                                    </Style.ModalNameInputDiv>
                                                    {
                                                        (() => {
                                                            if(this.state.only_assign){
                                                                return (<div style={{display:"contents"}}>
                                                                    <Style.ModalNameLabelDiv>Assignment Note</Style.ModalNameLabelDiv>
                                                                    <Style.ModalNameInputDiv>
                                                                        <Style.ModalTextArea name="cl_asignment_note" value={this.state.cl_asignment_note} onChange={this.handleChange} />
                                                                    </Style.ModalNameInputDiv>
                                                                </div>)
                                                            }
                                                        })()
                                                    }
                                                </div>
                                                )
                                        }
                                    })()
                                }
                                
                                {
                                    (() => {
                                        if(!this.state.only_assign) {
                                            return(<div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />*/}
                                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                                                </Style.ModalNameInputDiv>


                                                <Style.ModalNameLabelDiv>Author</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Style.ModalInput name="contact" defaultValue={contact} readOnly={true} />
                                                </Style.ModalNameInputDiv>
                                            </div>)
                                        }

                                    })()
                                }
                            </Style.ModalContainertop>



                            {
                                (() => {
                                    if(!this.state.only_assign) {
                                        return(
                                                <Style.ModalNameInputDiv>
                                                    <Style.ModalWiderDiv>
                                                        <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                        <Style.ModalClearDiv />
                                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%'}}>
                                                            {thisContact}&nbsp;&nbsp;&nbsp;&nbsp;{dateStr} 
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            {
                                                                (() => {
                                                                    if(this.state.email_notification > 0 && this.state.show_notification){
                                                                        return(<div style={{display:"contents"}}>
                                                                            <p style={{float: "right",margin:"2px", color:"rgb(242, 246, 9)", border: "3px solid", padding: "4px"}}>{this.state.email_notification.toString().length < 2 ? "0" + this.state.email_notification.toString().trim() : this.state.email_notification} New clarifications <b onClick={this.showNotification} style={{cursor:"pointer"}}>Show</b> &nbsp;&nbsp; <b onClick={this.ignoreNotification} style={{cursor:"pointer"}}>Ignore</b> </p>
                                                                        </div>)
                                                                    }
                                                                })()
                                                            }
                                                        </Style.ModalWiderHeaderDiv>
                                                        <Style.ModalTextArea style={{width: '93.3%'}} name="clarification" value={this.state.clarification.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} placeholder="Add clarification...." />
                                                    </Style.ModalWiderDiv>
                                                </Style.ModalNameInputDiv>
                                            )
                                    }
                                })()
                            }



                        
                    <Style.CancelSubmitButtonOuterContainer style={{width: '93%', marginTop:'10px'}}>
                        <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                        <Style.SubmitButton onClick={() => this.answerCARQuestion(1)}>Submit</Style.SubmitButton>
                    </Style.CancelSubmitButtonOuterContainer>
                    



                    <Style.ModalNameInputDiv>
                    <Style.ModalWiderDiv>

                        {
                            (() => {
                                if(this.state.custom_fields.enabled) {
                                    if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                        const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                            return item.name === "reference_number"
                                        })

                                        if(('value' in field) && field.value!=='') {
                                            return this.showCustomFields(field, "custom_terminology_fields");
                                        }
                                    }
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.custom_fields.enabled) {
                                    if (this.state.custom_fields.additional_fields.length !== 0) {
                                        let custom_html = [];
                                        this.state.custom_fields.additional_fields.forEach((item) => {
                                            if(('value' in item) && item.value !=='') {
                                                const field = this.showCustomFields(item, "additional_fields");
                                                custom_html.push(field);
                                            }
                                        })
                                        return custom_html;
                                    }
                                }
                            })()
                        }
                    
                    </Style.ModalWiderDiv>
                    </Style.ModalNameInputDiv>
                    {
                        (() => {
                            if(!this.state.only_assign) {
                                return (<div>
                                        {
                                            (() => {
                                                if(!this.state.show_notification){
                                                    return <div><CSLLoader /></div>
                                                }else{
                                                    return(<div>
                                                        {

                                                            clarifications.map((c,i) => {
                                                                return (<ClarificationMessage cardata={form_data} clarification={c} />)
                                                            })

                                                        }
                                                    </div>)
                                                }
                                            })()
                                        }

                                        <div>
                                        {
                                            (() => {
                                                return (<Originalanswer cardata={form_data} clarification={orig_answer} />)
                                            })()
                                        }
                                        </div>

                                        {
                                            (() =>{
                                                if (this.state.ext_advice){
                                                    return(
                                                        <Style.ModalNameInputDiv>
                                                            <Style.ModalWiderDiv>
                                                                <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                                {
                                                                    (() => {
                                                                        if(this.state.ext_advice_provider != ""){
                                                                        return(<div>
                                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                    External Advice Providers
                                                                                </Style.ModalWiderHeaderDiv>
                                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_advice_provider.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                            </div>)
                                                                        }
                                                                    })()
                                                                }
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External advice required
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalTextArea style={{width: '93.3%'}} value={this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>

                                                                {
                                                                    (() => {
                                                                        if(this.state.ext_ref_number != ""){
                                                                            return(<div>
                                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                    External Reference Number
                                                                                </Style.ModalWiderHeaderDiv>
                                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                            </div>)
                                                                        }
                                                                    })()
                                                                }
                                                                {
                                                                    (() => {
                                                                        if(this.state.ext_contact_name != ""){
                                                                            return(<div>
                                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                    External Contact Name
                                                                                </Style.ModalWiderHeaderDiv>
                                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                                            </div>)
                                                                        }
                                                                    })()
                                                                }
                                                                {
                                                                 (() => {
                                                                     if(this.state.ext_cur_files_json.length > 0) {
                                                                         return(<div>
                                                                                     <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                                   Attachments
                                                                                     </Style.ModalWiderHeaderDiv>
                                                                                     <div style={{width: '93.3%'}}>
                                                                                           <Dropzone initFiles={this.state.ext_cur_files_json} addnew={false} />
                                                                                     </div>
                                                                                 </div>
                                                                             )
                                                                     }
                                                                 })()
                                                                }
                                                            </Style.ModalWiderDiv>
                                                        </Style.ModalNameInputDiv>
                                                    )
                                                }
                                            })()
                                        }
                                    

                                </div>)
                            }
                        })()
                    }

                    {
                        (() => {
                            if(cl_asignment_notes.length > 0){
                                let assign_by = contacts[last_assignment.performed_by];
                                let time_obj = new Date(parseInt(last_assignment.actiondate));
                                let last_time_date = time_obj.toLocaleString('en-GB').replace(/,/g, '');
                                console.log("assign_by", assign_by);
                                return(
                                    <Style.ModalNameInputDiv>
                                        <Style.ModalWiderDiv>
                                            <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                            <Style.ModalClearDiv />
                                            <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%' }}>
                                                Assignment Note  &nbsp;&nbsp;{assign_by}&nbsp;&nbsp;&nbsp;&nbsp;{last_time_date}
                                            </Style.ModalWiderHeaderDiv>
                                            <div style={{backgroundColor: '#FFFFFF', width: '95%'}}>
                                                <Style.ModalTextArea style={{width: '98%'}} value={last_assignment.note.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                            </div>
                                        </Style.ModalWiderDiv>
                                    </Style.ModalNameInputDiv>
                                )
                            }
                        })()
                    }

                    </Style.ModalBodyInner>
                </Style.ModalBody>
            </Style.ModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
    }
}
export default ClarificationModal;

