import React from 'react';
import Store from '../../Common/Store.js';
import styled from 'styled-components';
import {FaPlus, FaTimes} from 'react-icons/fa';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const OkButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #04ACA7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const OkDangerButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #EA5A6E;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: white;
    color: #646A7E;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const AlertBoxContainer = styled.div`
	position: fixed;
	top: 40vh;
	left: 40vw;
    width: 450px;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F7F7F7;
    color: #2D2D2D;
    border-radius: 10px;
    border-style: solid;
    border-color: #F7F7F7;
    z-index: 2000;
`
const AlertBoxHeader = styled.div`
    width: calc(100% - 15px);
    background-color: #F7F7F7;
    color: #646A7E;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    height: 35px;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
`
const AlertBoxBody = styled.div`
    width: calc(100% - 30px);
    background-color: #F7F7F7;
    color: #646A7E;
    font-size: 18px;
    min-height: 100px;
    text-align: center;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px
`
const AlertBoxFooter = styled.div`
    width: calc(100% - 15px);
    background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
`

class AlertBox extends React.Component
{
    state = {view_count: 0 };

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    setComponentState = () => {
        if(this.props.alertParam === null) {
            this.setState({alertParam: null})
            return
        }
        let title = this.props.alertParam != null && 'title' in this.props.alertParam ? this.props.alertParam.title : 'Alert';
        let confirm = this.props.alertParam != null && 'confirm' in this.props.alertParam ? this.props.alertParam.confirm : false;
        let danger = this.props.alertParam != null && 'danger' in this.props.alertParam ? this.props.alertParam.danger : false;
        let ok_text = this.props.alertParam != null && 'ok_text' in this.props.alertParam ? this.props.alertParam.ok_text : 'Ok';
        let cancel_text = this.props.alertParam != null && 'cancel_text' in this.props.alertParam ? this.props.alertParam.cancel_text : 'Cancel';
        this.targetElement = document.querySelector('#CSLAlertBox');
        console.log('props', this.props)
        disableBodyScroll(this.targetElement);

        this.setState({title: title, confirm: confirm, danger: danger, ok_text: ok_text, cancel_text: cancel_text, message: this.props.alertParam.message})        
    }

    componentDidUpdate(prev_props) {
        if(this.props !== prev_props && this.props.alertParam !== null) {
            this.setComponentState()
        }
    }

    processOk = () => {
    	enableBodyScroll(this.targetElement);
    	this.props.alertParam.alertHandler(true, this.props.alertParam.stack)
    }

    processCancel = () => {
    	enableBodyScroll(this.targetElement);
    	this.props.alertParam.alertHandler(false, this.props.alertParam.stack)
    }

    render()
    {
        // console.log('this.props.alertParam', this.props.alertParam)
        if(this.props.alertParam === null)return <div></div>

        return (
            <div>
            	<InactiveOverlay />
                <AlertBoxContainer id="CSLAlertBox">
                	<AlertBoxHeader>{this.state.title}
                	</AlertBoxHeader>
                	{/* <AlertBoxBody>{this.state.message}
                	</AlertBoxBody> */}
                    <AlertBoxBody dangerouslySetInnerHTML={{__html: this.state.message}}></AlertBoxBody>
                	<AlertBoxFooter>
                	{
                		(() => {
                			if(this.state.danger) {
                				return <OkDangerButton onClick={this.processOk}>{this.state.ok_text}</OkDangerButton>
                			} else {
                				return <OkButton onClick={this.processOk}>{this.state.ok_text}</OkButton>
                			}
                			
                		})()
                	}
                	{
                		(() => {
                			if(this.state.confirm) return <CancelButton onClick={this.processCancel}>{this.state.cancel_text}</CancelButton>
                		})()
                	}
                	</AlertBoxFooter>
                </AlertBoxContainer>
            </div>
            )
    }
}
export default AlertBox;