import React from "react";
import styled from "styled-components";
import TAPICall from "./src/Common/APICall.js";
import RcmStore from "./src/Common/Store.js";
import Store from './../../../Common/Store';
import RegisterManagerView from './src/Components/Register/RegisterManagerView.js'

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;


class RcmHOC extends React.Component {

    state={ready: false, role:''}

    componentDidMount(){
        this.setEnv('rcm');
    }

    setEnv(module_name) {
        let api_url = `${Store.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;

        RcmStore.updateStore('module_name',module_name);
        RcmStore.updateStore('api_url',api_url);
        RcmStore.updateStore('is_admin',false);
        RcmStore.updateStore('is_debug',false);

        let postData = { command: "tmviewindexcall" };
        let api = new TAPICall();
        api.command(postData, this.processtaskIndex);
    }

    processtaskIndex = (result) => {
        RcmStore.updateStore('loggedincontact', result.result['contactid']);
        RcmStore.updateStore('companyid', result.result['companyid']);
        RcmStore.updateStore('module_config', result.result['module_config']);
        RcmStore.updateStore('ragulatorsname', result.result['regulator_type']);
        RcmStore.updateStore('rutypename', result.result['ru_types']);
        RcmStore.updateStore('rutypenameAll', result.result['ru_types_all']);
        RcmStore.updateStore('tags', result.result.getTagSelect.tags);
        //set company start
        let gc_comp = Store.getStoreData('gc_companie');
        RcmStore.updateStore('gc_company', gc_comp);
        //set company end
        let has_gc = gc_comp.length > 1 ? true : false;
        RcmStore.updateStore('has_gc',has_gc);

        let gc_assoc = {}
        let gc_filters = {};
        for(let gc of gc_comp) {
            let gc_id = gc.id.toString();
            gc_assoc[gc_id] = gc.company_name;
            if(gc.is_gc) {
                gc_filters[gc.id.toString()] = 1;
            }
        }
        console.log("gc_filters==>", gc_filters)
        RcmStore.updateStore('gc_filters', gc_filters);
        RcmStore.updateStore('gc_assoc', gc_assoc);

        let r = {};
        r['result'] = {};
        let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        console.log('cts==>', cts)
        let user_roles = {};
        let update_contacts = [];
        let user_name = {};
        if('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles['rcm'];
            for(let k in cts.result.contactlist){
                if(user_roles[k.toString()] !== undefined && user_roles[k.toString()] !== 'no_access' ){
                    update_contacts.push(cts.result.contactlist[k])
                    user_name[k.toString()] = cts.result.contactlist[k].ContactName;
                }
            }
            this.divideAllTasksIndex(cts.result.contactlist, user_roles)

            RcmStore.updateStore('userroles', user_roles);
            console.log("update_contacts", user_roles)
            RcmStore.updateStore('contacts', update_contacts);
            RcmStore.updateStore('user_name', user_name);
        }

        let userroles = RcmStore.getStoreData("userroles");
        let contact = Store.getStoreData("contact");
        let role = userroles[contact.ID.toString()];
        RcmStore.updateStore('role', role);
        RcmStore.updateStore('isreferenceUrl', true)
        this.setState({ready: true, role})
    }

    divideAllTasksIndex(users, user_roles){
        let shareUserList = [];
        let shareAcknowledgeMent = [];

        for(let k in users){
            if(k in user_roles && user_roles[k] !== 'no_access'){
                let data={};
                let datanew={};
                data.id = users[k].ID;
                data.name = users[k].ContactName;
                data.email = users[k].EmailAddress;
                data.role = user_roles[users[k].ID];
                data.select = false;
                data.foracknowledgement = true;
                data.forinformation = true;
                data.foracknowledgementCheck = false;
                data.forinformationCheck = false;
                shareUserList.push(data);

                datanew.id = users[k].ID;
                datanew.name = users[k].ContactName;
                datanew.email = users[k].EmailAddress;
                datanew.role = user_roles[users[k].ID];
                datanew.select = true;
                datanew.foracknowledgement = false;
                datanew.forinformation = true;
                datanew.foracknowledgementCheck = true;
                datanew.forinformationCheck = false;
                shareAcknowledgeMent.push(datanew);
            }
        }

        if(RcmStore.getStoreData('shareuserlist') === null) {
            RcmStore.updateStore('shareuserlist', shareUserList);
        }
        if(RcmStore.getStoreData('shareAcknowledgeMentList') === null) {
            RcmStore.updateStore('shareAcknowledgeMentList', shareAcknowledgeMent);
        }
        return true;
    }

    closeUserDialog = () => {
        this.setState({show_modal: false})
        this.props.closeUserDialog()
    }

    render() {
        if(!this.state.ready)return(<div></div>)
        console.log("cur_lane==>", this.props.cur_lane)
        return(
            <FolderContainer>
                <RegisterManagerView taskID={this.props.task_id} cur_lane={this.props.cur_lane} closeUserDialog={this.closeUserDialog}/>
            </FolderContainer>
        );
    }
}

export default RcmHOC;
