import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination"
import "./table_styles.css";

const HeaderText = styled.div`
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #1A3552;
  font-weight: 600;
  letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
  float: right;
  margin-right: 20px;
  margin-top: 15px;
  color: #c0c0c0;
`;
const TableContainer = styled.div`
  background-color: #ffffff;
`

/**
 * This component wraps the *[react.table](https://www.npmjs.com/package/react-table)*, adds headers and pagination
 */
class CSLTable extends React.Component {
    static defaultProps = {
        tableRows: 5,
        headerText: null,
        zebra: false
    }

    static propTypes = {
        /** Add + sign to fire this.showAddAskModal -- needs to change to pass function dynamically */
        add: PropTypes.bool,
        /**  Works together with Add -- needs to change to pass function dynamically */
        addTitle: PropTypes.string,
        /** Function that should return an object in format {data:[], colums:[]}*/
        processData: PropTypes.func,
        /** Number of rows */
        tableRows: PropTypes.number,
        /** Text for header */
        headerText: PropTypes.string,
        /** Alternated row background-colour */
        zebra: PropTypes.bool
    }

    // this prop is lost  needs to cross check
    // refreshCallback: undefined

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }


    render() {
        let tableData = this.props.processData();
        //console.log(tableData);
        return (
            <TableContainer>
                <HeaderText
                    style={{display: this.props.headerText ? 'inherit' : 'none'}}>{this.props.headerText}</HeaderText>
                {this.props.add &&
                <TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal}
                                         title={this.props.addTitle}/></TableHeaderIcon>
                }
                <div style={{width: '100%'}}>

                            <ReactTable
                                className={this.props.zebra ? "-striped" : ""}
                                PaginationComponent={Pagination}
                                data={tableData.data}
                                columns={tableData.columns}
                                defaultPageSize={parseInt(this.props.tableRows)}
                                filterable={true}
                                defaultFilterMethod={this.filterMethod}
                            />
                </div>
            </TableContainer>

        )
    }
}

export default CSLTable;
