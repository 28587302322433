import React, { Component } from "react";
import "./Dropzone.css";
import styled from 'styled-components';
import {FiDownload} from 'react-icons/fi'
import {MdClear} from 'react-icons/md'
import { saveAs } from 'file-saver';
import * as Style from './StyledComponents';

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;
const AttachmentButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    &:hover {
      background-color: #2e948f;
      border-color: #2e948f;
    }
`;

class Dropzone extends Component {

  state = {files: [], bin_files: [], files_to_show: [], addnew: true};
  binFiles = [];
  numFiles = 0;
  prevNumFiles = 0;
  numFilesLoading = 0;

  constructor(props) {
    super(props);
    this.state = { hightlight: false, got_file: 0, files: [], files_to_show: [] };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  updateProgress = (evt) => {
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      console.log('percentLoaded',percentLoaded);
    }
  }

  readBinaryFile = (file) => {
    let reader = new FileReader();
    let that = this;
    console.log("Sourav2222",that.binFiles)
    that.binFiles.forEach((tfile,index) => {
      console.log("tfile.uid",typeof tfile.uid)
      if(typeof tfile.uid === 'undefined'){
        that.binFiles.splice(index,1);
      }
    })
    reader.onprogress = this.updateProgress;
    reader.onload = (function(theFile) {
      return function(e) {
        // let processed_bin = e.target.result.replace(/\n/g,'');
        let fElement = {uid: that.genKey(10), name: theFile.name, bin_file: window.btoa(e.target.result).replace(/\n/g,'')};
        that.binFiles.push(fElement);
        that.numFiles++;
      };
    })(file);

    reader.readAsBinaryString(file);
  }

  checkFilesUploading = () => {
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading){
      setTimeout(this.checkFilesUploading, 500);
    } else {
      alert('New files loaded. You may safely save your form now.');
      console.log('this.binFiles', this.binFiles);
      let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
      this.binFiles.forEach((tfile) => {
        files_to_show.push(tfile);
      })
      console.log("Sourav111",this.binFiles)
      this.props.onFilesAdded(this.binFiles);
      this.setState({files_to_show});
    }
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    console.log("files.length",files.length)
    let tempFiles = this.state.files;
    this.numFilesLoading = files.length;
    this.prevNumFiles = this.numFiles;
    setTimeout(this.checkFilesUploading, 500);

    for (var i = 0; i < files.length; i++) {
      tempFiles.push(files.item(i));
      this.readBinaryFile(files.item(i));
      console.log("this.binFiles", this.binFiles);
    }
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleRemove = (uid) => {
    // console.log('remove index', index);
    let tempFiles = JSON.parse(JSON.stringify(this.state.files));
    let newtempFiles = [];
    this.binFiles.forEach((tfile) => {
      if (tfile.uid !== uid) {
        newtempFiles.push(tfile);
      }
    });
    this.binFiles = newtempFiles;
    console.log("this.binFiles",this.binFiles.length)
    // tempFiles.splice(index,1);
    let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
    newtempFiles.forEach((tfile) => {
      files_to_show.push(tfile);
    });
    this.setState({files: newtempFiles, files_to_show});
  }

  handleDownload_old = (index) => {
    // console.log('download index', index);
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading) {
      alert('Please wait, the files are still loading!');
      return;
    }
    // console.log('binfile',this.binFiles[index]);
    var binaryString =window.atob(this.binFiles[index].bin_file);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, this.binFiles[index].name);
  }

  handleDownload = (uid) => {
    let dbinfile = this.state.files_to_show.find(item => item.uid === uid);
    let dnld = dbinfile.bin_file.replace(/ /g, '+');
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  }

  componentDidMount() {
    let existing_files = this.props.initFiles === null ? [] : JSON.parse(JSON.stringify(this.props.initFiles));
    existing_files.forEach((efile) => {
      efile.removable = false;
    })
    let addnew = true;
    if ("addnew" in this.props) {
      addnew = this.props.addnew;
    }
    this.setState({bin_files: existing_files, files_to_show: existing_files, files: [], addnew});
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     let existing_files = this.props.initFiles === null ? [] : JSON.parse(JSON.stringify(this.props.initFiles));
  //     existing_files.forEach((efile) => {
  //       efile.removable = false;
  //     })
  //     this.setState({bin_files: existing_files, files_to_show: existing_files});
  //   }
  // }

  genKey = (length) => {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  render() {
    console.log('state in render', this.state);

    return (
      <div>
        {
          (() => {
                  if(this.state.files_to_show.length > 0) {
                    return (
                            <div style={{width:'100%'}}>
                            {
                              this.state.files_to_show.map((f, index) => {
                                console.log("f", f);
                                const file_parts = f.name.split(".");
                                let ext = "PDF";
                                switch(file_parts[file_parts.length - 1]) {
                                  case 'jpeg':
                                  case 'jpg' : ext = 'JPG';break;
                                  case 'png' : ext = 'PNG'; break;
                                  case 'docx' : ext = 'DOCX'; break;
                                  case 'doc' : ext = 'DOC'; break;
                                  case 'msg' : ext = 'MSG'; break;
                                  case 'txt' : ext = 'TXT'; break;
                                  case 'ppt' : ext = 'PPT'; break;
                                  case 'pptx' : ext = 'PPTX'; break;
                                  case 'xls' : ext = 'XLS'; break;
                                  case 'xlsx' : ext = 'XLS'; break;
                                  default: ext = 'PDF'; break;
                                }
                                console.log('ext', ext);
                                let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
                                file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
                                const file_icon = `/dropzoneImages/${ext}.png`;
                                return (
                                  <div key={index} style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
                                    <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                                        <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                          <img src={file_icon} height="100px" width="auto" />
                                          {/*<FileTypeContainer>{ext}</FileTypeContainer>*/}
                                        </div>
                                        <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                          <div><strong title={f.name} style={{color: '#000000'}}>{f.name.length > 20 ? f.name.substring(0, 20) : f.name}</strong><br /><br /></div>
                                          <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(f.uid)}><FiDownload /> Download</div>
                                          {
                                            (() => {
                                              if (!("removable" in f)) {
                                                return (<div style={{cursor: "pointer", marginTop: "10px"}} onClick={() => this.handleRemove(f.uid)}><MdClear /> Remove</div>);
                                              }
                                            })()
                                          }
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                  </div>
                                );
                              })
                            }
                            <div style={{clear: "both"}}></div>
                            </div>
                      )
                  }
          })()
        }
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png,.docx,.doc,.msg,.txt,.ppt,.pptx,.xls,.xlsx"
          onChange={this.onFilesAdded}
        />
        {
          (() => {
            console.log("Dropzone",this.state.addnew)
            if (this.state.addnew) {
              return (
                <div style={{paddingTop: "20px"}}>
                  <AttachmentButton onClick={this.openFileDialog}>Add an attachment</AttachmentButton>
                </div>
              );
            }
          })()
        }
      </div>
    );
  }
}

export default Dropzone;
