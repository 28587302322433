import Utils from '../../Common/Utils.js';
import Store from '../../Common/Store.js';
const moment = require('moment');
class RegisterHelper {
	 getTaskTitle(row){
	 	let task_json = JSON.parse(row.task_json);
	 	let contacts = Store.getStoreData('contacts');
	 	let task_title = '';
	 	switch(row.module_id) {
	 	  case 'filechecker' : 
	 	    if(row.task_type ==="COMP_FCK"){
	 	          task_title = ('headerinfo' in task_json.object_data && 'review_json' in task_json.object_data.headerinfo && 'general' in task_json.object_data.headerinfo.review_json)  ? task_json.object_data.headerinfo.review_json.general.name.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : '-- review type'; 
	 	                        let reviewee = 'headerinfo' in task_json.object_data ? contacts[task_json.object_data.headerinfo.reviewee_id.toString()] : 'unknown'
	 	                        task_title += ' for ' + reviewee
	 	     }else if(row.task_type ==="COMP_FCK_FOLLOWUP"){
	 	          task_title = ('subject' in task_json.object_data) ? task_json.object_data.subject.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : '';
	 	     }
	 	    break;
	 	  case 'car' : task_title = 'query' in task_json.object_data ? task_json.object_data.query.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                        break;
	 	  case 'rcm' : task_title = 'documentName' in task_json.object_data ? task_json.object_data.documentName.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break             
	 	  case 'cmp' : task_title = ('headerinfo' in task_json.object_data && 'risk_json' in task_json.object_data.headerinfo && 'actions' in task_json.object_data.headerinfo.risk_json && 'action_title' in task_json.object_data.headerinfo.risk_json.actions)  ? task_json.object_data.headerinfo.risk_json.actions.action_title.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break;
	 	  case 'conflicts' : task_title = ('headerinfo' in task_json.object_data && 'nature_of_conflict' in task_json.object_data.headerinfo) ? task_json.object_data.headerinfo.nature_of_conflict.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : 'subject' in task_json.object_data ? task_json.object_data.subject : "";
	 	                      break;
	 	  case 'attest' : task_title = ('headerinfo' in task_json.object_data && 'document_name' in task_json.object_data.headerinfo) ? task_json.object_data.headerinfo.document_name.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : 'subject' in task_json.object_data ? task_json.object_data.subject : "";
	 	                      break;
	 	  case 'breach' : task_title = ('headerinfo' in task_json.object_data && 'breach_deatils' in task_json.object_data.headerinfo) ? task_json.object_data.headerinfo.breach_deatils.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : 'subject' in task_json.object_data ? task_json.object_data.subject : "";
	 	                      break;
	 	  case 'padreg' : task_title = task_json.object_data.ref_id || "";
	 	                      break;
	 	  case 'finpromo' : task_title = ('name' in task_json.object_data) ? task_json.object_data.name : "";
	 	                      break;
	 	  case 'tmview' : task_title = 'name' in task_json.object_data ? task_json.object_data.name.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break;
	 	  //case 'committees' : task_title = 'action_title' in task_json.object_data.headerinfo.action_json ? task_json.object_data.headerinfo.action_json.action_title.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	  case 'committees' : task_title = ('headerinfo' in task_json.object_data && 'action_json' in task_json.object_data.headerinfo && 'action_title' in task_json.object_data.headerinfo.action_json)  ? task_json.object_data.headerinfo.action_json.action_title.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break; 
	 	  case 'docmgmt' : task_title = 'name' in task_json.object_data ? task_json.object_data.name.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break;     
	 	                                           
	 	}
	 	return task_title;
	 }
	 getTaskDescription(row) {
	 	let task_json = JSON.parse(row.task_json);
	 	let task_description = '';
	 	switch(row.module_id) {
	 	  case 'filechecker' : task_description = 'description' in task_json.object_data ? task_json.object_data.description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                       break;
	 	  case 'car' : task_description = 'description' in task_json.object_data ? task_json.object_data.description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                        break;
	 	  case 'rcm' : task_description = 'description' in task_json.object_data ? task_json.object_data.description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break
	 	  case 'cmp' :  task_description = 'description' in task_json.object_data ? task_json.object_data.description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                       break;
	 	  case 'conflicts' : task_description = 'description' in task_json.object_data ? task_json.object_data.description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break;
	 	  case 'padreg' : task_description = ('headerinfo' in task_json.object_data && 'asset' in task_json.object_data.headerinfo) ? task_json.object_data.headerinfo.asset.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break;
	 	  case 'finpromo' : task_description = ('description' in task_json.object_data) ? task_json.object_data.description : "";
	 	  if(Utils.isBase64(task_description)){
	 	    task_description = window.decodeURIComponent(atob(task_description))
	 	  }
	 	                      break;
	 	  case 'committees' : task_description = ('headerinfo' in task_json.object_data && 'action_json' in task_json.object_data.headerinfo && 'action_description' in task_json.object_data.headerinfo.action_json) ? task_json.object_data.headerinfo.action_json.action_description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : ""; 
	 	                      break;            
	 	  case 'tmview' : task_description = 'description' in task_json.object_data  ? task_json.object_data.description : "";
	 	  if(Utils.isBase64(task_description)){
	 	    task_description = window.decodeURIComponent(atob(task_description))
	 	  }
	 	  break;
	 	  case 'docmgmt' : task_description = 'description' in task_json.object_data ? task_json.object_data.description.replace(/(&rsquo;)/g, "'").replace(/'+/g,"'") : "";
	 	                      break;    
	 	                                           
	 	}
	 	return task_description.replace( /(<([^>]+)>)/ig, '');
	 }

	 getTaskCompleteNote(row) {
	     let task_json = JSON.parse(row.task_json);
	     let completed_note = ('completed_note' in task_json.object_data) ? task_json.object_data.completed_note : "";
	     return completed_note;
	 }

	 preferedColumns() {
	 	let prefered_columns ={};
	 	prefered_columns['csv_status'] = 'Status';
	 	prefered_columns['task'] = 'Task Name';
	 	prefered_columns['description'] = 'Task Description';
	 	if(Store.getStoreData("has_gc")){
	 	  prefered_columns['company_name'] = 'Company';
	 	}
	 	prefered_columns['csv_assign_to'] = 'Assigned To';
	 	prefered_columns['module_id'] = 'Source';
	 	prefered_columns['csv_created_by'] = 'Created by';
	 	prefered_columns['csv_created_date'] = 'Date Created';
	 	prefered_columns['due_date'] = 'Due Date';
	 	prefered_columns['days_overdue'] = 'Days Overdue';
	 	prefered_columns['csv_flagged'] = 'Flagged Y/N';
	 	return prefered_columns;
	 }

	 preferedCompleteColumns() {
	 	let prefered_completed_columns = {};
	 	prefered_completed_columns['csv_status'] = 'Status';
	 	prefered_completed_columns['task'] = 'Task Name';
	 	prefered_completed_columns['description'] = 'Task Description';
	 	if(Store.getStoreData("has_gc")){
	 	  prefered_completed_columns['company_name'] = 'Company';
	 	}
	 	prefered_completed_columns['csv_assign_to'] = 'Assigned To';
	 	prefered_completed_columns['module_id'] = 'Source';
	 	prefered_completed_columns['csv_created_by'] = 'Created by';
	 	prefered_completed_columns['due_date'] = 'Due Date';
	 	prefered_completed_columns['csv_created_date'] = 'Date Created';
	 	prefered_completed_columns['completed_date'] = 'Task Completion Date';
	 	prefered_completed_columns['days_overdue'] = 'Days Overdue';
	 	prefered_completed_columns['completed_note'] = 'Task Completion Text';
	 	prefered_completed_columns['csv_flagged'] = 'Flagged Y/N';
	 	return prefered_completed_columns;
	 }

	overDueCalculation(due_date, completed_date) {
		// console.log("overDueCalculation", due_date, completed_date)
		let date1 = new Date(due_date);
		let date2 = new Date(completed_date);
		console.log("overDueCalculation", date2)
		let Difference_In_Time = date2.getTime() - date1.getTime();
		let dues_day = 0
		if(Difference_In_Time > 0) {
			dues_day = Math.round(Difference_In_Time / (1000 * 3600 * 24));
		}
		return dues_day
	}

	DayestoComplete(due_date) {
		let date1 = new Date(due_date);
		let today = new Date();
		let yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedToday = mm + '/' + dd + '/' + yyyy;
		let date2 = new Date(formattedToday)
		let Difference_In_Time =  date2.getTime() - date1.getTime();
		let dues_day = 0
		if(Difference_In_Time > 0) {
			dues_day = Math.round(Difference_In_Time / (1000 * 3600 * 24));
		}
		return dues_day
	}

	preferedGCColumns() {
		let x = {}
		x['company_id'] = 'Company'
		x['open'] = 'Open'
		x['overdue'] = 'Overdue'
		x['flagged'] = 'Flagged'
		x['all'] = 'All'
		return x
	}

	preferedEMPColumns() {
		let x = {}
		x['emp_id'] = 'Employee'
		x['company'] = 'Company'
		x['open'] = 'Open'
		x['overdue'] = 'Overdue'
		x['flagged'] = 'Flagged'
		x['all'] = 'All'
		return x
	}


}

export default new RegisterHelper();