import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";

import "./table_styles.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderContainer = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    /*color: #c0c0c0;*/
	color: #000000;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
    float: left;
    margin-right: 5px;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
`

class CSLTable extends React.Component
{

    state = {toggle_state: true, ready: false};

    constructor(props) {
        super(props);
    }

   componentDidMount() {
	    let toggle_state = this.state.toggle_state;
        if ("toggle_state" in this.props) toggle_state = this.props.toggle_state;
		this.setState({toggle_state, ready: true});
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
			let toggle_state = this.state.toggle_state;
			if ("toggle_state" in this.props) toggle_state = this.props.toggle_state;
			this.setState({toggle_state, ready: true});
        }
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }


	render() {
		console.log('this.props', this.props , this.state);
		if(!this.state.ready)return <div></div>
		let tableData = this.props.processData();
		console.log("tableData===>",tableData)
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
		//console.log('tableData', row_count);
		return (
			<TableContainer>
			    <HeaderContainer>
                    <div >{this.props.headerText}</div>
					{
						(() => {
							if ("toggle_text" in this.props === true) {
								return (
									<div style={{float: "right" , marginTop : "-15px"}}>
										<div style={{float: "left"}}>{this.props.toggle_text}</div>
										<div style={{float: "right", marginLeft: "5px", marginTop : "-5px"}}>
										{
											(() => {
												if (this.state.toggle_state === true) {
													return (
														<div>
															<SelectedRadio>Yes</SelectedRadio>
															<UnselectedRadio onClick={this.props.toggleCallback}>No</UnselectedRadio>
														</div>
													);
												} else {
													return (
														<div>
															<UnselectedRadio onClick={this.props.toggleCallback}>Yes</UnselectedRadio>
															<SelectedRadio>No</SelectedRadio>
														</div>
													);
												}
											})()
										}
										</div>
										<div style={{clear: "both"}}></div>
									</div>
								);
							}
						})()
					}
                </HeaderContainer>                    
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                }                    
                {
                    (() => {
                        if (typeof this.props.showPagination !== 'undefined' && this.props.showPagination === false) {
                            return (
									<div style={{marginTop: '5px', width:'100%'}}>
										<ReactTable
											PaginationComponent={Pagination}
											data={tableData.data}
											columns={tableData.columns}
											/*defaultPageSize={parseInt(row_count)}*/
                                            filterable={false}
                                            showPagination={false}
                                            minRows={1}
											/*defaultFilterMethod={(filter, row, column) => {
												const id = filter.pivotId || filter.id
												console.log('id',id);
												return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
											}}*/

										/>
									</div>
								);
                        }else{
                            return (
									<div style={{marginTop: '5px', width:'100%'}}>
										<ReactTable
											PaginationComponent={Pagination}
											data={tableData.data}
											columns={tableData.columns}
											defaultPageSize={parseInt(row_count)}
											filterable={true}
											defaultFilterMethod={(filter, row, column) => {
												const id = filter.pivotId || filter.id
												console.log('id',id);
												return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
											}}

										/>
									</div>
								);							
                        }
                    })()
                }
			</TableContainer>
			);
	}
}

export default CSLTable;