import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../../Common/CSLTable';
import DatePicker from 'react-date-picker';
import APICall from '../../Common/APICall';
import CMConflictsApprovalModal from './CMConflictsApprovalModal';
import {IoIosArrowDown, IoIosArrowUp, IoIosTrash} from 'react-icons/io';
import CMAskConflictModal from './CMAskConflictModal';
import FollowupModal from './FollowupModal';
import CSLRegisterTable from '../RDR/CSLRegisterTable';
import reportData from '../RDR/reportData';
import CSLLoader from '../Common/CSLLoader';
import CurrentFollowupListsModal from './CurrentFollowupListsModal';
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox'

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from '../../Common/XcelLoader.js';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const BoxStyle = styled.div`
  float: left;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, .2);
  margin-right: 10px;
`;
const ConflictInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: black;
`;
const ConflictSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #414141;
`;
const CSLDateCover = styled.div`
    height: 20px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #323232;
    margin-bottom: 10px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #323232;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
    cursor: pointer;
`;
const ExportButtonDisable = styled.button`
    box-sizing: border-box;
    background-color: #213e61a1;
    color: #F2F2F2;
    border: 1px solid #213e61a1;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;    
`;

class CMConflictsList extends React.Component
{
    state = {
        isLoaded: false,
        pending_tasks: [],
        advanceFilter: false,
        show_modal: null,
        conflictFilterUser: "0",
        conflictFilterType: "",
        conflictFilterStatus: "",
        filterRequestedFromDate: new Date(),
        filterRequestedToDate: new Date(),
        ready: false,
        conflictsTaskList: null,
        conflictsTaskListFilter: null,
        curConflictsid: 0,
        userRole: "",
        isLoadedIcon: false,
        alert_param: null,
        assignuser: {},
        cOwnerLists: [],
        cOwnerValue: '',
        selectedCompany: '',
        conflictTypeList: []
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let myConflictsTask = Store.getStoreData('mytasks') !== null ? Store.getStoreData('mytasks') : [];
        let updateFromDate = new Date(this.state.filterRequestedFromDate.getFullYear(), this.state.filterRequestedFromDate.getMonth() - 1, 1);
        let task_id = this.props.taskId;
        console.log("task_id3===>",task_id) 

        let updateMyConflictsTask = [];
        for(let d of myConflictsTask){
            if(d[2] !== "COMP_COI_REMOVE"){
                updateMyConflictsTask.push(d)
            }
        }
        // myConflictsTask.forEach((item) => {
        //     if(item[2] !== "COMP_COI_REMOVE"){
        //         updateMyConflictsTask.push(item)
        //     }
        // })
           
        let userrole = Store.getStoreData('role'); 

        let updateMyConflictsTaskNew = []
        if(userrole === 'cm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){            
            for(let d of updateMyConflictsTask){
                if (d[1] !== Store.getStoreData('curCompanyID')) {                    
                    updateMyConflictsTaskNew.push(d);
                } 
            }
            // updateMyConflictsTask.forEach((value) => {
            //     if (value[1] !== Store.getStoreData('curCompanyID')) {                    
            //         updateMyConflictsTaskNew.push(value);
            //     }                
            // })
        }else{            
            updateMyConflictsTaskNew = updateMyConflictsTask;
        }

        let cOwnerObject = {}
        for(let d of updateMyConflictsTaskNew){
            if(d[19] !== ''){
                cOwnerObject[d[19]] = 1
            }
        }
        let contacts = Store.getStoreData('contacts');
        let cOwnerLists = []
        for(let k in cOwnerObject){
            cOwnerLists.push({'id': k, 'name': contacts[k]})
        }
        cOwnerLists = cOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

        let assignuser = Store.getStoreData('task_assign_user');

        let types = Store.getStoreData('conflictstype');
        let conflictTypeList = [];
        for(let k in types){
            if(types[k].isActive === 1){
                let c_obj = {};
                c_obj.id = k
                c_obj.name = types[k].title
                conflictTypeList.push(c_obj);
            }
        }
        conflictTypeList = conflictTypeList.sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} );

        this.setState({ conflictsTaskList: updateMyConflictsTaskNew,conflictsTaskListFilter: myConflictsTask, filterRequestedFromDate: updateFromDate, userRole:userrole,
            assignuser, cOwnerLists, conflictTypeList})
        if(task_id > 0) {
            console.log("task id", task_id)
            this.goToTaskUrlBare(task_id)
        }else{
            this.setState({ready: true})
        }
    }

    componentDidUpdate(prevprops) {
        if(this.props === prevprops)return
        let myConflictsTask = Store.getStoreData('mytasks');

        let updateMyConflictsTask = [];        
        for(let d of myConflictsTask){
            if(d[2] !== "COMP_COI_REMOVE"){
                updateMyConflictsTask.push(d)
            }
        }
        // myConflictsTask.forEach((item) => {
        //     if(item[2] !== "COMP_COI_REMOVE"){
        //         updateMyConflictsTask.push(item)
        //     }
        // })

        let userrole = Store.getStoreData('role'); 
        let updateMyConflictsTaskNew = []
        if(userrole === 'cm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){            
            for(let d of updateMyConflictsTask){
                if (d[1] !== Store.getStoreData('curCompanyID')) {                    
                    updateMyConflictsTaskNew.push(d);
                } 
            }
            // updateMyConflictsTask.forEach((value) => {
            //     if (value[1] !== Store.getStoreData('curCompanyID')) {                    
            //         updateMyConflictsTaskNew.push(value);
            //     }                
            // })
        }else{            
            updateMyConflictsTaskNew = updateMyConflictsTask;
        }
        let cOwnerObject = {}
        for(let d of updateMyConflictsTaskNew){
            if(d[19] !== ''){
                cOwnerObject[d[19]] = 1
            }
        }
        let contacts = Store.getStoreData('contacts');
        let cOwnerLists = []
        for(let k in cOwnerObject){
            cOwnerLists.push({'id': k, 'name': contacts[k]})
        }
        cOwnerLists = cOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

        let assignuser = Store.getStoreData('task_assign_user'); 
        let types = Store.getStoreData('conflictstype');
        let conflictTypeList = [];
        for(let k in types){
            if(types[k].isActive === 1){
                let c_obj = {};
                c_obj.id = k
                c_obj.name = types[k].title
                conflictTypeList.push(c_obj);
            }
        }
        conflictTypeList = conflictTypeList.sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} ); 

        this.setState({ conflictsTaskList: updateMyConflictsTaskNew,conflictsTaskListFilter: myConflictsTask, assignuser, ready: true, cOwnerLists, conflictTypeList })
    }

    goToTaskUrlBare = (taskID) => {    
        let postData = { "command" : "getTaskCallbyTaskID", "task_id" : taskID }
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.processConflictsTaskAPIData);        
    }

    checkDefaultalertparam = (result, stack) => {        
        this.setState({alert_param: null})
    } 

    processConflictsTaskAPIData = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            // alert(msg)
            let alert_param = {title: 'ERROR', message: msg, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
            this.setState({alert_param: alert_param})  
            return false;
          }
        let cur_lane = result.result.parenttask.cur_lane;
        let parentID = result.result.parenttask.id;
        if(cur_lane === 'COMP_COI_FOLLOWUP_ASSIGNMENT' || cur_lane === 'COMP_COI_FOLLOWUP_COMPLETE'){
            this.currentfollowupModal(parentID);
        }else if(cur_lane === 'COMP_CONFLICTS_TEAMSAVE'){
            this.processConflictsSaveTaskData(result);
        }else{
            this.processConflictsTaskData(result);
        }
    }

    currentfollowupModal = (task_id) => {
        //event.preventDefault();
        console.log('ROWS', task_id); 
        let postData = { command: "GetSubTaskCallbyTaskID", task_id: task_id};
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.openCurrentFollowupModal);
    }
    openCurrentFollowupModal = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            // alert(msg)
            let alert_param = {title: 'ERROR', message: msg, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
            this.setState({alert_param: alert_param})  
            return false;
          }
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'current_view_followup_modal',ready: true});
    }
    

    goToTaskUrl = (row) => (event) => {
        event.preventDefault();  
        console.log('ROWS', row);
        this.setState({isLoadedIcon: true})      
        this.showConflictsApprovalModal(row);            
    }

    showConflictsApprovalModal = (row) => {
        //event.preventDefault();        
        if(this.props.taskId > 0) {
            let mytaskdata = Store.getStoreData('mytasks');
            let curlane = "";
            for(let d of mytaskdata){
            //for(let i=0;i<mytaskdata.length;i++){                
                if(d[0] === parseInt(row)){
                    curlane = d[2]
                }
            }            
            console.log("mytaskdata",curlane)
            Store.updateStore('q_index', 1);
            let postData = { "command" : "getTaskCallbyTaskID", "task_id" : row }
            postData['gcs'] = Store.getStoreData('gc_filters');
            let api = new APICall();
            if(curlane === "COMP_CONFLICTS_TEAMSAVE"){
                api.command(postData, this.processConflictsSaveTaskDataNew);
            }else{
                api.command(postData, this.processConflictsTaskDataNew);
            }            
        }else{
            console.log('ROWS', row);
            Store.updateStore('q_index', row.index);
            //console.log("row",row.row.status)            
            console.log("conflictsdata",row.original.id);
            let postData = { "command" : "get_task", "task_id" : row.original.id }
            let api = new APICall();
            if(row.row.status === 'Saved') {
                api.command(postData, this.processConflictsSaveTaskData);
            }else{
                api.command(postData, this.processConflictsTaskData);
            }
        }        
        
    }
    processConflictsSaveTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            // alert(msg)
            let alert_param = {title: 'ERROR', message: msg, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
            this.setState({alert_param: alert_param})  
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "CMSaveConflictModal", ready: true});               
    }
    processConflictsTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;            
            // alert(msg)
            let alert_param = {title: 'ERROR', message: msg, ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkDefaultalertparam, stack: {}}    
            this.setState({alert_param: alert_param})  
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        Store.updateStore('updated_followups', result.result.childtask.result); 
        this.setState({showModal: "CMApprovalConflictModal", ready: true});               
    }
    processConflictsSaveTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "CMSaveConflictModal", ready: true, isLoadedIcon: false});               
    }
    processConflictsTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        let allChildTasks = result.result.childtask.result;
        let update_followuptask = [];
        for(let d of allChildTasks){
            if(d.is_active === 1 && d.is_delete === 0){
                update_followuptask.push(d)
            }
        }        
        Store.updateStore('updated_followups', update_followuptask); 
        this.setState({showModal: "CMApprovalConflictModal", ready: true, isLoadedIcon: false});               
    }
    processMyconflictsValue = () => {
        const has_gc = Store.getStoreData('has_gc');
        let module_config = Store.getStoreData('module_config');
        let isChangeStatusTerminology = 'chg_staus_termilogy' in module_config.general ? module_config.general.chg_staus_termilogy === true ? true : false : false
        var mytaskdata = this.state.conflictsTaskList;        
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[        
        {'Header' : 'Status', accessor: 'status', Cell: row => (
            <div>
                {
                    (() => {
                        if(row.original.status === "Confirmed" || row.original.status === "Closed"){
                            return( <div><BoxStyle style={{backgroundColor: "#04ADA8"}}/><div style={{padding: "1px"}}>{row.original.status}</div></div>)
                        }else{
                            return( <div><BoxStyle style={{backgroundColor: "#234162"}}/><div style={{padding: "1px"}}>{row.original.status}</div></div>)
                        }
                    })()
                }
                
                </div>
          ), width: 120, headerStyle: {textAlign: 'left'}},        
        {'Header' : 'ID', accessor: 'conflictid', Cell: row => (
            <div style={{textDecoration: "underline",color: "#00B5B2", cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>{row.original.conflictid}</div>
          ), width: 100, headerStyle: {textAlign: 'left'}},        
        {Header: 'Conflict Title', accessor: 'conflicts_title', minWidth: 200, headerStyle: {textAlign: 'left'}}, 
        {Header: 'Conflict Date', accessor: 'date_raised', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},  
        {'Header' : 'Tasks', Cell: row => (
            (() => {
                if(row.original.no_of_followup === '00'){
                    return(<div style={{textDecoration: "underline",color: "#00B5B2"}} >{row.original.no_of_followup}</div>)
                }else{
                    return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.goToFollowupTask(row)}>{row.original.no_of_followup}</div>)
                }
            })()            
          ), width: 80, headerStyle: {textAlign: 'left'}},
        {Header: 'Conflict Type', accessor: 'conflicttype', minWidth: 130, headerStyle: {textAlign: 'left'}},        
        {Header: 'Discloser', accessor: 'discloser', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Company', accessor: 'company_name', minWidth: 200, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
        {Header: isChangeStatusTerminology ? 'Closed Date' : 'Confirmed Date', accessor: 'compt_date', minWidth: 100, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},
        {Header: 'Last Updated', accessor: 'discloserdate', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},
        {'Header' : 'Action', Cell: row => (
            <div style={{display: "inline-flex"}}>
                <MRButton style={{cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>View</MRButton>
                {
                (() => {
                    if(this.state.userRole === 'manager' || this.state.userRole === 'admin_manager'){
                        return(<div style={{fontSize: "20px",marginLeft: "5px",cursor: 'pointer'}} onClick={this.deleteConflictsTask(row.original.id)}><IoIosTrash /></div>)
                    }
                })()
                }                
            </div>
          ), width: 120, headerStyle: {textAlign: 'center'}}];

        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = "";
        let conflict_types = Store.getStoreData('module_config').conflict_types
        let assignuser = this.state.assignuser;
        let contacts = Store.getStoreData('contacts');
        for(let d of mytaskdata) {           
            i++;
            gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
            let status = "";
            let numberOfFollowup = 0;            
            if(d[3] !== null){              
            //   numberOfFollowup = JSON.parse(d[3]).length;
                if(d[0] in assignuser){
                    numberOfFollowup = assignuser[d[0]].totalNoOfChildTask;
                }
            }           
            
            if(d[2] === "COMP_CONFLICTS_REGISTER") {
                status =  isChangeStatusTerminology ? 'Open' : "Registered";
            }else if(d[2] === "COMP_CONFLICTS_TEAMSAVE") {
                status = "Saved";
            }else if(d[2] === "COMP_CONFLICTS_COMPLETE") {
                status = isChangeStatusTerminology ? 'Closed' : "Confirmed"
            }else if(d[2] === "COMP_COI_REMOVE") {
                status = "Removed"
            }
                    
            let ref_id = "";            
            if(d[5] !== null) {
                ref_id = d[5];
            } 
            let conflictType = "n/a"            
            if(d[6] !== '') {     
                conflictType = d[6];
                if(!(d[6].match(/[a-z]/i)) && d[6] in conflict_types){
                    conflictType = conflict_types[d[6]].title
                }
            }            
            
            let discloserName = Store.getStoreData('contacts')[d[4]];            
            if(d[8] !== ''){
                discloserName = d[8];
            }    
            let nooffollowup = numberOfFollowup < 10 ? "0"+numberOfFollowup.toString() : numberOfFollowup;             
            
            let gcid = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].id : (parseInt(d[1])%100000)            
            let gcname = "";
            if(gcid in gc_assoc){
                gcname = gc_assoc[gcid];
            }
            let riskDescription = d[9];
            let mitigants = d[10];
            let additionalInformationProvided = d[11] === "Yes" ? "Y" : "N";
            let additionalInformationText = d[12];
            let disclosuretoThirdParty = d[13] === "Yes" ? "Y" : "N";
            let thirdPartyDisclosureText = d[14];

            let conflicts_title = 'n/a';
            if(d[16] !== ''){
                conflicts_title = d[16];
            }

            let qtext_nature_of_conflict = d[15]
            if(Utils.isBase64(d[15])){
                qtext_nature_of_conflict = window.decodeURIComponent(atob(qtext_nature_of_conflict))
            } 
            let qtext_risk_description = d[9]
            if(Utils.isBase64(d[9])){
                qtext_risk_description = window.decodeURIComponent(atob(qtext_risk_description))
            } 
            let qtext_mitigants = d[10]
            if(Utils.isBase64(d[10])){
                qtext_mitigants = window.decodeURIComponent(atob(qtext_mitigants))
            } 
            let qtext_additional_information_text = d[12]
            if(Utils.isBase64(d[12])){
                qtext_additional_information_text = window.decodeURIComponent(atob(qtext_additional_information_text))
            } 
            let qtext_third_party_disclosure_text = d[14]
            if(Utils.isBase64(d[14])){
                qtext_third_party_disclosure_text = window.decodeURIComponent(atob(qtext_third_party_disclosure_text))
            } 
            let regex = /(<([^>]+)>)/ig            

            let elem = {
                    'id': d[0],
                    'status' : status, 
                    'conflictid' : ref_id,
                    'company_name' : gc_company,
                    'conflict' : qtext_nature_of_conflict.replace(regex, "").replace(/&nbsp;/g,""),
                    'conflicttype' : conflictType,
                    'discloser' : discloserName,
                    'discloserdate' : moment.unix(d[7]).format('DD/MM/YYYY'),
                    'no_of_followup' : nooffollowup,
                    'group_company_name' : gcname,
                    'nature_of_conflict' : qtext_nature_of_conflict.replace(regex, "").replace(/&nbsp;/g,""),
                    'risk_description' : qtext_risk_description.replace(regex, "").replace(/&nbsp;/g,""),
                    'mitigants' : qtext_mitigants.replace(regex, ""),
                    'additional_information_provided' : additionalInformationProvided,
                    'additional_information_text' : qtext_additional_information_text.replace(regex, "").replace(/&nbsp;/g,""),
                    'disclosure_to_third_party' : disclosuretoThirdParty,
                    'third_party_disclosure_text' : qtext_third_party_disclosure_text.replace(regex, "").replace(/&nbsp;/g,""),
                    'conflicts_title': conflicts_title,
                    'date_raised' : d[17] !== '' ? moment.unix(d[17]).format('DD/MM/YYYY') : '',  
                    'additional_fields': d[18],
                    'conflicts_owner': d[19] !== '' ? contacts[d[19]] : '',
                    'created_at': moment.unix(d[20]).format('DD/MM/YYYY'),
                    'compt_date': d[2] === "COMP_CONFLICTS_COMPLETE" ? moment.unix(d[21]).format('DD/MM/YYYY') : '-',
                    'last_updated_by': d[22],           
                    'reason_for_removal': d[23],           
                    'removal_description': d[24],           
                   };
            ret.data.push(elem);
            
            // let sarr = [];
            // ret.data.forEach((item) => {
            //     if (item.status !== "Confirmed" && item.status !== "Removed") {
            //         sarr.push(item);
            //     }
            // })            
            // ret.data.forEach((item) => {
            //     if (item.status === "Confirmed") {
            //         sarr.push(item);
            //     }
            // })
            // ret.data.forEach((item) => {
            //     if (item.status === "Removed") {
            //         sarr.push(item);
            //     }
            // })
            // ret.data = sarr;
        }
        ret.data = ret.data.sort((a, b) => {
            if (a.discloserdate === b.discloserdate) {
              return 0;
            }
            let adue = a.discloserdate.split("/");
            const aReverse =
              parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.discloserdate.split("/");
            const bReverse =
              parseInt(bbdue[2]) * 10000 +
              parseInt(bbdue[1]) * 100 +
              parseInt(bbdue[0]);
            return aReverse < bReverse ? 1 : -1;
          });
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }

    deleteConflictsTask = (taskid) => (event) => {
        event.preventDefault();          
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.deleteConflictsAlert, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
        // if (window.confirm('Are you sure you want to delete this record?')) {
        //     console.log('ROWS', taskid);        
        //     let postData = {command: "remove_task", task_id: taskid };
        //     console.log("postData",postData)            
        //     let api = new APICall();
        //     this.setState({isLoadedIcon: true})
        //     api.command(postData, this.processRemoveConflictsData);
        // }       
    }

    deleteConflictsAlert = (result,stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})
        console.log('ROWS', stack.taskid);        
        let postData = {command: "remove_task", task_id: stack.taskid };                   
        let api = new APICall();        
        api.command(postData, this.processRemoveConflictsData);    
    }

    processRemoveConflictsData = (result) => {
        console.log("result", result)
        // alert('Conflict deleted successfully');
        let alert_param = {title: 'Success', message: 'Conflict deleted successfully.', ok_text: 'Ok', confirm: false, 
                alertHandler: this.okAfterComplete, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        // window.location.reload();
    }

    okAfterComplete = (result, stack) => {        
        this.setState({alert_param: null})
        window.location.reload();
    }

    goToFollowupTask = (row) => (event) => {
        event.preventDefault();  
        let command_data = { "command": "listfollowups", parent_id: row.original.id };        
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(command_data, this.updateFollowups(row.original.conflictid));    
    }    
    updateFollowups = (conflictid) => (result) => {
        Store.updateStore('current_followup_lists', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        //this.setState({child_task : result.result });
        this.setState({ showModal: "current_followup_list", curConflictsid: conflictid, isLoadedIcon: false});  
    }

    processMyfollowupValue = () => {
        var followup_tasks = Store.getStoreData('followup_tasks');
        console.log("followup_tasks",followup_tasks)
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
            {Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Status', accessor: 'status', width: 120, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}, 
            {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
            {'Header' : 'Action', Cell: row => (
               <div>
                   <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showFollowUp(row.original.id)}>View</MRButton>
               </div>
            ), headerStyle:{textAlign: 'left'}}];
        for(let d of followup_tasks) {
            i++;                     
            let today = moment().unix();
            let text = (d[3]+86400) > today ? 'Pending' : 'Overdue';            
            let color = (d[3]+86400) > today ? '#2E8B57' : '#FF0000';
            let task = Store.getStoreData('contacts')[parseInt(d[4])] +" has been assigned a task titled "+ d[5];            
            let elem = {'task' : task,
                        'assigned_to' : Store.getStoreData('contacts')[parseInt(d[4])],
                        'status' : {text: text, color: color},
                        'due_date' : moment.unix(d[3]).format('DD/MM/YYYY'),
                        'id' : d[0]
                   };
            ret.data.push(elem);
        }        
        return ret;
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal'});
    }

    advanceFiltershow = (val) => {        
        //console.log("Sourav",abc)
        this.setState({advanceFilter: val})
    }
    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }
    closeForApproval = (event) => {
        //event.preventDefault();
        let postData = { "command": "refreshTask" };
        let api = new APICall();
        api.command(postData, this.processTask);        
    }
    processTask = (result) => {
        console.log("result==>",result)  
        let myConflictsTask = result.result['my_tasks'];
        let userrole = Store.getStoreData('role'); 
        let updateMyConflictsTaskNew = []
        if(userrole === 'cm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){            
            myConflictsTask.forEach((value) => {
                if (value[1] !== Store.getStoreData('curCompanyID')) {                    
                    updateMyConflictsTaskNew.push(value);
                }                
            })
        }else{            
            updateMyConflictsTaskNew = myConflictsTask;
        }     
        this.setState({
            conflictsTaskList: updateMyConflictsTaskNew,
            assignuser: result.result['task_assign_user'],
            showModal: null
        })
    }

    exportToCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",status: "Status",conflictid: "ID",conflict:"Conflict",conflicttype: "Conflict Type", discloser: "Discloser", no_of_followup: "Task",discloserdate: "Date", 
        group_company_name: "Company Name", nature_of_conflict: "Please describe the nature of the Conflict", risk_description: "Risk Description", mitigants: "Mitigants",
        additional_information_provided: "Additional Information Provided", additional_information_text: "Additional Information Text", disclosure_to_third_party: "Disclosure to Third Party",
        third_party_disclosure_text: "Third Party Disclosure Text"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "conflict" : '"' + cur_table_data[k].conflict.replace(/,/g, '--comma--') + '"',
                "conflictid" : cur_table_data[k].conflictid ,
                "conflicttype" : cur_table_data[k].conflicttype ,
                "discloser" : cur_table_data[k].discloser ,
                "no_of_followup" : cur_table_data[k].no_of_followup,
                "discloserdate" : cur_table_data[k].discloserdate ,                
                "status" : cur_table_data[k].status ,
                "group_company_name" : cur_table_data[k].group_company_name, 
                "nature_of_conflict" : '"' + cur_table_data[k].nature_of_conflict + '"',        
                "risk_description" : '"' + cur_table_data[k].risk_description + '"',
                "mitigants" : '"' + cur_table_data[k].mitigants + '"',
                "additional_information_provided" : cur_table_data[k].additional_information_provided,
                "additional_information_text" : '"' + cur_table_data[k].additional_information_text + '"',                
                "disclosure_to_third_party" : cur_table_data[k].disclosure_to_third_party,
                "third_party_disclosure_text" : '"' + cur_table_data[k].third_party_disclosure_text + '"',
                "id" :  cur_table_data[k].id 
                
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];       
      
        //  let headerCSV = {id: "1234",status: "Status",conflictid: "ID",conflict:"Conflict",conflicttype: "Conflict Type", discloser: "Discloser",discloserdate: "Date"}
        // cur_table_data.unshift(headerCSV);
        console.log(cur_table_data.length)
        // cur_table_arr.push(headerCSV);
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0); 
        //   container.shift(); 
            container.pop() 
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8," 
            + cur_table_arr.map(e => e.join(",")).join("\n");
        //console.log("okkk", cur_table_arr.map(e => e.join(",")).join("\n").replace(/--comma--/g, ','))
        const encodedUri = encodeURI(csvContent);
        const fixedEncodedURI = encodedUri.replaceAll('#', '%23');
        var link = document.createElement("a");
        link.setAttribute("href", fixedEncodedURI);
        link.setAttribute("download", "Conflicts_Register_Team.csv");
        document.body.appendChild(link); 
        link.click();
      }
      handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }
    onRequestedFromDate = (date) => {
        this.setState({filterRequestedFromDate: date});
    }
    onRequestedToDate = (date) => {
        this.setState({filterRequestedToDate: date});
    }
    filterClear = () => {        
        console.log("Sourav")
        let filterFromdate = new Date();
        let updateFromDate = new Date(filterFromdate.getFullYear(), filterFromdate.getMonth() - 1, 1);
        this.setState({ conflictFilterUser: "0",
            conflictFilterType: "",
            conflictFilterStatus: "",
            filterRequestedFromDate: updateFromDate,
            filterRequestedToDate: new Date()})
    }
    
    filterSubmit = () => {        
        console.log("Sourav",this.state.filterRequestedFromDate)
        let conflictTaskAll = this.state.conflictsTaskListFilter
        console.log("conflictsTaskListFilter",conflictTaskAll);
        //USER section
        let filterconflict = [];
        if (this.state.conflictFilterUser === "0") {
            filterconflict = conflictTaskAll;
        } else {
            conflictTaskAll.forEach((item) => {
                if (item[4].toString() === this.state.conflictFilterUser) {
                    filterconflict.push(item);
                }
            })
        }
        //USER section
        //Company section
        let filterCompanys = [];
        if (this.state.selectedCompany === "") {
            filterCompanys = filterconflict;
        } else {			
            filterconflict.forEach((item) => {                
                let companyid = item[1];                           
                if (companyid.toString() === this.state.selectedCompany.toString()) {
                    filterCompanys.push(item);
                }
            })
        }
        //Company section
        
        let conflictFilterTypes = [];
        if (this.state.conflictFilterType === "") {
            conflictFilterTypes = filterCompanys;
        } else {
            filterCompanys.forEach((item) => {                 
                let conflictType = "n/a"
                if(item[6] !== '') {
                    conflictType = item[6];
                }
                if (this.state.conflictFilterType.toString() === conflictType.toString()) {
                    conflictFilterTypes.push(item);
                }
            })
        }
        let statusTypes = [];
        if (this.state.conflictFilterStatus === "") {
            statusTypes = conflictFilterTypes;
        } else {
            conflictFilterTypes.forEach((item) => {
                let status = "";
                if(item[2] === "COMP_CONFLICTS_REGISTER") {
                    status =  "Registered";
                }else if(item[2] === "COMP_CONFLICTS_TEAMSAVE") {
                    status = "Saved";
                }else if(item[2] === "COMP_CONFLICTS_COMPLETE") {
                    status = "Confirmed"
                }else if(item[2] === "COMP_COI_REMOVE"){
                    status = "Removed"
                }
                if (this.state.conflictFilterStatus === status) {
                    statusTypes.push(item);
                }
            })
        }

        let conflictOwners = [];
        if (this.state.cOwnerValue === "") {
            conflictOwners = statusTypes;
        } else {
            statusTypes.forEach((item) => {     
                if (item[19] !== '' && this.state.cOwnerValue.toString() === item[19].toString()) {
                    conflictOwners.push(item);
                }
            })
        }

        let formdatetoDate = [];
        if (this.state.filterRequestedFromDate === "" && this.state.filterRequestedToDate === "") {
            formdatetoDate = conflictOwners;
        }else{
            conflictOwners.forEach((item) => {
                //let task_json = JSON.parse(item.task_json); 
                let actionDate = item[7];
                let toDate = this.state.filterRequestedToDate;
                toDate.setHours(23);
                toDate.setMinutes(59);
                toDate.setSeconds(59);                
                let endDate = moment(toDate).unix()
                //console.log("actionDate",actionDate,"this.state.filterRequestedFromDate=>",this.state.filterRequestedFromDate,"this.state.filterRequestedToDate=>",endDate)
                if(actionDate > moment(this.state.filterRequestedFromDate).unix() && actionDate < endDate){
                    formdatetoDate.push(item);
                }
            })
        }
        console.log("filterconflict",formdatetoDate.length)
        this.setState({conflictsTaskList: formdatetoDate})
        
    }

    closeTaskModal = (event) => {
        this.props.closeUserDialog();  
    }

    render()
    { 
        let contracts = Store.getStoreData('contacts');
        
        let gcLists = Store.getStoreData('gcid_and_gcname');
        let module_config = Store.getStoreData('module_config');
        let isChangeStatusTerminology = 'chg_staus_termilogy' in module_config.general ? module_config.general.chg_staus_termilogy === true ? true : false : false
        let task_id = this.props.taskId;
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'CMApprovalConflictModal' : return <CMConflictsApprovalModal closeModal={this.closeTaskModal} />; break; 
                            case 'CMSaveConflictModal' : return <CMAskConflictModal closeModal={this.closeTaskModal} />; break;
                            case 'current_view_followup_modal' : return <FollowupModal closeModal={this.closeTaskModal} />; break;                           
                        }
                    }
                })()
                }
            </div>)
        }
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.advanceFilter) {
                            return (<div>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#323232", fontWeight: "600"}}>Advanced Filters</div> 
                                    <div style={{float: "right", color: "#323232", fontSize: "17px",cursor: "pointer"}} onClick={() => this.advanceFiltershow(false)}><IoIosArrowUp /></div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody>
                                    <div style={{display: "inline-block", marginRight: "30px", width: "30%",marginBottom: "10px"}}>
                                        <div style={{fontWeight: "600", color: "#323232", marginBottom: "12px"}}>Company</div>
                                        <ConflictSelect name="selectedCompany" onChange={this.handleChange} value={this.state.selectedCompany}>
                                            <option  value="">select company</option>
                                            {                                               
                                                Object.keys(gcLists).map((d, index) => {
                                                    return (<option key={index} value={d}>{gcLists[d]}</option>);
                                                })
                                            }                    
                                        </ConflictSelect>
                                    </div>
                                    <div style={{display: "inline-block", marginRight: "30px", width: "30%",marginBottom: "10px"}}>
                                        <div style={{fontWeight: "600", color: "#323232", marginBottom: "12px"}}>Conflict Type</div>
                                        <ConflictSelect name="conflictFilterType" onChange={this.handleChange} value={this.state.conflictFilterType}>
                                            <option  value="">select conflict type</option>
                                            {
                                                // Object.keys(conflictsType).map((d, index) => {
                                                //     return (<option key={index} value={conflictsType[d].title}>{conflictsType[d].title}</option>);
                                                // })
                                                this.state.conflictTypeList.map((rc,index) => {
                                                    return(<option key={index} value={rc.id}>{rc.name}</option>);
                                                })
                                            }                    
                                        </ConflictSelect>
                                    </div>
                                    <div style={{display: "inline-block", marginRight: "30px", width: "30%",marginBottom: "10px"}}>
                                        <div style={{fontWeight: "600", color: "#323232", marginBottom: "12px"}}>Status</div>
                                        <ConflictSelect name="conflictFilterStatus" onChange={this.handleChange} value={this.state.conflictFilterStatus}>
                                            <option  value="">select status</option>
                                            <option  value="Registered">{isChangeStatusTerminology ? 'Open' : "Registered"}</option>
                                            <option  value="Confirmed">{isChangeStatusTerminology ? 'Closed' : "Confirmed"}</option>
                                            <option  value="Saved">Saved</option>
                                            <option  value="Removed">Removed</option>                    
                                        </ConflictSelect>
                                    </div>
                                    <div style={{display: "inline-block", marginRight: "30px", width: "30%",marginBottom: "10px"}}>
                                        <div style={{fontWeight: "600", color: "#323232", marginBottom: "12px"}}>Conflict Owner</div>
                                        <ConflictSelect name="cOwnerValue" onChange={this.handleChange} value={this.state.cOwnerValue}>
                                        <option  value="">select conflict owner</option>
                                        {
                                            this.state.cOwnerLists.map((rc,index) => {
                                                return(<option key={index} value={rc.id}>{rc.name}</option>);
                                            })
                                        }              
                                        </ConflictSelect>
                                    </div>
                                    <div style={{display: "inline-block", marginRight: "30px", width: "30%",marginBottom: "10px"}}>
                                        <div style={{fontWeight: "600", color: "#323232", marginBottom: "12px"}}>User</div>
                                        <ConflictSelect name="conflictFilterUser" onChange={this.handleChange} value={this.state.conflictFilterUser}>
                                            <option  value="0">select user</option>
                                            {                                               
                                                Object.keys(contracts).map((d, index) => {
                                                    return (<option key={index} value={d}>{contracts[d]}</option>);
                                                })
                                            }                    
                                        </ConflictSelect>
                                    </div>
                                  <div>
                                        <RegModalIssueDateLabel>Requested Date</RegModalIssueDateLabel>
                                        <div style={{display: "block",width: "100%",height: "auto",float: "left",marginTop: "10px"}}>
                                    
                                            <CSLDateCover style={{display: "inline-block",marginRight: "26px"}}>
                                                <DatePicker onChange={this.onRequestedFromDate}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.filterRequestedFromDate}
                                                />
                                            </CSLDateCover>                                                                        
                                            <CSLDateCover style={{display: "inline-block"}}>
                                                <DatePicker onChange={this.onRequestedToDate}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.filterRequestedToDate}
                                                />
                                            </CSLDateCover>
                                        </div>
                                    </div>  
                                    <div style={{float: "right"}}>
                                    <RegCancelButton onClick={this.filterClear}>Clear</RegCancelButton>
                                    <RegSubmitButton onClick={this.filterSubmit}>Submit</RegSubmitButton>
                                        </div> 
                                        <div style={{clear: "both"}}></div>
                                </OverviewInfoBody>
                            </div>)
                        } else {
                            return (<div>
                                <OverviewInfoHeader>
                                <div style={{float: "left", color: "#323232", fontWeight: "600"}}>Advanced Filters</div> 
                                <div style={{float: "right", color: "#323232", fontSize: "17px", cursor: "pointer"}} onClick={() => this.advanceFiltershow(true)}><IoIosArrowDown /></div>
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>
                            </div>)
                        }
                    })()
                }
                
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#323232", fontWeight: "600"}}>Conflicts Register</div>                       
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    {/*<CSLTable 
                        add={false} 
                        processData={this.processMyconflictsValue} 
                        headerText={''} 
                        tableRows="10" 
                        refreshCallback={this.refreshCallback} 
                    /> */} 
                    <CSLRegisterTable
                        data={reportData}
                        processData={this.processMyconflictsValue}
                        reportButton={true}
                        headerText={''}
                        headerButtons={{}}
                        tableRows="10" 
                        buttonSet={{}}
                        fields={this.state.fields}
                        moduleId='conflicts'
                        export={true}
                    />                  
                    <div style={{float: "left"}}>
                    </div>
                    <div style={{float: "left", color: "#04ADA8", fontWeight: "600", fontStyle: "italic",marginTop: "21px",marginLeft: "4px"}}>{this.state.conflictsTaskList.length} Conflicts Listed</div>
                    <div style={{clear: "both"}}></div>                    
                </OverviewInfoBody>                
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'CMApprovalConflictModal' : return <CMConflictsApprovalModal closeModalForApproval={this.closeForApproval} closeModalNormal={this.closeModal}/>; break;
                            case 'CMSaveConflictModal' : return <CMAskConflictModal closeModal={this.closeModal} />; break;
                            case 'view_followup_modal' : return <FollowupModal closeModal={this.closeModal} />; break;
                            case 'current_followup_list' : return <CurrentFollowupListsModal curConflictsid={this.state.curConflictsid} closeModal={this.closeModal} closeModalandRefresh={this.closeForApproval}/>; break;
                        }
                    }
                })()
                }
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}
export default CMConflictsList;