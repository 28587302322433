import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #949494;
`;
const ModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #f7f7f7;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 56px;
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 72px;
    display:inline;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:7px;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const ModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 20% calc(80% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;


const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;
    margin-left: 50px;
`;


const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
`;


const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

class ShowAskModal extends React.Component
{
    state = {
        query: '',
        binFiles: [],
        cur_files_json: null,
        index: -1,
        form_data: null,
        Ref_ID: null,
        gc_companies: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        tags:[]
    };

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        Store.deRegisterCallback('changeTaskaction', this.component_id);
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }


    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_company');
        let is_gc = false;
        for(let gc of gc_companies) {
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
            }
        }
        this.setState({ cur_gc_id: evt.target.value, is_gc:is_gc});
    }



    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    addAttachment = () => {

    }

    filesLoaded = (files) => {
        //console.log('files in Modal', files);
    }

    handleDownload = (index) => {
        let cur_file = this.state.cur_files_json[index];
        // console.log('cur_file.bin_file', (cur_file.bin_file));
        // console.log('this.state', this.state);
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        //console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        //console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    selectOrUnselectTags = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags: tags });
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }



    componentDidMount() {
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log("index", index)
        let contact_id = Store.getStoreData('contact_id');
        let gc_companies = Store.getStoreData('gc_company');
        let cur_gc_id = gc_companies[0].id;
        let is_gc = gc_companies[0].is_gc;
        let ptags = {};
        let tags = this.isLoadTags(ptags);
        let form_data = {};
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            console.log('form_data_Rimpa', form_data);
            let task_json = JSON.parse(form_data.task_json);
            let ref_id = '';
            if('ref_id' in task_json.object_data) {
                ref_id = task_json.object_data.ref_id;
            }
            if('is_gc' in task_json.object_data) {
                is_gc = task_json.object_data.is_gc;
            }
            if('tags' in task_json.object_data) {
                ptags = task_json.object_data.tags;
            }
            
            tags = this.isLoadTags(ptags)

            let gc_company = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;

            this.setState({ cur_files_json: task_json.object_data.bin_files, index: index, form_data:form_data, query: task_json.object_data.query, Ref_ID:ref_id, gc_companies: gc_companies, ready: true,cur_gc_id:gc_company,is_gc:is_gc, tags: tags });
            
        }else{            
            this.setState({ Ref_ID: '', gc_companies: gc_companies, ready: true, cur_gc_id: cur_gc_id, is_gc:is_gc, tags: tags});            
        }
    }


    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }



    render()
    {
         console.log(this.state.cur_gc_id)
         if(!this.state.ready) {
             return(<div>Loading...</div>);
         }
        return (
            <ModalContainer>
                <ModalHeaderText>Show Query</ModalHeaderText>
                
                <ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <div style={{paddingTop: '40px'}}>
                <ModalNameLabelDivReference>CAR Reference</ModalNameLabelDivReference>
                <ModalNameLabelDivReferenceID>{this.state.Ref_ID}</ModalNameLabelDivReferenceID>
                </div>
                {
                    (() => {
                        if(Store.getStoreData('has_gc')) {
                            return(<div style={{display:"contents"}}>
                                        <div style={{paddingTop: '40px'}}>
                                        <ModalNameLabelDivReference>Select Companies </ModalNameLabelDivReference>
                                            <MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc} readOnly={true}>
                                                {
                                                        this.state.gc_companies.map((gc) => {
                                                        return(
                                                        <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                        );
                                                    })
                                                }
                                            </MRModalSelect>
                                        </div>
                                    </div>
                                )
                        }
                    })()
                }
                <ModalBody>
                    <ModalBodyInner>
                        <ModalContainertop>
                            <ModalNameLabelDiv>Query</ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                <ModalTextArea name="query" value={this.state.query.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={true} />
                            </ModalNameInputDiv>

                                    <ModalNameLabelDiv>Existing Attachments</ModalNameLabelDiv>
                                    <ModalNameInputDiv style={{ minHeight:'100px', height: '100px', marginLeft:'0px', display:'contents'}}>
                                    {
                                        (() => {
                                            if(this.state.cur_files_json !== null) {
                                                return(
                                                    <div style={{width:'100%', display: 'flex', overflow: 'auto', minHeight: 140}}>
                                                    {
                                                        this.state.cur_files_json.map((f, index) => {
                                                            //console.log('f',f);
                                                            let file_parts = f.name.split('.');
                                                            let ext = 'PDF';
                                                            switch(file_parts[file_parts.length - 1]) {
                                                                case 'jpeg':
                                                                case 'jpg' : ext = 'JPG';break;
                                                                case 'png' : ext = 'PNG'; break;
                                                                case 'docx' : ext = 'DOCX'; break;
                                                                case 'doc' : ext = 'DOC'; break;
                                                                case 'msg' : ext = 'MSG'; break;
                                                                case 'txt' : ext = 'TXT'; break;
                                                                case 'ppt' : ext = 'PPT'; break;
                                                                case 'pptx' : ext = 'PPTX'; break;
                                                                case 'xls' : ext = 'XLS'; break;
                                                                case 'xlsx' : ext = 'XLSX'; break;
                                                                default: ext = 'PDF';
                                                            }
                                                            //console.log('ext',ext);
                                                            return(
                                                                <FileContainer key={index}>
                                                                  <FileTypeContainer>{ext}</FileTypeContainer>
                                                                  <FileInfoContainer>
                                                                      <strong style={{color: '#000000'}}>{f.name}</strong><br /><br />
                                                                      Added just now<br /><br />
                                                                      <a style={{ cursor: 'pointer'}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</a> &nbsp; 
                                                                  </FileInfoContainer>
                                                                </FileContainer>
                                                              )

                                                        })
                                                    }
                                                    </div>
                                                )
                                            } else {
                                                return <div>No existing attachments.</div>
                                            }
                                        })()

                                    }
                            
                            </ModalNameInputDiv>

                            <ModalNameLabelDiv style={{ minHeight:'100px', height: '100px'}}>Avilable Tags</ModalNameLabelDiv>
                            <ModalNameInputDiv style={{ height: '100%', width:'100%', backgroundColor:'#fff'}}>
                                {
                                    this.state.tags.map((tag, index) => {
                                        return (<tag.styledcomponent key={index}>{tag.name}</tag.styledcomponent>);
                                    })
                                }
                            </ModalNameInputDiv>
                        </ModalContainertop>
                    </ModalBodyInner>
                </ModalBody>
                <CancelSubmitButtonOuterContainer>
                    <CancelButton onClick={this.closeModal}>Cancel</CancelButton>
                </CancelSubmitButtonOuterContainer>
            </ModalContainer>
        );
    }
}
export default ShowAskModal;