import React from 'react';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose} from 'react-icons/io';
import styled from 'styled-components';

const PillsContainer = styled.div`
	min-height: 40px;
	width: 100%;
	border: 1px solid #BABABA;
	border-radius: 4px;
	box-sizing: border-box;
	padding-bottom: 5px;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    // position: absolute;
	position: inherit;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`;
const Option = styled.div`
	padding: 10px 5px;
	border-bottom: 1px solid #DBDBDB;
	cursor: pointer;
	&:focus {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
	&:active {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;
const Pill = styled.div`
	padding: 5px;
	box-sizing: border-box;
	background-color: #2F496C;
	color: #ffffff;
	font-size: 11px;
	margin-left: 3px;
	float: left;
	border: 1px solid #2F496C;
	border-radius: 4px;
	margin-top: 5px;
`;
const PillText = styled.div`
	box-sizing: border-box;
	float: left;
`;
const PillCross = styled.div`
	box-sizing: border-box;
    float: right;
    cursor: pointer;
    font-size: 13px;
    margin-left: 5px;
`;
const SearchBoxDiv = styled.div`
	float: left;
    margin-left: 5px;
    box-sizing: border-box;
    position: relative;
    margin-top: 5px;
`;
const SearchBox = styled.input`
	background-color: #ffffff;
	border: none;
	font-family: 'Montserrat', sans-serif;
	width: 90px;
	&:focus {
		outline-width: 0;
	}
`;

class CslTypeAhead extends React.Component
{
	state = {options: null, options_toshow: null, selected_options: [], show_suggestion: false, searchtext: ""};

	componentDidMount()
	{
        document.addEventListener('mousedown', this.handleClickOutside);
        console.log("this.props.data",this.props.data);
		let options = JSON.parse(JSON.stringify(this.props.data));
		options.sort((a, b) => a.label.localeCompare(b.label))
        console.log(options);        
		this.setState({options, options_toshow: options});
	}

	componentWillUnmount() {
	    document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if(this.props.isclear){
				console.log("Sourav2")
				const options = JSON.parse(JSON.stringify(this.state.options));    
				this.props.changeClear()
                this.setState({selected_options: [], options_toshow: options})
            }
        }
    }

	setWrapperRef = (node) => {
	    this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		if (this.state.show_suggestion && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			// alert('You clicked outside of me!');
			this.setState({show_suggestion: false, searchtext: ""});
		}
	}

	searchFocused = (event) => {
		event.preventDefault();
		let options = JSON.parse(JSON.stringify(this.state.options));
		let selected_options = JSON.parse(JSON.stringify(this.state.selected_options));
		let options_toshow = [];
		if (selected_options.length === 0) {
			options_toshow = options;
		} else {
			let selected_arr = [];
			selected_options.forEach((item) => {
				selected_arr.push(item.id);
			})
			options.forEach((option) => {
				if (!(selected_arr.includes(option.id))) {
					options_toshow.push(option);
				}
			})
		}
		options_toshow.sort((a, b) => a.label.localeCompare(b.label))
		this.setState({options_toshow, show_suggestion: true});
	}

	selectThis = (itemid) => (event) => {
		event.preventDefault();
		let options = JSON.parse(JSON.stringify(this.state.options));
		let selected_options = JSON.parse(JSON.stringify(this.state.selected_options));
		let options_toshow = JSON.parse(JSON.stringify(this.state.options_toshow));
		selected_options.push(options.find((item) => {
			return item.id === itemid
		}))
		let remove_index = 0;
		options_toshow.forEach((item, index) => {
			if (item.id === itemid) {
				remove_index = index;
			}
		})
		options_toshow.splice(remove_index, 1);
        this.setState({selected_options, options_toshow, show_suggestion: false, searchtext: ""});
        this.props.ricipientValue(selected_options);
	}

	removeThis = (itemid) => (event) => {
		event.preventDefault();
		let options = JSON.parse(JSON.stringify(this.state.options));
		let selected_options = JSON.parse(JSON.stringify(this.state.selected_options));
		let options_toshow = JSON.parse(JSON.stringify(this.state.options_toshow));
		let remove_index = 0;
		selected_options.forEach((item, index) => {
			if (item.id === itemid) {
				remove_index = index;
			}
		})
		selected_options.splice(remove_index, 1);
		options_toshow.push(options.find((item) => {
			return item.id === itemid
		}));
		options_toshow.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({selected_options, options_toshow});
        this.props.ricipientValue(selected_options);
	}

	searchData = (event) => {
		event.preventDefault();
		const search_string = event.target.value;
		const re = new RegExp(search_string, 'gi');
		let options = JSON.parse(JSON.stringify(this.state.options));
		let selected_options = JSON.parse(JSON.stringify(this.state.selected_options));
		let options_toshow = JSON.parse(JSON.stringify(this.state.options_toshow));
		let new_options_toshow = [];
		if (search_string === "") {
			options_toshow = options;
		} else {
			options.forEach((option) => {
				if (option.label.match(re) !== null) {
					new_options_toshow.push(option);
				}
			})
			options_toshow = new_options_toshow;
		}
		options_toshow.sort((a, b) => a.label.localeCompare(b.label));
		this.setState({options_toshow, searchtext: search_string});
	}

	render()
	{

		return (
			<div ref={this.setWrapperRef} style={{cursor: "text"}} onClick={() => {this.myInp.focus()}}>
				<PillsContainer>
					{
						this.state.selected_options.map((item) => {
							return (
								<Pill key={item.id}>
									<PillText>{item.label}</PillText>
									<PillCross onClick={this.removeThis(item.id)}><IoIosClose /></PillCross>
									<div style={{clear: "both"}}></div>
								</Pill>
							);
						})
					}
					<SearchBoxDiv><SearchBox ref={(ip) => this.myInp = ip} onFocus={this.searchFocused} onChange={this.searchData} value={this.state.searchtext} /></SearchBoxDiv>
					<div style={{clear: "both"}}></div>
				</PillsContainer>
				{
					(() => {
						//console.log("this.state.show_suggestion",this.state.show_suggestion)
						if (this.state.show_suggestion) {
							return (
								<OptionsContainer>
									{
										this.state.options_toshow.map((item) => {
											return (<Option key={item.id} id={item.id} onClick={this.selectThis(item.id)}>{item.label}</Option>)
										})
									}
								</OptionsContainer>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default CslTypeAhead;