import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';

const CSLDateCover = styled.div`
  margin-top: 25px;
  width: 50%;
  border-radius: 5px;
  background-color: #ffffff;
  height: 35px;
  padding-top: 5px;
  padding-left: 10px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 0px;
`;
class ManageParameter extends React.Component
{
	state = {
		task: {},
		task_id: 0,
		task_name: "",
		description: "",
		ready: false,
		alert_param: null,
		frequency: "monthly",
		is_active: 1
	};
	constructor(props) {
	    super(props);
	}
	closeModal = (event) => {
	    event.preventDefault();
	    window.location.href = "/parameter";

	}
	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}
	processEditTaskData = (result) => {
		console.log("result", result)
		let task = JSON.parse(result.parameter_json)
		this.setState({task_id: result.id, task_name: result.name, description: result.description, is_active: result.is_active, frequency: task.frequency, task: task, ready: true})
	}

	processTaskData = () => {
		let paramId = this.props.match ? this.props.match.params.paramId : 0;
		console.log("paramId", paramId)
		if(paramId > 0) {
			let postData = { "command" : "get_parameter", "id" : paramId }
			let api = new APICall();
			api.command(postData, this.processEditTaskData);
		}else{
			this.setState({ ready: true});
		}
	}


	onDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({due_date: date});
	}
	onscheduleDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({schedule_date: date});
	}

	processAddScheduleTask = (result) => {
		let mgs = "New schedule has been created.";
		let title = "Success";
		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})
	}

	manageParameter = (submit_form) => (event) => {
	    if(this.state.task_name === '') {
	        let alert_param = {title: 'Alert', message: 'ERROR: Task name cannot be blank.', ok_text: 'Ok', confirm: false, alertHandler: this.alertcloseHandler, stack: {id: 0}}
	        this.setState({alert_param: alert_param})
	        return false;
	    }
	    if(this.state.frequency === "0") {
	    	let alert_param = {title: 'Alert', message: 'ERROR: Please choose a frequency.', ok_text: 'Ok', confirm: false, alertHandler: this.alertcloseHandler, stack: {id: 0}}
	    	this.setState({alert_param: alert_param})
	    	return false;
	    }
	    let postData = {
	    		command: "save_parameter",
	            task_name:this.state.task_name,
	            description: this.state.description,
	            frequency: this.state.frequency,
	            is_active: this.state.is_active,
	            submit:submit_form,
	            task_id: this.state.task_id
	        };
	        if(this.state.task_id > 0){
	        	postData['task'] = this.state.task;
	        }
	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processAddTask);

	}

	processAddTask = (result) =>
	{
		let mgs = "This task has been saved.";
		let title = "Saved";

		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertaddHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param})

	}

	alertaddHandler = (result, stack) => {
		let url = "/parameter";
		window.location = url;
	}

	alertcloseHandler = (result, stack) => {
		this.setState({alert_param: null})
	}


	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {is_active} = this.state;
		is_active = is_active === 1 ? 0 : 1;
		this.setState({is_active})
	}



	render()
	{
		if(!this.state.ready) {
		    return <div><CSLLoader /></div>;
		}
		let task =  this.state.task;
		let created_by = task.contact_id;
		let assign_to = task.cur_assigned_to;
		let frequency = Utils.list_shedules();
		return (<div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
			<Style.CSLModalContainer>
			    <Style.ModalHeaderText>
			    {
			    	(() => {
			    		if(this.state.task_id === 0) {
			    			return "Add MCC Recurring Task";
			    		}else{
			    			return "Update MCC Recurring Task";
			    		}
			    	})()
			    }
			    </Style.ModalHeaderText>

			    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    <div style={{clear: "both"}}></div>
			    <Style.ModalBody>
			        <Style.ModalBodyInner>
			            <Style.ModalContainertop>
			                <Style.ModalNameLabelDiv>Name</Style.ModalNameLabelDiv>
			                <Style.ModalNameInputDiv>
			                    <Style.ModalInput name="task_name" value={this.state.task_name.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.makecomplete} />
			                </Style.ModalNameInputDiv>
			                <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
			                <Style.ModalNameInputDiv>
			                    <Style.ModalTextArea name="description" value={this.state.description.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.makecomplete}/>
			                </Style.ModalNameInputDiv>
			                <Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
        					<Style.ModalNameInputDiv>
                				<Style.MRModalSelect name="frequency" value={this.state.frequency} onChange={this.handleChange}>
			                    {
			                        frequency.map((item) => {
			                            {
			                                return (
			                                    <option key={item.id} value={item.name}>{item.nickname}</option>
			                                );
			                            }
			                        })
			                    }
			                    </Style.MRModalSelect>
		                    </Style.ModalNameInputDiv>
		                    <Style.ModalNameLabelDiv>Active</Style.ModalNameLabelDiv>
		                    <Style.ModalNameInputDiv>
	                    	{
	                    		(() => {
	                    			if (this.state.is_active) {
	                    				return (
	                    					<div>
	                    						<SelectedRadio>Yes</SelectedRadio>
	                    						<UnselectedRadio onClick={this.switchRadio('is_active')}>No</UnselectedRadio>
	                    					</div>
	                    				);
	                    			} else {
	                    				return (
	                    					<div>
	                    						<UnselectedRadio onClick={this.switchRadio('is_active')}>Yes</UnselectedRadio>
	                    						<SelectedRadio>No</SelectedRadio>
	                    					</div>
	                    				);
	                    			}
	                    		})()
	                    	}
		                    </Style.ModalNameInputDiv>




			            </Style.ModalContainertop>
			        </Style.ModalBodyInner>
			    </Style.ModalBody>
			    <Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			            <Style.SubmitButton onClick={this.manageParameter(0)}>Save</Style.SubmitButton>
			    </Style.CancelSubmitButtonOuterContainer>
			</Style.CSLModalContainer>
			<AlertBox alertParam = {this.state.alert_param} />
				</div>)
	}
}

export default ManageParameter;
