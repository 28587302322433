import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import {FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #949494;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;

const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    margin-top: 10px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #EEEEEE;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
class ViewManagerRU extends React.Component
{
    closeRegulatoryModal = (event) => {
        this.props.closeModal();
    }
    processMyExternalLink = () => {
        var linksdata = Store.getStoreData('managerViewDetails').useFullLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Link', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Comment', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'url_value' : <a target="_blank" href={d.url}>{d.url}</a>,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    render() {
        let rufeed = Store.getStoreData('task');
        

        let managerviewDetails = Store.getStoreData('managerViewDetails');
        
        const issue_date_obj = new Date(managerviewDetails.uploadDate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_day}/${issue_month}/${issue_year}`;

        let docType = "";
        docType = managerviewDetails.documentType;
        if(!isNaN(managerviewDetails.documentType)) {
            //const documentType = Store.getStoreData('rutypename');
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            } 
            docType = doctype[managerviewDetails.documentType]
        }
        
        return(<RegModalContainer>
            <RegModalHeaderText>RC Viewer</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <RegDismisOuterContainer>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{managerviewDetails.ruName}</div>                    
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>           
                <OverviewInfoBody>
                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(managerviewDetails.summary))}} style={{textAlign: "justify"}}></div>                
                <div style={{marginTop: "30px", display: "inline-block",marginRight: "40px"}}>Type: {docType}</div>
                <div style={{marginTop: "30px", display: "inline-block",marginRight: "40px"}}>Date: {issue_date}</div>                               
                </OverviewInfoBody>
            </RegDismisOuterContainer>           
            {
                (() => {
                    if('section' in managerviewDetails && managerviewDetails.section.length !== 0){
                        managerviewDetails.section.map((section) => {
                            return (
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>                                                                
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                        <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>                                 
                                        
                                    </OverviewInfoBody>
                                </RegDismisOuterContainer>                                    
                            )
                        })
                    }
                })()                
            }             
            {
                    (() => {
                    
                        if('useFullLink' in managerviewDetails && managerviewDetails.useFullLink.length !== 0) {   
                            
                            return(<div>
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                        <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                    </OverviewInfoBody>           
                                </RegDismisOuterContainer>
                                </div>)   
                        }else {
                            return(<div>
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>           
                                    <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Useful Links Available</div>
                                    </OverviewInfoBody>
                                </RegDismisOuterContainer>
                            </div>)
                        }
                    })()                
                }

            </RegModalContainer>)
    }
}
export default ViewManagerRU