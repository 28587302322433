import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import APICall from '../Common/APICall.js';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

class ConfigIndex extends React.Component
{
  render()
  {
    return (
      <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
      </div>
    );
  }
}

export default ConfigIndex;
