import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import CSLLoader from '../Common/CSLLoader';
import moment from 'moment';
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1002;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 200px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:20px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 16px 15px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class PendingInfoTaskModal extends React.Component
{
    state = {
        ready: false,
        binFiles: [],
        cur_files_json: null,
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),
        task: null,
        parent_task_json: null,
        parenttaskdata: null,
        fileObject: {},
        task_id: null,
        is_alert_msg: true,
        isloadericon: false,
        qsetdueDate: new Date(),
        Parent_taskid: 0,
        alert_param: null
    };

    componentDidMount()
	{
        let task_data = Store.getStoreData('cur_pending_task');
        // let parent_task_data = Store.getStoreData('cur_parent_pending_task');
        let parent_task_binFiles = Store.getStoreData('cur_parent_binFiles');

        let subtaskdata = task_data.subtask;
        let task_id = subtaskdata.id;
        let parenttaskdata = task_data.parenttask;
        let parent_task_json = JSON.parse(parenttaskdata.task_json);
        let task_json = JSON.parse(subtaskdata.task_json);

        //let binfile_parent_json = JSON.parse(parent_task_data.task_json);

        let cur_files_json = parent_task_binFiles;
        // if('bin_files' in binfile_parent_json.object_data){
        //     cur_files_json = binfile_parent_json.object_data.bin_files;
        // }
        let fileid = {}
        cur_files_json.forEach((item) => {
            fileid[item.uid] = parenttaskdata.id
        });
        console.log("fileid",fileid)
        let duedate = new Date(parenttaskdata.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? '0' + duedate.getDate()+'/' : duedate.getDate()+'/';
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth+'/'+duedate.getFullYear() : '0'+uploadedmonth+'/'+duedate.getFullYear();

        let actiondate = new Date(parent_task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';
        editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear();

        let is_alert_msg = true;
        if(cur_files_json.length === 0){
            is_alert_msg = false;
        }
        if('downloadedFile' in task_json.object_data.headerinfo){
            let objectDate = task_json.object_data.headerinfo.downloadedFile;
            Object.keys(fileid).map((item) => {
                console.log("item",item)
                if(item in objectDate){
                    is_alert_msg = false;
                }else{
                    is_alert_msg = true;
                }
            })
        }
        console.log("item",is_alert_msg)


        console.log("subtask",cur_files_json)
        this.setState({cur_files_json: cur_files_json,
            documentVersion: task_json.object_data.headerinfo.document_version,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,
            description: task_json.object_data.headerinfo.description,
            task: task_json,
            parent_task_json: parent_task_json,
            parenttaskdata: parenttaskdata,
            task_id: task_id,
            is_alert_msg: is_alert_msg,
            qsetdueDate: subtaskdata.due_date,
            Parent_taskid:parenttaskdata.id,
            ready:true})
    }



    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_ATTEST_URL;
              }else{
                // window.location = '/';
                this.props.closeTaskModal();
              }
        } else {
            this.props.closeModal();
        }
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    updateDownloadValue = (uid) => {
        console.log("uid",uid)
        if(this.state.is_alert_msg){
            this.state.fileObject[uid] = this.state.parenttaskdata.id
            let task_json = this.state.task;
            task_json['object_data'].headerinfo.downloadedFile = this.state.fileObject;
            let postData = {command: "update_download_file_task", task_json: task_json, task_id: this.state.task_id };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.afterDownloadCompleted(this.state.fileObject));
        }
    }
    afterDownloadCompleted = (fileobject) => (result) => {
        console.log("Completed:", result);
        let fileid = {}
        this.state.cur_files_json.forEach((item) => {
            fileid[item.uid] = this.state.parenttaskdata.id
        });
        let is_alert_msg = true;
        console.log("fileid",fileid,"fileobject",fileobject)
        Object.keys(fileid).map((item) => {
            //console.log("item",item)
            if(item in fileobject){
                is_alert_msg = false;
            }else{
                is_alert_msg = true;
            }
        })
        this.setState({is_alert_msg: is_alert_msg})
    }
    completeTask = (event) => {
        event.preventDefault();
        let lp = Store.getStoreData('language_pack')
        if(this.state.is_alert_msg){
            let msg = lp['please_download_attachment'] === undefined ? 'Please download the attachment(s) before completing the task.' : lp['please_download_attachment']
            //alert(msg)
            let alert_param = {title: 'Warning', message: msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.customDownloadDocument, stack: {}}
            this.setState({alert_param: alert_param})
        }else
        {
            let d_date = new Date(this.state.qsetdueDate);
            let dueQuest_date = moment.unix(Math.floor(d_date / 1000)).format('YYYY/MM/DD')

            let task_json = this.state.task;
            //console.log(task_json);
            task_json['task_data'].last_action = 'COMP_ATTESTATION_SHARE_COMPLETED';
            task_json['task_data'].cur_lane = 'COMP_ATTESTATION_SHARE_COMPLETED';
            task_json['task_data'].actiondate = Date.now();
            task_json['task_data'].due_date = dueQuest_date;
            task_json['action_data'] = {action: "COMP_ATTESTATION_SHARE_COMPLETED", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_ATTESTATION_SHARE_COMPLETED", assigned_to: 0 };
            let postData = {command: "update_share_completed_task", task_json: task_json, task_id: this.state.task_id };
            console.log(postData);
            this.setState({isloadericon: true})
            const api = new APICall();
            api.command(postData, this.afterCompleted);
        }
    }

    customDownloadDocument = () => {
        this.setState({alert_param: null});
    }

    afterCompleted = (result) => {
        // console.log("Completed:", result);
        let lp = Store.getStoreData('language_pack')
        let msg = lp['attestation_completed_successfully'] === undefined ? 'Attestation completed successfully.' : lp['attestation_completed_successfully']
        //alert(msg)
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.customAMCompleteTask, stack: {result: result}}
        this.setState({alert_param: alert_param})
        /*let parent_task_json = this.state.parent_task_json;
        let contactid = Store.getStoreData('contact_id');
        parent_task_json['object_data'].headerinfo.shareUserList[contactid].status = "complete";
        let postData = {command: "update_download_file_task", task_json: parent_task_json, task_id: this.state.parenttaskdata.id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterUpdateParentTaskCompleted);*/
    }

    customAMCompleteTask = (result) => {
        this.setState({alert_param: null});
        let parent_task_json = this.state.parent_task_json;
        let contactid = Store.getStoreData('contact_id');
        parent_task_json['object_data'].headerinfo.shareUserList[contactid].status = "complete";
        let postData = {command: "update_download_file_task", task_json: parent_task_json, task_id: this.state.parenttaskdata.id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterUpdateParentTaskCompleted);
    }

    afterUpdateParentTaskCompleted = (result) => {
        // console.log("Completed:", result);
        //alert('Task completed successfully.');
        window.location.reload(true);
    }

    render()
    {
        let lp = Store.getStoreData('language_pack')
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let dtext = this.state.description;
        if(Utils.isBase64(dtext)){
            dtext = window.decodeURIComponent(atob(dtext))
        }
        return (
            <div>
            <MRModalContainer>
                {
                    (()=> {
                        if(this.state.isloadericon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <MRModalHeader>
                    <MRModalHeaderText>{lp['review_attestation'] === undefined ? 'Review Attestation' : lp['review_attestation']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{lp['view_the_details_of_this_attestation'] === undefined ? 'View the details of this attestation and complete any required actions' : lp['view_the_details_of_this_attestation']}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                                {
                                    (() => {
                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                            return(<div>
                                                    <MRModalLabel>{lp['document_information'] === undefined ? 'Document Information' : lp['document_information']}</MRModalLabel>
                                                    <ModalNameInputDiv>
                                                        <Dropzone onFilesAdded={this.filesLoaded} saveDownloadData={true} updateDownloadData={this.updateDownloadValue} initFiles={this.state.cur_files_json} addnew={false} downloadThroughApi={true} ParentTaskid={this.state.Parent_taskid}/>
                                                    </ModalNameInputDiv>
                                                    <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>
                                            </div>)
                                        }
                                    })()
                                }
                                <div style={{display: "inline-block",float: "left"}}>
                                    <MRModalLabel>{lp['document_version'] === undefined ? 'Document Version' : lp['document_version']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontWeight: "600"}}>{this.state.documentVersion}</MRModalLabelSub>
                                </div>
                                <div style={{display: "inline-block",float: "right"}}>
                                    <div style={{display: "inline-block",marginRight: "80px"}}>
                                    <MRModalLabel>{lp['uploaded_date'] === undefined ? 'Uploaded Date' : lp['uploaded_date']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.uploadedDate}</MRModalLabelSub>
                                    </div>
                                    <div style={{display: "inline-block"}}>
                                    <MRModalLabel>{lp['edited_date'] === undefined ? 'Edited Date' : lp['edited_date']}</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.editedDate}</MRModalLabelSub>
                                    </div>
                                </div>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel>{lp['description'] === undefined ? 'Description' : lp['description']}</MRModalLabel>
                        {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div>  */}
                        <div dangerouslySetInnerHTML={{__html: dtext}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                        </div>
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                    <MRModalSaveCloseBtn onClick={this.completeTask}>Complete</MRModalSaveCloseBtn>
                    <MRModalCancelBtn onClick={this.closeView}>{lp['cancel'] === undefined ? 'Cancel' : lp['cancel']}</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default PendingInfoTaskModal;
