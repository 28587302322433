import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import CSLHeader from './CSLHeader';
import WelcomeBanner from './WelcomeBanner';
import AlertBox from './Common/AlertBox';
import RegisterIndex from './Register/RegisterIndex';
import CSLIndex from './CSL/CSLIndex';
import TmviewHOC from './Tasks/tmview/TmviewHOC';

const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;
  margin-right:15px;
  opacity: 1 ; 
`;

const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  margin-right:15px;
  opacity: 1 ; 
`;

const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;
  margin-right:15px;
  opacity: 1 ; 
`;

const Checkmark = styled.span`
  content: "\2713";
  
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    padding-left: 12px;
    cursor: pointer;
`;

var taskListFiltered = {};

const sourceMap = {filechecker: 'File Checker', car: 'Compliance Advice Register', rcm: 'Regulatory Change Management', conflicts:'Conflicts of Interest', cmp:'Compliance Monitoring Programme', tmview: 'Generic', attest: 'Attestations', gifts: 'Gifts and Hospitality'}
const completeMap = {COMPLETE: 1, COMPLETED: 1, DISMISSED : 1, REJECTED: 1, DISSMISS: 1, REJECT: 1}
const statusMap = {Pending: <Pending />, Complete: <Complete />, Overdue: <Overdue />}


class Index extends React.Component
{

    state = { view_count: 0, cur_view: null, alert_param: null, task_id: 0}

    constructor(props) {
        super(props);
    }


    componentDidMount(){
        this.processDataRefresh();
    }
    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.processDataRefresh()
        }
    }

    processDataRefresh = () => {
      let cur_view = null;
      let alert_param = null;
      let is_admin = Store.getStoreData('is_admin');
      cur_view  = is_admin ? 'csl_index' : 'register_index';
      let task_id = this.props.match ? this.props.match.params.taskId : 0;
      cur_view = task_id > 0 ? 'task_index' : cur_view;
      this.setState({cur_view : cur_view, task_id: task_id});
    }

    alertHandler = (result, stack) => {
      window.location = '/select/ServiceBasedLanding';
    }

    closeUserDialog = () => {
      let url ="/";
      window.location = url;
    }


    render()
    {

      return (
        <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
          {
            (() => {
              switch(this.state.cur_view) {
                case 'csl_index' : return <CSLIndex p={this.props.p}/>; break;
                case 'register_index': return <RegisterIndex />; break;
                case 'task_index': return <TmviewHOC task_id={this.state.task_id} closeUserDialog={this.closeUserDialog}/>; break;
              }
            })()
          }
        </div>
      );
    }
}

export default Index;
