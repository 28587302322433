import Store from './Store.js';
import Utils from './Utils.js';

var focused_count = 0;
var all_count = 0;
var open_count = 0;
var overdue_count = 0;
var flagged_count = 0;
var completed_count = 0;
var unassign_count = 0;
var mytasks_count = 0;

class filterTasks {
	getFiltertasks(tasks,inactive_users,unassigntasks,user_login_hist,enabled_csl_user_list, flags){
		focused_count = 0;
		all_count = 0;
		open_count = 0;
		overdue_count = 0;
		flagged_count = 0;
		completed_count = 0;
		unassign_count = 0;
		mytasks_count = 0;
		let task_result = {rows: {}};
		let status_count = {focused: 0, all: 1, open: 0, overdue: 0, flagged: 0, completed: 0, unassign: 0, mytasks: 0}

		for(let t_id in tasks) {
		    if(!(tasks[t_id.toString()].id in task_result.rows)){
		        let filter = this.getFilter(tasks[t_id.toString()], flags, unassigntasks, inactive_users, user_login_hist, enabled_csl_user_list);
		        all_count ++;
		        if(filter.Unassigned === 1) {
		            tasks[t_id.toString()].cur_assigned_to = 0;
		        }
		        task_result.rows[tasks[t_id.toString()].id] = {row: tasks[t_id.toString()], filter: filter}
		    }
		}

		status_count.focused = focused_count;
		status_count.all = all_count;
		status_count.flagged = flagged_count;
		status_count.completed = completed_count;
		status_count.unassign = unassign_count;
		status_count.overdue = overdue_count;
		status_count.open = open_count;
		status_count.mytasks = mytasks_count;

		task_result['status_count'] = status_count;
		return task_result;
	}

	getStatus(row, configure){
	  // Utils.log("configure", configure)
	  let lane_parts = row.cur_lane.split('_')
	  let lane_end = lane_parts[lane_parts.length - 1]
	  let status = 'Pending';
	  let due_date = new Date(row.due_date);
	  let cur_date = new Date();
	  let cur_date_int = cur_date.getFullYear()*10000 + cur_date.getMonth()*100 + cur_date.getDate();
	  let due_date_int = due_date.getFullYear()*10000 + due_date.getMonth()*100 + due_date.getDate();

	  if(due_date_int < cur_date_int){
	      status = 'Overdue'
	  }
	  return status;
	}


	getUnassignStatus(row, configure,status, inactive_user_list, user_login_hist, enabled_csl_user_list) {
	    let new_status = status;

		if (row.cur_assigned_to === 0) {
			new_status = "Unassigned";
		}
		if(!(row.cur_assigned_to in enabled_csl_user_list)){
	        if((!(row.cur_assigned_to in user_login_hist) || user_login_hist[row.cur_assigned_to] < configure.last_login_time) && configure.last_login){
	            new_status = "Unassigned";
	        }else if(!(row.cur_assigned_to in user_login_hist) && configure.never_login) {
	            new_status = "Unassigned";
	        }
	    }
	    return new_status;

	}
	getFilter(data, flags, configure,inactive_users, user_login_hist, enabled_csl_user_list){
		let contact = Store.getStoreData('contact')
	console.log("data", data)
	        var arr_fil = {Focused: 0, All: 1, Open: 0, Overdue: 0, Flagged: 0, Completed: 0, Unassigned: 0, Mytasks: 0}
	        var status = this.getStatus(data, configure);
	       let newstatus = this.getUnassignStatus(data, configure,status, inactive_users, user_login_hist, enabled_csl_user_list);
	        if(status == 'Complete'){
	            arr_fil['Completed'] = 1;
	            completed_count ++;
	        }else if(status == 'Overdue'){
	             arr_fil['Overdue'] = 1
	             arr_fil['Focused'] = 1
	             focused_count ++;
	             overdue_count ++;
	        } else{
	            arr_fil['Open'] = 1
	            open_count ++;
	        }
	        if(newstatus === "Unassigned") {
	            arr_fil['Unassigned'] = 1
	            unassign_count ++;
	        }
	        if(data.id in flags) {
	            arr_fil.Flagged = 1;
	            flagged_count++
	            if(arr_fil['Overdue'] !== 1)focused_count++
	            arr_fil.Focused = 1
	        }
	        if(data.cur_assigned_to === contact.ID){
	        	arr_fil['Mytasks'] = 1
	        	mytasks_count++
	        }
	        return arr_fil;
	}
}
export default new filterTasks()
