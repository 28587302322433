import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import CSLTable from '../Common/CSLTable';


const RUThumbnailsContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
`;
const RUThumbnail = styled.div`
    float: left;
    box-sizing: border-box;
    margin-left: 20px;
    width: calc(22% - 10px);
    height: 160px;
    background-color: #ffffff;
    border-radius: 3px;
    position: relative;
`;
const RUThumbnailHeader = styled.div`
    padding: 20px 20px 0px 20px;
    font-weight: 600;
    color: #282828;
    font-size: 16px;
`;
const RUThumbnailbody = styled.div`
    padding: 20px;
    font-weight: 600;
    font-size: 50px;
    color: #434343;
    
`;

class DataCardSection extends React.Component
{
    state = {
        openAttestation: "00",
        overdueAttestation: "00",
        // pendingAttestation: "00",
        completeAttestation: "00",
        openTasks: "00"
    };
    constructor(props) {
        super(props);
    }

    setComponentState = () => {
        let dataCardValue = Store.getStoreData('data_card_value');
        let openAttestation = dataCardValue.open_attestation < 10 ? "0"+dataCardValue.open_attestation.toString() : dataCardValue.open_attestation.toString();
        let overdueAttestation = dataCardValue.overdue_attestation < 10 ? "0"+dataCardValue.overdue_attestation.toString() : dataCardValue.overdue_attestation.toString();
        // let pendingAttestation = dataCardValue.pending_attestation < 10 ? "0"+dataCardValue.pending_attestation.toString() : dataCardValue.pending_attestation.toString();
        let completeAttestation = dataCardValue.complete_attestation < 10 ? "0"+dataCardValue.complete_attestation.toString() : dataCardValue.complete_attestation.toString();
        let openTasks = dataCardValue.open_tasks < 10 ? "0"+dataCardValue.open_tasks.toString() : dataCardValue.open_tasks.toString();
        this.setState({openAttestation,overdueAttestation,completeAttestation, openTasks})
    }

    componentDidMount()
    {
        this.setComponentState()
    }

    componentDidUpdate(prevprops)
    {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    render()
    {         
        let lp = Store.getStoreData('language_pack')        
        return (
            <RUThumbnailsContainer>
                <RUThumbnail style={{borderLeft: "9px solid #04ADA8",marginLeft: "40px"}}>                    
                    <RUThumbnailHeader>{lp['open_attestations'] === undefined ? 'Open Attestations' : lp['open_attestations']}</RUThumbnailHeader>
                    <RUThumbnailbody><div style={{position: 'absolute', top: 60, left: 'calc(50% - 25px)'}}>{this.state.openAttestation}</div></RUThumbnailbody> 
                </RUThumbnail>
                <RUThumbnail style={{borderLeft: "9px solid #DD4054",marginLeft: "40px"}}>
                    <RUThumbnailHeader>{lp['overdue_attestations'] === undefined ? 'Overdue Attestations' : lp['overdue_attestations']}</RUThumbnailHeader>
                    <RUThumbnailbody><div style={{position: 'absolute', top: 60, left: 'calc(50% - 25px)'}}>{this.state.overdueAttestation}</div></RUThumbnailbody>                        
                </RUThumbnail>
                <RUThumbnail style={{borderLeft: "9px solid #6DD500",marginLeft: "40px"}}>
                    <RUThumbnailHeader>{lp['completed_attestations'] === undefined ? 'Complete Attestations' : lp['completed_attestations']}</RUThumbnailHeader>
                    <RUThumbnailbody><div style={{position: 'absolute', top: 60, left: 'calc(50% - 25px)'}}>{this.state.completeAttestation}</div></RUThumbnailbody>                            
                </RUThumbnail>
                <RUThumbnail style={{borderLeft: "9px solid #143151",marginLeft: "40px"}}>
                    <RUThumbnailHeader>{lp['open_tasks_card_title'] === undefined ? 'Open Tasks' : lp['open_tasks_card_title']}</RUThumbnailHeader>
                    <RUThumbnailbody><div style={{position: 'absolute', top: 60, left: 'calc(50% - 25px)'}}>{this.state.openTasks}</div></RUThumbnailbody>                        
                </RUThumbnail>                
                <div style={{clear: "both"}}></div>
            </RUThumbnailsContainer>
        );
    }
}
export default DataCardSection;