import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import {IoIosArrowUp} from 'react-icons/io';
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import '../../App.css';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1002;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const ProgressContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 400px;
    position: fixed;
    top: 80px;
    text-align: center;
    left: calc(50vw - 200px);
    z-index: 1008;
    color:  #143151;  
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
`;

const ProgressBarTotal = styled.div`
    width: 380px;
    height: 14px;
    background-color: white;
    border: 1px solid #04ADA8; 
    margin-top: 8px;
    margin-bottom: 8px;
`;

const ProgressBar = styled.div`
    height: 100%;
    background-color: blue;
    float: left;
`;

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 16px 15px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

class AMExtendShareAttestationModal extends React.Component
{
    state = {
        ready: false,        
        binFiles: [],
        cur_files_json: null,    
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),    
        acknowledgementDueDate: '',
        parrentTask: null,
        show_progress: false,
        gc_users: null,
        stop_polling: false,
        assigned_to: null,
        share_count: 0,
        is_submitting: false,
        rowchecked: false,
        extendedTaskID: {},
        currentShareUsersList: [],
        currentShareUsersListAll: [],
        isloadericon: false,
        alert_param: null                
    };

    stop_polling = false
    
    componentDidMount()
	{
        console.log("taskid===>", this.props.taskid);
        let postData = { "command" : "get_task_attest_table", "task_id" : this.props.taskid }
        let api = new APICall();
        api.command(postData, this.processAttestationTaskData);  
    }

    processAttestationTaskData = (result) => {
        let form_data = {};        
        form_data = result.result.parenttask;        
        let task_json = JSON.parse(form_data.task_json);
        console.log('task_json', task_json);   

        let duedate = new Date(form_data.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? '0' + duedate.getDate()+'/' : duedate.getDate()+'/';        
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth+'/'+duedate.getFullYear() : '0'+uploadedmonth+'/'+duedate.getFullYear();

        let actiondate = new Date(task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';        
        editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear(); 
        
        let extendDateshareTaskList = Store.getStoreData('extendDateshareTaskList');
        let currentShareUsersList = [];
        let extendedTaskID = {};
        extendDateshareTaskList.forEach((value) => { 
            if(value.cur_lane !== 'COMP_ATTESTATION_SHARE_COMPLETED'){        
                let currentShareUserListObect = {};
                currentShareUserListObect.id=value.id;
                currentShareUserListObect.userid = value.cur_assigned_to;
                currentShareUserListObect.name = Store.getStoreData('contacts')[value.cur_assigned_to];
                currentShareUserListObect.email = Store.getStoreData('contactsemail')[value.cur_assigned_to];
                currentShareUserListObect.select = true;
                currentShareUsersList.push(currentShareUserListObect);
                extendedTaskID[value.id] = value.cur_assigned_to;
            }
        })

        console.log("currentShareUserList==>", currentShareUsersList)

        let dtext = task_json.object_data.headerinfo.description;        
        if(Utils.isBase64(dtext)){
            dtext = window.decodeURIComponent(atob(dtext))            
        }

        this.setState({ cur_files_json: task_json.object_data.bin_files,
            documentVersion: task_json.object_data.headerinfo.document_version,
            assigned_to: task_json.object_data.headerinfo.assignedTo,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,            
            description: dtext,            
            parrentTask: form_data,  
            rowchecked: true,
            extendedTaskID: extendedTaskID, 
            currentShareUsersList: currentShareUsersList, 
            currentShareUsersListAll: currentShareUsersList, 
            ready: true
            });               
    }

    closeView = () => {
        console.log("this.props.taskid",this.props.taskid)
        this.stop_polling = true
        this.props.closeModal(this.props.taskid);
    } 

    selectAllshareUser = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let userListAll = this.state.currentShareUsersList;         
        console.log("userListAll", userListAll)
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of userListAll) {                
                let selectObject = {}    
                this.state.extendedTaskID[d.id] = d.userid;       
                i++;                  
                selectObject.id = d.id;
                selectObject.userid = d.userid;
                selectObject.name = d.name;
                selectObject.email = d.email;                             
                selectObject.select = true;                                            
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,currentShareUsersList: selectArray, currentShareUsersListAll:selectArray, extendedTaskID: this.state.extendedTaskID})
            console.log("extendedTaskID", this.state.extendedTaskID)
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of userListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.extendedTaskID[d.id.toString()];                            
                selectObject.id = d.id;
                selectObject.userid = d.userid;
                selectObject.name = d.name;
                selectObject.email = d.email;                             
                selectObject.select = false;                              
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,currentShareUsersList: selectArray, currentShareUsersListAll:selectArray, extendedTaskID: this.state.extendedTaskID})
            console.log("extendedTaskID", this.state.extendedTaskID)
       }
   }

   selectShareUser = (event) => {        
        let index = event.target.value;        
        let userListAll = this.state.currentShareUsersList;       
            
        if(userListAll[index].select){
            userListAll[index].select = false;  
            delete this.state.extendedTaskID[userListAll[index].id.toString()];                    
        } else {
            userListAll[index].select = true; 
            this.state.extendedTaskID[userListAll[index].id.toString()] = userListAll[index].userid;                            
        }
        this.setState({currentShareUsersList: userListAll, extendedTaskID: this.state.extendedTaskID,rowchecked: false})
        console.log("shareUserList", this.state.extendedTaskID)
        let allselectcheck = true
        for(let d of userListAll) {            
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    processMyshareGroup = () => {       
        var sharedata = this.state.currentShareUsersList;
        let lp = Store.getStoreData('language_pack')
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns = []
        ret.columns.push({'Header' : <div><input style={{cursor: "pointer"}} type="checkbox" checked={this.state.rowchecked} onClick={this.selectAllshareUser}/></div>,sortable: false,accessor: 'header', Cell: row => (
            <div>
                <input type="checkbox" style={{cursor: "pointer"}} checked={row.original.select} value={row.index} onClick={this.selectShareUser}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}})
        ret.columns.push({Header: lp['extend_name_column_title'] === undefined ? 'Name' : lp['extend_name_column_title'], accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}})
        ret.columns.push({Header: lp['extend_email_column_title'] === undefined ? 'Email' : lp['extend_email_column_title'], accessor: 'email_value', minWidth: 80, headerStyle: {textAlign: 'left'}})

        sharedata.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        for(let d of sharedata) { 
            i++;         
            let elem = {'index' : i,
                    'id': d.id, 
                    'name_value' : d.name,                    
                    'email_value' : d.email,                    
                    'select' : d.select                    
                   };
            ret.data.push(elem);
        }
        return ret; 
    }

    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);		
        let search_string = event.target.value;
        if (search_string === "") {
            let user_list_all = this.state.currentShareUsersListAll;            
            this.setState({currentShareUsersList: user_list_all});
        }else {
            let user_list_all = this.state.currentShareUsersListAll;
            let filtered_values = [];
            user_list_all.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_values.push(user);
                }                
            })            
            let user_list = filtered_values;
            this.setState({currentShareUsersList: user_list})
        }
	}

    onacknowledgementDueDate = (date) => {        
        this.setState({acknowledgementDueDate: date});
    }

    processTrackProgress = (result) => {
        console.log('result trackprogress', result)
        let count = result.result.count
        Store.updateStore('hide_progress', false)
        if(count < this.state.shareUserList.length - 1 && !this.stop_polling) {
            setTimeout(() => {
                this.trackShareProgress()
            }, 2000);
            this.setState({share_count:count})
        } else {
            window.location.reload();
        }
    }

    trackShareProgress = () => {
        let postData = {command: "get_share_count", task_id: this.props.taskid};
        const api = new APICall();
        Store.updateStore('hide_progress', true)
        api.command(postData, this.processTrackProgress);
    }
    customAttestExtendDueDateAlert = (event) => {
        event.preventDefault();  
        let alert_param = {title: 'Warning', message: 'This will extend the due date for all selected outstanding attestations; Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.submitExtendDueDate, stack: {}}
        this.setState({alert_param: alert_param})
    }

    submitExtendDueDate = (result) => {
        //event.preventDefault();        
        //if (window.confirm('This will extend the due date for all selected outstanding attestations; Do you want to continue?')) {
        let lp = Store.getStoreData('language_pack')
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let problems = false;
        let message = '';
        this.setState({alert_param: null, isloadericon: true})
        if(Object.keys(this.state.extendedTaskID).length === 0){
            //alert("Please select atleast one user.");
            problems = true;
            message = 'Please select atleast one user.';
        }if(this.state.acknowledgementDueDate === ''){
            //alert("Please select Acknowledgement Due Date.");
            problems = true;
            message = 'Please select Acknowledgement Due Date.';
        }
        if(problems) {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: 'Ok', confirm: false, 
            alertHandler: this.checkalertparam, stack: {}}
            this.setState({alert_param: alert_param})           
            return false
        }
        else{                
            let curmonth = parseInt(this.state.acknowledgementDueDate.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.acknowledgementDueDate.getFullYear()+'/'+currmonth+'/' : this.state.acknowledgementDueDate.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.acknowledgementDueDate.getDate() < 10 ? '0' + this.state.acknowledgementDueDate.getDate() : this.state.acknowledgementDueDate.getDate();
            
            let postData = {command: "extend_date_share_attestation_task", extendedTaskID: this.state.extendedTaskID, acknowledgementDueDate: dueDateStr};
            console.log('postData', postData)
            this.setState({is_submitting: true, isloadericon: true})                
            const api = new APICall();
            api.command(postData, this.afterCompletedSubmitShare);
        }
    }
    checkalertparam = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    afterCompletedSubmitShare = (result) => {  
        console.log('afterCompletedSubmitShare result', result)      
        //alert('Due Date has been updated successfully.');
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Due Date has been updated successfully. ', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.customupdateDueDate, stack: {result: result}}
        this.setState({alert_param: alert_param})   
        window.location.reload();

        // setTimeout(() => {
        //     this.trackShareProgress()
        // }, 2000);

        this.setState({isloadericon: false})
    }
    customupdateDueDate = (result) => {
        this.setState({alert_param: null, isLoadedIcon: false})
        window.location.reload();
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }  
        let lp = Store.getStoreData('language_pack')      
        return (     
            <div>
            {
                (()=> {
                    if(this.state.isloadericon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }        
            <MRModalContainer> 
                <MRModalHeader>
                    <MRModalHeaderText>{lp['extend_due_date_title'] === undefined ? 'Extend Due Date' : lp['extend_due_date_title']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{lp['extend_due_date_prompt'] === undefined ? 'Define and select the users to extend the current attestation with' : lp['extend_due_date_prompt']}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                            {
                                (() => {
                                    if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                        return(<div>
                                                <MRModalLabel>{lp['extend_document_information'] === undefined ? 'Document Information' : lp['extend_document_information']}</MRModalLabel>
                                                <ModalNameInputDiv>
                                                    <Dropzone initFiles={this.state.cur_files_json} addnew={false}/>
                                                </ModalNameInputDiv>
                                                <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>
                                        </div>)
                                    }
                                })()
                            }                                
                            <div style={{display: "inline-block",float: "left"}}>                                
                                <MRModalLabel>{lp['extend_document_version'] === undefined ? 'Document Version' : lp['extend_document_version']}</MRModalLabel>
                                <MRModalLabelSub style={{fontWeight: "600"}}>{this.state.documentVersion}</MRModalLabelSub>                                
                            </div>
                            <div style={{display: "inline-block",float: "right"}}>
                                <div style={{display: "inline-block",marginRight: "80px"}}>
                                <MRModalLabel>{lp['extend_uploaded_date'] === undefined ? 'Uploaded Date' : lp['extend_uploaded_date']}</MRModalLabel>
                                <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.uploadedDate}</MRModalLabelSub>
                                </div>
                                <div style={{display: "inline-block"}}>
                                <MRModalLabel>{lp['extend_edited_date'] === undefined ? 'Edited Date' : lp['extend_edited_date']}</MRModalLabel>
                                <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.editedDate}</MRModalLabelSub>
                                </div>
                            </div>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel>{lp['extend_document_description'] === undefined ? 'Description' : lp['extend_document_description']}</MRModalLabel>
                        {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div>  */}
                        <div dangerouslySetInnerHTML={{__html: this.state.description}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                        </div>                                              
                        
                                    
                        <OverviewInfoHeader>
                                <div style={{float: "left", color: "#000000", fontWeight: "600"}}>{lp['extend_users_table_title'] === undefined ? 'Users' : lp['extend_users_table_title']}</div> 
                                <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><SearchInput placeholder="Search..." onChange={this.searchValue} /></div>
                                <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>  
                        <OverviewInfoBody>
                            <CSLTable add={false} processData={this.processMyshareGroup} headerText={''} tableRows={10} refreshCallback={this.refreshState} />
                        </OverviewInfoBody>                         
                        <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>      
                        <MRModalLabel style={{color: "#030303"}}>{lp['extend_acknowledgment_due_date'] === undefined ? 'Acknowledgement Due Date' : lp['extend_acknowledgment_due_date']}</MRModalLabel>
                        <CSLDateCover style={{display: "inline-block"}}>
                            <DatePicker
                                    onChange={this.onacknowledgementDueDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    locale={"en-GB"}
                                    minDate={new Date()}
                                    value={this.state.acknowledgementDueDate}
                            />
                        </CSLDateCover>
                                                                 
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                    {
                        (() => {
                            if(!this.state.is_submitting) return <MRModalSaveCloseBtn onClick={this.customAttestExtendDueDateAlert}>{lp['extend_submit_button'] === undefined ? 'Submit' : lp['extend_submit_button']}</MRModalSaveCloseBtn>
                        })()
                    }                    
                    <MRModalCancelBtn onClick={this.closeView}>{lp['extend_cancel_button'] === undefined ? 'Cancel' : lp['extend_cancel_button']}</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
                {/* {
                    (() => {
                        if(this.state.show_progress) {
                            console.log('this.state', this.state)
                            let barwidth = `${parseInt(this.state.share_count * 100 / this.state.currentShareUsersList.length)}%`

                            return (<ProgressContainer> 
                                    Sharing the attestation with recepients.
                                    <ProgressBarTotal>
                                        <ProgressBar style={{width: barwidth}} />
                                    </ProgressBarTotal>
                                    <span style={{fontSize: 12}}>If you want, you may cancel this dialog and go about your work while we share the attestation with the recipients in the background.</span>
                                </ProgressContainer>)
                        }
                    })()
                } */}
            </MRModalContainer>
            <AlertBox alertParam={this.state.alert_param} />       
            </div>);
    }
}

export default AMExtendShareAttestationModal;