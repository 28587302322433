import Store from './Store.js';
import { trackPromise } from 'react-promise-tracker';
import Utils from '../Common/Utils';
import moment from 'moment';
import http from 'mcc-front-aux/dist/httpCommon';

const cache_commands = {index : 1, taskindex: 1}
const cache_renew_time = 15 * 60 * 60

class APICall {
    constructor() {
        this.url = Store.getStoreData('api_url');
        this.is_admin = Store.getStoreData('is_admin');
        this.is_debug = Store.getStoreData('is_debug');
        this.module_name = Store.getStoreData('module_name');
    }

    command(postData, callback, incremental_callback) {
        postData.env = "select";

        console.log('APICall module, command', Store.getStoreData('module_name'), postData.command)
        let now_time = moment().unix()
        let module_name = Store.getStoreData('module_name')
        let command = postData.command
        let command_id = `${module_name}-${command}`
        let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
        let incremental = (typeof incremental_callback !== 'undefined') ? true : false
        jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
        jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
        jsonDataString = jsonDataString.replace(/\n/g, '');
        jsonDataString = jsonDataString.replace(/\r/g, '');
        if(command in cache_commands) {
            let cached_result = Store.getStoreData(command_id)

            // console.log('cached_result_parsed', JSON.parse(cached_result))
            let cached_time = Store.getStoreData(command_id + '-last_cached')
            console.log('cached_time, now_time, now_time - cached_time, cache_renew_time', cached_time, now_time, now_time - cached_time, cache_renew_time)
            if(cached_time !== null && cached_result !== null && now_time - cached_time < cache_renew_time) {
                console.log('cached_result', cached_result)
                postData.incremental = 'incremental' in postData ? postData.incremental : incremental
                if(cached_result.error_code === 0)callback(cached_result)
                //callback(cached_result)
                // return
            }
        }
        console.log('APICall incremental_callback, postData', incremental_callback, postData)

        // Add in the json command if it exists
        let postURL = this.url
        if('command' in postData) {
            postURL += `/${postData.command}`
        }


        http.post(postURL, {jsondata: jsonDataString}).then(result => {
            result = result.data.body
            result = result.replace(/'+/g,"'");
            result = result.replace(/(&rsquo;)/g, "\'");
            result = result.replace(/(--plus--)/g, "+");
            result = result.replace(/(--quote--)/g, "'");
            result = result.replace(/(--and--)/g, "&");
            result = JSON.parse(result);
            if(command in cache_commands){
                console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(result).length/1024));
                Store.updateStore(command_id, result)
                Store.updateStore(command_id + '-last_cached', now_time)
            }
            if(incremental){
                incremental_callback(result);
            } else {
                callback(result);
            }

        }).catch(error => {
            console.log(error);
            return(null)
        });

        // trackPromise(fetch(this.url, httpVerb)
        //   .then(res => res.json())
        //   .then(result => {
        //     result = JSON.stringify(result);
        //     result = result.replace(/(--plus--)/g, "+");
        //     result = result.replace(/(--quote--)/g, "'");
        //     result = result.replace(/(--and--)/g, "&");
        //     result = result.replace(/'+/g,"'");
        //     result = JSON.parse(result);
        //     if(command in cache_commands){
        //       console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(result).length/1024));
        //       Store.updateStore(command_id, result)
        //       Store.updateStore(command_id + '-last_cached', now_time)
        //     }
        //     if(incremental){
        //       incremental_callback(result);
        //     } else {
        //       callback(result);
        //     }
        //
        //   }).catch(error => {
        //     console.log(error);
        //     return(null)
        //   }));
    }

    async commandWithoutCallback(postData, callback) {

        postData.env = "select";

        let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
        jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
        jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
        jsonDataString = jsonDataString.replace(/\n/g, '');
        jsonDataString = jsonDataString.replace(/\r/g, '');

        // Add in the json command if it exists
        let postURL = this.url
        if('command' in postData) {
            postURL += `/${postData.command}`
        }


        http.post(postURL, {jsondata: jsonDataString}).then(res => {
            res = res.data.body
            res = res.replace(/(--plus--)/g, "+");
            res = res.replace(/(--quote--)/g, "'");
            res = res.replace(/(--and--)/g, "&");
            res = res.replace(/'+/g, "'");
            res = JSON.parse(res);
            callback(res);
        }).catch(error => {
            console.log(error);
            return(null)
        });
        // trackPromise(fetch(this.url, httpVerb)
        //   .then(res => res.json())
        //   .then(result => {
        //     result = JSON.stringify(result);
        //     result = result.replace(/(--plus--)/g, "+");
        //     result = result.replace(/(--quote--)/g, "'");
        //     result = result.replace(/(--and--)/g, "&");
        //     result = result.replace(/'+/g,"'");
        //     result = JSON.parse(result);
        //     callback(result);
        //   }).catch(error => {
        //     console.log(error);
        //     return(null)
        //   }));
    }
}

export default APICall;
