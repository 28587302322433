import Store from './Store.js';

class XcelLoader {
	conflicts_export_tasks(tasks){		
		let j = 0;
		let results = [];
		let module_config = Store.getStoreData('module_config');  		
        let isChangeStatusTerminology = 'chg_staus_termilogy' in module_config.general ? module_config.general.chg_staus_termilogy === true ? true : false : false
		console.log("module_config===>", module_config)
		let contacts = Store.getStoreData('contacts');
		for(let d of tasks) {
			j++;		  
		    let elem = {	
					'Company Name' : d.group_company_name,
					'Conflict Title': d.conflicts_title,	            
					'Conflict Date': d.date_raised,	            
		            'Conflict Description': d.conflict,
		            'ID': d.conflictid,
		            'Conflict Type' : d.conflicttype,
		            'Discloser' : d.discloser,
		            'Task' : d.no_of_followup,
					'Entry Date' : d.discloserdate,
					'Status' : d.status,
					
					'Please describe the nature of the Conflict' : d.nature_of_conflict,
					'Risk Description' : d.risk_description,
					'Mitigants' : d.mitigants,
					'Additional Information Provided' : d.additional_information_provided,
					'Additional Information Text' : d.additional_information_text,
					'Disclosure to Third Party' : d.disclosure_to_third_party,
					'Third Party Disclosure Text' : d.third_party_disclosure_text,
					// 'Date Raised' : d.date_raised,
					'Conflict Owner' : d.conflicts_owner
		           };				   

					if(isChangeStatusTerminology){
							elem['Closed Date'] = d.compt_date
					}else{
							elem['Confirmed Date'] = d.compt_date
					}
					elem['Last Updated date'] = d.discloserdate
					elem['Last Updated By'] = d.last_updated_by in contacts ? contacts[d.last_updated_by] : ''				
				   
					if(Object.keys(module_config).length > 0 && module_config.custom_fields.enabled) {					
						if(module_config.custom_fields.additional_fields.length !== 0 && d.additional_fields.length !== 0){
							d.additional_fields.forEach((item) => {
								elem[item.nickname] = item.value;
							})
						}
					}
					elem['Reason for Removal'] = d.reason_for_removal;	    
					elem['Removal Description'] = d.removal_description;    
		    results.push(elem)
		}
		return results;
	}
}
export default new XcelLoader();