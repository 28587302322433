import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js';
import DatePicker from 'react-date-picker';
import HtmlEditor from '../Common/HtmlEditor';
import CSLLoader from '../Common/CSLLoader';
import IndexLoader from '../../Common/IndexLoader.js';

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #333;
    font-weight: 600;
`;

const ModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #234062;
    font-family: 'Montserrat', sans-serif;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #f7f7f7;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:10px;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
    cursor:pointer;
`;
const ModeltopText = styled.div`
    display: grid;
    grid-gap: 26px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6ebd6;
    color: #f6ce76;
    background-color: #f6ebd6;
`;
const ModalContainertop = styled.div`
    display: grid;
    // grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 10px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;



const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const PublishButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;
const InactiveButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: auto;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    height: 25px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    `;


class ShowReapprovalModal extends React.Component
{
    state = {
        faq_header_text: '',
        faq_answer: '',
        faq_query: '',
        faq_id: 0,
        binFiles: [],
        cur_files_json: null,
        tags:[],
        ref_id: '',
        company_id : 0,
        company: '',
        faq_created_by: 0,
        original_faq_id: 0,
        show_scorll: true,
        ready:false,
        expiry_date: new Date(),
        make_faq_inactive: true,
        is_active: 1,
        approved_by: 0,
        approved_date: '',
        is_loading: false

    };


    constructor(props) {
        super(props);

    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    selectOrUnselectTopic = (tagid) => (event) => {
        event.preventDefault();
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }


    PublishORrevertFAQ(flag){
        let altmsg = flag === "delete" ? "Are you sure you want to delete this FAQ?" : "Are you sure you want to reapprove this FAQ?";
        let confirm = window.confirm(altmsg);
        if (confirm == true) {
           let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
           // let bin_files = this.state.binFiles;
           let selectedtags = {};
           this.state.tags.forEach((tag) => {
               if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
           })
           let command = flag === "delete" ? "send_faq_delete": "send_reapprove_faq";
           
           let dateobj = new Date(this.state.expiry_date);
           let curmonth = parseInt(dateobj.getMonth());
           let currmonth = curmonth + 1;
           var expiry_date = currmonth >= 10 ? dateobj.getFullYear()+'/'+currmonth+'/' : dateobj.getFullYear()+'/0'+currmonth+'/';
           expiry_date += dateobj.getDate() < 10 ? '0' + dateobj.getDate() : dateobj.getDate();

           let answer = Store.getStoreData('answer');
           if(answer === null){
               answer = this.state.faq_answer;
           }
           let answer_summary = btoa(window.encodeURIComponent(answer));

           let postData = { 
               "command": command,
                faq_query: this.state.faq_query, 
                faq_answer: answer_summary,
                tags: selectedtags,
                task_id: this.state.faq_id,
                binFiles : bin_files,
                company_id: this.state.company_id,
                car_ref_id: this.state.ref_id,
                faq_id: this.state.original_faq_id
            };

            if(command === 'send_reapprove_faq') {
                postData['expiry_date'] = expiry_date;
            }

            console.log("postData", postData);

           let api = new APICall();
          api.indexCommand(postData, this.processApprovalFAQ);
          this.setState({is_loading: true})
        }else {
            return false;
        }
        
    }

    InactiveFAQ = (event) => {
        event.preventDefault();
        let altmsg = "Are you sure you want to make this FAQ inactive?";
        let confirm = window.confirm(altmsg);
        if (confirm == true) {
           let postData = { 
               "command": "inactive_faq",
                task_id: this.state.faq_id,
                faq_id: this.state.original_faq_id
            };
            Utils.log("postData", postData);

            let api = new APICall();
            api.indexCommand(postData, this.processInactiveFAQ);
            this.setState({is_loading: true})
        }else {
            return false;
        }
    }

    processInactiveFAQ = (result) => {
        this.setState({is_loading: false})
        let msg = result.error_msg;
        alert(msg);
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        this.props.refreshParentCallback(stack);
    }

    processApprovalFAQ =(result) => {
        this.setState({is_loading: false})
        if(result.mode === "delete"){
            let tasks = Store.getStoreData('tasks')
            let role = Store.getStoreData('role')
            let contact_id = Store.getStoreData('contact_id')
            delete tasks[result.task_id.toString()];
            Store.updateStore('tasks', tasks)
            IndexLoader.divideAllTasks(tasks, role, contact_id);
            this.props.refreshParentCallback();
        }else{
            let stack = {};
            stack['task_id'] = result.error_code === 0 ? result.task_id : 0
            stack['task'] = result.error_code === 0 ? result.task : {};
            stack['mode'] = result.error_code === 0 ? result.mode : '';
            let msg = result.error_msg;
            alert(msg);
            this.props.refreshParentCallback(stack);
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }

    processFAQData = (result) => {
        console.log("result", result);
        let task_json = JSON.parse(result.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }
        let {faq_header_text,faq_answer, faq_query, faq_id, cur_files_json, tags, ref_id,company_id,company,original_faq_id, expiry_date, make_faq_inactive, is_active} = this.state;
        faq_answer = task_json.object_data.answer;
        if(Utils.isBase64(faq_answer)){
          faq_answer = window.decodeURIComponent(atob(faq_answer))
        }
        faq_query = task_json.object_data.query;
        faq_header_text = task_json.object_data.query;
        faq_id = result.id;
        cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                cur_files_json.push(item);
            });                 
        }

        ref_id = ('faq_ref_id' in task_json.object_data) ? task_json.object_data.faq_ref_id : "";
        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        let ntags = this.isLoadTags(ptags);
        let create_for = !task_json.object_data.create_for ? 0 : task_json.object_data.create_for;
        company_id = result.company_id;
        // expiry_date = ('expiry_date' in task_json.object_data) ? new Date(task_json.object_data.expiry_date) : this.state.expiry_date;
        let moduleconfig = Store.getStoreData('moduleconfig');
        let days_after_faq_expire = 0;
        if(moduleconfig.enabled_faq_expiry){
            days_after_faq_expire = parseInt(moduleconfig.days_after_faq_expire);
        }
        expiry_date = new Date(Utils.adddaystoDate(days_after_faq_expire));
        original_faq_id = ('original_faq_id' in task_json.object_data) ? task_json.object_data.original_faq_id : 0;
        let gc_assoc = Store.getStoreData('gc_assoc');
        company = parseInt(result.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(result.company_id)%100000).toString()].company_name;

        if(result.is_active === 1){
            make_faq_inactive = ('make_faq_inactive' in task_json.object_data) ? task_json.object_data.make_faq_inactive : true;
        }else{
            make_faq_inactive = true;
        }
        is_active = result.is_active;

        let approved_by = ('published_by' in task_json.object_data) ? Store.getStoreData('contacts')[task_json.object_data.published_by] : '';

        let published_date = ('published_date' in task_json.object_data) ? task_json.object_data.published_date : this.state.expiry_date;
        var c_date = new Date(published_date);
        let dateStr = c_date.toLocaleDateString('en-GB').replace(/,/g, '')
        

        this.setState({faq_header_text,faq_answer, faq_query, faq_id,cur_files_json, tags:ntags, ref_id, company_id,company, original_faq_id, ready:true, expiry_date, make_faq_inactive, is_active, approved_by: approved_by, approved_date: dateStr});
    }

    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }

    componentDidMount()
    {
        let cur_faq_approval_data = Store.getStoreData('cur_faq_reapproval_date');
        this.processFAQData(cur_faq_approval_data);
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            let cur_faq_approval_data = Store.getStoreData('cur_faq_reapproval_date');
            this.processFAQData(cur_faq_approval_data);
        }
        if(this.state.show_scorll) {
            this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
        
    }


    onExpireDateChange = (date) => {
        console.log("date", date);
        this.setState({ expiry_date: date })
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        console.log("this.state", this.state);
        return (
            <ModalContainer>
                {
                    (() => {
                        if(this.state.is_loading) {
                            return <CSLLoader style={{position: 'absolute'}} />
                        }
                    })()
                }
                <ModalHeaderText>{this.state.ref_id} - {this.state.faq_header_text.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</ModalHeaderText>
                <ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <ModalBody>
                    <ModeltopText style={{fontWeight:"600",color: "#f6ce76"}}>This FAQ was submitted and published by {this.state.approved_by} on {this.state.approved_date}  </ModeltopText>
                    <ModalBodyInner>

                        <div style={{clear: "both"}}></div>
                        <ModalContainertop>
                            <ModalNameLabelDiv>ID</ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                {this.state.ref_id}
                            </ModalNameInputDiv>
                        </ModalContainertop>
                            {
                                (() => {
                                    if(Store.getStoreData('has_gc')) {
                                        return (<ModalContainertop>
                                                    <ModalNameLabelDiv>Company</ModalNameLabelDiv>
                                                    <ModalNameInputDiv>{this.state.company}</ModalNameInputDiv>
                                                </ModalContainertop>
                                                )
                                    }
                                })()
                            }
                        <div style={{clear: "both"}}></div>
                        <ModalContainertop>
                            <ModalNameLabelDiv>Question</ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                <ModalTextArea name="faq_query" defaultValue={this.state.faq_query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />
                            </ModalNameInputDiv>
                        </ModalContainertop>
                            
                            <ModalContainertop>
                                <ModalNameLabelDiv>Answer</ModalNameLabelDiv>
                                    <ModalNameInputDiv>
                                        <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.faq_answer} />
                                    </ModalNameInputDiv>
                                    <div style={{clear: "both"}}></div>
                            <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true} />
                            </Style.ModalNameInputDiv>
                        </ModalContainertop>
                        <div style={{clear: "both"}}></div>
                        <ModalContainertop>
                            <ModalNameLabelDiv>Tags</ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                {
                                    this.state.tags.map((tag, index) => {
                                        return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                        
                                    })
                                }
                            </ModalNameInputDiv>
                        </ModalContainertop>
                        {
                            (() => {
                                if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'ca_module_admin') {
                                    return(<ModalContainertop>
                                            <Style.ModalNameLabelDiv>Expiry date</Style.ModalNameLabelDiv>
                                            <CSLDateCover>
                                                <DatePicker
                                                        onChange={this.onExpireDateChange}
                                                        clearIcon={null}
                                                        calendarIcon={null}
                                                        locale={"en-GB"}
                                                        value={this.state.expiry_date}
                                                        />
                                                        </CSLDateCover>
                                            </ModalContainertop>
                                        )
                                }
                            })()
                        }
                    </ModalBodyInner>
                </ModalBody>
                <CancelSubmitButtonOuterContainer>
                {
                    (() => {
                        if(this.state.is_active === 1) {
                            return (
                                <div style={{display:"contents"}}>
                                    {
                                        (() => {
                                            if(!this.state.make_faq_inactive) {
                                                return <InactiveButton onClick={this.InactiveFAQ}>Mark FAQ as inactive</InactiveButton>
                                            }
                                        })()
                                    }
                                    
                                    <PublishButton onClick={() => this.PublishORrevertFAQ('reapprovd')}>Reapprove FAQ</PublishButton>
                                </div>
                                )
                        }
                    })()
                }
                    <CancelButton onClick={() => this.PublishORrevertFAQ('delete')}>Delete FAQ</CancelButton>
                    <CancelButton onClick={this.closeModal}>Close</CancelButton>
                </CancelSubmitButtonOuterContainer>
                <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>

            </ModalContainer>
        );
    }
}
export default ShowReapprovalModal;