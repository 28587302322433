import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store';
import CSLLoader from '../Common/CSLLoader';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
class ShowScheme extends React.Component
{
	state = { ready:false, show_schemes: [] }
	constructor(props) {
	    super(props);
	}
	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}

	processSchems = (result) => {
		console.log("result", result);
		this.setState({ready: true, show_schemes: result});
	}

	processTaskData = () => {
		let parameter_id = this.props.parameter_id;
		let api = new APICall();
		let postData = {};
		postData['command'] = 'show_schems';
		postData['parameter_id'] = parameter_id;
		api.command(postData, this.processSchems)
	}

	closeModal = (event) => {
	    event.preventDefault();
	    this.props.closeModal() 
	}


	processSchemsData = () => {
		let tabledata = this.state.show_schemes;
		console.log("tabledata", tabledata);
		let ret = {data: [], columns: []};
		let i = 0;
		ret.columns =[  
		                {Header: 'Scheme Name', accessor: 'name', minWidth: 150, headerStyle: {textAlign: 'left'}}, 
		                {Header: 'Scheme Description', accessor: 'description', minWidth: 300, headerStyle: {textAlign: 'left'}}
		            ];

		for(let d of tabledata) {
		    i++;
		    let elem = {
		        id: d.id,
		        name : d.name,
		        description : d.description,
		        status: d.status
		    };
		    ret.data.push(elem);
		}
		return ret;
	}
	render()
	{
		if(!this.state.ready) {
		    return(<CSLLoader />);
		}
		
		return (<Style.ModalContainer>
			    <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    <div style={{clear: "both"}}></div>
			    <Style.ModalBody>
			        <Style.ModalBodyInner>
			              <CSLTable add={false} processData={this.processSchemsData} headerText={'Associated Task Schemes'} tableRows="5"/>
			        </Style.ModalBodyInner>
			    </Style.ModalBody>
			    <Style.CancelSubmitButtonOuterContainer>
			        <Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			        </Style.CancelSubmitButtonOuterContainer>
			    </Style.CancelSubmitButtonOuterContainer>
			</Style.ModalContainer>);
	}
}
export default ShowScheme;