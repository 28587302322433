import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import moment from 'moment';

const monthwiseLastDate = {0: 31, 1: 28, 2 : 31, 3: 30, 4: 31, 5: 30 , 6: 31 , 7: 31 , 8: 30, 9: 31, 10: 30 , 11: 31};



Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}

class CMPUtils {


    get_task_from_task_id = (input_task_id) =>{
		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		let followup_tasks = Store.getStoreData('cmp-followup_tasks');
		let task = {};
		for(let k in tasks_ref_assoc){
			if(tasks_ref_assoc[k].id.toString()===input_task_id.toString())task=tasks_ref_assoc[k];
		}
		if(Object.keys(task).length===0){
			for(let element of followup_tasks){
				if(element.id.toString()===input_task_id.toString() && element.task_type === 'COMP_CMP_FOLLOWUP')task=element;
				//break;
			}
		}
		
		return task;
    }

    generate_tasks_ref_assoc = (tasks_from_db) =>{
		//console.log("tasks_from_db=>",tasks_from_db);

		let tasks_ref_assoc = {};
		if(tasks_from_db !== null ){ 
			tasks_from_db.forEach((task) => {
				task.id = parseInt(task.id);
				if(task.task_type === "COMP_CMP"){
					tasks_ref_assoc[task.ref_id] = task;
				}
			});
			
		} 
		Store.updateStore('tasks_ref_assoc',tasks_ref_assoc);
	}
    update_tasks_ref_assoc_for_single_ref_id = (task_from_db) =>{
		//console.log("task_from_db,",task_from_db);
		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		tasks_ref_assoc[task_from_db.ref_id] = task_from_db;
		Store.updateStore('tasks_ref_assoc',tasks_ref_assoc);
	}

    generate_same_series_tasks_hash = (tasks) =>{
		//console.log("tasks=>",tasks);
		let same_series_tasks_hash = {};

		tasks.forEach((task) => {
			//let show = false;
			if(task.task_type === "COMP_CMP"){
				if(typeof same_series_tasks_hash[task.series_id] !== 'undefined'){
					same_series_tasks_hash[task.series_id].push({'task_id' : task.id , cur_lane : task.cur_lane , due_date : task.due_date });
				}else{
					same_series_tasks_hash[task.series_id] = [];
					same_series_tasks_hash[task.series_id].push({'task_id' : task.id , cur_lane : task.cur_lane , due_date : task.due_date });
				}

			}

		});

		Store.updateStore('same_series_tasks_hash',same_series_tasks_hash);
	}

	compareDateGetColor= (first_schedule_date,cur_lane) => {
	  //console.log("cur_lane==>"+cur_lane);
      let ms = moment(first_schedule_date).diff(moment());
      let diff_in_days = parseInt(ms / (1000*24*60*60));
	  //console.log("moment_due_date==>",ms,diff_in_days);
	  let g1 = new Date();
	  let g2 = new Date(first_schedule_date);
	  let color_code = "#04ADA8"; // default green
	  let current_month = g1.getMonth()+1;
	  let current_year = g1.getFullYear();
	  let schedule_month = g2.getMonth()+1;
	  let schedule_year = g2.getFullYear();
	  if(cur_lane === "COMP_CMP_COMPLETED"){
		  color_code = "#04ADA8"; // green
	  }else{
		  
		  //if(g1 < g2){ //curdate less than schedule date
		  if(diff_in_days > 0){ //curdate less than schedule date
			  //color_code = "blue"; // blue
			  if(current_month === schedule_month && current_year === schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "#F39C12"; // amber
				  }			  
			  }else if(current_month === schedule_month && current_year > schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month === schedule_month && current_year < schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }else if(current_month < schedule_month && current_year === schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }else if(current_month < schedule_month && current_year > schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month < schedule_month && current_year < schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }else if(current_month > schedule_month && current_year === schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month > schedule_month && current_year > schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month > schedule_month && current_year < schedule_year){
				  if(cur_lane === "COMP_CMP_COMPLETED"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }
		  //}else if(g1 > g2){ //curdate greater than schedule date
		  }else if(diff_in_days < 0){ //curdate greater than schedule date
			  if(cur_lane === "COMP_CMP_COMPLETED"){
				  color_code = "#04ADA8"; // green
			  }else{
				  color_code = "red"; // red
			  }
		  }else{
			  if(cur_lane === "COMP_CMP_COMPLETED"){
				  color_code = "#04ADA8"; // green
			  }else{
				  color_code = "#F39C12"; // amber
			  }
		  }
		  

	  }
	  return color_code;
	}
  
	compareDateGetColorRemedial = (due_date,cur_lane) => {
	  
      let ms = moment(due_date).diff(moment());
      let diff_in_days = parseInt(ms / (1000*24*60*60));
	  //console.log("moment_due_date==>",ms,diff_in_days);
	  let g1 = new Date();
	  let g2 = new Date(due_date);
	  let color_code = "#04ADA8"; // default green
	  let current_month = g1.getMonth()+1;
	  let current_year = g1.getFullYear();
	  let schedule_month = g2.getMonth()+1;
	  let schedule_year = g2.getFullYear();
	  //console.log("compareDateGetColorRemedial==>",due_date,cur_lane,diff_in_days);
	  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
		  color_code = "#04ADA8"; // green
	  }else{
		  
		  if(g1.withoutTime() < g2.withoutTime()){ //curdate less than schedule date
		  //if(diff_in_days > 0){ //curdate less than schedule date
			  //color_code = "blue"; // blue
			  if(current_month === schedule_month && current_year === schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "#F39C12"; // amber
				  }			  
			  }else if(current_month === schedule_month && current_year > schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month === schedule_month && current_year < schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }else if(current_month < schedule_month && current_year === schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }else if(current_month < schedule_month && current_year > schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month < schedule_month && current_year < schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }else if(current_month > schedule_month && current_year === schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month > schedule_month && current_year > schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "red"; // red
				  }
			  }else if(current_month > schedule_month && current_year < schedule_year){
				  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
					  color_code = "#04ADA8"; // green
				  }else{
					  color_code = "blue"; // blue
				  }
			  }
			  //if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED' && color_code = "#F39C12")color_code='blue';
		  }else if(g1.withoutTime() > g2.withoutTime()){ //curdate greater than schedule date
		  //}else if(diff_in_days < 0){ //curdate greater than schedule date
			  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
				  color_code = "#04ADA8"; // green
			  }else{
				  color_code = "red"; // red
			  }
		  }else{
			  if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
				  color_code = "#04ADA8"; // green
			  }else{
				  color_code = "#F39C12"; // amber
			  }
		  }
	  }
	  if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED' && color_code === "#F39C12")color_code='blue';
	  return color_code;
	}
    get_color_code_basedon_priority = (color_codes_array) => {
		let ret_color_code ;
		if(color_codes_array.includes('red')){ // red
			ret_color_code = "red";
		}else{
			if(color_codes_array.includes('#F39C12')){ // amber
			    ret_color_code = "#F39C12";
			}else{
				if(color_codes_array.includes('blue')){ // blue
				    ret_color_code = "blue";
				}else{
					ret_color_code = "#04ADA8";
				}
			}
		}
		return ret_color_code;
	}
	makeDecisionToStayInTheList = (current_task,same_series_tasks_hash) => {

		let same_series_tasks_array = same_series_tasks_hash[current_task.series_id];
		
		let show = false;

		if(typeof same_series_tasks_array !== 'undefined' && same_series_tasks_array.length > 0){
			let current_index = 0;
			for(let i=0;i<same_series_tasks_array.length;i++){
				if('task_id' in current_task){
					if(current_task.task_id === same_series_tasks_array[i].task_id){
						current_index = i;
						break;
					}				
				}else{
					if(current_task.id === same_series_tasks_array[i].task_id){
						current_index = i;
						break;
					}				
				}

			}
			//console.log("HHH current_index=>",current_index);
			if(same_series_tasks_array.length === 1){
				show = true;
			}else{
				for(let i=0;i<same_series_tasks_array.length;i++){
					if(i === 0 && typeof current_index !== 'undefined' && current_index.toString() === i.toString()){
						show = true;
						break;
					}
					if(i > 0 &&  typeof current_index !== 'undefined' && current_index.toString() === i.toString()){
						let previous_task_id = same_series_tasks_array[i-1].task_id;
						if(same_series_tasks_array[i].cur_lane !== "COMP_CMP_COMPLETED" && same_series_tasks_array[i-1].cur_lane === "COMP_CMP_COMPLETED"){
							show = true;
							break;						
						}
						if(same_series_tasks_array[i].cur_lane === "COMP_CMP_COMPLETED" ){
							show = true;
							break;						
						}

					}
				}
				//console.log("HHH current_index=>",current_index , show , current_task.id , same_series_tasks_array);
			}			
		}

		return show;

	}

	getFormattedDate = (input_date) => {
		var date = new Date(input_date);
		var month = (date.getMonth() +1) < 10 ? '0'+(date.getMonth() +1) : (date.getMonth() +1);
		var dt = (date.getDate() < 10 ) ? '0'+(date.getDate()) : (date.getDate());
		return dt +'/'+ month + '/'+date.getFullYear();
	}

	/*hints below */
    processHint = (plan_json_parsed,plan_id,my_tasks,plan_ref_id,role,input_year , is_first_time_loading) => {
    	//console.log('selected_plan_id new_parent_tasks_list in hint === 0', plan_json_parsed,plan_id,my_tasks);
		let parent_company_id = Store.getStoreData('parent_company').id;
    	let tasks_assoc = {};
		let time_span = 3; // in years
		let hint_task = {};
		let no_of_activities_hash = {};
		no_of_activities_hash['Annually'] = 1*time_span;
		no_of_activities_hash['Semi-Annually'] = 2*time_span;
		no_of_activities_hash['Quarterly'] = 4*time_span;
		no_of_activities_hash['Every-Two-Months'] = 6*time_span;
		no_of_activities_hash['Monthly'] = 12*time_span;
		no_of_activities_hash['Bi-weekly'] = 7*4*time_span;
		no_of_activities_hash['Weekly'] = 13*4*time_span;
		no_of_activities_hash['Daily'] = 360*time_span; // 

		let parsed_plan_ref = plan_ref_id.split('_');
		let plan_ref = parsed_plan_ref[1] ;
		let gc_id = parsed_plan_ref[2];

		let ret = {new_parent_tasks_list : [] , new_followup_tasks_list : [] };

		let testing_activities = plan_json_parsed.testing_activities;
		//console.log("testing_activities=>",testing_activities);

		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');

		for (let i=0;i<testing_activities.length;i++){
			let risk_json = {
				"testing_activity": testing_activities[i].testing_activity,
				"testing_description": testing_activities[i].testing_description,
				"testing_freq": testing_activities[i].testing_freq,
				"first_schedule": testing_activities[i].first_schedule,
				"testing_owner": testing_activities[i].testing_owner,
				"assurance_partner_id": 'assurance_partner_id' in testing_activities[i] ? testing_activities[i].assurance_partner_id : '0',
				"testing_objectives": testing_activities[i].testing_objectives,
				"testing_method": testing_activities[i].testing_method,
				"additional_guidance": testing_activities[i].additional_guidance,
				"unique_testing_act_id": testing_activities[i].unique_testing_act_id,
				"unique_subcat_id": testing_activities[i].unique_subcat_id,
				"unique_risk_id": testing_activities[i].unique_risk_id
			}
			let ta_start = moment(testing_activities[i].first_schedule);
            
			let exclusions = {};


			
			// console.log('PlansChartLog t, ta_start', t, ta_start)
			//t.ta_series = []
			let ta_length = 100
			let ta_freq = 1
			let ta_period = 'day';

			switch(testing_activities[i].testing_freq) {
				case 'Annually':      ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 12
									  break;
				case 'Semi-Annually': ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 6
									  break;
				case 'Quarterly':     ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 3
									  break;
				case 'Every-Two-Months':     ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 2
									  break;
				case 'Monthly':       ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 1
									  break;
				case 'Bi-weekly':     ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'day'
									  ta_freq = 14
									  break;
				case 'Weekly':        ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'day'
									  ta_freq = 7
									  break;
				case 'Daily':         ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'day'
									  ta_freq = 1
									  break;
				case 'Once':          ta_length = 1;
									  ta_period = 'day'
									  ta_freq = 1
									  break;
			}
			//console.log("ta_length=>",ta_length);
            //is_deleted
			for(let j = 0; j < ta_length; j++) {
				let risk_json_new = JSON.parse(JSON.stringify(risk_json));
				let due_date = (j === 0) ? ta_start : ta_start.add(ta_freq, ta_period);
                risk_json_new.first_schedule = due_date.toDate().toISOString();
                //console.log("due_date==>",due_date.toDate() , testing_activities[i].first_schedule);
				let day = due_date.format('DD');
				let month = due_date.format('MM');
				let year = due_date.format('YYYY');
				let date = year + '-' + month + '-' + day;
				let date_moment_unix = moment(date).unix();
                //if(!is_first_time_loading && year.toString() !== input_year.toString())continue
				let ref_id = `${plan_id}-${gc_id}-${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${year}${month}${day}-${parent_company_id}`;

				let elem = {
							  id: `CMPTASK|${testing_activities[i].unique_risk_id}|${testing_activities[i].unique_subcat_id}|${testing_activities[i].unique_testing_act_id}|${year}${month}${day}|${plan_id}|${plan_ref}|${gc_id}`,
                              cur_lane : `COMP_CMP_ASSIGNMENT` ,							  
                              parent_task_id : 0 ,							  
                              physical_task_id : 0 ,							  
							  task_type: `COMP_CMP`, 
							  risk_result: {}, 
							  same_series_tasks: [], 
							  due_date: due_date.format('DD/MM/YYYY'), 
							  day: day, 
							  month: month, 
							  year: year,
                              date:	date,							  
                              sort_field:	date_moment_unix.toString(),							  
							  ref_id: ref_id,
							  plan_id: plan_id,
							  plan_ref: `${plan_ref}-${gc_id}`,
							  gc_id: gc_id,
							  unique_risk_id: testing_activities[i].unique_risk_id,
							  unique_subcat_id: testing_activities[i].unique_subcat_id,
							  unique_testing_act_id: testing_activities[i].unique_testing_act_id,
							  series_id: `${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${plan_ref}-${gc_id}`,
							  risk_json: risk_json_new,
							  binfiles_id: ('attachments' in testing_activities[i]) ? testing_activities[i].attachments[0] : 0 ,
						   };

				elem.created_by = (typeof testing_activities[i].created_by !== 'undefined') ? testing_activities[i].created_by : '0';
				let temp_date = `${year}${month}${day}`;
				let temp_dt = year + '-' + month + '-' + day;
				let temp_dt_unix = moment(temp_dt).unix();
                if(testing_activities[i].is_deleted){
					if(testing_activities[i].delete_reason.reason === 'date_change_series' || testing_activities[i].delete_reason.reason === 'acitivity_by_series' || testing_activities[i].delete_reason.reason === 'frequency_change'){
						let starting_from = testing_activities[i].delete_reason.starting_from;
						let starting_from_dt = starting_from.substring(0,4) + '-' + starting_from.substring(4,6) + '-' + starting_from.substring(6);
						let starting_from_unix = moment(starting_from_dt).unix();
						
						//console.log("date_change_series date",date);
						let delete_ref_id = (temp_date === starting_from) ? `${plan_id}-${gc_id}-${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${starting_from}-${parent_company_id}` : `${plan_id}-${gc_id}-${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${temp_date}-${parent_company_id}`;
						exclusions[starting_from] = true;
						if(typeof tasks_ref_assoc[delete_ref_id] === 'undefined' || temp_dt_unix > starting_from_unix){exclusions[temp_date] = true;}
						if(delete_ref_id in tasks_ref_assoc && tasks_ref_assoc[delete_ref_id].cur_lane === 'COMP_CMP_COMPLETED'){delete exclusions[temp_date];}
						if(temp_dt_unix < starting_from_unix)delete exclusions[temp_date];
						//console.log("in delete_ref_id",delete_ref_id,tasks_ref_assoc[delete_ref_id]);
					}
				}
				if(testing_activities[i].delete_reason.reason === 'date_scheduled_change_single'){
					for(let p of testing_activities[i].delete_reason.missing_at){
						if(p.actual.toString() === temp_date){
							elem.risk_json.first_schedule = p.tobeShown;
						}
					}
				}
				if(typeof testing_activities[i].exclusions !== 'undefined'){
					for(let newkey in testing_activities[i].exclusions){
						//let date = this.getFormattedDate(newkey);
						if(testing_activities[i].exclusions[newkey] === 'single'){
							exclusions[newkey] = true;
						}
					
					}				
				}
				if(tasks_ref_assoc !== null && typeof tasks_ref_assoc[ref_id] !== 'undefined'){
					elem.physical_task_id = tasks_ref_assoc[ref_id].id;
					elem.risk_result = tasks_ref_assoc[ref_id].risk_result;
					elem.cur_lane = tasks_ref_assoc[ref_id].cur_lane;
					elem.risk_json.first_schedule = tasks_ref_assoc[ref_id].risk_json.first_schedule;
					if(tasks_ref_assoc[ref_id].cur_lane === 'COMP_CMP_APPROVAL_REQUEST' && (role === 'plan_admin' || role === 'risk_owner'))elem.risk_json.enable_approval_assigned_to = tasks_ref_assoc[ref_id].cur_assigned_to;
				}
                
				if(typeof exclusions[temp_date] === 'undefined'){
                    let scheduled_date_year = new Date(elem.risk_json.first_schedule).getFullYear();
					//if(!is_first_time_loading && scheduled_date_year.toString() === input_year.toString())ret.new_parent_tasks_list.push(elem);
					//if(is_first_time_loading)ret.new_parent_tasks_list.push(elem);
					ret.new_parent_tasks_list.push(elem);
				}
				
			}
						
						
		}
		//ret.new_parent_tasks_list.sort(this.fieldCompare)
		return ret;
	}

	/*hints below */
    processHintTmView = (plan_json_parsed,plan_id,my_tasks,plan_ref_id,role,input_year , is_first_time_loading) => {
    	//console.log('selected_plan_id new_parent_tasks_list in hint === 0', plan_json_parsed,plan_id,my_tasks);
		let parent_company_id = Store.getStoreData('parent_company').id;
    	let tasks_assoc = {}
		let time_span = 2; // in years
		let hint_task = {};
		let no_of_activities_hash = {};
		no_of_activities_hash['Annually'] = 1*time_span;
		no_of_activities_hash['Semi-Annually'] = 2*time_span;
		no_of_activities_hash['Quarterly'] = 4*time_span;
		no_of_activities_hash['Every-Two-Months'] = 6*time_span;
		no_of_activities_hash['Monthly'] = 12*time_span;
		no_of_activities_hash['Bi-weekly'] = 7*4*time_span;
		no_of_activities_hash['Weekly'] = 13*4*time_span;
		no_of_activities_hash['Daily'] = 360*time_span; // 

		let parsed_plan_ref = plan_ref_id.split('_');
		let plan_ref = parsed_plan_ref[1] ;
		let gc_id = parsed_plan_ref[2];

		let ret = {new_parent_tasks_list : [] , new_followup_tasks_list : [] };

		let testing_activities = plan_json_parsed.testing_activities;
		//console.log("testing_activities=>",testing_activities);

		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');

		for (let i=0;i<testing_activities.length;i++){
			let risk_json = {
				"testing_activity": testing_activities[i].testing_activity,
				"testing_description": testing_activities[i].testing_description,
				"testing_freq": testing_activities[i].testing_freq,
				"first_schedule": testing_activities[i].first_schedule,
				"testing_owner": testing_activities[i].testing_owner,
				"assurance_partner_id": 'assurance_partner_id' in testing_activities[i] ? testing_activities[i].assurance_partner_id : '0',
				"testing_objectives": testing_activities[i].testing_objectives,
				"testing_method": testing_activities[i].testing_method,
				"additional_guidance": testing_activities[i].additional_guidance,
				"unique_testing_act_id": testing_activities[i].unique_testing_act_id,
				"unique_subcat_id": testing_activities[i].unique_subcat_id,
				"unique_risk_id": testing_activities[i].unique_risk_id
			}
			let ta_start = moment(testing_activities[i].first_schedule);
            
			let exclusions = {};


			
			//console.log('PlansChartLog t, ta_start', ta_start)
			//t.ta_series = []
			let ta_length = 100
			let ta_freq = 1
			let ta_period = 'day';

			switch(testing_activities[i].testing_freq) {
				case 'Annually':      ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 12
									  break;
				case 'Semi-Annually': ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 6
									  break;
				case 'Quarterly':     ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 3
									  break;
				case 'Every-Two-Months':     ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 2
									  break;
				case 'Monthly':       ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'month'
									  ta_freq = 1
									  break;
				case 'Bi-weekly':     ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'day'
									  ta_freq = 14
									  break;
				case 'Weekly':        ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'day'
									  ta_freq = 7
									  break;
				case 'Daily':         ta_length = no_of_activities_hash[testing_activities[i].testing_freq]
									  ta_period = 'day'
									  ta_freq = 1
									  break;
				case 'Once':          ta_length = 1;
									  ta_period = 'day'
									  ta_freq = 1
									  break;
			}


			for(let j = 0; j < ta_length; j++) {
				let risk_json_new = JSON.parse(JSON.stringify(risk_json));
				let due_date = (j === 0) ? ta_start : ta_start.add(ta_freq, ta_period);
                risk_json_new.first_schedule = due_date.toDate().toISOString();
                //console.log("due_date==>",due_date.toDate() , testing_activities[i].first_schedule);
				let day = due_date.format('DD');
				let month = due_date.format('MM');
				let year = due_date.format('YYYY');
				let date = year + '-' + month + '-' + day;
                //if(!is_first_time_loading && year.toString() !== input_year.toString())continue
				let ref_id = `${plan_id}-${gc_id}-${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${year}${month}${day}-${parent_company_id}`;

				let elem = {
							  id: `CMPTASK|${testing_activities[i].unique_risk_id}|${testing_activities[i].unique_subcat_id}|${testing_activities[i].unique_testing_act_id}|${year}${month}${day}|${plan_id}|${plan_ref}|${gc_id}`,
                              cur_lane : `COMP_CMP_ASSIGNMENT` ,							  
                              parent_task_id : 0 ,							  
                              physical_task_id : 0 ,							  
							  task_type: `COMP_CMP`, 
							  risk_result: {}, 
							  same_series_tasks: [], 
							  due_date: due_date.format('DD/MM/YYYY'), 
							  day: day, 
							  month: month, 
							  year: year,
                              date:	date,							  
							  ref_id: ref_id,
							  plan_id: plan_id,
							  plan_ref: `${plan_ref}-${gc_id}`,
							  gc_id: gc_id,
							  unique_risk_id: testing_activities[i].unique_risk_id,
							  unique_subcat_id: testing_activities[i].unique_subcat_id,
							  unique_testing_act_id: testing_activities[i].unique_testing_act_id,
							  series_id: `${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${plan_ref}-${gc_id}`,
							  risk_json: risk_json_new,
							  binfiles_id: ('attachments' in testing_activities[i]) ? testing_activities[i].attachments[0] : 0 ,
						   };

				elem.created_by = (typeof testing_activities[i].created_by !== 'undefined') ? testing_activities[i].created_by : '0';
				let temp_date = `${year}${month}${day}`;
                if(testing_activities[i].is_deleted){
					if(testing_activities[i].delete_reason.reason === 'date_change_series' || testing_activities[i].delete_reason.reason === 'acitivity_by_series' || testing_activities[i].delete_reason.reason === 'frequency_change'){
						let starting_from = testing_activities[i].delete_reason.starting_from;
						
						//console.log("date_change_series date",date);
						let delete_ref_id = (temp_date === starting_from) ? `${plan_id}-${gc_id}-${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${starting_from}` : `${plan_id}-${gc_id}-${testing_activities[i].unique_risk_id}-${testing_activities[i].unique_subcat_id.toString()}-${testing_activities[i].unique_testing_act_id}-${temp_date}`;
						exclusions[starting_from] = true;
						if(typeof tasks_ref_assoc[delete_ref_id] === 'undefined'){exclusions[temp_date] = true;}
					}
				}
				if(testing_activities[i].delete_reason.reason === 'date_scheduled_change_single'){
					for(let p of testing_activities[i].delete_reason.missing_at){
						if(p.actual.toString() === temp_date){
							elem.risk_json.first_schedule = p.tobeShown;
						}
					}
				}
				if(typeof testing_activities[i].exclusions !== 'undefined'){
					for(let newkey in testing_activities[i].exclusions){
						//let date = this.getFormattedDate(newkey);
						if(testing_activities[i].exclusions[newkey] === 'single'){
							exclusions[newkey] = true;
						}
					
					}				
				}
				if(tasks_ref_assoc !== null && typeof tasks_ref_assoc[ref_id] !== 'undefined'){
					elem.physical_task_id = tasks_ref_assoc[ref_id].id;
					elem.risk_result = tasks_ref_assoc[ref_id].risk_result;
					elem.cur_lane = tasks_ref_assoc[ref_id].cur_lane;
					elem.risk_json.first_schedule = tasks_ref_assoc[ref_id].risk_json.first_schedule;
					if(tasks_ref_assoc[ref_id].cur_lane === 'COMP_CMP_APPROVAL_REQUEST' && (role === 'plan_admin' || role === 'risk_owner'))elem.risk_json.enable_approval_assigned_to = tasks_ref_assoc[ref_id].cur_assigned_to;
				}
                
				if(typeof exclusions[temp_date] === 'undefined'){
                    let scheduled_date_year = new Date(elem.risk_json.first_schedule).getFullYear();
					//if(!is_first_time_loading && scheduled_date_year.toString() === input_year.toString())ret.new_parent_tasks_list.push(elem);
					//if(is_first_time_loading)ret.new_parent_tasks_list.push(elem);
					ret.new_parent_tasks_list.push(elem);
				}
				
			}
						
						
		}
		return ret;
	}


	add_date_by_weeks = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}	
	add_date_by_days = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}
	add_date_by_months = (date, months,last_month,last_year,type) => {
		//// console.log("last_month=>",last_month);
		
		if(typeof last_month !== 'undefined' && last_month !== ''){
			let result = new Date(date);
			//// console.log("date=>",date);
			if(typeof type !== 'undefined'){ // for month default
				if(type === 'Monthly'){
					let date1 = result.getDate();
					let year = last_year;
					let current_month = last_month + 1;
					if(current_month === 12){current_month = 0; year = year + 1;}
					
					if(date1 >= monthwiseLastDate[current_month]){
						result.setDate(monthwiseLastDate[current_month]);
						result.setMonth(current_month);
						result.setFullYear(year);
						//result.setHours(0,0,0,0);				
					}else{
						result.setMonth(result.getMonth() + months);
						//result.setHours(0,0,0,0);
					}					
				}
				if(type === 'Quarterly'){
					let date1 = result.getDate();
					let year = last_year;
					let current_month = last_month + 3;
					if(current_month === 12){current_month = 0; year = year + 1;}
					
					if(date1 >= monthwiseLastDate[current_month]){
						result.setDate(monthwiseLastDate[current_month]);
						result.setMonth(current_month);
						result.setFullYear(year);
						//result.setHours(0,0,0,0);				
					}else{
						result.setMonth(result.getMonth() + months);
						//result.setHours(0,0,0,0);
					}					
				}
				if(type === 'Semi-Annually'){
					let date1 = result.getDate();
					let year = last_year;
					let current_month = last_month + 6;
					if(current_month === 12){current_month = 0; year = year + 1;}
					
					if(date1 >= monthwiseLastDate[current_month]){
						result.setDate(monthwiseLastDate[current_month]);
						result.setMonth(current_month);
						result.setFullYear(year);
						//result.setHours(0,0,0,0);				
					}else{
						result.setMonth(result.getMonth() + months);
						//result.setHours(0,0,0,0);
					}					
				}
				if(type === 'Annually'){
					let date1 = result.getDate();
					let year = last_year;
					let current_month = last_month + 12;
					if(current_month === 12){current_month = 0; year = year + 1;}
					
					if(date1 >= monthwiseLastDate[current_month]){
						result.setDate(monthwiseLastDate[current_month]);
						result.setMonth(current_month);
						result.setFullYear(year);
						//result.setHours(0,0,0,0);				
					}else{
						result.setMonth(result.getMonth() + months);
						//result.setHours(0,0,0,0);
					}					
				}
				
			}
			//let result = new Date(date);

			
			return result;			
		}else{
			let result = new Date(date);
			result.setMonth(result.getMonth() + months);
			//result.setHours(0,0,0,0);
			return result;
		}
	}
	getRegenrationIndexBasedOnFrequencyMode = (frequency) => {
		
		let regenerate_interval = 0;
		switch(frequency) {
			case 'Annually': regenerate_interval = 1;
				break;
			case 'Semi-Annually': regenerate_interval = 2;
				break;
			case 'Quarterly': regenerate_interval = 4;
				break;
			case 'Every-Two-Months': regenerate_interval = 6;
				break;
			case 'Monthly': regenerate_interval = 12;
				break;
			case 'Bi-weekly': regenerate_interval = 28;
				break;
			case 'Weekly': regenerate_interval = 56;
				break;
			case 'Daily': regenerate_interval = 360;
				break;				
		}
		return regenerate_interval;
		
	}
	filter_tasks_by_date = (tasks,start_date,end_date) =>{
		let start_date_unix = moment(start_date).unix();
		let end_date_unix = moment(end_date).unix();
		const filtered_tasks = tasks.filter(task => moment(task.date).unix()>=start_date_unix && moment(task.date).unix()<=end_date_unix);
		return filtered_tasks;
	}
	processRiskAreas = (compliance_plans_pre ,selected_plan_ref) =>{
		let compliance_plans = Store.getStoreData('cmp-compliance_plans');
		//let compliance_plans = JSON.parse(JSON.stringify(compliance_plans_pre));
		//let cmputils = new CMPUtils();
        let gc_with_cmp_data = {};
		let plan_names = [];
		let original_risk_areas_pre_temp = [];
		let tasks_without_filter = [];
		let eligible_plans = {};
		let gc_assoc = Store.getStoreData('gc_assoc');
		let parent_company = Store.getStoreData('parent_company');
		let role = Store.getStoreData('role');
		let my_actual_tasks = Store.getStoreData('cmp-my_actual_tasks');
		let loggedin_user_info = Store.getStoreData('cmp-loggedin_user_info');
		let current_year = Store.getStoreData('current_year');

		let ref_plans = this.getOrderedPlanList(compliance_plans);
		if(selected_plan_ref === '0')selected_plan_ref = ref_plans[0].plan_ref;
		console.log("ref_plans,",ref_plans);
		let compliance_plan_assoc = {};
		for(let j=0;j<compliance_plans.length;j++){
			let plan_json = JSON.parse(compliance_plans[j].plan_json);
			if(compliance_plans[j].is_active === 0 || plan_json.risk_areas.length === "0")continue;
			if(compliance_plans[j].plan_ref_id.split('_')[1] !== selected_plan_ref)continue;

			gc_with_cmp_data[compliance_plans[j].gc_id] = true;
			
			plan_names.push({
				name: plan_json.plan_name, 
				id: compliance_plans[j].id,
				sort_field: plan_json.plan_name,
			});
			
			let plan_ref = compliance_plans[j].plan_ref_id.split('_')[1]
			plan_json.risk_areas.forEach((item) => {
				
				item.plan_id = compliance_plans[j].id;
				item.gc_id = compliance_plans[j].gc_id;
				item.plan_ref = plan_ref
				item.sort_field = item.gc_id.toString() !== '0' ? item.risk_area_name + '-' + gc_assoc[item.gc_id.toString()].company_name :  item.risk_area_name + '-' + parent_company.company_name
				let subcategories = [];
				item.subcategories.forEach((subcat_item) => {
					subcat_item.plan_id = compliance_plans[j].id;
					subcat_item.gc_id = compliance_plans[j].gc_id;
					subcat_item.plan_ref = plan_ref;
					subcategories.push(subcat_item);
					if(role === 'subcat_owner' && subcat_item.subcat_status === 'Active' && loggedin_user_info.ID.toString() === subcat_item.subcat_assignto.toString())eligible_plans[plan_ref] = true;
				});
				item.subcategories = subcategories;
				if(item.status !== 'Deleted')original_risk_areas_pre_temp.push(item);
			});
				
			
			// selected_plan_id = compliance_plans[0].id;
			//console.log('MGRIndex original_risk_areas_pre', original_risk_areas_pre_temp)

			let plan_ref_id = compliance_plans[j].plan_ref_id;
			compliance_plan_assoc[plan_ref_id] = {id : compliance_plans[j].id};

			let ret_from_hint_task =  this.processHint(plan_json,compliance_plans[j].id,my_actual_tasks,compliance_plans[j].plan_ref_id , role , current_year , false);
			ret_from_hint_task.new_parent_tasks_list.forEach((item) => {
				tasks_without_filter.push(item);
			});
		}
		//console.log("tasks_without_filter,",tasks_without_filter , current_year);
		if(role === 'subcat_owner' && Object.keys(eligible_plans).length > 0){
			console.log("eligible_plans,",eligible_plans);
			let updated_compliance_plans = [];
			for(let cp of compliance_plans) {
				if(cp.plan_ref_id.split('_')[1] in eligible_plans)updated_compliance_plans.push(cp);
			}
			compliance_plans = updated_compliance_plans;
			Store.updateStore('cmp-compliance_plans' , compliance_plans);
			let updated_risk_areas = [];
			for(let ra of original_risk_areas_pre_temp) {
				if(ra.plan_ref in eligible_plans)updated_risk_areas.push(ra);
			}
			original_risk_areas_pre_temp = updated_risk_areas;
		}
		original_risk_areas_pre_temp.sort(this.fieldCompare)
		Store.updateStore('gc_with_cmp_data',gc_with_cmp_data);
		plan_names.sort(this.fieldCompare)
		Store.updateStore('plan_names',plan_names);	
		
		return {  original_risk_areas_pre_temp : original_risk_areas_pre_temp , 
			      tasks_without_filter : tasks_without_filter , 
			      compliance_plans : compliance_plans , 
			      gc_with_cmp_data : gc_with_cmp_data
			    }
	}
	processFollowupActions = (followup_tasks) =>{
		console.log("followup_tasks,",followup_tasks);
		let actions = [];
		if(followup_tasks !== null){
			followup_tasks.forEach((task) => {
				
				//if((task.last_action === "COMP_CMP_FOLLOWUP_CREATE" || task.last_action === "COMP_CMP_FOLLOWUP_SAVED") && task.cur_assigned_to === Store.getStoreData('loggedin_user_info').ID ){
					let task_json = JSON.parse(task.task_json);
					let jsonobj = {'task_id' : task.id , 'action_title' : task_json.object_data.headerinfo.risk_json.actions.action_title , 'action_description' : task_json.object_data.headerinfo.risk_json.actions.action_description , 'cur_assigned_to' : task.cur_assigned_to , 'created_by' : task.created_by, 'parent_task_id' : task.parent_task_id ,'parent_task_ref_id' : task.parent_task_ref_id , 'cur_lane' : task.cur_lane , 'due_date' : (typeof task_json.object_data.headerinfo.risk_json.actions.due_date === 'undefined') ? '-' : task_json.object_data.headerinfo.risk_json.actions.due_date};
					actions.push(jsonobj);
				//}
			});
		}
		return actions;
	}
    fieldCompare = (a,b) => {
        return a.sort_field.localeCompare(b.sort_field)
    }
    nameCompare = (a,b) => {
        return a.company_name.localeCompare(b.company_name)
    }
	getCompaniesList = (selected_plan_ref) => {//changed on 02-10-2021
		
		let ret = {gc_list: [], current_gc: {}, parent_has_plan: false, company: null}
		if(!selected_plan_ref)return ret
        let gc_companies = Store.getStoreData('cmp-gc_companies');
        let compliance_plans = Store.getStoreData('cmp-compliance_plans');
        let current_gcid = Store.getStoreData('current_gcid');
        console.log('compliance_plans', compliance_plans,current_gcid);
        /*let selected_cp = {}
        for(let cp of compliance_plans) {
            if(cp.id.toString() === selected_plan_id.toString()) {
                selected_cp = cp
                break
            }
        }
        console.log('CreateRiskModal selected_cp', selected_cp)*/
        //let selected_planref_parts = selected_cp.plan_ref_id.split('_')
        //let selected_planref = `${selected_planref_parts[0]}_${selected_planref_parts[1]}`
        let included_gcs = {}
        // let parent_has_plan = false
        for(let cpp of compliance_plans) {
            //if(cpp.plan_ref_id.startsWith(selected_planref)) {
            if(cpp.plan_ref_id.split('_')[1].toString() === selected_plan_ref.toString()) {
                included_gcs[cpp.gc_id.toString()] = 1
                if(cpp.gc_id === 0)ret.parent_has_plan = true
            }
        }
        // let gc_list = []
        // let current_gc = {}
        // let company = {}
        for(let c of gc_companies) {
            console.log('gc_list c', c)
            // if(this.state.risk_owner_id in c.users){
            //if(c.is_gc === true && c.id !== current_gcid && c.id.toString() in included_gcs)ret.gc_list.push(c)
            if(c.is_gc === true && c.id.toString() in included_gcs)ret.gc_list.push(c)
            if(parseInt(current_gcid) === 0 && !c.is_gc)ret.current_gc = c
            if(parseInt(current_gcid) === parseInt(c.id) && c.is_gc)ret.current_gc = c
            if(!c.is_gc && "0" in included_gcs) {
				ret.company = c
				c.gc_id = 0
				ret.gc_list.push(c)
			}
            // }
        }
        ret.gc_list.sort(this.nameCompare)

		return ret
	}

	getCompaniesList_obsolate = (selected_plan_id) => {
		console.log('selected_plan_id', selected_plan_id)
		
		let ret = {gc_list: [], current_gc: {}, parent_has_plan: false, company: null}
		if(!selected_plan_id)return ret
        let gc_companies = Store.getStoreData('cmp-gc_companies');
        let compliance_plans = Store.getStoreData('cmp-compliance_plans');
        let current_gcid = Store.getStoreData('current_gcid')
        console.log('compliance_plans', compliance_plans)
        let selected_cp = {}
        for(let cp of compliance_plans) {
            if(cp.id.toString() === selected_plan_id.toString()) {
                selected_cp = cp
                break
            }
        }
        console.log('CreateRiskModal selected_cp', selected_cp)
        let selected_planref_parts = selected_cp.plan_ref_id.split('_')
        let selected_planref = `${selected_planref_parts[0]}_${selected_planref_parts[1]}`
        let included_gcs = {}
        // let parent_has_plan = false
        for(let cpp of compliance_plans) {
            if(cpp.plan_ref_id.startsWith(selected_planref)) {
                included_gcs[cpp.gc_id.toString()] = 1
                if(cpp.gc_id === 0)ret.parent_has_plan = true
            }
        }
        // let gc_list = []
        // let current_gc = {}
        // let company = {}
        for(let c of gc_companies) {
            console.log('gc_list c', c)
            // if(this.state.risk_owner_id in c.users){
            //if(c.is_gc === true && c.id !== current_gcid && c.id.toString() in included_gcs)ret.gc_list.push(c)
            if(c.is_gc === true && c.id.toString() in included_gcs)ret.gc_list.push(c)
            if(parseInt(current_gcid) === 0 && !c.is_gc)ret.current_gc = c
            if(parseInt(current_gcid) === parseInt(c.id) && c.is_gc)ret.current_gc = c
            if(!c.is_gc && "0" in included_gcs) {
				ret.company = c
				c.gc_id = 0
				ret.gc_list.push(c)
			}
            // }
        }
        ret.gc_list.sort(this.nameCompare)

		return ret
	}

	// Parse an ISO date string (i.e. "2019-01-18T00:00:00.000Z",
	// "2019-01-17T17:00:00.000-07:00", or "2019-01-18T07:00:00.000+07:00",
	// which are the same time) and return a JavaScript Date object with the
	// value represented by the string.
	isoStringToDate = (dateString) => {
		console.log("isoStringToDate=>",dateString);
		const b = dateString.split(/\D+/);
		const offsetMult = dateString.indexOf('+') !== -1 ? -1 : 1;
		const hrOffset = offsetMult * (+b[7] || 0);
		const minOffset = offsetMult * (+b[8] || 0);
		console.log("isoStringToDate=>",new Date(Date.UTC(+b[0], +b[1] - 1, +b[2], +b[3] + hrOffset, +b[4] + minOffset, +b[5], +b[6] || 0)));
		return new Date(Date.UTC(+b[0], +b[1] - 1, +b[2], +b[3] + hrOffset, +b[4] + minOffset, +b[5], +b[6] || 0));
	}

	processDate = (iso_date) => {
		//unixTimestamp = parseInt(unixTimestamp);
		let date = new Date(iso_date).toLocaleDateString("en-GB");
		return date;
	}

	updateCompliancePlans = (latest_compliance_plans , plan_delete_inct) => {
        let cur_plan_ref_deleted = (typeof plan_delete_inct !== 'undefined' && plan_delete_inct !== null && 'cur_plan_ref' in plan_delete_inct) ? plan_delete_inct.cur_plan_ref : '';
		let plans_prev = Store.getStoreData('cmp-compliance_plans');

		let active_plans = {};

		let plans_existing = {};
		if(plans_prev !== null){
			for(let i=0; i<plans_prev.length ; i++){
				plans_existing[plans_prev[i].id] = plans_prev[i];
			}			
		}

		for(let i=0; i<latest_compliance_plans.length ; i++){
			latest_compliance_plans[i].id = parseInt(latest_compliance_plans[i].id);
			if(latest_compliance_plans[i].is_deleted === 1 && latest_compliance_plans[i].id in plans_existing){delete plans_existing[latest_compliance_plans[i].id]}
			if(latest_compliance_plans[i].is_deleted === 0 && latest_compliance_plans[i].id in plans_existing)plans_existing[latest_compliance_plans[i].id] = latest_compliance_plans[i];
			if(latest_compliance_plans[i].is_deleted === 0 && !(latest_compliance_plans[i].id in plans_existing))plans_existing[latest_compliance_plans[i].id] = latest_compliance_plans[i];
		}
		let plans = Object.keys(plans_existing).map(function(k) {
			active_plans[k.toString()] = plans_existing[k];
			return plans_existing[k];
		});
		Store.updateStore('cmp-compliance_plans', plans);		
		Store.updateStore('active_plans', active_plans);		
	}
	updateMyActualTasks = (latest_my_tasks) => {
		let my_actual_tasks_prev = Store.getStoreData('cmp-my_actual_tasks');

		let tasks_existing = {};
		if(my_actual_tasks_prev !== null){
			for(let i=0; i<my_actual_tasks_prev.length ; i++){
				tasks_existing[my_actual_tasks_prev[i].id] = my_actual_tasks_prev[i];
			}			
		}

		for(let i=0; i<latest_my_tasks.length ; i++){
			if(latest_my_tasks[i].is_delete === 1 && latest_my_tasks[i].id in tasks_existing){delete tasks_existing[latest_my_tasks[i].id]}
			if(latest_my_tasks[i].is_delete === 0 && latest_my_tasks[i].id in tasks_existing)tasks_existing[latest_my_tasks[i].id] = latest_my_tasks[i];
			if(latest_my_tasks[i].is_delete === 0 && !(latest_my_tasks[i].id in tasks_existing))tasks_existing[latest_my_tasks[i].id] = latest_my_tasks[i];
		}
		let tasks = Object.keys(tasks_existing).map(function(k) {
			return tasks_existing[k];
		});
		console.log("my_actual_tasks_prev_new,",tasks);	
		Store.updateStore('cmp-my_actual_tasks', tasks);
		this.generate_tasks_ref_assoc(tasks);		
	}
	updateFollowupTasks = (latest_my_followup_tasks) => {
		let my_actual_followup_tasks_prev = Store.getStoreData('cmp-followup_tasks');
        //console.log("my_actual_followup_tasks_prev,",my_actual_followup_tasks_prev);
		let tasks_existing = {};
		if(my_actual_followup_tasks_prev !== null){
			for(let i=0; i<my_actual_followup_tasks_prev.length ; i++){
				tasks_existing[my_actual_followup_tasks_prev[i].id] = my_actual_followup_tasks_prev[i];
			}			
		}

		for(let i=0; i<latest_my_followup_tasks.length ; i++){
			if(latest_my_followup_tasks[i].is_delete === 1 && latest_my_followup_tasks[i].id in tasks_existing){delete tasks_existing[latest_my_followup_tasks[i].id]}
			if(latest_my_followup_tasks[i].is_delete === 0 && latest_my_followup_tasks[i].id in tasks_existing)tasks_existing[latest_my_followup_tasks[i].id] = latest_my_followup_tasks[i];
			if(latest_my_followup_tasks[i].is_delete === 0 && !(latest_my_followup_tasks[i].id in tasks_existing))tasks_existing[latest_my_followup_tasks[i].id] = latest_my_followup_tasks[i];
		}
		let tasks = Object.keys(tasks_existing).map(function(k) {
			return tasks_existing[k];
		});
		console.log("my_followup_tasks_new,",tasks);	
		Store.updateStore('cmp-followup_tasks', tasks);		
	}

	changeStringToAtob = (str_value) => {
			let utils = new Utils();
			let str_text = '';
			if(typeof str_value !== 'undefined'){
				str_text = str_value;
				if(utils.isBase64(str_value)){
					str_text = window.decodeURIComponent(atob(str_value));
				}				
			}
			return str_text;
	}

	hasJsonStructure = (str) => {
		if (typeof str !== 'string') return false;
		try {
			const result = JSON.parse(str);
			const type = Object.prototype.toString.call(result);
			return type === '[object Object]' 
				|| type === '[object Array]';
		} catch (err) {
			return false;
		}
	}

    planNameCompare = (a,b) => {
        return a.plan_name.localeCompare(b.plan_name)
    }

	getOrderedPlanList = (compliance_plans) =>{
		compliance_plans.sort(this.planNameCompare);
		let included_plans = {};
		let planList = compliance_plans.map(function(item , index){
			let obj = JSON.parse(item.plan_json);
			// let plan_gc_id =  
			//console.log('ComplianceDropDown item', item)
			let plan_ref_parts = item.plan_ref_id.split('_')
			let plan_ref = plan_ref_parts[1]
			if(!(plan_ref in included_plans)) {
				included_plans[plan_ref] = 1
				if(item.is_active === 1)return {plan_ref : plan_ref , plan_name : obj.plan_name};
			}
		});
		var filtered_plans = planList.filter(function(x) {
			 return x !== undefined;
		});
		return filtered_plans;
	}

    customRagDecider = (module_configs,current_rag) => {
		let id_array = [];
		let rag_id = '';
		for(let item of module_configs.custom_rags.additional_rags){
			id_array.push(item.id);
			if(item.nickname === current_rag){rag_id = item.id;break;}
			if(item.rag_value === current_rag){rag_id = item.id;break;}
		}
		if(rag_id !== '') return rag_id;
		if(id_array.includes(current_rag)) return current_rag;
    }
	getCustomRagAssoc = (module_configs) =>{
		let addtional_rags_assoc = {};
		for(let item of module_configs.custom_rags.additional_rags){
			addtional_rags_assoc[item.id] = item;
		}
		return addtional_rags_assoc;
	}
	parentTasksAssoc = (tasks) =>{
		let tasks_assoc = {};
		for(let item of tasks){
			tasks_assoc[item.ref_id] = item;
		}
		return tasks_assoc;
	}

}


export default CMPUtils;