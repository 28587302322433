import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import {FaTimes} from 'react-icons/fa';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import Dropzone from '../Common/Dropzone';
import CSLLoader from './../Common/CSLLoader';
import AlertBox from './../Common/AlertBox';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    //top: 170%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;    
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #333;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #282828;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 98%;
    margin: 0 auto;        
`;

const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;  
    font-family: 'Montserrat', sans-serif; 
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameLabelDivNew = styled.div`
    height: auto;
    width: 30%;
    padding-top: 7px;
    font-weight: 600;
    color: #222222;
    margin-bottom: 10px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class FollowupTaskModal extends React.Component
{
    state = { ready: false, documentName: '', documentType:'',upload_date: new Date(),summary:'', comments_note: '',curlane: '',
    objectData: {},binFiles: [], cur_files_json: null, is_disabled: false, task_note: "", show_attachments: true,isLoaderIcon: false, alert_param: null, completedNote:'' }

    constructor(props) {
		super(props);
    }
    componentDidMount = () => {
       console.log(Store.getStoreData('cur_followup_task'))
       let objectData = JSON.parse(Store.getStoreData('cur_followup_task').task_json).object_data;
       let documentname = objectData.documentName;
       let curlane = Store.getStoreData('cur_followup_task').cur_lane;
       console.log("curlane",curlane)
       let docType = "";
        // docType = objectData.documentTypes;
        // if(!isNaN(objectData.documentTypes)) {
        //     //const documentType = Store.getStoreData('rutypename');
        //     const documentType = Store.getStoreData('rutypenameAll');
        //     let doctype = {};
        //     for(let i=0; i < documentType.length; i++) {
        //         doctype[documentType[i].id] = documentType[i].documentname;
        //     }
        //     docType = doctype[objectData.documentTypes]
        // }
        let dNames = "";
        if(objectData.documentTypes !== null) {
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            }
            let i=0;
            for (let doc of objectData.documentTypes){
                i++;
                if(doc in doctype){
                    dNames += doctype[doc]
                    if(i <= objectData.documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }

        let uploadDate = objectData.uploadDate;
        if (uploadDate.includes(" ") === true) {
            uploadDate = uploadDate.replace(" ", "+");
        }
        let followup_task = Store.getStoreData('cur_followup_task');
        //console.log("Sourav123",followup_task)
        let task_json = JSON.parse(followup_task.task_json);
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        }

        const upload_date_obj = new Date(uploadDate);
		const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
		const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
		const upload_year = upload_date_obj.getFullYear();
        const upload_date = `${upload_day}/${upload_month}/${upload_year}`;

        let parentTask = Store.getStoreData('cur_parentTask');
        let parentobjectData = JSON.parse(parentTask.task_json).object_data;
        console.log("parentobjectData", parentobjectData)

        let task_note = objectData.note;

       this.setState({documentName: documentname,documentType:dNames,upload_date: upload_date,summary: objectData.summary,curlane: curlane,objectData: parentobjectData,
        cur_files_json: cur_files_json,task_note,ready: true, completedNote: objectData.commands})

    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    closeFollowupModal = (event) => {
        if(Store.getStoreData('isreferenceUrl') === true){
            //window.location = '/';
            this.props.closeTaskModal();
          } else {
            this.props.closeModal();
        }
        // this.props.closeModal();
    }

    saveFollowupTask = (event) => {
        event.preventDefault();
        let task_json = JSON.parse(Store.getStoreData('cur_followup_task').task_json);
        console.log(task_json);
        task_json['task_data'].last_action = 'COMP_RCM_FOLLOWUP_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_RCM_FOLLOWUP_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments_note;
        task_json['object_data'].cur_view = "Completed";
        task_json['object_data'].markforactionDate = Date.now();

        task_json['action_data'] = {action: "COMP_RCM_FOLLOWUP_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('loggedincontact'), cur_lane: "COMP_RCM_FOLLOWUP_COMPLETE", comments: this.state.comments_note, "assigned_to": Store.getStoreData('loggedincontact') };
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: Store.getStoreData('cur_followup_task').id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.afterCompleted);
    }

    afterCompleted = (result) => {
        // console.log("Completed:", result);
        //alert('You have successfully completed the RC task.');
        let alert_param = {title: 'Success', message: 'You have successfully actioned this regulatory change.', ok_text: 'OK', confirm: false,
                           alertHandler: this.updateafterCompleted, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateafterCompleted = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
              window.location = 'process.env.REACT_APP_RCM_URL';
            }else{
              window.location = '/';
            }
          } else {
            window.location.reload(true);
        }
    }

    processMyExternalLink = () => {
        var linksdata = this.state.objectData.externalLinks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {
            i++;
            //console.log("window.location.protocol===>",window.location.protocol)
            let url = <a style={{display: "table-cell"}} href={d.url} target="_blank" >{d.url}</a>
            let elem = {'index' : i,
                    'url_value' : url,
                    'description_value' : d.description
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    processMykeyData = () => {
        var keysdata = this.state.objectData.keyDates;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of keysdata) {
            i++;
            let elem = {'index' : i,
                    'date_value' : d.date,
                    'description_value' : d.description
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    togglePanel = (panel) => (event) => {
        event.preventDefault();
        let flag = this.state[panel];
        flag = flag ? false : true;
        this.setState({[panel]: flag});
    }

    render() {
        if(!this.state.ready){
            return(<div>Loading..</div>)
        }

        return(<div>
        <RegModalContainer style={{top: 'topValue' in this.props ? this.props.topValue : "170%"}}>
                {
                    (() => {
                        if(this.state.isLoaderIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <RegModalHeaderText>Task Generated from RCs</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeFollowupModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "1px solid #d9d9d9", marginBottom: "15px",width: "97%"}}/>
                <RegDismisOuterContainer>
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.state.documentName}</div>
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                        <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(this.state.summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {this.state.documentType}</div>
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {this.state.upload_date}</div>
                    </OverviewInfoBody>
                </RegDismisOuterContainer>
                {
                    this.state.objectData.sections.map((section) => {
                        return (
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                    <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>

                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        )
                    })
                }
                {
                    (() => {
                        if('externalLinks' in this.state.objectData) {
                            if(this.state.objectData.externalLinks.length !== 0) {
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        <OverviewInfoBody>
                                            <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    </div>)
                            }
                        }
                    })()
                }
                {
                    (() => {
                        if('keyDates' in this.state.objectData) {
                            if(this.state.objectData.keyDates.length !== 0) {
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        <OverviewInfoBody>
                                            <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    </div>)
                            }
                        }
                    })()
                }
                <RegDismisOuterContainer>
                    <ModalNameLabelDivNew>Task Notes</ModalNameLabelDivNew>
                    <div>{this.state.task_note}</div>
                </RegDismisOuterContainer>
                <RegDismisOuterContainer>
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Attachment</div>
                            <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.togglePanel("show_attachments")}>
                                {
                                    (() => {
                                        if (this.state.show_attachments) {
                                            return <IoIosArrowUp />
                                        } else {
                                            return <IoIosArrowDown />
                                        }
                                    })()
                                }
                            </div>
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    {
                        (() => {
                            if (this.state.show_attachments) {
                                return(<OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                {/* <ModalNameLabelDivNew>File Assignment - Attachment</ModalNameLabelDivNew> */}
                                {
                                    (() => {
                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0) {
                                            return(<ModalNameInputDiv style={{paddingTop:"0px"}}>
                                            <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                            </ModalNameInputDiv>)
                                        }else{
                                            return <div>No existing attachments.</div>
                                        }
                                    })()
                                }
                            </OverviewInfoBody>)
                            }else {
                                return (<div></div>);
                            }
                        })()
                    }
                </RegDismisOuterContainer>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "1px solid #d9d9d9", marginBottom: "15px",width: "97%"}}/>
                {
                    (() => {
                        if(this.state.curlane === "COMP_RCM_FOLLOWUP_COMPLETE"){
                            return(<RegDismisOuterContainer>
                                <ModalNameLabelDivNew>Comments</ModalNameLabelDivNew>
                                <div>{this.state.completedNote}</div>
                            </RegDismisOuterContainer>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.curlane !== "COMP_RCM_FOLLOWUP_COMPLETE"){
                            return(<RegModalContainertop>
                                <RegModalNameLabelDiv>Comments</RegModalNameLabelDiv>
                                <RegModalNameInputDiv>
                                <RegDismissalReason style={{marginTop: "10px"}} name="comments_note" onChange={this.handleChange} value={this.state.comments_note}></RegDismissalReason>
                                </RegModalNameInputDiv>
                            </RegModalContainertop>)
                        }
                    })()
                }
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeFollowupModal}>Cancel</RegCancelButton>
                    {
                        (() => {
                            if(this.state.curlane !== "COMP_RCM_FOLLOWUP_COMPLETE"){
                                return(<RegSubmitButton onClick={this.saveFollowupTask}>Complete</RegSubmitButton>)
                            }
                        })()
                    }

                </RegCancelSubmitButtonOuterContainer>
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>)
    }
}
export default FollowupTaskModal;
