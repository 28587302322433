import React from "react";
import styled from "styled-components";
import TAPICall from "./TAPICall.js";
import Store from '../../../Common/Store';
import TeamFollowupModal from './Team/TeamFollowupModal';
import FollowupModal from './GM/FollowupModal';
import GMAskGiftModal from './GM/GMAskGiftModal';
import TeamAskGiftsModal from './Team/TeamAskGiftsModal';
import GMGiftsApprovalModal from './GM/GMGiftsApprovalModal';

class GiftsHOC extends React.Component {
  state = {    
    ready: false,
    showModal: null,
    tmgift_user_roles: {},
    tmgift_contacts: [],    
    tmgift_contactsemail: [],    
    tmgift_reviewee_list: [],    
    tmgift_role: "",    
    tmgift_module_config: {},    
    tmgift_contactID: 0,    
    tmgift_companyID: 0,    
    tmgift_q_index: 0,    
    tmgift_currencyDetails: {},    
    tmgift_gc_company: [],    
    tmgift_gc_companies: [],    
    tmgift_has_gc: false,    
    tmgift_gc_assoc: {},    
    tmgift_followup_task: {},    
    tmgift_cur_q_modal_data: {},    
    tmgift_updated_followups: [],    
  };

  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.callTask();
  }; 

  callTask = () => {
      let r = {};
      r['result'] = {};
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      console.log('cts==>', cts)
      let user_roles = {};    
      let contacts_all = {};
      let contractsemail_all = {};
      let listcontacts_all = [];        
      
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles['gifts'];             
        for(let k in cts.result.contactlist){  
          if(user_roles[k.toString()] !== undefined){
            contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress; 
            let listConObject = {};
            listConObject.ID = cts.result.contactlist[k.toString()].ID;       
            listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;       
            listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;     
            listcontacts_all.push(listConObject);
            if (cts.result.user_roles['gifts'][k.toString()] !== 'no_access' ) {
                contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName; 
            }
          }
        }     
        console.log("user_roles", user_roles); 
        Store.updateStore('tmgift_user_roles', user_roles); 
        // Store.updateStore('contacts', contacts_all); 
        // Store.updateStore('contactsemail', contractsemail_all); 
        // Store.updateStore('reviewee_list', listcontacts_all);

        this.setState({tmgift_user_roles: user_roles, tmgift_contacts: contacts_all, tmgift_contactsemail: contractsemail_all, tmgift_reviewee_list: listcontacts_all})
      }     

      console.log("CurLane:", this.props.cur_lane, "task_id==>", this.props.task_id)

      let gc_companie = Store.getStoreData('gc_companie');
      let gc_filters = {}; 
      for(let gc of gc_companie) {
        gc_filters[gc.id.toString()] = 1;
      }

      console.log("gc_filters:", gc_filters)

      if(this.props.cur_lane === 'COMP_GIFTS_FOLLOWUP_ASSIGNMENT' || this.props.cur_lane === 'COMP_GIFTS_FOLLOWUP_COMPLETE'){
        let postData = { command: "GetSubTaskCallbyTaskID", task_id: this.props.task_id};
        postData['gcs'] = gc_filters;
        let api = new TAPICall();
        api.command(postData, this.openFollowupModalTaskApi);
      }else if(this.props.cur_lane === 'COMP_GIFTS_TEAMSAVE'){
        let postData = { "command" : "getTaskCallbyTaskID", "task_id" : this.props.task_id }
        postData['gcs'] = gc_filters;
        let api = new TAPICall();
        api.command(postData, this.processGiftsTaskAPIData);        
      }else{
        let postData = { "command" : "getTaskCallbyTaskID", "task_id" : this.props.task_id }
        postData['gcs'] = gc_filters;
        let api = new TAPICall();
        api.command(postData, this.processGiftsTaskAPIForAllData);
      }
  }

  processGiftsTaskAPIForAllData = (result) => {
    console.log(result);
    if(result.error_code > 0) {
        let msg = result.error_msg;            
        alert(msg)
        this.setState({show_modal: false})
        this.props.closeUserDialog()
        return false;
    }
    let userroles = this.state.tmgift_user_roles;
    //Store.updateStore('gift_role', userroles[result.contactID]);
    //Store.updateStore('module_config_tmview', result['module_config']);
    //Store.updateStore('current_contactID', result['contactID']);
    //Store.updateStore('current_companyID', result['companyID']);
    //Store.updateStore('q_index', 1);

    //Store.updateStore('tmview_currencyDetails', result['companyCurrencyDetails'])    
    //Store.updateStore('tmview_gc_company', result['gc_company']);
    //Store.updateStore('tmview_gc_companies', result['gc_companies']);
    let has_gc = result.gc_company.length > 1 ? true : false;
    //Store.updateStore('tmview_has_gc',has_gc);

    let gc_assoc = {}    
    for(let gc of result.gc_company) {
        let gc_id = gc.id.toString();
        gc_assoc[gc_id] = gc.company_name;                        
    } 
    //Store.updateStore('tmview_gc_assoc', gc_assoc); 

    //Store.updateStore('cur_q_modal_data', result.result.parenttask);

    console.log("user_roles==>", userroles)

    if(userroles[result.contactID] === 'team'){        
      this.setState({showModal: "teamAskGiftsModal", 
        ready: true,
        tmgift_cur_q_modal_data: result.result.parenttask,
        tmgift_q_index: 1,
        tmgift_module_config: result.module_config,
        tmgift_companyID: result.companyID,          
        tmgift_contactID: result.contactID,          
        tmgift_role: userroles[result.contactID],
        tmgift_currencyDetails: result.companyCurrencyDetails, 
        tmgift_gc_company: result.gc_company, 
        tmgift_gc_companies: result.gc_companies, 
        tmgift_has_gc: has_gc,
        tmgift_gc_assoc: gc_assoc
      });
    }else if(userroles[result.contactID] === 'gm' || userroles[result.contactID] === 'manager'){      
      //Store.updateStore('updated_followups', result.result.childtask.result);        
      this.setState({showModal: "GMApprovalGiftModal", 
        ready: true,
        tmgift_cur_q_modal_data: result.result.parenttask,
        tmgift_updated_followups: result.result.childtask.result, 
        tmgift_q_index: 1,
        tmgift_module_config: result.module_config,
        tmgift_companyID: result.companyID,          
        tmgift_contactID: result.contactID,          
        tmgift_role: userroles[result.contactID],
        tmgift_currencyDetails: result.companyCurrencyDetails, 
        tmgift_gc_company: result.gc_company, 
        tmgift_gc_companies: result.gc_companies, 
        tmgift_has_gc: has_gc,
        tmgift_gc_assoc: gc_assoc
      });    
    }   
  }

  processGiftsTaskAPIData = (result) => {
    console.log(result);
    if(result.error_code > 0) {
        let msg = result.error_msg;            
        alert(msg)
        this.setState({show_modal: false})
        this.props.closeUserDialog()
        return false;
    }
    let userroles = this.state.tmgift_user_roles;
    //Store.updateStore('gift_role', userroles[result.contactID]);
    //Store.updateStore('module_config_tmview', result['module_config']);
    //Store.updateStore('current_contactID', result['contactID']);
    //Store.updateStore('current_companyID', result['companyID']);
    //Store.updateStore('q_index', 1);

    //Store.updateStore('tmview_currencyDetails', result['companyCurrencyDetails'])    
    //Store.updateStore('tmview_gc_company', result['gc_company']);
    //Store.updateStore('tmview_gc_companies', result['gc_companies']);
    let has_gc = result.gc_company.length > 1 ? true : false;
    //Store.updateStore('tmview_has_gc',has_gc);   

    //Store.updateStore('cur_q_modal_data', result.result.parenttask);

    if(userroles[result.contactID] === 'team'){        
      this.setState({showModal: "teamAskGiftsModal", 
        ready: true,
        tmgift_cur_q_modal_data: result.result.parenttask,
        tmgift_q_index: 1,
        tmgift_module_config: result.module_config,
        tmgift_companyID: result.companyID,          
        tmgift_contactID: result.contactID,          
        tmgift_role: userroles[result.contactID],
        tmgift_currencyDetails: result.companyCurrencyDetails, 
        tmgift_gc_company: result.gc_company, 
        tmgift_gc_companies: result.gc_companies, 
        tmgift_has_gc: has_gc
      });
    }else if(userroles[result.contactID] === 'gm' || userroles[result.contactID] === 'manager'){
      this.setState({showModal: "GMSaveGiftModal", 
        ready: true,
        tmgift_cur_q_modal_data: result.result.parenttask,
        tmgift_q_index: 1,
        tmgift_module_config: result.module_config,
        tmgift_companyID: result.companyID,          
        tmgift_contactID: result.contactID,          
        tmgift_role: userroles[result.contactID],
        tmgift_currencyDetails: result.companyCurrencyDetails, 
        tmgift_gc_company: result.gc_company, 
        tmgift_gc_companies: result.gc_companies, 
        tmgift_has_gc: has_gc
      });
    }
        
  }

  openFollowupModalTaskApi = (result) => {
      console.log(result);
      if(result.error_code > 0) {
        let msg = result.error_msg;            
        alert(msg)
        this.setState({show_modal: false})
        this.props.closeUserDialog()
        return false;
      }
      // Store.updateStore('followup_task', result.result.task);
      // Store.updateStore('module_config_tmview', result['module_config']);
      // Store.updateStore('current_contactID', result.result['contactID']);
      // Store.updateStore('current_companyID', result.result['companyID']);
      
      let userroles = this.state.tmgift_user_roles;
      //Store.updateStore('gift_role', userroles[result.result.contactID]);
      if(userroles[result.result.contactID] === 'team'){        
        this.setState({showModal: 'view_followup_modal_team',
          ready: true, 
          tmgift_followup_task: result.result.task,
          tmgift_module_config: result.module_config,
          tmgift_companyID: result.result.companyID,          
          tmgift_contactID: result.result.contactID,          
          tmgift_role: userroles[result.result.contactID],
        });
      }else if(userroles[result.result.contactID] === 'gm' || userroles[result.result.contactID] === 'manager'){
        this.setState({showModal: 'view_followup_modal_gmanager',
          ready: true,
          tmgift_followup_task: result.result.task,
          tmgift_module_config: result.module_config,
          tmgift_companyID: result.result.companyID,          
          tmgift_contactID: result.result.contactID,          
          tmgift_role: userroles[result.result.contactID]
        });
      } 
  }

  closeUserDialog = () => {
    this.setState({show_modal: false})
    this.props.closeUserDialog()
  }

  render() {
    if(!this.state.ready)return(<div>....</div>)
    return (
      <div>
         {
            (() => {
                if (this.state.showModal !== null) {
                    switch(this.state.showModal) {                        
                        case 'view_followup_modal_team' : return <TeamFollowupModal 
                                                                    closeUserDialog={this.closeUserDialog}
                                                                    module_config={this.state.tmgift_module_config}
                                                                    followup_task={this.state.tmgift_followup_task}
                                                                    contactID={this.state.tmgift_contactID} 
                                                                    role={this.state.tmgift_role} 
                                                                />; break;                
                        case 'view_followup_modal_gmanager' : return <FollowupModal 
                                                                    closeUserDialog={this.closeUserDialog} 
                                                                    followup_task={this.state.tmgift_followup_task}
                                                                    contactID={this.state.tmgift_contactID} 
                                                                    role={this.state.tmgift_role}
                                                                />; break;                
                        case 'GMSaveGiftModal' : return <GMAskGiftModal 
                                                                    closeUserDialog={this.closeUserDialog}
                                                                    q_index={this.state.tmgift_q_index}
                                                                    contacts={this.state.tmgift_contacts}                                                                    
                                                                    module_config={this.state.tmgift_module_config} 
                                                                    gc_company={this.state.tmgift_gc_company} 
                                                                    gc_companies={this.state.tmgift_gc_companies} 
                                                                    cur_q_modal_data={this.state.tmgift_cur_q_modal_data} 
                                                                    currencyDetails={this.state.tmgift_currencyDetails} 
                                                                    has_gc={this.state.tmgift_has_gc} 
                                                                    companyID={this.state.tmgift_companyID}
                                                                    contactID={this.state.tmgift_contactID}
                                                                    role={this.state.tmgift_role}  
                                                                  />; break;                
                        case 'teamAskGiftsModal' : return <TeamAskGiftsModal 
                                                                    closeUserDialog={this.closeUserDialog}
                                                                    q_index={this.state.tmgift_q_index} 
                                                                    contacts={this.state.tmgift_contacts}                                                                    
                                                                    module_config={this.state.tmgift_module_config} 
                                                                    gc_company={this.state.tmgift_gc_company} 
                                                                    gc_companies={this.state.tmgift_gc_companies} 
                                                                    cur_q_modal_data={this.state.tmgift_cur_q_modal_data} 
                                                                    currencyDetails={this.state.tmgift_currencyDetails} 
                                                                    has_gc={this.state.tmgift_has_gc} 
                                                                    companyID={this.state.tmgift_companyID}
                                                                    contactID={this.state.tmgift_contactID}
                                                                    role={this.state.tmgift_role}
                                                                />; break;                
                        case 'GMApprovalGiftModal' : return <GMGiftsApprovalModal 
                                                                    closeUserDialog={this.closeUserDialog} 
                                                                    q_index={this.state.tmgift_q_index}                                                                    
                                                                    cur_q_modal_data={this.state.tmgift_cur_q_modal_data} 
                                                                    contacts={this.state.tmgift_contacts}
                                                                    module_config={this.state.tmgift_module_config}
                                                                    gc_assoc={this.state.tmgift_gc_assoc}
                                                                    gc_company={this.state.tmgift_gc_company} 
                                                                    role={this.state.tmgift_role}                                                                    
                                                                    currencyDetails={this.state.tmgift_currencyDetails}
                                                                    updated_followups={this.state.tmgift_updated_followups} 
                                                                    contactID={this.state.tmgift_contactID}
                                                                    contactsemail={this.state.tmgift_contactsemail}
                                                                />; break;                
                    }
                }
            })()
          }
      </div>
    );
  }
}

export default GiftsHOC;
