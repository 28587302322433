import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';

import "./table_styles.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #282828;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
`

const HeaderSelectText = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 12px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; 
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 4px 9px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 4px 9px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const FilterChevron = styled.div`
    float: right;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
`;
class CSLTable extends React.Component
{
    state = {toggle_state: true};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state});
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
            if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state});
        }
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
        let fileName = "export.csv";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();

        let headerCSV = this.props.prefered_columns;
        console.log("headerCSV",headerCSV)
        let data= tableData.data;
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
            let cont = {};

            Object.entries(headerCSV).forEach(([key, value]) => {
                //console.log("data[k][key]",data[k][key])
                    cont[key] = data[k][key].replace(/,/g, ' ').replace(/-/g, '-').replace(/–/g, '-').replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"');
            })            
            csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        //console.log("csv_data_final_arr",csv_data_final_arr)
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();
    
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

    toggleFilter = (event) => {        
        event.preventDefault();
        let show_filter = this.props.show_filter ? false : true;
        let table = this.props.table;
        this.props.setShowTable(show_filter, table);
    }
    


	render() {
		//console.log('this.props', this.props.prefered_columns);
		let tableData = this.props.processData();
        let row_count = this.props.tableRows === null ? 5 : this.props.tableRows;
        let show_filter = 'show_filter' in this.props ? this.props.show_filter : true;
		//console.log('tableData', row_count);
		return (
			<TableContainer>
                {
                    (()=> {
                        if ("selectHeader" in this.props && this.props.selectHeader === true) {
                            return(<HeaderSelectText>
                                <div style={{float: "left", marginTop: "toggle_text" in this.props ? "4px" : "0px"}}>{this.props.headerText}</div>
                                {
                                    (() => {
                                        if ("showinRole" in this.props && this.props.showinRole) {
                                            return(<FilterChevron style={{marginTop: "toggle_text" in this.props ? "4px" : "0px"}} onClick={this.toggleFilter}>
                                            {
                                                (() => {
                                                if (show_filter) {
                                                    return <IoIosArrowUp />
                                                } else {
                                                    return <IoIosArrowDown />
                                                }
                                                })()
                                            }
                                        </FilterChevron>)
                                        }
                                    })()
                                }
                                
                                {
                                    (() => {
                                        if ("showinRole" in this.props && this.props.showinRole && "toggle_text" in this.props === true) {
                                            return (
                                                <div style={{float: "right", marginRight: "10px"}}>
                                                    <div style={{float: "left", marginTop: "4px"}}>{this.props.toggle_text}</div>
                                                    <div style={{float: "right", marginLeft: "5px"}}>
                                                    {
                                                        (() => {
                                                            if (this.state.toggle_state === true) {
                                                                return (
                                                                    <div>
                                                                        <SelectedRadio>Yes</SelectedRadio>
                                                                        <UnselectedRadio onClick={this.props.toggleCallback}>No</UnselectedRadio>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div>
                                                                        <UnselectedRadio onClick={this.props.toggleCallback}>Yes</UnselectedRadio>
                                                                        <SelectedRadio>No</SelectedRadio>
                                                                    </div>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                    </div>
                                                    <div style={{clear: "both"}}></div>
                                                </div>
                                            );
                                        }
                                    })()
                                }
                                <div style={{clear: "both"}}></div>
                                </HeaderSelectText>)
                        }else{
                            return(<HeaderText>{this.props.headerText}</HeaderText>)
                        }
                    })()
                }			    
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                }      
                {
                    (() => {
                        if(show_filter) {
                            return(
                            <div style={{marginTop: '5px', width:'100%'}}>
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={tableData.data}
                                    columns={tableData.columns}
                                    defaultPageSize={parseInt(row_count)}
                                    filterable={true}
                                    defaultFilterMethod={(filter, row, column) => {
                                    const id = filter.pivotId || filter.id                            
                                    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                                }}
                                />
                                {
                                    (() => {
                                        if ('export' in this.props && this.props.export === true) {                            
                                            return (<ExportButton  onClick={this.exportToCSV()}>Export</ExportButton>);                                                        
                                        }
                                    })()
                                }  
                                {
                                    (() => {
                                        if ('reportButton' in this.props && this.props.reportButton === true) {                            
                                            return (<ExportButton  onClick={() => this.props.reportCallback()}>Preview Report</ExportButton>);                                                        
                                        }
                                    })()
                                }                         
                            </div>
                            )
                        }
                    })()
                }              
                    
			</TableContainer>
			);
	}
}

export default CSLTable;