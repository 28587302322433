import React from "react";
import styled from "styled-components";
import TAPICall from "./src/Common/APICall.js";
import CMConflictsList from './src/Components/CM/CMConflictsList.js';
import ConflictsList from './src/Components/Team/ConflictsList';
import ConflictsStore from "./src/Common/Store.js";
import Store from './../../../Common/Store';

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 8px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    border-color: #495E75;
    border-width: 3px;
    box-shadow: 0 0 20px #a7a6a6;
  `;


class ConflictsHOC extends React.Component {

    state={ready: false, role:''}

    componentDidMount(){
        this.setEnv('conflicts');
    }

    setEnv(module_name) {
        let api_url = `${Store.getStoreData('base_api_url')}/microservice/${module_name}/api/command`;

        ConflictsStore.updateStore('module_name',module_name);
        ConflictsStore.updateStore('api_url',api_url);
        ConflictsStore.updateStore('is_admin',false);
        ConflictsStore.updateStore('is_debug',false);

        let postData = { command: "taskindex" };
        let api = new TAPICall();
        api.command(postData, this.processtaskIndex);
    }

    processtaskIndex = (result) => {
        ConflictsStore.updateStore('module_config', result.result['module_config']);
        ConflictsStore.updateStore('contact_id', result.result['contact_id']);
        ConflictsStore.updateStore('curCompanyID', result.result['curCompanyID']);
        ConflictsStore.updateStore('conflictstype', result.result['conflictstype']);
        ConflictsStore.updateStore('removaltype',result.result['removaltype']);
        // ConflictsStore.updateStore('gc_company', result.result['gc_company']);
        // ConflictsStore.updateStore('gc_companies', result.result['gc_companies']);

        //set company start
        let gc_comp = Store.getStoreData('gc_companie');
        let my_mgrp_companies = [];
        for(let k=0;k<gc_comp.length;k++){
            if(gc_comp[k].id !== result.result['curCompanyID']){
                let my_mgrp_comObject = {};
                my_mgrp_comObject.address1 = gc_comp[k].address1
                my_mgrp_comObject.address2 = gc_comp[k].address2
                my_mgrp_comObject.company_name = gc_comp[k].company_name
                my_mgrp_comObject.created_at = gc_comp[k].created_at
                my_mgrp_comObject.id = gc_comp[k].id
                my_mgrp_comObject.is_deleted = gc_comp[k].is_deleted
                my_mgrp_comObject.parent_id = gc_comp[k].parent_id
                my_mgrp_comObject.phone = gc_comp[k].phone
                my_mgrp_comObject.tags_config = gc_comp[k].tags_config !== null ? JSON.stringify(gc_comp[k].tags_config) : null;
                my_mgrp_comObject.updated_at = gc_comp[k].updated_at
                my_mgrp_companies.push(my_mgrp_comObject)
            }
        }
        let gc_companies = my_mgrp_companies;
        console.log("gc_comp===>", gc_comp, "gc_companies=>", gc_companies)
        ConflictsStore.updateStore('gc_company', gc_comp);
        ConflictsStore.updateStore('gc_companies', gc_companies);
        //set company end


        let has_gc = gc_comp.length > 1 ? true : false;
        ConflictsStore.updateStore('has_gc',has_gc);

        let gc_assoc = {}
        let gc_filters = {};
        for(let gc of gc_comp) {
            let gc_id = gc.id.toString();
            gc_assoc[gc_id] = gc.company_name;
            if(gc.is_gc) {
              gc_filters[gc.id.toString()] = 1;
            }
        }
        console.log("gc_filters==>", gc_filters)
        ConflictsStore.updateStore('gc_filters', gc_filters);
        ConflictsStore.updateStore('gc_assoc', gc_assoc);

        let r = {};
        r['result'] = {};
        let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        console.log('cts==>', cts)
        let user_roles = {};
        let contacts_all = {};
        let contractsemail_all = {};
        let listcontacts_all = [];
        if('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles['conflicts'];
            for(let k in cts.result.contactlist){
                contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
                let listConObject = {};
                listConObject.ID = cts.result.contactlist[k.toString()].ID;
                listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
                listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
                listcontacts_all.push(listConObject);
                if (cts.result.user_roles['conflicts'][k.toString()] !== 'no_access' ) {
                    contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                }
            }
            console.log("user_roles", user_roles);
            ConflictsStore.updateStore('userroles', user_roles);
            ConflictsStore.updateStore('contacts', contacts_all);
            ConflictsStore.updateStore('contactsemail', contractsemail_all);
            ConflictsStore.updateStore('reviewee_list', listcontacts_all);
        }

        let userroles = ConflictsStore.getStoreData("userroles");
        let contact = Store.getStoreData("contact");
        let role = userroles[contact.ID.toString()];
        ConflictsStore.updateStore('role', role);
        ConflictsStore.updateStore('isreferenceUrl', true)
        this.setState({ready: true, role})
    }

    closeUserDialog = () => {
        this.setState({show_modal: false})
        this.props.closeUserDialog()
    }

    render() {
        if(!this.state.ready)return(<div></div>)
        return(
            <FolderContainer>
                {
                    (() => {
                        switch(this.state.role) {
                        case 'manager' :
                        case 'cm' : return <CMConflictsList taskId={this.props.task_id} closeUserDialog={this.closeUserDialog}/>; break;
                        case 'team' : return <ConflictsList taskId={this.props.task_id} closeUserDialog={this.closeUserDialog}/>; break;
                        default: return <div>Sorry, you do not have permission to view this page. </div>;
                        }
                    })()
                }
            </FolderContainer>
        );
    }
}

export default ConflictsHOC;
