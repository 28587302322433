import React from 'react';
import styled from 'styled-components';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import APIContact from './Common/APIContact';
import Utils from './Common/Utils.js';
import ComDecom from './Common/ComDecom.js';
import CSLHeader from './Components/CSLHeader';
import WelcomeBanner from './Components/WelcomeBanner';
// import RegisterIndex from './Components/Register/RegisterIndex';
import Index from './Components/Index';
import ConfigIndex from './Components/ConfigIndex';
import LogModal from './Components/LogModal';
import * as Style from './Components/Common/StyledComponents';
import BareIndex from './Components/BareIndex';
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import GenericTaskIndex from './Components/Task/GenericTaskIndex';


import ManageParameter from './Components/CSL/ManageParameter';
import Managescheme from './Components/CSL/Managescheme';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CSLLoader from './Components/Common/CSLLoader';
import AlertBox from './Components/Common/AlertBox';
import CSLPageloader from './Components/Common/CSLPageloader';

import Reload from './Common/Reload.js';
// import ErrorBoundary from './ErrorBoundary';
// import SLAPICall from './Components/SiteLayout/SLAPICall.js';

import authService from 'mcc-front-aux/dist/authService';

/*global localStorage*/

const moment = require('moment');
const last_action_time = moment().unix()



class AppContent extends React.Component
{
  state = {
    OverlayHeight: null,
    ready: false,
    OverlayTaskHeight: null,
    role: "noperm",
    refreshCount: 0,
    alert_param: null,
    task_loading: false,
    from_url: false
  };
  constructor(props) {
    super(props);
    Utils.setEnv('tmview');
    Utils.setApicall();
    this.callContact();
  }
  callContact() {
    let postData = { command: "contactlist"};
    let api = new APIContact();
    let j = api.command(postData, this.processContactData);
  }
  processContactData = (result) => {
    // let rs = result.result;
    console.log("result dcome ==>", result.result['contactlist'])
    let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
    result.result['contactlist'] = cl
    console.log("result dcome", result)

    console.log(cl);
    localStorage.setItem("contactlist", JSON.stringify(result));

  }

  alertHandler = (result, stack) => {
    // let url = Store.getStoreData('is_admin') ? "/ConsultantSummary.aspx" : "/select/ServiceBasedLanding";
    // window.location = url;
    Reload.ReloadPage();
  }


  processtaskIndex = (result) => {
    console.log("tmview store size without task",JSON.stringify(result).length/(1024 * 1024))
    if(result.error_code === 0) {
      if(result.role === "no_access") {
        let alert_param = {title: 'No Access', message: '', ok_text: 'Ok', confirm: false,
                           alertHandler: this.alertHandler, stack: {id: 0}};
        alert_param["message"] = result.error_msg
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
        return
      }

      Store.updateStore('role', result.role);
      Store.updateStore('select_schemes', result.schemes);
      Store.updateStore('enabled_task_scheme', result.enabled_task_scheme);
      let r = {};
      r['result'] = {};
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      let user_roles = {};
      let list_contacts = [];
      let contacts = {}
      let active_contacts = {};
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles['tmview'];
        for(let k in cts.result.contactlist){
          if(cts.result.contactlist[k.toString()].IsDeleted === false && cts.result.contactlist[k.toString()].IsActive === true){
              if(result.role === "team"){
                if (cts.result.user_roles['tmview'][k.toString()] === 'team') {
                  list_contacts.push({"ID": k, "contact_name": cts.result.contactlist[k.toString()].ContactName})
                }
              }else{
                if (cts.result.user_roles['tmview'][k.toString()] !== 'no_access') {
                  list_contacts.push({"ID": k, "contact_name": cts.result.contactlist[k.toString()].ContactName})
                }
              }
              active_contacts[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
          }
          contacts[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
        }
      }

      list_contacts.sort(this.compare);
      Store.updateStore('list_contacts', list_contacts);
      Store.updateStore('contacts', contacts);
      Store.updateStore('user_roles', user_roles);

      let contact_id = result.contact_id;
      let login_contact = cts.result.contactlist[contact_id.toString()]
      let loggedincontact = {};
      loggedincontact['ID'] = contact_id;
      loggedincontact['contact_id'] = contact_id;
      loggedincontact['contact_name'] = login_contact.ContactName;
      Store.updateStore('loggedincontact', loggedincontact);
      Store.updateStore('loggedin_user_info', loggedincontact);
      Store.updateStore('contact', loggedincontact);
      let task_loading = Store.getStoreData('is_admin') ? false : true;
      this.setState({ ready: true, task_loading: false});
    }else{
      let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                 alertHandler: this.alertHandler, stack: {id: 0}}
      alert_param["message"] = result.error_msg
      this.setState({ ready: true, alert_param: alert_param, task_loading: false});
    }
  }

  processIndex = (result) => {
    console.log("tmview store size without task",JSON.stringify(result).length/(1024 * 1024))
    Utils.log("result", result.result.reporters)
    if(result.error_code === 0) {
        let completed_count = 0;
        if(Store.getStoreData('is_admin')) {
          Store.updateStore('env', "csl");
          Store.updateStore('parameters', result.result.parameters);
          Store.updateStore('schemes', result.result.schemes);
          Store.updateStore('associated_schemes', result.result.associated_schemes);
        } else {
          if(result.role === "no_access") {
            let alert_param = {title: 'No Access', message: '', ok_text: 'Ok', confirm: false,
                               alertHandler: this.alertHandler, stack: {id: 0}};
            alert_param["message"] = result.error_msg
            this.setState({ ready: true, alert_param: alert_param, task_loading: false});
            return
          }
          if('reporters' in result)Store.updateStore('reporters', result.reporters);
          Store.updateStore('groupings', result.groupings);
          Store.updateStore('select_schemes', result.schemes);
          let gc_assoc = {};
          let gc_filters = {};
          let gc_users = {};
          // let group_assoc = {};

          for(let gc of result.gc_companie) {
            let id = 'parent_id' in gc ? (parseInt(gc.parent_id) * 100000 + parseInt(gc.id)).toString() : gc.id.toString()
            gc_assoc[id] = gc;
            gc_filters[id.toString()] = 1;

            if(gc.is_gc){
              let users = gc.users;
              for(let u in users){
                gc_users[u] = 1;
              }

            }
            // if()
            console.log("gc_assoc", gc_assoc)
          }
          console.log("gc_users", gc_users)
          Store.updateStore('gc_users',gc_users);

          Store.updateStore('gc_assoc',gc_assoc);
          Store.updateStore('gc_filters',gc_filters);

          Store.updateStore('role', result.role);
          Store.updateStore('sent_mail', result.sent_mail);
          Store.updateStore('enabled_task_scheme', result.enabled_task_scheme);
          Store.updateStore('enabled_approval_task', result.enabled_approval_task);
          Store.updateStore('enabled_approval_task', result.enabled_approval_task);
          Store.updateStore('teams', result.teams);

          Store.updateStore('enabled_csl_user_list', result.enabled_csl_user_list);
          Store.updateStore('inactive_user_list', result.inactive_user_list);
          Store.updateStore('unassigntasks', result.unassigntasks);
          Store.updateStore('user_login_hist', result.user_login_hist);
          Store.updateStore('flags', result.flags);

          let gc_companie = result.gc_companie;
          let gc_companies = {};
          let company = {}
          for(let com in gc_companie){
            if(gc_companie[com].is_gc){
              gc_companies[gc_companie[com].id] = gc_companie[com].company_name
            }else{
              company['parentcompany_id'] = gc_companie[com].id;
              company['parrent_company'] = gc_companie[com].company_name;
            }
          }

          Store.updateStore('gc_companies',gc_companies);
          Store.updateStore('gc_companie',gc_companie);

          Store.updateStore('parrent_company', company.parrent_company);
          Store.updateStore('parentcompany_id', company.parentcompany_id);
          Store.updateStore('company', company);




          let r = {};
          r['result'] = {};
          let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
          console.log('cts==>', cts)
          let user_roles = {};
          let list_contacts = [];
          let contacts = {}
          let active_contacts = {};
          if('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles['tmview'];
            for(let k in cts.result.contactlist){
              if(cts.result.user_roles['tmview'][k.toString()] !== undefined){
                if(cts.result.contactlist[k.toString()].IsDeleted === false && cts.result.contactlist[k.toString()].IsActive === true){
                  if(result.role === "team"){
                    if (cts.result.user_roles['tmview'][k.toString()] === 'team' && !cts.result.contactlist[k].IsDeleted && cts.result.contactlist[k].IsActive) {
                      list_contacts.push({"ID": k, "contact_name": cts.result.contactlist[k.toString()].ContactName})
                    }
                  }else{
                    if (cts.result.user_roles['tmview'][k.toString()] !== 'no_access') {
                      list_contacts.push({"ID": k, "contact_name": cts.result.contactlist[k.toString()].ContactName})
                    }
                  }
                  active_contacts[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                }
              }
              contacts[k.toString()] = cts?.result?.contactlist[k.toString()]?.IsActive ? cts?.result?.contactlist[k.toString()]?.ContactName : cts?.result?.contactlist[k.toString()]?.ContactName + "(Inactive)";
            }

          }

          console.log("contacts ===>", list_contacts)
          list_contacts.sort(this.compare);
          Store.updateStore('active_contacts', active_contacts);
          Store.updateStore('list_contacts', list_contacts);
          Store.updateStore('contacts', contacts);
          Store.updateStore('user_roles', user_roles);

          let contact_id = result.contact_id;

          let login_contact = cts.result.contactlist[contact_id.toString()]

          let loggedincontact = {};
          loggedincontact['ID'] = contact_id;
          loggedincontact['contact_id'] = contact_id;
          loggedincontact['contact_name'] = login_contact.ContactName;
          Store.updateStore('loggedincontact', loggedincontact);
          Store.updateStore('loggedin_user_info', loggedincontact);
          Store.updateStore('contact', loggedincontact);

          let has_gc = result.gc_companie.length > 1 ? true : false;
          Store.updateStore('has_gc',has_gc);
        }
        let task_loading = Store.getStoreData('is_admin') ? false : true;
        this.setState({ ready: true, task_loading: task_loading });
    }else{
      let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                 alertHandler: this.alertHandler, stack: {id: 0}}
      alert_param["message"] = result.error_msg
      this.setState({ ready: true, alert_param: alert_param, task_loading: false});
    }

  }


  compare(a, b) {
      const bandA = a.contact_name.toUpperCase();
      const bandB = b.contact_name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
   }

  size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };
  componentDidMount() {
    let api = new APICall();
    let postData = { "command": "index" };
    api.command(postData, this.processIndex);
  }

  refreshState = () => {
    this.setState({refreshCount : this.state.refreshCount + 1})
  }

  render()
  {

    /*if(Store.getStoreData('is_admin')){
      if(!this.state.ready) {
        return <div><CSLLoader /></div>
      }
    }else{
      if(this.state.from_url){
        if(!this.state.ready) {
          return <div><CSLLoader /></div>
        }
      }else{
        if(Store.getStoreData('tmview-index-select') === null){
          return <div><CSLPageloader /></div>
        }else{
          if(!this.state.ready) {
            return <div><CSLLoader /></div>
          }
        }
      }

    }*/

    if(this.state.alert_param !== null) {
      return <AlertBox alertParam = {this.state.alert_param} />
    }

    if(Store.getStoreData('is_admin')){
      if(!this.state.ready) {
        return <div><CSLLoader /></div>
      }
    }else{
      if(Store.getStoreData('tmview-index-select') === null){
        return <div><CSLPageloader /></div>
      }else{
        if(!this.state.ready) {
            return <div><CSLLoader /></div>
          }
      }
    }

      return(
        <Router>
          <Switch>
            <Route path="/moduleconfig" exact>
                    <ModuleConfigHOC />
            </Route>
            <Route path="/task/:taskId" component={Index} exact />
            <Route path="/config" exact>
                    <ConfigIndex />
            </Route>
            <Route path="/bare" exact>
                    <BareIndex />
            </Route>
            <Route path="/" exact>
                    <Index />
            </Route>
            <Route path="/parameter" exact>
                    <Index p="parameter"/>
            </Route>

            <Route path="/scheme" exact>
                    <Index p="scheme"/>
            </Route>
            <Route path="/parameter/:paramId" component={ManageParameter} exact />
            <Route path="/scheme/:schemeId" component={Managescheme} exact />
          </Switch>
        </Router>
      );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
    let is_debug = false
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', is_debug)
    Store.updateStore('api_url', api_url)

    authService.refresh().then(() => {
      this.setState({ready: true})
    })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

    return <AppContent />
  }
}


export default App;
