import React from 'react';
import { FaTimes, FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
// import {Dropzone} from '@mcc-regtech/core-library';
import Dropzone from '../Common/Dropzone';
import { APICall, Store } from '../Common';
import '../../App.css';
import '../../Components/Common/CSLDatePickerForModal.css';
import CSLLoader from './../Common/CSLLoader';

const MRModalLabel = styled.label`
  color: #212121;
  font-weight: 600;
  margin-top: 10px;
`;
const MRModalButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 11px;
  float: right;
  cursor: pointer;
`;
const MRModalCancelBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
`;
const MRModalNextBtn = styled(MRModalButton)`
  background-color: #37ada7;
  border: 1px solid #37ada7;
  margin-left: 10px;
`;
const MRModalSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: #414141;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const FCKCommentDiv = styled.div`
  padding-left: 20px;
  padding-right: 8px;
  color: #a0a0a0;
`;
const FCKTextarea = styled.textarea`
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;
`;
const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 20%;
  padding-top: 7px;
  font-weight: 600;
  color: #979797;
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 80%;
  padding-top: 7px;
`;
const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;
const ScoreContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  width: 100%;
  margin: 0px auto;
  padding: 15px;
  left: 15%;
`;
const ScoreHeaderContainer = styled.div`
  background-color: #36aba6;
  height: auto;
  padding: 10px;
  color: #ffffff;
  border: 1px solid #36aba6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`padding: 10px;`;
const HeaderButton = styled.div`
  color: #ffffff;
  width: 30px;
  height: 29px;
  float: right;
  font-size: 20px;
  cursor: pointer;
`;
const MRModalInput = styled.input`
  height: 26px;
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
`;

const RegModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 97%;
  position: absolute;
  margin: 0 0;
  top: 55%;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  margin-left: 10px;
`;

class EditFollowUpModal extends React.Component {
    state = {
        task: null,
        payload: {},
        task_id: null,
        comments: '',
        task_title: '',
        task_note: '',
        ready: false,
        send_mail_config: false,
        curlane: '',
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        task_due_date: null,
        review_list: [],
        due_by: '1970-01-01',
        // preventing multiple insert
        disabled: false,
        assign_to: 0,
        //**************************
        roles: Store.getStoreData('userroles'),
        gc_company: Store.getStoreData('gc_company'),
        gc_companies: Store.getStoreData('gc_companies'),
        my_group: Store.getStoreData('my_group'),
        //**************************
        isLoadedIcon: false
    };

    GetPayloadData = (parent_task) => {
        let payload = {};
        let parent_task_json = JSON.parse(parent_task.task_json);
        payload.Ref_ID = parent_task_json.object_data.ref_id;
        return payload;
    };

    constructor (props) {
        super(props);
        this.api = new APICall();
    }

    componentDidMount() {
        //debugger;
        let { task, payload, task_id, task_title, task_note, curlane, ready } = this.state;

        let task_data = Store.getStoreData('followup_task');

        curlane = task_data.subtask.cur_lane;
        let followup_task = task_data.subtask;
        task_id = followup_task.id;

        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject;
        task_note = task_json.object_data.note;
        //debugger;
        let cur_files_json = [];
        if ('bin_files' in task_json.object_data) {
            cur_files_json = task_json.object_data.bin_files;
        }
        let task_command = '';
        if ('commands' in task_json.object_data) {
            task_command = task_json.object_data.commands;
        }
        let parent_task = task_data.parenttask;
        payload = this.GetPayloadData(parent_task);

        //==============
        // let gc_users = [];
        // let my_company = null;
        // let gc_company = Store.getStoreData('gc_company');
        // let gcid = parseInt(task_json.task_data.company_id);
        // for (let gc of gc_company) {
        //     if (gc.id === gcid)
        //         my_company = gc;
        // }
        // let company_contacts = Store.getStoreData('contacts');
        // const com_roles = Store.getStoreData('userroles');
        // const myGroup = Store.getStoreData('my_group');
        // for (let id of Object.keys(my_company.users)) {
        //     // checks for myGroup.length to cover the scenario where there is NO group set
        //     if (
        //         com_roles[id.toString()] !== 'no_access' &&
        //         (myGroup.length ? myGroup.includes(id.toString()) : true)
        //     )
        //         gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
        // }

        let company_contacts = Store.getStoreData('contacts');
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gcid = parseInt(task_json.task_data.company_id) % 100000;
        let gcname = '';
        if (gcid in gc_assoc) gcname = gc_assoc[gcid];
        let gc_users = [];
        let my_company = null;
        let gc_company = Store.getStoreData('gc_company');
        for (let gc of gc_company) {
            if (gc.id === gcid)
                my_company = gc;
        }

        const com_roles = Store.getStoreData('userroles');
        const myGroup = Store.getStoreData('my_group');

        //debugger;
        for (let id of Object.keys(my_company.users)) {
            // checks for myGroup.length to cover the scenario where there is NO group set
            if (
                com_roles[id.toString()] !== 'no_access' &&
                (myGroup.length ? myGroup.includes(id.toString()) : true)
            )
                gc_users.push({ ID: id, ContactName: company_contacts[id.toString()] });
        }

        //perform due by
        let taskDueBy = new Date(task_json.task_data.due_date);
        let curmonth = parseInt(taskDueBy.getMonth());
        let currmonth = curmonth + 1;
        let dateStrDueBy = currmonth >= 10
            ? taskDueBy.getFullYear() + '/' + currmonth + '/' + taskDueBy.getDate()
            : taskDueBy.getFullYear() + '/0' + currmonth + '/' + taskDueBy.getDate();

        this.setState({
            task: task_json,
            payload,
            task_id,
            task_title,
            task_note,
            comments: task_command,
            curlane,
            cur_files_json: cur_files_json,
            ready: true,
            task_due_date: new Date(task_json.task_data.due_date),
            assign_to: task_json.task_data.cur_assigned_to,
            review_list: gc_users,
            due_by: dateStrDueBy
        }, () => {
            //use for followup task creation dropdown(users+FPrm+admin) and reviewee dropdown(FP+Managers)
            this.setAdminFpWithUsers();
        });
        // console.log('inside cdm()');
        // console.log(this.state.review_list);
        // console.log(gc_users);
    }

    setAdminFpWithUsers = () => {
        let company_id = parseInt(this.state.task.task_data.company_id) % 100000;
        let companies = this.state.gc_company;
        let companyDetails = companies.find(comp => comp.id == company_id);
        let companyUsers = companyDetails.users;
        let is_group_company = companyDetails.is_gc;
        let user_roles = this.state.roles;
        let adminFpManagers = [];
        let allUsers = [];

        // if group company
        if (is_group_company) {
            // push admin managers
            for (const [key, value] of Object.entries(user_roles)) {
                let userid = key;
                let role = value;
                if (role == 'manager') {
                    //{Store.getStoreData('contacts')[this.state.object_data.rvw_rqrd_assignto]}
                    if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                        adminFpManagers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                        allUsers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                    }
                }
            }

            for (const [key, value] of Object.entries(companyUsers)) {
                let userid = key;
                let userrole = user_roles[userid];
                if (userrole !== 'no_access') {
                    if (!allUsers.find(user => user.ID == userid)) {
                        if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                            allUsers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] })
                        }
                    }
                }
                if (userrole == 'fprm' || userrole == 'manager') {
                    if (!adminFpManagers.find(user => user.ID == userid)) {
                        if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                            adminFpManagers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                        }
                    }
                }
            }
        }
        // if parent
        else {
            //debugger;
            for (const [key, value] of Object.entries(companyUsers)) {
                let userid = key;
                let userrole = value;
                if (userrole != 'no_access') {
                    if (userrole == 'manager' || userrole == 'fprm') {
                        if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                            adminFpManagers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                        }
                    }
                    if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                        allUsers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                    }
                }
            }
        }
        //debugger;
        this.setState({ review_list: allUsers });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            let { task, payload, task_id, task_title, task_note, curlane, ready } = this.state;
            let task_data = Store.getStoreData('followup_task');
            curlane = task_data.subtask.cur_lane;
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject;
            task_note = task_json.object_data.note;
            let parent_task = task_data.parenttask;
            payload = this.GetPayloadData(parent_task);
            this.setState({ task: task_json, payload, task_id, task_title, task_note, curlane, ready: true });
        }
    }

    closeModal = (event) => {
        if (Store.getStoreData('isreferenceUrl') === true) window.location = process.env.REACT_APP_FINPROMO_URL;
        else this.props.closeModal();
    };

    UpdateSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        task_json['task_data'].last_action = 'COMP_FPR_FOLLOWUP_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_FPR_FOLLOWUP_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments;
        task_json['action_data'] = {
            action: 'COMP_FPR_FOLLOWUP_COMPLETE',
            actiondate: Date.now(),
            performed_by: Store.getStoreData('contact_id'),
            cur_lane: 'COMP_FPR_FOLLOWUP_COMPLETE',
            comments: this.state.comments,
            assigned_to: this.state.assign_to
        };
        let postData = { command: 'update_followup_task', task_json: task_json, task_id: this.state.task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.afterCompleted);
    };
    afterCompleted = (result) => {
        alert('Followup task completed successfully.');
        window.location.reload(true);
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({ ...this.state, task_title: event.target.value });
    };

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({ ...this.state, task_note: event.target.value });
    }

    handleChangeDropDown = (event) => {
        //debugger;
        event.preventDefault();
        this.setState({ ...this.state, assign_to: event.target.value });
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for (let i = 0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if (!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({ binFiles: files });
    };
    updateTask = (event) => {
        //debugger;
        event.preventDefault();
        console.log(this.state.task_id);
        //preventing multiple clicks
        if (this.state.disabled) {
            return;
        }
        this.setState({ disabled: true });


        let bin_files =
            this.state.cur_files_json === null
                ? this.state.binFiles
                : [...this.state.binFiles, ...this.state.cur_files_json];
        let task_json = this.state.task;
        task_json['task_data'].due_date = this.state.due_by;
        task_json['task_data'].cur_assigned_to = this.state.assign_to;
        task_json['object_data'].subject = this.state.task_title;
        task_json['object_data'].note = this.state.task_note;
        task_json['object_data'].assignedto = this.state.assign_to;
        task_json['object_data'].due_by = this.state.due_by;
        task_json['object_data'].bin_files = bin_files;
        task_json['action_data'] = {
            action: 'COMP_FPR_FOLLOWUP',
            actiondate: Date.now(),
            performed_by: Store.getStoreData('contact_id'),
            cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
            note: this.state.task_note,
            assigned_to: this.state.assign_to
        };
        //debugger;
        let postData = { command: 'update_followup_task', task_json: task_json, task_id: this.state.task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.afterUpdateCompleted);
        //this.setState({ followup_sub: '', followup_note: '', assigned_to: 0, date: new Date() });
    };

    afterUpdateCompleted = (result) => {
        alert('Followup task updated successfully.');
        window.location.reload(true);
    };
    onDateChangeDueBy = (date) => {
        //debugger;
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ due_by: dateStr, task_due_date: date });
    };

    closeTaskModal = () => {
        this.props.closeFollowupTaskModal();
    };

    render() {
        let index = Store.getStoreData('ftask_index') === null ? 0 : Store.getStoreData('ftask_index');
        return (
            <div>
            {
                (() => {
                    if (this.state.isLoadedIcon) {
                        return <div><CSLLoader style={{ position: 'absolute' }} /></div>
                    }
                })()
            }
            <RegModalContainer>
                <ScoreContainer>
                    <ScoreHeaderContainer>
                        Edit Follow Up task<HeaderButton onClick={this.closeModal}>
                            <FaTimes />
                        </HeaderButton>
                    </ScoreHeaderContainer>
                    <ScoreBodyContainer>
                        <MRModalLabel>Subject</MRModalLabel>
                        <MRModalInput
                            type="text"
                            name="followup_sub"
                            onChange={this.handleChange}
                            value={this.state.task_title}
                        />
                        <FCKCommentDiv style={{ padding: '0px', marginTop: '10px', marginBottom: '10px' }}>
                            <MRModalLabel>Note</MRModalLabel>
                            <FCKTextarea
                                rows="6"
                                style={{ width: '98%', marginTop: '10px' }}
                                value={this.state.task_note}
                                onChange={this.changeFollowupNote}
                            />
                        </FCKCommentDiv>
                        <MRModalLabel>Assign To</MRModalLabel>

                        <MRModalSelect onChange={this.handleChangeDropDown} name="assigned_to"
                            value={this.state.assign_to}>
                            {this.state.review_list.map((reviewer) => (
                                <option key={reviewer.ID} value={reviewer.ID}>
                                    {reviewer.ContactName}
                                </option>
                            ))}
                        </MRModalSelect>
                        <MRModalLabel>Due By</MRModalLabel>
                        <CSLDateCover>
                            <DatePicker
                                onChange={this.onDateChangeDueBy}
                                clearIcon={null}
                                //calendarIcon={null}
                                calendarIcon={<FaCalendarAlt style={{ color: '#243E57' }} />}
                                locale={'en-GB'}
                                value={this.state.task_due_date}
                            />
                        </CSLDateCover>
                        {/*Key field added inside <DropZone> else rendering is not working*/}
                        {(() => {
                            if (this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0) {
                                return (
                                    <div>
                                        <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                        <ModalNameInputDiv>
                                            <Dropzone
                                                key={1}
                                                onFilesAdded={this.filesLoaded}
                                                initFiles={this.state.cur_files_json}
                                                addnew={true}
                                            />
                                        </ModalNameInputDiv>
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                        <ModalNameInputDiv>
                                            <Dropzone
                                                key={2}
                                                onFilesAdded={this.filesLoaded}
                                                initFiles={this.state.cur_files_json}
                                                addnew={true}
                                            />
                                        </ModalNameInputDiv>
                                    </div>
                                )
                            }
                        })()}
                    </ScoreBodyContainer>

                    <div style={{ height: '60px', width: '100%', paddingRight: '12px' }}>
                        <MRModalNextBtn disabled={this.state.disabled} onClick={this.updateTask}>
                            {this.state.disabled ? 'Updating Task...' : 'Update Task'}
                        </MRModalNextBtn>
                        <MRModalCancelBtn onClick={this.closeModal}>Close</MRModalCancelBtn>
                    </div>

                </ScoreContainer>
            </RegModalContainer>
            </div>
        );
    }
}

export default EditFollowUpModal;
