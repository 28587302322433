import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import '../Team/AskConflicts.css';
import CMFollowupTask from './CMFollowupTask';
import CSLTable from '../../Common/CSLTable';
import Dropzone from '../Common/Dropzone';
import DropzoneApproval from '../Common/DropzoneApproval';
import Utils from '../../Common/Utils';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import CSLTextInput from './../Common/CSLTextInput';
import DatePicker from 'react-date-picker';
import HtmlEditor from './../Common/HtmlEditor';
import { MdModeEdit,MdDelete } from "react-icons/md";
import FollowupModal from './FollowupModal'

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #343434;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalRemoveBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #E75C71;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #E75C71;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #1E3E62;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #282828;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;

const RegModalNameInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #6d6d6d;
    background-color: #EEEEEE;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;    
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  box-shadow: 0 0 20px #a7a6a6;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 100%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;
const HeaderButton = styled.div`
    color: #ffffff;
    width: 30px;
    height: 29px;
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat',sans-serif;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 99.5%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #282828;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 21%;
    border-radius: 3px;
    height: 38px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: #758597; 
    // margin-left: 50px;
`;

class CMConflictsApprovalModal extends React.Component
{
    state = {
        nature_of_conflict: "",
        risk_description: "",
        steps_to_mitigate_risk: "",
        additional_records_of_disclosure: {
            enabled: "No",
            description: ""
        },
        disclosure_to_third_party: {
            enabled: "No",
            description: ""
        },
        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        showModal: false,
        showFollowModal: '',
        review_list: [],
        followupTask: [],
        conflictType: "",
        conflictTypeList: [],
        defaultConflictType: "",
        ready: false,
        showfollowupclose: true,
        groupcompanyName: "",
        dicloserid: 0,
        showRemovalReason: false,
        removalType: "",
        showDescription: false,
        reasonDescription: "",
        removal_readonly: false,
        header_message: "",
        reasonID: 0,
        module_config: null,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        ischangesFollowup: false,
        isLoadedIcon: false,
        alert_param: null,
        assign_data: null,
        conflicts_raised_date: new Date(),
        conflict_title: "",
        showDescriptionCType: false,
        cTypeDescription: "",
        isExitingfilesRemove: false,
        followupTasksList: [],
        headerFollowupText: '',
        conflictsOwnerID: '',
        conflictsOwnerLists: [],
        removalText: ""
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index in componentDidMount', index);
        let review_list = Store.getStoreData('reviewee_list');
        let module_config = Store.getStoreData('module_config');
        let types = module_config.conflict_types;
        let conflictTypeList = [];
        for(let k in types){
            if(types[k].isActive === 1){
                let c_obj = {};
                c_obj.id = k
                c_obj.name = types[k].title
                conflictTypeList.push(c_obj);
            }
        }
        conflictTypeList = conflictTypeList.sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);})
        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            let removal_readonly = false
            if(form_data.cur_lane === "COMP_COI_REMOVE") {
                removal_readonly =  true;
            }else if(form_data.cur_lane === "COMP_CONFLICTS_COMPLETE"){
                removal_readonly =  true;
            }

            let dicloserid = form_data.contact_id;
            let company_contacts = Store.getStoreData('contacts');
            let task_json = JSON.parse(form_data.task_json);

            let conflict_types = Store.getStoreData('module_config').conflict_types
            let conflict_types_object = {}
            for(let k in conflict_types){
                conflict_types_object[conflict_types[k].title] = k
            }
            let conflicttype = '';
            if('conflict_type' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.conflict_type !== ''){
                let cType = task_json.object_data.headerinfo.conflict_type;

                if(cType.match(/[a-z]/i) && cType in conflict_types_object){
                    cType = conflict_types_object[cType];
                }
                conflicttype = cType
            }
            console.log('conflicttype====>', conflicttype);
            let conflictsTypeMod = Store.getStoreData('conflictstype')
            let showDescriptionCType = false;
            let cTypeDescription = '';
            if(conflicttype in conflictsTypeMod && 'isComments' in conflictsTypeMod[conflicttype] && parseInt(conflictsTypeMod[conflicttype].isComments) === 1){
                showDescriptionCType = true
                cTypeDescription = 'cTypeDescription' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.cTypeDescription : '';
            }

            let gc_assoc = Store.getStoreData('gc_assoc');
            //let gcid = (parseInt(task_json.task_data.group_company_id)%100000)
            let gcid = parseInt(form_data.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(form_data.company_id)%100000);
            console.log("gcid",gcid);
            console.log("gc_assoc",gc_assoc);
            let gcname = "";
            if(gcid in gc_assoc){
                gcname = gc_assoc[gcid];
            }
            console.log("gcname",gcname)
            let gc_users = [];
            let my_company = null;
            let gc_company = Store.getStoreData('gc_company');
            for(let gc of gc_company) {
                if(gc.id === gcid)my_company = gc;
            }
            console.log('review_list', my_company.users)
            let com_roles = Store.getStoreData('userroles');
            for(let id of Object.keys(my_company.users)) {
                if (id in com_roles && com_roles[id.toString()] !== 'no_access' ) {
                    gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
                }
            }

            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = "uid" in item ? item.uid : this.genKey(10);
                })
            }

            let header_message = "This Conflict of Interest disclosure requires confirmation. Please review and select Confirm to publish this item to your Conflicts Register.";
            let module_config = Store.getStoreData('module_config');
            console.log('gc_users', gc_users)
            const {additional_records_of_disclosure} = this.state;
            additional_records_of_disclosure.enabled = task_json.object_data.headerinfo.additional_records_of_disclosure.enabled
            // additional_records_of_disclosure.description = task_json.object_data.headerinfo.additional_records_of_disclosure.description
            const {disclosure_to_third_party} = this.state;
            disclosure_to_third_party.enabled = task_json.object_data.headerinfo.disclosure_to_third_party.enabled
            // disclosure_to_third_party.description = task_json.object_data.headerinfo.disclosure_to_third_party.description

            assign_data['additional_fields'] = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
            let conflicts_raised_date = 'conflicts_raised_date' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.conflicts_raised_date) : new Date()
            let conflict_title = 'conflict_title' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.conflict_title : '';

            let qtext_nature_of_conflict = task_json.object_data.headerinfo.nature_of_conflict
            if(Utils.isBase64(task_json.object_data.headerinfo.nature_of_conflict)){
                qtext_nature_of_conflict = window.decodeURIComponent(atob(qtext_nature_of_conflict))
            }
            Store.updateStore('nature_of_conflict', null)

            let qtext_risk_description = task_json.object_data.headerinfo.risk_description
            if(Utils.isBase64(task_json.object_data.headerinfo.risk_description)){
                qtext_risk_description = window.decodeURIComponent(atob(qtext_risk_description))
            }
            Store.updateStore('risk_description', null)

            let qtext_steps_to_mitigate_risk = task_json.object_data.headerinfo.steps_to_mitigate_risk
            if(Utils.isBase64(task_json.object_data.headerinfo.steps_to_mitigate_risk)){
                qtext_steps_to_mitigate_risk = window.decodeURIComponent(atob(qtext_steps_to_mitigate_risk))
            }
            Store.updateStore('steps_to_mitigate_risk', null)

            let qtext_ard_description = task_json.object_data.headerinfo.additional_records_of_disclosure.description
            if(Utils.isBase64(task_json.object_data.headerinfo.additional_records_of_disclosure.description)){
                qtext_ard_description = window.decodeURIComponent(atob(qtext_ard_description))
            }
            additional_records_of_disclosure.description = qtext_ard_description;
            Store.updateStore('ard_description', null)

            let qtext_dtp_description = task_json.object_data.headerinfo.disclosure_to_third_party.description
            if(Utils.isBase64(task_json.object_data.headerinfo.disclosure_to_third_party.description)){
                qtext_dtp_description = window.decodeURIComponent(atob(qtext_dtp_description))
            }
            disclosure_to_third_party.description = qtext_dtp_description
            Store.updateStore('dtp_description', null)

            let followupTasksList = Store.getStoreData('updated_followups');

            let conflictsOwnerLists = [];
            let contacts = Store.getStoreData('contacts');
            for(let k in contacts){
                conflictsOwnerLists.push({'id': k, 'name': contacts[k]})
            }
            conflictsOwnerLists = conflictsOwnerLists.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            let conflictsOwnerID = 'conflictsOwnerID' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.conflictsOwnerID : ''

            let actiondata = task_json.action_data;

            let actiondata_lastObject = actiondata[actiondata.length - 1];
            let removalText = "";
            if('cur_lane' in actiondata_lastObject && actiondata_lastObject.cur_lane === 'COMP_COI_REMOVE' && 'removal_type' in actiondata_lastObject){
                removalText = actiondata_lastObject.removal_type;
                if('removal_description' in actiondata_lastObject && actiondata_lastObject.removal_description !== ''){
                    removalText = actiondata_lastObject.removal_type+" - "+actiondata_lastObject.removal_description
                }
            }



            this.setState({ Ref_ID: task_json.object_data.ref_id,
                nature_of_conflict:  qtext_nature_of_conflict,
                risk_description: qtext_risk_description,
                steps_to_mitigate_risk: qtext_steps_to_mitigate_risk,
                additional_records_of_disclosure: additional_records_of_disclosure,
                disclosure_to_third_party: disclosure_to_third_party,
                curlane: form_data.cur_lane,
                taskid: form_data.id,
                review_list: gc_users,
                conflictType: conflicttype,
                groupcompanyName: gcname,
                dicloserid: dicloserid,
                removal_readonly: removal_readonly,
                header_message: header_message,
                module_config: module_config,
                cur_files_json: task_json.object_data.bin_files,
                assign_data,
                conflicts_raised_date,
                conflict_title,
                showDescriptionCType,
                cTypeDescription,
                followupTasksList,
                conflictsOwnerLists,
                conflictsOwnerID,
                conflictTypeList,
                removalText,
                ready: true
            });
        }
    }

    showFollowupsTask = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        console.log('result.result.followuptasks', result.result.followuptasks);
        this.setState({ ready: true });
    }
    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            // window.location = '/';
            this.props.closeModal();
        } else {
            if(this.state.ischangesFollowup){
                this.props.closeModalForApproval();
            }else{
                this.props.closeModalNormal();
            }
        }
    }
    handleAdditionalROD = (event) => {
        console.log(event.target.value)
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.enabled = event.target.value;
        if(event.target.value === 'No'){
            additional_records_of_disclosure.description = "";
        }
        this.setState({ additional_records_of_disclosure })
    }
    handleThiredParty = (event) => {
        console.log(event.target.value)
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.enabled = event.target.value;
        if(event.target.value === 'No'){
            disclosure_to_third_party.description = "";
        }
        this.setState({ disclosure_to_third_party })
    }

    okAfterComplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload();
    }

    updateRegisterConflict = (submit_form) => (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        const  module_config = Store.getStoreData('module_config');
        if(this.state.conflict_title === ''){
            problems = true
            message += 'Please enter the Conflict title.<br>'
        }
        let nature_of_conflict = Store.getStoreData('nature_of_conflict');
        if(nature_of_conflict === null){
            nature_of_conflict = this.state.nature_of_conflict;
        }
        let risk_description = Store.getStoreData('risk_description');
        if(risk_description === null){
            risk_description = this.state.risk_description;
        }
        let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
        if(steps_to_mitigate_risk === null){
            steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
        }
        if(nature_of_conflict === '' || risk_description === "" || steps_to_mitigate_risk === "") {
            //alert('Please fill all the information');
            problems = true
            message += 'Please fill all the information.<br>'
        }
        if(this.state.conflictType === "") {
            //alert('Please select conflict type');
            problems = true
            message += 'Please select conflict type.<br>'
        }
        let ard_description = Store.getStoreData('ard_description');
        if(ard_description === null){
            ard_description = this.state.additional_records_of_disclosure.description;
        }
        if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === "") {
            problems = true
            message += 'Please fill the details for information relating to this Disclosure.<br>'
        }
        let dtp_description = Store.getStoreData('dtp_description');
        if(dtp_description === null){
            dtp_description = this.state.disclosure_to_third_party.description;
        }
        if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === "") {
            problems = true
            message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'
        }
        if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {
                    if (item.required === true && item.value === "") {
                        fields.push(item.nickname);
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                    numericFields.push(item.nickname);
                                }
                            }
                        }
                    }
                })
            }
            if (fields.length !== 0) {
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ".<br>";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);
                problems = true
                message += err_msg +`<br>`
            }
            if(is_numeric) {
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                }
                problems = true
                message += err_msg_new +`<br>`
            }
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkDefaultalertparam, stack: {}}
            this.setState({alert_param: alert_param})
            return
        }

        let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict));
        let risk_description_value = btoa(window.encodeURIComponent(risk_description));
        let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));
        let ard_description_value = btoa(window.encodeURIComponent(ard_description));
        let dtp_description_value = btoa(window.encodeURIComponent(dtp_description));

        let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}
        let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

        let headerinfo = {};
        headerinfo.nature_of_conflict = nature_of_conflict_value;
        headerinfo.risk_description = risk_description_value;
        headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;
        headerinfo.conflict_type = this.state.conflictType;
        headerinfo.additional_records_of_disclosure = ard;
        headerinfo.disclosure_to_third_party = dtp;
        headerinfo.conflicts_raised_date = this.state.conflicts_raised_date;
        headerinfo.conflict_title = this.state.conflict_title;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.conflictsOwnerID = this.state.conflictsOwnerID

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.ref_id = this.state.Ref_ID;

        let task_json = {};
        if(submit_form === 1) {
            task_json = {
                "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_REGISTER',
                "cur_assigned_to" : 0, 'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0 },
                "object_data": final_object_data,
                "action_data": { "action": "COMP_CONFLICTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_REGISTER"}
            };
        }else{
            task_json = {
                "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_TEAMSAVE',
                'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0 },
                "object_data": final_object_data,
                "action_data": { "action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_TEAMSAVE"}
            };
        }
        let postData = {command: "update_conflicts_task", task_json: task_json, submit: submit_form, taskid: this.state.taskid };
        console.log("postData",postData)
        this.setState({submit_form, isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processUpdateConflictsData);
    }

    processUpdateConflictsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){
            //alert('Conflicts of Interest Registerd Successfully');
            let alert_param = {title: 'Success', message: 'Conflicts of Interest Registerd Successfully.', ok_text: 'Ok', confirm: false,
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})
        }else {
            //alert('Conflicts of Interest Update Successfully');
            let alert_param = {title: 'Success', message: 'Conflicts of Interest Update Successfully.', ok_text: 'Ok', confirm: false,
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})
        }
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    handleChangeCType = (evt) => {
        let conflict_types = Store.getStoreData('module_config').conflict_types;
        let showDescriptionCType = false
        if(evt.target.value in conflict_types && 'isComments' in conflict_types[evt.target.value] && parseInt(conflict_types[evt.target.value].isComments) === 1){
            showDescriptionCType = true
        }
        this.setState({[evt.target.name]: evt.target.value, showDescriptionCType});
    }

    handleChangeRemoval = (evt) => {
        console.log("key",evt.target.options.selectedIndex)
        let removalItems = Store.getStoreData('removaltype').removalType;
        let curIndex = evt.target.options.selectedIndex;
        let showDescription = false;
        let reasonID = 0
        if(curIndex !== 0){
            if(removalItems[curIndex - 1].isDescription === 1){
                showDescription = true;
            }
            reasonID = removalItems[curIndex - 1].reasonID
        }
        console.log("Sourav111",showDescription)
        this.setState({[evt.target.name]: evt.target.value, showDescription, reasonID});
    }

    handleChangeDTTP = (evt) => {
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.description = evt.target.value;
        this.setState({ disclosure_to_third_party })
    }
    handleChangeAROD = (evt) => {
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.description = evt.target.value;
        this.setState({ additional_records_of_disclosure })
    }
    showAddFollowupTaskModal = (index) => (event) => {
        Store.updateStore('ftask_index', -1);
        this.setState({ showModal: true, showfollowupclose: false, headerFollowupText: "Add new"});
    }

    closeFollowupModal = () => {
        this.setState({ showModal: false, showfollowupclose: true });
    }

    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "conflicts", "last_action": "COMP_COI_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_COI_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_COI_FOLLOWUP_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_COI_FOLLOWUP", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_COI_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid};
        console.log("task Follow-up", postData);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.processAddTask);
    }

    processAddTask = (result) => {
        let alert_param = {title: 'Success', message: 'A Follow-up Task has been successfully added to this Conflict.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.followupSuccess, stack: {result: result}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }
    followupSuccess = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null, isLoadedIcon: true})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups);
    }

    updateFollowups = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        this.setState({ showModal: false,
            followupTasksList: result.result.followuptasks,
            isLoadedIcon: false,
            ischangesFollowup: true,
            showfollowupclose: true
        });
    }

    processMyfollowupTaskData = () => {
        var taskdata = this.state.followupTasksList;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', minWidth: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Notes', accessor: 'notes', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 200, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Status', accessor: 'status', minWidth: 150, headerStyle: {textAlign: 'center', fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action',Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                <MRButton style={{width: '80px',cursor: 'pointer'}} onClick={this.showFollowUp(row.original.id)}>View</MRButton>
                                {
                                    (() => {
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                                <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><MdModeEdit /></div>
                                                {
                                                    (() => {
                                                        if(row.original.role !== 'cm'){
                                                            return (<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteChildTask(row.original.id)}><MdDelete /></div>)
                                                        }
                                                    })()
                                                }
                                            </div>)
                                        }
                                    })()
                                }
                            </div>
                         ), width: 180, headerStyle: {textAlign: 'center',fontWeight: "600"}}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_COI_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_COI_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let notes = taskdataobject.note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'id' : d.id,
                'index' : i,
                'assign_to' : assigntoName,
                'notes' : notes,
                'due_date' : due_date,
                'status' : {text: text, color: color},
                'status_value': text,
                'role': Store.getStoreData('role')
               };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteChildTask = (taskid) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.deleteConfirmChildTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }

    deleteConfirmChildTask = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let postData = {command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData",postData)
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processRemoveFollowupData);
    }

    processRemoveFollowupData = (result) => {
        this.setState({alert_param: null, ischangesFollowup: true})
        let alert_param = {title: 'Success', message: 'Follow-up task deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editFollowupModal);
    }

    editFollowupModal = (result) => {
        console.log("followup_task",result.result.task);
        Store.updateStore('ftask_index', 0);
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showModal: true, headerFollowupText: "Edit"})
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        this.setState({isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        //console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showFollowModal: 'view_followup_modal', isLoadedIcon: false});
    }

    showfollowtaskModal = (row) => (event) => {
        event.preventDefault();
        Store.updateStore('ftask_index', row.index);
        this.setState({ showModal: true });
    }

    submitApproveConflict = (submit_form) => (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        const  module_config = Store.getStoreData('module_config');
        if(this.state.conflict_title === ''){
            problems = true
            message += 'Please enter the Conflict title.<br>'
        }
        let nature_of_conflict = Store.getStoreData('nature_of_conflict');
        if(nature_of_conflict === null){
            nature_of_conflict = this.state.nature_of_conflict;
        }
        let risk_description = Store.getStoreData('risk_description');
        if(risk_description === null){
            risk_description = this.state.risk_description;
        }
        let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
        if(steps_to_mitigate_risk === null){
            steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
        }
        if(nature_of_conflict === '' || risk_description === "" || steps_to_mitigate_risk === "") {
            //alert('Please fill all the information');
            problems = true
            message += 'Please fill all the information.<br>'
        }
        if(this.state.conflictType === "") {
            //alert('Please select conflict type');
            problems = true
            message += 'Please select conflict type.<br>'
        }
        if(this.state.showDescriptionCType && this.state.cTypeDescription === ''){
            problems = true
            message += 'Please fill the conflicts type description.<br>'
        }
        let ard_description = Store.getStoreData('ard_description');
        if(ard_description === null){
            ard_description = this.state.additional_records_of_disclosure.description;
        }
        if(this.state.additional_records_of_disclosure.enabled === "Yes" && ard_description === "") {
            //alert('Please fill the details for information relating to this Disclosure');
            problems = true
            message += 'Please fill the details for information relating to this Disclosure.<br>'
        }
        let dtp_description = Store.getStoreData('dtp_description');
        if(dtp_description === null){
            dtp_description = this.state.disclosure_to_third_party.description;
        }
        if(this.state.disclosure_to_third_party.enabled === "Yes" && dtp_description === "") {
            //alert('Please fill the details for Disclosure be made to a 3rd party');
            problems = true
            message += 'Please fill the details for Disclosure be made to a 3rd party.<br>'
        }
        if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {
                    if (item.required === true && item.value === "") {
                        fields.push(item.nickname);
                    }
                    if(item.value){
                        if(item.type === 'Numeric') {
                            if(item.value.length > 0) {
                                if(isNaN(item.value)){
                                    is_numeric = true;
                                    numericFields.push(item.nickname);
                                }
                            }
                        }
                    }
                })
            }
            if (fields.length !== 0) {
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ".<br>";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);
                problems = true
                message += err_msg +`<br>`
            }
            if(is_numeric) {
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                }
                problems = true
                message += err_msg_new +`<br>`
            }
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkDefaultalertparam, stack: {}}
            this.setState({alert_param: alert_param})
            return
        }

        let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict));
        let risk_description_value = btoa(window.encodeURIComponent(risk_description));
        let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));
        let ard_description_value = btoa(window.encodeURIComponent(ard_description));
        let dtp_description_value = btoa(window.encodeURIComponent(dtp_description));

        let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}
        let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

        let headerinfo = {};
        headerinfo.nature_of_conflict = nature_of_conflict_value;
        headerinfo.risk_description = risk_description_value;
        headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;
        headerinfo.conflict_type = this.state.conflictType;
        headerinfo.cTypeDescription = this.state.cTypeDescription;
        headerinfo.additional_records_of_disclosure = ard;
        headerinfo.disclosure_to_third_party = dtp;
        headerinfo.conflicts_raised_date = this.state.conflicts_raised_date;
        headerinfo.conflict_title = this.state.conflict_title;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.conflictsOwnerID = this.state.conflictsOwnerID

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.ref_id = this.state.Ref_ID;

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];

        let task_json = {};
        if(submit_form === 1) {
            task_json = {
                "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_COMPLETE", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_COMPLETE',
                "cur_assigned_to" : 0, 'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0 },
                "object_data": final_object_data,
                "action_data": { "action": "COMP_CONFLICTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_COMPLETE"}
            };
        }else{
            task_json = {
                "task_data": { "module_id": "conflicts", "last_action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_CONFLICTS_TEAMSAVE',
                'task_type' : 'COMP_CONFLICTS', 'parent_task_id' : 0 },
                "object_data": final_object_data,
                "action_data": { "action": "COMP_CONFLICTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_CONFLICTS_TEAMSAVE"}
            };
        }

        let curmytaskdata = Store.getStoreData('cur_q_modal_data');
        let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
        let from_api = 0
        if('from_api' in curtask_jsonValue.object_data){
            from_api = curtask_jsonValue.object_data.from_api;
        }

        let isSendEmail = true
        if(this.state.curlane === "COMP_CONFLICTS_COMPLETE" && !this.state.showRemovalReason){
            isSendEmail = false
        }

        let postData = {command: "update_conflicts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api,
                        isExitingfilesRemove: this.state.isExitingfilesRemove, isSendEmail: isSendEmail };
        console.log("postData",postData)
        this.setState({submit_form, isLoadedIcon: true})
        let api = new APICall();
        api.command(postData, this.processUpdateApproveConflictsData);
    }

    checkDefaultalertparam = (result, stack) => {
        this.setState({alert_param: null})
    }


    processUpdateApproveConflictsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){
            //alert('Conflicts of Interest Confirmed Successfully');
            let alert_param = {title: 'Success', message: 'Conflicts of Interest Confirmed Successfully.', ok_text: 'Ok', confirm: false,
                    alertHandler: this.okAfterComplete, stack: {}}
            this.setState({alert_param: alert_param, isLoadedIcon: false})
        }else {
            //alert('This Conflict of Interest has been saved but not confirmed. You can revisit this later and confirm.');
            if(this.state.curlane === "COMP_CONFLICTS_COMPLETE" && !this.state.showRemovalReason){
                let alert_param = {title: 'Success', message: 'Your changes have been saved.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.okAfterComplete, stack: {}}
                this.setState({alert_param: alert_param, isLoadedIcon: false})
            }else{
                let alert_param = {title: 'Success', message: 'This Conflict of Interest has been saved but not confirmed. You can revisit this later and confirm.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.okAfterComplete, stack: {}}
                this.setState({alert_param: alert_param, isLoadedIcon: false})
            }
        }
    }

    openRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: true});
    }

    closeRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: false});
    }

    submitReasonRemoval = (event) => {
        event.preventDefault();
        if(this.state.removalType === ""){
            // alert('You must select a Removal Reason first.');
            let alert_param = {title: 'ERROR', message: 'You must select a Removal Reason first.', ok_text: 'Ok', confirm: false,
                alertHandler: this.checkDefaultalertparam, stack: {}}
            this.setState({alert_param: alert_param})
            return;
        }
        if(this.state.showDescription && this.state.reasonDescription === ""){
            // alert('Please fill the reason description');
            let alert_param = {title: 'ERROR', message: 'Please fill the reason description.', ok_text: 'Ok', confirm: false,
                alertHandler: this.checkDefaultalertparam, stack: {}}
            this.setState({alert_param: alert_param})
            return;
        }
        if(this.state.curlane === "COMP_CONFLICTS_COMPLETE"){
            let alert_param = {title: 'Warning', message: 'The status of this conflict will be updated to Removed. Are you happy to proceed?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.resonRemovalConflictsTask, stack: {}}
            this.setState({alert_param: alert_param})
        }else{
            let alert_param = {title: 'Warning', message: 'This Conflict Disclosure will be removed from the system and the discloser updated appropriately. Do you want to proceed?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                            alertHandler: this.resonRemovalConflictsTask, stack: {}}
            this.setState({alert_param: alert_param})
        }
    }

    resonRemovalConflictsTask = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})
        let action_data = {
            task_id: this.state.taskid,
            cur_lane: "COMP_COI_REMOVE",
            assigned_to: 0,
            action: "COMP_COI_REMOVE",
            performed_by: Store.getStoreData('contact_id'),
            removal_type:  this.state.removalType,
            removal_description: this.state.reasonDescription
        };
        let form_data = Store.getStoreData('cur_q_modal_data');
        let task_json = JSON.parse(form_data.task_json);

        task_json.task_data['actiondate'] = Date.now();

        let nature_of_conflict = Store.getStoreData('nature_of_conflict');
        if(nature_of_conflict === null){
            nature_of_conflict = this.state.nature_of_conflict;
        }
        let nature_of_conflict_value = btoa(window.encodeURIComponent(nature_of_conflict));

        let risk_description = Store.getStoreData('risk_description');
        if(risk_description === null){
            risk_description = this.state.risk_description;
        }
        let risk_description_value = btoa(window.encodeURIComponent(risk_description));
        let steps_to_mitigate_risk = Store.getStoreData('steps_to_mitigate_risk');
        if(steps_to_mitigate_risk === null){
            steps_to_mitigate_risk = this.state.steps_to_mitigate_risk;
        }
        let steps_to_mitigate_risk_value = btoa(window.encodeURIComponent(steps_to_mitigate_risk));

        let ard_description = Store.getStoreData('ard_description');
        if(ard_description === null){
            ard_description = this.state.additional_records_of_disclosure.description;
        }
        let dtp_description = Store.getStoreData('dtp_description');
        if(dtp_description === null){
            dtp_description = this.state.disclosure_to_third_party.description;
        }

        let ard_description_value = btoa(window.encodeURIComponent(ard_description));
        let dtp_description_value = btoa(window.encodeURIComponent(dtp_description));

        let ard = {'enabled': this.state.additional_records_of_disclosure.enabled, description: ard_description_value}
        let dtp = {'enabled': this.state.disclosure_to_third_party.enabled, description: dtp_description_value}

        let headerinfo = {};
            headerinfo.nature_of_conflict = nature_of_conflict_value;
            headerinfo.risk_description = risk_description_value;
            headerinfo.steps_to_mitigate_risk = steps_to_mitigate_risk_value;
            headerinfo.conflict_type = this.state.conflictType;
            headerinfo.cTypeDescription = this.state.cTypeDescription;
            headerinfo.additional_records_of_disclosure = ard;
            headerinfo.disclosure_to_third_party = dtp;
            headerinfo.conflicts_raised_date = this.state.conflicts_raised_date;
            headerinfo.conflict_title = this.state.conflict_title;
            headerinfo.additional_fields = this.state.assign_data.additional_fields;
            headerinfo.conflictsOwnerID = this.state.conflictsOwnerID

        let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            final_object_data.ref_id = this.state.Ref_ID;

        task_json['object_data'] = final_object_data
        task_json['action_data'] = action_data

        let postData = { "command": "removalTask", "SystemID": 1001, task_json: task_json, task_id: this.state.taskid, dicloser_id: this.state.dicloserid };
        console.log("postData",postData);
        let api = new APICall();
        api.command(postData, this.processUpdateRemoveConflictsData);
    }

    processUpdateRemoveConflictsData = (result) => {
        console.log("result", result)
        // alert('Conflicts of Interest Removed Successfully');
        let alert_param = {title: 'Success', message: 'Conflicts of Interest Removed Successfully.', ok_text: 'Ok', confirm: false,
                alertHandler: this.checkRemovalalertparam, stack: {}}
        this.setState({alert_param: alert_param})
    }

    checkRemovalalertparam = (result, stack) => {
        this.setState({alert_param: null})
        console.log("reasonID",this.state.reasonID);
        let removalItems = Store.getStoreData('removaltype').removalType;
        let removalTypes = JSON.parse(JSON.stringify(removalItems));
        let changemodule = 0
        removalTypes.forEach((item) => {
            if (item.reasonID === this.state.reasonID && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });
        console.log("modified submitedShareUserList", removalTypes);
        if(changemodule === 1){
            let {module_config} = this.state;
            module_config.reason_removal = removalTypes;
            const postData = {command: "save_module_config", module_config: module_config};
            const api = new APICall();
            api.command(postData, this.afterUpdatemoduleconfig);
        }else{
            window.location.reload();
        }
    }

    afterUpdatemoduleconfig = (result) => {
        console.log(result);
        window.location.reload();
   }

    updateRemovalTask = (event) => {
        event.preventDefault();
        let header_message = "You are amending and updating a Conflict that has already been submitted.";
        this.setState({removal_readonly: false, header_message: header_message})
    }

    processConflictsTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({removal_readonly: false})
    }
    showalertFollowup = (event) => {
        event.preventDefault();
        // alert("Adding Follow-up tasks to a removed Conflict is not allowed.")
        let alert_param = {title: 'ERROR', message: 'Adding Follow-up tasks to a removed Conflict is not allowed.', ok_text: 'Ok', confirm: false,
                alertHandler: this.checkDefaultalertparam, stack: {}}
        this.setState({alert_param: alert_param})
        return
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            // case "Date":
                            //     fieldhtml = this.createDateField(data, data_container);
                            //     break;
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }
    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" disabled={this.state.removal_readonly}/>);
    }
    handleTextChange = (obj) => {
		console.log("obj:", obj);
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}
    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="text" min={0} name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} style={{width: "98%"}} disabled={this.state.removal_readonly}/>);
    }
    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("event.target.name===>", event.target.name)
        console.log("assign_data===>", assign_data)
        console.log("data_container===>", data_container)
        assign_data[data_container].forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }
    createDropdownField = (data, data_container) => {
        console.log("data.value==>", data.value)
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value} disabled={this.state.removal_readonly}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value" disabled={this.state.isdesable}/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }
    onChangeConflictsRaisedDate = (date) => {
        this.setState({conflicts_raised_date: date});
    }

    onExitsFilesUpdated = (exitsfiles) => {
        console.log("exitsfiles===>", exitsfiles)
        this.setState({cur_files_json: exitsfiles, isExitingfilesRemove: true});
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({showFollowModal: null})
    }

    EditrefreshTaskValue = (taskValue) => {
        //console.log(taskValue)
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_COI_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_COI_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData);
        this.setState({isLoadedIcon: true})
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);
    }

    processEditFollowupTask = (result) => {
        let alert_param = {title: 'Success', message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    updateprocessallchildtask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(command_data, this.updateallChildTask);
    }

    updateallChildTask = (result) => {
        Store.updateStore('current_followup_lists', result.result.followuptasks);
        console.log('result.result.current_child_lists', result);
        this.setState({ showModal: false,
            followupTasksList: result.result.followuptasks,
            isLoadedIcon: false
        });
    }

    render()
    {
        let curmytaskdata = Store.getStoreData('cur_q_modal_data');
        console.log("curmytaskdata",curmytaskdata)
        let task_json = JSON.parse(curmytaskdata.task_json);
        const discloserdate_obj = new Date(curmytaskdata.due_date);
        const discloserdate_day = discloserdate_obj.getDate() < 10 ? "0"+discloserdate_obj.getDate().toString() : discloserdate_obj.getDate();
        const discloserdate_month = discloserdate_obj.getMonth()+1 < 10 ? `0${(discloserdate_obj.getMonth() + 1).toString()}` : discloserdate_obj.getMonth()+1;
        const discloserdate_year = discloserdate_obj.getFullYear();
        const discloserdate_date = `${discloserdate_day}/${discloserdate_month}/${discloserdate_year}`;
        let status = "";
        if(curmytaskdata.cur_lane === "COMP_CONFLICTS_REGISTER") {
            status =  "Registered";
        }else if(curmytaskdata.cur_lane === "COMP_CONFLICTS_COMPLETE") {
            status =  "Confirmed";
        }else if(curmytaskdata.cur_lane === "COMP_COI_REMOVE") {
            status = "Removed"
        }
        let discloserName = Store.getStoreData('contacts')[curmytaskdata.contact_id];
        let discloserEmail = Store.getStoreData('contactsemail')[curmytaskdata.contact_id];

        if('requester_name' in task_json.object_data){
            discloserName = task_json.object_data.requester_name;
        }
        if('email' in task_json.object_data){
            discloserEmail = task_json.object_data.email;
        }
        // let conflictsType = Store.getStoreData('conflictstype');
        let removalItems = Store.getStoreData('removaltype');
        let updatedFollowups = Store.getStoreData('updated_followups');
        let module_config = Store.getStoreData('module_config');
        if(!this.state.ready){
            return(<div></div>)
        }
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
            <MRModalContainer>
            <MRModalHeader>
                <MRModalHeaderText>{this.state.Ref_ID} - Conflicts of Interest Disclosure</MRModalHeaderText>
                <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
            </MRModalHeader>
            <MRModalBody>
                {
                    (() => {
                        if(this.state.removal_readonly){
                            return(
                                <MRModalBodyInner style={{backgroundColor: "#EDF0F1", border: "1px solid #D6DDDF",color: "#33435E", fontWeight: "bold"}}>
                                    This Conflict of Interest Disclosure has been submitted.
                                </MRModalBodyInner>
                            )
                        }else{
                            return(
                                <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>
                                    {this.state.header_message}
                                </MRModalBodyInner>
                            )
                        }
                    })()
                }
                <MRModalHeaderText style={{marginTop: "14px",fontSize: "14px",color: "#676767"}}>Company Name - {this.state.groupcompanyName}</MRModalHeaderText>
                <MRModalLabel style={{marginTop: "50px"}}>Date of Entry</MRModalLabel>
                <RegModalNameInput style={{width: "20%"}} defaultValue={discloserdate_date} readOnly/>
                <div style={{width: "100%"}}>
                    <div style={{display: "inline-block", width: "50%"}}>
                        <MRModalLabel style={{marginTop: "50px"}}>Status</MRModalLabel>
                        <RegModalNameInput style={{width: "50%"}} defaultValue={status} readOnly/>
                    </div>
                    {
                        (() => {
                            if(status === 'Removed'){
                                return(<div style={{display: "inline-block", width: "50%"}}>
                                        <MRModalLabel style={{marginTop: "50px"}}>Reason for Removal</MRModalLabel>
                                        <RegModalNameInput style={{width: "80%"}} defaultValue={this.state.removalText} readOnly/>
                                    </div>)
                            }
                        })()
                    }
                </div>


                <MRModalLabel style={{marginTop: "50px"}}>Discloser's Name</MRModalLabel>
                <RegModalNameInput style={{width: "45%"}} defaultValue={discloserName} readOnly/>
                <MRModalLabel style={{marginTop: "50px"}}>Discloser's Email</MRModalLabel>
                <RegModalNameInput style={{width: "98%"}} defaultValue={discloserEmail} readOnly/>
                <MRModalLabel style={{marginTop: "50px"}}>Date Conflict was raised</MRModalLabel>
                <CSLDateCover id="conflicts_raised_date" style={{display: "inline-block"}}>
                    <DatePicker
                            onChange={this.onChangeConflictsRaisedDate}
                            clearIcon={null}
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={this.state.conflicts_raised_date}
                            disabled={this.state.removal_readonly}
                    />
                </CSLDateCover>
                <MRModalLabel style={{marginTop: "50px"}}>Conflict Title</MRModalLabel>
                <MRModalInput name="conflict_title" value={this.state.conflict_title} onChange={this.handleChange} disabled={this.state.removal_readonly}/>
                {
                    (() => {
                        if('record_conflict_owner' in module_config.general && module_config.general.record_conflict_owner === true){
                            return(<div>
                                <MRModalLabel style={{marginTop: "50px"}}>Conflict Owner</MRModalLabel>
                                <MRModalSelect id="conflictsOwnerID" name="conflictsOwnerID" onChange={this.handleChange} value={this.state.conflictsOwnerID} disabled={this.state.removal_readonly}>
                                    <option value="">Select</option>
                                    {
                                        this.state.conflictsOwnerLists.map((rc,index) => {
                                            return(<option key={index} value={rc.id}>{rc.name}</option>);
                                        })
                                    }
                                </MRModalSelect>
                            </div>)
                        }
                    })()
                }
                <MRModalLabel style={{marginTop: "50px"}}>Type</MRModalLabel>
                <MRModalSelect name="conflictType" onChange={this.handleChangeCType} value={this.state.conflictType} disabled={this.state.removal_readonly}>
                <option value="">Select type</option>
                {
                    // Object.keys(module_config.conflict_types).map((d, index) => {
                    //     return (<option key={index} value={d}>{module_config.conflict_types[d].title}</option>);
                    // })
                    this.state.conflictTypeList.map((rc,index) => {
                        return(<option key={index} value={rc.id}>{rc.name}</option>);
                    })
                }
                </MRModalSelect>
                {
                    (() => {
                        if(!this.state.removal_readonly && this.state.showDescriptionCType){
                            return(
                                <div>
                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Conflicts Type Description</MRModalLabel><span style={{marginLeft: "5px",color: "red", fontSize: "15px"}}>*</span>
                                    <MRModalTextarea rows="5" name="cTypeDescription" value={this.state.cTypeDescription} onChange={this.handleChange} />
                                </div>
                            )
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.removal_readonly && this.state.showDescriptionCType){
                            return(
                                <div>
                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Conflicts Type Description</MRModalLabel>
                                    <div dangerouslySetInnerHTML={{__html: this.state.cTypeDescription}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                </div>
                            )
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.removal_readonly){
                            return(
                                <div>
                                    <MRModalLabel style={{marginTop: "50px"}}>Please describe the nature of the Conflict</MRModalLabel>
                                    <div dangerouslySetInnerHTML={{__html: this.state.nature_of_conflict}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the risk</MRModalLabel>
                                    <div dangerouslySetInnerHTML={{__html: this.state.risk_description}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the steps being taken to mitigate this risk</MRModalLabel>
                                    <div dangerouslySetInnerHTML={{__html: this.state.steps_to_mitigate_risk}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                    {/* custom field start */}
                                    {
                                        (() => {
                                            if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                                if (this.state.assign_data.additional_fields.length !== 0) {
                                                    let custom_html = [];
                                                    this.state.assign_data.additional_fields.forEach((item) => {
                                                        const field = this.generateField(item, "additional_fields");
                                                        custom_html.push(field);
                                                    })
                                                    return custom_html;
                                                }
                                            }
                                        })()
                                    }
                                    {/* custom field end */}
                                    <MRModalLabel style={{marginTop: "30px"}}>Are there any additional records or information relating to this Disclosure?</MRModalLabel>
                                    <div>
                                        <div className="switch">
                                            <input type="radio" name="allowAROD" id="yes" value="Yes" checked={this.state.additional_records_of_disclosure.enabled === 'Yes'}  />
                                            <Radiolevel className="rediolabel" htmlFor="yes" style={{cursor: "default"}}>Yes</Radiolevel>
                                            <input type="radio" name="allowAROD" id="no" value="No" checked={this.state.additional_records_of_disclosure.enabled === 'No'}  />
                                            <Radiolevel className="rediolabel" htmlFor="no" style={{cursor: "default"}}>No</Radiolevel>
                                            <span className="switchFilter"></span>
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.additional_records_of_disclosure.enabled === 'Yes') {
                                                return (
                                                <div dangerouslySetInnerHTML={{__html: this.state.additional_records_of_disclosure.description}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                                );
                                            }
                                        })()
                                    }
                                    <MRModalLabel style={{marginTop: "30px"}}>Has or will a Disclosure be made to a 3rd party?</MRModalLabel>
                                    <div>
                                        <div className="switch1">
                                            <input type="radio" name="allowThiredParty" id="yes1" value="Yes" checked={this.state.disclosure_to_third_party.enabled === 'Yes'}  />
                                            <Radiolevel className="rediolabel" htmlFor="yes1" style={{cursor: "default"}}>Yes</Radiolevel>
                                            <input type="radio" name="allowThiredParty" id="no1" value="No" checked={this.state.disclosure_to_third_party.enabled === 'No'}  />
                                            <Radiolevel className="rediolabel" htmlFor="no1" style={{cursor: "default"}}>No</Radiolevel>
                                            <span className="switchFilter1"></span>
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.disclosure_to_third_party.enabled === 'Yes') {
                                                return (
                                                <div dangerouslySetInnerHTML={{__html: this.state.disclosure_to_third_party.description}} style={{textAlign: "justify",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}></div>
                                                );
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                                return(<div>
                                                        <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                                        <ModalNameInputDiv>
                                                            <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                                        </ModalNameInputDiv>
                                                </div>)
                                            }
                                        })()
                                    }
                                    <hr style={{marginTop: "20px", color: "#CCCFD4"}}/>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow-up Tasks</div>
                                            <div style={{float: "right", color: "#ffffff", fontSize: "17px"}} ><FaPlusCircle /></div>
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        {
                                            (() => {
                                                if(this.state.showModal) {
                                                    return(
                                                        <div>
                                                        <CMFollowupTask headerText={this.state.headerFollowupText} taskId={this.state.taskid} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch} EditrefreshTaskValue={this.EditrefreshTaskValue}/>
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if(updatedFollowups.length !== 0) {
                                                    return(
                                                        <OverviewInfoBody>
                                                            <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                                        </OverviewInfoBody>
                                                    )
                                                }else {
                                                    return(
                                                    <OverviewInfoBody>
                                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no Follow-up tasks assigned.</div>
                                                    </OverviewInfoBody>
                                                    );
                                                }
                                            })()
                                        }
                                    </RegDismisOuterContainer>
                                </div>
                            )
                        }else{
                            return(
                                <div>
                                    <MRModalLabel style={{marginTop: "50px"}}>Please describe the nature of the Conflict</MRModalLabel>
                                    {/* <MRModalTextarea rows="5" name="nature_of_conflict" value={this.state.nature_of_conflict} onChange={this.handleChange} /> */}
                                    <div style={{width: "100%"}}><HtmlEditor content={this.state.nature_of_conflict} content_type={'nature_of_conflict'}/></div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the risk</MRModalLabel>
                                    {/* <MRModalTextarea rows="5" name="risk_description" value={this.state.risk_description} onChange={this.handleChange}/> */}
                                    <div style={{width: "100%"}}><HtmlEditor content={this.state.risk_description} content_type={'risk_description'}/></div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Please describe the steps being taken to mitigate this risk</MRModalLabel>
                                    {/* <MRModalTextarea rows="5" name="steps_to_mitigate_risk" value={this.state.steps_to_mitigate_risk} onChange={this.handleChange}/> */}
                                    <div style={{width: "100%"}}><HtmlEditor content={this.state.steps_to_mitigate_risk} content_type={'steps_to_mitigate_risk'}/></div>
                                    {/* custom field start */}
                                    {
                                        (() => {
                                            if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                                if (this.state.assign_data.additional_fields.length !== 0) {
                                                    let custom_html = [];
                                                    this.state.assign_data.additional_fields.forEach((item) => {
                                                        const field = this.generateField(item, "additional_fields");
                                                        custom_html.push(field);
                                                    })
                                                    return custom_html;
                                                }
                                            }
                                        })()
                                    }
                                    {/* custom field end */}
                                    <MRModalLabel style={{marginTop: "30px"}}>Are there any additional records or information relating to this Disclosure?</MRModalLabel>
                                    <div>
                                        <div className="switch">
                                            <input type="radio" name="allowAROD" id="yes" value="Yes" checked={this.state.additional_records_of_disclosure.enabled === 'Yes'}  onChange={this.handleAdditionalROD}/>
                                            <Radiolevel className="rediolabel" htmlFor="yes">Yes</Radiolevel>
                                            <input type="radio" name="allowAROD" id="no" value="No" checked={this.state.additional_records_of_disclosure.enabled === 'No'} onChange={this.handleAdditionalROD} />
                                            <Radiolevel className="rediolabel" htmlFor="no">No</Radiolevel>
                                            <span className="switchFilter"></span>
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.additional_records_of_disclosure.enabled === 'Yes') {
                                                return (<div style={{width: "100%"}}><HtmlEditor content={this.state.additional_records_of_disclosure.description} content_type={'additional_records_of_disclosure_description'}/></div>);
                                            }
                                        })()
                                    }
                                    <MRModalLabel style={{marginTop: "30px"}}>Has or will a Disclosure be made to a 3rd party?</MRModalLabel>
                                    <div>
                                        <div className="switch1">
                                            <input type="radio" name="allowThiredParty" id="yes1" value="Yes" checked={this.state.disclosure_to_third_party.enabled === 'Yes'}  onChange={this.handleThiredParty}/>
                                            <Radiolevel className="rediolabel" htmlFor="yes1">Yes</Radiolevel>
                                            <input type="radio" name="allowThiredParty" id="no1" value="No" checked={this.state.disclosure_to_third_party.enabled === 'No'} onChange={this.handleThiredParty} />
                                            <Radiolevel className="rediolabel" htmlFor="no1">No</Radiolevel>
                                            <span className="switchFilter1"></span>
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.disclosure_to_third_party.enabled === 'Yes') {
                                                return (<div style={{width: "100%"}}><HtmlEditor content={this.state.disclosure_to_third_party.description} content_type={'disclosure_to_third_party_description'}/></div>);
                                            }
                                        })()
                                    }

                                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                    <ModalNameInputDiv>
                                        <DropzoneApproval onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true} onExitsFilesUpdated={this.onExitsFilesUpdated}/>
                                    </ModalNameInputDiv>

                                    <hr style={{marginTop: "20px", color: "#CCCFD4"}}/>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow-up Tasks</div>
                                            {
                                                (() => {
                                                    // if(this.state.curlane !== "COMP_CONFLICTS_COMPLETE"){
                                                        if(this.state.showfollowupclose){
                                                            if(this.state.curlane !== "COMP_COI_REMOVE"){
                                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddFollowupTaskModal(-1)}><FaPlusCircle /></div>)
                                                            }else{
                                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showalertFollowup}><FaPlusCircle /></div>)
                                                            }
                                                        }
                                                    // }
                                                })()
                                            }
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        {
                                            (() => {
                                                if(this.state.showModal) {
                                                    return(
                                                        <div>
                                                        <CMFollowupTask headerText={this.state.headerFollowupText} taskId={this.state.taskid} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch} EditrefreshTaskValue={this.EditrefreshTaskValue}/>
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if(updatedFollowups.length !== 0) {
                                                    return(
                                                        <OverviewInfoBody>
                                                            <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                                        </OverviewInfoBody>
                                                    )
                                                }else {
                                                    return(
                                                    <OverviewInfoBody>
                                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no Follow-up tasks assigned.</div>
                                                    </OverviewInfoBody>
                                                    );
                                                }
                                            })()
                                        }
                                    </RegDismisOuterContainer>
                                </div>
                            )
                        }
                    })()
                }

            </MRModalBody>
            <MRModalFooter>
                {
                    (() => {
                        if(this.state.removal_readonly){
                            return(<div>
                                {
                                    (()=> {
                                        if(Store.getStoreData('role') !== 'cm'){
                                            return(<MRModalSaveCloseBtn style={{backgroundColor: "#1E3E62", color: "#ffffff", border: "1px solid #1E3E62"}} onClick={this.updateRemovalTask}>Update</MRModalSaveCloseBtn>)
                                        }
                                    })()
                                }
                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                            </div>)
                        }else{
                            if(Store.getStoreData('q_index') >= 0) {
                                if(this.state.curlane === "COMP_CONFLICTS_TEAMSAVE") {
                                    return(<div>
                                        <MRModalNextBtn onClick={this.updateRegisterConflict(1)}>Register Conflict</MRModalNextBtn>
                                        <MRModalSaveCloseBtn onClick={this.updateRegisterConflict(0)}>Save and Close</MRModalSaveCloseBtn>
                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                    </div>);
                                }else if(this.state.curlane === "COMP_CONFLICTS_COMPLETE") {
                                    return(
                                            <div>
                                                {
                                                    (() => {
                                                        if(!this.state.showRemovalReason){
                                                            return(<MRModalNextBtn onClick={this.submitApproveConflict(1)}>Confirm</MRModalNextBtn>)
                                                        }else{
                                                            return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Confirm</MRModalNextBtn>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(removalItems.isRemovalButton){
                                                            if(status !== "Removed"){
                                                                return(<MRModalRemoveBtn onClick={this.openRemoval}>Remove</MRModalRemoveBtn>)
                                                            }
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(!this.state.showRemovalReason){
                                                            return(<div>
                                                                <MRModalSaveCloseBtn onClick={this.submitApproveConflict(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                            </div>)
                                                        }else{
                                                            return(<div>
                                                                <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                }else {
                                    if(this.state.showfollowupclose){
                                    return(
                                        <div>
                                            {
                                                (() => {
                                                    if(!this.state.showRemovalReason){
                                                        return(<MRModalNextBtn onClick={this.submitApproveConflict(1)}>Confirm</MRModalNextBtn>)
                                                    }else{
                                                        return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Confirm</MRModalNextBtn>)
                                                    }
                                                })()
                                            }

                                            {
                                                (() => {
                                                    if(removalItems.isRemovalButton){
                                                        if(status !== "Removed"){
                                                            return(<MRModalRemoveBtn onClick={this.openRemoval}>Remove</MRModalRemoveBtn>)
                                                        }
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if(!this.state.showRemovalReason){
                                                        return(<div>
                                                            <MRModalSaveCloseBtn onClick={this.submitApproveConflict(0)}>Save and Close</MRModalSaveCloseBtn>
                                                            <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                        </div>)
                                                    }else{
                                                        return(<div>
                                                            <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                            <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                        </div>)
                                                    }
                                                })()
                                            }

                                        </div>
                                    )
                                    }
                                }
                            }
                        }
                    })()
                }
            <div style={{clear: "both"}}></div>
            </MRModalFooter>
            {
                (() => {
                    if(this.state.showRemovalReason){
                        return(<div>
                            <MRModalBody>
                                <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Removal</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                <MRModalSelect style={{width: "33%",minWidth: "200px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                <option value="">Select</option>
                                {
                                    removalItems.removalType.map((r, index) => {
                                        return (<option key={index} value={r.reasonName}>{r.reasonName}</option>);
                                    })
                                }
                                </MRModalSelect>
                                {
                                    (() => {
                                        if(this.state.showDescription){
                                            return(
                                                <div>
                                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Description</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                                    <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />
                                                </div>
                                            )
                                        }
                                    })()
                                }
                            </MRModalBody>
                            <MRModalFooter>
                                <MRModalNextBtn onClick={this.submitReasonRemoval}>Confirm</MRModalNextBtn>
                                <MRModalCancelBtn onClick={this.closeRemoval}>Cancel</MRModalCancelBtn>
                                <div style={{clear: "both"}}></div>
                            </MRModalFooter>
                        </div>)
                    }
                })()
            }
        </MRModalContainer>
        {
            (() => {
                if (this.state.showFollowModal !== null) {
                    switch(this.state.showFollowModal) {
                        case 'view_followup_modal' : return <div style={{position:'absolute',width:'100%',marginTop: "80%"}}><FollowupModal closeModal={this.closefollowupModal} /></div>; break;
                    }
                }
            })()
        }
        <AlertBox alertParam={this.state.alert_param}/>
        </div>);
    }
}

export default CMConflictsApprovalModal;
