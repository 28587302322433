import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaStarOfLife} from 'react-icons/fa';
import APICall from './../TAPICall';
import Store from './../../../../Common/Store';
import GMFollowupTask from './GMFollowupTask';
import CSLTable from './../Common/CSLTable';
import Dropzone from './../Common/Dropzone';
import Utils from './../Common/Utils';
import CSLLoader from './../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import {FaCalendarAlt} from 'react-icons/fa';
import CSLTextInput from './../Common/CSLTextInput';
import moment from 'moment';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalRemoveBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #E75C71;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #E75C71;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;

const RegModalNameInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #6d6d6d;
    background-color: #EEEEEE;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;    
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  box-shadow: 0 0 20px #a7a6a6;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 100%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;
const HeaderButton = styled.div`
    color: #ffffff;
    width: 30px;
    height: 29px;
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 22px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const SpacerS = styled.div`
    height: 5px;
`;
const CSLDateCover = styled.div`   
    margin-top: 8px;
    width: 40%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #282828;
    background-color: #ffffff;
    height: 25px;
    padding-top: 2px;
    padding-left: 2px;
    // float: left;
    position: relative;
`;
const ModalContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 20% calc(80% - 15px);
  grid-gap: 5px;
  padding: 10px;
`;
const ModalNameLabelDivFin = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  font-weight: 600;
  color: #1a3552;
`;
const ModalNameInputDivFin = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;
const MRDataContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e7f0f7;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 10px;
`;
const DescriptionInputDiv = styled.div`
  height: auto;
  width: 100%;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;

class GMGiftsApprovalModal extends React.Component
{
    state = {        

        giftStatus: "received",
        recipientlist: [],
        recipientName: "",
        recipientNameAll: "",
        isOtherRecipientName: false,
        donorName: "",
        giftTypeid: 0,
        giftDescription: "",
        giftTypeList: [],
        currency: "",
        minvalue: 0,
        giftTypename: "",

        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        showModal: false,
        review_list: [],
        followupTask: [],        
        ready: false,
        showfollowupclose: true,
        groupcompanyName: "",
        dicloserid: 0,
        showRemovalReason: false,
        showApprovalReason: false,
        removalType: "",
        showDescription: false,
        reasonDescription: "",
        removal_readonly: false,
        header_message: "",
        reasonID: 0,
        module_config: null,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        isLoadedIcon: false,
        giftedDate: new Date(),        
        ischangesFollowup: false,
        assign_data: null,
        isCompletetoUpdate: false        
    };
    
    componentDidMount()
	{
        let index = this.props.q_index === null ? 0 : this.props.q_index;        
        console.log('index in componentDidMount', index);        
        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = this.props.cur_q_modal_data; 
            //console.log('form_data', form_data); 

            let contracts = this.props.contacts;
            let otherObject = {id: "Other", name: "Other"}            

            let removal_readonly = false
            if(form_data.cur_lane === "COMP_GIFTS_REMOVE") {
                removal_readonly =  true;
            }else if(form_data.cur_lane === "COMP_GIFTS_COMPLETE"){
                removal_readonly =  true;
            }

            let giftTypeList = [];
            if('gift_types' in this.props.module_config){
                giftTypeList = this.props.module_config.gift_types;
            } 
            

            let dicloserid = form_data.contact_id;
            let company_contacts = this.props.contacts;        
            let task_json = JSON.parse(form_data.task_json);

            let gifttypename = "";
            giftTypeList.forEach((item) => {
                if (item.giftTypeID === task_json.object_data.headerinfo.gift_typeid) {
                    gifttypename = item.giftTypeName;             
                }
            });            
            let gc_assoc = this.props.gc_assoc;            
            //let gcid = (parseInt(task_json.task_data.group_company_id)%100000)
            let gcid = parseInt(form_data.company_id) < 100000 ? parseInt(this.props.gc_company[0].id) : (parseInt(form_data.company_id)%100000);
            let gcname = "";
            if(gcid in gc_assoc){
                gcname = gc_assoc[gcid];
            }
            //console.log("gcname",gcname)
            let gc_users = [];
            let my_company = null;
            let gc_company = this.props.gc_company;
            let companyUsers = {};
            for(let gc of gc_company) {
                if(gc.id === gcid){
                    my_company = gc;
                    companyUsers = gc.users;
                }
            }

            //new            
            let contactsArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        contactsObject.id = contracts[d];
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);
                    }
                }) 
            }
            let role = this.props.role;
            if(role === "admin_manager" || role === "manager"){
                contactsArray.push(otherObject);   
            }             
            let recipientlist = contactsArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            //new



            console.log('com_roles', this.props.user_roles)
            let com_roles = Store.getStoreData('tmgift_user_roles');
            for(let id of Object.keys(my_company.users)) {
                if (com_roles[id.toString()] !== 'no_access' ) {
                    gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
                }
            }

            // if (task_json.object_data.bin_files.length !== 0) {
            //     task_json.object_data.bin_files.forEach((item) => {
            //         item.uid = "uid" in item ? item.uid : this.genKey(10);
            //     })
            // }

            let header_message = "This Gifts & Hospitality disclosure requires approval. Please review.";
            let module_config = this.props.module_config;
            //console.log('gc_users', gc_users)
            // const {additional_records_of_disclosure} = this.state;
            // additional_records_of_disclosure.enabled = task_json.object_data.headerinfo.additional_records_of_disclosure.enabled
            // additional_records_of_disclosure.description = task_json.object_data.headerinfo.additional_records_of_disclosure.description
            // const {disclosure_to_third_party} = this.state;
            // disclosure_to_third_party.enabled = task_json.object_data.headerinfo.disclosure_to_third_party.enabled
            // disclosure_to_third_party.description = task_json.object_data.headerinfo.disclosure_to_third_party.description
            let isOtherRecipientName = false;
            if(task_json.object_data.headerinfo.check_recipient_value === 'Other'){
                isOtherRecipientName = true;
            }
            let currencyValue = "";
            if('general' in this.props.module_config && 'companyCurrencyDetails' in this.props.module_config.general){
                currencyValue = this.props.module_config.general.companyCurrencyDetails.currency;
            }

            let giftedDate = 'giftedDate' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.giftedDate) : new Date();

            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                recipientlist: recipientlist,
                giftTypeList: giftTypeList,
                currency: currencyValue !== "" ? currencyValue : this.props.currencyDetails.currency,
                giftStatus: task_json.object_data.headerinfo.gift_status,
                donorName: task_json.object_data.headerinfo.donor_name.replace(/&rsquo;&rsquo;/g, '\''),
                recipientName: task_json.object_data.headerinfo.check_recipient_value,
                recipientNameAll: task_json.object_data.headerinfo.recipient_name,
                isOtherRecipientName: isOtherRecipientName,
                minvalue: task_json.object_data.headerinfo.minvalue,
                giftTypeid: task_json.object_data.headerinfo.gift_typeid,
                giftDescription: task_json.object_data.headerinfo.gift_description,
                giftTypename: gifttypename,

                curlane: form_data.cur_lane,
                taskid: form_data.id,
                review_list: gc_users,                
                groupcompanyName: gcname,
                dicloserid: dicloserid,
                removal_readonly: removal_readonly,
                header_message: header_message,
                module_config: module_config,
                cur_files_json: task_json.object_data.bin_files,
                giftedDate,
                assign_data: assign_data
            });
            
                // let command_data = { "command": "listfollowups", parent_id: form_data.id };
                // console.log('followuptasks list command', command_data);
                // let api = new APICall();
                // api.command(command_data, this.showFollowupsTask); 
                this.setState({ready: true});
        }       
    }

    // showFollowupsTask = (result) => {
    //     Store.updateStore('updated_followups', result.result.followuptasks);
    //     console.log('result.result.followuptasks', result.result.followuptasks);        
    //     this.setState({ ready: true });  
    // }

    closeView = () => {        
        this.props.closeUserDialog();            
    }
    handleAdditionalROD = (event) => {
        console.log(event.target.value)
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.enabled = event.target.value;
        if(event.target.value === 'No'){
            additional_records_of_disclosure.description = "";
        }
        this.setState({ additional_records_of_disclosure })        
    }
    handleThiredParty = (event) => {
        console.log(event.target.value)
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.enabled = event.target.value;
        if(event.target.value === 'No'){
            disclosure_to_third_party.description = "";
        }
        this.setState({ disclosure_to_third_party })        
    }    

    handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }
    handleChangeRemoval = (evt) => { 
        console.log("key",evt.target.options.selectedIndex)
        let resultType = this.props.module_config.result_types;
        let curIndex = evt.target.options.selectedIndex;
        let showDescription = false;
        let reasonID = 0
        if(curIndex !== 0){            
            if(resultType[curIndex - 1].isComments === 1){
                showDescription = true;
            }
            reasonID = resultType[curIndex - 1].resultTypeID
        }
        console.log("Sourav111",showDescription)
        this.setState({[evt.target.name]: evt.target.value, showDescription, reasonID});
    }
    handleChangeDTTP = (evt) => {
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.description = evt.target.value;
        this.setState({ disclosure_to_third_party })
    }
    handleChangeAROD = (evt) => {
        const {additional_records_of_disclosure} = this.state;
        //console.log("additional_records_of_disclosure",this.state.additional_records_of_disclosure)
        additional_records_of_disclosure.description = evt.target.value;
        this.setState({ additional_records_of_disclosure })   
    }
    showAddFollowupTaskModal = (index) => (event) => {    
        //event.preventDefault();
        //console.log("Sourav")
        Store.updateStore('ftask_index', -1);
        this.setState({ showModal: true, showfollowupclose: false});
    }
    closeFollowupModal = () => {                   
        this.setState({ showModal: false, showfollowupclose: true });
    }

    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12); 
        let task = {
            "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_GIFTS_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_GIFTS_FOLLOWUP_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_GIFTS_FOLLOWUP", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_GIFTS_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid};
        console.log("task follow-up", postData);
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.processAddTask);
    }
    processAddTask = (result) => {
        alert("The follow-up task has been added.");   
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups); 
    }
    updateFollowups = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        //this.setState({child_task : result.result });
        this.setState({ showModal: false,showfollowupclose: true, isLoadedIcon: false, ischangesFollowup: true });  
    }

    processMyfollowupTaskData = () => {
        var taskdata = this.props.updated_followups;
        console.log("taskdata",taskdata)
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Notes', accessor: 'notes', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status', width: 220, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}
                    ];
        for(let d of taskdata) {           
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let status = "Pending";
            if(d.last_action === 'COMP_GIFTS_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_GIFTS_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = this.props.contacts[taskdata1.cur_assigned_to]
            let notes = tdata[0].note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            
            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';  
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }                   
            let elem = {
                'index' : i, 
                'assign_to' : assigntoName,
                'notes' : notes,
                'due_date' : due_date,                
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    showfollowtaskModal = (row) => (event) => {
        event.preventDefault();
        Store.updateStore('ftask_index', row.index);  
        this.setState({ showModal: true });
    }

    submitApproveGift = (submit_form) => (event) => {
        event.preventDefault();     
        const  module_config = this.props.module_config;        
        if(this.state.recipientName === "") {                
            alert('Please select recipient name.');  
            return;                               
       }
       if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
           alert('Please add name of other recipient.'); 
           return;
       }
       if(this.state.giftStatus === "received" && this.state.donorName === ""){
           alert('Please add donor name.');
           return; 
       }
       if(this.state.giftTypeid.toString() === "0"){
           alert('Please select gift type.'); 
           return;
       }
       if(this.state.giftDescription === ""){
           alert('Please provide a description of the gift/hospitality.');
           return;
       }
       if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {
                    if(item.displayFor === this.state.giftStatus){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                    }
                    if(item.displayFor === this.state.giftStatus){
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                })
            }
            if (fields.length !== 0) {
                console.log("Sourav1")
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ", ";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);
                if (fields.length === 1) {
                    err_msg = err_msg + " field is mandatory. ";
                } else {
                    err_msg = err_msg + " fields are mandatory. ";
                }                    
                alert(err_msg);
                return;
            }      
            if(is_numeric) {
                console.log("Sourav2")
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "Please put the numeric vaule in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "Please put the numeric vaule in the "+ err_msg_new + " fields. ";
                }                    
                alert(err_msg_new);
                return;                
            }      
        } 
       if(parseInt(this.state.minvalue) < parseInt(this.props.module_config.general.companyCurrencyDetails.minvalue) && !window.confirm('This value is less that the minimum value of reportable gifts/hospitality. Do you want to proceed?')){                
        this.setState({cur_gc_id:null,giftStatus: "received",recipientName: "",recipientNameAll: "",donorName: "",giftTypeid: 0,giftDescription: "",})
        return;               
       }
       //else{                
                
                let headerinfo = {};
                headerinfo.gift_status = this.state.giftStatus;
                headerinfo.recipient_name = this.state.recipientNameAll;
                headerinfo.donor_name = this.state.donorName;
                headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                headerinfo.currency = this.state.currency;
                headerinfo.minvalue = this.state.minvalue;
                headerinfo.gift_description = this.state.giftDescription;
                headerinfo.check_recipient_value = this.state.recipientName;
                headerinfo.giftedDate = this.state.giftedDate;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo;
                final_object_data.ref_id = this.state.Ref_ID;
                let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
                let task_json = {};
                if(submit_form === 1) {
                    if(this.state.removalType === ""){
                        alert('You must select a Result Type first.');
                        return;
                    }
                    if(this.state.showDescription && this.state.reasonDescription === ""){            
                        alert('Please provide a reason for approval.');
                        return;            
                    }
                    if (window.confirm('The gift will be marked as approved and the discloser updated. Do you want to continue?')) {

                        final_object_data.headerinfo.approval_type = this.state.removalType;
                        final_object_data.headerinfo.approval_description = this.state.reasonDescription;
                        
                        task_json = {
                            "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), 
                            'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                            "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                            "object_data": final_object_data,
                            "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                            "approval_description": this.state.reasonDescription}
                        };
                    }else{
                        return;
                    }
                }else{
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), 
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE',
                        'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                        "object_data": final_object_data,
                        "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
                    };
                }             
                console.log("task jason", JSON.stringify(task_json));

                let curmytaskdata = this.props.cur_q_modal_data;
                let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
                let from_api = 0
                if('from_api' in curtask_jsonValue.object_data){
                    from_api = curtask_jsonValue.object_data.from_api;
                }
                let postData = {command: "update_gifts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api };
                console.log("postData",postData)
                this.setState({submit_form, isLoadedIcon: true})
                let api = new APICall();
                api.command(postData, this.processUpdateApproveGiftsData);
                     
        //}      
    }

    processUpdateApproveGiftsData = (result) => {
        console.log("result", result)
        if(this.state.submit_form === 1){            
            alert('Gifts & Hospitality Confirmed Successfully');  
            console.log("reasonID",this.state.reasonID);
            let resultType = this.props.module_config.result_types;
            let removalTypes = JSON.parse(JSON.stringify(resultType));
            let changemodule = 0
            removalTypes.forEach((item) => {
                if (item.resultTypeID === this.state.reasonID && item.isDelete !== 1) {
                    item.isDelete = 1; 
                    changemodule = 1               
                }
            });
            console.log("modified submitedShareUserList", removalTypes);
            //console.log("modified submitedShareUserList", removalTypes);
            if(changemodule === 1){
                let {module_config} = this.state;
                module_config.result_types = removalTypes;
                const postData = {command: "save_module_config", module_config: module_config};
                const api = new APICall();
                api.command(postData, this.afterUpdatemoduleconfig);
            }else{
                window.location.reload();
            }          
        }else {
            alert('This Gifts & Hospitality has been saved but not confirmed. You can revisit this later and confirm.');
            window.location.reload();
        }        
        
    }     
    
    openRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: true, removalType: ""});
    }

    openApproveGift = (event) => {
        event.preventDefault();
        this.setState({showApprovalReason: true, removalType: ""});
    }

    closeApproval = (event) => {
        event.preventDefault();
        this.setState({showApprovalReason: false});
    }

    closeRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: false});
    }

    submitReasonRemoval = (event) => {
        event.preventDefault();              
            if(this.state.removalType === ""){
                alert('You must select a Result Type first.');
                return;
            }
            if(this.state.showDescription && this.state.reasonDescription === ""){            
                alert('Please provide a reason for approval.');
                return;            
            }
            if (window.confirm('The gift will be removed from the register and the discloser updated. Do you want to continue?')) {              

            let action_data = {
                task_id: this.state.taskid,
                cur_lane: "COMP_GIFTS_REMOVE",
                assigned_to: 0,
                action: "COMP_GIFTS_REMOVE",
                performed_by: this.props.contactID,
                removal_type:  this.state.removalType,
                removal_description: this.state.reasonDescription
            };
            let form_data = this.props.cur_q_modal_data; 
            let task_json = JSON.parse(form_data.task_json);

                let headerinfo = {};
                headerinfo.gift_status = this.state.giftStatus;
                headerinfo.recipient_name = this.state.recipientNameAll;
                headerinfo.donor_name = this.state.donorName;
                headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
                headerinfo.currency = this.state.currency;
                headerinfo.minvalue = this.state.minvalue;
                headerinfo.gift_description = this.state.giftDescription;
                headerinfo.check_recipient_value = this.state.recipientName;
                headerinfo.giftedDate = this.state.giftedDate;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo;
                final_object_data.ref_id = this.state.Ref_ID;
                final_object_data.removal_type = this.state.removalType;
                final_object_data.removal_description = this.state.reasonDescription;

            task_json['object_data'] = final_object_data
            task_json['action_data'] = action_data           

            let postData = { "command": "removalTask", "SystemID": 1001, task_json: task_json, task_id: this.state.taskid, dicloser_id: this.state.dicloserid };
            console.log("postData",postData);
            let api = new APICall();
            this.setState({isLoadedIcon: true})
            api.command(postData, this.processUpdateRemoveGiftsData);  
        }      
    }

    processUpdateRemoveGiftsData = (result) => {
        console.log("result", result)
        alert('The gift has been removed.');
        console.log("reasonID",this.state.reasonID);
        let resultType = this.props.module_config.result_types;
        let removalTypes = JSON.parse(JSON.stringify(resultType));
        let changemodule = 0
        removalTypes.forEach((item) => {
            if (item.resultTypeID === this.state.reasonID && item.isDelete !== 1) {
                item.isDelete = 1; 
                changemodule = 1               
            }
        });
        console.log("modified submitedShareUserList", removalTypes);
        //console.log("modified submitedShareUserList", removalTypes);
        if(changemodule === 1){
            let {module_config} = this.state;
            module_config.result_types = removalTypes;
            const postData = {command: "save_module_config", module_config: module_config};
            const api = new APICall();
            api.command(postData, this.afterUpdatemoduleconfig);
        }else{
            window.location.reload();
        }
    }

    afterUpdatemoduleconfig = (result) => {
        console.log(result); 
        window.location.reload();
   }

    updateRemovalTask = (event) => {
        event.preventDefault();        
        let header_message = "You are amending and updating a Gifts & Hospitality that has already been submitted.";
        this.setState({removal_readonly: false, isCompletetoUpdate: true, header_message: header_message}) 
    }
    
    showalertFollowup = (event) => {
        event.preventDefault();
        alert("Adding follow-up tasks to a removed Gift is not allowed.")
    }

    filesLoaded = (files) => {
        let contact_id = this.props.contactID;
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    handleChangeRecipient = (evt) => {
        if(evt.target.value != 'Other'){
           this.setState({recipientName: evt.target.value,recipientNameAll: evt.target.value,isOtherRecipientName: false}) 
        }else{
            this.setState({isOtherRecipientName: true, recipientName: evt.target.value, recipientNameAll: ""})
        }
    }

    onChangeGiftedDate = (date) => { 
        this.setState({giftedDate: date});
    }

    generateFieldreadOnly = (data) => {
    	return (
    		<ModalContainerGrid key={data.name}>
    			<ModalNameLabelDivFin>{data.nickname}</ModalNameLabelDivFin>
                <ModalNameInputDivFin>{data.value}</ModalNameInputDivFin>    			
    		</ModalContainerGrid>
    	);
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;    						
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;                            
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);        
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);		
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));		
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}

    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="text" min={0} placeholder="Please enter the numeric value" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)    
        console.log("data_container===>", data_container)    
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {                    
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {                    
                    item.value = event.target.value;
                }
            })
        }	
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.setState({assign_data: assign_data});       
    }

    createDropdownField = (data, data_container) => {
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>                                                
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    render()
    {
        let curmytaskdata = this.props.cur_q_modal_data;
        //console.log("curmytaskdata",curmytaskdata)
        let task_json = JSON.parse(curmytaskdata.task_json);
        const discloserdate_obj = new Date(curmytaskdata.due_date);
        const discloserdate_day = discloserdate_obj.getDate() < 10 ? "0"+discloserdate_obj.getDate().toString() : discloserdate_obj.getDate();
        const discloserdate_month = discloserdate_obj.getMonth()+1 < 10 ? `0${(discloserdate_obj.getMonth() + 1).toString()}` : discloserdate_obj.getMonth()+1;
        const discloserdate_year = discloserdate_obj.getFullYear();
        const discloserdate_date = `${discloserdate_day}/${discloserdate_month}/${discloserdate_year}`;

        const gifteddate_obj = new Date(this.state.giftedDate);
        const gifteddate_day = gifteddate_obj.getDate() < 10 ? "0"+gifteddate_obj.getDate().toString() : gifteddate_obj.getDate();
        const gifteddate_month = gifteddate_obj.getMonth()+1 < 10 ? `0${(gifteddate_obj.getMonth() + 1).toString()}` : gifteddate_obj.getMonth()+1;
        const gifteddate_year = gifteddate_obj.getFullYear();
        const gifteddate_date = `${gifteddate_day}/${gifteddate_month}/${gifteddate_year}`;

        let updatedUserName = "";
        let updateduserDate = "";
        let isUpdate = 0;
        if('updated_by' in task_json.object_data){
            updatedUserName = this.props.contacts[task_json.object_data.updated_by];
            isUpdate = 1;
        }else{
            if(task_json.action_data.length !==0){
                if('performed_by' in task_json.action_data[task_json.action_data.length - 1]){
                    updatedUserName = this.props.contacts[task_json.action_data[task_json.action_data.length - 1].performed_by];
                }                
            }
        }
        console.log("updatedUserName==>", updatedUserName)


        if('updated_at' in task_json.object_data){
            updateduserDate = moment.unix(task_json.object_data.updated_at).format('DD/MM/YYYY')
        }else{
            if(task_json.action_data.length !==0){                
                if('actiondate' in task_json.action_data[task_json.action_data.length - 1]){
                    let actionUnix = moment(task_json.action_data[task_json.action_data.length - 1].actiondate).unix();
                    updateduserDate = moment.unix(actionUnix).format('DD/MM/YYYY')
                }
            }
        }
        let approvalType = "";
        let isApprovalType = 0;
        let approvalDescription = "";
        let isApprovalDescription = 0;
        if('headerinfo' in task_json.object_data && 'approval_type' in task_json.object_data.headerinfo){
            approvalType = task_json.object_data.headerinfo.approval_type;
            isApprovalType = 1;            
        }
        if('headerinfo' in task_json.object_data && 'approval_description' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.approval_description !== ''){
            approvalDescription = task_json.object_data.headerinfo.approval_description;
            isApprovalDescription = 1            
        }

        let status = "";
        if(curmytaskdata.cur_lane === "COMP_GIFTS_REGISTER") {
            status =  "Registered";
        }else if(curmytaskdata.cur_lane === "COMP_GIFTS_COMPLETE") {
            status =  "Confirmed";
        }else if(curmytaskdata.cur_lane === "COMP_GIFTS_REMOVE") {
            status = "Removed"
        }
        let discloserName = this.props.contacts[curmytaskdata.contact_id];
        let discloserEmail = this.props.contactsemail[curmytaskdata.contact_id];

        if('requester_name' in task_json.object_data){
            discloserName = task_json.object_data.requester_name;
        }
        if('email' in task_json.object_data){
            discloserEmail = task_json.object_data.email;
        }     
        let resultType = []   
        let resultTypeValue = this.props.module_config.result_types;        
        for(let d of resultTypeValue){
            if(d.isActive !== 0){
                resultType.push(d);
            }
        }

        let updatedFollowups = this.props.updated_followups; 
        let module_config = this.props.module_config;
        if(!this.state.ready){
            return(<div></div>)
        }
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                } 
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>{this.state.Ref_ID} - Gifts & Hospitality</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        {/* <MRModalSubHeaderText>Fill out the below fields to fully submit your Gifts & Hospitality disclosure.</MRModalSubHeaderText> */}
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader> 
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px"}}>
                            {
                                (() => {
                                    if(this.state.removal_readonly){
                                        return(
                                            // <MRModalBodyInner style={{backgroundColor: "#EDF0F1", border: "1px solid #D6DDDF",color: "#33435E", fontWeight: "bold"}}>                           
                                            //     This Gifts & Hospitality has been submitted.                           
                                            // </MRModalBodyInner>
                                            <MRModalBodyInner style={{backgroundColor: "#E7F0F7", border: "1px solid #D0D0D0",color: "#1A3552", fontWeight: "bold"}}>                           
                                                This entry was updated by {updatedUserName} on {updateduserDate}                           
                                            </MRModalBodyInner>
                                        )
                                    }else{
                                        if(isUpdate === 1){
                                            return(<div><MRModalBodyInner style={{backgroundColor: "#E7F0F7", border: "1px solid #D0D0D0",color: "#1A3552", fontWeight: "bold"}}>                           
                                            This entry was updated by {updatedUserName} on {updateduserDate}                           
                                            </MRModalBodyInner>
                                            <SpacerXL/> 
                                            <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>                           
                                                {this.state.header_message}                           
                                            </MRModalBodyInner>
                                            </div>)
                                        }else{
                                            if(this.state.isCompletetoUpdate){
                                                return(<div><MRModalBodyInner style={{backgroundColor: "#E7F0F7", border: "1px solid #D0D0D0",color: "#1A3552", fontWeight: "bold"}}>                           
                                                This entry was updated by {updatedUserName} on {updateduserDate}                           
                                                </MRModalBodyInner>
                                                <SpacerXL/>
                                                <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>                           
                                                        {this.state.header_message}                           
                                                    </MRModalBodyInner>
                                                </div>)
                                            }else{                                             
                                                return(
                                                    <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>                           
                                                        {this.state.header_message}                           
                                                    </MRModalBodyInner>
                                                )
                                            }
                                        }                                        
                                    }
                                })()
                            } 
                            {
                                (() => {
                                    if(!this.state.removal_readonly){
                                        return(<div>
                                            <SpacerS/>   
                                            <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                            <MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.groupcompanyName}</MRModalLabel>  
                                        </div>)
                                    }
                                })()
                            }
                                          
                            {
                                (() => {
                                    if(this.state.removal_readonly){
                                        return(
                                            <div>
                                                <MRDataContainer>
                                                    <ModalContainerGrid>
                                                        <ModalNameLabelDivFin>Company Name</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.groupcompanyName}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Added Date</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{discloserdate_date}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Gifted Date</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{gifteddate_date}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Status</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{status}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Given/Received</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.giftStatus  === 'received' ? 'Received' : 'Given'}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Type</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.giftTypename}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Value</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.minvalue}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Description</ModalNameLabelDivFin>
                                                        <DescriptionInputDiv>
                                                            <div style={{fontFamily: "'Montserrat', sans-serif",marginTop: "10px",whiteSpace: "pre-line"}}>{this.state.giftDescription}</div> 
                                                        </DescriptionInputDiv>
                                                                                                               
                                                    </ModalContainerGrid>
                                                        {
                                                            (() => {                                                                
                                                                if (this.state.assign_data.additional_fields.length !== 0) {
                                                                    let custom_html = [];
                                                                    this.state.assign_data.additional_fields.forEach((item) => {
                                                                        if(item.displayFor === this.state.giftStatus){
                                                                            const field = this.generateFieldreadOnly(item);
                                                                            custom_html.push(field);
                                                                        }
                                                                    })
                                                                    return custom_html;
                                                                }                                                                
                                                            })()
                                                        }
                                                        
                                                        {
                                                            (() => {
                                                                if(isApprovalType === 1){
                                                                    return(<ModalContainerGrid>
                                                                    <ModalNameLabelDivFin >Approval Reason </ModalNameLabelDivFin>
                                                                    {
                                                                        (() => {
                                                                            if(isApprovalDescription === 1){
                                                                                return(<ModalNameInputDivFin >{approvalType} - {approvalDescription}</ModalNameInputDivFin>)
                                                                            }else{
                                                                                return(<ModalNameInputDivFin >{approvalType}</ModalNameInputDivFin>)
                                                                            }
                                                                        })()
                                                                    }
                                                                                                                                        
                                                                    </ModalContainerGrid> )
                                                                }
                                                            })()
                                                        }  
                                                                                                      
                                                </MRDataContainer>                
                                                {
                                                    (() => {
                                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                                            return(<div>
                                                                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                                                    <ModalNameInputDiv>
                                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                                                    </ModalNameInputDiv>
                                                            </div>)
                                                        }
                                                    })()
                                                }                                                
                                                <RegDismisOuterContainer>
                                                    <OverviewInfoHeader>
                                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>follow-up Tasks</div>
                                                        <div style={{float: "right", color: "#ffffff", fontSize: "17px"}} ><FaPlusCircle /></div>
                                                        <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                                                    {
                                                        (() => {
                                                            if(updatedFollowups.length !== 0) {
                                                                return(
                                                                    <OverviewInfoBody>
                                                                        <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                                                    </OverviewInfoBody>
                                                                )
                                                            }else {
                                                                return(
                                                                <OverviewInfoBody>
                                                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no follow-up tasks assigned.</div>
                                                                </OverviewInfoBody>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                </RegDismisOuterContainer>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>                                                 
                                                <MRModalLabel>Type</MRModalLabel>
                                                <MRModalSelect id='type' name="giftTypeid" onChange={this.handleChange} value={this.state.giftTypeid}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        this.state.giftTypeList.map((gf,index) => {
                                                            return(
                                                                <option key={index} value={gf.giftTypeID}>{gf.giftTypeName}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>
                                                <SpacerS/>
                                                <MRModalLabel>Was the gift received or given?</MRModalLabel>  
                                                <MRModalSelect id='received_given' name="giftStatus" onChange={this.handleChange} value={this.state.giftStatus} >
                                                    <option value="received">Received</option>
                                                    <option value="given">Given</option>   
                                                </MRModalSelect> 
                                                <SpacerS/>
                                                <MRModalLabel>Gifted Date</MRModalLabel>
                                                <CSLDateCover id="gifted_date" style={{display: "inline-block"}}>
                                                    <DatePicker
                                                            onChange={this.onChangeGiftedDate}
                                                            clearIcon={null}
                                                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                            locale={"en-GB"}                                            
                                                            value={this.state.giftedDate}
                                                    />
                                                </CSLDateCover>
                                                <SpacerS/>
                                                <MRModalLabel>Recipient</MRModalLabel>  
                                                <MRModalSelect id='recipient' name="recipientName" onChange={this.handleChangeRecipient} value={this.state.recipientName} >
                                                    <option value="">Select</option>                    
                                                    {
                                                        this.state.recipientlist.map((rc,index) => {
                                                            return(
                                                            <option key={index} value={rc.id}>{rc.name}</option>
                                                            );
                                                        })
                                                    }                      
                                                </MRModalSelect>
                                                {
                                                    (() => {
                                                        if(this.state.isOtherRecipientName){
                                                            return(<div>
                                                                <SpacerS/>
                                                                <MRModalLabel>Add name of other recipient</MRModalLabel>
                                                                <MRModalInput id='recipient_name' type="text" name="recipientNameAll" onChange={this.handleChange} value={this.state.recipientNameAll} />
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.giftStatus === "received"){
                                                            return(<div>
                                                                <SpacerS/>
                                                                <MRModalLabel>Donor</MRModalLabel>
                                                                <MRModalInput id='donor' type="text" placeholder="Enter donor name..." name="donorName" onChange={this.handleChange} value={this.state.donorName} />
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <SpacerS/>
                                                <MRModalLabel>Value</MRModalLabel>
                                                <MRModalInput style={{display: "inline-block",width: "10%"}} value={this.state.currency} readOnly/>
                                                <MRModalInput style={{width: "10%",marginLeft: "30px"}} id='value' type="text" name="minvalue" onChange={this.handleChange} value={this.state.minvalue} />
                                                <SpacerS/>
                                                {/* custom field start */}
                                                {
                                                    (() => {
                                                        if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                                            if (this.state.assign_data.additional_fields.length !== 0) {
                                                                let custom_html = [];
                                                                this.state.assign_data.additional_fields.forEach((item) => {
                                                                    if(item.displayFor === this.state.giftStatus){
                                                                        const field = this.generateField(item, "additional_fields");
                                                                        custom_html.push(field);
                                                                    }
                                                                })
                                                                return custom_html;
                                                            }
                                                        }
                                                    })()
                                                }
                                                {/* custom field end */} 
                                                <SpacerS/>
                                                <MRModalLabel style={{marginTop: "30px"}}>Description</MRModalLabel> 
                                                <MRModalLabel style={{fontWeight: "100",fontSize: "13px",color: "#919292"}}>Please provide a description of the gift/hospitality</MRModalLabel>                            
                                                <MRModalTextarea id='description' rows="6" name="giftDescription" value={this.state.giftDescription} onChange={this.handleChange} />
                                                <SpacerS/>                                                
                                                {
                                                    (() => {
                                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                                            return(<div>
                                                                    <MRModalLabel>Attachments</MRModalLabel>
                                                                    <ModalNameInputDiv>
                                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                                                    </ModalNameInputDiv>
                                                            </div>)
                                                        }
                                                    })()
                                                }                                                                                               
                                                <hr style={{marginTop: "20px", color: "#CCCFD4"}}/>
                                                <RegDismisOuterContainer>
                                                    <OverviewInfoHeader>
                                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>follow-up Tasks</div>
                                                        {
                                                            (() => {                                                    
                                                                    if(this.state.showfollowupclose){
                                                                        if(this.state.curlane !== "COMP_GIFTS_REMOVE"){
                                                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddFollowupTaskModal(-1)}><FaPlusCircle /></div>)
                                                                        }else{
                                                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showalertFollowup}><FaPlusCircle /></div>)
                                                                        }
                                                                    }
                                                                // }
                                                            })()
                                                        }                                                        
                                                        <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                                                    {
                                                        (() => {
                                                            if(this.state.showModal) {
                                                                return(
                                                                    <div>                                    
                                                                    <GMFollowupTask followuptaskvalue={this.state.followupTask} taskId={this.state.taskid} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch}/>
                                                                    </div>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(updatedFollowups.length !== 0) {
                                                                return(
                                                                    <OverviewInfoBody>
                                                                        <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                                                    </OverviewInfoBody>
                                                                )
                                                            }else {
                                                                return(
                                                                <OverviewInfoBody>
                                                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no follow-up tasks assigned.</div>
                                                                </OverviewInfoBody>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                </RegDismisOuterContainer>
                                            </div>
                                        )
                                    }
                                })()
                            } 
                        </div>
                    </MRModalBody>
                    <MRModalFooter style={{backgroundColor: this.state.showApprovalReason || this.state.showRemovalReason ? "#F3F7FB" : "white", borderTop: this.state.showApprovalReason || this.state.showRemovalReason ? "none" : "1px solid #DFDFDF"}}>
                        {
                            (() => {
                                console.log("this.state.showRemovalReason===>", this.state.showApprovalReason)
                                if(this.state.removal_readonly){                            
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                        {
                                            (()=> {
                                                if(this.props.role !== 'gm'){
                                                    return(<MRModalSaveCloseBtn onClick={this.updateRemovalTask}>Update</MRModalSaveCloseBtn>)
                                                }
                                            })()
                                        }                                                                                                        
                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                    </div>)
                                }else{
                                    if(this.props.q_index >= 0) {
                                        if(this.state.curlane === "COMP_GIFTS_TEAMSAVE") {
                                            return(<div style={{position: "relative",padding: "17px"}}>
                                                <MRModalNextBtn >Submit</MRModalNextBtn>
                                                <MRModalSaveCloseBtn >Save and Close</MRModalSaveCloseBtn>                                    
                                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                            </div>);
                                        }else if(this.state.curlane === "COMP_GIFTS_COMPLETE") {
                                            return(                                    
                                                    <div style={{position: "relative",padding: "17px"}}>
                                                        {
                                                            (() => {
                                                                if(!this.state.showRemovalReason){
                                                                    return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                }else{
                                                                    return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                }
                                                            })()
                                                        }    
                                                        {
                                                            (() => {                                                        
                                                                    if(status !== "Removed"){
                                                                        if(!this.state.showApprovalReason){
                                                                            return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                        }else{
                                                                            return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                        }
                                                                    }
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                    return(<div>
                                                                        <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>                                                                                                          
                                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                                }else{
                                                                    return(<div>
                                                                        <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>                                                                                                          
                                                                        <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                                }                      
                                                            })()
                                                        }                                   
                                                    </div>
                                                )                               
                                        }else {
                                            if(this.state.showfollowupclose){
                                            return(
                                                <div style={{position: "relative",padding: "17px"}}>
                                                    {
                                                        (() => {
                                                            if(!this.state.showRemovalReason){
                                                                return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                            }else{
                                                                return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                            }
                                                        })()
                                                    }
                                                    
                                                    {
                                                        (() => {                                                    
                                                                if(status !== "Removed"){
                                                                    if(!this.state.showApprovalReason){
                                                                        return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                    }else{
                                                                        return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                    }
                                                                }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                return(<div>
                                                                    <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>                                                                                                          
                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                </div>)
                                                            }else{
                                                                return(<div>
                                                                    <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>                                                                                                          
                                                                    <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                </div>)
                                                            }                      
                                                        })()
                                                    }                     
                                                                                            
                                                </div>
                                            )
                                            }
                                        }                            
                                    }
                                }
                            })()
                        }
                    <div style={{clear: "both"}}></div>
                    </MRModalFooter>
            {
                (() => {
                    if(this.state.showRemovalReason){
                        return(<div>
                            <MRModalBody>
                                <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                <div style={{clear: "both"}}></div>
                                <div style={{position: "relative",padding: "35px 35px"}}>
                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Result</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                    <MRModalSelect style={{width: "33%",minWidth: "200px", marginLeft: "10px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                    <option value="">Select</option>
                                    {
                                        resultType.map((r, index) => {
                                            return (<option key={index} value={r.resultTypeName}>{r.resultTypeName}</option>);
                                        })
                                    }                    
                                    </MRModalSelect>
                                    {
                                        (() => {
                                            if(this.state.showDescription){
                                                return(
                                                    <div>
                                                        <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Rejection</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>                
                                                        <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />            
                                                    </div>
                                                )
                                            }
                                        })()
                                    } 
                                </div>
                            </MRModalBody>
                            <MRModalFooter>
                                <div style={{position: "relative",padding: "17px"}}>
                                    <MRModalNextBtn onClick={this.submitReasonRemoval}>Confirm</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeRemoval}>Cancel</MRModalCancelBtn>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </MRModalFooter>
                        </div>)
                    }
                })()
            } 
            {
                (() => {
                    if(this.state.showApprovalReason){
                        return(<div>
                            <MRModalBody>
                                <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                <div style={{clear: "both"}}></div>
                                <div style={{position: "relative",padding: "35px 35px"}}>
                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Result</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                    <MRModalSelect style={{width: "33%",minWidth: "200px", marginLeft: "10px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                    <option value="">Select</option>
                                    {
                                        resultType.map((r, index) => {
                                            return (<option key={index} value={r.resultTypeName}>{r.resultTypeName}</option>);
                                        })
                                    }                    
                                    </MRModalSelect>
                                    {
                                        (() => {
                                            if(this.state.showDescription){
                                                return(
                                                    <div>
                                                        <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Approval</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>                
                                                        <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />            
                                                    </div>
                                                )
                                            }
                                        })()
                                    } 
                                </div>
                            </MRModalBody>
                            <MRModalFooter>
                                <div style={{position: "relative",padding: "17px"}}>
                                    <MRModalNextBtn onClick={this.submitApproveGift(1)}>Confirm</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeApproval}>Cancel</MRModalCancelBtn>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </MRModalFooter>
                        </div>)
                    }
                })()
            }              
        </MRModalContainer>
        </div>);
    }
}

export default GMGiftsApprovalModal;