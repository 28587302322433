import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import DatePicker from 'react-date-picker';
import '../../App.css';
import BMBreachOfRulesSection from './BMBreachOfRulesSection';
import BMConductRulesBreachSection from './BMConductRulesBreachSection';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';
import Utils from '../../Common/Utils.js';
import moment from 'moment';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    //height: auto;
    width: 80%;
    //width: 45%;
    position: absolute;
    //top: 38px;
    z-index: 1000;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    //margin-left: 25%;
    margin-left: 10%;
`;
const MRModalHeader = styled.div`
    background-color: #F6F9FD;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 90px;    
    width: calc(100% - 0px);
    border-width: 1px 1px 1px 10px;    
    //border-left: 9px solid #04ADA8; 
    //border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 30px;
    margin-left: 35px;  
    color: #1A3552;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F6F9FD;
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #1a3552;
    font-weight: 600;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
    opacity: 0.58;
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    color: #758597;      
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #758597;   
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;
    padding-left: 5px;
    display:inline;
`;
const ModalNameLabelDivReference1 = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #212121;
    display:inline;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;

class BMAskBreachModal extends React.Component
{
    state = {
        ready: false,
        breachType: "breach_of_rules",
        isreportable: 1,
        breach_binFiles: [],
        breach_cur_files_json: null,
        finimp_binFiles: [],
        finimp_cur_files_json: null,
        evdence_binFiles: [],
        evdence_cur_files_json: null,
        breachDetails: "",
        breachDate: new Date(),
        reportedDate: new Date(),
        regulatorID: "",
        conductRuleUserID: "",
        //new
        incident_title: "",
        incidentType_id: "",
        incident_types: [],
        isinternal_external_incident: 2,//(1 = internal, 0 = External, 2 =  default)
        business_areas: [], // department
        department_id: "",
        third_party_details: "",
        impact_type: "",
        estimated_loss_curreny: "GBP",
        estimated_loss_value: "",
        actual_loss_curreny: "GBP",
        actual_loss_value: "",
        estimated_third_curreny: "GBP",
        estimated_third_value: "",
        actual_third_curreny: "GBP",
        actual_third_value: "",
        fin_impact_cal_method: "",
        com_paid_date: '',
        regulator_types: [],
        rule_reference: "",
        istrading_error: 2,
        fpp_name: "",
        groupcompanyName: "",
        dateofDetection: new Date(),
        //new

        smcrRoleID: "",
        detailsofinvestigation: "",
        disciplinaryAction: "no",
        disciplinaryAction_binFiles: [],
        disciplinaryAction_cur_files_json: null,
        assign_data: null,

        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        companyID: 0,
        due_date: '1970-01-01',
        is_disabled: false,
        isdesable: false,
        Ref_ID: null,
        curlane: null,
        taskid: null,
        alert_param: null,
        isLoadedIcon: false,

        reasonforbreach: "",
        reasonforbreachlist: [],
        breachimpact: "",
        breachimpactlist: [],
        riskcategorylist: [],
        riskcategorylistnew: [],
        selectedRiskCategory: [],
        ownershipresolution: "",
        isclear: false,
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index ====>', index);
        let module_config = Store.getStoreData('module_config');
        let custom_terminology_fields = [];
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                module_config.custom_fields.additional_fields.forEach((item) => {
                    let copy_item = JSON.parse(JSON.stringify(item));
                    copy_item.value = item.type === "Calendar" ? new Date() : "";
                    copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                    additional_fields.push(copy_item);
                })
            }
        }

        let gc_company = Store.getStoreData('gc_company');
        let gc_companies = Store.getStoreData('gc_companies');
        let cur_gc_id = 0;
        let is_gc = false;
        // console.log("company",gc_company)
        let gcs = [];
        for(let g in gc_company){
            if(gc_company[g].is_active === 1){
                gcs.push(gc_company[g])
            }
        }

        let form_data = {};
        let assign_data = {}

        //new
        let incident_types = [];
        if('incident_types' in Store.getStoreData('module_config')){
            incident_types = Store.getStoreData('module_config').incident_types;
        }
        let update_incident_types = []
        for(let k of incident_types){
            if(k.isActive === 1){
                update_incident_types.push(k);
            }
        }
        update_incident_types = update_incident_types.sort(function(a,b) {return (a.incidentTypeName > b.incidentTypeName) ? 1 : ((b.incidentTypeName > a.incidentTypeName) ? -1 : 0);} );


        let business_areas = [];
        if('business_areas' in Store.getStoreData('module_config')){
            business_areas = Store.getStoreData('module_config').business_areas;
        }
        let update_business_areas = [];
        for(let k of business_areas){
            if(k.isActive === 1){
                update_business_areas.push(k);
            }
        }
        let regulator_types = [];
        if('regulator_types' in Store.getStoreData('module_config')){
            regulator_types = Store.getStoreData('module_config').regulator_types;
        }
        let update_regulator_types = [];
        for(let k of regulator_types){
            if(k.isActive === 1){
                update_regulator_types.push(k);
            }
        }

        let reasonforbreachlist = [];
        if('reason_for_Breach' in Store.getStoreData('module_config') && Store.getStoreData('module_config').reason_for_Breach.length !== 0){
            Store.getStoreData('module_config').reason_for_Breach.forEach((item) => {
                if(item.isActive === 1){
                    reasonforbreachlist.push(item);
                }
            })
        }

        let breachimpactlist = [];
        if('breach_impact' in Store.getStoreData('module_config') && Store.getStoreData('module_config').breach_impact.length !== 0){
            Store.getStoreData('module_config').breach_impact.forEach((item) => {
                if(item.isActive === 1){
                    breachimpactlist.push(item);
                }
            })
        }

        let riskcategorylist = [];
        let riskcategorylistnew = [];
        if('risk_triggers' in Store.getStoreData('module_config') && Store.getStoreData('module_config').risk_triggers.length !== 0)
		Store.getStoreData('module_config').risk_triggers.forEach((item) => {
            if(item.isActive === 1){
                riskcategorylist.push({id: item.riskTriggerID, label: item.riskTriggerName});
                riskcategorylistnew.push(item);
            }
        })

        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);

            let curgc_id = task_json.task_data.group_company_id;
            let sampleNumber = curgc_id;
            let lastDigitcurgc_id = sampleNumber % 100000;
            console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
            let gc_company = Store.getStoreData('gc_company');
            let is_gc = false;
            for(let gc of gc_company) {
                if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                    is_gc = gc.is_gc;
                    console.log("gc.id", gc.id);
                }
            }
            let cur_gc_id = parseInt(lastDigitcurgc_id)
            let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
            let due_date = new Date(form_data.due_date);

            let isdesable = false;
            if(form_data.cur_lane !== "COMP_BREACH_USERSAVE") {
                isdesable = true;
            }

            let breach_date = new Date(task_json.object_data.headerinfo.breach_date);
            let dateofDetection = 'dateofDetection' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.dateofDetection) : new Date();
            let reported_date = new Date(task_json.object_data.headerinfo.reported_date);

            assign_data.custom_terminology_fields = custom_terminology_fields;
            // assign_data.additional_fields = additional_fields;
            //assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
            let add_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
            let update_add_fields = [];
            for(let d of add_fields){
                if('displayin' in d){
                    // if(task_json.object_data.headerinfo.breach_type === 'breach_of_rules' && (d.displayin === 'All Records' || d.displayin === 'Incident Record only' || d.displayin === 'Manager Screen Only')){
                    if(task_json.object_data.headerinfo.breach_type === 'breach_of_rules' && (d.displayin === 'All Records' || d.displayin === 'Incident Record only')){
                        update_add_fields.push(d)
                    }
                    // if(task_json.object_data.headerinfo.breach_type === 'conduct_rules_breach' && (d.displayin === 'All Records' || d.displayin === 'Conduct Breach only' || d.displayin === 'Manager Screen Only')){
                    if(task_json.object_data.headerinfo.breach_type === 'conduct_rules_breach' && (d.displayin === 'All Records' || d.displayin === 'Conduct Breach only')){
                        update_add_fields.push(d)
                    }
                }else{
                    update_add_fields.push(d);
                }
            }
            assign_data.additional_fields = update_add_fields;
            console.log("assign_data",assign_data)

            let breach_cur_files_json = []
            let finimp_cur_files_json = []
            let evdence_cur_files_json = []
            let disciplinaryAction_cur_files_json = []

            if('bin_files' in  task_json.object_data) {
				task_json.object_data.bin_files.forEach((item) => {
                    if("type" in item && item.type === 'breach_resource'){
                        breach_cur_files_json.push(item);
                    }else if("type" in item && item.type === 'evidence_resource'){
						evdence_cur_files_json.push(item);
					}else if("type" in item && item.type === 'finimp_resource'){
						finimp_cur_files_json.push(item);
					}else{
						disciplinaryAction_cur_files_json.push(item);
					}
				});
			}

            //new
            let incident_title = 'incident_title' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incident_title : "";
            let incidentType_id = 'incidentType_id' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.incidentType_id : "";
            let isinternal_external_incident = 'isinternal_external_incident' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.isinternal_external_incident : 2;
            let department_id = 'department_id' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.department_id : "";
            let third_party_details = 'third_party_details' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.third_party_details : "";
            let impact_type = 'impact_type' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.impact_type : "";
            let estimated_loss_curreny = 'est_los_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_cur : "GBP";
            let estimated_loss_value = 'est_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_los_val : "";
            let actual_loss_curreny = 'act_los_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_cur : "GBP";
            let actual_loss_value = 'act_los_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_los_val : "";
            let estimated_third_curreny = 'est_thi_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_cur : "GBP";
            let estimated_third_value = 'est_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.est_thi_val : "";
            let actual_third_curreny = 'act_thi_cur' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_cur : "GBP";
            let actual_third_value = 'act_thi_val' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.act_thi_val : "";
            let fin_impact_cal_method = 'fin_impact_cal_method' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fin_impact_cal_method : "";
            let com_paid_date = 'com_paid_date' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.com_paid_date : new Date();
            let rule_reference = 'rule_reference' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.rule_reference : "";
            let istrading_error = 'istrading_error' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.istrading_error : 2;
            let fpp_name = 'fpp_name' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.fpp_name : "";

            let qtext = task_json.object_data.headerinfo.breach_deatils
            if(Utils.isBase64(task_json.object_data.headerinfo.breach_deatils)){
                qtext = window.decodeURIComponent(atob(qtext))
            }

            let qtext_third_party_details = third_party_details;
            if(Utils.isBase64(third_party_details)){
                qtext_third_party_details = window.decodeURIComponent(atob(qtext_third_party_details))
            }

            let gc_assoc = Store.getStoreData('gc_assoc');
            let gcid = parseInt(form_data.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(form_data.company_id)%100000);
            let gcname = "";
            if(gcid in gc_assoc){
                gcname = gc_assoc[gcid];
            }

            let reasonforbreach = ""
            if('reasonforbreach' in task_json.object_data.headerinfo){
                reasonforbreach = task_json.object_data.headerinfo.reasonforbreach
            }
            let breachimpact = ""
            if('breachimpact' in task_json.object_data.headerinfo){
                breachimpact = task_json.object_data.headerinfo.breachimpact
            }
            let ownershipresolution = "";
            if('ownershipresolution' in task_json.object_data.headerinfo){
                ownershipresolution = task_json.object_data.headerinfo.ownershipresolution
            }
            let selectedRiskCategory = []
            if('selectedRiskCategory' in task_json.object_data.headerinfo){
                selectedRiskCategory = task_json.object_data.headerinfo.selectedRiskCategory
            }

            reasonforbreachlist.sort(function(a,b) {return (a.reasonforBreachName > b.reasonforBreachName) ? 1 : ((b.reasonforBreachName > a.reasonforBreachName) ? -1 : 0);} );
            breachimpactlist.sort(function(a,b) {return (a.breachImpactName > b.breachImpactName) ? 1 : ((b.breachImpactName > a.breachImpactName) ? -1 : 0);} );
            update_business_areas.sort(function(a,b) {return (a.businessAreaName > b.businessAreaName) ? 1 : ((b.businessAreaName > a.businessAreaName) ? -1 : 0);} );

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                breachType: task_json.object_data.headerinfo.breach_type,
                breachDetails: qtext,
                breachDate: breach_date,
                dateofDetection,
                reportedDate: reported_date,
                isreportable: parseInt(task_json.object_data.headerinfo.is_reportability),
                regulatorID: parseInt(task_json.object_data.headerinfo.regulator_id),
                breach_cur_files_json: breach_cur_files_json,
                finimp_cur_files_json: finimp_cur_files_json,
                evdence_cur_files_json: evdence_cur_files_json,
                disciplinaryAction_cur_files_json : disciplinaryAction_cur_files_json,
                assign_data: assign_data,
                conductRuleUserID: task_json.object_data.headerinfo.conduct_rule_user_id,
                smcrRoleID: task_json.object_data.headerinfo.smcr_role_id,
                disciplinaryAction: task_json.object_data.headerinfo.disciplinary_action,
                detailsofinvestigation: task_json.object_data.headerinfo.details_of_investigation,
                isdesable: isdesable,
                rowindex: index,
                taskid: form_data.id,
                curlane: form_data.cur_lane,
                gc_companies: gc_companies, gc_company: gcs, ready: true, cur_gc_id: lastDigitcurgc_id, is_gc: is_gc,
                companyID: companyID, due_date: due_date,
                //new
                incident_title: incident_title,
                incident_types: update_incident_types,
                business_areas: update_business_areas,
                regulator_types: update_regulator_types,
                incidentType_id: incidentType_id,
                isinternal_external_incident,
                department_id,
                third_party_details: qtext_third_party_details,
                impact_type,
                estimated_loss_curreny,
                estimated_loss_value,
                actual_loss_curreny,
                actual_loss_value,
                estimated_third_curreny,
                estimated_third_value,
                actual_third_curreny,
                actual_third_value,
                fin_impact_cal_method,
                com_paid_date,
                rule_reference,
                istrading_error,
                fpp_name,
                groupcompanyName: gcname,

                reasonforbreachlist: reasonforbreachlist,
                reasonforbreach: reasonforbreach,
                breachimpactlist: breachimpactlist,
                breachimpact: breachimpact,
                ownershipresolution: ownershipresolution,
                riskcategorylist: riskcategorylist,
                riskcategorylistnew: riskcategorylistnew,
                selectedRiskCategory: selectedRiskCategory,
                });

        }else{

            let gc_company = gcs;
            let userrole = Store.getStoreData('role');
            let update_gc_company = [];
            if(userrole === 'bm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_breach' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_breach === false){
                for(let gc of gc_company) {
                    if(parseInt(gc.id) !== parseInt(Store.getStoreData('curCompanyID'))){
                        update_gc_company.push(gc)
                    }
                }
            }else{
                update_gc_company = gc_company;
            }
            if(!Store.getStoreData('has_gc')){
                cur_gc_id = update_gc_company[0].id;
            }else if(Store.getStoreData('has_gc') && update_gc_company.length === 1){
                cur_gc_id = update_gc_company[0].id;
            }

            console.log("Sourav=====>",cur_gc_id);
            let due_date = new Date();
            assign_data.custom_terminology_fields = custom_terminology_fields;
            //assign_data.additional_fields = additional_fields;
            let add_fields = additional_fields;
            let update_add_fields = [];
            for(let d of add_fields){
                if('displayin' in d){
                    // if(this.state.breachType === 'breach_of_rules' && (d.displayin === 'All Records' || d.displayin === 'Incident Record only' || d.displayin === 'Manager Screen Only')){
                    if(this.state.breachType === 'breach_of_rules' && (d.displayin === 'All Records' || d.displayin === 'Incident Record only')){
                        if('type' in d && d.type === 'Dropdown'){
                            d.value = "";
                            update_add_fields.push(d)
                        }else{
                            update_add_fields.push(d)
                        }
                    }
                }else{
                    if('type' in d && d.type === 'Dropdown'){
                        d.value = "";
                        update_add_fields.push(d)
                    }else{
                        update_add_fields.push(d)
                    }
                }
            }
            assign_data.additional_fields = update_add_fields;
            console.log("assign_data",assign_data)

            let estimated_loss_curreny = "GBP";
            let actual_loss_curreny = "GBP";
            let estimated_third_curreny = "GBP";
            let actual_third_curreny = "GBP";

            if('financial_impact' in Store.getStoreData('module_config') && 'client_currency' in Store.getStoreData('module_config').financial_impact){
                estimated_loss_curreny = Store.getStoreData('module_config').financial_impact.client_currency;
                actual_loss_curreny = Store.getStoreData('module_config').financial_impact.client_currency;
                estimated_third_curreny = Store.getStoreData('module_config').financial_impact.client_currency;
                actual_third_curreny = Store.getStoreData('module_config').financial_impact.client_currency;
            }

            reasonforbreachlist.sort(function(a,b) {return (a.reasonforBreachName > b.reasonforBreachName) ? 1 : ((b.reasonforBreachName > a.reasonforBreachName) ? -1 : 0);} );
            breachimpactlist.sort(function(a,b) {return (a.breachImpactName > b.breachImpactName) ? 1 : ((b.breachImpactName > a.breachImpactName) ? -1 : 0);} );
            update_business_areas.sort(function(a,b) {return (a.businessAreaName > b.businessAreaName) ? 1 : ((b.businessAreaName > a.businessAreaName) ? -1 : 0);} );

            this.setState({ready: true, assign_data: assign_data,
                Ref_ID: "Not Yet Generated",
                gc_company: update_gc_company,
                gc_companies: gc_companies,
                companyID: cur_gc_id,
                cur_gc_id: cur_gc_id,
                is_gc: is_gc,
                due_date: due_date,
                rowindex: index,
                incident_types: update_incident_types,
                business_areas: update_business_areas,
                regulator_types: update_regulator_types,

                reasonforbreachlist: reasonforbreachlist,
                breachimpactlist: breachimpactlist,
                riskcategorylist: riskcategorylist,
                riskcategorylistnew: riskcategorylistnew,
                estimated_loss_curreny,
                actual_loss_curreny,
                estimated_third_curreny,
                actual_third_curreny
            })
        }
    }

    closeView = () => {
        // if(Store.getStoreData('isreferenceUrl') === true){
        //     this.props.closeModal();
        // } else {
        //     this.props.closeModal();
        // }

        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = process.env.REACT_APP_BREACH_URL;
            }else{
                this.props.closeTaskModal();
            }
        } else {
            this.props.closeModal();
        }
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    handleChangeIncidentCategory = (evt) => {
        let module_config = Store.getStoreData('module_config');
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                module_config.custom_fields.additional_fields.forEach((item) => {
                    let copy_item = JSON.parse(JSON.stringify(item));
                    copy_item.value = item.type === "Calendar" ? new Date() : "";
                    copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                    additional_fields.push(copy_item);
                })
            }
        }
        let add_fields = additional_fields;
        let update_add_fields = [];
        for(let d of add_fields){
            if('displayin' in d){
                if(evt.target.value === 'breach_of_rules' && (d.displayin === 'All Records' || d.displayin === 'Incident Record only')){
                    update_add_fields.push(d)
                }
                if(evt.target.value === 'conduct_rules_breach' && (d.displayin === 'All Records' || d.displayin === 'Conduct Breach only')){
                    update_add_fields.push(d)
                }
            }else{
                update_add_fields.push(d);
            }
        }
        let assign_data = {};
        assign_data.additional_fields = update_add_fields;
        console.log("assign_data",assign_data)
        this.setState({[evt.target.name]: evt.target.value, assign_data: assign_data});
    }

    handleChangeReportable = (ischeckValue) =>{
        this.setState({isreportable: ischeckValue, regulatorID: ""})
    }

    handleChangeExIntIncd = (ischeckValue) =>{
        this.setState({isinternal_external_incident: ischeckValue, department_id: ""})
    }
    handlenaSectionIncludedDis = (ischeckValue) =>{
        this.setState({disciplinaryAction: ischeckValue})
    }
    handleChangeTradingError = (ischeckValue) =>{
        this.setState({istrading_error: ischeckValue})
    }

    onChangeBreachDate = (date) => {
        let brDate = moment(date).unix();
        let decDate = moment(this.state.dateofDetection).unix();
        if(decDate <= brDate){
            console.log("True")
            this.setState({dateofDetection: date});
        }
        this.setState({breachDate: date});
    }
    onChangeDateofDetection = (date) => {
        this.setState({dateofDetection: date});
    }
    ChangePaidDate = (date) => {
        this.setState({com_paid_date: date});
    }

    onChangeReportedDate = (date) => {
        //this.props.ChangeReportedDate(date)
        this.setState({reportedDate: date});
    }

    checkvalidation = (result, stack) => {
        //console.log(result,stack)
        this.setState({alert_param: null})
        if(!result){
            //console.log("Sourav")
            this.submitRegisterBreach(stack.submit_form)
        }
    }

    submitRegisterBreach_validation = (submit_form) => (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        const  module_config = Store.getStoreData('module_config');
        if(submit_form === 1) {
            if(parseInt(this.state.cur_gc_id) === 0){
                problems = true
                message += 'Please select a Company. '
            }
            if(this.state.breachType === 'breach_of_rules'){
                if(this.state.conductRuleUserID === ""){
                    problems = true
                    message += 'Please select a individual reporting the incident. '
                }
                if(this.state.isinternal_external_incident === 2){
                    problems = true
                    message += 'Please select the incident internal or external. '
                }
                if(this.state.isinternal_external_incident === 1 && this.state.business_areas.length !== 0 && this.state.department_id === ""){
                    problems = true
                    message += 'Please select the Department. '
                }
                if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true && this.state.impact_type === ''){
                    problems = true
                    message += 'Please select Impact Type. '
                }
                if(this.state.isreportable === 1 && this.state.regulatorID === ""){
                    problems = true
                    message += 'Please select a Regulator. '
                }
                if (module_config.custom_fields.enabled) {
                    let fields = [];
                    if (module_config.custom_fields.additional_fields.length !== 0) {
                        this.state.assign_data.additional_fields.forEach((item) => {
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                        })
                    }
                    if (fields.length !== 0) {
                        let err_msg = "";
                        fields.forEach((item) => {
                            err_msg = err_msg + item + ", ";
                        })
                        err_msg = err_msg.substring(0, err_msg.length - 2);
                        if (fields.length === 1) {
                            err_msg = err_msg + " field is mandatory. ";
                        } else {
                            err_msg = err_msg + " fields are mandatory. ";
                        }
                        problems = true
                        message += err_msg
                    }
                }
            }else{
                if(this.state.conductRuleUserID === ""){
                    problems = true
                    message += 'Please select a individual reporting the incident. '
                }
                if(this.state.isinternal_external_incident === 2){
                    problems = true
                    message += 'Please select the incident internal or external. '
                }
                if(this.state.isinternal_external_incident === 1 && this.state.business_areas.length !== 0 && this.state.department_id === ""){
                    problems = true
                    message += 'Please select the Department. '
                }
                if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true && this.state.impact_type === ''){
                    problems = true
                    message += 'Please select Impact Type. '
                }
                if(this.state.isreportable === 1 && this.state.regulatorID === ""){
                    problems = true
                    message += 'Please select a Regulator. '
                }
                if (module_config.custom_fields.enabled) {
                    let fields = [];
                    if (module_config.custom_fields.additional_fields.length !== 0) {
                        this.state.assign_data.additional_fields.forEach((item) => {
                            if (item.required === true && item.value === "") {
                                fields.push(item.nickname);
                            }
                        })
                    }
                    if (fields.length !== 0) {
                        let err_msg = "";
                        fields.forEach((item) => {
                            err_msg = err_msg + item + ", ";
                        })
                        err_msg = err_msg.substring(0, err_msg.length - 2);
                        if (fields.length === 1) {
                            err_msg = err_msg + " field is mandatory. ";
                        } else {
                            err_msg = err_msg + " fields are mandatory. ";
                        }
                        problems = true
                        message += err_msg
                    }
                }
                if(this.state.disciplinaryAction === "yes" && this.state.detailsofinvestigation === ""){
                    problems = true
                    message += 'Please provide Details of Investigation. '
                }
            }
            if(Store.getStoreData('module_config').general.form_customisation_root_cause === '' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form'){
                if(this.state.reasonforbreach === ""){
                    problems = true
                    message += 'Please select the root cause. '
                }
            }
            if(Store.getStoreData('module_config').general.form_customisation_incident_impact === '' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form'){
                if(this.state.breachimpact === ""){
                    problems = true
                    message += 'Please select the Incident Impact. '
                }
            }
            if(Store.getStoreData('module_config').general.form_customisation_resolution_owner === '' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form'){
                if(this.state.ownershipresolution === ""){
                    problems = true
                    message += 'Please select the Ownership of Resolution. '
                }
            }
            if(Store.getStoreData('module_config').general.form_customisation_risk_category === '' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form'){
                if(this.state.selectedRiskCategory.length === 0){
                    problems = true
                    message += 'Please select the Risk Category. '
                }
            }
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkvalidation, stack: {submit_form: submit_form}}

            this.setState({alert_param: alert_param})
        } else {
            this.checkvalidation(false, {submit_form: submit_form})
        }
    }

    submitRegisterBreach = (submit_form) => {
        //event.preventDefault();
        let company_id = this.state.companyID;
        if(submit_form === 1) {
            console.log("sourav")

            let incidentType_name = "";
            let incident_types = this.state.incident_types
            for(let k of incident_types){
                if(k.incidentTypeID.toString() === this.state.incidentType_id){
                    incidentType_name = k.incidentTypeName
                }
            }

            let department_name = "";
            let business_areas = this.state.business_areas
            for(let k of business_areas){
                if(k.businessAreaID.toString() === this.state.department_id){
                    department_name = k.businessAreaName
                }
            }

            let incedentDetails = Store.getStoreData('incident_details');
            if(incedentDetails === null){
                incedentDetails = this.state.breachDetails;
            }
            let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

            let thirdPartyDetails = Store.getStoreData('third_party_details');
            if(thirdPartyDetails === null){
                thirdPartyDetails = this.state.third_party_details;
            }
            let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

            let headerinfo = {};
                //type - breach of rule start
                headerinfo.breach_type = this.state.breachType; //updated Incident Category (4)
                headerinfo.breach_deatils = incedentDetails_value; //updated Incident Details (8)
                headerinfo.breach_date = this.state.breachDate; //updated Incident Date(6)
                headerinfo.is_reportability = this.state.isreportable; // updated Is this defined as a Reportable Breach? (24)
                headerinfo.reported_date = this.state.reportedDate;//(25)
                headerinfo.regulator_id = parseInt(this.state.regulatorID);  //(26)
                    //new section
                    headerinfo.incident_title = this.state.incident_title; //(3)
                    headerinfo.incidentType_id = this.state.incidentType_id;//(5)
                    headerinfo.incidentType_name = incidentType_name;
                    headerinfo.isinternal_external_incident = this.state.isinternal_external_incident; //0 for external, 1 for internal (9)
                    headerinfo.department_id = this.state.department_id; //(10)
                    headerinfo.department_name = department_name;
                    headerinfo.third_party_details = thirdPartyDetails_value; //(11)
                    headerinfo.impact_type = this.state.impact_type; //(12)
                    headerinfo.est_los_cur=this.state.estimated_loss_curreny//(13)
                    headerinfo.est_los_val=this.state.estimated_loss_value//(14)
                    headerinfo.act_los_cur=this.state.actual_loss_curreny//(15)
                    headerinfo.act_los_val=this.state.actual_loss_value//(16)
                    headerinfo.est_thi_cur=this.state.estimated_third_curreny//(17)
                    headerinfo.est_thi_val=this.state.estimated_third_value//(18)
                    headerinfo.act_thi_cur=this.state.actual_third_curreny//(19)
                    headerinfo.act_thi_val=this.state.actual_third_value//(20)
                    headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method//(21)
                    headerinfo.com_paid_date = this.state.com_paid_date;//(22)
                    headerinfo.rule_reference = this.state.rule_reference;//(27)
                    headerinfo.istrading_error = this.state.istrading_error;//(28)
                    headerinfo.fpp_name = this.state.fpp_name//(29)
                    headerinfo.dateofDetection = this.state.dateofDetection;
                    //new section
                //type - breach of rule end
                //type - conduct of rule start
                headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID); //all type --> User (7)
                headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
                headerinfo.disciplinary_action = this.state.disciplinaryAction; //(31)
                headerinfo.details_of_investigation = this.state.detailsofinvestigation; //(32)
                //headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                headerinfo.reasonforbreach = this.state.reasonforbreach; //Root Cause
                headerinfo.breachimpact = this.state.breachimpact; //Incident Impact
                headerinfo.ownershipresolution = this.state.ownershipresolution;
                headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;

            let final_object_data = {};
                final_object_data.headerinfo = headerinfo;

            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

            let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

            let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

            let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
            // console.log("bin_files==>",bin_files)

            let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
            // console.log("bin_files==>",disciplinary_action_bin_files)

            var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

            let task_json = {
                "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_REGISTER", "actiondate": Date.now(),
                "cur_assigned_to" : 0, 'task_type' : 'COMP_BREACH', 'parent_task_id' : 0,'due_date' : dueDateStr,
                "group_company_id": this.state.cur_gc_id},
                "object_data": final_object_data, "group_company_id": company_id,
                "action_data": { "action": "COMP_BREACH_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_BREACH_REGISTER","performed_by": Store.getStoreData('contact_id') }
            };
            console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "create_breach_task", task_json: task_json,bin_files: bin_files,
            submit: submit_form, role: Store.getStoreData('role'), is_gc: this.state.is_gc, gc_company_id: this.state.cur_gc_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processBreachData);

        } else {

            let incidentType_name = "";
            let incident_types = this.state.incident_types
            for(let k of incident_types){
                if(k.incidentTypeID.toString() === this.state.incidentType_id){
                    incidentType_name = k.incidentTypeName
                }
            }

            let department_name = "";
            let business_areas = this.state.business_areas
            for(let k of business_areas){
                if(k.businessAreaID.toString() === this.state.department_id){
                    department_name = k.businessAreaName
                }
            }

            let incedentDetails = Store.getStoreData('incident_details');
            if(incedentDetails === null){
                incedentDetails = this.state.breachDetails;
            }
            let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

            let thirdPartyDetails = Store.getStoreData('third_party_details');
            if(thirdPartyDetails === null){
                thirdPartyDetails = this.state.third_party_details;
            }
            let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

            let headerinfo = {};
                //type - breach of rule start
                headerinfo.breach_type = this.state.breachType; //updated Incident Category (4)
                headerinfo.breach_deatils = incedentDetails_value; //updated Incident Details (8)
                headerinfo.breach_date = this.state.breachDate; //updated Incident Date(6)
                headerinfo.is_reportability = this.state.isreportable; // updated Is this defined as a Reportable Breach? (24)
                headerinfo.reported_date = this.state.reportedDate;//(25)
                headerinfo.regulator_id = parseInt(this.state.regulatorID);  //(26)
                    //new section
                    headerinfo.incident_title = this.state.incident_title; //(3)
                    headerinfo.incidentType_id = this.state.incidentType_id;//(5)
                    headerinfo.incidentType_name = incidentType_name;
                    headerinfo.isinternal_external_incident = this.state.isinternal_external_incident; //0 for external, 1 for internal (9)
                    headerinfo.department_id = this.state.department_id; //(10)
                    headerinfo.department_name = department_name;
                    headerinfo.third_party_details = thirdPartyDetails_value; //(11)
                    headerinfo.impact_type = this.state.impact_type; //(12)
                    headerinfo.est_los_cur=this.state.estimated_loss_curreny//(13)
                    headerinfo.est_los_val=this.state.estimated_loss_value//(14)
                    headerinfo.act_los_cur=this.state.actual_loss_curreny//(15)
                    headerinfo.act_los_val=this.state.actual_loss_value//(16)
                    headerinfo.est_thi_cur=this.state.estimated_third_curreny//(17)
                    headerinfo.est_thi_val=this.state.estimated_third_value//(18)
                    headerinfo.act_thi_cur=this.state.actual_third_curreny//(19)
                    headerinfo.act_thi_val=this.state.actual_third_value//(20)
                    headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method//(21)
                    headerinfo.com_paid_date = this.state.com_paid_date;//(22)
                    headerinfo.rule_reference = this.state.rule_reference;//(27)
                    headerinfo.istrading_error = this.state.istrading_error;//(28)
                    headerinfo.fpp_name = this.state.fpp_name//(29)
                    headerinfo.dateofDetection = this.state.dateofDetection;
                    //new section
                //type - breach of rule end
                //type - conduct of rule start
                headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID); //all type --> User (7)
                headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
                headerinfo.disciplinary_action = this.state.disciplinaryAction; //(31)
                headerinfo.details_of_investigation = this.state.detailsofinvestigation; //(32)
                //headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                headerinfo.reasonforbreach = this.state.reasonforbreach; //Root Cause
                headerinfo.breachimpact = this.state.breachimpact; //Incident Impact
                headerinfo.ownershipresolution = this.state.ownershipresolution;
                headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;

            let final_object_data = {};
                final_object_data.headerinfo = headerinfo;

            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

            let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json]; //(23)

            let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

            let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];//(30)
            // console.log("bin_files==>",bin_files)

            let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];//(33)
            // console.log("bin_files==>",disciplinary_action_bin_files)

            var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

            let task_json = {
                "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_USERSAVE", "actiondate": Date.now(),
                "cur_assigned_to" : 0, 'task_type' : 'COMP_BREACH', 'parent_task_id' : 0,'due_date' : dueDateStr,
                'group_company_id': this.state.cur_gc_id},
                "object_data": final_object_data, "group_company_id": company_id,
                "action_data": { "action": "COMP_BREACH_USERSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_BREACH_USERSAVE","performed_by": Store.getStoreData('contact_id') }
            };
            //console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "create_breach_task", task_json: task_json,bin_files: bin_files,
            submit: submit_form, is_gc: this.state.is_gc,
            gc_company_id: this.state.cur_gc_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processBreachData);
        }
    }

    processBreachData = (result) => {
        console.log("result", result)
        let message = ''
        if(this.state.submit_form === 1){
            message = 'Incident registered successfully'
            //alert('Breach registered successfully');
        }else {
            message = 'This Incident has been saved but not submitted for registration yet. You may modify and register it later.'
            //alert('This Breach Register has been saved but not submitted for registration yet. You may modify and register it later.');
        }
        let alert_param = {title: 'Success', message: message, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessBreachData, stack: {}}

        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload();
    }

    updateprocessBreachData = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        //localStorage.clear();
        window.location.reload();
    }

    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_companies');
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = true;
        console.log("gc_company", gc_company);

        for(let gc of gc_company) {
            //gc.id = (gc.id * 100000) + gc_company.id;
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
                console.log("gc.id", gc.id);

            }
        }
        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;

        let cur_contactID = Store.getStoreData('contact_id');

        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID, breachType: this.state.breachType, conductRuleUserID: cur_contactID});
        //console.log("CURGCID", this.state.cur_gc_id);
    }

    updatecheckvalidation = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(!result){
            console.log("Sourav")
            this.updateRegisterBreach(stack.submit_form)
        }
    }

        updateRegisterBreach_validation = (submit_form) => (event) => {
            event.preventDefault();
            let problems = false
            let message = ''
            const  module_config = Store.getStoreData('module_config');
            if(submit_form === 1) {
                if(parseInt(this.state.cur_gc_id) === 0){
                    problems = true
                    message += 'Please select a Company. '
                }
                if(this.state.breachType === 'breach_of_rules'){
                    if(this.state.conductRuleUserID === ""){
                        problems = true
                        message += 'Please select a individual reporting the incident. '
                    }
                    if(this.state.isinternal_external_incident === 2){
                        problems = true
                        message += 'Please select the incident internal or external. '
                    }
                    if(this.state.isinternal_external_incident === 1 && this.state.business_areas.length !== 0 && this.state.department_id === ""){
                        problems = true
                        message += 'Please select the Department. '
                    }
                    if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true && this.state.impact_type === ''){
                        problems = true
                        message += 'Please select Impact Type. '
                    }
                    if(this.state.isreportable === 1 && this.state.regulatorID === ""){
                        problems = true
                        message += 'Please select a Regulator. '
                    }
                    if (module_config.custom_fields.enabled) {
                        let fields = [];
                        if (module_config.custom_fields.additional_fields.length !== 0) {
                            this.state.assign_data.additional_fields.forEach((item) => {
                                if (item.required === true && item.value === "") {
                                    fields.push(item.nickname);
                                }
                            })
                        }
                        if (fields.length !== 0) {
                            let err_msg = "";
                            fields.forEach((item) => {
                                err_msg = err_msg + item + ", ";
                            })
                            err_msg = err_msg.substring(0, err_msg.length - 2);
                            if (fields.length === 1) {
                                err_msg = err_msg + " field is mandatory. ";
                            } else {
                                err_msg = err_msg + " fields are mandatory. ";
                            }
                            problems = true
                            message += err_msg
                        }
                    }
                }else{
                    if(this.state.conductRuleUserID === ""){
                        problems = true
                        message += 'Please select a individual reporting the incident. '
                    }
                    if(this.state.isinternal_external_incident === 2){
                        problems = true
                        message += 'Please select the incident internal or external. '
                    }
                    if(this.state.isinternal_external_incident === 1 && this.state.business_areas.length !== 0 && this.state.department_id === ""){
                        problems = true
                        message += 'Please select the Department. '
                    }
                    if('financial_impact' in Store.getStoreData('module_config') && 'impact_type' in Store.getStoreData('module_config').financial_impact && Store.getStoreData('module_config').financial_impact.impact_type === true && this.state.impact_type === ''){
                        problems = true
                        message += 'Please select Impact Type. '
                    }
                    if(this.state.isreportable === 1 && this.state.regulatorID === ""){
                        problems = true
                        message += 'Please select a Regulator. '
                    }
                    if (module_config.custom_fields.enabled) {
                        let fields = [];
                        if (module_config.custom_fields.additional_fields.length !== 0) {
                            this.state.assign_data.additional_fields.forEach((item) => {
                                if (item.required === true && item.value === "") {
                                    fields.push(item.nickname);
                                }
                            })
                        }
                        if (fields.length !== 0) {
                            let err_msg = "";
                            fields.forEach((item) => {
                                err_msg = err_msg + item + ", ";
                            })
                            err_msg = err_msg.substring(0, err_msg.length - 2);
                            if (fields.length === 1) {
                                err_msg = err_msg + " field is mandatory. ";
                            } else {
                                err_msg = err_msg + " fields are mandatory. ";
                            }
                            problems = true
                            message += err_msg
                        }
                    }
                    if(this.state.disciplinaryAction === "yes" && this.state.detailsofinvestigation === ""){
                        problems = true
                        message += 'Please provide Details of Investigation. '
                    }
                }
                console.log("=========>", Store.getStoreData('module_config').general.form_customisation_root_cause)
                // if(Store.getStoreData('module_config').general.form_customisation_root_cause === '' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_manager_form'){
                if(Store.getStoreData('module_config').general.form_customisation_root_cause === '' || Store.getStoreData('module_config').general.form_customisation_root_cause === 'show_on_entry_form'){
                    if(this.state.reasonforbreach === ""){
                        problems = true
                        message += 'Please select the root cause. '
                    }
                }
                // if(Store.getStoreData('module_config').general.form_customisation_incident_impact === '' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_manager_form'){
                if(Store.getStoreData('module_config').general.form_customisation_incident_impact === '' || Store.getStoreData('module_config').general.form_customisation_incident_impact === 'show_on_entry_form'){
                    if(this.state.breachimpact === ""){
                        problems = true
                        message += 'Please select the Incident Impact. '
                    }
                }
                // if(Store.getStoreData('module_config').general.form_customisation_resolution_owner === '' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_manager_form'){
                if(Store.getStoreData('module_config').general.form_customisation_resolution_owner === '' || Store.getStoreData('module_config').general.form_customisation_resolution_owner === 'show_on_entry_form'){
                    if(this.state.ownershipresolution === ""){
                        problems = true
                        message += 'Please select the Ownership of Resolution. '
                    }
                }
                // if(Store.getStoreData('module_config').general.form_customisation_risk_category === '' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_manager_form'){
                if(Store.getStoreData('module_config').general.form_customisation_risk_category === '' || Store.getStoreData('module_config').general.form_customisation_risk_category === 'show_on_entry_form'){
                    if(this.state.selectedRiskCategory.length === 0){
                        problems = true
                        message += 'Please select the Risk Category. '
                    }
                }
            }else{
                if(this.state.cur_gc_id === 0){
                    problems = true
                    message += 'Please select a Company. '
                }
            }
            if(problems) {
                let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                            alertHandler: this.updatecheckvalidation, stack: {submit_form: submit_form}}

                this.setState({alert_param: alert_param})
            } else {
                this.updatecheckvalidation(false, {submit_form: submit_form})
            }
        }

    updateRegisterBreach = (submit_form) => {
        //event.preventDefault();
        let company_id = this.state.companyID;
        if(submit_form === 1) {
            console.log("sourav")
            let incidentType_name = "";
            let incident_types = this.state.incident_types
            for(let k of incident_types){
                if(k.incidentTypeID.toString() === this.state.incidentType_id){
                    incidentType_name = k.incidentTypeName
                }
            }

            let department_name = "";
            let business_areas = this.state.business_areas
            for(let k of business_areas){
                if(k.businessAreaID.toString() === this.state.department_id){
                    department_name = k.businessAreaName
                }
            }

            let incedentDetails = Store.getStoreData('incident_details');
            if(incedentDetails === null){
                incedentDetails = this.state.breachDetails;
            }
            let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

            let thirdPartyDetails = Store.getStoreData('third_party_details');
            if(thirdPartyDetails === null){
                thirdPartyDetails = this.state.third_party_details;
            }
            let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

            let headerinfo = {};
                //type - breach of rule start
                headerinfo.breach_type = this.state.breachType; //updated Incident Category (4)
                headerinfo.breach_deatils = incedentDetails_value; //updated Incident Details (8)
                headerinfo.breach_date = this.state.breachDate; //updated Incident Date(6)
                headerinfo.is_reportability = this.state.isreportable; // updated Is this defined as a Reportable Breach? (24)
                headerinfo.reported_date = this.state.reportedDate;//(25)
                headerinfo.regulator_id = parseInt(this.state.regulatorID);  //(26)
                    //new section
                    headerinfo.incident_title = this.state.incident_title; //(3)
                    headerinfo.incidentType_id = this.state.incidentType_id;//(5)
                    headerinfo.incidentType_name = incidentType_name;
                    headerinfo.isinternal_external_incident = this.state.isinternal_external_incident; //0 for external, 1 for internal (9)
                    headerinfo.department_id = this.state.department_id; //(10)
                    headerinfo.department_name = department_name;
                    headerinfo.third_party_details = thirdPartyDetails_value; //(11)
                    headerinfo.impact_type = this.state.impact_type; //(12)
                    headerinfo.est_los_cur=this.state.estimated_loss_curreny//(13)
                    headerinfo.est_los_val=this.state.estimated_loss_value//(14)
                    headerinfo.act_los_cur=this.state.actual_loss_curreny//(15)
                    headerinfo.act_los_val=this.state.actual_loss_value//(16)
                    headerinfo.est_thi_cur=this.state.estimated_third_curreny//(17)
                    headerinfo.est_thi_val=this.state.estimated_third_value//(18)
                    headerinfo.act_thi_cur=this.state.actual_third_curreny//(19)
                    headerinfo.act_thi_val=this.state.actual_third_value//(20)
                    headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method//(21)
                    headerinfo.com_paid_date = this.state.com_paid_date;//(22)
                    headerinfo.rule_reference = this.state.rule_reference;//(27)
                    headerinfo.istrading_error = this.state.istrading_error;//(28)
                    headerinfo.fpp_name = this.state.fpp_name//(29)
                    headerinfo.dateofDetection = this.state.dateofDetection;
                    //new section
                //type - breach of rule end
                //type - conduct of rule start
                headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID); //all type --> User (7)
                headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
                headerinfo.disciplinary_action = this.state.disciplinaryAction; //(31)
                headerinfo.details_of_investigation = this.state.detailsofinvestigation; //(32)
                //headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                headerinfo.reasonforbreach = this.state.reasonforbreach; //Root Cause
                headerinfo.breachimpact = this.state.breachimpact; //Incident Impact
                headerinfo.ownershipresolution = this.state.ownershipresolution;
                headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;

            let final_object_data = {};
                final_object_data.headerinfo = headerinfo;

            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

            let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

            let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

            let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
            // console.log("bin_files==>",bin_files)

            let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
            // console.log("bin_files==>",disciplinary_action_bin_files)

            var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

            let task_json = {
                "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_REGISTER", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_BREACH_REGISTER',
                "cur_assigned_to" : 0, 'task_type' : 'COMP_BREACH', 'parent_task_id' : 0,'due_date' : dueDateStr,
                "group_company_id": company_id},
                "object_data": final_object_data,
                "action_data": { "action": "COMP_BREACH_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_BREACH_REGISTER"}
            };

            console.log("task jason", JSON.stringify(task_json));
            let postData = {command: "update_breach_task", task_json: task_json,bin_files: bin_files,submit: submit_form, role: Store.getStoreData('role'),
            taskid: this.state.taskid, gc_id: company_id};
            console.log("postData",postData)
            this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
            let api = new APICall();
            api.command(postData, this.processUpdateBreachData);
        }
        else{
            // if(this.state.cur_gc_id === 0){
            //     alert('Please select a Company.');
            // }else{

                let incidentType_name = "";
                let incident_types = this.state.incident_types
                for(let k of incident_types){
                    if(k.incidentTypeID.toString() === this.state.incidentType_id){
                        incidentType_name = k.incidentTypeName
                    }
                }

                let department_name = "";
                let business_areas = this.state.business_areas
                for(let k of business_areas){
                    if(k.businessAreaID.toString() === this.state.department_id){
                        department_name = k.businessAreaName
                    }
                }

                let incedentDetails = Store.getStoreData('incident_details');
                if(incedentDetails === null){
                    incedentDetails = this.state.breachDetails;
                }
                let incedentDetails_value = btoa(window.encodeURIComponent(incedentDetails));

                let thirdPartyDetails = Store.getStoreData('third_party_details');
                if(thirdPartyDetails === null){
                    thirdPartyDetails = this.state.third_party_details;
                }
                let thirdPartyDetails_value = btoa(window.encodeURIComponent(thirdPartyDetails));

                let headerinfo = {};
                //type - breach of rule start
                headerinfo.breach_type = this.state.breachType; //updated Incident Category (4)
                headerinfo.breach_deatils = incedentDetails_value; //updated Incident Details (8)
                headerinfo.breach_date = this.state.breachDate; //updated Incident Date(6)
                headerinfo.is_reportability = this.state.isreportable; // updated Is this defined as a Reportable Breach? (24)
                headerinfo.reported_date = this.state.reportedDate;//(25)
                headerinfo.regulator_id = parseInt(this.state.regulatorID);  //(26)
                    //new section
                    headerinfo.incident_title = this.state.incident_title; //(3)
                    headerinfo.incidentType_id = this.state.incidentType_id;//(5)
                    headerinfo.incidentType_name = incidentType_name;
                    headerinfo.isinternal_external_incident = this.state.isinternal_external_incident; //0 for external, 1 for internal (9)
                    headerinfo.department_id = this.state.department_id; //(10)
                    headerinfo.department_name = department_name;
                    headerinfo.third_party_details = thirdPartyDetails_value; //(11)
                    headerinfo.impact_type = this.state.impact_type; //(12)
                    headerinfo.est_los_cur=this.state.estimated_loss_curreny//(13)
                    headerinfo.est_los_val=this.state.estimated_loss_value//(14)
                    headerinfo.act_los_cur=this.state.actual_loss_curreny//(15)
                    headerinfo.act_los_val=this.state.actual_loss_value//(16)
                    headerinfo.est_thi_cur=this.state.estimated_third_curreny//(17)
                    headerinfo.est_thi_val=this.state.estimated_third_value//(18)
                    headerinfo.act_thi_cur=this.state.actual_third_curreny//(19)
                    headerinfo.act_thi_val=this.state.actual_third_value//(20)
                    headerinfo.fin_impact_cal_method = this.state.fin_impact_cal_method//(21)
                    headerinfo.com_paid_date = this.state.com_paid_date;//(22)
                    headerinfo.rule_reference = this.state.rule_reference;//(27)
                    headerinfo.istrading_error = this.state.istrading_error;//(28)
                    headerinfo.fpp_name = this.state.fpp_name//(29)
                    headerinfo.dateofDetection = this.state.dateofDetection;
                    //new section
                //type - breach of rule end
                //type - conduct of rule start
                headerinfo.conduct_rule_user_id = parseInt(this.state.conductRuleUserID); //all type --> User (7)
                headerinfo.smcr_role_id = parseInt(this.state.smcrRoleID);
                headerinfo.disciplinary_action = this.state.disciplinaryAction; //(31)
                headerinfo.details_of_investigation = this.state.detailsofinvestigation; //(32)
                //headerinfo.custom_terminology_fields = this.state.assign_data.custom_terminology_fields;
                headerinfo.additional_fields = this.state.assign_data.additional_fields;

                headerinfo.reasonforbreach = this.state.reasonforbreach; //Root Cause
                headerinfo.breachimpact = this.state.breachimpact; //Incident Impact
                headerinfo.ownershipresolution = this.state.ownershipresolution;
                headerinfo.selectedRiskCategory = this.state.selectedRiskCategory;

                let final_object_data = {};
                final_object_data.headerinfo = headerinfo;

                let curmonth = parseInt(this.state.due_date.getMonth());
                let currmonth = curmonth + 1;
                var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
                dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();

                let brebin_files = this.state.breach_cur_files_json === null ? this.state.breach_binFiles : [...this.state.breach_binFiles, ...this.state.breach_cur_files_json];

                let finbin_files = this.state.finimp_cur_files_json === null ? this.state.finimp_binFiles : [...this.state.finimp_binFiles, ...this.state.finimp_cur_files_json];

                let evibin_files = this.state.evdence_cur_files_json === null ? this.state.evdence_binFiles : [...this.state.evdence_binFiles, ...this.state.evdence_cur_files_json];
                // console.log("bin_files==>",bin_files)

                let disbin_files = this.state.disciplinaryAction_cur_files_json === null ? this.state.disciplinaryAction_binFiles : [...this.state.disciplinaryAction_binFiles, ...this.state.disciplinaryAction_cur_files_json];
                // console.log("bin_files==>",disciplinary_action_bin_files)

                var bin_files = [...evibin_files, ...disbin_files, ...brebin_files, ...finbin_files];

                let task_json = {
                    "task_data": { "module_id": "breach", "last_action": "COMP_BREACH_USERSAVE", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_BREACH_USERSAVE', 'group_company_id': company_id,
                    'task_type' : 'COMP_BREACH', 'parent_task_id' : 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_BREACH_USERSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_BREACH_USERSAVE"}
                };

                console.log("task jason", JSON.stringify(task_json));
                let postData = {command: "update_breach_task", task_json: task_json,bin_files: bin_files,submit: submit_form,
                taskid: this.state.taskid};
                console.log("postData",postData)
                this.setState({submit_form,is_disabled: true, isLoadedIcon: true})
                let api = new APICall();
                api.command(postData, this.processUpdateBreachData);
            //}
        }
    }

    processUpdateBreachData = (result) => {
        console.log("result", result)
        let message = ''
        if(this.state.submit_form === 1){
            message = 'Incident registered successfully';
        }else {
            message = 'Incident updated successfully';
        }
        let alert_param = {title: 'Success', message: message, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessBreachData, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload();
    }

    filesLoaded_breach = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "breach_resource"
            }
        }
        this.setState({breach_binFiles: files});
    }

    filesLoaded_finimp = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "finimp_resource"
            }
        }
        this.setState({finimp_binFiles: files});
    }

    filesLoaded_evidence = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "evidence_resource"
            }
        }
        this.setState({evdence_binFiles: files});
    }

    filesLoaded_disciplinaryaction = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
                files[i].type = "disciplinary_resource"
            }

        }
        this.setState({disciplinaryAction_binFiles: files});
    }

    modifyAssignData = (obj) => {
        this.setState({assign_data: obj});
    }

    riskcategoryValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = [];
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                //filtertypeObject[selected_options[i].id] = selected_options[i].label;
                filtertypeObject.push(selected_options[i])
            }
        }
        console.log("ricipientObject",filtertypeObject)
        this.setState({selectedRiskCategory: filtertypeObject})
    }

    changeClear = () => {
		this.setState({isclear: false})
    }

    render()
    {
        let moduleconfig = Store.getStoreData('module_config');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        return (
            <div>
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Add Incident</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    {/* <MRModalSubHeaderText>Complete the required fields to create a new Breach Record</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div> */}
                </MRModalHeader>
                <ScoreBodyContainer>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "0px 13px 30px 0px"}}>
                        <div>
                            <MRModalLabel style={{marginTop: "0px"}}>INCIDENT ID</MRModalLabel>
                            <MRModalInput style={{backgroundColor: "#F0F0F0"}} value={this.state.Ref_ID} disabled/>
                        </div>

                        {
                            (() => {
                                if(this.state.rowindex >= 0) {
                                    return(<div>
                                        <MRModalLabel>COMPANY</MRModalLabel>
                                        <MRModalInput name="cur_gc_id" value={this.state.groupcompanyName} disabled={true}/>
                                    </div>)
                                }else{
                                    if(Store.getStoreData('gc_companies').length === 0){
                                        return(
                                            <div>
                                                <MRModalLabel>COMPANY</MRModalLabel>
                                                <MRModalInput name="cur_gc_id" value={this.state.gc_company[0].company_name} disabled={true}/>
                                            </div>
                                        )
                                    }else if(this.state.gc_company.length === 1){
                                        return(
                                            <div>
                                                <MRModalLabel>COMPANY</MRModalLabel>
                                                <MRModalInput name="cur_gc_id" value={this.state.gc_company[0].company_name} disabled={true}/>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>
                                                <MRModalLabel>COMPANY</MRModalLabel>
                                                <MRModalSelect id="select_company" name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc} disabled={this.state.isdesable}>
                                                <option key={0} value={0}>Select</option>
                                                    {
                                                            this.state.gc_company.map((gc) => {
                                                            return(
                                                            <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>
                                            </div>
                                        )
                                    }
                                }
                            })()
                        }

                        <MRModalLabel>INCIDENT TITLE</MRModalLabel>
                        <MRModalInput name="incident_title" value={this.state.incident_title} onChange={this.handleChange} disabled={this.state.isdesable}/>
                        <MRModalLabel>INCIDENT CATEGORY</MRModalLabel>
                        <MRModalSelect id="breach_type" name="breachType" onChange={this.handleChangeIncidentCategory} value={this.state.breachType} disabled={this.state.isdesable}>
                            <option value="breach_of_rules">Incident</option>
                            <option value="conduct_rules_breach">Conduct Rules Breach</option>
                        </MRModalSelect>
                        {
                            (() => {
                                if(this.state.breachType === "breach_of_rules"){
                                    return(<BMBreachOfRulesSection
                                        conductRuleUserID={this.state.conductRuleUserID}
                                        isreportable={this.state.isreportable}
                                        isinternal_external_incident={this.state.isinternal_external_incident}
                                        handleChangeReportable={this.handleChangeReportable}
                                        handleChangeExIntIncd={this.handleChangeExIntIncd}

                                        breach_cur_files_json={this.state.breach_cur_files_json}
                                        filesUploadbreach={this.filesLoaded_breach}

                                        finimp_cur_files_json={this.state.finimp_cur_files_json}
                                        filesUploadfinimp={this.filesLoaded_finimp}

                                        cur_files_json={this.state.evdence_cur_files_json}
                                        filesUpload={this.filesLoaded_evidence}
                                        handleChangeAll={this.handleChange}
                                        breachDetails={this.state.breachDetails}
                                        breachDate={this.state.breachDate}
                                        dateofDetection={this.state.dateofDetection}
                                        changeDateofDetection={this.onChangeDateofDetection}
                                        reportedDate={this.state.reportedDate}
                                        regulatorID={this.state.regulatorID}
                                        ChangeBreachDate={this.onChangeBreachDate}
                                        ChangeReportedDate={this.onChangeReportedDate}
                                        disabledCheck={this.state.isdesable}

                                        assign_data={this.state.assign_data}
                                        modifyAssignData={this.modifyAssignData}
                                        smcrRoleID={this.state.smcrRoleID}
                                        cur_gc_id={this.state.cur_gc_id}
                                        //new
                                        incident_types={this.state.incident_types}
                                        incidentType_id={this.state.incidentType_id}
                                        business_areas={this.state.business_areas}
                                        department_id={this.state.department_id}
                                        third_party_details={this.state.third_party_details}
                                        impact_type={this.state.impact_type}
                                        regulator_types={this.state.regulator_types}
                                        estimated_loss_curreny={this.state.estimated_loss_curreny}
                                        estimated_loss_value={this.state.estimated_loss_value}
                                        actual_loss_curreny={this.state.actual_loss_curreny}
                                        actual_loss_value={this.state.actual_loss_value}
                                        estimated_third_curreny={this.state.estimated_third_curreny}
                                        estimated_third_value={this.state.estimated_third_value}
                                        actual_third_curreny={this.state.actual_third_curreny}
                                        actual_third_value={this.state.actual_third_value}
                                        fin_impact_cal_method={this.state.fin_impact_cal_method}
                                        com_paid_date={this.state.com_paid_date}
                                        ChangePaidDate={this.ChangePaidDate}
                                        rule_reference={this.state.rule_reference}
                                        istrading_error={this.state.istrading_error}
                                        handleChangeTradingError={this.handleChangeTradingError}
                                        fpp_name={this.state.fpp_name}
                                        disciplinaryAction={this.state.disciplinaryAction}
                                        handlenaSectionIncluded={this.handlenaSectionIncludedDis}
                                        detailsofinvestigation={this.state.detailsofinvestigation}
                                        cur_files_jsonNew={this.state.disciplinaryAction_cur_files_json}
                                        filesUpload1={this.filesLoaded_disciplinaryaction}

                                        reasonforbreach={this.state.reasonforbreach}
                                        reasonforbreachlist={this.state.reasonforbreachlist}
                                        breachimpactlist={this.state.breachimpactlist}
                                        breachimpact={this.state.breachimpact}
                                        ownershipresolution={this.state.ownershipresolution}
                                        riskcategorylist={this.state.riskcategorylist}
                                        selectedRiskCategory={this.state.selectedRiskCategory}
                                        isclear={this.state.isclear}
                                        riskcategoryValue={this.riskcategoryValue}
                                        changeClear={this.changeClear}
                                        />)
                                }else{
                                    return(<BMConductRulesBreachSection
                                        conductRuleUserID={this.state.conductRuleUserID}
                                        handleChangeAll={this.handleChange}
                                        breachDate={this.state.breachDate}
                                        ChangeBreachDate={this.onChangeBreachDate}
                                        dateofDetection={this.state.dateofDetection}
                                        changeDateofDetection={this.onChangeDateofDetection}
                                        isreportable={this.state.isreportable}
                                        handleChangeReportable={this.handleChangeReportable}
                                        reportedDate={this.state.reportedDate}
                                        ChangeReportedDate={this.onChangeReportedDate}
                                        regulatorID={this.state.regulatorID}
                                        filesUpload={this.filesLoaded_evidence}
                                        cur_files_json={this.state.evdence_cur_files_json}
                                        breachDetails={this.state.breachDetails}

                                        smcrRoleID={this.state.smcrRoleID}
                                        detailsofinvestigation={this.state.detailsofinvestigation}
                                        disciplinaryAction={this.state.disciplinaryAction}
                                        filesUpload1={this.filesLoaded_disciplinaryaction}
                                        cur_files_jsonNew={this.state.disciplinaryAction_cur_files_json}
                                        assign_data={this.state.assign_data}
                                        modifyAssignData={this.modifyAssignData}
                                        disabledCheck={this.state.isdesable}
                                        cur_gc_id={this.state.cur_gc_id}
                                        isinternal_external_incident={this.state.isinternal_external_incident}
                                        //new
                                        business_areas={this.state.business_areas}
                                        department_id={this.state.department_id}
                                        third_party_details={this.state.third_party_details}
                                        impact_type={this.state.impact_type}
                                        regulator_types={this.state.regulator_types}
                                        estimated_loss_curreny={this.state.estimated_loss_curreny}
                                        estimated_loss_value={this.state.estimated_loss_value}
                                        actual_loss_curreny={this.state.actual_loss_curreny}
                                        actual_loss_value={this.state.actual_loss_value}
                                        estimated_third_curreny={this.state.estimated_third_curreny}
                                        estimated_third_value={this.state.estimated_third_value}
                                        actual_third_curreny={this.state.actual_third_curreny}
                                        actual_third_value={this.state.actual_third_value}
                                        fin_impact_cal_method={this.state.fin_impact_cal_method}
                                        com_paid_date={this.state.com_paid_date}
                                        ChangePaidDate={this.ChangePaidDate}
                                        rule_reference={this.state.rule_reference}
                                        istrading_error={this.state.istrading_error}
                                        handleChangeTradingError={this.handleChangeTradingError}
                                        fpp_name={this.state.fpp_name}
                                        handlenaSectionIncluded={this.handlenaSectionIncludedDis}
                                        handleChangeExIntIncd={this.handleChangeExIntIncd}
                                        breach_cur_files_json={this.state.breach_cur_files_json}
                                        filesUploadbreach={this.filesLoaded_breach}

                                        finimp_cur_files_json={this.state.finimp_cur_files_json}
                                        filesUploadfinimp={this.filesLoaded_finimp}

                                        reasonforbreach={this.state.reasonforbreach}
                                        reasonforbreachlist={this.state.reasonforbreachlist}
                                        breachimpactlist={this.state.breachimpactlist}
                                        breachimpact={this.state.breachimpact}
                                        ownershipresolution={this.state.ownershipresolution}
                                        riskcategorylist={this.state.riskcategorylist}
                                        selectedRiskCategory={this.state.selectedRiskCategory}
                                        isclear={this.state.isclear}
                                        riskcategoryValue={this.riskcategoryValue}
                                        changeClear={this.changeClear}
                                    />)
                                }
                            })()
                        }
                    </div>
                </MRModalBody>
                </ScoreBodyContainer>
                <MRModalFooter>
                {
                    (() => {
                        if(this.state.rowindex >= 0) {
                            if(this.state.curlane === "COMP_BREACH_USERSAVE") {
                                return(<div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn id="submit" onClick={this.updateRegisterBreach_validation(1)} disabled={this.state.is_disabled}>Submit</MRModalSaveCloseBtn>
                                <MRModalSaveCloseBtn id="save_and_close" onClick={this.updateRegisterBreach_validation(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>
                                <MRModalCancelBtn id="cancel" onClick={this.closeView}>Cancel</MRModalCancelBtn>
                            </div>);
                            }
                            return(
                                <div style={{position: "relative",padding: "17px"}}>
                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                </div>
                            )
                        }else{
                            return(
                            <div style={{position: "relative",padding: "17px"}}>
                                <MRModalSaveCloseBtn id="submit" onClick={this.submitRegisterBreach_validation(1)} disabled={this.state.is_disabled}>Submit</MRModalSaveCloseBtn>
                                <MRModalSaveCloseBtn id="save_and_close" onClick={this.submitRegisterBreach_validation(0)} disabled={this.state.is_disabled}>Save and Close</MRModalSaveCloseBtn>
                                <MRModalCancelBtn id="cancel" onClick={this.closeView}>Cancel</MRModalCancelBtn>
                            </div>
                            )
                        }
                    })()
                }
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default BMAskBreachModal;
