import Store from './../../Common/Store';
class XcelLoader {
    conflicts_export_tasks(tasks) {
        let j = 0;
        let results = [];
        let module_config = Store.getStoreData('module_config');
        let status = {
            "COMP_FINPROMO_TEAMSAVE": "Saved",
            "COMP_FINPROMO_EXPIRED": "Expired",
            "COMP_FINPROMO_REVIEW_REQUIRED": "Review Required",
            "COMP_FINPROMO_WITHDRAWN": "Withdrawn",
            "COMP_FINPROMO_CONFIRMED": "Confirmed",
            "COMP_FINPROMO_REGISTERED": "Registered",
            "COMP_FINPROMO_REVISION": "Revision"
        }


        for (let d of tasks) {
            j++;

            let elem = {
                "Status": status[d.status],
                "FPRID": d.id,
                "Name": d.name,
                "Type": d.promotion,
                "Added By": d.addedBy,
                "addedDate": d.addedDate,
                "Number of Tasks": d.no_of_followup,
                "Approved by": d.approvedBy,
                "Approved Date": d.approvedDate,
                "Review Date": d.reviewDate,
                "Review By": d.reviewBy,
                "Expiry Date": d.expiryDate,
                "Company": d.group_company_name,
                "Approval Comments": d.comments_reviewer,
                "Withdraw Reason": d.reason_code
            };

            if (Object.keys(module_config).length > 0 && module_config.custom_fields.enabled) {
                debugger;
                if (module_config.custom_fields.additional_fields.length !== 0 && d.additional_fields.length !== 0) {
                    d.additional_fields.forEach((item) => {
                        if (item.value === 'other') {
                            elem[item.nickname] = item.other_value
                        } else {
                            elem[item.nickname] = item.value
                        }
                    })
                }
            }
            results.push(elem)
        }
        return results;
    }
}
export default new XcelLoader();
