import Store from './Store.js';

class IndexLoader {
	divideAllTasks(tasks, role, contact_id){
	  let my_open_tasks = [];
	  let my_approval_tasks = [];
	  let my_acceptance_tasks = [];
	  let my_initiated_tasks = [];
	  let my_saved_queries = [];
	  let my_answered_tasks = [];
	  let cmpleted_tsks = [];
	  let approval_faq_tasks = [];
	  let my_save_faqs = [];
	  let my_all_faqs = [];
	  let approved_faq_tasks = [];
	  let faq_revert_task = [];
	  let reapproval_faq_tasks = [];
	  let my_reverted_tasks = [];
	  let my_pending_tasks = [];
	  for (const task in tasks) {
	    // let task_json = JSON.parse(JSON.stringify(tasks[task].task_json))
	    console.log("task_json ===>", tasks[task], tasks[task].is_active)
	    let cur_lane = tasks[task].cur_lane;
	    let cur_assign_to = tasks[task].cur_assigned_to;
	    let created_by = tasks[task].contact_id;
	    let answered_by = tasks[task].answered_by;
	    let is_active = tasks[task].is_active;
	    // tasks[task].task_json = JSON.stringify(task_json);
	    if((cur_lane === "COMP_CAR_TEAMSAVE" || cur_lane === "COMP_CAR_ANSWER_SAVED") && (cur_assign_to === contact_id && is_active === 1)){
	      my_saved_queries.push(tasks[task]);
	    }
	    if((cur_lane === "COMP_CAR_ANSWER" || cur_lane === "COMP_CAR_ANSWER_SAVED" || cur_lane === "COMP_CAR_USER_CLARIFICATION") && (created_by === contact_id && is_active === 1)) {
	      my_initiated_tasks.push(tasks[task]);
	    }
	    if(cur_lane === "COMP_CAR_USER_COMPLETED" && is_active === 1) {
	      cmpleted_tsks.push(tasks[task]);
	    }
	    if(cur_lane === "CAR_FAQ_AWAITING_APPROVAL" && is_active === 1) {
	      approval_faq_tasks.push(tasks[task]);
	    }
	    if((cur_lane === "ADD_CAR_FAQ" || cur_lane === "CAR_FAQ_AWAITING_APPROVAL" || cur_lane === "CAR_FAQ_APPROVED") && (created_by === contact_id && is_active === 1)){
	      my_all_faqs.push(tasks[task]);
	    }
	    if(cur_lane === "ADD_CAR_FAQ" && (created_by === contact_id && is_active === 1)){
	      my_save_faqs.push(tasks[task]);
	    }
	    if(cur_lane === "CAR_FAQ_APPROVED" && is_active === 1){
	      approved_faq_tasks.push(tasks[task]);
	    }
	    if(cur_lane === "CAR_FAQ_AWAITING_REAPPROVAL") {
	      reapproval_faq_tasks.push(tasks[task]);
	    }
	    if(is_active === 1) {
	    	switch(role) {
	    	  case "ca_module_admin":
	    	  case "admin_manager":
	    	    if(cur_lane === "COMP_CAR_ANSWER" || cur_lane === "COMP_CAR_USER_CLARIFICATION"){
	    	      my_open_tasks.push(tasks[task]);
	    	    }
	    	    if(cur_lane === "COMP_CAR_USER_ACCEPTANCE_PENDING") {
	    	      my_approval_tasks.push(tasks[task])
	    	    }
	    	    if(cur_lane === "COMP_CAR_USER_ACCEPTANCE") {
	    	      my_acceptance_tasks.push(tasks[task]);
	    	    }
	    	    if(cur_lane === "COMP_CAR_USER_ACCEPTANCE") {
	    	      my_answered_tasks.push(tasks[task]);
	    	    }
	    	    if(cur_lane === "COMP_CAR_ANSWER" || cur_lane === "COMP_CAR_ANSWER_SAVED" || cur_lane === "COMP_CAR_USER_CLARIFICATION") {
	    	      my_pending_tasks.push(tasks[task]);
	    	    }
	    	    break;
	    	  case "ca":
	    	  case "jr_ca":
	    	    if((cur_lane === "COMP_CAR_ANSWER" || cur_lane === "COMP_CAR_USER_CLARIFICATION") && (cur_assign_to === contact_id || cur_assign_to === 0)){
	    	      my_open_tasks.push(tasks[task]);
	    	    }
	    	    if(cur_lane === "COMP_CAR_USER_ACCEPTANCE_PENDING" && (cur_assign_to === 0 || cur_assign_to === contact_id)) {
	    	      my_approval_tasks.push(tasks[task])
	    	    }
	    	    if(cur_lane === "COMP_CAR_USER_ACCEPTANCE" && created_by === contact_id) {
	    	      my_acceptance_tasks.push(tasks[task]);
	    	    }
	    	    if(cur_lane === "COMP_CAR_USER_ACCEPTANCE" && answered_by === contact_id) {
	    	      my_answered_tasks.push(tasks[task]);
	    	    }
	    	    if(cur_lane === "COMP_CAR_FAQ_REVERT" && created_by === contact_id) {
	    	      faq_revert_task.push(tasks[task]);
	    	    }
	    	    if((cur_lane === "COMP_CAR_USER_CLARIFICATION_REVERT" || cur_lane === "COMP_CAR_ANSWER_REVERT") && (cur_assign_to === contact_id || cur_assign_to === 0)) {
	    	      my_reverted_tasks.push(tasks[task]);
	    	    }
	    	    if((cur_lane === "COMP_CAR_ANSWER" || cur_lane === "COMP_CAR_ANSWER_SAVED" || cur_lane === "COMP_CAR_USER_CLARIFICATION" || cur_lane === "COMP_CAR_USER_ACCEPTANCE_PENDING") && (answered_by === contact_id || created_by === contact_id)) {
	    	      my_pending_tasks.push(tasks[task]);
	    	    }
	    	  break;
	    	  case "team":
	    	    
	    	  	if(created_by === contact_id){
	    	  		if(cur_lane === "COMP_CAR_ANSWER" || cur_lane === "COMP_CAR_ANSWER_SAVED" || cur_lane === "COMP_CAR_USER_CLARIFICATION" || cur_lane === "COMP_CAR_USER_ACCEPTANCE_PENDING"){
	    	  		  my_open_tasks.push(tasks[task]);
	    	  		}
	    	  		if(cur_lane === "COMP_CAR_USER_ACCEPTANCE") {
	    	  		  my_acceptance_tasks.push(tasks[task]);
	    	  		}
	    	  	}

	    	    break;

	    	}
	    }

	  }

	  Store.updateStore('my_open_tasks', my_open_tasks);
	  Store.updateStore('my_approval_tasks', my_approval_tasks);
	  Store.updateStore('my_acceptance_tasks', my_acceptance_tasks);
	  Store.updateStore('my_initiated_tasks', my_initiated_tasks);
	  Store.updateStore('my_saved_queries', my_saved_queries);
	  Store.updateStore('my_answered_tasks', my_answered_tasks);
	  Store.updateStore('cmpleted_tsks', cmpleted_tsks);
	  Store.updateStore('approval_faq_tasks', approval_faq_tasks);
	  Store.updateStore('my_all_faqs', my_all_faqs);
	  Store.updateStore('my_save_faqs', my_save_faqs);
	  Store.updateStore('approved_faq_tasks', approved_faq_tasks);
	  Store.updateStore('faq_revert_task', faq_revert_task);
	  Store.updateStore('my_reverted_tasks', my_reverted_tasks);
	  Store.updateStore('my_pending_tasks', my_pending_tasks);
	  Store.updateStore('reapproval_faq_tasks', reapproval_faq_tasks);

	  /*if(Store.getStoreData('my_open_tasks') === null) {
	   Store.updateStore('my_open_tasks', my_open_tasks);
	  }
	  if(Store.getStoreData('my_approval_tasks') === null) {
	   Store.updateStore('my_approval_tasks', my_approval_tasks);
	  }
	  if(Store.getStoreData('my_acceptance_tasks') === null) {
	   Store.updateStore('my_acceptance_tasks', my_acceptance_tasks);
	  }
	  if(Store.getStoreData('my_initiated_tasks') === null) {
	   Store.updateStore('my_initiated_tasks', my_initiated_tasks);
	  }
	  if(Store.getStoreData('my_saved_queries') === null) {
	   Store.updateStore('my_saved_queries', my_saved_queries);
	  }
	  if(Store.getStoreData('my_answered_tasks') === null) {
	   Store.updateStore('my_answered_tasks', my_answered_tasks);
	  }
	  if(Store.getStoreData('cmpleted_tsks') === null) {
	   Store.updateStore('cmpleted_tsks', cmpleted_tsks);
	  }
	  if(Store.getStoreData('approval_faq_tasks') === null) {
	   Store.updateStore('approval_faq_tasks', approval_faq_tasks);
	  }
	  if(Store.getStoreData('my_all_faqs') === null) {
	   Store.updateStore('my_all_faqs', my_all_faqs);
	  }
	  if(Store.getStoreData('my_save_faqs') === null) {
	   Store.updateStore('my_save_faqs', my_save_faqs);
	  }
	  if(Store.getStoreData('approved_faq_tasks') === null) {
	   Store.updateStore('approved_faq_tasks', approved_faq_tasks);
	  }
	  if(Store.getStoreData('faq_revert_task') === null) {
	   Store.updateStore('faq_revert_task', faq_revert_task);
	  }
	  if(Store.getStoreData('my_reverted_tasks') === null) {
	   Store.updateStore('my_reverted_tasks', my_reverted_tasks);
	  }
	  if(Store.getStoreData('my_pending_tasks') === null) {
	   Store.updateStore('my_pending_tasks', my_pending_tasks);
	  }
	  if(Store.getStoreData('reapproval_faq_tasks') === null) {
	   Store.updateStore('reapproval_faq_tasks', reapproval_faq_tasks);
	  }*/
	  return true;

	}
}

export default new IndexLoader();