import React from 'react';
import styled from 'styled-components';
const DateTimeCmp = styled.div`
    float: right;
    color: #ffffff;
    padding-top: 11px;
    padding-right: 20px;
`;
class WelcomeDateTime extends React.Component
{
    state = {date: null, time: null};
    componentDidMount()
    {
        setInterval(() => {
            let d = new Date();
            // console.log("d", d);
            let day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
            let month = parseInt(d.getMonth() + 1) < 10 ? `0${(d.getMonth() + 1).toString()}` : d.getMonth()+1;
            console.log("d.getMonth", d.getMonth() + 1);
            let year = d.getFullYear().toString();
            let hours = d.getHours() < 10 ? `0${d.getHours().toString()}` : d.getHours().toString();
            let minutes = d.getMinutes() < 10 ? `0${d.getMinutes().toString()}` : d.getMinutes().toString();
            let date = `${day}/${month}/${year}`;
            let time = `${hours}:${minutes}`;
            this.setState({date, time});
        }, 5000)
    }
    render()
    {
        return (
            <DateTimeCmp>{this.state.date}&nbsp;&nbsp;&nbsp;<b>{this.state.time}</b></DateTimeCmp>
        );
    }
}
export default WelcomeDateTime;