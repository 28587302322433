import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import {FaAngleDown} from 'react-icons/fa';
import {FaTimes} from 'react-icons/fa';
import Utils from '../../Common/Utils.js'
import DatePicker from 'react-date-picker'
import "../../App.css";
import Dropzone from '../Common/Dropzone.js';
import '../Common/Dropzone.css';
import * as Style from '../Common/StyledComponents';
import {FiDownload} from 'react-icons/fi';
import { saveAs } from 'file-saver';
import AddActions from './AddActions';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import CMPUtils from '../Common/CMPUtils.js';

let cmputils = new CMPUtils();

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 75%;
    left: 5%;
	top : 20px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: 600;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 98%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin:10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 77%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-left: 89px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;
const MRModalDivider = styled.div`
    background-color: #EBEBEB;
    height:3px;
    margin-top: 15px;
    margin-bottom: 15px;
	width: 97.5%;
	margin-left: 12px;
`;
const MRModalLabelTitle = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const TestingSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 78%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 73px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 10px 32px;
    color: #ffffff;
    cursor: pointer;
`;

const MRSubcatHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #26ADA7;
    padding: 13px;
    padding-bottom:27px;
    border: 1px solid #EBEBEB;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left : 17px;
    margin-top:10px;
`;
const MRSubcatList = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #FFFFFF;
    padding: 13px;    
    border: 1px solid #EBEBEB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left : 17px;
`;
const MRModalCircle = styled.label`
    height: 22px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    float: right;
    padding-bottom: 9px;
    padding-left: 5px;
    margin-bottom:2px;
    cursor:pointer;
`;
const MRTestingLabel = styled.label`
    display: inline-block;
    box-sizing: border-box;
    width: 78%;
    background-color: #EEEEEE;
    padding: 14px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-left: 71px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 210px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;
const TestingTextArea = styled.textarea`
    width:70%;
    margin-left:71px;
    margin-top: 15px;
    border: 1px solid #ffffff; 
    borderRadius: 3px; 
    boxShadow: 0 0 4px #c4c4c4;
    padding: 5px; 
    color: #979797; 
    fontFamily: Montserrat,sans-serif;
`;
const PartialDiv = styled.div`
    display:inline-block;
`;
const CompleteModalSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 20%;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

class CompleteActionModal extends React.Component
{
	bgColor = {'Amber' : '#F39C12' , 'Yellow' : 'Yellow' , 'Red' : 'Red' , 'Green' : '#04ADA8'}
	state = {
        ready: false,
		task_data: null,
        risk_area_name:'',
        testing_activity:'',
        testing_description:'',
        testing_freq :'Monthly',
        testing_owner:'',
        testing_objectives:'',
        testing_method:'',
        additional_guidance:'',
        testing_activity_undertaken: '',
        review_attachment:[],
        rag:'',
        ragBgColor:'',
        require_remediation: 'No',
        submit_msg:'',
        unique_testing_act_id :null,
        task_id : 0,
		parent_task_id : 0,
        binFiles: [],
        curBinFiles: [],
        cur_files_json: [],
        followupBinFiles: [],
        actions: [],
		action_title: "",
		action_description: "",
		action_note_undertaken: "",
		current_task: {},
		current_task_Id : 0,
		actionBinFiles: [],
		IsNoteProvided: false,
		is_form_modified : false,
		parent_ref_id : '',
	}

	componentDidMount(){
		//console.log("CompleteActionModal componentDidMount this.props=>"+JSON.stringify(this.props));
		//let current_task = JSON.parse(this.props.current_task);
		//console.log("current_task==>"+JSON.stringify(current_task));
        this.initializeState(this.props.taskData,this.props.taskId, this.props.my_task , this.props.tasks_without_filter , this.props.current_task , this.props.current_task_Id)
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props) {
			//console.log("CompleteActionModal componentDidUpdate this.props=>"+JSON.stringify(this.props));
			//let current_task = JSON.parse(this.props.current_task);
            this.initializeState(this.props.taskData,this.props.taskId, this.props.my_task , this.props.tasks_without_filter , this.props.current_task , this.props.current_task_Id)
        }
		if(this.state.is_form_modified === false){
			this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		}
    }
    initializeState(task_arr, task_id, my_task , tasks_without_filter , current_task_unparsed , current_task_Id){ // my_task is the parent one and current task is self(child)
        console.log("current_task_Id==>", current_task_Id);
        let task_data ={};
        let curBinFiles = [];
		let cur_files_json = [];
		let actionBinFiles = [];
		let followupBinFiles = [];

		let utils = new Utils();

		if(current_task_Id > 0){
			let my_task_temp = JSON.parse(my_task.task_json)
			console.log("initializeState my_tak",my_task);
			if('bin_files' in  my_task_temp.object_data) {
				//console.log("my_task_temp.object_data.bin_files.length=>"+my_task_temp.object_data.bin_files.length);
				if (my_task_temp.object_data.bin_files.length > 0) {
					my_task_temp.object_data.bin_files.forEach((item) => {
						item.uid = "uid" in item ? item.uid : this.genKey(10);
						if(!("type" in item)){
							curBinFiles.push(item);
						}else{
							cur_files_json.push(item);
						}
					});
				}
				//curBinFiles = my_task.object_data.bin_files;
			}

            //task_data =
			var task_json = my_task_temp;
			//console.log("task_json in complete action==>",task_json);
			var result = my_task_temp.object_data.result
			var risk_json = task_json.object_data.headerinfo.risk_json;
			var requireRemediation = result[0].require_remediation;
			if(result[0].require_remediation === ""){
				requireRemediation = "No"
			}
			var action_note_undertaken = "";
			var current_task = JSON.parse(current_task_unparsed.task_json);
			if(typeof current_task.object_data.result !== "undefined"){
				action_note_undertaken = current_task.object_data.result[0].action_note_undertaken;
			}
			//var action_note_undertaken = current_task.object_data.result[0].action_note_undertaken;
			//console.log("current_task==>"+JSON.stringify(current_task.object_data.bin_files));
			if('bin_files' in  current_task.object_data) {
				current_task.object_data.bin_files.forEach((item) => {
					item.uid = "uid" in item ? item.uid : utils.genKey(10);
					if(!("type" in item)){
						actionBinFiles.push(item);
					}else{
						followupBinFiles.push(item);
					}
				});
				//actionBinFiles = current_task.object_data.bin_files;
			}

			var testing_activity = risk_json.testing_activities.length > 0 ? risk_json.testing_activities[0] : risk_json.subcategories.testing_activities[0];
			let compliance_plans = Store.getStoreData('cmp-compliance_plans');
			//let hint_tasks = Store.getStoreData('hint_tasks');
			let ref_id = '';
			if('ref_id' in task_json.task_data){
				ref_id = task_json.task_data.ref_id;
			}
            //let ref_id_filt = 'PLAN_'+ref_id.split('_')[2] + '_' + ref_id.split('_')[3];
            console.log("completeactionmodal= ref_id_filt=>",ref_id);
            let plan_id = 0;
            let plan_json_parsed = {};
			for(let j=0;j<compliance_plans.length;j++){
				if(compliance_plans[j].id === parseInt(ref_id.split('-')[0])){
					plan_id = compliance_plans[j].id;
					plan_json_parsed = JSON.parse(compliance_plans[j].plan_json);
					break;
				}
			}
			let testing_activities = plan_json_parsed.testing_activities;
			let task_testing_activity_hints= {};
			for(let j=0;j<testing_activities.length;j++){
				if(testing_activities[j].unique_testing_act_id === ref_id.split('-')[4]){
					task_testing_activity_hints = testing_activities[j];
					break;
				}
			}
			console.log("completeactionmodal parent task result=>",result);

			//let task_testing_activity_hints = hint_obj[testing_activity.unique_testing_act_id];
			//let testing_method = testing_activity.testing_method.replace(/\r\n|\r|\n/g, '<br>');
			//console.log("testing_method==>"+testing_method);
			if(result[0].rag.toString() === 'red'){
				result[0].rag = 'Red';
			}
			let testing_activity_undertaken = "";
			if(this.state.testing_activity_undertaken === ""){
				testing_activity_undertaken = result[0].testing_activity_undertaken;
			}else{
				testing_activity_undertaken = this.state.testing_activity_undertaken;
			}

			if(testing_activity_undertaken !== '' ){
				if(utils.isBase64(testing_activity_undertaken)){
					testing_activity_undertaken = window.decodeURIComponent(atob(result[0].testing_activity_undertaken));
				}
			}else{
				testing_activity_undertaken = '<p>&nbsp;</p>'
			}

			let testing_description = "";
			if(this.state.testing_description === ""){
				testing_description = task_testing_activity_hints.testing_description;
			}else{
				testing_description = this.state.testing_description;
			}

			if(testing_description !== '' ){
				if(utils.isBase64(testing_description)){
					testing_description = window.decodeURIComponent(atob(task_testing_activity_hints.testing_description));
				}
			}else{
				testing_description = '<p>&nbsp;</p>'
			}

			let testing_objectives = "";
			if(this.state.testing_objectives === ""){
				testing_objectives = task_testing_activity_hints.testing_objectives;
			}else{
				testing_objectives = this.state.testing_objectives;
			}

			if(testing_objectives !== '' ){
				if(utils.isBase64(testing_objectives)){
					testing_objectives = window.decodeURIComponent(atob(task_testing_activity_hints.testing_objectives));
				}
			}else{
				testing_objectives = '<p>&nbsp;</p>'
			}

			let testing_method = "";
			if(this.state.testing_method === ""){
				testing_method = task_testing_activity_hints.testing_method;
			}else{
				testing_method = this.state.testing_method;
			}

			if(testing_method !== '' ){
				if(utils.isBase64(testing_method)){
					testing_method = window.decodeURIComponent(atob(task_testing_activity_hints.testing_method));
				}
			}else{
				testing_method = '<p>&nbsp;</p>'
			}

			let additional_guidance = "";
			if(this.state.additional_guidance === ""){
				additional_guidance = task_testing_activity_hints.additional_guidance;
			}else{
				additional_guidance = this.state.additional_guidance;
			}

			if(additional_guidance !== '' ){
				if(utils.isBase64(additional_guidance)){
					additional_guidance = window.decodeURIComponent(atob(task_testing_activity_hints.additional_guidance));
				}
			}else{
				additional_guidance = '<p>&nbsp;</p>'
			}


			let rag = cmputils.customRagDecider(this.props.module_configs,result[0].rag);
			let addtional_rags_assoc = cmputils.getCustomRagAssoc(this.props.module_configs);

			let ragBgColor =  addtional_rags_assoc[rag].rag_value;
			this.setState({ready: true ,parent_task_id: task_id, task_data:my_task_temp, testing_activity: task_testing_activity_hints.testing_activity,
				testing_description: testing_description, testing_freq:task_testing_activity_hints.testing_freq,
				testing_owner: task_testing_activity_hints.testing_owner, testing_objectives: testing_objectives,
				testing_method : testing_method, additional_guidance: additional_guidance,
				risk_area_name: risk_json.risk_area_name, unique_testing_act_id:testing_activity.unique_testing_act_id,
				testing_activity_undertaken : testing_activity_undertaken, rag:addtional_rags_assoc[rag].nickname, ragBgColor : ragBgColor , require_remediation: requireRemediation,
				curBinFiles:curBinFiles , cur_files_json: cur_files_json , action_title : current_task.object_data.headerinfo.risk_json.actions.action_title , action_description : current_task.object_data.headerinfo.risk_json.actions.action_description , current_task : current_task , current_task_Id : current_task_Id , actionBinFiles : actionBinFiles , action_note_undertaken : action_note_undertaken , followupBinFiles : followupBinFiles , parent_ref_id : ref_id})
		}

    }
    closeModal = (event)=>{
        event.preventDefault();
        console.log("in closeModal ");
        //if(this.state.is_form_modified){
        //    this.props.refreshParent('openDataLostWarningModal');
        //}else{
            this.props.refreshParent(null);
		//}
    }
	refreshStateRedirection = () => {
		localStorage.removeItem('cmp-index');
		localStorage.removeItem('cmp-get_mgr_index');
		if(document.referrer){

		  if(document.referrer.match(/tmview/g)){
				window.location.assign(document.referrer);
		  }else{
				window.location.href = process.env.REACT_APP_CMP_MODULE_URL;
		  }
		  //
		}else{
		  window.location.href = process.env.REACT_APP_CMP_MODULE_URL;
		}
	}
	closeModalSilent = ()=>{
        //event.preventDefault();
        this.props.refreshParent();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value , is_form_modified : true});
    }

	saveAction = (event) =>{
		event.preventDefault();
        if(this.state.action_note_undertaken.trim() == ''){
            this.setState({IsNoteProvided: true})
        }else{
			var bin_files = [...this.state.actionBinFiles, ...this.state.followupBinFiles];
			var action_data = {action: 'COMP_CMP_FOLLOWUP_SAVED',actiondate :Date.now(), assigned_to: Store.getStoreData('cmp-loggedin_user_info').ID, cur_lane : 'COMP_CMP_FOLLOWUP_SAVED'};
			var current_task = this.state.current_task;
			delete current_task.object_data.bin_files;
			var result = [{action_note_undertaken:this.state.action_note_undertaken}];
			current_task.object_data.result = result;
			current_task.object_data.bin_files = bin_files;
			current_task.action_data = action_data;
			//console.log("current_task in saveAction==>"+JSON.stringify(current_task));

			var task_data = {};
			task_data.task_json = current_task;
			task_data.id = this.state.current_task_Id;
			task_data.assigned_to = this.state.testing_owner;
			task_data.performed_by = Store.getStoreData('cmp-loggedin_user_info').ID;


			this.props.saveAction([task_data , this.state.parent_ref_id]);

			//this.refreshPage();
			//console.log("current_task in saveAction==>"+JSON.stringify(task_data));
		}
	}

	completeAction = (event) =>{
        if(this.state.action_note_undertaken.trim() == ''){
            this.setState({IsNoteProvided: true})
        }else{
			event.preventDefault();
			var bin_files = [...this.state.actionBinFiles, ...this.state.followupBinFiles];
			let current_task = this.state.current_task;
			delete current_task.object_data.bin_files;
			var result = [{action_note_undertaken:this.state.action_note_undertaken}];
			current_task.object_data.result = result;
			current_task.object_data.bin_files = bin_files;
			//current_task.task_data = { "module_id": "cmp", "last_action": "COMP_CMP_FOLLOWUP_COMPLETE", "actiondate": Date.now(), 'task_type' : 'COMP_CMP_FOLLOWUP', 'cur_lane': 'COMP_CMP_FOLLOWUP_COMPLETE', completed_date: Date.now()};
			current_task.task_data.actiondate = Date.now();
			current_task.task_data.cur_lane = 'COMP_CMP_FOLLOWUP_COMPLETE';
			current_task.task_data.completed_date = Date.now();

			var action_data = {action: 'COMP_CMP_FOLLOWUP_COMPLETE',actiondate :Date.now(), assigned_to: Store.getStoreData('cmp-loggedin_user_info').ID, cur_lane : 'COMP_CMP_FOLLOWUP_COMPLETE' , performed_by: Store.getStoreData('cmp-loggedin_user_info').ID};
			current_task.action_data = action_data;
			var task_data = {};
			task_data.task_json = current_task;
			task_data.id = this.state.current_task_Id;
			task_data.assigned_to = this.state.testing_owner;
			task_data.performed_by = Store.getStoreData('cmp-loggedin_user_info').ID;
			//console.log("current_task_data==>"+JSON.stringify(task_data));
			this.props.saveCompleteAction([task_data,this.state.testing_activity]);
			//this.closeModal(event);
			//this.refreshPage();

		}
	}

	refreshPage = () => {
        window.location.reload();
    }


    processAddTask = (result) =>{
        console.log("result",result);
		//this.closeModalSilent();
      }
	filesRemoved = (jsonObj) => {
		if(jsonObj.files_to_show_size === 0){
			this.setState({followupBinFiles: [], is_form_modified: false});
		}
	}
    filesLoaded = (files) => {
        let me = Store.getStoreData('cmp-loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
                files[i].type = "complete_resource"
            }
        }
        console.log('files in Modal', files);
        this.setState({binFiles: files});
    }
    handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    handleDownloadActionFiles = (index) => {
        let cur_file = this.state.actionBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

	genKey = (length) => {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

    followupFilesLoaded = (jsonObj) => {
        let me = Store.getStoreData('cmp-loggedin_user_info');
		let followupBinFiles = this.state.followupBinFiles;

		let file_exists = {};
		let curBinFiles_1 = [];
        if(jsonObj.command === 'add'){
			for(let i=0; i < jsonObj.files.length; i++) {
				jsonObj.files[i].name = jsonObj.files[i].name.replace('&', '');
				if(!('user_id' in jsonObj.files[i])) {
					jsonObj.files[i].user_id = me.ID;
					jsonObj.files[i].upload_date = Date.now();
					jsonObj.files[i].type = "followup_action_resource";
				}
				if(typeof file_exists[jsonObj.files[i].name] === "undefined"){
					followupBinFiles.push(jsonObj.files[i]);
					file_exists[jsonObj.files[i].name] = true;
				}
				curBinFiles_1 = followupBinFiles.filter((followupBinFiles, index, self) => index === self.findIndex((t) => (t.name === followupBinFiles.name && t.uid === followupBinFiles.uid)));
				//curBinFiles.push(jsonObj.files[i]);
			}
			//console.log('files in Modal', files);
			this.setState({followupBinFiles: curBinFiles_1 , is_form_modified: true});
		}else{//delete
			for(let i=0; i < followupBinFiles.length; i++) {
				if(followupBinFiles[i].uid.toString() === jsonObj.deleted_uid.toString()){
					followupBinFiles.splice(i,1);
				}
			}
			this.setState({followupBinFiles: followupBinFiles});
		}
    }

	escapeHTML = (unEscapedHTML) => {
		return unEscapedHTML.replace(/</g,'&lt;').replace(/>/g,'&gt;');
	}

	render()
	{
		console.log("completectionmodal state,",this.state);
        if (!this.state.ready) {
            return (<div>Loading...</div>);
        }
		//console.log("in render actionBinFiles.length==>"+this.state.actionBinFiles.length);
       // console.log("task_data_commodal", this.state.cur_files_json)
       //console.log("require_remediation",this.state.require_remediation)
       //console.log("ragBgColor in render==>",this.state.ragBgColor)
        return (
            <div>
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>{this.state.testing_activity}</MRModalHeaderText>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel style={{color:"#FF0000", marginLeft:"40%"}}>{this.state.submit_msg}</MRModalLabel>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}} ref={el => { this.el = el; }} ></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalSummary>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Testing Activity</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{this.state.testing_activity}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Description</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.testing_description}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Risk Area</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{this.state.risk_area_name}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Frequency</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{this.state.testing_freq}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Owner </MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
                                <MRModalLabel>{Store.getStoreData('contact_list')[this.state.testing_owner]}</MRModalLabel>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Testing </MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Objectives</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.testing_objectives}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>High Level </MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Testing Method</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%" }}>
								<div dangerouslySetInnerHTML={{__html: this.state.testing_method}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Additional</MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Guidance</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%"}}>
								<div dangerouslySetInnerHTML={{__html: this.state.additional_guidance}} />
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle >Useful Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%" , float: "right"}}>
									<div style={{padding: "10px"}}>
										<Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
										<Style.ModalNameInputDiv>
											<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.curBinFiles} showFileButton={false} />
										</Style.ModalNameInputDiv>
									</div>
									<div style={{clear: "both", marginTop:"10px"}}></div>

                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Extra Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%", float: "right"}}>
									<div style={{padding: "10px"}}>
										<Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
										<Style.ModalNameInputDiv>
											<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} showFileButton={false} />
										</Style.ModalNameInputDiv>
									</div>
									<div style={{clear: "both", marginTop:"10px"}}></div>
                            </PartialDiv>

                            <div style={{clear: "both",marginTop: "15px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Testing Activities</MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Undertaken</MRModalLabelTitle>
                            </PartialDiv>
                            <div style={{marginLeft : "245px" , marginTop : "-100px", width:"78%"}}>
                                <div dangerouslySetInnerHTML={{__html: this.state.testing_activity_undertaken}} />
                            </div>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Requires</MRModalLabelTitle>
                                <div style={{clear: "both"}}></div>
                                <MRModalLabelTitle>Remediation?</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%" }}>
                                <MRModalLabel>{this.state.require_remediation}</MRModalLabel>
								<div style={{width:"50%" , float: "right"}}>
									<div style={{float:"left" , width:"25%" , fontWeight: "bold" , color : "#000000"}}>Risk Rating</div>
								    <div style={{width:"75%"}}><div style={{float:"right" , width : "40px" , marginRight:"100px" , fontWeight: "bold"}}>{this.state.rag}</div><div style={{marginLeft:"100px" , marginTop:"1px" , width : "10px" , height : "10px" , backgroundColor: this.state.ragBgColor , borderRadius: "50%" }}></div ></div>
									<div style={{clear: "both"}}></div>
								</div>

                            </PartialDiv>



                        <div style={{clear: "both",marginTop: "25px"}}></div>
                        <PartialDiv style={{width:"22%"}}>
                            <MRModalLabelTitle>Action Title</MRModalLabelTitle>

                        </PartialDiv>
                        <PartialDiv style={{width:"78%"}}>
                            <MRModalLabel>{this.state.action_title}</MRModalLabel>

                        </PartialDiv>

                        <div style={{clear: "both",marginTop: "25px"}}></div>
                        <PartialDiv style={{width:"22%"}}>
                            <MRModalLabelTitle>Action Description</MRModalLabelTitle>

                        </PartialDiv>
                        <PartialDiv style={{width:"78%"}}>
                            <MRModalLabel>{this.state.action_description}</MRModalLabel>

                        </PartialDiv>

                            <div style={{clear: "both",marginTop: "25px"}}></div>
                            <PartialDiv style={{width:"22%"}}>
                                <MRModalLabelTitle>Action Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{width:"78%", float: "right"}}>
									<div style={{padding: "10px"}}>
										<Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
										<Style.ModalNameInputDiv>
											<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.actionBinFiles} showFileButton={false} />
										</Style.ModalNameInputDiv>
									</div>
									<div style={{clear: "both", marginTop:"10px"}}></div>

                            </PartialDiv>
						    <div style={{clear: "both",marginTop: "25px"}}></div>

                        </MRModalSummary>

						<div style={{clear: "both",marginTop: "25px"}}></div>
                        <PartialDiv style={{width:"22%", paddingLeft:"32px"}}>
                            <MRModalLabelTitle>Action Taken</MRModalLabelTitle>

                        </PartialDiv>
                        <PartialDiv style={{width:"78%"}}>
						<textarea name ="action_note_undertaken" rows="4" cols="80" value={this.state.action_note_undertaken} style={{width:"calc(99% - 40px)", marginLeft:"30%", marginTop: "-36px", border: "1px solid #ffffff", borderRadius: "3px", boxShadow: "0 0 4px #c4c4c4", padding: "5px", color: "#000000", fontWeight: "bold" ,fontFamily: "Montserrat,sans-serif", resize: "none"}} onChange={this.handleChange}>
						</textarea>
						{
							(() => {
								if(this.state.IsNoteProvided){
									return(
										<div style={{marginLeft: "calc(45% - 100px)", marginTop: "5px"}}>
											<div style={{clear: "both"}}></div>
											<label style={{color:"#FF0000"}}>Please provide action note.</label>
										</div>
										)
								}
							})()
						}


                        </PartialDiv>
                        <div style={{clear: "both",marginTop: "25px"}}></div>

						<div style={{display: 'flex',marginTop: "25px"}}>
										<Style.ModalNameLabelDiv style={{width:"20%", marginRight: "50px",paddingTop: "3px" , color: "#000000" , fontWeight: "bold", paddingLeft:"32px"}}>Attachments</Style.ModalNameLabelDiv>
										<Style.ModalNameInputDiv style={{ float: "right" ,marginRight: "50px"}}>
											{
												(() => {
													if(('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED") && ('cur_lane' in this.state.current_task && this.state.current_task.cur_lane.toString() !== "COMP_CMP_FOLLOWUP_COMPLETE")){
														return (<Dropzone onFilesAdded={this.followupFilesLoaded} initFiles={this.state.followupBinFiles} onRemoveFile={this.filesRemoved} />);
													}else{
														return (<Dropzone onFilesAdded={this.followupFilesLoaded} initFiles={this.state.followupBinFiles} onRemoveFile={this.filesRemoved} showFileButton={false}/>);
													}
												})()
								            }
											{/*<Dropzone onFilesAdded={this.followupFilesLoaded} initFiles={this.state.followupBinFiles} onRemoveFile={this.filesRemoved}/>*/}
										</Style.ModalNameInputDiv>
						</div>
						<div style={{clear: "both"}} ></div>

                        <MRModalDivider />

                    </MRModalBody>
                    <MRModalFooter>
                        {
                            (() => {
								//if(this.props.taskId > 0){
								//if(typeof this.state.task_data.cur_lane !== "undefined"){
									//if(this.props.my_task.cur_lane.toString() !== "COMP_CMP_COMPLETED" && this.props.current_task.cur_lane.toString() !== "COMP_CMP_FOLLOWUP_COMPLETE"){
										return (
										<div>
										<CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeAction}>Complete</CatBtn>
										<CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveAction}>Save & Close</CatBtn>
										</div>
										)
									//}
								//}
								//}
                            })()
                        }

                        <CatBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black", float: "right"}} onClick={this.closeModal}>Cancel</CatBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalFooter>
                </MRModalContainer>
            </div>
		);
	}
}

export default CompleteActionModal;
