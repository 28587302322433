import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents'
import ClarificationMessage from '../CA/ClarificationMessage'
import Originalanswer from '../CA/Originalanswer';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox'

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;


class AcceptanceModal extends React.Component
{
    state = {
        company: '',
        query: '',
        answer: '',
        binFiles: [],
        cur_files_json: null,
        extbinFiles: [],
        ext_cur_files_json: null,
        accept_mode: null,
        index: -1,
        form_data: null,
        id:0,
        category_id: 0,
        ext_advice: false,
        ext_advice_txt: '',
        ext_advice_provider:'',
        ext_ref_number: '',
        ext_contact_name: '',
        accept_note: '',
        clarifications: [],
        answered_by: 0,
        ready: false, 
        tags: [],
        acceptance_recever_id: 0,
        custom_fields: {},
        list_answrere: [],
        enabled_topics: false,
        topic: {},
        alert_param: null,
        alert_answer: null,
        is_loading: false
    };

    constructor(props) {
        super(props);
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }



    processAnswerQuery = (result) => {
        this.setState({is_loading: false})
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let msg = 'The advice request has been saved.'
        let title = 'Task update'
        if(this.state.accept_mode == 'clarify') {
            msg = 'Your clarification request has been successfully submitted to the Compliance team for review.';
            title = 'Task update';
            // alert('Your clarification request has been successfully submitted to the Compliance team for review.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
            this.setState({alert_param: alert_param})
        }else{
            msg = 'Your acknowledgment has been sent to the compliance team.';
            title = 'Task update';
            // alert('Your acknowledgment has been sent to the compliance team.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
            this.setState({alert_param: alert_param})
        }
    }

    answerCARQuestion(submit_form){
        if(this.state.accept_note === '' && this.state.accept_mode == 'clarify') {
            let alert_param = {title: 'Warning', message: 'Clarification note is mandatory.', ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];
        let bin_files = [...bin_files1, ...bin_files2];

        let clarifications = this.state.clarifications;
        // clarifications.push({'text' : this.state.accept_note })
        let postData = { "command": "add_clarification_request", query_id:this.state.id, answer: this.state.accept_note, 
                        submit:submit_form, bin_files: bin_files, category_id:this.state.category_id,
                        accept_mode: this.state.accept_mode,
                        clarifications: clarifications,
                        answered_by: this.state.answered_by,
                        clarification_text: this.state.accept_note,
                        index: this.state.index, task_id: this.state.form_data === null ? 0 : this.state.form_data.id,
                        acceptance_recever_id:this.state.acceptance_recever_id,
                        asker_id: this.state.form_data.contact_id };
        console.log('postData', postData);
        let api = new APICall();
        api.indexCommand(postData, this.processAnswerQuery);
        this.setState({is_loading: true})
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }


    isLoadTags = (tags) => {
        let rawtags = Store.getStoreData('tags');
        console.log("rawtags", rawtags);
        let otags = [];
        rawtags.forEach((top) => {
            if(Object.keys(tags).length === 0){
                otags.push({
                    name: top.TagName,
                    id: top.ID,
                    styledcomponent: UnselectedPill,
                    selected: false
                });
            }else {
                if(tags[top.ID.toString()] ===  top.TagName) {
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: SelectedPill,
                        selected: true
                    });
                }else{
                    otags.push({
                        name: top.TagName,
                        id: top.ID,
                        styledcomponent: UnselectedPill,
                        selected: false
                    });
                }
            }
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let ftags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    ftags.push(otags[j]);
                }
            }
        }
        return ftags;
    }


    componentDidMount() {
        let index = Store.getStoreData('a_index') === null ? 0 : Store.getStoreData('a_index');
        let moduleconfig = Store.getStoreData('moduleconfig');
        let contact_id = Store.getStoreData('contact_id');
        //console.log('index in componentDidMount', index);

        let form_data = {};
        form_data = Store.getStoreData('cur_accept_modal_data');
        let task_json = JSON.parse(form_data.task_json);
        let answered_by = ('answered_by' in task_json.object_data) ? task_json.object_data.answered_by : 0
        //console.log('form_data', form_data);
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = parseInt(form_data.company_id) < 100000 ? Store.getStoreData('company').CompanyName : gc_assoc[(parseInt(form_data.company_id)%100000).toString()].company_name;
        
        let gc_company_id = parseInt(form_data.company_id) < 100000 ? form_data.company_id : form_data.company_id%100000;
        let list_answrere = Utils.showCompanyUsers(gc_company_id,'answrer');

        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }

        let tags = this.isLoadTags(ptags);

        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }

        let cur_files_json = [];
        let ext_cur_files_json = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
        }

        let custom_fields = this.state.custom_fields;
        custom_fields.enabled = false;
        custom_fields = ('custom_fields' in  task_json.object_data) ? task_json.object_data.custom_fields : custom_fields;

        console.log("custom_fields =====>", custom_fields);

        let answer = task_json.object_data.answer;
        if(Utils.isBase64(answer)){
            answer = window.decodeURIComponent(atob(answer))
        }

        let enabled_topics = this.state.enabled_topics;
        let topic = this.state.topic;
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }
        // let alert_answer = ('original_lane' in form_data && form_data.original_lane ==='answer') ? true : false; 
        if('original_lane' in form_data && form_data.original_lane ==='answer'){
            let contacts = Store.getStoreData('contacts');
            let alert_answer = {title: form_data.alt_ref_id, message: 'This Compliance Advice Request was answered by '+ contacts[answered_by]+' on '+ Utils.timeToDate(task_json.object_data.answer_date), ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {id: 0}}
            this.setState({alert_answer: alert_answer})
        }



        // setTimeout(this.checkEmailNotification(form_data.id), 5000)



        this.setState({ company: gc_company,cur_files_json: cur_files_json, ext_cur_files_json:ext_cur_files_json,
                        index: index, form_data:form_data, query: task_json.object_data.query, 
                        answer: answer,
                        answered_by: answered_by,
                        clarifications: ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [],
                        ext_advice: ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : '',
                        ext_advice_txt: ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '',
                        ext_advice_provider: ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : '',
                        ext_ref_number: ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '',
                        ext_contact_name: ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '',
                        id: form_data.id, ready: true, tags: tags, custom_fields:  custom_fields, 
                        list_answrere: list_answrere, enabled_topics: enabled_topics,topic: topic});
    }


    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }


    handleCategoryChange = (evt) => {
        evt.preventDefault();
        let review_id = evt.target.value;
        this.setState({})
    }

    handleCheckboxChange = (event) => {
        this.setState({ ext_advice: event.target.checked })
    }
    
    handleAcceptMOde = (mode) => (event) => {
        event.preventDefault();
        switch(mode) {
            case 0: this.setState({accept_mode: 'clarify'}); break;
            case 1: this.setState({accept_mode: 'acknowledge'}); break;
            // case 2: this.setState({accept_mode: 'accept'}); break;
        }
    }

    handleAcceptCancel = () => {
        this.setState({accept_mode: null})
    }

    showCustomFields = (field, data_container) => {
        return (
            <div style={{display:"contents"}}>
                <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv style={{width: '93%'}}>
                    <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true}/>
                </Style.ModalNameInputDiv>
            </div>
        );
    }

     alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null})
        this.props.refreshParentCallback(stack);
    }

    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null, alert_answer: null})
    }

    render()
    {
        // let contact = Store.getStoreData('contact');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let contacts = Store.getStoreData('contacts');
        let form_data = Store.getStoreData('cur_accept_modal_data');
        let contact_id = form_data.contact_id;
        let contact = contacts[contact_id];
        let task_json = JSON.parse(form_data.task_json);
        let object_data = task_json.object_data;
        console.log('object_data', object_data);
        let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
        console.log('clarifications', clarifications);
        let clLength = clarifications.length
        let last_answer = clarifications.length === 0 ? object_data.answer : clarifications[clLength -1].note;
        
        if(Utils.isBase64(last_answer) && clLength  === 0){
            last_answer = window.decodeURIComponent(atob(last_answer))
        }

        let last_answerer = clarifications.length === 0 ? contacts[object_data.answered_by.toString()] : contacts[clarifications[clLength -1].performed_by.toString()]
        let last_answer_utime = clarifications.length === 0 ? object_data.answer_date : clarifications[clLength -1].actiondate
        let last_answer_time_obj = new Date(last_answer_utime)
        // let last_answer_time = last_answer_time_obj.toLocaleString('en-GB').replace(/,/g, '');
        let last_answer_time = last_answer_time_obj.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')
        let orig_answer = {actiondate: object_data.answer_date, note: object_data.answer, performed_by: object_data.answered_by }
        //console.log('orig_answer', orig_answer)
        clarifications.reverse()
        // clarifications.push(orig_answer)
        //console.log('out clarifications', clarifications)
        let cur_assigned = form_data.cur_assigned_to === 0 ? "Compliance Advisers" : contacts[form_data.cur_assigned_to.toString()];
        if('cur_recever_id' in task_json.object_data && form_data.cur_assigned_to === 0) {
          cur_assigned = task_json.object_data.cur_recever_id.toString() === "0" ? "Compliance Advisers" : Store.getStoreData('org_teams')[task_json.object_data.cur_recever_id.toString()].name;
        }
        return (
            <div>
            <Style.ModalContainer>
                {
                    (() => {
                        if(this.state.is_loading) {
                            return <CSLLoader style={{position: 'absolute'}} />
                        }
                    })()
                }
                <Style.ModalHeaderText>Provide Compliance Advice</Style.ModalHeaderText>
                <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <Style.ModalBody>
                    <Style.ModalBodyInner>
                        <Style.ModalContainertop>
                            {
                                (() => {
                                    if(Store.getStoreData('has_gc')) {
                                        return (<div style={{display:"contents"}}>
                                                    <Style.ModalNameLabelDiv>Company</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                        <Style.ModalNameInputDiv>
                                                            {this.state.company}
                                                        </Style.ModalNameInputDiv>
                                                    </Style.ModalNameInputDiv>
                                                </div>
                                                )
                                    }
                                })()
                            }
                            <Style.ModalNameLabelDiv>CAR Reference</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalNameInputDiv>{task_json.object_data.ref_id}</Style.ModalNameInputDiv>
                            </Style.ModalNameInputDiv>

                            {
                                (() => {
                                    if(this.state.enabled_topics) {
                                        return (
                                            <div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Topic</Style.ModalNameLabelDiv>
                                                    <Style.ModalNameInputDiv>
                                                        <Style.ModalNameInputDiv>{this.state.topic.value}</Style.ModalNameInputDiv>
                                                    </Style.ModalNameInputDiv>
                                            </div>
                                            )
                                    }

                                })()
                            }


                            <Style.ModalNameLabelDiv>Currently assigned to</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalNameInputDiv>{cur_assigned}</Style.ModalNameInputDiv>
                            </Style.ModalNameInputDiv>
                            {
                                (() => {
                                    if(Store.getStoreData('moduleconfig').specific_user_assignment_enabled && Store.getStoreData('moduleconfig').requesting_user_assignment_enabled) {
                                        return(
                                            <div style={{display:"contents"}}>
                                                <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
                                                <Style.ModalNameInputDiv>
                                                    <Style.MRModalSelect name="acceptance_recever_id" value={this.state.acceptance_recever_id} onChange={this.handleChange}>
                                                        {
                                                            this.state.list_answrere.map((item) => {
                                                                {
                                                                    return (
                                                                        <option key={item.ID} value={item.ID}>{item.contact_name}</option>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </Style.MRModalSelect>
                                                </Style.ModalNameInputDiv>
                                            </div>
                                            )

                                    }
                                })()
                            }
                            <Style.ModalNameLabelDiv>Advice Request</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalTextArea name="query" defaultValue={this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true} />
                            </Style.ModalNameInputDiv>
                            
                            <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                {/*<Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />*/}
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} />
                            </Style.ModalNameInputDiv>


                            <Style.ModalNameLabelDiv>Author</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv>
                                <Style.ModalInput name="contact" defaultValue={contact} readOnly={true} style={{width:'98%'}}/>
                            </Style.ModalNameInputDiv>
                        </Style.ModalContainertop>


                        <Style.ModalNameInputDiv>
                            <Style.ModalWiderDiv>
                                <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                <Style.ModalClearDiv />
                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', padding:'10px', width: '93%' }}>
                                    {last_answerer}&nbsp;&nbsp;&nbsp;&nbsp;{last_answer_time}
                                </Style.ModalWiderHeaderDiv>
                                <div style={{backgroundColor: '#FFFFFF', width: '95%'}}>
                                    {/*<Style.ModalTextAreaInvisible style={{width: '98%'}} name="last_answer" defaultValue={last_answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true} />*/}
                                    <div dangerouslySetInnerHTML={{__html: last_answer}} style={{textAlign: "justify", padding: "10px"}}></div>
                                    <Style.CancelSubmitButtonOuterContainer>
                                        <Style.ClarifyButton onClick={this.handleAcceptMOde(0)}>Clarification</Style.ClarifyButton>
                                        <Style.ClarifyButton onClick={this.handleAcceptMOde(1)}>Acknowledge</Style.ClarifyButton>
                                        {/* <Style.ClarifyButton onClick={this.handleAcceptMOde(2)}>Accept</Style.ClarifyButton> */}
                                    </Style.CancelSubmitButtonOuterContainer>
                                    
                                    {
                                        (() => {
                                            if(this.state.accept_mode !== null){
                                                let default_message = '';
                                                switch(this.state.accept_mode) {
                                                    case 'clarify' : default_message = 'Clarification notes...'; break;
                                                    case 'acknowledge' : default_message = 'Acknowledgment notes...'; break;
                                                    // case 'accept' : default_message = 'Acceptance note...'; break;
                                                }
                                                return (
                                                    <div style={{border: '2px solid #D5D5D5'}}>
                                                        <div style={{width: '99%'}}><Style.ModalTextAreaInvisible name="accept_note" placeholder={default_message} value={this.state.accept_note.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} /></div>
                                                        <Style.CancelSubmitButtonOuterContainer>
                                                            <Style.CancelButton onClick={this.handleAcceptCancel}>Cancel</Style.CancelButton>
                                                            <Style.SubmitButton onClick={() => this.answerCARQuestion(1)}>Submit</Style.SubmitButton>
                                                        </Style.CancelSubmitButtonOuterContainer>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                            </Style.ModalWiderDiv>
                        </Style.ModalNameInputDiv>


                        {
                            (() => {
                                if(Store.getStoreData('role') !== 'team') {
                                    return(                        
                                        <Style.ModalNameInputDiv>
                                        <Style.ModalWiderDiv>
                                            <Style.ModalClearDiv />
                                            <Style.ModalWiderHeaderDiv style={{color: '#ffffff', padding: '10px',width: '93%' }}>
                                                Tags
                                            </Style.ModalWiderHeaderDiv>
                                            <div style={{backgroundColor: '#FFFFFF', width: '95%'}}>
                                                {
                                                   this.state.tags.map((tag, index) => {
                                                    if(tag.selected){
                                                            return (<div style={{width:"98%", display:"inline"}}>
                                                                <tag.styledcomponent key={index}>{tag.name}</tag.styledcomponent>
                                                                </div>);
                                                        }
                                                            
                                                        })
                                                    
                                                }
                                            </div>
                                        </Style.ModalWiderDiv>
                                    </Style.ModalNameInputDiv>
                                    )
                                }
                            })()
                        }

                        {
                            clarifications.map((c,i) => {
                                if(i > 0)return (<ClarificationMessage cardata={form_data} clarification={c} />)
                            })
                        }
                        <div>
                        {
                            clarifications.map((c,i) => {
                                if(i == 0)return (<Originalanswer cardata={form_data} clarification={orig_answer} />)
                            })
                        
                        }
                        </div>  
                        <Style.ModalNameInputDiv>
                            <Style.ModalWiderDiv>
                            <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                            {
                                (() => {
                                    if(this.state.custom_fields.enabled) {
                                        if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                            const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                return item.name === "reference_number"
                                            })

                                            if(('value' in field) && field.value !=='') {
                                                return this.showCustomFields(field, "custom_terminology_fields");
                                            }
                                        }
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.custom_fields.enabled) {
                                        if (this.state.custom_fields.additional_fields.length !== 0) {
                                            let custom_html = [];
                                            this.state.custom_fields.additional_fields.forEach((item) => {
                                                if(('value' in item) && item.value!=='') {
                                                    const field = this.showCustomFields(item, "additional_fields");
                                                    custom_html.push(field);
                                                }
                                            })
                                            return custom_html;
                                        }
                                    }
                                })()
                            }
                            </Style.ModalWiderDiv>
                        </Style.ModalNameInputDiv>

                        {
                            (() =>{
                                if (this.state.ext_advice){
                                    return(
                                        <Style.ModalNameInputDiv>
                                            <Style.ModalWiderDiv>
                                                <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                {
                                                    (() => {
                                                        if(this.state.ext_advice_provider != ""){
                                                        return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Advice Providers
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_advice_provider.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                    External advice required
                                                </Style.ModalWiderHeaderDiv>
                                                    <Style.ModalTextArea style={{width: '93.3%'}} value={this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>

                                                {
                                                    (() => {
                                                        if(this.state.ext_ref_number != ""){
                                                            return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Reference Number
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.ext_contact_name != ""){
                                                            return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Contact Name
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                 (() => {
                                                     if(this.state.ext_cur_files_json.length > 0) {
                                                         return(<div>
                                                                     <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                   Attachments
                                                                     </Style.ModalWiderHeaderDiv>
                                                                     <div style={{width: '93.3%'}}>
                                                                           <Dropzone initFiles={this.state.ext_cur_files_json} addnew={false} />
                                                                     </div>
                                                                 </div>
                                                             )
                                                     }
                                                 })()
                                                }
                                            </Style.ModalWiderDiv>
                                        </Style.ModalNameInputDiv>

                                    )
                                }
                            })()
                        }
                    </Style.ModalBodyInner>
                </Style.ModalBody>
                <Style.CancelSubmitButtonOuterContainer>
                    <Style.CancelButton onClick={this.closeModal}>Close</Style.CancelButton>
                </Style.CancelSubmitButtonOuterContainer>
            </Style.ModalContainer>
            <AlertBox alertParam = {this.state.alert_param} />
            <AlertBox alertParam = {this.state.alert_answer} />
            </div>
        );
    }
}
export default AcceptanceModal;