import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPen} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents';
import ClarificationMessage from '../CA/ClarificationMessage';
import HtmlEditor from '../Common/HtmlEditor';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox'

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #333;
    font-weight: 600;
`;

const ModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #234062;
    font-family: 'Montserrat', sans-serif;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #333;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
    cursor:pointer;
`;

const ModalContainertop = styled.div`
    display: grid;
    // grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
    background-color:#edf0f1;
    margin-top: 10px;
    border-radius: 3px;
    border-color: #edf0f1;
    margin-top: 20px;
`;


const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    // padding-right: 20px;
    // padding-bottom: 20px;
    text-align: right;
    padding: 20px;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const PublishButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #04ada8;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: auto;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    cursor:pointer;
`;

const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;

const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const ModeltopRevertText = styled.div`
    display: grid;
    grid-gap: 5px;
    padding: 10px;
    border-radius: 3px;
    padding: 10px;
    border-color: #f6d6d6;
    color: #e27575;
    background-color: #f6d6d6;
`;
class ShowAnsweredRevretModal extends React.Component
{
      state = {
          header_text: '',
          query: '',
          answer: '',
          clarification_text: '',
          binFiles: [],
          cur_files_json: null,
          extbinFiles: [],
          ext_cur_files_json: null,
          tags:[],
          ref_id: '',
          task_id: 0,
          assign_to: 0,
          editquestionmode:false,
          edittagmode: false,
          editanswermode: false,
          editclmode: false,
          rejject_reason: '',
          company_id : 0,
          clarifications: [],
          ext_advice: false,
          ext_advice_provider: '',
          ext_advice_txt: '',
          ext_ref_number: '',
          ext_contact_name: '',
          from_api: 0,
          tags_count: 0,
          revert_car: false,
          revert_reason: '',
          accept_mode: '',
          show_faq: false,
          faq_query: '',
          faq_answer: '',
          jumping:false,
          revert_by: 0,
          revert_reason: '',
          asker_id: 0,
          cur_recever_id: 0,
          ready:false,
          custom_fields: {},
          enabled_topics: false,
          topic: {},
          alert_param: null,
          is_loading: false
      };

      constructor(props) {
          super(props);
      }


    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        this.setState({binFiles: files});
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    selectOrUnselectTopic = (tagid) => (event) => {
          event.preventDefault();
          let { tags } = this.state;
          tags.forEach((tag) => {
              if (tag.id === tagid) {
                  if (tag.selected) {
                      tag.selected = false;
                      tag.styledcomponent = UnselectedPill;
                  } else {
                      tag.selected = true;
                      tag.styledcomponent = SelectedPill;
                  }
              }
          })

          this.setState({ tags });
      }

      processAnswerQuery = (result) => {
        let msg = 'Your answer has been sent to a manager for approval.'
        let title = 'Alert'
        // alert("Your answer has been sent to a manager for approval.")
        let stack = {};
        stack['task_id'] = result.error_code === 0 ? result.task_id : 0
        stack['task'] = result.error_code === 0 ? result.task : {};
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: stack}
        this.setState({alert_param: alert_param, is_loading: false})
      }

      answerCARQuestion(submit_form){
          let selectedtags = {};
          this.state.tags.forEach((tag) => {
              if (tag.selected) selectedtags[tag.id.toString()] = tag.name;
          });

          let answer = Store.getStoreData('answer');
          if(answer === null){
              answer = this.state.answer;
          }
          let answer_summary = btoa(window.encodeURIComponent(answer));

          let bin_files1 = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
          let bin_files2 = this.state.ext_cur_files_json === null ? this.state.extbinFiles : [...this.state.extbinFiles, ...this.state.ext_cur_files_json];

          let bin_files = [...bin_files1, ...bin_files2];
          let postData = { "command": "add_answer", answer: answer_summary, submit:submit_form, bin_files: bin_files,
                          ext_advice: this.state.ext_advice, ext_advice_txt: this.state.ext_advice_txt,query_id: this.state.task_id,
                          task_id: this.state.task_id, asker_id: 0, tags: selectedtags, from_api: this.state.from_api, cur_recever_id: this.state.cur_recever_id,
                          send_approval:true
                        };
          console.log('postData', postData);
          let api = new APICall();
          api.indexCommand(postData, this.processAnswerQuery);
          this.setState({is_loading: true})
      }


      handleChange = (event) => {
          this.setState({ [event.target.name] : event.target.value })
      }

      processApprovalData = (result) => {
        let moduleconfig = Store.getStoreData('moduleconfig');
        let task_json = JSON.parse(result.task_json);
        if (task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : this.genKey(10);
            })
        }

        let {header_text,query, answer, cur_files_json,ext_cur_files_json, tags, ref_id, 
          task_id,assign_to,editquestionmode,edittagmode,editanswermode,company_id,
          clarifications,ext_advice,ext_advice_provider,ext_advice_txt,ext_ref_number,
          ext_contact_name,from_api,tags_count, accept_mode, revert_by, 
          revert_reason,asker_id, cur_recever_id, 
          custom_fields, enabled_topics,topic} = this.state;

        let cur_files_json_a = [];
        let ext_cur_files_json_b = [];
        if(task_json.object_data.bin_files.length !== 0) {
            task_json.object_data.bin_files.forEach((item) => {                 
                if("type" in item && item.type === 'external'){
                    ext_cur_files_json_b.push(item);
                }else{
                    cur_files_json_a.push(item);
                }
            });                 
        }

        clarifications =  ('clarifications' in task_json.object_data) ? task_json.object_data.clarifications : [];
        ext_advice = ('ext_advice' in task_json.object_data) ? task_json.object_data.ext_advice : false;
        ext_advice_txt = ('ext_advice_txt' in task_json.object_data) ? task_json.object_data.ext_advice_txt : '';

        ext_advice_provider = ('ext_advice_provider' in task_json.object_data) ? task_json.object_data.ext_advice_provider : '';

        ext_ref_number = ('ext_ref_number' in task_json.object_data) ? task_json.object_data.ext_ref_number : '';
        ext_contact_name = ('ext_contact_name' in task_json.object_data) ? task_json.object_data.ext_contact_name : '';
        
        custom_fields.enabled = false;
        custom_fields = ('custom_fields' in  task_json.object_data) ? task_json.object_data.custom_fields : custom_fields;

        header_text = task_json.object_data.query;
        query = task_json.object_data.query;
        // answer = task_json.object_data.answer;
        answer = task_json.object_data.answer;
        if(Utils.isBase64(answer)){
          answer = window.decodeURIComponent(atob(answer))
        }
        task_id = result.id;
        // cur_files_json = task_json.object_data.bin_files;
        // binFiles = task_json.object_data.bin_files;
        if('ref_id' in task_json.object_data) {
            ref_id = task_json.object_data.ref_id;
        }
        assign_to = ('asked_by' in task_json.object_data) ? task_json.object_data.asked_by : 0;
        accept_mode = ('accept_mode' in task_json.object_data) ? 'clarify' : 'answer';
        asker_id = result.contact_id;

        let ptags = {};
        if('tags' in task_json.object_data) {
            ptags = task_json.object_data.tags;
        }
        tags_count =  Object.keys(ptags).length;
        tags = this.isLoadTags(ptags);

        company_id = result.company_id;
        from_api = result.from_api;
        revert_by = ('reverted_by' in task_json.object_data) ? task_json.object_data.reverted_by : 0;
        revert_reason = ('revert_reason' in task_json.object_data) ? task_json.object_data.revert_reason : '';
        cur_recever_id = ('cur_recever_id' in task_json.object_data && task_json.object_data.cur_recever_id > 0) ? revert_by : 0; 
        if(moduleconfig.enabled_topics && ('topic' in task_json.object_data && 'value' in task_json.object_data.topic)){
            enabled_topics = true;
            topic = task_json.object_data.topic;
        }
        this.setState({header_text,query, answer, cur_files_json:cur_files_json_a, ext_cur_files_json:ext_cur_files_json_b, tags, ref_id, task_id,assign_to, editquestionmode,edittagmode,editanswermode,company_id, clarifications, ext_advice,ext_advice_provider, ext_advice_txt, ext_ref_number, ext_contact_name,from_api, tags_count, accept_mode, revert_by, revert_reason,asker_id,cur_recever_id, ready:true, custom_fields, enabled_topics,topic});
      }

      genKey = (length) => {
          var result           = '';
          var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
      }

      isLoadTags = (tags) => {
          let rawtags = Store.getStoreData('tags');
          console.log("rawtags", rawtags);
          let otags = [];
          rawtags.forEach((top) => {
              if(Object.keys(tags).length === 0){
                  otags.push({
                      name: top.TagName,
                      id: top.ID,
                      styledcomponent: UnselectedPill,
                      selected: false
                  });
              }else {
                  if(tags[top.ID.toString()] ===  top.TagName) {
                      otags.push({
                          name: top.TagName,
                          id: top.ID,
                          styledcomponent: SelectedPill,
                          selected: true
                      });
                  }else{
                      otags.push({
                          name: top.TagName,
                          id: top.ID,
                          styledcomponent: UnselectedPill,
                          selected: false
                      });
                  }
              }
          })
          let stagnames = [];
          otags.forEach((item) => {
              stagnames.push(item.name);
          })
          stagnames.sort();
          let ftags = [];
          for (let i=0; i<stagnames.length; i++) {
              for (let j=0; j<otags.length; j++) {
                  if (stagnames[i] === otags[j].name) {
                      ftags.push(otags[j]);
                  }
              }
          }
          return ftags;
      }

      componentDidMount()
      {
          let cur_approval_data = Store.getStoreData('cur_revert_modal_data');
          this.processApprovalData(cur_approval_data);
      }

      componentDidUpdate(prevProps)
      {
          if (prevProps !== this.props) {
              let cur_approval_data = Store.getStoreData('cur_revert_modal_data');
              this.processApprovalData(cur_approval_data);
          }
      }

      editQuestion = (event) => {
          event.preventDefault();
          let editquestionmode = this.state.editquestionmode ? false : true;
          this.setState({editquestionmode});

      }

      editTags = (event) => {
          event.preventDefault();
          let edittagmode = this.state.edittagmode ? false : true;
          this.setState({edittagmode});

      }

      editAnswer = (event) => {
          event.preventDefault();
          let editanswermode = this.state.editanswermode ? false : true;
          this.setState({editanswermode});
      }

      editClarification = (event) => {
          event.preventDefault();
          let editclmode = this.state.editclmode ? false : true;
          this.setState({editclmode});
      }

      RevertCAR = (event) => {
          event.preventDefault();
          let revert_car = this.state.revert_car ? false : true;
          this.setState({revert_car});
      }

      showCustomFields = (field, data_container) => {
          return (
              <div style={{display:"contents"}}>
                  <Style.ModalNameLabelDiv>{field.nickname.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</Style.ModalNameLabelDiv>
                  <Style.ModalNameInputDiv style={{width: '93%'}}>
                      <Style.ModalInput type="text" name={field.name} value={field.value.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}  readOnly={true}/>
                  </Style.ModalNameInputDiv>
              </div>
          );
      }

      alertremoveHandler = (result, stack) => {
         this.setState({alert_param: null})
         this.props.refreshParentCallback(stack);
    }

      render()
      {
          if(!this.state.ready) {
              return(<div>Loading...</div>);
          }
          let contacts = Store.getStoreData('contacts');
          let form_data = Store.getStoreData('cur_revert_modal_data');
          let contact_id = form_data.contact_id;
          let task_json = JSON.parse(form_data.task_json);
          let object_data = JSON.parse(form_data.task_json).object_data;
          let requester_name = "";
          if('requester_name' in object_data) {
            requester_name = object_data.requester_name;
          }
          let contact = form_data.from_api === 0 ? contacts[contact_id] : requester_name;
          let clarifications = JSON.parse(JSON.stringify(this.state.clarifications))
          let clLength = clarifications.length
          let last_answer = clarifications.length === 0 ? object_data.answer : clarifications[clLength -1].note
          let last_answerer = clarifications.length === 0 ? contacts[object_data.answered_by.toString()] : contacts[clarifications[clLength -1].performed_by.toString()]
          let last_answer_utime = clarifications.length === 0 ? object_data.answer_date : clarifications[clLength -1].actiondate
          let last_answer_time_obj = new Date(last_answer_utime)
          // let last_answer_time = last_answer_time_obj.toLocaleString('en-GB').replace(/,/g, '');
          let last_answer_time = last_answer_time_obj.toLocaleString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit'}).replace(/,/g, '')
          clarifications.reverse()
          let status = form_data.cur_lane === 'COMP_CAR_USER_COMPLETED' ? 'Closed' : 'Rejected';
          console.log(this.clarifications);
          return (
            <div>
              <ModalContainer>
                  {
                      (() => {
                          if(this.state.is_loading) {
                              return <CSLLoader style={{position: 'absolute'}} />
                          }
                      })()
                  }
                  <ModalHeaderText>{this.state.ref_id} - {this.state.header_text.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'").substring(0, 70) + "..."}</ModalHeaderText>
                  <ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></ModalHeaderCloseBtn>
                  <div style={{clear: "both"}}></div>
                  <ModalBody>
                      <ModalBodyInner>
                          <ModeltopRevertText>
                              <div style={{fontWeight:"600",color: "#f6d6d6;"}}>{ Store.getStoreData('contacts')[this.state.revert_by]} reviewed your Advice Request response and has reverted it back with the following comments.</div>
                              <div style={{clear: "both"}}></div>
                              <div style={{fontWeight:"400",color: "#f6d6d6;"}}>{this.state.revert_reason.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}</div>
                          </ModeltopRevertText>
                          <div style={{clear: "both"}}></div>
                          <ModalContainertop>
                              <table style={{width: "100%"}}>
                              <thead>
                                  <tr>
                                      <th style={{textAlign: "left",color: "#243e57"}}><strong>ID</strong></th>
                                      {
                                          (() => {
                                              if(this.state.enabled_topics) {
                                                  return <th style={{textAlign: "left",color: "#243e57"}}><strong>Topic</strong></th>
                                              }
                                          })()
                                      }
                                      <th style={{textAlign: "left", color: "#243e57"}}><strong>Requester</strong></th>
                                      <th style={{textAlign: "left", color: "#243e57"}}><strong>Requested Date</strong></th>
                                      <th style={{textAlign: "left", color: "#243e57"}}><strong>Status</strong></th>
                                      <th style={{textAlign: "left", color: "#243e57"}}><strong>Tags</strong></th>
                                  </tr>
                                </thead>
                                  <tbody>
                                      <tr>
                                          <td style={{textAlign: "left",color: "#243e57"}}>{this.state.ref_id}</td>
                                          {
                                              (() => {
                                                  return <td style={{textAlign: "left", color: "#243e57"}}>{this.state.topic.value}</td>
                                              })()
                                          }
                                          <td style={{textAlign: "left", color: "#243e57"}}>{ Store.getStoreData('contacts')[this.state.revert_by]}</td>
                                          <td style={{textAlign: "left", color: "#243e57"}}>{last_answer_time}</td>
                                          <td style={{textAlign: "left", color: "#243e57"}}>Reverted</td>
                                          <td style={{textAlign: "left", color: "#243e57"}}>{this.state.tags_count}</td>
                                      </tr>
                                  </tbody>
                              </table>

                              <ModalNameLabelDiv>Question</ModalNameLabelDiv>
                              <ModalNameInputDiv style={{whiteSpace:  "break-spaces"}}>
                                  {this.state.query.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")}
                              </ModalNameInputDiv>
                              <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                              <Style.ModalNameInputDiv>
                                  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} />
                              </Style.ModalNameInputDiv>
                          </ModalContainertop>
                          <div style={{clear: "both"}}></div>
                          <ModalContainertop>
                              <ModalNameLabelDiv>Answer <span><FaPen onClick={this.editAnswer}/></span></ModalNameLabelDiv>
                                  <ModalNameInputDiv>
                                      {
                                          (() => {
                                              if(this.state.editanswermode){
                                                  return(<HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.answer} />)
                                              }else{
                                                  return(<div dangerouslySetInnerHTML={{__html: this.state.answer}} style={{textAlign: "justify", padding: "10px"}}></div>)
                                              }
                                          })()
                                      }
                                  </ModalNameInputDiv>
                          </ModalContainertop>
                          <div style={{clear: "both"}}></div>
                          <ModalContainertop>
                              <ModalNameLabelDiv>Tags <span><FaPen onClick={this.editTags}/></span></ModalNameLabelDiv>
                              <ModalNameInputDiv>
                                  {
                                      this.state.tags.map((tag, index) => {
                                          if(this.state.edittagmode){
                                              return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                          }else {
                                              if(tag.selected){
                                                  return (<tag.styledcomponent key={index} >{tag.name}</tag.styledcomponent>);
                                              }
                                          }
                                          
                                      })
                                  }
                              </ModalNameInputDiv>
                          </ModalContainertop>

                          {
                              (() => {
                                  if(clLength > 0) {
                                      return(                       
                                       <ModalContainertop>
                                          <ModalNameLabelDiv>Clarification<span><FaPen onClick={this.editClarification}/></span></ModalNameLabelDiv>
                                              <ModalNameInputDiv>
                                                  {
                                                      (() => {
                                                          if(this.state.editclmode){
                                                              return(<ModalTextArea name="clarification_text" defaultValue={last_answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />)
                                                          }else{
                                                              return(<Style.ModalTextAreaReadonly defaultValue={last_answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly />)
                                                          }
                                                      })()
                                                  }
                                              </ModalNameInputDiv>
                                      </ModalContainertop>
                                      )
                                  }
                              })()
                          }
                          <div style={{clear: "both"}}></div>
                          {
                              (() => {
                                  if(clLength > 0) {
                                      return (                        
                                          <ModalContainertop>
                                              <ModalNameInputDiv>
                                                  {
                                                      clarifications.map((c,i) => {
                                                          return (<ClarificationMessage cardata={form_data} clarification={c} />)
                                                      })
                                                  }
                                              </ModalNameInputDiv>
                                              </ModalContainertop>
                                          )
                                  }
                              })()
                          }

                          <div style={{clear: "both"}}></div>
                          <Style.ModalNameInputDiv>
                              <Style.ModalWiderDiv>
                                  {
                                      (() => {
                                          if(this.state.custom_fields.enabled) {
                                              if (this.state.custom_fields.custom_terminology_fields.length !== 0 ) {
                                                  const field = this.state.custom_fields.custom_terminology_fields.find((item) => {
                                                      return item.name === "reference_number"
                                                  })

                                                  if(('value' in field) && field.value!=='') {
                                                      return this.showCustomFields(field, "custom_terminology_fields");
                                                  }
                                              }
                                          }
                                      })()
                                  }
                                  {
                                      (() => {
                                          if(this.state.custom_fields.enabled) {
                                              if (this.state.custom_fields.additional_fields.length !== 0) {
                                                  let custom_html = [];
                                                  this.state.custom_fields.additional_fields.forEach((item) => {
                                                      if(('value' in item) && item.value!=='') {
                                                          const field = this.showCustomFields(item, "additional_fields");
                                                          custom_html.push(field);
                                                      }
                                                  })
                                                  return custom_html;
                                              }
                                          }
                                      })()
                                  }
                              </Style.ModalWiderDiv>
                          </Style.ModalNameInputDiv>
                          {
                              (() =>{
                                  if (this.state.ext_advice){
                                      return(
                                        <Style.ModalNameInputDiv>
                                            <Style.ModalWiderDiv>
                                                <Style.ModalWiderLineDiv style={{width: '90%'}}/>
                                                {
                                                    (() => {
                                                        if(this.state.ext_advice_provider != ""){
                                                        return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Advice Providers
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_advice_provider.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                    External advice required
                                                </Style.ModalWiderHeaderDiv>
                                                    <Style.ModalTextArea style={{width: '93.3%'}} value={this.state.ext_advice_txt.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>

                                                {
                                                    (() => {
                                                        if(this.state.ext_ref_number != ""){
                                                            return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Reference Number
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_ref_number.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.ext_contact_name != ""){
                                                            return(<div>
                                                                <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                    External Contact Name
                                                                </Style.ModalWiderHeaderDiv>
                                                                    <Style.ModalInput style={{width: '93.3%'}} value={this.state.ext_contact_name.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} readOnly={true}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                 (() => {
                                                     if(this.state.ext_cur_files_json.length > 0) {
                                                         return(<div>
                                                                     <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px',width: '93%', marginTop:'10px' }}>
                                                                                   Attachments
                                                                     </Style.ModalWiderHeaderDiv>
                                                                     <div style={{width: '93.3%'}}>
                                                                           <Dropzone initFiles={this.state.ext_cur_files_json} addnew={false} />
                                                                     </div>
                                                                 </div>
                                                             )
                                                     }
                                                 })()
                                                }
                                            </Style.ModalWiderDiv>
                                        </Style.ModalNameInputDiv>
                                      )
                                  }
                              })()
                          }



                          <div style={{clear: "both"}}></div>
                          <CancelSubmitButtonOuterContainer>
                              <CancelButton onClick={this.closeModal}>Close</CancelButton>
                              <PublishButton onClick={() => this.answerCARQuestion(1)}>Send for Approval</PublishButton>
                          </CancelSubmitButtonOuterContainer>
                      </ModalBodyInner>
                  </ModalBody>
              </ModalContainer>
              <AlertBox alertParam = {this.state.alert_param} />  
            </div>  
          );
      }
  }
export default ShowAnsweredRevretModal;