import $ from 'jquery';
import Utils from './Utils.js';

const cached_data_ids = { 'finpromo-index': 1, 'finpromo-index-last_cached': 1,
						'isemptylocalstore' : 1, 'isemptylocalstore-last_cached' : 1 };

class Store {
	constructor() {
		this.storeData = {};
		this.callBacks = {};
	}

	// Call this function to update store data. The data_id identifies the data
	// and you can use this from any component to access this piece of data
	updateStore = (data_id, state, component_id=null, no_updates=false) => {
        let store_data = JSON.parse(JSON.stringify(state));
        this.storeData[data_id] = store_data
        if(data_id in cached_data_ids) {
            // localStorage.setItem(data_id, JSON.stringify(store_data))
            try {
              localStorage.setItem(data_id, JSON.stringify(store_data))
            } catch (err) {
              let loggedin_contact_id = localStorage.getItem('loggedin_contact_id');
              localStorage.clear();
              localStorage.setItem('isemptylocalstore', true)
              localStorage.setItem('loggedin_contact_id', loggedin_contact_id)
            }
        }

        if(no_updates === true) return;
        if(data_id in this.callBacks){
            // for(var i=0; i<this.callBacks[data_id].length; i++) {
            //     this.callBacks[data_id][i]();
            // }
            for (var key in this.callBacks[data_id]) {
                if(component_id === null || key !== component_id)this.callBacks[data_id][key]();
            }
        }
    }
	// updateStore = (data_id, state, component_id=null, no_updates=false) => {
	//     this.storeData[data_id] = null;
	//     if(state) {
	//         this.storeData[data_id] = JSON.parse(JSON.stringify(state));
	//     }

	//     if(no_updates === true) return;
	//     if(data_id in this.callBacks){
	//         for (var key in this.callBacks[data_id]) {
	//             if(component_id === null || key !== component_id)this.callBacks[data_id][key]();
	//         }
	//     }
	// }

	runCallBacks(data_ids) {
		for (var i; i < data_ids.length; i++) {
			var data_id = data_ids[i];
			for (var key in this.callBacks[data_id]) {
				this.callBacks[data_id][key]();
			}
		}
	}

	// Call updateStoreFromURL to load data from an API URL directly into the store.
	// Note: The API should always return a JSON of the following format:
	// {
	//     "error" : <number_0_means_success>,
	//     "error_msg" : <string_custom_error_msg>,
	//     "result" : {<json_data_that_goes_into_the_Store_and_is_used_in_the_app>}
	// }

	updateStoreFromURL = (data_id, url, method = "GET", payload = null) => {
        switch(method) {
            case 'POST' : $.post({url: url, data_id: data_id, updateFn: this.updateStore, success: function(result){
                                //console.log.log('result from server', result);
                                let server_response = result;
                                if(typeof server_response === 'string') server_response = JSON.parse(server_response);
                                this.updateFn(this.data_id, server_response);
                            }, data: payload});break;
            case 'GET' : $.get({url: url, data_id: data_id, updateFn: this.updateStore, success: function(result){
                                let server_response = result;
                                if(typeof server_response === 'string') server_response = JSON.parse(server_response);
                                this.updateFn(this.data_id, server_response);
                            }});break;
            default : this.updateStore(data_id, {'error' : 1001, 'error_msg' : 'Could not load data from URL: ' + url, 'result' : {}});
        }
    }

	getStoreAllData = () =>{
        return JSON.parse(JSON.stringify(this.storeData));
    }

	// Usually called from the callback function to update the component state
	// using the store data and forcing re-render in case data has changed

	getStoreData = (data_id) => {
        if(data_id in cached_data_ids) {
            let ret = localStorage.getItem(data_id)
            if(ret !== null) {
                ret = JSON.parse(ret);
            }
            return ret
        }
        if(data_id in this.storeData) return JSON.parse(JSON.stringify(this.storeData[data_id]));
        else return null;
    }
    getAllStoreData = () => {
        return JSON.parse(JSON.stringify(this.storeData));
        
    }

	// getStoreData = (data_id) => {
	//     if(data_id in this.storeData) return JSON.parse(JSON.stringify(this.storeData[data_id]));
	//     else return null;
	// }

	// Call this from the constructor of your component like so:

	// this.updateState = this.updateState.bind(this);
	// Store.registerCallBack('respomap', this.updateState);

	// Where updateState is a function within the calling Component that is responsible for
	// updating state with the retrieved data from the Store.
	// This function can read something like this:

	// updateState() {
	//     let store_data = Store.getStoreData('this_data_id');
	//     this.setState({data : store_data, isLoaded: true});
	// }

	registerCallBack = (data_id, callback, component_id=null) => {
        let key = component_id === null ? Utils.genKey(12) : component_id;
        //console.log.log("key",key);
        if(!(data_id in this.callBacks)) {
            this.callBacks[data_id] = {};
        }
        this.callBacks[data_id][key] = callback;
        return key;
    }

    deRegisterCallback = (data_id, component_id) => {
        if(data_id in this.callBacks && component_id in this.callBacks[data_id]) {
            delete this.callBacks[data_id][component_id];
        }
    }
}

export default new Store();
