import React from 'react';
import styled from 'styled-components';
import * as Style from '../../Common/StyledComponents';
import {FaTimes} from 'react-icons/fa';
import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import Dropzone from '../../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import AlertBox from '../../Common/AlertBox';
import '../../../App.css';
import CSLLoader from '../../Common/CSLLoader';
import Utils from '../../../Common/Utils.js';
import CSLTable from '../../Common/CSLTable';
import HtmlEditor from '../../Common/HtmlEditor';
import TasksHOC from '../TasksHOC';
import { IoMdCreate, IoIosClose, IoMdAdd } from "react-icons/io";

import { FaPen } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const CSLDateCover = styled.div`
	  margin-top: 5px;
	  width: 40%;
	  border-radius: 5px;
	  border-style: solid;
	  border-width: 1px;
	  border-color: #282828;
	  background-color: #ffffff;
	  height: 30px;
	  padding-top: 2px;
	  padding-left: 2px;
	  float: left;
	  position: relative;
`;
const CSLtableDate = styled.div`
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: #282828;
	background-color: #ffffff;
	height: 30px;
	position: relative;
	`;
const SchemeDateCover = styled.div`
	margin-top: 4px;
	width: 100%;
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: #282828;
	background-color: #ffffff;
	height: 25px;
	padding-left: 2px;
	float: right;
	position: absulate;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 0px;
`;
const MRButton = styled.button`
	width: 33px;
	font-size: 20px;
	height: 28px;
	margin-left: 10px;
	cursor: pointer
`;

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;

const ModalBody = styled.div`
    padding: 10px 20px 10px 20px;
    overflow-y: scroll;
    height: calc(65vh - 20px);
`;

class MangeTask extends React.Component
{
	state = {
		gc_comp_id: 0,
		task: {},
		task_id: 0,
		task_name: "",
		description: "",
		assign_to: 0,
		completed_date: new Date(),
		due_date: new Date(),
		show_due_date: '',
		binFiles: [],
		cur_files_json: null,
		ready: false,
		alert_param: null,
		makecomplete: false,
		comments: "",
		repeate_task: false,
		frequency: "monthly",
		schedule_date: new Date(),
		scheme_id: 0,
		show_scheme: false,
		hint_tasks: {},
		edit_hint: false,
		single_hint: {},
		is_recurring: false,
		is_overdue: false,
		row_id: 0,
		show_calander: false,
		calender_date: '',
		tableindex: 0,
		isOpen: false,
		id_due_change: false,
		edit_due: null,
		list_contacts: {},
		has_gc: false,
		gc_lists: {},
		list_managers:[],
		manager_id: 0,
		is_multi_user: false,
		rowchecked: false,
		scheme_loading: false,
		overdue_task: false,
		ref: {},
		show_ref: ''
	};
	constructor(props) {
	    super(props);
	}
	closeModal = (event) => {
	    event.preventDefault();
	    if(Store.getStoreData('description') !== null){
	        Store.updateStore('description', null)
	    }
	    this.props.closeUserDialog();
	}
	closeRefModal = (is_reload=null) => {
		// event.preventDefault();
		// alert(is_reload)
		this.setState({show_ref: ''})
		if(is_reload!==null){
			this.props.closeUserDialog(is_reload)
		}

	}
	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	handleSchemeChange = (event) => {
		event.preventDefault();
		let scheme_id = event.target.value;
		if(scheme_id !=="0"){
	        let postData = {command: "get_scheme", id: scheme_id};
			let api = new APICall();
			this.setState({scheme_loading: true})
			api.command(postData, this.SchemeTask);
		}else{
			this.setState({show_scheme: false, scheme_id: scheme_id, scheme_loading: false})
		}
	}
	SchemeTask = (result) => {
		console.log("result scheme", result)
		// let list_contacts = Store.getStoreData('list_contacts')
		let list_contacts = this.state.list_contacts;
		let scheme_json = JSON.parse(result.scheme_json);
		let used_tasks = scheme_json.used_tasks;
		let hint_tasks = {}
		let date = new Date()
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
		for(let key in used_tasks) {
			if(used_tasks[key].is_active === 1)hint_tasks[key.toString()] = {"id": key, "name" : used_tasks[key].name, "description": used_tasks[key].description, "frequency": used_tasks[key].frequency, is_recurring: true, assign_to: list_contacts[0].ID, schedule_date: dateStr, first_schedule_date: dateStr, is_delete: false}
		}
		console.log("hint_tasks", hint_tasks)
		this.setState({show_scheme: true, scheme_id: result.id, hint_tasks: hint_tasks, scheme_loading: false})
	}

	componentDidMount()
	{
	    this.processTaskData();
	}

	componentDidUpdate(prevProps)
	{
	    if (prevProps !== this.props) {
	        this.processTaskData();
	    }
	}

	isOverdueTask = (date) => {
		let due_date = new Date(date);
		let cur_date = new Date();
		let cur_date_int = cur_date.getFullYear()*10000 + cur_date.getMonth()*100 + cur_date.getDate();
		let due_date_int = due_date.getFullYear()*10000 + due_date.getMonth()*100 + due_date.getDate();
		let flag = due_date_int < cur_date_int ? true : false;
		return flag;
	}

	processEditTaskData = (result) => {
		if(result.error_code === 0) {
			console.log("result ===>", result);
			let task = result.result.parenttask;
			let list_contacts = Utils.ShowgcUsers(task.company_id);
			let list_managers = Utils.showManagers(task.company_id);
			// console.log("list_managers --->", list_managers, list_contacts)

			let task_json = JSON.parse(task.task_json);
			let task_name = task_json.object_data.name;
			let description = ('description' in task_json.object_data) ? task_json.object_data.description : '';
			if(Utils.isBase64(description)){
			  description = window.decodeURIComponent(atob(description))
			}
			let overdue_task = this.isOverdueTask(task.due_date)

			// console.log("overdue_task", overdue_task)

			let due_date = new Date(task.due_date);
			let show_due_date = this.getDate(task.due_date);
			if (task_json.object_data.bin_files.length !== 0) {
	            task_json.object_data.bin_files.forEach((item) => {
	                item.uid = "uid" in item ? item.uid : this.genKey(10);
	            })
	        }
	        let cur_files_json = task_json.object_data.bin_files;
	        let binFiles = task_json.object_data.bin_files;
	        let is_recurring = ('is_recurring' in task_json.object_data) ? task_json.object_data.is_recurring : false;
	        let frequency = ('frequency' in task_json.object_data) ? task_json.object_data.frequency : this.state.frequency;
	        let is_overdue = task.cur_lane === "COMP_GENERIC_COMPLETED" ? false : true;
	        let recurring_overdue = ('recurring_overdue' in task_json.object_data) ? task_json.object_data.recurring_overdue : false;

	        let ref = ('ref' in result && (overdue_task || recurring_overdue)) ? result.ref : {};
			this.setState({task:task, assign_to: task.cur_assigned_to,list_contacts: list_contacts,task_name: task_name,description:description,due_date:due_date,show_due_date: show_due_date,binFiles:binFiles,cur_files_json:cur_files_json,task_id:task.id, ready: true, is_recurring: is_recurring, is_overdue: is_overdue, list_managers: list_managers, frequency: frequency, overdue_task: overdue_task, ref: ref});
		}else{
			let msg = result.error_msg;
			let title = "Alert";
			let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
			console.log("alert_param", alert_param)
			this.setState({alert_param: alert_param, ready: true, cur_view: 'no_task'})
		}
	}

	processTaskData = () => {
		let task_id = this.props.task_id;
		if(task_id > 0) {
			let postData = { "command" : "get_task", "task_id" : task_id }
			postData['gcs'] = Store.getStoreData("gc_filters")
			let api = new APICall();
			api.command(postData, this.processEditTaskData);
		}else{
			// let list_contacts = Store.getStoreData('list_contacts');
			let list_contacts = Utils.ShowgcUsers(null);
			let gc_assoc = Store.getStoreData('gc_assoc');
			for(let k in gc_assoc) {
				if(gc_assoc[k].is_active === 0)delete gc_assoc[k]
			}
			let has_gc = Object.keys(gc_assoc).length === 1 ? false : true;

			console.log("gc_assoc", gc_assoc)

			this.setState({ assign_to: list_contacts[0].ID, list_contacts: list_contacts,gc_lists:gc_assoc,has_gc:has_gc,ready: true});
		}
	}

	filesLoaded = (jsonObj) => {
	    let contact_id = Store.getStoreData('contact_id');
	    let files_to_show = JSON.parse(JSON.stringify(jsonObj.files));
	    let binFiles = this.state.binFiles;

	    console.log("binFiles", binFiles)
	    console.log("jsonObj", jsonObj)

	    let file_exists = {};
	    let curBinFiles = [];

	    if(jsonObj.command === 'add'){
	        for(let i=0; i < files_to_show.length; i++) {
	            if(typeof files_to_show[i].name !== "undefined"){
	                files_to_show[i].name = jsonObj.files[i].name.replace('&', '');
	                if(!('user_id' in files_to_show[i])) {
	                    files_to_show[i].user_id = contact_id;
	                    files_to_show[i].upload_date = Date.now();
	                }
	                if(typeof file_exists[files_to_show[i].name] === "undefined"){
	                    binFiles.push(files_to_show[i]);
	                    file_exists[files_to_show[i].name] = true;
	                }
	            }

	        }
	        curBinFiles = binFiles.filter((binFiles, index, self) => index === self.findIndex((t) => (t.name === binFiles.name && t.uid === binFiles.uid)));
	        this.setState({binFiles: curBinFiles});
	    }else{
	        for(let i=0; i < binFiles.length; i++) {
	            if(binFiles[i].uid.toString() === jsonObj.uid.toString()){
	                binFiles.splice(i,1);
	            }
	        }
	        this.setState({binFiles: binFiles});
	    }
	}
	onDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({due_date: date, id_due_change: this.state.is_recurring});
	}
	loadDateChangeTask = (result, stack) => {
		if(!result || stack.prevent) {
		    this.setState({alert_param: null})
		    return
		}
		let curmonth = parseInt(stack.date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr = currmonth >= 10 ? stack.date.getFullYear()+'/'+currmonth+'/'+stack.date.getDate() : stack.date.getFullYear()+'/'+currmonth+'/'+stack.date.getDate();
		this.setState({due_date: stack.date});
		this.setState({alert_param: null})
	}
	onscheduleDateChange = (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    this.setState({schedule_date: date});
	}
	createScheduleTask = (event) => {
		event.preventDefault();
		if(this.state.task_name === '') {
		    let mgs = "ERROR: Task name cannot be blank.";
		    let title = "ERROR";
		    let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
		    this.setState({alert_param: alert_param})
		    return false;
		}
		if(this.state.frequency === "0") {
			let mgs = "ERROR: Please select a frequency.";
			let title = "ERROR";
			let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
			this.setState({alert_param: alert_param})
			return false;
		}

		let curmonth_sched = parseInt(this.state.schedule_date.getMonth());
		curmonth_sched = curmonth_sched + 1;
		var schedDateStr = curmonth_sched >= 10 ? this.state.schedule_date.getFullYear()+'/'+curmonth_sched+'/' : this.state.schedule_date.getFullYear()+'/0'+curmonth_sched+'/';
		schedDateStr += this.state.schedule_date.getDate() < 10 ? '0' + this.state.schedule_date.getDate() : this.state.schedule_date.getDate();
	    let bin_files = this.state.binFiles;
	    let assign_to = this.state.assign_to;
	    let loggedincontact = Store.getStoreData('loggedincontact');
	    if(Store.getStoreData('role') === "team") {
			assign_to = loggedincontact.contact_id;
		}
        let description = Store.getStoreData('description');
        if(description === null){
            description = this.state.description;
        }
        let description_summary = btoa(window.encodeURIComponent(description));

	    let hint_tasks = {};
	    /*let single_hint = {};
	    single_hint['id'] = id;
	    single_hint['name'] = this.state.task_name;
	    single_hint['description'] = description_summary;
	    single_hint['frequency'] = this.state.frequency;
	    single_hint['is_recurring'] = true;
	    if(this.state.is_multi_user){
	    	let list_contacts = this.state.list_contacts;
	    	let cts = {};
	    	for (let key in list_contacts) {
	    	  if (list_contacts[key].selected) cts[list_contacts[key].ID.toString()] = 1;
	    	}
	    	single_hint['assign_to_users'] = cts
	    }else{
	    	single_hint['assign_to'] = assign_to;
	    }
	    single_hint['schedule_date'] = schedDateStr;
	    single_hint['first_schedule_date'] = schedDateStr;
	    single_hint['is_delete'] = false;

	    hint_tasks[id.toString()] = single_hint;*/

	    if(this.state.is_multi_user){
	    	let list_contacts = this.state.list_contacts;
	    	for (let key in list_contacts) {
	    	  if (list_contacts[key].selected){
	    	  	let id =  Utils.genKey(5);
	    	  	hint_tasks[id.toString()] = {"id": id, "name" : this.state.task_name, "description": description_summary, "frequency": this.state.frequency, is_recurring: true, assign_to: list_contacts[key].ID, schedule_date: schedDateStr, first_schedule_date: schedDateStr, is_delete: false}
	    	  }
	    	}
	    }else{
	    	let id =  Utils.genKey(5);
	    	let single_hint = {};
		    single_hint['id'] = id;
		    single_hint['name'] = this.state.task_name;
		    single_hint['description'] = description_summary;
		    single_hint['frequency'] = this.state.frequency;
		    single_hint['is_recurring'] = true;
		    single_hint['assign_to'] = assign_to;
		    single_hint['schedule_date'] = schedDateStr;
		    single_hint['first_schedule_date'] = schedDateStr;
		    single_hint['is_delete'] = false;
		    hint_tasks[id.toString()] = single_hint;
	    }


    	// let hint_tasks = this.state.hint_tasks;
        let postData = {
        					"command": "create_mcc_scheme",
                			hint_tasks:hint_tasks,
                			bin_files: bin_files,
                			gc_comp_id: this.state.gc_comp_id === 0 ? Store.getStoreData('parentcompany_id') : this.state.gc_comp_id
                		};

	    let api = new APICall();
	    console.log("postData", postData);
	    if(this.state.is_multi_user){
	    	postData["command"] = "create_mcc_scheme_multiusers";
	    	api.command(postData, this.processAddMultiuserScheduleTask);
	    }else{
	    	api.command(postData, this.processAddScheduleTask);
	    }
	    this.setState({scheme_loading: true})


	}
	processAddMultiuserScheduleTask = (result) => {
		let mgs = "Assigning task to multiple users, this may take a few seconds to update";
		let title = "";
		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
	}
	processAddScheduleTask = (result) => {
		let mgs = "New scheduled tasks have been created";
		let title = "New task";
		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
	}
	createGeneralTask = (submit_form) => (event) => {

		console.log("this.state", this.state.id_due_change)
	    if(this.state.task_name === '') {
	        let mgs = "ERROR: Task name cannot be blank.";
	        let title = "ERROR";
	        let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
	        this.setState({alert_param: alert_param})
	        return false;
	    }

	    let is_recurring = this.state.is_recurring;
	    let overdue_task = this.state.overdue_task;
	    let ref_id = this.state.task.ref_id;
	    if(is_recurring && !overdue_task) {
	    	if(this.state.id_due_change){
	    		let mgs = "Changing the date or frequency of this task will delete all the future tasks and re-schedule this task. All overdue tasks will be uneffected. Are you sure you want to continue?";
	    		let title = "Alert";
	    		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: true,alertHandler: this.updateTaskProcessor, stack: {task_id : this.state.task_id, ref_id : ref_id, submit: submit_form, option_value: "1"}}
	    		this.setState({alert_param: alert_param})
	    		return false;

	    	}else{
		    	let alert_param = {title: 'Confirm', message: 'Tasks already scheduled are not completed. Are you sure want to update this activity?', ok_text: 'Yes',cancel_text: 'No', confirm: true,
				alertHandler: this.updateTaskProcessor, stack: {task_id : this.state.task_id, ref_id : ref_id, submit: submit_form} , continue_to_next_screen_if_yes: true ,
				next_messages: [{value : 1 , msg : 'Update all the future tasks starting from this one?'},{value : 2 , msg : 'Update only this task?'}] ,
				option_type: 'Radio'};
				this.setState({alert_param: alert_param})
		    	return false;
	    	}
	    }




	    let curmonth = parseInt(this.state.due_date.getMonth());
	    let currmonth = curmonth + 1;
	    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
	    dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
	    let bin_files = this.state.binFiles;
	    let assign_to = this.state.assign_to;
	    let loggedincontact = Store.getStoreData('loggedincontact');
	    if(Store.getStoreData('role') === "team") {
			assign_to = loggedincontact.contact_id;
		}


	    let description = Store.getStoreData('description');
	    if(description === null){
	        description = this.state.description;
	    }
	    let description_summary = btoa(window.encodeURIComponent(description));


	    let postData = { "command": "add_task",
	            task_name:this.state.task_name,
	            submit:submit_form,
	            bin_files: bin_files,
	            task_id: this.state.task_id,
	            description: description_summary,
	            due_date: dueDateStr,
	            gc_comp_id: this.state.gc_comp_id === 0 ? Store.getStoreData('parentcompany_id') : this.state.gc_comp_id
	        };
	    if(is_recurring){
	    	postData['recurring_overdue'] = true;
	    }
	    let d = this.getDate(this.state.task['due_date']);
	    let x = Utils.checkIssamedate(dueDateStr, d)

	    /*let y = this.isOverdueTask(dueDateStr);
	    if(y){
	    	let mgs = "Due date cannot be a past date, please select the current date or any future date as the due date of the task";
	    	let title = "Alert";
	    	let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
	    	this.setState({alert_param: alert_param})
	    	return false;
	    }*/



	    postData['is_due_change'] = x;
	    if(this.state.is_multi_user){
	    	let list_contacts = this.state.list_contacts;
	    	let cts = {};
	    	for (let key in list_contacts) {
	    	  if (list_contacts[key].selected) cts[list_contacts[key].ID.toString()] = 1;
	    	}
	    	postData['assign_to_users'] = cts
	    }else{
	    	postData['assign_to'] = assign_to;
	    }
	    let api = new APICall();

	    if(this.state.is_multi_user){
	    	postData["command"] = "share_task_to_users";
	    	api.command(postData, this.processAddMultiuserScheduleTask);
	    }else{
	    	api.command(postData, this.processAddTask);
	    }
	    this.setState({scheme_loading: true})



	    // console.log("postData add task", postData);
	    // api.command(postData, this.processAddTask);

	}

	updateTaskProcessor = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        console.log("stack", stack)
        let task_id = stack.task_id;
        let option = ('option_value' in stack) ? stack.option_value : 0;
        let submit_form = stack.submit

        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
        let bin_files = this.state.binFiles;
        let assign_to = this.state.assign_to;
        let loggedincontact = Store.getStoreData('loggedincontact');
        if(Store.getStoreData('role') === "team") {
    		assign_to = loggedincontact.contact_id;
    	}


        let description = Store.getStoreData('description');
        if(description === null){
            description = this.state.description;
        }
        let description_summary = btoa(window.encodeURIComponent(description));

        let postData = { "command": "add_task",
                task_name:this.state.task_name,
                submit:submit_form,
                bin_files: bin_files,
                task_id: this.state.task_id,
                description: description_summary,
                due_date: dueDateStr,
                gc_comp_id: this.state.gc_comp_id === 0 ? Store.getStoreData('parentcompany_id') : this.state.gc_comp_id,
                frequency: this.state.frequency
            };
        let d = this.getDate(this.state.task['due_date']);
        let x = Utils.checkIssamedate(dueDateStr, d)
        postData['is_due_change'] = x;
        postData['assign_to'] = assign_to;
        postData['ref_id'] = stack.ref_id;
        if(option === "1"){
        	postData['command'] = "update_recurring_task";
        }
        console.log("postData", postData)
		let api = new APICall();
		api.command(postData, this.reloadRecuringTask);
        this.setState({alert_param: null, scheme_loading: true})

    }

    reloadRecuringTask = (result) => {
		let alert_param = {title: 'Success', message: 'Tasks has been Updated.', ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
    }

	processshareMultiuserTask = (result) => {

	}

	processAddTask = (result) =>
	{
		// let url = Store.getStoreData('is_debug') ? "/" : "/";
		let mgs = "New task has been created.";
		let title = "New task";
	    if(result.submit === 1) {
	        mgs = "New task has been created";
	        title = "New task";
	    }else if(result.submit === 2) {
	    	mgs = "Task has been updated.";
	    	title = "Task update";
	    }else{
	    	mgs = "The task has been updated.";
	    	title = "Task Saved";
	    }

		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})

	}

	RemoveTask = (task_id) => (event) => {
		let ref_id = this.state.task.ref_id;
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
				                   alertHandler: this.removeTaskProcessor, stack: {task_id: task_id, ref_id: ref_id}}
		let overdue_task = this.state.overdue_task;
		let is_recurring = this.state.is_recurring;

		let schedule_date = this.state.task.due_date;
		if(!overdue_task && is_recurring) {
			alert_param = {title: 'Confirm', message: 'Tasks already scheduled are not completed. Are you sure want to delete this activity?', ok_text: 'Yes',cancel_text: 'No', confirm: true,
			alertHandler: this.removeTaskProcessor, stack: {task_id : task_id, ref_id : ref_id, schedule_date: schedule_date} , continue_to_next_screen_if_yes: true ,
			next_messages: [{value : 1 , msg : 'Delete all the future tasks starting from this one?'},{value : 2 , msg : 'Delete only this particular instance?'}] ,
			option_type: 'Radio'};
		}
		this.setState({alert_param: alert_param});



		// let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
		//                    alertHandler: this.removeTaskProcessor, stack: {task_id: task_id}}
		// this.setState({alert_param: alert_param})
	}

	removeTaskProcessor = (result, stack) => {

        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let task_id = stack.task_id;
        let option = ('option_value' in stack) ? stack.option_value : 0;
        console.log("stack", stack, result, option)

        let postData = {command: "remove_single_task", task_id: task_id};
        // let postData = {command: "remove_task", task_id: task_id};
        postData['ref_id'] = stack.ref_id;
        if(option == 1 && stack.ref_id != ''){

        	postData['command'] = "remove_recurring_task";

        	postData['schedule_date'] = stack.schedule_date;
        }

        if(stack.ref_id === ""){
        	postData['command'] = "remove_task";
        }
        console.log("postData", postData)
		let api = new APICall();
		api.command(postData, this.reloadTask);
        this.setState({alert_param: null, scheme_loading: true})
    }

    alertremoveHandler = (result, stack) => {
    	this.props.closeUserDialog(true);
    }

	reloadTask = (result) => {

		// alert('Task has been removed successfully.');
		let alert_param = {title: 'Success', message: 'This task has been removed.', ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
	}

	makeComplete = (event) => {
		event.preventDefault()
		let makecomplete = this.state.makecomplete ? false : true;
		this.setState({makecomplete: makecomplete})
	}

	completeGeneralTask = (submit_form) => (event) => {
		let api = new APICall();
	    if(this.state.comments === '') {
	        let mgs = "ERROR: Comments cannot be blank.";
	        let title = "ERROR";
	        let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
	        this.setState({alert_param: alert_param})
	        return false;
	    }
	    if(Store.getStoreData('role') === "team" && Store.getStoreData('enabled_approval_task')){
	    	if(parseInt(this.state.manager_id) === 0){
	    		let mgs = "Approval is required for this task. Please select the Manager that will approved your task.";
	    		let title = "Alert";
	    		let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: false, alertHandler: this.loadSchemeTask, stack: {id: 0}}
	    		this.setState({alert_param: alert_param})
	    		return false;
	    	}
	    }
	    let curmonth = parseInt(this.state.completed_date.getMonth());
	    let currmonth = curmonth + 1;
	    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
	    dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate();
	    let bin_files = this.state.binFiles;
	    let description = Store.getStoreData('description');
	    if(description === null){
	        description = this.state.description;
	    }
	    let description_summary = btoa(window.encodeURIComponent(description));

	    let postData = { "command": "complete_task",
	            task_name:this.state.task_name,
	            description:description_summary,
	            submit:submit_form,
	            bin_files: bin_files,
	            task_id: this.state.task_id,
	            comments: this.state.comments,
	            completed_date: dueDateStr
	        };
        if(this.state.is_recurring){
        	postData['ref_id'] = this.state.task.ref_id;
        }

	    if(Store.getStoreData('role') === "team" && Store.getStoreData('enabled_approval_task')){
	    	postData['command'] = "send_for_approval";
	    	postData['assigned_to'] = parseInt(this.state.manager_id);
	    	console.log(postData)
	    	api.command(postData, this.processApprovalTask);
	    }else{
	    	api.command(postData, this.processCompletdTask);
	    }
	    this.setState({scheme_loading: true})

	}

	processApprovalTask = (result) => {
		let alert_param = {title: 'Alert', message: 'Your task has been sent for approval', ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
	}

	processCompletdTask = (result) =>
	{
		let alert_param = {title: 'Task complete', message: 'Your task has been completed.', ok_text: 'Ok', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
	}

	getDate = (input_date) => {
	  if(input_date != null){
	    let dateStr = input_date.split('T')[0];
	    let dateParts = dateStr.split('-');
	    let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
	    return ret;
	  }else{
	    return '';
	  }

	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {repeate_task} = this.state;
		repeate_task = repeate_task ? false : true;
		this.setState({repeate_task})
	}

	ChangeRecurring = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].is_recurring = hint_tasks[id].is_recurring ? false : true;
		hint_tasks[id].frequency = hint_tasks[id].is_recurring ? hint_tasks[id].frequency : 'once';
		this.setState({hint_tasks: hint_tasks})
	}

	handleAssignChange = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].assign_to = event.target.value
		this.setState({hint_tasks: hint_tasks})
	}
	handleFrequencyChange = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].frequency = event.target.value
		this.setState({hint_tasks: hint_tasks})
	}

	onSchemeDateChange = (id) => (date) => {
	    let curmonth = parseInt(date.getMonth());
	    let currmonth = curmonth + 1;
	    var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
	    let hint_tasks = this.state.hint_tasks;
	    hint_tasks[id].schedule_date = dateStr;
	    hint_tasks[id].first_schedule_date = dateStr;
	    this.setState({hint_tasks: hint_tasks, show_calander: false})
	}

	viewParam = (id) => (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		let hint_task = hint_tasks[id]
		let description = ('description' in hint_task) ? hint_task.description : '';
		if(Utils.isBase64(description)){
		  description = window.decodeURIComponent(atob(description))
		}
		hint_task.description = description;
		console.log("hint_task", hint_task)
		hint_task["id"] = id;
		this.setState({edit_hint: true, single_hint: hint_task})
	}
	RemoveParam = (id) => (event) => {
		event.preventDefault();
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this task?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
		                   alertHandler: this.removeSchemeTask, stack: {id: id}}
		this.setState({alert_param: alert_param})
		// let hint_tasks = this.state.hint_tasks;
		// let hint_task = hint_tasks[i]
	}

	removeSchemeTask = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let id = stack.id;
        let hint_tasks = this.state.hint_tasks;
        delete hint_tasks[id];
        this.setState({hint_tasks: hint_tasks, alert_param: null})
	}

	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	handlehintChange = (event) => {
		let single_hint = this.state.single_hint;
		single_hint[event.target.name] = event.target.value;
		this.setState({single_hint: single_hint})
	}

	closeScheme = (event) => {
		this.setState({edit_hint: false, single_hint: {}})
	}
	updateSchemeTask = (event) => {
		let alert_param = {title: 'Success', message: 'Your scheme task has been saved', ok_text: 'Ok', cancel_text: 'No', confirm: false,
		                   alertHandler: this.loadSchemeTask, stack: {id: 0}}

		let hint_tasks = this.state.hint_tasks;
		let single_hint = this.state.single_hint;


		let description = Store.getStoreData('description');
		if(description === null){
		    description = single_hint['description'];
		}
		let description_summary = btoa(window.encodeURIComponent(description));
		single_hint['description'] = description_summary



		let id = single_hint.id;
		hint_tasks[id] = single_hint;

		console.log("hint_tasks gc_comp_id", this.state.gc_comp_id)
		if(single_hint.name === "") {
			alert_param = {title: 'Alert', message: 'ERROR: Task name cannot be blank.', ok_text: 'Ok', confirm: false,alertHandler: this.loadSchemeTask, stack: {id: 0}}
			this.setState({alert_param: alert_param})
			return false
		}

		this.setState({alert_param: alert_param,hint_tasks: hint_tasks, edit_hint: false, single_hint: {} })
	}
	loadSchemeTask = (result, stack) => {
		if(!result || stack.prevent) {
		    this.setState({alert_param: null})
		    return
		}
		this.setState({alert_param: null})
		// this.props.closeUserDialog(true);
	}





	createRecurringTask = (event) => {
		event.preventDefault();
		let hint_tasks = this.state.hint_tasks;
		let l = Object.keys(hint_tasks).length;
		let alert_param = {title: 'Confirm', message: 'Clicking OK will confirm that '+l+' tasks are scheduled and, where enabled, emails will be sent to all contacts with new tasks assigned', ok_text: 'OK',cancel_text: 'Cancel', confirm: true,
		alertHandler: this.createRecurring, stack: {id: 0}};
		this.setState({alert_param: alert_param});

	}

	createRecurring = (result, stack) => {
		if(!result || stack.prevent) {
		    this.setState({alert_param: null})
		    return
		}
		this.setState({alert_param: null})
		let hint_tasks = this.state.hint_tasks;
	    let postData = { "command": "create_mcc_scheme", hint_tasks:this.state.hint_tasks};
	    postData["gc_comp_id"] = this.state.gc_comp_id === 0 ? Store.getStoreData('parentcompany_id') : this.state.gc_comp_id
	    let api = new APICall();
	    console.log("postData", postData);
	    api.command(postData, this.processRecurringTask);
	    this.setState({scheme_loading: true})
	}




	processRecurringTask = (result) =>
	{
		let hint_tasks = this.state.hint_tasks;
		let l = Object.keys(hint_tasks).length;
		let alert_param = {title: 'Success', message: 'Tasks are scheduled and, where enabled, emails will be sent to all contacts with new tasks assigned', ok_text: 'OK', cancel_text: 'No', confirm: false,
		                   alertHandler: this.alertremoveHandler, stack: {id: 0}}
		this.setState({alert_param: alert_param, scheme_loading: false})
	}

	addsubTask = (event) => {
		let date = new Date()
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
		let list_contacts = Store.getStoreData('list_contacts')
		let single_hint = {};
		single_hint['id'] = Utils.genKey(5);
		single_hint['name'] = '';
		single_hint['description'] = '';
		single_hint['frequency'] = 'monthly';
		single_hint['is_recurring'] = true;
		single_hint['assign_to'] = list_contacts[0].ID;
		single_hint['schedule_date'] = dateStr;
		single_hint['first_schedule_date'] = dateStr;
		single_hint['is_delete'] = false;
		this.setState({edit_hint: true, single_hint: single_hint});
	}


	processschemeData = () => {
		let tasks = this.state.hint_tasks;
		let frequency = Utils.list_shedules();
		// console.log("tabledata", tabledata);
		let ret = {data: [], columns: []};
		let i = 0;
		ret.columns =[
		                {Header: 'Name', accessor: 'name', width: 100, headerStyle: {textAlign: 'left'}},
		                {Header: 'Description', accessor: 'description', minWidth: 150, headerStyle: {textAlign: 'left'}},
		                 {'Header' : 'Recurring task', Cell: row => (
		                    <div>
		                        <input type="checkbox" checked={row.original.is_recurring} onChange={this.ChangeRecurring(row.original.id)} />
		                    </div>
		                 ), width: 120, headerStyle: {textAlign: 'left'}},
		                {Header: 'Assigned To', Cell: row => (
		                    <div>
                    				<Style.MRModalSelect style ={{height: "30px"}} name="assign_to" value={row.original.assign_to} onChange={this.handleAssignChange(row.original.id)}>
    			                    {
    			                        /*Store.getStoreData('list_contacts').map((item) => {
    			                            {
    			                                return (
    			                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
    			                                );
    			                            }
    			                        })*/
    			                        this.state.list_contacts.map((item) => {
    			                            {
    			                                return (
    			                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
    			                                );
    			                            }
    			                        })
    			                    }
    			                    </Style.MRModalSelect>
		                    </div>
		                 ), width: 100, headerStyle: {textAlign: 'left'}},
		                {Header: 'First Schedule Date', Cell: row => (
			                    <CSLtableDate onClick={this.viewCalender(row.original.id, row.original.schedule_date, row.viewIndex)}>
	                    			<DatePicker
	                    	          clearIcon={null}
	                    	          calendarIcon={null}
	                    	          locale={"en-GB"}
	                    	          value={row.original.schedule_date}
	                    	        />
							</CSLtableDate>
		                 ), width: 150, headerStyle: {textAlign: 'left'}},
		                {Header: 'Frequency', Cell: row => (
		                    <div>
                				<Style.MRModalSelect style ={{height: "30px"}} name="frequency" value={row.original.frequency} onChange={this.handleFrequencyChange(row.original.id)} >
			                    {
			                        frequency.map((item) => {
			                            {
			                                return (
			                                    <option key={item.id} value={item.name}>{item.nickname}</option>
			                                );
			                            }
			                        })
			                    }
			                    </Style.MRModalSelect>
		                    </div>
		                 ), width: 120, headerStyle: {textAlign: 'left'}},
		                {'Header' : 'Action', Cell: row => (
		                   <div>
		                       <ViewDiv onClick={this.viewParam(row.original.id)}><FaPen style={{ float: "right", marginRight: "14px" }}/></ViewDiv>
		                       <ViewDiv onClick={this.RemoveParam(row.original.id)}><MdDelete style={{ float: "right", marginRight: "14px" }}/></ViewDiv>
		                   </div>
		                ), width: 75, headerStyle: {textAlign: 'left'}}];

		for(let key in tasks) {
		    i++;
		    let description = ('description' in tasks[key]) ? tasks[key].description : '';
		    if(Utils.isBase64(description)){
		      description = window.decodeURIComponent(atob(description))
		    }
		    let elem = {
		    	index : i,
		        id: key,
		        name : tasks[key].name,
		        // description : description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' '),
		        description : <div dangerouslySetInnerHTML={{__html: description}}></div>,
		        is_recurring : tasks[key].is_recurring,
		        frequency:  tasks[key].frequency,
		        assign_to: tasks[key].assign_to,
		        schedule_date: tasks[key].schedule_date === "" ? this.state.schedule_date : new Date(tasks[key].schedule_date),
		        show_schedule_date: tasks[key].schedule_date
		    };
		    ret.data.push(elem);
		}
		return ret;
	}
	viewCalender = (row_id, date, index) => (event) => {
		event.preventDefault();
	    this.setState({row_id: row_id, show_calander: true, calender_date: date, tableindex:index, isOpen: true})
	}

	HideDate = (index) => (event) => {
		this.setState({show_calander: false, tableindex:index})
	}

	closePicker = () => {
		// event.preventDefault();
	    this.setState({
	      isOpen: false
	    });
	  }

	  /*selectDate(date) {
	    this.setState({
	      selectedDate: date
	    });
	  }*/

	 selectDate = (id) => (date) => {
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/'+currmonth+'/'+date.getDate();
		let hint_tasks = this.state.hint_tasks;
		hint_tasks[id].schedule_date = dateStr;
		hint_tasks[id].first_schedule_date = dateStr;
		this.setState({hint_tasks: hint_tasks, show_calander: false, isOpen: false})
	 }


	handleCompanyChange = (event) => {
		event.preventDefault();
		let list_contacts = Utils.ShowgcUsers(event.target.value);
		console.log("list_users", list_contacts)
		this.setState({ [event.target.name] : event.target.value, list_contacts: list_contacts, assign_to: list_contacts[0].ID })
	}

	changeMultiusers = (event) => {
		// event.preventDefault();
		let is_multi_user = this.state.is_multi_user ? false : true;
		this.setState({is_multi_user});
	}

	processsUserData = () => {
		let list_contacts = this.state.list_contacts;
		let table = { data: [], columns: [] };
		table.columns = [
		{
		  Header: (
		    <div>
		      <input type="checkbox" checked={this.state.rowchecked}  onClick={this.selectAll} />
		    </div>
		  ),
		  Cell: (row) => (
		    <div>
		      <input type="checkbox" checked={row.original.selected} value={row.original.index} onClick={this.selectUser} />
		    </div>
		  ),

		  minWidth: 130,
		  headerStyle: { textAlign: "left" },
		},
		  { Header: "Name",minWidth: 130, accessor: "name", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } }
		];

		for (let k = 0; k < list_contacts.length; k++) {
		  let elem = {
		    name: list_contacts[k].contact_name,
		    id: list_contacts[k].ID,
		    selected: "selected" in list_contacts[k] ? list_contacts[k].selected : false,
		    index: k
		  };
		  table.data.push(elem);
		}
		return table;

	}

	selectAll = (event) => {
	  let rowchecked = this.state.rowchecked;
	  rowchecked = rowchecked ? false : true;
	  let list_contacts = this.state.list_contacts;
	  for (let key in list_contacts) {
	    list_contacts[key].selected = rowchecked;
	  }
	  this.setState({ list_contacts: list_contacts, rowchecked: rowchecked });
	};

	selectUser = (event) => {
	  let index = event.target.value;
	  let list_contacts = this.state.list_contacts;
	  list_contacts[index].selected = list_contacts[index].selected ? false : true;
	  this.setState({ list_contacts: list_contacts });
	};


	handleChangeFrequecny = (event) => {
		let frequency = event.target.value;
	    /*if(this.state.is_recurring){
	    	let mgs = "Changing the date or frequency of this task will delete all the future tasks and re-schedule this task. All overdue tasks will be uneffected. Are you sure you want to continue?";
	    	let title = "Alert";
	    	let alert_param = {title: title, message: mgs, ok_text: 'Ok', confirm: true, alertHandler: this.loadFrequencyChangeTask, stack: {frequency: frequency}}
	    	this.setState({alert_param: alert_param})
	    	return false;
	    }*/
	    this.setState({frequency: frequency, id_due_change: this.state.is_recurring})
	}
	loadFrequencyChangeTask = (result, stack) => {
		if(!result || stack.prevent) {
		    this.setState({alert_param: null})
		    return
		}
		this.setState({frequency: stack.frequency});
		this.setState({alert_param: null})
	}

	showReferenceTask = (event) => {
		this.setState({show_ref: "view_tasks"})
	}



	render()
	{
		if(!this.state.ready) {
		    return <div><CSLLoader /></div>;
		}
		let task =  this.state.task;
		let loggedincontact = Store.getStoreData('loggedincontact').contact_id;
		let created_by = task.contact_id;
		let assign_to = task.cur_assigned_to;
		let frequency = Utils.list_shedules();
		let role = Store.getStoreData('role');
		let schemes = Store.getStoreData('select_schemes');
		let enabled_task_scheme = Store.getStoreData('enabled_task_scheme');
		let gcs = Store.getStoreData('gc_companies');
		console.log("enabled_task_scheme", enabled_task_scheme, gcs)



		if(this.state.alert_param !== null) {
		  return <AlertBox alertParam = {this.state.alert_param} />
		}

		return (<div style={{backgroundColor: "#f2f2f3", position: "absolute"}}>
			<Style.ModalContainer>
			    <Style.ModalHeaderText>
			    {
			    	(() => {
			    		if(this.state.task_id === 0) {
			    			return "Create Task";
			    		}else{
			    			return "Update Task";
			    		}
			    	})()
			    }
			    </Style.ModalHeaderText>

			    {
			    	(() => {
			    		if(this.state.show_scheme && this.state.edit_hint){
			    			return <Style.ModalHeaderCloseBtn onClick={this.closeScheme}><FaTimes /></Style.ModalHeaderCloseBtn>
			    		}else{
			    			return <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
			    		}
			    	})()
			    }

			    <div style={{clear: "both"}}></div>
			    <ModalBody>
			        <Style.ModalBodyInner>
			            {
			            	(() => {
			            		if(Object.keys(this.state.ref).length > 0) {
			            			return(<Style.HeaderText>
			            					The task is a part of the following recurring task: <span style={{cursor: "pointer", color: "#115d9c"}} onClick={this.showReferenceTask}>{this.state.ref['ref_id']}</span>. Ad-hoc tasks that become overdue are converted into non-recurring tasks
			            					</Style.HeaderText>
			            					)
			            		}
			            	})()
			            }

			            <Style.ModalContainertop>
		                {
		                	(() => {
		                		if(this.state.task_id === 0 && this.state.has_gc && !this.state.edit_hint) {
		                			return(<div style={{display: "contents"}}>
		                				<Style.ModalNameLabelDiv>Select Company</Style.ModalNameLabelDiv>
		                				<Style.ModalNameInputDiv>
			                				<Style.MRModalSelect name="gc_comp_id" value={this.state.gc_comp_id} onChange={this.handleCompanyChange}>
			                				{/*<option key="0" value="0">Select a Company</option>*/}
						                    {
						                        (() => {
						                              if (Object.keys(this.state.gc_lists).length !== 0) {
						                                let c = [];
						                                for (let key in this.state.gc_lists) {
						                                  c.push(
						                                    <option key={key} value={key}>{this.state.gc_lists[key].company_name}</option>
						                                  );
						                                }
						                                return c;
						                              }
						                        })()
						                    }
						                    </Style.MRModalSelect>
		                				</Style.ModalNameInputDiv>
		                			</div>)
		                		}
		                	})()
		                }


		                {
		                	(() => {
		                		if(this.state.task_id === 0 && (role === "admin_manager" || role === "team_manager") && enabled_task_scheme && !this.state.edit_hint) {
		                			return(<div style={{display: "contents"}}>
		                				<Style.ModalNameLabelDiv>Select Scheme</Style.ModalNameLabelDiv>
		                				<Style.ModalNameInputDiv>
			                				<Style.MRModalSelect name="scheme_id" value={this.state.scheme_id} onChange={this.handleSchemeChange}>
			                				<option key="0" value="0">Select a scheme</option>
						                    {
						                        schemes.map((item) => {
						                            {
						                                return (
						                                    <option key={item.id} value={item.id}>{item.name}</option>
						                                );
						                            }
						                        })
						                    }
						                    </Style.MRModalSelect>
		                				</Style.ModalNameInputDiv>
		                			</div>)
		                		}
		                	})()
		                }

		                {
		                	(() => {
		                		if(this.state.show_scheme){
		                			return (<div style={{display: "contents"}}>

		                						{
		                							(() => {
		                								if(this.state.edit_hint){
		                									return(<div style={{display: "contents"}}>
		                										<Style.ModalNameLabelDiv>Task Name</Style.ModalNameLabelDiv>
		                										<Style.ModalNameInputDiv>
		                										    <Style.ModalInput name="name" value={this.state.single_hint.name.replace(/'+/g,"'")} onChange={this.handlehintChange} />
		                										</Style.ModalNameInputDiv>
		                										<Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
		                										<Style.ModalNameInputDiv>
		                										    {/*<Style.ModalTextArea name="description" value={this.state.single_hint.description.replace(/'+/g,"'")} onChange={this.handlehintChange} />*/}
		                											<HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.single_hint.description} />


		                										</Style.ModalNameInputDiv>
								                				<Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
								                					<Style.ModalNameInputDiv>
										                				<Style.MRModalSelect name="frequency" value={this.state.single_hint.frequency} onChange={this.handlehintChange}>
													                    {
													                        frequency.map((item) => {
													                            {
													                                return (
													                                    <option key={item.id} value={item.name}>{item.nickname}</option>
													                                );
													                            }
													                        })
													                    }
													                    </Style.MRModalSelect>
												                    </Style.ModalNameInputDiv>
		                										</div>)
		                								}else{
		                									return(<div style={{display: "contents"}}>
		                										<Style.ModalNameLabelDiv>Tasks</Style.ModalNameLabelDiv>
		                										<Style.ModalNameInputDiv>
		                											<CSLTable add={false} processData={this.processschemeData} tableRows={10} headerText={'MCC Scheme'} subtask={true} addsubTask={this.addsubTask}/>
		                											{
		                												(() => {
		                													if(this.state.show_calander) {
		                													    console.log('this.state.x, this.state.y', this.state.tableindex)
		                													    const startDate = null;
		                													    return (
		                													    	<Style.MemberMenu style={{top: `${320 + (this.state.tableindex*42)}px`, right: 250}} onMouseLeave={this.HideDate(this.state.row_id)} >
									                    				                    	<DatePicker
									                    		                    	          onChange={this.onSchemeDateChange(this.state.row_id)}
									                    		                    	          locale={"en-GB"}
									                    		                    	          clearIcon={null}
									                    		                    	          calendarIcon={null}
									                    		                    	          value={this.state.calender_date}
									                    		                    	          isOpen={true}
									                    		                    	          minDate={new Date()}
									                    		                    	        />

		                													         </Style.MemberMenu>

		                													            )
		                													}
		                												})()
		                											}
		                										</Style.ModalNameInputDiv>
		                									</div>)
		                								}

		                							})()
		                						}

		                					</div>)
		                		}else{
		                			return(<div style={{display: "contents"}}>
							                <Style.ModalNameLabelDiv>Task Name</Style.ModalNameLabelDiv>
							                <Style.ModalNameInputDiv>
							                    <Style.ModalInput name="task_name" value={this.state.task_name.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.makecomplete}/>
							                </Style.ModalNameInputDiv>
							                <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
							                <Style.ModalNameInputDiv>
							                    {/*<Style.ModalTextArea name="description" value={this.state.description.replace(/'+/g,"'")} onChange={this.handleChange} readOnly={this.state.makecomplete}/>*/}
							                	<HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.description} />

							                </Style.ModalNameInputDiv>
							                {
							                	(() => {
							                		if(Store.getStoreData('role') !=='team') {
							                			return(<div style={{display: "contents"}}>
							                						{
							                							(() => {
							                								if(this.state.task_id === 0){
							                									return(<div style={{display: "contents"}}>
							                										<Style.ModalNameLabelDiv>Assign to Multiple Users</Style.ModalNameLabelDiv>
							                										<Style.ModalNameInputDiv>
							                											<input type="checkbox" checked={this.state.is_multi_user}  onChange={this.changeMultiusers}/>
							                										</Style.ModalNameInputDiv>
							                									</div>)
							                								}
							                							})()
							                						}
							                						{
							                							(() => {
							                								if(!this.state.is_multi_user){
							                									return(<div style={{display: "contents"}}>
			                												                <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
			                												                <Style.ModalNameInputDiv>
												                                				<Style.MRModalSelect name="assign_to" value={this.state.assign_to} onChange={this.handleChange} disabled={this.state.makecomplete}>
												                			                    {
												                			                        this.state.list_contacts.map((item) => {
												                			                            {
												                			                                return (
												                			                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
												                			                                );
												                			                            }
												                			                        })
												                			                    }
												                			                    </Style.MRModalSelect>
			                												                </Style.ModalNameInputDiv>
							                											</div>)
							                								}else{
							                									return(<div style={{display: "contents"}}>
							                												<Style.ModalNameLabelDiv>Users</Style.ModalNameLabelDiv>
									                										<Style.ModalNameInputDiv>
									                											<CSLTable add={false} processData={this.processsUserData} tableRows={10} headerText={'Assign Multiple users'} subtask={false} />
									                										</Style.ModalNameInputDiv>
									                									</div>)
							                								}
							                							})()
							                						}
							                				</div>)
							                		}
							                	})()
							                }

							                {
							                	(() => {
							                		if(this.state.task_id === 0) {
							                			return(<div style={{display:"contents"}}>
							                				<Style.ModalNameLabelDiv>Repeat this task </Style.ModalNameLabelDiv>
							                				<Style.ModalNameInputDiv>
							                					{
							                						(() => {
							                							if (this.state.repeate_task) {
							                								return (
							                									<div>
							                										<SelectedRadio>Yes</SelectedRadio>
							                										<UnselectedRadio onClick={this.switchRadio('repeate_task')}>No</UnselectedRadio>
							                									</div>
							                								);
							                							} else {
							                								return (
							                									<div>
							                										<UnselectedRadio onClick={this.switchRadio('repeate_task')}>Yes</UnselectedRadio>
							                										<SelectedRadio>No</SelectedRadio>
							                									</div>
							                								);
							                							}
							                						})()
							                					}
							                				</Style.ModalNameInputDiv>
							                				</div>)
							                		}
							                	})()
							                }


							                {
							                	(() => {
							                		if(this.state.repeate_task) {
							                			return(<div style={{display:"contents"}}>
							                				<Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
							                					<Style.ModalNameInputDiv>
									                				<Style.MRModalSelect name="frequency" value={this.state.frequency} onChange={this.handleChange}>
												                    {
												                        frequency.map((item) => {
												                            {
												                                return (
												                                    <option key={item.id} value={item.name}>{item.nickname}</option>
												                                );
												                            }
												                        })
												                    }
												                    </Style.MRModalSelect>
											                    </Style.ModalNameInputDiv>
											                    <Style.ModalNameLabelDiv>First scheduled<span style={{color:"#ff474a"}} title="Schedule date must be greter than current date">*</span></Style.ModalNameLabelDiv>
				                                				<CSLDateCover>
				                				                    <DatePicker
				                				                              onChange={this.onscheduleDateChange}
				                				                              clearIcon={null}
				                				                              calendarIcon={null}
				                				                              locale={"en-GB"}
				                				                              value={this.state.schedule_date}
				                				                              minDate={new Date()}
				                				                            />
				                				                </CSLDateCover>
							                				</div>)
							                		} else {
							                			return(<div style={{display:"contents"}}>
					                				                <Style.ModalNameLabelDiv>Due Date</Style.ModalNameLabelDiv>
					                				                {
					                				                	(() => {
					                				                		if(this.state.makecomplete) {
					                				                			return <Style.ModalNameInputDiv>{this.state.show_due_date}</Style.ModalNameInputDiv>
					                				                		}else{
					                				                			return(
					                				                				<CSLDateCover>
					                								                    <DatePicker
					                								                              onChange={this.onDateChange}
					                								                              clearIcon={null}
					                								                              calendarIcon={null}
					                								                              locale={"en-GB"}
					                								                              value={this.state.due_date}
					                								                              minDate={this.state.overdue_task ? '' : new Date()}
					                								                            />
					                								                </CSLDateCover>)
					                				                		}
					                				                	})()
					                				                }

							                				</div>)
							                		}
							                	})()
							                }


							                {
							                	(() => {
							                		if(this.state.is_recurring && !this.state.overdue_task) {
							                			return(<div style={{display:"contents"}}>
	                				                				<Style.ModalNameLabelDiv>Frequency</Style.ModalNameLabelDiv>
	                				                					<Style.ModalNameInputDiv>
	                						                				<Style.MRModalSelect name="frequency" value={this.state.frequency} onChange={this.handleChangeFrequecny} disabled={this.state.makecomplete || this.state.overdue_task}>
	                									                    {
	                									                        frequency.map((item) => {
	                									                            {
	                									                                return (
	                									                                    <option key={item.id} value={item.name}>{item.nickname}</option>
	                									                                );
	                									                            }
	                									                        })
	                									                    }
	                									                    </Style.MRModalSelect>
	                								                    </Style.ModalNameInputDiv>
							                					</div>)
							                		}
							                	})()
							                }



							                <Style.ModalNameLabelDiv>Attachments</Style.ModalNameLabelDiv>
							                <Style.ModalNameInputDiv style={{paddingTop: "0px"}}>
							                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} />
							                </Style.ModalNameInputDiv>


							                {
							                	(() => {
							                		if(this.state.makecomplete) {
							                			return(
							                				<div style={{display: "contents"}}>
											                    <Style.ModalNameLabelDiv>Comments</Style.ModalNameLabelDiv>
											                    <Style.ModalNameInputDiv>
											                        <Style.ModalTextArea name="comments" value={this.state.comments.replace(/'+/g,"'")} onChange={this.handleChange} />
											                    </Style.ModalNameInputDiv>
											                </div>
											              )
							                		}
							                	})()
							                }

							                {
							                	(() => {
							                			if(this.state.makecomplete && Store.getStoreData('role') === "team" && Store.getStoreData('enabled_approval_task')){
							                				return(<div style={{display: "contents"}}>
							                					<div style={{display:"contents"}}>
							                					<Style.ModalNameLabelDiv></Style.ModalNameLabelDiv>
							                					<Style.ModalNameInputDiv>This task requires approval, please select a manager to proceed</Style.ModalNameInputDiv>
							                					</div>
					                				                <Style.ModalNameLabelDiv>Select a Manager</Style.ModalNameLabelDiv>

						                				                <Style.ModalNameInputDiv>
	        				                                				<Style.MRModalSelect name="manager_id" value={this.state.manager_id} onChange={this.handleChange}>
	        				                			                    <option key="0" value="0">Select a manager</option>
	        				                			                    {
	        				                			                        this.state.list_managers.map((item) => {
	        				                			                            {
	        				                			                                return (
	        				                			                                    <option key={item.ID} value={item.ID}>{item.contact_name}</option>
	        				                			                                );
	        				                			                            }
	        				                			                        })
	        				                			                    }
	        				                			                    </Style.MRModalSelect>
						                				                </Style.ModalNameInputDiv>
							                				</div>)
							                			}
							                	})()
							                }
		                			</div>)
		                		}

		                	})()
		                }

			            </Style.ModalContainertop>
			        </Style.ModalBodyInner>
			    </ModalBody>

			    {
			    	(() => {
			    		if(this.state.show_scheme){
			    			return(<Style.CancelSubmitButtonOuterContainer>
			    				{
			    					(() => {
			    						if(this.state.edit_hint){
			    							return(<div>
			    								<Style.CancelButton onClick={this.closeScheme}>Back</Style.CancelButton>
			    								<Style.SubmitButton onClick={this.updateSchemeTask}>Save</Style.SubmitButton>
			    							</div>)
			    						}else{
			    							return(<div>
			    								<Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			    								<Style.SubmitButton onClick={this.createRecurringTask}>Submit</Style.SubmitButton>
			    							</div>)
			    						}
			    					})()
			    				}

			    			</Style.CancelSubmitButtonOuterContainer>)
			    		}else{
			    			return(<Style.CancelSubmitButtonOuterContainer>
			            <Style.CancelButton onClick={this.closeModal}>Cancel</Style.CancelButton>
			            {
			            	(() => {
			            		if(this.state.task_id === 0) {
			            			if(this.state.repeate_task){
			            				return <Style.SubmitButton onClick={this.createScheduleTask}>Submit</Style.SubmitButton>
			            			}else{
			            				return <Style.SubmitButton onClick={this.createGeneralTask(1)}>Submit</Style.SubmitButton>
			            			}
			            		}else{
			            			if(this.state.makecomplete) {
			            				return (<Style.SubmitButton onClick={this.completeGeneralTask(1)}>Submit</Style.SubmitButton>)
			            			}else{
				            				if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'manager' || Store.getStoreData('role') === 'team_manager') {
				            					return (<div style={{display:"contents"}}>
				            						<Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.createGeneralTask(2)}>Update</Style.SubmitButton>
				            						<Style.SubmitButton style={{backgroundColor: "#04ada8"}} onClick={this.makeComplete}>Complete</Style.SubmitButton>
				            						<Style.SubmitButton style={{backgroundColor: "#e13751"}} onClick={this.RemoveTask(this.state.task_id)}>Remove</Style.SubmitButton>
				            					</div>)
				            				}else{
												if(parseInt(loggedincontact) === parseInt(created_by)){
				            						return (<div style={{display:"contents"}}>
				            						<Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.createGeneralTask(2)}>Update</Style.SubmitButton>
				            						<Style.SubmitButton style={{backgroundColor: "#04ada8"}} onClick={this.makeComplete}>Complete</Style.SubmitButton>
				            						</div>)
				            					} else if(parseInt(loggedincontact) === parseInt(assign_to)){
				            						return <Style.SubmitButton style={{backgroundColor: "#1e3e62"}} onClick={this.makeComplete}>Complete</Style.SubmitButton>
				            					}
				            				}
			            			}
			            		}
			            	})()
			            }

			    </Style.CancelSubmitButtonOuterContainer>)
			    		}
			    	})()
			    }
			    {
			    	(() => {
			    		if(this.state.scheme_loading){
			    			return <CSLLoader />
			    		}
			    	})()
			    }
			    {
			      (() => {
			        switch(this.state.show_ref) {
			    		case 'view_tasks' : return <div><InactiveOverlay /><TasksHOC task_id={this.state.ref['task_id']} module_id='tmview' closeUserDialog={this.closeRefModal} cur_lane={this.state.ref['cur_lane']} /></div>; break;
			        }
			      })()
			    }
			</Style.ModalContainer>

				</div>)
	}
}

export default MangeTask;
