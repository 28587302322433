import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import Dropzone from '../Common/Dropzone';
import { FaTimes} from 'react-icons/fa';
import '../../App.css';
import AlertBox from '../Common/AlertBox';
import DatePicker from 'react-date-picker';
import moment from 'moment';

const MRModalLabel = styled.label`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #414141;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: #a0a0a0;
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-weight: 100;
    font-family: 'Montserrat',sans-serif;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
    const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 80%;
    margin: 0px auto;
    padding: 15px;
    left: 10%;
    top: 65%;
    position: absolute;
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;
const HeaderButton = styled.div`
    color: #ffffff;
    width: 30px;
    height: 29px;
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 10px;
    font-family: 'Montserrat',sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

class EditFollowupTaskModal extends React.Component
{ 
    state = { tasks: null, ready: false, followup_sub: '', assigned_to: 0, followup_note: '', date: new Date(), review_list: [], parent_task_id: 0,
    due_by: '1970-01-01' ,binFiles: [], cur_files_json: null, isdesable: false, alert_param: null}    

	componentDidMount()
	{
        console.log("followuptaskvalue",Store.getStoreData('cur_followup_task'))
        let cur_followup_task = Store.getStoreData('cur_followup_task');
        let objectData = JSON.parse(Store.getStoreData('cur_followup_task').task_json).object_data;

        let followup_sub = objectData.subject;
        let followup_note = objectData.note;
        let assigned_to = cur_followup_task.cur_assigned_to;
        let date = new Date(cur_followup_task.due_date);
        let dateStr = moment(cur_followup_task.due_date).format('YYYY/MM/DD');
        
        let task_json = JSON.parse(cur_followup_task.task_json);
        let cur_files_json = [];
        console.log("task_json.object_data==>", task_json.object_data)
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        } 
        console.log("cur_files_json==>", cur_files_json)
        let review_list = Store.getStoreData('reviewee_list');

        let gc_users = Store.getStoreData('gc_users');
        let has_gc = Store.getStoreData('has_gc');
        let role = Store.getStoreData('role');
        let update_review_list = [];
        if(role === 'am' && has_gc === true){                                    
            review_list.forEach((item) => {
                let reviewID = item.ID.toString();
                if(reviewID in gc_users){
                    update_review_list.push(item); 
                }
            })                                                                     
        }else{                                    
            update_review_list = review_list;
        }

        let reviewee_name = update_review_list.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
        this.setState({review_list: reviewee_name, followup_sub, followup_note, assigned_to, cur_files_json,
            due_by: dateStr, date: date, ready: true})      
	}
	
    closeModal = (event) => {        
        this.props.closeModal();
    }    

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    onDateChangeDueBy = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        this.setState({due_by: dateStr, date: date});
    }

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }

    EditTask = (event) => {
        event.preventDefault();        
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json]; 
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);  
        task_json['object_data'].subject = this.state.followup_sub;
        task_json['object_data'].note = this.state.followup_note;        
        task_json['task_data'].cur_assigned_to = this.state.assigned_to;
        task_json['task_data'].due_date = this.state.due_by;
        task_json['action_data'] = { "action": "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.followup_note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: bin_files };
        console.log(postData); 
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask); 
        //this.setState({ followup_sub: '', followup_note: '',assigned_to: 0, date: new Date(),binFiles: [], cur_files_json: null})  
    }

    processEditFollowupTask = (result) => {        
        //alert("The follow-up task has been updated successfully.");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.customprocessEditFollowupTask, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        //window.location.reload(true);
    }
    customprocessEditFollowupTask= (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        window.location.reload(true);
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        console.log('files in Modal', files);
        this.setState({binFiles: files});
    } 

    render()
    {           
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <div>
            <ScoreContainer>
                <AlwaysScrollToView />
                <ScoreHeaderContainer>Edit follow-up task<HeaderButton onClick={this.closeModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                <ScoreBodyContainer>
                    <MRModalLabel>Subject</MRModalLabel>
                    <MRModalInput type="text" name="followup_sub" onChange={this.handleChange} value={this.state.followup_sub}/>
                    <FCKCommentDiv style={{padding: "0px", marginTop: "10px",marginBottom: "10px"}}>
                        <MRModalLabel>Note</MRModalLabel>
                        <FCKTextarea rows="6" style={{width: "98%",marginTop: "10px"}} value={this.state.followup_note} onChange={this.changeFollowupNote} />
                    </FCKCommentDiv>
                    <MRModalLabel>Assign To</MRModalLabel>
                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                        {
                            (() => {
                                if(this.state.review_list.length !== 0) {
                                    let reviewees = [];
                                    //console.log("his.state.reviewee_id", this.state.reviewee_id);
                                    for(let item of this.state.review_list){
                                        reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                        
                                    }
                                    return reviewees;
                                }

                            })()
                        }
                    </MRModalSelect>
                    <MRModalLabel>Due By</MRModalLabel>
                    <CSLDateCover>
                        <DatePicker
                                onChange={this.onDateChangeDueBy}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                minDate={new Date()}
                                value={this.state.date}
                                />
                    </CSLDateCover>
                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                    <ModalNameInputDiv>
                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={!this.state.isdesable}/>
                    </ModalNameInputDiv>
                </ScoreBodyContainer>
                
                <div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
                    <MRModalNextBtn onClick={this.EditTask}>Update Task</MRModalNextBtn>
                    <MRModalCancelBtn onClick={this.closeModal}>Close</MRModalCancelBtn>
                </div>                           
                
            </ScoreContainer>    
            <AlertBox alertParam={this.state.alert_param} />
            </div>  
        )
    }

}
export default EditFollowupTaskModal;