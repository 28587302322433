import React from 'react';
import styled from 'styled-components';

const MRModalInput = styled.input`
    height: 28px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;

class CSLTextInput extends React.Component
{
    state = {value: ""};

    componentDidMount() {
        this.setState({value: this.props.value});
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state !== nextState) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    componentDidUpdate(nextProps) {
        console.log("Text input updated");
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({value: event.target.value});
    }

    synchronizeHoc = (event) => {
        event.preventDefault();
        const obj = {entity: this.props.entity, name: this.props.name, value: this.state.value, field: this.props.field};
        this.props.handleTextChange(obj);
    }

    render() {
        let disabledValue = 'disabled' in this.props ? this.props.disabled : false
        return (
            <MRModalInput id={this.props.id} type="text" value={this.state.value} onChange={this.handleChange} onBlur={this.synchronizeHoc} disabled={disabledValue}/>
        );
    }
}

export default CSLTextInput;