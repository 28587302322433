import React from 'react';
import styled from 'styled-components';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 5px;
`;
const RadioBlock = styled.div`
	margin-top: 5px;
`;

class General extends React.Component
{
	state = {general: null};

	constructor(props) {
	    super(props);

	}

	componentDidMount()
	{

		this.setState({general: this.props.general});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({general: this.props.general});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}


	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
		return (
			<GeneralContainer>
							<GeneralInnerContainer>
								<GeneralLabel>Enable Approval Process</GeneralLabel>
								{
									(() => {
										if (this.state.general.enabled_approval_task) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('enabled_approval_task')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('enabled_approval_task')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>
							
			</GeneralContainer>
		);
	}
}

export default General;