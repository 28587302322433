import React from 'react';
import moment from 'moment';
import CSLTable from './CSLTable';
import AdvanceFilter from './AdvanceFilter';
// import ReportModal from './ReportModal';
// import PdfGenerator from './PdfGenerator';
import CSLReportModal from './CSLReportModal';
import PdfGenerator from './PdfGenerator';

import Store from '../../../Common/Store';
import APICall from '../../../Common/APICall.js';
import Utils from '../../../Common/Utils.js';
import styled from 'styled-components';



const RButton = styled.button`
	background-color: #1E3E62;
    color: #ffffff;
    padding: 10px 15px;
    border: 1px solid #1E3E62;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const Section88 = styled.div`
	width: calc(100% - 10px);
	box-sizing: border-box;
	float: left;
`;
const Section12 = styled.div`
	width: 12%;
	box-sizing: border-box;
	float: left;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 160px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
    cursor: pointer;
`;

const completeFiled = {cur_assigned_to: 'assigned_to_text', contact_id: 'created_by', company_id : 'company_name'}


class EmployeeReporting extends React.Component
{
	state = {
				show_filter: true, 
				filters: null, 
				pill_filters: null, 
				show_report: false, 
				generate: false, 
				groupby_selections: [], 
				sortby_selected: {accessor: 'none', order: 'Descending'},
				tilte_pdf : null,
				orientation:'portrait'
			}

	data = {};

	constructor(props) {
    	super(props);
    	this.scrollDiv = React.createRef();
  	}

  	setComponentState = () => {
  		let filters = this.state.filters === null ? this.props.filters : this.state.filters;
      console.log("filters->>comple", filters)
  		let pill_filters = this.state.pill_filters === null ? this.props.pillFilters : this.state.pill_filters
  		let grouping_config = Store.getStoreData('groupings')
  		let tags_config = grouping_config === null ? [] : grouping_config.tags_config
  		let groupings = {}
  		let data = this.props.data

  	
      let table_data = this.generateTableData()
      for(let r of table_data.raw_data) {
      	for(let id in filters) {
      		if(!(r[id] in filters[id].options)) {
      			filters[id].options[r[id]] = {name: r[id]}
      		}
      	}
      }


      console.log("gc_companies", Store.getStoreData('gc_assoc'), filters)
      let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
      let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
  		this.setState({filters, pill_filters, data, groupings, tilte_pdf, orientation})
  	}



  	componentDidMount() {
  		this.setComponentState()
  	}

  	componentDidUpdate(prevprops) {
  		if(prevprops === this.props)return

  		this.setComponentState()
  	}


	showOnlyAssign = (flag) =>{
	  this.props.showOnlyAssign(flag);
	}

	setShowTable = (flag, table) => {
	  this.props.setShowTable(flag, table);
	}

	toggleConfigureReport = (event) => {
		let show_configure_report = false;
		show_configure_report = show_configure_report === true ? false : true;
		this.setState({show_configure_report});
	}

	hideReport = () => {
		this.setState({show_report: false});
	}

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});
	}

	reportCallback = () => {
		this.setState({show_report: true})
	}

    generateTableData = () => {
    	let data = this.props.processTableData()
    	let ret = {columns: data.columns, data: [], raw_data: []}
    	
    	for(let elem of data.raw_data) {
    		ret.data.push(elem);
    		ret.raw_data.push(elem);   		
    	}

    	return ret
    }

    goToReport = () => {
    	this.setState({show_report: true})
    }
		toggleOrientation = (mode) => {
			this.setState({orientation: mode});
		}

			changeSort = (sortby) => {
		        let data = JSON.parse(JSON.stringify(this.state.data));
		        this.data.sortby = sortby;
		        this.setState({data: this.data, sortby_selected: sortby.selected});
			}

			changeGroup = (groupby) => {
		        let data = JSON.parse(JSON.stringify(this.data));
		        this.data.groupby = groupby;
		        this.setState({data: this.data, groupby_selections: groupby.selections});
			}

	render()
	{
		
		if(this.state.filters === null) return <div></div>

		console.log('this.props.buttonSetTop', this.props)
		return (
			<div style={{position: "relative", backgroundColor: "#F2F2F2", padding: "10px 0px"}} ref={this.scrollDiv}>
				<div style={{clear: "both", height: '3px'}}></div>
				<div style={{clear: "both"}}></div>
				<CSLTable export={true}  goToReport={this.goToReport} export_file_name={"Register"}  
							add={false}  
							processData={this.generateTableData} 
							prefered_columns = {this.props.preferedColumns} 
							export_columns = {this.props.preferedColumns} 
							headerText={`${this.props.headerText} Register`}  
							tableRows={10} 
							refreshCallback={this.refreshCallback}
							report={true}
              showHF={true}
              showtopLebel={this.props.showtopLebel}
              buttonSet={this.props.buttonSet}
              buttonSetTop={this.props.buttonSetTop}
              textView={this.props.textView}
				/>           

				{
					(() => {
						if(this.state.show_report) {
							let fields = this.props.fields
							let data = this.props.data
							data.data = this.generateTableData().data

							console.log("show report", data)
							return (
									<div style={{position:'absolute', top:'0px', width:'100%'}}>
										<InactiveOverlay />
										<CSLReportModal
											data={data}
											changeSort={this.changeSort}
											changeGroup={this.changeGroup}
											hideReport={this.hideReport}
											toggleGenerate={this.toggleGenerate}
											filters={this.state.filters}
											toggleOrientation={this.toggleOrientation}
										/>
									</div>
								);
						}
					})()
				}
				{
					(() => {
						if (this.state.generate) {
							return (
								<div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
									<InactiveOverlay style={{zIndex: "1010"}} />
									<PdfGenerator 
									filters={this.state.filters} 
									pills={this.state.pill_filters} 
									toggleGenerate={this.toggleGenerate} 
									contactName={Store.getStoreData('contact')} 
									tiltePDF={this.state.tilte_pdf}
                  orientation={this.state.orientation}
									/>
								</div>
							);
						}
					})()
				}
			</div>
		);
	}

	scrollToMyRef = () => this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
}

export default EmployeeReporting;

